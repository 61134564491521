import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import {
  icon_svg_hierarchy,
  icon_svg_instances,
  icon_svg_object
} from '../../assets/components/svgs';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  createMetadataInstances,
  createMetadataObject,
  getApplication,
  getMetadataInstance,
  getMetadataInstances,
  getMetadataObject,
  updateMetadataInstance,
  updateMetadataObject
} from '../../http/metadata-service';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import Card from '../shared/cards/Card';
import InputField from '../shared/Fields/InputField';
import RadioButton from '../shared/Fields/RadioButton';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setApplication, setMetadataInstance, setMetadataObject } from '../../app/metadataReducer';
import PlusIcon from '@heroicons/react/outline/PlusIcon';
import {
  cloneDeep,
  compact,
  filter,
  find,
  flatten,
  flattenDeep,
  get,
  initial,
  isArray,
  isBoolean,
  isEmpty,
  isInteger,
  isNumber,
  isObject,
  isString,
  isUndefined,
  map,
  merge,
  pickBy,
  set,
  uniq
} from 'lodash';
import InstanceTree from './InstanceTree';
import {
  getDataTypes,
  reconcileNode,
  buildObjectAttributes,
  UNKNOWN_ATTRIBUTE_ACTION,
  ADD_ATTRIBUTE_ACTION,
  EDIT_ATTRIBUTE_ACTION,
  METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH,
  METADATA_OBJECT_ATTRIBUTE_MIN_VALUE,
  METADATA_OBJECT_ATTRIBUTE_MAX_VALUE,
  validateMaximumLength,
  METADATA_INSTANCE_DATA_TYPE_USER_INPUTS,
  METADATA_OBJECT_DATA_TYPE_STRING_ARRAY,
  METADATA_OBJECT_DATA_TYPE_BOOLEAN,
  METADATA_OBJECT_DATA_TYPE_OBJECT,
  METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY,
  METADATA_OBJECT_DATA_TYPE_STRING,
  METADATA_OBJECT_DATA_TYPE_NUMBER,
  METADATA_OBJECT_DATA_TYPE_INTEGER
} from './metadataUtil';
import BiButton from '../primitives/buttons/BiButton.primitive';
import ObjectMenu from './ObjectMenu';
import { RouteConstants } from '../../constants/RouteConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import moment from 'moment';
import { AuditBlock, AuditItem } from '../../styles/globalVariables';
import { AppConstants } from '../../constants/AppConstants';
import TextAreaField from '../shared/Fields/TextAreaField';
import TreeView from '../shared/TreeView/TreeView';
import { Attribute } from './AttributeTreeNode';
import { setApplicationList } from '../../app/appInitializeReducer';
import InstanceMenu from './InstanceMenu';
import { deleteMetadataInstance } from '../../http/survey-service';
import NoPermissionMessage from '../RoutePermission/NoPermissionMessage';
import { useTheme } from '../../context/themeContext';
import JSONFormCreator from './JSONEditor';
import JSONEditor from '../AppSettings/JSONEditor';
import AuditInfo from '../Common/AuditInfo';

/**
 * @parentId metadata
 * @manager Create, View, Edit Metadata Instance
 * @overview Create, View, Edit - New Metadata Instance
 *   <section>
 *       <p>
 *           <b>View Metadata Instance</b><br/>
 *           Clicking on metadata instance row in the metadata instance listing page will take user to View Metadata Instance Page in READ-ONLY mode. Clicking on <b>Back to <object-name> Instances</b> will take the user to metadata instance listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i>
 *           <br/><br/>
 *           <b>Edit Metadata Instance</b><br/>
 *           Clicking on <b>Manage Instance</b> button and then selecting <b>Edit Instance</b> on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Label, Attributes</b> fields. Clicking on <b>Cancel</b> will take the user back to READ-ONLY mode.
 *           <br/><br/>
 *           <b>Create Metadata Instance</b><br/>
 *           Clicking on <b>New Instance</b> button on metadata instance listing page or <b>Add Instance</b> from metadata object detail page <b>Manage Object</b> dropdown menu will take user to New Metadata Instance creation page. Clicking on <b>Cancel</b> will take the user to metadata instance listing page.
 *           <br/><br/>
 *           User can create new metadata instance by entering <b>Label</b> & <b>Attributes</b> mandatory fields and clicking on <b>Save</b> button on the admin portal toolbar.
 *           <br/><br/>
 *           The fields in the Attributes are rendered according to how the schema of the metadata object is defined. Data types can either be user-input or reference types.
 *           User input types are the ones to which user can store or record a value. There are various data types of user input attributes:
 *           <br/>
 *           <ul>
 *            <li>String</li>
 *            <li>Integer</li>
 *            <li>Number</li>
 *            <li>Date</li>
 *            <li>Enum</li>
 *            <li>Boolean</li>
 *            <li>Date</li>
 *            <li>Time</li>
 *            <li>Datetime</li>
 *            </ul>
 *            <br/><br/>
 *            Reference types are the ones which include User Input types. They are of following types:
 *           <br/>
 *           <ul>
 *            <li>String[]</li>
 *            <li>Object[]</li>
 *            <li>Object</li>
 *            </ul>
 *       </p>
 *       <p>
 *          <h4>View/Edit Metadata Instance Dropdown Menu</h4>
 *          <p>Following are the menu items listed under metadata instance <b>Manage Instance</b> drop down.</p>
 *          <br>
 *          <ul>
 *            <li>Edit Instance</li>
 *            <li>Delete Instance</li>
 *          </ul>
 *       </p>
 *       <p>
 *           <h4>Create/View/Edit Metadata Instance Fields</h4>
 *           <p>Create/View/Edit metadata instance page will display below fields with following validation constraints.</p>
 *           <br>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Label</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *            <tr>
 *               <td>Attributes</td>
 *               <td>Yes</td>
 *               <td>JSON(Javascript Object Notation</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       <p>This section describes the Create Metadata Object Status Code information.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_BAD_FORMAT</td>
 *               <td>Bad Syntax: {0} must be a {1}.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_ERROR_PARSING</td>
 *               <td>Error parsing resource JSON or XML ({0}).</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_NOT_EXIST</td>
 *               <td>Resource Id {0} does not exist.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_PARAM_DUPLICATE</td>
 *               <td>Duplicate name, <b><instance-name></b> for resource type MetadataInstance.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create metadata application page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create metadata application.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.1.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *            <tr>
 *               <td>Create Instance</td>
 *               <td>/applications/{id}/objects/{objectId}/instances/new</td>
 *               <td>POST</td>
 *               <td>metadata-service.metadata-instance.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>View Instance</td>
 *               <td>/applications/{id}/objects/{objectId}/instances/{instanceId}</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-instance.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Instance</td>
 *               <td>/applications/{id}/objects/{objectId}/instances/{instanceId}</td>
 *               <td>PUT</td>
 *               <td>metadata-service.metadata-instance.update</td>
 *               <td>Yes</td>
 *           </tr>
 *            <tr>
 *               <td>Delete Instance</td>
 *               <td>/applications/{id}/objects/{objectId}/instances/{instanceId}</td>
 *               <td>DELETE</td>
 *               <td>metadata-service.metadata-instance.delete</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>List users (View & Edit Metadata Object)</td>
 *               <td>/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create metadata object</p>
 *   </section>
 */
interface InstanceHeaderProps {
  onClick: Function;
  t: Function;
}

const AttributeHeaders: React.FC<InstanceHeaderProps> = ({ onClick, t }: InstanceHeaderProps) => (
  <>
    <div className="flex-start m-1 flex h-12 flex-1 flex-row flex-wrap items-center bg-[#F5F5F5] ">
      <div className="flex-1 flex-row">{t('T_NAME')}</div>
      <div className="flex-1 flex-row">{t('T_TYPE')}</div>
      <div className="flex-1 flex-row">{t('T_REQUIRED?')}</div>
      <div className="flex-1 flex-row">{t('T_ALLOW_EDITS?')}</div>
      <div className="flex-1 flex-row">{t('T_HASH_OF_CHARATERS')}</div>
      <div className="flex-1 flex-row">{t('T_MIN_HASH')}</div>
      <div className="flex-1 flex-row">{t('T_MAX_HASH')}</div>
      <div className="flex-1 flex-row">{t('T_ACTIONS')}</div>
    </div>
  </>
);

const CreateInstance = () => {
  const navigate = useNavigate();
  const { id: appId = '', objectId = '', instanceId = '' } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [instances, setInstances] = useState<Array<any>>([]);

  const [attribute, setAttribute] = useState<Attribute>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [attributeInfo, setAttributeInfo] = useState<any>();

  const [pageError, setPageError] = useState<any>();

  const [JSONMode, setJSONMode] = useState<boolean>(false);
  const [jsonString, setJsonString] = useState('');
  const [createdBy, setCreatedBy] = useState<string>('');

  const application = useAppSelector((state) => state.metadata.application);
  const metadataObject = useAppSelector((state: any) => state.metadata.metadataObject);
  const metadataInstance = useAppSelector((state: any) => state.metadata.metadataInstance);

  const [formData, setFormData] = useState<any>({
    label: '',
    createdBy: '',
    createdOn: '',
    updatedBy: '',
    updatedOn: ''
  });
  const [formError, setFormError] = useState<any>({
    label: ''
  });
  const [schema, setSchema] = useState<any>([]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      if (!application) {
        if (appId) {
          await getMetadataApplication(appId);
        }
      }

      if (!metadataObject) {
        if (objectId) {
          await getApplicationObject(appId, objectId);
        }
      }

      if (!metadataInstance) {
        if (instanceId) {
          await getMetadataObjectInstance(objectId, instanceId);
        } else {
          setIsEdit(true);
        }
      }
    };
    init();
  }, []);

  useEffect(() => {
    //Edit Flow
    if (!instanceId) {
      // setIsLoading(false);
      return;
    }
    const init = async () => {
      const {
        label = '',
        attributes = {},
        createdBy,
        createdOn,
        updatedBy,
        updatedOn
      } = metadataInstance || {};

      const userInfoMap: Map<string, any> = await getInstanceAuditInfo();
      setFormData({
        label,
        createdBy: getUserName(userInfoMap.get(createdBy)),
        updatedBy: getUserName(userInfoMap.get(updatedBy)),
        updatedOn: moment(updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT),
        createdOn: moment(createdOn).format(AppConstants.DEFAULT_DATE_FORMAT)
      });
    };
    if (metadataInstance && instanceId) {
      init();
    }
  }, [metadataInstance]);

  useEffect(() => {
    const breadcrumb: Array<string> = ['Metadata'];
    if (application) breadcrumb.splice(1, 0, application?.name);
    if (metadataObject) breadcrumb.splice(2, 0, metadataObject?.name);
    if (metadataInstance) breadcrumb.splice(3, 0, `${t('EDIT')} ${metadataInstance?.label}`);
    if (!instanceId) {
      breadcrumb.push(t('T_NEW_METADATA_INSTANCE'));
    }
    let label: string = breadcrumb.join(' > ');

    const key: string = RouteConstants.ROUTE_MDS_INSTANCE_VIEW.replace(':id', appId)
      .replace(':objectId', objectId)
      .replace(':instanceId', instanceId || 'new');

    dispatch(setURLPaths([{ key, label }]));

    if (metadataObject?.attributes && !instanceId) {
      let initialSchema = cloneDeep(metadataObject?.attributes);
      let schema = updateKeyPath(initialSchema);
      //Create FLOW
      setSchema(schema);
      setIsLoading(false);
    }
    if (metadataObject?.attributes && metadataInstance?.attributes) {
      //Edit Flow
      const { label = '' } = metadataInstance || {};
      setFormData({ ...formData, label });
      setSchema(generateSchemaFromResult(metadataInstance.attributes, metadataObject.attributes));
      setIsLoading(false);
    }
  }, [application, metadataObject, metadataInstance]);

  const getInstanceAuditInfo = async () => {
    if (!metadataInstance) {
      return new Map<string, any>();
    }
    const { createdBy, updatedBy } = metadataInstance || {};
    const userProfiles: Array<any> = await getUserProfiles(uniq(compact([createdBy, updatedBy])));
    return new Map<string, any>(
      userProfiles.map((userProfile: any) => [userProfile.userId, userProfile])
    );
  };

  const getMetadataApplication = async (id: string) => {
    if (application) {
      return;
    }
    const response = await getApplication(id);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setApplication(data));
    } else {
      setPageError({ message: getFailureMessage(response), status });
    }
  };

  const getMetadataObjectInstance = async (objectId: string, instanceId: string) => {
    if (metadataInstance) {
      return;
    }

    const response = await getMetadataInstance(objectId, instanceId);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setMetadataInstance(data));
    } else {
      setPageError({ message: getFailureMessage(response), status });
    }
  };

  const getApplicationObject = async (applicationId: string, objectId: string) => {
    if (metadataObject) {
      return;
    }
    const response = await getMetadataObject(applicationId, objectId);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setMetadataObject(data));
    } else {
      setPageError({ message: getFailureMessage(response), status });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    let toastType: string = 'success';
    let toastMessage: string = (
      instanceId
        ? t('T_METADATA_INSTANCE_UPDATED_SUCCESS')
        : t('T_METADATA_INSTANCE_CREATED_SUCCESS')
    ).replace('%s', formData.label);

    const payload: any = instanceId
      ? {
          label: formData.label,
          attributes: generateAttributesPayload(schema)
        }
      : {
          instances: [
            {
              label: formData.label,
              attributes: generateAttributesPayload(schema)
            }
          ]
        };

    let response: any;
    if (!instanceId) {
      response = await createMetadataInstances(objectId, payload);
    } else {
      response = await updateMetadataInstance(objectId, instanceId, payload);
    }
    const { status, data = {} } = response;
    const success: boolean = HTTP_STATUS.isSuccess(status);
    if (!success) {
      toastType = 'error';
      toastMessage = getFailureMessage(response);
    } else {
      setIsEdit(!isEdit);
    }
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType
      })
    );
    setIsLoading(false);

    if (success) {
      if (!instanceId) {
        navigate(
          RouteConstants.ROUTE_MDS_INSTANCE_LIST.replace(':id', appId).replace(
            ':objectId',
            objectId
          )
        );
      } else {
        dispatch(setMetadataInstance(data));
      }
    }
  };

  const getCancelButtonText = (): string => {
    if (!instanceId || isEdit) {
      return t('T_CANCEL');
    }

    return t('T_BACK_TO_INSTANCES').replace('%s', metadataObject?.name || '');
  };

  const deleteInstance = async () => {
    setIsLoading(true);
    const msg: string = t('T_METADATA_INSTANCE_DELELTE_SUCCESS');
    const { id, metadataObjectId, label } = metadataInstance;
    const response: any = await deleteMetadataInstance(metadataObjectId, id);
    const { status } = response;
    const success = HTTP_STATUS.isSuccess(status);
    dispatch(
      setToastData({
        toastMessage: success ? msg.replace('%s', label || id) : getFailureMessage(response),
        isToastActive: true,
        toastType: success ? 'success' : 'error'
      })
    );
    setIsLoading(false);
  };

  const setValue = (keyPath: string, newValue: any, strIndex: number = 0) => {
    //strIndex is for String[] types
    let clonedSchema = cloneDeep(schema);
    let type = get(clonedSchema, keyPath)?.type;

    if (type === METADATA_OBJECT_DATA_TYPE_STRING_ARRAY) {
      let values = get(clonedSchema, keyPath)?.value;
      values[strIndex] = newValue;
      clonedSchema = set(clonedSchema, `${keyPath}.value`, values);
    } else {
      set(clonedSchema, `${keyPath}.value`, newValue);
    }

    setSchema(clonedSchema);
  };

  // const generateInitialSchema = (arr: any, parentKeyPath: string = '') => {
  //   for (let i = 0; i < arr.length; i++) {
  //     const obj = arr[i];
  //     const keyPath = parentKeyPath ? `${parentKeyPath}.attributes[${i}]` : `[${i}]`;

  //     if (
  //       ['String', 'Date', 'Datetime', 'Time', 'Integer', 'Number', 'Boolean'].includes(obj.type)
  //     ) {
  //       arr[i] = { ...obj, value: '', keyPath };
  //     } else {
  //       arr[i] = { ...obj, keyPath };
  //     }

  //     if (obj.attributes && obj.attributes.length > 0) {
  //       arr[i].attributes = generateInitialSchema(obj.attributes, keyPath);
  //     }
  //   }

  //   return arr;
  // };

  const generateSchemaFromResult = (result: any, initialSchema: any[]): any[] => {
    const updatedSchema = cloneDeep(updateKeyPath(initialSchema));

    const updateAttributes = (obj: any, attributes: any[], keyPath: string) => {
      for (let attr of attributes) {
        const attrKeyPath = `${keyPath}.${attr.name}`;

        if (
          attr.type === METADATA_OBJECT_DATA_TYPE_OBJECT ||
          attr.type === METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY
        ) {
          if (obj[attr.name]) {
            updateAttributes(obj[attr.name], attr.attributes, attrKeyPath);
          }
        } else if (METADATA_INSTANCE_DATA_TYPE_USER_INPUTS.includes(attr.type)) {
          if (obj[attr.name]) {
            attr.value = obj[attr.name];
          }
        }

        attr.keyPath = attrKeyPath;
      }
    };

    const updateSchema = (obj: any, schema: any[]) => {
      for (let item of schema) {
        if (obj[item.name] !== undefined) {
          if (item.attributes) {
            if (Array.isArray(obj[item.name])) {
              const attributes = obj[item.name].map((attr: any) => {
                return generateSchemaFromResult(attr, item.attributes);
              });
              item.attributes = attributes.length > 0 ? attributes : [];
            } else {
              item.attributes = generateSchemaFromResult(obj[item.name], item.attributes);
            }
          } else {
            item.value = obj[item.name];
          }
        }
      }
    };

    updateAttributes(result, updatedSchema, '[0]');
    updateSchema(result, updatedSchema);

    return updateKeyPath(updatedSchema);
  };

  const updateKeyPath = (array: any, parentKeyPath = '') => {
    return array.map((item: any, index: number) => {
      const keyPath = `${parentKeyPath}[${index}]`;

      if (Array.isArray(item)) {
        return updateKeyPath(item, keyPath);
      }

      const newItem = {
        ...item,
        keyPath
      };
      if (METADATA_INSTANCE_DATA_TYPE_USER_INPUTS.includes(item.type)) {
        newItem.value =
          item.type === METADATA_OBJECT_DATA_TYPE_STRING_ARRAY
            ? item.value || ['']
            : item.type === METADATA_OBJECT_DATA_TYPE_BOOLEAN
            ? item.value || false
            : item.value || '';
      }

      if (newItem.attributes) {
        newItem.attributes = updateKeyPath(newItem.attributes, `${keyPath}.attributes`);
      }

      return newItem;
    });
  };

  const addToSchemaArray = (keyPath: string, explicitSchema: any) => {
    const schemaInitial = explicitSchema || schema;

    let existingAttributes = get(schemaInitial, keyPath)?.attributes;

    if (!existingAttributes) {
      return;
    }

    let flag = Array.isArray(existingAttributes[0]); //True then 2d array else it is 1d

    let mergedArray: any = flag ? [] : [[], []];

    if (flag) {
      mergedArray = [...existingAttributes, []];
      let attr = existingAttributes[existingAttributes.length - 1];
      attr.forEach((obj: any, index: number) => {
        mergedArray[mergedArray.length - 1].push(cloneDeep(obj));
      });
    } else {
      existingAttributes.forEach((obj: any, index: number) => {
        mergedArray[0].push(cloneDeep(obj));
        mergedArray[1].push(cloneDeep(obj));
      });
    }

    let schemaClone = cloneDeep(schemaInitial);

    set(schemaClone, `${keyPath}.attributes`, mergedArray);
    return updateKeyPath(schemaClone);
  };

  const addDeleteToStringArray = (keyPath: string, type: string = 'add', index: number = 0) => {
    let clonedSchema = cloneDeep(schema);
    let values = get(clonedSchema, keyPath)?.value;

    if (type === 'add') {
      values.push('');
    } else {
      values.splice(index, 1);
    }

    set(clonedSchema, `${keyPath}.value`, values);
    setSchema(updateKeyPath(clonedSchema));
  };

  const deleteFromSchemaArray = (keyPath: string, subIndex: number) => {
    let existingAttributes = get(schema, keyPath)?.attributes;

    let flag = existingAttributes.length === 2; //If its going to be a single array of objects and not 2D

    if (flag) {
      existingAttributes.splice(subIndex, 1);
      existingAttributes = existingAttributes[0];
    } else {
      existingAttributes.splice(subIndex, 1);
    }
    let schemaClone = cloneDeep(schema);

    set(schemaClone, `${keyPath}.attributes`, existingAttributes);
    setSchema(updateKeyPath(schemaClone));
  };

  const generateAttributesPayload = (arr: any) => {
    const output: any = {};

    function processAttributes(attributes: any, name: string = '') {
      if (!Array.isArray(attributes)) {
        return {};
      }

      if (Array.isArray(attributes[0])) {
        const arr: any[] = [];
        attributes.forEach((attr: any) => {
          arr.push(processAttributes(attr));
        });
        return arr;
      }

      const obj: any = {};

      attributes.forEach((attr: any) => {
        if (Array.isArray(attr)) {
          obj[attr[0]] = processAttributes(attr.slice(1));
        } else if (
          attr.type === METADATA_OBJECT_DATA_TYPE_OBJECT ||
          attr.type === METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY
        ) {
          let val = processAttributes(attr.attributes);
          if (!isEmpty(val)) {
            obj[attr.name] = attr.attributes
              ? attr.type === METADATA_OBJECT_DATA_TYPE_OBJECT
                ? val
                : attr.type === METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY && isArray(val)
                ? val
                : [val]
              : '';
          }
        } else {
          if (
            attr.type === METADATA_OBJECT_DATA_TYPE_STRING ||
            attr.type === METADATA_OBJECT_DATA_TYPE_STRING_ARRAY
          ) {
            if (
              (isString(attr.value) && attr.value.length) ||
              (isArray(attr.value) && attr?.value[0]?.length)
            ) {
              if (attr.value !== '') {
                obj[attr.name] = attr.value;
              }
            }
          } else if (attr.type === METADATA_OBJECT_DATA_TYPE_BOOLEAN) {
            obj[attr.name] = attr.value;
          } else if (
            attr.type === METADATA_OBJECT_DATA_TYPE_NUMBER ||
            attr.type === METADATA_OBJECT_DATA_TYPE_INTEGER
          ) {
            if (attr.value !== '') {
              obj[attr.name] = attr.value;
            }
          } else {
            if (attr.value !== '') {
              obj[attr.name] = attr.value;
            }
          }
        }
      });

      return obj;
    }

    function removeEmptyObjects(obj: any) {
      return pickBy(obj, (value: any) => {
        if (isObject(value)) {
          const nonEmptyValue: any = removeEmptyObjects(value);
          return !isEmpty(nonEmptyValue);
        }
        return true;
      });
    }

    arr.forEach((item: any) => {
      if (item.attributes) {
        let val = processAttributes(item.attributes);
        output[item.name] = isArray(val) ? val : item.type === 'Object' ? val : [val];
      } else if (isArray(item.value) && item.value.length > 0) {
        output[item.name] = filter(item.value, Boolean);
      } else {
        if (
          (isString(item.value) && item.value.length) ||
          isNumber(item.value) ||
          isBoolean(item.value) ||
          isInteger(item.value)
        ) {
          output[item.name] = item.value;
        }
      }
    });

    return removeEmptyObjects(output);
  };

  // console.log(schema);
  // console.log(generateAttributesPayload(schema));

  const validateSchema = (schema: any, parentRequired: boolean) => {
    const { label } = formData;

    if (!label) {
      return false;
    }

    const validateAttributes = (
      attributes: any,
      isParentRequired: boolean,
      shouldObeyChildRequired: boolean,
      parentAttributesPath: string
    ) => {
      for (const attribute of attributes) {
        let isRequired = attribute.isRequired;
        let parentAttributes = get(schema, parentAttributesPath);
        if (shouldObeyChildRequired && parentRequired && !isRequired) {
          isRequired = true;
        } else if (!isParentRequired && isRequired && parentAttributes?.attributes.length) {
          const otherValuesBlank = parentAttributes.attributes.every(
            (parentAttribute: any) =>
              parentAttribute?.value === undefined || parentAttribute.value === ''
          );

          isRequired = !otherValuesBlank;
        }

        if (
          typeof attribute.value !== 'undefined' &&
          (attribute.value !== '' || (isArray(attribute.value) && attribute.value[0] !== '')) &&
          !isParentRequired &&
          isRequired &&
          parentAttributesPath !== ''
        ) {
          shouldObeyChildRequired = true;
        }

        if (
          attribute.type === METADATA_OBJECT_DATA_TYPE_STRING_ARRAY &&
          (isRequired || isParentRequired) &&
          !filter(attribute.value, Boolean).length
        ) {
          return false;
        }

        if (
          METADATA_INSTANCE_DATA_TYPE_USER_INPUTS.includes(attribute.type) &&
          (isRequired || isParentRequired) &&
          attribute.value === ''
        ) {
          return false;
        }

        if (
          attribute.attributes &&
          !validateAttributes(
            attribute.attributes,
            isRequired,
            shouldObeyChildRequired,
            attribute.keyPath
          )
        ) {
          return false;
        }
      }

      return true;
    };

    return validateAttributes(schema, parentRequired, false, '');
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <MenuButtonsPortal>
        <BiButton
          className="bg-white text-primary"
          type="button"
          onClick={() => {
            if (!instanceId) {
              navigate(-1);
              return;
            }
            if (isEdit) {
              setIsEdit(false);
            } else {
              navigate(
                RouteConstants.ROUTE_MDS_INSTANCE_LIST.replace(':id', appId).replace(
                  ':objectId',
                  objectId
                )
              );
            }
          }}
        >
          {getCancelButtonText()}
        </BiButton>
        {isEdit ? (
          <BiButton
            className="bg-primary text-white"
            type="button"
            onClick={handleSave}
            disabled={!validateSchema(schema, false)}
          >
            {t('T_SAVE')}
          </BiButton>
        ) : (
          metadataObject &&
          metadataInstance && (
            <InstanceMenu
              metadataObject={metadataObject}
              metadataInstance={metadataInstance}
              setEdit={setIsEdit}
              onDelete={deleteInstance}
            />
          )
        )}
      </MenuButtonsPortal>

      {!pageError && (
        <div
          className="flex max-h-screen w-full flex-col justify-start overflow-y-scroll bg-gray-100 px-2 py-3"
          style={useTheme().theme.bgColorStyleForLoggedInPage}
        >
          <div className="flex w-full flex-row">
            <div className="w-3/4 p-2">
              <Card
                title={t('T_INSTANCE_DETAILS')}
                icon={icon_svg_object}
                alt="file-code-icon"
                className="flex flex-1"
              >
                <div className="flex w-full flex-col flex-wrap">
                  <div className="mb-5 flex w-full flex-row">
                    <div className="flex w-3/4 flex-col">
                      <InputField
                        type={'text'}
                        id={'instance-name'}
                        label={'T_LABEL'}
                        placeholder={''}
                        fieldKey={'label'}
                        asterixFirst={true}
                        required={true}
                        formData={formData}
                        setFormData={setFormData}
                        formError={formError}
                        disabled={!isEdit}
                        min={0}
                        max={1024}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="w-1/2 p-2">
              {instanceId && ( //Edit Flow
                <div className="ml-5 flex flex-1 flex-col">
                  <AuditBlock>
                    {/* <AuditItem>
                      <div>{t('T_METADATA_NAME')}</div>
                      <div>{application?.name}</div>
                    </AuditItem>
                    <AuditItem>
                      <div>{t('T_OBJECT_NAME')}</div>
                      <div>{metadataObject?.name}</div>
                    </AuditItem> */}
                    <AuditItem>
                      {(formData.createdBy || formData?.createdOn) && (
                        <div>
                          <AuditInfo
                            label={'CREATED'}
                            date={formData?.createdOn || ''}
                            user={formData?.createdBy || ''}
                            isUserId={false}
                          />
                        </div>
                      )}
                      {(formData.updatedOn || formData?.updatedBy) && (
                        <div>
                          <AuditInfo
                            label={'LAST_MODIFIED'}
                            date={formData?.updatedOn || ''}
                            user={formData?.updatedBy || ''}
                            isUserId={false}
                          />
                        </div>
                      )}
                    </AuditItem>
                  </AuditBlock>
                </div>
              )}
            </div>
          </div>
          <div className="fex-col w-3/4 p-2">
            <Card
              title={t('T_ATTRIBUTES')}
              icon={icon_svg_hierarchy}
              alt="file-code-icon"
              className="flex flex-1"
            >
              <JSONFormCreator
                schema={schema || []}
                isEditMode={instanceId.length > 0}
                setSchema={setValue}
                disabled={isLoading || !isEdit}
                addToSchemaArray={(path: string) => {
                  setSchema(addToSchemaArray(path, schema));
                }}
                deleteFromSchemaArray={deleteFromSchemaArray}
                addDeleteToStringArray={addDeleteToStringArray}
              ></JSONFormCreator>
            </Card>
          </div>
        </div>
      )}
      {pageError && <NoPermissionMessage message={pageError?.message} />}
    </LoaderContainer>
  );
};

export default React.memo(CreateInstance);
