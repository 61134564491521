import { useEffect, useState, createRef } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TemplateTable from '../../shared/tables/TemplateTable';
import { ITableHeader } from '../../shared/tables/models/ITableHeader';
import { useNavigate, useParams } from 'react-router-dom';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import MenuButtonsPortal from '../../Menu/MenuButtonsPortal';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import { setToastData } from '../../../app/toastReducer';
import { noDataAvailableMessage } from '../../Organizations/helpers';
import { setURLPaths } from '../../../app/organizationReducer';
import { AppConstants } from '../../../constants/AppConstants';
import { RouteConstants } from '../../../constants/RouteConstants';
import { getQuestionnaires } from '../../../http/survey-service';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import { useDidMountEffect } from '../../../util/customHooks';
import { setTableOptions, resetTableOptions } from '../../../app/templateTableReducer';

import AddCircle from '../../../assets/addCircle.svg';
import { capitalize, cloneDeep, findIndex, filter, map, find } from 'lodash';
import moment from 'moment';
import { getSortTableArray } from '../../../util/table-utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../context/themeContext';
import { rolePermissions } from '../../../app/models/content/constants';
import Checkbox from '../../shared/Fields/Checkbox';
import { deleteManifestItem, setManifest } from '../../../app/migrationExportReducer';
import { ActionButtons } from '../../Reusable/ActionButton';
import { surveyFilters, setEmptyItemsList } from '../../../util/surveyUtils';

import {
  getConfigKeyOrg,
  configServiceFilters,
  updateOrgResponse,
  updateSelectedFilter,
  getUserLoggedInOrg
} from '../../ConfigurationService/Configutils';
import { EllipsisDiv } from '../../../styles/globalStyles';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

/**
 * @parentId survey templates
 * @manager Survey Templates Listing
 * @overview
 *   <section>
 *       <p>
 *           Survey Templates listing is the default page when the user is navigated on clicking <b>Survey Templates</b> navigation bar menuitem. Listing page will show all the survey templates in a paginated way.
 *           The default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & survey templates data.
 *       </p>
 *       <br>
 *       <p>
 *           New Survey Template can be created by clicking on <b>New Survey Template</b> in tool bar.
 *       </p>
 *       <br>
 *        <p>
 *           Datagrid will display details of each Survey Template as row item with each column representing survey template property.
 *           <br/>
 *           <ul>
 *               <li>Status</li>
 *               <li>Name</li>
 *               <li>Title</li>
 *               <li>Period Start</li>
 *               <li>Period End</li>
 *           </ul>
 *       </p>
 *       <p>
 *           Sorting of Survey Templates grid is supported on <b>Status, Name, Title, Period Start & Period End</b> columns.The breadcrumb bar on the toolbar will reflect location of the user in the admin portal. Clicking on survey template rowitem will drill down user to survey template details page which will be displayed in readonly mode.
 *       </p>
 *     </section>
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes the survey templates lisitng status code information.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *             <tr>
 *               <td>400</td>
 *               <td>SS_SORT_UNKNOWN</td>
 *               <td>Unknown sort parameter name</td>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>SS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>SS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of survey templates listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for survey templates listing</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Survey Service</td>
 *               <td>1.3.2</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for listing survey templates page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/questionnaires</td>
 *               <td>GET</td>
 *               <td>survey-service.questionnaire.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for Survey Templates listing page</p>
 *   </section>
 */

interface SurveyListProps {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const SurveyList = ({ type = 'listing', toggleModal = () => {} }: SurveyListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [paging, setPaging] = useState<any>(null);
  // const [size, setSize] = useState(
  //   type === 'listing' ? AppConstants.DEFAULT_PAGE_SIZE : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
  // );

  const [rows, setRows] = useState<any>([]);
  const [pagingOpts, setPagingOpts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const manifestQuestionnairesData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.globalQuestionnaires
  );
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const userProfileInfo: any = useAppSelector((state) => state.userManagement.userProfileInfo);

  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);

  const [loadNextData, setLoadNextData] = useState<any>({
    orgPaging: false,
    categoriesPaging: false,
    searchOrgName: '',
    searchOrg: false
  });

  const fetchMoreOrgData = () => {
    setLoadNextData((prevState: any) => ({ ...prevState, orgPaging: true }));
  };

  const searchOrgData = (data: any) => {
    setEmptyItemsList('orgFilter', filterList, setFilterList);
    setLoadNextData((prevState: any) => ({ ...prevState, searchOrgName: data, searchOrg: true }));
  };

  const [paramObj, setParamObj] = useState({
    orgFilter: '',
    statusFilter: ''
  });

  const surveyFiltersList = surveyFilters.map((filter: any) => {
    return {
      ...filter,
      fetchMoreData: fetchMoreOrgData,
      searchData: searchOrgData
    };
  });

  const [filterList, setFilterList] = useState<Array<any>>(surveyFiltersList);
  const [filterPillsData, setFilterPillsData] = useState<Array<any>>([]);

  useEffect(() => {
    const init = async () => {
      await setFilterData();
      //await getSurveyList({ currentPage, size });
      initBreadcrump();
    };
    if (type === 'migrationPreview') {
      setIsLoading(false);
      return;
    }

    init();

    if (type !== 'listing') {
      // dispatch(resetTableOptions());

      dispatch(
        setTableOptions({
          ['survey-list']: {
            searchText: '',
            currentPage: AppConstants.DEFAULT_PAGE,
            size: AppConstants.DEFAULT_PAGE_SIZE,
            sortName: '',
            sortType: '',
            tableFilters: { orgFilter: [] }
          }
        })
      );
    }
  }, []);

  useDidMountEffect(() => {
    const { tableFilters, searchText, currentPage, size, sortName, sortType } =
      globalFilters['survey-list'];
    applyFilters(searchText, currentPage, size, sortName, sortType, tableFilters);
  }, [globalFilters]);

  useDidMountEffect(async () => {
    if (loadNextData.orgPaging && !loadNextData.searchOrg) {
      loadOrgData();
    }
    if (loadNextData.searchOrg && !loadNextData.orgPaging) {
      loadOrgData();
    }
  }, [loadNextData]);

  const loadOrgData = async () => {
    let orgResponse = await getConfigKeyOrg(loadNextData, filterList, userProfileInfo);

    let initialFilterList: any = [...filterList];
    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'orgFilter') {
        return updateOrgResponse(obj, orgResponse, loadNextData, setLoadNextData);
      } else {
        return { ...obj };
      }
    });

    setFilterList(currentFilterList);
    updateSelectedFilter(
      filterList,
      filterPillsData,
      '',
      fetchMoreOrgData,
      setFilterPillsData,
      () => {} //setFilterList
    );
  };

  const setFilterData = async () => {
    let [orgResponse, loggedInOrgResponse] = await Promise.all([
      getConfigKeyOrg(loadNextData, filterList, userProfileInfo),
      getUserLoggedInOrg(userProfileInfo)
    ]);
    if (orgResponse && orgResponse.data) {
      orgResponse.data = [...orgResponse.data, loggedInOrgResponse];
      orgResponse.data = orgResponse.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
    }
    let initialFilterList: any = [...filterList];

    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'orgFilter') {
        return updateOrgResponse(obj, orgResponse, loadNextData, setLoadNextData);
      } else {
        return { ...obj };
      }
    });
    setFilterList(currentFilterList);

    const {
      tableFilters = { orgFilter: [loggedInOrgResponse] },
      searchText = '',
      currentPage: filterCurrentPage = AppConstants.DEFAULT_PAGE,
      size: filterSize = AppConstants.DEFAULT_PAGE_SIZE,
      sortName = '',
      sortType = ''
    } = globalFilters['survey-list'] || {}; 

    dispatch(
      setTableOptions({
        ['survey-list']: {
          searchText: searchText,
          currentPage: filterCurrentPage,
          size: filterSize,
          sortName: sortName,
          sortType: sortType,
          tableFilters: tableFilters
        }
      })
    );
  };

  const getSurveyList = async ({ ...options }) => {
    const { page, size, search = '', sortOrder = '', sortType = '', filters = {} } = options;
    const surveyListResponse = await getQuestionnaires(
      page,
      type === 'listing' ? size : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
      sortType ? [`${sortOrder},${sortType}`] : [],
      search,
      filters
    );

    const { status, data } = surveyListResponse;

    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: surveyList = [], paging } = data;
      if (surveyList.length) {
        setRows(surveyList);
        setPaging(paging);
        setIsLoading(false);
      } else {
        setRows(`${t('T_NO_DATA_AVAILABLE_MSG')}`);
        setIsLoading(false);
      }
    }
  };

  const initBreadcrump = async () => {
    dispatch(
      setURLPaths([
        {
          key: `${RouteConstants.ROUTE_SURVEY_TEMPLATE_LISTING}`,
          label: `Survey Templates`
        }
      ])
    );
  };

  const applyFilters = async (
    search: string = '',
    page: number,
    size: number,
    sortOrder: string = '',
    sortType: string = '',
    filters: any
  ) => {
    const appliedFilterList = Object.keys(filters);
    setFilterPillsData(updatePillsData(filters, appliedFilterList));
    setFilterList(updateFilterListSelected(filters));
    setParamObj(updateParamObj(filters, appliedFilterList));
    getSurveyList({ page, size, search, sortOrder, sortType, filters });
  };

  const updateFilterListSelected = (appliedFilters: any) => {
    const initialFilterList: any = [...filterList];
    const updatedFilterList = initialFilterList.map((filterObj: any) => {
      const selectedFilter = appliedFilters[filterObj.id] ? appliedFilters[filterObj.id] : [];
      return { ...filterObj, selectedFilter };
    });

    return updatedFilterList;
  };

  const updatePillsData = (appliedFilters: any, appliedFilterList: any) => {
    const pillsData: any = [];
    if (appliedFilterList && appliedFilterList.length) {
      appliedFilterList.forEach((key: any) => {
        switch (key) {
          case 'statusFilter':
            if (appliedFilters[key]) {
              appliedFilters[key].forEach((item: any) => {
                pillsData.push({ name: item.display, label: 'Status', id: key });
              });
            }

            break;
          case 'orgFilter':
            if (appliedFilters[key]) {
              appliedFilters[key].forEach((item: any) => {
                pillsData.push({
                  name: item.name,
                  label: 'Organization',
                  id: key
                  //hideIcon: queryParams.id ? true : false
                });
              });
            }

            break;
        }
      });
    }

    return pillsData;
  };

  const updateParamObj = (appliedFilters: any, appliedFilterList: any) => {
    const initialParamObj: any = { ...paramObj };
    const initialFilterList: any = [...filterList];
    initialFilterList.forEach((filterObj: any) => {
      switch (filterObj.id) {
        case 'orgFilter':
          //initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'id').join(',');
          initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'id');
          break;
        case 'statusFilter':
          //initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'key').join(',');
          initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'key');
          break;
      }
    });

    return initialParamObj;
  };

  const removeFromPillsFilter = (pillsFilterObj: any) => {
    let initialFilterList: any = [...filterList];
    const initialFilterObj = find(initialFilterList, { id: pillsFilterObj.id });

    initialFilterObj.selectedFilter = filter(initialFilterObj.selectedFilter, (obj) => {
      return obj.name !== pillsFilterObj.name;
    });
    const { tableFilters } = globalFilters['survey-list'];
    const initialTableFilters = {...tableFilters};
    initialTableFilters[initialFilterObj.id] = initialFilterObj.selectedFilter;
    dispatch(
      setTableOptions({
        ['survey-list']: {
          ...globalFilters['survey-list'],
          tableFilters: {}
        }
      })
    );
    const updatedFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === pillsFilterObj.id) {
        return { ...obj, initialFilterObj };
      } else {
        return { ...obj };
      }
    });
    updateParamObjAfterRemovePills(updatedFilterList);
    updatePillsDataAfterRemovePills(updatedFilterList);
    setFilterList(updatedFilterList);
  };

  const updatePillsDataAfterRemovePills = (updatedFilterList: any) => {
    const pillsData: any = [];
    updatedFilterList.forEach((obj: any) => {
      switch (obj.id) {
        case 'orgFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({
              name: item.name,
              label: 'Organization',
              id: obj.id
              // hideIcon: queryParams.id ? true : false
            });
          });
          break;
        case 'statusFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({ name: item.display, label: 'Status', id: obj.id });
          });
          break;
      }
    });
    setFilterPillsData(pillsData);
  };

  const updateParamObjAfterRemovePills = (updatedFilterList: any) => {
    const initialParamObj: any = { ...paramObj };
    updatedFilterList.forEach((obj: any) => {
      initialParamObj[obj.id] = obj.selectedFilter;
      // switch (obj.id) {
      //   case 'orgFilter':
      //     //initialParamObj[obj.id] = map(obj.selectedFilter, 'id').join(',');
      //     initialParamObj[obj.id] = map(obj.selectedFilter, 'id');
      //     break;
      //   case 'statusFilter':
      //     //initialParamObj[obj.id] = map(obj.selectedFilter, 'key').join(',');
      //     initialParamObj[obj.id] = map(obj.selectedFilter, 'key');
      //     break;
      // }
    });
    setParamObj(initialParamObj);
    setIsLoading(true);
    const {
      searchText = '',
      currentPage: filterCurrentPage = AppConstants.DEFAULT_PAGE,
      size: filterSize = AppConstants.DEFAULT_PAGE_SIZE,
      sortName = '',
      sortType = ''
    } = globalFilters['survey-list'] || {};

    dispatch(
      setTableOptions({
        ['survey-list']: {
          searchText: searchText,
          currentPage: filterCurrentPage,
          size: filterSize,
          sortName: sortName,
          sortType: sortType,
          tableFilters: initialParamObj
        }
      })
    );
  };

  const elementSelector = (data: any, step: number) => {
    if (type === 'listing') {
      const { id } = data;
      roleAPIPermissions[AppConstants.PERMISSION_QUESTIONNAIRE_GET]
        ? navigate(`/survey-template/edit/${id}`)
        : dispatch(
            setToastData({
              toastMessage: 'User do not have permission to view Survey',
              isToastActive: true,
              toastType: 'error'
            })
          );
    }
  };

  const refreshTableData = (
    search: string = '',
    page: number,
    size: number,
    sortOrder: string = '',
    sortType: string = '',
    filters: any
  ) => {
    setTableValues(page, size, search, sortOrder, sortType, filters);
  };

  const setTableValues = (
    page: number,
    size: number,
    search: string,
    sortOrder: string = '',
    sortType: string = '',
    filters: any
  ) => {
    if (type === 'migrationPreview') {
      setCurrentActivePage(page * size - size);
    }
    setIsLoading(true);
  };

  const isCheckboxSelected = (data: any) => {
    const { id } = data;

    return (
      findIndex(selectedItems, function (item: any) {
        return item.id === id;
      }) !== -1
    );
  };

  const toggleCheckbox = (data: any) => {
    const { id, status = '', name = '', title = '' } = data;

    let existingArr: any = cloneDeep(selectedItems);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      existingArr.splice(existingId, 1);
      setSelectedItems(existingArr);
      return;
    }
    existingArr.push({ id, status, name, title });
    setSelectedItems(existingArr);
  };

  const toggleOverWrite = (data: any) => {
    const { id } = data;

    let existingArr: any = cloneDeep(manifestQuestionnairesData);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      if (existingArr[existingId]?.overWrite) {
        delete existingArr[existingId].overWrite;
      } else {
        existingArr[existingId].overWrite = true;
      }

      dispatch(setManifest({ key: 'globalQuestionnaires', value: existingArr }));
      return;
    }
  };

  const surveyHeaderBuilder = () => {
    let SURVEY_QUESTION_HEADERS: Array<ITableHeader> = [
      {
        key: 'status',
        description: 'T_STATUS',
        sortEnabled: type !== 'migrationPreview',
        width: type === 'migrationPreview' ? '15%' : '20%',
        sortingInformation: {
          order: ''
        },
        nastedData: true,
        render: (data: any) => {
          return data?.status === 'retired' ? 'Not Active' : capitalize(data.status);
        }
      },
      {
        key: 'name',
        description: 'T_NAME',
        sortEnabled: type !== 'migrationPreview',
        width: type === 'listing' ? '25%' : '35%',
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.name}>
              {data?.name}
            </EllipsisDiv>
          );
        }
      },
      {
        key: 'title',
        description: 'T_TITLE',
        sortEnabled: type !== 'migrationPreview',
        width: type === 'listing' ? '25%' : type === 'migrationPreview' ? '30%' : '35%',
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.title}>
              {data?.title}
            </EllipsisDiv>
          );
        }
      }
    ];

    switch (type) {
      case 'listing':
        SURVEY_QUESTION_HEADERS = SURVEY_QUESTION_HEADERS.concat([
          {
            key: 'effectivePeriodStart',
            description: 'T_SURVEY_PERIOD_START',
            sortEnabled: true,
            width: '15%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any) => {
              let date = data['effectivePeriodStart'];

              return date ? moment(date).format('MMMM DD, YYYY') : 'NA';
            }
          },
          {
            key: 'effectivePeriodEnd',
            description: 'T_SURVEY_PERIOD_END',
            sortEnabled: true,
            width: '15%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any) => {
              let date = data['effectivePeriodEnd'];

              return date ? moment(date).format('MMMM DD, YYYY') : 'NA';
            }
          }
        ]);

        break;

      case 'migrationSelection':
        SURVEY_QUESTION_HEADERS.unshift({
          key: 'checked',
          description: '',
          sortEnabled: false,
          width: '10%',
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={isCheckboxSelected(data)}
                  onChange={() => {
                    toggleCheckbox(data);
                  }}
                  testID="addModuleCheckbox"
                />
              </>
            );
          }
        });
        break;
      case 'migrationPreview':
        SURVEY_QUESTION_HEADERS.push(
          {
            key: 'overWrite',
            description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any, index: any) => {
              return (
                <>
                  <Checkbox
                    className="mx-auto"
                    height="20px"
                    width="20px"
                    onChange={() => {
                      toggleOverWrite(data);
                    }}
                    checked={data?.overWrite}
                    testID="allowOverWriteCheckbox"
                  />
                </>
              );
            }
          },
          {
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            styles: {
              justifyContent: 'center'
            }
          }
        );
        break;
    }

    return SURVEY_QUESTION_HEADERS;
  };

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(surveyHeaderBuilder());

  useEffect(() => {
    if (manifestQuestionnairesData?.length) {
      setSelectedItems(manifestQuestionnairesData);
    }
  }, [manifestQuestionnairesData]);

  useEffect(() => {
    if (type === 'migrationSelection') {
      setTableHeaders(surveyHeaderBuilder());
    } else if (type === 'migrationPreview') {
      setRows(selectedItems);
      setIsLoading(false);
      setTableHeaders(surveyHeaderBuilder());
    }
  }, [selectedItems]);

  // useEffect(() => {
  //   if (type === 'migrationPreview') {
  //     setCurrentActivePage(currentPage * size - size);
  //   }
  // }, [currentPage]);

  const saveSelection = () => {
    dispatch(setManifest({ key: 'globalQuestionnaires', value: selectedItems }));
    toggleModal();
  };

  const removeSelection = (index: number) => {
    const row: any = rows[index + currentActivePage];
    if (row) {
      const { id } = row;
      dispatch(deleteManifestItem({ key: 'globalQuestionnaires', id }));
    }
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <CustomersTableContainer className={`${type !== 'listing' && '!pb-0'}`}>
          {roleAPIPermissions[AppConstants.PERMISSION_QUESTIONNAIRE_CREATE] &&
          type === 'listing' ? (
            <MenuButtonsPortal>
              <BiButton
                className={'flex flex-row bg-primary text-white'}
                type="button"
                onClick={() => {
                  navigate(`/survey-template/new`);
                }}
              >
                <img src={AddCircle} className="mr-2 w-6 text-center"></img>
                {t('T_SURVEY_CREATION_BUTTON_TEXT')}
              </BiButton>
            </MenuButtonsPortal>
          ) : null}
          {/* )} */}
          {/* <ContractInviteMenu /> */}
          {/* {permissions.list ? ( */}
          <TemplateTable
            tableData={
              type === 'migrationPreview'
                ? rows.slice(
                    currentActivePage,
                    currentActivePage + AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
                  )
                : rows
            }
            templateSelector={elementSelector}
            onRefreshTableData={refreshTableData}
            searchPlaceholder={t('T_SEARCH_NAME_SURVEY_TEMPLATE') || ''}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            setCurrentPage={(page: any) => {
              setCurrentActivePage(parseInt(page));
            }}
            currentPage={
              type === 'migrationPreview' ? currentActivePage : globalFilters?.currentPage
            }
            pagingData={paging}
            containerClass="mt-0"
            showDeleteButton={true}
            theme={useTheme().theme}
            size={
              type === 'migrationPreview'
                ? AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
                : globalFilters?.size
            }
            isLocalPaginator={type === 'migrationPreview'}
            totalDataLength={rows.length}
            isPaginated={type !== 'migrationPreview'}
            disablePageSize={type !== 'listing'}
            isSearchable={type !== 'migrationPreview'}
            deleteOnClick={removeSelection}
            isFilterable={type !== 'migrationPreview'}
            filtersList={filterList}
            applyFilters={applyFilters}
            pillsData={filterPillsData}
            removeFilter={removeFromPillsFilter}
            defaultPillsLabel={t('T_CONFIG_CATEGORIES_FILTERS_DEFAULT_VIEW')}
            globalFilters={globalFilters}
            tablePage="survey-list"
            type={type}
          />
          {type === 'migrationSelection' ? (
            <div className="w-full">
              <ActionButtons
                primaryButtonLabel={t('T_SAVE')}
                primaryButtonAction={saveSelection}
                secondaryButtonLabel={t('T_CANCEL')}
                secondaryButtonAction={toggleModal}
                primaryButtonWidth="full"
                primaryButtonClass={'px-4'}
              />
            </div>
          ) : (
            ''
          )}
          {/* ) : !isLoading ? (
            <Table className="flex h-[100%] items-center justify-center">
              <TableBody>
                <TableRow className="font-bold	">
                  You don't have permission to view the table
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            ''
          )} */}
        </CustomersTableContainer>
      </LoaderContainer>
    </>
  );
};

export default SurveyList;
