import { AppConstants } from '../constants/AppConstants';
import env from '../environment';
import { getSessionContext } from '../util/admin-utils';
import BaseAPI from './api';
import { SwitchContext, switchSessionContext } from './authentication-services';
import { HTTP_STATUS } from './constants/http.status';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI.axiosInstance;

export interface INotificationParams {
  channel?: String;
  category?: String;
}
export interface TemplateParams {
  channel: String;
  type: String;
  key: String;
  value: String;
  description: String;
}
export interface NotificationTemplate {
  id?: string;
  orgId?: string;
  category: string;
  channel?: String;
  description: String;
  params: Array<TemplateParams>;
  configParams?: object;
}

export const updateNotificationTemplate = async (id: string, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(`${env.notificationPath}/notifications/templates/${id}`, body, {
    headers
  }).then((res) => res);
  return response;
};

export const getTemplateById = async (id: string) => {
  const headers = { ...globalHeaders };
  const response = await API.get(`${env.notificationPath}/notifications/templates/${id}`, {
    headers
  }).then((res) => res);
  return response;
};

export const getNotificationTemplates = async (
  category: string,
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: string = AppConstants.DEFAULT_SORT_BY,
  order: string = AppConstants.DEFAULT_SORT_ORDER,
  orgId: string = '',
  ignore_org: boolean = false,
  parent_org: boolean = false,
  filters: string = ''
) => {
  const headers = {
    ...globalHeaders,
    apikey: env.apikey
  };
  let query = `?page=${page}&size=${size}`;

  query += category ? `&category=${category}` : '';

  if (ignore_org) {
    query += `&ignore_org=${ignore_org}`;
  }
  if (orgId) {
    query += `&organization_id=${orgId}`;
  }
  if (parent_org) {
    query += `&parent_org=${parent_org}`;
  }

  if (filters) {
    query += `&${filters}`;
  }

  query = `${query}&sort=${sort},${order}`;

  return await API.get(`${env.notificationPath}/notifications/templates${query}`, {
    headers
  }).then((res) => res);
};

export const listNotificationTemplatesFiltered = async (name: string | undefined) => {
  const headers = { ...globalHeaders };
  const response = await API.get(`${env.notificationPath}/notifications/templates?size=100`, {
    headers
  });
  return response.data.data.filter((template: any) => {
    return template.category.startsWith(name);
  });
};

export const createNotificationTemplate = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.notificationPath}/notifications/templates`, body, {
    headers
  });
  return response;
};

export const deleteTemplate = async (id: string) => {
  const headers = { ...globalHeaders };
  const response = await API.delete(`${env.notificationPath}/notifications/templates/${id}`, {
    headers
  });
  return response;
};

export const copyDefaultTemplates = async (
  userOrg: string,
  templates: Array<string> = [],
  t?: any
) => {
  return new Promise(async (resolve) => {
    let failure;
    const sourceContext: SwitchContext | null = getSessionContext();
    if (!sourceContext) {
      resolve({
        data: {
          issues: [{ details: `${t('T_SESSION_EMPTY_MSG')}` }],
          status: HTTP_STATUS.HTTP_FORBIDEN
        }
      });
    }

    let stdTemplates: Array<NotificationTemplate> = [];
    const { category = '' }: INotificationParams = { category: 'INVITATION_NOTIFICATION' };
    const response = await getNotificationTemplates(
      templates.length ? '' : category?.toString(),
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );

    const { status, data: responseBody } = response;
    if (status != HTTP_STATUS.HTTP_OK) {
      resolve(response);
      return;
    }

    const { data = [] } = responseBody;
    if (!templates.length) {
      templates.push(category.toString());
    }

    stdTemplates = data
      .filter((template: any) => templates.indexOf(template.category) != -1)
      .map((template: any) => {
        const { channel: channel1, params } = template;
        params.forEach((param: any) => {
          param.channel = channel1;
          delete param.id;
        });
        delete template.id;
        delete template.channel;
        delete template.configParams;
        return template;
      });

    if (!stdTemplates.length) {
      resolve({
        data: {
          issues: [{ details: `${t('T_NO_DEFAULT_TEMPLATES_AVAILABLE')}` }],
          status: HTTP_STATUS.HTTP_BAD_REQUEST
        }
      });
      return;
    }

    const { tokenId, userRole }: any = sourceContext;
    const targetContext: SwitchContext = {
      userOrg,
      userRole,
      tokenId
    };

    // Session context switch to newly created organization
    const switchResponse = await switchSessionContext(targetContext);
    const { status: switchStatus } = switchResponse;
    if (switchStatus != HTTP_STATUS.HTTP_OK) {
      resolve(switchResponse);
      return;
    }
    const responses = await Promise.all(
      stdTemplates.map((template) => createNotificationTemplate(template))
    );
    const failures = responses.filter(
      (response: any) => response?.status >= HTTP_STATUS.HTTP_BAD_REQUEST
    );
    if (failures.length) {
      [failure] = failures;
    }

    // Restore back to logged in organization session context
    const switchResponse1 = await switchSessionContext(sourceContext);
    const { status: switchStatus1 } = switchResponse1;
    if (switchStatus1 != HTTP_STATUS.HTTP_OK) {
      resolve(switchResponse1);
      return;
    }
    resolve(failure || { data: { success: true }, status: HTTP_STATUS.HTTP_OK });
  });
};

export const getNotificationTemplatesForOrg = async (
  id: string,
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: string = AppConstants.QUERY_PARAM_CATEGORY,
  order: string = AppConstants.DEFAULT_SORT_ASC
) => {
  const headers = {
    ...globalHeaders,
    apikey: env.apikey
  };
  let query = id ? `orgId=${id}` : '';
  query = `${query}&page=${page}&size=${size}&sort=${sort},${order}`;

  return await API.get(`${env.notificationPath}/notifications/templates?${query}`, {
    headers
  }).then((res) => res);
};
