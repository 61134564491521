import { createSlice } from '@reduxjs/toolkit';

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState: {
    application: null as any,
    metadataObject: null as any,
    metadataInstance: null as any
  },
  reducers: {
    setApplication: (state, action) => {
      state.application = action.payload;
    },
    setMetadataObject: (state, action) => {
      state.metadataObject = action.payload;
    },
    setMetadataInstance: (state, action) => {
      state.metadataInstance = action.payload;
    }
  }
});

export const { setApplication, setMetadataObject, setMetadataInstance } = metadataSlice.actions;

export default metadataSlice.reducer;
