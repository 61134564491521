import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DotMenu, { Options } from '../primitives/dotMenu/DotMenu';
import { MenuConstants } from '../../constants/MenuConstants';
import ActionDropdown from '../primitives/dropdowns/ActionDropdown';
import { RouteConstants } from '../../constants/RouteConstants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setMetadataObject } from '../../app/metadataReducer';
import { AppConstants } from '../../constants/AppConstants';

interface InstanceMenuProps {
  setEdit: Function;
  onDelete: Function;
  metadataObject: any;
  metadataInstance: any;
}

const InstanceMenu = ({
  metadataObject,
  metadataInstance,
  setEdit,
  onDelete
}: InstanceMenuProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<Options | null>();

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const options: Array<any> = [];
  const { id, metadataObjectId } = metadataInstance;
  const { metadataApplicationId } = metadataObject;

  if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_UPDATE]) {
    options.push({
      display: `${t('T_EDIT_METADATA_INSTANCE')}`,
      key: MenuConstants.MENU_EDIT_METADATA_INSTANCE,
      action: () => {
        setEdit(true);
        navigate(
          RouteConstants.ROUTE_MDS_INSTANCE_VIEW.replace(':id', metadataApplicationId)
            .replace(':objectId', metadataObjectId)
            .replace(':instanceId', id)
        );
      }
    });
  }

  if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_DELETE]) {
    options.push({
      display: `${t('T_DELETE_METADATA_INSTANCE')}`,
      key: MenuConstants.MENU_DELETE_METADATA_INSTANCE,
      action: async () => {
        await onDelete();
        navigate(
          RouteConstants.ROUTE_MDS_INSTANCE_LIST.replace(':id', metadataApplicationId).replace(
            ':objectId',
            metadataObjectId
          )
        );
      }
    });
  }

  useEffect(() => {
    if (selectedOption?.key == MenuConstants.MENU_EDIT_METADATA_INSTANCE) {
      setEdit(true);
    }
  }, [selectedOption]);

  return (
    <>
      <ActionDropdown
        options={options}
        onSelectedOptionChange={(value: any) => {
          const { action } = value;
          action && action();
        }}
        value={selectedOption}
        defaultLabel={t('T_MANAGE_METADATA_INSTANCE')}
      />
    </>
  );
};

export default React.memo(InstanceMenu);
