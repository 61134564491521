import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';

import RadioButton from '../../../shared/Fields/RadioButton';
import InputField from '../../../shared/Fields/InputField';
import BiButton from '../../../primitives/buttons/BiButton.primitive';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../../../constants/AppConstants';
import { getGlobalBucketName } from '../../../../util/AppSetting';
import { find, isEmpty } from 'lodash';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFileNameWithTimeStamp } from '../QueUtils';

const content_types = ['image', 'video', 'audio'];
const default_val = { IMG: false, VID: false, AUD: false };

const conetentTypeValidations: any = { image: '5120', video: '10240', audio: '20480' };

const MediaQuestion = ({
  formData,
  setFormData,
  editMode,
  editFromSurveyTemplate = false,
  resetData,
  isPreview = false
}: any) => {
  const { t } = useTranslation();
  const fileRef = useRef<any>(null);

  const location = useLocation();
  const flowType = location.pathname.split('/').at(-1);
  const [isEdit, setIsEdit] = useState(flowType !== 'new');

  const [isOpen, setIsOpen] = useState<any>(default_val);
  const [contentType, setContentType] = useState<string>('image');
  const [fileUploadObj, setFileUploadObj] = useState<any>({});
  const [withMedia, setWithMedia] = useState<any>(true);
  const [timeStamp, setTimeStamp] = useState<any>(moment().unix());
  const [permission, setPermission] = useState<any>('public');
  // const queTypeObj = find(formData.queList, { type: formData.type });
  // const alloweFileTypes: any = queTypeObj
  //   ? JSON.parse(decodeURIComponent(queTypeObj?.queMetaData?.object)).alloweFileTypes
  //   : {};

  const alloweFileTypes: any = {
    image: ['.jpeg', '.jpg', '.png'],
    audio: ['.wav', '.mp3'],
    video: ['.mp4', '.m4v', '.mov']
  };

  const [mediaVal, setMediaVal] = useState<any>({
    file: '',
    fileName: '',
    bucketName: getGlobalBucketName(),
    path: `${AppConstants.SURVEY_MEDIA_PATH}`
  });

  const [formError, setFormError] = useState<any>({
    fileName: ''
  });

  useEffect(() => {
    setFormData({
      ...formData,
      fileUploadObj: fileUploadObj,
      extension: {
        ...mediaVal,
        path: `${AppConstants.SURVEY_MEDIA_PATH}${formData.type}/`,
        fileName: withMedia
          ? !isEmpty(fileUploadObj)
            ? mediaVal.fileName
            : formData.extension.fileName
          : '',
        allowedFileTypes: alloweFileTypes[contentType],
        mediaType: contentType,
        displayType: 'media'
      }
    });
  }, [contentType, fileUploadObj, mediaVal, permission, timeStamp, withMedia]);

  const getFileNameWithoutTimeStamp = (fileName: any) => {
    if (fileName) {
      setWithMedia(true);
      const extLastIndex = fileName.lastIndexOf('.');
      const fileWithoutExt = fileName.substr(0, extLastIndex);
      const timeStampIndex = fileWithoutExt.lastIndexOf('_');

      const fileNameWithoutTimestamp = `${fileWithoutExt.substr(
        0,
        timeStampIndex
      )}${fileName.substr(extLastIndex)}`;
      return fileNameWithoutTimestamp;
    }
  };

  const updateInitialExtensionData = (formData: any) => {
    if (formData.extension) {
      const { mediaType = 'image', fileName = '', permission = 'public' } = formData.extension;
      setContentType(mediaType);
      setMediaVal({ ...mediaVal, fileName: getFileNameWithoutTimeStamp(fileName) });
      setPermission(permission);
    }
  };

  useEffect(() => {
    updateInitialExtensionData(formData);
  }, []);

  useEffect(() => {
    if (resetData) {
      updateInitialExtensionData(resetData);
    }
  }, [resetData]);

  const fileUploadField = () => {
    return (
      <>
        <div className="flex w-full">
          <InputField
            className="pointer-events-none w-full !rounded-r-none bg-transparent"
            type="text"
            id="max-word-count"
            label=""
            placeholder="Upload default media"
            fieldKey="fileName"
            required={false}
            formData={mediaVal}
            setFormData={setMediaVal}
            formError={formError}
            handleChange={() => {}}
            disabled={false}
          />
          <BiButton
            className={`mr-2 w-[100px] rounded-l-none border border-solid border-[#8c8c8c] py-1.5`}
            type="button"
            disabled={isPreview}
            onClick={(e: any) => {
              !editMode && contentType && fileRef.current.click();
            }}
          >
            {t('T_BROWSE')}
          </BiButton>
          {!isPreview && mediaVal.fileName && (
            <div>
              {' '}
              <DeleteIcon
                className="mt-2 h-6 text-gray-600 hover:text-black"
                onClick={() => {
                  if (!editMode) {
                    setMediaVal({ ...mediaVal, fileName: '', file: '' });
                    setWithMedia(false);
                  }
                }}
                data-testid="delete-icon"
              />
            </div>
          )}

          <input
            type="file"
            ref={fileRef}
            data-testid={'media-attachment'}
            onChange={(e) => {
              let file: any = e.target.files;
              if (file.length) {
                const [fileObj] = file;
                const { size = '' } = fileObj;

                const maxSize = conetentTypeValidations[contentType];

                // if (size > maxSize) {
                //   setFormError({
                //     fileName: t(`T_FILE_SIZE_SURVEY_MEDIA_${contentType.toUpperCase()}_ERROR_MSG`)
                //   });
                // } else {
                //   setFormError({ fileName: '' });
                // }
                const timeStamp = moment().unix();
                setMediaVal({
                  ...mediaVal,
                  fileName: getFileNameWithTimeStamp(fileObj.name, timeStamp),
                  file: file
                });
                setFileUploadObj({
                  ...fileUploadObj,
                  [0]: { fileName: getFileNameWithTimeStamp(fileObj.name, timeStamp), file: file }
                });
                setTimeStamp(timeStamp);
                setWithMedia(true);
              }
            }}
            style={{ display: 'none' }}
            accept={(alloweFileTypes[contentType] || []).join(', ')}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="my-1 flex w-full flex-row">Select upload file type:</div>
      <div className="my-1 flex w-full flex-row">
        <div className="self-center">
          <RadioButton
            selectedField={contentType}
            disabled={isPreview}
            option={content_types[0]}
            name={`mediaQuestion`}
            onClick={() => {
              !editMode && setContentType(content_types[0]);
            }}
          />
        </div>
        <div className="my-auto flex w-full text-[14px]">{t('T_IMAGE_RADIO_MEDIA_QUESTION')}</div>
      </div>
      <div className="my-1 flex w-full flex-row">
        <div className="self-center">
          <RadioButton
            selectedField={contentType}
            disabled={isPreview}
            option={content_types[2]}
            name={`mediaQuestion`}
            onClick={() => {
              !editMode && setContentType(content_types[2]);
            }}
          />
        </div>
        <div className="my-auto flex w-full text-[14px]">{t('T_AUDIO_RADIO_MEDIA_QUESTION')}</div>
      </div>
      <div className="my-1 flex w-full flex-row">
        <div className="self-center">
          <RadioButton
            selectedField={contentType}
            disabled={isPreview}
            option={content_types[1]}
            name={`mediaQuestion`}
            onClick={() => {
              !editMode && setContentType(content_types[1]);
            }}
          />
        </div>
        <div className="my-auto flex w-full text-[14px]">{t('T_VIDEO_RADIO_MEDIA_QUESTION')}</div>
      </div>

      <div className="mb-4 mt-5 flex w-full flex-row">
        {t('T_MEDIA_QUESTION_FILE_UPLOAD_HEADER')}
      </div>

      {editFromSurveyTemplate && (
        <div className="mt-5 flex w-full">
          <div className="w-full">{fileUploadField()}</div>
        </div>
      )}
    </>
  );
};

const InputFieldContaner = styled.div`
  input {
    width: 100%;
  }
`;

export default MediaQuestion;
