import { createSlice } from '@reduxjs/toolkit';

export const surveySlice = createSlice({
  name: 'surveyManagement',
  initialState: {
    questionType: '',
    surveyDetails: '' as any,
    objectIds: {
      question: '',
      surveyQuestion: ''
    }
  },
  reducers: {
    setQuestionType: (state, action) => {
      state.questionType = action.payload;
    },
    setNewSurveyDetails: (state, action) => {
      state.surveyDetails = action.payload;
    },
    setObjectIds: (state, action) => {
      state.objectIds = action.payload;
    }
  }
});

export const { setQuestionType, setNewSurveyDetails, setObjectIds } = surveySlice.actions;

export default surveySlice.reducer;
