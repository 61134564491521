import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DotMenu, { Options } from '../primitives/dotMenu/DotMenu';
import { MenuConstants } from '../../constants/MenuConstants';
import ActionDropdown from '../primitives/dropdowns/ActionDropdown';
import { RouteConstants } from '../../constants/RouteConstants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setMetadataInstance, setMetadataObject } from '../../app/metadataReducer';
import { AppConstants } from '../../constants/AppConstants';

interface ObjectMenuProps {
  setEdit: Function;
  metadataObject: any;
}

const ObjectMenu = ({ metadataObject, setEdit }: ObjectMenuProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<Options | null>();

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const options: Array<any> = [];
  const { id, metadataApplicationId: applicationId } = metadataObject;

  if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_OBJECT_UPDATE]) {
    options.push({
      display: `${t('T_EDIT_METADATA_OBJECT')}`,
      key: MenuConstants.MENU_EDIT_METADATA_OBJECT,
      action: () => {
        setEdit(true);
        navigate(
          RouteConstants.ROUTE_MDS_OBJECT_VIEW.replace(':id', applicationId).replace(
            ':objectId',
            id
          )
        );
      }
    });
  }

  if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_LIST]) {
    options.push({
      display: `${t('T_LIST_METADATA_INSTANCES')}`,
      key: MenuConstants.MENU_LIST_METADATA_INSTANCE,
      action: () => {
        dispatch(setMetadataObject(metadataObject));
        navigate(
          RouteConstants.ROUTE_MDS_INSTANCE_LIST.replace(':id', applicationId).replace(
            ':objectId',
            id
          )
        );
      }
    });
  }

  if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_LIST]) {
    options.push({
      display: `${t('T_ADD_METADATA_INSTANCE')}`,
      key: MenuConstants.MENU_CREATE_METADATA_INSTANCE,
      action: () => {
        dispatch(setMetadataInstance(null));
        navigate(
          RouteConstants.ROUTE_MDS_INSTANCE_CREATE.replace(':id', applicationId).replace(
            ':objectId',
            id
          )
        );
      }
    });
  }

  useEffect(() => {
    if (selectedOption?.key == MenuConstants.MENU_EDIT_METADATA_OBJECT) {
      setEdit(true);
    }
  }, [selectedOption]);

  return (
    <>
      <ActionDropdown
        options={options}
        onSelectedOptionChange={(value: any) => {
          const { action } = value;
          action && action();
        }}
        value={selectedOption}
        defaultLabel={t('T_MANAGE_METADATA_OBJECT')}
      />
    </>
  );
};

export default React.memo(ObjectMenu);
