import { useTranslation } from 'react-i18next';
import { getFileSize } from './BucketUtil';
import moment from 'moment';
import { AppConstants } from '../../constants/AppConstants';
import { Label } from '../Onboarding/OnboardingStyles';
import ListView from '../primitives/ListView/ListView';
import InputField from '../shared/Fields/InputField';
import React, { useState } from 'react';
import BiButton from '../primitives/buttons/BiButton.primitive';
import AddCircle from '../../assets/addCircle.svg';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import TextAreaField from '../shared/Fields/TextAreaField';

interface BucketFileProps {
  name: string;
  generation?: string;
  size: number;
  createTime: string;
  updateTime: string;
  disabled?: boolean;
  metadata?: any;
  metadataEntries?: Array<any>;
  currentVersion?: boolean;
  type?: string;
  updateFunction?: Function;
  deleteFunction?: Function;
  addItemFunction?: Function;
}

interface FileMetaDataItemProps {
  item: any;
  index: number;
  rightIcon?: string;
  disabled?: boolean;
  customClass?: string;
  updateFunction?: Function;
  deleteFunction?: Function;
}

interface FileMetaDataProps {
  metadata: any;
  metadataEntries?: Array<any>;
  disabled?: boolean;
  updateFunction?: Function;
  deleteFunction?: Function;
  addItemFunction?: Function;
}

const FileDetailView = ({ name, size, createTime, updateTime }: BucketFileProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full md:grid md:grid-cols-2 md:gap-1">
      <div className="flex-col">
        <Label
          className="flex-1 items-center text-base font-normal text-gray-400"
          id="metadata-label-name"
          data-testId="metadata-label-name"
        >
          {t('T_NAME')}:
        </Label>
        <div className="flex-1" data-testId="metadata-value-name">
          {name}
        </div>
      </div>
      <div className="flex-col">
        <Label
          className="flex-1 items-center text-base font-normal text-gray-400"
          id="metadata-label-createdOn"
          data-testId="metadata-label-createdOn"
        >
          {t('T_CREATED_ON')}:
        </Label>
        <div
          className="flex-1"
          id="metadata-value-createdOn"
          data-testId="metadata-value-createdOn"
        >
          {createTime ? moment(createTime).format(AppConstants.DEFAULT_DATE_FORMAT) : ''}
        </div>
      </div>
      <div className="flex-col">
        <Label
          className="flex-1 items-center text-base font-normal text-gray-400"
          id="metadata-label-size"
          data-testId="metadata-label-size"
        >
          {t('T_SIZE')}
        </Label>
        <div className="flex-1" id="metadata-value-size" data-testId="metadata-value-size">
          {getFileSize(size)}
        </div>
      </div>
      <div className="flex-col">
        <Label
          className="flex-1 items-center text-base font-normal text-gray-400"
          id="metadata-label-updatedOn"
          data-testId="metadata-label-updatedOn"
        >
          {t('T_LAST_MODIFIED_ON')}
        </Label>
        <div
          className="flex-1"
          id="metadata-value-updatedOn"
          data-testId="metadata-value-updatedOn"
        >
          {updateTime ? moment(updateTime).format(AppConstants.DEFAULT_DATE_FORMAT) : ''}
        </div>
      </div>
    </div>
  );
};

const FileMetaDataItem = ({
  index,
  disabled,
  rightIcon,
  item = {},
  customClass,
  updateFunction,
  deleteFunction
}: FileMetaDataItemProps) => {
  const { t } = useTranslation();
  const { name = '', value = '' } = item || {};
  const [formData, setFormData] = useState<any>({
    name,
    value
  });
  const [formError, setFormError] = useState<any>({
    name: '',
    value: ''
  });
  return (
    <div key={`file-${index}`} data-testid="card-elements">
      <div className={customClass}>
        <div className={`flex ${!disabled ? 'w-[46%]' : 'w-[48%]'} flex-col`}>
          <InputField
            type={'text'}
            id={'metadata-input-name'}
            data-testId="metadata-input-name"
            label={''}
            placeholder={`${t('T_KEY')} ${index + 1}`}
            fieldKey={'name'}
            asterixFirst={false}
            required={false}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            disabled={disabled}
            handleChange={(e: any) => {
              setFormData({ ...item, name: e.target.value });
              updateFunction && updateFunction(index, { ...item, name: e.target.value });
            }}
            min={1}
            max={1024}
          />
        </div>
        <div className={`flex ${!disabled ? 'w-[46%]' : 'w-[48%]'} flex-col`}>
          <TextAreaField
            type={'text'}
            id={'metadata-input-value'}
            data-testId="metadata-input-value"
            label={''}
            placeholder={`${t('T_VALUE')} ${index + 1}`}
            fieldKey={'value'}
            asterixFirst={false}
            required={false}
            formData={formData}
            setFormData={setFormData}
            rows={1}
            style={{ resize: 'vertical' }}
            formError={formError}
            disabled={disabled}
            handleChange={(e: any) => {
              setFormData({ ...item, value: e.target.value });
              updateFunction && updateFunction(index, { ...item, value: e.target.value });
            }}
          />
        </div>
        {!disabled && (
          <div className={`flex w-[6%] items-center`}>
            <DeleteIcon
              className={`mx-auto mt-2 h-6 w-6 cursor-pointer text-gray-600 hover:text-black ${
                disabled && 'cursor-not-allowed bg-transparent'
              }`}
              onClick={() => {
                !disabled && deleteFunction && deleteFunction(index);
              }}
              id="metadata-button-delete"
              data-testid="metadata-button-delete"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const FileMetaData = ({
  name,
  size,
  disabled,
  createTime,
  updateTime,
  metadata = {},
  metadataEntries = [],
  addItemFunction,
  updateFunction,
  deleteFunction
}: BucketFileProps) => {
  return (
    <div className="text-md flex flex-col space-y-2">
      <FileDetailView name={name} size={size} createTime={createTime} updateTime={updateTime} />
      <FileMetaDataListing
        metadata={metadata}
        disabled={disabled}
        metadataEntries={metadataEntries}
        deleteFunction={deleteFunction}
        updateFunction={updateFunction}
        addItemFunction={addItemFunction}
      />
    </div>
  );
};

export const FileMetaDataListing = ({
  metadata = {},
  disabled = false,
  metadataEntries = [],
  deleteFunction,
  updateFunction,
  addItemFunction
}: FileMetaDataProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center">
        <div className="font-medium">{t('T_CUSTOM_METADATA')}</div>
        <div className="flex flex-1 justify-end">
          {!disabled && (
            <BiButton
              className={'flex flex-row bg-primary text-white'}
              type="button"
              disabled={disabled}
              onClick={() => {
                addItemFunction && addItemFunction();
              }}
            >
              <img src={AddCircle} className="mr-2 w-6"></img>
              {t('T_ADD_ITEM')}
            </BiButton>
          )}
        </div>
      </div>
      <ListView
        id="file-metadata-list"
        data-testid="file-metadata-list"
        containerClassName={`font-medium`}
        maxHeight="18rem"
      >
        {metadataEntries.map((item: any, index: number) => {
          return (
            <FileMetaDataItem
              item={item}
              index={index}
              disabled={disabled}
              key={`file-${item?.id}`}
              deleteFunction={deleteFunction}
              updateFunction={updateFunction}
              customClass={`w-full flex my-2 text-base font-normal align-center justify-center justify-around`}
            />
          );
        })}
      </ListView>
    </div>
  );
};
export default React.memo(FileMetaData);
