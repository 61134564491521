import styled from 'styled-components';
import Search from '../../../assets/search.svg';
import { colorLightGray6, colorLightGray7 } from '../../../styles/globalVariables';

export type SearchInputProps = {
  isEmailTemplate?: boolean;
};

export const BiSearchIconInput = styled.input<SearchInputProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ isEmailTemplate }) => (isEmailTemplate ? '6px 40px 10px 8px' : '0.65rem 0.75rem')};
  border: 0.5px solid rgb(${colorLightGray7});
  box-sizing: border-box;
  border-radius: 4px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;

  background-image: url(${Search});
  background-repeat: no-repeat;
  background-position: center right 10px;

  ::placeholder {
    color: rgb(${colorLightGray6});
  }
`;
