import { upperFirst } from 'lodash';
import styled from 'styled-components/macro';

import { AppConstants } from '../../constants/AppConstants';
import { FieldItem } from './FieldItem';
import { useQuery } from '../../hooks/queryParams';

const BorderContainer = styled.div<{ showborder: boolean }>`
  border-bottom: ${({ showborder }) => (showborder ? '1px solid #d9d9d9' : 'unset')};
`;

interface FieldLayoutProps {
  fieldGroup: any;
  fieldItem: any;
  fieldValue: any;
  fieldErrors: Array<string>;
  onValueChange: Function;
  lastItem: boolean;
}

export const FieldLayout: React.FC<FieldLayoutProps> = ({
  fieldGroup,
  fieldItem,
  fieldValue,
  fieldErrors,
  onValueChange,
  lastItem = false
}: FieldLayoutProps) => {
  const { attributes } = fieldGroup;
  const { layout: grpLayout } = attributes;
  const { value: grpValue } = grpLayout;

  const { layout = {}, metadata, value: globalValue, valueConfig } = fieldItem;
  const { value: configValue } = valueConfig;
  const { attributes: layoutParams = {} } = layout;
  const {
    display = AppConstants.DISPLAY_INLINE,
    columnSpan = AppConstants.PAGE_LAYOUT_MONO,
    divider = false
  } = layoutParams;
  const { attributes: metaParams } = metadata;
  const { description, displayName } = metaParams;

  let direction: string = display;
  const query = useQuery();
  if (query.get('display')) {
    direction = query.get('display').toUpperCase();
  }

  return (
    <div
      className={`flex flex-col`}
      style={{ width: `${((columnSpan / grpValue) * 100 - 1).toFixed(2)}%` }}
    >
      <BorderContainer
        className={`${'mt-3 flex w-full pb-3'} ${
          direction === AppConstants.DISPLAY_INLINE ? 'flex-row justify-between ' : 'flex-col'
        } `}
        showborder={divider && !lastItem}
      >
        <div className={'flex-1'}>
          <div className={`$text-base font-medium`}>{upperFirst(displayName)}</div>
          {description && (
            <div className={`mb-1 text-sm font-normal`} style={{ color: '#262626' }}>
              {description}
            </div>
          )}
        </div>
        <FieldItem
          fieldItem={fieldItem}
          fieldValue={fieldValue || globalValue || configValue}
          fieldErrors={fieldErrors}
          onValueChange={onValueChange}
          direction={direction}
        />
      </BorderContainer>
    </div>
  );
};
