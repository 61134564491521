import { css } from 'styled-components/macro';
import styled from 'styled-components';

/**
 * Colors
 */

// make TS interface

export const colorWhite = '255, 255, 255';
export const colorBlack = '0, 0, 0';

export const colorProductPrimary = '44,113,173'; //New Default color:  #2C71AD
export const colorProductDisabled = '171,198,222'; // cadet blue crayola #ABC6DE
export const colorProductBlur = '38, 61, 82'; // charcoal #263D52
export const colorProductLink = '18, 122, 219'; // bright navy blue #127ADB
export const colorProductLinkHover = '5, 96, 169'; // lapis lazuli #0560A9

export const colorProductPrimaryHover = '65,167,255'; // bright navy blue #0074E0
export const colorBlueFont = '46, 56, 77'; // space cadet #2E384D
export const colorSecondaryHover = '220, 238, 254'; // alice blue #dceefe

export const colorGreen = '40, 185, 128'; // medium sea green #28b980
export const colorInfoToast = '64, 169, 255'; // blue jeans #40A9FF

export const colorLightGray2 = '250, 250, 250'; // cultured #FAFAFA
export const colorLightGray4 = '245, 247, 250'; // ghost white #F5F7FA
export const colorLightGray5 = '217, 217, 217'; // gainsboro #D9D9D9
export const colorLightGray6 = '125, 125, 125'; // gainsboro #D9D9D9
export const colorLightGray7 = '140, 140, 140'; // battleship gray #8C8C8C
export const colorLightGray8 = '89, 89, 89'; // davys grey #595959
export const colorLightGray9 = '38, 38, 38'; // eerie black #262626

export const colorRed3 = '255, 163, 158'; // melon #FFA39E
export const colorRed6 = '211, 32, 41'; // fire engine red D32029

export const colorBackgroundBlue = '235, 243, 250'; // alice blue #ebf3fa

export const colorBackgroundAlert1 = '255, 240, 240'; // lavender blush #FFF0F0
export const colorBorderAlert1 = '255, 77, 79'; // tart orange #FF4D4F
export const colorBackgroundAlert2 = '255, 251, 210'; // lemon chiffon #FFFBD2
export const colorBorderAlert2 = '212, 177, 6'; // gold metalic #D4B106
export const colorBackgroundAlert3 = '227, 255, 245'; // honeydew #E3FFF5
export const colorBorderAlert3 = '85, 192, 154'; // ocean green #55C09A
export const colorBiText = '46,56,77';
export const colorForLightGrayBackground = '249, 251, 253';
export const colorForGrayBackground = '243, 244, 246';

/**
 * Utility
 */

export const gridCenter = css`
  display: grid;
  place-items: center;
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AuditBlock = styled.div`
  // font-family: 'Open Sans';
  // font-style: normal;
  // font-weight: 600;
  // font-size: 14px;
  // line-height: 22px;
  color: #595959;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
`;

export const AuditItem = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  margin: 0.25rem;
`;

export const ModalButton = styled('div')`
  display: flex;
  justify-content: end;
`;

export const OverlayMessageDiv = styled('div')`
  position: absolute;
  margin-left: -12%;
  margin-top: -3rem;
  padding: 4px 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: rgb(${colorForLightGrayBackground});
  color: rgb(${colorBorderAlert1})
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;
