export const formatDate = (date: any) => {
  return new Date(date).toISOString().split('T')[0];
};

export const formatPhoneNumber = (value: any) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return `${phoneNumber}`;

  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const getCountryCodeAndNumber = (value: string) => {
  let countryCode = '';
  let phone=value.slice(-10)
  let formattedNumber = formatPhoneNumber(phone);
  if (value.length > 10) {
    countryCode = value.replace(phone, '')
  }
  return { countryCode, formattedNumber };
};