import { useEffect, useState, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { useTranslation } from 'react-i18next';
import { RouteConstants } from '../../constants/RouteConstants';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import InputField from '../shared/Fields/InputField';
import TextAreaField from '../shared/Fields/TextAreaField';
import { Label } from '../AccountProfile/ProfileStyles';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { setToastData } from '../../app/toastReducer';
import { consentNameValidation } from '../../util/validators';
import {
  createFolder,
  deleteFolder,
  getFolder,
  updateFolder
} from '../../http/asset-management-services';
import { setURLPaths } from '../../app/organizationReducer';
import TabToggleButton from '../Reusable/TabToggleButton';
import PickList from '../shared/Fields/PickList';
import PickListWithApiSearch, { PICKLIST_TYPES } from '../shared/Fields/PickListWithAPISearch';
import { getUsers } from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { listRoles } from '../../http/access-management-service';
import SelectField from '../shared/Fields/SelectField';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import _, { cloneDeep, filter, find, isEmpty } from 'lodash';
import Checkbox from '../shared/Fields/Checkbox';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import Card from '../shared/cards/Card';
import { SettingIcon, lock_icon } from '../../assets/components/svgs';
import { getUserName } from '../../util/admin-utils';
import moment from 'moment';
import ActionDropdown from '../primitives/dropdowns/ActionDropdown';
import { useQuery } from '../../hooks/queryParams';
import { folderDetailsPayload, getBucketRelativeName, getFullPath } from './BucketUtil';
import { setFolderData } from '../../app/bucketReducer';
import { getFileName } from './BucketUtil';
import { SubItemForRoleAndUser } from './SubItem';
import DeleteFileFolder from './DeleteFileFolder';
import AuditInfo from '../Common/AuditInfo';

/**
 * @parentId file-management
 * @manager Create, Read, Update, Delete Folder
 * @overview Folder Detail Page used for Create, Read, Update, Delete functions
 *   <section>
 *       <p>
 *           <b>View Folder</b><br/>
 *           Clicking on folder rowitem on the Folder/File(s) List page will take user to view folder details page in view only mode. Clicking on <b>Back to Folder</b> will take the user to Folder/File(s) List page.
 *           <br/><br/>
 *           <b>Edit Folder</b><br/>
 *           Editing of Folder is only possible if the logged in user role API permission has edit permissions. i.e <strong>asset-management-service.folder.patch</strong> and if the userId is present in folderPermissions with writePermission enabled. Clicking on <b>Manage</b> and then <b>Edit</b> button on the admin portal toolbar will change the page into editing mode, which allows users to edit <b>Description</b>, <b>Add/Remove Individual Users with access level</b>, <b>Add/Remove User Roles with access level</b>, and Save the Folder changes. Clicking on <b>Cancel</b> will take the user back to folder details page in view only mode.
 *           Once a bucket is created, the bucket name cannot be changed.
 *           <br/><br/>
 *           <b>Create Folder</b><br/>
 *           <b>New Folder</b> button is only enabled if the logged-in user role API permission has create permission. i.e <strong>asset-management-service.folder.create</strong>
 *           Clicking on <b>New Folder</b> button on Folder/File(s) List page will take user to new folder creation page. Clicking on <b>Cancel</b> will take the user to Folder/File(s) List page.
 *           <br/>
 *           User can create new folder by entering <b>Folder Name</b>,<b>Description</b> mandatory fields and clicking on <b>Save</b> button on the admin portal toolbar. <b>Adding Individual Users</b> and <b>Role Groups</b> are optional.
 *           <br/><br/>
 *           <b>Delete Folder</b><br/>
 *           Deleting of Folder is of two types. First is Soft Delete (Folder can be recovered from cloud) where in user clicks on <b>Manage</b> button and selects<b>Delete</b> which is only possible if the logged in user role API permission has delete permissions. i.e <strong>asset-management-service.folder.delete</strong>. Second is Hard Delete (Folder is permanently removed) where in user clicks on <b>Manage</b> button and then selects <b>Permanent delete</b> which is only possible if the logged in user role API permission has delete permissions i.e user clicks on <b>Manage</b> button and selects<b>Delete</b> which is only possible if the logged in user role API permission has delete permissions. i.e <strong>asset-management-service.folder.delete.hard</strong>.
 *           <br/><br/>
 *       </p> <br/>
  *       <p>
 *          <h4>Context Menu Options</h4>
 *          View/Edit Folder page will display the following options in context menu in Admin portal toolbar:
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Name</th>
 *               <th>Description</th>
 *               <th>Permission Needed</th>
 *            </tr>
 *            <tr>
 *               <td>Edit</td>
 *               <td>Changes to Edit Mode from Read-Only (View) Mode</td>
 *               <td>asset-management-service.folder.patch</td>
 *            </tr>
               <tr>
 *               <td>Files & Folders</td>
 *               <td>Navigates to Files and Folders Page for that folder</td>
 *               <td>asset-management-service.bucket.files</td>
 *            </tr>
 *            <tr>
 *               <td>Delete</td>
 *               <td>Used to Soft delete the folder.</td>
 *               <td>asset-management-service.folder.delete</td>
 *            </tr>
 *              <tr>
 *               <td>Permanent delete</td>
 *               <td>Used to Hard delete the folder or permanently remove it.</td>
 *               <td>asset-management-service.folder.delete.hard</td>
 *            </tr>
 *          </table>
 *          <br/>
 *        </p>
*       <p>
 *          <h4>View/Create/Edit Folder Fields</h4>
 *          Create/View/Edit folder page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Field Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Folder Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>Textfield</td>
 *               <td>63</td>
 *            </tr>
 *             <tr>
 *               <td>Description</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>Textfield</td>
 *               <td>4096</td>
 *            </tr>
 *             <tr>
 *               <td>Folder User Owners</td>
 *               <td>Yes</td>
 *               <td>Array</td>
 *               <td>Dropdown</td>
 *               <td>NA</td>
 *            </tr>
 *            <tr>
 *               <td>Folder Role Owners</td>
 *               <td>Yes</td>
 *               <td>Array</td>
 *               <td>Dropdown</td>
 *               <td>NA</td>
 *            </tr>
 *          </table>
 *          <br/>
 *     </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the List Bucket Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>AMS_PARAM_DUPLICATE</td>
 *               <td>Duplicate name, assets for resource type folder.</td>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>AMS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>AMS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of Folder detail page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for  Folder/File(s) listing</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Access Control Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *           <tr>
 *               <td>Asset Management Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for Folder/File(s) listing page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/bucket/listfiles</td>
 *               <td>POST</td>
 *               <td>asset-management-service.bucket.files</td>
 *               <td>No</td>
 *           </tr>
 *             <tr>
 *               <td>/folder/{id}</td>
 *               <td>GET</td>
 *               <td>asset-management-service.folder.get</td>
 *               <td>Yes</td>
 *             </tr>
 *               <tr>
 *               <td>/folder</td>
 *               <td>POST</td>
 *               <td>asset-management-service.folder.create</td>
 *               <td>Yes</td>
 *           </tr>
 *             <tr>
 *               <td>/folder/{folder-id}</td>
 *               <td>DELETE</td>
 *               <td>asset-management-service.folder.delete</td>
 *               <td>Yes</td>
 *           </tr>
 *               <tr>
 *               <td>/folder/{folder-id}/hard</td>
 *               <td>DELETE</td>
 *               <td>asset-management-service.folder.delete.hard</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *   </section>
 */

const Image = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 4px;
`;

const READ_TYPE_OPTION = [
  { key: 'Read & write', value: 'READ & WRITE' },
  { key: 'Read Only', value: 'READ ONLY' },
  { key: 'Owner', value: 'OWNER' }
];

const CREATE_FOLDER_FORM_SCHEMA = {
  name: '',
  description: '',
  selectedUser: [],
  selectedRole: []
};

function FileContextMenu({ options }: any) {
  const [selectedOption, setSelectedOption] = useState<any>('');
  const { t } = useTranslation();
  return (
    <ActionDropdown
      className="!w-40"
      options={options}
      onSelectedOptionChange={(value: any) => {
        value.action();
      }}
      value={selectedOption}
      defaultLabel={t('T_MANAGE')}
    />
  );
}

const FolderDetails = () => {
  const params = useParams();
  const location = useLocation();
  const query = useQuery();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>(CREATE_FOLDER_FORM_SCHEMA);
  const [formDataCopy, setFormDataCopy] = useState<any>(CREATE_FOLDER_FORM_SCHEMA);
  const [formError, setFormError] = useState<any>({
    name: '',
    description: ''
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [folderDetails, setFolderDetails] = useState<any>(null);

  const bucketInfo = useAppSelector((state) => state.bucketSlice.bucketInfo);
  const folderInfo = useAppSelector((state) => state.bucketSlice.folderInfo);

  const [activeTab, setActiveTab] = useState<string>(t('T_INDIVIDUAL_USER') || '');
  const [roleList, setRoleList] = useState<any[]>([]);
  const [roleListTemp, setRoleListTemp] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);

  const [userHasMore, setUserHasMore] = useState<boolean>(true);
  const [picklistLoading, setPicklistLoading] = useState<boolean>(false);
  const [roleHasMore, setRoleHasMore] = useState<boolean>(true);
  const [deleteId, setDeleteId] = useState<any>('');

  const [usedUserList, setUsedUserList] = useState<any>(null);

  const [nestedFolder, setNestedFolder] = useState<boolean>(false);

  useEffect(() => {
    async function init() {
      setIsLoading(true);

      if (location.pathname.includes('/new')) {
        fetchRoleData('', 1, formData?.selectedRole);
        fetchUserData('', 1, [], formData?.selectedUser);
        setIsEdit(true);
        setIsLoading(false);
        SetBredcrumps('');
      } else {
        await getFoldersById(params.folderId);
      }
    }
    init();
  }, []);

  useEffect(() => {
    if (folderInfo && !isEmpty(folderInfo)) {
      if (
        (!location.pathname.includes('/new') &&
          params.folderId &&
          folderInfo.parentFolderId !== bucketInfo.id) ||
        (location.pathname.includes('/new') && params.folderId)
      ) {
        setNestedFolder(true);
      }
    }
  }, [folderInfo]);

  useEffect(() => {
    if (usedUserList) {
      SetFolderDataInForm();
      setIsLoading(false);
    }
  }, [usedUserList, roleListTemp]);

  const SetFolderDataInForm = async (folderData: any = folderDetails) => {
    if (folderData) {
      let permissionData = getPermissionsData();

      setFormData({
        name: folderData.name,
        description: folderData.description,
        ...permissionData
      });
    }
  };

  const SetBredcrumps = (folderName: string) => {
    let keyUrl = RouteConstants.ROUTE_AMS_FOLDER_LIST.replace(':id', bucketInfo.id || '');
    let fpath = params.folderId;

    let keyT =
      fpath && location.pathname.includes('/new')
        ? `${keyUrl}/${fpath}/new`
        : !fpath && location.pathname.includes('/new')
        ? `${keyUrl}/new`
        : `${keyUrl}/${fpath}`;

    let labelT = `${t('T_BUCKET')} > ${getBucketRelativeName(bucketInfo?.name)} > ${
      location.pathname.includes('/new') ? t('T_NEW_FOLDER') : folderName
    }`;

    dispatch(
      setURLPaths([
        {
          key: keyT,
          label: labelT
        }
      ])
    );
  };

  const getPermissionsData = () => {
    const {
      folderOwners = [],
      folderPermissions = [],
      folderRoleOwners = [],
      folderRolePermissions = []
    } = folderDetails || {};

    let userList = [...folderOwners, ...folderPermissions];
    let userData: any = [];

    userList.forEach((user: any) => {
      const { userId, writePermission = null, hardDeletePermission = false } = user;

      const FindUser: any = find(usedUserList || [], { userId: userId || user });
      const { mail = '' } = FindUser || {};

      if (writePermission === null) {
        userData.push({
          key: userId || user,
          value: mail || userId,
          permissionKey: 'OWNER',
          permisssion: READ_TYPE_OPTION[2]
        });
      } else {
        userData.push({
          key: userId,
          value: mail || userId,
          permissionKey: writePermission ? READ_TYPE_OPTION[0].value : READ_TYPE_OPTION[1].value,
          permisssion: writePermission ? READ_TYPE_OPTION[0] : READ_TYPE_OPTION[1],
          hard: hardDeletePermission
        });
      }
    });

    let folderRoleList = [...folderRoleOwners, ...folderRolePermissions];
    let roleData: any = [];

    folderRoleList.forEach((user: any) => {
      const { roleId, writePermission = null, hardDeletePermission = false } = user;
      const folderRole: any = find(roleListTemp || [], { id: roleId });
      const { name = '' } = folderRole || {};

      if (writePermission === null) {
        roleData.push({
          key: roleId,
          value: name || roleId,
          permissionKey: 'OWNER',
          permisssion: READ_TYPE_OPTION[2]
        });
      } else {
        roleData.push({
          key: roleId,
          value: name || roleId,
          permissionKey: writePermission ? READ_TYPE_OPTION[0].value : READ_TYPE_OPTION[1].value,
          permisssion: writePermission ? READ_TYPE_OPTION[0] : READ_TYPE_OPTION[1],
          hard: hardDeletePermission
        });
      }
    });

    return { selectedRole: roleData, selectedUser: userData };
  };

  const saveResponseCb = (folderData: any) => {
    let IdArr: any = getIdFromData(folderData);
    fetchRoleData('', 1, folderData?.folderRolePermissions);
    fetchUserData('', 1, IdArr.userList);

    const { name = '' } = folderData || {};
    SetBredcrumps(name);
    setFolderDetails(folderData);
    SetFolderDataInForm(folderData);
  };

  const getFoldersById = async (id: any) => {
    const response = await getFolder(id);
    const { data: folderData, status } = response;
    dispatch(setFolderData(folderDetailsPayload(folderInfo, folderData)));
    if (status === HTTP_STATUS.HTTP_OK) {
      saveResponseCb(folderData);
    } else {
      setIsLoading(false);
      let error = getFailureMessage(response);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
  };

  useEffect(() => {
    if (formData.selectedUser.length) {
      let mappedUsers = formData.selectedUser.map((item: any) => item?.key);
      setUserList(
        filter(userList, (item: any) => {
          return !mappedUsers.includes(item?.userId);
        })
      );
    }
  }, [formData.selectedUser]);

  const filterRoleSelection = (roleData: any) => {
    let mappedRoles = roleData.map((item: any) => item?.key);
    setRoleList(
      filter(roleListTemp, (item: any) => {
        return !mappedRoles.includes(item?.id);
      })
    );
  };

  useEffect(() => {
    filterRoleSelection(formData.selectedRole);
  }, [formData.selectedRole]);

  const fetchRoleData = async (
    name: string = '',
    page: number = AppConstants.DEFAULT_PAGE,
    selectedRoles = formData.selectedRole
  ) => {
    let trimmedName = name.trim();
    let mappedIds = selectedRoles.length
      ? selectedRoles.map((item: any) => item?.roleId || item?.key)
      : [];
    setPicklistLoading(true);
    if ((!trimmedName || trimmedName === '') && roleListTemp.length) {
      let mappedIds = formData.selectedRole.length
        ? formData.selectedRole.map((item: any) => item?.key)
        : [];
      setRoleList(
        filter(roleListTemp, (item: any) => {
          return !mappedIds.includes(item?.id);
        })
      );
      setPicklistLoading(false);
      return [];
    }
    if (!roleListTemp.length) {
      let response;
      response = await listRoles(trimmedName, true, page, AppConstants.MAXIMUM_PAGE_SIZE, '', '');
      const { status, data: rolesResponse } = response;
      setRoleListTemp(rolesResponse.data);
      setPicklistLoading(false);

      if (HTTP_STATUS.isSuccess(status)) {
        setRoleList(
          filter(rolesResponse.data, (item: any) => {
            return !mappedIds.includes(item?.id);
          })
        );
      }
      setRoleHasMore(rolesResponse.paging?.next !== null);
    } else {
      if (trimmedName) {
        setRoleList(
          filter(roleListTemp, (item: any) => {
            return (
              !mappedIds.includes(item?.id) &&
              item.name.toLowerCase().includes(trimmedName.toLowerCase())
            );
          })
        );
      }
      setRoleHasMore(false);
      setPicklistLoading(false);
    }
  };

  const fetchUserData = async (
    name: string = '',
    page: number,
    idArr: any[] = [],
    selectedUsers: any[] = []
  ) => {
    let trimmedName = name.trim();
    let apiFilter = trimmedName
      ? `[{"field":"mail","operation":"co","value":"${trimmedName}"}]`
      : '';
    let idFilter = `[{"field":"userId","operation":"in","value":"${idArr.join(',')}"}]`;
    setPicklistLoading(true);

    if ((!trimmedName || trimmedName === '') && !idArr.length) {
      setUserList([]);
      setUserHasMore(false);
      setPicklistLoading(false);
      return [];
    }
    let response = await getUsers(
      idArr.length ? idFilter : apiFilter,
      page,
      AppConstants.DEFAULT_PAGE_SIZE,
      ''
    );

    let mappedUsers = selectedUsers.length ? selectedUsers.map((item) => item?.key) : idArr;

    const { status, data: usersResponse } = response;
    let retVal = null;
    setPicklistLoading(false);
    if (HTTP_STATUS.isSuccess(status)) {
      if (trimmedName) {
        setUserHasMore(usersResponse.paging?.next !== null);
        let filteredResponse = filter([...userList, ...usersResponse.data], (item: any) => {
          return !mappedUsers.includes(item?.userId);
        });

        if (!folderDetails && isEmpty(folderDetails)) {
          filteredResponse = filter(filteredResponse, (item: any) => {
            return item.userId !== userProfile?.userId;
          });
        }
        setUserList(filteredResponse);
      } else {
        if (idArr.length) {
          retVal = [...usersResponse.data];
          setUsedUserList([...usersResponse.data]);
          setUserHasMore(usersResponse.paging?.next !== null);
          return retVal;
        } else {
          retVal = [...userList, ...usersResponse.data];
          setUserList(
            filter(retVal, (item: any) => {
              return !mappedUsers.includes(item?.userId);
            })
          );
          setUserHasMore(usersResponse.paging?.next !== null);
          return retVal;
        }
      }
    } else {
      if (idArr.length) {
        setUsedUserList([...idArr]);
      } else {
        setUserList([...userList]);
      }
      return retVal;
    }
  };

  const getIdFromData = (fileData: any) => {
    let arr: any[] = [];
    const {
      folderOwners = [],
      folderPermissions = [],
      folderRoleOwners = [],
      folderRolePermissions = [],
      createdBy = '',
      updatedBy = ''
    } = fileData || {};

    let userList = [...folderOwners, ...folderPermissions].map((ele: any) => ele.userId);
    userList = [...userList, createdBy, updatedBy];

    let roleList = [...folderRoleOwners, ...folderRolePermissions].map((ele: any) => ele.id);

    return { userList, roleList };
  };

  const GetPayload = () => {
    const { name, description, selectedUser, selectedRole } = formData;
    let payload: any = {
      name: name.trim(),
      description: description.trim(),
      bucketId: bucketInfo.id
    };

    if (!nestedFolder) {
      payload = {
        ...payload,
        folderOwners: [],
        folderPermissions: [],
        folderRoleOwners: [],
        folderRolePermissions: []
      };

      let ownersUser: any = [];
      let permissionsUser: any = [];
      selectedUser.forEach((user: any) => {
        if (user.permissionKey === 'OWNER') {
          ownersUser.push(user.key);
        } else {
          permissionsUser.push({
            userId: user.key,
            writePermission: user.permissionKey === 'READ & WRITE',
            hardDeletePermission: user.hard
          });
        }
      });
      payload.folderOwners = ownersUser;
      payload.folderPermissions = permissionsUser;

      let ownersRole: any = [];
      let permissionsRole: any = [];
      selectedRole.forEach((role: any) => {
        if (role.permissionKey === 'OWNER') {
          ownersRole.push(role.key);
        } else {
          permissionsRole.push({
            roleId: role.key,
            writePermission: role.permissionKey === 'READ & WRITE',
            hardDeletePermission: role.hard
          });
        }
      });
      payload.folderRoleOwners = ownersRole;
      payload.folderRolePermissions = permissionsRole;
    }

    return payload;
  };

  const UpdateFolderCall = async () => {
    let payload: any = GetPayload();

    let createResponse = await updateFolder(payload, folderDetails.id);
    const { status, data: folderData } = createResponse;
    if (HTTP_STATUS.isSuccess(status)) {
      setUserList([]);
      setUserHasMore(true);
      setRoleList([]);
      setRoleHasMore(true);
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_FOLDER_UPDATED_SUCCESS_MSG'),
          toastType: 'success'
        })
      );
      dispatch(setFolderData(folderDetailsPayload(folderInfo, folderData)));
      saveResponseCb(folderData);
      setIsEdit(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      let error = getFailureMessage(createResponse);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
  };

  getFullPath(folderInfo);

  const CreateNewFolderAPICall = async () => {
    let payload = GetPayload();

    if (nestedFolder) {
      let fullPath = getFullPath(folderInfo);
      payload.name = `${fullPath ? fullPath + '/' : ''}${payload.name}`.trim();
    }

    let createResponse = await createFolder(payload);
    const { status } = createResponse;
    if (status === HTTP_STATUS.HTTP_CREATED) {
      setTimeout(() => {
        setIsLoading(false);
        dispatch(
          setToastData({
            isToastActive: true,
            toastMessage: t('T_FOLDER_CREATION_SUCCESS_MSG'),
            toastType: 'success'
          })
        );
        navigate(-1);
      }, 3000);
    } else {
      setIsLoading(false);
      let error = getFailureMessage(createResponse);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
  };

  const cleanupUser = () => {
    setUserList([]);
    setUserHasMore(false);
  };

  const cleanupRole = () => {
    filterRoleSelection(formData.selectedRole);
  };

  const tabConfig = [
    {
      label: t('T_INDIVIDUAL_USER'),
      component: (
        <div>
          <SubItemForRoleAndUser
            formData={formData}
            formError={formError}
            setFormData={setFormData}
            formDataKey={'selectedUser'}
            optionsList={userList.map((ele: any) => {
              return { ...ele, name: ele.mail, id: ele.userId };
            })}
            setOptionsList={setUserList}
            fetchDataAPICall={fetchUserData}
            hasMore={userHasMore}
            setHasMore={setUserHasMore}
            isLoading={picklistLoading}
            setIsLoading={setPicklistLoading}
            cleanup={cleanupUser}
            type="folder"
          />
        </div>
      )
    },
    {
      label: t('T_USER_ROLE'),
      component: (
        <div>
          <SubItemForRoleAndUser
            formData={formData}
            key={roleListTemp.length}
            formError={formError}
            setFormData={setFormData}
            formDataKey={'selectedRole'}
            optionsList={roleList}
            setOptionsList={setRoleList}
            fetchDataAPICall={fetchRoleData}
            hasMore={roleHasMore}
            setHasMore={setRoleHasMore}
            isLoading={picklistLoading}
            setIsLoading={setPicklistLoading}
            cleanup={cleanupRole}
            type="folder"
          />
        </div>
      )
    }
  ];

  const removeFolder = async (hard: boolean = false) => {
    const { id, name } = folderDetails;
    setIsLoading(true);
    let success: boolean = false;
    let toastMessage: string = t('T_FOLDER_DELETE_SUCCESS_MSG').replace('%s', getFileName(name));
    let response: any = await deleteFolder(id, hard);
    success = HTTP_STATUS.isSuccess(response?.status);
    if (success) {
      navigate(-1);
    } else {
      toastMessage = getFailureMessage(response);
    }
    dispatch(
      setToastData({
        isToastActive: true,
        toastMessage,
        toastType: success ? 'success' : 'error'
      })
    );
    setIsLoading(false);
  };

  const FileMenu: any = () => {
    let options: any = [];
    if (roleAPIPermissions[AppConstants.PERMISSION_AMS_UPDATE_FOLDER]) {
      options.push({
        display: t('T_EDIT'),
        action: () => {
          setIsEdit(true);
        }
      });
    }

    if (roleAPIPermissions[AppConstants.PERMISSION_AMS_LIST_FILES]) {
      options.push({
        display: t('T_BUCKET_FILE_FOLDER'),
        action: () => {
          navigate(
            RouteConstants.ROUTE_AMS_FOLDER_NESTED_LIST.replace(':id', bucketInfo.id).replace(
              ':folderId',
              folderDetails.id
            )
          );
        }
      });
    }

    if (roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_FOLDER] && folderDetails?.id) {
      options.push({
        key: 'soft_delete',
        display: t('T_DELETE'),
        action: () => {
          setDeleteId(folderDetails?.id);
        }
      });
    }

    // if (
    //   roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_FOLDER_HARD] &&
    //   (folderDetails?.folderOwners.some((user: any) => user?.userId === userProfile?.userId) ||
    //     folderDetails?.folderPermissions.some(
    //       (user: any) => user?.userId === userProfile?.userId && user.hardDeletePermission === true
    //     ))
    // ) {
    //   options.push({
    //     key: 'hard_delete',
    //     display: t('T_HARD_DELETE_FOLDER'),
    //     action: (folder: any) => {
    //       removeFolder(true);
    //     }
    //   });
    // }

    return options;
  };

  const validator = () => {
    const { name, description } = formData;
    if (!name.trim || !description.trim()) {
      return false;
    }
    return true;
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <MenuButtonsPortal>
        {isEdit ? (
          <>
            <BiButton
              className={'ml-auto mr-2 text-primary'}
              type="button"
              onClick={() => {
                if (location.pathname.includes('/new')) {
                  navigate(-1);
                } else {
                  SetFolderDataInForm();
                  setIsEdit(false);
                }
              }}
            >
              {t('T_CANCEL')}
            </BiButton>

            <BiButton
              className={'bg-primary text-white'}
              type="button"
              onClick={() => {
                setIsLoading(true);
                if (folderDetails) {
                  UpdateFolderCall();
                } else {
                  CreateNewFolderAPICall();
                }
              }}
              disabled={!validator()}
            >
              {folderDetails && !isEmpty(folderDetails) ? t('T_UPDATE') : t('T_CREATE')}
            </BiButton>
          </>
        ) : (
          <>
            <BiButton className={'mr-2 text-primary'} type="button" onClick={() => navigate(-1)}>
              {t('T_BACK')}
            </BiButton>

            <div>
              <FileContextMenu options={FileMenu()} />
            </div>
          </>
        )}
      </MenuButtonsPortal>
      <div className="flex w-full">
        <div className="w-3/4">
          <div className="full flex flex-col  overflow-visible px-2 py-3">
            <Card
              title={t('T_FOLDER_DETAILS')}
              icon={SettingIcon}
              alt={'T_FOLDER_DETAILS'}
              height={''}
              width={''}
              className=""
            >
              <div className="flex w-full flex-col  overflow-visible px-2 py-3">
                <div className="flex w-full flex-wrap">
                  <div className="mb-5 flex w-full flex-col">
                    <InputField
                      type={'text'}
                      id={'importName'}
                      label={t('T_FOLDER_NAME')}
                      placeholder={''}
                      fieldKey={'name'}
                      asterixFirst
                      required
                      formData={formData}
                      setFormData={setFormData}
                      formError={formError}
                      disabled={!!folderDetails?.name}
                      max={AppConstants.MAX_LENGTH_IMPORT_NAME}
                      handleChange={(e: any) => {
                        const { value = '' } = e.target;
                        if (consentNameValidation(value)) {
                          setFormData({ ...formData, name: value });
                        }
                      }}
                    />
                  </div>
                  <div className="mb-5 flex w-full flex-col flex-wrap">
                    <TextAreaField
                      fieldKey={'description'}
                      type={'text'}
                      id={'importDesc'}
                      label={t('T_DESCRIPTION')}
                      placeholder={''}
                      asterixFirst
                      required
                      formData={formData}
                      setFormData={setFormData}
                      formError={formError}
                      disabled={!isEdit}
                      rows={3}
                      maxLength={AppConstants.MAX_LENGTH_IMPORT_DESCRIPTION}
                    ></TextAreaField>
                  </div>
                </div>
              </div>
            </Card>

            {!nestedFolder && (
              <Card
                title={t('T_FOLDER_OWNERS')}
                icon={lock_icon}
                alt={'T_FOLDER_OWNERS'}
                height={''}
                width={''}
                className=""
              >
                <Label>{t('T_FOLDER_OWNER_DESCRIPTION_TEXT')}</Label>

                <div className="mt-4">
                  <TabToggleButton
                    activteTab={activeTab}
                    tabConfig={tabConfig}
                    changeTab={setActiveTab}
                    disabled={!isEdit}
                  />
                </div>
              </Card>
            )}
          </div>
        </div>

        {deleteId.length ? (
          <DeleteFileFolder
            type="Folder"
            id={deleteId}
            setId={setDeleteId}
            fetchList={() => {
              navigate(-1);
            }}
          ></DeleteFileFolder>
        ) : (
          ''
        )}

        {!isLoading && !isEdit && (
          <div className="ml-4">
            {(folderDetails?.createdOn || folderDetails?.createdBy) && (
                  <div>
                    <AuditInfo
                      label={'CREATED'}
                      date={folderDetails?.createdOn || ''}
                      user={folderDetails?.createdBy || ''}
                    />
                  </div>
                )}
                {(folderDetails?.updatedOn || folderDetails?.updatedBy) && (
                  <div>
                    <AuditInfo
                      label={'LAST_MODIFIED'}
                      date={folderDetails?.updatedOn || ''}
                      user={folderDetails?.updatedBy || ''}
                    />
                  </div>
                )}
          </div>
        )}
      </div>
    </LoaderContainer>
  );
};

export default FolderDetails;
