// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { useEffect, useState } from 'react';
import Card from '../shared/cards/Card';
import { useNavigate, useParams } from 'react-router-dom';
import BiButton from '../primitives/buttons/BiButton.primitive';
import styled from 'styled-components/macro';
import { useAppDispatch } from '../../app/hooks';
import { lock_icon } from '../../assets/components/svgs';
import { useTranslation } from 'react-i18next';
import PasswordRequirement, { passwordRequirementComponent } from '../Register/PasswordRequirement';
import { initialPasswordRequirements } from '../Register/Register.component';
import { performRegisterPasswordValidation } from '../Register/registerPasswordValidation';
import { changePassword, getPasswordPolicyRegister } from '../../http/user-management-services';
import { setToastData } from '../../app/toastReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import InputField from '../shared/Fields/InputField';
import { AppConstants } from '../../constants/AppConstants';

interface ChangePasswordProps {
  setIsLoading: Function;
  userData: any;
  disabled: boolean;
}

function ChangePassword({ setIsLoading, userData, disabled }: ChangePasswordProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [passwordRequirements, setPasswordRequirements] = useState(initialPasswordRequirements);
  const [passwordPolicy, setPasswordPolicy] = useState<any>('');
  let errors: any = {};
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [formError, setFormError] = useState<any>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const callApi = async (paramEmail: any) => {
    const response = await getPasswordPolicyRegister(paramEmail);
    if (response.status === HTTP_STATUS.HTTP_OK) {
      setPasswordPolicy(response.data);
    }
  };

  useEffect(() => {
    const { currentPassword, newPassword, confirmPassword } = formData;

    if (currentPassword || newPassword || confirmPassword) resetForm();
  }, [disabled]);

  useEffect(() => {
    callApi(userData.mail);

    if (
      passwordPolicy &&
      passwordPolicy.characterSets &&
      passwordPolicy.characterSets.length > 0 &&
      passwordRequirements.characterSets.length === 0
    ) {
      let characterSetObject = passwordPolicy.characterSets.map(() => false);
      setPasswordRequirements({
        ...passwordRequirements,
        characterSets: [...characterSetObject]
      });
    }
  }, []);

  const setToastMsg = (msg: string, isActive: boolean, type: string) => {
    dispatch(setToastData({ toastMessage: msg, isToastActive: isActive, toastType: type }));
  };

  const setOnChange = (key: string, value: string) => {
    if (key == 'newPassword' || key == 'confirmPassword') {
      setFormData({ ...formData, [key]: value });

      errors = performRegisterPasswordValidation(
        {
          password: key == 'newPassword' ? value : formData['newPassword'],
          confirmPassword: key == 'confirmPassword' ? value : formData['confirmPassword']
        },
        setPasswordRequirements,
        passwordPolicy,
        t
      );
    } else {
      setFormData({ ...formData, [key]: value });
    }

    // if (errors.password) {
    const { password, confirmPassword } = errors;
    if (password || confirmPassword) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
    // }
  };

  const isPasswordValidAndMatch = () => {
    const { currentPassword, newPassword, confirmPassword } = formData;
    return (
      currentPassword &&
      newPassword &&
      confirmPassword &&
      newPassword === confirmPassword &&
      isValidPassword
    );
  };

  const ChangePasswordCall = async () => {
    let payload = {
      currentPassword: formData['currentPassword'],
      newPassword: formData['newPassword'],
      deliveryMethod: AppConstants.DEFAULT_NOTIFICATION_DELIVERY_METHOD,
      changePasswordTemplate: AppConstants.DEFAULT_CHANGE_PASSWORD_SUCCESS_TEMPLATE
    };
    let res = await changePassword(userData.userId, payload);
    if (res.status === HTTP_STATUS.HTTP_OK) {
      resetForm();
      setToastMsg(t('T_CHANGE_PASSWORD_SUCCESS_MSG'), true, 'success');
    } else {
      if (res.data.issues[0].code === AppConstants.INVALID_PASSWORD_ERROR) {
        setToastMsg(t('T_INVALID_CURRENT_PASSWORD_TEXT'), true, 'error');
      } else {
        setToastMsg(res.data.issues[0].details, true, 'error');
      }
    }
    setIsLoading(false);
  };

  const resetForm = () => {
    setFormData({ newPassword: '', currentPassword: '', confirmPassword: '' });
    setIsValidPassword(false);
    performRegisterPasswordValidation(
      { password: '', confirmPassword: '' },
      setPasswordRequirements,
      passwordPolicy,
      t
    );
  };

  return (
    <>
      <Card
        title={t('T_CHANGE_PASSWORD')}
        icon={lock_icon}
        alt={t('T_CARD_ALT_MESSAGE')}
        height=""
        width=""
      >
        <div className="relative flex flex-wrap" style={{ minWidth: '750px' }}>
          <div className="w-[50%]">
            <div style={{ position: 'relative' }}>
              <InputField
                className="mb-5 w-full pr-[45px]"
                type="password"
                id={'currentPassword'}
                label={t('T_CURRENT_PASSWORD')}
                placeholder={t('T_CURRENT_PASSWORD')}
                fieldKey="currentPassword"
                required={true}
                formData={formData}
                formError={formError}
                setFormData={setFormData}
                disabled={disabled}
                handleChange={(e: any) => {
                  setOnChange('currentPassword', e.target.value);
                }}
                autoComplete={'new-password'}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <InputField
                className="mb-5 w-full pr-[45px]"
                type="password"
                id={'newPassword'}
                label={t('T_NEW_PASSWORD')}
                placeholder={t('T_NEW_PASSWORD')}
                fieldKey="newPassword"
                required={true}
                formData={formData}
                formError={formError}
                setFormData={setFormData}
                disabled={disabled}
                handleChange={(e: any) => {
                  setOnChange('newPassword', e.target.value);
                }}
                autoComplete={'new-password'}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <InputField
                className="w-full pr-[45px]"
                type="password"
                id={'confirmPassword'}
                label={t('T_CONFIRM_PASSWORD')}
                placeholder={t('T_CONFIRM_PASSWORD')}
                fieldKey="confirmPassword"
                required={true}
                formData={formData}
                formError={formError}
                setFormData={setFormData}
                disabled={disabled}
                handleChange={(e: any) => {
                  setOnChange('confirmPassword', e.target.value);
                }}
                autoComplete={'new-password'}
              />
            </div>
          </div>
          <div className="ml-8 flex items-center">
            <div>
              <PasswordRequirement
                passwordRequirements={passwordRequirements}
                passwordPolicy={passwordPolicy}
              />
              <div className="mt-2">
                {passwordRequirementComponent(
                  formData.newPassword &&
                    formData.confirmPassword &&
                    formData.newPassword === formData.confirmPassword,
                  t('T_PASSWORD_MATCH_MSG')
                )}
              </div>
            </div>
          </div>
        </div>
        {
          <div className="text-end">
            <BiButton
              className={'w-[170px] bg-primary text-white'}
              type="button"
              onClick={() => {
                setIsLoading(true);
                ChangePasswordCall();
              }}
              disabled={!isValidPassword}
            >
              {t('T_CHANGE_PASSWORD')}
            </BiButton>
          </div>
        }
      </Card>
    </>
  );
}

const Container = styled.div`
  width: 100%;
`;

export default ChangePassword;
