import { upperFirst } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PageLayouts } from '../../constants/LayoutConstants';
import Card from '../shared/cards/Card';
import { FieldLayout } from './FieldLayout';

interface FieldGroupProps {
  fieldGroup: any;
  fieldItems: Array<any>;
  fieldValues: any;
  fieldErrors: any;
  onValueChange: Function;
  pageLayout: number;
}

export const FieldGroupCard: React.FC<FieldGroupProps> = ({
  fieldGroup,
  fieldItems = [],
  fieldValues = {},
  fieldErrors = {},
  onValueChange,
  pageLayout = 1
}: FieldGroupProps) => {
  const { attributes } = fieldGroup;
  const { icon, name: title, layout, groupId, description } = attributes;
  const { value: grpLayout } = layout;

  const { t } = useTranslation();

  return (
    <Card
      title={title}
      icon={icon.trim() || Clipboard}
      alt={groupId}
      className={`mb-3 rounded-none rounded-t-lg`}
    >
      {description && <div className="text-base font-normal"> {upperFirst(description)}</div>}
      <div
        className={`flex w-full justify-between ${
          grpLayout > 1 ? 'flex-row flex-wrap' : 'flex-col'
        }`}
      >
        {fieldItems.map((fieldItem: any, index: number) => {
          return (
            <FieldLayout
              key={Math.random()}
              fieldGroup={fieldGroup}
              fieldItem={fieldItem}
              lastItem={fieldItems.length - 1 === index}
              fieldValue={fieldValues[fieldItem.key]}
              fieldErrors={fieldErrors[fieldItem.key]}
              onValueChange={onValueChange}
            />
          );
        })}
      </div>
    </Card>
  );
};
