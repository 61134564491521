import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  setInitialDataLoaded,
  setApplicationList,
  setMetaDataObjectList,
  setMetaDataQuestionTypes,
  setAPIPermissions,
  setMetaDataPermissionSet,
  setMobileConfigCatalog
} from '../../app/appInitializeReducer';

import { setRolePermissions, setUserFeatures } from '../../app/loginReducer';

import { HTTP_STATUS } from '../../http/constants/http.status';

import {
  getApplications,
  getMetadataObjects,
  getMetadataInstances
} from '../../http/metadata-service';

import LoaderContainer from '../../components/shared/loaderContainer/LoaderContainer';
import { find } from 'lodash';

import {
  modifyQuestionTypes,
  getLoggedInRolePermissionSet,
  getAdminPortalFeatures
} from '../../util/appInitializeUtil';
import { AppConstants } from '../../constants/AppConstants';
import {
  getUserRoles,
  checkUserProfileConsent,
  createConsentAssignForLoggedUser
} from '../../util/admin-utils';
import { getRole } from '../../http/access-management-service';
import NoPermissionMessage from '../RoutePermission/NoPermissionMessage';

import environment from '../../environment';
import { getFailureMessage } from '../../util/ErrorUtil';

import {
  createConsentAssignment,
  getConsentAssignByGiverId,
  listConsentTemplates,
  listFieldGropus
} from '../../http/access-management-service';

type Props = {
  children: React.ReactNode;
};

const AppInitialize = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(true);
  const [failureMessage, setFailureMessage] = useState('');
  const [permissionInitialized, setPermissionInitialized] = useState(false);

  const userProfileInfo: any = useAppSelector((state) => state.userManagement.userProfileInfo);
  const userInvitationPolicy: any = useAppSelector(
    (state: any) => state.configuration.userInvitationPolicy
  );

  const dispatchError = (response: any) => {
    // dispatch(
    //   setToastData({
    //     toastMessage: getFailureMessage(response)
    //     isToastActive: true,
    //     toastType: 'error'
    //   })
    // );
    dispatch(setInitialDataLoaded(true));
    setLoading(false);
  };

  const getApplicationMetaData = async (name: string) => {
    const response = await getApplications(
      name,
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: applicationResponse = {} } = response;
    const { data: applications = [] } = applicationResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: applications = [] } = applicationResponse;
      dispatch(setApplicationList(applications));
    } else {
      dispatchError(response);
    }

    return applications;
  };

  const getApplicationMetaDataObjects = async (id: string) => {
    const response = await getMetadataObjects(
      id,
      [],
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: objectResponse = {} } = response;
    const { data: objects = [] } = objectResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setMetaDataObjectList(objects));
    } else {
      dispatchError(response);
    }
    return objects;
  };

  const getQuestionTypeInstances = async (id: string) => {
    const response = await getMetadataInstances(
      id,
      [],
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: instanceResponse = {} } = response;
    const { data: instances = [] } = instanceResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setMetaDataQuestionTypes(modifyQuestionTypes(instances)));
    } else {
      dispatchError(response);
    }
    return instances;
  };

  const getMetaDataPermissionSetInstances = async (id: string, loggedInRole: string) => {
    const response = await getMetadataInstances(
      id,
      [],
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: instanceResponse = {} } = response;
    const { data: instances = [] } = instanceResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setMetaDataPermissionSet(getLoggedInRolePermissionSet(instances, loggedInRole)));
    } else {
      dispatchError(response);
    }
    return instances;
  };

  const getMetaDataAdminPortalFeatures = async (id: string) => {
    const response = await getMetadataInstances(
      id,
      [],
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: instanceResponse = {} } = response;
    const { data: instances = [] } = instanceResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setUserFeatures(JSON.stringify(getAdminPortalFeatures(instances))));
      //dispatch(setMetaDataPermissionSet(getAdminPortalFeatures(instances)));
    } else {
      dispatchError(response);
    }
    return instances;
  };

  const getMetaDataMobileCatalogInstance = async (id: string) => {
    const response = await getMetadataInstances(
      id,
      [],
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: instanceResponse = {} } = response;
    const { data: instances = [] } = instanceResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setMobileConfigCatalog(instances));
    } else {
      dispatchError(response);
    }
    return instances;
  };

  const loadMetaData = async () => {
    let roleAPIPermissions: any = {};
    let rolePermissionLoaded: boolean = false;
    let fetchMetaDataInstances = [];
    let loginRole = await getUserRoles(userProfileInfo);
    const response = await getRole(loginRole[0]?.roleId);

    const { status, data: roleResponse = {} } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { permissions: rolePermissions = [] } = roleResponse;
      rolePermissions.forEach((rolePermission: any) => {
        const { apiPermission } = rolePermission;
        if (apiPermission) {
          roleAPIPermissions[apiPermission] = { ...rolePermission };
        }
      });
      rolePermissionLoaded = true;
    } else {
      setFailureMessage(`${AppConstants.ACCESS_CONTROL_SERVICE}: ${getFailureMessage(response)}`);
    }
    setPermissionInitialized(rolePermissionLoaded);
    dispatch(setAPIPermissions(roleAPIPermissions));

    if (rolePermissionLoaded) {
      if (roleAPIPermissions[AppConstants.PERMISSION_ROLE_ASSIGNMENT_GIVERS_LIST]) {
        const { userId, roles: userRoles, createdBy } = userProfileInfo;
        const userConsent = await getConsentAssignByGiverId(
          AppConstants.MAXIMUM_PAGE_SIZE,
          AppConstants.DEFAULT_PAGE,
          userId
        );
        const userProfileConsentObj = await checkUserProfileConsent(
          userConsent,
          userInvitationPolicy,
          userRoles
        );

        const { isImplicitUserConsentAdded, consentForRoles } = userProfileConsentObj;
        if (isImplicitUserConsentAdded) {
          await createConsentAssignForLoggedUser(consentForRoles, userProfileInfo);
        }
      }
      const applications: Array<any> = await getApplicationMetaData(
        environment.ENV_SOLUTION_NAME || environment.ENV_TENANT_NAME
      );
      if (applications.length) {
        const application = find(applications, {
          name: environment.ENV_SOLUTION_NAME || environment.ENV_TENANT_NAME
        });
        if (application) {
          const { id: applicationId } = application;
          const applicationObjects: Array<any> = await getApplicationMetaDataObjects(applicationId);
          if (!applicationObjects.length) {
            return;
          }
          // const questionTypeMetaData = find(applicationObjects, {
          //   name: AppConstants.OBJECTS_SURVEY_QUESTION_TYPES
          // });

          const metaDataPermissionSet = find(applicationObjects, {
            name: AppConstants.METADATA_OBJECT_PERMISSION_SET
          });

          const metaDataAdminFeatures = find(applicationObjects, {
            name: AppConstants.METADATA_OBJECT_ADMIN_FEATURES
          });

          const mobileConfigCatalog = find(applicationObjects, {
            name: AppConstants.METADATA_MOBILE_CATALOG
          });

          // if (questionTypeMetaData) {
          //   const { id: questionTypeMetaDataId } = questionTypeMetaData || {};
          //   if (questionTypeMetaDataId) {
          //     fetchMetaDataInstances.push(getQuestionTypeInstances(questionTypeMetaDataId));
          //     //await getQuestionTypeInstances(questionTypeMetaDataId);
          //   }
          // }
          if (metaDataAdminFeatures) {
            const { id: metaDataAdminFeaturesId } = metaDataAdminFeatures || {};
            if (metaDataAdminFeaturesId) {
              fetchMetaDataInstances.push(getMetaDataAdminPortalFeatures(metaDataAdminFeaturesId));
              //await getMetaDataAdminPortalFeatures(metaDataAdminFeaturesId);
            }
          }

          if (mobileConfigCatalog) {
            const { id: mobileConfigCatalogId } = mobileConfigCatalog;
            if (mobileConfigCatalogId) {
              fetchMetaDataInstances.push(getMetaDataMobileCatalogInstance(mobileConfigCatalogId));
              //await getMetaDataMobileCatalogInstance(mobileConfigCatalogId);
            }
          }

          if (metaDataPermissionSet) {
            const { id: metaDataPermissionSetId } = metaDataPermissionSet || {};
            if (metaDataPermissionSetId) {
              fetchMetaDataInstances.push(
                getMetaDataPermissionSetInstances(metaDataPermissionSetId, loginRole[0]?.name)
              );
              //await getMetaDataPermissionSetInstances(metaDataPermissionSetId, loginRole[0]?.name);
            }
          }
          if (fetchMetaDataInstances.length) {
            await Promise.all(fetchMetaDataInstances);
          }
        }
      }
    }
    dispatch(setInitialDataLoaded(true));
    setLoading(false);
  };

  useEffect(() => {
    loadMetaData();
  }, []);

  return permissionInitialized ? (
    <LoaderContainer isLoading={isLoading}>{children}</LoaderContainer>
  ) : !isLoading ? (
    <LoaderContainer isLoading={isLoading}>
      <NoPermissionMessage message={failureMessage} />
    </LoaderContainer>
  ) : (
    <LoaderContainer isLoading={isLoading} children={undefined}></LoaderContainer>
  );
};

export default AppInitialize;
