import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../../hooks/queryParams';
import { useTranslation } from 'react-i18next';
import { RouteConstants } from '../../../constants/RouteConstants';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import Card from '../../shared/cards/Card';
import SettingIcon from '../../../assets/toolkit.svg';
import MenuButtonsPortal from '../../Menu/MenuButtonsPortal';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import InputField from '../../shared/Fields/InputField';
import TextAreaField from '../../shared/Fields/TextAreaField';
import TabDrawer from '../../Reusable/TabDrawer';
import { Label } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';
import AddModules from './AddModules';
import { AppConstants } from '../../../constants/AppConstants';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import { cloneDeep, isEmpty, pick } from 'lodash';
import ConfigKeyList from '../../../components/ConfigurationService/ConfigKeyList';

import ModuleCard from './ModuleCardContainer';
import { getMigrationExportJob, startExportJob } from '../../../http/migration-service';
import { setToastData } from '../../../app/toastReducer';
import { fieldIsEmpty, validateNameLength } from '../../../util/validators';
import JSONEditor from '../../AppSettings/JSONEditor';
import MigrationContextMenu from '../Listing/MigrationContextMenu';
import { getFailureMessage } from '../../../util/ErrorUtil';
import { EllipsisDiv } from '../../../styles/globalStyles';
import OrganizationsList from '../../Organizations/OrganizationsList';
import MetadataList from '../../Metadata/MetadataList';
import ObjectList from '../../Metadata/ObjectList';
import InstanceList from '../../Metadata/InstanceList';
import RoleList from '../../Roles/RoleList';
import TemplateList from '../../Templates/TemplateList';
import SurveyList from '../../Survey/Template/SurveyList';
import CategoriesList from '../../ConfigurationService/CategoriesList';
import migrationExportReducer, {
  changeMetadataComponent,
  resetMigrationReducer,
  setManifest,
  updateManifest
} from '../../../app/migrationExportReducer';
import { resetGlobalSortSearch } from '../../../app/tableReducer';

const CREATE_EXPORT_FORM_SCHEMA = {
  description: '',
  name: ''
};

interface PayloadMapper {
  [key: string]: string;
}

/**
 * @parentId migrations
 * @manager Create - Export
 * @overview Create of Export Job
 *     <p><strong>Create Export Job</strong><br />
 *     Clicking on <strong>New Export</strong> button on Export Job listing page will take user to New Export Job creation page. Clicking on <strong>Cancel</strong> will take the user to Export Job listing page.<br />
 *     <br />
 *     User can create a new Export Job by entering <strong>Export Name</strong>, <strong>Export Description</strong>, and selecting at least one manifest item (mandatory fields) and clicking on the <strong>Save</strong> button on the admin portal toolbar. <strong>Description</strong> is an additional mandatory field which the user can use to enter more details about the export job. Description is a freetext field.<br />
 *     <br />
 *     In the second Card, the user is displayed two tabs:</p>
 *
 *     <ul>
 *     	<li><strong>Manifest:&nbsp;</strong>In this section User can select which features he wants to export, upon clicking on on the &quot;Add&quot; button on the desired feature, user is displayed a popup where in he can pick the elements he wishes to export. Upon selecting the elements, we send the id field in the manifest.</li>
 *     	<li><strong>Manifest JSON: </strong>This section is a Read-Only JSON Previewer which displays how the selected manifest features are being sent in the payload</li>
 *     </ul>
 *
 *     <p>&nbsp;</p>
 *
 *     <h4>Create Export Job Fields</h4>
 *
 *     <p>Create Export Job page will display below fields with following validation constraints.</p>
 *
 *     <table>
 *     	<tbody>
 *     		<tr>
 *     			<th>Field Name</th>
 *     			<th>Required</th>
 *     			<th>Data Type</th>
 *     			<th>Max Length</th>
 *     		</tr>
 *     		<tr>
 *     			<td>Export Name</td>
 *     			<td>Yes</td>
 *     			<td>String</td>
 *     			<td>255</td>
 *     		</tr>
 *     		<tr>
 *     			<td>Description</td>
 *     			<td>Yes</td>
 *     			<td>String</td>
 *     			<td>4096</td>
 *     		</tr>
 *     		<tr>
 *     			<td>Manifest</td>
 *     			<td>Yes</td>
 *     			<td>Array</td>
 *     			<td>N/A</td>
 *     		</tr>
 *     	</tbody>
 *     </table>
 *
 *     <p>&nbsp;</p>
 *
 *     <h4>Failure Status Codes</h4>
 *
 *     <p>This section describes the Create Export Job Status Code information.</p>
 *
 *     <table>
 *     	<tbody>
 *     		<tr>
 *     			<th>HTTP Status Code</th>
 *     			<th>Service Error Code</th>
 *     			<th>Error Message</th>
 *     		</tr>
 *     		<tr>
 *     			<td>403</td>
 *     			<td>MDS_PERMISSION_DENIED</td>
 *     			<td>You do not have permission to view this page.</td>
 *     		</tr>
 *     		<tr>
 *     			<td>400</td>
 *     			<td>MDS_PARAM_DUPLICATE</td>
 *     			<td>Duplicate name, Export Name for resource type MigrationExport</td>
 *     		</tr>
 *     		<tr>
 *     			<td>500</td>
 *     			<td>MDS_INTERNAL_ERROR</td>
 *     			<td>Internal Server Error</td>
 *     		</tr>
 *     		<tr>
 *     			<td>503</td>
 *     			<td>&nbsp;</td>
 *     			<td>Service Unavailable</td>
 *     		</tr>
 *     	</tbody>
 *     </table>
 *
 *     <p>&nbsp;</p>
 *
 *     <h4>Dependent System settings, Platform services &amp; Role Permission</h4>
 *
 *     <p>This section describes the list of dependent system settings &amp; platform services required for the functioning of the create Export Job page.</p>
 *
 *     <p>System Settings</p>
 *
 *     <p>Table lists all the dependent system setting(s) defined in the configuration service with either global/organization scope.</p>
 *
 *     <table>
 *     	<tbody>
 *     		<tr>
 *     			<th>Key</th>
 *     			<th>Type</th>
 *     			<th>Value</th>
 *     			<th>Scope</th>
 *     		</tr>
 *     		<tr>
 *     			<td>None</td>
 *     			<td>None</td>
 *     			<td>None</td>
 *     			<td>None</td>
 *     		</tr>
 *     	</tbody>
 *     </table>
 *
 *     <p>&nbsp;</p>
 *
 *     <p>Platform Service(s)</p>
 *
 *     <p>Table lists all the dependent platform service(s) with specific version(s) for create Export Job.</p>
 *
 *     <table>
 *     	<tbody>
 *     		<tr>
 *     			<th>Service Name</th>
 *     			<th>Version</th>
 *     		</tr>
 *     		<tr>
 *     			<td>Migration Service</td>
 *     			<td>1.1.0</td>
 *     		</tr>
 *     	</tbody>
 *     </table>
 *
 *     <p>&nbsp;</p>
 *
 *     <p>API Role Permission(s)</p>
 *
 *     <p>Table lists the required API role permissions for creating the Export Job page</p>
 *
 *     <table>
 *     	<tbody>
 *     		<tr>
 *     			<th>Feature</th>
 *     			<th>API URL</th>
 *     			<th>API Method</th>
 *     			<th>API Permission</th>
 *     			<th>Required</th>
 *     		</tr>
 *     		<tr>
 *     			<td>Create Export Job</td>
 *     			<td>/export</td>
 *     			<td>POST</td>
 *     			<td>migration-service.migration-export.create</td>
 *     			<td>Yes</td>
 *     		</tr>
 *     	</tbody>
 *     </table>
 *
 *     <p>Sequence Diagram for create Export Job</p>
 *
 */

const ExportDetail = () => {
  const query = useQuery();
  const params = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const manifestReducer = useAppSelector((state: any) => state.migrationExport.manifest);
  const metadataComponent = useAppSelector((state: any) => state.migrationExport.metadataComponent);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(`${t('T_MIGRATION_FORM_TAB_MANIFEST')}`);
  const [formData, setFormData] = useState<any>(CREATE_EXPORT_FORM_SCHEMA);
  const [formError, setFormError] = useState<any>({
    name: '',
    description: ''
  });
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [exportJobDetails, setExportJobDetails] = useState<any>({});

  const [modalTool, setModalTool] = useState({ visible: false, index: 0 });
  const [disableButton, setDisableButton] = useState(false);

  // console.log(manifestReducer);
  const manifestItemsSchema = [
    {
      title: 'T_MIGRATION_FORM_MANIFEST_ROLESPERMISSION',
      key: 'globalRoles',
      component: (type: string) => {
        return (
          <RoleList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
            }}
          ></RoleList>
        );
      }
    },
    {
      title: 'T_MIGRATION_FORM_MANIFEST_SYSTEMSETTINGS',
      key: 'globalSettings',
      component: (type: string) => {
        return (
          <ConfigKeyList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
            }}
          ></ConfigKeyList>
        );
      }
    },
    {
      title: 'T_MIGRATION_FORM_MANIFEST_CATEGORIES',
      key: 'globalCategories',
      searchField: 'name',
      component: (type: string) => {
        return (
          <CategoriesList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
            }}
          ></CategoriesList>
        );
      }
    },
    {
      title: 'T_MIGRATION_FORM_MANIFEST_NOTIFICATIONTEMPLATES',
      key: 'globalTemplates',
      component: (type: string) => {
        return (
          <TemplateList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
            }}
          ></TemplateList>
        );
      }
    },
    {
      title: 'T_MIGRATION_FORM_MANIFEST_SURVEYS',
      key: 'globalQuestionnaires',
      component: (type: string) => {
        return (
          <SurveyList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
            }}
          ></SurveyList>
        );
      }
    },
    {
      title:
        metadataComponent?.route === 'metadata'
          ? 'T_MIGRATION_FORM_MANIFEST_METADATA'
          : metadataComponent?.route === 'objects'
          ? 'T_MIGRATION_FORM_MANIFEST_OBJECTS'
          : 'T_MIGRATION_FORM_MANIFEST_INSTANCES',

      key: 'applications',
      component: (type: string) => {
        return metadataComponent?.route === 'metadata' ? (
          <MetadataList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
              dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
            }}
          ></MetadataList>
        ) : metadataComponent?.route === 'objects' ? (
          <ObjectList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
              dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
            }}
          ></ObjectList>
        ) : (
          <InstanceList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
              dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
            }}
          ></InstanceList>
        );
      }
    },
    {
      title: 'T_MIGRATION_FORM_MANIFEST_ORGANIZATIONS',
      key: 'organizations',
      component: (type: string) => {
        return (
          <OrganizationsList
            type={type === 'selection' ? 'migrationSelection' : 'migrationPreview'}
            toggleModal={() => {
              setModalTool({ ...modalTool, visible: false });
            }}
            migrationHeaders={[
              {
                key: 'name',
                description: t('T_ORGANIZATION_NAME'),
                sortEnabled: type === 'selection',
                width: type === 'selection' ? '35%' : '30%',
                clickable: true,
                sortingInformation: {
                  order: ''
                },
                nastedData: true,
                render: (data: any) => {
                  return (
                    <EllipsisDiv className="w-full flex-1" title={data?.name}>
                      {data?.name}
                    </EllipsisDiv>
                  );
                },
                styles: { borderRight: 'none' }
              },

              {
                key: 'type',
                description: t('T_TYPE'),
                sortEnabled: false,
                clickable: true,
                width: '15%',
                sortingInformation: {
                  order: ''
                }
              },
              {
                key: 'status',
                description: t('T_STATUS'),
                sortEnabled: false,
                clickable: true,
                width: '10%',
                sortingInformation: {
                  order: ''
                }
              },
              {
                key: 'parent',
                description: t('T_PARENT'),
                sortEnabled: false,
                clickable: true,
                width: type === 'selection' ? '30%' : '25%',
                sortingInformation: {
                  order: ''
                }
              }
            ]}
          ></OrganizationsList>
        );
      }
    }
  ];

  const [manifestItems, setManifestItems] = useState<any>(manifestItemsSchema);

  useEffect(() => {
    async function init() {
      dispatch(resetMigrationReducer());
      dispatch(resetGlobalSortSearch());
      if (!params.id) {
      } else {
        setIsLoading(true);
        setIsEdit(false);
        try {
          const response = await getMigrationExportJob(parseInt(params.id));
          const { data: exportJob, status } = response;
          if (status === 200) {
            setExportJobDetails(exportJob);

            // getManifestData(exportJob?.manifest, [], 2, 5);
          } else {
            let error = getFailureMessage(response);
            dispatch(
              setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' })
            );
          }
          setIsLoading(false);
        } catch (e: any) {
          dispatch(
            setToastData({
              isToastActive: true,
              toastMessage: t('T_ERROR_SOMETHING_WRONG'),
              toastType: 'error'
            })
          );
          navigate(-1);
        }
      }
    }

    init();
  }, []);

  useEffect(() => {
    setManifestItems(manifestItemsSchema);
  }, [metadataComponent]);

  useEffect(() => {
    if (disableButton) {
      setDisableButton(false);
      setFormError({
        name: '',
        description: ''
      });
    }
  }, [formData]);

  useEffect(() => {
    if (!isEmpty(exportJobDetails)) {
      const { name, description, manifest } = exportJobDetails;
      setFormData({ name, description });
      dispatch(updateManifest(manifest));
    }
  }, [exportJobDetails]);

  const mergeArrayByKey = (arr1: any[], arr2: any[], key: string): any[] => {
    const merged: any[] = cloneDeep(arr2);

    arr1.forEach((item1) => {
      const matchingIndex = merged.findIndex((item2) => item2[key] === item1[key]);

      if (matchingIndex !== -1) {
        merged[matchingIndex] = {
          ...merged[matchingIndex],
          ...item1
        };
      } else {
        merged.push(item1);
      }
    });

    return merged;
  };
  const mergeDependentArtifacts = (arr1: any[], arr2: any[]): any[] => {
    const merged: any[] = cloneDeep(arr2);

    arr1.forEach((item1) => {
      const matchingIndex = merged.findIndex((item2) => item2.id === item1.id);

      if (matchingIndex !== -1) {
        merged[matchingIndex] = {
          id: merged[matchingIndex].id,
          dependentArtifactConfigurations: {
            ...merged[matchingIndex].dependentArtifactConfigurations,
            ...item1.dependentArtifactConfigurations
          }
        };
      } else {
        merged.push(item1);
      }
    });

    return merged;
  };

  const payloadGenerator = () => {
    let payload: any = {
      name: formData.name,
      manifest: {}
    };

    if (formData.description) {
      payload.description = formData.description;
    }

    Object.keys(manifestReducer).forEach((manifestKey: any) => {
      if (manifestReducer[manifestKey].length) {
        if (
          ['organizations', 'globalSettingsOrg', 'orgRoles', 'orgTemplates'].includes(manifestKey)
        ) {
          let payloadMapper: PayloadMapper = {
            globalSettingsOrg: 'configurations',
            orgRoles: 'roles',
            orgTemplates: 'templates'
          };

          let formattedSettingsOrg =
            manifestKey !== 'organizations'
              ? manifestReducer[manifestKey].length
                ? manifestReducer[manifestKey].map((org: any) => ({
                    id: org.id,
                    dependentArtifactConfigurations: {
                      [payloadMapper[manifestKey]]: org.dependentArtifactConfigurations[
                        payloadMapper[manifestKey]
                      ].map((config: any) => {
                        return config.overWrite
                          ? { id: config.id, overWrite: config.overWrite }
                          : { id: config.id };
                      })
                    }
                  }))
                : manifestReducer[manifestKey]
              : [];

          let orgManifest = manifestReducer.organizations.map((ele: any) => {
            return pick(ele, ['id', 'overWrite']);
          });
          let mergedOrg = formattedSettingsOrg.length
            ? mergeArrayByKey(formattedSettingsOrg, orgManifest, 'id')
            : orgManifest;
          if (mergedOrg.length) {
            payload.manifest.organizations = payload.manifest?.organizations?.length
              ? mergeDependentArtifacts(
                  mergedOrg.map((ele: any) => {
                    return pick(ele, ['id', 'overWrite', 'dependentArtifactConfigurations']);
                  }),
                  payload.manifest.organizations
                )
              : mergedOrg.map((ele: any) => {
                  return pick(ele, ['id', 'overWrite', 'dependentArtifactConfigurations']);
                });
          }
        } else if (manifestKey === 'applications') {
          payload.manifest[manifestKey] = manifestReducer[manifestKey].map((app: any) => ({
            id: app.id,
            overWrite: app.overWrite || false,
            dependentArtifactConfigurations: {
              objects: app.dependentArtifactConfigurations.objects.map((object: any) => ({
                id: object.id,
                overWrite: object.overWrite || false,
                ...(object.id !== 'ALL' && {
                  dependentArtifactConfigurations: {
                    instances: object.dependentArtifactConfigurations.instances.map(
                      (instance: any) => ({
                        id: instance.id
                      })
                    )
                  }
                })
              }))
            }
          }));
        } else if (!['globalSettingsOrg', 'tempApplications'].includes(manifestKey)) {
          payload.manifest[manifestKey] = manifestReducer[manifestKey].map((ele: any) => {
            return pick(ele, ['id', 'overWrite']);
          });
        }
      }
    });

    return payload;
  };

  const toggleModal = async (index: number) => {
    setModalTool({ visible: true, index });
  };

  const updateLocalManifest = (key: string, data: any) => {
    const updatedManifest = manifestReducer[key].map((manifestItem: any) => {
      const match = data[key].find((element: any) => element.id === manifestItem.id);
      if (match) {
        return { ...manifestItem, ...match };
      } else {
        return manifestItem;
      }
    }); // Add Delete Condition

    dispatch(setManifest({ key, value: updatedManifest }));
  };

  const toggleExpanded = async (key: string) => {
    //Commented as edit flow not required
    // if (params.id) {
    //   setIsLoading(true);
    //   let data = await getManifestData(manifestReducer, [key], 1, 5);
    //   updateLocalManifest(key, data);
    // }
    // setIsLoading(false);
  };

  const tabConfig = [
    {
      label: t('T_MIGRATION_FORM_TAB_MANIFEST'),
      component: (
        <div>
          {isEdit ? (
            <Label className="px-5 py-4">
              <img src={Asterix} className="mr-1 h-2 w-2" />
              {t('T_MIGRATION_FORM_MANIFEST_INFO')}
            </Label>
          ) : (
            ''
          )}
          {manifestItems.map((manifest: any, index: number) => {
            const { title, key } = manifest;
            return (
              <ModuleCard
                toggleModal={() => {
                  toggleModal(index);
                }}
                toggleExpanded={toggleExpanded}
                manifest={manifest}
                disabled={isLoading || !isEdit}
              />
            );
          })}
        </div>
      )
    },
    {
      label: t('T_MIGRATION_FORM_TAB_MANIFEST_JSON'),
      component: (
        <div className="m-5">
          <JSONEditor
            jsonString={JSON.stringify(payloadGenerator()?.manifest)}
            setIsLoading={setIsLoading}
            isComponent
            disabled
            showLineNumber
            JSONEditorLabel={t('T_CONFIG_KEY_VALUE')}
            required={true}
          />
        </div>
      )
    }
  ];

  const formValidator = () => {
    if (disableButton) {
      return disableButton;
    }

    if (formData.name.trim()) {
      return false;
    }
    return true;
  };

  const validateFormData = () => {
    let errors: any = {
      name: '',
      description: ''
    };

    const { name, description } = formData;

    errors['name'] = fieldIsEmpty(name.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(name.trim(), 1024)
      ? ''
      : `${t('T_VALIDATION_ERR_TEXT_LENGTH_CUSTOM_MSG').replace('${LENGTH}', '1024')}`;

    errors['description'] = validateNameLength(description.trim(), 4096)
      ? ''
      : `${t('T_VALIDATION_ERR_TEXT_LENGTH_CUSTOM_MSG').replace('${LENGTH}', '4096')}`;
    setFormError({ ...errors });
    return errors;
  };

  const handleSave = async () => {
    const errors = validateFormData();
    if (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    ) {
      setDisableButton(true);
      return;
    }

    const payload = payloadGenerator();

    if (isEmpty(payload.manifest)) {
      dispatch(
        setToastData({
          toastMessage: t('T_MIGRATION_EXPORT_MANIFEST_EMPTY_ERROR'),
          isToastActive: true,
          toastType: 'error'
        })
      );
      return;
    }
    setIsLoading(true);
    let response = await startExportJob(payload);

    if ([HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(response.status)) {
      dispatch(
        setToastData({
          toastMessage: t('T_MIGRATION_EXPORT_START_SUCCESS'),
          isToastActive: true,
          toastType: 'success'
        })
      );
      navigate(RouteConstants.ROUTE_MIGRATION_EXPORT_LIST);
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <div className="flex w-4/5 flex-col  overflow-visible px-2 py-3">
        <Card
          title={t('T_MIGRATION_EXPORT_FORM_CARD_HEADER')}
          icon={SettingIcon}
          alt={'T_MIGRATION_EXPORT_FORM_CARD_HEADER'}
          height={''}
          width={''}
          className=""
        >
          {modalTool.visible && (
            <AddModules
              toggleModal={(bool: boolean) => {
                setModalTool({ ...modalTool, visible: bool });
              }}
              config={manifestItems[modalTool.index]}
            ></AddModules>
          )}
          <MenuButtonsPortal>
            {isEdit ? (
              <>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t('T_CANCEL')}
                </BiButton>

                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={handleSave}
                  disabled={formValidator()}
                >
                  {t('T_EXPORT')}
                </BiButton>
              </>
            ) : (
              <>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  {t('T_MIGRATION_BACK_TO_EXPORT_LIST')}
                </BiButton>
                <MigrationContextMenu
                  className="!px-1.5 !py-1"
                  iconSize=""
                  migrationJob={exportJobDetails}
                  setIsLoading={setIsLoading}
                  dropdownMenu={true}
                  jobType={AppConstants.MIGRATION_JOB_EXPORT}
                  setEdit={setIsEdit}
                />
              </>
            )}
          </MenuButtonsPortal>
          <div className="flex w-full flex-wrap">
            <>
              <div className="mb-5 flex w-full flex-col flex-wrap">
                <InputField
                  type={'text'}
                  id={'exportName'}
                  label={t('T_MIGRATION_EXPORT_FORM_NAME')}
                  placeholder={''}
                  fieldKey={'name'}
                  asterixFirst={true}
                  required={true}
                  formData={formData}
                  setFormData={setFormData}
                  formError={formError}
                  disabled={!isEdit}
                  handleChange={(e: any) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
              </div>
              <div className="mb-5 flex w-full flex-col flex-wrap">
                <TextAreaField
                  fieldKey={'description'}
                  type={'text'}
                  id={'exportyDesc'}
                  label={t('T_MIGRATION_EXPORT_FORM_DESCRIPTION')}
                  placeholder={''}
                  asterixFirst={true}
                  required={false}
                  formData={formData}
                  setFormData={setFormData}
                  formError={formError}
                  rows={3}
                  disabled={!isEdit}
                  handleChange={(e: any) => {
                    setFormData({ ...formData, description: e.target.value });
                  }}
                ></TextAreaField>
              </div>
            </>
          </div>
        </Card>
        <div className="mt-4 w-full" style={{ border: '1px solid #D9D9D9', borderRadius: '7px' }}>
          <TabDrawer
            borderBottom={true}
            tabConfig={tabConfig}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </LoaderContainer>
  );
};

export default ExportDetail;
