import { createSlice } from '@reduxjs/toolkit';

export const bucketSlice = createSlice({
  name: 'bucketData',
  initialState: {
    bucketInfo: JSON.parse(sessionStorage.getItem('bucketInfo') || '{}') as any,
    folderInfo: JSON.parse(sessionStorage.getItem('folderInfo') || '{}') as any
  },
  reducers: {
    setBucketData: (state, action) => {
      sessionStorage.setItem('bucketInfo', JSON.stringify(action.payload));
      state.bucketInfo = action.payload;
    },
    setFolderData: (state, action) => {
      sessionStorage.setItem('folderInfo', JSON.stringify(action.payload));
      state.folderInfo = action.payload;
    }
  }
});

export const { setBucketData,setFolderData } = bucketSlice.actions;

export default bucketSlice.reducer;
