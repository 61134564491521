import TabDrawer from '../Reusable/TabDrawer';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/queryParams';
import { useTranslation } from 'react-i18next';
import AdminList from './AdminList';
import AdminInvitedList from './AdminInvitedList';
import { setURLPaths } from '../../app/organizationReducer';
import { RouteConstants } from '../../constants/RouteConstants';

interface AdminContainerProps {
  defaultTab?: string;
}

const AdminContainer = ({ defaultTab }: AdminContainerProps) => {
  const query = useQuery();
  const params = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState(defaultTab || 'Active Users');

  const tabPaths: any = {
    'Active Users': '/admin-list',
    'Invited Users': '/admin-list/invited'
  };

  const tabConfig = [
    {
      label: `Active Users`,
      component: <AdminList />
    },
    {
      label: `Invited Users`,
      component: <AdminInvitedList />
    }
  ];

  const ChangeTab = (tab: string) => {
    //navigate(tabPaths[tab]);
    if (params?.id) {
      navigate(`${tabPaths[tab]}/${params?.id}`);
    } else {
      navigate(tabPaths[tab]);
    }
    setActiveTab(tab);
  };

  return (
    <div style={{ width: '100%' }}>
      <TabDrawer
        borderBottom={false}
        tabConfig={tabConfig}
        activeTab={activeTab}
        setActiveTab={ChangeTab}
        style={{
          width: '100%',
          background: '#fff',
          height: '48px',
          borderBottom: '0.5px solid',
          paddingTop: '2px'
        }}
        childStyle={{ height: 'calc(100vh - 9rem)' }}
      />
    </div>
  );
};

export default AdminContainer;
