import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import TemplateTable from '../shared/tables/TemplateTable';
import { TableContainer } from '../Organizations/OrganizationsList';
import { getApplications, deleteApplication } from '../../http/metadata-service';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { AppConstants } from '../../constants/AppConstants';
import { useTheme } from '../../context/themeContext';
import { setURLPaths } from '../../app/organizationReducer';
import { useNavigate } from 'react-router-dom';
import { plusSign } from '../../assets/components/svgs';
import { setApplication } from '../../app/metadataReducer';
import { setToastData } from '../../app/toastReducer';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { cloneDeep, debounce, findIndex } from 'lodash';
import { getFailureMessage } from '../../util/ErrorUtil';
import { RouteConstants } from '../../constants/RouteConstants';
import DeleteConfirmModal from './DeleteConfirmModal';
import { EllipsisDiv } from '../../styles/globalStyles';
import Checkbox from '../shared/Fields/Checkbox';
import {
  changeMetadataComponent,
  deleteManifestItem,
  setManifest
} from '../../app/migrationExportReducer';
import { ActionButtons } from '../Reusable/ActionButton';
import AddCircle from '../../assets/addCircle.svg';
import moment from 'moment';

/**
 * @parentId metadata
 * @manager Metadata Application Listing
 * @overview Application entity is top level namespace under which all the object schema definitions & instances will be stored.
 *   <section>
 *       <p>
 *           Application listing page is the default page the user is navigated on clicking <b>Metadata</b> navigation bar menuitem.Listing page will show all the application(s) or namespace(s) in a paginated way.
 *           The Default pagination size be fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & application data.
 *       </p>
 *       <br>
 *       <p>
 *           Application(s) can be filtered based on the application name using the search field provided above the application listing data grid.Admin user(s) can create new application by clicking on <b>New Application</b> button provided on the admin portal toolbar,
 *           which will navigate user to Metadata application creation page. <b>New Application</b> button will be shown based on logged in
 *           user role API permissions as described below.
 *       </p>
 *       <br/>
 *       <p>
 *           Datagrid will display details of each application as row item with each column representing application property.
 *           <br/>
 *           <ul>
 *               <li>Status</li>
 *               <li>Name</li>
 *               <li>Display Name</li>
 *               <li>Description</li>
 *               <li>Last Modified On</li>
 *               <li>Delete Action</li>
 *           </ul>
 *           <b>Note:</b> Delete action is shown based on logged in user role API permission to delete application. Actual deletion happens after user confirms on the deletion prompt.
 *       </p>
 *       <p>
 *           Sorting of application grid is only supported on <i>Name</i> column.The breadcrumb bar on the application toolbar will reflect location of the user in the admin portal. Clicking on application rowitem will drill down user to application details page which will be displayed in readonly mode.
 *       </p>
 *     </section>
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes the List Metadata Applications Status Code information.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of metadata application listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for Metadata application listing</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for listing metadata applications page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/applications</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-application.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/applications</td>
 *               <td>POST</td>
 *               <td>metadata-service.metadata-application.create</td>
 *               <td>No</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for metadata application listing page</p>
 *   </section>
 */

interface MetadataListProps {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const MetadataList = ({ type = 'listing', toggleModal = () => {} }: MetadataListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState<any>([]);
  const [paging, setPaging] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deletableIndex, setDeletableIndex] = useState<number>(-1);

  const [size, setSize] = useState<number>(
    type === 'listing' ? AppConstants.DEFAULT_PAGE_SIZE : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
  );
  const [currentPage, setCurrentPage] = useState<number>(AppConstants.DEFAULT_PAGE);

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const manifestAppData =
    useAppSelector((state: any) => state.migrationExport?.manifest?.applications) || [];

  const manifestTempAppData =
    useAppSelector((state: any) => state.migrationExport?.manifest?.tempApplications) || [];

  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const isCheckboxSelected = (data: any) => {
    const { id } = data;
    return (
      findIndex(selectedItems, function (item: any) {
        return item.id === id;
      }) !== -1
    );
  };

  const toggleCheckbox = (data: any) => {
    const { id, name, displayName, active } = data;

    let existingArr: any = cloneDeep(selectedItems);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      existingArr.splice(existingId, 1);
      setSelectedItems(existingArr);
      return;
    }
    existingArr.push({
      id,
      name,
      displayName,
      active,
      dependentArtifactConfigurations: {
        objects: [
          {
            id: 'ALL'
          }
        ]
      }
    });
    setSelectedItems(existingArr);
  };

  const toggleOverWrite = (data: any) => {
    const { id } = data;

    let existingArr: any = cloneDeep(manifestAppData);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      existingArr[existingId].overWrite = !existingArr[existingId].overWrite;
      dispatch(setManifest({ key: 'applications', value: existingArr }));
      return;
    }
  };

  const tableHeadersBuilder = () => {
    let TABLE_HEADERS: Array<ITableHeader> = [
      {
        key: 'active',
        description: 'T_STATUS',
        sortEnabled: false,
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (row: any) => {
          return row?.active ? t('T_ACTIVE') : t('T_NOT_ACTIVE');
        },
        width: '10%'
      },
      {
        key: 'name',
        description: 'T_NAME',
        sortEnabled: type !== 'migrationPreview',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.name}>
              {data?.name}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '30%' : type === 'migrationPreview' ? '35%' : '40%'
      },
      {
        key: 'displayName',
        description: 'T_DISPLAY_NAME',
        sortEnabled: false,
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.displayName}>
              {data?.displayName}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '30%' : type === 'migrationPreview' ? '35%' : '40%'
      }
    ];

    switch (type) {
      case 'listing':
        TABLE_HEADERS.push({
          key: 'description',
          description: 'T_DESCRIPTION',
          sortEnabled: false,
          clickable: true,
          sortingInformation: {
            order: ''
          },
          render: (data: any) => {
            return (
              <EllipsisDiv className="w-full flex-1" title={data?.description}>
                {data?.description}
              </EllipsisDiv>
            );
          },
          width: '30%'
        });

        TABLE_HEADERS.push({
          key: 'updatedOn',
          description: 'T_LAST_MODIFIED_ON',
          sortEnabled: false,
          clickable: true,
          sortingInformation: {
            order: ''
          },
          render: (data: any) => {
            return data.updatedOn
              ? moment(data.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT)
              : '';
          },
          width: '30%'
        });
        // TODO: Need clarity on sorting and showing user name
        // TABLE_HEADERS.push({
        //   key: 'updatedBy',
        //   description: 'T_LAST_MODIFIED_BY',
        //   sortEnabled: false,
        //   clickable: true,
        //   sortingInformation: {
        //     order: ''
        //   },
        //   width: '30%'
        // });

        if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_APPN_DELETE]) {
          TABLE_HEADERS.push({
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '5%',
            sortingInformation: {
              order: ''
            }
          });
        }
        break;

      case 'migrationSelection':
        TABLE_HEADERS.unshift({
          key: 'checked',
          description: '',
          sortEnabled: false,
          width: '10%',
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={isCheckboxSelected(data)}
                  onChange={() => {
                    toggleCheckbox(data);
                  }}
                  testID="addModuleCheckbox"
                />
              </>
            );
          }
        });
        break;
      case 'migrationPreview':
        TABLE_HEADERS.push(
          {
            key: 'overWrite',
            description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any, index: any) => {
              return (
                <>
                  <Checkbox
                    className="mx-auto"
                    height="20px"
                    width="20px"
                    onChange={() => {
                      toggleOverWrite(data);
                    }}
                    checked={data?.overWrite}
                    testID="allowOverWriteCheckbox"
                  />
                </>
              );
            }
          },
          {
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            }
          }
        );
        break;
    }

    return TABLE_HEADERS;
  };

  const [tableHeaders, setTableHeaders] = useState(tableHeadersBuilder());

  useEffect(() => {
    dispatch(
      setURLPaths([{ key: RouteConstants.ROUTE_MDS_APPLN_LIST, label: 'Metadata > Applications' }])
    );
    if (type === 'migrationPreview') {
      setIsLoading(false);
      return;
    }
    loadApplicationList('', currentPage, size, AppConstants.DEFAULT_SORT_BY);
  }, []);

  useEffect(() => {
    if (type !== 'listing') {
      if (manifestTempAppData.length) {
        setSelectedItems(manifestTempAppData);
      } else {
        setSelectedItems(manifestAppData);
      }
    }
  }, [manifestAppData, manifestTempAppData]);

  useEffect(() => {
    if (type === 'migrationSelection') {
      setTableHeaders(tableHeadersBuilder());
    } else if (type === 'migrationPreview') {
      setTableHeaders(tableHeadersBuilder());
      setRows(selectedItems);
      setIsLoading(false);
    }
  }, [selectedItems]);

  const loadApplicationList = async (
    name: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    const response = await getApplications(name, page, size, sort, sortType);
    const { status, data: appResponse } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: applications = [], paging } = appResponse;
      if (applications.length) {
        setRows([...applications]);
        setPaging(paging);
      } else {
        noDataAvailableMessage(setRows, setSize, setPaging, paging, t);
      }
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const refreshTableData = (
    name: string = '',
    page: number,
    size: number,
    sort: string,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setTableValues(name, page, size, sort, sortType);
  };

  const debouncedCall = useRef(
    debounce((name, page, size, sort, sortType) => {
      setSize(size);
      setCurrentPage(page);
      setIsLoading(true);
      loadApplicationList(name, page, size, sort, sortType);
    }, 400)
  ).current;

  const setTableValues = (
    name: string = '',
    page: number,
    size: number,
    sort: string = '',
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    debouncedCall(name, page, size, sort, sortType);
  };

  const applicationSelector = (data: any) => {
    let selectedItemsClone = cloneDeep(selectedItems);
    function navigator() {
      dispatch(setApplication({ ...data }));
      dispatch(setManifest({ key: 'tempApplications', value: selectedItemsClone }));
      dispatch(changeMetadataComponent({ route: 'objects', id: data?.id, objectId: '' }));
    }
    const { name, displayName, active, id } = data;
    if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_APPN_GET] && type === 'listing') {
      dispatch(setApplication({ ...data }));
      navigate(RouteConstants.ROUTE_MDS_APPLN_VIEW.replace(':id', data?.id));
    } else {
      if (type === 'migrationPreview') {
        if (
          findIndex(data?.dependentArtifactConfigurations.objects, function (item: any) {
            return item.id === 'ALL';
          }) == -1
        ) {
          navigator();
        }
      } else {
        if (!isCheckboxSelected(data)) {
          selectedItemsClone.push({
            id,
            name,
            displayName,
            active,
            dependentArtifactConfigurations: {
              objects: [
                {
                  id: 'ALL'
                }
              ]
            }
          });
        }
        navigator();
      }
    }
  };

  const confirmDelete = (row: number, show: boolean = true) => {
    if (type === 'migrationPreview') {
      dispatch(deleteManifestItem({ key: 'applications', id: rows[row + currentActivePage]?.id }));
      dispatch(
        deleteManifestItem({ key: 'tempApplications', id: rows[row + currentActivePage]?.id })
      );
      return;
    }
    setDeleteConfirmation(show);
    setDeletableIndex(row);
  };

  const removeApplication = async (row: number) => {
    setIsLoading(true);
    const msg: string = t('T_METADATA_APPLN_DELELTE_SUCCESS');
    const { id, name } = rows[row];
    const response: any = await deleteApplication(id);
    const { status } = response;
    const success = HTTP_STATUS.isSuccess(status);
    let errorMessage = success
      ? ''
      : getFailureMessage(response).includes(`id ${id}`)
      ? getFailureMessage(response).replace(`id ${id}`, `name ${name}`)
      : getFailureMessage(response);
    dispatch(
      setToastData({
        toastMessage: success ? msg.replace('%s', name) : errorMessage,
        isToastActive: true,
        toastType: success ? 'success' : 'error'
      })
    );
    if (success) {
      const newRows: Array<any> = [...rows];
      newRows.splice(deletableIndex, 1);
      setRows(newRows);
    }
    setDeletableIndex(-1);
    setIsLoading(false);
  };

  const saveSelection = () => {
    dispatch(setManifest({ key: 'applications', value: selectedItems }));
    toggleModal();
  };

  useEffect(() => {
    if (type === 'migrationPreview') {
      setCurrentActivePage(currentPage * size - size);
    }
  }, [currentPage]);

  return (
    <LoaderContainer isLoading={isLoading}>
      <TableContainer className={`${type !== 'listing' && '!pb-0'}`}>
        {type === 'listing' ? (
          <MenuButtonsPortal>
            {roleAPIPermissions[AppConstants.PERMISSION_METADATA_APPN_CREATE] && (
              <BiButton
                className={'flex flex-row bg-primary text-white'}
                type="button"
                onClick={() => {
                  navigate(RouteConstants.ROUTE_MDS_APPLN_CREATE);
                }}
              >
                <img src={AddCircle} className="mr-2 w-6 text-center"></img>
                {t('T_NEW_METADATA')}
              </BiButton>
            )}
          </MenuButtonsPortal>
        ) : (
          ''
        )}
        <TemplateTable
          tableData={
            type === 'migrationPreview'
              ? rows.slice(currentActivePage, currentActivePage + size)
              : rows
          }
          onRefreshTableData={refreshTableData}
          size={size.toString()}
          currentPage={currentPage.toString()}
          isSearchable={type !== 'migrationPreview'}
          templateSelector={applicationSelector}
          cellSelector={applicationSelector}
          searchPlaceholder={t('T_SEARCH_METADATA_BY_NAME')}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={paging}
          theme={useTheme().theme}
          containerClass="mt-0"
          showDeleteButton={
            !!roleAPIPermissions[AppConstants.PERMISSION_METADATA_APPN_DELETE] ||
            type === 'migrationPreview'
          }
          deleteOnClick={confirmDelete}
          setCurrentPage={setCurrentPage}
          isLocalPaginator={type === 'migrationPreview'}
          totalDataLength={rows.length}
          isPaginated={type !== 'migrationPreview'}
          disablePageSize={type !== 'listing'}
        />
      </TableContainer>
      {deleteConfirmation && (
        <DeleteConfirmModal
          width={'35rem'}
          title={t('T_DELETE_CONFIRMATION_TITLE')}
          onClose={() => confirmDelete(-1, false)}
          children={
            <div>
              <span>
                {t('T_DELETE_CONFIRMATION_TEXT')}
                <b>{rows[deletableIndex]?.name} </b>
                {t('T_APPLICATION')}?
              </span>
            </div>
          }
          onCancel={() => confirmDelete(-1, false)}
          // confirmClassName={'rose-600'}
          onConfirm={() => {
            setDeleteConfirmation(false);
            setTimeout(() => removeApplication(deletableIndex));
          }}
        />
      )}
      {type === 'migrationSelection' ? (
        <div className="w-full">
          <ActionButtons
            primaryButtonLabel={t('T_SAVE')}
            primaryButtonAction={saveSelection}
            secondaryButtonLabel={t('T_CANCEL')}
            secondaryButtonAction={toggleModal}
            primaryButtonWidth="full"
            primaryButtonClass={'px-4'}
          />
        </div>
      ) : (
        ''
      )}
    </LoaderContainer>
  );
};

export default MetadataList;
