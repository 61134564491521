import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BiButton from '../primitives/buttons/BiButton.primitive';
import SelectField from '../shared/Fields/SelectField';
import Modal from '../shared/ModalPortal/Modal';

import { ActionButtons } from '../PermissionSet/NewPermissionSet';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { AppConstants } from '../../constants/AppConstants';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';

interface Props {
  selectOptions: Array<any>;
  visibility: boolean;
  toggleVisibility: Function;
  setSelection: Function;
}

const CreateTemplateType = ({
  selectOptions,
  visibility,
  toggleVisibility,
  setSelection
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [formData, setFormData] = useState<any>({
    type: ''
  });
  const [formError, setFormError] = useState<any>({});

  const setSelectedOption = (selectedOption: any) => {
    let [selection] = selectedOption;
    setSelection(selection.value);
  };

  return (
    <>
      <Modal
        showHeader={true}
        showCloseIcon={true}
        width="27rem"
        headerText={t('T_TEMPLATE_TYPE')}
        toggleVisiblity={(value: boolean) => {
          toggleVisibility(false);
        }}
        isVisible={visibility}
        overflowVisible={true}
        className="bottom-4"
        body={
          <div data-testid="createTemplateModal">
            <PickList
              fieldKey={'value'}
              label={t('T_TEMPLATE_TYPE_SUB_TEXT')}
              required={true}
              fieldValues={['text']}
              selectedItems={[]}
              onSelectedChange={(item: any) => {
                setSelectedOption(item);
              }}
              items={selectOptions}
              disabled={false}
              readOnly={false}
              hasMore={false}
              type={PICKLIST_TYPES.SINGLE}
            />
            <div className="my-5">
              <ActionButtons
                primaryButtonLabel={t('T_NEXT')}
                primaryButtonAction={() => {
                  setSelectedOption(formData);
                }}
                secondaryButtonLabel={t('T_CANCEL')}
                secondaryButtonAction={() => {
                  toggleVisibility(false);
                }}
                disabled={formData?.type ? false : true}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default CreateTemplateType;
