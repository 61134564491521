import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from '../shared/ModalPortal/Modal';
import BiButton from '../primitives/buttons/BiButton.primitive';

import { RouteConstants } from '../../constants/RouteConstants';
import { useTheme } from '../../context/themeContext';

import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import ToastNotification from '../Reusable/ToastNotification';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import TemplateTable from '../shared/tables/TemplateTable';

import { TableContainer } from '../Organizations/OrganizationsList';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import { AppConstants } from '../../constants/AppConstants';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { useTranslation } from 'react-i18next';
import {
  getChildOrganizations,
  getOrganization,
  getOrganizations
} from '../../http/organization-services';
import {
  getSystemSettings,
  getOrganizationSettings,
  getCategories,
  getUserSettings,
  getOrganizationCategories,
  deleteSystemSetting
} from '../../http/configuration-services';

import {
  getLoggedInOrg,
  getLoggedInOrg as getLoggedInUserOrg,
  getLoginUserRoles as getLoggedInUserRoles,
  getUserRoles
} from '../../util/admin-utils';
import ConfigKeyMenu from './ConfigKeyMenu';
import Filter from './Filter';
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  map,
  remove,
  unionBy,
  upperFirst,
  property,
  flatMap,
  isEmpty
} from 'lodash';
import { useDidMountEffect } from '../../util/customHooks';
import { setOrgSysSetting, setUserSysSetting } from '../../app/sysSettingReducer';
import {
  getConfigKeyOrg,
  configServiceFilters,
  setEmptyItemsList,
  updateOrgResponse,
  updateSelectedFilter,
  getUserLoggedInOrg
} from './Configutils';
import { MenuConstants } from '../../constants/MenuConstants';
import { getFailureMessage } from '../../util/ErrorUtil';

import { EllipsisDiv } from '../../styles/globalStyles';
import Checkbox from '../shared/Fields/Checkbox';
import { ActionButtons } from '../Reusable/ActionButton';
import { deleteManifestItem, setManifest } from '../../app/migrationExportReducer';
import { getOrganizationType } from '../../util/OrganizationUtil';
import { isGlobalSelected, removeOrgData, toggleGlobalCheckbox } from '../Migration/migrationUtil';
import { setGlobalTableFilters } from '../../app/tableReducer';
import { Group, Row } from '../shared/tables/TemplateTableStyles';
import { setTableOptions } from '../../app/templateTableReducer';

/**
 * @parentId configuration service
 * @manager Configuration Keys Listing
 * @overview
 *   <section>
 *       <p>
 *           Configuration keys listing is the default page when the user is navigated on clicking <b>Configuration Keys</b> navigation bar menuitem. Listing page will show all the global level configuration keys(system settings) in a paginated way.
 *           The default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & configuration keys data.
 *       </p>
 *       <br>
 *       <p>
 *           The user can also navigate to <b>Configuration Keys</b> through Organization screen, by click on context menu with option 'View Configurations' of organization name row item.
 *           If the user is navigated from Organization screen to <b>Configuration Keys</b>, the Configuration keys associated to organization will be listed.
 *       </p>
 *       <br>
 *       <p>
 *           The user can also navigate to <b>Configuration Keys</b> through Users screen, by click on context menu with option 'View Configurations' of User name row item.
 *           If the user is navigated from Users screen to <b>Configuration Keys</b>, the Configuration keys associated to user will be listed.
 *       </p>
 *       <br>
 *       <p>
 *          Following are the menu items listed under configuration keys <b>Manage Configuration Keys</b> drop down.
 *          <br>
 *          <ul>
 *            <li>Create System Setting</li>
 *            <li>Create Category</li>
 *            <li>View Categories</li>
 *          </ul>
 *       </p>
 *       <br>
 *        <p>
 *          Configuraion keys can be filtered based on,
 *          <ul>
 *              <li>
 *                  <b>Organization:</b>
 *                  <br>
 *                  <p>
 *                    Organiztion filter is a dropdown, which is single select, the dropdown lists the names of organizations, the user can scroll the list in dropdown to select the organization.
 *                    Or the user can search the organizaion list in the search box present in dropdown to get the list of organization names.
 *                    On apply of the filter the <b>Configuration keys</b> listed are associated to organization.
 *                  </p>
 *                </li>
 *              <li>
 *                  <b>Categories:</b>
 *                  <br>
 *                  <p>
 *                    Categories filter is a dropdown, by default 25 gloabl categories are listed and user can select only one category. But when the Organization filter is applied,
 *                    the categories drop down lists the categories associated with the organization and also the user can select multiple categories for filtering of Configuration Keys.
 *                  </p>
 *               </li>
 *              <li>
 *                <b>Data Types:</b>
 *                 <br>
 *                 <p>
 *                   Data Types is a dropdown, the list contains 'String', 'Integer', 'Float', 'Boolean', 'DateTime', 'Date', 'Json'.
 *                 </p>
 *              </li>
 *          </ul>
 *       </p>
 *       <br>
 *
 *        <p>
 *           Datagrid will display details of each Configuration Keys as row item with each column representing Configuration Keys property.
 *           <br/>
 *           <ul>
 *               <li>Key Name</li>
 *               <li>Data Type</li>
 *               <li>Allow Override</li>
 *               <li>Description</li>
 *               <li>Categories</li>
 *           </ul>
 *           <b>Note:</b> Delete action is shown in context menu based on logged in user role API permission to delete Configuration key. Actual deletion happens after user confirms on the deletion prompt.
 *       </p>
 *       <p>
 *           Sorting of Configuratin keys grid is supported on <i>Key Name</i>, <i>Data Type</i> column.The breadcrumb bar on the toolbar will reflect location of the user in the admin portal. Clicking on Configuration key rowitem will drill down user to Configuration key details page which will be displayed in readonly mode.
 *       </p>
 *     </section>
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes the List Configuration keys Status Code information.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>CS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>CS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of configuration keys listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for Configuraton Keys listing</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Configuration Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for listing configuration keys page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/system-settings</td>
 *               <td>GET</td>
 *               <td>configuration-service.setting.list</td>
 *               <td>Yes</td>
 *           </tr>
 *            <tr>
 *               <td>/categories</td>
 *               <td>GET</td>
 *               <td>configuration-service.category.list</td>
 *               <td>Yes</td>
 *           </tr>
 *            <tr>
 *               <td>/organizations/{organization-id}/categories</td>
 *               <td>GET</td>
 *               <td>configuration-service.organization-category.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{organization-id}/settings</td>
 *               <td>GET</td>
 *               <td>configuration-service.organization-setting.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for Configuration keys listing page</p>
 *   </section>
 */
interface ConfigKeyList {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const ConfigKeyList = ({ type = 'listing', toggleModal = () => {} }: ConfigKeyList) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toastData = useAppSelector((state) => state.toast);
  const queryParams = useParams();
  const [isUserFLow, setisUserFlow] = useState(queryParams.userId ? true : false);
  const [isOrgFlow, setisOrgFlow] = useState(queryParams.id ? true : false);
  const [isLoading, setIsLoading] = useState(true);
  const [sysSettings, setSysSettings] = useState<any>([]);
  const apiPermissions: any = useAppSelector((state) => state.initialLoadData?.apiPermissions);
  const userProfileInfo: any = useAppSelector((state) => state.userManagement.userProfileInfo);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedSysSetting, setSelectedSysSetting] = useState({ id: '', key: '' });
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedOrgItems, setSelectedOrgItems] = useState<any>([]);
  const [selectedCombined, setSelectedCombined] = useState<any>([]);
  const [currentActivePage, setCurrentActivePage] = useState(0);
  const manifestSettingData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.globalSettings
  );
  const manifestOrgSettingData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.globalSettingsOrg
  );

  // const globalFilters: any = useAppSelector((state) => state.tableSortSearchSlice.filters);
  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);
  const globalTableFilterRoute: any = useAppSelector((state) => state.tableSortSearchSlice.route);

  const [filterCall, setFilterCall] = useState(false);

  const [initialCall, setInitialCall] = useState(false);
  const resetTable = useRef(false);

  const setResetTable = (value: boolean) => {
    resetTable.current = value;
  };

  const [dependentFieldObj, setDependentFieldObj] = useState<any>({
    loadCategories: false,
    selectedItem: [],
    isDependentFieldEmpty: false
  });

  const [paramObj, setParamObj] = useState({
    orgFilter: '',
    categoriesFilter: '',
    dataTypeFilter: ''
  });
  const [tablePage, setTablePage] = useState({});

  const [loadNextData, setLoadNextData] = useState<any>({
    orgPaging: false,
    categoriesPaging: false,
    searchOrgName: '',
    searchOrg: false
  });

  const fetchMoreOrgData = () => {
    setLoadNextData((prevState: any) => ({ ...prevState, orgPaging: true }));
  };

  const fetchMoreCategoriesData = () => {
    setLoadNextData({ ...loadNextData, categoriesPaging: true });
  };

  const fetchDependentFields = (selectedItem: any) => {
    setDependentFieldObj({ ...dependentFieldObj, loadCategories: true, selectedItem });
  };

  const searchOrgData = (data: any) => {
    setEmptyItemsList('orgFilter', filterList, setFilterList);
    setLoadNextData((prevState: any) => ({ ...prevState, searchOrgName: data, searchOrg: true }));
  };

  const configKeyFilters = configServiceFilters.map((filter: any) => {
    if (filter.id === 'orgFilter') {
      return {
        ...filter,
        fetchMoreData: fetchMoreOrgData,
        fetchDependentFields: fetchDependentFields,
        searchData: searchOrgData,
        searchPlaceholder: t('T_SEARCH_ORG_NAME')
      };
    } else if (filter.id === 'categoriesFilter') {
      return {
        ...filter,
        fetchMoreData: fetchMoreCategoriesData
      };
    } else {
      return { ...filter };
    }
  });

  const getTableCellWidth = (header: any, isCategoryPresent: boolean) => {
    const { key, width } = header;
    let cellWidth = width;
    switch (key) {
      case 'key':
        cellWidth = isCategoryPresent ? (type === 'listing' ? '40%' : '50%') : '50%';
        break;
      case 'description':
        cellWidth = isCategoryPresent ? '25%' : '40%';
        break;
    }
    return cellWidth;
  };

  const contextMenuHandler = (key: string, data: any) => {
    if (key === MenuConstants.CONFIG_KEY_CATEGORY_DELETE) {
      checkDeleteConfirm(data);
    }
  };

  const isCheckboxSelected = (data: any) => {
    const { id, organizationId } = data;
    const orgFilter = find(filterList, { id: 'orgFilter' });

    if (orgFilter.selectedFilter.length) {
      let orgIndex = findIndex(selectedOrgItems, function (item: any) {
        return item.id === organizationId;
      });
      if (orgIndex !== -1) {
        return (
          findIndex(
            selectedOrgItems[orgIndex]?.dependentArtifactConfigurations?.configurations,
            function (item: any) {
              return item.id === data.id;
            }
          ) !== -1
        );
      } else {
        return false;
      }
    } else {
      return isGlobalSelected(selectedItems, data);
    }
  };

  const toggleOverWrite = (data: any) => {
    const { id, organizationId = '' } = data;
    const flag = organizationId.length > 0;

    let existingArr: any = cloneDeep(flag ? manifestOrgSettingData : manifestSettingData);
    let existingId = flag
      ? findIndex(existingArr, function (item: any) {
          return item.id === organizationId;
        })
      : findIndex(existingArr, function (item: any) {
          return item.id === id;
        });

    if (flag) {
      let categoryId = findIndex(
        existingArr[existingId]?.dependentArtifactConfigurations?.configurations,
        function (item: any) {
          return item.id === id;
        }
      );

      if (categoryId !== -1) {
        if (
          existingArr[existingId]?.dependentArtifactConfigurations?.configurations[categoryId]
            ?.overWrite
        ) {
          delete existingArr[existingId].dependentArtifactConfigurations.configurations[categoryId]
            .overWrite;
        } else {
          existingArr[existingId].dependentArtifactConfigurations.configurations[
            categoryId
          ].overWrite = true;
        }
        dispatch(setManifest({ key: 'globalSettingsOrg', value: existingArr }));
        return;
      }
    } else {
      if (existingId !== -1) {
        if (existingArr[existingId]?.overWrite) {
          delete existingArr[existingId].overWrite;
        } else {
          existingArr[existingId].overWrite = true;
        }

        dispatch(setManifest({ key: 'globalSettings', value: existingArr }));
        return;
      }
    }
  };

  const toggleCheckbox = (data: any) => {
    const { id, organizationId, key, categories = [], systemSettingId = '' } = data;
    const orgFilter = find(filterList, { id: 'orgFilter' });
    if (orgFilter.selectedFilter.length) {
      let [selectedOrg] = orgFilter.selectedFilter;
      const { name = '', parentId = '', active } = selectedOrg;
      let existingArr: any = cloneDeep(selectedOrgItems);
      let orgIndex = findIndex(existingArr, function (item: any) {
        return item.id === organizationId;
      });
      if (orgIndex !== -1) {
        let existingId = findIndex(
          existingArr[orgIndex]?.dependentArtifactConfigurations?.configurations,
          function (item: any) {
            return item.id === data.id;
          }
        );

        if (existingId !== -1) {
          existingArr[orgIndex]?.dependentArtifactConfigurations?.configurations.splice(
            existingId,
            1
          );

          setSelectedOrgItems(existingArr);
          return;
        } else {
          existingArr[orgIndex]?.dependentArtifactConfigurations?.configurations.push({
            id,
            key,
            categories,
            organizationId,
            orgName: selectedOrg.name
          });
        }
        setSelectedOrgItems(existingArr);
      } else {
        let payload = {
          id: organizationId,
          name,
          key: organizationId,
          parentId,
          status: active ? `${t('T_ACTIVE')}` : `${t('T_NOT_ACTIVE')}`,
          type: getOrganizationType(selectedOrg),
          dependentArtifactConfigurations: {
            configurations: [
              {
                id,
                key,
                categories,
                organizationId,
                orgName: selectedOrg.name
              }
            ]
          }
        };
        existingArr.push(payload);
        setSelectedOrgItems(existingArr);
      }
    } else {
      let arr = toggleGlobalCheckbox(selectedItems, data);
      setSelectedItems(arr);
    }
  };

  const setFilters = (appliedFilters: any) => {
    const { tableFilters, searchText, currentPage, size, sortName, sortType } =
      globalFilters['config-key-list'];
    dispatch(
      setTableOptions({
        ['config-key-list']: {
          searchText: searchText,
          currentPage: AppConstants.DEFAULT_PAGE,
          size: size,
          sortName: sortName,
          sortType: sortType,
          tableFilters: appliedFilters
        }
      })
    );
  };

  const configKeyHeadersDefault = () => {
    let headers: Array<ITableHeader> = [
      {
        key: 'key',
        description: 'T_CONFIG_KEY_NAME',
        sortEnabled: type !== 'migrationPreview',
        width: '',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        styles: {
          borderRight: 'none'
        }
      }
    ];

    switch (type) {
      case 'listing':
        headers.push(
          {
            key: 'options',
            description: '',
            sortEnabled: false,
            width: '4%',
            clickable: false,
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any) => {
              return (
                <div className="hover:bg-[#d9d9d9]" style={{ borderRadius: '10px' }}>
                  <ConfigKeyMenu
                    dropdownMenu={false}
                    onOptionClick={(key: string) => contextMenuHandler(key, data)}
                  />
                </div>
              );
            },
            styles: {
              paddingRight: '0',
              paddingLeft: '0',
              borderLeft: 'none',
              rowStyle: { padding: '0px' }
            }
          },
          {
            key: 'dataType',
            description: 'T_CONFIG_KEY_DATA_TYPE',
            sortEnabled: true,
            width: '8%',
            clickable: true,
            sortingInformation: {
              order: ''
            },
            render: (data: any) => {
              return <>{upperFirst(data.dataType)}</>;
            }
          },
          {
            key: 'allowOverride',
            description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
            sortEnabled: true,
            width: '10%',
            clickable: true,
            sortingInformation: {
              order: ''
            },
            render: (data: any) => {
              return <>{upperFirst(data.allowOverride)}</>;
            }
          },
          {
            key: 'description',
            description: 'T_CONFIG_KEY_DESCRIPTION',
            sortEnabled: true,
            width: '',
            clickable: true,
            sortingInformation: {
              order: ''
            },
            render: (data: any) => {
              return <EllipsisDiv title={data?.description}>{data?.description}</EllipsisDiv>;
            }
          }
        );
        break;

      case 'migrationSelection':
        headers.unshift({
          key: 'checked',
          description: '',
          sortEnabled: false,
          width: '10%',
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={isCheckboxSelected(data)}
                  onChange={() => {
                    toggleCheckbox(data);
                  }}
                  testID="addModuleCheckbox"
                />
              </>
            );
          }
        });
        break;
      case 'migrationPreview':
        if (manifestOrgSettingData.length) {
          headers.push({
            key: 'orgName',
            description: 'T_ORGANIZATION_NAME',
            sortEnabled: false,
            width: '25%',
            clickable: false,
            sortingInformation: {
              order: ''
            },
            render: (data: any) => {
              return <EllipsisDiv title={data?.orgName}>{data?.orgName}</EllipsisDiv>;
            }
          });
        }
        headers.push(
          {
            key: 'overWrite',
            description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
            sortEnabled: false,
            width: '15%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any, index: any) => {
              return (
                <>
                  <Checkbox
                    className="mx-auto"
                    height="20px"
                    width="20px"
                    onChange={() => {
                      toggleOverWrite(data);
                    }}
                    checked={data?.overWrite}
                    testID="allowOverWriteCheckbox"
                  />
                </>
              );
            }
          },
          {
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '13%',
            sortingInformation: {
              order: ''
            },
            styles: {
              justifyContent: 'center'
            }
          }
        );
        break;
    }

    return headers;
  };

  const getConfigKeyHeaders = (isCategoryPresent: boolean, configKeyHeaders: any) => {
    remove(configKeyHeaders, { key: 'categories' });

    const updatedConfigKeyHeaders = configKeyHeaders.map((header: any) => {
      return { ...header, width: getTableCellWidth(header, isCategoryPresent) };
    });
    if (isCategoryPresent) {
      let categoryObj = {
        key: 'categories',
        description: 'T_CONFIG_KEY_CATEGORIES',
        sortEnabled: false,
        width: type === 'listing' ? '25%' : '40%',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any, index: any) => {
          const { categories = [] } = data;
          return <>{map(categories, 'name').join(', ')}</>;
        }
      };

      if (type === 'migrationPreview') {
        updatedConfigKeyHeaders.splice(-2, 0, categoryObj);
      } else {
        updatedConfigKeyHeaders.push(categoryObj);
      }
    }

    if (!isCategoryPresent && type === 'migrationSelection') {
      let keyNameIndex = findIndex(updatedConfigKeyHeaders, function (item: any) {
        return item.key === 'key';
      });
      updatedConfigKeyHeaders[keyNameIndex].width = '100%';
    }

    return updatedConfigKeyHeaders;
  };
  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(
    getConfigKeyHeaders(!queryParams.id && !queryParams.userId, configKeyHeadersDefault())
  );
  const [filterList, setFilterList] = useState<Array<any>>(configKeyFilters);
  const [filterPillsData, setFilterPillsData] = useState<Array<any>>([]);

  const filterRef = useRef();
  const setFilterRef = (payload: any) => {
    filterRef.current = payload;
  };
  const loadOrgCategories = async () => {
    let initialFilterList: any = [...filterList];
    let categoriesResponse = await getConfigCategories();
    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'categoriesFilter') {
        return updateCategoriesResponse(obj, categoriesResponse);
      } else {
        return { ...obj };
      }
    });
    setFilterList(currentFilterList);
  };

  useDidMountEffect(async () => {
    if (dependentFieldObj.loadCategories) {
      setEmptyItemsList('categoriesFilter', filterList, setFilterList);
      const { selectedItem } = dependentFieldObj;
      const orgId = selectedItem.length && selectedItem[0].id;
      setPickListType(orgId ? true : false);
    }
  }, [dependentFieldObj]);

  useDidMountEffect(async () => {
    if (dependentFieldObj.loadCategories) {
      loadOrgCategories();
    }
  }, [filterList]);

  const loadOrgData = async () => {
    let orgResponse = await getConfigKeyOrg(loadNextData, filterList, userProfileInfo);

    let initialFilterList: any = [...filterList];
    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'orgFilter') {
        return updateOrgResponse(obj, orgResponse, loadNextData, setLoadNextData);
      } else {
        return { ...obj };
      }
    });

    setFilterList(currentFilterList);
    updateSelectedFilter(
      filterList,
      filterPillsData,
      queryParams.id,
      fetchMoreOrgData,
      setFilterPillsData,
      () => {} //setFilterList
    );
  };
  useDidMountEffect(() => {
    const { tableFilters, searchText, currentPage, size, sortName, sortType } =
      globalFilters['config-key-list'];
    applyFilters(searchText, currentPage, size, sortName, sortType, tableFilters);
  }, [globalFilters]);

  useDidMountEffect(async () => {
    if (loadNextData.categoriesPaging) {
      let categoriesResponse = await getConfigCategories();
      let initialFilterList: any = [...filterList];
      const currentFilterList = initialFilterList.map((obj: any) => {
        if (obj.id === 'categoriesFilter') {
          return updateCategoriesResponse(obj, categoriesResponse);
        } else {
          return { ...obj };
        }
      });
      setFilterList(currentFilterList);
    }
    if (loadNextData.orgPaging && !loadNextData.searchOrg) {
      loadOrgData();
    }
    if (loadNextData.searchOrg && !loadNextData.orgPaging) {
      loadOrgData();
    }
  }, [loadNextData]);

  useEffect(() => {
    const init = async () => {
      if (type !== 'migrationPreview') {
        // getConfigList();
        await setFilterData();
      }
    };

    init();

    dispatch(
      setURLPaths([
        { key: RouteConstants.ROUTE_CONFIGURATIONSERVICE_LIST, label: t('T_CONFIGURATION_KEYS') }
      ])
    );
    if (type !== 'listing') {
      dispatch(
        setTableOptions({
          ['config-key-list']: {
            searchText: '',
            currentPage: AppConstants.DEFAULT_PAGE,
            size: AppConstants.DEFAULT_PAGE_SIZE,
            sortName: '',
            sortType: '',
            tableFilters: { orgFilter: [] }
          }
        })
      );
    }

    return () => {
      if (filterRef.current) {
        dispatch(
          setGlobalTableFilters({
            filters: filterRef.current
          })
        );
      }
    };
  }, []);

  useDidMountEffect(() => {
    setIsLoading(true);
    //getConfigList();
  }, [paramObj]);

  const checkConfigPermission = (permissionType: string) => {
    let hasPermission = false;
    const permissionMap: any = {
      sysSettingList: AppConstants.PERMISSION_CONFIG_SERVICE_SETTING_LIST,
      orgsysSettingList: AppConstants.PERMISSION_CONFIG_SERVICE_ORG_SETTING_LIST,
      sysSettingUpdate: AppConstants.PERMISSION_CONFIG_KEY_SETTING_UPDATE,
      orgsysSettingUpdate: AppConstants.PERMISSION_CONFIG_KEY_SETTING_UPDATE
    };
    if (isOrgFlow) {
      if (apiPermissions[permissionMap[`org${permissionType}`]]) {
        hasPermission = true;
      }
    } else {
      if (apiPermissions[permissionMap[permissionType]]) {
        hasPermission = true;
      }
    }
    return hasPermission;
  };

  const checkDeleteConfirm = (selectedSysSetting: any) => {
    const isDeleteAllowed = !!apiPermissions[AppConstants.PERMISSION_CONFIG_SERVICE_SETTING_DELETE];

    if (isDeleteAllowed) {
      setSelectedSysSetting(selectedSysSetting);
      setDeleteConfirmation(true);
    } else {
      dispatch(
        setToastData({
          toastMessage: `${t('T_USER_NO_PERMISSION_ACTION_MESSAGE')}`,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const deleteSysSetting = async () => {
    setIsLoading(true);
    const { id: sysId } = selectedSysSetting;
    let response,
      error = false,
      toastMessage = `${t('T_SYSTEM_SETTING_DELETED')}`;
    response = await deleteSystemSetting(sysId);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      toastMessage = `${t('T_SYSTEM_SETTING_DELETED')}`;
    } else {
      error = !error;
      toastMessage = getFailureMessage(response);
    }
    setDeleteConfirmation(false);
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType: error ? 'error' : 'success'
      })
    );
    getConfigList();
  };

  // useDidMountEffect(() => {
  //   // getConfigList();
  // }, [paramObj]);

  useEffect(() => {
    if (globalFilters?.filters?.orgFilter?.length) {
      let [org] = globalFilters?.filters?.orgFilter;
      if (org?.id !== queryParams.id) {
        return;
      }
    }
    if (
      globalFilters?.filters &&
      !isEmpty(globalFilters?.filters) &&
      filterCall &&
      (location.pathname === globalTableFilterRoute ||
        `/${location.pathname.split('/').pop()}` === globalTableFilterRoute)
    ) {
      const { tableFilters, searchText, currentPage, size, sortName, sortType } =
        globalFilters['config-key-list'];
      applyFilters(searchText, currentPage, size, sortName, sortType, tableFilters);
    }
  }, [globalFilters?.filters, globalTableFilterRoute, filterCall]);

  const getConfigList = async (
    size: number = type === 'listing'
      ? AppConstants.DEFAULT_PAGE_SIZE
      : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
    currentPage: number = AppConstants.DEFAULT_PAGE,
    sortOrder: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC,
    filter: any = paramObj
  ) => {
    setIsLoading(true);
    const { categoriesFilter, dataTypeFilter, orgFilter } = filter;

    if (!checkConfigPermission('sysSettingList') && type === 'listing') {
      setIsLoading(false);

      return;
    }
    let response;
    if (queryParams.id || orgFilter) {
      //setTableHeaders(getConfigKeyheaders(false));
      setTableHeaders((prevState) => getConfigKeyHeaders(false, prevState));
      response = await getOrganizationSettings(
        queryParams.id || orgFilter,
        categoriesFilter,
        currentPage,
        size,
        [`${sortOrder},${sortType}`]
      );
    } else if (queryParams.userId) {
      setTableHeaders((prevState) => getConfigKeyHeaders(false, prevState));
      //setTableHeaders(getConfigKeyheaders(false));

      response = await getUserSettings(queryParams.userId, categoriesFilter, currentPage, size, [
        `${sortOrder},${sortType}`
      ]);
    } else {
      setTableHeaders((prevState) => getConfigKeyHeaders(true, prevState));
      //setTableHeaders(getConfigKeyheaders(true));

      response = await getSystemSettings(categoriesFilter, dataTypeFilter, currentPage, size, [
        `${sortOrder},${sortType}`
      ]);
    }
    const { status, data } = response;

    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: settingList = [], paging } = data;
      setTablePage(paging);
      if (settingList.length) {
        setSysSettings(settingList);
      } else {
        setSysSettings(`${t('T_NO_DATA_AVAILABLE_MSG')}`);
      }
    } else {
      // TODO - Need to show an error page
    }
    setIsLoading(false);
  };

  const updateCategoriesResponse = (obj: any, categoriesResponse: any) => {
    const { selectedItem, loadCategories } = dependentFieldObj;
    const orgId = selectedItem.length && selectedItem[0].id;
    if (categoriesResponse) {
      let categoriesList, pagingResponse;
      if (orgId) {
        categoriesList = categoriesResponse;
        pagingResponse = {
          next: null
        };
      } else {
        categoriesList = categoriesResponse.data;
        pagingResponse = categoriesResponse.paging;
      }
      //let { data: categoriesList, paging: pagingResponse } = categoriesResponse;
      let { paging: nextpagingObj } = obj;
      const { size, page } = pagingResponse;
      if (pagingResponse.next) {
        nextpagingObj = { page: page + 1, size };
      }
      setLoadNextData({ ...loadNextData, categoriesPaging: false });
      setDependentFieldObj({ ...dependentFieldObj, loadCategories: false });
      return {
        ...obj,
        itemsList: loadCategories
          ? [...categoriesList].sort((a: any, b: any) => a.name.localeCompare(b.name))
          : [...obj.itemsList, ...categoriesList],
        paging: nextpagingObj,
        hasMore: !!pagingResponse.next
      };
    }
  };

  const setFilterData = async () => {
    let [categoriesResponse, orgResponse, loggedInOrgResponse] = await Promise.all([
      getConfigCategories(),
      getConfigKeyOrg(loadNextData, filterList, userProfileInfo),
      getUserLoggedInOrg(userProfileInfo)
    ]);
    if (orgResponse && orgResponse.data) {
      orgResponse.data = [...orgResponse.data, loggedInOrgResponse];
      orgResponse.data = orgResponse.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
    }

    let initialFilterList: any = [...filterList];

    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'orgFilter') {
        return updateOrgResponse(obj, orgResponse, loadNextData, setLoadNextData);
      } else if (obj.id === 'categoriesFilter') {
        return updateCategoriesResponse(obj, categoriesResponse);
      } else {
        return { ...obj };
      }
    });
    setFilterList(currentFilterList);
    const {
      tableFilters = { orgFilter: [] },
      searchText = '',
      currentPage: filterCurrentPage = AppConstants.DEFAULT_PAGE,
      size: filterSize = AppConstants.DEFAULT_PAGE_SIZE,
      sortName = AppConstants.DEFAULT_SORT_BY,
      sortType = AppConstants.DEFAULT_SORT_DESC
    } = globalFilters['config-key-list'] || {};

    dispatch(
      setTableOptions({
        ['config-key-list']: {
          searchText: searchText,
          currentPage: filterCurrentPage,
          size: filterSize,
          sortName: sortName,
          sortType: sortType,
          tableFilters: tableFilters
        }
      })
    );

    updateSelectedFilter(
      filterList,
      [],
      queryParams.id,
      fetchMoreOrgData,
      setFilterPillsData,
      setFilterList,
      () => {
        if (!filterCall) {
          setFilterCall(true);
        }
      }
    );
  };

  useDidMountEffect(() => {
    if (!filterCall && filterPillsData.length) {
      setFilterCall(true);
    }
  }, [filterPillsData]);

  const setPickListType = (isMulti: boolean) => {
    let initialFilterList: any = [...filterList];
    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'categoriesFilter') {
        return { ...obj, type: isMulti ? 'multi' : 'single' };
      } else {
        return { ...obj };
      }
    });
    //setFilterList((prevState: any) => [...prevState, ...currentFilterList]);
    setFilterList(currentFilterList);
  };

  const getConfigCategories = async () => {
    const { selectedItem, loadCategories } = dependentFieldObj;
    const orgId = selectedItem.length && selectedItem[0].id;
    //setPicklistType(orgId ? true : false);
    const { paging } = find(filterList, { id: 'categoriesFilter' });
    const { size, page } = !loadCategories ? paging : { size: 25, page: 1 };
    let response;
    if (orgId) {
      response = await getOrganizationCategories(orgId, page, size, [`name,asc`]);
    } else {
      response = await getCategories('', page, size, [`name,asc`]);
    }

    const { status, data: categoriesResp } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data, paging } = categoriesResp;
      if ((data && data.length) || orgId) {
        return categoriesResp;
      }
    }
  };

  const updateParamObj = (appliedFilters: any, appliedFilterList: any) => {
    const initialParamObj: any = { ...paramObj };
    const initialFilterList: any = [...filterList];
    initialFilterList.forEach((filterObj: any) => {
      switch (filterObj.id) {
        case 'categoriesFilter':
          initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'name').join(',');
          break;
        case 'orgFilter':
          initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'id').join(',');
          break;
        case 'dataTypeFilter':
          initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'key').join(',');
          break;
      }
    });

    return initialParamObj;
  };

  const updateFilterListSelected = (appliedFilters: any) => {
    const initialFilterList: any = [...filterList];
    const updatedFilterList = initialFilterList.map((filterObj: any) => {
      const selectedFilter = appliedFilters[filterObj.id] ? appliedFilters[filterObj.id] : [];
      return { ...filterObj, selectedFilter };
    });

    return updatedFilterList;
  };

  const updatePillsData = (appliedFilters: any, appliedFilterList: any) => {
    const pillsData: any = [];
    if (appliedFilterList && appliedFilterList.length) {
      appliedFilterList.forEach((key: any) => {
        switch (key) {
          case 'categoriesFilter':
            appliedFilters[key].forEach((item: any) => {
              pillsData.push({ name: item.name, label: 'Category', id: key });
            });
            break;
          case 'orgFilter':
            appliedFilters[key].forEach((item: any) => {
              pillsData.push({
                name: item.name,
                label: 'Organization',
                id: key
                //hideIcon: queryParams.id ? true : false
              });
            });
            break;
          case 'dataTypeFilter':
            appliedFilters[key].forEach((item: any) => {
              pillsData.push({ name: item.key, label: 'DataType', id: key });
            });
            break;
        }
      });
    }

    return pillsData;
  };

  const updatePillsDataAfterRemovePills = (updatedFilterList: any) => {
    const pillsData: any = [];
    updatedFilterList.forEach((obj: any) => {
      switch (obj.id) {
        case 'categoriesFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({ name: item.name, label: 'Category', id: obj.id });
          });
          break;
        case 'orgFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({
              name: item.name,
              label: 'Organization',
              id: obj.id
              // hideIcon: queryParams.id ? true : false
            });
          });
          break;
        case 'dataTypeFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({ name: item.key, label: 'DataType', id: obj.id });
          });
          break;
      }
    });
    setFilterPillsData(pillsData);
  };

  const updateParamObjAfterremovePills = (updatedFilterList: any) => {
    const initialParamObj: any = { ...paramObj };
    updatedFilterList.forEach((obj: any) => {
      initialParamObj[obj.id] = obj.selectedFilter;
      switch (obj.id) {
        case 'categoriesFilter':
          initialParamObj[obj.id] = map(obj.selectedFilter, 'name').join(',');
          break;
        case 'orgFilter':
          initialParamObj[obj.id] = map(obj.selectedFilter, 'id').join(',');
          break;
        case 'dataTypeFilter':
          initialParamObj[obj.id] = map(obj.selectedFilter, 'key').join(',');
          break;
      }
    });
    setParamObj(initialParamObj);
    getConfigList(
      type === 'listing'
        ? AppConstants.DEFAULT_PAGE_SIZE
        : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
      AppConstants.DEFAULT_PAGE,
      AppConstants.DEFAULT_SORT_BY,
      AppConstants.DEFAULT_SORT_DESC,
      initialParamObj
    );
  };

  const updateCategoriesFilter = (initialFilterList: any) => {
    return initialFilterList.map((obj: any) => {
      if (obj.id === 'categoriesFilter') {
        return { ...obj, selectedFilter: [], type: 'single' };
      } else {
        return { ...obj };
      }
    });
  };

  const removeFromPillsFilter = (pillsFilterObj: any) => {
    let initialFilterList: any = [...filterList];
    const initialFilterObj = find(initialFilterList, { id: pillsFilterObj.id });
    const { tableFilters } = globalFilters['config-key-list'];
    const initialTableFilters = {...tableFilters};
    if (initialFilterObj.id === 'orgFilter' || initialFilterObj.id === 'dataTypeFilter') {
      initialFilterObj.selectedFilter = [];
      fetchDependentFields([]);
      //setTableHeaders(getConfigKeyheaders(true));
      setTableHeaders((prevState) => getConfigKeyHeaders(true, prevState));
      initialTableFilters[initialFilterObj.id] = [];
      if (queryParams.id) {
        navigate('/config-key-list');
      }
    } else {
      initialFilterObj.selectedFilter = filter(initialFilterObj.selectedFilter, (obj) => {
        return obj.name !== pillsFilterObj.name;
      });
      initialTableFilters[initialFilterObj.id] = initialFilterObj.selectedFilter;
    }
    dispatch(
      setTableOptions({
        ['config-key-list']: {
          ...globalFilters['config-key-list'],
          tableFilters: initialTableFilters
        }
      })
    );
    if (initialFilterObj.id === 'orgFilter') {
      initialFilterList = updateCategoriesFilter(initialFilterList);
    }
    const updatedFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === pillsFilterObj.id) {
        return { ...obj, initialFilterObj };
      } else {
        return { ...obj };
      }
    });
    updateParamObjAfterremovePills(updatedFilterList);
    updatePillsDataAfterRemovePills(updatedFilterList);
    setFilterList(updatedFilterList);
  };

  const applyFilters = (
    search: string = '',
    page: number,
    size: number,
    sortOrder: string = '',
    sortType: string = '',
    filters: any
  ) => {
    setFilterRef(filters);
    const appliedFilterList = Object.keys(filters);

    if (type === 'migrationSelection') {
      //To re render in case of migration flow
      setSelectedItems([...selectedItems]);
      setSelectedOrgItems([...selectedOrgItems]);
    }
    if (filters['orgFilter'] && filters['orgFilter'].length) {
      //setTableHeaders(getConfigKeyheaders(false));
      setTableHeaders((prevState) => getConfigKeyHeaders(false, prevState));
    } else {
      //setTableHeaders(getConfigKeyheaders(true));
      setTableHeaders((prevState) => getConfigKeyHeaders(true, prevState));
    }
    setFilterPillsData(updatePillsData(filters, appliedFilterList));
    setFilterList(updateFilterListSelected(filters));
    setParamObj(updateParamObj(filters, appliedFilterList));
    getConfigList(
      type === 'listing' ? size : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
      page,
      sortOrder,
      sortType,
      updateParamObj(filters, appliedFilterList)
    );
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sortOrder: string = '',
    sortType: string = '',
    filters: any
  ) => {
    if (sortOrder && sortType) {
      setTableValues(name, page, size, sortOrder, sortType, filters);
    } else {
      setTableValues(name, page, size, 'createdOn', 'desc', filters);
    }
  };

  const setTableValues = async (
    name: string,
    page: number,
    size: number,
    sortOrder: string,
    sortType: string,
    filters: any
  ) => {
    let localParamObj = cloneDeep(paramObj);
    if (!initialCall && filters?.filters && !isEmpty(filters?.filters)) {
      localParamObj = updateParamObj(filters.filters, Object.keys(filters.filters));
    }
    setInitialCall(true);
    setIsLoading(true);
  };

  const navigateToDetails = (data: any) => {
    const { id: orgId, userId } = queryParams;
    const { orgFilter } = paramObj;
    if (orgId || orgFilter) {
      dispatch(setOrgSysSetting(data));
      navigate(`/organization/${orgId || orgFilter}/config-key/edit/${data.systemSettingId}`);
    } else if (userId) {
      dispatch(setUserSysSetting(data));
      navigate(`/users/${userId}/config-key/edit/${data.id}`);
    } else {
      navigate(`/config-key/edit/${data.id}`);
    }
  };

  const configkeySelector = (data: any) => {
    if (type === 'listing') {
      checkConfigPermission('sysSettingUpdate')
        ? navigateToDetails(data)
        : dispatch(
            setToastData({
              toastMessage: t('T_CONFIG_PERMISSION_KEY_ERROR'),
              isToastActive: true,
              toastType: 'error'
            })
          );
    }
  };

  useEffect(() => {
    setSelectedItems(manifestSettingData);
  }, [manifestSettingData]);

  useEffect(() => {
    if (manifestOrgSettingData?.length) {
      setSelectedOrgItems(manifestOrgSettingData);
    }
  }, [manifestOrgSettingData]);

  useEffect(() => {
    const { categoriesFilter, dataTypeFilter, orgFilter } = paramObj;
    if (type === 'migrationSelection') {
      setTableHeaders(getConfigKeyHeaders(!orgFilter, configKeyHeadersDefault()));
    } else if (type === 'migrationPreview') {
      const mappedOrgItems = selectedOrgItems.length
        ? flatMap(map(selectedOrgItems, property('dependentArtifactConfigurations.configurations')))
        : [];

      let mergedItems = [...selectedItems, ...mappedOrgItems];

      setTableHeaders(getConfigKeyHeaders(!orgFilter, configKeyHeadersDefault()));
      setSysSettings(mergedItems);
      setIsLoading(false);
    }
  }, [selectedItems, selectedOrgItems]);

  const saveSelection = () => {
    dispatch(setManifest({ key: 'globalSettings', value: selectedItems }));
    if (selectedOrgItems.length) {
      dispatch(setManifest({ key: 'globalSettingsOrg', value: selectedOrgItems }));
    }
    toggleModal();
  };

  const deleteItem = (index: any, currentActivePage: any) => {
    if (type === 'migrationPreview') {
      let sysSetting = sysSettings[index + currentActivePage];
      const { organizationId = '', id } = sysSetting;
      if (organizationId) {
        let arr: any = removeOrgData(selectedOrgItems, sysSetting, 'configurations');
        dispatch(setManifest({ key: 'globalSettingsOrg', value: arr }));
      } else {
        dispatch(
          deleteManifestItem({
            key: 'globalSettings',
            id: id
          })
        );
      }
    }
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      {deleteConfirmation && type === 'listing' && (
        <Modal
          overflowVisible={true}
          showCloseIcon={true}
          width="30rem"
          showHeader={true}
          headerText={t('T_SETTING_DELETE_CONFIRMATION_HEADER')}
          toggleVisiblity={(value: boolean) => {
            setDeleteConfirmation(false);
          }}
          isVisible={deleteConfirmation}
          body={
            <>
              <div className="mb-5 flex flex-col">
                {/* <div className="">{`${t('T_SETTING_DELETE_CONFIRMATION')} (${
                  selectedSysSetting.key
                })`}</div> */}
                <div className="">{t('T_SETTING_DELETE_CONFIRMATION')}</div>
              </div>

              <ModalButton>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    setDeleteConfirmation(false);
                  }}
                >
                  {t('T_CANCEL')}
                </BiButton>
                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    deleteSysSetting();
                  }}
                  // disabled={!permissions.create}
                >
                  {t('T_CATEGORY_DELETE_CONFIRMATION_PROMPT_YES')}
                </BiButton>
              </ModalButton>
            </>
          }
        />
      )}
      <TableContainer className={`${type !== 'listing' && '!pb-0'}`}>
        {type === 'listing' ? (
          <MenuButtonsPortal>
            <ConfigKeyMenu dropdownMenu={true} />
          </MenuButtonsPortal>
        ) : (
          ''
        )}

        <div className="h-full">
          <div
            className={`w-full pb-10 ${type !== 'listing' && '!pb-0'}`}
            style={{ height: 'inherit' }}
          >
            {type !== 'migrationPreview' ? (
              <Row justifyContent="flex-start" className="float-left">
                <Group width="" marginBottom="0">
                  <Filter
                    filtersList={filterList}
                    applyFilters={setFilters}
                    pillsData={filterPillsData}
                    removeFilter={removeFromPillsFilter}
                    defaultPillsLabel={t('T_CONFIG_FILTERS_DEFAULT_VIEW')}
                  />
                </Group>
              </Row>
            ) : (
              ''
            )}

            <TemplateTable
              tableData={
                type === 'migrationPreview'
                  ? sysSettings.slice(
                      currentActivePage,
                      currentActivePage + AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
                    )
                  : sysSettings
              }
              onRefreshTableData={refreshTableData}
              size={
                type === 'migrationPreview'
                  ? AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
                  : globalFilters?.size?.toString()
              }
              currentPage={
                type === 'migrationPreview' ? currentActivePage : globalFilters?.currentPage
              }
              setCurrentPage={(page: any) => {
                setCurrentActivePage(parseInt(page));
              }}
              cellSelector={configkeySelector}
              templateSelector={configkeySelector}
              tableHeaders={tableHeaders}
              setTableHeaders={setTableHeaders}
              pagingData={tablePage}
              containerClass="mt-0"
              isSearchable={false}
              showDeleteButton={true}
              globalFilters={globalFilters}
              theme={useTheme().theme}
              tablePage="config-key-list"
              isLocalPaginator={type === 'migrationPreview'}
              totalDataLength={sysSettings.length}
              isPaginated={type !== 'migrationPreview'}
              disablePageSize={type !== 'listing'}
              deleteOnClick={deleteItem}
            />
          </div>
        </div>
      </TableContainer>
      {type === 'migrationSelection' ? (
        <div className="w-full">
          <ActionButtons
            primaryButtonLabel={t('T_SAVE')}
            primaryButtonAction={saveSelection}
            secondaryButtonLabel={t('T_CANCEL')}
            secondaryButtonAction={toggleModal}
            primaryButtonWidth="full"
            primaryButtonClass={'px-4'}
          />
        </div>
      ) : (
        ''
      )}
    </LoaderContainer>
  );
};

export const ModalButton = styled('div')`
  display: flex;
  justify-content: end;
`;

export default ConfigKeyList;
