import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DotMenu, { Options } from '../primitives/dotMenu/DotMenu';
import { MenuConstants } from '../../constants/MenuConstants';
import ActionDropdown from '../primitives/dropdowns/ActionDropdown';

interface ConfigMenuProps {
  className?: string;
  iconSize?: string;
  dropdownMenu?: boolean;
  onOptionClick?: Function;
}

const ConfigKeyMenu: React.FC<ConfigMenuProps> = ({
  iconSize = '',
  className = '',
  dropdownMenu = false,
  onOptionClick
}) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<Options | null>();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedOption) {
      switch (selectedOption.key) {
        case MenuConstants.CONFIG_KEY_CREATE_SETTINGS:
          navigate('/config-key/create');
          break;
        case MenuConstants.CONFIG_KEY_VIEW_CATEGORIES:
          navigate(`/config-category-list`);
          break;
        case MenuConstants.CONFIG_KEY_CREATE_NEW_CATEGORY:
          navigate(`/config-category/create`);
          break;
        case MenuConstants.CONFIG_KEY_CATEGORY_DELETE:
          if (onOptionClick) {
            onOptionClick(MenuConstants.CONFIG_KEY_CATEGORY_DELETE);
          }
          break;
      }
    }
  }, [selectedOption]);

  const actionDropDownOptions: Array<any> = [
    {
      display: `${t('T_CREATE_SETTINGS')}`,
      key: MenuConstants.CONFIG_KEY_CREATE_SETTINGS
    },
    {
      display: `${t('T_VIEW_CATEGORIES')}`,
      key: MenuConstants.CONFIG_KEY_VIEW_CATEGORIES
    },
    {
      display: `${t('T_CREATE_NEW_CATEGORY')}`,
      key: MenuConstants.CONFIG_KEY_CREATE_NEW_CATEGORY
    }
  ];

  const dotMenuOptions: Array<any> = [
    {
      display: `${t('T_DELETE')}`,
      key: MenuConstants.CONFIG_KEY_CATEGORY_DELETE
    }
  ];

  return (
    <>
      {dropdownMenu ? (
        <ActionDropdown
          options={actionDropDownOptions}
          onSelectedOptionChange={(value: any) => {
            setSelectedOption(value);
          }}
          value={selectedOption}
          defaultLabel={t('T_CONFIG_MANAGE_KEYS')}
        />
      ) : (
        <DotMenu
          options={dotMenuOptions}
          onSelectedOptionChange={(value) => {
            setSelectedOption(value);
          }}
          value={selectedOption}
        />
      )}
    </>
  );
};

export default ConfigKeyMenu;
