import { forwardRef, memo, useEffect } from 'react';
// import SunEditor from 'suneditor-react';
import JoditEditor, { Jodit } from 'jodit-react';
// import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
interface HtmlEditor {
  htmlContent?: any;
  onChange?: any;
  height?: string;
  disabled?: boolean;
  htmlRef: any;
  ref?: any;
  setEmailBodyError: Function;
}
const HtmlEditor = forwardRef(
  ({ htmlContent, height, disabled, htmlRef, onChange, setEmailBodyError }: HtmlEditor, ref) => {
    const config: any = {
      placeholder: '',
      height: height ? height : '250px',
      readonly: disabled,
      hidePoweredByJodit: true,
      removeButtons: ['about']
    };
    return (
      <div>
        {/* <SunEditor
          htmlEditorRef={htmlEditorRef}
          name="htmlEditor"
          setContents={htmlContent}
          toggleCodeView={isCodeEditor}
          disable={disabled}
          hideToolbar={disabled}
          onChange={handleChange}
          // setDefaultStyle="font-family: cursive; font-size: 16px;"
          setOptions={{
            height: height ? `calc(80vh - ${height})` : '250px',
            attributesWhitelist: {
              all: 'style'
            },
            buttonList: [
              ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
              ['link', 'showBlocks', 'preview', 'fullScreen', 'codeView'],
              // '/', // Line break
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
              ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'list', 'table']
            ]
          }}
        /> */}
        <JoditEditor
          ref={htmlRef}
          value={htmlContent}
          config={config}
          onBlur={(newContent) => {
            setTimeout(() => onChange(newContent), 0);
          }}
          onChange={(html) => {
            if (html.length && html !== '<p><br></p>') {
              setEmailBodyError(false);
            } else {
              setEmailBodyError(true);
            }
          }}
        />
      </div>
    );
  }
);
export default memo(HtmlEditor);
