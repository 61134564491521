import React from 'react';
import Modal from '../shared/ModalPortal/Modal';
import { useTranslation } from 'react-i18next';
import { ModalButton } from '../../styles/globalVariables';
import BiButton from '../primitives/buttons/BiButton.primitive';

interface DeleteConfirmModalProps {
  width?: string;
  show?: boolean;
  className?: string;
  showCloseIco?: boolean;
  titleClassName?: string;
  cancelClassName?: string;
  confirmClassName?: string;
  cancelBtnText?: string;
  confirmBtnText?: string;

  title: string;
  children: JSX.Element;
  onClose: Function;
  onConfirm: Function;
  onCancel: Function;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  show = true,
  title,
  width = '30rem',
  titleClassName = '',
  children,
  cancelBtnText,
  cancelClassName = '',
  confirmBtnText,
  confirmClassName = '',
  className = '',
  onClose,
  onConfirm,
  onCancel
}: DeleteConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      overflowVisible={true}
      showCloseIcon={true}
      width={width}
      showHeader={!!title}
      headerText={title}
      headerStyles={titleClassName}
      toggleVisiblity={(value: boolean) => {
        onClose(false);
      }}
      isVisible={show}
      body={
        <>
          <div className="mb-5 flex flex-col">
            <div className={className}>{children}</div>
          </div>

          <ModalButton>
            <BiButton
              className={`${cancelClassName || 'mr-2 text-primary'}`}
              type="button"
              onClick={() => {
                onCancel();
              }}
            >
              {cancelBtnText || t('T_CANCEL')}
            </BiButton>
            <BiButton
              className={`${confirmClassName || 'bg-primary text-white'}`}
              type="button"
              onClick={() => {
                onConfirm();
              }}
            >
              {confirmBtnText || t('T_DELETE')}
            </BiButton>
          </ModalButton>
        </>
      }
    />
  );
};
export default React.memo(DeleteConfirmModal);
