import React, { useEffect, useState } from 'react';
import Modal from '../../shared/ModalPortal/Modal';
import styled from 'styled-components';
import { AppConstants } from '../../../constants/AppConstants';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TemplateTable from '../../shared/tables/TemplateTable';
import { ITableHeader } from '../../shared/tables/models/ITableHeader';
import Checkbox from '../../shared/Fields/Checkbox';
import { Label, Input } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';
import RadioButton from '../../shared/Fields/RadioButton';
import { forEach, isEmpty } from 'lodash';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import cloneDeep from 'lodash/cloneDeep';
import checkCircle from '../../../assets/checkCircle.svg';
import dropdown from '../../../assets/dropdown.svg';
import { API_TO_QUESTION_MAPPING } from './TemplateDetail';
import { getQuestionTypeLabel } from '../../../util/surveyUtils';
import { useTranslation } from 'react-i18next';
import groupQuestionIcon from '../../../assets/groupquestion.svg';
import Card from '../../shared/cards/Card';
import Likert from '../Question/AnswerDetailComponents/Likert';
import MediaQuestion from '../Question/AnswerDetailComponents/Media';
import PreviewBodyMap from '../Question/AnswerDetailComponents/PreviewBodyMap';

interface PreviewQuestionDialogProps {
  questions: any;
  closePopup: Function;
}

const Container = styled.div`
  padding: 1rem;
  width: 100%;
  background-color: #f5f5f5;
  padding: 0px 2.5rem 0px 2.5rem;
  min-height: 25vh;
  max-height: 75vh;
  overflow: auto;
`;

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

const booleanRenderOptions: any = [
  { type: 'boolean', value: 'true' },
  { type: 'boolean', value: 'false' }
];

const PreviewQuestionDialog = ({ questions, closePopup }: PreviewQuestionDialogProps) => {
  const { t } = useTranslation();
  const [singleOption, setSingleOption] = useState<any>('');
  const [multiOptions, setMultiOptions] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [selections, setSelections] = useState<any>({});
  const questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);

  useEffect(() => {
    let toSet: any = {};

    const setInitialOptions = (questionItem: any) => {
      questionItem.forEach((item: any) => {
        if (item.item?.length) {
          setInitialOptions(item.item);
        }
        toSet[item.id] = ['multi', 'choice'].includes(item.apiType) ? [] : '';

        if (item?.answerOption?.length) {
          item.answerOption.forEach((answer: any) => {
            if (answer.initialSelected) {
              if (['multi', 'choice'].includes(item.apiType)) {
                toSet[item.id].push(answer.value.display || answer.value);
              } else {
                toSet[item.id] = answer.value.value || answer.value;
              }
            }
          });
        }

        if (item?.initial?.length) {
          item?.initial.forEach((ans: any) => {
            toSet[item.id] = ans?.value?.value || ans;
          });
        }
      });
    };
    setInitialOptions(questions);
    setSelections(toSet);
    setLoading(false);
  }, []);

  const setCheckbox = (id: string, item: string) => {
    if (selections[id].includes(item)) {
      let filteredArray = selections[id].filter((value: any) => item !== value);
      setSelections({ ...selections, [id]: [...filteredArray] });
    } else {
      setSelections({
        ...selections,
        [id]: [...selections[id], item]
      });
    }
  };

  const getLikertAnswerOptions = (answerOption: any, ele: any) => {
    const answerOptions: any = {};
    if (answerOption.length) {
      answerOption.forEach((answer: any, index: any) => {
        answerOptions[`option${index + 1}`] = {
          value: answer.value.display ? answer.value.display : answer.value,
          isSelected: answer.initialSelected
        };
      });
    }
    return answerOptions;
  };

  const getUpdateExtObj = (ele: any) => {
    const updateextObj = { ...ele };
    const { extension = {}, answerOption } = updateextObj;
    updateextObj.answerOptions = getLikertAnswerOptions(answerOption, ele);
    updateextObj.extension =
      !isEmpty(extension) && extension.object ? JSON.parse(extension.object) : extension;
    return updateextObj;
  };

  const getChoiceTypePreview = (ele: any) => {
    const { displayType } = ele;
    switch (displayType) {
      case 'multi':
      case 'single':
        return ele?.answerOption?.map((item: any, key: number) => {
          return (
            <div
              className={`mb-1.5 flex w-full flex-row px-1.5 py-2  ${
                item.initialSelected && 'items-center justify-between '
              }`}
              style={{ background: item.initialSelected ? '#F5F7FA' : 'unset' }}
            >
              {displayType === 'multi' && (
                <div className="flex flex-row ">
                  <Checkbox
                    height="20px"
                    width="20px"
                    disabled={ele.readOnly}
                    checked={selections[ele.id]?.includes(item.value.display || item.value)}
                    onChange={() => setCheckbox(ele.id, item.value.display || item.value)}
                  />
                  <span className="ml-2.5 text-sm font-normal">
                    {item.value.display || item.value}
                  </span>
                </div>
              )}
              {displayType === 'single' && (
                <RadioButton
                  className="font-normal"
                  fontSize="14px"
                  fontColor="#262626"
                  selectedField={
                    selections[ele.id]?.display || (selections[ele.id] && selections[ele.id].length)
                      ? selections[ele.id][0]
                      : ''
                  }
                  option={item.value.display || item.value}
                  label={item.value.display || item.value}
                  disabled={ele.readOnly}
                  name={``}
                  onClick={() => {
                    setSelections({
                      ...selections,
                      [ele.id]: [item.value.display || item.value]
                    });
                  }}
                ></RadioButton>
              )}
              {item.initialSelected && (
                <div className="flex flex-row">
                  <img src={checkCircle} alt="default" className="mr-1.5 w-3.5" />
                  <span className="text-xs font-semibold" style={{ color: '#595959' }}>
                    {t('T_SURVEY_DEFAULT_SELECTION')}
                  </span>
                </div>
              )}
            </div>
          );
        });
        break;
      case 'likert':
      case 'likertIconsWithLabels':
      case 'likertIcons':
        return (
          <Likert
            formData={getUpdateExtObj(ele)}
            setFormData={() => {}}
            formError={{}}
            editFromSurveyTemplate={true}
            isPreview={true}
          ></Likert>
        );
        break;
      case 'bodyMap':
      case 'bodyMapSingle':
      case 'bodyMapMulti':
        return (
          <PreviewBodyMap
            formData={getUpdateExtObj(ele)}
            setFormData={() => {}}
            formError={{}}
            editFromSurveyTemplate={true}
            isPreview={true}
          ></PreviewBodyMap>
        );
        break;
    }
  };

  const getUrlType = (ele: any) => {
    const { displayType } = ele;
    switch (displayType) {
      case 'url':
        return (
          <input
            id="content"
            disabled={ele.readOnly}
            className="w-full rounded-md border border-solid border-gray-300 px-3 py-3"
            value={selections[ele.id]}
            onChange={(e) => setSelections({ ...selections, [ele.id]: e.target.value })}
          />
        );
        break;
      case 'media':
        return (
          <MediaQuestion
            formData={getUpdateExtObj(ele)}
            setFormData={() => {}}
            formError={{}}
            editFromSurveyTemplate={true}
            isPreview={true}
          ></MediaQuestion>
        );
        break;
    }
  };

  const renderOptions = (ele: any) => {
    const { apiType, type } = ele;
    switch (apiType) {
      case 'text':
        if (ele?.answerOption?.length) {
          return ele?.answerOption?.map((item: any, key: number) => {
            return (
              <div
                className={`mb-1.5 flex w-full flex-row px-1.5 pb-2  ${
                  item.initialSelected && 'items-center justify-between '
                }`}
                style={{ background: item.initialSelected ? '#F5F7FA' : 'unset' }}
              >
                <RadioButton
                  className="font-normal"
                  fontSize="14px"
                  fontColor="#262626"
                  selectedField={selections[ele.id]}
                  option={item.value.value || item.value}
                  label={item.value.value || item.value}
                  disabled={ele.readOnly}
                  name={``}
                  onClick={() => {
                    setSelections({
                      ...selections,
                      [ele.id]: item.value.value || item.value
                    });
                  }}
                ></RadioButton>
                {item.initialSelected && (
                  <div className="flex flex-row">
                    <img src={checkCircle} alt="default" className="mr-1.5 w-3.5" />
                    <span className="text-xs font-semibold" style={{ color: '#595959' }}>
                      {t('T_SURVEY_DEFAULT_SELECTION')}
                    </span>
                  </div>
                )}
              </div>
            );
          });
        } else {
          return (
            <textarea
              id="content"
              disabled={ele.readOnly}
              className="w-full rounded-md border border-solid border-gray-300 px-4 pt-4"
              rows={2}
              value={selections[ele.id]}
              onChange={(e) => setSelections({ ...selections, [ele.id]: e.target.value })}
            />
          );
        }
        break;
      case 'boolean':
        return booleanRenderOptions.map((item: any, key: number) => {
          return (
            <div
              className={`mb-1.5 flex w-full flex-row px-1.5 py-2  ${
                ele.initial.some((o: any) => (o?.value?.value || o) === item.value) &&
                'items-center justify-between '
              }`}
              style={{
                background: ele.initial.some((o: any) => (o?.value?.value || o) === item.value)
                  ? '#F5F7FA'
                  : 'unset'
              }}
            >
              <RadioButton
                className="font-normal"
                fontSize="14px"
                fontColor="#262626"
                selectedField={selections[ele.id]}
                option={item.value}
                label={item.value === 'true' ? 'Yes' : 'No'}
                name={``}
                disabled={ele.readOnly}
                onClick={() => {
                  setSelections({
                    ...selections,
                    [ele.id]: item.value
                  });
                }}
              ></RadioButton>
              {ele.initial.some((o: any) => (o?.value?.value || o) === item.value) && (
                <div className="flex flex-row">
                  <img src={checkCircle} alt="default" className="mr-1.5 w-3.5" />
                  <span className="text-xs font-semibold" style={{ color: '#595959' }}>
                    {t('T_SURVEY_DEFAULT_SELECTION')}
                  </span>
                </div>
              )}
            </div>
          );
        });

        break;
      case 'multi':
      case 'choice':
        return getChoiceTypePreview(ele);
        break;
      case 'date':
      case 'time':
      case 'dateTime':
      case 'datetime':
      case 'display':
        return (
          <div className="flex flex-row">
            <span className="text-xs font-semibold" style={{ color: '#595959' }}>
              {ele.type === 'display'
                ? ele.text
                : selections[ele.id]
                ? selections[ele.id]
                : ele.type === 'date'
                ? 'User will select date in YYYY-MM-DD format'
                : ele.type === 'time'
                ? 'User will select time in HH:mm format'
                : ele.type === 'dateTime'
                ? 'User will select time in YYYY-MM-DD HH:mm format'
                : ''}
            </span>
            {ele.type !== 'display' && (
              <img src={dropdown} alt="default" className="ml-1.5 w-3.5" />
            )}
          </div>
        );
        break;
      case 'url':
        return getUrlType(ele);
        break;
      case 'integer':
      case 'decimal':
        return (
          <input
            id="content"
            disabled={ele.readOnly}
            className="w-1/5 rounded-md border border-solid border-gray-300 px-3 py-3"
            value={selections[ele.id]}
            type="number"
            step={ele.type === 'decimal' ? 0.01 : 1}
            min={0}
            max={999999}
            onChange={(e: any) => setSelections({ ...selections, [ele.id]: e.target.value })}
          />
        );
        break;
      default:
        return <></>;
    }
  };

  const getEnableWhenConditionsText = (ele: any) => {
    const condLogicVal: any = {
      all: 'AND',
      any: 'OR'
    };
    const { enableWhen, enableBehavior } = ele;
    if (enableWhen && enableWhen.length && enableWhen[0] !== null) {
      const noteText =
        enableWhen.length >= 1
          ? `If ${enableBehavior} of the following condition satisfies for below mentioned questions, this question will be enabled.`
          : '';
      const enableQuetextArr: any = [];
      enableWhen.forEach((condition: any) => {
        const { question, operator, selectedOption } = condition;
        const { text } = question;
        const { value } = selectedOption;
        const { value: operatorValue } = operator[0];
        enableQuetextArr.push(`Question -  ${text} ${operatorValue} ${value}`);
      });

      return (
        <>
          <span>{`Note: Question gets enabled if:`}</span>
          <ol type="a">
            {enableQuetextArr.map((text: any, index: any) => {
              return (
                <ListItem>{`${text} ${
                  index !== enableQuetextArr.length - 1
                    ? condLogicVal[enableBehavior.toLowerCase()]
                    : ''
                }`}</ListItem>
              );
            })}
          </ol>
        </>
      );
    }
  };

  const previewQuestionCard = (ele: any, index: any) => {
    return (
      <div className="mb-2 flex flex-col overflow-y-auto bg-white px-8 py-5">
        <span className="text-xs font-semibold capitalize" style={{ color: '#8C8C8C' }}>
          {/* {API_TO_QUESTION_MAPPING[ele.type]
        ? API_TO_QUESTION_MAPPING[ele.type] === 'single' &&
          !ele.answerOption.length
          ? 'Free Text'
          : API_TO_QUESTION_MAPPING[ele.type]
        : ele.type} */}
          {getQuestionTypeLabel(ele.apiType, ele.displayType, questionTypes, ele.type)}
          {ele.readOnly && ` | ${t('T_SURVEY_READ_ONLY')}`}
        </span>

        <Label className="mt-2 text-base font-normal">
          {ele.required ? <Image src={Asterix} className="mr-1" /> : ''}
          {index}. {ele.question || ele.text}
        </Label>
        <div className="mt-2">{renderOptions(ele)}</div>
        {ele.enableWhen && ele.enableWhen.length && (
          <div className="mt-2">
            <span className="text-xs font-semibold" style={{ color: '#8C8C8C' }}>
              {getEnableWhenConditionsText(ele)}
            </span>
          </div>
        )}
      </div>
    );
  };

  const previewGroupQuestionCard = (ele: any, index: any) => {
    const { text: questionName = '', description = '', item: questions = [] } = ele || {};

    return (
      <Card
        title={'Group Question'}
        icon={groupQuestionIcon}
        alt="groupQuestionIcon"
        height=""
        width=""
        className="!rounded-lg border border-solid !border-[#d9d9d9] bg-[#FAFAFA]"
        headingClass={'bg-[#F5F7FA] text-[#4575B4]'}
      >
        <div className="w-4/5 text-[#262626]">
          <div className="text-base font-medium">
            {index}. {questionName}
          </div>
          <div className="mt-2.5 text-base font-normal">{description}</div>
        </div>
        <div>
          {questions.map((item: any, i: number) => {
            return previewQuestionCard(item, `${index}.${i + 1}`);
          })}
        </div>
      </Card>
    );
  };

  return (
    <>
      <LoaderContainer isLoading={loading}>
        <Modal
          showHeader={true}
          showCloseIcon={true}
          width="75rem"
          headerText={t('T_SURVEY_PREVIEW_QUESTIONS')}
          toggleVisiblity={(value: boolean) => {
            closePopup(value);
          }}
          className="overflow-y-hidden"
          isVisible={true}
          headerBackgroundColor="#F5F5F5"
          containerBackgroundColor="#F5F5F5"
          body={
            !loading && (
              <Container>
                <div className="mb-4 text-sm font-normal" style={{ color: '#595959' }}>
                  {t('T_SURVEY_DIFF_UI_INFO')}
                </div>
                {questions.map((ele: any, index: number) => {
                  const { type = '' } = ele || {};
                  return type === 'group'
                    ? previewGroupQuestionCard(ele, index + 1)
                    : previewQuestionCard(ele, index + 1);
                })}
              </Container>
            )
          }
        />
      </LoaderContainer>
    </>
  );
};

export default PreviewQuestionDialog;

const ListItem = styled('li')`
  list-style: inside;
`;
