import styled from 'styled-components';

export type ListItemProps = {
  type?: string;
};

export type DropListProps = {
  isDropTop?: boolean;
};

export const DropDownHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const options = ['10', '20'];

export const DropDownListContainer = styled('div')``;

export const DropDownList = styled('ul')<DropListProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0px;
  position: absolute;
  min-width: 120px;
  z-index: 1;
  left: 0;
  top: ${({ isDropTop }) => (isDropTop ? '-75px' : '45px')};
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
`;

export const ListItem = styled('li')<ListItemProps>`
  list-style: none;
  width: 77px;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  cursor: pointer;
  width: 100%;
  // margin: ${({ type }) => (type === 'menuItem' ? '9px 10px' : '0px 10px')};
  padding: ${({ type }) => (type === 'menuItem' ? '9px 10px' : '0px 10px')};
`;
