import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { AppConstants } from '../../constants/AppConstants';
import { useTranslation } from 'react-i18next';
import BiButton from '../primitives/buttons/BiButton.primitive';
import FilterIcon from '../../assets/filter.svg';
import { close, resetIcon } from '../../assets/components/svgs';
import PillsButton from '../shared/Fields/PillsButton';
import { compact, isArray, upperFirst, find, filter } from 'lodash';
import { removeArrayElement } from '../../util/consent-utils';
import useOutsideClick from '../../hooks/useOutsideClick';
import { Label } from '../AccountProfile/ProfileStyles';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';

export interface filterConfigProps {
  type: string;
  name: string;
}

interface TemplateFilterProps {
  filterConfig: filterConfigProps;
  setFilterConfig: Function;
}

const defaultFilter = {
  type: '',
  name: ''
};

const TemplateFilter = ({ filterConfig, setFilterConfig }: TemplateFilterProps) => {
  let disableDismiss = false;
  const { t } = useTranslation();
  const selectRef = useRef(null);

  const [filter, setFilter] = useState(false);

  const [formFilterData, setFormFilterData] = useState<any>(defaultFilter);

  const [formError, setFormError] = useState<any>({
    type: '',
    name: ''
  });

  const [pills, setPills] = useState<any>([]);

  useEffect(() => {
    setFormFilterData(filterConfig);
  }, [filter]);

  useEffect(() => {
    setFormFilterData(filterConfig);
  }, [filterConfig]);

  useEffect(() => {
    setPillsData();
  }, [formFilterData]);

  const options = [
    { key: 'email', value: t('T_EMAIL') },
    { key: 'sms', value: t('T_SMS') },
    { key: 'push_notification', value: t('T_PUSH') }
  ];

  const applyFilter = () => {
    setFilterConfig(formFilterData);
    setFilter(false);
  };

  useOutsideClick(
    selectRef,
    () => {
      if (filter && !disableDismiss) {
        setFilter(!filter);
        return;
      }
      disableDismiss = false;
    },
    false
  );

  const TypeFilters = () => {
    return (
      <div className="h-[200px]">
        <div className="flex">
          <BiButton
            className={'ml-auto flex py-[6px] text-primary'}
            type="button"
            disabled={false}
            onClick={() => {
              setFormFilterData(defaultFilter);
              setFormError({ type: '', name: '' });
            }}
          >
            <span className="mr-1 pt-[4px]">{resetIcon}</span>
            {t('T_RESET_FILTER')}
          </BiButton>
          <BiButton
            className={'ml-5 bg-primary py-[6px] text-white'}
            type="button"
            disabled={!Object.keys(formError).every((k) => !formError[k])}
            onClick={() => {
              applyFilter();
            }}
          >
            {t('T_APPLY_FILTER')}
          </BiButton>
          <div
            className="ml-9 w-fit cursor-pointer pt-[8px]"
            onClick={() => {
              setFilter(!filter);
            }}
          >
            {close}
          </div>
        </div>

        <div className="flex w-full pt-[30px]">
          <div className="mr-2 w-full">
            <Label>{t('T_STATUS')}</Label>
            <PickList
              fieldKey={'key'}
              fieldValues={['label']}
              selectedItems={formFilterData['type']}
              onSelectedChange={(items: any) => {
                disableDismiss = true;
                setFormFilterData({ ...formFilterData, ['type']: items });
              }}
              items={
                options.map((ele: any) => {
                  const { key, value } = ele;
                  return { key, value, label: value };
                }) || []
              }
              disabled={false}
              readOnly={false}
              type={PICKLIST_TYPES.SINGLE}
            />
          </div>
        </div>
      </div>
    );
  };

  const setPillsData = () => {
    let t: Array<any> = [];
    Object.keys(formFilterData).map((ele: any) => {
      if (formFilterData[ele]) {
        if (isArray(formFilterData[ele])) {
          formFilterData[ele].map((item: any) => {
            t.push({
              key: ele,
              value: item,
              label: upperFirst(ele),
              name: upperFirst(item.value)
            });
          });
        } else {
          t.push({
            key: ele,
            value: ele,
            label: upperFirst(ele),
            name: upperFirst(formFilterData[ele])
          });
        }
      }
    });

    setPills(t);
  };

  const removePill = (ele: any) => {
    const { key, value } = ele;
    const oldFilterData = {...formFilterData};
    let val: any = '';
    if (isArray(oldFilterData[key])) {
      val = removeArrayElement(value, oldFilterData[key]);
    }
    let filterData = { ...oldFilterData, [ele.key]: val };
    setFormFilterData(filterData);
    setFilterConfig(filterData);
  };

  return (
    <>
      <div className="flex absolute pt-[10px] leading-[30px]">
        <div className="my-auto min-w-[6rem]">
          <BiButton
            className={
              'mr-2 flex border border-solid border-[#99B0C6] !py-[6px] text-[12px] font-semibold leading-[15px]'
            }
            type="button"
            disabled={false}
            onClick={() => {
              setFilter(true);
            }}
            style={{ padding: '0 5px' }}
          >
            <img src={FilterIcon} alt={'filter'} className="mr-1" />
            {t('T_FILTER')}
          </BiButton>
        </div>
        <div className="w-full">
          {pills.length ? (
            <div className="-mt-[5px]" style={filter ? { pointerEvents: 'none' } : {}}>
              <PillsButton
                formData={formFilterData}
                setFormData={setFormFilterData}
                rows={2}
                pillsData={pills || []}
                removeFilter={removePill}
              ></PillsButton>
            </div>
          ) : (
            <span className="text-[12px] font-normal">
              {t('T_DEFAULT_ALL_NOTIFICATION_TEMPLATE')}
            </span>
          )}
        </div>
      </div>

      {filter && (
        <div
          ref={selectRef}
          style={{
            display: filter ? 'block' : 'none',
            filter: 'drop-shadow(0px 25px 50px rgba(31, 41, 55, 0.25))',
            border: '1px solid #D9D9D9',
            height: '200px',
            zIndex: '9'
          }}
          className="absolute ml-5 w-[30rem] rounded-lg bg-white p-5"
        >
          {TypeFilters()}
        </div>
      )}
    </>
  );
};

export default TemplateFilter;
