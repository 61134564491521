import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BiButton from '../../primitives/buttons/BiButton.primitive';
import SelectField from '../../shared/Fields/SelectField';
import PickList, { PICKLIST_TYPES } from '../../shared/Fields/PickList';

import Modal from '../../shared/ModalPortal/Modal';

import { ActionButtons } from '../../PermissionSet/NewPermissionSet';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setQuestionType } from '../../../app/surveyReducer';
import {
  date,
  time,
  datetime,
  text,
  likert,
  multi,
  url,
  media,
  single,
  display,
  boolean,
  integer,
  decimal,
  plusSign
} from '../../../assets/components/svgs';
import { AppConstants } from '../../../constants/AppConstants';
import { setQueTypeIcons } from './QueUtils';
import AddCircle from '../../../assets/addCircle.svg';

const CreateQuestion = ({ selectOptions, selectOptionsIcons, selectionObj, queList }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [selectedQue, setSelectedQue] = useState<any>([]);
  const [queListWithIcons, setQueListWithIcons] = useState([]);
  const [formData, setFormData] = useState<any>({
    type: ''
  });
  const [formError, setFormError] = useState<any>({});

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  useEffect(() => {
    if (queList && queList.length) {
      setQueListWithIcons(setQueTypeIcons(queList));
    }
  }, [queList]);

  const setSelectedOption = () => {
    const questionType = selectedQue.length ? selectedQue[0].type : '';
    navigate(`/survey-question/new?type=${questionType}`);
    dispatch(setQuestionType(questionType));
  };

  return (
    <>
      {roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_CREATE] && (
        <BiButton
          className={'flex flex-row bg-primary text-white'}
          type="button"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <img src={AddCircle} className="mr-2 w-6 text-center"></img>
          {t('T_BUTTON_NEW_QUESTION')}
        </BiButton>
      )}
      <Modal
        showHeader={true}
        showCloseIcon={true}
        width="27rem"
        headerText={t('T_QUESTION_MODAL_HEADER')}
        toggleVisiblity={(value: boolean) => {
          setOpenModal(false);
          setFormData({ type: '' });
        }}
        isVisible={openModal}
        overflowVisible={true}
        className="bottom-16"
        body={
          <div>
            <div>{t('T_QUESTION_MODAL_QUESTION')}</div>
            {/* <SelectField
              type="text"
              id="questionType"
              label=""
              placeholder={''}
              fieldKey={'type'}
              required={false}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              options={selectOptions}
              icon={selectOptionsIcons}
              maxHeight={true}
              fontSize={'16px'}
            /> */}

            <PickList
              fieldKey={'type'}
              required={false}
              fieldValues={['label']}
              selectedItems={selectedQue}
              onSelectedChange={(item: any) => {
                setSelectedQue(item);
              }}
              items={queListWithIcons}
              type={PICKLIST_TYPES.SINGLE}
              disabled={false}
              readOnly={false}
            />

            <div className="my-5">
              <ActionButtons
                primaryButtonLabel={t('T_NEXT')}
                primaryButtonAction={() => {
                  setSelectedOption();
                }}
                secondaryButtonLabel={t('T_CANCEL')}
                secondaryButtonAction={() => {
                  setOpenModal(false);
                  setSelectedQue([]);
                }}
                disabled={selectedQue.length ? false : true}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default CreateQuestion;
