import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import Card from '../shared/cards/Card';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { debounce, find, findIndex, isEqual, isFunction, isUndefined } from 'lodash';
import { RouteConstants } from '../../constants/RouteConstants';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import LockIcon from '../../assets/lock-alt.svg';
import Asterix from '../../assets/asterix.svg';
import { CalcButton, Operation, SubCard } from '../Roles/RoleUIElements';
import {
  createPasswordPolicy,
  deletePasswordPolicy,
  getPasswordPolicy,
  updatePasswordPolicy
} from '../../http/user-management-services';
import { resetPasswordPolicy, setPasswordPolicy } from '../../app/passwdPolicyReducer';
import InputField from '../shared/Fields/InputField';
import NoPermissionMessage from '../RoutePermission/NoPermissionMessage';
import { ExclamationIcon } from '@heroicons/react/outline';
import Modal from '../shared/ModalPortal/Modal';

/**
 * @parentId passwordpolicy
 * @manager Create, View, Edit - Password Policy
 * @overview Create, View, Edit of Password Policy
 *   <section>
 *       <p>
 *           <b>View Password Policy</b><br/>
 *           Clicking on password policy row on the password policy listing page will take user to View Password Policy Page in READ-ONLY mode. Clicking on <b>Back to Password Policies</b> will take the user to password policy listing page.
 *           <br/><br/>
 *           <b>Edit Password Policy</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Password policy name , Password Complexity, Password Expiration & Password History</b> fields. Clicking on <b>Cancel</b> will take the user to password policy listing page.
 *           <br/><br/>
 *           <b>Create Password Policy</b><br/>
 *           Clicking on <b>New Password Policy</b> button on password policy listing will take user to New Password Policy creation page. Clicking on <b>Cancel</b> will take the user to password policy listing page.
 *           <br/><br/>
 *           User can create new password policy by entering <b>Password policy name</b> mandatory field and clicking on <b> Save </b> button on the admin portal toolbar.
 *           <b>Password Complexity</b> & <b>Password Expiration</b> & <b>Password History</b> are additional non mandatory fields which user can use to enter more detail about the password policy.
 *
 *
 *       <p>
 *          <h4>View/Create/Edit Password Policy </h4>
 *          Create/View/Edit password policy will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Password policy name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Minimum number of characters</td>
 *               <td>Yes</td>
 *               <td>Integer</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Minimum special character(s)</td>
 *               <td>N/A</td>
 *               <td>Integer</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Minimum number(s)</td>
 *               <td>N/A</td>
 *               <td>Integer</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Minimum lowercase letter(s)</td>
 *               <td>N/A</td>
 *               <td>Integer</td>
 *               <td>N/A</td>
 *            </tr>
 *             <tr>
 *               <td>Minimum uppercase letter(s)</td>
 *               <td>N/A</td>
 *               <td>Integer</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Password Expiration Days</td>
 *               <td>N/A</td>
 *               <td>Integer</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Password History</td>
 *               <td>N/A</td>
 *               <td>Integer</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Password Policy Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>UMS_AUTH_REQUIRED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *
 *           <tr>
 *               <td>500</td>
 *               <td>UMS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create password policy page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit password policy.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating password policy page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Password Policy</td>
 *               <td>/password-policies</td>
 *               <td>GET</td>
 *               <td>user-management-service.password-policy.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit password Policy</td>
 *               <td>/password-policies/{id}</td>
 *               <td>PATCH</td>
 *               <td>user-management-service.password-policy.patch</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create password Policy</td>
 *               <td>/password-policies</td>
 *               <td>POST</td>
 *               <td>user-management-service.password-policy.create</td>
 *               <td>Yes</td>
 *           </tr>
 *
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create password policy</p>
 *   </section>
 */

interface Props {
  setNewDataLoaded?: Function;
  buttonActions?: Function;
}
const PasswordPolicy = ({ setNewDataLoaded, buttonActions = () => {} }: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageError, setPageError] = useState<string>('');
  const [isCreate, setIsCreate] = useState(!params?.id);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const selectedPasswdPolicy = useAppSelector((state) => state.passwordPolicy.passwordPolicy);
  const apiPermisions: any = useAppSelector((state) => state.initialLoadData?.apiPermissions) || {};
  const configDefaults: any = useAppSelector(
    (state: any) => state.configuration.solutionConfigurationDefaults || {}
  );

  const isModalMode = isFunction(setNewDataLoaded);

  const { passwordPolicy = {} } = configDefaults;
  const {
    pwdMaxAge = AppConstants.MINIMUM_PASSWORD_EXPIRY,
    pwdInHistory = AppConstants.MINIMUM_PASSWORD_HISTORY,
    pwdMinLength = AppConstants.DEFAULT_MIN_PASSWORD_LENGTH,
    characterSets = {}
  } = passwordPolicy;
  const { specical, numeric, lowercase, uppercase } = characterSets;

  const passwordComplexitySchema: Array<any> = [
    {
      id: 'passwordComplexityCharacterNumbers',
      count: pwdMinLength,
      apiKey: function () {
        return this.count;
      },
      name: 'T_MINIMUM_NO_OF_CHARS',
      required: true
    },
    {
      id: 'passwordComplexitySpecialCharacters',
      count: isUndefined(specical?.minimum)
        ? AppConstants.DEFAULT_SPECICAL_CHARS_IN_PASSWORD
        : specical?.minimum,
      allowedChar: '~!@#$%^&*()-_=+[]{}|;:,.<>/?',
      apiKey: function () {
        return `${this.count}:${this.allowedChar}`;
      },
      name: 'T_MINIMUM_NO_OF_SPECIAL_CHARS',
      required: false
    },
    {
      id: 'passwordComplexityNumbers',
      count: isUndefined(numeric?.minimum)
        ? AppConstants.DEFAULT_NUMERIC_CHARS_IN_PASSWORD
        : numeric?.minimum,
      allowedChar: '0123456789',
      apiKey: function () {
        return `${this.count}:${this.allowedChar}`;
      },
      name: 'T_MINIMUM_NO_OF_NUMBERS',
      required: false
    },
    {
      id: 'passwordComplexityLowercase',
      count: isUndefined(lowercase?.minimum)
        ? AppConstants.DEFAULT_LOWERCASE_CHARS_IN_PASSWORD
        : lowercase?.minimum,
      allowedChar: 'abcdefghijklmnopqrstuvwxyz',
      apiKey: function () {
        return `${this.count}:${this.allowedChar}`;
      },
      name: 'T_MINIMUM_NO_OF_LOWERCASE',
      required: false
    },
    {
      id: 'passwordComplexityUppercase',
      count: isUndefined(uppercase?.minimum)
        ? AppConstants.DEFAULT_UPPERCASE_CHARS_IN_PASSWORD
        : uppercase?.minimum,
      allowedChar: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      apiKey: function () {
        return `${this.count}:${this.allowedChar}`;
      },
      name: 'T_MINIMUM_NO_OF_UPPERCASE',
      required: false
    }
  ];

  const [passwordComplexity, setPasswordComplexity] = useState(passwordComplexitySchema);

  const [formData, setFormData] = useState<any>({
    name: '',
    passwordExpiry: { focus: false, value: pwdMaxAge || AppConstants.DEFAULT_MAX_PASSWORD_EXPIRY },
    passwordHistory: {
      focus: false,
      value: pwdInHistory || AppConstants.DEFAULT_MIN_PASSWORD_HISTORY
    },
    passwordComplexityCharacterNumbers: { focus: false, value: pwdMinLength },
    passwordComplexitySpecialCharacters: {
      focus: false,
      value: passwordComplexitySchema[1]?.count
    },
    passwordComplexityNumbers: {
      focus: false,
      value: passwordComplexitySchema[2]?.count
    },
    passwordComplexityLowercase: {
      focus: false,
      value: passwordComplexitySchema[3]?.count
    },
    passwordComplexityUppercase: {
      focus: false,
      value: passwordComplexitySchema[4]?.count
    }
  });

  const [formError, setFormError] = useState<any>({
    name: ''
  });

  useEffect(() => {
    const init = async () => {
      if (params?.id) {
        if (!selectedPasswdPolicy) {
          setIsLoading(true);
          const response: any = await getPasswordPolicy(
            '',
            AppConstants.DEFAULT_PAGE,
            AppConstants.MAXIMUM_PAGE_SIZE
          );
          const { status, data } = response;
          if (HTTP_STATUS.isSuccess(status)) {
            const { data: passwdPolicies = [] } = data;
            const filteredPasswdPolicy: Array<any> = passwdPolicies.filter(
              (passwdPolicy: any) => passwdPolicy.id === params?.id
            );
            if (filteredPasswdPolicy.length) {
              dispatch(setPasswordPolicy(filteredPasswdPolicy[0]));
            }
          } else {
            setPageError(getFailureMessage(response));
          }
          setIsLoading(false);
        }
      } else {
        setIsEdit(true);
        setIsCreate(true);
        dispatch(
          setURLPaths([
            {
              key: RouteConstants.ROUTE_UMS_PASSWORD_POLICY_CREATE,
              label: `${t('T_PASSWORD_POLICIES')} > ${t('T_NEW_PASSWORD_POLICY')}`
            }
          ])
        );
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (!params?.id || params?.id === undefined) {
      dispatch(resetPasswordPolicy());
      return;
    }
    if (!selectedPasswdPolicy) {
      return;
    }
    dispatch(
      setURLPaths([
        {
          key: RouteConstants.ROUTE_UMS_PASSWORD_POLICY_VIEW.replace(':id', params.id),
          label: `${t('T_PASSWORD_POLICIES')} > ${selectedPasswdPolicy?.name}`
        }
      ])
    );
    const {
      name,
      characterSets = [],
      pwdInHistory,
      pwdMaxAge,
      pwdMinLength
    } = selectedPasswdPolicy;
    setFormData({
      name,
      passwordExpiry: { focus: true, value: pwdMaxAge },
      passwordHistory: { focus: true, value: pwdInHistory },
      passwordComplexityCharacterNumbers: { focus: true, value: pwdMinLength },
      passwordComplexitySpecialCharacters: {
        focus: true,
        value: getCharaterSetCount(characterSets, passwordComplexitySchema[1].allowedChar)
      },
      passwordComplexityNumbers: {
        focus: true,
        value: getCharaterSetCount(characterSets, passwordComplexitySchema[2].allowedChar)
      },
      passwordComplexityLowercase: {
        focus: true,
        value: getCharaterSetCount(characterSets, passwordComplexitySchema[3].allowedChar)
      },
      passwordComplexityUppercase: {
        focus: true,
        value: getCharaterSetCount(characterSets, passwordComplexitySchema[4].allowedChar)
      }
    });
  }, [selectedPasswdPolicy]);

  const getCharaterSetCount = (passwordComplexity: Array<string> = [], pattern: String = '') => {
    let value: number = 0;
    if (passwordComplexity.length) {
      const matchToken: Array<string> = passwordComplexity.filter((item: string) => {
        const tokens: Array<string> = item.split(/\d:/);
        if (tokens.length > 1) {
          return isEqual(tokens[1], pattern);
        }
        return false;
      });
      if (matchToken.length) {
        value = Number(matchToken[0].split(':')[0]);
      }
    }
    return value;
  };

  const removePasswordPolicy = async () => {
    setConfirmDelete(false);
    setIsLoading(true);
    const response: any = await deletePasswordPolicy(selectedPasswdPolicy?.id);
    if (!HTTP_STATUS.isSuccess(response?.status)) {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const getCancelButtonText = () => {
    if (isEdit) {
      return t('T_CANCEL');
    }
    return t('T_BACK_TO_PASSWORD_POLICIES');
  };

  const handleOnCancel = () => {
    setIsEdit(false);
  };

  const getPwdPolicyCharacterSet = (): Array<string> => {
    const characterSet: Array<string> = [];
    passwordComplexity.forEach((element: any) => {
      const { id } = element;
      if (id !== 'passwordComplexityCharacterNumbers') {
        characterSet.push(`${formData[id]?.value}:${element.allowedChar}`);
      }
    });
    return characterSet;
  };

  const createPayload = (): any => {
    return {
      name: formData.name,
      pwdInHistory: formData.passwordHistory.value,
      pwdMaxAge: formData.passwordExpiry.value,
      pwdMinLength: formData.passwordComplexityCharacterNumbers.value,
      characterSets: getPwdPolicyCharacterSet()
    };
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const payload: any = createPayload();
      let response;
      if (params?.id) {
        response = await updatePasswordPolicy(params?.id, payload);
      } else {
        response = await createPasswordPolicy(payload);
      }
      let toastType: string = 'success';
      let toastMessage: string = t('T_PASSWORD_POLICY_CREATE_SUCCESS');
      const success: boolean = [HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(
        response?.status
      );
      if (!success) {
        toastType = 'error';
        toastMessage = getFailureMessage(response);
      } else {
        if (setNewDataLoaded) {
          setNewDataLoaded(response.data);
          return;
        }
        if (params?.id) {
          setIsEdit(false);
          toastMessage = t('T_PASSWORD_POLICY_UPDATE_SUCCESS').replace('%s', formData.name);
        } else {
          if (!isModalMode) navigate(RouteConstants.ROUTE_UMS_PASSWORD_POLICY_LIST);
        }
      }
      dispatch(
        setToastData({
          toastMessage,
          isToastActive: true,
          toastType
        })
      );
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const disableSave = (): boolean => {
    if (!selectedPasswdPolicy) {
      return !!formError.name;
    }
    const payload: any = createPayload();
    const clonedPasswdPolicy: any = { ...selectedPasswdPolicy, characterSets };
    delete clonedPasswdPolicy.id;

    return isEqual(clonedPasswdPolicy, payload);
  };

  const debouncedSearch = useRef(
    debounce(async (filter: string = '') => {
      let name: string = '';
      if (filter) {
        const response = await getPasswordPolicy(
          filter,
          AppConstants.DEFAULT_PAGE,
          AppConstants.DEFAULT_PAGE_SIZE
        );

        const { status, data: appResponse } = response;
        if (HTTP_STATUS.isSuccess(status)) {
          const { data: pwdPolices = [] } = appResponse;
          if (pwdPolices.length) {
            const filteredPasswdPolicies: Array<any> = pwdPolices.filter((pwdPolicy: any) =>
              isEqual(pwdPolicy?.name?.toLowerCase(), filter?.toLowerCase())
            );
            if (filteredPasswdPolicies.length) {
              name = t('T_PASSWORD_POLICY_ALREADY_EXISTS');
            }
          }
        }
      }
      setFormError({ name });
    }, 400)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleChange = (key: string, value: string = '') => {
    let error: string = '';
    switch (key) {
      case AppConstants.QUERY_PARAM_NAME:
        if (!value) {
          error = t('T_PASSWORD_POLICY_CANNOT_BE_EMPTY');
        } else {
          if (!isEqual(value, selectedPasswdPolicy?.name)) {
            debouncedSearch(value);
          }
        }
        break;
    }
    setFormData({ ...formData, [key]: value });
    setFormError({ ...formError, [key]: error });
  };

  const manualCalc = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const clonedValues: any = { ...formData };
    const newValue: number = e.target.value ? parseInt(e.target.value, 10) : 0;
    if (id === 'passwordHistory' && newValue >= pwdInHistory) {
      clonedValues.passwordHistory.value = newValue;
    } else if (id === 'passwordExpiry' && newValue >= pwdMaxAge) {
      clonedValues.passwordExpiry.value = newValue;
    } else {
      const index: number = findIndex(passwordComplexitySchema, { id });
      if (index != -1 && newValue >= passwordComplexitySchema[index].count) {
        clonedValues[id].value = newValue;
      }
    }
    setFormData(clonedValues);
  };

  const checkTempValidation = (key: string) => {
    // let minimumValue = key === 'passwordComplexityCharacterNumbers' ? 6 : 0;
    // let tempValuesClone: any = { ...formData };
    // let passwordComplexityIndex = findIndex(passwordComplexity, {
    //   id: key
    // });
    // if (tempValuesClone[key].value < minimumValue || tempValuesClone[key].value > 999) {
    //   tempValuesClone[key].value =
    //     key === 'passwordExpiry'
    //       ? expirySchema
    //       : key === 'passwordHistory'
    //       ? passwordHistory
    //       : passwordComplexity[passwordComplexityIndex].count;
    //   tempValuesClone[key].focus = false;
    //   setFormData(tempValuesClone);
    // } else {
    //   let items;
    //   if (key === 'passwordHistory') {
    //     items = cloneDeep(passwordHistory);
    //     items = tempValuesClone[key].value;
    //     tempValuesClone[key].focus = false;
    //     setPasswordHistory(items);
    //     setFormData(tempValuesClone);
    //   } else if (key === 'passwordExpiry') {
    //     items = cloneDeep(expirySchema);
    //     items = tempValuesClone[key].value;
    //     tempValuesClone[key].focus = false;
    //     setExpirySchema(items);
    //     setFormData(tempValuesClone);
    //   } else {
    //     items = [...passwordComplexity];
    //     items[passwordComplexityIndex].count = tempValuesClone[key].value;
    //     tempValuesClone[key].focus = false;
    //     setPasswordComplexity(items);
    //     setFormData(tempValuesClone);
    //   }
    // }
  };

  const sumFunction = (key: string, operation: Operation, aggregate: boolean = false) => {
    const clonedValues: any = { ...formData };
    const oldValue: number = clonedValues[key].value;
    clonedValues[key].value = operation ? oldValue - 1 : oldValue + 1;

    if (aggregate) {
      let tempMinPwdLength: number = 0;
      const minPwdLengthKey: string = 'passwordComplexityCharacterNumbers';
      passwordComplexity.forEach((element: any) => {
        const { id } = element;
        if (id !== minPwdLengthKey) {
          tempMinPwdLength += clonedValues[id]?.value;
        }
      });
      const minPwdLength: number = clonedValues[minPwdLengthKey]?.value || 0;
      if (tempMinPwdLength > minPwdLength) {
        clonedValues[minPwdLengthKey].value = tempMinPwdLength;
      }
    }
    setFormData(clonedValues);
  };

  const disableDecrement = (id: string) => {
    let status: boolean = false;
    const item: any = find(passwordComplexitySchema, { id });
    if (item) {
      status = formData[id].value <= item.count;
    }
    return status;
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      {pageError && <NoPermissionMessage message={pageError} />}
      {!pageError && (
        <div className="flex max-h-screen w-full flex-col">
          {!isModalMode && (
            <MenuButtonsPortal>
              <BiButton
                className="bg-white text-primary"
                type="button"
                onClick={() => {
                  if (isCreate) {
                    navigate(RouteConstants.ROUTE_UMS_PASSWORD_POLICY_LIST);
                    return;
                  }

                  if (isEdit) {
                    handleOnCancel();
                  } else {
                    navigate(RouteConstants.ROUTE_UMS_PASSWORD_POLICY_LIST);
                  }
                }}
              >
                {getCancelButtonText()}
              </BiButton>
              {/* {isEdit && ( */}
              <BiButton
                className="bg-primary text-white"
                type="button"
                onClick={() => {
                  if (!isEdit) {
                    setIsEdit(!isEdit);
                    return;
                  }
                  handleSave();
                }}
                disabled={disableSave()}
              >
                {isEdit ? t('T_SAVE') : t('T_EDIT')}
              </BiButton>
              {/* )} */}
            </MenuButtonsPortal>
          )}
          <div
            className={`flex max-h-screen w-full flex-col justify-start overflow-y-scroll bg-gray-100 ${
              !isModalMode && 'px-2 py-3'
            }`}
            style={useTheme().theme.bgColorStyleForLoggedInPage}
          >
            <div className="flex">
              <div className={`flex ${!isModalMode ? 'w-3/4' : 'w-full'} flex-col`}>
                <div className={`flex-col justify-between ${!isModalMode && 'p-2'}`}></div>
                <Card
                  title={t('T_PASSWORD_POLICY')}
                  icon={LockIcon}
                  alt={t('T_CARD_ALT_MESSAGE')}
                  height=""
                  width=""
                  className="mb-0 rounded-none rounded-t-lg"
                >
                  <div className="flex w-full flex-col">
                    <div className="mb-5 flex w-full flex-row">
                      <div className="flex w-1/2 flex-col">
                        <InputField
                          type={'text'}
                          id={'password-policy-name'}
                          label={'T_NAME'}
                          placeholder={'T_PASSWORD_POLICY_NAME'}
                          fieldKey={'name'}
                          asterixFirst={true}
                          required={true}
                          formData={formData}
                          setFormData={setFormData}
                          formError={formError}
                          disabled={!isCreate}
                          min={0}
                          max={256}
                          handleChange={(e: any) => {
                            handleChange('name', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <SubCard className="bg-white">
                      <label
                        className="mb-4 flex items-center text-base font-semibold"
                        htmlFor="name"
                      >
                        {t('T_PASSWORD_COMPLEXITY')}
                      </label>

                      <div className="mb-0">
                        {passwordComplexity.map((item: any, index: number) => {
                          const { name, id, count, required } = item;
                          return (
                            <div key={id} className={`mb-5 flex flex-row items-center`}>
                              <CalcButton
                                type={Operation.PLUS}
                                onClick={() => {
                                  sumFunction(id, Operation.PLUS, true);
                                }}
                                testId="passwordComplex-add"
                                disabled={!isEdit}
                              />
                              <div className="mx-2">
                                <input
                                  type="text"
                                  id="subject"
                                  data-testid="passwordComplex-value"
                                  className="h-10 w-14 rounded-md border border-solid border-gray-300 px-3"
                                  // value={tempValues[id].focus ? tempValues[id].value : count}
                                  value={formData[id]?.value || count}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    manualCalc(e, id)
                                  }
                                  disabled={!isEdit}
                                  onBlur={() => {
                                    checkTempValidation(id);
                                  }}
                                />
                              </div>

                              <CalcButton
                                type={Operation.MINUS}
                                testId="passwordComplex-minus"
                                onClick={() => {
                                  sumFunction(id, Operation.MINUS, true);
                                }}
                                disabled={!isEdit || disableDecrement(id)}
                              />
                              <div className="ml-2 flex items-center text-base font-normal">
                                {required ? (
                                  <img className="mx-1 h-2 w-2" src={Asterix} alt="asterix" />
                                ) : (
                                  ''
                                )}
                                {t(name)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </SubCard>
                  </div>

                  <SubCard className="bg-white">
                    <div className="flex w-2/3 flex-col">
                      <label
                        className="mb-2 flex items-center text-base font-semibold"
                        htmlFor="name"
                      >
                        {t('T_PASSWORD_EXPIRATION')}
                      </label>

                      <div className="flex flex-row items-baseline" key={`exp_0`}>
                        <div className="flex flex-row items-center text-base font-normal">
                          {t('T_EXPIRES_IN')}
                          <CalcButton
                            type={Operation.PLUS}
                            testId="add-password-expiry"
                            disabled={!isEdit}
                            className={'ml-2'}
                            onClick={() => {
                              sumFunction('passwordExpiry', Operation.PLUS);
                            }}
                          />
                          <div className="mx-2">
                            <input
                              type="text"
                              id="subject"
                              className="h-10 w-14 rounded-md border border-solid border-gray-300 px-3"
                              // value={
                              //   tempValues.passwordExpiry.focus
                              //     ? tempValues.passwordExpiry.value
                              //     : expirySchema
                              // }
                              value={formData.passwordExpiry.value}
                              data-testid="password-expiry-value"
                              disabled={!isEdit}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                manualCalc(e, 'passwordExpiry')
                              }
                              onBlur={() => {
                                checkTempValidation('passwordExpiry');
                              }}
                            />
                          </div>
                          <CalcButton
                            type={Operation.MINUS}
                            className={'mr-2'}
                            testId="minus-password-expiry"
                            disabled={!isEdit || formData.passwordExpiry.value <= pwdMaxAge}
                            onClick={() => {
                              sumFunction('passwordExpiry', Operation.MINUS);
                            }}
                          />
                          {t('T_DAYS')}
                        </div>
                      </div>
                    </div>
                  </SubCard>
                  <SubCard className="bg-white">
                    <div className="flex w-2/3 flex-col">
                      <label
                        className="mb-2 flex items-center text-base font-semibold"
                        htmlFor="name"
                      >
                        {t('T_PASSWORD_HISTORY')}
                      </label>

                      <div className="flex flex-row items-baseline" key={`password_history0`}>
                        <div className="flex flex-row items-center text-base font-normal">
                          {t('T_PASSWORD_TO_REMEMBER')}
                          <CalcButton
                            type={Operation.PLUS}
                            testId="password-remember-add"
                            disabled={!isEdit}
                            className={'ml-2'}
                            onClick={() => {
                              sumFunction('passwordHistory', Operation.PLUS);
                            }}
                          />
                          <div className="mx-2">
                            <input
                              type="text"
                              data-testid="password-remember-value"
                              id="subject"
                              className="h-10 w-14 rounded-md border border-solid border-gray-300 px-3"
                              // value={
                              //   tempValues.passwordHistory.focus
                              //     ? tempValues.passwordHistory.value
                              //     : passwordHistory
                              // }
                              value={formData.passwordHistory.value}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                manualCalc(e, 'passwordHistory')
                              }
                              disabled={!isEdit}
                              onBlur={() => {
                                checkTempValidation('passwordHistory');
                              }}
                            />
                          </div>
                          <CalcButton
                            type={Operation.MINUS}
                            testId="password-remember-minus"
                            className={'mr-2'}
                            disabled={!isEdit || formData.passwordHistory.value <= pwdInHistory}
                            onClick={() => {
                              sumFunction('passwordHistory', Operation.MINUS);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </SubCard>
                </Card>
                {isModalMode && buttonActions(handleSave, !formData.name)}
              </div>
              {!isCreate && !isModalMode && (
                <div className="ml-5 flex w-1/4 flex-col">
                  <div className="my-7">
                    {!!apiPermisions[AppConstants.PERMISSION_UMS_PASSWORD_POLICY_DELETE] && (
                      <div className="mt-6">
                        <BiButton
                          className={
                            'w-48 border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
                          }
                          type="button"
                          onClick={() => {
                            setConfirmDelete(true);
                          }}
                        >
                          {t('T_DELETE_PASSWORD_POLICY')}
                        </BiButton>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!isModalMode ? (
        <Modal
          showCloseIcon={true}
          width="48rem"
          headerText={t('T_DELETE_CONFIRMATION_TITLE')}
          toggleVisiblity={(value: boolean) => {
            setConfirmDelete(false);
          }}
          isVisible={confirmDelete}
          body={
            <div>
              <span>
                {t('T_DELETE_CONFIRMATION_PLACEHOLDER').replace(
                  '%s',
                  t('T_PASSWORD_POLICY').toLowerCase()
                )}
                <b>{` ${selectedPasswdPolicy?.name} ?`}</b>
              </span>
              <div className="mt-8 flex">
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => setConfirmDelete(false)}
                >
                  {t('T_NO_CANCEL')}
                </BiButton>
                <BiButton
                  className={'border border-[#D32029] text-[#D32029]'}
                  type="button"
                  onClick={() => removePasswordPolicy()}
                >
                  {t('T_YES_DELETE')}
                </BiButton>
              </div>
            </div>
          }
          headerLeftIcon={<ExclamationIcon className="h-7.5 w-6" />}
        />
      ) : (
        ''
      )}
    </LoaderContainer>
  );
};

export default PasswordPolicy;
