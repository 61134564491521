import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import TemplateTable from '../shared/tables/TemplateTable';
import { TableContainer } from '../Organizations/OrganizationsList';
import {
  deleteMetadataObject,
  getApplication,
  getMetadataObjects
} from '../../http/metadata-service';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { AppConstants } from '../../constants/AppConstants';
import { useTheme } from '../../context/themeContext';
import { setURLPaths } from '../../app/organizationReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { setApplication, setMetadataObject } from '../../app/metadataReducer';
import { setToastData } from '../../app/toastReducer';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { cloneDeep, compact, debounce, findIndex, uniq } from 'lodash';
import { getFailureMessage } from '../../util/ErrorUtil';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import moment from 'moment';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { plusSign } from '../../assets/components/svgs';
import { RouteConstants } from '../../constants/RouteConstants';
import DeleteConfirmModal from './DeleteConfirmModal';
import { EllipsisDiv } from '../../styles/globalStyles';
import Checkbox from '../shared/Fields/Checkbox';
import { changeMetadataComponent, setManifest } from '../../app/migrationExportReducer';
import { ActionButtons } from '../Reusable/ActionButton';
import { Button } from '../Reusable/Shared';
import AddCircle from '../../assets/addCircle.svg';

/**
 * @parentId metadata
 * @manager Metadata Object Listing
 * @overview Lists metadata objects defined within application.
 *   <section>
 *       <p>
 *           On selecting application user can select <b>View Objects</b> from Manage metadata menu dropdown. Metadata object listing page will display all th objects created within an application namesapce.
 *           Metadata object listing page will show all the object(s) in a paginated way. The Default pagination size be fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer.
 *           Datagrid footer will also display the current page and total number of pages available based on the choosen page size & metadata object(s) data.
 *       </p>
 *       <br>
 *       <p>
 *           Object(s) can be filtered based on the object complete name using the search field provided above the Object listing data grid. Admin user(s) can create new Object by clicking on <b>New Object</b> button provided on the admin portal toolbar,
 *           which will navigate user to Metadata object creation page. <b>New Object</b> button will be shown based on logged in user role API permissions as described below.
 *       </p>
 *       <br>
 *       <p>
 *           Datagrid will display details of each metadata object as row item with each column representing below object property.
 *           <br>
 *           <ul>
 *               <li>Status</li>
 *               <li>Name</li>
 *               <li>Description</li>
 *               <li>Created By</li>
 *               <li>Created On</li>
 *               <li>Last Modified On</li>
 *               <li>Delete Action</li>
 *           </ul>
 *           <b>Note:</b> Delete action is shown based on logged in user role API permission to delete object. Actual deletion happens after user confirms on the deletion prompt.
 *       </p>
 *       <p>
 *           Sorting of application grid is only supported on <i>Status</i>, <i>Name</i>, <i>Created On</i>, <i>Created By</i>, <i>Last Modified On</i> column.The breadcrumb bar on the object toolbar will reflect location of the user in the admin portal. Clicking on metadata object rowitem will drill down user to object details page which will be displayed in readonly mode.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the List Metadata Object Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of metadata object listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for metadata object listing</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for listing metadata object(s) page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/applications/{appId}/objects</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-object.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/applications/{appId}/objects</td>
 *               <td>POST</td>
 *               <td>metadata-service.metadata-object.create</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for metadata object listing page</p>
 *   </section>
 */

interface ObjectListProps {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const ObjectList = ({ type = 'listing', toggleModal = () => {} }: ObjectListProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [rows, setRows] = useState<any>([]);
  const [paging, setPaging] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deletableIndex, setDeletableIndex] = useState<number>(-1);

  const [size, setSize] = useState<number>(
    type === 'listing' ? AppConstants.DEFAULT_PAGE_SIZE : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
  );
  const [currentPage, setCurrentPage] = useState<number>(AppConstants.DEFAULT_PAGE);

  const application = useAppSelector((state) => state.metadata.application);
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const metadataId = useAppSelector((state: any) => state.migrationExport.metadataComponent?.id);
  const manifestAppData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.tempApplications
  );

  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const usersMap: Map<string, any> = new Map<string, any>();

  const isCheckboxSelected = (data: any) => {
    const { id } = data;
    return (
      findIndex(selectedItems, function (item: any) {
        return item.id === id;
      }) !== -1
    );
  };

  const toggleOverWrite = (data: any) => {
    let metadataApplication = JSON.parse(JSON.stringify(manifestAppData));
    const appIndex = findIndex(metadataApplication, function (item: any) {
      return item.id === metadataId;
    });

    let existingId = findIndex(
      metadataApplication[appIndex].dependentArtifactConfigurations.objects,
      function (item: any) {
        return item.id === data?.id;
      }
    );

    if (existingId !== -1) {
      metadataApplication[appIndex].dependentArtifactConfigurations.objects[existingId][
        'overWrite'
      ] =
        !metadataApplication[appIndex].dependentArtifactConfigurations.objects[existingId][
          'overWrite'
        ];
      dispatch(setManifest({ key: 'applications', value: metadataApplication }));
      dispatch(setManifest({ key: 'tempApplications', value: metadataApplication }));
      return;
    }
  };

  const toggleCheckbox = (data: any) => {
    const { id, description, name, active } = data;

    let existingArr: any = cloneDeep(selectedItems);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      existingArr.splice(existingId, 1);
      if (!existingArr.length) {
        setSelectedItems([{ id: 'ALL' }]);
        return;
      } else {
        setSelectedItems(existingArr);
        return;
      }
    }

    let allId = findIndex(existingArr, function (item: any) {
      return item.id === 'ALL';
    });
    if (allId !== -1) {
      existingArr.splice(allId, 1);
    }

    existingArr.push({
      id,
      name,
      active,
      description,
      dependentArtifactConfigurations: {
        instances: [
          {
            id: 'ALL'
          }
        ]
      }
    });
    setSelectedItems(existingArr);
  };

  const tableHeadersBuilder = () => {
    let TABLE_HEADERS: Array<ITableHeader> = [
      {
        key: 'active',
        description: 'T_STATUS',
        sortEnabled: type !== 'migrationPreview',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (row: any) => {
          return row?.active ? t('T_ACTIVE') : t('T_NOT_ACTIVE');
        },
        width: '10%'
      },
      {
        key: 'name',
        description: 'T_NAME',
        sortEnabled: type !== 'migrationPreview',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.name}>
              {data?.name}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '25%' : type === 'migrationPreview' ? '35%' : '40%'
      },
      {
        key: 'description',
        description: 'T_DESCRIPTION',
        sortEnabled: false,
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.description}>
              {data?.description}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '20%' : type === 'migrationPreview' ? '35%' : '40%'
      }
    ];

    switch (type) {
      case 'listing':
        const listingHeaders: Array<ITableHeader> = [
          // {
          //   key: 'createdBy',
          //   description: 'T_CREATED_BY',
          //   sortEnabled: true,
          //   width: '15%',
          //   clickable: true,
          //   sortingInformation: {
          //     order: ''
          //   },
          //   render: (row: any) => {
          //     return getUserName(usersMap.get(row.createdBy)) || '';
          //   }
          // },
          {
            key: 'createdOn',
            description: 'T_CREATED_ON',
            sortEnabled: true,
            width: '20%',
            clickable: true,
            sortingInformation: {
              order: 'desc'
            },
            render: (row: any) => {
              return moment(row.createdOn).format(AppConstants.DEFAULT_DATE_FORMAT);
            }
          },
          {
            key: 'updatedOn',
            description: 'T_LAST_MODIFIED_ON',
            sortEnabled: true,
            width: '20%',
            clickable: true,
            sortingInformation: {
              order: ''
            },
            render: (row: any) => {
              return moment(row.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT);
            }
          },
          // TODO: Need clarity on sorting and showing user name
          // {
          //   key: 'updatedBy',
          //   description: 'T_LAST_MODIFIED_BY',
          //   sortEnabled: false,
          //   width: '20%',
          //   clickable: true,
          //   sortingInformation: {
          //     order: ''
          //   },
          // }
        ];
        TABLE_HEADERS = [...TABLE_HEADERS, ...listingHeaders];

        if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_OBJECT_DELETE]) {
          TABLE_HEADERS.push({
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '5%',
            sortingInformation: {
              order: ''
            }
          });
        }

        break;

      case 'migrationSelection':
        TABLE_HEADERS.unshift({
          key: 'checked',
          description: '',
          sortEnabled: false,
          width: '10%',
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={isCheckboxSelected(data)}
                  onChange={() => {
                    toggleCheckbox(data);
                  }}
                  testID="addModuleCheckbox"
                />
              </>
            );
          }
        });
        break;
      case 'migrationPreview':
        TABLE_HEADERS.push(
          {
            key: 'overWrite',
            description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any, index: any) => {
              return (
                <>
                  <Checkbox
                    className="mx-auto"
                    height="20px"
                    width="20px"
                    onChange={() => {
                      toggleOverWrite(data);
                    }}
                    checked={data?.overWrite}
                    testID="allowOverWriteCheckbox"
                  />
                </>
              );
            }
          },
          {
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            }
          }
        );
        break;
    }

    return TABLE_HEADERS;
  };

  const [tableHeaders, setTableHeaders] = useState(tableHeadersBuilder());

  useEffect(() => {
    if (type === 'migrationPreview') {
      setIsLoading(false);
      return;
    }
    if (!application) {
      getMetadataApplication((params?.id || metadataId) as string);
    }
    loadObjects(
      '',
      currentPage,
      size,
      AppConstants.DEFAULT_SORT_BY,
      AppConstants.DEFAULT_SORT_DESC
    );
    return () => {
      usersMap.clear();
    };
  }, []);

  useEffect(() => {
    application &&
      dispatch(
        setURLPaths([
          {
            key: RouteConstants.ROUTE_MDS_OBJECT_LIST.replace(
              ':id',
              (params?.id || metadataId) as string
            ),
            label: `Metadata > ${application?.name} > Objects`
          }
        ])
      );
  }, [application]);

  useEffect(() => {
    if (manifestAppData?.length && metadataId) {
      let index = findIndex(manifestAppData, function (item: any) {
        return item.id === metadataId;
      });
      if (index !== -1) {
        setSelectedItems(manifestAppData[index]?.dependentArtifactConfigurations?.objects);
      }
    }
  }, [manifestAppData, metadataId]);

  useEffect(() => {
    if (type === 'migrationSelection') {
      setTableHeaders(tableHeadersBuilder());
    } else if (type === 'migrationPreview') {
      setTableHeaders(tableHeadersBuilder());
      setRows(selectedItems);
      setIsLoading(false);
    }
  }, [selectedItems]);

  const getMetadataApplication = async (id: string) => {
    const response = await getApplication(id);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setApplication(data));
    }
  };

  const loadObjects = async (
    name: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType?: string
  ) => {
    const sorters = new Array<string>();
    if (sort) {
      if (sortType) {
        sorters.push(`${sort},${sortType}`);
      } else {
        sorters.push(`${sort}`);
      }
    }
    const response = await getMetadataObjects(
      (params?.id || metadataId) as string,
      name.split(','),
      page,
      size,
      sorters
    );
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: objects = [], paging } = data;
      if (objects.length) {
        const users: Array<string> = objects
          .filter((object: any) => !usersMap.get(object.createdBy))
          .map((object: any) => object.createdBy);

        if (users.length) {
          const userProfiles: Array<any> = await getUserProfiles(uniq(compact(users)));
          userProfiles.forEach((user: any) => {
            usersMap.set(user?.userId, user);
          });
        }
        setRows([...objects]);
        setPaging(paging);
      } else {
        noDataAvailableMessage(setRows, setSize, setPaging, paging, t);
      }
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setTableValues(name, page, size, sort, sortType);
  };

  const debouncedCall = useRef(
    debounce((name, page, size, sort, sortType) => {
      setSize(size);
      setCurrentPage(page);
      setIsLoading(true);
      loadObjects(name, page, size, sort, sortType);
    }, 400)
  ).current;

  const setTableValues = (
    name: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    debouncedCall(name, page, size, sort, sortType);
  };

  const objectSelector = (data: any) => {
    const { metadataApplicationId: applicationId, id, name, active, description } = data;
    if (type === 'listing') {
      dispatch(setMetadataObject({ ...data }));
      navigate(`/metadata/${applicationId}/objects/${id}`);
    } else {
      if (type === 'migrationPreview') {
        if (
          findIndex(data?.dependentArtifactConfigurations.instances, function (item: any) {
            return item.id === 'ALL';
          }) == -1
        ) {
          dispatch(setMetadataObject({ ...data }));
          dispatch(changeMetadataComponent({ route: 'instances', id: metadataId, objectId: id }));
        }

        return;
      }
      let selectedItemsClone = cloneDeep(selectedItems);
      if (!isCheckboxSelected(data)) {
        let allId = findIndex(selectedItemsClone, function (item: any) {
          return item.id === 'ALL';
        });
        if (allId !== -1) {
          selectedItemsClone.splice(allId, 1);
        }
        selectedItemsClone.push({
          id,
          name,
          active,
          description,
          dependentArtifactConfigurations: {
            instances: [
              {
                id: 'ALL'
              }
            ]
          }
        });
      }
      let metadataApplication = cloneDeep(manifestAppData);
      const index = findIndex(metadataApplication, function (item: any) {
        return item.id === metadataId;
      });
      metadataApplication[index].dependentArtifactConfigurations.objects = selectedItemsClone;
      dispatch(setMetadataObject({ ...data }));
      dispatch(setManifest({ key: 'tempApplications', value: metadataApplication }));
      dispatch(changeMetadataComponent({ route: 'instances', id: applicationId, objectId: id }));
    }
  };

  const removeMigrationObject = (data: any) => {
    let navigationFlag = false;
    let metadataApplication = cloneDeep(manifestAppData);
    const index = findIndex(metadataApplication, function (item: any) {
      return item.id === metadataId;
    });

    let objIndex = findIndex(
      metadataApplication[index].dependentArtifactConfigurations.objects,
      function (item: any) {
        return item.id === data?.id;
      }
    );
    metadataApplication[index].dependentArtifactConfigurations.objects.splice(objIndex, 1);

    if (!metadataApplication[index].dependentArtifactConfigurations.objects.length) {
      metadataApplication[index].dependentArtifactConfigurations.objects.push({ id: 'ALL' });
      navigationFlag = true;
    }
    dispatch(setManifest({ key: 'applications', value: metadataApplication }));
    dispatch(setManifest({ key: 'tempApplications', value: metadataApplication }));
    if (navigationFlag) {
      dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
    }
  };

  const confirmDelete = (row: number, show: boolean = true) => {
    if (type === 'migrationPreview') {
      removeMigrationObject(rows[row + currentActivePage]);
      return;
    }
    setDeleteConfirmation(show);
    setDeletableIndex(row);
  };

  const deleteObject = async (row: number) => {
    setIsLoading(true);
    const msg: string = t('T_METADATA_OBJECT_DELELTE_SUCCESS');
    const { id, metadataApplicationId, name } = rows[row];
    const response: any = await deleteMetadataObject(metadataApplicationId, id);
    const { status } = response;
    const success = HTTP_STATUS.isSuccess(status);
    let errorMessage = success
      ? ''
      : getFailureMessage(response).includes(`id ${id}`)
      ? getFailureMessage(response).replace(`id ${id}`, `name ${name}`)
      : getFailureMessage(response);
    dispatch(
      setToastData({
        toastMessage: success ? msg.replace('%s', name) : errorMessage,
        isToastActive: true,
        toastType: success ? 'success' : 'error'
      })
    );
    if (success) {
      const newRows: Array<any> = [...rows];
      newRows.splice(row, 1);
      setRows(newRows);
    }
    setDeletableIndex(-1);
    setIsLoading(false);
  };

  const cancelSelection = () => {
    dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
    toggleModal();
  };

  const saveSelection = () => {
    let metadataApplication = cloneDeep(manifestAppData);
    const index = findIndex(metadataApplication, function (item: any) {
      return item.id === metadataId;
    });
    metadataApplication[index].dependentArtifactConfigurations.objects = selectedItems;
    dispatch(setManifest({ key: 'applications', value: metadataApplication }));
    dispatch(setManifest({ key: 'tempApplications', value: [] }));
    dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
    toggleModal();
  };

  useEffect(() => {
    if (type === 'migrationPreview') {
      setCurrentActivePage(currentPage * size - size);
    }
  }, [currentPage]);

  return (
    <LoaderContainer isLoading={isLoading}>
      <TableContainer className={`${type !== 'listing' && '!pb-0'}`}>
        {type === 'listing' ? (
          <MenuButtonsPortal>
            <BiButton
              className="bg-white text-primary"
              type="button"
              onClick={() => {
                navigate(RouteConstants.ROUTE_MDS_APPLN_VIEW.replace(':id', params?.id as string));
              }}
            >
              {t('T_BACK_TO_APPLICATION').replace(
                '%s',
                application?.name.length < 50
                  ? application?.name
                  : `${application?.name.substring(0, 50)}...`
              )}
            </BiButton>

            {roleAPIPermissions[AppConstants.PERMISSION_METADATA_OBJECT_CREATE] && (
              <BiButton
                className={'flex flex-row bg-primary text-white'}
                type="button"
                onClick={() => {
                  navigate(
                    RouteConstants.ROUTE_MDS_OBJECT_CREATE.replace(':id', params?.id as string)
                  );
                }}
              >
                <img src={AddCircle} className="mr-2 w-6 text-center"></img>
                {t('T_NEW_METADATA_OBJECT')}
              </BiButton>
            )}
          </MenuButtonsPortal>
        ) : (
          <BiButton
            className="bg-white text-primary"
            type="button"
            onClick={() => {
              dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
            }}
          >
            {t('T_BACK_TO_APPLICATION').replace(
              '%s',
              application?.name.length < 50
                ? application?.name
                : `${application?.name.substring(0, 50)}...`
            )}
          </BiButton>
        )}
        <TemplateTable
          tableData={
            type === 'migrationPreview'
              ? rows.slice(currentActivePage, currentActivePage + size)
              : rows
          }
          onRefreshTableData={refreshTableData}
          size={size.toString()}
          currentPage={currentPage.toString()}
          isSearchable={type !== 'migrationPreview'}
          searchPlaceholder={t('T_SEARCH_OBJECTS_BY_NAME')}
          templateSelector={objectSelector}
          cellSelector={objectSelector}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={paging}
          theme={useTheme().theme}
          containerClass="mt-0"
          showDeleteButton={
            !!roleAPIPermissions[AppConstants.PERMISSION_METADATA_OBJECT_DELETE] ||
            type === 'migrationPreview'
          }
          deleteOnClick={confirmDelete}
          setCurrentPage={setCurrentPage}
          isLocalPaginator={type === 'migrationPreview'}
          totalDataLength={rows.length}
          isPaginated={type !== 'migrationPreview'}
          disablePageSize={type !== 'listing'}
        />
      </TableContainer>
      {deleteConfirmation && (
        <DeleteConfirmModal
          width={'35rem'}
          title={t('T_DELETE_CONFIRMATION_TITLE')}
          onClose={() => confirmDelete(-1, false)}
          children={
            <div>
              <span>
                {t('T_DELETE_CONFIRMATION_TEXT')}
                <b>{rows[deletableIndex]?.name} </b>
                {t('T_OBJECT')}?
              </span>
            </div>
          }
          onCancel={() => confirmDelete(-1, false)}
          onConfirm={() => {
            setDeleteConfirmation(false);
            setTimeout(() => deleteObject(deletableIndex));
          }}
        />
      )}
      {type === 'migrationSelection' ? (
        <div className="w-full">
          <ActionButtons
            primaryButtonLabel={t('T_SAVE')}
            primaryButtonAction={saveSelection}
            secondaryButtonLabel={t('T_CANCEL')}
            secondaryButtonAction={cancelSelection}
            primaryButtonWidth="full"
            primaryButtonClass={'px-4'}
          />
        </div>
      ) : (
        ''
      )}
    </LoaderContainer>
  );
};

export default ObjectList;
