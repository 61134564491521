import { useEffect } from 'react';

const useOutsideClick = (ref: any, callback: any, modalFlag: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target) && modalFlag !== true) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
