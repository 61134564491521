import styled, { css } from 'styled-components/macro';
import {
  colorBorderAlert1,
  colorLightGray7,
  colorProductDisabled,
  colorProductLink,
  colorProductLinkHover,
  colorProductPrimary,
  colorProductPrimaryHover,
  colorRed3,
  colorRed6,
  colorSecondaryHover,
  colorWhite,
  gridCenter
} from '../../styles/globalVariables';
import { IError } from '../Onboarding/interfaces';
import { CSSProperties } from 'react';
import { ITheme, useTheme } from '../../context/themeContext';

/**
 * Buttons
 */

const COLOR = {
  primary: css`
    background-color: rgb(${colorProductPrimary});
    color: rgb(${colorWhite});

    &:hover {
      background-color: rgb(${colorProductPrimaryHover});
    }

    &:disabled {
      background-color: rgb(${colorProductDisabled});
      color: rgb(${colorWhite});
      border: 1px solid rgb(${colorProductDisabled});

      &:hover {
        background-color: rgb(${colorProductDisabled});
        color: rgb(${colorWhite});
        border: 1px solid rgb(${colorProductDisabled});
      }
    }
  `,
  secondary: css`
    background-color: rgb(${colorWhite});
    color: rgb(${colorProductPrimary});
    border: 1px solid rgb(${colorProductDisabled});

    &:hover {
      background-color: rgb(${colorSecondaryHover});
      color: rgb(${colorProductPrimaryHover});
      border: 1px solid rgb(${colorProductPrimaryHover});
    }

    &:disabled {
      background-color: rgb(${colorWhite});
      color: rgb(${colorProductDisabled});
      &:hover {
        background-color: rgb(${colorWhite});
        border: 1px solid rgb(${colorProductDisabled});
        color: rgb(${colorProductDisabled});
      }
    }
  `,
  tertiary: css`
    color: rgb(${colorProductPrimary});
    background-color: rgb(${colorWhite});

    &:hover {
      color: rgb(${colorProductPrimaryHover});
    }

    &:disabled {
      color: rgb(${colorProductDisabled});
      &:hover {
        background-color: rgb(${colorWhite});
        color: rgb(${colorProductDisabled});
      }
    }
  `,
  danger: css`
    background-color: rgb(${colorWhite});
    color: rgb(${colorRed6});
    border: 1px solid rgb(${colorRed6});

    &:hover {
      background-color: rgb(${colorRed3});
    }

    &:disabled {
      border: 1px solid rgb(${colorRed3});
      color: rgb(${colorRed3});
      &:hover {
        background-color: rgb(${colorWhite});
        border: 1px solid rgb(${colorRed3});
        color: rgb(${colorRed3});
      }
    }
  `
};

type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children: React.ReactNode; // make the component able to receive children elements
  color: 'primary' | 'secondary' | 'tertiary' | 'danger';
  disabled?: boolean; // make the button disabled or not
  width?: string;
  height?: string;
  padding?: string;
  type?: 'button' | 'submit';
  style?: CSSProperties | string;
  classes?: string;
  theme?: ITheme;
  id?: string;
};

const Container = styled.button<ButtonProps>`
  height: ${(p) => (p.height ? p.height : '2.25rem')};
  width: ${(p) => (p.width ? p.width : '6.25rem')};
  padding: ${(p) => p.padding && p.padding};
  font-weight: 600;
  transition: all 0.3s ease;
  ${gridCenter}
  border-radius: 5px;
  &:disabled {
    cursor: not-allowed;
  }
  ${(props) => {
    if (props.color === 'primary') {
      return props.theme.signInBtnStyle.primary;
    }
    if (props.color === 'secondary') {
      return props.theme.signInBtnStyle.secondary;
    }
    if (props.color === 'tertiary') {
      return props.theme.signInBtnStyle.tertiary;
    }

    if (props.color === 'danger') {
      return props.theme.signInBtnStyle.danger;
    }
  }}
`;

export const Button = ({
  onClick,
  children,
  color,
  disabled,
  height,
  width,
  type = 'button',
  style,
  classes = '',
  theme = useTheme().theme,
  id = ''
}: ButtonProps) => {
  return (
    <Container
      height={height}
      width={width}
      onClick={onClick}
      color={color}
      disabled={disabled}
      type={type}
      style={style}
      className={classes}
      theme={theme}
      id={id}
      data-testId={id}
    >
      {children}
    </Container>
  );
};

/**
 * Links
 */

export const TextLink = styled.a<IError>`
  font-size: 1rem;
  font-weight: 400;
  pointer-events: ${(p) => (p.isError ? 'none' : 'all')};
  color: rgb(${(p) => (p.isError ? colorProductDisabled : colorProductLink)});
  transition: 0.3s ease color;
  cursor: default;

  &:hover {
    color: rgb(${colorProductLinkHover});
  }
`;

/**
 * Input
 */

export const SharedTextInput = styled.input<IError>`
  border: 0.5px solid rgb(${colorLightGray7});
  background-color: rgb(${colorWhite});
  border-radius: 4px;
  padding: 0.8125rem 0.75rem;

  ${(p) =>
    p.isError &&
    `
    border: 2px solid rgb(${colorBorderAlert1});
    box-shadow: 0px 0px 4px rgba(245, 34, 45, 0.5);
  `}

  &:focus {
    box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
    border: 1px solid #177ddc;
  }
`;
