import { createSlice } from '@reduxjs/toolkit';

export const appInitializeSlice = createSlice({
  name: 'metaData',
  initialState: {
    applicationList: [],
    metaDataObjectList: [],
    metaDataQuestionTypes: {},
    mobileConfigCatalog: {},
    initialDataLoaded: false,
    apiPermissions: {} as any,
    metaDataPermissionSet: {} as any
  },
  reducers: {
    setApplicationList: (state, action) => {
      state.applicationList = action.payload;
    },
    setMetaDataObjectList: (state, action) => {
      state.metaDataObjectList = action.payload;
    },
    setMetaDataQuestionTypes: (state, action) => {
      state.metaDataQuestionTypes = action.payload;
    },
    setMetaDataPermissionSet: (state, action) => {
      state.metaDataPermissionSet = action.payload;
    },
    setInitialDataLoaded: (state, action) => {
      state.initialDataLoaded = action.payload;
    },
    setAPIPermissions: (state, action) => {
      state.apiPermissions = action.payload;
    },
    setMobileConfigCatalog: (state, action) => {
      state.mobileConfigCatalog = action.payload;
    }
  }
});

export const {
  setApplicationList,
  setMetaDataObjectList,
  setMetaDataQuestionTypes,
  setInitialDataLoaded,
  setAPIPermissions,
  setMetaDataPermissionSet,
  setMobileConfigCatalog,
} = appInitializeSlice.actions;

export default appInitializeSlice.reducer;
