import { lazy } from 'react';

export const GenericNotFound = lazy(() =>
  import('../pages/GenericNotFound/GenericNotFound').then((module) => ({
    default: module.GenericNotFound
  }))
);

export const Login = lazy(() =>
  import('../components/Auth/Login').then((module) => ({ default: module.default }))
);
