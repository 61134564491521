import { useEffect, useState } from 'react';
import Modal from '../shared/ModalPortal/Modal';
import userIcon from '../../assets/user-group.svg';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { cancelInviteUser, deleteUser, reinviteUser } from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import ExclamationIcon from '@heroicons/react/outline/ExclamationIcon';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

interface OrganizationMenuProps {
  userId: string;
  userName: string;
  status: string;
  setToastMsg: Function;
  setIsLoading: Function;
  allowReinvite: boolean;
  UpdateAdminDataStatus: Function;
}

const AdminActionModal: React.FC<OrganizationMenuProps> = ({
  userId,
  userName,
  status,
  setToastMsg,
  setIsLoading,
  allowReinvite,
  UpdateAdminDataStatus
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const options = ['reinvite', 'deactivate', 'cancelInvitation'];
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const organizationId = useAppSelector((state) => state.admin.orgId);

  const navigateToAdminList = () => {
    if (location.pathname.split('/').includes('invited')) {
      navigate(`/admin-list/invited${organizationId ? `/${organizationId}` : ''}`);
    } else {
      navigate(`/admin-list${organizationId ? `/${organizationId}` : ''}`);
    }
  };

  const callDeactivateAdminAPI = async () => {
    const response: any = await deleteUser(userId);
    if (response.status === HTTP_STATUS.HTTP_OK) {
      setToastMsg(`${t('T_USER')} ${userName} ${t('T_TOAST_DEACTIVATED_MSG')}`, true, 'success');
      navigateToAdminList();
    } else {
      setToastMsg(response.data.issues[0].details, true, 'error');
    }
    setIsLoading(false);
    setSelectedOption('');
  };

  const callReinviteAdminAPI = async () => {
    setSelectedOption('');
    const response: any = await reinviteUser(userId);
    if (response.status === HTTP_STATUS.HTTP_OK) {
      setToastMsg(`${t('T_USER')}  ${userName} ${t('T_TOAST_RE_INVITED_MSG')}`, true, 'success');
      UpdateAdminDataStatus('Reinvited');
      navigateToAdminList();
    } else if (response.status === HTTP_STATUS.HTTP_BAD_REQUEST) {
      setToastMsg(response.data.issues[0].details, true, 'error');
    }
    setIsLoading(false);
  };

  const callCancelInvitationAPI = async () => {
    setSelectedOption('');
    const response: any = await cancelInviteUser(userId);
    if (response.status === HTTP_STATUS.HTTP_OK) {
      setToastMsg(
        `${t('T_USER')}  ${userName} ${t('T_TOAST_RE_CANCEl_INVITE_MSG')}`,
        true,
        'success'
      );
      UpdateAdminDataStatus('cancelled');
      navigateToAdminList();
    } else if (response.status === HTTP_STATUS.HTTP_BAD_REQUEST) {
      setToastMsg(response.data.issues[0].details, true, 'error');
    }
    setIsLoading(false);
  };

  const DeactivateComp = () => {
    return (
      <div>
        <div>{t('T_DEACTIVATE_TITLE')}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={
              'border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setIsLoading(true);
              callDeactivateAdminAPI();
            }}
          >
            {t('T_BUTTON_YES_DEACTIVATE')}
          </BiButton>
        </div>
      </div>
    );
  };

  const CancelInviteComp = () => {
    return (
      <div>
        <div>{t('T_CANCEL_INVITATION_TITLE')}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={
              'border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setIsLoading(true);
              callCancelInvitationAPI();
            }}
          >
            {t('T_BUTTON_YES_CANCEL_INVITATION')}
          </BiButton>
        </div>
      </div>
    );
  };

  const ReinviteComp = () => {
    return (
      <div>
        <div>
          {t('T_RE_INVITE_USER')} {userName}.
        </div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={'border border-solid border-white bg-primary text-white'}
            type="button"
            onClick={() => {
              setIsLoading(true);
              callReinviteAdminAPI();
            }}
          >
            {t('T_BUTTON_YES_RE_INVITE')}
          </BiButton>
        </div>
      </div>
    );
  };

  const getActionButtonsBlock = (status: any) => {
    switch (status) {
      case 'invited':
      case 'reinvited':
        return (
          <>
            {allowReinvite && (
              <div className="mt-6">
                <BiButton
                  className={
                    'w-48 border border-solid border-primary bg-primary text-primary text-white'
                  }
                  type="button"
                  onClick={() => {
                    setSelectedOption('reinvite');
                  }}
                >
                  {t('T_BUTTON_RE_INVITE_USER')}
                </BiButton>
                {/* <div className="mt-2 w-60 text-sm text-gray-800">{t('T_BUTTON_RE_INVITE_USER')}</div> */}
              </div>
            )}
            <div className="mt-6">
              <BiButton
                className={
                  'w-48 border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
                }
                type="button"
                onClick={() => {
                  setSelectedOption('cancelInvitation');
                }}
              >
                {t('T_CANCEL_INVITATION')}
              </BiButton>
            </div>
          </>
        );
      case 'active':
      case 'created':
      case 'registered':
        return (
          <div className="mt-6">
            <BiButton
              className={
                'w-48 border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
              }
              type="button"
              onClick={() => {
                setSelectedOption('deactivate');
              }}
            >
              {t('T_DEACTIVATE_ACCOUNT')}
            </BiButton>
            <div className="mt-2 w-60 text-sm text-gray-800">{t('T_DEACTIVATE_ACCOUNT_MSG')}</div>
          </div>
        );
    }
  };

  return (
    <>
      {getActionButtonsBlock(status)}
      {/* {status === 'invited' || status === 'reinvited' ? (
        <div className="mt-6">
          <BiButton
            className={
              'w-48 border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setSelectedOption('cancelInvitation');
            }}
          >
            {t('T_CANCEL_INVITATION')}
          </BiButton>
        </div>
      ) : (
        <></>
      )}

      {status !== 'cancelled' ? (
        <div className="mt-6">
          <BiButton
            className={
              'w-48 border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setSelectedOption('deactivate');
            }}
          >
            {t('T_DEACTIVATE_ACCOUNT')}
          </BiButton>
          <div className="mt-2 w-60 text-sm text-gray-800">{t('T_DEACTIVATE_ACCOUNT_MSG')}</div>
        </div>
      ) : (
        <></>
      )}

      {status !== 'cancelled' && status !== 'registered' && allowReinvite ? (
        <div className="mt-6">
          <BiButton
            className={'w-48 border border-solid border-primary bg-primary text-primary text-white'}
            type="button"
            onClick={() => {
              setSelectedOption('reinvite');
            }}
          >
            {t('T_BUTTON_RE_INVITE_USER')}
          </BiButton>
        </div>
      ) : (
        <></>
      )} */}

      <Modal
        showCloseIcon={true}
        width="48rem"
        headerText={
          selectedOption === 'deactivate'
            ? t('T_DEACTIVATE_USER')
            : selectedOption === 'reinvite'
            ? t('T_BUTTON_RE_INVITE_USER')
            : selectedOption === 'cancelInvitation'
            ? t('T_BUTTON_CANCEL_USER_INVITATION')
            : ''
        }
        toggleVisiblity={(value: boolean) => {
          setSelectedOption('');
        }}
        isVisible={options.includes(selectedOption)}
        body={
          selectedOption === 'deactivate'
            ? DeactivateComp()
            : selectedOption === 'reinvite'
            ? ReinviteComp()
            : selectedOption === 'cancelInvitation'
            ? CancelInviteComp()
            : ''
        }
        headerLeftIcon={
          selectedOption === 'deactivate' ? <ExclamationIcon className="h-7.5 w-6" /> : userIcon
        }
      />
    </>
  );
};

export default AdminActionModal;

//CancelInviteComp
