import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../app/loginReducer';
import customersReducer from './customersReducer';
import contextReducer from './contextReducer';
import rolesReducer from './rolesReducer';
import customerFormReducer from './customerFormReducer';
import templateReducer from './templateReducer';
import userManagementReducer from './userManagementReducer';
import rolesAndFeatureReducer from './rolesAndFeatureReducer';
import userReducer from './userReducer';
import organizationReducer from './organizationReducer';
import adminReducer from './adminReducer';
import toastReducer from './toastReducer';
import permissionSetReducer from './permissionSetReducer';
import configurationReducer from './configurationReducer';
import surveyReducer from './surveyReducer';
import appInitializeReducer from './appInitializeReducer';
import mobileConfigurationReducer from './mobileConfigurationReducer';
import consentReducer from './consentReducer';
import metadataReducer from './metadataReducer';
import sysSettingReducer from './sysSettingReducer';
import migrationExportReducer from './migrationExportReducer';
import passwdPolicyReducer from './passwdPolicyReducer';
import bucketSlice from './bucketReducer';
import tableSortSearchSlice  from './tableReducer';
import templateTableReducer from './templateTableReducer'
import carePlanReducer from './carePlanReducer'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    customers: customersReducer,
    roles: rolesReducer,
    customerForm: customerFormReducer,
    context: contextReducer,
    templateData: templateReducer,
    userManagement: userManagementReducer,
    rolesAndFeature: rolesAndFeatureReducer,
    users: userReducer,
    organization: organizationReducer,
    admin: adminReducer,
    toast: toastReducer,
    permissionSet: permissionSetReducer,
    configuration: configurationReducer,
    survey: surveyReducer,
    initialLoadData: appInitializeReducer,
    mobileConfiguration: mobileConfigurationReducer,
    consent: consentReducer,
    metadata: metadataReducer,
    sysSetting: sysSettingReducer,
    migrationExport: migrationExportReducer,
    passwordPolicy: passwdPolicyReducer,
    bucketSlice: bucketSlice,
    tableSortSearchSlice:tableSortSearchSlice,
    templateTableReducer:templateTableReducer,
    carePlanData: carePlanReducer
  }
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
