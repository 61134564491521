import { createSlice } from '@reduxjs/toolkit';

export const PermissionSetSlice = createSlice({
  name: 'permissionSet',
  initialState: {
    permissionSetData: '' as any,
    permissionSetList: null as any,
    pagingOpt: null as any,
    noChanges: false
  },
  reducers: {
    setPermissionData: (state, action) => {
      state.permissionSetData = action.payload;
    },
    setPermissionSetList: (state, action) => {
      state.permissionSetList = action.payload;
    },
    setPagingOpt: (state, action) => {
      state.pagingOpt = action.payload;
    },
    setIsContentChanged: (state, action) => {
      state.noChanges = action.payload;
    }
  }
});

export const { setPermissionData, setPermissionSetList, setPagingOpt, setIsContentChanged } =
  PermissionSetSlice.actions;

export default PermissionSetSlice.reducer;
