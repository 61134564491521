import { useEffect, useState } from 'react';
import DotMenu, { Options } from '../primitives/dotMenu/DotMenu';
import ActionDropdown from '../primitives/dropdowns/ActionDropdown';
import Modal from '../shared/ModalPortal/Modal';
import NewInviteOrg from './NewInviteOrg';
import ManageContracts from './ManageContracts';
import userIcon from '../../assets/user-group.svg';
import ToastNotification from '../Reusable/ToastNotification';
import { MenuConstants } from '../../constants/MenuConstants';
import { useNavigate } from 'react-router-dom';
import { getBucketName } from '../../util/AppSetting';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../constants/AppConstants';
import { useAppSelector } from '../../app/hooks';
import { apiPermissionCheck } from '../../util/RoleUtil';
import { getOrganizationType } from '../../util/OrganizationUtil';
interface OrganizationMenuProps {
  className?: string;
  iconSize?: string;
  getOpenModalFunction?: Function;
  dropdownMenu?: boolean;
  organization: any;
}

const OrganizationMenu: React.FC<OrganizationMenuProps> = ({
  iconSize = '',
  organization,
  className = '',
  getOpenModalFunction = (fun: any) => {},
  dropdownMenu = false
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const options: Array<any> = [];
  const { id, name } = organization;
  const orgType = getOrganizationType(organization);

  const [selectedOption, setSelectedOption] = useState<Options | null>();
  const orgCreationPolicy: any =
    useAppSelector((state) => state.configuration.orgCreationPolicy) || {};
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const editAPIPermissions = [
    AppConstants.PERMISSION_ORGANIZATION_UPDATE,
    AppConstants.PERMISSION_ORGANIZATION_PATCH
  ];
  const editPermission = apiPermissionCheck(roleAPIPermissions, editAPIPermissions);
  if (editPermission) {
    options.push({
      display: `${t('T_EDIT_ORGANIZATION_DETAILS')}`,
      key: MenuConstants.MENU_EDIT_ORGANIZATION_DETAILS
    });
  }

  // const inviteAPIPermissions = [AppConstants.PERMISSION_UMS_INVITE_USER];
  // const invitePermission = apiPermissionCheck(roleAPIPermissions, inviteAPIPermissions);
  // if (invitePermission) {
  options.push({
    display: `${t('T_INVITE_USERS')}`,
    key: MenuConstants.MENU_INVITE_ADMINISTRATORS
  });
  // }

  const listUserAPIPermissions = [AppConstants.PERMISSION_UMS_LIST_USERS];
  const listUserPermission = apiPermissionCheck(roleAPIPermissions, listUserAPIPermissions);
  if (listUserPermission) {
    options.push({ display: `${t('T_VIEW_USERS')}`, key: MenuConstants.MENU_VIEW_ADMINISTRATORS });
  }

  const listTemplateAPIPermissions = [AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_LIST];
  const listTemplatePermission = apiPermissionCheck(roleAPIPermissions, listTemplateAPIPermissions);
  if (listTemplatePermission) {
    options.push({
      display: `${t('T_VIEW_TEMPLATES')}`,
      key: MenuConstants.MENU_VIEW_ORGANIZATION_TEMPLATES
    });
  }

  const creationPolicies = orgCreationPolicy[orgType] || [];
  const createOrgAPIPermissions = [AppConstants.PERMISSION_ORGNIZATION_CREATE];
  const createOrgPermission = apiPermissionCheck(roleAPIPermissions, createOrgAPIPermissions);
  if (createOrgPermission && creationPolicies.length) {
    options.push({
      display: `${t('T_CREATE_CHILD_ORGANIZATION')}`,
      key: MenuConstants.MENU_NEW_CHILD_ORGANIZATION
    });
  }

  // const listFileAPIPermissions = [AppConstants.PERMISSION_AMS_LIST_FILES];
  // const listFilesPermission = apiPermissionCheck(roleAPIPermissions, listFileAPIPermissions);
  // if (listFilesPermission && orgType !== 'Clinic') {
  //   options.push({
  //     display: `${t('T_MANAGE_CONTRACT')}`,
  //     key: MenuConstants.MENU_MANAGE_CONTRACTS
  //   });
  // }

  const listOrgSettingAPIPermissions = [AppConstants.PERMISSION_CONFIG_SERVICE_ORG_SETTING_LIST];
  const listOrgSettingPermission = apiPermissionCheck(
    roleAPIPermissions,
    listOrgSettingAPIPermissions
  );
  if (listOrgSettingPermission) {
    options.push({
      display: `${t('T_VIEW_SETTINGS')}`,
      key: MenuConstants.MENU_VIEW_SETTINGS,
      action: () => {
        navigate(`/organization/${id}/config-key-list`);
      }
    });
  }

  const listOrgCategoriesAPIPermissions = [
    AppConstants.PERMISSION_CONFIG_SERVICE_ORG_CATEGORIES_LIST
  ];
  const listOrgCategoriesPermission = apiPermissionCheck(
    roleAPIPermissions,
    listOrgCategoriesAPIPermissions
  );
  if (listOrgCategoriesPermission) {
    options.push({
      display: `${t('T_VIEW_CATEGORIES')}`,
      key: MenuConstants.MENU_VIEW_CATEGORIES,
      action: () => {
        navigate(`/organization/${id}/config-category-list`);
      }
    });
  }

  const [toasterMessage, setToasterMessage] = useState('');
  const [isToastActive, setIsToastActive] = useState(false);
  const [toasterType, setToasterType] = useState<any>('success');

  useEffect(() => {
    getOpenModalFunction(() => setSelectedOption);
  }, []);

  return (
    <>
      <ToastNotification
        message={toasterMessage}
        isActive={isToastActive}
        setIsActive={setIsToastActive}
        status={toasterType}
      />
      <div>
        {dropdownMenu ? (
          <ActionDropdown
            options={options}
            onSelectedOptionChange={(value: any) => {
              setSelectedOption(value);
            }}
            value={selectedOption}
          />
        ) : (
          <DotMenu
            options={options}
            onSelectedOptionChange={(value) => {
              setSelectedOption(value);
            }}
            value={selectedOption}
            containerClassName={className}
            iconSize={iconSize}
          />
        )}
      </div>

      {selectedOption?.key == MenuConstants.MENU_INVITE_ADMINISTRATORS && (
        <Modal
          showCloseIcon={true}
          width="60rem"
          headerText={selectedOption.display}
          toggleVisiblity={(value: boolean) => {
            setSelectedOption(null);
          }}
          isVisible={!!selectedOption}
          body={
            <NewInviteOrg
              toggleVisiblity={(value: boolean) => {
                setSelectedOption(null);
              }}
              organization={organization}
            />
          }
          headerLeftIcon={userIcon}
        />
      )}
      {selectedOption?.key == MenuConstants.MENU_MANAGE_CONTRACTS && (
        <ManageContracts
          toggleVisiblity={(value: boolean) => {
            setSelectedOption(null);
          }}
          isVisible={!!selectedOption}
          bucketName={getBucketName(id)}
          setIsToastActive={setIsToastActive}
          setToasterMessage={setToasterMessage}
          setToasterType={setToasterType}
        ></ManageContracts>
      )}
      {selectedOption?.key == MenuConstants.MENU_VIEW_ADMINISTRATORS &&
        navigate(`/admin-list/${id}`)}

      {selectedOption?.key == MenuConstants.MENU_EDIT_ORGANIZATION_DETAILS &&
        navigate(`/organization/edit/${id}`)}

      {selectedOption?.key == MenuConstants.MENU_VIEW_ORGANIZATION_TEMPLATES &&
        navigate(`/templates/${id}`)}
      {selectedOption?.key == MenuConstants.MENU_NEW_CHILD_ORGANIZATION &&
        navigate(`/organization/new?parentOrg=${id}`)}

      {selectedOption?.key === MenuConstants.MENU_VIEW_SETTINGS &&
        navigate(`/organization/${id}/config-key-list`)}

      {selectedOption?.key === MenuConstants.MENU_VIEW_CATEGORIES &&
        navigate(`/organization/${id}/config-category-list`)}
    </>
  );
};

export default OrganizationMenu;
