import React, { useEffect, useState } from 'react';
import Modal from '../../shared/ModalPortal/Modal';
import styled from 'styled-components';
import { AppConstants } from '../../../constants/AppConstants';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TemplateTable from '../../shared/tables/TemplateTable';
import { ITableHeader } from '../../shared/tables/models/ITableHeader';
import Checkbox from '../../shared/Fields/Checkbox';
import { ActionButtons } from '../../Reusable/ActionButton';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';

import { getQuestionTypeIcon, getQuestionTypeLabel } from '../../../util/surveyUtils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../context/themeContext';

import { EllipsisDiv } from '../../../styles/globalStyles';
import Stepper from '../../Reusable/Stepper';
import DynamicFormFieldRenderer from '../../Reusable/DynamicFormFieldRenderer';
import { surveyGroupQuestionFormSchema } from '../../../util/constants';
import { fieldIsEmpty, validateNameLength } from '../../../util/validators';

const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;
  min-height: 25vh;
  max-height: 85vh;
  overflow: auto;
`;

interface AddGroupQuestionDialogProps {
  questions: any;
  setQuestions: Function;
  closePopup: Function;
  addedQuestions: any;
  getNewQuestions: Function;
  paging: any;
  setPaging: Function;
  size: number;
  setSize: Function;
  currentPage: number;
  setCurrentPage: Function;
  pagingLoading: boolean;
  setPagingLoading: Function;
  closeModal: Function;
  isEdit: boolean;
}

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const StepperData = [
  { step: 1, title: 'T_NAME_AND_DESCRIPTION' },
  { step: 2, title: 'T_SELECT_QUESTIONS' }
];

const AddGroupQuestionDialog = ({
  questions,
  addedQuestions,
  setQuestions,
  closePopup,
  closeModal,
  getNewQuestions,
  paging,
  setPaging,
  size,
  setSize,
  currentPage,
  setCurrentPage,
  pagingLoading,
  setPagingLoading,
  isEdit
}: AddGroupQuestionDialogProps) => {
  const {
    text: qname = '',
    description: desc = '',
    item: group_question = []
  } = addedQuestions || {};

  const { t } = useTranslation();
  const questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);
  const [tempQuestions, setTempQuestions] = useState<any>(group_question);

  const [stepperData, setStepperData] = useState(StepperData);
  const [activeStep, setActiveStep] = useState(1);

  const [groupFormData, setGroupFormData] = useState<any>({
    questionName: qname,
    description: desc
  });

  const [groupFormError, setGroupFormError] = useState<any>({
    questionName: '',
    description: ''
  });

  const toggleCheckbox = (index: number, val: any) => {
    let copy = [...tempQuestions];
    let existIndex = tempQuestions.findIndex(
      (item: any) =>
        (item.text === val.question || item.question == val.question) &&
        (item.text === val.text || item.displayType === val.text)
    );
    if (existIndex === -1) {
      if (copy.length < 5) {
        copy.push(val);
        //   setQuestions([getGroupQuestionItem(copy)]);
        setTempQuestions(copy);
      }
    } else {
      copy.splice(existIndex, 1);
      //   setQuestions([getGroupQuestionItem(copy)]);
      setTempQuestions(copy);
    }
  };

  const getCount = () => {
    return tempQuestions.length;
  };

  const TABLE_HEADERS: Array<ITableHeader> = [
    {
      key: 'checked',
      description: '',
      sortEnabled: false,
      width: '5%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any, index: any) => {
        return (
          <>
            <Checkbox
              className="mx-auto"
              height="20px"
              width="20px"
              disabled={
                tempQuestions.length === 5 &&
                !tempQuestions.some((o: any) => {
                  return (
                    (o.text === data.question || o.question == data.question) &&
                    (o.text === data.text || o.displayType === data.text)
                  );
                })
              }
              checked={tempQuestions.some((o: any) => {
                return (
                  (o.text === data.question || o.question == data.question) &&
                  (o.text === data.text || o.displayType === data.text)
                );
              })}
              onChange={() => toggleCheckbox(index, data)}
              testID="addQuestionCheckbox"
            />
          </>
        );
      }
    },
    {
      key: 'question',
      description: 'T_QUESTION_NAME',
      sortEnabled: false, // TODO: Add Sort
      width: '75%',
      sortingInformation: {
        order: ''
      },
      render: (data: any) => {
        return <EllipsisDiv title={data?.question}>{data?.question}</EllipsisDiv>;
      },
      nastedData: false
    },
    {
      key: 'type',
      description: 'T_QUESTION_TYPE',
      sortEnabled: false, // TODO: Add Sort
      width: '20%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,

      render: (data: any, index: any) => {
        return (
          <div className="flex flex-row">
            <div className="mr-2 self-center">
              {getQuestionTypeIcon(data?.apiType, data?.displayType, questionTypes, data?.type)}
            </div>
            <div className="text-sm font-normal">
              {getQuestionTypeLabel(data?.apiType, data?.displayType, questionTypes, data?.type)}
            </div>
          </div>
        );
      }
    }
  ];

  const invokAPI = async (name: string, page: number, size: number) => {
    getNewQuestions(name, page, size);
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc'
  ) => {
    setTableValues(name, page, size, sort, sortType);
  };

  const setTableValues = (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc'
  ) => {
    setSize(size);
    setCurrentPage(page);
    setPagingLoading(true);
    invokAPI(name, page, size);
  };

  const validateFormData = () => {
    let errors: any = {
      questionName: '',
      description: ''
    };

    errors['questionName'] = fieldIsEmpty(groupFormData.questionName.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(groupFormData.questionName.trim(), 255)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG');
    errors['description'] = fieldIsEmpty(groupFormData?.description?.trim())
      ? ''
      : validateNameLength(groupFormData.description.trim(), 4096)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG_4096');

    setGroupFormError({ ...errors });
    return errors;
  };

  const AddGroupQuestionComp = () => {
    return (
      <>
        <CustomersTableContainer>
          <TemplateTable
            tableData={questions}
            size={size.toString()}
            currentPage={currentPage.toString()}
            templateSelector={() => {}}
            onRefreshTableData={refreshTableData}
            tableHeaders={TABLE_HEADERS}
            setTableHeaders={null}
            searchPlaceholder="Search for a question"
            containerClass="mt-0"
            isSearchable={false}
            tableName={t('T_SURVEY_SELECT_QUESTIONS')}
            pagingData={paging}
            theme={useTheme().theme}
          />
        </CustomersTableContainer>
      </>
    );
  };

  const GroupNameAndDescriptionForm = () => {
    return (
      <div className="mb-52 flex w-full flex-wrap px-24">
        <DynamicFormFieldRenderer
          layout={2}
          dynamicFormData={surveyGroupQuestionFormSchema}
          formData={groupFormData}
          setFormData={setGroupFormData}
          formError={groupFormError}
          editable={true}
          asterixFirst={true}
          disabled={isEdit}
        />
      </div>
    );
  };

  const checkValidation = () => {
    const errors = validateFormData();
    return (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    );
  };

  const PrimaryButtonActions = () => {
    const { questionName, description } = groupFormData;
    if (activeStep === 1) {
      const errors = validateFormData();
      if (checkValidation()) {
        return;
      } else {
        setActiveStep(2);
      }
    } else if (activeStep === 2) {
      setQuestions([...[getGroupQuestionItem(tempQuestions)]]);
      //refreshTableData('', AppConstants.DEFAULT_PAGE, AppConstants.DEFAULT_PAGE_SIZE);
      closeModal();
    }
  };

  const getGroupQuestionItem = (questionArr: any) => {
    const { questionName, description } = groupFormData;
    return {
      // questionName,
      // description,
      id: questionName.trim().replace(/\s+/g, '_')?.toLowerCase() + 'id-drag',
      type: AppConstants.QUESTION_TYPE_GROUP,
      apiType: AppConstants.QUESTION_TYPE_GROUP,
      text: questionName,
      prefix: '',
      linkId: '',
      required: false,
      readOnly: false,
      displayType: 'combination-group',
      item: questionArr,
      initial: [],
      description: description,
      answerOption: [],
      answerValueSet: null,
      questionnaireId: null,
      maxLength: null,
      enableWhen: null,
      enableBehavior: null,
      repeats: null,
      extension: {},
      definition: null,
      code: null
    };
  };

  const getPrimaryDisabled = (activeStep: any) => {
    const { questionName, description } = groupFormData;
    if (activeStep === 1) {
      if (fieldIsEmpty(questionName.trim())) {
        return true;
      } else if (!validateNameLength(questionName.trim(), 255)) {
        return true;
      } else if (!validateNameLength(description.trim(), 4096)) {
        return true;
      }
    }
    if (activeStep === 2) {
      return getCount() === 0;
    }
    return false;
  };

  return (
    <>
      <LoaderContainer isLoading={pagingLoading}>
        <Modal
          showHeader={true}
          showCloseIcon={true}
          width="75rem"
          headerText={t('T_SURVEY_SELECT_QUESTIONS')}
          toggleVisiblity={(value: boolean) => {
            //refreshTableData('', AppConstants.DEFAULT_PAGE, AppConstants.DEFAULT_PAGE_SIZE);
            closePopup();
            closeModal();
          }}
          isVisible={true}
          parentOverflowProperty="overflow-hidden"
          body={
            <>
              <Container>
                <div className="mb-5 mt-3">
                  <Stepper
                    stepperData={stepperData}
                    activeStep={activeStep}
                    className={`w-[260px]`}
                  />

                  {activeStep === 1 && GroupNameAndDescriptionForm()}

                  {activeStep === 2 && AddGroupQuestionComp()}
                </div>
              </Container>
              <ActionButtons
                primaryButtonDisabled={getPrimaryDisabled(activeStep)}
                primaryButtonLabel={
                  activeStep === 1
                    ? t('T_SURVEY_SELECT_QUESTIONS')
                    : `${t('T_CREATE_GROUP_QUESTION')} (${getCount()})`
                }
                primaryButtonAction={() => {
                  PrimaryButtonActions();
                }}
                secondaryButtonLabel={'Cancel'}
                secondaryButtonAction={() => {
                  // refreshTableData('', AppConstants.DEFAULT_PAGE, AppConstants.DEFAULT_PAGE_SIZE);
                  closePopup();
                  closeModal();
                }}
                primaryButtonWidth="full"
                primaryButtonClass={'px-4 mr-4'}
              />
            </>
          }
        />
      </LoaderContainer>
    </>
  );
};

export default AddGroupQuestionDialog;
