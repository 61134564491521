import SelectField from '../shared/Fields/SelectField';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import PickListWithApiSearch, { PICKLIST_TYPES } from '../shared/Fields/PickListWithAPISearch';
import PickList from '../shared/Fields/PickList';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { Label } from '../AccountProfile/ProfileStyles';
import _ from 'lodash';
import { AppConstants } from '../../constants/AppConstants';
import Checkbox from '../shared/Fields/Checkbox';

function SelectItems({
  data,
  formData,
  setFormData,
  formErrorData,
  index,
  formDataKey,
  type
}: any) {
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);
  const { t } = useTranslation();

  const READ_TYPE_OPTION = [
    { key: 'Read & write', value: t('T_READ_WRITE') },
    { key: 'Read Only', value: t('T_READ_ONLY') },
    { key: 'Owner', value: t('T_OWNER') }
  ];

  const isBucketOwner = () => {
    return (
      [userProfile?.userId, userProfile?.mail].includes(data?.value) &&
      data?.permissionKey === 'OWNER'
    );
  };

  const removeItem = (indexData: number, data: any[]) => {
    const arr = data || [];
    let rejectArr = [...arr];
    _.remove(rejectArr, (n, i) => {
      return i === indexData;
    });
    setFormData({ ...formData, [formDataKey]: rejectArr });
  };

  const { hard = false, soft = false } = data;

  return type === 'bucket' ? (
    <div className="mt-2 flex w-full">
      <div className="flex w-full rounded-md bg-[#F5F7FA] px-3">
        <div className="flex-1 leading-10" id="metadata-owners-value">
          {data?.value || 'Item name'}
        </div>
        <div className="w-40">
          <SelectField
            type="text"
            id="metadata-owners-option"
            label={''}
            placeholder={''}
            fieldKey={'permissionKey'}
            required={true}
            formData={data}
            setFormData={(ele: any) => {
              let perm = READ_TYPE_OPTION.filter((opt: any) => opt.value === ele.permissionKey);
              ele.permission = perm;
              let temp = formData[formDataKey];
              temp[index] = ele;

              setFormData({ ...formData, [formDataKey]: temp });
            }}
            formError={formErrorData}
            options={READ_TYPE_OPTION.map((option: any) => option.value)}
            maxHeight={false}
            fontSize={'16px'}
            disabled={false}
            style={{
              border: 'none',
              background: 'transparent',
              color: '#2C71AD'
            }}
          />
        </div>
      </div>

      <div className="flex w-[5%]">
        {!isBucketOwner() && (
          <DeleteIcon
            className="mx-auto mt-2 h-6 w-6 cursor-pointer text-gray-600 hover:text-black"
            onClick={() => {
              removeItem(index, formData[formDataKey]);
            }}
            data-testid="delete-icon"
            id="metadata-owners-delete"
          />
        )}
      </div>
    </div>
  ) : (
    <div className="mt-2 flex w-full">
      <div className="flex w-[90%] rounded-md bg-[#F5F7FA] px-3">
        <div className="flex-1">
          <div className="leading-10">{data?.value || '-'}</div>
          {data.permissionKey === 'READ & WRITE' && (
            <div>
              <div className="flex flex-1">
                <Label>{t('T_DELETE_PERMISSION')}:</Label>
                <Checkbox
                  className="ml-5 items-center"
                  height="20px"
                  width="20px"
                  checked={hard}
                  onChange={() => {
                    let temp = formData[formDataKey];
                    temp[index].hard = !hard;

                    setFormData({ ...formData, [formDataKey]: temp });
                  }}
                  testID={`hardDelete-${formDataKey}-${data?.value}`}
                />
                <Label className="ml-2">{t('T_HARD_DELETE_FOLDER')}</Label>

                {/* <Checkbox
                  className="ml-5 items-center"
                  height="20px"
                  width="20px"
                  checked={soft}
                  onChange={() => {
                    let temp = formData[formDataKey];
                    temp[index].soft = !soft;

                    setFormData({ ...formData, [formDataKey]: temp });
                  }}
                  testID={`softDelete-${formDataKey}-${data?.value}`}
                />
                <Label className="ml-2">{t('T_SOFT_DELETE_FOLDER')}</Label> */}
              </div>
            </div>
          )}
        </div>
        <div className={`w-40 items-center ${data.permissionKey === 'READ & WRITE' && 'pt-3'}`}>
          <SelectField
            type="text"
            id="permission"
            label={''}
            placeholder={''}
            fieldKey={'permissionKey'}
            required={true}
            formData={data}
            setFormData={(ele: any) => {
              let perm = READ_TYPE_OPTION.filter((opt: any) => opt.value === ele.permissionKey);
              ele.permission = perm;
              let temp = formData[formDataKey];
              temp[index] = ele;

              setFormData({ ...formData, [formDataKey]: temp });
            }}
            formError={formErrorData}
            options={READ_TYPE_OPTION.map((option: any) => option.value)}
            maxHeight={false}
            fontSize={'16px'}
            disabled={false}
            style={{
              border: 'none',
              background: 'transparent',
              color: '#2C71AD'
            }}
          />
        </div>
      </div>
      <div className="flex w-[10%]">
        <DeleteIcon
          className="mx-auto mt-2 h-6 w-6 cursor-pointer text-gray-600 hover:text-black"
          onClick={() => {
            removeItem(index, formData[formDataKey]);
          }}
          data-testid="delete-icon"
        />
      </div>
    </div>
  );
}

interface SubItemForRoleAndUserProps {
  formData: any;
  formError: any;
  setFormData: Function;
  formDataKey: string;
  optionsList: Array<any>;
  fetchDataAPICall: Function;
  searchPlaceholder?: string;
  hasMore: boolean;
  setHasMore?: Function;
  isLoading?: boolean;
  cleanup?: Function;
  setIsLoading?: Function;
  setOptionsList: Function;
  type: 'bucket' | 'folder';
}

export function SubItemForRoleAndUser({
  formData,
  formError,
  setFormData,
  formDataKey,
  optionsList,
  setOptionsList,
  fetchDataAPICall,
  searchPlaceholder = '',
  hasMore,
  setHasMore = () => {},
  isLoading = false,
  setIsLoading = () => {},
  cleanup = () => {},
  type
}: SubItemForRoleAndUserProps) {
  const { t } = useTranslation();

  const READ_TYPE_OPTION = [
    { key: 'Read & write', value: t('T_READ_WRITE') },
    { key: 'Read Only', value: t('T_READ_ONLY') },
    { key: 'Owner', value: t('T_OWNER') }
  ];

  const [selectionData, setSelectionData] = useState<any>({
    selectedItems: [],
    permission: [READ_TYPE_OPTION[0]]
  });

  const [page, setPage] = useState<number>(AppConstants.DEFAULT_PAGE);
  const [searchString, setSearchString] = useState<string>('');
  const [hard, setHard] = useState<boolean>(false);
  const [soft, setSoft] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      cleanup();
      setPage(AppConstants.DEFAULT_PAGE);
      setSearchString('');
    };
  }, []);

  const fetchMoreDataFun = (name: string = '') => {
    if (searchString === name) {
      if (formDataKey === 'selectedRole') {
        fetchDataAPICall(name, 1, formData?.[formDataKey]);
      } else {
        fetchDataAPICall(name, page + 1, [], formData?.[formDataKey]);
      }
      setPage(page + 1);
    } else {
      setSearchString(name);
      setPage(1);
      if (formDataKey === 'selectedRole') {
        fetchDataAPICall(name, 1, formData?.[formDataKey]);
      } else {
        fetchDataAPICall(name, 1, [], formData?.[formDataKey]);
      }
    }
  };

  return (
    <div>
      <div className={`flex ${type === 'bucket' ? 'flex-row' : 'flex-col'}`}>
        <div className={`flex flex-row ${type === 'bucket' && 'w-full'}`}>
          <div
            className={type === 'bucket' ? 'w-2/3' : 'w-3/4'}
            key={JSON.stringify(formData[formDataKey])}
          >
            <PickListWithApiSearch
              fieldKey={'key'}
              fieldValues={['value']}
              selectedItems={selectionData.selectedItems}
              onSelectedChange={(items: any) => {
                setSelectionData({ ...selectionData, selectedItems: items });
              }}
              items={
                optionsList.map((item: any) => {
                  return { key: item.id, value: item.name };
                }) || []
              }
              disabled={false}
              readOnly={false}
              type={PICKLIST_TYPES.MULTI}
              searchable={true}
              fetchMoreData={fetchMoreDataFun}
              hasMore={hasMore}
              searchPlaceholder={searchPlaceholder}
              isLoading={isLoading}
              noDataMessage={t('T_NO_SEARCH_DATA_MSG')}
            />
          </div>
          <div className={`${type === 'bucket' ? 'w-1/6' : 'w-3/12'} min-w-[160px] !text-primary`}>
            <PickList
              fieldKey={'key'}
              fieldValues={['value']}
              selectedItems={selectionData.permission}
              onSelectedChange={(items: any) => {
                setSelectionData({ ...selectionData, permission: items });
              }}
              items={READ_TYPE_OPTION}
              disabled={false}
              readOnly={false}
              type={PICKLIST_TYPES.SINGLE}
            />
          </div>
        </div>

        <div className={type === 'bucket' ? 'w-1/6 text-center' : 'mt-4 flex'}>
          {type === 'folder' ? (
            <div className="flex flex-1">
              {selectionData.permission[0].value === 'READ & WRITE' && (
                <>
                  <Label>{t('T_DELETE_PERMISSION')}:</Label>
                  <Checkbox
                    className="ml-5 items-center"
                    height="20px"
                    width="20px"
                    checked={hard}
                    onChange={() => {
                      setHard(!hard);
                    }}
                    testID={`hardDelete-${formDataKey}`}
                  />
                  <Label className="ml-2">{t('T_HARD_DELETE_FOLDER')}</Label>

                  {/* <Checkbox
                className="ml-5 items-center"
                height="20px"
                width="20px"
                checked={soft}
                onChange={() => {
                  setSoft(!soft);
                }}
                testID={`softDelete-${formDataKey}`}
              /> */}
                  {/* <Label className="ml-2">{t('T_SOFT_DELETE_FOLDER')}</Label> */}
                </>
              )}
            </div>
          ) : (
            ''
          )}
          <BiButton
            className={`${type === 'folder' ? 'w-24' : ''} bg-primary text-white`}
            type="button"
            onClick={() => {
              let temp = selectionData.selectedItems.map((ele: any) => {
                ele['permission'] = selectionData.permission;
                ele['permissionKey'] = selectionData.permission[0].value;
                if (type === 'folder') {
                  ele['hard'] = hard;
                  ele['soft'] = soft;
                }
                return ele;
              });
              setFormData({ ...formData, [formDataKey]: [...formData[formDataKey], ...temp] });
              setSelectionData({
                selectedItems: [],
                permission: [READ_TYPE_OPTION[0]]
              });
              setOptionsList([]);
              setHasMore(false);
              setIsLoading(false);
            }}
            disabled={selectionData.selectedItems.length === 0}
          >
            {t('T_ADD')}
          </BiButton>
        </div>
      </div>
      <div className="mt-10 h-[38rem] overflow-y-scroll border-t-[0.5px] border-solid border-[#8c8c8c] py-3">
        <Label className="mb-5" id="metadata-label-owners" data-testId="metadata-label-owners">
          {type === 'bucket' ? t('T_BUCKET_OWNERS') : t('T_FOLDER_OWNERS')}
        </Label>
        {(formData[formDataKey] || []).map((ele: any, index: number) => {
          return (
            <SelectItems
              id="metadata-owners-select"
              data={ele}
              key={ele.key || index}
              formData={formData}
              setFormData={setFormData}
              formErrorData={formError}
              index={index}
              formDataKey={formDataKey}
              type={type}
            />
          );
        })}
      </div>
    </div>
  );
}
