import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableRow } from '../shared/tables/TemplateTableStyles';

type Props = {
  message?: string;
};

const NoPermissionMessage = ({ message }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="font-bold">{message ? message : t('T_NO_PERMISSION_TO_VIEW_THIS_PAGE')}</div>
    </div>
    // <Table className="flex flex-1 items-center justify-center">
    //   <TableBody>
    //     <TableRow className="font-bold	">
    //       {message ? message : t('T_NO_PERMISSION_TO_VIEW_THIS_PAGE')}
    //     </TableRow>
    //   </TableBody>
    // </Table>
  );
};

export default NoPermissionMessage;
