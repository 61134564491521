import React from 'react';

type LoaderContainerProps = {
  children: React.ReactNode;
  isLoading: boolean;
};

const LoaderContainer = ({ children, isLoading }: LoaderContainerProps) => {
  return isLoading ? (
    <>
      <div className="fixed top-0 left-0 z-40 flex h-full w-full flex-col items-center justify-center bg-halfBlack">
        <div
          className="spinner-border inline-block h-8 w-8 animate-spin rounded-full border-4 text-[#2C71AD]"
          role="status"
        />
        <h3 className="mt-2 font-semibold text-[#2C71AD]">Loading...</h3>
      </div>
      {children}
    </>
  ) : (
    <> {children} </>
  );
};

export default LoaderContainer;
