import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';

export const CustomComponent = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoaderContainer isLoading={isLoading}>
      <div></div>
    </LoaderContainer>
  );
};
