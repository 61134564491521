import React, { useEffect, useState } from 'react';
import { filter, isEqual, without } from 'lodash';
import Card from '../../../shared/cards/Card';
import LoaderContainer from '../../../shared/loaderContainer/LoaderContainer';
import DynamicFormFieldRenderer from '../../../Reusable/DynamicFormFieldRenderer';
import styled from 'styled-components';
import AnswerDetails from '../../Question/AnswerDetails';

import { clipboard, clipboardCheck } from '../../../../assets/components/svgs';

import { NewOrganizationFormLayout, questionSchema } from '../../../../util/constants';

const Container = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  min-height: 25vh;
  max-height: 75vh;
  overflow: auto;
`;

interface EditQuestionProps {
  editFrom: string;
  editedQuestion: any;
  updateQuestionnaire: Function;
}

interface QuestionData {
  questionType: string;
  questionName: string;
  question: string;
  questionDescription: string;
  questionStatus: string;
  answerValues: Boolean;
  answerOptions: Array<{ value: string; isSelected: string }>;
  type: string;
  //answerOptions: any;
}

const EditQuestion: React.FC<EditQuestionProps> = ({
  editFrom,
  editedQuestion,
  updateQuestionnaire
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [formError, setFormError] = useState<any>({
    questionType: '',
    questionName: '',
    question: '',
    questionDescription: ''
  });

  const [questionData, setquestionData] = useState<QuestionData>(editedQuestion);

  const [editQueSchema, setEditQueSchema] = useState<any>(questionSchema);

  const { questionType, type, answerOptions } = questionData;

  useEffect(() => {
    if (!isEqual(editedQuestion, questionData)) {
      updateQuestionnaire(questionData);
    }
  }, [questionData]);

  useEffect(() => {
    const modifiedQueSchema = filter(editQueSchema, (obj: any) => {
      return obj.id !== 'questionName' && obj.id !== 'questionDescription';
    });
    setEditQueSchema(modifiedQueSchema);
  }, []);

  return (
    <Container>
      <div className="flex w-full flex-col  overflow-visible  bg-gray-100 px-2 py-3">
        <div className="flex">
          <Card
            title={'Question Details'}
            icon={clipboard}
            alt={'question detail icon'}
            height={''}
            width={''}
            className="w-full"
          >
            <div className="flex w-full flex-wrap">
              <DynamicFormFieldRenderer
                dynamicFormData={editQueSchema}
                formData={questionData}
                setFormData={setquestionData}
                formError=""
                layout={NewOrganizationFormLayout.twoColumn}
                editable={true}
              />
            </div>
          </Card>
        </div>
        {type !== 'Text' && type !== 'display' && (
          <div className="flex">
            <Card
              title={'Answer Details'}
              icon={clipboardCheck}
              alt={'answer detail icon'}
              height={''}
              width={''}
              className="w-full"
            >
              <div className="flex w-full flex-wrap">
                <AnswerDetails
                  formData={questionData}
                  setFormData={setquestionData}
                  formError={formError}
                  questionType={type}
                  setFormError={setFormError}
                  editMode={false}
                  editFromSurveyTemplate={true}
                />
              </div>
            </Card>
          </div>
        )}
      </div>
    </Container>
  );
};

export default EditQuestion;
