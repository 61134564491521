import TabDrawer from '../Reusable/TabDrawer';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/queryParams';
import { useTranslation } from 'react-i18next';
import FieldGroupList from './fieldGroupList';
import ConsentTemplateList from './consentTemplateList';

interface ConsentContainerProps {
  defaultTab?: string;
}

const ConsentContainer = ({ defaultTab }: ConsentContainerProps) => {
  const query = useQuery();
  const params = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState(defaultTab || 'Field Groups');

  const tabPaths: any = {
    'Field Groups': '/consents/fieldgroup',
    'Consent Templates': '/consents'
  };

  const tabConfig = [
    {
      label: `Field Groups`,
      component: <FieldGroupList />
    },
    {
      label: `Consent Templates`,
      component: <ConsentTemplateList />
    }
  ];

  const ChangeTab = (tab: string) => {
    navigate(tabPaths[tab]);
    setActiveTab(tab);
  };

  return (
    <div style={{ width: '100%' }}>
      <TabDrawer
        borderBottom={false}
        tabConfig={tabConfig}
        activeTab={activeTab}
        setActiveTab={ChangeTab}
        style={{
          width: '100%',
          background: '#fff',
          height: '48px',
          borderBottom: '0.5px solid',
          paddingTop: '2px'
        }}
        childStyle={{ height: 'calc(100vh - 9rem)' }}
      />
    </div>
  );
};

export default ConsentContainer;
