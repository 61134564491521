import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import TimesCircleIcon from '../../../assets/times-circle.svg';
import { Switch, ToggleButton } from '../../shared/Fields/Switch';
import Checkbox from '../../shared/Fields/Checkbox';
import { FileInfo } from '../../FileManagement/UploadFiles';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../../constants/AppConstants';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { cloneDeep, isEmpty, isEqual, isUndefined } from 'lodash';
import FileMetaData, { FileMetaDataListing } from '../../FileManagement/FileMetaData';
import { randomNumber } from '../../FileManagement/BucketUtil';

const ErrorLabel = styled.span`
  font-size: 11px;
  color: red;
  font-weight: 400;
`;

type CardProps = {
  id: string;
  item: FileInfo;
  disabled?: boolean;
  bucketVersioning?: boolean;
  publicObject?: boolean;
  customClass?: string;
  rightIcon?: string;
  updateFunction?: Function;
  deleteFunction?: Function;
  showMetadata?: any;
  disableOptions?: boolean;
};

const getFailureMessage = (issues: Array<any> = [], t: any) =>
  issues.map((issue: any) => {
    const { code, details } = issue;
    return (
      <>
        <ErrorLabel>{`${
          isEqual(code, AppConstants.AMS_PARAM_DUPLICATE_BUCKET)
            ? t('T_AMS_PARAM_DUPLICATE_FILE_MSG')
            : details
        }`}</ErrorLabel>
      </>
    );
  });
const Card = ({
  id,
  item,
  disabled = false,
  customClass,
  rightIcon,
  updateFunction,
  deleteFunction = () => {},
  bucketVersioning = false,
  showMetadata = false,
  disableOptions = false
}: CardProps) => {
  const { t } = useTranslation();
  const { file, versioning, publicObject, metadata = {}, issues = [] } = item;

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [metaDataEntries, setMetaDataEntries] = useState<Array<any>>([]);

  const addFileMetaDataItem = () => {
    const name: string = '';
    const value: string = '';
    setMetaDataEntries([
      ...metaDataEntries,
      {
        id: randomNumber(),
        name,
        value
      }
    ]);
  };

  const removeFileMetaDataItem = (index: number) => {
    if (isUndefined(index) || index < 0 || index > metaDataEntries.length) {
      return;
    }
    let cloneArr = cloneDeep(metaDataEntries);
    cloneArr.splice(index, 1);

    setMetaDataEntries(cloneArr);
  };

  const updateFileMetaDataItem = (index: number, item: any) => {
    if (isUndefined(index) || index < 0 || index > metaDataEntries.length) {
      return;
    }
    metaDataEntries.splice(index, 1);
    metaDataEntries.splice(index, 0, item);
    setMetaDataEntries([...metaDataEntries]);
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  useEffect(() => {
    if (showMetadata) {
      let metadataObj: any = {};
      metaDataEntries.forEach((item: any) => {
        const { value = '', name = '' } = item;
        if (value.length && name.length) {
          metadataObj[name] = value;
        }
      });
      if (updateFunction) {
        updateFunction(id, { ...item, metadata: metadataObj });
      }
    }
  }, [metaDataEntries]);

  return (
    <div
      key={`file-${id}`}
      data-testid="card-elements"
      className="mb-4 rounded-xl border border-gray-200"
    >
      <div className={`${customClass} ${isAccordionOpen && 'pb-2'} ${!showMetadata && 'px-4'}`}>
        {showMetadata ? (
          <div className="ml-2 cursor-pointer" onClick={toggleAccordion}>
            <ChevronDownIcon className={`h-6 w-6 ${isAccordionOpen ? 'rotate-180' : ''}`} />
          </div>
        ) : (
          ''
        )}
        <div
          id={`item-${id}`}
          data-testId={`item-${id}`}
          className={`w-[60%] ${
            disabled && 'opacity-30'
          } overflow-hidden text-ellipsis whitespace-nowrap`}
        >
          {file?.name?.substring(file?.name.indexOf('/') + 1)}
        </div>
        {!disableOptions ? (
          <div
            className={`w-[17%] ${
              !bucketVersioning || disabled ? 'opacity-30' : ''
            } flex flex-row overflow-hidden text-ellipsis whitespace-nowrap`}
          >
            <Switch
              className={`${!bucketVersioning || disabled ? `disabled` : ``}`}
              toggle={versioning}
              onClick={() => {
                if (!bucketVersioning || disabled) {
                  return;
                }
                updateFunction && updateFunction(id, { ...item, versioning: !versioning });
              }}
              data-testid={'boolean-field'}
              id={`versioning-${id}`}
            >
              <ToggleButton toggle={versioning} />
            </Switch>
            <div className="ml-3" data-testId="versioning-value">
              {versioning ? t('T_TRUE') : t('T_FALSE')}
            </div>
          </div>
        ) : (
          ''
        )}
        {!disableOptions ? (
          <div
            className={`w-[17%] ${
              disabled ? 'opacity-30' : ''
            } flex flex-row overflow-hidden text-ellipsis whitespace-nowrap`}
          >
            <Switch
              className={`${disabled ? `disabled` : ``}`}
              toggle={publicObject}
              onClick={() => {
                updateFunction && updateFunction(id, { ...item, publicObject: !publicObject });
              }}
              data-testid={'boolean-field'}
              id={'boolean'}
            >
              <ToggleButton toggle={publicObject} />
            </Switch>
            <div className="ml-3" data-testId="public-object-value">
              {publicObject ? 'True' : 'False'}
            </div>
          </div>
        ) : (
          ''
        )}
        {!disabled && (
          <div className={`flex ${disabled ? 'opacity-30' : ''}`}>
            <BiButton
              className={`text-blue-600 ${disabled ? 'cursor-not-allowed bg-transparent' : ''}`}
              onClick={() => deleteFunction(id)}
            >
              <img src={rightIcon ? rightIcon : TimesCircleIcon} alt="contract icon" />
            </BiButton>
          </div>
        )}
      </div>
      {isAccordionOpen && showMetadata ? (
        <div className="relative rounded-xl bg-gray-100 px-4 py-2">
          <FileMetaDataListing
            metadata={metadata}
            disabled={false}
            metadataEntries={metaDataEntries}
            deleteFunction={removeFileMetaDataItem}
            updateFunction={updateFileMetaDataItem}
            addItemFunction={addFileMetaDataItem}
          ></FileMetaDataListing>
        </div>
      ) : (
        ''
      )}
      {issues.length ? (
        <div className={`mx-auto mb-2 flex w-[95%] items-center overflow-y-scroll`}>
          {getFailureMessage(issues, t)}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default React.memo(Card);
