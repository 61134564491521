interface StatusTagProps {
  status: string;
}

const statusColor: any = {
  completed: {
    borderColor: '#91BFDB',
    background: '#E1ECF4'
  },
  success: {
    borderColor: '#86CF9B',
    background: '#dbf0e1'
  },
  failed: {
    borderColor: '#D73027',
    background: '#EFCFD0'
  },
  default: {
    borderColor: '#91BFDB',
    background: '#E1ECF4'
  }
};

const StatusTag = ({ status }: StatusTagProps) => {
  return (
    <div
      className="w-[7.5rem] rounded-sm border border-solid px-2 py-[0.063rem] text-center text-sm font-light"
      style={statusColor[status] || statusColor.default}
    >
      {status}
    </div>
  );
};

export default StatusTag;
