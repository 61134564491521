import { useEffect, useState } from 'react';

import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { AppConstants } from '../../constants/AppConstants';
import { useTranslation } from 'react-i18next';
import { setURLPaths } from '../../app/organizationReducer';
import { useNavigate } from 'react-router-dom';
import { setSelectedConfig } from '../../app/mobileConfigurationReducer';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import TemplateTable from '../shared/tables/TemplateTable';
import { getCatalogConfigData } from './MobileConfigUtil';
import { RouteConstants } from '../../constants/RouteConstants';
import { isEmpty } from 'lodash';

/**
 * @parentId mobile-configuration
 * @manager Mobile Configuration Catalog Listing
 * @overview
 *   <section>
 *       <p>
 *          Mobile Configuration Catalog listing page is the default page the user is navigated on clicking <b>Mobile Platform</b> navigation bar menuitem. The Mobile Configuration Listing displays the metadata catalog information. This Catalog holds various configuration for the mobile app setting.
 *           Since this Catalog Listing holds a limited number of items there is no pagination visible.
 *       </p>
 *       </br>
 *       <p>
 *           Admin user(s) View/Edit by clicking on the desired Catalog Item from datagrid,
 *           which will navigate user to specific catalog item page.
 *       </p>
 *       </br>
 *       <p>
 *           Datagrid will display details of each mobile configuration catalog as row item with each column representing different catalog configurations.
 *           <br>
 *           <ul>
 *               <li>Category</li>
 *               <li>Name</li>
 *               <li>Version</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *       </p>
 *       <p>
 *          The Mobile Configuration Listing displays the metadata catalog information. This Catalog holds various configuration for the mobile app setting.
 *          Mobile Configuration Listing has 4 primary catalog items,
 *          <ul>
 *              <li>
 *                  <b>App Config:</b>
 *                  <br>
 *                  <p>
 *                    This catalog item holds settings for App Configuration settings. These may include anything related to the display settings, validations, fields to display and other configuration settings.
 *                  </p>
 *                </li>
 *              <li>
 *                  <b>Styles Config:</b>
 *                  <br>
 *                  <p>
 *                    This catalog item holds styling settings for the App. These include Font Family, Colour Settings and other styling related settings.
 *                  </p>
 *               </li>
 *              <li>
 *                <b>File:</b>
 *                 <br>
 *                 <p>
 *                   This catalog item manages control over various file used for the functioning of the app. These may include various kinds of logo files and other files used for the app functioning.
 *                 </p>
 *              </li>
 *              <li>
 *                <b>Language Translations:</b>
 *                 <br>
 *                 <p>
 *                   This catalog item manages control over the translation language pack files used for the app localization.
 *                 </p>
 *              </li>
 *          </ul>
 *       </p>
 *       <p>
 *           Sorting mobile configuration catalog is not supported on any column. The breadcrumb bar on the application toolbar will reflect location of the user in the admin portal. Clicking on mobile configuration catalog row item will drill down user to specific catalog details page.
 *       </p>
 *     </section>
 *     <section>
 *     <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the List Mobile Configuration Catalog(s) Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *     <h4>Dependent System settings, Platform services & Role Permission</h3>
 *     <p>This section describes the list of dependent system settings & platform services required for functioning of mobile configuration catalog listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *     <h5>Platform Service(s)</h4>
 *     <p>Table lists all the dependent platform service(s) with specific version(s) for Metadata application listing</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.1.0</td>
 *           </tr>
 *           <tr>
 *             <td>User Management Service</td>
 *             <td>1.1.0</td>
 *         </tr>
 *       </table>
 *       <br>
 *     <h5>API Role Permission(s)</h4>
 *     <p>Table lists the required API role permissions for listing metadata applications page</p>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *         <tr>
 *             <td>/objects/{id}/instances</td>
 *             <td>GET</td>
 *             <td>metadata-service.metadata-instance.list</td>
 *             <td>Yes</td>
 *         </tr>
 *           <tr>
 *             <td>/users</td>
 *             <td>GET</td>
 *             <td>user-management-service.user.list</td>
 *             <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *   <p>Sequence Diagram for mobile configuration catalog listing</p>
 *   </section>
 */

const MobileConfigurationListComponent = () => {
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [rows, setRows] = useState<any>([]);
  const [paging, setPaging] = useState<any>(null);
  const [size, setSize] = useState<number>(AppConstants.DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState<number>(AppConstants.DEFAULT_PAGE);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      setBreadCrumb();
      await getCatalog(currentPage, size);
    };
    init();
  }, []);

  const customNavigate = (path: string, data: any) => {
    dispatch(setSelectedConfig(data));
    navigate(path);
  };

  const getCatalog = async (page: number, size: number, sort: string = AppConstants.DEFAULT_SORT_BY, sortType: string = AppConstants.DEFAULT_SORT_DESC) => {
    await getCatalogConfigData(
      metaDataObjectList,
      dispatch,
      true,
      (data: any, paging: any) => {
        setRows(data);
        setPaging(paging);
        setIsLoading(false);
      },
      (paging: any) => {
        noDataAvailableMessage(setRows, setSize, setPaging, paging, t);
        setIsLoading(false);
      },
      { page, size },
      [ sort, sortType ]
    );
  };

  const selectConfig = (data: any) => {
    switch (
      data.displayName //TODO: To change it from metadata route
    ) {
      case 'Styles Config':
        customNavigate(RouteConstants.ROUTE_MOBILE_CONFIG_STYLE, data);
        break;
      // case 'Language':
      //   customNavigate('/mobile-configuration/language', data);
      //   break;
      case 'App Config':
        customNavigate(RouteConstants.ROUTE_MOBILE_CONFIG_CONFIGURATION, data);
        break;
      case 'File':
        customNavigate(RouteConstants.ROUTE_MOBILE_CONFIG_FILE, data);
        break;
      case 'Language Translations':
        customNavigate(RouteConstants.ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION, data);
        break;
      default:
        return;
    }
  };

  const setBreadCrumb = () => {
    dispatch(
      setURLPaths([
        ...storedURLPaths,
        {
          key: RouteConstants.ROUTE_MOBILE_CONFIG_LIST,
          label: `${t('T_MOBILE_CONFIGURATION_PLATFORM')} > ${t(
            'T_MOBILE_CONFIGURATION_COMPONENTS'
          )}`
        }
      ])
    );
  };

  const TABLE_HEADERS: Array<ITableHeader> = [
    {
      key: 'category',
      description: t('T_CATEGORY'),
      sortEnabled: false,
      width: '15%',
      sortingInformation: {
        order: ''
      },
      nastedData: false
    },
    {
      key: 'displayName',
      description: t('T_NAME'),
      sortEnabled: false,
      width: '30%',
      sortingInformation: {
        order: ''
      },
      nastedData: false
    },
    {
      key: 'version',
      description: t('T_VERSION'),
      sortEnabled: false,
      width: '15%',
      sortingInformation: {
        order: ''
      },
      nastedData: false
    },
    {
      key: 'updatedOn',
      description: t('T_LAST_MODIFIED_ON'),
      sortEnabled: false,
      width: '40%',
      sortingInformation: {
        order: ''
      },
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: true,
    //   clickable: true,
    //   width: '15%',
    //   sortingInformation: {
    //     order: ''
    //   },
    //   styles: {
    //     notCursor: true
    //   }
    // },
  ];

  const refreshTableData = (
    name: string = '',
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc'
  ) => {
    if(!isEmpty(sort)){
      setTableValues(name, page, size, sort, sortType);
    }else{
      setTableValues(name, page, size);
    }
  };

  const setTableValues = (
    name: string = '',
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setSize(size);
    setCurrentPage(page);
    setIsLoading(true);
    getCatalog(page, size, sort, sortType);
  };

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(TABLE_HEADERS);
  return (
    <LoaderContainer isLoading={isLoading}>
      <div className="w-full p-4">
        <TemplateTable
          tableData={rows}
          size={size.toString()}
          currentPage={currentPage.toString()}
          templateSelector={selectConfig}
          onRefreshTableData={refreshTableData}
          isSearchable={false}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={paging}
          containerClass="mt-0"
          showDeleteButton={true}
        />
      </div>
    </LoaderContainer>
  );
};

export default MobileConfigurationListComponent;
