import BaseAPI from './api';
import env from '../environment';
import { SystemSetting } from '../util/RoleUtil';
import { AppConstants } from '../constants/AppConstants';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

interface category {
  name: string;
  description?: string;
}

export const getSystemSettings = async (
  category?: string,
  dataType?: string,
  page: number = 1,
  size: number = 10,
  sort: Array<string> = [],
  name: string = ''
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.configurationPath}/system-settings`;
  uri += `?page=${page}&size=${size}`;
  if (category) {
    //uri += `&category=${category}`;
    uri +=
      '&' +
      category
        .split(',')
        .map((e: string) => `category=${e}`)
        .join('&');
  }
  if (dataType) {
    uri += `&data-type=${dataType}`;
  }
  if (sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }
  if (name) {
    uri += `&name=${name}`;
  }
  const response = await API.get(`${uri}`, { headers }).then((res) => res);
  return response;
};

export const getSettingById = async (id: string) => {
  const headers = { ...globalHeaders };
  const response = await API.get(`${env.configurationPath}/system-settings/${id}`, {
    headers
  }).then((res) => res);
  return response;
};

export const getCategories = async (
  orgId?: string,
  page: number = 1,
  size: number = 10,
  sort: Array<string> = []
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.configurationPath}/categories`;
  uri += `?page=${page}&size=${size}`;
  if (orgId) {
    uri += `&organization-id=${orgId}`;
  }
  if (sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }
  const response = await API.get(`${uri}`, { headers }).then((res) => res);
  return response;
};

export const getCategoryById = async (id: string) => {
  const headers = { ...globalHeaders };

  const response = await API.get(`${env.configurationPath}/categories/${id}`, {
    headers
  }).then((res) => res);
  return response;
};

export const createCategory = async (body: category): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.configurationPath}/categories`, body, { headers });
};

export const updateCategory = async (id: string, body: category): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.put(`${env.configurationPath}/categories/${id}`, body, { headers });
};

export const createSystemSetting = async (body: SystemSetting): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.configurationPath}/system-settings`, body, { headers });
};

export const updateSystemSetting = async (id: string, body: SystemSetting): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.put(`${env.configurationPath}/system-settings/${id}`, body, { headers });
};

export const overRideSystemSetting = async (id: string, body: SystemSetting): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.configurationPath}/organizations/${id}/settings`, body, { headers });
};

export const overRideUserSystemSetting = async (id: string, body: SystemSetting): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.configurationPath}/users/${id}/settings`, body, { headers });
};

export const deleteSystemSetting = async (id: string): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.delete(`${env.configurationPath}/system-settings/${id}`, { headers });
};

export const patchSystemSetting = async (id: string, body: any): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.patch(`${env.configurationPath}/system-settings/${id}`, body, { headers });
};

export const createMultipleSystemSetting = async (body: any): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.configurationPath}/system-settings/batch`, body, { headers });
};

export const updateMultipleSystemSetting = async (body: any): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.put(`${env.configurationPath}/system-settings/batch`, body, { headers });
};

export const getOrganizationSettings = async (
  orgId: string,
  category?: string,
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: Array<string> = []
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.configurationPath}/organizations/${orgId}/settings`;
  uri += `?page=${page}&size=${size}`;
  if (category) {
    //uri += `&category=${category}`;
    uri +=
      '&' +
      category
        .split(',')
        .map((e: string) => `category=${e}`)
        .join('&');
  }
  if (sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }
  return await API.get(`${uri}`, { headers }).then((res) => res);
};

export const getUserSettings = async (
  userId: string,
  category?: string,
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: Array<string> = []
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.configurationPath}/users/${userId}/settings`;
  uri += `?page=${page}&size=${size}`;
  if (category) {
    //uri += `&category=${category}`;
    uri +=
      '&' +
      category
        .split(',')
        .map((e: string) => `category=${e}`)
        .join('&');
  }
  if (sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }
  return await API.get(`${uri}`, { headers }).then((res) => res);
};

export const getOrganizationCategories = async (
  orgId: string,
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: Array<string> = []
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.configurationPath}/organizations/${orgId}/categories`;
  uri += `?page=${page}&size=${size}`;
  if (sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }
  return await API.get(`${uri}`, { headers }).then((res) => res);
};

export const createOrganizationSetting = async (orgId: string, body: any): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.configurationPath}/organizations/${orgId}/settings`, body, {
    headers
  });
};

export const deleteCategory = async (id: string): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.delete(`${env.configurationPath}/categories/${id}`, { headers });
};

export const deleteCategoryFromOrganization = async (
  orgId: string,
  categoryId: string
): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.delete(
    `${env.configurationPath}/organizations/${orgId}/categories/${categoryId}`,
    { headers }
  );
};

export const addCategoryToOrganization = async (
  orgId: string,
  categoryId: string
): Promise<any> => {
  const headers = { ...globalHeaders };
  return await API.post(
    `${env.configurationPath}/organizations/${orgId}/categories/${categoryId}`,
    {},
    { headers }
  );
};
