import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Label, Input, ViewPasswordEye } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';
import { formatPhoneNumber } from '../../../util/functions';
import { formatNameField } from '../../../util/validators';
import { useTranslation } from 'react-i18next';
import { eye } from '../../../assets/components/svgs';

interface NewOrganizationSampleData {
  name?: string;
  phone?: string;
  fax?: string;
  address?: string;
  city?: string;
  zip?: string;
  state?: string;
  country?: string;
  roleName?: string;
  createdBy?: string;
}

interface InputFieldProps {
  type: string;
  title?: string;
  id: string;
  label: string;
  placeholder: any;
  fieldKey: string;
  required: boolean;
  formData: NewOrganizationSampleData;
  setFormData: Function;
  formError: NewOrganizationSampleData;
  asterixFirst?: boolean;
  disabled?: boolean;
  className?: string;
  handleChange?: Function | null;
  onBlurhandler?: Function | null;
  min?: number;
  max?: number;
  autoComplete?: string;
  errorClass?: string;
  disableLabel?: boolean;
  validateField?: boolean;
  codeFieldKey: string;
  codeDisabled?: boolean;
}
const Image = styled.img`
  width: 8px;
  height: 8px;
`;

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

const ViewErrorContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: right;
  margin-left: auto;
  top: 28px;
  margin-right: 10px;
  right: 0;
`;
function PhoneField({
  type,
  id,
  label,
  placeholder,
  fieldKey,
  required,
  formData,
  setFormData,
  formError,
  asterixFirst,
  disabled,
  className,
  handleChange = null,
  onBlurhandler = null,
  min = 0,
  max = 0,
  autoComplete = '',
  errorClass = '',
  disableLabel = false,
  validateField = true,
  codeFieldKey,
  codeDisabled,
  title = ''
}: InputFieldProps) {
  const { t } = useTranslation();
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  let inputProps: any = {};
  if (min) {
    inputProps.minLength = min;
  }
  if (max) {
    inputProps.maxLength = max;
  }
  if (autoComplete) {
    inputProps.autoComplete = autoComplete;
  }
  return (
    <>
      {!disableLabel ? (
        <Label htmlFor={id}>
          {asterixFirst && required ? <Image src={Asterix} className="mr-1" /> : ''}
          {t(label)}
          {required && !asterixFirst ? <Image src={Asterix} /> : ''}
        </Label>
      ) : (
        ''
      )}
      <div className="flex w-full">
        <Input
          title={title}
          className="w-[4rem] !rounded-r-none !border-r-0"
          id={'country-code'}
          data-testId={`${t(label)}_input-field`}
          placeholder={t('+1')}
          required={required}
          value={
            formData[codeFieldKey as keyof NewOrganizationSampleData]
              ? formData[codeFieldKey as keyof NewOrganizationSampleData]
              : ''
          }
          onBlur={(e) => {
            if (onBlurhandler) {
              onBlurhandler(e);
            }
          }}
          onChange={(e) => {
            const COUNTRY_CODE_REGEX = /^(?=[^0]{2})\+[0-9]+$/;
            let { value = '' } = e.target;
            if (value && value[0] !== '+') {
              value = `+${value}`;
            }
            if (value === '+') {
              setFormData({ ...formData, [codeFieldKey]: '' });
              return;
            }

            if (COUNTRY_CODE_REGEX.test(value) || !value) {
              setFormData({ ...formData, [codeFieldKey]: value });
            }
          }}
          maxLength={4}
          disabled={fieldKey === 'type' || disabled || codeDisabled}
        />
        <Input
          className={`w-full !rounded-l-none ${className ? className : ''}`}
          type={viewPassword ? 'text' : type}
          id={id}
          data-testId={`${t(label)}_input-field`}
          placeholder={t(placeholder) || ''}
          required={required}
          value={
            formData[fieldKey as keyof NewOrganizationSampleData]
              ? formatPhoneNumber(formData[fieldKey as keyof NewOrganizationSampleData])
              : ''
          }
          onBlur={(e) => {
            if (onBlurhandler) {
              onBlurhandler(e);
            }
          }}
          onChange={(e) => {
            const ONLY_NUMBER_REGEX = /^[\(\+1\)]*[0-9\b]+$/;
            const COUNTRY_CODE_REGEX = /^(?=[^0]{2})\+[0-9]+$/;
            if (handleChange) {
              handleChange(e);
            } else if (fieldKey === 'phone' || fieldKey === 'fax') {
              if (
                e.target.value === '' ||
                ONLY_NUMBER_REGEX.test(e.target.value.replace(/[^\d]/g, ''))
              ) {
                // const formattedNumber = formatPhoneNumber(e.target.value);
                setFormData({ ...formData, [fieldKey]: formatPhoneNumber(e.target.value) });
              }
            } else {
              setFormData({ ...formData, [fieldKey]: e.target.value });
            }
          }}
          disabled={(fieldKey === 'type' ? true : false) || disabled}
          {...inputProps}
        />
      </div>
      {type === 'password' && (
        <ViewErrorContainer>
          <ViewPasswordEye
            onClick={() => {
              setViewPassword(!viewPassword);
            }}
          >
            <div className="m-auto w-fit">{eye}</div>
            <div className="-mt-[7px]	select-none">{viewPassword ? 'hide' : 'view'}</div>
          </ViewPasswordEye>
        </ViewErrorContainer>
      )}

      <ErrorLabel className={errorClass}>
        {formError[fieldKey as keyof NewOrganizationSampleData]
          ? formError[fieldKey as keyof NewOrganizationSampleData]
          : ''}
      </ErrorLabel>
    </>
  );
}

export default PhoneField;
