import { SystemSetting } from './RoleUtil';
import { getSettingById } from '../http/configuration-services';
import { HTTP_STATUS } from '../http/constants/http.status';
import { AppConstants } from '../constants/AppConstants';
import environment from '../environment';

export const filterApplicationSolutionSettings = (settingsData: any[]) => {
  let solutonSetting = settingsData.filter(
    (ele: any) => ele.key === AppConstants.SOLUTION_APPLICATION_SETTINGS
  );

  if (solutonSetting.length) {
    let solutionSettingsState = [];
    solutionSettingsState.push(
      ...solutonSetting[0].value.map((ele: any) => {
        return { id: ele.id, key: ele.key };
      })
    );
    sessionStorage.setItem(
      AppConstants.SOLUTION_APPLICATION_SETTINGS,
      JSON.stringify(solutionSettingsState)
    );
    return solutionSettingsState;
  }
  return null;
};

export const formatPayloadForAPICall = (data: SystemSetting) => {
  const { categories } = data;
  let payload: SystemSetting = data;
  payload.categories = categories?.map((ele: any) => ele?.id || ele);
  return payload;
};

export const getSolutionSettingsJSONString = async (setJsonString: Function) => {
  const solutionSettingReference = JSON.parse(
    sessionStorage.getItem(AppConstants.SOLUTION_APPLICATION_SETTINGS) || '[]'
  );

  let solutionSettingsState: SystemSetting[] = [];

  if (solutionSettingReference) {
    let settingPromises = solutionSettingReference?.value?.settings?.map((setting: any) => {
      return getSettingById(setting.id);
    });

    const response: any = await Promise?.all(settingPromises);
    solutionSettingsState.push(
      ...response
        .filter((res: any) => res.status === HTTP_STATUS.HTTP_OK)
        .map((res: any) => res.data)
    );
  }
  setJsonString(JSON.stringify(solutionSettingsState));
};

export const getSolutionSettingObject = () => {
  const solutionSettingReference = JSON.parse(
    sessionStorage.getItem(AppConstants.SOLUTION_APPLICATION_SETTINGS) || '{}'
  );

  return solutionSettingReference?.data;
};

export const getBucketName = (organizationId: string) => {
  let bucketName = '';
  if (environment.ENV_TENANT_NAME) {
    bucketName = `${environment.ENV_TENANT_NAME}-`;
  }
  if (environment.ENV_SOLUTION_NAME) {
    bucketName += `${environment.ENV_SOLUTION_NAME}-`;
  }
  return `${bucketName}${organizationId}`;
};

export const getGlobalBucketName = () => {
  let bucketName = '';
  if (environment.ENV_TENANT_NAME) {
    bucketName = `${environment.ENV_TENANT_NAME}-`;
  }
  return `${bucketName}${AppConstants.ASSET_MANAGEMENT_SERVICE}`;
};
