import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import TemplateTable from '../shared/tables/TemplateTable';
import { TableContainer } from '../Organizations/OrganizationsList';
import {
  getMetadataObject,
  getMetadataInstances,
  getApplication
} from '../../http/metadata-service';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { AppConstants } from '../../constants/AppConstants';
import { useTheme } from '../../context/themeContext';
import { setURLPaths } from '../../app/organizationReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { setApplication, setMetadataInstance, setMetadataObject } from '../../app/metadataReducer';
import { setToastData } from '../../app/toastReducer';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { cloneDeep, compact, debounce, findIndex, uniq } from 'lodash';
import { getFailureMessage } from '../../util/ErrorUtil';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import moment from 'moment';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { plusSign } from '../../assets/components/svgs';
import { RouteConstants } from '../../constants/RouteConstants';
import { deleteMetadataInstance } from '../../http/survey-service';
import DeleteConfirmModal from './DeleteConfirmModal';
import { EllipsisDiv } from '../../styles/globalStyles';
import Checkbox from '../shared/Fields/Checkbox';
import { changeMetadataComponent, setManifest } from '../../app/migrationExportReducer';
import { ActionButtons } from '../Reusable/ActionButton';
import AddCircle from '../../assets/addCircle.svg';

interface InstanceListProps {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const InstanceList = ({ type = 'listing', toggleModal = () => {} }: InstanceListProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState<any>([]);
  // const [reload, setReload] = useState(false);
  const [paging, setPaging] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deletableIndex, setDeletableIndex] = useState<number>(-1);

  const [size, setSize] = useState<number>(
    type === 'listing' ? AppConstants.DEFAULT_PAGE_SIZE : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
  );
  const [currentPage, setCurrentPage] = useState<number>(AppConstants.DEFAULT_PAGE);

  const application = useAppSelector((state) => state.metadata.application);
  const metadataObject = useAppSelector((state) => state.metadata.metadataObject);
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const metadataId = useAppSelector((state: any) => state.migrationExport.metadataComponent);
  const manifestAppData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.tempApplications
  );

  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const id = params?.id || metadataId?.id;
  const objectId = params?.objectId || metadataId?.objectId;

  const usersMap: Map<string, any> = new Map<string, any>();

  const isCheckboxSelected = (data: any) => {
    const { id } = data;
    return (
      findIndex(selectedItems, function (item: any) {
        return item.id === id;
      }) !== -1
    );
  };

  const toggleOverWrite = (data: any) => {
    let metadataApplication = JSON.parse(JSON.stringify(manifestAppData));
    const appIndex = findIndex(metadataApplication, function (item: any) {
      return item.id === id;
    });
    const objectIndex = findIndex(
      metadataApplication[appIndex].dependentArtifactConfigurations.objects,
      function (item: any) {
        return item.id === objectId;
      }
    );

    let existingId = findIndex(
      metadataApplication[appIndex].dependentArtifactConfigurations.objects[objectIndex]
        .dependentArtifactConfigurations.instances,
      function (item: any) {
        return item.id === data?.id;
      }
    );

    if (existingId !== -1) {
      metadataApplication[appIndex].dependentArtifactConfigurations.objects[
        objectIndex
      ].dependentArtifactConfigurations.instances[existingId]['overWrite'] =
        !metadataApplication[appIndex].dependentArtifactConfigurations.objects[objectIndex]
          .dependentArtifactConfigurations.instances[existingId]['overWrite'];
      dispatch(setManifest({ key: 'applications', value: metadataApplication }));
      dispatch(setManifest({ key: 'tempApplications', value: metadataApplication }));
      return;
    }
  };

  const toggleCheckbox = (data: any) => {
    const { id, label } = data;
    let existingArr: any = cloneDeep(selectedItems);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      existingArr.splice(existingId, 1);
      if (!existingArr.length) {
        setSelectedItems([{ id: 'ALL' }]);
        return;
      } else {
        setSelectedItems(existingArr);
        return;
      }
    }

    let allId = findIndex(existingArr, function (item: any) {
      return item.id === 'ALL';
    });
    if (allId !== -1) {
      existingArr.splice(allId, 1);
    }

    existingArr.push({
      id,
      label
    });

    setSelectedItems(existingArr);
  };

  const tableHeadersBuilder = () => {
    let TABLE_HEADERS: Array<ITableHeader> = [
      {
        key: 'label',
        description: 'T_LABEL',
        sortEnabled: type !== 'migrationPreview',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.label}>
              {data?.label}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '50%' : '100%'
      }
    ];

    switch (type) {
      case 'listing':
        const listingHeaders: Array<ITableHeader> = [
          // {
          //   key: 'createdBy',
          //   description: 'T_CREATED_BY',
          //   sortEnabled: true,
          //   width: '18%',
          //   clickable: true,
          //   sortingInformation: {
          //     order: ''
          //   },
          //   render: (row: any) => {
          //     return getUserName(usersMap.get(row?.createdBy)) || '';
          //   }
          // },
          {
            key: 'createdOn',
            description: 'T_CREATED_ON',
            sortEnabled: true,
            width: '25%',
            clickable: true,
            sortingInformation: {
              order: 'desc'
            },
            render: (row: any) => {
              return moment(row?.createdOn).format(AppConstants.DEFAULT_DATE_FORMAT);
            }
          },
          {
            key: 'updatedOn',
            description: 'T_LAST_MODIFIED_ON',
            sortEnabled: true,
            width: '25%',
            clickable: true,
            sortingInformation: {
              order: ''
            },
            render: (row: any) => {
              return moment(row?.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT);
            }
          }
        ];
        TABLE_HEADERS = [...TABLE_HEADERS, ...listingHeaders];

        if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_DELETE]) {
          TABLE_HEADERS.push({
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '6%',
            sortingInformation: {
              order: ''
            }
          });
        }

        break;

      // case 'migrationSelection':
      //   TABLE_HEADERS.unshift({
      //     key: 'checked',
      //     description: '',
      //     sortEnabled: false,
      //     width: '10%',
      //     sortingInformation: {
      //       order: ''
      //     },
      //     nastedData: true,
      //     render: (data: any, index: any) => {
      //       return (
      //         <>
      //           <Checkbox
      //             className="mx-auto"
      //             height="20px"
      //             width="20px"
      //             checked={isCheckboxSelected(data)}
      //             onChange={() => {
      //               toggleCheckbox(data);
      //             }}
      //             testID="addModuleCheckbox"
      //           />
      //         </>
      //       );
      //     }
      //   });
      //   break;
      // case 'migrationPreview':
      //   TABLE_HEADERS.push(
      //     {
      //       key: 'overWrite',
      //       description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
      //       sortEnabled: false,
      //       width: '10%',
      //       sortingInformation: {
      //         order: ''
      //       },
      //       nastedData: true,
      //       render: (data: any, index: any) => {
      //         return (
      //           <>
      //             <Checkbox
      //               className="mx-auto"
      //               height="20px"
      //               width="20px"
      //               onChange={() => {
      //                 toggleOverWrite(data);
      //               }}
      //               checked={data?.overWrite}
      //               testID="allowOverWriteCheckbox"
      //             />
      //           </>
      //         );
      //       }
      //     },
      //     {
      //       key: 'buttons',
      //       description: t('T_ACTIONS'),
      //       sortEnabled: false,
      //       width: '10%',
      //       sortingInformation: {
      //         order: ''
      //       }
      //     }
      //   );
      //   break;
    }

    return TABLE_HEADERS;
  };

  const [tableHeaders, setTableHeaders] = useState(tableHeadersBuilder());

  useEffect(() => {
    const init = async () => {
      if (!application) {
        await getMetadataApplication(id);
        await getMetadataApplnObject(id, objectId);
      }
      await getInstances(
        '',
        currentPage,
        size,
        AppConstants.DEFAULT_SORT_BY,
        AppConstants.DEFAULT_SORT_DESC
      );
      // setReload(false);
    };
    if (type === 'migrationPreview') {
      setIsLoading(false);
      return;
    }
    init();
    return () => {
      usersMap.clear();
    };
  }, []);

  useEffect(() => {
    const breadcrumb: Array<string> = ['Metadata', 'Instances'];
    if (application) breadcrumb.splice(1, 0, application?.name);
    if (metadataObject) breadcrumb.splice(2, 0, metadataObject?.name);

    const label: string = breadcrumb.join(' > ');
    const key: string = RouteConstants.ROUTE_MDS_INSTANCE_LIST.replace(':id', id).replace(
      ':objectId',
      objectId
    );

    dispatch(setURLPaths([{ key, label }]));
  }, [application, metadataObject]);

  useEffect(() => {
    if (manifestAppData?.length && objectId && id) {
      let appIndex = findIndex(manifestAppData, function (item: any) {
        return item.id === id;
      });
      if (appIndex !== -1) {
        let objectIndex = findIndex(
          manifestAppData[appIndex].dependentArtifactConfigurations.objects,
          function (item: any) {
            return item.id === objectId;
          }
        );
        if (manifestAppData[appIndex].dependentArtifactConfigurations.objects.length) {
          if (objectIndex !== -1) {
            setSelectedItems(
              manifestAppData[appIndex]?.dependentArtifactConfigurations?.objects[objectIndex]
                .dependentArtifactConfigurations.instances
            );
          } else {
            setSelectedItems(manifestAppData[appIndex]?.dependentArtifactConfigurations?.objects);
          }
        }
      }
    }
  }, [manifestAppData, metadataId]);

  useEffect(() => {
    if (type === 'migrationSelection') {
      setTableHeaders(tableHeadersBuilder());
    } else if (type === 'migrationPreview') {
      setTableHeaders(tableHeadersBuilder());
      setRows(selectedItems);
      setIsLoading(false);
    }
  }, [selectedItems]);

  const getMetadataApplication = async (id: string) => {
    const response = await getApplication(id);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setApplication(data));
    }
  };

  const getMetadataApplnObject = async (id: string, objectId: string) => {
    const response = await getMetadataObject(id, objectId);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setMetadataObject(data));
    }
  };

  const getInstances = async (
    labels: string = '',
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType?: string
  ) => {
    const sorters = new Array<string>();
    if (sort) {
      if (sortType) {
        sorters.push(`${sort},${sortType}`);
      } else {
        sorters.push(`${sort}`);
      }
    }
    const response = await getMetadataInstances(
      objectId as string,
      labels.split(','),
      page,
      size,
      sorters
    );
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: instances = [], paging } = data;
      if (instances.length) {
        const users: Array<string> = instances
          .filter((object: any) => !usersMap.get(object.createdBy))
          .map((object: any) => object.createdBy);

        if (users.length) {
          const userProfiles: Array<any> = await getUserProfiles(uniq(compact(users)));
          userProfiles.forEach((userProfile: any) => {
            usersMap.set(userProfile.userId, userProfile);
          });
        }
        setRows([...instances]);
        setPaging(paging);
      } else {
        noDataAvailableMessage(setRows, setSize, setPaging, paging, t);
      }
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setTableValues(name, page, size, sort, sortType);
  };

  const debouncedCall = useRef(
    debounce((name, page, size, sort, sortType) => {
      setSize(size);
      setCurrentPage(page);
      setIsLoading(true);
      getInstances(name, page, size, sort, sortType);
    }, 400)
  ).current;

  const setTableValues = (
    name: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    debouncedCall(name, page, size, sort, sortType);
  };

  const instanceSelector = (data: any) => {
    if (type === 'listing') {
      const { metadataObjectId, id } = data;
      dispatch(setMetadataInstance({ ...data }));
      // navigate(`/metadata/${metadataObjectId}/instance/${id}`);
      navigate(
        RouteConstants.ROUTE_MDS_INSTANCE_VIEW.replace(':id', application?.id)
          .replace(':objectId', metadataObjectId)
          .replace(':instanceId', id)
      );
    }
  };

  const removeMigrationObject = (data: any) => {
    let navigationFlag = false;
    let metadataApplication = cloneDeep(manifestAppData);
    const index = findIndex(metadataApplication, function (item: any) {
      return item.id === id;
    });

    let objIndex = findIndex(
      metadataApplication[index].dependentArtifactConfigurations.objects,
      function (item: any) {
        return item.id === objectId;
      }
    );

    let instanceIndex = findIndex(
      metadataApplication[index].dependentArtifactConfigurations.objects[objIndex]
        .dependentArtifactConfigurations.instances,
      function (item: any) {
        return item.id === data?.id;
      }
    );
    metadataApplication[index].dependentArtifactConfigurations.objects[
      objIndex
    ].dependentArtifactConfigurations.instances.splice(instanceIndex, 1);

    if (
      !metadataApplication[index].dependentArtifactConfigurations.objects[objIndex]
        .dependentArtifactConfigurations.instances.length
    ) {
      metadataApplication[index].dependentArtifactConfigurations.objects[
        objIndex
      ].dependentArtifactConfigurations.instances.push({ id: 'ALL' });
      navigationFlag = true;
    }
    dispatch(setManifest({ key: 'applications', value: metadataApplication }));
    dispatch(setManifest({ key: 'tempApplications', value: metadataApplication }));
    if (navigationFlag) {
      dispatch(changeMetadataComponent({ route: 'objects', id: id, objectId: '' }));
    }
  };

  const confirmDelete = (row: number, show: boolean = true) => {
    if (type === 'migrationPreview') {
      removeMigrationObject(rows[row + currentActivePage]);
      return;
    }
    setDeleteConfirmation(show);
    setDeletableIndex(row);
  };

  const deleteInstance = async (row: number) => {
    setIsLoading(true);
    const msg: string = t('T_METADATA_INSTANCE_DELELTE_SUCCESS');
    const { id, metadataObjectId, label } = rows[row];
    const response: any = await deleteMetadataInstance(metadataObjectId, id);
    const { status } = response;
    const success = HTTP_STATUS.isSuccess(status);
    dispatch(
      setToastData({
        toastMessage: success ? msg.replace('%s', label || id) : getFailureMessage(response),
        isToastActive: true,
        toastType: success ? 'success' : 'error'
      })
    );
    if (success) {
      const newRows = [...rows];
      newRows.splice(row, 1);
      setRows(newRows);
    }
    setDeletableIndex(-1);
    setIsLoading(false);
  };

  const cancelSelection = () => {
    dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
    toggleModal();
  };

  const saveSelection = () => {
    let metadataApplication = cloneDeep(manifestAppData);
    const appIndex = findIndex(metadataApplication, function (item: any) {
      return item.id === id;
    });
    const objectIndex = findIndex(
      metadataApplication[appIndex].dependentArtifactConfigurations.objects,
      function (item: any) {
        return item.id === objectId;
      }
    );
    metadataApplication[appIndex].dependentArtifactConfigurations.objects[
      objectIndex
    ].dependentArtifactConfigurations.instances = selectedItems;
    dispatch(setManifest({ key: 'applications', value: metadataApplication }));
    dispatch(setManifest({ key: 'tempApplications', value: [] }));
    dispatch(changeMetadataComponent({ route: 'metadata', id: '', objectId: '' }));
    toggleModal();
  };

  useEffect(() => {
    if (manifestAppData?.length && metadataId?.id && metadataId?.objectId) {
      const appIndex = findIndex(manifestAppData, function (item: any) {
        return item.id === id;
      });
      if (appIndex !== -1) {
        const objectIndex = findIndex(
          manifestAppData[appIndex].dependentArtifactConfigurations.objects,
          function (item: any) {
            return item.id === objectId;
          }
        );
        if (objectIndex !== -1) {
          setSelectedItems(
            manifestAppData[appIndex].dependentArtifactConfigurations.objects[objectIndex]
              .dependentArtifactConfigurations.instances
          );
        }
      }
    }
  }, [manifestAppData, metadataId]);

  useEffect(() => {
    if (type === 'migrationPreview') {
      setCurrentActivePage(currentPage * size - size);
    }
  }, [currentPage]);

  return (
    <LoaderContainer isLoading={isLoading}>
      <TableContainer className={`${type !== 'listing' && '!pb-0'}`}>
        {type === 'listing' ? (
          <MenuButtonsPortal>
            <BiButton
              className="bg-white text-primary"
              type="button"
              onClick={() => {
                navigate(
                  RouteConstants.ROUTE_MDS_OBJECT_VIEW.replace(':id', id).replace(
                    ':objectId',
                    objectId
                  )
                );
              }}
            >
              {t('T_BACK_TO_OBJECT').replace(
                '%s',
                metadataObject?.name.length < 50
                  ? metadataObject?.name
                  : `${metadataObject?.name.substring(0, 50)}...`
              )}
            </BiButton>
            {roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_CREATE] && (
              <BiButton
                className={'flex flex-row bg-primary text-white'}
                type="button"
                onClick={() => {
                  dispatch(setMetadataInstance(null));
                  navigate(
                    RouteConstants.ROUTE_MDS_INSTANCE_CREATE.replace(':id', id).replace(
                      ':objectId',
                      objectId
                    )
                  );
                }}
              >
                <img src={AddCircle} className="mr-2 w-6 text-center"></img>
                {t('T_NEW_METADATA_INSTANCE')}
              </BiButton>
            )}
          </MenuButtonsPortal>
        ) : (
          <BiButton
            className="bg-white text-primary"
            type="button"
            onClick={() => {
              dispatch(changeMetadataComponent({ route: 'objects', id: id, objectId: objectId }));
            }}
          >
            {t('T_BACK_TO_OBJECT').replace(
              '%s',
              metadataObject?.name.length < 50
                ? metadataObject?.name
                : `${metadataObject?.name.substring(0, 50)}...`
            )}
          </BiButton>
        )}
        <TemplateTable
          tableData={
            type === 'migrationPreview'
              ? rows.slice(currentActivePage, currentActivePage + size)
              : rows
          }
          onRefreshTableData={refreshTableData}
          size={size.toString()}
          currentPage={currentPage.toString()}
          isSearchable={type !== 'migrationPreview'}
          searchPlaceholder={t('T_SEARCH_INSTANCES_BY_NAME')}
          templateSelector={instanceSelector}
          cellSelector={instanceSelector}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={paging}
          theme={useTheme().theme}
          containerClass="mt-0"
          showDeleteButton={!!roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_DELETE]}
          deleteOnClick={confirmDelete}
          setCurrentPage={setCurrentPage}
          isLocalPaginator={type === 'migrationPreview'}
          totalDataLength={rows.length}
          isPaginated={type !== 'migrationPreview'}
          disablePageSize={type !== 'listing'}
        />
      </TableContainer>
      {deleteConfirmation && (
        <DeleteConfirmModal
          width={'35rem'}
          title={t('T_DELETE_CONFIRMATION_TITLE')}
          onClose={() => confirmDelete(-1, false)}
          children={
            <div>
              <span>
                {t('T_DELETE_CONFIRMATION_TEXT')}
                <b>{rows[deletableIndex]?.label} </b>
                {t('T_INSTANCE')}?
              </span>
            </div>
          }
          onCancel={() => confirmDelete(-1, false)}
          onConfirm={() => {
            setDeleteConfirmation(false);
            setTimeout(() => deleteInstance(deletableIndex));
          }}
        />
      )}
      {type === 'migrationSelection' ? (
        <div className="w-full">
          <ActionButtons
            primaryButtonLabel={t('T_SAVE')}
            primaryButtonAction={saveSelection}
            secondaryButtonLabel={t('T_CANCEL')}
            secondaryButtonAction={cancelSelection}
            primaryButtonWidth="full"
            primaryButtonClass={'px-4'}
          />
        </div>
      ) : (
        ''
      )}
    </LoaderContainer>
  );
};

export default React.memo(InstanceList);
