import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Options } from '../primitives/dotMenu/DotMenu';
import { MenuConstants } from '../../constants/MenuConstants';
import ActionDropdown from '../primitives/dropdowns/ActionDropdown';
import { RouteConstants } from '../../constants/RouteConstants';
import { useAppSelector } from '../../app/hooks';
import { AppConstants } from '../../constants/AppConstants';

interface IMetadataMenuProps {
  metadata: any;
  setEdit: Function;
}

const MetadataMenu = ({ metadata, setEdit }: IMetadataMenuProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<Options | null>();

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const { id } = metadata;
  const options: Array<any> = [];

  if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_APPN_UPDATE]) {
    options.push({
      display: `${t('T_EDIT_METADATA')}`,
      key: MenuConstants.MENU_EDIT_METADATA,
      action: () => {
        setEdit(true);
        navigate(RouteConstants.ROUTE_MDS_APPLN_VIEW.replace(':id', id));
      }
    });
  }

  if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_OBJECT_LIST]) {
    options.push({
      display: `${t('T_LIST_METADATA_OBJECTS')}`,
      key: MenuConstants.MENU_METADATA_LIST_OBJECT,
      action: () => {
        navigate(RouteConstants.ROUTE_MDS_OBJECT_LIST.replace(':id', id));
      }
    });
  }

  if (roleAPIPermissions[AppConstants.PERMISSION_METADATA_OBJECT_CREATE]) {
    options.push({
      display: `${t('T_ADD_METADATA_OBJECT')}`,
      key: MenuConstants.MENU_METADATA_ADD_OBJECT,
      action: () => {
        navigate(RouteConstants.ROUTE_MDS_OBJECT_CREATE.replace(':id', id));
      }
    });
  }

  useEffect(() => {
    if (selectedOption?.key == MenuConstants.MENU_EDIT_METADATA) {
      setEdit(true);
    }
  }, [selectedOption]);

  return (
    <>
      <ActionDropdown
        options={options}
        onSelectedOptionChange={(value: any) => {
          const { action } = value;
          action && action();
        }}
        value={selectedOption}
        defaultLabel={t('T_MANAGE_METADATA')}
      />
    </>
  );
};

export default React.memo(MetadataMenu);
