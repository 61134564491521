import React, { useEffect, useState } from 'react';
import { OverlayMessageDiv } from '../../styles/globalVariables';

interface TooltipButtonProps {
  disabled?: boolean;
  message?: string;
  children: React.ReactNode;
}

export const TooltipButton: React.FC<TooltipButtonProps> = ({
  children,
  disabled = false,
  message = ''
}: TooltipButtonProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        className={`flex flex-row items-center justify-center ${
          disabled ? 'cursor-not-allowed border-[#99B0C6]' : 'border-[#99B0C6] hover:border-black'
        }`}
      >
        {children}
      </div>
      {showTooltip && <OverlayMessageDiv>{message}</OverlayMessageDiv>}
    </div>
  );
};

export default React.memo(TooltipButton);
