import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import InputField from './InputField';

import { debounce, uniqueId } from 'lodash';

interface AutocompleteProps {
  fetchData: (searchText: string) => Promise<any[]>;
  placeholder?: string;
  customText?: string;
  label?: string;
  formData?: any;
  setFormData?: Function;
  disabled?: boolean;
  fieldKey: string;
  formError: any;
  dataKey: string;
  debouncedDelay?: number;
}

const AutocompleteInput: React.FC<AutocompleteProps> = ({
  fetchData,
  placeholder = 'T_AUTOCOMPLETE_DEFAULT_SEARCH_TEXT',
  customText,
  label = '',
  formData,
  setFormData = () => {},
  disabled = false,
  fieldKey,
  formError,
  dataKey,
  debouncedDelay = 600
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchText, setSearchText] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedSearch = useRef(
    debounce(async (searchText: string) => {
      fetchData(searchText)
        .then((data: Array<any>) => {
          setSearchResults(data);
          setLoading(false);
        })
        .catch((error) => {
          setSearchResults([]);
          setLoading(false);
        });
    }, debouncedDelay)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (searchText.trim() === '') {
      setSearchResults([]);
      return;
    }
    setLoading(true);
    debouncedSearch(searchText);
  }, [searchText]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setFormData({ ...formData, [fieldKey]: text });
    setSearchText(text);
    setShowDropdown(text.trim() !== '');
  };

  const handleItemSelect = (item: any) => {
    setSearchText('');
    setShowDropdown(false);
    setFormData({ ...formData, [fieldKey]: item[dataKey] });
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <AutocompleteContainer ref={inputRef}>
      <InputField
        type={'text'}
        id={'autocomplete-input'}
        label={label}
        placeholder={placeholder}
        fieldKey={fieldKey}
        asterixFirst
        required={true}
        formData={formData}
        setFormData={setFormData}
        formError={formError}
        disabled={disabled}
        handleChange={handleInputChange}
        min={1}
        isLoading={loading}
        max={1024}
      />
      {showDropdown && searchResults.length ? (
        <Dropdown>
          {searchResults.map((item) => (
            <DropdownItem key={item?.id || uniqueId()} onClick={() => handleItemSelect(item)}>
              {item[dataKey]}
            </DropdownItem>
          ))}
        </Dropdown>
      ) : (
        ''
      )}
      {customText && (
        <CustomText>
          <span>{customText}</span>
        </CustomText>
      )}
    </AutocompleteContainer>
  );
};

const AutocompleteContainer = styled.div`
  position: relative;
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 1;
  list-style: none;
  padding: 0;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const CustomText = styled.div`
  margin-top: 10px;
`;

export default AutocompleteInput;
