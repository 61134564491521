import DotMenu from '../primitives/dotMenu/DotMenu';

interface MenuOptionsProps {
  options: any[];
  folderData: any;
}

export const MenuOptions = ({ options, folderData }: MenuOptionsProps) => {
  return (
    <>
      <DotMenu
        options={options}
        onSelectedOptionChange={(value) => {
          value.action(folderData);
        }}
        value={''}
      />
    </>
  );
};
