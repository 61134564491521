import axios from 'axios';
import BaseAPI from './api';
import env from '../environment';
import { AppConstants } from '../constants/AppConstants';
import environment from '../environment';
import { fetchFromCookie } from '../util/admin-utils';
import { getFileName } from '../components/FileManagement/BucketUtil';

const globalHeaders = {
  [AppConstants.HEADER_CONTENT_TYPE]: AppConstants.VALUE_APPLICATION_JSON
};
const userProfileHeaders = {
  Authorization: env.api,
  [AppConstants.HEADER_CONTENT_TYPE]: AppConstants.VALUE_APPLICATION_JSON,
  apikey: fetchFromCookie('access_token') //localStorage.getItem('access-token')
};

const API = BaseAPI?.axiosInstance;

export const createSignedURL = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.assetManagementPath}/signed-url`, body, { headers });
  return response;
};

export const uploadFile = async (payload: any, success?: Function, error?: Function) => {
  const axiosInstance = axios.create();
  const response = await axiosInstance.put(payload?.url, payload?.body, {
    headers: {
      ...payload?.headers
    }
  });
  return response;
};

export const listBuckets = async (
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE
) => {
  const headers = { ...globalHeaders };
  const response = await API.get(`${env.assetManagementPath}/bucket?page=${page}&size=${size}`, {
    headers
  });
  return response;
};

export const listFiles = async (
  body: any,
  queryParams: { [key: string]: string | number },
  sort: Array<string> = []
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.assetManagementPath}/bucket/listfiles?page=${queryParams.page}&size=${queryParams.size}`;
  if (sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }
  const response = await API.post(uri, body, { headers });
  return response;
};

export const fileHistory = async (body: any,  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.assetManagementPath}/bucket/filehistory?page=${page}&size=${size}`, body, {
    headers
  });
  return response;
};

export const updateFileMetaData = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.assetManagementPath}/file/metadata`, body, { headers });
  return response;
};

export const createBucket = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.assetManagementPath}/bucket`, body, { headers });
  return response;
};

export const deleteBucket = async (id: string) => {
  const headers = { ...globalHeaders };
  const response = await API.delete(`${env.assetManagementPath}/bucket/${id}`, { headers });
  return response;
};

export const updateBucketPermissions = async (body: any, id: string) => {
  const headers = { ...globalHeaders };
  const response = await API.patch(`${env.assetManagementPath}/bucket/${id}`, body, { headers });
  return response;
};

export const getThemColors = async () => {
  let result = {
    primaryColor: '',
    secondaryColor: '',
    hoverColor: '',
    striveColor: ''
  };
  const themeURL = `${AppConstants.BASE_STORAGE_URL}/${environment.ENV_SOLUTION_NAME}/theme.json`;
  await fetch(`${themeURL}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })
    .then((response) => response.json())
    .then((data) => {
      result = data;
    });
  return result;
};

export const getPublicMobileConfig = async () => {
  const headers = { ...globalHeaders };
  const response = await axios.get(AppConstants.MOBILE_CONFIG_PUBLIC_DATA, { headers });
  return response;
};

export const getGCPFile = async (url: string) => {
  const headers = { ...globalHeaders };
  const response = await axios.get(url, { headers });
  return response;
};

export const getListOfBuckets = async (
  name: string,
  page: number,
  size: number,
  sort: Array<string> = []
) => {
  const headers = { ...globalHeaders };
  let uri: string = `${env.assetManagementPath}/bucket?page=${page}&size=${size}`;

  if (name) {
    uri += `&name=${name}`;
  }

  if (sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }

  const response = await API.get(uri, {
    headers
  });
  return response;
};

export const getBucketById = async (id: string) => {
  const headers = { ...globalHeaders };
  let uri: string = `${env.assetManagementPath}/bucket/${id}`;

  const response = await API.get(uri, {
    headers
  });
  return response;
};

export const createFolder = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.assetManagementPath}/folder`, body, { headers });
  return response;
};

export const updateFolder = async (body: any, id: string) => {
  const headers = { ...globalHeaders };
  const response = await API.patch(`${env.assetManagementPath}/folder/${id}`, body, { headers });
  return response;
};

export const getFolder = async (id: string) => {
  const headers = { ...globalHeaders };
  let uri: string = `${env.assetManagementPath}/folder/${id}`;

  const response = await API.get(uri, {
    headers
  });
  return response;
};

export const deleteFolder = async (id: string, hard: boolean = false) => {
  const headers = { ...globalHeaders };
  let uri: string = `${env.assetManagementPath}/folder/${id}`;
  if (hard) {
    uri += '/hard';
  }
  return await API.delete(uri, { headers });
};

export const deleteFile = async (assetid: string, fileName: string, hard: boolean = false) => {
  const headers = { ...globalHeaders };
  let uri: string = `${env.assetManagementPath}/file/${assetid}/${fileName}`;
  if (hard) {
    uri += '/hard';
  }
  return await API.delete(uri, { headers });
};

export const downloadWithRedirection = async (body: any): Promise<any> => {
  const { filePath } = body;
  const headers = {
    ...globalHeaders,
    [AppConstants.CONTENT_DISPOSITION]: `attachment; filename=${getFileName(filePath)}`
  };
  return await API.post(`${env.assetManagementPath}/signed-url/redirect`, body, {
    headers
  });
};
