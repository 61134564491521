import { Label } from '../../AccountProfile/ProfileStyles';
import styled from 'styled-components/macro';
import Asterix from '../../../assets/asterix.svg';
import DropdownSvg from '../../../assets/dropdown.svg';
import { useRef, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';
import {
  CheckMark,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ImageContainer,
  PickListItem,
  Text,
  Image
} from './PickList';
import Check from '../../../assets/check.svg';

interface NewOrganizationSampleData {
  name: string;
  phone: string;
  fax: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  permissionSetName: any;
}

interface CountryStateProps {
  type: string;
  id: string;
  label: string;
  placeholder: string;
  fieldKey: string;
  required: boolean;
  formData: NewOrganizationSampleData;
  setFormData: Function;
  formError: NewOrganizationSampleData;
  options?: any;
  disabled?: boolean;
  objectKey?: string;
  icon?: JSX.Element[] | any;
  maxHeight?: boolean;
  fontSize?: string;
  style?: any;
  asterixFirst?: Boolean;
  dynamicDisplayField?: Function;
  getCurrentSelection?: Function;
}

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

export default function CountryState({
  label,
  fieldKey,
  required,
  formData,
  setFormData,
  formError,
  options,
  disabled,
  dynamicDisplayField = () => {
    return '';
  },
  getCurrentSelection = () => {
    return '';
  }
}: CountryStateProps) {
  const selectRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  const toggling = () => {
    setShowPopup(!showPopup);
  };

  useOutsideClick(
    selectRef,
    () => {
      if (showPopup) {
        setShowPopup(false);
      }
    },
    false
  );
  const onOptionClicked = (value: any) => () => {
    const { country } = formData;
    const { code = '' } = value;
    const countryCode = value?.countryCode || '';
    setShowPopup(false);
    setFormData(
      country !== code && fieldKey === 'country'
        ? { ...formData, [fieldKey]: code, state: '', countryCode, faxCountryCode: countryCode }
        : { ...formData, [fieldKey]: code }
    );
  };

  const { t } = useTranslation();

  return (
    <>
      {label && (
        <Label>
          {t(label)}
          {required ? <Image src={Asterix} /> : ''}
        </Label>
      )}

      <DropDownContainer className={disabled ? 'disabled' : ''}>
        <DropDownHeader onClick={toggling} data-testid="countryState-field-header">
          {getCurrentSelection
            ? getCurrentSelection(formData[fieldKey as keyof NewOrganizationSampleData])
            : t('T_SELECT')}
          <img src={DropdownSvg} />
        </DropDownHeader>
        {showPopup && (
          <DropDownListContainer ref={selectRef} data-testid="countryState-option-list">
            <DropDownList showAddOption={false}>
              <PickListItem onClick={onOptionClicked('')} data-testid="countryState-option-default">
                {t('T_SELECT')}
              </PickListItem>
              <div className="w-full">
                {options.map((option: any) => (
                  <PickListItem
                    onClick={onOptionClicked(option)}
                    key={Math.random()}
                    data-testid="countryState-option-item"
                  >
                    <ImageContainer>
                      {formData[fieldKey as keyof NewOrganizationSampleData] === option.code ||
                      formData[fieldKey as keyof NewOrganizationSampleData] === option.name ? (
                        <CheckMark src={Check} />
                      ) : (
                        ''
                      )}
                    </ImageContainer>
                    <Text>{dynamicDisplayField(option.code, option.name)}</Text>
                  </PickListItem>
                ))}
              </div>
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>

      <ErrorLabel>
        {formError[fieldKey as keyof NewOrganizationSampleData]
          ? formError[fieldKey as keyof NewOrganizationSampleData]
          : ''}
      </ErrorLabel>
    </>
  );
}
