import { createSlice } from '@reduxjs/toolkit';
import { AppConstants } from '../constants/AppConstants';

const initialState: any = {
  searchText: null,
  sortType: null,
  sortName: null,
  pagingOpts:null,
  size:null,
  currentPage:null,
  route: null,
  filters:null
};

export const tableSortSearchSlice = createSlice({
  name: 'tableSortSearch',
  initialState,
  reducers: {
    setGlobalSearchText: (state, action) => {
      if (state.searchText !== action.payload) {
        state.searchText = action.payload.trim();
      }
    },
    setGlobalSortType: (state, action) => {
      if (state.sortType !== action.payload) {
        state.sortType = action.payload;
      }
    },
    setGlobalSortName: (state, action) => {
      if (state.sortName !== action.payload) {
        state.sortName = action.payload;
      }
    },
    setGlobalTableRoute: (state, action) => {
      if (state.route !== action.payload) {
        state.route = action.payload;
      }
    },
    setGlobalPagingOpts: (state, action) => {
      if (JSON.stringify(state.pagingOpts) !== JSON.stringify(action.payload)) {
        state.pagingOpts = action.payload;
      }
    },
    setGlobalTableSize: (state, action) => {
      if (state.size !== action.payload) {
        state.size = action.payload;
      }
    },
    setGlobalTablePage: (state, action) => {
      if (state.currentPage !== action.payload) {
        state.currentPage = action.payload;
      }
    },
    setGlobalTableFilters: (state, action) => {
      if (JSON.stringify(state.filters) !== JSON.stringify(action.payload)) {
        state.filters = action.payload;
      }
    },
    resetGlobalSortSearch: () => initialState
  }
});

export const {
  setGlobalSearchText,
  setGlobalSortName,
  setGlobalSortType,
  resetGlobalSortSearch,
  setGlobalTableRoute,
  setGlobalPagingOpts,
  setGlobalTableSize,
  setGlobalTablePage,
  setGlobalTableFilters
} = tableSortSearchSlice.actions;

export default tableSortSearchSlice.reducer;
