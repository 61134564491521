import InputField from '../../../shared/Fields/InputField';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef } from 'react';

interface DateProps {
  formData: any;
  setFormData: any;
  editMode?: boolean;
  formError: any;
  setFormError: Function;
  editFromSurveyTemplate: boolean;
}
const DateQuestion = ({
  formData,
  setFormData,
  editMode,
  formError,
  setFormError,
  editFromSurveyTemplate = false
}: DateProps) => {
  const { t } = useTranslation();
  const validateField = () => {
    if (formData.initial && formData.initial.length) {
      if (!moment(formData.initial, `${t('T_VALID_DATE_FORMAT')}`, true).isValid()) {
        setFormError({ initial: t('T_INVALID_DATE_FORMAT_ERROR_MESSAGE') });
      } else if (moment(formData.initial, `${t('T_VALID_DATE_FORMAT')}`, true).isValid()) {
        setFormError({ initial: '' });
      }
    }
  };

  const DateInputField = forwardRef(({ value, onClick }: any, ref) => (
    <InputField
      className="w-full"
      type="text"
      id="survey-question-date"
      label=""
      placeholder={'T_VALID_DATE_FORMAT'}
      fieldKey="initial"
      required={false}
      formData={formData}
      setFormData={setFormData}
      formError={formError}
      disabled={editMode}
      onClick={onClick}
      ref={ref}
      handleChange={() => {}}
      onBlurhandler={(e: any) => {
        validateField();
      }}
    />
  ));

  return (
    <>
      {editFromSurveyTemplate && (
        <>
          <div className="flex w-full flex-row">
            <span>{t('T_DATE_QUESTION_ANSWER_SECTION_LABEL')}:</span>
          </div>
          <div className="mt-3 flex w-full flex-col">
            <DatePicker
              selected={formData?.initial?.length ? new Date(formData?.initial) : new Date()}
              disabledKeyboardNavigation
              onChange={(date: any) => {
                if (formError.initial) {
                  setFormError({ ...formError, initial: '' });
                }
                setFormData({
                  ...formData,
                  initial: date ? [moment(date).format(t('T_VALID_DATE_FORMAT'))] : []
                });
              }}
              customInput={<DateInputField />}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DateQuestion;
