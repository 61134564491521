import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';

import Card from '../shared/cards/Card';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import DynamicFormFieldRenderer from '../Reusable/DynamicFormFieldRenderer';
import BiButton from '../primitives/buttons/BiButton.primitive';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import OrganizationMenu from './OrganizationMenu';
import { dynamicFormData, NewOrganizationFormLayout } from '../../util/constants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setIndividualOrganizationData, setURLPaths } from '../../app/organizationReducer';
import { library, SettingIcon } from '../../assets/components/svgs';
import { getOrganizationDetails } from './helpers';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import ToastNotification from '../Reusable/ToastNotification';
import { getLoggedInOrgName, getLoginUserId, getLoginUserRoles } from '../../util/admin-utils';
import { getOrganizationSettings } from '../../http/configuration-services';
import { AppConstants } from '../../constants/AppConstants';
import { SystemSetting } from '../../util/RoleUtil';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { getFailureMessage } from '../../util/ErrorUtil';
import Checkbox from '../shared/Fields/Checkbox';
import { find } from 'lodash';
import { parseTemplateLiteral } from '../../util/OrganizationUtil';
import { RouteConstants } from '../../constants/RouteConstants';
import AuditInfo from '../Common/AuditInfo';

interface ManageSettingCardProps {
  navigateTo: Function;
  description: string;
  buttonLabel: string;
  title: string;
  icon: any;
  t: any;
}

interface StatusCardProps {
  show?: boolean;
  status?: boolean;
}

const StatusCard: React.FC<StatusCardProps> = ({
  show = false,
  status = false
}: StatusCardProps) => {
  const { t } = useTranslation();
  return (
    <>
      {show && (
        <div
          className={`${
            status ? 'bg-[#9bd7b2]' : 'bg-[#FFF6DD]'
          } text-weight-600 ml-auto mr-[20px] rounded p-1 px-[10px] text-[14px]`}
        >
          {status ? `${t('T_ACTIVE')}` : `${t('T_NOT_ACTIVE')}`}
        </div>
      )}
    </>
  );
};

const ManageSettingCard: React.FC<ManageSettingCardProps> = ({
  navigateTo,
  description,
  buttonLabel,
  title,
  icon,
  t
}) => {
  return (
    <Card title={title} icon={icon} alt={t('T_SHOW_ORG_CARD_ALT_MSG')} height="" width="">
      <div className="flex w-full flex-wrap">
        <div className="w-full">
          <p className="mb-9 max-w-[350px]">{description}</p>

          <BiButton
            className={
              'mr-2 w-full border border-solid border-primary-disabled py-1.5 text-primary'
            }
            type="button"
            onClick={navigateTo}
          >
            {buttonLabel}
          </BiButton>
        </div>
      </div>
    </Card>
  );
};

function ShowOrganization() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const toastData = useAppSelector((state) => state.toast);
  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const organizationDetails = useAppSelector((state) => state.organization.organizationDetails);
  const orgAddressCountryList: any = useAppSelector(
    (state) => state.configuration.orgAddressCountryList
  );
  const solutionConfigurationDefaults: any = useAppSelector(
    (state) => state.configuration.solutionConfigurationDefaults
  );

  const [organization, setOrganization] = useState<any>({});
  const [organizationData, setOrganizationData] = useState<any>({});
  const [organizationStatus, setOrganizationStatus] = useState<boolean>(false);

  const [openModalFunction, setOpenModalFunction] = useState<any>(() => {});
  const [overrideTemplates, setOverrideTemplates] = useState<boolean>(false);
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    fax: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    type: '',
    counrtyCode: '',
    faxCountryCode: ''
  });
  const [formError, setFormError] = useState({
    name: '',
    phone: '',
    fax: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    type: ''
  });

  useEffect(() => {
    const init = async () => {
      if (organizationData && !Object.keys(organizationData).length && params.id) {
        setIsLoading(true);
        callApi(params?.id);
        getOrganizationSetting(params?.id);
      }
      if (storedURLPaths.length && Object.keys(organizationData).length) {
        let orgName: string = getLoggedInOrgName();
        dispatch(
          setURLPaths([
            ...storedURLPaths,
            {
              key: `/organization/${params?.id}`,
              label: `${orgName ? orgName : t('T_ORGANIZATION')} ${
                orgName === organizationData.name ? '' : `> ${organizationData.name}`
              }`
            }
          ])
        );
      }
    };
    init();
  }, [organizationData]);

  const getOrganizationSetting = async (organizationId: string) => {
    const response = await getOrganizationSettings(
      organizationId,
      AppConstants.CATEGORY_ORGANIZATION_SETTINGS,
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: settingResponse } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: settingList = [] } = settingResponse;
      const [organizationSetting] = settingList.filter(
        (setting: SystemSetting) => setting.key === AppConstants.CATEGORY_ORGANIZATION_SETTINGS
      );
      const { value = {} } = organizationSetting || {};
      const { overrideTemplates = true } = value;
      setOverrideTemplates(overrideTemplates);
    }
  };

  const callApi = async (id: string) => {
    const { tempData, response }: any = await getOrganizationDetails(id, t);
    if (response.status === HTTP_STATUS.HTTP_OK) {
      setFormData({ ...tempData });
      dispatch(setIndividualOrganizationData({ ...tempData }));
      setOrganizationData({ ...tempData });
      setOrganizationStatus(response.data.active);
      setIsLoading(false);

      setOrganization(response?.data);
      // isUserEnrolledToOrganization(response?.data);
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const getFormData = () => {
    const { organization } = solutionConfigurationDefaults;
    const {
      defaultCountry = '',
      displayPattern,
      defaultState = '',
      excludeDefaults = []
    } = organization || {};

    const { country: selectedCountry = '' } = formData;
    let stateData =
      find(orgAddressCountryList, function (country: any) {
        return country.code === selectedCountry || country.name === selectedCountry;
      })?.states || [];

    return dynamicFormData().map((ele: any) => {
      const { key } = ele;
      if (['country', 'state'].includes(key)) {
        return {
          ...ele,
          disabled: true,
          dynamicDisplayField: function (code: string, name: string) {
            return code || name ? parseTemplateLiteral(displayPattern, { code, name }) : '';
          },
          options: key === 'country' ? orgAddressCountryList : stateData
        };
      }
      return { ...ele, disabled: true };
    });
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <div
        className="flex w-full flex-col justify-start overflow-visible  bg-gray-100 px-2 py-3"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <div className="flex">
          <div className="flex w-3/5">
            <div className="p-2"></div>
            <Container>
              <Card
                title={t('T_ORG_DETAIL_TITLE')}
                icon={library}
                alt={t('T_CARD_ALT_MESSAGE')}
                actions={<StatusCard show={true} status={organizationStatus} />}
              >
                <MenuButtonsPortal>
                  <BiButton
                    className={'mr-2 text-primary'}
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    {storedURLPaths?.filter(
                      (path: any) =>
                        path.key === RouteConstants.ROUTE_OS_ORGANIZATION_LISTING && path.label
                    ).length
                      ? t('T_BACK_TO_ORGANIZATION_LIST_BTN')
                      : t('T_BACK')}
                  </BiButton>
                  <div>
                    <OrganizationMenu
                      getOpenModalFunction={(fun: any) => {
                        setOpenModalFunction(fun);
                      }}
                      dropdownMenu={true}
                      organization={organization}
                    />
                  </div>
                </MenuButtonsPortal>

                <ToastNotification
                  message={toastData.toastMessage}
                  isActive={toastData.isToastActive}
                  setIsActive={() =>
                    dispatch(
                      setToastData({
                        toastMessage: '',
                        isToastActive: false,
                        toastType: null
                      })
                    )
                  }
                  status={toastData.toastType}
                />
                <div className="flex w-full flex-wrap">
                  <DynamicFormFieldRenderer
                    layout={NewOrganizationFormLayout.twoColumn}
                    dynamicFormData={getFormData()}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    editable={false}
                  />
                </div>
              </Card>
            </Container>
          </div>
          <div className="flex w-2/5 ">
            <div className="p-2"></div>
            <div className="flex flex-col">
              {(organizationDetails?.original?.createdOn ||
                organizationDetails?.original?.createdBy) && (
                <div>
                  <AuditInfo
                    label={'CREATED'}
                    date={organizationDetails?.original?.createdOn || ''}
                    user={organizationDetails?.original?.createdBy || ''}
                  />
                </div>
              )}
              {(organizationDetails?.updatedOn || organizationDetails?.updatedBy) && (
                <div>
                  <AuditInfo
                    label={'LAST_MODIFIED'}
                    date={organizationDetails?.updatedOn || ''}
                    user={organizationDetails?.updatedBy || ''}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="p-1"></div>
          <div className="w-3/5 p-2">
            <Card
              title={t('T_ORG_SETTINGS')}
              icon={SettingIcon}
              alt={t('T_CARD_ALT_MESSAGE')}
              height=""
              width=""
            >
              <div className={`mb-5 flex flex-row items-center`}>
                <Checkbox
                  height="20px"
                  width="20px"
                  checked={overrideTemplates}
                  onChange={() => {
                    setOverrideTemplates(!overrideTemplates);
                  }}
                  disabled={true}
                />
                <span className="ml-2">{t('T_CLONE_DEFAULT_TEMPLATES')}</span>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </LoaderContainer>
  );
}

const Container = styled.div`
  width: 100%;
`;

export default ShowOrganization;
