import BaseAPI from './api';
import env from '../environment';
import { CreateOrganizationFormStructure } from './models/organization/Organization-services.interface';
import { AppConstants } from '../constants/AppConstants';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

export const getChildOrganizations = async (
  id: string,
  level: string = AppConstants.QUERY_PARAM_LEVEL_DESENDANTS,
  name: string,
  page: number = 0,
  size: number = 10,
  sort: Array<string> = []
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.organizationPath}/organizations/${id}/children`;
  uri += `?page=${page}&size=${size}`;
  if (level) {
    uri += `&${AppConstants.QUERY_PARAM_LEVEL}=${level}`;
  }
  if (name) {
    uri += `&name=${name}`;
  }
  if (sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }

  return await API.get(uri, { headers });
};

export const createOrganization = async (body: CreateOrganizationFormStructure) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.organizationPath}/organizations`, body, { headers });
  return response;
};

export const getOrganizations = async (name: string, page: number, size: number, sort?: string) => {
  const headers = { ...globalHeaders };
  const response = await API.get(
    `${env.organizationPath}/organizations?name=${name ? name : ''}&page=${
      page ? page : '0'
    }&size=${size ? size : '10'}${sort ? `&sort=${sort}` : ''}`,
    { headers }
  );
  return response;
};

export const getOrganization = async (id: string) => {
  const headers = { ...globalHeaders };
  return await API.get(`${env.organizationPath}/organizations/${id}`, { headers });
};

//Soft Delete
export const deleteOrganization = async (id: string) => {
  const headers = { ...globalHeaders };
  return await API.delete(`${env.organizationPath}/organizations/${id}`, { headers });
};

//Hard Delete
export const hardDeleteOrganization = async (id: string) => {
  const headers = { ...globalHeaders };
  return await API.delete(`${env.organizationPath}/organizations/hard-delete/${id}`, { headers });
};

export const patchOrganization = async (body: CreateOrganizationFormStructure, id: string) => {
  const headers = { ...globalHeaders };
  return await API.patch(`${env.organizationPath}/organizations/${id}`, body, {
    headers
  });
};
