import { createSlice } from '@reduxjs/toolkit';

export const sysSettingSlice = createSlice({
  name: 'sysSetting',
  initialState: {
    orgSysSetting: {} as any,
    userSysSetting: {} as any
  },
  reducers: {
    setOrgSysSetting: (state, action) => {
      state.orgSysSetting = action.payload;
    },
    setUserSysSetting: (state, action) => {
      state.userSysSetting = action.payload;
    }
  }
});

export const { setOrgSysSetting, setUserSysSetting } = sysSettingSlice.actions;

export default sysSettingSlice.reducer;
