import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import MailIcon from '../../assets/mail.svg';
import EditTemplate from './EditTemplate';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import BiButton from '../primitives/buttons/BiButton.primitive';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import { setTemplateData } from '../../app/templateReducer';
import {
  createNotificationTemplate,
  deleteTemplate,
  getTemplateById,
  NotificationTemplate,
  updateNotificationTemplate
} from '../../http/notification-services';
import RadioButton from '../shared/Fields/RadioButton';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../util/functions';
import { setToastData } from '../../app/toastReducer';
import { getFailureMessage } from '../../util/ErrorUtil';
import { setURLPaths } from '../../app/organizationReducer';
import { enrollmentCheck } from '../../http/user-management-services';
import {
  getLoggedInOrg,
  getLoginUserId,
  getUserName,
  getUserProfiles
} from '../../util/admin-utils';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  validateEmail,
  validateLength,
  validateNameLength,
  validateTemplateName
} from '../../util/validators';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../constants/AppConstants';
import InputField from '../shared/Fields/InputField';
import { useTheme } from '../../context/themeContext';
import { cloneDeep, isEmpty, set } from 'lodash';
import Card from '../shared/cards/Card';
import ToastNotification from '../Reusable/ToastNotification';
import DynamicFormFieldRenderer from '../Reusable/DynamicFormFieldRenderer';
import { notificationFormSchema } from '../../util/constants';
import CreateTemplateType from './NewTemplateType';
import { RouteConstants } from '../../constants/RouteConstants';
import moment from 'moment';
import { rolePermissions } from '../../app/models/content/constants';
import AuditInfo from '../Common/AuditInfo';

const Container = styled.div`
  padding: 1rem;
  width: 100%;
`;

export const initialiseSchema = (value: any) => {
  const obj = {
    category: '',
    subject: '',
    createdOn: moment(value?.createdOn).format(AppConstants.DEFAULT_DATE_FORMAT),
    createdBy: value?.createdBy,
    lastModifiedAt: moment(value?.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT),
    lastModifiedBy: value?.updatedBy,
    type: '',
    id: '',
    data: '' as any,
    subjectKey: 1,
    contentKey: 0,
    description: '',
    fromEmail: '',
    fromName: ''
  };
  obj.category = value.category;
  obj.description = value?.description || '';
  if (value.channel === 'sms') {
    // obj.subject = value.description;
    obj.contentKey = value.params.findIndex((elem: { key: string }) => elem.key === 'sms_content');
  } else if (value.channel === 'email') {
    obj.contentKey = value.params.findIndex(
      (elem: { key: string }) => elem.key === 'email_content'
    );
    obj.subjectKey = value.params.findIndex(
      (elem: { key: string }) => elem.key === 'email_subject'
    );
    obj.subject = value.params[obj.subjectKey]?.value || '';
  } else if (value.channel === 'push_notification') {
    obj.contentKey = value.params.findIndex(
      (elem: { key: string }) => elem.key === 'push_notification_content'
    );
    obj.subjectKey = value.params.findIndex(
      (elem: { key: string }) => elem.key === 'push_notification_subject'
    );
    obj.subject = value.params[obj.subjectKey]?.value || '';
  }
  // obj.owner = value.createdBy ? value.createdBy : '';
  obj.type = value.channel;
  obj.id = value.id;
  obj.data = value;

  obj.fromEmail = value?.configParams?.fromEmail || '';
  obj.fromName = value?.configParams?.fromName || '';

  return obj;
};

/**
 * @parentId notifications
 * @manager Create, View, Edit - Notification Template
 * @overview Create, View, Edit Notification Template
 *   <section>
 *       <p>
 *           <b>View Notification Template</b><br/>
 *           Clicking on notification template row item on the notification template listing page will take user to view notification template details page in view only mode. Clicking on <b>Back to Notification List</b> will take the user to notification template listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i>
 *           <br/><br/>
 *           <b>Edit Notification Template</b><br/>
 *           Editing of notification template is only possible if the logged in user role API permission has edit permissions. i.e <strong>notification-service.template.update</strong>. Clicking on <b>Edit</b> button on the admin portal toolbar will change the page into editing mode, which allows users to edit <b>Subject & template body</b> fields and Save the template. Clicking on <b>Cancel</b> will take the user back to notification details page in view only mode.
 *           Once a notification template is created, Template Category & Type cannot be changed.
 *           <br/><br/>
 *           <b>Create Notification Template</b><br/>
 *           Create notification template button is only enabled if the logged-in user role API permission has create notification template permission. i.e <strong>notification-service.template.create</strong>
 *           Clicking on <b>New New Template</b> button on notification template listing page will take user to new notification template creation page. Clicking on <b>Cancel</b> will take the user to notification template listing page.
 *           <br/><br/>
 *           User can create new notification template by entering <b>Name, Channel Type, Subject & Template body</b> mandatory fields and clicking on <b>Save</b> button on the admin portal toolbar. Template body can be created using an inline WYSIWYG HTML editor with preview support and full screen mode to design html template. Embedding of images & hyperlinks are posible by using suitable HTML tags which will render them in most of the browser enabled containers like mailclients.
 *       </p>
 *
 *       <p>
 *          <h4>View/Create/Edit Notification Template Fields</h4>
 *          Create/View/Edit notification template page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Template Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Channel</td>
 *               <td>Yes</td>
 *               <td>Enum (Email, SMS, PUSH)</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Subject</td>
 *               <td>Yes</td>
 *               <td>TEXT</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Body</td>
 *               <td>Yes</td>
 *               <td>TEXT</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> Channel is used to define the type of notification templates and it can be one of the 3 types. i.e Email, SMS, PUSH.
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes Create/Edit notifcate template failure status code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>NS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>NS_PARAM_DUPLICATE</td>
 *               <td>Duplicate notification template.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>NS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create/edit/view notification template page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit notification template page.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Notification Service</td>
 *               <td>1.1.0</td>
 *           </tr>
 *           <tr>
 *               <td>Organization Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>Access Control Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Notification Template</td>
 *               <td>/notifications/templates/{id}</td>
 *               <td>GET</td>
 *               <td>	notification-service.template.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>View Notification Template</td>
 *               <td>/organizations/{id}</td>
 *               <td>GET</td>
 *               <td>organization-service.organization.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Notification Template</td>
 *               <td>/notifications/templates/{id}</td>
 *               <td>GET</td>
 *               <td>	notification-service.template.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Notification Template</td>
 *               <td>/notifications/templates</td>
 *               <td>POST</td>
 *               <td>notification-service.template.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Notification Template</td>
 *               <td>/organizations/{id}</td>
 *               <td>GET</td>
 *               <td>organization-service.organization.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Notification Template</td>
 *               <td>/notifications/templates/{id}</td>
 *               <td>GET</td>
 *               <td>	notification-service.template.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Notification Template</td>
 *               <td>/notifications/templates</td>
 *               <td>PUT</td>
 *               <td>notification-service.template.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Notification Template</td>
 *               <td>/organizations/{id}</td>
 *               <td>GET</td>
 *               <td>organization-service.organization.get</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for view/create/edit notification template</p>
 *   </section>
 */

interface NotificationDetails {
  category: string;
  channel: string;
  description: string;
  fromName: string;
  fromEmail: string;
}

const TemplateDetail = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const htmlEditorRef: any = useRef(null);

  const [mode, setMode] = useState('edit');
  const [isEdit, setIsEdit] = useState(false);

  const loginOrgId = getLoggedInOrg()?.organizationId;

  const [isLoading, setIsLoading] = useState(true);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [editAllowed, isEditAllowed] = useState<boolean>(true);
  const [emailBodyError, setEmailBodyError] = useState(true);
  const [initialPayload, setInitialPayload] = useState<any>(null);

  const [createdBy, setCreatedBy] = useState<string>('');

  const template = useAppSelector((state) => state?.templateData.templatePayload);
  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const apiPermisions: any = useAppSelector((state) => state.initialLoadData?.apiPermissions) || {};

  const { t } = useTranslation();

  const templateTypeOptions = [
    { value: 'email', text: `${t('T_EMAIL')}` },
    { value: 'sms', text: `${t('T_SMS')}` },
    { value: 'push_notification', text: `${t('T_PUSH')}` }
  ];

  const [formError, setFormError] = useState<any>({
    fromEmail: '',
    fromName: '',
    description: '',
    category: ''
  });

  const isEditMode = () => mode === 'edit';

  const createSchema = (channelType: string) => {
    const createPayload = {
      category: '', //template Name
      sender: '',
      subject: '',
      owner: '',
      lastModifiedAt: '',
      type: channelType,
      replyToEmail: '',
      description: '',
      id: '',
      data: {
        params:
          channelType == 'email'
            ? [
                {
                  type: 'text',
                  key: 'email_subject',
                  value: '',
                  description: '',
                  channel: channelType
                },
                {
                  type: 'text',
                  key: 'email_content',
                  value: '',
                  channel: channelType,
                  description: ''
                }
              ]
            : channelType == 'push_notification'
            ? [
                {
                  type: 'text',
                  key: 'push_notification_subject',
                  value: '',
                  description: '',
                  channel: channelType
                },
                {
                  type: 'text',
                  key: 'push_notification_content',
                  value: '',
                  channel: channelType,
                  description: ''
                }
              ]
            : [
                {
                  type: 'text',
                  key: 'sms_content',
                  value: '',
                  channel: channelType,
                  description: ''
                }
              ]
      },
      applyBrandImage: 1,
      applyFooter: 1,
      allowClinicians: 0,
      loremIpsum: 1,
      existingPatients: 2,
      subjectKey: 0,
      contentKey: ['email', 'push_notification'].includes(channelType) ? 1 : 0,
      fromEmail: '',
      fromName: ''
    };
    dispatch(setTemplateData(createPayload));
  };

  useEffect(() => {
    async function init() {
      if (location.pathname.split('/').at(1) === 'create-template') {
        dispatch(setTemplateData(''));
        if (!apiPermisions[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_CREATE]) {
          dispatch(
            setToastData({
              toastMessage: t('T_USER_NO_PERMISSION_ACTION_MESSAGE'),
              isToastActive: true,
              toastType: 'error'
            })
          );
          cancelFunc();
        }
        setIsEdit(true);
        setIsLoading(false);
        setMode('create');
        setBreadCrumb('create');
        setShowTypeModal(true);
        return;
      } else {
        let response = {} as any;
        let schema: any = template;
        if (isEmpty(schema) && params?.id) {
          response = await getTemplateById(params?.id);
          if (response?.data) {
            schema = initialiseSchema(response?.data);
            dispatch(setTemplateData(schema));
            setInitialPayload(schema);
          } else {
            cancelFunc();
          }
        } else {
          setInitialPayload(schema);
        }

        await editOption();
        await getCreatedByName(schema.createdBy);
        // await enableDisableEditing(template?.data || response?.data);
        setIsLoading(false);
      }
    }
    init();
  }, []);

  const setBreadCrumb = (mode: string, name?: string) => {
    if (mode === 'edit') {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: `/templates/edit/${params.id}`,
            label: `${t('T_BREADCRUMB_NOTIFICATION_TEMPLATE_LBL')} ${name}`
          }
        ])
      );
    } else {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: `/create-template/${params.id}`,
            label: `${t('T_BREADCRUMB_NOTIFICATION_CREATE_TEMPLATE_LBL')}`
          }
        ])
      );
    }
  };

  const editOption = async () => {
    setEditTemplate(!!apiPermisions[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_UPDATE]);
  };

  const enableDisableEditing = async (template: NotificationTemplate) => {
    if (!template) {
      return;
    }
    const response = await enrollmentCheck(getLoginUserId(), template?.orgId as string);
    const { status, data } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { success = false } = data;
      isEditAllowed(success);
    } else {
      isEditAllowed(false);
    }
  };

  const getCreatedByName = async (userId: string) => {
    const users: Array<any> = await getUserProfiles([userId]);
    if (users.length) {
      setCreatedBy(getUserName(users[0]));
    }
  };

  const changeReducerData = (keyName: string, payload: any) => {
    const localData = JSON.parse(JSON.stringify(template));
    set(localData, keyName, payload);
    dispatch(setTemplateData(localData));
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeReducerData('category', event.target.value);
  };

  const toggleRadio = (data: string) => {
    changeReducerData('type', data);
  };

  // const htmlEditorRef = (sunEditor: SunEditorCore) => {
  //   editor.current = sunEditor;
  // };

  // const isCodeEditor = (data: boolean) => {
  //   setCodeViewMode(data);
  // };

  const handleValidation = () => {
    const { description, category, fromEmail, fromName } = template || {};
    const errorMessage = t('T_VALIDATION_ERR_TEXT_LENGTH_MSG');

    return {
      description: description?.length ? validateLength(description, t, 4096) : '',
      category: validateNameLength(category, 255) ? '' : errorMessage,
      fromEmail: fromEmail.length
        ? validateEmail(fromEmail)
          ? ''
          : t('T_VALIDATION_ERR_EMAIL_MSG')
        : '',
      fromName: fromName.length
        ? validateNameLength(fromName?.trim(), 255)
          ? ''
          : errorMessage
        : ''
    };
  };

  const saveTemplate = async () => {
    let formErrorLocal;

    formErrorLocal = handleValidation();
    setFormError(formErrorLocal);
    const { fromEmail, fromName, description, category } = formErrorLocal;

    if (![fromEmail, fromName, description, category].some((field) => field.length)) {
      let localData = JSON.parse(JSON.stringify(template));

      const trimValue = (key: any) => {
        localData.data.params[key].value = localData.data.params[key].value.trim();
      };

      if (localData?.type === 'email') {
        trimValue(localData.subjectKey);
      } else if (localData?.type === 'sms') {
        trimValue(localData.contentKey);
      } else {
        trimValue(localData.subjectKey);
        trimValue(localData.contentKey);
      }

      if (localData?.type === 'email') {
        let htmlContent = htmlEditorRef?.current?.value;
        localData.data.params[localData.contentKey].value = htmlContent;
      }
      setIsLoading(true);
      if (mode === 'create') {
        const payload = {
          params: localData.data.params,
          category: localData.category.trim(),
          description: localData?.description.trim(),
          channel: localData.type,
          orgId: params?.id || loginOrgId,
          configParams: {
            fromName: localData?.fromName.trim() || '',
            fromEmail: localData?.fromEmail.trim() || ''
          }
        };
        const response = await createNotificationTemplate(payload);
        const { status, data } = response;
        if (HTTP_STATUS.isSuccess(status)) {
          dispatch(
            setToastData({
              toastMessage: t('T_TEMPLATE_CREATED_SUCCESS_MSG'),
              isToastActive: true,
              toastType: 'success'
            })
          );
          navigate(`/templates/${params?.id}`);
        } else {
          const { issues = [] } = data;
          if (issues.length) {
            const [issue] = issues;
            if (issue?.code === AppConstants.NOTIFICATION_DUPLICATE_NAME_ERROR) {
              dispatch(
                setToastData({
                  toastMessage: t('T_CREATE_TEMPLATE_DUPLICATE_NAME_ERROR'),
                  isToastActive: true,
                  toastType: 'error'
                })
              );
            } else {
              dispatch(
                setToastData({
                  toastMessage: getFailureMessage(response),
                  isToastActive: true,
                  toastType: 'error'
                })
              );
            }
          }
        }
        setIsLoading(false);
      } else {
        const payload: any = {
          category: localData.category,
          channel: localData.type,
          params: localData.data.params,
          description: localData?.description.trim(),
          orgId: template?.data?.orgId,
          configParams: {
            fromName: localData?.fromName.trim() || '',
            fromEmail: localData?.fromEmail.trim() || ''
          }
        };
        // if (localData?.type == 'sms') {
        //   payload.description = localData.subject.trim();
        // }

        const response = await updateNotificationTemplate(localData?.id, payload);
        const { status, data } = response;

        if (HTTP_STATUS.isSuccess(status)) {
          setIsEdit(false);
          dispatch(setTemplateData(initialiseSchema(data)));
          dispatch(
            setToastData({
              toastMessage: t('T_TEMPLATE_SAVED_SUCCESS_MSG'),
              isToastActive: true,
              toastType: 'success'
            })
          );
        } else {
          dispatch(
            setToastData({
              toastMessage: getFailureMessage(response),
              isToastActive: true,
              toastType: 'error'
            })
          );
        }
        setIsLoading(false);
      }
    }
  };
  const validator = () => {
    let disabled = false;
    let templateClone = cloneDeep(template);
    if (templateClone.type == 'email') {
      if (mode === 'edit') {
        templateClone.data.params[template.contentKey] = htmlEditorRef?.current?.value;
      }
      if (
        !templateClone.category.trim() ||
        !templateClone.data.params[templateClone.subjectKey]?.value.trim() ||
        emailBodyError
      ) {
        disabled = true;
      }
    } else {
      if (
        !templateClone.category.trim() ||
        (templateClone.type !== 'sms' &&
          !templateClone.data.params[templateClone.subjectKey]?.value.trim()) ||
        !templateClone.data.params[templateClone.contentKey].value.trim()
      ) {
        disabled = true;
      }
    }

    if (mode === 'edit' && JSON.stringify(templateClone) === JSON.stringify(initialPayload)) {
      disabled = true;
    }
    return disabled;
  };

  const RadioButtonContainer = (keyName: string, name: string, size: string, fontSize: string) => {
    return (
      <RadioButton
        className="flex flex-row items-center"
        size={size}
        selectedField={template.type}
        option={keyName}
        onClick={() => {
          toggleRadio(keyName);
        }}
        disabled
        label={name}
        name={name}
        fontSize={fontSize}
        fontColor="#262626"
      ></RadioButton>
    );
  };

  const cancelFunc = async () => {
    if (isEdit && mode === 'edit') {
      setIsLoading(true);
      resetFormError();
      let response = await getTemplateById(params?.id as string);
      if (response?.data) {
        dispatch(setTemplateData(initialiseSchema(response?.data)));
      }
      setIsEdit(false);
      setIsLoading(false);
      dispatch(setTemplateData(initialPayload));
      return;
    }
    dispatch(setTemplateData(''));
    navigate(`/templates${template ? `/${loginOrgId || params?.id}` : ''}`);
  };
  const onInputChange = (keyName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormError({ fromEmail: '', fromName: '' });
    changeReducerData(keyName, event.target.value);
  };

  const setFormData = (payload: any) => {
    setFormError({ category: '', description: '', fromName: '', fromEmail: '' });
    dispatch(setTemplateData(payload));
  };

  const toggleVisibility = (val: boolean) => {
    if (!val) {
      cancelFunc();
    }

    setShowTypeModal(val);
  };

  const resetFormError = () => {
    setFormError({ fromEmail: '', fromName: '', description: '', category: '' });
  };
  const removeNotification = async () => {
    let error = false;
    setIsLoading(true);
    let toastMessage = `${template?.category} ${t('T_DELETED_SUCCESS_MESSAGE')}`;
    const response = await deleteTemplate(template?.id);
    const { status } = response;
    const success = HTTP_STATUS.isSuccess(status);
    if (!success) {
      error = !error;
      toastMessage = getFailureMessage(response);
    }
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType: error ? 'error' : 'success'
      })
    );
    setIsLoading(false);
    if (success) {
      dispatch(setTemplateData(''));
      navigate(`/templates${template ? `/${loginOrgId || params?.id}` : ''}`);
    }
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <div
        className="flex h-fit w-3/4 flex-col p-5"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <CreateTemplateType
          selectOptions={templateTypeOptions}
          visibility={showTypeModal}
          toggleVisibility={toggleVisibility}
          setSelection={(type: string) => {
            createSchema(type);
            setShowTypeModal(false);
          }}
        />

        {!isEmpty(template) && editAllowed && (
          <MenuButtonsPortal>
            <BiButton className={'mr-2 text-primary'} type="button" onClick={cancelFunc}>
              {isEdit && mode !== 'create' ? t('T_CANCEL') : t('T_BACK_TO_NOTIFICATION_BTN')}
            </BiButton>
            {isEdit ? (
              <BiButton
                className={'mx-2 bg-primary text-white'}
                type="button"
                onClick={saveTemplate}
                disabled={validator()}
              >
                {t('T_SAVE_TEMPLATE')}
              </BiButton>
            ) : editTemplate ? (
              <BiButton
                className={'mx-2 bg-primary text-white'}
                type="button"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                {t('T_EDIT')}
              </BiButton>
            ) : (
              ''
            )}
          </MenuButtonsPortal>
        )}

        <div className="flex h-fit w-full flex-col justify-start overflow-visible">
          <Card
            title={t('T_TEMPLATE_DETAILS')}
            icon={MailIcon}
            alt="template details"
            height=""
            width=""
          >
            <div className="flex w-full flex-wrap">
              <DynamicFormFieldRenderer
                layout={2}
                dynamicFormData={notificationFormSchema(mode, template?.type)}
                formData={template}
                setFormData={setFormData}
                setFormError={setFormError}
                formError={formError}
                editable={true}
                asterixFirst={true}
                disabled={!isEdit}
              />
            </div>
          </Card>
        </div>

        {!isEmpty(template) && (
          <>
            <EditTemplate
              disabled={!isEdit}
              key={isEdit ? 'refresh' : 'no-refresh'}
              htmlEditorRef={htmlEditorRef}
              mode={mode}
              setEmailBodyError={setEmailBodyError}
            ></EditTemplate>
          </>
        )}
      </div>
      {isEditMode() && (
        <div className="ml-5 flex w-1/4 flex-col">
          <div className="my-7">
            {(template?.createdOn || template?.createdBy) && (
              <div>
                <AuditInfo
                  label={'CREATED'}
                  date={template?.createdOn || ''}
                  user={template?.createdBy || ''}
                  isDateFormatted={true}
                />
              </div>
            )}
            {(template?.updatedOn || template?.lastModifiedBy) && (
              <div>
                <AuditInfo
                  label={'LAST_MODIFIED'}
                  date={template?.updatedOn || ''}
                  user={template?.lastModifiedBy || ''}
                  isDateFormatted={true}
                />
              </div>
            )}

            {!!apiPermisions[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_DELETE] && (
              <div className="mt-6">
                <BiButton
                  className={
                    'w-48 border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
                  }
                  type="button"
                  onClick={() => {
                    removeNotification();
                  }}
                  data-testId={`notification_delete_button`}
                >
                  {t('T_DELETE_TEMPLATE_TEXT')}
                </BiButton>
              </div>
            )}
          </div>
        </div>
      )}
    </LoaderContainer>
  );
};

export default TemplateDetail;
