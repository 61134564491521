import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { setURLPaths } from '../../app/organizationReducer';

import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { hasSpecialCharacter, validateLength } from '../../util/validators';

import Card from '../../components/shared/cards/Card';
import InputField from '../shared/Fields/InputField';
import SelectField from '../shared/Fields/SelectField';
import PillsButton from '../shared/Fields/PillsButton';
import TextAreaField from '../shared/Fields/TextAreaField';
import RadioButton from '../shared/Fields/RadioButton';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';

import { AppConstants } from '../../constants/AppConstants';

import { getCategoryById, createCategory, updateCategory } from '../../http/configuration-services';
import { SettingIcon } from '../../assets/components/svgs';
import { isEqual, lowerCase, map, pick, uniq } from 'lodash';
import { getFailureMessage } from '../../util/ErrorUtil';
import { setCategoryDetails } from '../../app/configurationReducer';

interface categoryData {
  description: string;
  id: string;
  name: string;
}

interface categoryValidateData {
  name: string;
  description: string;
}

const DEFAULT_CATEGORY_OBJ = {
  id: '',
  description: '',
  name: ''
};

/**
 * @parentId configuration service
 * @manager Create, View, Edit - Categories
 * @overview Create, View, Edit of Categories
 *   <section>
 *
 *      <p>
 *           <b>Create Configuration Key</b><br/>
 *           Clicking on <b>Create New category</b> from the drop down menu on Configuration Keys listing page will take user to New Category creation page. Clicking on <b>Cancel</b> will take the user to Categories listing page.
 *           <br/><br/>
 *           User can create new Category by entering <b>Category Name</b> as mandatory field and clicking on <b> Save </b> button on the admin portal toolbar.
 *           <b>Description</b> is additional non mandatory field which user can use to enter more detail about the Category. Description is an freetext field.
 *           <br/><br/>
 *       </p>
 *       <p>
 *           <b>View Category</b><br/>
 *           Clicking on category key row on the category listing page will take user to View Category Key in READ-ONLY mode. Clicking on <b>Back to Categories Listing</b> will take the user to Categories listing page.
 *           <br/><br/>
 *        </p>
 *       <p>
 *           <b>Edit Category</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Category Name</b>, <b>Description</b> fields. Clicking on <b>Cancel</b> will take the user to Category details in READ-ONLY mode.
 *           <br/><br/>
 *        </p>
 *
 *       <p>
 *          <h4>View/Create/Edit Category Fields</h4>
 *          Create/View/Edit category page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *
 *            <tr>
 *               <td>category Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Description</td>
 *               <td>No</td>
 *               <td>String</td>
 *               <td>4096</td>
 *            </tr>
 *          </table>
 *          <br/>
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Metadata Application Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>CS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>CS_PARAM_DUPLICATE</td>
 *               <td>Duplicate name, <b>Category Name</b> for resource type.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>CS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create metadata application page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit metadata application.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Configuration Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Category Details</td>
 *               <td>/categories/{id}</td>
 *               <td>GET</td>
 *               <td>configuration-service.category.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Configuration Key</td>
 *               <td>/categories/{id}</td>
 *               <td>PUT</td>
 *               <td>configuration-service.category.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Category</td>
 *               <td>/categories</td>
 *               <td>POST</td>
 *               <td>configuration-service.category.create</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create metadata application</p>
 *   </section>
 */

const CategoryDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({});
  const queryParams = useParams();
  const [categoryObj, setCategoryObj] = useState<any>(DEFAULT_CATEGORY_OBJ);
  const [selectedItems, setSelectedItems] = useState<any>();
  const [flowType, setFlowType] = useState<any>({
    isCreate: location.pathname.split('/').at(-1) === 'create',
    isEdit: queryParams.id ? true : false,
    isReadOnly: queryParams.id ? true : false
  });

  const categoryDetails: any = useAppSelector((state) => state.configuration.categoryDetails);

  const [formError, setFormError] = useState<any>({
    name: '',
    description: ''
  });

  useEffect(() => {
    if (flowType.isEdit) {
      getCategoryDetail();
    } else {
      setIsLoading(false);
    }
    dispatch(setURLPaths([{ key: '/config-category/create', label: 'New Category' }]));
  }, []);

  const getIsEditOrReadOnly = () => {
    const { isEdit, isReadOnly } = flowType;
    if (isEdit && isReadOnly) {
      return true;
    } else if (isEdit && !isReadOnly) {
      return false;
    }
    return false;
  };

  const setisReadOnly = (isReadOnly: boolean) => {
    if (isReadOnly) {
      setCategoryObj(categoryDetails);
    }
    setFlowType({ ...flowType, isEdit: queryParams.id && isReadOnly, isReadOnly: isReadOnly });
  };

  const validateSave = () => {
    const { name } = categoryObj;
    return name.trim() === '';
  };

  const handleValidation = (): categoryValidateData => {
    const { name, description } = categoryObj;

    const nameLength = validateLength(name, t, 255);
    const nameSplChar = hasSpecialCharacter(name) ? `${t('T_VALIDATION_ERR_NAME_MSG')}` : '';

    return {
      name: nameLength || nameSplChar,
      description: validateLength(description, t, 4096)
    };
  };

  const hasErrors = (): boolean => {
    return Object.entries(formError).filter((key: any, value: any) => value.length).length > 0;
  };

  const isDataChanges = () => {
    return isEqual(categoryObj, categoryDetails);
  };

  const createConfigKeyData = (responseData: categoryData) => {
    const { id, name, description } = responseData;
    setCategoryObj(responseData);
    dispatch(setCategoryDetails(responseData));
  };

  const getCategoryDetail = async () => {
    let categoryDetailResponse = await getCategoryById(queryParams.id || '');
    if (categoryDetailResponse.status === HTTP_STATUS.HTTP_OK) {
      createConfigKeyData(categoryDetailResponse.data);
      setIsLoading(false);
    }
  };

  const handleChange = (key: string, value: any) => {
    setCategoryObj({ ...categoryObj, [key]: value });
  };

  const normalizeKey = (key: string): string => {
    return key ? key.trim().replace(/\s+/g, '_')?.toLowerCase() : key;
  };

  const saveCategory = async () => {
    let formError: categoryValidateData = handleValidation();
    setFormError(formError);
    if (!formError.name && !formError.description) {
      setIsLoading(true);
      let error = false,
        toastMessage = `${t('T_CATEGORY_CREATED')}`;
      const { name, description, id } = categoryObj;
      const categoryBody = {
        name,
        description: description ? description : ' '
      };
      let response: any;
      if (!flowType.isCreate) {
        response = await updateCategory(id, categoryBody);
        if (response.status === HTTP_STATUS.HTTP_OK) {
          toastMessage = `${t('T_CATEGORY_UPDATED')}`;
          navigate('/config-category-list');
        } else {
          error = !error;
          toastMessage = getFailureMessage(response);
        }
      } else {
        response = await createCategory(categoryBody);
        if (response.status === HTTP_STATUS.HTTP_CREATED) {
          navigate('/config-category-list');
        } else {
          error = !error;
          toastMessage = getFailureMessage(response);
        }
      }

      dispatch(
        setToastData({
          toastMessage,
          isToastActive: true,
          toastType: error ? 'error' : 'success'
        })
      );
      setIsLoading(false);
    }
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <div className="flex w-3/5 flex-col  overflow-visible px-2 py-3">
        <Card
          title={t('T_CATEGORY_DETAILS')}
          icon={SettingIcon}
          alt={'T_CATEGORY_DETAILS'}
          height={''}
          width={''}
          className=""
        >
          <MenuButtonsPortal>
            {flowType.isCreate && (
              <BiButton
                className={'mr-2 text-primary'}
                type="button"
                onClick={() => {
                  navigate(`/config-category-list`);
                }}
              >
                {t('T_BACK_TO_CATEGORY_LIST')}
              </BiButton>
            )}
            {!flowType.isCreate && (
              <BiButton
                className={'mr-2 text-primary'}
                type="button"
                onClick={() => {
                  getIsEditOrReadOnly() ? navigate(`/config-category-list`) : setisReadOnly(true);
                }}
              >
                {getIsEditOrReadOnly() ? t('T_BACK_TO_CATEGORY_LIST') : t('T_CANCEL')}
              </BiButton>
            )}

            {!getIsEditOrReadOnly() && (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  saveCategory();
                }}
                disabled={hasErrors() || validateSave() || isDataChanges()}
              >
                {t('T_SAVE')}
              </BiButton>
            )}
            {getIsEditOrReadOnly() && (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  setisReadOnly(false);
                }}
                disabled={false}
              >
                {t('T_EDIT')}
              </BiButton>
            )}
          </MenuButtonsPortal>
          <div className="flex w-full flex-wrap">
            {categoryObj ? (
              <>
                <div className="mb-5 flex w-full flex-col flex-wrap">
                  <InputField
                    type={'text'}
                    id={'categoryName'}
                    label={t('T_CATEGORY_NAME')}
                    placeholder={''}
                    fieldKey={'name'}
                    asterixFirst={true}
                    required={true}
                    formData={categoryObj}
                    setFormData={setCategoryObj}
                    formError={formError}
                    disabled={getIsEditOrReadOnly()}
                    handleChange={(e: any) => {
                      handleChange('name', e.target.value);
                    }}
                  />
                </div>
                <div className="mb-5 flex w-full flex-col flex-wrap">
                  <TextAreaField
                    fieldKey={'description'}
                    type={'text'}
                    id={'categoryDesc'}
                    label={t('T_CATEGORY_KEY_DESCRIPTION')}
                    placeholder={''}
                    required={false}
                    formData={categoryObj}
                    setFormData={setCategoryObj}
                    formError={formError}
                    asterixFirst={false}
                    rows={3}
                    disabled={getIsEditOrReadOnly()}
                  ></TextAreaField>
                </div>
              </>
            ) : null}
          </div>
        </Card>
      </div>
    </LoaderContainer>
  );
};

export default CategoryDetails;
