import React from 'react';
import { useTranslation } from 'react-i18next';

import BooleanQuestion from './AnswerDetailComponents/BooleanQuestion';
import DateQuestion from './AnswerDetailComponents/Date';
import DateTimeQuestion from './AnswerDetailComponents/DateTime';
import FreeText from './AnswerDetailComponents/FreeText';
import Likert from './AnswerDetailComponents/Likert';
import MultipleSelection from './AnswerDetailComponents/MultipleSelection';
import NumberEntry from './AnswerDetailComponents/NumberEntry';
import SingleSelection from './AnswerDetailComponents/SingleSelection';
import TextBlock from './AnswerDetailComponents/TextBlock';
import TimeQuestion from './AnswerDetailComponents/Time';
import URLComponent from './AnswerDetailComponents/URL';
import NumericComponent from './AnswerDetailComponents/NumericComponent';
import { asterisk } from '../../../assets/components/svgs';
import MediaQuestion from './AnswerDetailComponents/Media';
import LikertWithImage from './AnswerDetailComponents/LikertWithImage';
import BodyMapQuestion from './AnswerDetailComponents/BodyMap';

const AnswerDetails = ({
  formData,
  setFormData,
  formError,
  setFormError,
  questionType,
  editMode,
  editFromSurveyTemplate,
  resetData
}: any) => {
  const { t } = useTranslation();

  // console.log(questionType);

  const answerDescription = (questionType: string) => {
    switch (questionType.toLowerCase()) {
      case 'date':
        return (
          <>
            <DateQuestion
              formData={formData}
              setFormData={setFormData}
              editMode={editMode}
              formError={formError}
              setFormError={setFormError}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );

      case 'time':
        return (
          <>
            <TimeQuestion
              formData={formData}
              setFormData={setFormData}
              editMode={editMode}
              formError={formError}
              setFormError={setFormError}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );

      case 'datetime':
        return (
          <>
            <DateTimeQuestion
              formData={formData}
              setFormData={setFormData}
              editMode={editMode}
              formError={formError}
              setFormError={setFormError}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );

      case 'text':
        return (
          <>
            <div className="flex w-full flex-col">
              Patients will be able to answer with free text
            </div>
            <FreeText
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
            />
          </>
        );

      case 'multi':
        return (
          <>
            <div className="flex w-full flex-col">
              <span className="flex flex-row">
                <div className="self-center">{asterisk}</div>
                {t('T_MULTIPLE_SELECTION_ANSWER_DESCRIPTION')}
              </span>
            </div>
            <MultipleSelection
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );

      case 'numeric':
        return (
          <>
            <div className="flex w-full flex-col">
              <span>Patients will enter a numerical value in the format below:</span>
            </div>
            <NumberEntry
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );

      case 'single':
        return (
          <>
            <div className="flex w-full flex-col">
              <span className="flex flex-row">
                <div className="self-center">{asterisk}</div>
                {t('T_SINGLE_SELECTION_ANSWER_DESCRIPTION')}
              </span>
            </div>
            <SingleSelection
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );

      case 'display':
        return (
          <>
            <div className="flex w-full flex-col">
              <span>{t('T_TEXTBOX_ANSWER_DESCRIPTION')}</span>
            </div>
            <TextBlock
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
            />
          </>
        );

      case 'url':
        return (
          <URLComponent
            formData={formData}
            setFormData={setFormData}
            editMode={editMode}
            formError={formError}
            setFormError={setFormError}
            editFromSurveyTemplate={editFromSurveyTemplate}
          />
        );
      case 'integer':
      case 'decimal':
        return (
          <NumericComponent
            formData={formData}
            setFormData={setFormData}
            editMode={editMode}
            formError={formError}
            editFromSurveyTemplate={editFromSurveyTemplate}
          />
        );

      case 'boolean':
        return (
          <>
            <div className="flex w-full flex-col">
              <span>{t('T_BOOLEAN_ANSWER_DESCRIPTION')}</span>
            </div>
            <BooleanQuestion
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );
      case 'media':
        return (
          <>
            <MediaQuestion
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
              editFromSurveyTemplate={editFromSurveyTemplate}
              resetData={resetData}
            />
          </>
        );
      case 'likert':
        return (
          <>
            <Likert
              formData={formData}
              resetData={resetData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );

      case 'bodymap':
        // switch need to change as per metadata
        return (
          <>
            <BodyMapQuestion
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              editMode={editMode}
              editFromSurveyTemplate={editFromSurveyTemplate}
            />
          </>
        );
    }
  };
  return <>{answerDescription(questionType)}</>;
};

export default AnswerDetails;
