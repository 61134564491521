import {
  checkLowerUpperCase,
  eightChar,
  hasNumber,
  hasSpecialCharacter,
  includeNumber
} from './validators';
import CountryList from './Country';
import { find } from 'lodash';

export const passwordBuilder = (text: string) => {
  return [
    {
      text: 'T_VALIDATION_ERR_PASSWORD_MSG1',
      validator: eightChar(text)
    },
    {
      text: 'T_VALIDATION_ERR_PASSWORD_MSG2',
      validator: checkLowerUpperCase(text)
    },
    {
      text: 'T_VALIDATION_ERR_PASSWORD_MSG3',
      validator: includeNumber(text)
    },
    {
      text: 'T_VALIDATION_ERR_PASSWORD_MSG4',
      validator: hasSpecialCharacter(text)
    }
  ];
};

export const truncateMail = (email: string) => {
  return email.replace(/(\w{2})[\w.-]+@([\w.]+\w)/, '$1***@$2');
};

export const bucketGCSNaming = (text: string) => {
  let formatted = text
    .replace(/^[^\p{L}\p{N}]+|[^\p{L}\p{N}]+$/gu, '')
    .replaceAll(' ', '-')
    .toLowerCase()
    .replace(/\s*(-)\s*|[^\w-]+/g, (x, y) => y || '');
  return formatted;
};

export const dynamicFormData = () => {
  return [
    {
      tag: 'input',
      type: 'text',
      id: 'organizationName',
      label: 'T_NAME',
      placeholder: 'T_NAME',
      key: 'name',
      formClass: 'form-control',
      required: true,
      width: 'full',
      colSpan: 2,
      seq: 1,
      validateField: false,
      allowSplChar: true
    },
    {
      tag: 'phone',
      type: 'text',
      id: 'organizationPhone',
      label: 'T_PHONE',
      placeholder: 'T_PHONE',
      key: 'phone',
      formClass: 'form-control',
      countryCode: 'countryCode',
      required: false,
      codeDisabled: false,
      width: 'half',
      colSpan: 1,
      seq: 8
    },
    {
      tag: 'phone',
      type: 'text',
      id: 'organizationFax',
      label: 'T_FAX',
      placeholder: 'T_FAX',
      key: 'fax',
      formClass: 'form-control',
      codeDisabled: false,
      countryCode: 'faxCountryCode',
      required: false,
      width: 'half',
      colSpan: 1,
      seq: 9
    },
    {
      tag: 'input',
      type: 'text',
      id: 'organizationAddress',
      label: 'T_ADDRESS',
      placeholder: 'T_ADDRESS',
      key: 'address',
      formClass: 'form-control',
      required: false,
      width: 'full',
      colSpan: 2,
      seq: 3
    },
    {
      tag: 'input',
      type: 'text',
      id: 'organizationCity',
      label: 'T_CITY',
      placeholder: 'T_CITY',
      key: 'city',
      formClass: 'form-control',
      required: false,
      width: 'half',
      colSpan: 1,
      seq: 7
    },
    {
      tag: 'countryState',
      type: 'text',
      id: 'organizationState',
      label: 'T_STATE',
      placeholder: 'T_STATE',
      key: 'state',
      objectKey: 'value.code',
      formClass: 'form-control',
      required: false,
      width: 'half',
      colSpan: 1,
      seq: 5
    },
    {
      tag: 'input',
      type: 'text',
      id: 'organizationZip',
      label: 'T_ZIP',
      placeholder: 'T_ZIP',
      key: 'zip',
      formClass: 'form-control',
      required: false,
      width: 'half',
      colSpan: 1,
      seq: 6
    },
    {
      tag: 'countryState',
      type: 'text',
      id: 'organizationCountry',
      label: 'T_COUNTRY',
      placeholder: 'T_COUNTRY',
      key: 'country',
      objectKey: 'value.code',

      formClass: 'form-control',
      required: false,
      width: 'half',
      colSpan: 1,
      seq: 4
    },
    {
      tag: 'select',
      type: 'text',
      id: 'organizationType',
      label: 'T_TYPE',
      placeholder: 'T_TYPE',
      key: 'type',
      formClass: 'form-control',
      required: true,
      width: 'half',
      colSpan: 2,
      seq: 2,
      options: []
    }
  ];
};

export const roleDetailsFormSchema = (mode: number) => {
  return [
    {
      tag: 'input',
      type: 'text',
      id: 'roleName',
      label: 'T_APPLICATION_ROLE_NAME',
      placeholder: '',
      key: 'roleName',
      formClass: 'form-control',
      required: true,
      width: 'half',
      colSpan: 1,
      seq: 1,
      validateField: false
    },
    {
      tag: !mode ? 'input' : 'select',
      type: 'text',
      id: 'permissionSet',
      label: 'T_PERMISSION_SET',
      placeholder: !mode ? '' : 'T_SELECT',
      key: 'permissionSetName',
      objectKey: 'attributes.name',
      formClass: 'form-control',
      required: false,
      disabled: !mode,
      width: 'half',
      colSpan: 1,
      seq: 3,
      options: ['Permission Set 1', 'Permission Set 2']
    },
    {
      tag: 'textarea',
      type: 'text',
      id: 'description',
      label: 'T_ROLE_DESCRIPTION',
      placeholder: '',
      secondaryLabel: '(optional)',
      key: 'roleDescription',
      formClass: 'form-control',
      required: false,
      width: 'full',
      colSpan: 3,
      seq: 4,
      rows: 3
    }
  ];
};

export const surveyDetailsFormSchema = [
  {
    tag: 'input',
    type: 'text',
    id: 'surveyName',
    label: 'Survey Name',
    placeholder: '',
    key: 'surveyName',
    formClass: 'form-control',
    required: true,
    width: 'half',
    colSpan: 1,
    seq: 1,
    validateField: false,
    min: 1
  },
  {
    tag: 'input',
    type: 'text',
    id: 'surveyTitle',
    label: 'Survey Title',
    placeholder: '',
    key: 'surveyTitle',
    formClass: 'form-control',
    required: false,
    width: 'half',
    colSpan: 1,
    disabled: false,
    seq: 2,
    min: 1
  },
  {
    tag: 'picklist',
    type: 'single',
    id: 'surveyOrgId',
    label: 'T_SURVEY_ORAGANIZATION_ID',
    placeholder: '',
    key: 'name',
    formClass: 'form-control',
    fieldValues: ['name'],
    customOptions: 'orgOptionsList',
    onSelectedChange: 'onOrgSelectionChange',
    customSelectedItems: 'orgSelectedItems',
    fetchMoreData: 'fetchMoreOrgData',
    searchData: 'searchOrgData',
    searchable: true,
    searchPlaceholder: 'T_FILTER_KEY_ORGANIZATIONS',
    customHasMore: 'hasMoreOrg',
    hasMore: true,
    required: false,
    width: 'half',
    colSpan: 1,
    disabled: false,
    seq: 3,
    min: 1,
    onlySearch: true
  },
  {
    tag: 'picklist',
    type: 'single',
    id: 'surveyRoleId',
    label: 'T_SURVEY_ROLE_ID',
    placeholder: '',
    key: 'name',
    formClass: 'form-control',
    fieldValues: ['name'],
    customOptions: 'roleOptionsList',
    onSelectedChange: 'onRoleSelectionChange',
    customSelectedItems: 'roleSelectedItems',
    fetchMoreData: 'fetchMoreRoleData',
    searchData: 'searchRoleData',
    searchable: true,
    searchPlaceholder: 'T_SEARCH_ROLE_NAME_PLACEHOLDER',
    customHasMore: 'hasMoreRole',
    hasMore: true,
    required: false,
    width: 'half',
    colSpan: 1,
    disabled: false,
    seq: 4,
    min: 1,
    onlySearch: true
  },
  {
    tag: 'textarea',
    type: 'text',
    id: 'description',
    label: 'Description',
    placeholder: '',
    secondaryLabel: '(optional)',
    key: 'description',
    formClass: 'form-control',
    required: false,
    width: 'full',
    colSpan: 3,
    seq: 5,
    rows: 3,
    min: 1
  }
];

export const notificationFormSchema = (mode: string, type: string) => {
  let schema: any = [
    {
      tag: 'input',
      type: 'text',
      id: 'notificationName',
      label: 'T_TEMPLATE_NAME',
      placeholder: '',
      key: 'category',
      formClass: 'form-control',
      required: true,
      width: 'half',
      colSpan: 1,
      seq: 1,
      validateField: false,
      min: 1,
      disabled: mode === 'edit'
    },
    {
      tag: 'input',
      type: 'text',
      id: 'type',
      label: 'T_TYPE',
      placeholder: '',
      key: 'type',
      className: type === 'sms' ? 'uppercase' : 'capitalize',
      overrideValue: type === 'push_notification' ? 'Push' : '',
      formClass: 'form-control',
      required: false,
      width: 'half',
      colSpan: 1,
      disabled: true,
      seq: 2,
      min: 1
    },
    {
      tag: 'textarea',
      type: 'text',
      id: 'description',
      label: 'Description',
      placeholder: '',
      secondaryLabel: '(optional)',
      key: 'description',
      formClass: 'form-control',
      required: false,
      width: 'full',
      colSpan: 3,
      seq: 3,
      rows: 3,
      min: 1
    }
  ];
  if (type === 'email') {
    schema = schema.concat([
      {
        tag: 'input',
        type: 'text',
        id: 'fromName',
        label: 'T_TEMPLATE_FROM_NAME',
        placeholder: '',
        key: 'fromName',
        formClass: 'form-control',
        required: false,
        width: 'half',
        colSpan: 1,
        seq: 4,
        validateField: false,
        min: 1
      },
      {
        tag: 'input',
        type: 'text',
        id: 'type',
        label: 'T_TEMPLATE_FROM_EMAIL',
        placeholder: '',
        key: 'fromEmail',
        formClass: 'form-control',
        required: false,
        width: 'half',
        colSpan: 1,
        disabled: false,
        seq: 5,
        min: 1
      }
    ]);
  }
  return schema;
};

export const surveyGroupQuestionFormSchema = [
  {
    tag: 'input',
    type: 'text',
    id: 'questionName',
    label: 'Group Name',
    placeholder: '',
    key: 'questionName',
    formClass: 'form-control',
    required: true,
    width: 'full',
    colSpan: 3,
    seq: 1,
    validateField: false,
    min: 1
  },
  {
    tag: 'textarea',
    type: 'text',
    id: 'description',
    label: 'Description',
    placeholder: '',
    secondaryLabel: '(optional)',
    key: 'description',
    formClass: 'form-control',
    required: false,
    width: 'full',
    colSpan: 3,
    seq: 4,
    rows: 3,
    min: 1
  }
];

export const NewOrganizationFormLayout = {
  oneColumn: 1,
  twoColumn: 2,
  threeColumn: 3
};

export const permissionDetailsFormSchema = [
  {
    tag: 'input',
    type: 'text',
    id: 'permissionName',
    label: 'T_NAME',
    placeholder: 'T_DESC_PERMISSION_SET',
    key: 'permissionName',
    formClass: 'form-control',
    required: true,
    width: 'half',
    colSpan: 1,
    seq: 1,
    validateField: false
  },
  {
    tag: 'input',
    type: 'text',
    id: 'roleName',
    label: 'T_ROLE',
    placeholder: '',
    key: 'roleName',
    formClass: 'form-control',
    required: false,
    disabled: true,
    width: 'half',
    colSpan: 1,
    seq: 2
  },
  {
    tag: 'textarea',
    type: 'text',
    id: 'description',
    label: 'T_DESCRIPTION',
    placeholder: 'T_DESC_PERMISSION_DESCRIPTION',
    secondaryLabel: '(optional)',
    key: 'description',
    formClass: 'form-control',
    required: false,
    width: 'full',
    colSpan: 2,
    seq: 4,
    rows: 3,
    min: 0,
    max: 1024
  }
];

export const questionSchema = [
  {
    tag: 'input',
    type: 'text',
    id: 'questionTypeLabel',
    label: 'T_TYPE',
    placeholder: '',
    key: 'questionTypeLabel',
    formClass: 'form-control',
    required: false,
    disabled: true,
    width: 'half',
    colSpan: 1,
    seq: 1,
    min: 1,
    max: 255
  },
  {
    tag: 'input',
    type: 'text',
    id: 'questionName',
    label: 'T_QUESTION_NAME',
    placeholder: '',
    key: 'questionName',
    formClass: 'form-control',
    required: true,
    disabled: false,
    width: 'half',
    colSpan: 1,
    seq: 2,
    min: 1,
    validateField: false
  },
  {
    tag: 'input',
    type: 'text',
    id: 'question',
    label: 'T_QUESTION',
    placeholder: '',
    key: 'question',
    formClass: 'form-control',
    required: true,
    disabled: false,
    width: 'half',
    colSpan: 2,
    seq: 3,
    min: 1
  },
  {
    tag: 'textarea',
    type: 'text',
    id: 'questionDescription',
    label: 'T_DESCRIPTION',
    placeholder: '',
    key: 'questionDescription',
    formClass: 'form-control',
    required: false,
    disabled: false,
    width: 'half',
    colSpan: 2,
    rows: 3,
    seq: 4,
    min: 0
  }
];

export const urlPATTERN =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i;
