import { AppConstants } from '../constants/AppConstants';

export const eightChar = (text: string) => {
  return text.length >= 8;
};

export const checkLowerUpperCase = (text: string) => {
  const upper = /[A-Z]/.test(text),
    lower = /[a-z]/.test(text);

  return upper && lower;
};

export const hasNumber = (text: string) => {
  return /^[0-9]+$/.test(text);
};

export const includeNumber = (text: string) => {
  return /\d/.test(text);
};

export const hasSpecialCharacter = (text: string) => {
  return /^(?=.*[!@#$%^&*]).*$/.test(text);
};

export const entirePasswordValidation = (text: string) => {
  return /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(text);
};

export const consentNameValidation = (text: string) => {
  return text.length < AppConstants.DEFAULT_TEXT_MAX_LENGTH && /^[a-zA-Z0-9 _.-]*$/.test(text);
};

export const consentVersionValidation = (version: any, t?: any) => {
  if (version < AppConstants.MAX_CONSENT_VERSION || version === '') {
    return '';
  } else {
    return `${t('T_INT4_CONSENT_VERSION_VALIDATION')}`;
  }
};

export const firstLastNameValidation = (text: string) => {
  return /^[a-zA-Z ]*$/.test(text);
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const fieldIsEmpty = (text: string) => {
  return text === '';
};

export const validateContact = (contact: string, required: boolean, t?: any) => {
  const PHONE_NUMBER_REGEX = /^(\+\d{1,3}[- ]?)? \d{10}$/; // phone with contry code max length 13- /^(\+|\d)[0-9]{7,13}$/
  const contactNumber = contact.replace(/[^\d]/g, '');
  if (required) {
    if (fieldIsEmpty(contactNumber)) {
      return `${t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')}`;
    } else if (!hasNumber(contactNumber)) {
      return `${t('T_VALIDATION_INPUT_ERR_ONLY_NUMBER_MSG')}`;
    } else if (contactNumber.length !== 10) {
      return `${t('T_VALIDATION_ERR_CONTACT_NUMBER')}`;
    } else {
      return '';
    }
  } else {
    if (!fieldIsEmpty(contactNumber)) {
      if (!hasNumber(contactNumber)) {
        return `${t('T_VALIDATION_INPUT_ERR_ONLY_NUMBER_MSG')}`;
      } else if (contactNumber.length !== 10) {
        return `${t('T_VALIDATION_ERR_CONTACT_NUMBER')}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
};

export const validatePoastalCode = (poastalCode: string) => {
  if (poastalCode.length === 5 && /^[0-9]+$/.test(poastalCode)) {
    return true;
  } else {
    return false;
  }
};

export const validateLength = (text: string, t?: any, length: number = 255) => {
  if (text.length > length) {
    return `${t('T_VALIDATION_ERR_TEXT_LENGTH_CUSTOM_MSG').replace('${LENGTH}', length)}`;
  } else {
    return '';
  }
};

export const validateName = (text: string, t?: any) => {
  if (fieldIsEmpty(text.trim())) {
    return `${t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')}`;
  } else if (text.length > 255) {
    return `${t('T_VALIDATION_ERR_TEXT_LENGTH_MSG')}`;
  } else {
    return '';
  }
};

export const validateAddress = (text: string, t?: any) => {
  if (fieldIsEmpty(text.trim())) {
    return `${t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')}`;
  } else if (text.length > 1024) {
    return `${t('T_VALIDATION_ERR_TEXT_TOO_LONG_MSG')}`;
  } else {
    return '';
  }
};

export const formatNameField = (text: string, noSpace: boolean = true) => {
  const REGEX = noSpace ? /[^a-z0-9\-\_\.]/gi : /[^a-z0-9\s\-\_\.]/gi;
  const formattedValue = text.replace(REGEX, '');
  return formattedValue;
};

export const validateTemplateName = (text: string) => {
  if (text.length > 255) {
    return false;
  }
  return true;
};

export const validateNameLength = (text: string, maxAllowedCharacters: number) => {
  return text.length <= maxAllowedCharacters;
};
