import { useEffect, useState } from 'react';
import styled from 'styled-components';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { useNavigate } from 'react-router-dom';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import Checkbox from '../shared/Fields/Checkbox';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

interface ApplicationFeatureProps {
  ActionButtons?: any;
  setStpperStep?: Function;
  applicationFeatures: any;
  rows?: any;
  columnFeature?: any;
  setRows?: any;
  resetData: Function;
  active?: boolean;
}

export const onSelectionChange = (data: any, action: any, index: any, rows: any, setRows: any) => {
  let tableRows = rows;
  for (let i = 0; i < rows.length; i++) {
    if (tableRows[i].name === data.name) {
      tableRows[i][action].checked = !data[action].checked;
    }
  }
  setRows([...tableRows]);
};

const ApplicationFeatureDataGrid: React.FC<ApplicationFeatureProps> = ({
  ActionButtons = null,
  setStpperStep = () => {},
  applicationFeatures = {},
  rows,
  columnFeature,
  setRows,
  resetData,
  active = true
}) => {
  useEffect(() => {
    applicationFeatures;
  }, []);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [appFeatures, setAppFeatures] = useState(applicationFeatures);
  const [columns, setColumns] = useState<Array<ITableHeader>>([]);
  const [size, setSize] = useState('10');
  const [currentPage, setCurrentPage] = useState('1');
  const [currentActivePage, setCurrentActivePage] = useState(0);
  const [on, setOn] = useState(false);
  const { t } = useTranslation();

  let createColumns: any;
  useEffect(() => {
    createColumns = (columnData: Array<any> = []): Array<ITableHeader> => {
      const appColums: Array<ITableHeader> = columnData.map((column: any, index: number) => {
        return {
          key: column?.action,
          description: column?.action,
          sortEnabled: false,
          width: '10%',
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={data[column.action]?.checked}
                  disabled={
                    !active || !data[column.action] || (!active && !data[column.action]?.checked)
                  }
                  onChange={() =>
                    active && onSelectionChange(data, column.action, index, rows, setRows)
                  }
                />
              </>
            );
          },
          sortingInformation: {
            order: ''
          }
        };
      });
      return appColums.length
        ? [
            {
              key: 'name',
              description: `${t('T_NAME')}`,
              sortEnabled: false,
              width: '50%',
              sortingInformation: {
                order: ''
              }
            },
            ...appColums
          ]
        : [];
    };
    if (Object.keys(appFeatures).length) {
      setColumns(createColumns(columnFeature));
    }
  }, [active, appFeatures]);

  useEffect(() => {
    setCurrentActivePage(parseInt(currentPage) * parseInt(size) - parseInt(size));
  }, [currentPage]);

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <div style={{ minHeight: '60vh' }}>
          <CustomersTableContainer>
            <TemplateTable
              tableData={
                rows.length
                  ? rows.slice(currentActivePage, currentActivePage + parseInt(size))
                  : `${t('T_NO_DATA_AVAILABLE_MSG')}`
              }
              size={size}
              currentPage={currentPage}
              templateSelector={() => {}}
              onRefreshTableData={() => {}}
              tableHeaders={columns}
              setTableHeaders={null}
              searchPlaceholder={t('T_SEARCH_APP_FEATURES') || ''}
              isPaginated={false}
              containerClass="mt-0"
              isLocalPaginator
              totalDataLength={rows.length}
              setSize={setSize}
              setCurrentPage={setCurrentPage}
              isSearchable={false}
              tableName={t('T_TABLE_APP_PERMISSION_NAME') || ''}
              theme={useTheme().theme}
              tableBodyClass="max-h-[50vh] block overflow-y-scroll"
            />
          </CustomersTableContainer>
        </div>

        {ActionButtons && (
          <ActionButtons
            primaryButtonLabel={t('T_NEXT')}
            primaryButtonAction={() => {
              setStpperStep(3);
            }}
            secondaryButtonLabel={t('T_BUTTON_BACK_TO_PERMISSION_SET')}
            secondaryButtonAction={() => {
              setStpperStep(1);
            }}
          />
        )}
      </LoaderContainer>
    </>
  );
};

export default ApplicationFeatureDataGrid;
