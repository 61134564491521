import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TemplateTable from '../../shared/tables/TemplateTable';
import { ITableHeader } from '../../shared/tables/models/ITableHeader';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import { setURLPaths } from '../../../app/organizationReducer';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import { setToastData } from '../../../app/toastReducer';
import { noDataAvailableMessage } from '../../Organizations/helpers';
import { AppConstants } from '../../../constants/AppConstants';
import { RouteConstants } from '../../../constants/RouteConstants';
import { getFailureMessage } from '../../../util/ErrorUtil';
import { useTheme } from '../../../context/themeContext';
import { useTranslation } from 'react-i18next';
import MenuButtonsPortal from '../../Menu/MenuButtonsPortal';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import { getSortTableArray } from '../../../util/table-utils';
import MigrationJobFilter, { filterConfigProps } from './migrationJobFilter';
import {
  listMigrationExport,
  getMigrationExportJob,
  startExportJob
} from '../../../http/migration-service';
import StatusTag from './StatusTag';
import moment from 'moment';
import MigrationContextMenu from './MigrationContextMenu';
import refreshIcon from '../../../assets/refresh.svg';
import { IconButton } from '../../primitives/buttons/IconButton';
import { cloneDeep, findIndex } from 'lodash';
import AddCircle from '../../../assets/addCircle.svg';
import LogViewer from './LogViewer';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const defaultSystemFilterOption = {
  purposeOption: new Map(),
  categoryOption: new Map(),
  statusOption: []
};

/**
 * @parentId migrations
 * @manager Export Listing
 * @overview
 *   <section>
 *       <p>
 *          Export listing page is the default page the user is navigated on clicking <b>Migrations</b> navigation bar menuitem. Listing page will show all the Export(s) jobs in a paginated way.
 *          The Default pagination size be fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & Export(s) data.
 *       </p>
 *       </br>
 *       <p>
 *          Export(s) job can be filtered based on the status attribute with filter button provided above the template listing data grid. We can filter the Export(s) list with multiple status.
 *       </p>
 *       <br/>
 *       <p>In the table grid we have context menu for each item with options: <b>Download zip file</b> with this we can download an export job in a ZIP format, <b>View Log</b> with this we can view logs and even download the log file. Context menu option can be changes as per the job status</p>
 *       <br/>
 *       <p>For status like 'starting', 'stopping', 'unknown' and 'started' we have a Refresh Icon onClick of which the status of the job is refreshed </p>
 *       <br/>
 *       <p>For status like 'failed', 'unknown' and 'abandoned' we have a Retrigger Icon onClick of which the Job is Retriggered with the timestamp of when it was triggered </p>
 *       <br/>
 *       <p>
 *           Admin user(s) can create new export job by clicking on <b>New Export</b> button provided on the admin portal toolbar,
 *           which will navigate user to Export job creation page. <b>New Export</b> button will be shown based on logged in
 *           user role API permissions as described below.
 *       </p>
 *       </br>
 *       <p>
 *           Datagrid will display details of each Export job as row item with each column representing question property.
 *           <br>
 *           <ul>
 *               <li>Status</li>
 *               <li>Export Name</li>
 *               <li>Description</li>
 *               <li>Created On</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *       </p>
 *       <p>
 *           Sorting of Export grid is not supported on any column. The breadcrumb bar on the application toolbar will reflect location of the user in the admin portal. Clicking on suvrey question row item will drill down user to question details page which will be displayed in readonly mode.
 *       </p>
 *     </section>
 *     <section>
 *     <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the List Export(s) Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *     <h4>Dependent System settings, Platform services & Role Permission</h3>
 *     <p>This section describes the list of dependent system settings & platform services required for functioning of Export(s) job listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *     <h5>Platform Service(s)</h4>
 *     <p>Table lists all the dependent platform service(s) with specific version(s) for Export(s) job listing</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Migration Service</td>
 *               <td>1.1.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *     <h5>API Role Permission(s)</h4>
 *     <p>Table lists the required API role permissions for listing metadata applications page</p>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *             <td>/export</td>
 *             <td>GET</td>
 *             <td>migration-service.migration-export.list</td>
 *             <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *   <p>Sequence Diagram for metadata application listing page</p>
 *   </section>
 */

const ExportList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(AppConstants.DEFAULT_PAGE);
  const [size, setSize] = useState<number>(AppConstants.DEFAULT_PAGE_SIZE);
  const [pagingOpts, setPagingOpts] = useState<any>(null);

  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [logVisibility, toggleLogVisibility] = useState<boolean>(false);
  const [logUrl, setLogUrl] = useState<string>('');

  const [formFilterData, setFormFilterData] = useState<filterConfigProps>({
    status: '',
    name: ''
  });
  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);

  useEffect(() => {
    const {
      searchText = '',
      currentPage = AppConstants.DEFAULT_PAGE,
      size: currentSize = AppConstants.DEFAULT_PAGE_SIZE,
    } = globalFilters['export-list'] || {};
    refreshTableData(searchText, currentPage, currentSize);
  }, [formFilterData]);

  const jobSelector = (data: any, step: number) => {
    navigate(RouteConstants.ROUTE_MIGRATION_EXPORT_DETAIL_EDIT.replace(':id', data.id));
  };

  const refreshTableData = (name: string, page: number, size: number) => {
    setIsLoading(true);
    setTableValues(name, page, size);
  };

  const setTableValues = (name: string = '', page: number, size: number) => {
    setSize(size);
    setPage(page);
    fetchExportJobs(size, page, name);
  };

  const reTriggerJob = async (data: any) => {
    const { name, description, manifest } = data;

    const isRetrigger = name.includes('_Retrigger_');
    const utcTimeFormat = moment.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    let newName;
    if (isRetrigger) {
      const nameParts = name.split('_');
      const index = nameParts.findIndex((part: any) => part === 'Retrigger');
      if (index !== -1) {
        nameParts.splice(index + 1, 1, utcTimeFormat);
      }
      newName = nameParts.join('_');
    } else {
      newName = `${name}_Retrigger_${utcTimeFormat}`;
    }
    const payload = {
      name: newName,
      description,
      manifest
    };
    setIsLoading(true);
    try {
      let response = await startExportJob(payload);
      const { data = {}, status } = response;
      if ([HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(status)) {
        dispatch(
          setToastData({
            toastMessage: t('T_MIGRATION_EXPORT_RETRIGGER_SUCCESS'),
            isToastActive: true,
            toastType: 'success'
          })
        );
        let clone = cloneDeep(rows);
        clone.unshift(data);
        setRows(clone);
        setIsLoading(false);
      }
    } catch (e: any) {
      setIsLoading(false);
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_ERROR_SOMETHING_WRONG'),
          toastType: 'error'
        })
      );
    }
  };

  const fetchExportJobs = async (size: number, page: number, name: string = '') => {
    const filter: string = getFilters();
    const sorters: string[] = getSortTableArray(tableHeaders);
    let resposne = await listMigrationExport(size, page, filter, sorters.join());
    const { data = {}, status } = resposne;
    const { data: jobList = [], paging } = data;
    if (status === HTTP_STATUS.HTTP_OK) {
      if (jobList.length) {
        setRows(jobList);
        setPagingOpts(paging);
      } else {
        noDataAvailableMessage(setRows, setSize, setPagingOpts, paging, t);
      }
    } else {
      let error = getFailureMessage(resposne);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
    setIsLoading(false);
  };

  const refreshStatus = async (data: any) => {
    const { id } = data;
    try {
      const response = await getMigrationExportJob(parseInt(data.id));
      const { data: exportJob, status } = response;
      if (status === 200) {
        let index = findIndex(rows, function (item: any) {
          return item.id === id;
        });
        if (index !== -1) {
          let clone = cloneDeep(rows);
          clone[index] = exportJob;
          setRows(clone);
        }
      }
    } catch (e: any) {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_ERROR_SOMETHING_WRONG'),
          toastType: 'error'
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      setURLPaths([
        {
          key: RouteConstants.ROUTE_MIGRATION_EXPORT_LIST,
          label: 'Migration'
        }
      ])
    );
  }, []);

  const RefreshStatus = (data: any) => {
    return (
      <div className="mr-2 cursor-pointer !px-2 !py-1" onClick={() => refreshStatus(data)}>
        <img className="h-5 w-5" src={refreshIcon}></img>
      </div>
    );
  };

  const exportJobHeader: Array<ITableHeader> = [
    {
      key: 'status',
      description: 'T_STATUS',
      sortEnabled: false,
      width: '15%',
      sortingInformation: {
        order: ''
      },
      clickable: true,
      nastedData: true,
      render: (data: any) => {
        const { status } = data;
        return <StatusTag status={status} />;
      },
      styles: {
        notCursor: true
      }
    },
    {
      key: 'name',
      description: 'T_EXPORT_NAME',
      sortEnabled: false,
      width: '25%',
      clickable: true,
      sortingInformation: {
        order: ''
      },
      styles: { borderRight: 'none', notCursor: true }
    },
    {
      key: 'options',
      description: '',
      sortEnabled: false,
      width: '10%',
      clickable: false,
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return (
          <div
            className="ml-auto flex flex-row hover:bg-[#d9d9d9]"
            style={{ borderRadius: '10px' }}
          >
            {['starting', 'stopping', 'unknown', 'started'].includes(data.status)
              ? RefreshStatus(data)
              : ''}

            <MigrationContextMenu
              className="!px-1.5 !py-1"
              iconSize=""
              migrationJob={data}
              setIsLoading={setIsLoading}
              dropdownMenu={false}
              jobType={AppConstants.MIGRATION_JOB_EXPORT}
              reTrigger={() => {
                reTriggerJob(data);
              }}
              viewLog={(url: string) => {
                setLogUrl(url);
                toggleLogVisibility(true);
              }}
            />
          </div>
        );
      },
      styles: {
        paddingRight: '0',
        paddingLeft: '0',
        borderLeft: 'none',
        rowStyle: { fontWeight: 'normal', padding: '0px' },
        notCursor: true
      }
    },
    {
      key: 'description',
      description: 'T_DESCRIPTION',
      sortEnabled: false,
      clickable: true,
      width: '35%',
      sortingInformation: {
        order: ''
      },
      styles: {
        notCursor: true
      }
    },
    {
      key: 'createdOn',
      description: 'T_CREATED_ON',
      sortEnabled: true,
      clickable: true,
      width: '15%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return moment(data?.createdOn).format(AppConstants.DEFAULT_DATE_FORMAT);
      },
      styles: {
        notCursor: true
      }
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: true,
    //   clickable: true,
    //   width: '15%',
    //   sortingInformation: {
    //     order: ''
    //   },
    //   styles: {
    //     notCursor: true
    //   }
    // },
    {
      key: 'updatedOn',
      description: 'T_LAST_MODIFIED_ON',
      sortEnabled: false,
      clickable: true,
      width: '15%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return moment(data?.createdOn).format(AppConstants.DEFAULT_DATE_FORMAT);
      },
      styles: {
        notCursor: true
      }
    }
  ];

  const getFilters = () => {
    let filter: string[] = [];
    Object.entries(formFilterData).forEach(([key, value]) => {
      if (value) {
        if (key === 'status') {
          let tempVal = value.map((ele: any) => ele.value);
          filter.push(`${key}=${tempVal.join(',')}`);
        } else {
          filter.push(`${key}=${value}`);
        }
      }
    });
    return filter.join('&');
  };

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(exportJobHeader);

  useEffect(() => {
    if (rows.length) {
      let tempHeader = [...exportJobHeader];
      tableHeaders.forEach((ele: any, index: number) => {
        if (ele.key === 'createdOn') {
          tempHeader[index] = ele;
        }
      });
      setTableHeaders(tempHeader);
    }
  }, [rows]);

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        {logVisibility && logUrl ? (
          <LogViewer show={logVisibility} setShow={toggleLogVisibility} url={logUrl}></LogViewer>
        ) : (
          ''
        )}
        <MenuButtonsPortal>
          <BiButton
            className={'flex bg-primary text-white'}
            type="button"
            disabled={false}
            onClick={() => {
              navigate(RouteConstants.ROUTE_MIGRATION_EXPORT_DETAIL_CREATE);
            }}
          >
            <img src={AddCircle} className="mr-2 w-6 text-center"></img>
            {t('T_NEW_EXPORT')}
          </BiButton>
        </MenuButtonsPortal>

        <div>
          <MigrationJobFilter
            filterConfig={formFilterData}
            setFilterConfig={setFormFilterData}
            jobType={'EXPORT'}
          />
        </div>

        <CustomersTableContainer style={{ height: 'inherit' }}>
          <TemplateTable
            tableData={rows}
            size={size.toString()}
            currentPage={page.toString()}
            templateSelector={() => {}}
            cellSelector={() => {}}
            onRefreshTableData={refreshTableData}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            searchPlaceholder={t('T_SEARCH_NAME')}
            pagingData={pagingOpts}
            containerClass="mt-0"
            showDeleteButton={true}
            theme={useTheme().theme}
            isSearchable={false}
            globalFilters={globalFilters}
            tablePage="export-list"
          />
        </CustomersTableContainer>
      </LoaderContainer>
    </>
  );
};

export default ExportList;
