import { createSlice } from '@reduxjs/toolkit';
import { SystemSetting } from '../util/RoleUtil';

export const organizationSlice = createSlice({
  name: 'organizationManagement',
  initialState: {
    organizationList: [],
    parentDetails: [],
    organizationData: {},
    loggedInOrganization: null,
    urlPaths: [],
    organizationSetting: {} as SystemSetting,
    organizationPermission: [],
    organizationDetails: {} as any,
  },
  reducers: {
    setOrganizationList: (state, action) => {
      state.organizationList = action.payload;
    },
    setParentDetails: (state, action) => {
      state.parentDetails = action.payload;
    },
    setIndividualOrganizationData: (state, action) => {
      state.organizationData = action.payload;
    },
    setLoggedInOrganization: (state, action) => {
      state.loggedInOrganization = action.payload;
    },
    setURLPaths: (state, action) => {
      state.urlPaths = action.payload;
    },
    resetOrganizationManagement: (state) => {
      state.organizationList = [];
      state.parentDetails = [];
      state.organizationData = {};
    },
    setOrganizationSetting: (state, action) => {
      state.organizationSetting = action.payload;
    },
    setOrganizationPermission: (state, action) => {
      state.organizationPermission = action.payload;
    },
    setOrganizationDetails: (state, action) => {
      state.organizationDetails = action.payload;
    },

  }
});

export const {
  setOrganizationList,
  setParentDetails,
  setIndividualOrganizationData,
  setLoggedInOrganization,
  setURLPaths,
  resetOrganizationManagement,
  setOrganizationSetting,
  setOrganizationDetails
} = organizationSlice.actions;

export default organizationSlice.reducer;
