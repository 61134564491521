import styled from 'styled-components/macro';
import {
  colorBlack,
  colorBlueFont,
  colorBorderAlert1,
  colorLightGray4,
  colorLightGray8,
  colorLightGray9,
  colorProductPrimary,
  colorWhite,
  flexColumn,
  gridCenter
} from '../../styles/globalVariables';
import DropdownSvg from '../../assets/dropdown.svg';
import { StyledModal } from '../Onboarding/OnboardingStyles';
import { uTransitionTranslateY, uTransitionVisiblityOnly } from '../../styles/utility';
import { device } from '../../styles/mediaQueries';
import { IError } from '../Onboarding/interfaces';
import { SharedTextInput, TextLink } from '../Reusable/Shared';

type LoginModalProps = {
  mode: number;
};

export const Dropdown = styled.select`
  padding: 10px 12px;
  width: 300px;
  height: 44px;
  background: #ffffff;
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 5px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;

  color: #262626;

  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  background: transparent;
  overflow: hidden;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  background-image: url(${DropdownSvg});
  background-position: top 60% right 10px;
  background-repeat: no-repeat;
`;

export const LoginDropdownContainer = styled(DropdownContainer)`
  margin-left: auto;
  display: flex;
  margin-right: 1.875rem;
`;

export const LanguageDropdown = styled(Dropdown)`
  height: 2.375rem;
  width: 12.5rem;
  margin: auto;
  padding: 0;
  padding-left: 0.75rem;
  ${gridCenter}
  border-radius: 4px;
`;
export const Background = styled.div`
  ${props => props.theme.bgColorStyleForLoginPage}
  height: 95.7vh;
  display: grid;
  place-items: center;
`;

export const LoginBackground = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const LoginModal = styled(StyledModal)<LoginModalProps>`
  flex-direction: row;
  width: 59.75rem;
  height: ${({ mode }) => (mode == 2 ? '48rem' : '39rem')};
  border-radius: 7px;
  margin-inline: auto;
  position: relative;

  @media ${device.tablet} {
    flex-direction: column;
    width: 29.875rem;
    background-color: rgb(${colorLightGray4});
    margin: auto;
  }
`;

export const ModalLeft = styled.div`
  ${flexColumn}
  flex: 0 0 50%;
  height: 100%;
  gap: 4.625rem;
  position: relative;

  h1 {
    font-size: 1.375rem;
    color: rgb(${colorProductPrimary});
    position: absolute;
    top: 6.375rem;
  }

  img {
    justify-self: center;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const ModalRight = styled.div`
  background-color: rgb(${colorLightGray4});
  flex: 0 0 50%;
  height: 100%;
  ${flexColumn}
  justify-content: flex-start;
  padding-top: 5.6rem;
  border-start-end-radius: 7px;
  border-end-end-radius: 7px;

  & > h1 {
    font-size: 1.9375rem;
    font-weight: 300;
    color: rgb(${colorBlueFont});
  }

  & > h2 {
    color: rgb(${colorLightGray8});
    padding-top: 0.625rem;
  }

  @media ${device.tablet} {
    width: 100%;
    border-radius: 7px;
    height: 100%;
  }
`;

export const ModalRightForgotPassword = styled(ModalRight)`
  background-color: #f5f7fa;
  padding: 135px 80px;
  & > h2 {
    color: #001423;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: Open Sans;
  }
`;

export const ModalRightForgotPasswordStep2 = styled(ModalRightForgotPassword)`
  padding: 3.125rem 3.875rem;
  color: #001423;
  font-size: 13px;
`;
export const RequestNewOtp = styled(TextLink)`
  font-size: 0.875rem;
  color: #0560a9;
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  margin-top: 5px;
`;

export const Step2Divider = styled.div`
  height: 8px;
  background: #ebeef2;
  width: 100%;
  margin: 8px 0px 4px 0px;
`;

export const InputContainer = styled.div`
  color: rgb(${colorLightGray9});
  padding-top: 3.75rem;
`;

export const EmailContainer = styled.div`
  ${flexColumn}
  align-items: flex-start;
`;

export const RememberMeContainer = styled.div`
  padding-top: 0.375rem;
`;

export const RememberMeLabel = styled.label`
  cursor: pointer;
`;

export const RememberMeCheckbox = styled.input`
  all: revert;
  cursor: pointer;
  margin-right: 0.5rem;
`;

export const Label = styled.label`
  font-size: 0.875rem;
`;

export const EmailLabel = styled(Label)`
  /* color: rgb(${colorLightGray9}); */
`;

export const Input = styled(SharedTextInput)<IError>`
  height: 2.75rem;
  width: 20rem;
  margin-top: 0.3125rem;
`;

export const Email = styled(Input)``;

export const OtpInput = styled(SharedTextInput)<IError>`
  height: 2.75rem;
  width: 10rem;
`;

export const PasswordContainer = styled.div`
  ${flexColumn}
  align-items: flex-start;
  position: relative;
  padding-top: 1rem;
`;

export const CapsToolTip = styled.span`
  background-color: rgb(${colorWhite});
  position: absolute;
  height: 2.125rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
  right: -8rem;
  top: 2.8rem;
  font-size: 0.875rem;
  padding: 0 0.6rem;
  box-shadow: 0px 9px 28px 8px rgba(${colorBlack}, 0.05), 0px 3px 6px -4px rgba(${colorBlack}, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(${colorBlack}, 0.08));

  ${uTransitionVisiblityOnly}

  & > svg {
    color: rgb(${colorWhite});
    transform: translateX(-100%);
    position: absolute;
    left: 0;
  }
`;

export const PasswordLabel = styled(Label)``;

export const NewPasswordLabel = styled(Label)`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #000000;
`;

export const Password = styled(Input)``;

export const EyeButton = styled.button`
  position: absolute;
  right: 1rem;
  ${flexColumn}
  font-size: .5rem;
  color: rgb(${colorLightGray9});

  & > svg {
    margin-bottom: -0.2rem;
  }
`;
export const EyeButtonConfirmPassword = styled(EyeButton)`
  right: 2.5rem;
  top: 3.5rem;
  justify-content: flex-end;
`;

export const ForgotPassword = styled(TextLink)`
  font-size: 0.9375rem;
  align-self: flex-end;
  cursor: pointer;
`;

export const LoginFooter = styled.div`
  position: fixed;
  bottom: -7.2rem;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  padding-top: 2.1875rem;
  ${flexColumn}
  gap: 0.625rem;
`;

// export const SignInButton = styled(AgreeButton)`
//   width: 20rem;
// `;

// export const RegisterButton = styled(SecondaryButton)`
//   width: 20rem;
//   margin-top: 0.625rem;
// `;

export const LockoutContainer = styled.div`
  position: absolute;
  top: 2rem;
  width: 25rem;
`;

export const ToastContainer = styled.div`
  position: absolute;
  top: 1rem;
  margin-inline: auto;
  transform: translateY(-32px);
  left: 0;
  right: 0;
  z-index: 100;
`;

/**
 * Verification
 */

export const StyledVerification = styled(StyledModal)`
  width: 26.5rem;
  position: fixed;
  pointer-events: all;
  z-index: 2000;
  justify-content: flex-start;
  gap: 1.5rem;
  padding-bottom: 1rem;

  ${uTransitionTranslateY}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;

  & > h1 {
    font-size: 1.125rem;
    margin-top: 1rem;
  }

  & > p {
    font-size: 0.875rem;
    color: rgb(${colorLightGray8});
    text-align: center;
    width: 48ch;
    margin-top: 1rem;
  }
`;

export const MessageContainer = styled.div`
  ${flexColumn}
`;

export const AlertContainer = styled.div`
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VerificationInput = styled(Input)`
  margin-top: 0;
  width: 16.5rem;
  height: 2.25rem;
`;

export const StyledErrorMessage = styled.div<IError>`
  display: ${(p) => (p.isError ? 'flex' : 'none')};
  align-items: center;
  font-size: 0.875rem;
  color: rgb(${colorBorderAlert1});
  gap: 0.3rem;
  align-self: center;
  margin-top: 0.4rem;
`;

export const AuthLinks = styled.div`
  display: flex;
  gap: 1.875rem;
`;

export const AuthLink = styled(TextLink)<IError>`
  font-size: 0.875rem;
`;

/**
 * AccountLockoutModal
 */

export const LockoutTimeContainer = styled.div`
  & > h2 {
    font-weight: 600;
  }
`;

export const CheckedValidation = styled.div`
  display: flex;
  flex-direction: row;
  color: #00ac69;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
`;
export const UnCheckedValidation = styled(CheckedValidation)`
  color: #858585;
`;
