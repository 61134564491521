import React, { useEffect, useState } from 'react';
import close from '../../../../assets/close.svg';
import { plusSmall } from '../../../../assets/components/svgs';
import { find } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import InputField from '../../../shared/Fields/InputField';
import BiButton from '../../../primitives/buttons/BiButton.primitive';
import { useTranslation } from 'react-i18next';
import { setToastData } from '../../../../app/toastReducer';
import Checkbox from '../../../shared/Fields/Checkbox';
import { SVGMap } from '../QueUtils';

const ConfigureBodyMap = ({
  closeModal,
  selectedSvgImgPath,
  updatePathSelection,
  setSelectedChoices,
  bodyMapImageLabel,
  isPreview = false,
  svgAttributes = {}
}: any) => {
  const { t } = useTranslation();
  const [blob, setBlob] = useState<any>(null);
  const [showLabelBox, setShowLabelBox] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [selectedId, setSelectedId] = useState('');
  const dispatch = useAppDispatch();

  const toastData = useAppSelector((state) => state.toast);

  // useEffect(() => {
  //   updateSelectedChoicesType(checkValue);
  // }, [checkValue]);

  useEffect(() => {
    const setSelectedLabel: any = {};
    if (selectedSvgImgPath) {
      selectedSvgImgPath.map((option: any) => {
        if (option.label) {
          setSelectedLabel[option.id] = option.label;
        }
      });
    }
    setFormData(setSelectedLabel);
  }, []);

  const setLabelSelection = (e: any) => {
    const selectedBodyPart = find(selectedSvgImgPath, { id: e.target.id });
    if (!isPreview) {
      setShowLabelBox(!selectedBodyPart.isChecked || formData[e.target.id]);
      updatePathSelection(e, formData);
      setSelectedId(e.target.id);
    } else {
      if (selectedBodyPart.label) {
        setSelectedId(e.target.id);
        setShowLabelBox(true);
      }
    }
  };

  useEffect(() => {
    if (selectedSvgImgPath) {
      setBlob(SVGMap(selectedSvgImgPath, setLabelSelection, svgAttributes));
    }
  }, [selectedSvgImgPath]);

  const getSelectedLabels = () => {
    const clonedBodyMapImageLabel = bodyMapImageLabel;
    let selectedLabels: any = [];
    for (let k in clonedBodyMapImageLabel) {
      if (clonedBodyMapImageLabel[k].imageLabel) {
        selectedLabels = [...selectedLabels, ...clonedBodyMapImageLabel[k].imageLabel];
      }
    }
    return selectedLabels;
  };

  const validateDuplicateLabel = (selectedId: any) => {
    const prevSelectedLabels = getSelectedLabels();
    let isDuplicateLabel = false;
    const selectedLabel = formData[selectedId].trim();
    if (prevSelectedLabels.includes(selectedLabel)) {
      isDuplicateLabel = true;
    }

    return isDuplicateLabel;
  };

  const onLabelSelection = (selectedId: any) => {
    if (formData[selectedId] === undefined) {
      setSelectedChoices(formData);
      return false;
    }
    if (!validateDuplicateLabel(selectedId)) {
      if (formData[selectedId]?.trim() === '') {
        delete formData[selectedId];
      }
      setSelectedChoices(formData);
    }
  };

  return (
    <div>
      <div className="mb-5 text-base font-normal">
        {!isPreview ? t('T_CLICK_ADD_DRAG_FOR_SECTION') : t('T_CLICK_PREVIEV_LABEL_SELECTION')}
      </div>
      <div className="w-full rounded-lg bg-[#F5F5F5]  text-center">
        {showLabelBox && (
          <>
            <div className="mt-2 flex w-full rounded-lg bg-[#e6e8eb] p-1">
              <div className="flex w-full" style={{ margin: '5px' }}>
                <div className="w-3/4">
                  <InputField
                    className="w-full"
                    type="text"
                    id="bodyLabel"
                    disableLabel={true}
                    label=""
                    placeholder={t('T_SURVEY_LABEL_SELECTION')}
                    fieldKey={selectedId}
                    required={false}
                    formData={formData}
                    setFormData={setFormData}
                    formError={{}}
                    disabled={false}
                  />
                </div>
                <div className="flex text-right">
                  <div className="text-right">
                    {!isPreview && (
                      <BiButton
                        className={
                          'ml-3  w-[65px] border border-solid border-primary bg-primary text-white'
                        }
                        type="button"
                        disabled={
                          !formData[selectedId] ||
                          formData[selectedId].trim() === '' ||
                          validateDuplicateLabel(selectedId)
                        }
                        onClick={() => {
                          onLabelSelection(selectedId);
                          dispatch(
                            setToastData({
                              toastMessage: t('T_SURVEY_UPDATE_ANSWER_CHOICE'),
                              isToastActive: true,
                              toastType: 'success'
                            })
                          );
                          setShowLabelBox(false);
                        }}
                      >
                        {t('T_BUTTON_ADD')}
                      </BiButton>
                    )}

                    <BiButton
                      className={
                        'ml-3 min-w-[50px] border border-solid border-primary text-primary'
                      }
                      type="button"
                      onClick={() => setShowLabelBox(false)}
                    >
                      {t('T_CANCEL')}
                    </BiButton>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex h-[500px] w-full justify-center overflow-scroll p-[10px]">{blob}</div>
      </div>

      <div className="mt-8 text-right">
        <BiButton
          className={'mr-5 min-w-[100px] border border-solid border-primary text-primary'}
          type="button"
          onClick={() => {
            closeModal();
          }}
        >
          {t('T_TIMES_ICON_ALT_MSG')}
        </BiButton>
      </div>
    </div>
  );
};

export default ConfigureBodyMap;
