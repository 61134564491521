export class TaskTrackerConstants {
  static readonly CREATE_ORGANIZATION_TASKS = [
    {
      step: 1,
      title: 'T_CONTRACTS',
      description: 'T_CREATE_ORGANIZATION_TASKS_CONTRACTS_MSG',
      status: ''
    },
    {
      step: 2,
      title: 'T_TEMPLATES',
      description: 'T_CREATE_ORGANIZATION_TASKS_TEMPLATES_MSG',
      status: ''
    },
    {
      step: 3,
      title: 'T_VALIDATION_CHECK',
      description: 'T_CREATE_ORGANIZATION_TASKS_CHECK_MSG',
      status: ''
    }
  ];
}
