import { useState, useRef } from 'react';
import {
  DropDownListContainer,
  DropDownHeader,
  DropDownList,
  ListItem
} from '../dropdowns/BiDropdownStyles';
import MenuDot from '../../../assets/menu-dot.svg';
import { useOutsideDetect } from '../../../util/customHooks';

export type Options = {
  display: string;
  key: string;
  value?: any;
  description?: any;
};

type Props = {
  options: Array<Options>;
  onSelectedOptionChange: (option: any) => void;
  value: any;
  containerClassName?: string;
  iconSize?: string;
  disabled?: boolean;
};
const DotMenu = ({
  options,
  onSelectedOptionChange,
  value,
  containerClassName,
  iconSize,
  disabled = false
}: Props) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value);
    toggling();
    onSelectedOptionChange(value);
  };

  useOutsideDetect(wrapperRef, () => {
    setIsOpen(false);
  });

  return (
    <div className={'align-center relative px-4 py-2 ' + containerClassName} ref={wrapperRef}>
      <DropDownHeader id="dropdown-dot">
        <img
          src={MenuDot}
          alt="dropdownIconForMenuDot"
          onClick={toggling}
          data-testid="menu-option-icon"
          className={disabled ? 'pointer-events-none cursor-not-allowed' : ''}
        />
      </DropDownHeader>
      {!disabled && isOpen && (
        <DropDownListContainer>
          <DropDownList
            className={`max-h-96 w-max overflow-y-auto overflow-x-hidden`}
            style={iconSize ? { left: '-165px', top: '30px' } : { left: '-108px', top: '30px' }}
          >
            {options.map((currentOption) =>
              typeof currentOption == 'string' ? (
                <ListItem
                  onClick={onOptionClicked(currentOption)}
                  key={Math.random()}
                  type="menuItem"
                  className="hover:bg-gray-200"
                  data-testId="menu-option-list"
                >
                  {currentOption}
                </ListItem>
              ) : (
                <ListItem
                  onClick={onOptionClicked(currentOption)}
                  key={Math.random()}
                  type="menuItem"
                  className="hover:bg-gray-200"
                  data-testId="menu-option-list"
                >
                  {currentOption.description || currentOption.display}
                </ListItem>
              )
            )}
          </DropDownList>
        </DropDownListContainer>
      )}
    </div>
  );
};

export default DotMenu;
