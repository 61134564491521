import BaseAPI from './api';
import env from '../environment';
import { AppConstants } from '../constants/AppConstants';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

export const listRoles = async (
  name: string,
  ignorePermissions: boolean = false,
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: string = AppConstants.DEFAULT_SORT_BY,
  sortType: string = AppConstants.DEFAULT_SORT_DESC
) => {
  const headers = {
    ...globalHeaders,
    apikey: env.apikey
  };
  let uri = `${env.accessControlPath}/roles?page=${page}&size=${size}`;
  if (name) {
    uri += `&name=${name}`;
  }
  if (ignorePermissions) {
    uri += `&${AppConstants.QUERY_PARAM_IGNORE_PERMISSIONS}=${ignorePermissions}`;
  }
  if (sort) {
    uri += `&sort=${sort},${sortType}`;
  }
  const response = await API.get(uri, { headers }).then((res) => res);
  return response;
};

export const createRole = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.accessControlPath}/roles`, body, { headers });
  return response;
};

export const updateRole = async (id: string, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(`${env.accessControlPath}/roles/${id}`, body, {
    headers
  });
  return response;
};

export const getRole = async (roleId: any) => {
  const headers = { ...globalHeaders };
  return await API.get(`${env.accessControlPath}/roles/${roleId}`, { headers });
};

export const deleteRole = async (roleId: any) => {
  const headers = { ...globalHeaders };
  return await API.delete(`${env.accessControlPath}/roles/${roleId}`, { headers });
};

export const getPermissions = async (size: number) => {
  const headers = { ...globalHeaders };
  return await API.get(`${env.accessControlPath}/permissions?size=${5000}`, { headers });
};

export const listFieldGropus = async (
  size: number,
  page: number,
  name: string = '',
  sorters: string = ''
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.accessControlPath}/field-groups?size=${size}&page=${page}${
    name && `&name=${name}`
  }`;
  if (sorters) {
    uri += `${sorters}`;
  }
  const response = await API.get(uri, { headers });
  return response;
};

export const listConsentTemplates = async (
  size: number,
  page: number,
  name: string = '',
  filters: string = '',
  sorters: string = ''
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.accessControlPath}/consent/templates?size=${size}&page=${page}${
    name && `&name=${name}`
  }`;

  if (filters) {
    uri += `&${filters}`;
  }

  if (sorters) {
    uri += `${sorters}`;
  }
  const response = await API.get(uri, { headers });
  return response;
};

export const getConsentAssignByGiverId = async (
  size: number,
  page: number,
  giverId: string = ''
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.accessControlPath}/consent/assignments/givers/${giverId}?size=${size}&page=${page}`;

  const response = await API.get(uri, { headers });
  return response;
};

export const createConsentTemplate = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.accessControlPath}/consent/templates`, body, { headers });
  return response;
};

export const createConsentAssignment = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.accessControlPath}/consent/assignments`, body, {
    headers
  });
  return response;
};

export const updateConsentTemplate = async (id: string, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(`${env.accessControlPath}/consent/templates/${id}`, body, {
    headers
  });
  return response;
};

export const createNewVersiononsentTemplate = async (id: string) => {
  const headers = { ...globalHeaders };
  const response = await API.post(
    `${env.accessControlPath}/consent/templates/${id}/version`,
    {},
    {
      headers
    }
  );
  return response;
};

export const addPermissionsToRole = async (roleId: any, body: any) => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.accessControlPath}/roles/${roleId}/permissions`, body, {
    headers
  });
};

export const deletePermissionsFromRole = async (roleId: any, body: any) => {
  const headers = { ...globalHeaders };
  return await API.delete(`${env.accessControlPath}/roles/${roleId}/permissions`, {
    data: body,
    headers
  });
};



export const addAttachmentsToConsent = async (id: string, body: any) => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.accessControlPath}/consent/templates/${id}/attachments`, body, {
    headers
  });
};

export const removeAttachmentsFromConsent = async (id: string, attachmentId: string) => {
  const headers = { ...globalHeaders };
  return await API.delete(`${env.accessControlPath}/consent/templates/${id}/attachments/${attachmentId}`, {
    headers
  });
};

export const updateAttachmentsOfConsent = async (id: string, attachmentId: string,body:any) => {
  const headers = { ...globalHeaders };
  return await API.put(`${env.accessControlPath}/consent/templates/${id}/attachments/${attachmentId}`,body, {
    headers
  });
};