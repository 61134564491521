import React, { useEffect, useState } from 'react';
import Modal from '../shared/ModalPortal/Modal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { deleteFolder, getFolder } from '../../http/asset-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import Spinner from '../primitives/Spinner/Loader';
import { getFileName } from './BucketUtil';
import { getFailureMessage } from '../../util/ErrorUtil';
import { setToastData } from '../../app/toastReducer';
import { AppConstants } from '../../constants/AppConstants';

interface DeleteFileFolderProps {
  id: string;
  setId: Function;
  type: 'File' | 'Folder';
  fetchList?: Function;
  deleteFile?: Function;
}

const DeleteFileFolder = ({ id, setId, type, fetchList, deleteFile }: DeleteFileFolderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [hardDeletePermission, setHardDeletePermission] = useState(false);

  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const removeFolder = async (hard: boolean = false) => {
    const { name, id } = data;
    setIsLoading(true);
    let success: boolean = false;
    let toastMessage: string = t('T_FOLDER_DELETE_SUCCESS_MSG').replace('%s', getFileName(name));
    let response: any = await deleteFolder(id, hard);
    success = HTTP_STATUS.isSuccess(response?.status);
    if (success) {
      setId('');
      setIsLoading(false);
      if (fetchList) fetchList();
    } else {
      toastMessage = getFailureMessage(response);
    }
    dispatch(
      setToastData({
        isToastActive: true,
        toastMessage,
        toastType: success ? 'success' : 'error'
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    async function init() {
      const response = await getFolder(id);
      const { data: folderData, status } = response;
      if (HTTP_STATUS.isSuccess(status)) {
        setData(folderData);
        if (
          (folderData?.folderOwners.length || folderData?.folderPermissions.length) &&
          roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_FOLDER_HARD]
        ) {
          let flag =
            folderData?.folderOwners.some((user: any) => user?.userId === userProfile?.userId) ||
            folderData?.folderPermissions.some(
              (user: any) =>
                user?.userId === userProfile?.userId && user.hardDeletePermission === true
            );
          setHardDeletePermission(flag);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setId('');
      }
    }
    if (id && type === 'Folder') {
      init();
    } else if (type == 'File' && id.length) {
      setIsLoading(false);
      setHardDeletePermission(roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_FILE_HARD]);
    }
  }, [id]);
  return (
    <Modal
      isVisible={!!id}
      headerText={t('T_DELETE_CONFIRMATION_TITLE')}
      showCloseIcon={true}
      toggleVisiblity={setId}
      width="65rem"
      body={
        isLoading ? (
          <div className="flex items-center justify-center py-4">
            <Spinner height="40px" width="40px"></Spinner>
          </div>
        ) : (
          <div>
            <h2 className="mb-4 text-lg font-semibold">{t('T_FILE_FOLDER_DELETE_HEADER')}</h2>
            {hardDeletePermission ? (
              <div className="mb-4">
                <p className="text-gray-600">{t('T_HARD_DELETE_DESCRIPTION')}</p>
              </div>
            ) : (
              ''
            )}
            <div>
              <p className="text-gray-600">{t('T_SOFT_DELETE_DESCRIPTION')}</p>
            </div>
          </div>
        )
      }
      primaryButtonText={
        isLoading
          ? ''
          : hardDeletePermission
          ? t('T_HARD_DELETE_FOLDER')
          : t('T_SOFT_DELETE_FOLDER')
      }
      primaryButtonWidth={'10rem'}
      primaryOnClick={() => {
        if (type === 'File') {
          if (deleteFile) deleteFile(id, true);
        } else {
          if (hardDeletePermission) {
            removeFolder(true);
          } else {
            removeFolder(false);
          }
        }
      }}
      primaryButtonDisabled={false}
      secondaryOnClick={() => {
        if (type === 'File') {
          if (deleteFile) deleteFile(id);
        } else {
          if (hardDeletePermission) {
            removeFolder(false);
          } else {
            setId('');
          }
        }
      }}
      secondaryButtonText={
        isLoading ? '' : hardDeletePermission ? t('T_SOFT_DELETE_FOLDER') : t('T_CANCEL')
      }
      secondaryButtonWidth={'10rem'}
      secondaryButtonDisabled={false}
      tertiaryButtonText={isLoading ? '' : t('T_CANCEL')}
      tertiaryOnClick={() => setId('')}
      twoButton={!hardDeletePermission}
      buttonLayout="123"
    ></Modal>
  );
};
export default React.memo(DeleteFileFolder);
