export class FileTypeConstant {
  static readonly FILE_TYPES = [
    { value: 'pdf', type: 'application/pdf', apiType: 'APPLICATION_PDF' },
    {
      value: 'docx',
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      apiType: 'APPLICATION_DOCX'
    },
    {
      value: 'doc',
      type: 'application/msword',
      apiType: 'APPLICATION_DOC'
    },
    { value: 'png', type: 'image/png', apiType: 'IMAGE_PNG' },
    { value: 'jpg', type: 'image/jpeg', apiType: 'IMAGE_JPEG' },
    { value: 'json', type: 'application/json', apiType: 'APPLICATION_JSON' },
    { value: 'txt', type: 'text/plain', apiType: 'TEXT_PLAIN' },
    { value: 'xml', type: 'text/xml', apiType: 'TEXT_XML' },
    {
      value: 'ppt',
      type: 'application/vnd.ms-powerpoint',
      apiType: 'APPLICATION_OCTET_STREAM'
    },
    {
      value: 'pptx',
      type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      apiType: 'APPLICATION_OCTET_STREAM'
    },
    {
      value: 'xls',
      type: 'application/vnd.ms-excel',
      apiType: 'APPLICATION_OCTET_STREAM'
    },
    {
      value: 'xlsx',
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      apiType: 'APPLICATION_OCTET_STREAM'
    }
  ];

  static readonly WILDCARD_OPTION="WILDCARD"

  static readonly FILE_TYPES_OPTION = [
    'APPLICATION_ATOM_XML',
    'APPLICATION_FORM_URLENCODED',
    'APPLICATION_JSON',
    'APPLICATION_OCTET_STREAM',
    'APPLICATION_SOAP_XML',
    'APPLICATION_SVG_XML',
    'APPLICATION_XHTML_XML',
    'APPLICATION_XML',
    'APPLICATION_DOC',
    'APPLICATION_DOCX',
    'APPLICATION_PDF',
    'IMAGE_BMP',
    'IMAGE_GIF',
    'IMAGE_JPEG',
    'IMAGE_PNG',
    'IMAGE_SVG',
    'IMAGE_TIFF',
    'IMAGE_WEBP',
    'MULTIPART_FORM_DATA',
    'TEXT_HTML',
    'TEXT_PLAIN',
    'TEXT_XML',
    'WILDCARD'
  ];
}
