import BaseAPI from './api';
import env from '../environment';
import { AppConstants } from '../constants/AppConstants';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

export const getApplications = async (
  name: string,
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort?: string,
  sortType: string = 'desc'
) => {
  const headers = { ...globalHeaders };
  let query = `?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;
  if (name) {
    query += `&name=${name}`;
  }
  if (sort) {
    query += `&sort=${sort},${sortType}`;
  }
  let uri = `${env.metadataPath}/applications${query}`;
  const response = await API.get(uri, { headers });
  return response;
};

export const createApplication = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(
    `${env.metadataPath}/applications`,
    { ...body },
    {
      headers
    }
  );
  return response;
};

export const getApplication = async (applicationId: any) => {
  const headers = { ...globalHeaders };
  const response = await API.get(`${env.metadataPath}/applications/${applicationId}`);
  return response;
};

export const deleteApplication = async (applicationId: any) => {
  return await API.delete(`${env.metadataPath}/applications/${applicationId}`);
};

export const updateApplication = async (applicationId: string, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(
    `${env.metadataPath}/applications/${applicationId}`,
    { ...body },
    {
      headers
    }
  );
  return response;
};

export const getMetadataObjects = async (
  id: string,
  objectNames: Array<string> = [],
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: Array<string> = [`${AppConstants.DEFAULT_SORT_BY},${AppConstants.DEFAULT_SORT_DESC}`]
) => {
  const headers = { ...globalHeaders };
  let query = `?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;
  if (objectNames.length) {
    query += '&' + `${AppConstants.QUERY_PARAM_OBJECT_NAMES}=${objectNames.join()}`;
  }
  if (sort.length) {
    query += '&' + sort.map((item: string) => `${AppConstants.QUERY_PARAM_SORT}=${item}`).join('&');
  }
  let uri = `${env.metadataPath}/applications/${id}/objects${query}`;
  const response = await API.get(`${uri}`, { headers });
  return response;
};

export const getMetadataObject = async (id: string, objectId: string): Promise<any> => {
  const headers = { ...globalHeaders };
  let uri: string = `${env.metadataPath}/applications/${id}/objects/${objectId}`;
  return await API.get(`${uri}`, { headers });
};

export const createMetadataObject = async (id: string, payload: any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${env.metadataPath}/applications/${id}/objects`;
  return await API.post(uri, payload, { headers });
};

export const updateMetadataObject = async (id: string, objectId: string, payload: any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${env.metadataPath}/applications/${id}/objects/${objectId}`;
  return await API.put(uri, payload, { headers });
};

export const patchMetadataObject = async (id: string, objectId: string, payload: any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${env.metadataPath}/applications/${id}/objects/${objectId}`;
  return await API.patch(uri, payload, { headers });
};

export const deleteMetadataObject = async (appId: string, objId: string) => {
  return await API.delete(`${env.metadataPath}/applications/${appId}/objects/${objId}`);
};

export const getMetadataInstances = async (
  id: string,
  wildcard: Array<string> = [],
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: Array<string> = [`${AppConstants.DEFAULT_SORT_BY},${AppConstants.DEFAULT_SORT_DESC}`]
): Promise<any> => {
  const headers = { ...globalHeaders };
  let query = `?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;
  if (wildcard.length && wildcard.every(item => item !== "")) {
    query += '&' + `${AppConstants.QUERY_PARAM_WILDCARD}=${wildcard.join()}`;
  }
  if (sort.length) {
    query += '&' + sort.map((item: string) => `${AppConstants.QUERY_PARAM_SORT}=${item}`).join('&');
  }
  let uri = `${env.metadataPath}/objects/${id}/instances${query}`;
  return await API.get(uri, { headers });
};

export const createMetadataInstances = async (id: any, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.metadataPath}/objects/${id}/instances`, body, { headers });
  return response;
};

export const getMetadataInstance = async (objectId: any, id: any) => {
  const headers = { ...globalHeaders };
  const response = await API.get(`${env.metadataPath}/objects/${objectId}/instances/${id}`, {
    headers
  });
  return response;
};

export const updateMetadataInstance = async (objectId: any, id: any, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(
    `${env.metadataPath}/objects/${objectId}/instances/${id}`,
    { ...body },
    {
      headers
    }
  );
  return response;
};
