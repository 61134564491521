import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { Email } from '../Auth/AuthStyles';
import { Button } from '../Reusable/Shared';
import Exclamation from '../../assets/exclamation.svg';
import { useAppDispatch } from '../../app/hooks';
import { registerEmail } from '../../app/userManagementReducer';
import {useTheme} from "../../context/themeContext";
import {useTranslation} from "react-i18next";

const InitalRegister = ({ position, setPosition, paramEmail }: any) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useAppDispatch();
  const handleRegisterEmail = async (values: any) => {
    dispatch(registerEmail(values));
  };
  const {t} = useTranslation();

  useEffect(() => {
    if (paramEmail) {
      handleChange(paramEmail);
    }
  }, []);

  const handleChange = (value: any) => {
    setEmail(value);
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(value.toLowerCase())) {
      setIsDisabled(true);
      setEmailError(`${t('T_INVALID_EMAIL')}`);
    } else {
      setEmailError('');
      setIsDisabled(false);
    }
  };

  return (
    <>
      {/* Middle content of card - Description + Input */}
      <div className={`${position === 0 ? 'visible' : 'invisible '}`}>
        <div className="bg-dashboard flex h-[451px] flex-col justify-start bg-[#f5f7fa] pl-[50px] pt-[25px] pb-[25px] ">
          <h1 className="text-2xl font-light" style={{color: useTheme().theme.primaryAppColor}}>Enter your email</h1>
          <p className="mb-[50px] mt-[20px] mb-[25px] max-w-[537px] text-base font-light">
            {t('T_WELCOME_TO_ADMIN_MSG')}
          </p>

          <label htmlFor="register-email">{t('T_EMAIL_ADDRESS')}</label>
          <Email
            id="register-email"
            name="email"
            autoFocus
            value={email}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            type="email"
            className="h-8 h-[44px] w-[320px] placeholder:text-sm"
            disabled={paramEmail}
          />
          {emailError ? (
            <>
              <ErrorLabel>
                <Img src={Exclamation} />
                {emailError}
              </ErrorLabel>
            </>
          ) : null}
          <span className="text-base text-sm font-normal text-[#7D7D7D]">
            {t('T_USER_NAME_HINT_MSG')}
          </span>
        </div>
        {/* Bottom of card - Next button */}
        <div className="flex h-[102px] h-[76px] items-center justify-end  pr-[60px]">
          <Button
            color="primary"
            width="20rem"
            onClick={() => {
              handleRegisterEmail(email);
              setPosition((state: any) => state - 100);
            }}
            disabled={isDisabled}
            theme={useTheme().theme}
          >
            {t('T_NEXT')}
          </Button>
        </div>
      </div>
    </>
  );
};

const ErrorLabel = styled.span`
  font-size: 13px !important;
  color: red;
`;

const Img = styled.img`
  height: 17px;
  width: 17px;
  display: inline;
`;

export default InitalRegister;
