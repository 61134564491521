import TaskCompletedIcon from '../../../assets/task-complete.svg';
import TaskInCompletedIcon from '../../../assets/task-incomplete.svg';
import TaskFailureIcon from '../../../assets/red-warning.svg';
import Modal from '../ModalPortal/Modal';
import './TaskTracker.css';
import { AppConstants } from '../../../constants/AppConstants';
import { Button } from '../../AccountProfile/ProfileStyles';
import { useTranslation } from "react-i18next";

interface TaskTrackerProps {
  title: string;
  active: boolean;
  toggleVisiblity: Function;
  taskList: any[];
}

interface TaskItemProps {
  title: string;
  description: string;
  status: string;
}

const Spinner = () => {
  return (
    <div className="border-3 spinner-border inline-block h-[25px] w-[25px] animate-spin rounded-full text-[#8C8C8C]" />
  );
};

const TaskItem: React.FC<TaskItemProps> = ({ title, description, status }) => {
  const { t } = useTranslation();
  return (
    <section className="taskItem">
      <div className={`flex-1 ${status === 'completed' && 'text-[#00BB98]'}`}>
        <div className="font-semibold leading-6">{t(title)}</div>
        <div className="text-[14px] font-normal leading-6">{t(description)}</div>
      </div>

      <div className="pr-[10px] leading-[48px]">
        {status === AppConstants.TASK_TRACKER_STATUS_COMPLETE && (
          <img src={TaskCompletedIcon} alt={t('T_TASK_TRACKER_ALT_MSG') || ''} className="mt-[12px]" />
        )}
        {status === AppConstants.TASK_TRACKER_STATUS_FAILED && (
          <img src={TaskFailureIcon} alt={t('T_TASK_FAILURE_ALT_MSG') || ''} className="mt-[12px]" />
        )}
        {status === AppConstants.TASK_TRACKER_STATUS_INPROGRESS && <Spinner />}
        {status === '' && (
          <img src={TaskInCompletedIcon} alt={t('T_TASK_INCOMPLETE_ALT_MSG') || ''} className="mt-[12px]" />
        )}
      </div>
    </section>
  );
};

const TaskTracker: React.FC<TaskTrackerProps> = ({ title, active, toggleVisiblity, taskList }) => {
  const validateAllTaskCompleted = () => {
    let flag = true;
    taskList.forEach((task: any) => {
      if (task.status === AppConstants.TASK_TRACKER_STATUS_INPROGRESS || task.status === '') {
        flag = false;
      }
    });

    return flag;
  };
  const { t } = useTranslation();
  return (
    <>
      <Modal
        showCloseIcon={false}
        width="48rem"
        headerText={title}
        toggleVisiblity={toggleVisiblity}
        isVisible={active}
        body={
          <>
            <div>
              {taskList.map((task: any, index: number) => {
                return <TaskItem key={index} {...task} />;
              })}
            </div>

            <div className="mt-[30px] text-right">
              <Button
                disabled={!validateAllTaskCompleted()}
                className={`px-4 ${!validateAllTaskCompleted() ? 'cursor-not-allowed' : ''}`}
                style={{ width: '100px' }}
                onClick={() => toggleVisiblity()}
              >
                {t('T_DONE')}
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};

export default TaskTracker;
