import { SELECT_OPTIONS_ICON } from "../components/Survey/Question/QueUtils";
import {isEmpty, cloneDeepWith, noop} from 'lodash';
import { AppConstants } from '../constants/AppConstants';


export const API_TO_QUESTION_MAPPING: any = {
  choice: 'multi',
  multi: 'multi',
  display: 'display',
  boolean: 'boolean',
  decimal: 'decimal',
  integer: 'integer',
  date: 'date',
  time: 'time',
  dateTime: 'datetime',
  datetime: 'datetime',
  url: 'url',
  quantity: 'numeric',
  text: 'text',
  media: 'media'
};


export const getDisplayType = (item: any) => {
  const { attributes = {} } = item;
  const { extension, apiType, type } = attributes;
  let dispType = apiType;
  const extendedObj = extension && extension.object ? JSON.parse(extension.object) : {};
  if (extendedObj && !isEmpty(extendedObj)) {
    const { displayType = '' } = extendedObj;
    dispType = displayType;
  }

  if(apiType === 'choice' && type === 'bodymap') {
    dispType = 'bodymap';
  }
  return dispType;
};

export const getQuestionTypeLabel = (apiType: any = '', displayType: any, questionTypes: any, type: any) => {
  let label = questionTypes[apiType.toLowerCase()]?.label || '';

  // if (apiType === 'text' && displayType === 'single') {
  //   label = questionTypes['single']?.label
  // }

  if(apiType === 'choice' || apiType === 'url') {
    switch(displayType){
      case 'likertIconsWithLabels':
      case 'likertIcons':
      case 'likert':
        label = questionTypes['likert']?.label;
      break;
      default:
        label = displayType ? questionTypes[displayType]?.label : label;
    }
  } 

  if(apiType === 'choice' && (type === 'bodymap' || displayType === 'bodyMapSingle' || displayType === 'bodyMapMulti' ||  displayType === 'bodymap')){
    label = questionTypes['bodymap']?.label;
  }
  
  return label;
  
};

export const getQuestionTypeIcon = (apiType: any = '', displayType: any, questionTypes: any, type: any) => {
  let icon = SELECT_OPTIONS_ICON[apiType.toLowerCase()];

  if(apiType === 'choice' || apiType === 'url') {
    switch(displayType){
      case 'likertIconsWithLabels':
      case 'likertIcons':
      case 'likert':
        icon = SELECT_OPTIONS_ICON['likert']
      break;
      default:
        icon = SELECT_OPTIONS_ICON[displayType];
    }
  } 

  if(apiType === 'choice' && (type === 'bodymap' || displayType === 'bodyMapSingle' || displayType === 'bodyMapMulti')){
    icon = SELECT_OPTIONS_ICON['bodymap'];
  }
  
  return icon;
  
};

export const getUpdatedQueArr = (modifiedQuestion: any, id: any, updatedArr: any) => {
  for (let i = 0; i < updatedArr.length; i++) {
    if (updatedArr[i].id === id) {
      updatedArr[i] = { ...modifiedQuestion };
    }
    if (updatedArr[i]?.item?.length) {
      const result = cloneDeepWith([...updatedArr[i].item], (value) => {
        return value && value.id === id ? { ...value, ...modifiedQuestion } : noop();
      });
      if (result) {
        updatedArr[i].item = [...result];
      }
    }
  }
  return updatedArr;
};

export const getInitialQueObj = (id:any,updatedArr: any) => {
  let updatedQue = '';
  for (let i = 0; i < updatedArr.length; i++) {
    if (updatedArr[i].id === id) {
      updatedQue = updatedArr[i]
    }
    if (updatedArr[i]?.item?.length) {
      const result = cloneDeepWith([...updatedArr[i].item], (value) => {
        return value && value.id === id ? { ...value } : noop();
      });
      if (result) {
        updatedQue = result;
      }
    }
  }
  return updatedQue;
}

export const updateOrgRoleResponse = (obj: any, orgResponse: any, loadNextData:any, setLoadNextData: any) => {
  if (orgResponse) {
    let sortedOrgList;
    const { data: orgList, paging: pagingResponse } = orgResponse;
    let { paging: nextpagingObj } = obj;
    const { size, page } = pagingResponse;
    if (pagingResponse.next) {
      nextpagingObj = { page: page + 1, size };
    }
    setLoadNextData({ ...loadNextData, orgPaging: false, searchOrg: false });

    sortedOrgList = [...obj.itemsList, ...orgList].sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    return {
      ...obj,
      itemsList: sortedOrgList,
      paging: nextpagingObj,
      hasMore: pagingResponse.next !== null
    };
  }
};


export const updateOrgResponse = (obj: any, orgResponse: any, loadNextData:any, setLoadNextData: any) => {
  if (orgResponse) {
    let sortedOrgList;
    const { data: orgList, paging: pagingResponse } = orgResponse;
    let { paging: nextpagingObj } = obj;
    const { size, page } = pagingResponse;
    if (pagingResponse.next) {
      nextpagingObj = { page: page + 1, size };
    }
    setLoadNextData({ ...loadNextData, orgPaging: false, searchOrg: false });

    sortedOrgList = [...obj.itemsList, ...orgList].sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    return {
      ...obj,
      itemsList: sortedOrgList,
      paging: nextpagingObj,
      hasMore: pagingResponse.next !== null
    };
  }
};

export const updateRoleResponse = (obj: any, orgResponse: any, loadNextData:any, setLoadNextData: any) => {
  if (orgResponse) {
    let sortedOrgList;
    const { data: orgList, paging: pagingResponse } = orgResponse;
    let { paging: nextpagingObj } = obj;
    const { size, page } = pagingResponse;
    if (pagingResponse.next) {
      nextpagingObj = { page: page + 1, size };
    }
    setLoadNextData({ ...loadNextData, rolePaging: false, searchRole: false });

    sortedOrgList = [...obj.itemsList, ...orgList].sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    return {
      ...obj,
      itemsList: sortedOrgList,
      paging: nextpagingObj,
      hasMore: pagingResponse.next !== null
    };
  }
};

export const surveyFilters = [
  {
    id: 'orgFilter',
    label: 'T_FILTER_KEY_ORGANIZATIONS',
    component: 'picklist',
    type: 'single',
    itemsList: [],
    selectedFilter: [],
    fieldKey: 'name',
    fieldValues: 'name',
    disabled: false,
    hasMore: true,
    paging: { size: 25, page: 1 },
    isDependentFilter: true,
    dependentChildId: 'categoriesFilter',
    searchable: true,
    onlySearch: true
  },
  {
    id: 'statusFilter',
    label: 'T_SURVEY_STATUS_FILTER',
    component: 'picklist',
    type: 'single',
    itemsList: AppConstants.SURVEY_STATUS_TYPES,
    selectedFilter: [],
    fieldKey: 'key',
    fieldValues: 'display',
    disabled: false
  }
];

export const setEmptyItemsList = (filterType: any, filterList: any, setFilterList: any) => {
  let initialFilterList: any = [...filterList];
  const currentFilterList = initialFilterList.map((obj: any) => {
    if (obj.id === filterType) {
      return {
        ...obj,
        itemsList: [],
        paging: { page: 1, size: 25 },
        hasMore: true
      };
    } else {
      return { ...obj };
    }
  });
  setFilterList(currentFilterList);
};
