import styled from 'styled-components/macro';
import DropdownSvg from '../../../assets/dropdown.svg';
import { useRef, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';

interface SelectFieldProps {
  fieldKey: string;
  data: any;
  setData: Function;
  options?: any;
  disabled?: boolean;
}

function DropDownField({ fieldKey, data, setData, options, disabled }: SelectFieldProps) {
  const selectRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();

  const toggling = () => {
    setShowPopup(!showPopup);
  };

  useOutsideClick(
    selectRef,
    () => {
      if (showPopup) {
        setShowPopup(false);
      }
    },
    false
  );
  const onOptionClicked = (value: any) => () => {
    setShowPopup(false);
    setData(value);
  };

  return (
    <>
      <DropDownContainer className={disabled ? 'disabled' : ''}>
        <DropDownHeader onClick={toggling} data-testid="select-field-header">
          {data[fieldKey] || t('T_SELECT')}
          <img src={DropdownSvg} />
        </DropDownHeader>
        {showPopup && (
          <DropDownListContainer ref={selectRef}>
            <DropDownList data-testid="select-option-list">
              <ListItem onClick={onOptionClicked('')}>{t('T_SELECT')}</ListItem>

              {options.map((option: any) => (
                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                  {option[fieldKey]}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </>
  );
}

const DropDownContainer = styled('div')`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  width: 100%;
  height: 44px;
  background: #ffffff;

  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    background-color: #f5f5f5;
  }
`;

const DropDownHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DropDownListContainer = styled('div')``;

const DropDownList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0px;
  position: absolute;
  width: 100%;
  max-height: 280px;
  z-index: 1;
  left: 0;
  top: 45px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  overflow-y: auto;
`;

const ListItem = styled('li')`
  list-style: none;
  width: 77px;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin: 9px 10px;
  cursor: pointer;
  width: 100%;
`;

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

export default DropDownField;
