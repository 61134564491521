import {
  CurrentResultsWrapper,
  PaginatorWrapper,
  RowsPerPageWrapper,
  PagingText
} from './PaginatorStyles';
import BiDropdown from '../../primitives/dropdowns/BiDropdown.primitive';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { convertArrToString } from '../../../util/table-utils';
import { useAppSelector } from '../../../app/hooks';

type Props = {
  onSizeChanged: any;
  size: string;
  currentPage: string;
  setCurrentPage: any;
  pagingData?: any;
  refreshPaginatedData?: any;
  containerClass?: string;
  dataLength?: any;
  theme?: any;
  disablePageSize?: boolean;
  totalDataLength?: number;
};
const LocalPaginator = ({
  onSizeChanged,
  size,
  currentPage,
  setCurrentPage,
  pagingData,
  refreshPaginatedData,
  containerClass,
  dataLength,
  theme,
  disablePageSize = false,
  totalDataLength = 0
}: Props) => {
  const options: Array<string> = ['5', '10', '20'];

  const configurationPages: any =
    useAppSelector(
      (state: any) => state.configuration?.solutionConfigurationDefaults?.dataGrid?.pages
    ) || [];

  const sizeChanged = (newSize: string) => {
    setCurrentPage('1');
    onSizeChanged(newSize);
  };
  const { t } = useTranslation();
  let paginationEndNumber = parseInt(size) * (parseInt(currentPage) - 1) + parseInt(size);
  let paginationStartNumber = parseInt(size) * (parseInt(currentPage) - 1) + 1;

  return (
    <StyledPaginatorWrapper>
      {!disablePageSize ? <RowsPerPageWrapper> Rows per page</RowsPerPageWrapper> : ''}
      {!disablePageSize ? (
        <BiDropdown
          options={configurationPages.length ? convertArrToString(configurationPages) : options}
          onSelectedOptionChange={sizeChanged}
          value={size}
          containerClassName={containerClass}
        />
      ) : (
        ''
      )}

      <CurrentResultsWrapper>
        {pagingData?.size
          ? `${t('T_SHOWING')} ${paginationStartNumber} -
          ${
            paginationEndNumber > dataLength && currentPage === '1'
              ? dataLength
              : paginationEndNumber < pagingData?.size
              ? paginationEndNumber > dataLength
                ? parseInt(size) * (parseInt(currentPage) - 1) + dataLength
                : paginationEndNumber
              : pagingData?.size
          }`
          : ''}
      </CurrentResultsWrapper>
      <RowsPerPageWrapper>
        {![1, '1'].includes(currentPage) && (
          <PagingText
            onClick={() => {
              setCurrentPage((parseInt(currentPage) - 1).toString());
            }}
            theme={theme}
          >
            {t('T_PREVIOUS')}
          </PagingText>
        )}
        {pagingData?.previous && pagingData?.next && pagingData?.size === parseInt(size) && '-'}
        {totalDataLength > parseInt(size) * parseInt(currentPage) &&
          dataLength === parseInt(size) && (
            <PagingText
              onClick={() => {
                setCurrentPage(parseInt(currentPage) + 1);
              }}
              theme={theme}
            >
              {t('T_NEXT')}
            </PagingText>
          )}
      </RowsPerPageWrapper>
    </StyledPaginatorWrapper>
  );
};

const StyledPaginatorWrapper = styled(PaginatorWrapper)`
  .mt-4 {
    margin-top: 0 !important;
  }
`;
export default LocalPaginator;
