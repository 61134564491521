import { useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';

import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { setURLPaths } from '../../app/organizationReducer';
import { RouteConstants } from '../../constants/RouteConstants';
import { validateLength } from '../../util/validators';
import Modal from '../shared/ModalPortal/Modal';
import { getFailureMessage } from '../../util/ErrorUtil';
import { Label } from '../AccountProfile/ProfileStyles';
import Asterix from '../../assets/asterix.svg';

import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';

import Card from '../../components/shared/cards/Card';
import InputField from '../shared/Fields/InputField';
import PillsButton from '../shared/Fields/PillsButton';
import TextAreaField from '../shared/Fields/TextAreaField';
import RadioButton from '../shared/Fields/RadioButton';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import JSONEditor from '../AppSettings/JSONEditor';

import { AppConstants } from '../../constants/AppConstants';
import { getLoggedInOrg, getUserId } from '../../util/admin-utils';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import {
  getSettingById,
  getCategories,
  createSystemSetting,
  updateSystemSetting,
  overRideSystemSetting,
  overRideUserSystemSetting
} from '../../http/configuration-services';

import { setSettingKeyDetails } from '../../app/configurationReducer';

import { SettingIcon } from '../../assets/components/svgs';
import { filter, isEmpty, lowerCase, map, pick, pullAllBy, uniq, toLower, isEqual } from 'lodash';
import {
  DocumentAddIcon,
  DocumentDuplicateIcon,
  DocumentSearchIcon,
  DocumentTextIcon
} from '@heroicons/react/outline';
import TooltipButton from '../Reusable/TooltipButton';

interface configKeyData {
  allowOverride: Boolean;
  allowedValues: Array<string>;
  categories: Array<any>;
  categoriesList: Array<string>;
  dataType: string;
  description: string;
  id: string;
  key: string;
  value: string;
}

interface configKeyValidateData {
  key: string;
  value: string;
  dataType: string;
  description: string;
}

const DEFAULT_CONFIG_KEY_OBJ = {
  id: '',
  key: '',
  value: '',
  description: '',
  dataType: '',
  categoriesList: [],
  categories: [],
  allowedValues: [],
  allowOverride: true
};

/**
 * @parentId configuration service
 * @manager Create, View, Edit - Configuration Key
 * @overview Create, View, Edit of Configuration Key
 *   <section>
 *
 *      <p>
 *           <b>Create Configuration Key</b><br/>
 *           Clicking on <b>Create System Setting</b> from the drop down menu on Configuration Keys listing page will take user to New Configuration Key creation page. Clicking on <b>Cancel</b> will take the user to Configuration Keys listing page.
 *           <br/><br/>
 *           User can create new Configuration Key by entering <b>Key Name</b>, <b>Data Type</b>, <b>Value</b> as mandatory fields and clicking on <b> Save </b> button on the admin portal toolbar.
 *           <b>Allow Override</b>, <b>Categories</b>, <b>Allowed Values</b> & <b>Description</b> are additional non mandatory fields which user can use to enter more detail about the Configuration Key. Description is an freetext field.
 *           <br/><br/>
 *           The <b>Data Type</b> can be one of the following picked from drop down 'String', 'Integer', 'Float', 'Boolean', 'DateTime', 'Date', 'Json'.
 *           Based on the <b>Data Type</b> selection the type of the <b>Value</b> field changes, i.e if 'Date' is selected the <b>Value</b> field has datepicker, if Json is selcted the value is coverted to JSON editor and so on.
 *       </p>
 *       <p>
 *           <b>View Configuration Key</b><br/>
 *           Clicking on configuration key row on the configuration key listing page will take user to View Configuration Key in READ-ONLY mode. Clicking on <b>Back to Configuration Keys</b> will take the user to Configuration Keys listing page.
 *           <br/><br/>
 *        </p>
 *       <p>
 *           <b>Edit Configuration Key</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Categories, Allowed values, Description & Value</b> fields. Clicking on <b>Cancel</b> will take the user to Configuration Key details in READ-ONLY mode.
 *           <br/><br/>
 *           Clicking on <b>Save</b> a confirmation dialog will be displayed if the Configuration Key has to be saved at Global level or Organization level or User level.
 *        </p>
 *
 *       <p>
 *          <h4>View/Create/Edit Configuration Key Fields</h4>
 *          Create/View/Edit configuration key page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <td>Key Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Data Type</td>
 *               <td>Yes</td>
 *               <td>PickList</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Allow Override</td>
 *               <td>No</td>
 *               <td>Boolean</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Categories</td>
 *               <td>No</td>
 *               <td>PickList</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Description</td>
 *               <td>No</td>
 *               <td>String</td>
 *               <td>4096</td>
 *            </tr>
 *            <tr>
 *               <td>Value</td>
 *               <td>Yes</td>
 *               <td>JSON(Javascript Object Notation)</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> If Allow Override is set to <b>TRUE</b> you will not be able to override the Configuration Key(System Setting) at Organization level.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Metadata Application Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>CS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>CS_PARAM_DUPLICATE</td>
 *               <td>Duplicate name, <b>Key Name</b> for resource type.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>CS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create metadata application page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit metadata application.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Configuration Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Configuration Key</td>
 *               <td>/system-settings/{id}</td>
 *               <td>GET</td>
 *               <td>configuration-service.setting.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Configuration Key</td>
 *               <td>/system-settings/{id}</td>
 *               <td>PUT</td>
 *               <td>configuration-service.setting.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Configuration Key</td>
 *               <td>/system-settings</td>
 *               <td>POST</td>
 *               <td>configuration-service.setting.create</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create metadata application</p>
 *   </section>
 */

const ConfigKeyDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = useParams();
  const [configKeyObj, setConfigKeyObj] = useState<any>(DEFAULT_CONFIG_KEY_OBJ);
  const [systemCategories, setSystemCategories] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [dataTypeSelected, setDataTypeSelected] = useState<any>([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [assignToLevel, setAssignToLevel] = useState<any>(`${t('T_SYSTEM_LEVEL')}`);
  const [hasMore, sethasMore] = useState(true);
  const [paging, setPaging] = useState({ size: 25, page: 1 });
  const [flowType, setFlowType] = useState<any>({
    isCreate: location.pathname.split('/').at(-1) === 'create',
    isEdit: queryParams.sysId ? true : false,
    isReadOnly: queryParams.sysId ? true : false
  });
  const orgSysSetting = useAppSelector((state) => state.sysSetting.orgSysSetting);
  const userSysSetting = useAppSelector((state) => state.sysSetting.userSysSetting);
  const userProfileInfo: any = useAppSelector((state) => state.userManagement.userProfileInfo);
  const settingKeyDetails: any = useAppSelector((state) => state.configuration.settingKeyDetails);

  const [jsonString, setJsonString] = useState('');
  const [startDate, setStartDate] = useState<any>(new Date());
  const [startDateTime, setStartDateTime] = useState<any>(new Date());

  const [formError, setFormError] = useState<any>({
    key: '',
    value: '',
    dataType: '',
    description: ''
  });

  useEffect(() => {
    setConfigKeyObj({ ...configKeyObj, value: jsonString });
  }, [jsonString]);

  const getIsEditOrReadOnly = () => {
    const { isEdit, isReadOnly } = flowType;
    if (isEdit && isReadOnly) {
      return true;
    } else if (isEdit && !isReadOnly) {
      return false;
    }
    return false;
  };

  const setisReadOnly = (isReadOnly: boolean) => {
    //console.log(settingKeyDetails);
    if (isReadOnly) {
      setConfigKeyObj(settingKeyDetails);
    }
    setFlowType({ ...flowType, isEdit: queryParams.sysId && isReadOnly, isReadOnly: isReadOnly });
  };

  const validateSave = () => {
    const { key, value, dataType } = configKeyObj;
    return key.trim() === '' || value.toString().trim() === '' || dataType.trim() === '';
  };

  useEffect(() => {
    if (flowType.isEdit) {
      getConfigKeyDetail();
    }
    getConfigCategories();
    dispatch(
      setURLPaths([
        { key: RouteConstants.ROUTE_CONFIG_KEY_CREATE, label: t('T_CREATE_CONFIGURATION_KEY') }
      ])
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(configKeyObj) && configKeyObj.categories && configKeyObj.categories.length) {
      updateConfigCategories();
    }
  }, [configKeyObj.categoriesList]);

  const updateConfigCategories = () => {
    if (configKeyObj.categories.length) {
      setSelectedItems(configKeyObj.categoriesList);
    }
  };

  const getConfigCategories = async () => {
    const { size, page } = paging;
    let configCategoriesResponse = await getCategories('', page, size, [`name,asc`]);

    if (configCategoriesResponse && configCategoriesResponse.status === HTTP_STATUS.HTTP_OK) {
      const { data, paging } = configCategoriesResponse.data;
      setSystemCategories([...systemCategories, ...data]);
      if (paging.next) {
        setPaging({ page: page + 1, size });
      }
      sethasMore(paging.next !== null);
      setIsLoading(false);
    }
  };

  const fetchMoreData = async () => {
    getConfigCategories();
  };

  const displayAllowedValues = (dataType: any, allowedValues: any) => {
    if (allowedValues && allowedValues.length) {
      if (toLower(dataType) === 'datetime') {
        return allowedValues
          .map((dateStr: any) => {
            return moment(dateStr).format('YYYY-MM-DD hh:mm');
          })
          .toString();
      } else {
        return allowedValues.toString();
      }
    }
    return '';
  };

  const createConfigKeyData = (responseData: configKeyData, globalSysSetting: any) => {
    const { categories = [], allowedValues, dataType, value, description } = responseData;
    //const categoriesList = map(categories, 'name');
    let categoriesList = categories;
    if (globalSysSetting) {
      const { categories = [] } = globalSysSetting;
      categoriesList = categories;
    }

    let configKeyVal = value;
    if (toLower(dataType) === 'json') {
      configKeyVal = JSON.stringify(value);
    } else if (toLower(dataType) === 'datetime') {
      configKeyVal = moment(value).format(`${t('T_VALID_DATE_TIME_FORMAT')}`);
      setStartDateTime(new Date(value));
    } else if (toLower(dataType) === 'date') {
      configKeyVal = moment(value).format(`${t('T_VALID_DATE_FORMAT')}`);
      setStartDate(new Date(value));
    }
    setJsonString(configKeyVal);
    setSelectedItems(categoriesList);
    setDataTypeSelected(
      filter(AppConstants.CONFIG_KEY_DATA_TYPES_PICKLIST, (obj) => toLower(obj.key) === dataType)
    );
    const configKeyResponseObj = { ...responseData, categories: categoriesList };
    const configKeyObj = {
      ...configKeyResponseObj,
      categoriesList,
      allowedValues: displayAllowedValues(dataType, allowedValues),
      value: configKeyVal,
      description: description ? description : ''
    };
    setConfigKeyObj(configKeyObj);
    dispatch(setSettingKeyDetails(configKeyObj));
  };

  const getConfigKeyDetail = async () => {
    let globalSysSetting;
    const { orgId, userId } = queryParams;
    globalSysSetting = await getSettingById(queryParams.sysId || '');
    if (globalSysSetting && globalSysSetting.status === HTTP_STATUS.HTTP_OK) {
      if (orgId) {
        if (isEmpty(orgSysSetting)) {
          navigate(`/organization/${orgId}/config-key-list`);
        } else {
          createConfigKeyData(orgSysSetting, globalSysSetting.data);
        }
      } else if (userId) {
        if (isEmpty(userSysSetting)) {
          navigate(`/users/${userId}/config-key-list`);
        } else {
          createConfigKeyData(userSysSetting, globalSysSetting.data);
        }
      } else {
        createConfigKeyData(globalSysSetting.data, false);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (key: string, value: any) => {
    setConfigKeyObj({ ...configKeyObj, [key]: value });
  };

  const onPicklistChange = (item: any) => {
    const { categoriesList } = configKeyObj;
    const selectedCategory = map(item, 'name');
    setSelectedItems(item);
    setConfigKeyObj({
      ...configKeyObj,
      categoriesList: [...item],
      categories: [...item]
    });
  };

  const onDataTypePicklistChange = (item: any) => {
    setDataTypeSelected(item);
    setConfigKeyObj({
      ...configKeyObj,
      dataType: map(item, 'key').join()
    });
  };

  const removePillsFilter = (item: any) => {
    const { categoriesList } = configKeyObj;
    const categoriesSelected = filter(categoriesList, (category) => {
      return category.name !== item.name;
    });
    setSelectedItems(categoriesSelected);
    setConfigKeyObj({
      ...configKeyObj,
      categoriesList: categoriesSelected,
      categories: categoriesSelected
    });
  };

  const normalizeKey = (key: string): string => {
    // return key ? key.trim().replace(/\s+/g, '_')?.toLowerCase() : key;
    return key ? key.trim().replace(/\s+/g, '_') : key;
  };

  const getSystemSettingValue = () => {
    const { dataType, value } = configKeyObj;
    // if (toLower(dataType) === 'json') {
    //   return JSON.parse(value);
    // }
    // return value;

    switch (toLower(dataType)) {
      case 'string':
      case 'integer':
      case 'float':
      case 'date':
        return value.trim();
        break;
      case 'boolean':
        return Boolean(value);
      case 'datetime':
        return (
          moment(value, `${t('T_VALID_DATE_TIME_FORMAT')}`)
            .toISOString()
            .split('.')[0] + 'Z'
        );
        break;
      case 'json':
        return JSON.parse(value);
        break;
    }
  };

  const creatSetting = async (systemSettingBody: any) => {
    const response = await createSystemSetting(systemSettingBody);
    let error = false;
    let toastMessage = `${t('T_TOAST_CONFIGURATION_CREATED_SUCCESS_MSG')}`;
    if (response.status === HTTP_STATUS.HTTP_CREATED) {
      navigate(RouteConstants.ROUTE_CONFIGURATIONSERVICE_LIST);
    } else {
      error = !error;
      toastMessage = getFailureMessage(response);
    }
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType: error ? 'error' : 'success'
      })
    );
    setIsLoading(false);
  };

  const updateOrglevelSetting = async (systemSettingBody: any) => {
    let response,
      error = false,
      toastMessage = `${t('T_TOAST_CONFIGURATION_ORG_UPDATED_SUCCESS_MSG')}`;
    const { organizationId } = getLoggedInOrg(userProfileInfo);
    const orgId = queryParams.orgId || organizationId || '';
    response = await overRideSystemSetting(orgId, systemSettingBody);
    if (response.status === HTTP_STATUS.HTTP_CREATED) {
      navigate(`/organization/${orgId}/config-key-list`);
    } else {
      error = !error;
      toastMessage = getFailureMessage(response);
    }
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType: error ? 'error' : 'success'
      })
    );
    setIsLoading(false);
  };

  const updateUserLevelSetting = async (systemSettingBody: any) => {
    let response,
      error = false,
      toastMessage = `${t('T_TOAST_CONFIGURATION_USER_UPDATED_SUCCESS_MSG')}`;
    const loggedInUser = getUserId(userProfileInfo);
    const userId = queryParams.userId || loggedInUser || '';
    response = await overRideUserSystemSetting(userId, systemSettingBody);
    if (response.status === HTTP_STATUS.HTTP_CREATED) {
      navigate(`/users/${userId}/config-key-list`);
    } else {
      error = !error;
      toastMessage = getFailureMessage(response);
    }
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType: error ? 'error' : 'success'
      })
    );
    setIsLoading(false);
  };

  const updateSystemLevelSetting = async (systemSettingBody: any) => {
    let response,
      error = false,
      toastMessage = `${t('T_TOAST_CONFIGURATION_UPDATED_SUCCESS_MSG')}`;
    response = await updateSystemSetting(
      queryParams.sysId ? queryParams.sysId : '',
      systemSettingBody
    );
    if (response.status === HTTP_STATUS.HTTP_OK) {
      navigate(RouteConstants.ROUTE_CONFIGURATIONSERVICE_LIST);
    } else {
      error = !error;
      toastMessage = getFailureMessage(response);
    }
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType: error ? 'error' : 'success'
      })
    );
    setIsLoading(false);
  };

  const updateSetting = async () => {
    setIsLoading(true);
    const systemSettingBody = getSysSettingBody();
    if (assignToLevel === `${t('T_ORGANIZATION_LEVEL')}`) {
      updateOrglevelSetting(systemSettingBody);
    } else if (assignToLevel === `${t('T_USER_LEVEL')}`) {
      updateUserLevelSetting(systemSettingBody);
    } else if (assignToLevel === `${t('T_SYSTEM_LEVEL')}`) {
      updateSystemLevelSetting(systemSettingBody);
    }
    setConfirmModal(false);
  };

  const handleValidation = (): configKeyValidateData => {
    const { key, value, dataType, description = '' } = configKeyObj;
    return {
      key: validateLength(key, t, 1024),
      value: toLower(dataType) !== 'json' ? validateLength(value.toString(), t, 4096) : '',
      dataType: validateLength(dataType, t, 255),
      description: validateLength(description, t, 4096)
    };
  };

  const hasErrors = (): boolean => {
    return Object.entries(formError).filter((key: any, value: any) => value.length).length > 0;
  };

  const isDataChanges = () => {
    return isEqual(configKeyObj, settingKeyDetails);
  };

  const getAllowedValues = () => {
    const { dataType, allowedValues } = configKeyObj;

    if (toLower(dataType) === 'datetime') {
      if (allowedValues.length) {
        return allowedValues.split(',').map((dateStr: any) => {
          return dateStr && moment(dateStr, 'YYYY-MM-DD hh:mm').toISOString().split('.')[0] + 'Z';
        });
      } else {
        return [];
      }
    } else {
      return allowedValues.length ? allowedValues.split(',') : [];
    }
  };

  const getSysSettingBody = () => {
    const { categories, key, dataType, description, allowOverride } = configKeyObj;
    const systemSettingBody: any = {
      categories: map(categories, 'id'),
      //key: normalizeKey(key),
      key,
      value: getSystemSettingValue(),
      dataType: dataType,
      //description: description ? description : ' ',
      allowOverride,
      allowedValues: getAllowedValues()
    };
    if (description) {
      //systemSettingBody = {...systemSettingBody, description}
      systemSettingBody['description'] = description;
    }
    return systemSettingBody;
  };

  const saveSystemSetting = async () => {
    let formError: configKeyValidateData = handleValidation();
    setFormError(formError);
    if (!formError.key && !formError.value && !formError.dataType && !formError.description) {
      let response;
      if (!flowType.isCreate) {
        if (queryParams.orgId || queryParams.userId) {
          //updateSetting();
          setConfirmModal(true);
          setAssignToLevel(queryParams.orgId ? t('T_ORGANIZATION_LEVEL') : t('T_USER_LEVEL'));
        } else {
          //setConfirmModal(true);
          setAssignToLevel(t('T_SYSTEM_LEVEL'));
          updateSetting();
        }
      } else {
        setIsLoading(true);
        creatSetting(getSysSettingBody());
      }
    }
  };

  const getInputField = (type: string) => {
    return (
      <InputField
        type={type}
        id={'configKeyValue'}
        label={t('T_CONFIG_KEY_VALUE')}
        placeholder={''}
        fieldKey={'value'}
        asterixFirst={true}
        required={true}
        min={type === 'number' || type === 'decimal' ? 0 : undefined}
        formData={configKeyObj}
        setFormData={setConfigKeyObj}
        formError={formError}
        disabled={getIsEditOrReadOnly()}
        handleChange={(e: any) => {
          handleChange('value', e.target.value);
        }}
      />
    );
  };

  const DateInputField = forwardRef(({ value, onClick }: any, ref) => (
    <InputField
      type={'text'}
      id={'configKeyValue'}
      label={t('T_CONFIG_KEY_VALUE')}
      placeholder={''}
      fieldKey={'value'}
      asterixFirst={true}
      required={true}
      formData={configKeyObj}
      setFormData={setConfigKeyObj}
      formError={formError}
      onClick={onClick}
      style={{ width: '100%' }}
      ref={ref}
      disabled={getIsEditOrReadOnly()}
      handleChange={() => {}}
    />
  ));

  const getValueField = () => {
    const { dataType, value } = configKeyObj;

    switch (toLower(dataType)) {
      case 'string':
        return getInputField('text');
        break;
      case 'boolean':
        const result: boolean = value ? JSON.parse(value) : false;
        return (
          <>
            <div className="w-fit">
              <Label htmlFor={'boolean'}>
                <Image src={Asterix} className="mr-1" />
                {t('T_CONFIG_KEY_VALUE')}
              </Label>
            </div>

            <div className={`ml-3 flex h-full`}>
              <Switch
                className={`${getIsEditOrReadOnly() ? `disabled` : ``}`}
                toggle={result}
                onClick={() => handleChange('value', !result)}
                data-testid={'boolean-field'}
                id={'boolean'}
              >
                <ToggleButton toggle={result} />
              </Switch>
              <div className="ml-3">{result ? 'True' : 'False'}</div>
            </div>
          </>
        );
      case 'datetime':
        return (
          <DatePicker
            selected={startDateTime}
            disabledKeyboardNavigation={true}
            showTimeSelect
            onChange={(date) => {
              setStartDateTime(date);
              handleChange('value', moment(date).format(t('T_VALID_DATE_TIME_FORMAT')));
            }}
            customInput={<DateInputField />}
          />
        );
        break;
      case 'date':
        return (
          <DatePicker
            selected={startDate}
            disabledKeyboardNavigation={true}
            onChange={(date) => {
              setStartDate(date);
              handleChange('value', moment(date).format(t('T_VALID_DATE_FORMAT')));
            }}
            customInput={<DateInputField />}
          />
        );
        break;
      case 'integer':
      case 'float':
        return getInputField('number');
        break;
      case 'json':
        return (
          <JSONEditor
            setIsLoading={setIsLoading}
            isComponent={true}
            jsonString={jsonString}
            setJsonString={setJsonString}
            disabled={getIsEditOrReadOnly()}
            showLineNumber={true}
            showFormatJSONButton={true}
            showImportJSONButton={false}
            formatJSONButtonLabel="{}"
            JSONEditorLabel={t('T_CONFIG_KEY_VALUE')}
            required={true}
          />
        );
        break;
      default:
        return getInputField('text');
        break;
    }
  };

  const navigateToDetails = () => {
    const { orgId, userId, sysId } = queryParams;
    if (orgId) {
      navigate(`/organization/${orgId}/config-key-list`);
    } else if (userId) {
      navigate(`/users/${userId}/config-key-list`);
    } else {
      navigate(`/config-key-list`);
    }
  };

  const getEditDisabledForOverride = () => {
    if (!configKeyObj.allowOverride && (queryParams.orgId || queryParams.userId)) {
      return true;
    }
    return false;
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      {confirmModal && (
        <Modal
          overflowVisible={true}
          showCloseIcon={true}
          width="20rem"
          showHeader={true}
          headerText={t('T_ASSIGN_LEVEL')}
          toggleVisiblity={(value: boolean) => {
            setConfirmModal(false);
          }}
          isVisible={confirmModal}
          body={
            <div className="flex flex-col">
              <div className="flex">{t('T_WHAT_LEVEL_THIS_KEY_APPLY')}</div>
              <div className="mt-2 flex flex-col">
                <RadioButton
                  className="mb-3"
                  selectedField={
                    assignToLevel === t('T_SYSTEM_LEVEL') ? t('T_SYSTEM_LEVEL') : 'none'
                  }
                  option={t('T_SYSTEM_LEVEL')}
                  name={t('T_SYSTEM_LEVEL')}
                  label={t('T_SYSTEM_LEVEL')}
                  onClick={() => {
                    setAssignToLevel(t('T_SYSTEM_LEVEL'));
                  }}
                />
                <RadioButton
                  selectedField={
                    assignToLevel === t('T_ORGANIZATION_LEVEL') ? t('T_ORGANIZATION_LEVEL') : 'none'
                  }
                  option={t('T_ORGANIZATION_LEVEL')}
                  name={t('T_ORGANIZATION_LEVEL')}
                  label={t('T_ORGANIZATION_LEVEL')}
                  className="mb-3"
                  onClick={() => {
                    setAssignToLevel(t('T_ORGANIZATION_LEVEL'));
                  }}
                />
                <RadioButton
                  selectedField={assignToLevel === t('T_USER_LEVEL') ? t('T_USER_LEVEL') : 'none'}
                  option={t('T_USER_LEVEL')}
                  name={t('T_USER_LEVEL')}
                  label={
                    queryParams.userId ? t('T_CURRENT_USER_LEVEL') : t('T_LOGGED_IN_USER_LEVEL')
                  }
                  className="mb-3"
                  onClick={() => {
                    setAssignToLevel(t('T_USER_LEVEL'));
                  }}
                />
              </div>
              <ModalButton>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                >
                  {t('T_CANCEL')}
                </BiButton>
                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    updateSetting();
                  }}
                  // disabled={!permissions.create}
                >
                  {t('T_SAVE')}
                </BiButton>
              </ModalButton>
            </div>
          }
        />
      )}
      <div className="flex w-3/5 flex-col overflow-visible px-2 py-3">
        <Card
          title={t('T_SETTING_DETAILS')}
          icon={SettingIcon}
          alt={'T_SETTING_DETAILS'}
          height={''}
          width={''}
          className=""
          actions={
            <div className="ml-auto">
              {configKeyObj?.systemSettingId &&
              !isEqual(configKeyObj?.id, configKeyObj?.systemSettingId) ? (
                <TooltipButton message={t('T_OVERRIDDEN_CONFIGURATION')}>
                  <DocumentDuplicateIcon className="mx-2 h-6 w-6" />
                </TooltipButton>
              ) : (
                <TooltipButton message={t('T_INHERITED_CONFIGURATION')}>
                  <DocumentTextIcon className="mx-2 h-6 w-6" />
                </TooltipButton>
              )}
            </div>
          }
        >
          <MenuButtonsPortal>
            {flowType.isCreate && (
              <BiButton
                className={'mr-2 text-primary'}
                type="button"
                onClick={() => {
                  //navigate(`/config-key-list`);
                  navigateToDetails();
                }}
              >
                {t('T_BACK_TO_CONFIGURATION_KEYS')}
              </BiButton>
            )}
            {!flowType.isCreate && (
              <BiButton
                className={'mr-2 text-primary'}
                type="button"
                onClick={() => {
                  getIsEditOrReadOnly() ? navigateToDetails() : setisReadOnly(true);
                }}
              >
                {getIsEditOrReadOnly() ? t('T_BACK_TO_CONFIGURATION_KEYS') : t('T_CANCEL')}
              </BiButton>
            )}

            {!getIsEditOrReadOnly() && (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  saveSystemSetting();
                }}
                disabled={hasErrors() || validateSave() || isDataChanges()}
              >
                {t('T_SAVE')}
              </BiButton>
            )}
            {getIsEditOrReadOnly() && (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  setisReadOnly(false);
                }}
                //disabled={getEditDisabledForOverride()}
              >
                {t('T_EDIT')}
              </BiButton>
            )}
          </MenuButtonsPortal>
          <div className="flex w-full flex-wrap">
            {configKeyObj ? (
              <>
                <div className="mb-5 flex w-full flex-col flex-wrap">
                  <InputField
                    type={'text'}
                    id={'configKey'}
                    label={t('T_CONFIG_KEY_NAME')}
                    placeholder={''}
                    fieldKey={'key'}
                    asterixFirst={true}
                    required={true}
                    formData={configKeyObj}
                    setFormData={setConfigKeyObj}
                    formError={formError}
                    disabled={queryParams.sysId ? true : false}
                    handleChange={(e: any) => {
                      handleChange('key', e.target.value);
                    }}
                  />
                </div>
                <div className="mb-5 flex w-1/2 flex-col flex-wrap">
                  <PickList
                    fieldKey={'key'}
                    label={t('T_CONFIG_KEY_DATA_TYPE')}
                    required={true}
                    fieldValues={['key']}
                    selectedItems={dataTypeSelected}
                    onSelectedChange={(item: any) => {
                      onDataTypePicklistChange(item);
                    }}
                    items={AppConstants.CONFIG_KEY_DATA_TYPES_PICKLIST}
                    disabled={queryParams.sysId ? true : false}
                    readOnly={false}
                    hasMore={false}
                    type={PICKLIST_TYPES.SINGLE}
                  />
                </div>
                <div className="mb-5 mt-5 flex w-1/2 flex-row justify-center">
                  <div className="ml-5 w-fit self-center">
                    <span>{t('T_CONFIG_KEY_ALLOW_OVERRIDE')}</span>
                  </div>
                  <div className="ml-3 flex h-full items-center justify-start">
                    <RadioButton
                      selectedField={configKeyObj?.allowOverride ? 'Yes' : 'none'}
                      option={'Yes'}
                      name={'Yes'}
                      label={t('T_YES')}
                      onClick={() => {
                        setConfigKeyObj({ ...configKeyObj, allowOverride: true });
                      }}
                      disabled={getIsEditOrReadOnly()}
                    />
                    <RadioButton
                      selectedField={!configKeyObj?.allowOverride ? 'No' : 'none'}
                      option={'No'}
                      name={'No'}
                      label={t('T_NO')}
                      className="pl-2"
                      onClick={() => {
                        setConfigKeyObj({ ...configKeyObj, allowOverride: false });
                      }}
                      disabled={getIsEditOrReadOnly()}
                    />
                  </div>
                </div>
                {!queryParams.orgId && !queryParams.userId && (
                  <div className="mb-5 flex w-full flex-col flex-wrap">
                    <PickList
                      fieldKey={'name'}
                      label={t('T_CONFIG_KEY_CATEGORIES')}
                      required={false}
                      fieldValues={['name']}
                      selectedItems={selectedItems}
                      onSelectedChange={(item: any) => {
                        onPicklistChange(item);
                      }}
                      items={systemCategories}
                      disabled={getIsEditOrReadOnly()}
                      readOnly={false}
                      fetchMoreData={() => fetchMoreData()}
                      hasMore={hasMore}
                      type={PICKLIST_TYPES.MULTI}
                    />
                    <div className="mt-2 flex w-full flex-col flex-wrap">
                      <PillsButton
                        //label={t('T_CONFIG_KEY_CATEGORIES_ASSIGNED')}
                        formData={configKeyObj}
                        setFormData={setConfigKeyObj}
                        rows={2}
                        disabled={getIsEditOrReadOnly()}
                        pillsData={configKeyObj.categoriesList}
                        removeFilter={removePillsFilter}
                      ></PillsButton>
                    </div>
                  </div>
                )}

                <div className="mb-5 flex w-full flex-col flex-wrap">
                  <InputField
                    type={'text'}
                    id={'configKeyAllowedValues'}
                    label={t('T_CONFIG_KEY_ALLOWED_VALUES')}
                    placeholder={''}
                    fieldKey={'allowedValues'}
                    asterixFirst={false}
                    required={false}
                    formData={configKeyObj}
                    setFormData={setConfigKeyObj}
                    formError={formError}
                    disabled={getIsEditOrReadOnly() || !!queryParams.orgId || !!queryParams.userId}
                    handleChange={(e: any) => {
                      handleChange('allowedValues', e.target.value);
                    }}
                  />
                </div>
                <div className="mb-5 flex w-full flex-col flex-wrap">
                  <TextAreaField
                    fieldKey={'description'}
                    type={'text'}
                    id={'configKeyDesc'}
                    label={t('T_CONFIG_KEY_DESCRIPTION')}
                    placeholder={''}
                    required={false}
                    formData={configKeyObj}
                    setFormData={setConfigKeyObj}
                    formError={formError}
                    asterixFirst={false}
                    rows={3}
                    disabled={getIsEditOrReadOnly()}
                  ></TextAreaField>
                </div>

                <div
                  className={`mb-5 flex w-full  ${
                    toLower(configKeyObj.dataType) === 'boolean'
                      ? `flex-row`
                      : ` flex-col flex-wrap`
                  }}`}
                >
                  {getValueField()}
                  {/* {toLower(configKeyObj.dataType) !== 'json' ? (
                    <InputField
                      type={'text'}
                      id={'configKeyValue'}
                      label={t('T_CONFIG_KEY_VALUE')}
                      placeholder={''}
                      fieldKey={'value'}
                      asterixFirst={true}
                      required={true}
                      formData={configKeyObj}
                      setFormData={setConfigKeyObj}
                      formError={formError}
                      disabled={getIsEditOrReadOnly()}
                      handleChange={(e: any) => {
                        handleChange('value', e.target.value);
                      }}
                    />
                  ) : (
                    <JSONEditor
                      setIsLoading={setIsLoading}
                      isComponent={true}
                      jsonString={jsonString}
                      setJsonString={setJsonString}
                      disabled={getIsEditOrReadOnly()}
                      showLineNumber={true}
                      showFormatJSONButton={true}
                      showImportJSONButton={false}
                      formatJSONButtonLabel="{}"
                      JSONEditorLabel={t('T_CONFIG_KEY_VALUE')}
                      required={true}
                    />
                  )} */}
                </div>
              </>
            ) : null}
          </div>
        </Card>
      </div>
    </LoaderContainer>
  );
};

export const ModalButton = styled('div')`
  display: flex;
  justify-content: end;
`;

const Switch = styled.div<{ toggle: boolean }>`
  width: 44px;
  height: 22px;
  background: ${({ toggle }) => (!toggle ? '#BFBFBF' : '#0074e0')};
  border-radius: 16px;
  position: relative;
  margin-top: 3px;
  transition: ease-out 0.5s;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    background-color: #bfbfbf;
  }
`;

const ToggleButton = styled.div<{ toggle: boolean }>`
  position: absolute;
  height: 18px;
  top: calc(50% - 18px / 2);
  background: #ffffff;
  border-radius: 16px;
  width: 16px;
  ${({ toggle }) => (!toggle ? `left: 2px;` : 'right: 2px;')}
`;

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

export default ConfigKeyDetails;
