import styled from 'styled-components/macro';
export interface InputContainerProps {
  readonly disabled?: boolean;
}

export interface InputProps {
  readonly outline?: boolean;
  ref?: any;
}

export const Container = styled.main`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<Props>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const Label = styled.label`
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const OptionalLabel = styled.label`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #7d7d7d;
  margin-left: 4px;
`;

export const Input = styled.input<InputProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  height: 44px;
  background: #ffffff;
  // border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  color: #262626;

  &:disabled {
    background-color: #f5f5f5;
  }
  border: ${({ outline }) => (outline ? '1px solid #dc2626' : '0.5px solid #8c8c8c')};
`;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  background: ${(props) => (props.disabled ? '#f5f5f5' : '#ffffff')};
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  overflow: hidden;
`;

export const InputF = styled.input`
  align-items: center;
  height: 44px;
  background: #ffffff;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  padding: 10px 12px;
  outline: none;

  &:disabled {
    background-color: #f5f5f5;
  }
`;

export const ViewPasswordEye = styled.div`
  font-size: 10px;
  cursor: pointer;
  margin-left: -35px;
  margin-top: 10px;
  width: 20px;
`;

export const TextArea = styled.textarea`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  background: #ffffff;
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  resize: none;
  color: #262626;
  &:disabled {
    background-color: #f5f5f5;
  }
`;

export const InputStatus = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  height: 44px;
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
`;

export const Group = styled.section<GroupProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  margin-right: 20px;
  margin-bottom: 15px;
`;

export const Button = styled.button`
  width: 210px;
  height: 40px;
  border-radius: 5px;
  ${(props) => props.theme.bgPrimaryStyleColor}
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: Open Sans;
  &:disabled {
    ${(props) => props.theme.bgPrimaryDisabledStyleColor}
  }
`;

export type Props = {
  justifyContent: string;
};

export type GroupProps = {
  width: string;
};
