import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppConstants } from '../../constants/AppConstants';
import { useTranslation } from 'react-i18next';
import BiButton from '../primitives/buttons/BiButton.primitive';
import FilterIcon from '../../assets/filter.svg';
import Modal from '../shared/ModalPortal/Modal';
import { close, resetIcon } from '../../assets/components/svgs';
import SelectField from '../shared/Fields/SelectField';
import InputField from '../shared/Fields/InputField';
import { useAppSelector } from '../../app/hooks';
import PillsButton from '../shared/Fields/PillsButton';
import { compact, isArray, upperFirst, find, filter, isEqual } from 'lodash';
import { consentNameValidation, consentVersionValidation } from '../../util/validators';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import { Label } from '../AccountProfile/ProfileStyles';
import { removeArrayElement, getPickListItems, sortArray } from '../../util/consent-utils';

export interface filterConfigProps {
  status: string;
  type: string;
  name: string;
  version: string;
  'category-code': any;
  'purpose-code': any;
}

interface ConsentListFilterProps {
  filterConfig: filterConfigProps;
  setFilterConfig: Function;
  systemFilterOption: any;
}

const defaultSystemFilterOption = {
  purposeOption: new Map(),
  categoryOption: new Map()
};

const defaultFilter = {
  status: '',
  type: '',
  name: '',
  version: '',
  'category-code': [],
  'purpose-code': []
};

const ConsentListFilter = ({
  filterConfig,
  setFilterConfig,
  systemFilterOption
}: ConsentListFilterProps) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState(false);

  const [formFilterData, setFormFilterData] = useState<any>(defaultFilter);

  const [formError, setFormError] = useState<any>({
    status: '',
    type: '',
    name: '',
    version: ''
  });

  const [pills, setPills] = useState<any>([]);

  useEffect(() => {
    setFormFilterData(filterConfig);
  }, [filter]);

  useEffect(() => {
    setPillsData();
  }, [formFilterData]);

  const typeOption: string[] = ['PERMIT', 'DENY'];

  const applyFilter = () => {
    setFilterConfig(formFilterData);
    setFilter(false);
  };

  const ConsentTemplate = () => {
    return (
      <div className="h-[300px]">
        <div className="flex">
          <BiButton
            className={'ml-auto flex py-[6px] text-primary'}
            type="button"
            disabled={false}
            onClick={() => {
              setFormFilterData(defaultFilter);
              setFormError({ status: '', type: '', name: '', version: '' });
            }}
          >
            <span className="mr-1 pt-[4px]">{resetIcon}</span>
            {t('T_RESET_FILTER')}
          </BiButton>
          <BiButton
            className={'ml-5 bg-primary py-[6px] text-white'}
            type="button"
            disabled={
              !Object.keys(formError).every((k) => !formError[k]) ||
              isEqual(formFilterData, filterConfig)
            }
            onClick={() => {
              applyFilter();
            }}
          >
            {t('T_APPLY_FILTER')}
          </BiButton>
          <div
            className="ml-9 w-fit cursor-pointer pt-[8px]"
            onClick={() => {
              setFilter(!filter);
            }}
          >
            {close}
          </div>
        </div>

        <div className="flex w-full pt-[30px]">
          <div className="mr-2 w-1/3">
            <SelectField
              type="text"
              id="status"
              label={t('T_STATUS')}
              placeholder={t('T_STATUS')}
              fieldKey={'status'}
              required={false}
              formData={formFilterData}
              setFormData={setFormFilterData}
              formError={formError}
              options={[...systemFilterOption.statusOption].sort()}
              maxHeight={false}
              fontSize={'16px'}
            />
          </div>

          <div className="mx-2 w-1/3">
            <InputField
              className="w-full"
              type="text"
              id={'name'}
              label={t('T_CONSENT_NAME')}
              placeholder={t('T_CONSENT_NAME')}
              fieldKey="name"
              required={false}
              formData={formFilterData}
              formError={formError}
              setFormData={setFormFilterData}
              disabled={false}
              handleChange={(e: any) => {
                const { value = '' } = e.target || {};
                if (consentNameValidation(value)) {
                  setFormFilterData({ ...formFilterData, name: value });
                }
              }}
              min={0}
              max={255}
            />
          </div>

          <div className="mx-2 w-1/3">
            <SelectField
              type="text"
              id="type"
              label={t('T_TYPE')}
              placeholder={t('T_TYPE')}
              fieldKey={'type'}
              required={false}
              formData={formFilterData}
              setFormData={setFormFilterData}
              formError={formError}
              options={typeOption.sort()}
              maxHeight={true}
              fontSize={'16px'}
            />
          </div>
        </div>

        <div className="flex w-full pt-[30px]">
          <div className="mr-2 w-1/3">
            <Label>{t('T_CATEGORY')}</Label>
            <PickList
              fieldKey={'key'}
              fieldValues={['value']}
              selectedItems={formFilterData['category-code']}
              onSelectedChange={(items: any) => {
                setFormFilterData({ ...formFilterData, ['category-code']: items });
              }}
              items={sortArray(
                Array.from(systemFilterOption.categoryOption.keys()).map((ele: any) => {
                  return getPickListItems(systemFilterOption.categoryOption, ele);
                }) || []
              )}
              disabled={false}
              readOnly={false}
              type={PICKLIST_TYPES.MULTI}
            />
          </div>

          <div className="mx-2 w-1/3">
            <Label>{t('T_PURPOSE')}</Label>
            <PickList
              fieldKey={'key'}
              fieldValues={['value']}
              selectedItems={formFilterData['purpose-code']}
              onSelectedChange={(items: any) => {
                setFormFilterData({ ...formFilterData, ['purpose-code']: items });
              }}
              items={sortArray(
                Array.from(systemFilterOption.purposeOption.keys()).map((ele: any) => {
                  return getPickListItems(systemFilterOption.purposeOption, ele);
                }) || []
              )}
              disabled={false}
              readOnly={false}
              type={PICKLIST_TYPES.MULTI}
            />
          </div>

          <div className="ml-2 w-1/3">
            <InputField
              className="w-full"
              type="number"
              id={t('T_VERSION')}
              label={t('T_VERSION')}
              placeholder={'Version'}
              fieldKey="version"
              required={false}
              formData={formFilterData}
              formError={formError}
              setFormData={setFormFilterData}
              disabled={false}
              handleChange={(e: any) => {
                const { value = '' } = e.target;
                const intValue = parseInt(value);
                if (!isNaN(intValue)) {
                  const validVersion = consentVersionValidation(intValue, t);
                  setFormError({ ...formError, version: validVersion });
                  if (intValue >= 1) setFormFilterData({ ...formFilterData, version: intValue });
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const setPillsData = () => {
    let t: Array<any> = [];
    Object.keys(formFilterData).map((ele: any) => {
      if (formFilterData[ele]) {
        if (isArray(formFilterData[ele])) {
          formFilterData[ele].map((item: any) => {
            t.push({
              key: ele,
              value: item,
              label: upperFirst(ele.replace('-code', '')),
              name: item.value
            });
          });
        } else {
          t.push({
            key: ele,
            value: ele,
            label: upperFirst(ele),
            name: formFilterData[ele]
          });
        }
      }
    });

    setPills(t);
  };

  const removePill = (ele: any) => {
    const { key, value } = ele;
    let val: any = '';
    if (isArray(formFilterData[key])) {
      val = removeArrayElement(value, formFilterData[key]);
    }
    let filterData = { ...formFilterData, [ele.key]: val };
    setFormFilterData(filterData);
    setFilterConfig(filterData);
  };

  return (
    <>
      <div className="flex pl-[20px] pt-[20px] leading-[30px]">
        <div className="my-auto min-w-[6rem]">
          <BiButton
            className={
              'mr-2 flex border border-solid border-[#99B0C6] !py-[6px] text-[12px] font-semibold leading-[15px]'
            }
            type="button"
            disabled={false}
            onClick={() => {
              setFilter(true);
            }}
            style={{ padding: '0 5px' }}
          >
            <img src={FilterIcon} alt={'filter'} className="mr-1" />
            {t('T_FILTER')}
          </BiButton>
        </div>

        <div className="w-full">
          {pills.length ? (
            <div className="-mt-[5px]">
              <PillsButton
                formData={formFilterData}
                setFormData={setFormFilterData}
                rows={2}
                pillsData={pills || []}
                removeFilter={removePill}
              ></PillsButton>
            </div>
          ) : (
            <span className="text-[12px] font-normal">{t('T_DEFAULT_ALL_CONSENT_TEMPLATE')}</span>
          )}
        </div>
      </div>

      <Modal
        showCloseIcon={true}
        width="70rem"
        headerText={''}
        toggleVisiblity={(value: boolean) => {
          setFilter(value);
        }}
        isVisible={filter}
        body={ConsentTemplate()}
        headerLeftIcon={<img src={FilterIcon} alt={t('T_FILTER')} />}
        showHeader={false}
        className="mt-[50px] overflow-visible"
        overflowVisible={true}
      />
    </>
  );
};

export default ConsentListFilter;
