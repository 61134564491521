import { AppConstants } from '../../constants/AppConstants';
import { FieldGroupCard } from './FieldGroup';

const childContainerLayout: any = {
  1: 'w-3/4',
  2: 'basis-[49%]',
  3: 'basis-[33%]',
  4: 'basis-[24.5%]'
};

interface MobileConfigurationDynamicUIProps {
  configurations: any;
  pageLayout: number;
  fieldValues: any;
  fieldErrors: any;
  onValueChange: Function;
}

const MobileConfigurationDynamicUI = ({
  configurations,
  fieldValues,
  fieldErrors,
  onValueChange,
  pageLayout = AppConstants.PAGE_LAYOUT_MONO
}: MobileConfigurationDynamicUIProps) => {
  const { grouphierarchy = [], fieldByGroup = {} } = configurations;

  return (
    <div
      className={`flex h-fit w-full justify-between overflow-visible  ${
        pageLayout > 1 ? `flex-row flex-wrap` : 'flex-col'
      }`}
    >
      {grouphierarchy.map((fieldGroup: any) => {
        return (
          <FieldGroupCard
            key={Math.random()}
            fieldGroup={fieldGroup}
            fieldItems={fieldByGroup[fieldGroup.attributes?.groupId] || []}
            fieldValues={fieldValues}
            fieldErrors={fieldErrors}
            onValueChange={onValueChange}
            pageLayout={pageLayout}
          />
        );
      })}
    </div>
  );
};
export default MobileConfigurationDynamicUI;
