import { useEffect, useState } from 'react';
import DotMenu, { Options } from '../../primitives/dotMenu/DotMenu';
import ActionDropdown from '../../primitives/dropdowns/ActionDropdown';
import userIcon from '../../assets/user-group.svg';
import ToastNotification from '../../Reusable/ToastNotification';
import { MenuConstants } from '../../../constants/MenuConstants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../../constants/AppConstants';
import { useAppSelector } from '../../../app/hooks';
import PlusIcon from '@heroicons/react/outline/PlusIcon';

interface AdminMenuProps {
  className?: string;
  iconSize?: string;
  AddQuestions: any;
  AddGroupQuestions: any;
}

const AddQuestionMenu: React.FC<AdminMenuProps> = ({
  iconSize = '',
  AddQuestions,
  AddGroupQuestions,
  className = ''
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const options: Array<any> = [];

  const [selectedOption, setSelectedOption] = useState<Options | null>();

  options.push({
    display: (
      <div>
        <div>{`${t('Question(S)')}`}</div>
        <div className="text-xs">{`${t('T_MENU_ADD_25_QUESTIONS')}`}</div>
      </div>
    ),
    key: MenuConstants.MENU_VIEW_SETTINGS,
    action: () => {
      AddQuestions();
    }
  });

  options.push({
    display: (
      <div>
        <div>{`${t('Group Question')}`}</div>
        <div className="text-xs">{`${t('T_MENU_BUNDLE_5_QUESTIONS')}`}</div>
      </div>
    ),
    key: MenuConstants.MENU_VIEW_SETTINGS,
    action: () => {
      AddGroupQuestions();
    }
  });

  const [toasterMessage, setToasterMessage] = useState('');
  const [isToastActive, setIsToastActive] = useState(false);
  const [toasterType, setToasterType] = useState<any>('success');

  return (
    <>
      <ToastNotification
        message={toasterMessage}
        isActive={isToastActive}
        setIsActive={setIsToastActive}
        status={toasterType}
      />
      <div>
        <ActionDropdown
          options={options}
          onSelectedOptionChange={(value: any) => {
            value.action();
            setSelectedOption(value);
          }}
          value={'Add'}
          defaultLabel={
            <>
              <PlusIcon className="m-1 ml-0 h-4 w-4" data-testid="plus-icon" />{' '}
              <span className="-ml-8">Add</span>
            </>
          }
          className="!w-[130px]"
        />
      </div>

      {/* {selectedOption?.key === MenuConstants.MENU_VIEW_SETTINGS && AddQuestions()} */}
    </>
  );
};

export default AddQuestionMenu;
