import {
  getCategories,
  getSystemSettings,
  updateSystemSetting
} from '../http/configuration-services';
import { AppConstants } from '../constants/AppConstants';
import { createSystemSetting } from '../http/configuration-services';
import { HTTP_STATUS } from '../http/constants/http.status';
import { getLoggedInOrg, getLoginUserId, getLoginUserRoles } from './admin-utils';
import { getMetadataInstances, updateMetadataInstance } from '../http/metadata-service';
import { createEvent } from '../http/event-services';
import { loadOtherSettings } from '../components/PermissionSet/PermissionSetUtil';

export interface PasswordPolcy {
  id?: string;
  name: string;
  pwdInHistory?: number;
  pwdMaxAge?: number;
  pwdMinLength: boolean;
  characterSets?: Array<string>;
}
export interface RolePayload {
  name: string;
  mfa: boolean;
  locationHeuristics: boolean;
  description?: string;
  apiPermissions?: Array<string>;
  passwordPolicy?: string;
}
export interface Role {
  id: string;
  name: string;
  mfa: boolean;
  locationHeuristics: boolean;
  description?: string;
  permissions: Array<PermissionDTO>;
  passwordPolicy?: string;
}
interface PermissionDTO {
  id: string;
  apiPermission: string;
  apiUrl: string;
  apiMethod: string;
  description: string;
}

export interface SystemSetting {
  id?: string;
  key: string;
  value: any;
  dataType: string;
  description?: string | null;
  allowOverride?: boolean;
  allowedValues?: Array<string>;
  categories?: Array<string | Category>;
  attributes?: any;
  label?: string;
}

export interface Category {
  id?: string;
  name: string;
  description?: string;
}

export const createPasswordPolicy = (payload: PasswordPolcy): PasswordPolcy | null => {
  return null; //Done
};

export const checkRoleFieldLength = (roleName: string): boolean => {
  return roleName.length <= 1024;
};

export const getApplicationCategory = async (
  categoryNames: Array<string> = [],
  listCategories: any = null
): Promise<Array<Category>> => {
  let filteredCategories: Array<Category> = [];
  let response;

  if (!listCategories) {
    response = await getCategories('', AppConstants.DEFAULT_PAGE, AppConstants.MAXIMUM_PAGE_SIZE);
  } else {
    response = { ...listCategories };
  }

  const { status, data: categoryResponse } = response;
  if (status == HTTP_STATUS.HTTP_OK) {
    const { data: categories = [] } = categoryResponse;
    filteredCategories = categories.filter((category: Category) =>
      categoryNames.includes(category.name)
    );
  }
  return filteredCategories;
};

export const createRole = (payload: RolePayload, passwordPolicy: string): Role | null => {
  return null; //Done
};

export const getUnAssignedPermissionSets = async (
  flag: number,
  category: string
): Promise<Array<SystemSetting>> => {
  let permissionSets: Array<SystemSetting> = [];
  if (flag === 2) {
    const response = await getMetadataInstances(
      category,
      [],
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: permSetRespons } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: permissionSetList = [] } = permSetRespons;
      permissionSets = permissionSetList.filter((permissionSet: SystemSetting) => {
        const { attributes = {} } = permissionSet;
        const { roles = [] } = attributes;
        return !roles.length;
      });
    }
  }
  if (flag === 1) {
    const response = await getSystemSettings(
      category,
      AppConstants.DATA_TYPE_JSON,
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data: permSetRespons } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: permissionSetList = [] } = permSetRespons;
      permissionSets = permissionSetList.filter((permissionSet: SystemSetting) => {
        const { value = {} } = permissionSet;
        const { roles = [] } = value;
        return !roles.length;
      });
    }
  }
  return permissionSets;
};

export const normalizeKey = (key: string): string => {
  return key ? key.trim().replace(/\s+/g, '_')?.toLowerCase() : key;
};

export const formatApplicationPrivileges = (application: Array<any> = []) => {
  return application.flatMap((item: any) => {
    const { feature, functions = [] } = item;
    return functions.flatMap((func: any) => {
      const { action } = func;
      return { action, feature };
    });
  });
};

export const createApplicationRole = async (
  permissionSet: SystemSetting,
  role: Role,
  categories: Array<string> = []
): Promise<SystemSetting | null> => {
  let applicationRole = null;
  const {
    attributes = {},
    description,
    dataType = AppConstants.DATA_TYPE_JSON,
    allowOverride,
    allowedValues
  } = permissionSet || {};
  if (!Object.values(attributes).length) {
    return applicationRole;
  }
  const { name: rolename } = role;
  const { permissions = {} } = attributes;
  const { application = [] } = permissions;
  const privileges = formatApplicationPrivileges(application);

  const key = `${AppConstants.SOLUTION_FEATURE_KEY}.${
    AppConstants.SOLUTION_ASSIGNMENT
  }.${rolename?.toLowerCase()}`;
  const payload = {
    key,
    value: { rolename, privileges },
    description,
    dataType,
    allowOverride,
    allowedValues,
    categories
  };

  const response = await createSystemSetting(payload);
  const { status, data } = response;
  if (status == HTTP_STATUS.HTTP_CREATED) {
    applicationRole = data;
  }
  return applicationRole;
};

export const updateApplicationContextKey = async (
  role: Role,
  category: string,
  identifier: string
): Promise<SystemSetting | null> => {
  const { name } = role;
  let updateSetting = null;
  const key = `${AppConstants.SOLUTION_FEATURE_KEY}.${
    AppConstants.SOLUTION_ASSIGNMENT
  }.${name?.toLowerCase()}`;
  const response = await getSystemSettings(
    category,
    AppConstants.DATA_TYPE_JSON,
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );
  const { status, data: settingResponse } = response;
  if (status == HTTP_STATUS.HTTP_OK) {
    const { data: settings = [] } = settingResponse;
    const [applicationContext] = settings.filter(
      (setting: SystemSetting) => setting.key === identifier
    );

    if (applicationContext) {
      const { id, value = {}, categories = [] } = applicationContext;
      const application = value[`${AppConstants.APPLICATION_NAME}`];
      const { keys = [] } = application;
      keys.push({
        key,
        element: AppConstants.FEATURE_ASSIGNMENT_ELEMENT,
        element_type: AppConstants.FEATURE_ASSIGNMENT_TYPE
      });
      const updateResponse = await updateSystemSetting(id, {
        ...applicationContext,
        categories: categories.map((category: Category) => category.id as string)
      });
      const { status: updateStatus, data: updateData } = updateResponse;
      if (updateStatus == HTTP_STATUS.HTTP_OK) {
        updateSetting = updateData;
      }
    }
  }
  return updateSetting;
};

export const updatePermissionSet = async (
  permissionSet: SystemSetting,
  appRole: SystemSetting,
  role: Role,
  permissionUpdateSettings?: Array<any>
): Promise<SystemSetting | null> => {
  let updatedPermSet = null;
  const { id: roleId } = role;
  const { id, key, value: appRoleValue = {} } = appRole;
  const { rolename = '' } = appRoleValue;
  const { value = {}, id: permSetId, categories = [] } = permissionSet;
  const { roles = [], permissions = {} } = value;
  const { application = [], settings = '[]' } = permissions;
  roles.push({ roleId, mapping: { id, key } });
  const loggedInOrg = getLoggedInOrg();
  const userId = getLoginUserId();
  let payload = {
    ...permissionSet,
    categories: categories.map((category: any) => category.id),
    value: {
      ...value,
      roles: [
        {
          ...value.roles[0],
          settings:
            value.permissions.settings && Array.isArray(value.permissions.settings)
              ? value.permissions.settings.map((setting: any) => {
                  return { id: setting.id, key: setting.key };
                })
              : value.permissions.settings &&
                typeof value.permissions.settings === 'object' &&
                !Array.isArray(value.permissions.settings)
              ? [
                  {
                    id: value.permissions.settings.id,
                    key: value.permissions.settings.key
                  }
                ]
              : []
        }
      ],
      permissions: { ...value.permissions, application: [], settings: '' }
    }
  };

  if (permissionUpdateSettings) {
    payload.value.roles[0].settings = [
      ...payload.value.roles[0].settings,
      ...permissionUpdateSettings
    ];
  }

  const response = await updateSystemSetting(permSetId as string, payload);
  const { status: updateStatus, data } = response;
  if (updateStatus == HTTP_STATUS.HTTP_OK) {
    updatedPermSet = data;
    // const eventPayload = {
    //   userId: userId,
    //   organizationId: loggedInOrg?.organizationId,
    //   source: AppConstants.EVENT_SOURCE,
    //   type: AppConstants.EVENT_TYPE_CREATE,
    //   time: getCurrentDate(),
    //   data: {
    //     roleName: rolename,
    //     permissions: formatApplication(application),
    //     settings: settings
    //   }
    // };

    // const eventResponse = await createEvent(eventPayload);
    await triggerCreateEvent(userId, loggedInOrg, rolename, application, settings);
  }
  return updatedPermSet;
};

export const triggerCreateEvent = async (
  userId: string,
  loggedInOrg: any,
  rolename: string,
  application: any,
  settings: any
) => {
  const eventPayload = {
    userId: userId,
    organizationId: loggedInOrg?.organizationId,
    source: AppConstants.EVENT_SOURCE,
    type: AppConstants.EVENT_TYPE_CREATE,
    time: getCurrentDate(),
    data: {
      roleName: rolename,
      permissions: formatApplication(application),
      settings: settings
    }
  };

  await createEvent(eventPayload);
};

export const updatePermissionSetInstance = async (
  objectId: string,
  permissionSet: SystemSetting,

  role: Role,
  permissionUpdateSettings?: Array<any>
): Promise<SystemSetting | null> => {
  // const permissionListResponse = await getMetadataInstances(objectId);
  // let updatedPermSet = null;
  // if (permissionListResponse.status === HTTP_STATUS.HTTP_OK) {
  //   const {
  //     data: { data: permissionSetList = [] }
  //   } = permissionListResponse;
  //   const filteredSet = permissionSetList.filter(
  //     (item: any) => item.attributes.name === permissionSet.value.name
  //   );
  //   if (filteredSet.length) {
  //     const { id: roleId } = role;
  //     const { value } = appRole;
  //     const { rolename } = value;
  //     const { attributes = {}, id: permSetId } = filteredSet[0];
  //     const { roles = [], permissions = {} } = attributes;
  //     const { application = [], settings = '[]' } = permissions;

  //     roles.push(rolename);

  //     let payload = {
  //       attributes: {
  //         ...attributes,
  //         roles: roles,
  //         permissions: { ...attributes.permissions, application: application, settings: settings }
  //       }
  //     };

  //     if (permissionUpdateSettings) {
  //       payload.attributes.roles[0].settings = [
  //         ...payload.attributes.roles[0].settings,
  //         ...permissionUpdateSettings
  //       ];
  //     }
  //     if (permSetId) {
  //       const response = await updateMetadataInstance(objectId, permSetId as string, payload);
  //       const { status: updateStatus, data } = response;
  //       if (updateStatus == HTTP_STATUS.HTTP_OK) {
  //         updatedPermSet = data;
  //       }
  //     }
  //   }
  // }
  let updatedPermSet = null,
    eventSettings: any = '';
  const { id: roleId, name = '' } = role;
  // const { value = {} } = appRole;
  // const { rolename = '' } = value;
  const { attributes = {}, id: permSetId, label = '' } = permissionSet;
  const { roles = [], permissions = {} } = attributes;
  const { application = [], settings = [], adminPortal = [] } = permissions;
  const loggedInOrg = getLoggedInOrg();
  const userId = getLoginUserId();
  if (name) {
    roles.push(name);
  }
  const { successResponses } = await loadOtherSettings(settings);
  if (Array.isArray(successResponses)) {
    try {
      eventSettings = JSON.stringify(successResponses);
    } catch {
      eventSettings = '';
    }
  } else {
    eventSettings = '';
  }
  let payload = {
    label,
    attributes: {
      ...attributes,
      roles: roles.length ? roles : [],
      permissions: { ...attributes.permissions, application: application, settings: settings }
    }
  };
  if (permSetId) {
    const response = await updateMetadataInstance(objectId, permSetId as string, payload);
    const { status: updateStatus, data } = response;
    if (updateStatus == HTTP_STATUS.HTTP_OK) {
      updatedPermSet = data;
      await triggerCreateEvent(userId, loggedInOrg, name, application, eventSettings);
    }
  }
  return updatedPermSet;
};

export const getCurrentDate = () => {
  return new Date().toISOString().split('.')[0] + 'Z';
};

export const formatApplication = (applications: any) => {
  return applications
    .filter((application: any) => application.functions.length)
    .map((application: any) => {
      return {
        feature: application.feature,
        functions: application.functions.map((functionObj: any) => {
          return functionObj.action;
        })
      };
    });
};

// export const getRoles = (
//   type: string,
//   orgInvitationPolicy: any,
//   orgCreationPolicy: any,
//   parentType?: string,
//   orgName?: string
// ) => {
//   const userRoles: Array<any> = getLoginUserRoles();
//   let role = userRoles[0]?.name;

//   const roles: Set<string> = new Set([]);
//   const orgType = type;
//   let invitationPolicys: Array<any> = orgInvitationPolicy[orgType?.trim()];
//   if (invitationPolicys && invitationPolicys.length) {
//     const [invitationPolicy] = invitationPolicys.filter(
//       (invitationPolicy: any) => invitationPolicy?.isAdmin
//     );

//     if (invitationPolicy) {
//       role = invitationPolicy.role;
//       roles.add(role);
//     }
//   }
//   if (parentType) {
//     invitationPolicys = orgInvitationPolicy[parentType?.trim()];
//     if (invitationPolicys && invitationPolicys.length) {
//       const [invitationPolicy1] = invitationPolicys.filter(
//         (invitationPolicy: any) => invitationPolicy?.isAdmin
//       );
//       if (invitationPolicy1) {
//         role = invitationPolicy1?.role;
//         roles.add(role);
//       }
//     }
//   } else {
//     const orgs: Array<string> = [];
//     for (const property in orgCreationPolicy) {
//       if (orgCreationPolicy[property].includes(orgType)) {
//         orgs.push(property);
//       }
//     }
//     const parentInvitPolices = orgs.flatMap((org: any) => orgInvitationPolicy[org]);
//     if (parentInvitPolices.length) {
//       const [parentInvitePolicy] = parentInvitPolices.filter(
//         (invitationPolicy: any) => invitationPolicy?.isAdmin
//       );
//       if (parentInvitePolicy) {
//         roles.add(parentInvitePolicy.role);
//       }
//     }
//   }

//   return roles;
// };

export const apiPermissionCheck = (permissions: any = {}, apiPermissions: string[] = []) => {
  return apiPermissions.length
    ? apiPermissions.filter((apiPermission: string) => permissions[apiPermission]).length
    : false;
};
