import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import translationLocalEN from './locales/en/translation';
import environment from './environment';
import { AppConstants } from './constants/AppConstants';

const currentLocale = navigator.language;
const loadCurrentLocaleTranslation = `${AppConstants.BASE_STORAGE_URL}/${
  environment.ENV_SOLUTION_NAME
}/i18n/translation_${currentLocale.trim()}.json`;

const resources = {
  en: {
    translation: translationLocalEN
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    fallbackLng: 'en',
    // i18next config
    backend: {
      backends: [
        HttpApi, // primary to load data from gcp bucket
        resourcesToBackend(resources) // fallback
      ],
      backendOptions: [
        {
          loadPath: loadCurrentLocaleTranslation
        }
      ]
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
