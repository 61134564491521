import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  dragIcon,
  multiDropdown,
  multiDropdownUp,
  plusSign
} from '../../../../assets/components/svgs';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Checkbox from '../../../shared/Fields/Checkbox';
import InputField from '../../../shared/Fields/InputField';
import { DropDownList } from '../../../shared/Fields/SelectField';
import { handleDragStart, handleDragEnter, handleDragEnd } from './dragAndDropFunctions';
import { cloneDeep } from 'lodash';

interface MultipleSelectionProps {
  formData: any;
  setFormData: any;
  formError: any;
  editMode?: boolean;
  editFromSurveyTemplate?: boolean;
}
const MultipleSelection = ({
  formData,
  setFormData,
  formError,
  editMode,
  editFromSurveyTemplate
}: MultipleSelectionProps) => {
  const { t } = useTranslation();

  const [optionValue, setOptionValue] = useState<any>({
    option1: '',
    option2: ''
  });

  const [isOpen, setIsOpen] = useState<any>({ option1: false, option2: false });
  const [isDefaultSelected, setIsDefaultSelected] = useState<any>({
    option1: false,
    option2: false
  });
  const params = useParams();
  const dropdownRef = useRef<any>(null);

  let todoItemDrag = useRef<any>();
  let todoItemDragOver = useRef<any>();

  const handleAddField = () => {
    let newField = { [`option${Object.keys(optionValue).length + 1}`]: '' };
    const tempField = { [`option${Object.keys(optionValue).length + 1}`]: false };
    setOptionValue({ ...optionValue, ...newField });
    setIsDefaultSelected({ ...isDefaultSelected, ...tempField });
    setFormData({
      ...formData,
      answerOptions: {
        ...formData.answerOptions,
        [`option${Object.keys(optionValue).length + 1}`]: { value: '', isSelected: false }
      }
    });
  };

  const handleRemoveField = (index: any) => {
    let tempFormData = { ...optionValue };
    let newFormData: any = {};
    let tempIsDefaultSelected: any = { ...isDefaultSelected };
    let newDefaultSelected: any = {};
    let data: any = {};

    delete tempIsDefaultSelected[`option${index + 1}`];
    delete tempFormData[`option${index + 1}`];
    Object.entries(tempFormData).forEach(([key, value], index) => {
      newFormData[`option${index + 1}`] = value;
    });
    Object.entries(tempIsDefaultSelected).forEach(([key, value], index) => {
      newDefaultSelected[`option${index + 1}`] = value;
    });
    Object.keys(newFormData).forEach((option: any, index: any) => {
      data[`option${index + 1}`] = {
        value: newFormData[option],
        isSelected: newDefaultSelected[option] ? newDefaultSelected[option] : false
      };
    });

    setOptionValue(newFormData);
    setFormData({ ...formData, answerOptions: data });
    setIsDefaultSelected(newDefaultSelected);
  };

  useOutsideClick(
    dropdownRef,
    () => {
      let tempIsOpen: any = {};
      Object.keys(isOpen).forEach((option: any) => {
        if (isOpen[option]) {
          setIsOpen({ ...isOpen, [option]: !isOpen[option] });
        }
      });

      // setIsOpen(tempIsOpen);
    },
    false
  );
  useEffect(() => {
    if (!params.id && !editFromSurveyTemplate) {
      setFormData({
        ...formData,
        answerValues: true,
        extension: { displayType: 'multi' },
        answerOptions: {
          option1: { value: '', isSelected: false },
          option2: { value: '', isSelected: false }
        }
      });
    } else {
      setFormData({
        ...formData,
        extension: { displayType: 'multi' },
        answerValues: true
      });
    }
  }, []);

  useEffect(() => {
    if ((editMode && params.id) || editFromSurveyTemplate) {
      if (formData && formData.answerOptions) {
        let tempData: any = {};
        let tempData2: any = {};
        Object.keys(formData?.answerOptions).forEach((options: any, index: any) => {
          tempData = { ...tempData, [`option${index + 1}`]: formData.answerOptions[options].value };
          tempData2 = {
            ...tempData2,
            [`option${index + 1}`]: formData.answerOptions[options].isSelected
          };
        });
        setOptionValue(tempData);
        setIsDefaultSelected(tempData2);
      }
    }
  }, [formData]);

  const removeDefaultSelect = (index: number) => {
    if (isDefaultSelected[`option${index + 1}`]) {
      setIsDefaultSelected({
        ...isDefaultSelected,
        [`option${index + 1}`]: false
      });
      setFormData({
        ...formData,
        answerOptions: {
          ...formData?.answerOptions,
          [`option${index + 1}`]: {
            ...formData?.answerOptions?.[`option${index + 1}`],
            isSelected: false
          }
        }
      });
    }
  };

  const showEditDropdown = () => {
    // Show edit options if the edit from survey template or the options are more than 2 while creating question.
    return (
      !editMode &&
      (editFromSurveyTemplate || (!editFromSurveyTemplate && Object.keys(optionValue).length > 2))
    );
  };

  const updateFormDataOptions = (answerOptions: any, defaultOptions: any) => {
    let formClone = cloneDeep(formData);
    let answersTemp: any = {};
    Object.keys(formData?.answerOptions).forEach((options: any, index: any) => {
      formClone[options] = answerOptions?.[options];
      answersTemp = {
        ...answersTemp,
        [options]: {
          value: answerOptions?.[options] || formData?.answerOptions?.[options]?.value,
          isSelected: defaultOptions?.[options] || false
        }
      };
    });

    formClone.answerOptions = answersTemp;
    setFormData(formClone);
  };

  return (
    <>
      {Object.keys(optionValue).map((option: any, index: any) => {
        return (
          <>
            <div
              key={`key-${index}`}
              className={`flex w-full flex-row ${
                isDefaultSelected[`option${index + 1}`] ? '' : 'mb-5'
              }`}
              draggable
              onDragStart={(e) => handleDragStart(e, index + 1, todoItemDrag)}
              onDragEnter={(e) => handleDragEnter(e, index + 1, todoItemDragOver)}
              onDragEnd={(e) => {
                let data = handleDragEnd(
                  e,
                  index + 1,
                  todoItemDrag,
                  todoItemDragOver,
                  optionValue,
                  isDefaultSelected
                );
                const { options, defaultOptions } = data;
                updateFormDataOptions(options, defaultOptions);
                setOptionValue(options);
                setIsDefaultSelected(defaultOptions);
              }}
            >
              {editMode ? (
                ''
              ) : (
                <div className="self-center" style={{ cursor: 'grab' }}>
                  {dragIcon}
                </div>
              )}
              {editFromSurveyTemplate && (
                <div className="self-center">
                  <Checkbox
                    className="cursor-default"
                    checked={isDefaultSelected[`option${index + 1}`]}
                    onChange={() => {
                      // removeDefaultSelect(index);
                      if (!editMode) {
                        setIsDefaultSelected({
                          ...isDefaultSelected,
                          [`option${index + 1}`]: !isDefaultSelected?.[`option${index + 1}`]
                        });
                        setFormData({
                          ...formData,
                          answerOptions: {
                            ...formData?.answerOptions,
                            [`option${index + 1}`]: {
                              ...formData?.answerOptions?.[`option${index + 1}`],
                              isSelected:
                                !formData?.answerOptions?.[`option${index + 1}`]?.isSelected
                            }
                          }
                        });
                      }
                    }}
                  />
                </div>
              )}

              <InputFieldContaner className="ml-2 w-full">
                <InputField
                  type="text"
                  id={`single-select-option-${index}`}
                  label=""
                  placeholder={`Option ${index + 1}`}
                  fieldKey={`option${index + 1}`}
                  required={false}
                  formData={optionValue}
                  setFormData={setOptionValue}
                  formError={formError}
                  handleChange={(e: any) => {
                    setFormData({
                      ...formData,
                      [`option${index + 1}`]: e.target.value,
                      answerOptions: {
                        ...formData?.answerOptions,
                        [`option${index + 1}`]: {
                          ...formData?.answerOptions?.[`option${index + 1}`],
                          value: e.target.value,
                          isSelected: false
                        }
                      }
                    });
                    setOptionValue({ ...optionValue, [`option${index + 1}`]: e.target.value });
                  }}
                  disabled={editMode}
                />
              </InputFieldContaner>
              {showEditDropdown() && (
                <DropDownContainer className="ml-3 cursor-pointer self-center">
                  <div
                    className="ml-3 cursor-pointer self-center"
                    onClick={() =>
                      setIsOpen({
                        ...isOpen,
                        [`option${index + 1}`]: !isOpen[`option${index + 1}`]
                      })
                    }
                    ref={dropdownRef}
                  >
                    {isOpen[`option${index + 1}`] ? multiDropdownUp : multiDropdown}
                  </div>
                  {isOpen[`option${index + 1}`] && (
                    <div>
                      <StyledDropdownList maxHeight={true}>
                        {editFromSurveyTemplate && (
                          <>
                            {!isDefaultSelected[`option${index + 1}`] ? (
                              <li
                                onClick={() => {
                                  setIsOpen({ ...isOpen, [`option${index + 1}`]: false });
                                  setIsDefaultSelected({
                                    ...isDefaultSelected,
                                    [`option${index + 1}`]: true
                                  });
                                  setFormData({
                                    ...formData,
                                    answerOptions: {
                                      ...formData?.answerOptions,
                                      [`option${index + 1}`]: {
                                        ...formData?.answerOptions?.[`option${index + 1}`],
                                        isSelected: true
                                      }
                                    }
                                  });
                                }}
                              >
                                {t('T_DEFAULT_OPTION_SELECTED')}
                              </li>
                            ) : isDefaultSelected[`option${index + 1}`] ? (
                              <li
                                onClick={() => {
                                  removeDefaultSelect(index);
                                }}
                              >
                                {t('T_REMOVE_DEFAULT_SELECTED_MSG2')}
                              </li>
                            ) : (
                              ''
                            )}
                          </>
                        )}

                        {Object.keys(optionValue).length > 2 && (
                          <li
                            onClick={() => {
                              setIsOpen({ ...isOpen, [`option${index + 1}`]: false });
                              handleRemoveField(index);
                            }}
                            style={{ color: 'red' }}
                          >
                            {t('T_REMOVE_OPTION')}
                          </li>
                        )}
                      </StyledDropdownList>
                    </div>
                  )}
                </DropDownContainer>
              )}
            </div>
            {isDefaultSelected[`option${index + 1}`] && !editMode && (
              <div className="mb-3 flex w-full items-center pl-12">
                {t('T_REMOVE_DEFAULT_SELECTED_MSG1')}
              </div>
            )}
          </>
        );
      })}
      {!editMode ? (
        <AddOptionButton className="flex items-center text-primary" onClick={handleAddField}>
          <div className="mr-2 self-center text-primary">{plusSign} </div> {t('T_ADD_OPTION')}
        </AddOptionButton>
      ) : (
        ''
      )}
    </>
  );
};

const AddOptionButton = styled.div`
  cursor: pointer;
`;

const InputFieldContaner = styled.div`
  input {
    width: 100%;
  }
`;

export const RemoveDefaultButton = styled.span`
  color: 'blue';
  cursor: pointer !important;
  text-decoration: underline;
`;

export const DropDownContainer = styled.div`
  position: relative;
  overflow: visible;
`;

export const StyledDropdownList = styled(DropDownList)`
  width: 210px;
  padding: 10px;
  top: 30px;
  left: -150px;
`;

export default MultipleSelection;
