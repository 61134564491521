import { useAppSelector } from '../../app/hooks';
import { apiPermissionCheck } from '../../util/RoleUtil';
import NoPermissionMessage from './NoPermissionMessage';

interface ProtectedRouteProps {
  children: any;
  apiPermissions: string[];
}
const ProtectRoute = ({ children, apiPermissions = [] }: ProtectedRouteProps) => {
  const roleAPIPermisions: any =
    useAppSelector((state) => state.initialLoadData?.apiPermissions) || {};
  return apiPermissionCheck(roleAPIPermisions, apiPermissions) ? children : <NoPermissionMessage />;
};

export default ProtectRoute;
