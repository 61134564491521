import { css } from 'styled-components/macro';
import { ActiveProp, TransitionProp } from '../components/Onboarding/interfaces';

export const uTransitionTranslateX = css<ActiveProp>`
  transition: all ease-in 0.5s;
  transform: translateX(${(p) => (p.isActive ? '0' : '20%')});
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  visibility: ${(p) => (p.isActive ? 'visible' : 'hidden')};
`;

export const uTransitionTranslateY = css<TransitionProp>`
  transition: all ease-out ${(p) => (p.transitionTime ? '0.' + p.transitionTime + 's' : '0.3s')};
  transform: translateY(${(p) => (p.isActive ? '0' : '-20%')});
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  visibility: ${(p) => (p.isActive ? 'visible' : 'hidden')};
`;

export const uTransitionTranslateYModal = css<TransitionProp>`
  transition: all ease-out ${(p) => (p.transitionTime ? '0.' + p.transitionTime + 's' : '0.3s')};
  transform: translate(-50%, ${(p) => (p.isActive ? '0' : '-20%')});
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  visibility: ${(p) => (p.isActive ? 'visible' : 'hidden')};
`;

export const uTransitionVisiblityOnly = css<ActiveProp>`
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  visibility: ${(p) => (p.isActive ? 'visible' : 'hidden')};
`;
