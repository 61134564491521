import React, { useState } from 'react';
import { exclamationTriangle } from '../../../assets/components/svgs';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import Modal from '../../shared/ModalPortal/Modal';
import { useTranslation } from 'react-i18next';

interface DeleteQuestionModalProps {
  deleteQuestion: Function;
}

const DeleteQuestionModal = ({ deleteQuestion }: DeleteQuestionModalProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <BiButton
        className={'flex items-center justify-center border border-[#D32029] text-[#D32029]'}
        type="button"
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {t('T_SURVEY_QUESTION_DELETE_BUTTON')}
      </BiButton>
      <Modal
        showHeader={true}
        showCloseIcon={true}
        isVisible={openModal}
        headerText={'Delete question'}
        width={'30rem'}
        toggleVisiblity={() => {
          setOpenModal(false);
        }}
        headerLeftIcon={exclamationTriangle}
        body={
          <div>
            <div className="mb-9">{t('T_SURVEY_QUESTION_DELETE_MODAL_BODY')}</div>
            <div className="flex items-end justify-end">
              <BiButton
                className={'mr-2 text-primary'}
                type="button"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                {t('T_SURVEY_QUESTION_MODAL_CANCEL_BUTTON')}
              </BiButton>
              <BiButton
                className={' border border-[#D32029] text-[#D32029]'}
                type="button"
                onClick={() => {
                  deleteQuestion();
                  setOpenModal(false);
                }}
              >
                {t('T_SURVEY_QUESTION_DELETE_BUTTON_LABEL')}
              </BiButton>
            </div>
          </div>
        }
      />
    </>
  );
};

export default DeleteQuestionModal;
