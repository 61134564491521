// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TabDrawer from '../Reusable/TabDrawer';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setURLPaths } from '../../app/organizationReducer';
import JSONEditor from './JSONEditor';
import { useTranslation } from 'react-i18next';

/**
 * @parentId app-settings
 * @manager Create, View, Edit - App Settings
 * @overview Create, View, Edit - App Settings
 *   <section>
 *       <p>
 *          App Settings page is the default page the user is navigated on clicking <b>App Settings</b> navigation bar menuitem. Page will show all the setting(s) in a plan json format in Text editor.
 *       </p>
 *       <br/><br/>
 *       <b>Edit and Create App Setting(s)</b><br/>
 *          Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>JSON string</b> in JSON editor. Clicking on <b>Cancel</b> will disable edit mode and discard all new changes.
 *          In JSON string if setting object contains an <b>id</b> then that setting will be updated and If setting object does not contains id then it will create new setting.
 *       <br/><br/>
 *          Once we are in edit mode we will see two more action button on toolbar <b>Format JSON</b> and <b>Import JSON</b>. Clicking on Format JSON button will format string into valid JSON format.
 *          and clicking on Import JSON button will allow user to import an JSON file content into JSON editor.
 *       <br/><br/>
 *
 *       <p>
 *          <h4>View/Create/Edit App Setting Fields</h4>
 *          Create/View/Edit App Setting page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Settings JSON</td>
 *               <td>Yes</td>
 *               <td>JSON String</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> While creating or Editing an App settings all settings should be in Array and text should be a valid JSON string.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create App Setting Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create App Setting page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>solution.application.settings</td>
 *               <td>json</td>
 *               <td>N/A</td>
 *               <td>N/A</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit App Setting.</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Configuration Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating App Setting page</p>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>List System Setting</td>
 *               <td>/system-settings</td>
 *               <td>GET</td>
 *               <td>configuration-service.setting.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Get System Setting</td>
 *               <td>/system-settings/{id}</td>
 *               <td>GET</td>
 *               <td>configuration-service.setting.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Update System Setting</td>
 *               <td>/system-settings/batch</td>
 *               <td>PUT</td>
 *               <td>configuration-service.setting.update.batch</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create System Setting</td>
 *               <td>/system-settings/batch</td>
 *               <td>POST</td>
 *               <td>configuration-service.setting.create.batch</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create App Setting</p>
 *   </section>
 */

const ApplicationSettings = ({
  jsonString,
  setJsonString,
  isEdit,
  isComponent = false,
  DMSsetting = false
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(`${t('T_LBL_FOR_SETTINGS_JSON')}`);
  const [jsonStr, setJsonStr] = useState('');

  const storedURLPaths = useAppSelector((state) => state.organization?.urlPaths);

  useEffect(() => {
    if (!isComponent && storedURLPaths) {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: `/app-settings`,
            label: `${t('T_APP_SETTINGS')}`
          }
        ])
      );
    }
  }, []);

  const tabDataData = [
    {
      label: t('T_LBL_FOR_SETTINGS_JSON'),
      component: (
        <div className="ml-3">
          <JSONEditor
            jsonString={DMSsetting ? jsonStr : jsonString}
            setJsonString={DMSsetting ? setJsonStr : setJsonString}
            setIsLoading={setIsLoading}
            isComponent={isComponent}
            disabled={!isEdit}
            DMSsetting={DMSsetting}
          />
        </div>
      )
    }
  ];

  return (
    <div className="w-full">
      <LoaderContainer isLoading={isLoading}>
        <div
          className="mx-4 mt-4 mb-4"
          style={{ border: '1px solid #D9D9D9', borderRadius: '7px', height: '95%' }}
        >
          <TabDrawer
            borderBottom={true}
            tabConfig={tabDataData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </LoaderContainer>
    </div>
  );
};

export default ApplicationSettings;
