import React from 'react';
import { BiIconCheck } from '../../assets/components/svgs';
import {useTheme} from "../../context/themeContext";
import {useTranslation} from "react-i18next";

interface Props {
  children: React.ReactNode;
  position?: number;
  setPosition: Function;
}

const
    RegisterCard = ({ children, position, setPosition }: Props) => {
      const {t} = useTranslation();
  return (
    <div className="flex h-screen flex-col items-center justify-between overflow-hidden" style={useTheme().theme.bgPrimaryStyleColor}>
      <div
        className="z-10 mx-auto flex h-full w-full items-center justify-start transition-transform duration-700"
      >
        <div className={`flex h-[750px] min-w-full justify-center`}>
          <div
            className={`${
              position === -100 ? 'relative h-full overflow-hidden' : ''
            } mt-4 flex w-[842px] flex-col rounded-lg bg-white`}
          >
            {/* Top of card - Text + Steps */}
            <div className="mb-[30px] flex h-[177px] flex-col items-center">
              <>
                <h1 className="mt-[40px] mb-[30px] text-2xl font-semibold text-black">
                  {t('T_CREATE_YOUR_ACCOUNT')}
                </h1>
                <section className="flex flex-col">
                  {position === 0 ? (
                    <div className="flex items-center">
                      <span className="flex h-[24px] w-[24px] items-center justify-center rounded-full border-[1.5px] border-solid border-black text-xs">
                        1
                      </span>
                      <div className="mx-[10px] h-[4px] w-[130px] rounded bg-gray-200"></div>
                      <span className="text-bi-gray-800 flex h-[24px] w-[24px] items-center justify-center rounded-full bg-gray-200 text-xs font-light">
                        2
                      </span>
                    </div>
                  ) : (
                    <div
                      className="flex cursor-pointer items-center"
                      onClick={() => setPosition((state: any) => 0)}
                    >
                      <span className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-[#28b980] text-xs text-white">
                        {BiIconCheck}
                      </span>
                      <div className="mx-[10px] h-[4px] w-[130px] rounded bg-[#28b980]"></div>
                      <span className="flex h-[24px] w-[24px] items-center justify-center rounded-full border-[1.5px] border-solid border-black text-xs">
                        2
                      </span>
                    </div>
                  )}
                  <div className="flex items-center">
                    <span className="relative right-[20px] mt-[10px] text-xs font-semibold">
                      {t('T_CUSTOMER_STEPPER_DESCRIPTION')}
                    </span>
                    <span className="text-bi-gray-800 relative left-[70px] mt-[10px] text-xs font-light">
                      {t('T_CUSTOMER_STEPPER_CODE_PLUS_PASSWORD')}
                    </span>
                  </div>
                </section>
              </>
            </div>
            <div
              className="delay-0 transition-transform transition-all duration-700"
              style={{ transform: `translateX(${position}%)` }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterCard;
