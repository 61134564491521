import { AppConstants } from '../../constants/AppConstants';
import { listRoles } from '../../http/access-management-service';
import { createRole } from '../../http/authentication-services';
import { createSignedURL, uploadFile } from '../../http/asset-management-services';
import {
  createOrganizationSetting,
  createSystemSetting,
  getCategories,
  getOrganizationSettings,
  getSettingById,
  getSystemSettings
} from '../../http/configuration-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getOrganization } from '../../http/organization-services';
import { createPasswordPolicy, getPasswordPolicy } from '../../http/user-management-services';
import { formatPhoneNumber, getCountryCodeAndNumber } from '../../util/functions';
import {
  Category,
  getApplicationCategory,
  normalizeKey,
  Role,
  SystemSetting,
  updateApplicationContextKey,
  updatePermissionSet
} from '../../util/RoleUtil';
import { switchSession } from '../../util/admin-utils';
import { getLoginUserRoles } from '../../util/admin-utils';
import { getRole } from '../../http/access-management-service';

////////// Method for Uploading Contracts //////////
type IuploadFiles = {
  error?: string;
  isValid: boolean;
  filename?: any;
  message?: any;
  pluralContext?: boolean;
};
export const uploadFiles = async (
  files: File[],
  bucketName: string,
  documentType: string,
  profile: any,
  t?: any,
  filePath?: string
): Promise<IuploadFiles> => {
  ////Fixed Wrong Promise Integration

  const uploadFileSuccessfully: any = [];
  const uploadFileFailure: any = [];
  if (files.length) {
    const fileUploadPromise = async (file: any, profile: any, bucketName: string) => {
      const metadata = {
        ...profile,
        isdeleted: false
      };
      const { name, type } = file;
      const body = {
        filePath: filePath || `${AppConstants.DEFAULT_CONTRACTS_FOLDER_PATH}${name}`,
        expirationTime: AppConstants.GCS_MAX_EXPIRATION_TIME,
        action: AppConstants.GCS_UPLOAD_ACTION,
        versioning: true,
        bucketName,
        metadata
      };
      const response = await createSignedURL(body);
      const { status, data } = response;
      if (data) {
        const { url, headers = {}, issues = [] } = data;

        if (status == HTTP_STATUS.HTTP_CREATED) {
          const payload = {
            url,
            body: file,
            headers: { ...headers, [AppConstants.HEADER_CONTENT_TYPE]: type }
          };
          await uploadFile(payload);
          uploadFileSuccessfully.push(name);
          return {
            isValid: true,
            pluralContext: uploadFileSuccessfully?.length > 1,
            filename: name
          };
        } else if (status == HTTP_STATUS.HTTP_BAD_REQUEST) {
          uploadFileFailure.push(name);
          if (issues[0].code === 'AMS_PARAM_DUPLICATE_BUCKET') {
            return {
              error: `${t('T_VALIDATION_DUPLICATE_BUCKET_MSG1')}( ${name} ) ${t(
                'T_VALIDATION_DUPLICATE_BUCKET_MSG2'
              )} `,
              isValid: false,
              fileName: name
            };
          } else if (issues[0].code === 'AMS_PARAM_INVALID') {
            // uploadFileUnSuccessfully.push(name)
            return {
              error: `${t('T_VALIDATION_DUPLICATE_BUCKET_MSG1')} ( ${name} ) ${t(
                'T_VALIDATION_DUPLICATE_BUCKET_MSG3'
              )}`,
              isValid: false,
              fileName: name
            };
          } else {
            return {
              error: `${t('T_VALIDATION_DUPLICATE_BUCKET_MSG4')} ( ${name}  )`,
              isValid: false,
              fileName: name
            };
          }
        }
      }
    };

    const forEachSeries = async (files: any) => {
      for (const file of files) {
        await fileUploadPromise(file, profile, bucketName);
      }
    };

    await forEachSeries(files);
    // await Promise.all(filePromise);
    return {
      isValid: true,
      pluralContext: uploadFileSuccessfully?.length > 1,
      filename: { uploadFileSuccessfully, uploadFileFailure }
    };
  } else {
    return {
      error: `${t('T_NO_FILE_CHOSEN')}`,
      isValid: false,
      filename: {}
    };
  }
};

// Method for getting organization Data//

export const getOrganizationDetails = async (id: string, t?: any) => {
  const response = await getOrganization(id);
  let tempData = {
    name: '',
    phone: '',
    fax: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    type: '',
    countryCode: '',
    faxCountryCode: ''
  };
  const { status, data } = response;
  if (status === HTTP_STATUS.HTTP_OK) {
    const phoneNo = getCountryCodeAndNumber(response.data.telecom?.[0]?.value || '');
    const faxNo = getCountryCodeAndNumber(response.data.telecom?.[1]?.value || '');

    (tempData['name'] = response.data.name ? response.data.name : ''),
      (tempData['phone'] = phoneNo.formattedNumber),
      (tempData['countryCode'] = phoneNo.countryCode),
      (tempData['fax'] = faxNo.formattedNumber),
      (tempData['faxCountryCode'] = faxNo.countryCode),
      (tempData['address'] = response.data.address?.[0]?.line
        ? response.data.address?.[0]?.line.toString()
        : response.data.address?.[0]?.text
        ? response.data.address?.[0]?.text
        : ''),
      (tempData['city'] = response.data.address?.[0]?.city ? response.data.address?.[0]?.city : ''),
      (tempData['zip'] = response.data.address?.[0]?.postalCode
        ? response.data.address?.[0]?.postalCode
        : ''),
      (tempData['state'] = response.data.address?.[0]?.state
        ? response.data.address?.[0]?.state
        : ''),
      (tempData['country'] = response.data.address?.[0]?.country
        ? response.data.address?.[0]?.country
        : '');
    tempData['type'] = response.data.type?.[0]?.text
      ? response.data.type?.[0]?.text === 'Health' ||
        response.data.type?.[0]?.text === 'HealthCare' ||
        response.data.type?.[0]?.text === 'HealthSystem'
        ? t('T_HEALTH_SYSTEM')
        : response.data.type?.[0]?.text
      : '';
    return { tempData, response };
  } else {
    return { tempData, response };
  }
};

export const noDataAvailableMessage = (
  setMessage: any,
  setSize: any,
  setPagingData: any,
  pagingData: any,
  t?: any,
  msg?: any
) => {
  //setSize(0);
  setPagingData(pagingData);
  setMessage(msg || `${t('T_NO_DATA_AVAILABLE_MSG')}`);
};

export const RolePermission = async (permissionName: any) => {
  let loginRole = getLoginUserRoles();
  let rolePermissions = [];
  const response = await getRole(loginRole[0]?.roleId);
  if (response.status === HTTP_STATUS.HTTP_OK) {
    rolePermissions = response.data.permissions;
  }
  let createPermission = rolePermissions?.filter(
    (ele: any) => ele.apiPermission === permissionName
  );
  return createPermission?.length ? true : false;
};

export const cloneDataConsent = async (
  orgApplicationConfig: any,
  role: Role,
  oldRoleName: any,
  categories: Array<string> = [],
  rolesName: Array<string> = [],
  orgName: string
): Promise<SystemSetting | null> => {
  let consentResponse = null;
  let oldDataConsentKey = `${AppConstants.APPLICATION_NAME}.${
    AppConstants.APPLICATION_DATA_CONSENT_TEMPLATES_KEY
  }.${oldRoleName.toLowerCase()}`;

  let oldConsentTemplate = orgApplicationConfig.filter(
    (item: any) => item.key === oldDataConsentKey
  );

  const { name: rolename } = role;

  let newDataConsentKey = `${AppConstants.APPLICATION_NAME}.${
    AppConstants.APPLICATION_DATA_CONSENT_TEMPLATES_KEY
  }.${rolename.toLowerCase()}`;

  let consentPayload = {
    ...oldConsentTemplate[0],
    key: newDataConsentKey,
    categories
  };
  delete consentPayload.id;
  consentPayload.value.roleName = rolename;

  rolesName.forEach((item: string) => {
    let index = consentPayload?.value?.grantAccessTo?.findIndex(
      (ele: any) => ele.role.split('__')[0] === item?.split('__')[0]
    );
    if (index !== -1) {
      consentPayload.value.grantAccessTo[index].role = `${
        consentPayload.value.grantAccessTo[index]?.role?.split('__')[0]
      }__${orgName}`;
    }
  });

  consentResponse = await createSystemSetting(consentPayload);

  const { status, data } = consentResponse;
  if (status == HTTP_STATUS.HTTP_CREATED) {
    consentResponse = data;
  }

  return consentResponse;
};

export const cloneLegalDocument = async (
  orgApplicationConfig: any,
  role: Role,
  oldRoleName: any,
  categories: Array<string> = []
): Promise<SystemSetting | null> => {
  let legalDocResponse = null;
  let oldLegalDocKey = `${AppConstants.APPLICATION_NAME}.${
    AppConstants.LEGAL_DOCUMENT_KEY
  }.${oldRoleName.toLowerCase()}`;

  let oldLegalDocTemplate = orgApplicationConfig.filter((item: any) => item.key === oldLegalDocKey);

  const { name: rolename } = role;

  let newDataConsentKey = `${AppConstants.APPLICATION_NAME}.${
    AppConstants.LEGAL_DOCUMENT_KEY
  }.${rolename.toLowerCase()}`;

  let legalDocPayload = {
    ...oldLegalDocTemplate[0],
    key: newDataConsentKey,
    categories
  };
  delete legalDocPayload.id;
  legalDocPayload.value.roleName = rolename;

  legalDocResponse = await createSystemSetting(legalDocPayload);

  const { status, data } = legalDocResponse;
  if (status == HTTP_STATUS.HTTP_CREATED) {
    legalDocResponse = data;
  }

  return legalDocResponse;
};

const formatInvitationPolicyNames = (
  solutionRoles: any,
  invitationPolicys: any,
  orgName: string,
  roles: any = []
) => {
  // let changedRoles = solutionRoles.value.roleTypes[1].basicRoles.map(
  //   (item: any) => item.name.split('__')[0]
  // );
  let currentPolicy = invitationPolicys[0];

  Object.keys(currentPolicy.value).forEach((key: any) => {
    currentPolicy.value[key].forEach((element: any, index: number) => {
      if (roles.includes(element)) {
        currentPolicy.value[key][index] = `${element.split('__')[0]}__${orgName}`;
      }
      // else {
      //   currentPolicy.value[key][index] = `${element.split('__')[0]}`;
      // }
    });
    if (roles.includes(key)) {
      let updatedKey = `${key.split('__')[0]}__${orgName}`;
      currentPolicy.value[updatedKey] = currentPolicy.value[key];
      delete currentPolicy.value[key];
    }
    // else {
    //   let updatedKey = key.split('__')[0];
    //   if (!currentPolicy.value[updatedKey]) {
    //     currentPolicy.value[updatedKey] = currentPolicy.value[key];
    //     delete currentPolicy.value[key];
    //   }
    // }
  });
  return currentPolicy;
};

export const updateUserInvitationPolicy = async (
  solutionRoles: any,
  org: any,
  newOrg: any,
  roles: any
) => {
  const response = await getOrganizationSettings(
    org.id,
    AppConstants.CATEGORY_USER_MGMT_INVITATION_POLICY,
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );
  const { status, data } = response;
  const { data: invitationPolicys = [] } = data;
  if (status == HTTP_STATUS.HTTP_OK && invitationPolicys.length) {
    let payload = formatInvitationPolicyNames(solutionRoles, invitationPolicys, newOrg.name, roles);
    await createOrganizationSetting(newOrg.id, payload);
  } else {
    return;
  }
};

export const orgInvitationPolicy = async (
  parentOrg: any,
  org: any,
  roles: Array<string> = ['']
) => {
  let orgInvitationSettings = await getOrganizationSettings(
    parentOrg.id,
    AppConstants.CATEGORY_SOLUTION_CONFIG
  );

  let filtered = orgInvitationSettings.data.data.filter(
    (data: any) => data.key === AppConstants.SOLUTION_ORG_INVITATION_POLICY_KEY
  );

  let payload = {
    ...filtered[0]
  };

  Object.keys(payload.value).forEach((key: any) => {
    payload.value[key].forEach((element: any, index: number) => {
      if (roles.includes(element?.role)) {
        payload.value[key][index].role = `${element?.role.split('__')[0]}__${org.name}`;
      }
    });
  });

  let response = await createOrganizationSetting(org.id, payload);
};

export const updateSolutionRoles = async (solutionRoles: any, org: any) => {
  let payload = { ...solutionRoles };

  payload.value.roleTypes[1].basicRoles.map(
    (item: any) => (item.name = `${item.name.split('__')[0]}__${org.name}`)
  );

  await createOrganizationSetting(org.id, payload);
};

export const getAssociatedRoles = async (
  orgAppSystemSettings: any,
  type: string,
  parentOrg: any
) => {
  let filteredRoles = orgAppSystemSettings.data.filter(
    (obj: any) => obj.key === AppConstants.SOLUTION_ROLES
  );

  const roles = filteredRoles[0]?.value?.roleTypes[1]?.basicRoles
    .filter((item: any) => item.level.filter((ele: any) => ele?.home?.toLowerCase() == type).length)
    .map((obj: any) => obj?.name);

  return { rolesList: Array.from(new Set<string>(roles)), solutionRoles: filteredRoles };
};

export const cloneApplicationRole = async (
  mappedRoleId: string,
  role: Role,
  categories: Array<string> = []
) => {
  let applicationRole = null;
  let mappedRoleResponse = await getSettingById(mappedRoleId);
  let privileges: Array<any> = mappedRoleResponse?.data?.value?.privileges || [];
  const { name: rolename } = role;

  const {
    description = ' ',
    dataType = AppConstants.DATA_TYPE_JSON,
    allowedValues = [],
    allowOverride = true
  } = mappedRoleResponse?.data;

  const key = `${AppConstants.SOLUTION_FEATURE_KEY}.${
    AppConstants.SOLUTION_ASSIGNMENT
  }.${rolename.toLowerCase()}`;

  const payload = {
    key,
    value: { rolename, privileges },
    description,
    dataType,
    allowOverride,
    allowedValues,
    categories
  };

  const response = await createSystemSetting(payload);
  const { status, data } = response;
  if (status == HTTP_STATUS.HTTP_CREATED) {
    applicationRole = data;
  }
  return applicationRole;
};

export const cloneRoleApi = async (
  rolePayload: any,
  mappedId: string,
  permissionSetResponse: any,
  oldRoleName: string,
  orgApplicationConfig: any,
  categories: Array<string> = [],
  rolesName: Array<string> = [],
  orgResponse: any,
  listCategories: any
) => {
  let roleResponse = await createRole(rolePayload);
  const filteredCategories = await getApplicationCategory(
    [AppConstants.CATEGORY_APPLICATION_MODEL],
    listCategories
  );

  if ([HTTP_STATUS.HTTP_CREATED].includes(roleResponse.status)) {
    let updatedRolePayload = {
      ...rolePayload,
      id: roleResponse.data.id,
      permissions: roleResponse.data.permissions
    };

    let appRole = await cloneApplicationRole(
      mappedId,
      updatedRolePayload,
      filteredCategories.map((category: Category) => category.id as string)
    );

    let permissionUpdateSettings = [];
    //Data Consent and Legal Documents
    let dataConsentContextPayload = null;
    let dataConsentPermissionSetPayload = null;
    let dataConsentResponse = await cloneDataConsent(
      orgApplicationConfig,
      updatedRolePayload,
      oldRoleName,
      categories,
      rolesName,
      orgResponse.name
    );

    let legalDocContextPayload = null;
    let legalDocPermissionSetPayload = null;
    let legalDocResponse = await cloneLegalDocument(
      orgApplicationConfig,
      updatedRolePayload,
      oldRoleName,
      categories
    );

    if (dataConsentResponse) {
      dataConsentContextPayload = {
        key: dataConsentResponse.key,
        element: AppConstants.APPLICATION_DATA_CONSENT_TEMPLATES_ELEMENT,
        element_type: AppConstants.APPLICATION_DATA_CONSENT_TEMPLATES_ELEMENT_TYPE
      };
      dataConsentPermissionSetPayload = {
        id: dataConsentResponse.id,
        key: dataConsentResponse.key
      };
      permissionUpdateSettings.push(dataConsentPermissionSetPayload);
    }

    if (legalDocResponse) {
      legalDocContextPayload = {
        key: legalDocResponse.key,
        element: AppConstants.LEGAL_DOCUMENT_ELEMENT,
        element_type: AppConstants.LEGAL_DOCUMENT_ELEMENT_TYPE
      };
      legalDocPermissionSetPayload = {
        id: legalDocResponse.id,
        key: legalDocResponse.key
      };
      permissionUpdateSettings.push(legalDocPermissionSetPayload);
    }

    if (appRole) {
      const updateSetting = await updateApplicationContextKey(
        roleResponse.data,
        AppConstants.CATEGORY_APPLICATION_SYSTEM,
        AppConstants.SOLUTION_APPLICATION_CONTEXT_KEY
        // dataConsentContextPayload,
        // legalDocContextPayload
      );

      const updatedPermSet = await updatePermissionSet(
        permissionSetResponse.data,
        appRole as SystemSetting,
        roleResponse.data,
        permissionUpdateSettings
      );
    }
  }
};

export const cloneRolesPermission = async (
  orgResponse: any,
  parentOrg: any,
  orgAppSystemSettings: any,
  callback: Function
) => {
  if (!parentOrg && !parentOrg?.id) {
    return;
  }
  let associatedRoles = await getAssociatedRoles(
    orgAppSystemSettings,
    orgResponse?.type[0].text.toLowerCase(),
    parentOrg
  );
  let rolesName = associatedRoles?.rolesList;
  let solutionRoles = associatedRoles?.solutionRoles;

  if (!rolesName.length) {
    return;
  }

  let rolesData: any = {};
  let passwordPolicyData: any = {};
  let passwordPolicyNames: any = new Set<string>();
  let createdPolicies: any = [];
  const userData = JSON.parse(localStorage.getItem('user-profile') || '');

  let orgApplicationConfig = await getSystemSettings(
    AppConstants.CATEGORY_APPLICATION_CONFIG,
    AppConstants.DATA_TYPE_JSON,
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );

  let listCategories = await getCategories(
    '',
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );

  let categoryMap = new Map(listCategories.data.data.map((item: any) => [item.name, item]));
  let mappedId = categoryMap.get(AppConstants.CATEGORY_APPLICATION_CONFIG) as any;

  await Promise.all(rolesName.map((policy: any) => listRoles(policy, true, 1, 100))).then(
    async (responses) => {
      responses.forEach((response: any, index: number) => {
        if (response?.status == HTTP_STATUS.HTTP_OK) {
          let filteredResponse = response?.data.data.filter(
            (data: any) => data.name === rolesName[index]
          );
          if (filteredResponse.length) {
            rolesData[filteredResponse[0].id] = filteredResponse[0];
            passwordPolicyNames.add(filteredResponse[0].passwordPolicy);
          }
        }
      });
    }
  );

  passwordPolicyNames = Array.from(passwordPolicyNames);

  await Promise.all(passwordPolicyNames.map((policy: any) => getPasswordPolicy(policy, 0))).then(
    async (responses) => {
      responses.forEach((response: any) => {
        if (response?.status == HTTP_STATUS.HTTP_OK) {
          passwordPolicyData[response?.data?.data[0].name] = response?.data?.data[0];
        }
      });
    }
  );

  let permList = await getSystemSettings(
    AppConstants.CATEGORY_PERMISSION_SETS,
    AppConstants.DATA_TYPE_JSON,
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );

  const filteredPermission = permList.data.data.filter((permData: any) => {
    //Filter Permissions which have role in it
    return permData?.value?.roles?.length && permData?.value?.roles[0]?.roleId in rolesData;
  });

  for (const mapPermission of filteredPermission) {
    let oldRoleId = mapPermission.value.roles[0].roleId;
    let oldPolicyName = rolesData[oldRoleId].passwordPolicy;
    let oldRoleName = rolesData[oldRoleId].name.split('__')[0];
    let clonedRolePolicyName = `${oldRoleName}__${orgResponse.name}`;
    let oldPermissionName = mapPermission.value.name.split('__')[0];

    let createPermissionClonePayload = {
      key: normalizeKey(`${mapPermission?.key}__${orgResponse.name}`),
      dataType: AppConstants.DATA_TYPE_JSON,
      categories: [mapPermission?.categories[0]?.id],
      value: {
        name: `${oldPermissionName}__${orgResponse.name}`,
        organizationId: orgResponse.id,
        description: mapPermission?.value?.description || '',
        roles: [],
        permissions: mapPermission?.value?.permissions,
        createdBy: userData?.userId,
        createdByOrg: userData?.createdByOrg,
        updatedBy: userData?.userId,
        updatedOn: new Date().toISOString(),
        createdOn: new Date().toISOString()
      }
    };

    let permissionSetResponse = await createSystemSetting(createPermissionClonePayload);

    let passwordPolicyPayload = {
      ...passwordPolicyData[oldPolicyName],
      name: clonedRolePolicyName
    };

    let rolePayload: any = {
      ...rolesData[oldRoleId],
      name: clonedRolePolicyName,
      passwordPolicy: clonedRolePolicyName,
      apiPermissions: rolesData[oldRoleId]?.permissions?.map((item: any) => item.apiPermission)
    };

    delete passwordPolicyPayload.id;
    delete rolePayload.id;
    delete rolePayload.permissions;

    if ([HTTP_STATUS.HTTP_CREATED].includes(permissionSetResponse.status)) {
      if (!createdPolicies.includes(clonedRolePolicyName)) {
        let passwordPolicyResponse = await createPasswordPolicy(passwordPolicyPayload);

        if ([HTTP_STATUS.HTTP_CREATED].includes(passwordPolicyResponse.status)) {
          createdPolicies.push(clonedRolePolicyName);
        }
      }

      await cloneRoleApi(
        //Clone Roles and update application features
        rolePayload, //Payload to create the role
        mapPermission?.value?.roles[0]?.mapping?.id, //Mapped Id In Permission Set (Old)
        permissionSetResponse, //Response from Newly created Permission Set
        oldRoleName, //Name of the Old Role For Example: CMO
        orgApplicationConfig.data.data, //Response from categoru: Application_Config in System Settings (Used for dataConsent and Legal Docs)
        [mappedId.id], //Used to Send category field in payload
        rolesName, //Array of Roles to clone in string
        orgResponse, //Response of Newly created Org. Using for Name Field
        listCategories
      );
    }
  }
  const response1 = await switchSession(orgResponse.id);
  await updateSolutionRoles(solutionRoles[0], orgResponse);
  await updateUserInvitationPolicy(solutionRoles[0], parentOrg, orgResponse, rolesName);
  await orgInvitationPolicy(parentOrg, orgResponse, rolesName);
  await callback();
  const response2 = await switchSession(parentOrg.id);
};
