import { useState, useEffect, useCallback } from 'react';
import Modal from '../shared/ModalPortal/Modal';
import { uploadFiles } from './helpers';
import TabDrawer from '../Reusable/TabDrawer';
import { setToastData } from '../../app/toastReducer';
import { useAppDispatch } from '../../app/hooks';
import ListContracts from '../ManageContracts/ListContracts';
import UploadContracts from '../ManageContracts/UploadContracts';
import { listFiles, fileHistory } from '../../http/asset-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getFailureMessage } from '../../util/ErrorUtil';
import { getUsers } from '../../http/user-management-services';
import { AppConstants } from '../../constants/AppConstants';
import { useTranslation } from 'react-i18next';

interface UploadContractProps {
  isVisible: boolean;
  toggleVisiblity: Function;
  bucketName: string;
  setIsToastActive: Function;
  setToasterMessage: Function;
  setToasterType: Function;
}
interface Pagination {
  first: String;
  next: String;
  page: Number;
  previous: String;
  size: Number;
}

interface ResponseBody {
  data: Array<ContractFileInfo>;
  paging: Pagination;
}
interface ContractFileInfo {
  cacheControl: String;
  contentType: String;
  createTime: Date;
  currentVersion: boolean;
  generation: String;
  name: String;
  size: Number;
  type: String;
  updateTime: Date;
  url: String;
}

interface UploadContractProps {
  isVisible: boolean;
  toggleVisiblity: Function;
  bucketName: string;
  setIsToastActive: Function;
  setToasterMessage: Function;
  setToasterType: Function;
}
const TABS = {
  CONTRACTS: 'TABS_CONTRACTS',
  UPLOAD_CONTRACT: 'T_UPLOAD_NEW_CONTRACT'
};
function ManageContracts({ isVisible, toggleVisiblity, bucketName }: UploadContractProps) {
  const [files, setFiles] = useState<Array<File>>([]);
  const dispatch = useAppDispatch();
  const [contractsDocs, setContractsDocs] = useState<Array<File>>([]);
  const [loader, setLoader] = useState(false);
  const [callApiLoader, setCallAPiLoader] = useState(false);
  const [fileError, setFileError] = useState({ errorText: '', fileName: '' });
  const [profile, setProfile] = useState<any>({ name: '', email: '', userId: '' });
  const [rows, setRows] = useState<Array<any>>([]);
  const [cache, setCache] = useState<Array<any>>([]);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(`${t('T_UPLOAD_NEW_CONTRACT')}`);

  const setToastMsg = (msg: string, isActive: boolean, type: string) => {
    dispatch(setToastData({ toastMessage: msg, isToastActive: isActive, toastType: type }));
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const response = JSON.parse(localStorage.getItem('user-profile') || '{}') as any;
      setProfile({
        name: response?.firstName + ' ' + response?.lastName,
        email: response?.mail,
        userId: response?.userId
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    callAPI();
  }, []);

  const callAPI = async (progress: boolean = true) => {
    setCallAPiLoader(progress);
    let payload = {
      folderPath: 'contracts/',
      expirationTime: 30,
      generateSignedUrl: false,
      bucketName,
      metadata: {
        isdeleted: false
      }
    };
    let response;
    try {
      response = await listFiles(payload, { page: 1, size: 100 });
    } catch (error) {
      console.error({ error });
    }

    let { data, status }: any = response;

    if (status != HTTP_STATUS.HTTP_OK) {
      setCallAPiLoader(false);
      setToastMsg(getFailureMessage(response), true, 'error');
      toggleVisiblity('');
    }

    if (!data?.data?.length) {
      setActiveTab(`${t('T_UPLOAD_NEW_CONTRACT')}`);
      setCallAPiLoader(false);
      return;
    }

    if (status == HTTP_STATUS.HTTP_OK) {
      let { data: files = [] }: ResponseBody = data;
      let versionArray: any = [];

      const userIds = new Set(files.map((file: any) => file?.metadata?.userid));
      const USERS_FILTER: string = `[{"field":"userId","operation":"in","value":"${Array.from(
        userIds
      )}"}]`;
      const usersResponse = await getUsers(
        USERS_FILTER,
        AppConstants.DEFAULT_PAGE,
        AppConstants.MAXIMUM_PAGE_SIZE
      );
      if (usersResponse) {
        let usersMap: Map<string, any> = new Map();
        const { status: usersStatus, data: usersData } = usersResponse;

        if (usersStatus == HTTP_STATUS.HTTP_OK) {
          const { data: usersList = [] } = usersData;
          usersMap = new Map(usersList.map((user: any) => [user.userId, user]));
        }
        await Promise.all(
          files.map(async (file: any) => {
            const fileBody = {
              filePath: file.name,
              bucketName
            };
            const historyResponse = await fileHistory(fileBody);
            if (historyResponse) {
              let currentFileVersion = await historyResponse?.data?.data?.filter(
                (file: any) => file.currentVersion
              );
              const versionLength = historyResponse?.data?.data?.length;
              if (versionLength) {
                currentFileVersion = currentFileVersion && {
                  ...currentFileVersion[0],
                  versions: versionLength,
                  loading: false
                };
              }
              if (currentFileVersion) {
                const { metadata } = currentFileVersion;
                const { userid, name: currentName } = metadata;
                const userProfile: any = usersMap.get(userid);
                if (userProfile) {
                  const { firstName, lastName, name: names = [] } = userProfile;
                  const name =
                    firstName && lastName
                      ? `${firstName} ${lastName}`
                      : names.length
                      ? `${names[0].given} ${names[0].family}`
                      : currentName;
                  if (metadata) {
                    currentFileVersion = {
                      ...currentFileVersion,
                      metadata: { ...metadata, name }
                    };
                  }
                }
                versionArray = [...versionArray, currentFileVersion];
              }
            }
          })
        );
        setRows([...versionArray]);
        setCache([...versionArray]);
        setCallAPiLoader(false);
      }
    }
    setCallAPiLoader(false);
  };

  const uploadFilesApi = async () => {
    setLoader(true);
    setFileError({ errorText: '', fileName: '' });
    try {
      const response = await uploadFiles(contractsDocs, bucketName, 'Contract', profile, t);
      if (response.isValid) {
        setLoader(false);
        setActiveTab(`${t('T_CONTRACTS')}`);
        setToastMsg(`${t('T_CONTRACT_UPLOAD_SUCCESS_MSG')}`, true, 'success');
        setFiles([]);
        await callAPI();
      }
    } catch (error: any) {
      setActiveTab(`${t('T_CONTRACTS')}`);
      setToastMsg(`${[...error.fileName.uploadFileFailure]} ${t('T_NOT_UPLOADED')}`, true, 'error');
      setLoader(false);
      setFiles([]);
      await callAPI();
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
      const fileNames = files.map(({ name }: any) => name);
      setFiles((files: any) => [
        ...files,
        ...acceptedFiles.filter((file: File) => !fileNames.includes(file.name))
      ]);
      setContractsDocs(acceptedFiles);
    },
    [files]
  );

  const removeFileFromUploadList = (selectedIndex: number, fileName: string) => () => {
    if (fileName == fileError.fileName) {
      setFileError({ errorText: '', fileName: '' });
    }
    setFiles((files: any) => files.filter((value: any, index: any) => index !== selectedIndex));
    setContractsDocs((files: any) =>
      files.filter((value: any, index: any) => index !== selectedIndex)
    );
  };

  const tabConfig = [
    {
      label: `${t('T_CONTRACTS')}`,
      component: (
        <ListContracts
          key={'Contracts'}
          setToastMsg={setToastMsg}
          bucketName={bucketName}
          setActiveTab={setActiveTab}
          TABS={TABS}
          toggleVisiblity={toggleVisiblity}
          rows={rows}
          setRows={setRows}
          loader={callApiLoader}
          setLoader={setCallAPiLoader}
          cache={cache}
          setCache={setCache}
        />
      )
    },
    {
      label: `${t('T_UPLOAD_NEW_CONTRACT')}`,
      component: (
        <UploadContracts
          key={'T_UPLOAD_NEW_CONTRACT'}
          files={files.length ? [{ file: files[0] }] : []}
          fileError={fileError}
          loader={loader}
          onDrop={onDrop}
          removeFileFromUploadList={removeFileFromUploadList}
        />
      )
    }
  ];

  return (
    <div>
      <Modal
        isVisible={isVisible}
        headerText={t('T_MANAGE_CONTRACT')}
        showCloseIcon={true}
        toggleVisiblity={toggleVisiblity}
        width="60rem"
        body={
          <TabDrawer
            borderBottom={false}
            tabConfig={tabConfig}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        }
        secondaryOnClick={() => toggleVisiblity()}
        secondaryButtonText={activeTab === `${t('T_UPLOAD_NEW_CONTRACT')}` ? 'Cancel' : ''}
        secondaryButtonWidth={activeTab === `${t('T_UPLOAD_NEW_CONTRACT')}` ? '100px' : '0px'}
        primaryButtonText={activeTab === `${t('T_UPLOAD_NEW_CONTRACT')}` ? 'Upload' : ''}
        primaryButtonWidth={activeTab === `${t('T_UPLOAD_NEW_CONTRACT')}` ? '100px' : '0px'}
        primaryOnClick={uploadFilesApi}
        primaryButtonDisabled={files.length === 0 ? true : false}
      ></Modal>
    </div>
  );
}

export default ManageContracts;
