import { useTranslation } from 'react-i18next';
import { closeCircle } from '../../../assets/components/svgs';
import { isDisabled } from '@testing-library/user-event/dist/utils';

interface PillsProps {
  name: string;
  onClick: any;
  isLast: boolean;
  removeAll: any;
  disabled?: boolean;
}

const Pills = ({ name, onClick, isLast, removeAll, disabled }: PillsProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-fit flex-row">
      <div
        className={`flex items-center rounded-[60px] border-[1px] border-primary bg-secondary px-2 py-1`}
      >
        <p className="flex-grow text-base font-normal capitalize leading-6">{name}</p>

        {!isDisabled && (
          <div onClick={onClick} className="ml-2 h-5 w-5 cursor-pointer text-primary">
            {closeCircle}
          </div>
        )}
      </div>
      {isLast && !disabled && (
        <span className="ml-2 flex h-full flex-row text-center text-gray-400">
          |
          <div onClick={removeAll} className="ml-2 cursor-pointer leading-6 text-primary">
            {t('T_REMOVE_ALL')}
          </div>
        </span>
      )}
    </div>
  );
};

export default Pills;
