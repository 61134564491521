import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { deleteRole, listRoles } from '../../http/access-management-service';
import { useNavigate } from 'react-router-dom';
import BiButton from '../primitives/buttons/BiButton.primitive';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import { setOrgDetails, setPermissionSet } from '../../app/rolesAndFeatureReducer';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { noDataAvailableMessage, RolePermission } from '../Organizations/helpers';
import { setURLPaths } from '../../app/organizationReducer';
import { getCurrentDate, SystemSetting } from '../../util/RoleUtil';
import { AppConstants } from '../../constants/AppConstants';
import { useQuery } from '../../hooks/queryParams';
import { getFailureMessage } from '../../util/ErrorUtil';
import { getLoggedInOrg, getLoginUserId } from '../../util/admin-utils';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { find, flatMap, isEmpty, map, property } from 'lodash';
import { loadPermissionSetList } from '../PermissionSet/PermissionSetUtil';
import Checkbox from '../shared/Fields/Checkbox';
import { ActionButtons } from '../Reusable/ActionButton';
import { deleteManifestItem, setManifest } from '../../app/migrationExportReducer';
import { getOrganization } from '../../http/organization-services';
import { RouteConstants } from '../../constants/RouteConstants';
import {
  isGlobalSelected,
  isOrgSpecificSelected,
  removeOrgData,
  toggleGlobalCheckbox,
  toggleGlobalOverrideCheckbox,
  toggleOrgCheckbox,
  toggleOrgOverrideCheckbox
} from '../Migration/migrationUtil';
import moment from 'moment';
import AddCircle from '../../assets/addCircle.svg';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

/**
 * @parentId roles
 * @manager Role List
 * @overview Lists all the roles
 *   <section>
 *       <p>
 *           Roles listing page lists all the roles. User can navigate to roles listing page by clicking <b>Application Roles</b> navigation menuitem under the <strong>Security</strong> menu group of admin portal left sidebar.
 *           By Default roles listing page will display all the roles in flat table hireachry with pagination support & role(s) are sorted by created-on field timestamp in descending order. The Default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer.
 *           Datagrid footer will also display the current page and total number of pages available based on the choosen page size & roles data. User can switch to next/previous pages using the <b>Next</b> & <b>Previous</b> buttons enabled based on the content in the data grid footer.
 *       </p>
 *       <br>
 *       <p>
 *           Role(s) can be filtered based on the role name using the search field provided above the roles listing data grid. Admin user(s) with <b>accesscontrol-service.role.list</b> API role permission can create new Role by clicking on <b>New Role</b> button provided on the roles listing page toolbar,
 *           which will navigate user to role creation page. <b>New Role</b> button will be shown based on logged in user role <b>accesscontrol-service.role.create</b> API permissions as described below.
 *       </p>
 *       <br/>
 *       <p>
 *           Datagrid will display below properties of each role as columns. These columns are fixed in width and can't be hidden, changed or re-ordered.
 *           <br/>
 *           <ul>
 *               <li>Role Name</li>
 *               <li>MFA</li>
 *               <li>Permission Set</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *       </p>
 *       <p>
 *          Sorting of role(s) is only supported on <i>Role Name</i> and <i>Last Modified On</i> columns, However the default sort of the listing page is based on <b>Created On</b> field with descending sort order. The breadcrumb on the adminportal toolbar will reflect Security > Roles.
 *          Clicking on role rowitem will drill down user to roles details page which will display role page in view only mode.
 *       </p>
 *     </section>
 *
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes possibile failure status code information while listing roles.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>NS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>NS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td>None</td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings, platform services & role API permissions required for the functioning of role(s) listing page.</p>
 *       <br/>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for roles listing page.</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for roles listing page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <td>/roles</td>
 *               <td>GET</td>
 *               <td>accesscontrol-service.role.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/user-management-service/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>/roles</td>
 *               <td>POST</td>
 *               <td>accesscontrol-service.role.create</td>
 *               <td>No</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for roles listing page</p>
 *   </section>
 */

interface RoleListProps {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const RoleList = ({ type = 'listing', toggleModal = () => {} }: RoleListProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [size, setSize] = useState(
    type === 'listing' ? AppConstants.DEFAULT_PAGE_SIZE : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
  );
  const [currentPage, setCurrentPage] = useState(AppConstants.DEFAULT_PAGE);
  const [organizations, setOrganizations] = useState<Map<string, any>>(new Map());
  const [loadedOrgIds, setLoadedOrgIds] = useState<Array<string>>([]);

  const [rows, setRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedOrgItems, setSelectedOrgItems] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [pagingOpts, setPagingOpts] = useState(null);
  const [activePage, setCurrentActivePage] = useState(0);
  const [permissionSetMap, setPermissionSetMap] = useState<Map<string, SystemSetting>>(new Map());

  // const step = useAppSelector((state) => state.rolesAndFeature.step);
  // const storedURLPaths = useAppSelector((state) => state.organization.urlPaths); //Unused variable
  // const loginOrgId = getLoggedInOrg()?.organizationId;

  const { t } = useTranslation();
  const [enableCreate, setEnableCreate] = useState<boolean>(false);
  // const [permissions, setPermissions] = useState<any>({});
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const permissionSetInstances: any = find(metaDataObjectList, {
    name: AppConstants.METADATA_OBJECT_PERMISSION_SET
  });

  const manifestRolesData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.globalRoles
  );

  const manifestOrgRolesData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.orgRoles
  );

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const loggedInOrg = getLoggedInOrg();
  const userId = getLoginUserId();

  useEffect(() => {
    const init = async () => {
      // await enableDisableFeatures();
      await Promise.all([
        getPermissionSets(),
        callAPI('', currentPage, size, AppConstants.DEFAULT_SORT_BY, AppConstants.DEFAULT_SORT_DESC)
      ]);
    };
    if (type !== 'migrationPreview') {
      init();
    }
  }, []);

  useEffect(() => {
    if (type === 'migrationPreview') {
      setCurrentActivePage(currentPage * size - size);
    }
  }, [size, currentPage]);

  const getAdminOrgs = async (roles: Array<any> = []) => {
    const loadableOrgIds: Set<string> = new Set();
    roles.forEach((role: any) => {
      if (role?.organizationId && !loadedOrgIds.includes(role?.organizationId)) {
        loadableOrgIds.add(role?.organizationId);
      }
    });

    await getOrganizations(Array.from(loadableOrgIds.values()));
  };

  const getOrganizations = async (orgIds: Array<string> = []) => {
    const responses: Array<any> = await Promise.all(
      orgIds.map((orgId: string) => {
        return getOrganization(orgId);
      })
    );
    responses.forEach((response: any) => {
      if (response) {
        const { status, data } = response;
        if (status == HTTP_STATUS.HTTP_OK) {
          organizations.set(data?.id, data);
          loadedOrgIds.push(data?.id);
        }
      }
    });
    setLoadedOrgIds(loadedOrgIds);
    setOrganizations(organizations);
  };

  const getPermissionSets = async () => {
    if (type === 'listing') {
      const responseList: any = await loadPermissionSetList(
        2,
        {
          category: AppConstants.CATEGORY_PERMISSION_SETS,
          page: AppConstants.DEFAULT_PAGE,
          size: AppConstants.MAXIMUM_PAGE_SIZE
        },
        {
          permissionSetInstances,
          page: AppConstants.DEFAULT_PAGE,
          size: AppConstants.MAXIMUM_PAGE_SIZE
        }
      );
      const { flag, response } = responseList;
      switch (flag) {
        case 1:
          const { status, data: respBody } = response;
          if (status == HTTP_STATUS.HTTP_OK) {
            const { data: permissionSets = [] } = respBody;
            const mappedPermSets = permissionSets.filter(
              (permissionSet: SystemSetting) => permissionSet?.value?.roles?.length
            );
            if (mappedPermSets.length) {
              mappedPermSets.forEach((setting: any) => {
                permissionSetMap.set(setting?.value?.roles[0]?.roleId, setting);
              });
            }
          }
          setPermissionSetMap(permissionSetMap);
          break;
        case 2:
          const { status: respInstanceStatus, data: respInstanceBody } = response;
          if (respInstanceStatus == HTTP_STATUS.HTTP_OK) {
            const { data: permissionSets = [] } = respInstanceBody;
            const mappedPermSets = permissionSets.filter(
              (permissionSet: SystemSetting) => permissionSet?.attributes?.roles?.length
            );
            if (mappedPermSets.length) {
              mappedPermSets.forEach((setting: any) => {
                permissionSetMap.set(setting?.attributes?.roles?.[0], setting);
              });
            }
          }
          setPermissionSetMap(permissionSetMap);
          break;
      }
    }
  };

  // const enableDisableFeatures = async () => {
  //   let createPermissions: any = await RolePermission(AppConstants.PERMISSION_ROLE_CREATE);
  //   let data = {
  //     create: createPermissions
  //   };
  //   setPermissions(data);
  //   return data;
  // };

  const callAPI = async (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = ''
  ) => {
    const response: any = await listRoles(name, true, page, size, sort, sortType);
    if (response.status == HTTP_STATUS.HTTP_OK) {
      if (response.data.data && !response.data.data.length) {
        noDataAvailableMessage(setRows, setSize, setPagingOpts, response.data.paging, t);
      } else {
        await getAdminOrgs(response.data.data);
        setRows(response.data.data);
        setPagingOpts(response.data.paging);
        // setRolesDataClone(response.data.data);
      }
      dispatch(
        setURLPaths([
          {
            key: RouteConstants.ROUTE_ROLE_MANAGEMENT_LIST,
            label: t('T_BREADCRUMB_LABEL_FOR_ROLE_MGMT_PAGE')
          }
        ])
      );
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const roleSelector = (data: any, step: number) => {
    if (type === 'listing') {
      const { organizationId = '', id = '', name = '' } = data;
      const permissionSet = permissionSetMap.get(id);
      const permissionSetWithName = permissionSetMap.get(name);

      let orgData = organizations.get(organizationId);
      if (!isEmpty(orgData)) {
        dispatch(setOrgDetails(orgData));
      }
      if (!isEmpty(permissionSet)) {
        dispatch(setPermissionSet(permissionSet));
      } else if (!isEmpty(permissionSetWithName)) {
        dispatch(setPermissionSet(permissionSetWithName));
      } else {
        dispatch(setPermissionSet({}));
      }
      // dispatch(setNewRoleData(data));
      navigate(RouteConstants.ROUTE_ROLE_MANAGEMENT_EDIT.replace(':id', data.id));
    }
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc'
  ) => {
    setTableValues(name, page, size, sort, sortType);
  };

  const setTableValues = (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc'
  ) => {
    setSize(size);
    setCurrentPage(page);
    setIsLoading(true);
    callAPI(name, page, size, sort, sortType);
  };

  const isCheckboxSelected = (data: any) => {
    const { organizationId = '' } = data;

    if (organizationId.length) {
      return isOrgSpecificSelected(selectedOrgItems, data, 'roles');
    } else {
      return isGlobalSelected(selectedItems, data);
    }
  };

  const toggleOverWrite = (data: any) => {
    const { id, organizationId = '' } = data;

    if (organizationId.length) {
      let arr = toggleOrgOverrideCheckbox(manifestOrgRolesData, data, 'roles');
      dispatch(setManifest({ key: 'orgRoles', value: arr }));
    } else {
      dispatch(
        setManifest({
          key: 'globalRoles',
          value: toggleGlobalOverrideCheckbox(manifestRolesData, data)
        })
      );
      return;
    }
  };

  const toggleCheckbox = (data: any) => {
    const { id, organizationId = '', name = '', passwordPolicy = '' } = data;

    if (organizationId.length) {
      let arr = toggleOrgCheckbox(selectedOrgItems, data, 'roles');

      setSelectedOrgItems(arr);
    } else {
      let arr = toggleGlobalCheckbox(selectedItems, data);

      setSelectedItems(arr);
    }
  };

  const roleHeaderBuilder = () => {
    let ROLE_HEADERS: Array<ITableHeader> = [
      {
        key: 'name',
        description: `${t('T_ROLE_NAME')}`,
        sortEnabled: type !== 'migrationPreview',
        width: type === 'listing' ? '25%' : '50%',
        sortingInformation: {
          order: ''
        }
      }
    ];
    switch (type) {
      case 'listing':
        ROLE_HEADERS = ROLE_HEADERS.concat([
          {
            key: 'mfa',
            description: `${t('T_MFA')}`,
            sortEnabled: true,
            width: '8%',
            sortingInformation: {
              order: ''
            },
            render: (data: any) => {
              let mfa = data['mfa'];
              return mfa ? `${t('T_ON')}` : `${t('T_OFF')}`;
            }
          },
          {
            key: 'passwordPolicy',
            description: `${t('T_PASSWORD_POLICY')}`,
            sortEnabled: false,
            width: '18%',
            sortingInformation: {
              order: ''
            },
            render: (data: any) => {
              return data['passwordPolicy'] || '';
            }
          },
          {
            key: 'permissionsSet',
            description: `${t('T_PERMISSION_SET')}`,
            sortEnabled: false,
            width: '18%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any) => {
              const { id, name } = data;
              const permSet = permissionSetMap.get(name);
              const permSetWithId = permissionSetMap.get(id);
              if (permSet) {
                const { attributes } = permSet;
                const { name = '' } = attributes || {};
                return name;
              } else if (permSetWithId) {
                const { attributes } = permSetWithId;
                const { name = '' } = attributes || {};
                return name;
              }
              return '';
            }
          },
          {
            key: 'organizationId',
            description: `${t('T_ORGANIZATION')}`,
            sortEnabled: false,
            width: '18%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any) => {
              const { organizationId = '' } = data;
              let orgData = organizations.get(organizationId);

              if (!isEmpty(orgData)) {
                return orgData?.name;
              }
            }
          },
          {
            key: 'updatedOn',
            description: 'T_LAST_MODIFIED_ON',
            sortEnabled: true,
            width: '14%',
            clickable: true,
            sortingInformation: {
              order: ''
            },
            render: (row: any) => {
              return moment(row.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT);
            }
          },
          // TODO: Need clarity on sorting and showing user name
          // {
          //   key: 'updatedBy',
          //   description: 'T_LAST_MODIFIED_BY',
          //   sortEnabled: true,
          //   width: '14%',
          //   clickable: true,
          //   sortingInformation: {
          //     order: 'desc'
          //   },
          // }
        ]);

        const query = useQuery();
        if (query.get('devmode')) {
          ROLE_HEADERS.push({
            key: 'buttons',
            description: `${t('T_ACTIONS')}`,
            sortEnabled: false,
            width: '13%',
            sortingInformation: {
              order: ''
            }
          });
        }
        break;

      case 'migrationSelection':
        ROLE_HEADERS.unshift({
          key: 'checked',
          description: '',
          sortEnabled: false,
          width: '10%',
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={isCheckboxSelected(data)}
                  onChange={() => {
                    toggleCheckbox(data);
                  }}
                  testID="addModuleCheckbox"
                />
              </>
            );
          }
        });
        ROLE_HEADERS.push({
          key: 'passwordPolicy',
          description: 'T_PASSWORD_POLICY',
          sortEnabled: false,
          width: '40%',
          sortingInformation: {
            order: ''
          }
        });

        break;
      case 'migrationPreview':
        ROLE_HEADERS = ROLE_HEADERS.concat([
          {
            key: 'passwordPolicy',
            description: 'T_PASSWORD_POLICY',
            sortEnabled: false,
            width: '30%',
            sortingInformation: {
              order: ''
            }
          },
          {
            key: 'overWrite',
            description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any, index: any) => {
              return (
                <>
                  <Checkbox
                    className="mx-auto"
                    height="20px"
                    width="20px"
                    onChange={() => {
                      toggleOverWrite(data);
                    }}
                    checked={data?.overWrite}
                    testID="allowOverWriteCheckbox"
                  />
                </>
              );
            }
          },
          {
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            styles: {
              justifyContent: 'center'
            }
          }
        ]);
        break;
    }

    return ROLE_HEADERS;
  };

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(roleHeaderBuilder());

  const removeRole = async (index: number) => {
    const row: any = rows[type === 'listing' ? index : index + activePage];
    if (row) {
      let error = false;
      const { id, name, organizationId = '' } = row;
      if (type === 'migrationPreview') {
        if (organizationId.length) {
          let arr: any = removeOrgData(selectedOrgItems, row, 'roles');
          dispatch(setManifest({ key: 'orgRoles', value: arr }));
          return;
        } else {
          dispatch(
            deleteManifestItem({
              key: 'globalRoles',
              id: id
            })
          );
          return;
        }
      }
      let toastMessage = `${name} ${t('T_DELETED_SUCCESS_MESSAGE')}`;
      const response = await deleteRole(id);
      const { status } = response;
      if (status == HTTP_STATUS.HTTP_OK) {
        rows.splice(index, 1);
        setRows([...rows]);
      } else {
        error = !error;
        toastMessage = getFailureMessage(response);
      }
      dispatch(
        setToastData({
          toastMessage,
          isToastActive: true,
          toastType: error ? 'error' : 'success'
        })
      );
    }
  };

  useEffect(() => {
    if (manifestOrgRolesData?.length) {
      setSelectedOrgItems(manifestOrgRolesData);
    }
  }, [manifestOrgRolesData]);

  useEffect(() => {
    setSelectedItems(manifestRolesData);
  }, [manifestRolesData]);

  useEffect(() => {
    if (type === 'migrationSelection') {
      setTableHeaders(roleHeaderBuilder());
    } else if (type === 'migrationPreview') {
      const mappedOrgItems = selectedOrgItems.length
        ? flatMap(map(selectedOrgItems, property('dependentArtifactConfigurations.roles')))
        : [];

      let mergedItems: any = [...selectedItems, ...mappedOrgItems];
      setTableHeaders(roleHeaderBuilder());
      setRows(mergedItems);
      setIsLoading(false);
    }
  }, [selectedItems, selectedOrgItems]);

  // useEffect(() => {
  // if (type === 'migrationPreview') {
  //   const getData = async () => {
  //     await getManifestData(currentPage);
  //     setCurrentActivePage(currentPage * size - size);
  //   };
  //   getData();
  // }
  // }, [currentPage]);

  const saveSelection = () => {
    dispatch(setManifest({ key: 'globalRoles', value: selectedItems }));
    if (selectedOrgItems.length) {
      dispatch(setManifest({ key: 'orgRoles', value: selectedOrgItems }));
    }
    toggleModal();
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <CustomersTableContainer className={`${type !== 'listing' && '!pb-0'}`}>
          {/* {feauresObj.features[8].functions[1].permissions[0].claim ==
            'Role.access.management.window.create' &&
            step === 1 && ( */}

          {roleAPIPermissions[AppConstants.PERMISSION_ROLE_CREATE] && type === 'listing' ? (
            <MenuButtonsPortal>
              <BiButton
                className={'flex flex-row bg-primary text-white'}
                type="button"
                onClick={() => {
                  navigate(RouteConstants.ROUTE_ROLE_MANAGEMENT_CREATE);
                }}
              >
                <img src={AddCircle} className="mr-2 w-6 text-center"></img>
                {t('T_NEW_ROLE')}
              </BiButton>

              <div></div>
            </MenuButtonsPortal>
          ) : null}
          {/* )} */}

          {/* <ContractInviteMenu /> */}
          <TemplateTable
            tableData={
              type === 'migrationPreview' ? rows.slice(activePage, activePage + size) : rows
            }
            size={size.toString()}
            currentPage={currentPage.toString()}
            templateSelector={roleSelector}
            // setUserTemplateData={setRolesData}
            onRefreshTableData={refreshTableData}
            // tableDataClone={rolesDataClone}
            isSearchable={type !== 'migrationPreview'}
            setSize={setSize}
            setCurrentPage={setCurrentPage}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            searchPlaceholder={t('T_SEARCH_ROLE_NAME_PLACEHOLDER') || ''}
            pagingData={pagingOpts}
            containerClass="mt-0"
            showDeleteButton={true}
            deleteOnClick={removeRole}
            theme={useTheme().theme}
            isLocalPaginator={type === 'migrationPreview'}
            totalDataLength={rows.length}
            isPaginated={type !== 'migrationPreview'}
            disablePageSize={type !== 'listing'}
          />
          {type === 'migrationSelection' ? (
            <div className="w-full">
              <ActionButtons
                primaryButtonLabel={t('T_SAVE')}
                primaryButtonAction={saveSelection}
                secondaryButtonLabel={t('T_CANCEL')}
                secondaryButtonAction={toggleModal}
                primaryButtonWidth="full"
                primaryButtonClass={'px-4'}
              />
            </div>
          ) : (
            ''
          )}
        </CustomersTableContainer>
      </LoaderContainer>
    </>
  );
};

export default RoleList;
