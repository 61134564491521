import React, { useCallback, version } from 'react';
import Dropzone from '../shared/DragAndDrop/Dropzone';
import ListView from '../primitives/ListView/ListView';
import Card from '../Organizations/Reusable/Card';
import Spinner from '../primitives/Spinner/Loader';
import { isEmpty } from 'lodash';
import { Label } from '../Onboarding/OnboardingStyles';
import { FileInfo } from '../FileManagement/UploadFiles';
import { useTranslation } from 'react-i18next';

interface UploadingContractsProps {
  files: any;
  loader: Boolean;
  fileError: { [key: string]: string };
  onDrop: Function;
  updateFileInfo?: Function;
  removeFileFromUploadList: Function;
  acceptAllFiles?: boolean;
  bucketVersioning?: boolean;
  maxFiles?: number;
  customFormat?: any;
  dropzoneClass?: string;
  cardClass?: string;
  showMetadata?: boolean;
  disableOptions?: boolean;
}

function UploadContracts({
  files,
  loader,
  fileError,
  updateFileInfo,
  removeFileFromUploadList,
  onDrop,
  acceptAllFiles = false,
  maxFiles = 25,
  customFormat = {},
  dropzoneClass = '',
  cardClass = '',
  bucketVersioning = false,
  showMetadata = false,
  disableOptions = false
}: UploadingContractsProps) {
  const { t } = useTranslation();
  return (
    <div className="text-md mx-3 flex flex-col space-y-2 ">
      <div className={`mx-auto mb-2 w-full pt-[1rem] ${dropzoneClass}`}>
        <Dropzone
          onDrop={onDrop}
          maxFiles={maxFiles}
          accept={
            !isEmpty(customFormat)
              ? customFormat
              : acceptAllFiles
              ? {}
              : { 'application/pdf': ['.pdf'] }
          }
        />
      </div>
      {loader ? (
        <div data-testid="upload-loader" className="flex w-full items-center justify-center py-12">
          <Spinner height="40px" width="40px"></Spinner>
        </div>
      ) : (
        <div className="flex flex-row">
          {files.length > 0 && (
            <div className="flex w-full flex-col">
              {!disableOptions ? (
                <div className="mx-6 mb-2 flex w-full items-center px-2 text-base font-normal text-gray-400">
                  <Label className="flex w-[60%]">{t('T_FILE_NAME')}</Label>
                  <Label className="flex w-[17%]">{t('T_FILE_VERSIONING')}</Label>
                  <Label className="flex w-[17%]">{t('T_PUBLIC_ACCESS')}</Label>
                  <Label className="flex w-[6%]"></Label>
                </div>
              ) : (
                ''
              )}
              <ListView
                id="upload-contract-list"
                data-testid="upload-contract-list"
                // containerClassName={`mb-5 font-medium  ${
                //   files.length > 3 ? 'md:grid md:grid-cols-2 md:gap-1' : ''
                // } `}
                containerClassName={`font-medium`}
                maxHeight="18rem"
              >
                {files.map((item: FileInfo, index: any) => {
                  const { file, issues = [] } = item;
                  return (
                    <Card
                      id={index}
                      item={item}
                      key={`file-${index}`}
                      showMetadata={showMetadata}
                      disableOptions={disableOptions}
                      deleteFunction={removeFileFromUploadList(
                        index,
                        file?.name?.substring(file?.name.indexOf('/') + 1)
                      )}
                      updateFunction={updateFileInfo}
                      // customClass={`h-10 mb-2 w-[90%] mx-auto flex items-center overdlow-y-scroll rounded-lg border border-solid border-gray-400 px-3 text-base font-normal ${
                      //   file?.name?.substring(file?.name.indexOf('/') + 1) == fileError.fileName &&
                      //   'border-rose-500'
                      // } ${cardClass}`}
                      customClass={`h-10 py-1 ${
                        issues.length && 'border-2 border-rose-600'
                      } shadow-md w-full mx-auto flex ${
                        !disableOptions ? 'justify-center' : 'justify-between'
                      } items-center rounded-md text-base font-normal ${cardClass}`}
                      bucketVersioning={bucketVersioning}
                    ></Card>
                  );
                })}
              </ListView>
            </div>
          )}
        </div>
      )}
      {fileError.errorText && (
        <div data-testid="file-error" className="my-1 text-sm text-rose-600">
          {fileError.errorText}
        </div>
      )}
    </div>
  );
}
export default React.memo(UploadContracts);
