import React from 'react';

import styled from 'styled-components/macro';
import { Input, Label } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';
import { useTranslation } from 'react-i18next';

interface NewOrganizationSampleData {
  name: string;
  phone: string;
  fax: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  country: string;
}

interface NonEditableFieldProps {
  label: string;
  fieldKey: string;
  required: boolean;
  formData: NewOrganizationSampleData;
  dynamicDisplayField: Function;
}
const Image = styled.img`
  width: 8px;
  height: 8px;
`;

function NonEditableFields({
  label,
  fieldKey,
  required,
  formData,
  dynamicDisplayField
}: NonEditableFieldProps) {
  const { t } = useTranslation();

  let explicitValue = dynamicDisplayField(formData[fieldKey as keyof NewOrganizationSampleData]);

  return (
    <>
      <Label>
        {t(label)}
        {required ? <Image src={Asterix} /> : ''}
      </Label>
      <Input
        value={
          formData[fieldKey as keyof NewOrganizationSampleData]
            ? explicitValue || formData[fieldKey as keyof NewOrganizationSampleData]
            : ''
        }
        readOnly={true}
        disabled={true}
      />
    </>
  );
}
const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 44px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
`;

export default NonEditableFields;
