import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { AppConstants } from '../../constants/AppConstants';
import { useQuery } from '../../hooks/queryParams';
import { RouteConstants } from '../../constants/RouteConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { listConsentTemplates } from '../../http/access-management-service';
import { setConsentTemplate, setDefaultValueSettings } from '../../app/consentReducer';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { getSortTableArray } from '../../util/table-utils';
import ConsentListFilter, { filterConfigProps } from './consentListFilter';
import {
  getCodableValue,
  getPurpose,
  getCodableValueArray,
  getCodeFromCodable,
  GetObjectFromData
} from '../../util/consent-utils';
import { getSystemSettings } from '../../http/configuration-services';
import { SystemSetting } from '../../util/RoleUtil';
import AddCircle from '../../assets/addCircle.svg';
import moment from 'moment';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const defaultSystemFilterOption = {
  purposeOption: new Map(),
  categoryOption: new Map(),
  statusOption: []
};

/**
 * @parentId consent-management
 * @manager ConsentTemplateList
 * @overview
 *   <section>
 *           <p>
 *               Consent template listing page is the default page the user is navigated on clicking <b>Consent Management</b> navigation bar menuitem. Listing page will show all the consent template(s) in a paginated way.
 *               The Default pagination size be fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & consent template data.
 *           </p>
 *           </br>
 *       <p>
 *           Admin user(s) can create new consent template by clicking on <b>New Consent</b> button provided on the admin portal toolbar,
 *           which will navigate user to Consent Tempplate creation page. <b>New Consent</b> button will be shown based on logged in
 *           user role API permissions as described below.
 *       </p>
 *           </br>
 *           <p>
 *               Consent Template(s) can be filtered based on the multiple attribute with filter button provided above the template listing data grid. We can filter the consent template list as per below attributes:
 *                <ul>
 *                    <li>Status</li>
 *                    <li>Consent Name</li>
 *                    <li>Type</li>
 *                    <li>Category</li>
 *                    <li>Purpose</li>
 *                    <li>Version</li>
 *                </ul>
 *           </p>
 *           </br>
 *           <p>
 *               Datagrid will display details of each consent template as row item with each column representing template property.
 *               <br>
 *               <ul>
 *                   <li>Name</li> <li>Category</li> <li>Purpose</li> <li>Type</li> <li>Version</li> <li>Status</li> <li>Action</li> <li>Last Modified On</li>
 *               </ul>
 *           </p>
 *           <p>
 *               Sorting of consent template grid is supported on <i>Status, Name, Type, Version, Last Modified On</i> columns. The breadcrumb bar on the application toolbar will reflect location of the user in the admin portal. Clicking on user rowitem will drill down user to user details page which will be displayed in readonly mode.
 *           </p>
 *         </section>
 *         <section>
 *          <h4>Failure Status Codes</h3>
 *           <p>
 *           This section describes the List Consent Template(s) Status Code information.
 *           <table>
 *               <tr>
 *                   <th>HTTP Status Code</th>
 *                   <th>Service Error Code</th>
 *                   <th>Error Message</th>
 *               </tr>
 *               <tr>
 *                   <td>403</td>
 *                   <td>UMS_PERMISSION_DENIED</td>
 *                   <td>You do not have permission to view this page</td>
 *               </tr>
 *               <tr>
 *                   <td>500</td>
 *                   <td>UMS_INTERNAL_ERROR</td>
 *                   <td>Internal Server Error</td>
 *               </tr>
 *               <tr>
 *                   <td>503</td>
 *                   <td></td>
 *                   <td>Service Unavailable</td>
 *               </tr>
 *           </table>
 *       </p>
 *       </section
 *       <section>
 *          <h4>Dependent System settings, Platform services & Role Permission</h3>
 *          <p>This section describes the list of dependent system settings & platform services required for functioning of Consent Template(s) listing page.</p>
 *           <h5>System Settings</h4>
 *           <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *           <table>
 *               <tr>
 *                   <th>Key</th>
 *                   <th>Type</th>
 *                   <th>Value</th>
 *                   <th>Scope</th>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.type.default.values</td>
 *                   <td>String</td>
 *                   <td>PERMIT</td>
 *                   <td>None</td>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.status.default.values</td>
 *                   <td>String</td>
 *                   <td>DRAFT</td>
 *                   <td>None</td>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.action.default.codes</td>
 *                   <td>json</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.category.default.values</td>
 *                   <td>json</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.purpose.default.values</td>
 *                   <td>json</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *               </tr>
 *           </table>
 *           <br>
 *          <h5>Platform Service(s)</h4>
 *          <p>Table lists all the dependent platform service(s) with specific version(s) for User(s) listing</p>
 *           <table>
 *               <tr>
 *                   <th>Service Name</th>
 *                   <th>Version</th>
 *               </tr>
 *               <tr>
 *                   <td>Access Control Service</td>
 *                   <td>1.4.0</td>
 *               </tr>
 *               <tr>
 *                   <td>Configuration Service</td>
 *                   <td>1.4.0</td>
 *               </tr>
 *           </table>
 *           <br>
 *          <h5>API Role Permission(s)</h4>
 *          <p>Table lists the required API role permissions for listing Consent Template(s) page</p>
 *           <table>
 *               <tr>
 *                   <th>API URL</th>
 *                   <th>API Method</th>
 *                   <th>API Permission</th>
 *                   <th>Required</th>
 *               </tr>
 *               <tr>
 *                  <td>/system-settings</td>
 *                  <td>GET</td>
 *                  <td>configuration-service.setting.list</td>
 *                  <td>Yes</td>
 *              </tr>
 *               <tr>
 *                   <td>/consent/templates</td>
 *                   <td>GET</td>
 *                   <td>N/A</td>
 *                   <td>Yes</td>
 *               </tr>
 *           </table>
 *       </section>
 *       <section>
 *        <p>Sequence Diagram for Consent Template(s) listing page</p>
 *       </section>
 */

const ConsentTemplateList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(AppConstants.DEFAULT_PAGE);
  const [size, setSize] = useState<number>(AppConstants.DEFAULT_PAGE_SIZE);
  const [pagingOpts, setPagingOpts] = useState<any>(null);

  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [systemFilterOption, setSystemFilterOption] = useState<any>(defaultSystemFilterOption);

  const [formFilterData, setFormFilterData] = useState<filterConfigProps>({
    status: '',
    type: '',
    name: '',
    version: '',
    'category-code': [],
    'purpose-code': []
  });

  useEffect(() => {
    refreshTableData('', AppConstants.DEFAULT_PAGE, size || AppConstants.DEFAULT_PAGE_SIZE);
  }, [formFilterData]);

  const consentTemplateSelector = (data: any, step: number) => {
    dispatch(setConsentTemplate(data));
    navigate(`/consents/${data.id}`);
  };

  const refreshTableData = (name: string, page: number, size: number) => {
    setIsLoading(true);
    setTableValues(name, page, size);
  };

  const setTableValues = (name: string = '', page: number, size: number) => {
    setSize(size);
    setPage(page);
    fetchConsentTemplates(size, page, name);
  };

  const fetchConsentTemplates = async (size: number, page: number, name: string = '') => {
    const filter: string = getFilters();
    const sorters: string[] = getSortTableArray(tableHeaders);
    let resposne = await listConsentTemplates(size, page, name, filter, sorters.join());
    if (resposne.status === HTTP_STATUS.HTTP_OK) {
      await fetchCreatedBy(resposne);
    } else {
      let error = getFailureMessage(resposne);
      setIsLoading(false);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
  };

  const fetchCreatedBy = async (resposne: any) => {
    const { data = {}, status } = resposne;
    const { data: templateList = [], paging } = data;
    if (status === HTTP_STATUS.HTTP_OK) {
      if (templateList.length) {
        let newRow: any[] = [];
        templateList.forEach(async (ele: any, index: any) => {
          setIsLoading(true);
          newRow.push({
            ...ele,
            ...{
              purpose: ele.purpose,
              category: ele.category,
              action: ele.action,
              scope: ele.scope
            }
          });
          setRows(newRow);
          setPagingOpts(paging);
          setIsLoading(false);
        });
      } else {
        noDataAvailableMessage(setRows, setSize, setPagingOpts, paging, t);
      }
    } else {
      let error = getFailureMessage(resposne);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
    setIsLoading(false);
  };

  const getConsentTemplateDefaultSystemSettings = async () => {
    let systemSettings: Array<SystemSetting> = new Array<SystemSetting>();
    const response = await getSystemSettings(
      AppConstants.CATEGORY_ACCESS_CONTROL_CONSENT_TEMPLATE_DEFAULT_VALUES,
      '',
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );

    const { status, data } = response;
    if (HTTP_STATUS.isSuccess(status)) {
      const { data: consentDefaults = [] } = data;
      systemSettings = [...consentDefaults];
    }
    setIsLoading(false);
    return systemSettings;
  };

  const getMapData = (value: Array<any> = []) => {
    return new Map<string, any>(
      value.map((item: any) => {
        const data = GetObjectFromData(item);
        return [data.display, data];
      })
    );
  };

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const defaultSystemSettings: Array<SystemSetting> =
        await getConsentTemplateDefaultSystemSettings();
      dispatch(setDefaultValueSettings(defaultSystemSettings));

      let statusOption: any = [];
      let purposeOption: Map<string, any> = new Map<string, any>();
      let categoryOption: Map<string, any> = new Map<string, any>();

      defaultSystemSettings.forEach((ele: any) => {
        if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_PURPOSE_KEY) {
          purposeOption = getMapData(ele.value);
        } else if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_CATEGORY_KEY) {
          categoryOption = getMapData(ele.value);
        } else if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_STATUS_KEY) {
          statusOption = ele.allowedValues || [];
        }
      });

      setSystemFilterOption({
        purposeOption,
        categoryOption,
        statusOption
      });
    };

    init();

    dispatch(
      setURLPaths([
        {
          key: RouteConstants.ROUTE_CONSENTS,
          label: 'Consents'
        }
      ])
    );
  }, []);

  const consentTemplateHeaders: Array<ITableHeader> = [
    {
      key: 'status',
      description: 'T_STATUS',
      sortEnabled: true,
      width: '10%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'name',
      description: 'T_NAME',
      sortEnabled: true,
      width: '20%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'purpose',
      description: 'T_PURPOSE',
      sortEnabled: false,
      width: '20%',
      nastedData: true,
      render: (data: any) => {
        return getPurpose(data).join(', ');
      },
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'type',
      description: 'T_TYPE',
      sortEnabled: true,
      width: '10%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'version',
      description: 'T_VERSION',
      sortEnabled: true,
      width: '10%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'action',
      description: 'T_ACTION',
      sortEnabled: false,
      nastedData: true,
      render: (data: any) => {
        return getCodableValueArray(data.action || []).join(', ');
      },
      width: '15%'
    },
    {
      key: 'category',
      description: 'T_CATEGORY',
      sortEnabled: false,
      nastedData: true,
      render: (data: any) => {
        return getCodableValueArray(data.category || []).join(', ');
      },
      width: '15%'
    },
    {
      key: 'updatedOn',
      description: 'T_LAST_MODIFIED_ON',
      sortEnabled: true,
      render: (data: any) => {
        return data.updatedOn
          ? moment(data.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '';
      },
      sortingInformation: {
        order: '',
      },
      width: '15%'
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: false,
    //   nastedData: true,
    //   width: '15%'
    // }
  ];

  const getFilters = () => {
    let filter: string[] = [];
    Object.entries(formFilterData).forEach(([key, value]) => {
      if (value) {
        let codevalue = null;
        if (key === 'purpose-code') {
          let purposeCode: any = [];
          value.forEach((ele: any) => {
            const item = getCodeFromCodable(systemFilterOption.purposeOption.get(ele.key) || {});
            purposeCode.push(item);
          });
          codevalue = purposeCode.join(',');
        } else if (key === 'category-code') {
          let categoryCode: any = [];
          value.forEach((ele: any) => {
            const item = getCodeFromCodable(systemFilterOption.categoryOption.get(ele.key) || {});
            categoryCode.push(item);
          });
          codevalue = categoryCode.join(',');
        }
        filter.push(`${key}=${codevalue || value}`);
      }
    });
    return filter.join('&');
  };

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(consentTemplateHeaders);

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <MenuButtonsPortal>
          <BiButton
            className={'flex flex-row bg-primary text-white'}
            type="button"
            disabled={false}
            onClick={() => {
              navigate(RouteConstants.ROUTE_NEW_CONSENTS);
            }}
          >
            <img src={AddCircle} className="mr-2 w-6 text-center"></img>
            {t('T_NEW_CONSENT')}
          </BiButton>
        </MenuButtonsPortal>

        <div>
          <ConsentListFilter
            filterConfig={formFilterData}
            setFilterConfig={setFormFilterData}
            systemFilterOption={systemFilterOption}
          />
        </div>

        <CustomersTableContainer style={{ height: 'inherit' }}>
          <TemplateTable
            tableData={rows}
            size={size.toString()}
            currentPage={page.toString()}
            templateSelector={consentTemplateSelector}
            onRefreshTableData={refreshTableData}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            searchPlaceholder={t('T_SEARCH_NAME')}
            pagingData={pagingOpts}
            containerClass="mt-0"
            showDeleteButton={true}
            theme={useTheme().theme}
            isSearchable={false}
            isMultiColumnSort={false}
            hasExternalCustomFilter={true}
          />
        </CustomersTableContainer>
      </LoaderContainer>
    </>
  );
};

export default ConsentTemplateList;
