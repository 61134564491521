import BaseAPI from './api';
import env from '../environment';
import { AppConstants } from '../constants/AppConstants';
import { isEmpty } from 'lodash';

import { carePlanFilterQuery } from '../components/CarePlan/carePlanUtils';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

export const getDeviceList = async (
  size: number,
  page: number,
  filters: string = '',
  sorters: string = ''
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.devicePath}/devices?size=${size}&page=${page}`;

  if (filters) {
    uri += `&${filters}`;
  }

  if (sorters) {
    uri += `${sorters}`;
  }
  const response = await API.get(uri, { headers });
  return response;
};
