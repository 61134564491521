export const passwordComplexitySchema: any = [
  {
    id: 'passwordComplexityCharacterNumbers',
    count: 8,
    apiKey: function () {
      return this.count;
    },
    name: 'T_MINIMUM_NO_OF_CHARS',
    required: true
  },
  {
    id: 'passwordComplexitySpecialCharacters',
    count: 1,
    allowedChar: '~!@#$%^&*()-_=+[]{}|;:,.<>/?',
    apiKey: function () {
      return `${this.count}:${this.allowedChar}`;
    },
    name: 'T_MINIMUM_NO_OF_SPECIAL_CHARS',
    required: false
  },
  {
    id: 'passwordComplexityNumbers',
    count: 1,
    allowedChar: '0123456789',
    apiKey: function () {
      return `${this.count}:${this.allowedChar}`;
    },
    name: 'T_MINIMUM_NO_OF_NUMBERS',
    required: false
  },
  {
    id: 'passwordComplexityUppercase',
    count: 1,
    allowedChar:'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    apiKey: function () {
      return `${this.count}:${this.allowedChar}`;
    },
    name: 'T_MINIMUM_NO_OF_UPPERCASE',
    required: false
  },
  {
    id: 'passwordComplexityLowercase',
    count: 1,
    allowedChar:'abcdefghijklmnopqrstuvwxyz',
    apiKey: function () {
      return `${this.count}:${this.allowedChar}`;
    },
    name: 'T_MINIMUM_NO_OF_LOWERCASE',
    required: false
  }
];
