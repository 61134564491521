import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import {
  AuthorsCollab,
  CareTeam,
  Clipboard,
  Conditions,
  Organization,
  fileCode
} from '../../assets/components/svgs';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { createApplication, getApplication, updateApplication } from '../../http/metadata-service';
import JSONEditor from '../AppSettings/JSONEditor';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import Card from '../shared/cards/Card';
import InputField from '../shared/Fields/InputField';
import RadioButton from '../shared/Fields/RadioButton';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import MetadataMenu from './CarePlanTemplateMenu';
import { setApplication } from '../../app/metadataReducer';
import {
  capitalize,
  cloneDeep,
  compact,
  filter,
  find,
  isArray,
  isEmpty,
  isEqual,
  uniq
} from 'lodash';
import { RouteConstants } from '../../constants/RouteConstants';
import { fieldIsEmpty, validateNameLength } from '../../util/validators';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { type } from '@testing-library/user-event/dist/type';
import TabDrawer from '../Reusable/TabDrawer';
import ToastNotification from '../Reusable/ToastNotification';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import TextAreaField from '../shared/Fields/TextAreaField';
import UserRole from '../../assets/user-cog.svg';
import CarePlanDetails from './CarePlanDetails';
import Collaborators from './Collaborators';
import Assignment from './CarePlanAssignment';
import {
  createCareplan,
  deleteCareplan,
  getCarePlan,
  updateCareplan,
  getEncounterList
} from '../../http/careplan-services';
import {
  resetCarePlanReducer,
  resetFormData,
  setCareTeamsList,
  setCareplanData,
  setConditionsList,
  setFormData,
  setFormError,
  setOrgList,
  setPatientUserList,
  setEncounterList,
  setDeviceList
} from '../../app/carePlanReducer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../shared/ModalPortal/Modal';
import { ExclamationIcon } from '@heroicons/react/outline';
import { CalendarIcon } from '@heroicons/react/outline';
import {
  fetchDeviceList,
  fetchPatientRoleUserList,
  fetchPractitionerRole,
  fetchPractitionerRoleUserList,
  filterEncounters,
  getCarePlanConditions,
  getCarePlanOrganizations,
  getCareTeamById,
  getCareTeamsList,
  getConditionsList,
  getEncounterListData,
  getEncounterListItems
} from './carePlanUtils';
import AuditInfo from '../Common/AuditInfo';

const statusField: any = {
  active: { color: '#D9F2E8', helper: 'T_CARE_PLAN_ACTIVE_HEADER' },
  draft: { color: '#FFFFFF', helper: 'T_CARE_PLAN_DRAFT_HEADER' },
  'on-hold': { color: '#F0F0F0', helper: 'T_CARE_PLAN_ONHOLD_HEADER' },
  revoked: { color: '#FEEFC7', helper: 'T_CARE_PLAN_REVOKED_HEADER' },
  completed: { color: '#D9F2E8', helper: 'T_CARE_PLAN_COMPLETED_HEADER' },
  'entered-in-error': { color: '#FEDDCD', helper: 'T_CARE_PLAN_ERROR_HEADER' },
  unknown: { color: '#F0F0F0', helper: 'T_CARE_PLAN_UNKNOWN_HEADER' }
};

const CarePlanTemplate = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [disableButton, setDisableButton] = useState(false);

  const [activeTab, setActiveTab] = useState(`${t('T_CARE_PLAN_DETAILS_HEADER')}`);
  const [auditData, setAuditData] = useState({
    createdBy: '',
    updatedBy: '',
    updatedOn: '',
    createdOn: '',
    periodStart: '',
    periodEnd: '',
    createdByOrg: ''
  });
  const [selectedOption, setSelectedOption] = useState('');

  const formData = useAppSelector((state) => state.carePlanData.formData);
  const encounterListData: any = useAppSelector((state) => state.carePlanData.encounterList);
  const careplanData = useAppSelector((state) => state.carePlanData.careplanData);
  const carePlanOrgList: any = useAppSelector((state) => state.carePlanData.orgList) || [];
  const carePlanUserList: any =
    useAppSelector((state) => state.carePlanData.patientUsersList) || [];
  const careTeamList: any = useAppSelector((state) => state.carePlanData.careTeamList) || [];
  const deviceList: any = useAppSelector((state) => state.carePlanData.deviceList) || [];
  const conditionsListData: any =
    useAppSelector((state) => state.carePlanData.conditionsList) || [];
  const practitionerList: any =
    useAppSelector((state) => state.carePlanData.practitionerList) || [];
  const practitionerRoleList: any =
    useAppSelector((state) => state.carePlanData.practitionerRoleList) || [];
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const toastData = useAppSelector((state) => state.toast);

  useEffect(() => {
    dispatch(resetFormData());
    if (!params?.id) {
      dispatch(setCareplanData({}));

      setIsEdit(true);
      dispatch(
        setURLPaths([
          {
            key: RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_CREATE,
            label: `${t('T_CARE_PLAN')} > ${t('T_NEW_CAREPLAN')}`
          }
        ])
      );
      if (formData?.title) {
        dispatch(resetCarePlanReducer());
      }
      setIsLoading(false);
    } else {
      verifyEditData();
    }
  }, []);

  useEffect(() => {
    if (!encounterListData.length) {
      fetchEncounterData();
    }
  }, [encounterListData]);

  const fetchEncounterData = async () => {
    const encounterData = await getEncounterListData();
    dispatch(
      setEncounterList(
        isArray(encounterData) && encounterData.length
          ? filterEncounters(encounterData)
          : encounterData
      )
    );
  };

  const verifyEditData = async () => {
    async function init() {
      try {
        let response = await getCarePlan(params?.id);
        const { status, data } = response;
        if (HTTP_STATUS.isSuccess(response?.status)) {
          dispatch(setCareplanData(data));
        } else {
          dispatch(
            setToastData({
              toastMessage: getFailureMessage(response),
              isToastActive: true,
              toastType: 'error'
            })
          );
          setIsLoading(false);
          navigate(-1);
        }
      } catch (e) {
        dispatch(
          setToastData({
            isToastActive: true,
            toastMessage: t('T_ERROR_SOMETHING_WRONG'),
            toastType: 'error'
          })
        );
        setIsLoading(false);
        navigate(-1);
      }
    }
    if (
      (params?.id && !isEmpty(careplanData) && params.id !== careplanData?.id) ||
      (isEmpty(careplanData) && params?.id)
    ) {
      await init();
    }
  };
  const getAuditInfo = async () => {
    if (isEmpty(careplanData)) {
      return new Map<string, any>();
    }
    const { createdBy, updatedBy } = careplanData || {};
    const userProfiles: Array<any> = await getUserProfiles(uniq(compact([createdBy, updatedBy])));
    return new Map<string, any>(
      userProfiles.map((userProfile: any) => [userProfile.userId, userProfile])
    );
  };

  useEffect(() => {
    const init = async () => {
      const listData: any = {
        organization: cloneDeep(carePlanOrgList),
        patient: cloneDeep(carePlanUserList),
        care_team: cloneDeep(careTeamList),
        conditions: cloneDeep(conditionsListData),
        encounters: cloneDeep(encounterListData),
        device: cloneDeep(deviceList),
        practitioner: cloneDeep(practitionerList),
        practitionerRole: cloneDeep(practitionerRoleList)
      };

      const populateList = async (type: string) => {
        switch (type) {
          case 'organization':
            const orgData = await getCarePlanOrganizations();
            listData.organization = orgData;
            break;
          case 'care_team':
            const careTeamData = await getCareTeamsList();
            listData.care_team = careTeamData;
            break;
          case 'patient':
            const patientData = await fetchPatientRoleUserList();
            listData.patient = patientData;
            break;
          case 'condition':
            const conditionsData = await getCarePlanConditions();
            listData.conditions = conditionsData;
            break;
          case 'encounter':
            const encounterData = await getEncounterListData();
            listData.encounters = encounterData;
            break;
          case 'device':
            const deviceData = await fetchDeviceList();
            listData.device = deviceData;
            break;
          case 'practitioner':
            const practitionerData = await fetchPractitionerRoleUserList();
            listData.practitioner = practitionerData;
            break;
          case 'practitionerRole':
            const practitionerRoleData = await fetchPractitionerRole();
            listData.practitionerRole = practitionerRoleData;
            break;
        }
      };

      const {
        id,
        title,
        intent,
        description,
        category,
        status,
        createdBy,
        createdOn,
        updatedOn,
        updatedBy,
        periodEnd,
        periodStart,
        authorType,
        authorId,
        contributorType,
        contributorId,
        careteamIds,
        conditionIds,
        organizationIds,
        encounterId
      } = careplanData;

      let payload: any = {};
      payload.title = title;
      if (intent?.length) {
        payload.intent = intent; //[find(AppConstants.CAREPLAN_INTENT, { key: intent })];
      }

      if (description.length) {
        payload.description = description;
      }

      if (category?.length) {
        payload.category = category[0]?.text;
      }

      if (status?.length) {
        let ele = find(AppConstants.CAREPLAN_STATUS, { key: status });
        payload.status = ele ? [ele] : [];
      }

      if (authorType?.length) {
        if (!listData?.[authorType]?.length) {
          await populateList(authorType);
        }
        let authorTypeEle = find(AppConstants.CAREPLAN_COLLABORATORS_AUTHOR, { key: authorType });
        let authorEle = find(listData?.[authorType], { id: authorId });
        payload.author = [authorEle];
        payload.authorCategory = [authorTypeEle];
      }

      if (authorType?.length && authorId?.length) {
        if (!listData?.[authorType]?.length) {
          await populateList(authorType);
        }
        let authorTypeEle = find(AppConstants.CAREPLAN_COLLABORATORS_AUTHOR, { key: authorType });
        let authorEle = find(listData?.[authorType], {
          [authorType === 'patient' ? 'userId' : 'id']: authorId
        });
        payload.author = authorEle ? [authorEle] : [];
        payload.authorCategory = authorTypeEle ? [authorTypeEle] : [];
      }

      if (contributorType?.length && contributorId?.length) {
        if (!listData?.[contributorType]?.length) {
          await populateList(contributorType);
        }
        let contributorTypeEle = find(AppConstants.CAREPLAN_COLLABORATORS_AUTHOR, {
          key: contributorType
        });
        let contributerEle = find(listData?.[contributorType], {
          [contributorType === 'patient' ? 'userId' : 'id']: contributorId
        });
        payload.contributor = contributerEle ? [contributerEle] : [];
        payload.contributorCategory = contributorTypeEle ? [contributorTypeEle] : [];
      }

      if (careteamIds?.length) {
        if (!listData?.care_team?.length) {
          await populateList('care_team');
        }

        let careteams = filter(listData?.care_team, (obj: any) => careteamIds.includes(obj.id));

        payload.careTeam = careteams?.length ? careteams : [];
      }

      if (conditionIds?.length) {
        if (!listData?.conditions?.length) {
          await populateList('condition');
        }
        let conditions = filter(listData?.conditions, (obj: any) => conditionIds.includes(obj.id));
        payload.conditions = conditions?.length ? getConditionsList(conditions) : [];
      }

      if (organizationIds?.length) {
        if (!listData?.organization?.length) {
          await populateList('organization');
        }

        let organizations = filter(listData?.organization, (obj: any) =>
          organizationIds.includes(obj.id)
        );
        payload.organization = organizations?.length ? organizations : [];
      }

      if (encounterId?.length) {
        if (!listData?.encounters?.length) {
          await populateList('encounter');
        }

        let encounter = filter(listData?.encounters, (obj: any) => encounterId === obj?.id);

        payload.encounter = encounter?.length ? getEncounterListItems(encounter) : [];
      }

      const userInfoMap: Map<string, any> = await getAuditInfo();

      setAuditData({
        createdBy: getUserName(userInfoMap.get(createdBy)),
        updatedBy: getUserName(userInfoMap.get(updatedBy)),
        updatedOn: moment(updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT),
        createdOn: moment(createdOn).format(AppConstants.DEFAULT_DATE_FORMAT),
        periodStart: periodStart
          ? moment(periodStart).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '',
        periodEnd: periodEnd
          ? moment(periodEnd).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '',
        createdByOrg: ''
      });

      dispatch(setFormData(payload));
      dispatch(
        setURLPaths([
          {
            key: RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_VIEW.replace(':id', id),
            label: `${t('T_CARE_PLAN')} > ${payload?.title}`
          }
        ])
      );
      if (isEmpty(carePlanOrgList) && !isEmpty(listData?.organization)) {
        dispatch(setOrgList(listData.organization));
      }
      if (isEmpty(carePlanUserList) && !isEmpty(listData?.patient)) {
        dispatch(setPatientUserList(listData.patient));
      }
      if (isEmpty(careTeamList) && !isEmpty(listData?.care_team)) {
        dispatch(setCareTeamsList(listData.care_team));
      }
      if (isEmpty(conditionsListData) && !isEmpty(listData?.conditions)) {
        dispatch(setConditionsList(listData.conditions));
      }

      if (isEmpty(deviceList) && !isEmpty(listData?.device)) {
        dispatch(setDeviceList(listData.device));
      }
      setIsLoading(false);
    };

    if (!isEmpty(careplanData) && params?.id) {
      init();
    }
  }, [careplanData]);

  const validateFormData = () => {
    let errors: any = {
      title: '',
      description: '',
      author: '',
      contributor: '',
      periodStart: '',
      periodEnd: ''
    };
    errors['title'] = fieldIsEmpty(formData.title.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(formData.title.trim(), 1024)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_TOO_LONG_MSG');

    errors['contributor'] =
      formData?.contributorCategory.length && !formData?.contributor.length
        ? t('T_CARE_PLAN_SELECTION_REQUIRED')
        : '';

    errors['author'] =
      formData?.authorCategory.length && !formData?.author.length
        ? t('T_CARE_PLAN_SELECTION_REQUIRED')
        : '';

    errors['description'] = fieldIsEmpty(formData?.description?.trim())
      ? ''
      : validateNameLength(formData.description.trim(), 4096)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG_4096');

    const { title, description, author, contributor } = errors;

    if (!title.length && !description.length && (author.length || contributor.length)) {
      setActiveTab(`${t('T_CARE_PLAN_COLLABORATORS')}`);
    }
    dispatch(setFormError(errors));
    return errors;
  };

  const validator = () => {
    if (disableButton) {
      return disableButton;
    }
    const { title, description, status } = formData;
    if (!title.length || !description.length) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (disableButton) {
      setDisableButton(false);
    }
  }, [formData]);

  const handleSave = async () => {
    // const errors = validateFormData();
    // if (
    //   Object.keys(errors).length !==
    //   Object.keys(errors).filter((validateError) => {
    //     return errors[validateError] === '';
    //   }).length
    // ) {
    //   setDisableButton(true);
    //   return;
    // }
    // setIsLoading(true);
    // const { name, displayName = '', description = '', active = true, properties = '' } = formData;
    // const payload = {
    //   name: name.trim(),
    //   displayName: (displayName && displayName.trim()) || '',
    //   description: (description && description.trim()) || '',
    //   active,
    //   properties:
    //     typeof properties === 'string'
    //       ? properties.trim().length
    //         ? JSON.parse(properties)
    //         : null
    //       : properties
    // };
    // if (!params?.id) {
    //   const response = await createApplication(payload);
    //   if (response.status === HTTP_STATUS.HTTP_CREATED) {
    //     dispatch(
    //       setToastData({
    //         toastMessage: t('T_NEW_METADATA_CREATED_SUCCESS_MESSAGE').replace('%s', name),
    //         isToastActive: true,
    //         toastType: 'success'
    //       })
    //     );
    //     setIsLoading(false);
    //     navigate(RouteConstants.ROUTE_MDS_APPLN_LIST);
    //   } else {
    //     dispatch(
    //       setToastData({
    //         toastMessage: getFailureMessage(response),
    //         isToastActive: true,
    //         toastType: 'error'
    //       })
    //     );
    //     setIsLoading(false);
    //   }
    // } else {
    //   const response = await updateApplication(params.id, payload);
    //   if (response.status === HTTP_STATUS.HTTP_OK) {
    //     dispatch(
    //       setToastData({
    //         toastMessage: t('T_METADATA_UPDATED_SUCCESS_MESSAGE').replace('%s', name),
    //         isToastActive: true,
    //         toastType: 'success'
    //       })
    //     );
    //     setIsEdit(false);
    //   } else {
    //     dispatch(
    //       setToastData({
    //         toastMessage: getFailureMessage(response),
    //         isToastActive: true,
    //         toastType: 'error'
    //       })
    //     );
    //   }
    //   setIsLoading(false);
    // }
  };

  const getCancelButtonText = () => {
    if (!isEdit) {
      return t('T_BACK_TO_METADATA');
    }
    return t('T_CANCEL');
  };

  const handleOnCancel = () => {
    // const { name, displayName, description, properties, active } = application;
    // setFormData({ ...formData, name, displayName, description, properties, active });
    // setJsonString(JSON.stringify(properties));
    // setIsEdit(false);
  };

  const tabConfig = [
    {
      label: t('T_CARE_PLAN_DETAILS_HEADER'),
      component: <CarePlanDetails isEdit={isEdit}></CarePlanDetails>
    },
    {
      label: t('T_CARE_PLAN_COLLABORATORS'),
      component: <Collaborators isEdit={isEdit}></Collaborators>
    },
    {
      label: t('T_CARE_PLAN_ASSIGNMENT'),
      component: <Assignment isEdit={isEdit}></Assignment>
    }
  ];

  const deleteCareplanAPI = async () => {
    setIsLoading(true);
    let response = await deleteCareplan(params?.id || careplanData?.id);

    if ([HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(response.status)) {
      dispatch(
        setToastData({
          toastMessage: t('T_CARE_PLAN_DELETED_SUCCESS'),
          isToastActive: true,
          toastType: 'success'
        })
      );

      navigate(RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_LIST);
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: t('T_ERROR_SOMETHING_WRONG'),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };
  const deleteCareplanUI = () => {
    return (
      <div>
        <div>{t('T_CARE_PLAN_DELETE_PROMPT')}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={
              'border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setSelectedOption('');
              deleteCareplanAPI();
            }}
          >
            {t('T_YES_DELETE')}
          </BiButton>
        </div>
      </div>
    );
  };

  const savePayload = async () => {
    const editMode = params?.id?.length || false;
    const errors = validateFormData();
    if (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    ) {
      setDisableButton(true);
      return;
    }
    setIsLoading(true);
    const {
      title,
      intent,
      description,
      category,
      encounter,
      authorCategory,
      author,
      contributorCategory,
      contributor,
      careTeam,
      conditions,
      organization,
      status,
      periodStart,
      periodEnd
    } = formData;

    let payload: any = {
      title,
      status: status?.[0]?.key || AppConstants.CAREPLAN_STATUS_DEFAULT,
      description
      // encounterId: ''
    };
    if (category?.length) {
      payload.category = [
        {
          text: category
        }
      ];
    }
    if (intent?.length) {
      // const [selection] = intent;
      payload.intent = intent; //selection?.key || '';
    }

    if (authorCategory?.length) {
      const [selectedCategory] = authorCategory;
      const [selectedAuthor] = author;
      payload.authorType = selectedCategory?.key || '';
      payload.authorId =
        selectedCategory?.key === 'patient' ? selectedAuthor?.userId : selectedAuthor?.id || '';
    }

    if (encounter?.length) {
      const [encounterItem] = encounter;
      payload.encounterId = encounterItem?.key || '';
    }

    if (contributorCategory?.length) {
      const [selectedCategory] = contributorCategory;
      const [selectedContributor] = contributor;
      payload.contributorType = selectedCategory?.key || '';
      payload.contributorId =
        selectedCategory?.key === 'patient'
          ? selectedContributor?.userId
          : selectedContributor?.id || '';
    }

    if (careTeam?.length) {
      payload.careteamIds = careTeam.map((careTeams: any) => careTeams?.id);
    }

    if (conditions?.length) {
      payload.conditionIds = conditions.map((condition: any) => condition?.key);
    }

    if (organization?.length) {
      payload.organizationIds = organization.map((org: any) => org?.id);
    }

    if (periodStart && editMode) {
      payload.periodStart = moment(periodStart).format(AppConstants.CAREPLAN_DATE_FORMAT);
    }

    if (periodEnd && editMode) {
      payload.periodEnd = moment(periodEnd).format(AppConstants.CAREPLAN_DATE_FORMAT);
    }

    try {
      if (!editMode) {
        let response = await createCareplan(payload);
        const { data, status } = response;
        if (HTTP_STATUS.isSuccess(status)) {
          dispatch(
            setToastData({
              toastMessage: t('T_CARE_PLAN_CREATED_SUCCESS'),
              isToastActive: true,
              toastType: 'success'
            })
          );
          navigate(RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_LIST);
        } else {
          dispatch(
            setToastData({
              toastMessage: getFailureMessage(response),
              isToastActive: true,
              toastType: 'error'
            })
          );
        }
      } else {
        let response = await updateCareplan(params?.id || careplanData?.id, payload);
        const { data, status } = response;
        if (HTTP_STATUS.isSuccess(status)) {
          dispatch(
            setToastData({
              toastMessage: t('T_CARE_PLAN_UPDATED_SUCCESS').replace('%s', payload.title),
              isToastActive: true,
              toastType: 'success'
            })
          );
          setIsLoading(false);
          setIsEdit(false);
        } else {
          dispatch(
            setToastData({
              toastMessage: getFailureMessage(response),
              isToastActive: true,
              toastType: 'error'
            })
          );
        }
      }
    } catch (e: any) {
      dispatch(
        setToastData({
          toastMessage: t('T_SERVER_ERROR'),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const toggleStatus = (ev: any) => {
    switch (ev.toLowerCase()) {
      case 'active':
        setSelectedOption('activate');
        break;
      case 'draft':
        setSelectedOption('draft');
        break;
      case 'unknown':
        setSelectedOption('unknown');
        break;
    }
  };

  const DateInputField = forwardRef(
    ({ onClick, formError, disabled, placeholder, label, fieldKey, overrideValue }: any, ref) => (
      <InputField
        overrideValue={overrideValue}
        className="w-full"
        type="text"
        id="max-word-count"
        label={label}
        placeholder={placeholder}
        fieldKey={fieldKey}
        required={false}
        formData={formData}
        setFormData={setFormData}
        formError={formError}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        onBlurhandler={(e: any) => {}}
        handleChange={() => {}}
        CustomIcon={CalendarIcon}
      />
    )
  );

  const setStartandEnd = () => {
    const { periodEnd, periodStart } = formData;

    const handleDateChange = (date: any, key: string) => {
      const updatedDate = new Date(date);
      dispatch(setFormData({ [key]: updatedDate.toISOString() }));
    };

    const handleTimeChange = (time: any, key: string) => {
      const updatedTime = new Date(time);
      dispatch(setFormData({ [key]: updatedTime.toISOString() }));
    };

    return (
      <div>
        <div className="mt-4 flex w-full flex-row justify-between">
          <div className="w-[49%]">
            <DatePicker
              selected={periodStart ? new Date(periodStart) : null}
              onChange={(date: any) => handleDateChange(date, 'periodStart')}
              fixedHeight
              minDate={new Date()}
              showDisabledMonthNavigation
              shouldCloseOnSelect={false}
              calendarClassName={'text-base'}
              customInput={
                <DateInputField
                  overrideValue={
                    periodStart
                      ? moment(periodStart).format(AppConstants.DEFAULT_CAREPLAN_DATE_FORMAT)
                      : ''
                  }
                  fieldKey="periodStart"
                  formError={{ periodStart: '' }}
                  label={t('T_CARE_PLAN_START_DATE')}
                />
              }
            />
          </div>
          <div className="w-[49%]">
            <DatePicker
              selected={periodStart ? new Date(periodStart) : null}
              onChange={(date: any) => handleTimeChange(date, 'periodStart')}
              fixedHeight
              showTimeSelect
              showTimeSelectOnly
              calendarClassName={'text-base'}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              customInput={
                <DateInputField
                  overrideValue={
                    periodStart
                      ? moment(periodStart).format(AppConstants.DEFAULT_CAREPLAN_TIME_FORMAT)
                      : ''
                  }
                  fieldKey="periodStart"
                  formError={{ periodStart: '' }}
                  label={t('T_CARE_PLAN_START_TIME')}
                />
              }
            />
          </div>
        </div>
        <div className="mt-4 flex w-full flex-row justify-between">
          <div className="w-[49%]">
            <DatePicker
              selected={periodEnd ? new Date(periodEnd) : null}
              onChange={(date: any) => handleDateChange(date, 'periodEnd')}
              fixedHeight
              minDate={new Date(periodStart)}
              showDisabledMonthNavigation
              shouldCloseOnSelect={false}
              calendarClassName={'text-base'}
              customInput={
                <DateInputField
                  overrideValue={
                    periodEnd
                      ? moment(periodEnd).format(AppConstants.DEFAULT_CAREPLAN_DATE_FORMAT)
                      : ''
                  }
                  fieldKey="periodEnd"
                  formError={{ periodEnd: '' }}
                  label={t('T_CARE_PLAN_END_DATE')}
                />
              }
            />
          </div>
          <div className="w-[49%]">
            <DatePicker
              selected={periodEnd ? new Date(periodEnd) : null}
              onChange={(date: any) => handleTimeChange(date, 'periodEnd')}
              fixedHeight
              showTimeSelect
              showTimeSelectOnly
              calendarClassName={'text-base'}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              customInput={
                <DateInputField
                  overrideValue={
                    periodEnd
                      ? moment(periodEnd).format(AppConstants.DEFAULT_CAREPLAN_TIME_FORMAT)
                      : ''
                  }
                  fieldKey="periodEnd"
                  formError={{ periodEnd: '' }}
                  label={t('T_CARE_PLAN_END_TIME')}
                />
              }
            />
          </div>
        </div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              dispatch(setFormData({ periodStart: '', periodEnd: '' }));
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={'border border-solid border-white bg-primary text-white'}
            type="button"
            disabled={false}
            onClick={() => {
              setSelectedOption('');
              // editTemplate();
            }}
          >
            {t('T_ACTIVATE')}
          </BiButton>
        </div>
      </div>
    );
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <div className="flex w-4/5 flex-col  overflow-visible px-2 py-3">
        <MenuButtonsPortal>
          <>
            <BiButton
              className={'mr-2 text-primary'}
              type="button"
              onClick={() => {
                if (params.id) {
                  if (isEdit) {
                    setIsEdit(false);
                  } else {
                    navigate(RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_LIST);
                  }
                } else {
                  navigate(RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_LIST);
                }
              }}
            >
              {params?.id && !isEdit ? t('T_CARE_PLAN_LIST_BACK') : t('T_CANCEL')}
            </BiButton>

            {isEdit ? (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={savePayload}
                disabled={validator()}
              >
                {t('T_SAVE')}
              </BiButton>
            ) : roleAPIPermissions[AppConstants.PERMISSION_CAREPLAN_TEMPLATE_UPDATE] ? (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => setIsEdit(true)}
              >
                {t('T_EDIT')}
              </BiButton>
            ) : (
              ''
            )}
          </>
        </MenuButtonsPortal>
        <ToastNotification
          message={toastData.toastMessage}
          isActive={toastData.isToastActive}
          setIsActive={() =>
            dispatch(
              setToastData({
                toastMessage: '',
                isToastActive: false,
                toastType: null
              })
            )
          }
          status={toastData.toastType}
        />

        <Modal
          showCloseIcon={true}
          width={'50rem'}
          headerText={t('T_CARE_PLAN_DATE_PICKER')}
          toggleVisiblity={(value: boolean) => {
            setSelectedOption('');
          }}
          isVisible={['activate'].includes(selectedOption)}
          body={setStartandEnd()}
          headerLeftIcon={
            selectedOption === 'delete' ? <ExclamationIcon className="h-7.5 w-6" /> : ''
          }
        />

        <Modal
          showCloseIcon={true}
          width={'30rem'}
          headerText={t('T_CONFIRM')}
          toggleVisiblity={(value: boolean) => {
            setSelectedOption('');
          }}
          isVisible={['delete'].includes(selectedOption)}
          body={deleteCareplanUI()}
          headerLeftIcon={
            selectedOption === 'delete' ? <ExclamationIcon className="h-7.5 w-6" /> : ''
          }
        />

        <div className="border-1 overflow-auto rounded-md border-solid border-gray-300">
          <TabDrawer
            borderBottom={true}
            tabConfig={tabConfig}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>

      <div className="flex w-1/5 flex-col pl-5 pt-5">
        <>
          {params?.id && !isLoading && (
            <div className="w-60">
              <>
                <PickList
                  fieldKey={'key'}
                  label={t('T_STATUS')}
                  required={false}
                  fieldValues={['value']}
                  selectedItems={formData?.status}
                  onSelectedChange={(item: any) => {
                    const [statusVal] = item;
                    dispatch(setFormData({ status: item }));
                    //
                  }}
                  items={AppConstants.CAREPLAN_STATUS}
                  disabled={!isEdit}
                  readOnly={false}
                  hasMore={false}
                  type={PICKLIST_TYPES.SINGLE}
                  style={
                    isEdit && !isEmpty(statusField[formData?.status?.[0]?.key])
                      ? {
                          backgroundColor: statusField[formData?.status?.[0]?.key]?.color
                        }
                      : {}
                  }
                />
                {!isEmpty(statusField[formData?.status?.[0]?.key]) ? (
                  <div className="mt-0.5 text-sm" style={{ color: '#8C8C8C' }}>
                    {t(statusField[formData?.status?.[0]?.key]?.helper)}
                  </div>
                ) : (
                  ''
                )}
              </>
            </div>
          )}
          {isEdit && params?.id && formData?.status?.[0]?.key === 'active' && (
            <div className="mt-6">
              <BiButton
                disabled={!isEdit}
                className={'w-60 border border-solid border-[#0074E0] bg-[#DCEEFE] text-[#0074E0]'}
                type="button"
                onClick={() => {
                  toggleStatus('active');
                }}
              >
                {t('T_SURVEY_SET_DATES')}
              </BiButton>
            </div>
          )}
          {params?.id && (
            <div className="mt-6">
              <>
                {(auditData?.createdOn || auditData?.createdBy) && (
                  <div>
                    <AuditInfo
                      label={'CREATED'}
                      date={auditData?.createdOn || ''}
                      user={auditData?.createdBy || ''}
                    />
                  </div>
                )}
                {(auditData?.updatedOn || auditData?.updatedBy) && (
                  <div>
                    <AuditInfo
                      label={'LAST_MODIFIED'}
                      date={auditData?.updatedOn || ''}
                      user={auditData?.updatedBy || ''}
                    />
                  </div>
                )}
                {auditData.periodStart && (
                  <div className="mt-2 w-3/5 text-sm text-[#595959]">
                    <p>{t('T_START_DATE_TIME')}</p>
                    <p>{auditData.periodStart}</p>
                  </div>
                )}
                {auditData.periodEnd && (
                  <div className="mt-2 w-3/5 text-sm text-[#595959]">
                    <p>{t('T_END_DATE_TIME')}</p>
                    <p>{auditData.periodEnd}</p>
                  </div>
                )}
                {auditData.createdByOrg && (
                  <div className="mt-2 w-3/5 text-sm text-[#595959]">
                    <p>{t('T_CREATED_BY_ORG')}</p>
                    <p>{auditData.createdByOrg}</p>
                  </div>
                )}
              </>
            </div>
          )}
          {formData?.status !== 'draft' && params?.id ? (
            <div className="mt-6">
              <BiButton
                className={
                  'w-60 border border-solid border-red-600 bg-white text-danger hover:bg-red-600 hover:text-white'
                }
                type="button"
                onClick={() => {
                  setSelectedOption('delete');
                }}
              >
                {t('T_DELETE')}
              </BiButton>
            </div>
          ) : (
            ''
          )}
        </>
      </div>
    </LoaderContainer>
  );
};

export default CarePlanTemplate;
