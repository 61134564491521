import { ITableHeader } from '../components/shared/tables/models/ITableHeader';

export const getSortTableArray = (data: ITableHeader[]) => {
  let sortArray: string[] = [];

  data.forEach((ele: any) => {
    if (ele.sortingInformation?.order) {
      sortArray.push(`&sort=${ele.key},${ele.sortingInformation?.order}`);
    }
  });

  return sortArray;
};

export const convertArrToString = (arr: any) => {
  return arr.map((element: any) => (typeof element === 'string' ? element : String(element)));
};