import React, { useEffect, useState } from 'react';
import Card from '../shared/cards/Card';
import { useNavigate, useParams } from 'react-router-dom';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import styled from 'styled-components/macro';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { library } from '../../assets/components/svgs';
import userIcon from '../../assets/user-group.svg';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import InputField from '../shared/Fields/InputField';
import FieldListTable from './fieldListTable';
import moment from 'moment';
import { setURLPaths } from '../../app/organizationReducer';
import { getOrganization } from '../../http/organization-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { RouteConstants } from '../../constants/RouteConstants';
import Checkbox from '../shared/Fields/Checkbox';
import AuditInfo from '../Common/AuditInfo';

function CardSection({ children }: any) {
  return (
    <div
      className={`flex w-fit flex-col justify-start  overflow-visible bg-gray-100 pt-2`}
      style={useTheme().theme.bgColorStyleForLoggedInPage}
    >
      <div className="flex">
        <div className="flex">
          <div className="p-2"></div>
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  );
}

/**
 * @parentId consent-management
 * @manager View - Field Group
 * @overview View the Field Group details
 *   <section>
 *       <p>
 *           <b>View Field Group</b><br/>
 *           Clicking on field group row on the field group listing page will take user to View Field Group Page in READ-ONLY mode. Clicking on <b>Back to Field Group List</b> will take the user to field group listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i>
 *           <br/>
 *       </p>
 *
 *       <p>
 *          <h4>View Field Group Fields</h4>
 *          View Field Group page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Resource Type</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Is Default</td>
 *               <td>Yes</td>
 *               <td>Boolean</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> While viewing an field group details there is one more ddategrid table in page which shows all the fields related to specifiec field group.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Field Group Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of view Field Group page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for View Field Group.</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Access Control Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *           <tr>
 *               <td>Organization Service</td>
 *               <td>1.3.1</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for View Field Group page</p>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Field Group</td>
 *               <td>/field-groups/{id}</td>
 *               <td>GET</td>
 *               <td>accesscontrol-service.fieldgroup.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>List users (View Field Group)</td>
 *               <td>/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for view Field Group</p>
 *   </section>
 */

function FieldGroupDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [org, setOrg] = useState<string>('-');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fieldGroupData = useAppSelector((state) => state.consent.fieldGroup);

  useEffect(() => {
    if (!fieldGroupData.name) {
      navigate(RouteConstants.ROUTE_CONSENTS_FIELDGROUP);
    } else {
      dispatch(
        setURLPaths([
          {
            key: `${RouteConstants.ROUTE_CONSENTS_FIELDGROUP}/${params.id}`,
            label: `Field Groups > ${fieldGroupData.name}`
          }
        ])
      );

      GetOrganizationName();
    }
  }, [fieldGroupData]);

  const formErrorData: any = {};

  const GetUpdatedBy = () => {
    const { createdBy, updatedBy, createdByUser } = fieldGroupData;
    return (createdBy === updatedBy && createdByUser) || '-';
  };

  const GetOrganizationName = async () => {
    let response = await getOrganization(fieldGroupData.createdByOrg);
    const { data = {}, status } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      setOrg(data.name);
    }
    setIsLoading(false);
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <MenuButtonsPortal>
        <>
          {
            <div
              className="mr-4 cursor-pointer text-sm leading-10 text-primary hover:text-sky-500"
              onClick={() => navigate(RouteConstants.ROUTE_CONSENTS_FIELDGROUP)}
            >
              {t('T_RETURN_FIELDGROUP')}
            </div>
          }
        </>
      </MenuButtonsPortal>
      <div className="flex">
        <div>
          <div
            className="w-fit bg-gray-100 pt-5"
            style={useTheme().theme.bgColorStyleForLoggedInPage}
          >
            <CardSection>
              <Card
                title={t('T_FIELD_GROUP_DETAILS')}
                icon={userIcon}
                alt={t('T_USER_CARD_ALT_MESSAGE')}
                height=""
                width=""
              >
                <div className="relative" style={{ width: '750px' }}>
                  <div className="mb-4 w-full">
                    <InputField
                      className="w-full"
                      type="text"
                      id={'name'}
                      label={t('T_NAME')}
                      placeholder={t('T_NAME')}
                      fieldKey="name"
                      required={false}
                      formData={fieldGroupData}
                      formError={formErrorData}
                      setFormData={() => {}}
                      disabled={true}
                    />
                  </div>
                  <div className="flex">
                    <div className="mb-4 w-3/4">
                      <InputField
                        className="w-full"
                        type="text"
                        id={'resourceType'}
                        label={t('T_RESOURCE_TYPE')}
                        placeholder={t('T_RESOURCE_TYPE')}
                        fieldKey="resourceType"
                        required={false}
                        formData={fieldGroupData}
                        formError={formErrorData}
                        setFormData={() => {}}
                        disabled={true}
                      />
                    </div>

                    <div className="mb-2.5 flex w-1/4 items-center justify-center pt-5">
                      <div className="flex leading-5">
                        {t('T_IS_DEFAULT')}{' '}
                        <div className="ml-2 flex flex-row">
                          <Switch onClick={() => {}} data-testid={'boolean-field'} id={'isDefault'}>
                            <ToggleButton toggle={fieldGroupData.isDefault} />
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </CardSection>
          </div>

          <div
            className="w-full bg-gray-100 pt-[20px]"
            style={useTheme().theme.bgColorStyleForLoggedInPage}
          >
            <CardSection>
              <Card
                title={t('T_FIELDS')}
                icon={library}
                alt={t('T_CARD_ALT_MESSAGE')}
                height=""
                width=""
                bodyStyle={{ padding: '0px' }}
              >
                <div className="relative flex-wrap" style={{ width: '790px' }}>
                  <FieldListTable fieldList={fieldGroupData.fields} />
                </div>
              </Card>
            </CardSection>
          </div>
        </div>

        <div className="mt-[25px] flex w-[33%] flex-col">
          <div className="ml-5">
            {(fieldGroupData?.createdOn || fieldGroupData?.createdBy) && (
              <div>
                <AuditInfo
                  label={'CREATED'}
                  date={fieldGroupData?.createdOn || ''}
                  user={fieldGroupData?.createdBy || ''}
                />
              </div>
            )}
            <div className="my-2.5">
              <div>{t('T_CREATED_BY_ORG')}</div>
              <div className="font-light">{org}</div>
            </div>
            {(fieldGroupData?.updatedOn || fieldGroupData?.updatedBy) && (
              <div>
                <AuditInfo
                  label={'LAST_MODIFIED'}
                  date={fieldGroupData?.updatedOn || ''}
                  user={fieldGroupData?.updatedBy || ''}
                  isUserId={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </LoaderContainer>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Switch = styled.div`
  width: 44px;
  height: 22px;
  background: #BFBFBF;
  border-radius: 16px;
  position: relative;
  transition: ease-out 0.5s;
  disabled
`;

const ToggleButton = styled.div<{ toggle: boolean }>`
  position: absolute;
  height: 18px;
  top: calc(50% - 18px / 2);
  background: #ffffff;
  border-radius: 16px;
  width: 16px;
  ${({ toggle }) => (!toggle ? `left: 2px;` : 'right: 2px;')}
`;
export default FieldGroupDetails;
