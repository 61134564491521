import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { useNavigate } from 'react-router-dom';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import NewPermissionSet from './NewPermissionSet';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  setPermissionData,
  setPagingOpt,
  setPermissionSetList,
  setIsContentChanged
} from '../../app/permissionSetReducer';
import { setToastData } from '../../app/toastReducer';
import { deleteSystemSetting, getSystemSettings } from '../../http/configuration-services';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { SystemSetting } from '../../util/RoleUtil';
import { RouteConstants } from '../../constants/RouteConstants';
import { useQuery } from '../../hooks/queryParams';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { find, isEmpty } from 'lodash';
import { getRole } from '../../http/access-management-service';
import { loadPermissionSetList } from './PermissionSetUtil';

import { EllipsisDiv } from '../../styles/globalStyles';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { deleteMetadataInstance } from '../../http/survey-service';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { RolePermission } from '../Organizations/helpers';
import AddCircle from '../../assets/addCircle.svg';
import moment from 'moment';

const Container = styled.div`
  padding: 1rem;
  width: 100%;
`;

/**
 * @parentId permission set
 * @manager Permission Set Listing
 * @overview
 *   <section>
 *       <p>
 *           Permission Set listing is the default page when the user is navigated on clicking <b>Permission Set</b> navigation bar menuitem. Listing page will show all the created permission sets in a paginated way.
 *           The default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & permission set data.<br/>
 *            Additionally user can also search on permission set.
 *       </p>
 *       <br>
 *
 *        <p>
 *           Datagrid will display details of each Permission set as row item with each column representing permission set property.
 *           <br/>
 *           <ul>
 *               <li>Permission Set Name</li>
 *               <li>Application Role Assigned</li>
 *               <li>Description</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *           <br/>
 *           <b>Note:</b> Permission Set and role is one to one mapping, by default when the permission set is created no role is assigned,Assignment of role to permission set happens while creating the role.
 *       </p>
 *       <p>
 *           Sorting of permission sets is supported on <i>Last Modified On</i> column. 
*        </p>
 *     </section>
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes the permission set list Status Code information.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of permission set listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for permission set listing</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for listing configuration keys page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *            <tr>
 *               <td>/applications</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-application.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/applications/{appId}/objects</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-object.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/applications/{appId}/objects/{objId}/instances</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-instances.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for permission set listing page</p>
 *   </section>
 */

const PermissionSetList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [pagingOpts, setPagingOpts] = useState({});
  const [page, setPage] = useState(AppConstants.DEFAULT_PAGE);
  const [size, setSize] = useState(AppConstants.DEFAULT_PAGE_SIZE);

  const [openModal, setOpenModal] = useState(false);

  const [rows, setRows] = useState<Array<any>>([]);
  const [contentChanged, setContentChanged] = useState<boolean>(false);
  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);

  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const permissionSetInstances: any = find(metaDataObjectList, { name: 'PermissionSet' });
  const permissionSetList = useAppSelector((state) => state.permissionSet.permissionSetList);
  const permissionSetPagingData = useAppSelector((state) => state.permissionSet.pagingOpt);
  const noChanges = useAppSelector((state) => state.permissionSet.noChanges);
  const rolePermissions = useAppSelector<any>((state: any) => {
    return state?.login?.rolePermissions;
  });

  const apiRolePermissions = useAppSelector((state) => state.initialLoadData.apiPermissions);

  const { t } = useTranslation();

  useEffect(() => {
    if (contentChanged) {
      setPage(AppConstants.DEFAULT_PAGE);
    }
    const init = async () => {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: RouteConstants.ROUTE_PERMISSION_SET_LISTING,
            label: `${t('T_PERMISSION_SETS')}`
          }
        ])
      );
      // const rolesResponse = await listRoles(
      //   '',
      //   AppConstants.DEFAULT_PAGE,
      //   AppConstants.MAXIMUM_PAGE_SIZE
      // );
      // if (rolesResponse.status === HTTP_STATUS.HTTP_OK) {
      //   const {
      //     data: { data: rolesArray = [] }
      //   } = rolesResponse;
      //   console.log(rolesArray);
      //   dispatch(setRoles(rolesArray));
      // }
      invokeAPI(
        AppConstants.CATEGORY_PERMISSION_SETS,
        contentChanged ? AppConstants.DEFAULT_PAGE : page,
        size,
        '',
        'desc',
        permissionSetList && noChanges ? true : false
      ); // page, size
    };
    init();
  }, [contentChanged]);

  const invokeAPI = async (
    category: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC,
    refreshData: boolean = true,
    name: string = ''
  ) => {
    const responseList: any = await loadPermissionSetList(
      2,
      { category, page, size, getRoles, setPagingOpts, dispatch, setIsLoading },
      { permissionSetInstances, page, size, name },
      [sort, sortType]
    );
    const { flag, response } = responseList;
    const { status, data } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: systemSettings = [], paging } = data;
      if (!systemSettings.length) {
        noDataAvailableMessage(setRows, setSize, setPagingOpts, paging, t);
        setIsLoading(false);
      } else {
        getRoles(flag, systemSettings, page, size);
        setPagingOpts(paging);
      }
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
      setIsLoading(false);
    }
  };

  const getRoles = async (
    flag: number,
    systemSettings: Array<SystemSetting>,
    page: number,
    size: number
  ) => {
    let roleIds: any = new Set<string>();
    switch (flag) {
      case 1:
        for (let i = 0; i < systemSettings.length; i++) {
          if (systemSettings[i].value.roles.length) {
            roleIds.add(systemSettings[i].value.roles[0].roleId);
          }
        }

        roleIds = [...Array.from(roleIds)];
        if (!roleIds.length) {
          setRows(systemSettings);
          setIsLoading(false);
          return;
        }

        Promise.all(roleIds.map((roleId: string) => getRole(roleId)))
          .then(async (responses) => {
            const roleIdMap: Map<string, any> = new Map();
            responses.forEach((response: any) => {
              if (response?.status == HTTP_STATUS.HTTP_OK) {
                roleIdMap.set(response?.data.id, response?.data);
              }
            });

            setRows(
              systemSettings.map((systemSetting: any) => {
                const roleId = systemSetting?.value?.roles[0]?.roleId;
                const role = roleIdMap.get(roleId);
                return roleId
                  ? {
                      ...systemSetting,
                      role
                    }
                  : {
                      ...systemSetting,
                      role: ''
                    };
              })
            );
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 2:
        for (let i = 0; i < systemSettings.length; i++) {
          if (systemSettings[i].attributes.roles.length) {
            roleIds.add(systemSettings[i].attributes.roles[0]);
          }
        }

        roleIds = [...Array.from(roleIds)];
        // if (!roleIds.length) {
        setRows(systemSettings);
        setIsLoading(false);
        // return;
        // }

        // Promise.all(roleIds.map((roleId: string) => getRole(roleId)))
        //   .then(async (responses) => {
        //     const roleIdMap: Map<string, any> = new Map();
        //     responses.forEach((response: any) => {
        //       if (response?.status == HTTP_STATUS.HTTP_OK) {
        //         roleIdMap.set(response?.data.id, response?.data);
        //       }
        //     });

        //     setRows(
        //       systemSettings.map((systemSetting: any) => {
        //         const roleId = systemSetting?.attributes?.roles?.[0];
        //         const role = roleIdMap.get(roleId);
        //         return roleId
        //           ? {
        //               ...systemSetting,
        //               role
        //             }
        //           : {
        //               ...systemSetting,
        //               role: ''
        //             };
        //       })
        //     );
        //     setIsLoading(false);
        //   })
        //   .catch((err) => {
        //     setIsLoading(false);
        //   })
        //   .finally(() => {
        //     setIsLoading(false);
        //   });
        break;
    }
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc'
  ) => {
    if(!isEmpty(sort)){
      setTableValues(name, page, size, sort, sortType);
    }else{
      setTableValues(name, page, size);
    }
  };

  const setTableValues = (
    name: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setSize(size);
    setPage(page);
    setIsLoading(true);
    invokeAPI(AppConstants.CATEGORY_PERMISSION_SETS, page, size, sort, sortType, false, name);
  };

  const PERMISSION_SET_HEADERS: Array<ITableHeader> = [
    {
      key: 'name',
      description: t('T_DESC_FOR_PERMISSION_HEADER'),
      sortEnabled: false,
      width: '25%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return data?.attributes?.name;
      }
    },
    {
      key: 'role',
      description: t('T_DESC_FOR_APP_ROLE_ASSIGNED'),
      sortEnabled: false,
      width: '25%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return data?.role
          ? data?.role?.name
          : data?.attributes?.roles?.[0]
          ? data?.attributes?.roles?.[0]
          : '';
      }
    },
    {
      key: 'description',
      description: t('T_DESCRIPTION'),
      sortEnabled: false,
      width: '50%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return (
          <EllipsisDiv title={data?.attributes?.description}>
            {data?.attributes?.description}
          </EllipsisDiv>
        );
      }
    },{
      key: 'updatedOn',
      description: 'T_LAST_MODIFIED_ON',
      sortEnabled: true,
      render: (data: any) => {
        return data.attributes.updatedOn
          ? moment(data.attributes.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '';
      },
      sortingInformation: {
        order: '',
      },
      width: '25%'
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: false,
    //   width: '25%',
    //   render: (data: any) => {
    //     return data?.attributes?.updatedBy;
    //   }
    // }
  ];

  const query = useQuery();
  if (query.get('devmode')) {
    PERMISSION_SET_HEADERS.push({
      key: 'buttons',
      description: t('T_ACTIONS'),
      sortEnabled: false,
      width: '13%',
      sortingInformation: {
        order: ''
      }
    });
  }

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(PERMISSION_SET_HEADERS);

  const showPermissionSet = (systemSetting: any) => {
    dispatch(setPermissionData(systemSetting));
    navigate(`/permission/${systemSetting.id}`);
  };

  const removePermissionSet = async (index: number) => {
    const row: any = rows[index];
    if (row) {
      let error = false;
      const { id, label, metadataObjectId } = row;
      let toastMessage = `${label} ${t('T_DELETED_SUCCESS_MESSAGE')}`;
      const response = await deleteMetadataInstance(metadataObjectId, id);
      const { status } = response;
      if (status == HTTP_STATUS.HTTP_OK) {
        rows.splice(index, 1);
        setRows([...rows]);
      } else {
        error = !error;
        toastMessage = getFailureMessage(response);
      }
      dispatch(
        setToastData({
          toastMessage,
          isToastActive: true,
          toastType: error ? 'error' : 'success'
        })
      );
    }
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <MenuButtonsPortal>
          {apiRolePermissions[AppConstants.PERMISSION_ROLE_PERMISSION_CREATE] ? (
            <BiButton
              className={'flex flex-row bg-primary text-white'}
              type="button"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <img src={AddCircle} className="mr-2 w-6 text-center"></img>
              {t('T_BUTTON_NEW_PERMISSION_SET')}
            </BiButton>
          ) : (
            ''
          )}

          <NewPermissionSet
            setNewDataLoaded={(check: any) => {
              setContentChanged(check);
            }}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </MenuButtonsPortal>

        <Container>
          <TemplateTable
            tableData={rows}
            templateSelector={showPermissionSet}
            onRefreshTableData={refreshTableData} //refreshTableData
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            searchPlaceholder={t('T_SEARCH_BY_PERMISSION_SET') || ''}
            isSearchable={true}
            size={size.toString()}
            currentPage={page.toString()}
            pagingData={pagingOpts}
            containerClass="mt-0"
            showDeleteButton={true}
            deleteOnClick={removePermissionSet}
            theme={useTheme().theme}
          />
        </Container>
      </LoaderContainer>
    </>
  );
};

export default PermissionSetList;
