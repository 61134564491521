import { AppConstants } from '../constants/AppConstants';
import { uniq,flatten, find } from 'lodash';

export const modifyQuestionTypes = (questionMetaData: any) => {
  const questionTypesObj: any = {};
  questionMetaData.forEach((obj: any) => {
    const { attributes } = obj;
    const { type, active } = attributes;
    if (active) {
      questionTypesObj[type] = { ...attributes, icon: type };
    }
  });
  return questionTypesObj;
};

export const getLoggedInRolePermissionSet = (permissionSet: any, loggedInRole: string) => {
  let instanceObject: any;
  instanceObject = find(permissionSet, (permissionObj) => {
    const {attributes} = permissionObj
    const {roles = []} = attributes
    if(roles.length && roles.includes(loggedInRole)) {
      return permissionObj
    }
  })

  return instanceObject?.attributes?.permissions || [];
}

export const getAdminPortalFeatures = (featuresInstanceList: any) => {
    const adminPortalFeatureObject: any = {
      'features': []
    }

    featuresInstanceList.forEach((instance: any) => {
      const {attributes} = instance;
      const {active = true} = attributes
      if(active){
        adminPortalFeatureObject.features.push(instance.attributes)
      }
    })

    return adminPortalFeatureObject;
}

// export const getPermittedItems = (
//   menuArray: Array<any> = [],
//   permsArray: Array<any> = [],
//   permissions: any,
//   appendData: boolean = false
// ) => {

//   let tempArray: any = [];
//   const featureArray: any = permsArray.map((item: any) => [
//     item?.feature || item?.name,
//     ...(tempArray = (item?.function || item?.functions)?.map((e: any) => e?.apiPermissions))
//   ]);

//   const featureAccessMap = new Map<string, any>(featureArray);

//   const permitedItems: any = menuArray.filter((menuItem: any) => {
//     const { name } = menuItem;
//     if(featureAccessMap.get(name)) {
//       return featureAccessMap.get(name).some((item: any) => {
//         switch (name) {
//           case 'Organizations':
//             return permissions?.[AppConstants.PERMISSION_ORGANIZATION_LIST_CHILDREN]?.apiPermission;
//           case 'Administrators':
//             return permissions?.[AppConstants.PERMISSION_ROLE_LIST]?.apiPermission;
//           case 'Notification Templates':
//             return permissions?.[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_LIST]?.apiPermission;
//           case 'DMS Settings':
//           case 'Application Roles':
//           case 'Consent Management':
//             return permissions?.[AppConstants.PERMISSION_ROLE_LIST]?.apiPermission;
//           case 'Permission Sets':
//             return permissions?.[AppConstants.PERMISSION_CONFIG_SERVICE_SETTING_LIST]?.apiPermission;
  
//           default:
//             return permissions?.[item]?.apiPermission;
//         }
//       });
//     }
//   });

//   return [...permitedItems];
// };

export const getPermittedItems = (
  menuArray: Array<any> = [],
  permsArray: Array<any> = [],
  permissions: any,
  appendData: boolean = false
) => {

  let tempArray: any = [];

  const menuArrayApiPermission: any = new Map(menuArray.map((item) => [
    item?.name,
    (uniq(flatten(item.functions.map((feature : any) => feature.permissions)).map((apiPermissions: any) => apiPermissions.claim)))
  ]));

  const featureAccessMap = new Map(permsArray.map((item: any) => [
    item?.feature,
    (tempArray = (item?.function || item?.functions)?.map((e: any) => e?.action))
  ]))

  const permitedItems: any = menuArray.filter((menuItem: any) => {
    const { name } = menuItem;
    if(featureAccessMap.get(name)) {
      if(featureAccessMap.get(name).includes('All')){
        let isServiceAvailable = true
        menuArrayApiPermission.get(name).forEach((featurePermission : any) => { 
          if(!permissions[featurePermission]){
            isServiceAvailable = false;
          }
        })
        if(isServiceAvailable)  {
          return menuItem
        }
      } else {
        //This block is for future changes for feature granular level like checking permission for list, create, edit etc
      }
    }
  });

  return [...permitedItems];
};

