import React, { useEffect, useState } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import MenuBottom from './MenuBottom';
import { ContentMain, StyledHeaderMenu } from './MenuStyles';
import MenuTop from './MenuTop';
import Sidebar from './Sidebar';
import ToastNotification from '../Reusable/ToastNotification';
import { setToastData } from '../../app/toastReducer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTheme } from '../../context/themeContext';

type Props = {
  children: React.ReactNode;
  breadcrumbs?: JSX.Element;
  buttons?: JSX.Element;
  email?: JSX.Element;
  modalIsActive?: boolean;
};

const Menu = ({ children, breadcrumbs, buttons, email }: Props) => {
  const [width] = useWindowSize();
  const [sidebarIsActive, setSidebarIsActive] = useState(false);
  const toastData = useAppSelector((state) => state.toast);
  const apiPermissions: any =
    useAppSelector((state) => state.initialLoadData?.apiPermissions) || {};
  const metaDataPermissions =
    useAppSelector((state) => state.initialLoadData?.metaDataPermissionSet) || {};
  const adminFeatures = useAppSelector((state: any) => {
    return JSON.parse(state?.login?.adminFeatures || '{}');
  });
  const dispatch = useAppDispatch();
  const theme = useTheme().theme;

  useEffect(() => {
    if (width > 1440) {
      setSidebarIsActive(true);
    } else {
      setSidebarIsActive(false);
    }
  }, [width]);

  return (
    <>
      <StyledHeaderMenu>
        <MenuTop />
        <MenuBottom
          sidebarIsActive={sidebarIsActive}
          setSidebarIsActive={setSidebarIsActive}
          breadcrumbs={breadcrumbs}
          buttons={buttons}
        />
      </StyledHeaderMenu>
      {Object.keys(apiPermissions).length &&
      Object.keys(metaDataPermissions).length &&
      Object.keys(adminFeatures).length ? (
        <Sidebar sidebarIsActive={sidebarIsActive} />
      ) : (
        <div />
      )}
      <ContentMain isActive={sidebarIsActive} theme={theme}>
        {children}
      </ContentMain>

      <ToastNotification
        message={toastData.toastMessage}
        isActive={toastData.isToastActive}
        setIsActive={(value) => {
          dispatch(
            setToastData({
              toastMessage: toastData.toastMessage,
              isToastActive: false,
              toastType: toastData.toastType
            })
          );
        }}
        status={toastData.toastType}
      />
    </>
  );
};

export default Menu;
