import { useState, useCallback } from 'react';
import Modal from '../shared/ModalPortal/Modal';
import { setToastData } from '../../app/toastReducer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import UploadContracts from '../ManageContracts/UploadContracts';
import { createSignedURL, uploadFile } from '../../http/asset-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { AppConstants } from '../../constants/AppConstants';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/themeContext';
import { getUserName } from '../../util/admin-utils';

interface Pagination {
  first: String;
  next: String;
  page: Number;
  previous: String;
  size: Number;
}

interface ResponseBody {
  data: Array<ContractFileInfo>;
  paging: Pagination;
}
interface ContractFileInfo {
  cacheControl: String;
  contentType: String;
  createTime: Date;
  currentVersion: boolean;
  generation: String;
  name: String;
  size: Number;
  type: String;
  updateTime: Date;
  url: String;
}

interface UploadFilesProps {
  bucket: any;
  isVisible: boolean;
  toggleVisiblity: Function;
  fileLocation: string;
  showMetadata: boolean;
  theme?: any;
}

export interface FileInfo {
  file: File;
  metadata: any;
  versioning: boolean;
  publicObject: boolean;
  issues?: Array<any>;
}

function UploadFiles({
  isVisible,
  toggleVisiblity,
  bucket,
  fileLocation,
  theme,
  showMetadata = false
}: UploadFilesProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loader, setLoader] = useState(false);
  const [files, setFiles] = useState<Array<FileInfo>>([]);
  // const [FilesDocs, setFilesDocs] = useState<Array<FileInfo>>([]);

  const [fileError, setFileError] = useState({ errorText: '', fileName: '' });

  const setToastMsg = (msg: string, isActive: boolean, type: string) => {
    dispatch(setToastData({ toastMessage: msg, isToastActive: isActive, toastType: type }));
  };
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);

  type IUploadFiles = {
    uploadFileFailures?: Array<any>;
    uploadFilePasses?: Array<any>;
  };

  const uploadFiles = async (
    files: FileInfo[],
    bucketName: string,
    documentType: string,
    t?: any,
    filePath?: string
  ): Promise<IUploadFiles> => {
    const uploadFilePasses: any = [];
    const uploadFileFailures: any = [];
    if (files.length) {
      const fileUploadPromise = async (fileInfo: FileInfo, bucketName: string) => {
        const defaults: any = {
          // email: userProfile?.mail,
          // userId: userProfile?.userId,
          // name: getUserName(userProfile),
          // isdeleted: false
        };
        const { file, versioning = false, publicObject = false, metadata = {} } = fileInfo;
        const { name, type } = file;
        const body = {
          bucketName,
          versioning,
          publicObject,
          filePath: `${filePath}${name}`,
          action: AppConstants.GCS_UPLOAD_ACTION,
          metadata: { ...defaults, ...metadata },
          expirationTime: AppConstants.GCS_MAX_EXPIRATION_TIME
        };
        const response = await createSignedURL(body);
        const { status, data } = response;

        if (data) {
          console.log(status, data);
          const { url, headers = {}, issues = [] } = data;
          if (status == HTTP_STATUS.HTTP_CREATED) {
            const payload = {
              url,
              body: file,
              headers: { ...headers, [AppConstants.HEADER_CONTENT_TYPE]: type }
            };
            await uploadFile(payload);
            uploadFilePasses.push({ fileInfo });
            return {
              uploadFilePasses
            };
          } else {
            uploadFileFailures.push({ fileInfo, issues });
          }
        }
      };

      const forEachSeries = async (files: FileInfo[] = []) => {
        for (const file of files) {
          await fileUploadPromise(file, bucketName);
        }
      };

      await forEachSeries(files);
      // await Promise.all(filePromise);
      return {
        uploadFilePasses,
        uploadFileFailures
      };
    } else {
      return {
        uploadFilePasses,
        uploadFileFailures
      };
    }
  };

  const uploadFilesApi = async () => {
    setLoader(true);
    setFileError({ errorText: '', fileName: '' });
    try {
      const response = await uploadFiles(files, bucket?.name, 'file', t, fileLocation);
      const { uploadFileFailures = [], uploadFilePasses = [] } = response;
      if (uploadFileFailures.length) {
        setFiles([
          ...uploadFileFailures.map((failure: any) => {
            return { ...failure?.fileInfo, issues: failure?.issues };
          })
        ]);
        dispatch(
          setToastData({
            isToastActive: true,
            toastMessage: `${
              uploadFilePasses.length
                ? t('T_FILES_UPLOAD_PARTIAL_SUCCESS')
                : t('T_FILES_UPLOAD_ALL_FAILURE')
            }`
              .replace(
                '%s',
                `${uploadFilePasses.length ? uploadFilePasses.length : uploadFileFailures.length}`
              )
              .replace('%d', `${uploadFilePasses.length + uploadFileFailures.length}`),
            toastType: uploadFilePasses.length ? 'info' : 'error'
          })
        );
        setLoader(false);
      } else {
        const [first] = files;
        const index: number = first?.file?.name.lastIndexOf('/');
        let toastMessage: string =
          files.length > 1
            ? t('T_FILES_UPLOAD_PARTIAL_SUCCESS')
                .replace('%s', `${files.length}`)
                .replace('%d', `${files.length}`)
            : t('T_FILE_UPLOAD_SUCCESS').replace(
                '%s',
                index !== -1 ? first?.file?.name?.substring(index + 1) : first?.file?.name
              );
        setTimeout(() => {
          setFiles([]);
          toggleVisiblity(true);
          setLoader(false);
          dispatch(
            setToastData({
              toastMessage,
              isToastActive: true,
              toastType: 'success'
            })
          );
        }, 3000);
      }
    } catch (error: any) {
      setTimeout(() => {
        setLoader(false);
        setFiles([]);
        dispatch(
          setToastData({
            isToastActive: true,
            toastMessage: error,
            toastType: 'error'
          })
        );
      }, 3000);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
      const fileNames = files.map(({ file }: any) => file?.name);
      setFiles((files: any) => [
        ...files,
        ...acceptedFiles
          .filter((file: any) => !fileNames.includes(file.name))
          .map((file: File) => {
            return { file, versioning: false, publicObject: false };
          })
      ]);
      // setFilesDocs(
      //   acceptedFiles.map((file: File) => {
      //     return { file, versioning: false, publicObject: false };
      //   })
      // );
    },
    [files]
  );

  const removeFileFromUploadList = (selectedIndex: number, fileName: string) => () => {
    if (fileName == fileError.fileName) {
      setFileError({ errorText: '', fileName: '' });
    }
    setFiles((files: any) => files.filter((value: any, index: any) => index !== selectedIndex));
    // setFilesDocs((files: any) => files.filter((value: any, index: any) => index !== selectedIndex));
  };

  const updateFileInfo = (index: number, fileInfo: FileInfo) => {
    if (index !== -1) {
      files.splice(index, 1);
      files.splice(index, 0, fileInfo);
      setFiles([...files]);
    }
  };

  const onClose = () => {
    setFiles([]);
    toggleVisiblity();
  };

  return (
    <div>
      <Modal
        isVisible={isVisible}
        headerText={t('T_UPLOAD_FILES')}
        showCloseIcon={true}
        toggleVisiblity={() => onClose()}
        width="75rem"
        containerBackgroundColor={theme ? theme?.bgColorStyleForLoggedInPage?.backgroundColor : ''}
        headerBackgroundColor={theme ? theme?.bgColorStyleForLoggedInPage?.backgroundColor : ''}
        body={
          <UploadContracts
            key={'T_UPLOAD_NEW_FILES'}
            files={files}
            loader={loader}
            onDrop={onDrop}
            acceptAllFiles={true}
            fileError={fileError}
            updateFileInfo={updateFileInfo}
            bucketVersioning={bucket?.versioning}
            removeFileFromUploadList={removeFileFromUploadList}
            showMetadata={showMetadata}
          />
        }
        secondaryOnClick={() => onClose()}
        secondaryButtonText={'Cancel'}
        secondaryButtonWidth={'100px'}
        primaryButtonText={t('T_UPLOAD')}
        primaryButtonWidth={'100px'}
        primaryOnClick={uploadFilesApi}
        primaryButtonDisabled={files.length && !loader ? false : true}
      ></Modal>
    </div>
  );
}

export default UploadFiles;
