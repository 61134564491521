import styled from 'styled-components/macro';
import { css } from 'styled-components';
import {
  colorLightGray5,
  colorLightGray7,
  colorLightGray9,
  colorProductLink,
  colorProductPrimary,
  colorSecondaryHover,
  colorWhite,
  flexColumn,
  gridCenter
} from '../../styles/globalVariables';
import { device } from '../../styles/mediaQueries';
import { AccordionBody, ActiveProp, IOnClick } from '../Onboarding/interfaces';
import { TextLink } from '../Reusable/Shared';

/**
 * HeaderMenu
 */

export const StyledHeaderMenu = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-inline: auto;
  background-color: rgb(${colorWhite});
  position: sticky;
  top: 0;
  z-index: 5;
`;

export const Top = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(${colorLightGray5});
  height: 2.75rem;
`;

export const LogoContainer = styled.div`
  margin-left: 0.5rem;
  ${flexColumn}
  align-items: flex-start;
  h2 {
    color: rgb(${colorProductLink});
    font-size: 0.75rem;
  }
`;

export const ContentMain = styled.main<any>`
  margin-left: ${(p) => (p.isActive ? '13.4rem' : '4.5rem')};
  // margin-top: 1.25rem;
  width: ${(p) => (p.isActive ? 'calc(100% - 13.4rem)' : 'calc(100% - 4.5rem)')};
  transition: all 0.5s ease;
  display: flex;
  max-height: calc(100vh - 6rem);
  min-height: calc(100vh - 6rem);
  overflow: auto;
  ${(props) => props.theme.bgColorStyleForLoggedInPage};
  width: @media ${device.desktop} {
    margin-left: ${(p) => (p.isActive ? '13.4rem' : '4.5rem')};
    flex-direction: column;
  }
  @media ${device.tablet} {
    margin-left: 0;
  }
`;

/**
 * MenuTop
 */

export const EmailContainer = styled.div`
  ${gridCenter}
  margin-left: auto;
  margin-right: 2rem;
`;

interface IHasMarginLeft {
  marginLeft?: boolean;
}

export const Dropdown = styled.div<IHasMarginLeft>`
  margin-left: ${(p) => (p.marginLeft ? 'none' : 'auto')};
  display: flex;
  align-items: center;
  position: relative;
  & > button {
    margin-right: 1.25rem;
    ${gridCenter}
  }
`;

export const UserContainer = styled.div`
  ${flexColumn}
  align-items: flex-end;
  margin-right: 0.625rem;
`;

export const Name = styled.p`
  font-size: 0.875rem;
  width: 12.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  line-height: 1.4rem;
`;

export const Role = styled.p`
  font-size: 0.75rem;
  font-weight: 300;
`;

export const SignOutButton = styled.button`
  padding: 0 1.875rem;
  ${(props) => props.theme.themeTextStyleColor}
  border-left: 1px solid rgb(${colorLightGray5});
  font-size: 0.875rem;
  background-color: rgb(${colorWhite});
  transition: all 0.3s ease;

  &:hover {
    background-color: rgb(${colorSecondaryHover});
  }
`;

/**
 * MenuBottom
 */

export const Bottom = styled.div`
  display: flex;
  height: 3rem;
  align-items: center;
`;

export const MenuButtonContainer = styled.div`
  ${flexColumn}
  padding-inline: 1.25rem;
  border-right: 3px solid ${(props) => props.theme.primaryAppColor};
  border-bottom: 1px solid ${(props) => props.theme.primaryAppColor};
  height: 100%;
  width: 4.5rem;
  background: ${(props) => props.theme.primaryAppColor};
`;

export const Location = styled.h2`
  font-weight: 600;
  font-size: 1.25rem;
  color: ${(props) => props.theme.breadcrumbTextColor};
  margin-left: 1.25rem;
`;

export const DynamicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgb(${colorLightGray5});
`;

export const DynamicButtonContainer = styled.div`
  display: flex;
  margin-right: 1.8125rem;
  gap: 1.25rem;
`;

export const MenuButton = styled.button<IOnClick>`
  ${flexColumn}
  font-size: .625rem;
  color: white;
  & > svg {
    transform: rotate(${(p) => (p.isActive ? '180deg' : '0')});
  }
`;

export const BreadcumbsContainer = styled.div``;

export const BreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  ${TextLink} {
    font-size: 0.75rem;
  }
  margin-left: 1.25rem;
  gap: 5px;
  color: rgb(${colorLightGray9});

  & > svg {
    height: 0.625rem;
    width: 0.625rem;
    color: rgb(${colorLightGray7});
  }
`;

/**
 * Sidebar
 */
export const StyledSidebar = styled.div<ActiveProp>`
  ${(props) => props.theme.bgPrimaryStyleStriveColor}
  ${flexColumn}
  justify-content: flex-start;
  position: fixed;
  top: 0;
  transition: all 0.5s ease;
  height: 100vh;
  /* padding-top: 8.8rem; */
  width: ${(p) => (p.isActive ? '13.4rem' : '4.5rem')};
  @media ${device.tablet} {
    width: ${(p) => (p.isActive ? '4.5rem' : '0')};
  }
`;

export const LinkContainer = styled.div<ActiveProp>`
  color: rgb(${colorWhite});
  width: 100%;
  ${flexColumn}
  gap: .625rem;
  padding-top: ${(p) => (p.isActive ? '8.5rem' : '14rem')};
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4.5px;
  }
`;

export const AccordionItem = styled.div`
  width: 100%;
  /* &:not(:last-child) {
    border-bottom: 1px solid black;
  } */
`;

export const AccBody = styled.div<AccordionBody>`
  display: block;
  position: relative;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
  width: 100%;

  ${({ active }) =>
    active &&
    css`
      height: fit-content;
    `}
`;

export const SideButton = styled.button<ActiveProp>`
  ${flexColumn}
  width: 100%;
  gap: ${(p) => (p.isActive ? '0.4rem' : 0)};
  padding: 7px 2px;
  background-color: ${(p) =>
    p.isSelected ? (props) => props.theme.primaryAppColor : (props) => props.theme.whiteAppColor};
  color: ${(p) =>
    p.isSelected ? (props) => props.theme.striveColor : (props) => props.theme.primaryAppColor};
  justify-content: ${(p) => (p.isActive ? 'flex-start' : 'center')};
  padding-left: ${(p) => (p.isActive ? '1rem' : '')};
  flex-direction: ${(p) => (p.isActive ? 'row' : 'column')};
  position: relative;

  &:hover {
    background-color: ${(props) => (props.isSelected ? '' : props.theme.sidebarBtnHoverBgColor)};
    color: ${(props) => (props.isSelected ? '' : props.theme.sidebarBtnHoverTextColor)};
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding-left: 0;
    justify-content: center;
  }
`;

export const SideGroupButton = styled.button<ActiveProp>`
  ${flexColumn}
  width: 100%;
  gap: ${(p) => (p.isActive ? '0.4rem' : 0)};
  padding: 7px 2px;
  background-color: ${(p) =>
    p.isSelected ? (props) => props.theme.whiteAppColor : (props) => props.theme.primaryAppColor};
  color: ${(p) =>
    p.isSelected ? (props) => props.theme.primaryAppColor : (props) => props.theme.whiteAppColor};
  justify-content: ${(p) => (p.isActive ? 'flex-start' : 'center')};
  padding-left: ${(p) => (p.isActive ? '1rem' : '')};
  flex-direction: ${(p) => (p.isActive ? 'row' : 'column')};
  position: relative;

  &:hover {
    background-color: ${(props) => props.theme.sidebarBtnHoverBgColor} !important;
    color: ${(props) => props.theme.sidebarBtnHoverTextColor} !important;
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding-left: 0;
    justify-content: center;
  }

  &:after {
    display: none;
  }

  &:not(.collapsed) {
    color: ${(p) =>
      p.isSelected ? (props) => props.theme.primaryAppColor : (props) => props.theme.whiteAppColor};
    background-color: ${(p) =>
      p.isSelected ? (props) => props.theme.whiteAppColor : (props) => props.theme.primaryAppColor};
    box-shadow: none;
    overflow: hidden;
  }
`;

export const SideGroupSubButton = styled.button<ActiveProp>`
  ${flexColumn}
  width: 100%;
  gap: ${(p) => (p.isActive ? '0.4rem' : 0)};
  padding: 7px 2px;
  font-weight: 400;
  background-color: #fafafa;
  color: ${(p) => (p.isSelected ? (props) => props.theme.primaryAppColor : '#7D7D7D')};
  justify-content: ${(p) => (p.isActive ? 'flex-start' : 'center')};
  padding-left: ${(p) => (p.isActive ? '1.1rem' : '')};
  flex-direction: ${(p) => (p.isActive ? 'row' : 'column')};
  position: relative;

  &:hover {
    background-color: ${(props) => props.theme.sidebarBtnHoverBgColor};
    color: ${(props) => props.theme.sidebarBtnHoverTextColor};
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding-left: 0;
    justify-content: center;
  }
`;

export const AccordionUl = styled.ul`
  &.show {
    visibility: visible
  }
}
`;

export const SideGroupButtonTitle = styled.p<ActiveProp>`
  font-size: ${(p) => (p.isActive ? '14px' : '10px')};
  text-transform: capitalize;
  font-weight: 400;
  text-align: ${(p) => (p.isActive ? 'left' : 'center')};

  @media ${device.tablet} {
    font-size: 9px;
  }
`;

export const ChildButton = styled.button<ActiveProp>`
  ${flexColumn}
  width: 100%;
  gap: 0.4rem;
  padding: 0.4rem 0;
  background-color: ${(p) => (p.isSelected ? 'rgb(255, 255, 255)' : '#99B0C6')};
  color: ${(p) => (p.isSelected ? 'rgb(44,113,173)' : 'black')};
  justify-content: ${(p) => (p.isActive ? 'flex-end' : 'flex-end')};
  flex-direction: ${(p) => (p.isActive ? 'row' : 'column')};
  position: relative;

  &:hover {
    background-color: rgb(${colorWhite});
    color: rgb(${colorProductPrimary});
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding-left: 0;
    justify-content: center;
  }
`;

export const SideButtonTitle = styled.p<ActiveProp>`
  font-size: ${(p) => (p.isActive ? '14px' : '10px')};
  text-transform: capitalize;
  font-weight: 600;
  text-align: ${(p) => (p.isActive ? 'left' : 'center')};

  @media ${device.tablet} {
    font-size: 9px;
  }
`;

export const DropdownButton = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    ${(props) => props.theme.secondaryHoverBgColor}
    border-radius: 50%;
  }
`;

export const DropdownItem = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 40px;
  top: 46px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 9px 28px 8px rgb(0 0 0 / 5%), 0px 6px 16px rgb(0 0 0 / 8%),
    0px 3px 6px -4px rgb(0 0 0 / 12%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  justify-content: center;
  padding: 10px;
  right: 20px;
  & > a {
    cursor: pointer;
  }
`;

export const VersionDiv = styled.div<ActiveProp>`
  margin-top: auto;
  text-align: ${(p) => (p.isActive ? 'left' : 'center')};
  width: 100%;
  padding-left: ${(p) => (p.isActive ? '1.4rem' : '0px')};
  padding-bottom: 0.5rem;
  color: white;
  font-size: 12px;
  font-weight: 400;
`;
