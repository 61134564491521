import { useEffect, useState, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { RouteConstants } from '../../../constants/RouteConstants';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import Card from '../../shared/cards/Card';
import SettingIcon from '../../../assets/toolkit.svg';
import MenuButtonsPortal from '../../Menu/MenuButtonsPortal';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import InputField from '../../shared/Fields/InputField';
import TextAreaField from '../../shared/Fields/TextAreaField';
import { Label } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';
import { AppConstants } from '../../../constants/AppConstants';
import MigrationContextMenu from '../Listing/MigrationContextMenu';
import { getMigrationImportJob, startImportJob } from '../../../http/migration-service';
import { getFailureMessage } from '../../../util/ErrorUtil';
import { setToastData } from '../../../app/toastReducer';
import Dropzone from '../../shared/DragAndDrop/Dropzone';
import RadioButton from '../../shared/Fields/RadioButton';
import { XCircleIcon } from '@heroicons/react/outline';
import { consentNameValidation } from '../../../util/validators';

const Image = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 4px;
`;

const CREATE_IMPORT_FORM_SCHEMA = {
  name: '',
  description: '',
  validateOnly: false
};

/**
 * @parentId migrations
 * @manager Create - Import
 * @overview Create of Import Job
 *   <section>
 *       <p>
 *           <b>Create Import Job</b><br/>
 *           Clicking on <b>New Import</b> button on Import Job listing page will take user to New Import Job creation page. Clicking on <b>Cancel</b> will take the user to Import Job listing page.
 *           <br/><br/>
 *           User can create new Import Job by entering <b>Import Name</b>, <b>Validation Only</b> & <b>Migration Zip File</b> mandatory field and clicking on <b> Save </b> button on the admin portal toolbar.
 *           <b>Description</b> is additional non mandatory fields which user can use to enter more detail about the import job. Description is an freetext field & Properties is an JSON (Javascript Object Notation).
 *       </p>
 *
 *       <p>
 *          <h4>Create Import Job Fields</h4>
 *          Create Import Job page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Import Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Validation Only</td>
 *               <td>Yes</td>
 *               <td>Boolean</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Description</td>
 *               <td>No</td>
 *               <td>String</td>
 *               <td>4096</td>
 *            </tr>
 *            <tr>
 *               <td>Migration File</td>
 *               <td>Yes</td>
 *               <td>File</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Import Job Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_PARAM_DUPLICATE</td>
 *               <td>Duplicate name, Import Name for resource type MigrationImport</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create Import Job page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create Import Job.</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Migration Service</td>
 *               <td>1.1.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating Import Job page</p>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Import Job</td>
 *               <td>/import</td>
 *               <td>POST</td>
 *               <td>migration-service.migration-import.create</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create Import Job</p>
 *   </section>
 */

const ImportDetail = () => {
  const params = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>(CREATE_IMPORT_FORM_SCHEMA);
  const [formError, setFormError] = useState<any>({
    name: '',
    description: '',
    validateOnly: false
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [importJobDetails, setImportJobDetails] = useState<any>({});
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    async function init() {
      if (!params.id) {
        setIsEdit(true);
      } else {
        setIsLoading(true);
        const response = await getMigrationImportJob(parseInt(params.id));
        const { data: importJob, status } = response;
        if (status === 200) {
          setImportJobDetails(importJob);
        } else {
          let error = getFailureMessage(response);
          dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
        }
        setIsLoading(false);
      }
    }

    init();
  }, []);

  const CallStartImportJob = async () => {
    const payload = new FormData();
    const { name, description, validateOnly } = formData;

    payload.append('name', name);
    payload.append('description', description || ' ');
    payload.append('migrationData', file);
    payload.append('validateOnly', validateOnly);

    const response = await startImportJob(payload);
    const { data: importJob, status } = response;
    if (status === 201) {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: 'Import Job Created Successfully',
          toastType: 'success'
        })
      );
      navigate(RouteConstants.ROUTE_MIGRATION_IMPORT_LIST);
    } else {
      let error = getFailureMessage(response);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
    setIsLoading(false);
  };

  const setFileData = (data: any) => {
    setFile(data);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles.length) {
        dispatch(
          setToastData({
            toastMessage: t('T_NOT_UPLOADED'),
            isToastActive: true,
            toastType: 'error'
          })
        );
        return;
      }
      setFileData(acceptedFiles[0]);
    },
    [file]
  );

  return (
    <LoaderContainer isLoading={isLoading}>
      <div className="flex w-3/4 flex-col  overflow-visible px-2 py-3">
        <Card
          title={t('T_MIGRATION_FORM_CARD_HEADER')}
          icon={SettingIcon}
          alt={'T_MIGRATION_IMPORT_FORM_CARD_HEADER'}
          height={''}
          width={''}
          className=""
        >
          <MenuButtonsPortal>
            {isEdit ? (
              <>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t('T_CANCEL')}
                </BiButton>

                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    setIsLoading(true);
                    CallStartImportJob();
                  }}
                  disabled={!formData.name || !file}
                >
                  {t('T_IMPORT_JOB')}
                </BiButton>
              </>
            ) : (
              <>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  {t('T_MIGRATION_BACK_TO_IMPORT_LIST')}
                </BiButton>
                <MigrationContextMenu
                  className="!px-1.5 !py-1"
                  iconSize=""
                  migrationJob={importJobDetails}
                  setIsLoading={setIsLoading}
                  dropdownMenu={true}
                  jobType={AppConstants.MIGRATION_JOB_IMPORT}
                />
              </>
            )}
          </MenuButtonsPortal>
          <div className="flex w-full flex-wrap">
            <div className="mb-5 flex w-full">
              <div className="flex w-1/2 flex-col">
                <InputField
                  type={'text'}
                  id={'importName'}
                  label={t('T_IMPORT_NAME')}
                  placeholder={''}
                  fieldKey={'name'}
                  asterixFirst={true}
                  required={true}
                  formData={formData}
                  setFormData={setFormData}
                  formError={formError}
                  disabled={false}
                  max={AppConstants.MAX_LENGTH_IMPORT_NAME}
                  handleChange={(e: any) => {
                    const { value = '' } = e.target;
                    if (consentNameValidation(value)) {
                      setFormData({ ...formData, name: value });
                    }
                  }}
                />
              </div>

              <div className="mt-3 flex w-1/2 flex-col pl-7">
                <RadioButton
                  selectedField={formData.validateOnly}
                  option={false}
                  name={t('I_MIGRATION_IMPORT_VALIDATION_IMPORT')}
                  label={t('I_MIGRATION_IMPORT_VALIDATION_IMPORT')}
                  className="mb-3 mr-3"
                  onClick={() => {
                    setFormData({ ...formData, validateOnly: false });
                  }}
                />
                <RadioButton
                  selectedField={formData.validateOnly}
                  option={true}
                  name={t('I_MIGRATION_IMPORT_VALIDATION_ONLY')}
                  label={t('I_MIGRATION_IMPORT_VALIDATION_ONLY')}
                  className="mb-3"
                  onClick={() => {
                    setFormData({ ...formData, validateOnly: true });
                  }}
                />
              </div>
            </div>
            <div className="mb-5 flex w-full flex-col flex-wrap">
              <TextAreaField
                fieldKey={'description'}
                type={'text'}
                id={'importDesc'}
                label={t('T_IMPORT_DESCRIPTION')}
                placeholder={''}
                required={false}
                formData={formData}
                setFormData={setFormData}
                formError={formError}
                asterixFirst={false}
                rows={3}
                disabled={false}
                maxLength={AppConstants.MAX_LENGTH_IMPORT_DESCRIPTION}
              ></TextAreaField>
            </div>
          </div>
        </Card>

        <Card
          title={t('T_MIGRATION_FILE_CARD_HEADER')}
          icon={SettingIcon}
          alt={'T_MIGRATION_FILE_CARD_HEADER'}
          height={''}
          width={''}
          className=""
        >
          <div className="mx-auto w-[75%] py-5">
            <Dropzone onDrop={onDrop} accept={{ 'application/zip': ['.zip'] }} maxFiles={1} />
          </div>
          {file && (
            <div className="mx-auto flex w-[75%] rounded border border-solid px-2 py-1.5">
              <div className="text-sm font-light">{file && file.name}</div>
              <XCircleIcon
                className="my-auto ml-auto mr-1.5 h-4 w-4 cursor-pointer"
                onClick={() => {
                  setFile(null);
                }}
              />
            </div>
          )}
        </Card>
      </div>
    </LoaderContainer>
  );
};

export default ImportDetail;
