import moment from "moment";
import { getUserName, getUserProfiles } from "../../util/admin-utils";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { AppConstants } from "../../constants/AppConstants";

interface StatusProps{
  label: string;
  date: any;
  user: any;
  isUserId?: boolean;
  isDateFormatted?: boolean;
}

const AuditInfo:React.FC<StatusProps> = (props: StatusProps) => {
    const { label, date, user, isUserId = true, isDateFormatted = false } = props;
    const [userName, setUserName] = useState(user);
    const { t } = useTranslation();
    const dateTime = !isDateFormatted ? moment(date).format(AppConstants.DEFAULT_DATE_FORMAT) : date;
    useEffect(() => {
      isUserId && getUserInfo();
    }, []);
    const getUserInfo = async () =>{
      const users: Array<any> = await getUserProfiles([user]);
      if (users.length) {
        setUserName(getUserName(users[0]));
      }
    }

    return (
      <>
        <div className="my-2.5">
          <div>{t(`T_${label}_BY`)}</div>
          <div className="font-light" data-testId={`${label.toLocaleLowerCase()}_by`}>{userName || '-'}</div>
        </div>
        <div className="my-2.5">
          <div>{t(`T_${label}_ON`)}</div>
          <div className="font-light" data-testId={`${label.toLocaleLowerCase()}_on`}>{dateTime || '-'}</div>
        </div>
      </>
    );
  };

  export default AuditInfo;