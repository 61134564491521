export function handleDragStart(e: any, index: any, todoItemDrag: any) {
  todoItemDrag.current = index;
}
export function handleDragEnter(e: any, index: any, todoItemDragOver: any) {
  todoItemDragOver.current = index;
}
export function handleDragEnd(
  e: any,
  index: any,
  todoItemDrag: any,
  todoItemDragOver: any,
  optionsObject: any,
  defaultSelected: any,
) {
  let arr1 = { ...optionsObject };
  let arr2 = { ...defaultSelected };

  let objArr1 = Object.entries(arr1);
  let objArr2 = Object.entries(arr2);

  const dragItemContent1 = objArr1[todoItemDrag.current - 1];
  objArr1.splice(todoItemDrag.current - 1, 1);
  objArr1.splice(todoItemDragOver.current - 1, 0, dragItemContent1);

  const dragItemContent2 = objArr2[todoItemDrag.current - 1];
  objArr2.splice(todoItemDrag.current - 1, 1);
  objArr2.splice(todoItemDragOver.current - 1, 0, dragItemContent2);

  const newObj = Object.fromEntries(objArr1);
  const newObj2 = Object.fromEntries(objArr2);

  let temp = {},
    temp2 = {};
  Object.keys(newObj).map((obj: any, index: any) => {
    temp = { ...temp, [`option${index + 1}`]: newObj[obj] };
  });
  Object.keys(newObj2).map((obj: any, index: any) => {
    temp2 = { ...temp2, [`option${index + 1}`]: newObj2[obj] };
  });

  todoItemDrag.current = null;
  todoItemDragOver.current = null;

  return { options: temp, defaultOptions: temp2 };
}
