import InfiniteScroll from 'react-infinite-scroll-component';

import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import DropdownSvg from '../../../assets/dropdown.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { DropDownListContainer } from '../dropdowns/BiDropdownStyles';
import { useTranslation } from 'react-i18next';
import { BiSearchIconInput } from '../../primitives/inputs/BiSearchIconInput.primitive';
import { debounce } from 'lodash';
import { useAppDispatch } from '../../../app/hooks';
import { setToastData } from '../../../app/toastReducer';
import { AppConstants } from '../../../constants/AppConstants';

interface ActionDropdownProps {
  options: any;
  onSelectedOptionChange: Function;
  value?: any;
  defaultLabel?: any;
  className?: string;
  searchPlaceholder?: string;
  searchable?: boolean;
  searchData?: Function;
  fetchMoreData?: Function;
  hasMore?: boolean;
}

const ActionDropdown: React.FC<ActionDropdownProps> = ({
  options,
  onSelectedOptionChange,
  value,
  defaultLabel,
  className,
  searchPlaceholder = 'Search',
  searchData = () => {},
  searchable = false,
  fetchMoreData = () => {},
  hasMore = false
}) => {
  const selectRef = useRef(null);
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { t } = useTranslation();

  const toggling = () => {
    setShowPopup(!showPopup);
  };

  useOutsideClick(
    selectRef,
    () => {
      if (showPopup) {
        setShowPopup(false);
      }
    },
    false
  );

  const onOptionClicked = (value: any) => () => {
    if (!value) {
      return;
    }

    onSelectedOptionChange(value);
    setShowPopup(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (e.target.value.length < AppConstants.DEFAULT_TEXT_MAX_LENGTH) {
      debouncedSearch(e.target.value);
    } else {
      dispatch(
        setToastData({
          toastMessage: t('T_SEARCH_ERROR'),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const debouncedSearch = useRef(
    debounce(async (data) => {
      if (data && data.trim()) {
        searchData(data.trim());
      } else if (!data) {
        searchData(data);
      }
    }, 400)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const fetchMoreDataFunc = () => {
    fetchMoreData();
  };

  return (
    <DropDownContainer className={`mt-[3px] ${className}`} ref={selectRef}>
      <DropDownHeader onClick={toggling} data-testid="select-field-header">
        {value?.display
          ? value.display
          : defaultLabel
          ? defaultLabel
          : `${t('T_MANAGE_ORGANIZATION')}`}

        <EndIcon>
          <img src={DropdownSvg} />
        </EndIcon>
      </DropDownHeader>
      {showPopup && (
        <DropDownListContainer>
          <DropDownList data-testid="select-option-list">
            {searchable && (
              <ListItem onClick={onOptionClicked('')}>
                {' '}
                <BiSearchIconInput
                  isEmailTemplate
                  autoFocus
                  value={searchText}
                  placeholder={searchPlaceholder}
                  onChange={handleSearchChange}
                />
              </ListItem>
            )}
            {!hasMore && !options.length && (
              <ListItem onClick={onOptionClicked('')}>{t('T_NO_DATA_AVAILABLE_MSG')}</ListItem>
            )}
            <InfiniteScrollOuterDiv>
              <InfiniteScroll
                dataLength={options.length}
                next={fetchMoreDataFunc}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                height={hasMore ? 280 : 'auto'}
              >
                {options.map((option: any) => (
                  <ListItem
                    key={Math.random()}
                    onClick={onOptionClicked(option)}
                    className="hover:bg-[#dceefe]"
                  >
                    {option.display}
                  </ListItem>
                ))}
              </InfiniteScroll>
            </InfiniteScrollOuterDiv>

            {/* {options.map((option: any) => (
              <ListItem
                key={Math.random()}
                onClick={onOptionClicked(option)}
                className="hover:bg-[#dceefe]"
              >
                {option.display}
              </ListItem>
            ))} */}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

const InfiniteScrollOuterDiv = styled('div')`
  width: 100%;
`;

const DropDownContainer = styled('div')`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px 10px 12px;
  width: 260px;
  height: 34px;
  background: #ffffff;

  border: 1px solid #0074e0;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    background-color: #f5f5f5;
  }
`;

const DropDownHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  color: #0074e0;
  font-weight: 600;
`;

const DropDownList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0px;
  position: absolute;
  width: 100%;
  max-height: 325px;
  z-index: 1;
  left: 0;
  top: 45px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  overflow-y: auto;
`;

const ListItem = styled('li')`
  list-style: none;
  width: 77px;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding: 9px 10px;
  cursor: pointer;
  width: 100%;
`;

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

const EndIcon = styled.span`
  height: 34px;
  padding: 5px 2px;
  background: #dceefe;
  border: 1px solid #0074e0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: none;
`;

export default ActionDropdown;
