import React from 'react';
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from 'react-dropzone';
import CloudUpload from '../../../assets/cloud-upload.svg';
import { useTranslation } from 'react-i18next';

interface IDropZoneParams {
  onDrop: any;
  accept?: any;
  maxFiles?: number;
  disabled?: boolean;
}

const Dropzone = (params: IDropZoneParams) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } =
    useDropzone(params);
  const { t } = useTranslation();
  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  const styles1 =
    'h-40 p-5 m-auto text-center border-2 border-gray-300 border-dashed rounded-xl cursor-pointer text-gray-400 w-full';

  const styles2 =
    'h-40 px-5 py-14 align-middle text-center border-2 border-emerald-500 border-dashed rounded-xl cursor-pointer bg-emerald-50 text-gray-400 text-2xl w-full';

  const disabledStyles = 'pointer-events-none bg-[#f5f5f5]';

  return (
    <div
      {...getRootProps()}
      className={`${isDragActive ? styles2 : styles1} ${params.disabled ? disabledStyles : ''}`}
    >
      <input className="dropzone-input" {...getInputProps()} data-testid="test-fileupload" />
      <div className="text-center">
        {isDragActive ? (
          <p className="m-auto">{t('T_DROP_FILE_HERE')}</p>
        ) : (
          <p className="m-auto w-72">
            <img src={CloudUpload} alt={t('T_CLOUD_UPLOAD') || ''} className="m-auto w-14" />
            {t('T_DROP_DRAG_FILE_MSG')}{' '}
            <span className={`${params.disabled ? '' : 'text-blue-600'}`}>
              {t('T_UPLOAD_FROM_COMPUTER')}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
