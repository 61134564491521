import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useLocation } from 'react-router-dom';
import { cloneDeep, debounce, findIndex, isEmpty } from 'lodash';

import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../../components/shared/tables/models/ITableHeader';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setLoggedInOrganization,
  setOrganizationDetails,
  setParentDetails,
  setURLPaths
} from '../../app/organizationReducer';
import {
  deleteOrganization,
  getChildOrganizations,
  getOrganization,
  hardDeleteOrganization
} from '../../http/organization-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { noDataAvailableMessage } from './helpers';
import { getLoggedInOrg, getLoggedInOrg as getLoggedInUserOrg } from '../../util/admin-utils';
import OrganizationMenu from './OrganizationMenu';
import { AppConstants } from '../../constants/AppConstants';
import { useQuery } from '../../hooks/queryParams';
import { getFailureMessage } from '../../util/ErrorUtil';
import { deleteBucket, listBuckets } from '../../http/asset-management-services';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { getOragnizationAddress, getOrganizationType } from '../../util/OrganizationUtil';
import { RouteConstants } from '../../constants/RouteConstants';
import { EllipsisDiv } from '../../styles/globalStyles';
import Checkbox from '../shared/Fields/Checkbox';
import { ActionButtons } from '../Reusable/ActionButton';
import { deleteManifestItem, setManifest } from '../../app/migrationExportReducer';
import AddCircle from '../../assets/addCircle.svg';
import moment from 'moment';
import { setTableOptions } from '../../app/templateTableReducer';

/**
 * @parentId organizations
 * @manager Organization Listing
 * @overview Lists all the descendent child organization(s) of logged-in user organization
 *   <section>
 *       <p>
 *           Organization listing page is the default admin portal landing for the logged in user. User can also navigated to organization listing page on clicking <b>Organizations</b>  navigation menuitem under <b>Users and Orgs</b> dropdown group item from left sidebar of admin portal.
 *           Organization listing page will display all the descendent child organization of logged user organization in flat table hireachry with pagination support & organization(s) sorted by created-on field timestamp & descending order of creation. The Default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer.
 *           Datagrid footer will also display the current page and total number of pages available based on the choosen page size & application data. User can switch to next/previous pages using the <b>Next</b> & <b>Previous</b> buttons enabled based on the content in the data grid footer.
 *       </p>
 *       <br>
 *       <p>
 *           Organization(s) can be filtered based on organization name using the search field provided above the organization listing data grid.
 *           Admin user(s) with <b>organization-service.organization.create</b> API role permission can create new child organization by clicking on <b>New Organization</b> button provided on the organization listing page toolbar.
 *           Clicking on it will navigate user to Organization creation page. <b>New Organization</b> button will be shown based on logged in user role <b>organization-service.organization.create</b> API permissions as described below.
 *       </p>
 *       <br/>
 *       <p>
 *           Datagrid will display below properties of each organization as columns. These columns are fixed in width and can't be hidden, changed or re-ordered.
 *           <br/>
 *           <ul>
 *               <li>Name</li>
 *               <li>Type</li>
 *               <li>Status</li>
 *               <li>Address</li>
 *               <li>Parent</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *       </p>
 *       <p>
 *          Sorting of organization(s) columns is only supported on <i>Name</i> column, However the default sort of the listing page is based on <b>Created On</b> field with Descending order. The breadcrumb on the adminportal toolbar will reflect organization hirearchy of the selected organization from its root organization.
 *          Clicking on organization rowitem will drill down user to organization details page which will display organization page in view only mode.
 *       </p>
 *     </section>
 *     <section>
 *       <h4>Organization Context Menu</h4>
 *       <p>
 *        User can perform a set of actions on each organization based on his role. Following are the actions which are displayed in the Context Menu for each organization based on the role API permissions. Few of them might be hidden due to logged in user role not having required API permissions.
 *        <br/>
 *        <table>
 *           <tr>
 *               <th>Action</th>
 *               <th>Role API Permission</th>
 *           </tr>
 *           <tr>
 *               <td>Edit Organization Details</td>
 *               <td>organization-service.organization.update</td>
 *           </tr>
 *           <tr>
 *               <td>Invite Users</td>
 *               <td>N/A</td>
 *           </tr>
 *           <tr>
 *               <td>View Users</td>
 *               <td>user-management-service.user.list</td>
 *           </tr>
 *           <tr>
 *               <td>View Templates</td>
 *               <td>notification-service.template.list</td>
 *           </tr>
 *           <tr>
 *               <td>Manage Contracts</td>
 *               <td>asset-management-service.bucket.files</td>
 *           </tr>
 *           <tr>
 *               <td>View Settings</td>
 *               <td>configuration-service.organization-setting.list</td>
 *           </tr>
 *           <tr>
 *               <td>View Categories</td>
 *               <td>configuration-service.organization-category.list</td>
 *           </tr>
 *       </table>
 *       <p>
 *     </section>
 *
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes the List Metadata Applications Status Code information.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>OS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>OS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td>None</td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings, platform services & role API permissions required for the functioning of organization listing page.</p>
 *       <br/>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for organization listing page.</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Organization Service</td>
 *               <td>1.3.1</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *          <tr>
 *               <td>Configuration Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for organization listing page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{id}/children</td>
 *               <td>GET</td>
 *               <td>organization-service.organization.list.children</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations</td>
 *               <td>POST</td>
 *               <td>organization-service.organization.create</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{id}</td>
 *               <td>GET</td>
 *               <td>organization-service.organization.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/users/profile</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{organization-id}/categories</td>
 *               <td>GET</td>
 *               <td>	configuration-service.organization-category.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{organization-id}/settings</td>
 *               <td>GET</td>
 *               <td>configuration-service.organization-setting.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for organization listing page</p>
 *   </section>
 */
interface OrgKeyList {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
  migrationHeaders?: Array<any>;
}

const OrganizationsList = ({
  type = 'listing',
  toggleModal = () => {},
  migrationHeaders = []
}: OrgKeyList) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState<any>([]);
  const [error, setError] = useState<any>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const creationPolicy: any =
    useAppSelector((state) => state.configuration.orgCreationPolicy) || {};
  const userProfileInfo: any = useAppSelector((state) => state.userManagement.userProfileInfo);
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};
  const loginOrganization: any = useAppSelector((state) => state.organization.loggedInOrganization);
  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);

  const manifestOrgData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.organizations
  );

  const { organizationId } = getLoggedInOrg(userProfileInfo);
  const parentMap: Map<string, any> = new Map<string, any>();

  const pagingRef: any = useRef();

  const setPagingRef = (paging: any) => {
    pagingRef.current = paging;
  };

  const ORGANIZATION_HEADERS: Array<ITableHeader> = [
    {
      key: 'name',
      description: t('T_ORGANIZATION_NAME'),
      sortEnabled: type !== 'migrationPreview',
      width: '28%',
      clickable: true,
      sortingInformation: {
        order: ''
      },
      render: (data: any) => {
        return (
          <EllipsisDiv className="w-full flex-1" title={data?.name}>
            {data?.name}
          </EllipsisDiv>
        );
      },
      styles: { borderRight: 'none' }
    },
    {
      key: 'options',
      description: '',
      sortEnabled: false,
      width: '4%',
      clickable: false,
      sortingInformation: {
        order: ''
      },
      render: (data: any) => {
        const { original } = data;
        return (
          <div className="hover:bg-[#d9d9d9]" style={{ borderRadius: '10px' }}>
            <OrganizationMenu
              className="px-0 py-0"
              iconSize="h-[28px] py-[4px] min-w-[20px]"
              organization={original}
            />
          </div>
        );
      },
      styles: {
        paddingRight: '0',
        paddingLeft: '0',
        borderLeft: 'none',
        rowStyle: { fontWeight: 'normal', padding: '0px' }
      }
    },
    {
      key: 'type',
      description: t('T_TYPE'),
      sortEnabled: false,
      clickable: true,
      width: '15%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'status',
      description: t('T_STATUS'),
      sortEnabled: false,
      clickable: true,
      width: '7%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'address',
      description: t('T_ADDRESS'),
      sortEnabled: false,
      clickable: true,
      width: '25%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'parent',
      description: t('T_PARENT'),
      sortEnabled: false,
      clickable: true,
      width: '15%',
      sortingInformation: {
        order: ''
      }
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: t('T_LAST_MODIFIED_BY'),
    //   sortEnabled: false,
    //   clickable: true,
    //   width: '15%',
    //   sortingInformation: {
    //     order: ''
    //   }
    // },
    {
      key: 'updatedOn',
      description: t('T_LAST_MODIFIED_ON'),
      sortEnabled: false,
      clickable: true,
      render: (data: any) => {
        return data.updatedOn
          ? moment(data.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '';
      },
      width: '15%',
      sortingInformation: {
        order: ''
      }
    }
  ];

  const isCheckboxSelected = (data: any) => {
    const { id } = data;

    return (
      findIndex(selectedItems, function (item: any) {
        return item.id === id;
      }) !== -1
    );
  };

  const toggleOverWrite = (data: any) => {
    const { id } = data;

    let existingArr: any = cloneDeep(manifestOrgData);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      if (existingArr[existingId]?.overWrite) {
        delete existingArr[existingId].overWrite;
      } else {
        existingArr[existingId].overWrite = true;
      }

      dispatch(setManifest({ key: 'organizations', value: existingArr }));
      return;
    }
  };

  const toggleCheckbox = (data: any) => {
    const { id, name = '', key = '', parent = '', parentId = '', status = '', type = '' } = data;
    let existingArr: any = cloneDeep(selectedItems);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      existingArr.splice(existingId, 1);
      setSelectedItems(existingArr);
      return;
    }
    existingArr.push({ id, name, key, parent, parentId, status, type });
    setSelectedItems(existingArr);
  };

  const orgHeaderBuilder = () => {
    if (type !== 'listing') {
      let headers = [...migrationHeaders];
      if (type === 'migrationSelection') {
        headers.unshift({
          key: 'checked',
          description: '',
          sortEnabled: false,
          width: '10%',
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={isCheckboxSelected(data)}
                  onChange={() => {
                    toggleCheckbox(data);
                  }}
                  testID="addModuleCheckbox"
                />
              </>
            );
          }
        });
      } else {
        headers.push(
          {
            key: 'overWrite',
            description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any, index: any) => {
              return (
                <>
                  <Checkbox
                    className="mx-auto"
                    height="20px"
                    width="20px"
                    onChange={() => {
                      toggleOverWrite(data);
                    }}
                    checked={data?.overWrite}
                    testID="allowOverWriteCheckbox"
                  />
                </>
              );
            }
          },
          {
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '13%',
            sortingInformation: {
              order: ''
            }
          }
        );
      }
      return headers;
    }
    return ORGANIZATION_HEADERS;
  };

  const query = useQuery();
  if (query.get('devmode') && type === 'listing') {
    ORGANIZATION_HEADERS.push({
      key: 'buttons',
      description: t('T_ACTIONS'),
      sortEnabled: false,
      width: '5%',
      sortingInformation: {
        order: ''
      }
    });
  }

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(orgHeaderBuilder());

  useEffect(() => {
    const {
      tableFilters = '',
      searchText = '',
      currentPage = AppConstants.DEFAULT_PAGE,
      size = AppConstants.DEFAULT_PAGE_SIZE,
      sortName = AppConstants.DEFAULT_SORT_BY,
      sortType = AppConstants.DEFAULT_SORT_DESC
    } = globalFilters['organization-list'] || {};

    const init = async () => {
      if (!loginOrganization) {
        loadOrganization(organizationId);
      } else {
        parentMap.set(loginOrganization?.id, loginOrganization);
      }

      await getOrganizations(organizationId, searchText, currentPage, size, sortName, sortType);

      setIsLoading(false);
    };

    dispatch(
      setTableOptions({
        ['organization-list']: {
          searchText: searchText,
          currentPage,
          size,
          sortName: sortName,
          sortType: sortType,
          tableFilters: tableFilters
        }
      })
    );
    if (type == 'migrationPreview') {
      setIsLoading(false);
      return;
    }

    init();
    return () => {
      parentMap.clear();
    };
  }, []);

  useEffect(() => {
    dispatch(
      setURLPaths([
        {
          key: RouteConstants.ROUTE_OS_ORGANIZATION_LISTING,
          label: `${loginOrganization?.name || getFailureMessage(error)}`
        }
      ])
    );
  }, [loginOrganization]);

  const loadOrganization = async (organizationId: string) => {
    const response = await getOrganization(organizationId);
    const { status, data } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      parentMap.set(data?.id, data);
      dispatch(setLoggedInOrganization(data));
    } else {
      setError(response);
    }
  };

  const removeOrganization = async (index: number, currentActivePage: any) => {
    if (type === 'migrationPreview') {
      dispatch(
        deleteManifestItem({
          key: 'organizations',
          id: manifestOrgData[index + currentActivePage]?.id
        })
      );

      return;
    }
    const row: any = rows[index];
    if (row) {
      let error = false;
      const { id, name } = row;
      let toastMessage = `${name} ${t('T_DELETED_SUCCESS_MESSAGE')}`;
      const deleteResponse = await hardDeleteOrganization(id);
      const { status: deleteStatus } = deleteResponse;
      if (deleteStatus == HTTP_STATUS.HTTP_OK) {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
      } else {
        error = !error;
        toastMessage = getFailureMessage(deleteResponse);
      }
      dispatch(
        setToastData({
          toastMessage,
          isToastActive: true,
          toastType: error ? 'error' : 'success'
        })
      );
    }
  };

  const getOrganizations = async (
    id: string,
    name: string = '',
    page: number = AppConstants.DEFAULT_PAGE,
    size: number = type === 'listing'
      ? AppConstants.DEFAULT_PAGE_SIZE
      : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
    sortOrder: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setIsLoading(true);
    const response = await getChildOrganizations(
      id,
      AppConstants.QUERY_PARAM_LEVEL_DESENDANTS,
      name,
      page,
      size,
      [`${sortOrder},${sortType}`]
    );
    const { status, data: listOrgResponse } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: organizationList = [], paging } = listOrgResponse;
      if (!organizationList.length) {
        setIsLoading(false);
        noDataAvailableMessage(setRows, () => {}, setPagingRef, paging, t);
        return;
      }
      let tableRows: any[] = organizationList.map((organization: any) => {
        const {
          id,
          name,
          active,
          address = [],
          parentId,
          type = [],
          telecom = [],
          updatedBy,
          updatedOn
        } = organization;
        const [address1 = []] = address || [];
        const { city, country, postalCode, state } = address1;
        const [phone, fax] = telecom || [];

        parentMap.set(id, organization);

        return {
          key: id,
          id,
          name,
          status: active ? `${t('T_ACTIVE')}` : `${t('T_NOT_ACTIVE')}`,
          address: `${getOragnizationAddress(organization)}`,
          city,
          state,
          zip: postalCode,
          country,
          phone: phone?.value,
          fax: fax?.value,
          parentId,
          type: getOrganizationType(organization),
          parent: parentMap.get(parentId)?.name || 'N/A',
          original: organization,
          updatedBy,
          updatedOn
        };
      });

      const parentIds = new Set(
        tableRows
          .filter((tableRow: any) => !parentMap.get(tableRow?.parentId))
          .map((tableRow: any) => tableRow?.parentId)
      );
      const responses = await Promise.all(
        Array.from(parentIds).map((parentId: string) => getOrganization(parentId))
      );
      responses.forEach((resp: any) => {
        const { status: orgStatus, data: orgResp } = resp;
        if (orgStatus == HTTP_STATUS.HTTP_OK) {
          parentMap.set(orgResp?.id, orgResp);
        }
      });
      setRows(
        tableRows.map((tableRow: any) => {
          return { ...tableRow, parent: parentMap.get(tableRow?.parentId)?.name || 'N/A' };
        })
      );

      setPagingRef(paging);
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const organizationSelector = (data: any) => {
    if (type === 'listing') {
      if (roleAPIPermissions[AppConstants.PERMISSION_ORGANIZATION_GET]) {
        dispatch(setOrganizationDetails(data));
        navigate(`/organization/${data?.id}`);
      } else {
        dispatch(
          setToastData({
            toastMessage: `${t('T_NO_VIEW_ORGANIZATION_PERMISSION')}`,
            isToastActive: true,
            toastType: 'error'
          })
        );
      }
    }
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sortOrder: string,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setTableValues(name, page, size, sortOrder, sortType);
  };

  const debouncedCall = useRef(
    debounce(async (id, name, page, size, sortOrder, sortType) => {
      await getOrganizations(id, name, page, size, sortOrder, sortType);
      dispatch(setParentDetails([]));
      setIsLoading(false);
    }, 400)
  ).current;

  const setTableValues = async (
    name: string,
    page: number,
    size: number,
    sortOrder: string,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    debouncedCall(organizationId, name, page, size, sortOrder, sortType);
  };

  const enableCreate = () => {
    let create: boolean = false;
    if (loginOrganization) {
      const { type: orgTypes = [] } = loginOrganization;
      const type = (orgTypes && orgTypes.length && orgTypes[0]?.text) || '';
      if (Object.keys(creationPolicy).length) {
        create = (creationPolicy[type?.trim()] || []).length;
      } else {
        create = true;
      }
    }
    return create;
  };

  useEffect(() => {
    if (manifestOrgData?.length) {
      setSelectedItems(manifestOrgData);
    }
  }, [manifestOrgData]);

  useEffect(() => {
    if (type === 'migrationSelection') {
      setTableHeaders(orgHeaderBuilder());
    } else if (type === 'migrationPreview') {
      setTableHeaders(orgHeaderBuilder());
      setRows(selectedItems);
      setIsLoading(false);
    }
  }, [selectedItems]);

  const saveSelection = () => {
    dispatch(setManifest({ key: 'organizations', value: selectedItems }));

    toggleModal();
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <TableContainer className={`${type !== 'listing' && '!pb-0'}`}>
        {type === 'listing' && roleAPIPermissions[AppConstants.PERMISSION_ORGNIZATION_CREATE] ? (
          <MenuButtonsPortal>
            <BiButton
              className={'flex flex-row bg-primary text-white'}
              type="button"
              disabled={!enableCreate()}
              onClick={() => {
                navigate('/organization/new');
              }}
            >
              <img src={AddCircle} className="mr-2 w-6 text-center"></img>
              {t('T_NEW_ORGANIZATION')}
            </BiButton>
          </MenuButtonsPortal>
        ) : null}
        <TemplateTable
          tableData={
            type === 'migrationPreview'
              ? rows.slice(
                  currentActivePage,
                  currentActivePage + AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
                )
              : rows
          }
          onRefreshTableData={refreshTableData}
          size={
            type === 'migrationPreview'
              ? AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
              : globalFilters?.size?.toString()
          }
          currentPage={type === 'migrationPreview' ? currentActivePage : globalFilters?.currentPage}
          setCurrentPage={(page: any) => {
            setCurrentActivePage(parseInt(page));
          }}
          cellSelector={organizationSelector}
          templateSelector={organizationSelector}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={pagingRef.current}
          containerClass="mt-0"
          isSearchable={true}
          showDeleteButton={true}
          theme={useTheme().theme}
          tablePage="organization-list"
          isLocalPaginator={type === 'migrationPreview'}
          totalDataLength={rows.length}
          isPaginated={type !== 'migrationPreview'}
          disablePageSize={type !== 'listing'}
          deleteOnClick={removeOrganization}
          searchPlaceholder={t('T_SEARCH_ORG_NAME') || ''}
          globalFilters={globalFilters}
        />
      </TableContainer>
      {type === 'migrationSelection' ? (
        <div className="w-full">
          <ActionButtons
            primaryButtonLabel={t('T_SAVE')}
            primaryButtonAction={saveSelection}
            secondaryButtonLabel={t('T_CANCEL')}
            secondaryButtonAction={toggleModal}
            primaryButtonWidth="full"
            primaryButtonClass={'px-4'}
          />
        </div>
      ) : (
        ''
      )}
    </LoaderContainer>
  );
};

export const TableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

export default OrganizationsList;
