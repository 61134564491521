import BaseAPI from './api';
import env from '../environment';
import { AppConstants } from '../constants/AppConstants';
import { isEmpty } from 'lodash';

import { carePlanFilterQuery } from '../components/CarePlan/carePlanUtils';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

interface ConditionQueryOptions {
  name?: string;
  page?: number;
  size?: number;
  sort?: Array<string>;
  filters?: any;
}

export const getCarePlanTemplates = async (
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: Array<string> = [],
  filters: any = {}
) => {
  const headers = { ...globalHeaders };
  let query = `?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;
  query = carePlanFilterQuery(filters, query);

  if (sort.length) {
    query += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }
  let uri = `${env.carePlanPath}/careplans${query}`;
  const response = await API.get(uri, { headers });
  return response;
};

export const getConditions = async (queryOptions: ConditionQueryOptions) => {
  const {
    name = '',
    page = AppConstants.DEFAULT_PAGE,
    size = AppConstants.DEFAULT_PAGE_SIZE,
    sort = [],
    filters = {}
  } = queryOptions;
  const headers = { ...globalHeaders };

  let query = `?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;

  if (name) {
    query += `&code=${name}`;
  }

  if (sort.length) {
    query += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }

  let uri = `${env.conditionPath}/conditions${query}`;
  const response = await API.get(uri, { headers });
  return response;
};

export const getCareTeams = async (queryOptions: ConditionQueryOptions) => {
  const {
    name = '',
    page = AppConstants.DEFAULT_PAGE,
    size = AppConstants.DEFAULT_PAGE_SIZE,
    sort = [],
    filters = {}
  } = queryOptions;
  const headers = { ...globalHeaders };

  let query = `?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;

  if (name) {
    query += `&name=${name}`;
  }

  if (sort.length) {
    query += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }

  let uri = `${env.careTeamPath}/care-teams${query}`;
  const response = await API.get(uri, { headers });
  return response;
};

export const createCareplan = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.carePlanPath}/careplans`, body, { headers });
  return response;
};

export const getCarePlan = async (id: string = '') => {
  const headers = { ...globalHeaders };
  let uri = `${env.carePlanPath}/careplans/${id}`;

  return await API.get(uri, { headers });
};

export const updateCareplan = async (id: string, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(`${env.carePlanPath}/careplans/${id}`, body, {
    headers
  });
  return response;
};

export const deleteCareplan = async (id: any) => {
  const headers = { ...globalHeaders };
  const response = await API.delete(`${env.carePlanPath}/careplans/${id}`, {
    headers
  });
  return response;
};

export const getEncounterList = async (queryOptions: any) => {
  const { page = AppConstants.DEFAULT_PAGE, size = AppConstants.DEFAULT_PAGE_SIZE } = queryOptions;
  const headers = { ...globalHeaders };

  let query = `?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;

  let uri = `${env.encounterServicePath}/encounters${query}`;
  const response = await API.get(uri, { headers });
  return response;
};
