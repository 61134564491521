// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PermissionDetails from './PermissionDetails';
import Modal from '../shared/ModalPortal/Modal';
import AdminFeaturesDataGrid from './AdminFeatureDataGrid';
import { Button } from '../Reusable/Shared';
import Stepper from '../Reusable/Stepper';
import BiButton from '../primitives/buttons/BiButton.primitive';
import ApplicationFeatureDataGrid from './ApplicationFeatureDataGrid';
import {
  createSystemSetting,
  getCategories,
  getSystemSettings
} from '../../http/configuration-services';
import { AppConstants } from '../../constants/AppConstants';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getAppFeatureColumns, getAppFeatureRows } from '../../util/ColumnUtil';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToastData } from '../../app/toastReducer';
import ToastNotification from '../Reusable/ToastNotification';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import ApplicationSettings from '../AppSettings/ApplicationSettings';
import { normalizeKey } from '../../util/RoleUtil';
import { getLoggedInOrg } from '../../util/admin-utils';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';

import { find } from 'lodash';
import { createMetadataInstances, getMetadataInstances } from '../../http/metadata-service';
import { setPagingOpt, setPermissionSetList } from '../../app/permissionSetReducer';
import {
  createPermissionSets,
  fetchApplFeaturesFromConfig,
  fetchApplFeaturesFromMetaData,
  getApplSolnFeatures
} from './PermissionSetUtil';

import { getPermittedItems } from '../../util/appInitializeUtil';

interface ActionButtonsProps {
  primaryButtonLabel: string;
  primaryButtonAction: Function;
  secondaryButtonLabel: string;
  secondaryButtonAction: Function;
  disabled?: boolean;
}
export const ActionButtons: React.FC<ActionButtonsProps> = ({
  primaryButtonAction,
  primaryButtonLabel,
  secondaryButtonAction,
  secondaryButtonLabel,
  disabled = false
}) => {
  return (
    <div className="flex w-full">
      <div className="ml-auto"></div>
      {secondaryButtonLabel && (
        <Button
          color={'secondary'}
          width={'fit'}
          classes={'px-4 mr-4'}
          onClick={() => {
            secondaryButtonAction();
          }}
          theme={useTheme().theme}
        >
          {secondaryButtonLabel}
        </Button>
      )}
      <Button
        color={'primary'}
        width={''}
        onClick={() => {
          primaryButtonAction();
        }}
        disabled={disabled}
      >
        {primaryButtonLabel}
      </Button>
    </div>
  );
};

const StepperData = [
  { step: 1, title: 'T_LBL_FOR_PERMISSION_SET_DETAILS' },
  { step: 2, title: 'T_LBL_APPLICATION_FEATURES' },
  { step: 3, title: 'T_ADMIN_PORTAL_FEATURES' },
  { step: 4, title: 'T_LBL_FOR_OTHERS_SETTINGS' }
];

const ADMIN_FEATURES = sessionStorage.getItem(AppConstants.SESSION_ADMINPORTAL_KEY);
const SOLUTION_FEATURES = sessionStorage.getItem(AppConstants.SESSION_SOLUTION_KEY);

export const getApplicationFeatures = async (
  setIsLoading: any,
  setCollectData: any,
  collectdata: any,
  webPortal: any,
  dispatch: any
) => {
  setIsLoading(true);
  await getApplSolnFeatures(webPortal, setCollectData, collectdata, dispatch);
  setIsLoading(false);
};

const NewPermissionSet = ({ setNewDataLoaded, fromRole = false, openModal, setOpenModal }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [stepperData, setStepperData] = useState(StepperData);
  const [collectdata, setCollectData] = useState<any>({
    permissionDetails: null,
    applicationFeatures: JSON.parse(SOLUTION_FEATURES || '{}'),
    adminPortalFeatures: JSON.parse(ADMIN_FEATURES || '{}')
  });
  const [rows, setRows] = useState<any>([]);
  const [columnFeature, setColumnFeature] = useState<Array<any>>([]);
  const [adminFeatureTableData, setAdminFeatureTableData] = useState<any>([]);
  const [otherSettings, setOtherSettings] = useState<any>('[]');
  const adminFeatures = JSON.parse(
    sessionStorage.getItem(AppConstants.SESSION_ADMINPORTAL_KEY) || '{}'
  );
  const dispatch = useAppDispatch();
  const toastData = useAppSelector((state) => state.toast);
  const loginOrgId = getLoggedInOrg()?.organizationId;
  const { t } = useTranslation();

  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const webPortal = find(metaDataObjectList, { name: AppConstants.APPLICATION_NAME });
  const permissionSetInstances: any = find(metaDataObjectList, {
    name: AppConstants.METADATA_OBJECT_PERMISSION_SET
  });
  const rolePermissions = useAppSelector<any>((state: any) => {
    return state?.login?.rolePermissions;
  });

  const metaDataPermissions = useAppSelector(
    (state) => state.initialLoadData.metaDataPermissionSet
  );

  const permissions = useAppSelector((state) => state.initialLoadData.apiPermissions);

  // const { getPermission } = RolePermission();

  const { permissionDetails, applicationFeatures } = collectdata;

  useEffect(() => {
    if (!Object.keys(applicationFeatures).length) {
      getApplicationFeatures(setIsLoading, setCollectData, collectdata, webPortal, dispatch);
    }
    if (adminFeatures) {
      const permittedAdminFeatures = getPermittedItems(
        adminFeatures.features,
        //rolePermissions,
        metaDataPermissions.adminPortal,
        permissions
      );
      for (let i = 0; i < permittedAdminFeatures.length; i++) {
        permittedAdminFeatures[i] = {
          ...permittedAdminFeatures[i],
          checked: { All: false }
        };
      }
      setAdminFeatureTableData(permittedAdminFeatures);
    }
  }, []);

  /**
   * Method to make API to backend to fetch application features
   */

  const setStpperStep = (step: number, data?: any) => {
    if (data) {
      setCollectData({ ...collectdata, ...data });
    }
    setActiveStep(step);
  };

  useEffect(() => {
    if (Object.keys(applicationFeatures).length) {
      setColumnFeature(getAppFeatureColumns(applicationFeatures, 'action'));
      setRows(createRows(getAppFeatureRows(applicationFeatures, 'feature')));
    }
  }, [applicationFeatures]);

  const createRows = (rows: Array<any> = []): Array<any> => {
    return rows.map((row: any) => {
      let rowData = {};
      row.forEach((e: any) => {
        const { feature: name, action } = e;
        rowData = { ...rowData, name, [action]: { checked: false } };
      });
      return rowData;
    });
  };

  const resetPermissionData = () => {
    setCollectData({
      permissionDetails: {
        permissionName: '',
        description: ''
      },
      applicationFeatures: JSON.parse(
        sessionStorage.getItem(AppConstants.SESSION_SOLUTION_KEY) || '{}'
      ),
      adminPortalFeatures: JSON.parse(
        sessionStorage.getItem(AppConstants.ADMINPORTAL_FEATURES) || '{}'
      )
    });
    setAdminFeatureTableData(
      adminFeatureTableData.map((feature: any) => {
        feature.checked['All'] = false;
        return feature;
      })
    );

    setOtherSettings('[]');
    setActiveStep(1);
  };

  const handleCreateSystemSetting = async (
    permissionDetails: any,
    applicationFeatures: any,
    adminFeatures: any,
    otherSettings: any
  ) => {
    setIsLoading(true);
    // const { data } = await getCategories(
    //   '',
    //   AppConstants.DEFAULT_PAGE,
    //   AppConstants.MAXIMUM_PAGE_SIZE
    // );

    const userData = JSON.parse(localStorage.getItem('user-profile') || '');
    // const categoryId = data.data.filter(
    //   (idObj: any) => idObj.name === AppConstants.CATEGORY_PERMISSION_SETS
    // );
    dispatch(setPermissionSetList(null));
    dispatch(setPagingOpt(null));
    let settingObj: any = [];
    try {
      settingObj = JSON.parse(otherSettings);
    } catch (e) {
      settingObj = [];
    }

    const sortedFeature = applicationFeatures.map((feature: any) => {
      return {
        feature: feature.name,
        functions: Object.keys(feature)
          .filter((property) => {
            if (property !== 'name' && feature?.[property]?.checked) {
              return true;
            } else {
              return false;
            }
          })
          .map((filteredProperty) => {
            return {
              action: filteredProperty,
              apiPermission: []
            };
          })
      };
    });

    const sortedAdminFeatures = adminFeatures.map((feature: any) => {
      return {
        feature: feature.name,
        functions: feature.checked.All
          ? [
              {
                action: 'All',
                apiPermissions: feature.functions.map((permission: any) => {
                  return permission?.permissions[0]?.claim;
                })
              }
            ]
          : []
      };
    });

    const sortedSettings = settingObj?.map((setting: any) => {
      return {
        id: setting?.id,
        key: setting?.key,
        categories: [setting?.categories?.[0]?.name]
      };
    });
    //Payload for creating Permission Set in Metadata Instance.
    const systemSettingBody = {
      instances: [
        {
          label: permissionDetails.permissionName.trim(),
          attributes: {
            name: permissionDetails.permissionName.trim(),
            organizationId: loginOrgId,
            description: permissionDetails?.description || ' ',
            roles: [],
            permissions: {
              application: sortedFeature,
              adminPortal: sortedAdminFeatures,
              settings: sortedSettings.length ? sortedSettings : []
            },
            createdBy: userData.userId,
            createdByOrg: userData.createdByOrg,
            updatedBy: userData.userId,
            updatedOn: moment().toISOString().split('.')[0] + 'Z',
            //updatedOn: new Date().toISOString(),
            //createdOn: new Date().toISOString()
            createdOn: moment().toISOString().split('.')[0] + 'Z'
          }
        }
      ]
    };
    //Payload for creating Permission Set in system settings
    const systemSettingBodyCategory = {
      // categories: [categoryId[0].id],
      key: normalizeKey(permissionDetails.permissionName),
      value: {
        name: permissionDetails.permissionName.trim(),
        organizationId: loginOrgId,
        description: permissionDetails?.description || '',
        roles: [],
        permissions: {
          application: sortedFeature,
          adminPortal: sortedAdminFeatures,
          settings: settingObj ? settingObj : otherSettings
        },
        createdBy: userData.userId,
        createdByOrg: userData.createdByOrg,
        updatedBy: userData.userId,
        updatedOn: new Date().toISOString(),
        createdOn: new Date().toISOString()
      },
      dataType: AppConstants.DATA_TYPE_JSON
    };

    const response: any = await createPermissionSets(2, systemSettingBodyCategory, {
      id: permissionSetInstances?.id,
      body: systemSettingBody
    });
    if (Array.isArray(response)) {
      if (response.length) {
        dispatch(
          setToastData({
            toastMessage: `${permissionDetails.permissionName} ${t('T_TOAST_CREATED_SUCCESS_MSG')}`,
            isToastActive: true,
            toastType: 'success'
          })
        );
        setIsLoading(false);
        if (fromRole) {
          setNewDataLoaded(response[0]);
          setOpenModal(false);
        } else {
          setNewDataLoaded(true);
        }
      } else {
        dispatch(
          setToastData({
            toastMessage: `${t('T_ERROR_SOMETHING_WRONG')}`,
            isToastActive: true,
            toastType: 'error'
          })
        );
        if (fromRole) {
          setNewDataLoaded([]);
          setOpenModal(false);
        } else {
          setNewDataLoaded(true);
        }
      }
    } else {
      if (response.status === HTTP_STATUS.HTTP_CREATED) {
        dispatch(
          setToastData({
            toastMessage: `${permissionDetails.permissionName} ${t('T_TOAST_CREATED_SUCCESS_MSG')}`,
            isToastActive: true,
            toastType: 'success'
          })
        );
        setIsLoading(false);
        if (fromRole) {
          setNewDataLoaded(response?.data?.instances || []);
          setOpenModal(false);
        } else {
          setNewDataLoaded(true);
        }
      } else {
        dispatch(
          setToastData({
            toastMessage: `${t('T_ERROR_SOMETHING_WRONG')}`,
            isToastActive: true,
            toastType: 'error'
          })
        );
        setIsLoading(false);
      }
    }
    resetPermissionData();
  };
  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <ToastNotification
          message={toastData.toastMessage}
          isActive={toastData.isToastActive}
          setIsActive={() =>
            dispatch(
              setToastData({
                toastMessage: '',
                isToastActive: false,
                toastType: null
              })
            )
          }
          status={toastData.toastType}
        />

        <Modal
          showHeader={true}
          showCloseIcon={true}
          width="75rem"
          headerText={t('T_HEADER_TITLE_FOR_NEW_PERMISSION')}
          toggleVisiblity={(value: boolean) => {
            setOpenModal(false);
            resetPermissionData();
          }}
          isVisible={openModal}
          parentOverflowProperty="overflow-hidden"
          body={
            <div>
              <div className="mb-5 mt-3">
                <Stepper
                  stepperData={stepperData}
                  activeStep={activeStep}
                  className={`w-[540px]`}
                />
              </div>

              {activeStep === 1 && (
                <PermissionDetails
                  ActionButtons={ActionButtons}
                  setStpperStep={setStpperStep}
                  detailsData={permissionDetails}
                  closeModal={() => {
                    setOpenModal(false);
                    resetPermissionData();
                  }}
                  edit={false}
                />
              )}

              {activeStep === 2 && (
                <ApplicationFeatureDataGrid
                  ActionButtons={ActionButtons}
                  setStpperStep={setStpperStep}
                  applicationFeatures={applicationFeatures}
                  rows={rows}
                  columnFeature={columnFeature}
                  setRows={setRows}
                  resetData={resetPermissionData}
                />
              )}

              {activeStep === 3 && (
                <AdminFeaturesDataGrid
                  ActionButtons={ActionButtons}
                  setStpperStep={setStpperStep}
                  tableData={adminFeatureTableData}
                  setTableData={setAdminFeatureTableData}
                />
              )}

              {activeStep === 4 && (
                <>
                  <ApplicationSettings
                    jsonString={otherSettings}
                    setJsonString={setOtherSettings}
                    isComponent
                    isEdit
                  />
                  <ActionButtons
                    primaryButtonLabel={t('T_FINISH')}
                    primaryButtonAction={() => {
                      // setStpperStep(5);
                      setIsLoading(true);
                      if (!fromRole) setOpenModal(false);
                      handleCreateSystemSetting(
                        collectdata.permissionDetails,
                        rows,
                        adminFeatureTableData,
                        otherSettings
                      );
                    }}
                    secondaryButtonLabel={t('T_BUTTON_LABEL_FOR_BACK_TO_PORTAL')}
                    secondaryButtonAction={() => {
                      setStpperStep(3);
                    }}
                  />
                </>
              )}
            </div>
          }
        />
      </LoaderContainer>
    </>
  );
};

export default NewPermissionSet;
