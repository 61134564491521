import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    requestBody: {} as any,
    tokenId: '',
    userRol: null,
    rolePermissions: JSON.parse(sessionStorage.getItem('rolePermissions') || '[]'),
    adminFeatures: sessionStorage.getItem('adminFeatures') || null,
    expirationTime:
      sessionStorage.getItem('expirationTime') !== undefined &&
      sessionStorage.getItem('expirationTime') !== null
        ? parseInt(sessionStorage.getItem('expirationTime') || '0')
        : -1,
    timeRemaining: -1,
    timerStarted: false,
    isLoggedIn:
      sessionStorage.getItem('loggedIn') !== undefined &&
      sessionStorage.getItem('loggedIn') !== null &&
      sessionStorage.getItem('loggedIn') === 'true'
        ? true
        : false
  },
  reducers: {
    loginStep2Action: (state, action) => {
      state.requestBody = action.payload;
    },
    setOTP: (state, action) => {
      state.requestBody.callbacks[0].input[0].value = action.payload;
    },
    setTokenId: (state, action) => {
      state.tokenId = action.payload.token;
      // state.isLoggedIn = true;
      // sessionStorage.setItem('loggedIn', 'true');
    },
    setUserRol: (state, action) => {
      state.userRol = action.payload;
    },
    setRolePermissions: (state, action) => {
      state.rolePermissions = action.payload;
      sessionStorage.setItem('rolePermissions', JSON.stringify(action.payload));
    },
    setUserFeatures: (state, action) => {
      state.adminFeatures = action.payload;
      sessionStorage.setItem('adminFeatures', action.payload);
    },
    getState: (state) => {
      return state;
    },
    logIn: (state) => {
      state.isLoggedIn = true;
      sessionStorage.setItem('loggedIn', 'true');
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      sessionStorage.clear();
      localStorage.clear();
    }
  }
});

export const {
  loginStep2Action,
  setOTP,
  setTokenId,
  setUserRol,
  setRolePermissions,
  setUserFeatures,

  getState,
  logIn,
  logOut
} = loginSlice.actions;

export default loginSlice.reducer;
