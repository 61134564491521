interface TabDrawerProps {
  tabConfig: any;
  activeTab?: string;
  className?: string;
  setActiveTab: Function;
  borderBottom?: boolean;
  style?: any;
  childStyle?: any;
}

interface TabItemProps {
  label: string;
  active: string;
  setActiveTab: Function;
}

const TabItem: React.FC<TabItemProps> = ({ label, active, setActiveTab }) => {
  return (
    <li
      className="mr-2"
      onClick={() => {
        setActiveTab(label);
      }}
      key={`${label}-key`}
      data-testid={label}
    >
      <span
        className={`mx-4 inline-block cursor-pointer rounded-t-lg border-b-2 py-[0.6rem] ${
          label !== active
            ? 'border-transparent font-normal hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300'
            : 'border-[#127ADB] font-semibold text-[#127ADB] dark:border-[#127ADB] dark:text-[#127ADB]'
        }`}
      >
        {label}
      </span>
    </li>
  );
};

const TabDrawer: React.FC<TabDrawerProps> = ({
  tabConfig,
  activeTab,
  className = '',
  setActiveTab,
  borderBottom,
  style = {},
  childStyle = {}
}) => {
  return (
    <>
      <div
        className="text-center text-gray-500 dark:border-gray-700 dark:text-gray-400"
        style={borderBottom ? { borderBottom: '1px solid #D9D9D9' } : { borderBottom: 'none' }}
      >
        <ul className="-mb-px flex flex-wrap" style={style}>
          {tabConfig.map((config: any, idx: number) => {
            return (
              <TabItem
                key={idx}
                label={config.label}
                active={`${activeTab}`}
                setActiveTab={setActiveTab}
              />
            );
          })}
        </ul>
      </div>

      <div key={tabConfig} style={childStyle}>
        {tabConfig.map((ele: any, idx: number) => {
          return (
            <span key={idx} style={{ height: 'inherit' }}>
              {ele.label === activeTab && ele.component}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default TabDrawer;
