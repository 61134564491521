import styled from 'styled-components/macro';
import {
  colorBackgroundAlert1,
  colorBackgroundBlue,
  colorBlack,
  colorBorderAlert1,
  colorGreen,
  colorLightGray4,
  colorLightGray5,
  colorLightGray7,
  colorLightGray8,
  colorWhite,
  flexCenter,
  flexColumn,
  gridCenter
} from '../../styles/globalVariables';
import { device } from '../../styles/mediaQueries';
import { uTransitionTranslateX, uTransitionTranslateY } from '../../styles/utility';
import { SharedTextInput } from '../Reusable/Shared';
import { ActiveProp, StepProp } from './interfaces';

/**
 * Explore
 */

export const StyledModal = styled.div`
  ${flexColumn}
  border-radius: 10px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  width: 842px;
  position: relative;
  justify-content: space-between;
  background-color: rgb(${colorWhite});
  pointer-events: all;

  ${uTransitionTranslateY}

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Welcome = styled.div`
  flex: 0 0 30%;
  ${flexColumn}
  margin-top: 3.75rem;
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  & > p {
    margin-bottom: 2.4rem;
    color: rgb(${colorLightGray8});
  }
`;

export const ProgressBarContainer = styled.div`
  ${flexCenter}
  width: 100%;
  align-items: flex-start;
  margin-bottom: 2.68rem;
`;

export const StepContainer = styled.div`
  ${gridCenter}
`;

export const StepDescription = styled.p<ActiveProp>`
  font-size: 0.75rem;
  width: 10ch;
  text-align: center;
  margin-top: 0.56rem;
  color: rgb(${(p) => (p.isActive ? colorBlack : colorLightGray7)});
  font-weight: ${(p) => (p.isActive ? 600 : 400)};
`;

export const Step = styled.div<StepProp>`
  font-size: 0.75rem;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  ${gridCenter}
  transition: all 0.6s ease;

  border: 1.5px solid
    rgb(${(p) => (p.isActive ? colorBlack : p.isDone ? colorGreen : colorLightGray5)});
  color: rgb(${(p) => (p.isActive ? colorBlack : colorLightGray8)});
  background-color: rgb(
    ${(p) => (p.isActive ? colorWhite : p.isDone ? colorGreen : colorLightGray5)}
  );
  font-weight: ${(p) => (p.isActive ? 500 : 300)};
`;

export const ProgressBar = styled.div<StepProp>`
  height: 0.3rem;
  width: 34%;
  background-color: rgb(${colorLightGray5});
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-inline: -0.7rem;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    background-color: rgb(${colorGreen});
    position: absolute;
    transition: transform ease 0.6s;
    transform: ${(p) => (p.isDone ? 'translateX(0%)' : 'translateX(-100%)')};
  }
`;

export const Middle = styled.div`
  display: flex;
  flex: 0 0 40%;
  justify-content: space-evenly;
  width: 100%;
  padding-bottom: 13.4rem;
`;

export const ContentContainer = styled.div`
  ${flexColumn}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  img {
    position: relative;
    z-index: 1;
    top: 3.3rem;
  }
`;

export const Text = styled.div`
  text-align: center;
  margin-top: 1rem;
  h2 {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 0.3rem;
  }

  p {
    line-height: 22px;
    width: 25ch;
    color: rgb(${colorLightGray8});
    font-size: 0.88rem;
  }
`;

export const ImgContainer = styled.div`
  position: relative;
  margin-bottom: 7rem;
  ${flexCenter}
`;

export const ImgBackground = styled.div`
  position: absolute;
  background-color: rgb(${colorBackgroundBlue});
  border-radius: 50%;
  height: 12rem;
  aspect-ratio: 1;
  top: 0;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 2.5rem;
  right: 3.75rem;
`;

/**
 * PersonalInfo
 */

export const InfoMiddle = styled(Middle)`
  flex-direction: column;
  padding-bottom: 10.3rem;

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
  }
`;

export const Info = styled.div`
  margin-left: 3.75rem;
`;

export const StyledPersonalInfo = styled.div`
  flex: 0 0 70%;
  width: 100%;
`;

export const Inputs = styled.div`
  display: flex;
  margin-top: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 1.87rem;
`;

export const InputContainer = styled.div`
  ${flexColumn}
  align-items: flex-start;
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: 0.35rem;

  svg {
    align-self: center;
    margin-right: 0.32rem;
  }
`;

export const Label = styled.label`
  ${flexColumn}
  align-items: flex-start;
`;

export const Input = styled(SharedTextInput)`
  border-radius: 4px;
  width: 18.75rem;
  height: 2.75rem;
  padding: 0.81rem 0.75rem;
`;

export const InputProfile = styled(SharedTextInput)`
  border-radius: 4px;
  width: 18.75rem;
  height: 2rem;
  padding: 0.81rem 0.75rem;
`;

export const Divider = styled.hr`
  width: 100%;
  height: 0.5rem;
  background-color: rgb(${colorLightGray4});
  margin-bottom: 1.25rem;
`;

export const UploadPhotoContainer = styled.div`
  ${flexColumn}
  margin-left: 3.75rem;
  h2 {
    align-self: flex-start;
    margin-bottom: 1.87rem;
  }
`;

export const UploadPhoto = styled.div`
  display: flex;
  align-self: flex-start;
`;

export const PhotoWrapper = styled.div`
  & > input {
    display: none;
  }
  img {
    margin-left: 0.3rem;
    margin-bottom: 1rem;
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const PhotoInfo = styled.div`
  color: rgb(${colorLightGray8});
  margin-left: 3.4rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Instructions = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
  font-weight: 400;
  color: #595959;
`;

export const SmallText = styled.p`
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.3rem;
  color: #595959;
`;

export const UploadFailed = styled.div<ActiveProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  background-color: rgb(${colorBackgroundAlert1});
  border: 1px solid rgb(${colorBorderAlert1});
  font-size: 0.75rem;
  border-radius: 4px;
  padding: 0.34rem 0.7rem;
  justify-self: flex-end;
  margin: auto 0 0.2rem 0;

  ${uTransitionTranslateX}

  & > img {
    margin-right: 0.625rem;
  }
`;

export const AlertContainer = styled.div`
  position: absolute;
  bottom: -5rem;
`;
