import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../shared/ModalPortal/Modal';
import { useTranslation } from 'react-i18next';
import { ModalButton } from '../../../styles/globalVariables';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import DownloadIcon from '@heroicons/react/outline/DownloadIcon';
import { getMigrationLogData } from '../../../http/migration-service';
import { AppConstants } from '../../../constants/AppConstants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import styled from 'styled-components/macro';
import { setToastData } from '../../../app/toastReducer';
import { IconButton } from '../../primitives/buttons/IconButton';

interface LogViewerProps {
  url: string;
  show: boolean;
  setShow: Function;
}

const Container = styled.div`
  padding: 1rem;
  width: 100%;
  padding: 0px 2.5rem 0px 2.5rem;
  min-height: 25vh;
  max-height: 75vh;
  overflow: auto;
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  padding: 1rem;
  z-index: 1;
`;

const LogViewer: React.FC<LogViewerProps> = ({ url, show, setShow }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [logData, setLogData] = useState('');
  const [getLogs, setGetLogs] = useState(true);
  const logContainerRef = useRef<any>(null);

  useEffect(() => {
    if (logContainerRef.current && logData.length) {
      logContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [logData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMigrationLogData(url);
        if (response.status === HTTP_STATUS.HTTP_OK) {
          const { data } = response;
          setLogData(data);

          if (data.includes('Export Status: {SUCCESS}')) {
            setGetLogs(false);
          }
        } else {
          dispatch(
            setToastData({
              toastMessage: t('T_MIGRATION_JOB_LOG_NOT_FOUN'),
              isToastActive: true,
              toastType: 'error'
            })
          );
          handleDismiss();
        }
      } catch (error) {
        dispatch(
          setToastData({
            toastMessage: t('T_MIGRATION_JOB_LOG_NOT_FOUN'),
            isToastActive: true,
            toastType: 'error'
          })
        );
        handleDismiss();
      }
    };

    fetchData();

    const interval = setInterval(() => {
      if (getLogs) {
        fetchData();
      }
    }, AppConstants.DEFAULT_MIGRATION_LOG_REFRESH_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [getLogs]);

  const handleDismiss = () => {
    setShow(false);
  };

  const handleDownload = () => {
    window.open(url);
  };

  return (
    <Modal
      overflowVisible={true}
      showCloseIcon={true}
      width="90rem"
      showHeader={true}
      headerText={t('T_LOG_VIEWER')}
      isVisible={show}
      toggleVisiblity={handleDismiss}
      body={
        <Container>
          <StickyContainer>
            <div className="flex justify-end">
              <IconButton onClick={handleDownload} className="mr-2">
                <DownloadIcon className="h-8"></DownloadIcon>
              </IconButton>
            </div>
          </StickyContainer>
          <div>
            <pre>{logData}</pre>
            <div ref={logContainerRef}></div>
          </div>
        </Container>
      }
    />
  );
};

export default LogViewer;
