import { BiIconCheck } from '../../assets/components/svgs';
import { useTranslation } from 'react-i18next';
export interface IPasswordPolicy {
  [x: string]: any;
  minLength: number;
  maxLength: number | null;
  requireSpecialCharacter: boolean;
  requireUpperCase: boolean;
  requireLowerCase: boolean;
  requireNumber: boolean;
}

export const handleOnSuccessCss = (isSuccess: boolean) => {
  let initialClassName = 'text-gray-800';

  if (isSuccess) {
    initialClassName = 'text-green';
  }

  return initialClassName;
};

export const passwordRequirementComponent = (
  conditionCheck: any,
  statement: string,
  policy: string = ''
) => {
  return (
    <li key={policy} className="flex items-center gap-1">
      <span className={handleOnSuccessCss(conditionCheck)}>{BiIconCheck}</span>
      <p
        data-testid="account-profile-minimum-length-p"
        className={`text-sm font-light ${handleOnSuccessCss(conditionCheck)}`}
      >
        {statement}
      </p>
    </li>
  );
};

function PasswordRequirement({ passwordRequirements, passwordPolicy }: any) {
  const { t } = useTranslation();

  const getPolicyName = (policyName: string) => {
    switch (policyName.slice(0, 2)) {
      case 'AB':
        return `${t('T_UPPERCASE')}`;
      case 'ab':
        return `${t('T_LOWERCASE')}`;
      case '01':
        return `${t('T_DIGITS')}`;
      default:
        return policyName;
    }
  };
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-sm">{t('T_PASSPORT_POLICY_HINT_MSG1')}</h3>
      <ul className="flex flex-col gap-2">
        {passwordPolicy?.pwdMinLength
          ? passwordRequirementComponent(
              passwordRequirements.pwdMinLength,
              `${passwordPolicy?.pwdMinLength || 8} ${t('T_PASSPORT_POLICY_HINT_MSG2')}`
            )
          : ''}
        {passwordPolicy?.characterSets?.map((policy: string, index: number) => {
          return passwordRequirementComponent(
            passwordRequirements.characterSets[index],
            `${t('T_AT_LEAST')} ${policy[0]} ${getPolicyName(policy?.slice(2))}`,
            policy
          );
        })}
      </ul>
    </div>
  );
}

export default PasswordRequirement;
