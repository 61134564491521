import styled from 'styled-components/macro';
import Plus from '../../assets/plus.svg';
import Minus from '../../assets/minus.svg';
import { colorLightGray5, colorLightGray7 } from '../../styles/globalVariables';

interface ChildProps {
  disabled?: boolean;
}
const Child = styled.div<ChildProps>`
  width: 32px;
  height: 28px;
  background: ${({ disabled }) => (disabled ? '#FAFAFA' : '#ffffff')};
  box-shadow: ${({ disabled }) =>
    disabled ? '0px 0px 3px rgba(165, 165, 165, 0.2)' : '0px 0px 3px 0px #a5a5a533'};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 38px;
  cursor: pointer;
  box-shadow: 0px 0.5px 1.5px 0px #00000030;
`;

export const SubCard = styled.div`
  border-top-width: 1px;
  border-bottom-width: 1px;
  padding: 20px 0px;
  border-color: rgb(${colorLightGray5});
  border-type: solid;
`;

const Icon = styled.img``;

interface CalcButtonProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  type: number;
  className?: string;
  testId?: string;
}

export const enum Operation {
  PLUS,
  MINUS
}

export const CalcButton = ({ onClick, type, className, disabled, testId }: CalcButtonProps) => {
  return (
    <Container
      onClick={onClick}
      data-testid={testId}
      className={`${className} ${
        disabled && 'disabled pointer-events-none cursor-not-allowed disabled:opacity-75 '
      }`}
    >
      <Child disabled={disabled}>
        <Icon src={type ? Minus : Plus}></Icon>
      </Child>
    </Container>
  );
};
