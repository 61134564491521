import { createSlice } from '@reduxjs/toolkit';

export const toastSlice = createSlice({
  name: 'toaster',
  initialState: {
    isToastActive: false as boolean,
    toastMessage: '' as string,
    toastType: 'success' as any
  },
  reducers: {
    setToastData: (state, action) => {
      (state.isToastActive = action.payload.isToastActive),
        (state.toastMessage = action.payload.toastMessage),
        (state.toastType = action.payload.toastType);
    }
  }
});

export const { setToastData } = toastSlice.actions;

export default toastSlice.reducer;
