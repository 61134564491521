// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { Children, useEffect, useState } from 'react';
import Card from '../shared/cards/Card';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { useNavigate, useParams } from 'react-router-dom';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import styled from 'styled-components/macro';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { library } from '../../assets/components/svgs';
import userIcon from '../../assets/user-group.svg';
import {
  Label,
  InputStatus,
  InputContainer,
  ViewPasswordEye,
  InputF
} from '../AccountProfile/ProfileStyles';
import Asterix from '../../assets/asterix.svg';
import AdminActionModal from './adminActionModal';
import {
  validateContact,
  validateEmail,
  validateName,
  firstLastNameValidation
} from '../../util/validators';
import { getUsers, updateUser } from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { setAdminData } from '../../app/adminReducer';
import { getUserProfiles, isInviteExpired } from '../../util/admin-utils';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { getOrganization } from '../../http/organization-services';
import { getFailureMessage } from '../../util/ErrorUtil';
import { RouteConstants } from '../../constants/RouteConstants';
import { setURLPaths } from '../../app/organizationReducer';
import ChangePassword from './changePassword';
import { eye } from '../../assets/components/svgs';
import InputField from '../shared/Fields/InputField';
import { formatPhoneNumber } from '../../util/functions';
import { AppConstants } from '../../constants/AppConstants';
import { setUserProfileInfo } from '../../app/userManagementReducer';
import AuditInfo from '../Common/AuditInfo';

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

export interface adminSampleData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

interface InputFieldProps {
  label: string;
  required: boolean;
  type: string;
  id: string;
  placeholder: any;
  value: string;
  error: any;
  disabled: boolean;
  layout: string;
  fieldInfo?: string;
  onDataChange?: Function;
  countryCode?: string;
}

function InputFieldF({
  label,
  required,
  type,
  id,
  placeholder,
  value,
  error,
  disabled,
  layout,
  fieldInfo = '',
  onDataChange = () => {},
  countryCode = '+1'
}: InputFieldProps) {
  const statusField: any = {
    registered: { background: '#bfe4bf', border: '1px solid green' },
    cancelled: { background: '#E8A6B3', border: '1px solid #e3342f' }
  };
  const { t } = useTranslation();
  const [viewPassword, setViewPassword] = useState(false);

  return (
    <>
      <div className={`mb-5 pr-4 ${layout === '50' ? 'w-1/2' : 'w-full'}`}>
        <Label htmlFor={'id demo'}>
          {t(label)}
          {required ? <Image src={Asterix} /> : ''}
        </Label>
        {id === 'status' ? (
          <InputStatus
            className={`w-48 capitalize`}
            style={statusField[value] ? statusField[value] : {}}
            type={type}
            id={id}
            placeholder={t(placeholder) || ''}
            required={required}
            value={value}
            onChange={(e) => {
              onDataChange(id, e.target.value);
            }}
            disabled={disabled}
          />
        ) : (
          <InputContainer disabled={disabled}>
            {type === 'tel' && (
              <InputF
                className={'w-[65px] border-r-[0.5px] border-[#8c8c8c]'}
                type="text"
                required={required}
                id={'countryCode'}
                value={countryCode}
                disabled={disabled}
                onChange={(e) => {
                  onDataChange('countryCode', e.target.value);
                }}
              />
            )}
            <InputF
              className={`w-full`}
              type={viewPassword ? 'text' : type}
              id={id}
              placeholder={t(placeholder) || ''}
              required={required}
              value={value}
              onChange={(e) => {
                onDataChange(id, e.target.value);
              }}
              disabled={disabled}
              autoComplete="new-password"
            />
            {type === 'password' && (
              <ViewPasswordEye
                onClick={() => {
                  setViewPassword(!viewPassword);
                }}
              >
                <div className="m-auto w-fit">{eye}</div>
                <div className="-mt-[8px]	select-none">{viewPassword ? 'hide' : 'view'}</div>
              </ViewPasswordEye>
            )}
          </InputContainer>
        )}
        <div className="text-sm text-gray-700">{fieldInfo ? fieldInfo : ''}</div>
        <ErrorLabel>{error[id] ? error[id] : ''}</ErrorLabel>
      </div>
    </>
  );
}

function CardSection({ children }: any) {
  return (
    <div
      className={`flex w-fit flex-col justify-start  overflow-visible bg-gray-100 pt-2`}
      style={useTheme().theme.bgColorStyleForLoggedInPage}
    >
      <div className="flex">
        <div className="flex">
          <div className="p-2"></div>
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  );
}

/**
 * @parentId user-list
 * @manager View, Edit - User Details
 * @overview View, Edit of User Details
 *   <section>
 *       <p>
 *           <b>Edit User Details</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>First Name, Last Name, Phone, Email Address</b> fields. Clicking on <b>Reset</b> will reset the details to the default values.
 *           <br/></br>
 *
 *           <b>Change Password</b><br/>
 *           When we are in edit mode we can also change an password of user. By entering <b>Current Password, New Password and Confirm Password</b> we can changes the password.
 *           <br/>
 *           <b>Note: </b><i>Edit details and Change password</i> Option will only available if we are on profile page or if we seeing the details of an logged in user.<br/>
 *
 *           </br></br>
 *           <b>View User Details</b><br/>
 *           Clicking on user row on the user(s) listing page will take user to View User Details Page in READ-ONLY mode. Clicking on <b>Return to User(s) List</b> will take the user to User(s) listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i> 
 *           <br/><br/>
 *
 *           <b>Reinvite User</b><br/>
 *           User will only see <b>Reinvite User</b> button if user status is <b>Invited</b> or <b>Reinvited</b>. Clicking on <b>Reinvite User</b> reinvite user API will get called and button will get hidden for next 24 hours.
 *           <br/><br/>
 *
 *           <b>Deactivate User</b><br/>
 *           Clicking on <b>Deactivate User</b> will call an deactivate user API, this will remove user from the user list.
 *           <br/><br/>
 *           <b>Note: </b><i>Reinvite User</i> and <i>Deactivate User</i> Option will only available if we are on other users details page. This buttons will not be present on profile and logged in user detail page.<br/>
 *
 *       </p>
 *        </br>
 *       <p>
 *          <h4>View/Edit User Details Fields</h4>
 *          View/Edit User Details page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>First Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Last Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Phone</td>
 *               <td>No</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Role</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Email Address</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> While Editing an user details we cannot change role, It will be readonly field in form.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Edit User Details Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>UMS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>UMS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of View/Edit User Details page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for View/Edit User Details.</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>s
 *           <tr>
 *               <td>Organization Service</td>
 *               <td>1.3.1</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating User Details page</p>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View User Details</td>
 *               <td>/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Update User Details</td>
 *               <td>/users/{id}</td>
 *               <td>PATCH</td>
 *               <td>user-management-service.user.patch</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Reinvite User</td>
 *               <td>/invitations/{id}</td>
 *               <td>PUT</td>
 *               <td>user-management-service.invitation.reinvite</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>Deactivate User</td>
 *               <td>/users/{id}</td>
 *               <td>DELETE</td>
 *               <td>user-management-service.user.delete</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>Change User Password</td>
 *               <td>/users/{user-id}/password</td>
 *               <td>PUT</td>
 *               <td>user-management-service.password.change</td>
 *               <td>No</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for View/Edit User Details</p>
 *   </section>
 */

function AdminDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [orgnizationError, setOrganizationError] = useState<any>();
  const [userOrganization, setUserOrganization] = useState<any>(null);

  const organizationId = useAppSelector((state) => state.admin.orgId);
  const adminData = useAppSelector((state) => state.admin.adminPayload);
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);

  const isOnProfile = window.location.pathname === RouteConstants.ROUTE_PROFILE;
  const { t } = useTranslation();

  const setToastMsg = (msg: string, isActive: boolean, type: string) => {
    dispatch(setToastData({ toastMessage: msg, isToastActive: isActive, toastType: type }));
  };

  const CallUpdateAdminUserAPI = async (payload: any) => {
    const response: any = await updateUser(payload, adminData.userId || userProfile.userId);
    if (response.status === HTTP_STATUS.HTTP_OK) {
      setToastMsg(
        `${t('T_USER')} ${formData.firstName} ${formData.lastName} ${t('T_IS_UPDATE_SUCCESS_MSG')}`,
        true,
        'success'
      );
      if (isOnProfile || adminData.userId === userProfile.userId)
        dispatch(
          setUserProfileInfo({
            ...userProfile,
            ...response.data,
            ...{ mobilePhone: payload.mobilePhone }
          })
        );

      if (!isOnProfile)
        dispatch(
          setAdminData({ ...adminData, ...response.data, ...{ mobilePhone: payload.mobilePhone } })
        );
    } else {
      setToastMsg(response.data.issues[0].details, true, 'error');
    }
    setIsLoading(false);
    setEdit(false);
  };

  const getUserOrganization = async (organizationId: string) => {
    const orgResponse = await getOrganization(organizationId);
    const { status, data } = orgResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      setUserOrganization(data);
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(orgResponse),
          isToastActive: true,
          toastType: 'error'
        })
      );
      setOrganizationError(orgResponse);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const userId = params?.id || userProfile?.userId;
      const editProfile = userId === userProfile?.userId;
      let userProfileData = editProfile ? userProfile : adminData;
      if (userProfileData && !Object.keys(userProfileData).length) {
        const users: Array<any> = await getUserProfiles([userId]);
        if (users.length) {
          [userProfileData] = users;
        }
      }
      const { organization = [] } = userProfileData;
      const organizations: Array<any> = organization.filter((userOrg: any) => userOrg?.isOwner);
      if (organizations.length) {
        const [organization] = organizations;
        const { organizationId } = organization;
        getUserOrganization(organizationId);
      }
      setAllowEdit(editProfile);
      setAdminDataToState(userProfileData);
      updateBreadcrumb();
    };
    init();
  }, [isOnProfile, userProfile, adminData]);

  const updateBreadcrumb = () => {
    let name = adminData?.orgnizationName || userOrganization?.name;
    dispatch(
      setURLPaths([
        {
          key: !isOnProfile
            ? `${RouteConstants.ROUTE_ADMIN_LISTING}/${params?.id}`
            : `${RouteConstants.ROUTE_PROFILE}`,
          label: !isOnProfile ? `Users > ${name}` : t('T_PROFILE')
        }
      ])
    );
  };

  const setAdminDataToState = (data: any) => {
    const {
      firstName = '',
      lastName = '',
      mail: email = '',
      mobilePhone: phone = '',
      roles = [],
      accountStatus = '',
      orgnaizationName = '',
      name = [],
      userId = ''
    } = data;

    if (!userId) {
      navigate(RouteConstants.ROUTE_ADMIN_LISTING);
    }
    try {
      const countryCode = phone.slice(0, -10);
      let payload = {
        firstName,
        lastName,
        email,
        phone: phone ? formatPhoneNumber(phone.slice(-10)) : '',
        role: roles.map((role: any) => role.name).join(),
        organization: orgnaizationName,
        status: accountStatus === 'active' ? 'registered' : accountStatus,
        countryCode: countryCode === '' ? '+' : countryCode,
      };
      setFormData(payload);
    } catch {
      navigate(RouteConstants.ROUTE_ADMIN_LISTING);
    }
  };

  const [formData, setFormData] = useState<any>({
    firstName: '',
    lastName: '',
    phone: '',
    organization: '',
    email: '',
    status: '',
    role: '',
    countryCode: '+1'
  });

  const [formErrorData, setFormErrorData] = useState<any>({
    firstName: '',
    lastName: '',
    phone: '',
    email: ''
  });

  const setOnChange = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
    setFormErrorData({ ...formErrorData, [key]: '' });
  };

  const handleValidation = (): adminSampleData => {
    return {
      firstName: validateName(formData.firstName, t),
      lastName: validateName(formData.lastName, t),
      phone: validateContact(formData.phone, false, t),
      email: validateEmail(formData.email) ? '' : t('T_VALIDATION_ERR_EMAIL_MSG')
    };
  };

  const hasErrors = (): boolean => {
    return Object.entries(formErrorData).filter((key: any, value: any) => value.length).length > 0;
  };

  const hasBlankData = (): boolean => {
    const { firstName, lastName, email } = formData;
    return firstName.trim() === '' || lastName.trim() === '' || email.trim() === '';
  };

  const isDataChanges = () => {
    let userData = isOnProfile ? userProfile : adminData;
    const { firstName, lastName, phone, email, countryCode } = formData;
    return (
      firstName !== userData.firstName ||
      lastName !== userData.lastName ||
      email !== userData.mail ||
      (countryCode + phone).replace(/-/g, '') !== userData.mobilePhone
    );
  };

  const onSave = () => {
    let formError: adminSampleData = handleValidation();
    setFormErrorData(formError);
    let userData = isOnProfile ? userProfile : adminData;
    if (!formError.firstName && !formError.lastName && !formError.email && !formError.phone) {
      let postPayload = {
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        mobilePhone: formData.phone
          ? ((formData.countryCode !== '+' ? formData.countryCode : '') + formData.phone).replace(
              /-/g,
              ''
            )
          : '',
        email: formData.email.trim(),
        name: [
          userData.name.length
            ? {
                ...userData.name[0],
                given: formData.firstName.trim(),
                family: formData.lastName.trim(),
                text: `${formData.firstName.trim()} ${formData.lastName.trim()}`
              }
            : {
                given: formData.firstName.trim(),
                family: formData.lastName.trim(),
                text: `${formData.firstName.trim()} ${formData.lastName.trim()}`
              }
        ]
      };
      setIsLoading(true);
      CallUpdateAdminUserAPI(postPayload);
    }
  };

  const UpdateAdminDataStatus = (value: string) => {
    let updatedStatus = { ...adminData, ...{ accountStatus: value } };
    dispatch(setAdminData(updatedStatus));
    setAdminDataToState(updatedStatus);
    setIsLoading(false);
  };

  const navigateOrganization = () => {
    if (userOrganization) {
      navigate(`/organization/${userOrganization.id}`);
    }
  };

  const onCancel = () => {
    // setEdit(false);
    setAdminDataToState(isOnProfile ? userProfile : adminData);
    setFormErrorData({ firstName: '', lastName: '', phone: '', email: '' });
  };

  const getPhoneNumber = () => {
    const { telecom = [] } = userOrganization;
    let contact = '-';
    if (telecom && telecom.length) {
      telecom.forEach((ele: any) => {
        if (ele.system === AppConstants.ORGANIZATION_TELECOM_SYSTEM_PHONE_KEY) {
          contact = ele.value;
        }
      });
    }
    return contact;
  };

  const getAddress = () => {
    const { address = [] } = userOrganization;

    let _address = new Array();
    if (address && address.length) {
      const [address1] = address;
      const { line = [], city = '', state = '', country = '', postalCode = '' } = address1;
      _address.push(line && line.map((line: any) => line).join(', '));

      if (city) _address.push(city);
      if (state) _address.push(state);
      if (country) _address.push(country);
      if (postalCode) _address.push(postalCode);
    }
    return _address.join(', ') || '-';
  };

  const handleNameChange = (e: any) => {
    const { value = '', id = '' } = e.target || {};
    if (firstLastNameValidation(value)) {
      setFormData({ ...formData, [id]: value });
    }
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <MenuButtonsPortal>
        {!edit && (
          <>
            {!isOnProfile && (
              <div
                className="mr-4 cursor-pointer text-sm leading-10 text-primary hover:text-sky-500"
                //onClick={() => navigate(`/admin-list${organizationId ? `/${organizationId}` : ''}`)}
                onClick={() => {
                  if (location.pathname.split('/').includes('invited')) {
                    navigate(`/admin-list/invited${organizationId ? `/${organizationId}` : ''}`);
                  } else {
                    navigate(`/admin-list${organizationId ? `/${organizationId}` : ''}`);
                  }
                }}
              >
                {t('T_RETURN_TO_USER_LIST')}
              </div>
            )}

            {allowEdit && (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  setEdit(true);
                }}
              >
                {t('T_EDIT')}
              </BiButton>
            )}
          </>
        )}
      </MenuButtonsPortal>
      <div className="w-fit bg-gray-100 pt-5" style={useTheme().theme.bgColorStyleForLoggedInPage}>
        <CardSection>
          <Card
            title={t('T_USER_DETAIL_TITLE')}
            icon={userIcon}
            alt={t('T_USER_CARD_ALT_MESSAGE')}
            height=""
            width=""
          >
            <div className="relative flex flex-wrap" style={{ minWidth: '750px' }}>
              <div className="mb-4 mr-2 w-[48%]">
                <InputField
                  className="w-full"
                  type="text"
                  id={'firstName'}
                  label={t('T_FIRST_NAME')}
                  placeholder={t('T_FIRST_NAME')}
                  fieldKey="firstName"
                  required={true}
                  formData={formData}
                  formError={formErrorData}
                  setFormData={setFormData}
                  disabled={!edit}
                  handleChange={handleNameChange}
                />
              </div>

              <div className="mb-4 ml-5 w-[48%]">
                <InputField
                  className="w-full"
                  type="text"
                  id={'lastName'}
                  label={t('T_LAST_NAME')}
                  placeholder={t('T_LAST_NAME')}
                  fieldKey="lastName"
                  required={true}
                  formData={formData}
                  formError={formErrorData}
                  setFormData={setFormData}
                  disabled={!edit}
                  handleChange={handleNameChange}
                />
              </div>

              <div className="mr-2 flex w-[48%]">
                <div>
                  <InputField
                    className="w-[65px] border-none"
                    type="text"
                    id={'countryCode'}
                    label={t('T_PHONE_NUMBER')}
                    placeholder={''}
                    fieldKey="countryCode"
                    required={false}
                    formData={formData}
                    formError={formErrorData}
                    setFormData={setFormData}
                    disabled={!edit}
                    max={4}
                  />
                </div>

                <div className="w-full">
                  <InputField
                    className="mt-[24px] w-full"
                    errorClass="-ml-[65px]"
                    type="tel"
                    id={'phone'}
                    label={''}
                    placeholder={t('T_PHONE')}
                    fieldKey="phone"
                    required={false}
                    formData={formData}
                    formError={formErrorData}
                    setFormData={setFormData}
                    disabled={!edit}
                  />
                </div>
              </div>

              <div className="mb-4 ml-5 w-[48%]">
                <InputField
                  className="w-full"
                  type="text"
                  id={'role'}
                  label={t('T_ROLE')}
                  placeholder={t('T_ROLE')}
                  fieldKey="role"
                  required={true}
                  formData={formData}
                  formError={formErrorData}
                  setFormData={setFormData}
                  disabled={true}
                />
              </div>

              <div className="w-full">
                <InputField
                  className="w-full"
                  type="email"
                  id={'email'}
                  label={t('T_EMAIL_ADDRESS')}
                  placeholder={t('T_EMAIL_ADDRESS')}
                  fieldKey="email"
                  required={true}
                  formData={formData}
                  formError={formErrorData}
                  setFormData={setFormData}
                  disabled={!edit}
                />
              </div>
            </div>
            {edit && (
              <div className="mt-5 text-end">
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  {t('T_RESET')}
                </BiButton>

                <BiButton
                  className={'w-[170px] bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    onSave();
                  }}
                  disabled={hasErrors() || !isDataChanges() || hasBlankData()}
                >
                  {t('T_SAVE_CHANGES')}
                </BiButton>
              </div>
            )}
          </Card>
        </CardSection>

        {allowEdit && (
          <CardSection>
            <ChangePassword
              setIsLoading={setIsLoading}
              userData={isOnProfile ? userProfile : adminData}
              disabled={!edit}
            />
          </CardSection>
        )}
      </div>

      <div
        className="w-full bg-gray-100 pt-[20px]"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <CardSection>
          <Card
            title={t('T_ORG_DETAIL_TITLE')}
            icon={library}
            alt={t('T_CARD_ALT_MESSAGE')}
            height=""
            width=""
            bodyStyle={{ padding: '0px' }}
          >
            {!orgnizationError && (
              <div className="relative flex-wrap" style={{ width: '330px' }}>
                <div className="border-b-[1px] border-[#d9d9d9] px-[20px] py-[10px]">
                  <div className="text-[16px] font-light">Name</div>
                  <div
                    className="w-fit underline hover:cursor-pointer hover:text-blue-600"
                    onClick={navigateOrganization}
                  >
                    {formData['organization'] || userOrganization?.name}
                  </div>
                </div>

                <div className="border-b-[1px] border-[#d9d9d9] px-[20px] py-[10px]">
                  <div className="text-[16px] font-light">Address</div>
                  <div>{userOrganization && getAddress()}</div>
                </div>

                <div className="px-[20px] py-[10px]">
                  <div className="text-[16px] font-light">Phone Number</div>
                  <div>{userOrganization && getPhoneNumber()}</div>
                </div>
              </div>
            )}

            {orgnizationError && (
              <div className="relative flex-wrap text-red-700" style={{ width: '330px' }}>
                <div className="border-[#d9d9d9] px-[20px] py-[10px]">
                  <div className="px-[20px] py-[10px]">
                    <strong className="font-bold">{getFailureMessage(orgnizationError)}</strong>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </CardSection>

        {!isOnProfile && (
          <div className="pl-4">
            <InputFieldF
              type={'text'}
              id={'status'}
              label={t('T_STATUS')}
              placeholder={t('T_STATUS')}
              required={false}
              value={formData['status']}
              error={''}
              disabled={true}
              layout="100"
              fieldInfo={`${t('T_USER_IS_MSG')} ${formData['status']}.`}
            />
            {(adminData?.createdOn || adminData?.createdBy) && (
              <div>
                <AuditInfo
                  label={'CREATED'}
                  date={adminData?.createdOn || ''}
                  user={adminData?.createdBy || ''}
                />
              </div>
            )}
            {(adminData?.updatedOn || adminData?.updatedBy) && (
              <div>
                <AuditInfo
                  label={'LAST_MODIFIED'}
                  date={adminData?.updatedOn || ''}
                  user={adminData?.updatedBy || ''}
                />
              </div>
            )}

            <AdminActionModal
              status={formData.status}
              userId={adminData.userId}
              userName={`${formData.firstName} ${formData.lastName}`}
              setToastMsg={setToastMsg}
              setIsLoading={(value: boolean) => {
                setIsLoading(value);
              }}
              allowReinvite={isInviteExpired(adminData)}
              UpdateAdminDataStatus={UpdateAdminDataStatus}
            />
          </div>
        )}
      </div>
    </LoaderContainer>
  );
}

const Container = styled.div`
  width: 100%;
`;

export default AdminDetails;
