import {
  cloneDeep,
  compact,
  differenceBy,
  findIndex,
  isObject,
  remove,
  upperFirst,
  without
} from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@heroicons/react/outline/ChevronLeftIcon';
import ArrowRightIcon from '@heroicons/react/outline/ChevronRightIcon';
import ArrowUpIcon from '@heroicons/react/outline/ChevronUpIcon';
import ArrowDownIcon from '@heroicons/react/outline/ChevronDownIcon';
import DoubleRight from '@heroicons/react/solid/ChevronDoubleRightIcon';
import DoubleLeft from '@heroicons/react/solid/ChevronDoubleLeftIcon';
import DoubleUp from '@heroicons/react/outline/ChevronDoubleUpIcon';
import DoubleDown from '@heroicons/react/outline/ChevronDoubleDownIcon';
import { BiSearchIconInput } from '../../primitives/inputs/BiSearchIconInput.primitive';
import { IconButton } from '../../primitives/buttons/IconButton';
import { useDidMountEffect } from '../../../util/customHooks';

interface DualListStyleProps {
  listHeight?: string;
  listWidth?: string;
  buttonWidth?: string;
  iconHeight?: string;
  buttonHeight?: string;
  fontSize?: string;
  listHeaderFontSize?: string;
  searchWidth?: string;
}

interface DualingListPickProps {
  initialData: Array<any>;
  setInitialData: Function;
  outputData: Array<any>;
  setOutputData: Function;
  disableSearch?: boolean;
  searchPlaceholder?: string;
  fieldKey: string;
  fieldValues: Array<string>;
  styles?: DualListStyleProps;
  orderingControls?: boolean;
  inputHeader?: string;
  outputHeader?: string;
  disabled?: boolean;
}

export const DualingListPick: React.FC<DualingListPickProps> = ({
  initialData,
  setInitialData,
  outputData,
  setOutputData,
  disableSearch = false,
  fieldKey,
  fieldValues = [],
  searchPlaceholder = 'T_DUALING_LIST_DEFAULT_SEARCH_PLACEHOLDER',
  inputHeader = '',
  outputHeader = '',
  styles = {
    buttonHeight: 'h-8',
    buttonWidth: 'w-8',
    fontSize: 'text-base',
    iconHeight: 'h-4',
    listHeight: 'h-96',
    listWidth: 'w-80',
    listHeaderFontSize: 'text-base'
  },
  orderingControls = true,
  disabled = false
}: DualingListPickProps) => {
  const [selectedInput, setSelectedInput] = useState<any>([]);
  const [selectedOutput, setSelectedOutput] = useState<any>([]);
  const [inputData, setInputData] = useState<Array<any>>([]);
  const [availableSearchText, setAvailableSearchText] = useState('');
  const [selectedSearchText, setSelectedSearchText] = useState('');

  const [outputCopy, setOutputCopy] = useState<Array<any>>([]);

  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [isControlPressed, setIsControlPressed] = useState<boolean>(false);
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);

  const [isMouseDragging, setIsMouseDragging] = useState(false);
  const [startMouseIndex, setStartMouseIndex] = useState(-1);
  const [endMouseIndex, setEndMouseIndex] = useState(-1);

  const { t } = useTranslation();

  const {
    buttonHeight,
    buttonWidth,
    fontSize,
    iconHeight,
    listHeight,
    listWidth,
    listHeaderFontSize
  } = styles;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (['Control', 'Meta'].includes(e.key)) {
        setIsControlPressed(true);
      }
      if (e.key === 'Shift') {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (['Control', 'Meta'].includes(e.key)) {
        setIsControlPressed(false);
      }
      if (e.key === 'Shift') {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (!flag1 && initialData.length) {
      setInputData(initialData);
      setFlag1(true);
    }
  }, [initialData, flag1]);

  useEffect(() => {
    if (!flag2 && outputData.length) {
      setOutputCopy(outputData);
      setFlag2(true);
    }
  }, [outputData, flag2]);

  const selectItem = (type: string, val: any) => {
    if (type === 'input') {
      const selectedItemsFil = isControlPressed
        ? selectedInput.includes(val)
          ? selectedInput.filter((item: any) => item !== val)
          : [...selectedInput, val]
        : [val];
      setSelectedInput(selectedItemsFil);
    } else {
      const selectedItemsFil = isControlPressed
        ? selectedOutput.includes(val)
          ? selectedOutput.filter((item: any) => item !== val)
          : [...selectedOutput, val]
        : [val];
      setSelectedOutput(selectedItemsFil);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disableSearch) {
      const { name, value } = e.target;
      if (name === 'input') {
        setAvailableSearchText(value);
      } else if (name === 'output') {
        setSelectedSearchText(value);
      }
    }
  };

  useEffect(() => {
    if (!disableSearch) {
      let copy = cloneDeep(initialData);
      let availableSearchResult = copy.filter((item: any) =>
        getDisplayValue(isObject(item?.value) ? item?.value : item)?.includes(availableSearchText)
      );
      setInputData(availableSearchResult);
    }
  }, [availableSearchText]);

  useEffect(() => {
    if (!disableSearch) {
      let output = cloneDeep(outputData);
      let selectedSearchResult = output.filter((item: any) =>
        getDisplayValue(isObject(item?.value) ? item?.value : item)?.includes(selectedSearchText)
      );
      setOutputCopy(selectedSearchResult);
    }
  }, [selectedSearchText]);

  const buttonActions = (action: string) => {
    let inputClone = [...inputData];
    let outputClone = [...outputData];
    let initialClone = [...initialData];
    let outputCopyClone = [...outputCopy];
    let outputSelection = [...selectedOutput];
    let inputSelection = [...selectedInput];

    let result;

    switch (action) {
      case 'shiftToOutput':
        inputSelection.forEach((selection: any) => {
          let inputIndex = findIndex(inputClone, {
            [fieldKey]: selection
          });
          let element = inputClone[inputIndex];
          outputClone = [...outputClone, element];
          inputClone.splice(inputIndex, 1);

          if (availableSearchText.length) {
            let i = findIndex(initialClone, {
              [fieldKey]: selection
            });
            initialClone.splice(i, 1);
          }
        });

        setInputData(inputClone);
        setInitialData(availableSearchText.length ? initialClone : inputClone);
        setOutputData(outputClone);
        setOutputCopy(outputClone);
        setSelectedInput([]);
        setSelectedOutput([]);
        return;
      case 'shiftToInput':
        outputSelection.forEach((selection: any) => {
          let outputIndex = findIndex(outputClone, {
            [fieldKey]: selection
          });
          let element = outputClone[outputIndex];
          inputClone = [...inputClone, element];
          outputClone.splice(outputIndex, 1);
          if (selectedSearchText.length) {
            let i = findIndex(outputCopyClone, {
              [fieldKey]: selection
            });
            outputCopyClone.splice(i, 1);
          }
        });

        setOutputCopy(selectedSearchText.length ? outputCopyClone : outputClone);
        setInputData(inputClone);
        setInitialData(inputClone);
        setOutputData(outputClone);
        setSelectedInput([]);
        setSelectedOutput([]);
        return;
      case 'shiftEntireInput':
        result = [...outputClone, ...inputClone];
        setInputData([]);
        if (availableSearchText.length) {
          setInitialData(differenceBy(initialClone, inputClone, fieldKey));
          setAvailableSearchText('');
        } else {
          setInitialData([]);
        }
        setOutputData(result);
        setOutputCopy(result);
        setSelectedInput([]);
        setSelectedOutput([]);
        return;
      case 'shiftEntireOutput':
        result = [...inputClone, ...outputClone];
        if (selectedSearchText.length) {
          setOutputCopy([]);
          setOutputData(differenceBy(outputClone, outputCopyClone, fieldKey));
          setSelectedSearchText('');
        } else {
          setOutputCopy([]);
          setOutputData([]);
        }

        setInitialData(result);
        setInputData(result);
        setSelectedInput([]);
        setSelectedOutput([]);
        return;
      case 'moveToTop':
        outputSelection.forEach((selection: any) => {
          let outputIndex = findIndex(outputCopyClone, {
            [fieldKey]: selection
          });
          outputCopyClone.unshift(outputCopyClone.splice(outputIndex, 1)[0]);
        });
        setOutputCopy(outputCopyClone);
        setOutputData(outputCopyClone);
        setSelectedInput([]);
        setSelectedOutput([]);
        return;
      case 'moveToBottom':
        outputSelection.forEach((selection: any) => {
          let outputIndex = findIndex(outputCopyClone, {
            [fieldKey]: selection
          });
          outputCopyClone.push(outputCopyClone.splice(outputIndex, 1)[0]);
        });
        setOutputCopy(outputCopyClone);
        setOutputData(outputCopyClone);
        setSelectedInput([]);
        setSelectedOutput([]);
        return;
      case 'moveUpOne':
      case 'moveDownOne':
        outputSelection.forEach((selection: any) => {
          let outputIndex = findIndex(outputCopyClone, {
            [fieldKey]: selection
          });
          let finalIndex =
            action === 'moveDownOne'
              ? outputIndex + 1 === outputCopyClone.length
                ? 0
                : outputIndex + 1
              : outputIndex - 1 === -1
              ? outputCopyClone.length - 1
              : outputIndex - 1;
          let element = outputCopyClone[outputIndex];
          outputCopyClone.splice(outputIndex, 1);
          outputCopyClone.splice(finalIndex, 0, element);
        });

        setOutputCopy(outputCopyClone);
        setOutputData(outputCopyClone);
        setSelectedInput([]);
        setSelectedOutput([]);
        return;
      default:
        return () => {};
    }
  };
  const validator = (action: string) => {
    switch (action) {
      case 'shiftToOutput':
        return !selectedInput.length;
      case 'shiftToInput':
      case 'moveToTop':
      case 'moveUpOne':
      case 'moveToBottom':
      case 'moveDownOne':
        return !selectedOutput.length;
      case 'shiftEntireInput':
        return !inputData.length;
      case 'shiftEntireOutput':
        return !outputData.length;
      default:
        false;
    }
  };

  const getDisplayValue = (item: any = {}) =>
    compact(fieldValues.map((fieldValue: string) => item[fieldValue])).join();

  const handleMouseDown = (e: React.MouseEvent, type: string, val: any, index: number) => {
    if (isShiftPressed) {
      setIsMouseDragging(true);
      setStartMouseIndex(index);
      setEndMouseIndex(index);
      selectItem(type, val);
    }
  };

  const handleMouseMove = (e: React.MouseEvent, index: number, type: string) => {
    if (isShiftPressed) {
      if (isMouseDragging) {
        setEndMouseIndex(index);
        const startIndex = Math.min(startMouseIndex, endMouseIndex);
        const endIndex = Math.max(startMouseIndex, endMouseIndex);

        if (startIndex !== -1 && endIndex !== -1) {
          const selectedItems = type === 'input' ? selectedInput : selectedOutput;
          const selectedInRange = (() => {
            return type === 'input' ? inputData : outputCopy;
          })()
            .slice(startIndex, endIndex + 1)
            .map((item) => item[fieldKey]);
          const newSelectedItems = selectedItems.concat(selectedInRange);
          const uniqueSelectedItems = Array.from(new Set(newSelectedItems));
          if (type === 'input') {
            setSelectedInput(uniqueSelectedItems);
          } else {
            setSelectedOutput(uniqueSelectedItems);
          }
        }
      }
    }
  };

  const handleMouseUp = () => {
    if (isShiftPressed) {
      setIsMouseDragging(false);
      setStartMouseIndex(-1);
      setEndMouseIndex(-1);
    }
  };

  const listColumns = (type: string) => {
    const listRow = (data: any) => {
      return data.map((item: any, index: number) => {
        return (
          <li
            onMouseDown={(e: React.MouseEvent) => handleMouseDown(e, type, item[fieldKey], index)}
            onMouseMove={(e: React.MouseEvent) => handleMouseMove(e, index, type)}
            onMouseUp={handleMouseUp}
            data-testid={'dual-list-li'}
            className={`w-full cursor-pointer border-b border-gray-200 px-6 py-2 ${fontSize} ${
              (type === 'input'
                ? selectedInput.includes(item[fieldKey])
                : selectedOutput.includes(item[fieldKey])) && 'bg-gray-700 text-white'
            }`}
            onClick={() => {
              selectItem(type, item[fieldKey]);
            }}
          >
            {getDisplayValue(isObject(item.value) ? item.value : item)}
          </li>
        );
      });
    };

    return (
      <div className={`flex flex-col ${listHeight} ${listWidth}`}>
        <div className={`bg-gray-200 px-6 py-3 font-semibold ${listHeaderFontSize}`}>
          {type === 'input'
            ? inputHeader || t('T_DUAL_LIST_INPUT_HEADER')
            : outputHeader || t('T_DUAL_LIST_OUTPUT_HEADER')}
        </div>
        {disableSearch ? (
          ''
        ) : (
          <div className="flex w-full flex-row justify-between">
            <section className={'w-full'}>
              <BiSearchIconInput
                className="w-full"
                name={type}
                autoFocus
                value={type === 'input' ? availableSearchText : selectedSearchText}
                placeholder={t(`${searchPlaceholder}`)}
                onChange={handleChange}
              />
            </section>
          </div>
        )}
        <ul
          className={`h-full w-full overflow-y-auto rounded-b-lg border border-gray-200 bg-white text-gray-900`}
        >
          {listRow(type === 'input' ? inputData : outputCopy)}
        </ul>
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-row">
        {listColumns('input')}

        <div className="mx-3 mt-8 flex flex-col">
          <IconButton
            className={`${buttonHeight} ${buttonWidth}`}
            disabled={validator('shiftEntireInput') || disabled}
            onClick={() => {
              buttonActions('shiftEntireInput');
            }}
          >
            <DoubleRight className={iconHeight} />
          </IconButton>

          <IconButton
            className={`${buttonHeight} ${buttonWidth}`}
            disabled={validator('shiftToOutput') || disabled}
            onClick={() => {
              buttonActions('shiftToOutput');
            }}
          >
            <ArrowRightIcon className={iconHeight} />
          </IconButton>

          <IconButton
            className={`${buttonHeight} ${buttonWidth}`}
            disabled={validator('shiftToInput') || disabled}
            onClick={() => {
              buttonActions('shiftToInput');
            }}
          >
            <ArrowLeftIcon className={iconHeight} />
          </IconButton>

          <IconButton
            className={`${buttonHeight} ${buttonWidth}`}
            disabled={validator('shiftEntireOutput') || disabled}
            onClick={() => {
              buttonActions('shiftEntireOutput');
            }}
          >
            <DoubleLeft className={iconHeight} />
          </IconButton>
        </div>

        {listColumns('output')}

        {orderingControls ? (
          <div className="ml-3 mt-8 flex flex-col">
            <IconButton
              className={`${buttonHeight} ${buttonWidth}`}
              disabled={validator('moveToTop') || disabled}
              onClick={() => {
                buttonActions('moveToTop');
              }}
            >
              <DoubleUp className={iconHeight} />
            </IconButton>
            <IconButton
              className={`${buttonHeight} ${buttonWidth}`}
              disabled={validator('moveUpOne') || disabled}
              onClick={() => {
                buttonActions('moveUpOne');
              }}
            >
              <ArrowUpIcon className={iconHeight} />
            </IconButton>
            <IconButton
              className={`${buttonHeight} ${buttonWidth}`}
              disabled={validator('moveDownOne') || disabled}
              onClick={() => {
                buttonActions('moveDownOne');
              }}
            >
              <ArrowDownIcon className={iconHeight} />
            </IconButton>
            <IconButton
              className={`${buttonHeight} ${buttonWidth}`}
              disabled={validator('moveToBottom') || disabled}
              onClick={() => {
                buttonActions('moveToBottom');
              }}
            >
              <DoubleDown className={iconHeight} />
            </IconButton>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
