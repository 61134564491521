import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextAreaField from '../../../shared/Fields/TextAreaField';

interface TextBlockProps {
  formData: any;
  setFormData: any;
  formError: any;
  editMode?: boolean;
}
const TextBlock = ({ formData, setFormData, formError, editMode }: TextBlockProps) => {
  const { t } = useTranslation();

  const [charCount, setCharCount] = useState(0);
  useEffect(() => {
    if (formData?.option1) {
      setCharCount(formData.option1.length);
    } else if (!formData?.option1 && typeof formData?.option1 === 'string') {
      setCharCount(0);
    }
  }, [formData]);
  return (
    <div className="mt-3 flex w-full flex-col">
      <TextAreaField
        type={'text'}
        id={'question-read-only-textbox'}
        label={t('T_TEXT_BLOCK_INPUT')}
        placeholder={''}
        fieldKey={'option1'}
        required={true}
        formData={formData}
        setFormData={setFormData}
        formError={formError}
        rows={2}
        maxLength={150}
        handleChange={(e: any) => {
          setFormData({
            ...formData,
            option1: e.target.value,
            answerOptions: {
              ...formData?.answerOptions,
              option1: {
                ...formData?.answerOptions?.option1,
                value: e.target.value,
                isSelected: false
              }
            }
          });
        }}
        disabled={editMode}
      />
      <div className="self-end text-xs text-[#003A70]">{charCount}/150</div>
    </div>
  );
};

export default TextBlock;
