import { groupBy, uniqBy } from 'lodash';
import { ITableHeader } from '../components/shared/tables/models/ITableHeader';
import { SystemSetting } from './RoleUtil';

export const getAppFeatureColumns = (solnFeatures: SystemSetting, field: string): Array<any> => {
  let columns: Array<ITableHeader> = [];
  if (!solnFeatures || !Object.keys(solnFeatures).length) return columns;
  const { value } = solnFeatures;
  const { features = [] } = value;
  const featureObjects = features.flatMap((feature: any) => {
    const { name, functions = [] } = feature;
    return functions.flatMap((func: any) => {
      const { display, description, privilege } = func;
      return { display, description, ...privilege, name };
    });
  });
  return uniqBy(featureObjects, field);
};

export const getAppFeatureRows = (
  solnFeatures: SystemSetting,
  field: string,
  checked: boolean = false
): Array<any> => {
  let appFeatures: Array<ITableHeader> = [];
  if (!solnFeatures || !Object.keys(solnFeatures).length) return appFeatures;
  const { value } = solnFeatures;
  const { features = [] } = value;
  const featureObjects = features.flatMap((feature: any) => {
    const { name, functions = [] } = feature;
    return functions.flatMap((func: any) => {
      const { display, description, privilege = {} } = func;
      return { display, description, ...privilege, name, checked };
    });
  });
  return Object.values(groupBy(featureObjects, field));
};
