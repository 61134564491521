import React, { useState } from 'react';
import Modal from '../shared/ModalPortal/Modal';
import { useTranslation } from 'react-i18next';
import { Attribute } from './AttributeTreeNode';
import InputField from '../shared/Fields/InputField';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import {
  ADD_ATTRIBUTE_ACTION,
  EDIT_ATTRIBUTE_ACTION,
  METADATA_OBJECT_ATTRIBUTE_ENUM,
  METADATA_OBJECT_ATTRIBUTE_SEARCHABLE,
  METADATA_OBJECT_ATTRIBUTE_SORTABLE,
  METADATA_OBJECT_DATA_TYPE_ENUM,
  METADATA_OBJECT_DATA_TYPE_OBJECT,
  METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY,
  METADATA_OBJECT_DATA_TYPE_STRING_ARRAY,
  UNKNOWN_ATTRIBUTE_ACTION,
  getDataTypes,
  isEnumType,
  isNumericType,
  isObjectType,
  isStringArrayType,
  isStringType,
  validateAttributeName,
  validateMaximumLength
} from './metadataUtil';
import styled from 'styled-components/macro';
import { cloneDeep, isEqual, isUndefined } from 'lodash';
import { Label } from '../Onboarding/OnboardingStyles';
import { reconcileNode } from './metadataUtil';
import { METADATA_OBJECT_ATTRIBUTE_NAME } from './metadataUtil';
import { METADATA_OBJECT_ATTRIBUTE_REQUIRED } from './metadataUtil';
import { METADATA_OBJECT_ATTRIBUTE_EDITABLE } from './metadataUtil';
import { METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH } from './metadataUtil';
import { METADATA_OBJECT_ATTRIBUTE_MIN_VALUE } from './metadataUtil';
import { METADATA_OBJECT_ATTRIBUTE_MAX_VALUE } from './metadataUtil';
import { ModalButton } from '../../styles/globalVariables';
import BiButton from '../primitives/buttons/BiButton.primitive';
import EnumOptions from './EnumOptions';

const Switch = styled.div<{ toggle: boolean; disabled: boolean }>`
  width: 44px;
  height: 22px;
  background: ${({ toggle, disabled }) =>
    toggle ? (disabled ? '#BFBFBF' : '#0074e0') : '#BFBFBF'};
  border-radius: 16px;
  position: relative;
  transition: ease-out 0.5s;
  cursor: pointer;
  disabled;
`;

const ToggleButton = styled.div<{ toggle: boolean; disabled: boolean }>`
  position: absolute;
  height: 18px;
  top: calc(50% - 18px / 2);
  background: #ffffff;
  border-radius: 16px;
  width: 16px;
  ${({ toggle, disabled }) => (toggle ? (disabled ? `left: 2px;` : 'right: 2px;') : `left: 2px;`)}
`;

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

interface AttributeNodeProps {
  attribute: Attribute;
  level?: number;
  isNew?: boolean;
  operation?: number;
  onClose?: Function;
  onCancel?: Function;
  onSave?: Function;
  hasInstances?: boolean;
  baseParent?: any;
}

const AttributeNode: React.FC<AttributeNodeProps> = ({
  attribute,
  level = 0,
  operation = UNKNOWN_ATTRIBUTE_ACTION,
  onClose,
  onCancel,
  onSave,
  hasInstances = false,
  baseParent
}: AttributeNodeProps) => {
  const {
    name,
    type,
    isRequired = false,
    editable = true,
    maxLength,
    min,
    max,
    attributes,
    parent,
    isNew = false,
    enum: enumTypes = [],
    searchable = false,
    sortable = false
  } = attribute;
  const { t } = useTranslation();

  const [formData, setFormData] = useState<any>({
    name,
    type,
    isRequired,
    editable,
    searchable,
    sortable,
    maxLength,
    min,
    max,
    attributes,
    isNew,
    enumTypes
  });
  const [formError, setFormError] = useState<any>({
    name: name && name.trim().length > 0 ? '' : t('T_EMPTY_ATTRIBUTE_NAME'),
    type: type && type.trim().length > 0 ? '' : t('T_SELECT_ATTRIBUTE_TYPE')
  });

  const onChange = (key: string, value: any, focusOut: boolean = false) => {
    let error: string = formError[key] || '';
    switch (key) {
      case METADATA_OBJECT_ATTRIBUTE_NAME:
        error = validateAttributeName(value, t);
        break;
      case METADATA_OBJECT_ATTRIBUTE_MIN_VALUE:
        break;
      case METADATA_OBJECT_ATTRIBUTE_MAX_VALUE:
        break;
      case METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH:
        error = validateMaximumLength(value, t);
        break;
      case METADATA_OBJECT_ATTRIBUTE_ENUM:
        break;
    }
    setFormData({ ...formData, [key]: value });
    setFormError({ ...formError, [key]: error });
  };

  const onTypeChange = (value: any) => {
    if (attributes?.length && !isObjectType(value)) {
      setFormData({ ...formData, type: value });
      setFormError({ ...formError, type: t('T_TYPE_MISMATCH') });
      return;
    }
    const { type } = formError;
    if (type) {
      setFormError({ ...formError, type: '' });
    }
    const node: any = { ...formData };
    reconcileNode(node, value);
    setFormData({ ...node });
  };

  const isEditable = () => {
    return isNew;
  };

  const hasErrors = (): boolean => {
    return !!Object.values(formError).filter((item: any) => !!(item && item.trim())).length;
  };

  const isEditAction = () => {
    return operation === EDIT_ATTRIBUTE_ACTION;
  };

  const getAttributeTypes = (): Array<string> => {
    const dataTypes: Array<string> = getDataTypes(parent?.type);
    if (isEditAction()) {
      if (attributes?.length) {
        dataTypes.length = 0;
        dataTypes.push(METADATA_OBJECT_DATA_TYPE_OBJECT);
        dataTypes.push(METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY);
      }
    }
    return dataTypes;
  };

  const disableRequired = (): boolean => {
    let disabled: boolean = false;
    return disabled;
  };

  const disableUpdate = (): boolean => {
    const {
      name,
      type,
      isRequired,
      editable,
      min,
      max,
      searchable,
      sortable,
      maxLength,
      enum: enumTypes
    } = attribute;
    return isEqual(
      { name, type, isRequired, editable, searchable, sortable, min, max, maxLength, enumTypes },
      {
        name: formData.name.trim(),
        type: formData.type,
        isRequired: formData.isRequired,
        editable: formData.editable,
        searchable: formData.searchable,
        sortable: formData.sortable,
        min: formData.min,
        max: formData.max,
        maxLength: formData.maxLength,
        enumTypes: formData.enumTypes
      }
    );
  };

  const enableEditable = () => true;

  const checkEnumValidation = () => {
    const { type, enumTypes } = formData;
    return type === METADATA_OBJECT_DATA_TYPE_ENUM ? (enumTypes.length > 0 ? true : false) : true;
  };

  return (
    <div className="flex w-full flex-col flex-wrap">
      <>
        <div className="flex w-full flex-1 flex-col">
          <div className="mb-5 flex w-full flex-1 flex-col flex-wrap">
            <InputField
              type={'text'}
              id={METADATA_OBJECT_ATTRIBUTE_NAME}
              label={t('T_NAME')}
              placeholder={t('T_ATTRIBUTE_NAME')}
              fieldKey={METADATA_OBJECT_ATTRIBUTE_NAME}
              asterixFirst={true}
              required={true}
              formData={formData}
              setFormData={formError}
              formError={formError}
              disabled={isEditAction() && hasInstances && !isEditable()}
              // outline={formError.name}
              className={'flex flex-1 flex-row'}
              handleChange={(e: any) => {
                onChange(METADATA_OBJECT_ATTRIBUTE_NAME, e.target.value, false);
              }}
              min={1}
            />
          </div>

          <div className="mb-5 flex w-full flex-1 flex-col flex-wrap">
            <PickList
              fieldKey={'key'}
              label={t('T_TYPE')}
              required={true}
              fieldValues={['key']}
              selectedItems={
                formData.type ? [{ ['key']: formData.type, value: formData.type }] : []
              }
              onSelectedChange={(selection: any) => {
                const [selectedOption] = selection;
                const { value } = selectedOption || '';
                onTypeChange(value);
              }}
              items={getAttributeTypes()
                .map((value: string) => {
                  return { key: value, value };
                })
                .sort((a: any, b: any) => a.value - b.value)}
              disabled={isEditAction() && hasInstances && !isEditable()}
              readOnly={false}
              type={PICKLIST_TYPES.SINGLE}
            />
            {formError.type && <ErrorLabel>{formError.type}</ErrorLabel>}
          </div>

          {formData.type === METADATA_OBJECT_DATA_TYPE_ENUM ? (
            <div className="mb-5 flex w-full flex-1 flex-col flex-wrap">
              <EnumOptions
                options={formData.enumTypes}
                setOptions={(options: Array<string>) => {
                  setFormData({ ...formData, enumTypes: options });
                }}
              />
              {!checkEnumValidation() && <ErrorLabel>{t('T_INVALID_ENUM_OPTIONS')}</ErrorLabel>}
            </div>
          ) : (
            ''
          )}

          <div className="mb-5 flex w-full flex-1 flex-col flex-wrap">
            <div className="flex w-1/2 flex-1 flex-wrap">
              <Label className="mb-1 flex flex-1">{`${t('T_IS_REQUIRED')}`}</Label>
              <div className="flex flex-1 items-center justify-center">
                <Switch
                  toggle={formData.isRequired}
                  disabled={disableRequired()}
                  onClick={(e: any) => {
                    if (!disableRequired()) {
                      onChange(METADATA_OBJECT_ATTRIBUTE_REQUIRED, !formData.isRequired, false);
                    }
                  }}
                  data-testid={'boolean-field'}
                >
                  <ToggleButton toggle={formData.isRequired} disabled={disableRequired()} />
                </Switch>
                <div className="ml-3 self-center">{formData.isRequired ? 'True' : 'False'}</div>
              </div>
            </div>
            {formError.isRequired && <ErrorLabel>{formError.isRequired}</ErrorLabel>}
          </div>

          <div className="mb-5 flex w-1/2 flex-1 flex-wrap">
            <Label className="mb-1 flex flex-1">{`${t('T_EDITABLE')}`}</Label>
            <div className="flex flex flex-1 items-center justify-center">
              <Switch
                toggle={formData.editable}
                disabled={!enableEditable()}
                onClick={(e: any) => {
                  if (!enableEditable()) {
                    return;
                  }
                  onChange(METADATA_OBJECT_ATTRIBUTE_EDITABLE, !formData.editable, false);
                }}
                data-testid={'boolean-field'}
              >
                <ToggleButton toggle={formData.editable} disabled={!enableEditable()} />
              </Switch>
              <div className="ml-3 self-center">{formData.editable ? 'True' : 'False'}</div>
            </div>
          </div>

          {!isObjectType(formData.type) ? (
            <div className="mb-5 flex w-1/2 flex-1 flex-wrap">
              <Label className="mb-1 flex flex-1">{`${t('T_SEARCHABLE')}`}</Label>
              <div className="flex flex flex-1 items-center justify-center">
                <Switch
                  toggle={formData.searchable}
                  disabled={!enableEditable()}
                  onClick={(e: any) => {
                    if (!enableEditable()) {
                      return;
                    }
                    onChange(METADATA_OBJECT_ATTRIBUTE_SEARCHABLE, !formData.searchable, false);
                  }}
                  data-testid={'boolean-field'}
                >
                  <ToggleButton toggle={formData.searchable} disabled={!enableEditable()} />
                </Switch>
                <div className="ml-3 self-center">{formData.searchable ? 'True' : 'False'}</div>
              </div>
            </div>
          ) : (
            <div />
          )}

          {!isObjectType(formData.type) &&
          level <= 1 &&
          formData.type !== METADATA_OBJECT_DATA_TYPE_STRING_ARRAY &&
          baseParent?.type !== METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY ? (
            <div className="mb-5 flex w-1/2 flex-1 flex-wrap">
              <Label className="mb-1 flex flex-1">{`${t('T_SORTABLE')}`}</Label>
              <div className="flex flex-1 items-center justify-center">
                <Switch
                  toggle={formData.sortable}
                  disabled={!enableEditable()}
                  onClick={(e: any) => {
                    if (!enableEditable()) {
                      return;
                    }
                    onChange(METADATA_OBJECT_ATTRIBUTE_SORTABLE, !formData.sortable, false);
                  }}
                  data-testid={'boolean-field'}
                >
                  <ToggleButton toggle={formData.sortable} disabled={!enableEditable()} />
                </Switch>
                <div className="ml-3 self-center">{formData.sortable ? 'True' : 'False'}</div>
              </div>
            </div>
          ) : (
            <div />
          )}

          {!isUndefined(formData.maxLength) && (
            <div className="mb-5 flex w-full flex-1 flex-wrap">
              <InputField
                type={'number'}
                id={METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH}
                label={'T_MAX_LENGTH'}
                placeholder={''}
                fieldKey={METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH}
                required={isStringType(formData.type)}
                formData={formData}
                setFormData={setFormData}
                formError={formError}
                // className="col-span-3"
                disabled={false}
                // outline={formError.maxLength}
                min={Number.MIN_VALUE}
                max={Number.MAX_VALUE}
                onBlurhandler={(e: any) => {
                  onChange(METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH, Number(e.target.value), true);
                }}
                handleChange={(e: any) => {
                  onChange(METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH, Number(e.target.value), false);
                }}
              />
            </div>
          )}
          {isNumericType(formData.type) && (
            <div className="mb-5 flex w-full flex-1 flex-row">
              {!isUndefined(formData.min) && (
                <div className="mb-5 flex w-1/2 flex-1 flex-wrap">
                  <InputField
                    type={'number'}
                    id={METADATA_OBJECT_ATTRIBUTE_MIN_VALUE}
                    label={'T_MIN_VALUE'}
                    placeholder={t('T_MIN_VALUE')}
                    fieldKey={METADATA_OBJECT_ATTRIBUTE_MIN_VALUE}
                    required={isNumericType(formData.type)}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    // className="col-span-3"
                    disabled={false}
                    // outline={formError.min}
                    min={Number.MIN_VALUE}
                    max={Number.MAX_VALUE}
                    onBlurhandler={(e: any) => {
                      onChange(METADATA_OBJECT_ATTRIBUTE_MIN_VALUE, Number(e.target.value), true);
                    }}
                  />
                </div>
              )}
              {!isUndefined(formData.max) && (
                <div className="mb-5 flex w-1/2 flex-1 flex-wrap">
                  <InputField
                    type={'number'}
                    id={METADATA_OBJECT_ATTRIBUTE_MAX_VALUE}
                    label={'T_MAX_VALUE'}
                    placeholder={t('T_MAX_VALUE')}
                    fieldKey={METADATA_OBJECT_ATTRIBUTE_MAX_VALUE}
                    required={isNumericType(formData.type)}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    // className="col-span-3"
                    disabled={false}
                    // outline={formError.max}
                    min={Number.MIN_VALUE}
                    max={Number.MAX_VALUE}
                    onBlurhandler={(e: any) => {
                      onChange(METADATA_OBJECT_ATTRIBUTE_MAX_VALUE, Number(e.target.value), true);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </>
      <>
        <ModalButton>
          <BiButton
            className={'mr-2 text-primary'}
            type="button"
            onClick={() => {
              onCancel && onCancel();
            }}
            // disabled={!permissions.create}
          >
            {t('T_CANCEL')}
          </BiButton>
          <BiButton
            className={'bg-primary text-white'}
            type="button"
            onClick={() => {
              onSave && onSave(formData);
            }}
            disabled={hasErrors() || disableUpdate() || !checkEnumValidation()}
          >
            {operation == ADD_ATTRIBUTE_ACTION ? t('T_DONE') : t('T_UPDATE')}
          </BiButton>
        </ModalButton>
      </>
    </div>
  );
};

interface CreateNodeModalProps {
  attribute: any;
  level?: number;
  order?: number;
  // edit?: boolean;
  isNew?: boolean;
  show?: boolean;
  operation?: number;
  onSave?: Function;
  onClose?: Function;
  onCancel?: Function;
  hasInstances?: boolean;
  baseParent?: any;
}
const CreateNodeModal: React.FC<CreateNodeModalProps> = ({
  attribute = {},
  level = 0,
  isNew = false,
  operation = UNKNOWN_ATTRIBUTE_ACTION,
  show = false,
  onClose,
  onCancel,
  onSave,
  hasInstances = false,
  baseParent
}: CreateNodeModalProps) => {
  const { t } = useTranslation();

  const saveNodeData = (data: any) => {
    const cloneData = cloneDeep(data);

    if (isEnumType(cloneData.type)) {
      cloneData.enum = cloneData.enumTypes;
    } else {
      if (isObjectType(cloneData.type)) {
        delete cloneData.searchable;
        delete cloneData.sortable;
      } else if (isStringArrayType(cloneData.type)) {
        delete cloneData.sortable;
      }

      // Sortable is not allowed for neseted items
      if (level > 1) {
        delete cloneData.sortable;
      }
      delete cloneData.enum;
    }
    delete cloneData.enumTypes;

    // if (cloneData.type !== METADATA_OBJECT_DATA_TYPE_ENUM) {
    //   delete cloneData.enumTypes;
    // } else {
    //   cloneData.enum = cloneData.enumTypes;
    //   delete cloneData.enumTypes;
    // }
    onSave && onSave(cloneData);
  };
  return (
    <Modal
      showCloseIcon={true}
      width="50rem"
      headerText={operation == ADD_ATTRIBUTE_ACTION ? t('T_ADD_ATTRIBUTE') : t('T_EDIT_ATTRIBUTE')}
      toggleVisiblity={() => {
        if (onClose) {
          onClose(!show);
        }
      }}
      isVisible={show}
      body={
        <AttributeNode
          level={level}
          isNew={isNew}
          operation={operation}
          onCancel={onCancel}
          onClose={onClose}
          onSave={saveNodeData}
          attribute={attribute}
          hasInstances={hasInstances}
          baseParent={baseParent}
        />
      }
      //   headerLeftIcon={userIcon}
    />
  );
};

export default React.memo(CreateNodeModal);
