import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

interface ValidRefTarget {
  contains(target: EventTarget | null): any;
}

export const useOutsideDetect = (ref: React.RefObject<ValidRefTarget>, cb: Function) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

export const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const useEffectIfStateChanged = (effect: () => void, deps: React.DependencyList) => {
  const isFirstRender = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const isStateChanged = prevDeps.current.some(
        (prevDep, index) => !isEqual(prevDep, deps[index])
      );
      if (isStateChanged) {
        effect();
      }
    }

    prevDeps.current = deps;
  }, deps);
};
