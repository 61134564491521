import Card from '../shared/cards/Card';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import UserRole from '../../assets/user-cog.svg';
import Asterix from '../../assets/asterix.svg';
import { useState, useEffect } from 'react';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { setToastData } from '../../app/toastReducer';
import ToastNotification from '../Reusable/ToastNotification';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DynamicFormFieldRenderer from '../Reusable/DynamicFormFieldRenderer';
import { roleDetailsFormSchema } from '../../util/constants';
import LockIcon from '../../assets/lock-alt.svg';
import ShieldExclaimation from '../../assets/shield-excl.svg';
import Checkbox from '../shared/Fields/Checkbox';
import { setNewRoleData, setPermissionSet } from '../../app/rolesAndFeatureReducer';
import { CalcButton, SubCard } from './RoleUIElements';
import { RolePermission } from '../Organizations/helpers';

import {
  createPasswordPolicy,
  getPasswordPolicy,
  updatePasswordPolicy
} from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  createRole,
  getPermissions,
  getRole,
  updateRole
} from '../../http/access-management-service';
import { passwordComplexitySchema } from './Schema';
import { setURLPaths } from '../../app/organizationReducer';
import {
  Category,
  checkRoleFieldLength,
  createApplicationRole,
  getApplicationCategory,
  getUnAssignedPermissionSets,
  SystemSetting,
  updateApplicationContextKey,
  updatePermissionSet,
  updatePermissionSetInstance
} from '../../util/RoleUtil';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { find, findIndex, cloneDeep } from 'lodash';
import { getLoggedInOrg, getLoginUserId } from '../../util/admin-utils';
import { createEvent } from '../../http/event-services';
import { getMetadataInstances, updateMetadataInstance } from '../../http/metadata-service';
import { getSystemSettings } from '../../http/configuration-services';
import {
  checkPermissionSetMetadataExists,
  loadPermissionSetList
} from '../PermissionSet/PermissionSetUtil';
import { RouteConstants } from '../../constants/RouteConstants';
interface DynamicObject {
  [key: string]: any;
}

interface RoleDetails {
  roleName: string;
  roleDescription: string;
  permissionSetName: any; //SystemSetting
}

/**
 * @parentId roles
 * @manager Create, View, Edit - Role
 * @overview Create, View, Edit Role
 *   <section>
 *       <p>
 *           <b>View Role</b><br/>
 *           Clicking on role rowitem on the role listing page will take user to view role details page in view only mode. Clicking on <b>Back to Roles List</b> will take the user to role(s) listing page.
 *           <br/><br/>
 *           <b>Edit Role</b><br/>
 *           Editing of Role is only possible if the logged in user role API permission has edit permissions. i.e <strong>accesscontrol-service.role.update</strong>. Clicking on <b>Edit</b> button on the admin portal toolbar will change the page into editing mode, which allows users to edit <b>Application Role Name</b>, <b>Role Description</b>, <b>Application Role Security</b> and <b>Account Security Options</b> fields and Save the Role. Clicking on <b>Cancel</b> will take the user back to roles details page in view only mode.
 *           Once a role is created, Permission Set cannot be changed.
 *           <br/><br/>
 *           <b>Create Role</b><br/>
 *           New Role button is only enabled if the logged-in user role API permission has create  permission. i.e <strong>accesscontrol-service.role.create</strong>
 *           Clicking on <b>New Role</b> button on role listing page will take user to new role creation page. Clicking on <b>Cancel</b> will take the user to role listing page.
 *           <br/><br/>
 *           User can create new role by entering <b>Application Role Name</b>, selecting a <b>Permission Set</b> (via a dropdown) mandatory fields and clicking on <b>Save</b> button on the admin portal toolbar. <b>Role Description </b>field is optional.
 *       </p> <br/>
 *       <p>
 *       <h3>Application Security Role Fields</h3>
 *          <br/>
 *          <h4>Password Complexity</h4> <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Description</th>
 *               <th>Field Type</th>
 *               <th>Validation</th>
 *            </tr>
 *            <tr>
 *               <td>Minimum number of characters</td>
 *               <td>Minimum Length of Password</td>
 *               <td>Textfield with + - controls</td>
 *               <td>Minimum 6 characters</td>
 *            </tr>
 *            <tr>
 *               <td>Minimum special character(s)</td>
 *               <td>Minimum Length of special character(s)</td>
 *               <td>Textfield with + - controls</td>
 *               <td>NA</td>
 *            </tr>
 *            <tr>
 *               <td>Minimum number(s)</td>
 *               <td>Minimum Length of numbers in password</td>
 *               <td>Textfield with + - controls</td>
 *               <td>NA</td>
 *            </tr>
 *            <tr>
 *               <td>Minimum uppercase letter(s)</td>
 *               <td>Minimum Length of uppercase characters in password</td>
 *               <td>Textfield with + - controls</td>
 *               <td>NA</td>
 *            </tr>
 *            <tr>
 *               <td>Minimum lowercase letter(s)</td>
 *               <td>Minimum Length of lowercase characters in password</td>
 *               <td>Textfield with + - controls</td>
 *               <td>NA</td>
 *            </tr>
 *          </table><br/>
 *        <h4>Password Expiration</h4><br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Description</th>
 *               <th>Field Type</th>
 *               <th>Validation</th>
 *            </tr>
 *            <tr>
 *               <td>Expires in Days</td>
 *               <td>Days after which the password will expire and user will need to create a new password</td>
 *               <td>Textfield with + - controls</td>
 *               <td>NA</td>
 *            </tr>
 *          </table><br/>
 *        <h4>Password History</h4><br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Description</th>
 *               <th>Field Type</th>
 *               <th>Validation</th>
 *            </tr>
 *            <tr>
 *               <td>Passwords to remember:</td>
 *               <td>Number of passwords in history which can be reused</td>
 *               <td>Textfield with + - controls</td>
 *               <td>NA</td>
 *            </tr>
 *          </table><br/>
 *      <h3>Account Security Options Fields</h3>
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Description</th>
 *               <th>Field Type</th>
 *               <th>Validation</th>
 *            </tr>
 *            <tr>
 *               <td>Multi-factor Authentication</td>
 *               <td>Check to enable/disable multi-factor authentication</td>
 *               <td>Textfield with + - controls</td>
 *               <td>NA</td>
 *            </tr>
 *          </table>
 *       </p>
 *       <p>
 *          <h4>View/Create/Edit role Fields</h4>
 *          Create/View/Edit role page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Field Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Application Role Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>Textfield</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Permission Set</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>Dropdown</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Role Description</td>
 *               <td>No</td>
 *               <td>String</td>
 *               <td>Textfield</td>
 *               <td>1024</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> Deletion of Role is not possible from Admin Portal.
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes Create/Edit notifcate template failure status code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>NS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>NS_PARAM_DUPLICATE</td>
 *               <td>Duplicate role.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>NS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create/edit/view role page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit role page.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Access Control Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>Get Roles List</td>
 *               <td>/roles</td>
 *               <td>GET</td>
 *               <td>accesscontrol-service.role.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create role</td>
 *               <td>/roles</td>
 *               <td>POST</td>
 *               <td>accesscontrol-service.role.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Update role</td>
 *               <td>/roles/{role-id}</td>
 *               <td>PUT</td>
 *               <td>accesscontrol-service.role.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Get Role by ID</td>
 *               <td>/roles/{role-id}</td>
 *               <td>GET</td>
 *               <td>accesscontrol-service.role.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Password Policy</td>
 *               <td>/password-policies</td>
 *               <td>POST</td>
 *               <td>	user-management-service.password-policy.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Password Policy</td>
 *               <td>/password-policies/{id}</td>
 *               <td>PATCH</td>
 *               <td>user-management-service.password-policy.patch</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Get Password Policy</td>
 *               <td>/password-policies</td>
 *               <td>GET</td>
 *               <td>user-management-service.password-policy.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Get Permission Set</td>
 *               <td>/permissions</td>
 *               <td>GET</td>
 *               <td>accesscontrol-service.permission.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for view/create/edit role</p>
 *   </section>
 */

const RoleDetail = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(true);
  const [locationHeuristics, setLocationHeuristics] = useState<boolean>(false);
  const toastData = useAppSelector((state) => state.toast);
  const [mode, setMode] = useState(0); //0 is for Edit, 1 is for Create
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [editIds, setEditIds] = useState({ roleId: '', passwordPolicyId: '' });
  const [isLoading, setIsLoading] = useState(true);

  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const [permissionSetList, setPermissionSetList] = useState<Array<SystemSetting>>([]);
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [roleData, setRoleData] = useState<any>(null);
  const permissionSet = useAppSelector((state) => state.rolesAndFeature.permissionSet);
  const { t } = useTranslation();

  const [roleDetailsForm, setRoleDetailsForm] = useState<RoleDetails>({
    roleName: '',
    // createdBy: '',
    permissionSetName: permissionSet?.value?.name,
    roleDescription: ''
  });

  const [roleDetailsFormError, setRoleDetailsFormError] = useState({
    roleName: '',
    // createdBy: '',
    permissionSetName: '',
    roleDescription: ''
  });

  const [passwordComplexity, setPasswordComplexity] = useState(passwordComplexitySchema);
  const [passwordPolicyName, setPasswordPolicyName] = useState('');
  const [expirySchema, setExpirySchema] = useState(90);
  const [passwordHistory, setPasswordHistory] = useState(5);
  const [editRole, setEditRole] = useState(false);
  const [createRolePermission, setCreateRolePermission] = useState(false);

  const [tempValues, setTempValues] = useState<any>({
    passwordHistory: { focus: false, value: 5 },
    passwordExpiry: { focus: false, value: 90 },
    passwordComplexityCharacterNumbers: { focus: false, value: 8 },
    passwordComplexitySpecialCharacters: { focus: false, value: 1 },
    passwordComplexityNumbers: { focus: false, value: 1 },
    passwordComplexityLowercase: { focus: false, value: 1 },
    passwordComplexityUppercase: { focus: false, value: 1 }
  });
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const permissionSetObject: any = find(metaDataObjectList, {
    name: AppConstants.METADATA_OBJECT_PERMISSION_SET
  });
  const loggedInOrg = getLoggedInOrg();
  const userId = getLoginUserId();

  useEffect(() => {
    async function initialise() {
      await enableEditRole();
      if (location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === 'create') {
        // const permissionSetList = await getUnAssignedPermissionSets(permissionSetInstances.id);
        const permissionSetList = await getUnAssignedPermissionSets(2, permissionSetObject?.id);
        const filteredCategories = await getApplicationCategory([
          AppConstants.CATEGORY_APPLICATION_MODEL
        ]);

        const getRolePermissionsResponse = await getPermissions(1000);
        setRolePermissions(getRolePermissionsResponse?.data?.data);

        setCategories(filteredCategories);
        setPermissionSetList(permissionSetList);
        setMode(1);
        setIsEdit(true);
        setIsLoading(false);
        setBreadCrumb(1);
      } else {
        saveEditResponse(null);
      }
    }

    initialise();
  }, []);

  const loadPermissionSet = async (roleData: any) => {
    const responseList: any = await loadPermissionSetList(
      2,
      {
        category: AppConstants.CATEGORY_PERMISSION_SETS,
        page: AppConstants.DEFAULT_PAGE,
        size: AppConstants.MAXIMUM_PAGE_SIZE
      },
      {
        permissionSetInstances: permissionSetObject,
        page: AppConstants.DEFAULT_PAGE,
        size: AppConstants.MAXIMUM_PAGE_SIZE
      }
    );
    const { flag, response } = responseList;
    switch (flag) {
      case 1:
        const { status, data: respBody } = response;
        if (status == HTTP_STATUS.HTTP_OK) {
          const { data: permissionSets = [] } = respBody;
          const mappedPermSets = permissionSets.filter(
            (permissionSet: SystemSetting) =>
              permissionSet?.value?.roles?.[0]?.roleId === roleData.id
          );
          if (mappedPermSets.length) {
            dispatch(setPermissionSet(mappedPermSets[0]));
            return mappedPermSets[0].value.name;
          } else {
            return '';
          }
        }
        break;
      case 2:
        const { status: respInstanceStatus, data: respInstanceBody } = response;
        if (respInstanceStatus == HTTP_STATUS.HTTP_OK) {
          const { data: permissionSets = [] } = respInstanceBody;
          const mappedPermSets = permissionSets.filter(
            (permissionSet: SystemSetting) => permissionSet?.value?.roles?.[0] === roleData.name
          );
          if (mappedPermSets.length) {
            dispatch(setPermissionSet(mappedPermSets[0]));
            return mappedPermSets[0].attributes.name;
          } else {
            return '';
          }
        }
        break;
    }
  };

  const enableEditRole = async () => {
    let permission = await RolePermission(AppConstants.PERMISSION_ROLE_PERMISSION_UPDATE);
    if (!permission) {
      permission = await RolePermission(AppConstants.PERMISSION_ROLE_PERMISSION_PATCH);
    }
    setEditRole(permission);
  };

  const setBreadCrumb = (mode: number, id?: string, name?: string) => {
    if (!mode) {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: `/roles/${id}`,
            label: `${t('T_BREADCRUMB_LABEL_FOR_ROLE_MGMT_EDIT_PAGE')} ${name}`
          }
        ])
      );
    } else {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: `/roles/new`,
            label: `${t('T_BREADCRUMB_LABEL_FOR_ROLE_MGMT_CREATE_PAGE')}`
          }
        ])
      );
    }
  };
  const formValidator = () => {
    if (
      (roleDetailsForm?.roleName.trim() &&
        mode &&
        roleDetailsForm?.permissionSetName?.attributes) ||
      (!mode && roleDetailsForm.roleName.trim())
    ) {
      return false;
    }
    return true;
  };
  const isValidFields = () => {
    const { roleName, roleDescription } = roleDetailsForm;
    if (!checkRoleFieldLength(roleName) || !checkRoleFieldLength(roleDescription)) {
      dispatch(
        setToastData({
          toastType: 'error',
          isToastActive: true,
          toastMessage: t('T_ROLE_FIELD_MAX_LIMIT_ERROR')
        })
      );

      setIsLoading(false);
      return false;
    }
    return true;
  };

  const saveRole = async (type: number) => {
    setIsLoading(true);
    if (!isValidFields()) {
      return;
    }

    //0 is for Create, 1 is for Edit
    let pwdMinLengthIndex = findIndex(passwordComplexity, {
      id: 'passwordComplexityCharacterNumbers'
    });

    let payload: DynamicObject = {
      name: roleDetailsForm.roleName.trim(),
      pwdMinLength: passwordComplexity[pwdMinLengthIndex].count,
      characterSets: getCharacterSets()
    };

    payload.pwdMaxAge = expirySchema;
    payload.pwdInHistory = passwordHistory;

    let response;
    if (!type) {
      payload.name = passwordPolicyName;
      response = await updatePasswordPolicy(editIds.passwordPolicyId, payload);
    } else {
      response = await createPasswordPolicy(payload);
    }
    if ([HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(response?.status)) {
      let rolePayload: any = {
        name: roleDetailsForm.roleName.trim(),
        mfa: mfaEnabled,
        locationHeuristics: locationHeuristics,
        description: roleDetailsForm.roleDescription,
        passwordPolicy: roleDetailsForm.roleName.trim()
      };

      let roleResponse;

      if (!type) {
        rolePayload.passwordPolicy = passwordPolicyName;
        rolePayload.apiPermissions = roleData?.permissions?.map((item: any) => item.apiPermission);
        roleResponse = await updateRole(editIds.roleId, rolePayload);
        const eventPayload: any = {
          userId: userId,
          organizationId: loggedInOrg?.organizationId,
          source: 'dm-admin-portal',
          type: 'APPLICATION_ROLE_UPDATED',
          time: new Date().toISOString().split('.')[0] + 'Z',
          data: {}
        };
        if (roleData && roleData.name !== roleDetailsForm.roleName.trim()) {
          eventPayload.data.rolename = roleDetailsForm.roleName.trim();
        }
        // if (Object.keys(eventPayload.data).length) {
        //   const eventResponse = await createEvent(eventPayload);
        // }
      } else {
        rolePayload.apiPermissions = rolePermissions?.map((item: any) => item.apiPermission);
        roleResponse = await createRole(rolePayload);
      }

      if ([HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(roleResponse.status)) {
        if (!type) {
          if (roleData && roleData.name !== roleDetailsForm.roleName.trim()) {
            let filteredSet: any = [];
            // if (permissionSetObject?.id) {
            //   filteredSet = await checkPermissionSetMetadataExists(
            //     permissionSetObject?.id,
            //     permissionSet?.value?.name
            //   );
            //   if (filteredSet.length) {
            //     const payload = {
            //       attributes: {
            //         ...filteredSet?.[0]?.attributes,
            //         roles: [roleDetailsForm.roleName.trim()]
            //       }
            //     };
            //     const response = await updateMetadataInstance(
            //       permissionSetObject?.id,
            //       filteredSet[0]?.id,
            //       payload
            //     );
            //   }
            // }
            if (permissionSetObject?.id) {
              const payload = {
                label: permissionSet?.label || '',
                attributes: {
                  ...permissionSet?.attributes,
                  roles: [roleDetailsForm.roleName.trim()]
                }
              };
              const response = await updateMetadataInstance(
                permissionSetObject?.id,
                permissionSet?.id,
                payload
              );
            }
          }
          setIsEdit(false);
          saveEditResponse(roleResponse.data);
          dispatch(
            setToastData({
              toastMessage: t('T_ROLE_UPDATE_SUCCESS_MSG'),
              isToastActive: true,
              toastType: 'success'
            })
          );
        } else {
          let updatedRolePayload = {
            ...rolePayload,
            id: roleResponse.data.id,
            permissions: roleResponse.data.permissions
          };
          // let appRole = await createApplicationRole(
          //   roleDetailsForm.permissionSetName,
          //   updatedRolePayload,
          //   categories.map((category: Category) => category.id as string)
          // );
          const updateSetting = await updateApplicationContextKey(
            roleResponse.data,
            AppConstants.CATEGORY_APPLICATION_SYSTEM,
            AppConstants.SOLUTION_APPLICATION_CONTEXT_KEY
          );

          // const updatePermSet = await updatePermissionSet(
          //   roleDetailsForm.permissionSetName,
          //   appRole as SystemSetting,
          //   roleResponse.data
          // );
          if (permissionSetObject?.id) {
            const updatedPermSet = await updatePermissionSetInstance(
              permissionSetObject.id,
              roleDetailsForm.permissionSetName,

              roleResponse.data
            );
          }

          setIsLoading(false);
          dispatch(
            setToastData({
              toastMessage: t('T_ROLE_CREATED_SUCCESS_MSG'),
              isToastActive: true,
              toastType: 'success'
            })
          );
          navigate(RouteConstants.ROUTE_ROLE_MANAGEMENT_LIST);
        }
      } else {
        setIsLoading(false);
        dispatch(
          setToastData({
            toastMessage: getFailureMessage(roleResponse),
            isToastActive: true,
            toastType: 'error'
          })
        );
      }
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const changeCheckbox = (index: number, value: boolean) => {
    let current = { ...passwordComplexity };
    current.checks[index].value = value;
    setPasswordComplexity(current);
  };

  const saveEditResponse = async (updatedData: any) => {
    let editData = cloneDeep(roleData);
    let tempValuesClone = { ...tempValues };
    if (!editData && !updatedData) {
      let response = await getRole(params.id);
      editData = response.data;
      editData.permissionSetName = await loadPermissionSet(response.data);
      setRoleData(editData);
    }
    if (updatedData) {
      editData = updatedData;
      setRoleData(updatedData);
    }

    let response = await getPasswordPolicy(editData.passwordPolicy, 0);

    if (response.status === HTTP_STATUS.HTTP_OK) {
      let responseData = response.data.data[0];

      let localPasswordComplexity = [...passwordComplexity];
      const { characterSets, pwdMinLength } = responseData;
      let charSetObj: any = {};
      characterSets?.forEach((element: any) => {
        let count = element.split(':')[0];
        let allowedChar = element.slice(element.indexOf(':') + 1);
        charSetObj[allowedChar] = count;
      });

      localPasswordComplexity?.forEach((element: any, index: number) => {
        if (element.id === 'passwordComplexityCharacterNumbers') {
          localPasswordComplexity[index].count = pwdMinLength;
        } else {
          if (element.allowedChar in charSetObj) {
            localPasswordComplexity[index].count = charSetObj[element.allowedChar];
          } else {
            localPasswordComplexity[index].count = 0;
          }
        }
      });

      setRoleDetailsForm({
        ...roleDetailsForm,
        roleName: editData.name,
        roleDescription: editData.description,
        permissionSetName: permissionSet?.attributes?.name || editData.permissionSetName
      });

      setExpirySchema(responseData.pwdMaxAge);
      tempValuesClone['passwordExpiry'].value = responseData.pwdMaxAge;
      setPasswordHistory(responseData.pwdInHistory);
      tempValuesClone['passwordHistory'].value = responseData.pwdInHistory;

      setTempValues(tempValuesClone);
      setMfaEnabled(editData.mfa);
      setPasswordPolicyName(editData.passwordPolicy);
      setLocationHeuristics(editData.locationHeuristics);
      setPasswordComplexity(localPasswordComplexity);
      setEditIds({ roleId: editData.id, passwordPolicyId: responseData.id });
      setBreadCrumb(0, editData.id, editData.name);
      setIsLoading(false);
    }
  };

  const getCharacterSets = () => {
    let arr: any = [];

    passwordComplexity.forEach((element: any) => {
      const { count, id } = element;
      if (count > 0 && id !== 'passwordComplexityCharacterNumbers') {
        arr.push(element.apiKey());
      }
    });
    return arr;
  };

  const cancelNavigation = () => {
    dispatch(setNewRoleData(''));
    navigate(RouteConstants.ROUTE_ROLE_MANAGEMENT_LIST);
  };

  const sumFunction = (key: string, type: number) => {
    let items;
    let current;
    if (key === 'passwordHistory') {
      items = cloneDeep(passwordHistory);
      current = items;
      items = !type ? current + 1 : current > 0 ? current - 1 : current;
      setPasswordHistory(items);
    } else if (key === 'passwordExpiry') {
      items = cloneDeep(expirySchema);
      current = items;
      items = !type ? current + 1 : current > 0 ? current - 1 : current;
      setExpirySchema(items);
    } else {
      let minimumValue = key === 'passwordComplexityCharacterNumbers' ? 6 : 0;
      items = [...passwordComplexity];
      let index = findIndex(items, {
        id: key
      });
      current = parseInt(items[index].count);
      items[index].count = !type ? current + 1 : current > minimumValue ? current - 1 : current;
      setPasswordComplexity(items);
    }
  };

  const manualCalc = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    let items: any = { ...tempValues };
    let parsedValue = e.target.value ? parseInt(e.target.value, 10) : 0;
    if (parsedValue >= 0) {
      items[key].value = parsedValue;
      items[key].focus = true;
      setTempValues(items);
    }
  };

  const checkTempValidation = (key: string) => {
    let minimumValue = key === 'passwordComplexityCharacterNumbers' ? 6 : 0;
    let tempValuesClone: any = { ...tempValues };

    let passwordComplexityIndex = findIndex(passwordComplexity, {
      id: key
    });

    if (tempValuesClone[key].value < minimumValue || tempValuesClone[key].value > 999) {
      tempValuesClone[key].value =
        key === 'passwordExpiry'
          ? expirySchema
          : key === 'passwordHistory'
          ? passwordHistory
          : passwordComplexity[passwordComplexityIndex].count;
      tempValuesClone[key].focus = false;
      setTempValues(tempValuesClone);
    } else {
      let items;
      if (key === 'passwordHistory') {
        items = cloneDeep(passwordHistory);
        items = tempValuesClone[key].value;
        tempValuesClone[key].focus = false;
        setPasswordHistory(items);
        setTempValues(tempValuesClone);
      } else if (key === 'passwordExpiry') {
        items = cloneDeep(expirySchema);
        items = tempValuesClone[key].value;
        tempValuesClone[key].focus = false;
        setExpirySchema(items);
        setTempValues(tempValuesClone);
      } else {
        items = [...passwordComplexity];
        items[passwordComplexityIndex].count = tempValuesClone[key].value;
        tempValuesClone[key].focus = false;
        setPasswordComplexity(items);
        setTempValues(tempValuesClone);
      }
    }
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <div
        className="flex h-fit w-full flex-col justify-start overflow-visible  bg-gray-100 px-2 py-3"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <div className="flex">
          <div className="flex w-5/6">
            <div className="p-2"></div>
            <div className="w-full">
              <Card
                title={t('T_ROLE_DETAIL_CARD_TITLE')}
                icon={UserRole}
                alt={t('T_CARD_ALT_MESSAGE')}
                height=""
                width=""
              >
                <MenuButtonsPortal>
                  <BiButton
                    className={
                      'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'
                    }
                    type="button"
                    onClick={cancelNavigation}
                  >
                    {!mode ? t('T_ROLE_DETAILS_BACK_TO_ROLE_BTN') : t('T_CANCEL')}
                  </BiButton>
                  <BiButton
                    className={'bg-primary py-1.5 text-white'}
                    type="button"
                    disabled={isEdit && formValidator() && editRole}
                    onClick={
                      !mode && !isEdit
                        ? () => {
                            setIsEdit(true);
                          }
                        : !mode && isEdit
                        ? () => saveRole(0)
                        : () => saveRole(1)
                    }
                  >
                    {!mode && !isEdit ? t('T_EDIT') : t('T_SAVE')}
                  </BiButton>
                </MenuButtonsPortal>

                <ToastNotification
                  message={toastData.toastMessage}
                  isActive={toastData.isToastActive}
                  setIsActive={() =>
                    dispatch(
                      setToastData({
                        toastMessage: '',
                        isToastActive: false,
                        toastType: null
                      })
                    )
                  }
                  status={toastData.toastType}
                />
                <div className="flex w-full flex-wrap">
                  <DynamicFormFieldRenderer
                    layout={3}
                    dynamicFormData={roleDetailsFormSchema(mode)}
                    formData={roleDetailsForm}
                    setFormData={setRoleDetailsForm}
                    formError={roleDetailsFormError}
                    editable={true}
                    asterixFirst={true}
                    customSelectData={permissionSetList}
                    disabled={!isEdit}
                  />
                </div>
              </Card>
              <div className="flex w-full flex-row justify-between">
                <div className="flex w-7/12 flex-col">
                  <Card
                    title={t('T_ROLE_DETAIL_SECURITY_CARD_TITLE')}
                    icon={LockIcon}
                    alt={t('T_CARD_ALT_MESSAGE')}
                    height=""
                    width=""
                    className="mb-0 rounded-none rounded-t-lg"
                  >
                    <div className="flex w-full flex-col">
                      <label
                        className="mb-2 flex items-center text-base font-semibold"
                        htmlFor="name"
                      >
                        {t('T_PASSWORD_COMPLEXITY')}
                      </label>

                      <div className="mb-0">
                        {passwordComplexity.map((item: any, index: number) => {
                          const { name, id, count, required } = item;
                          return (
                            <div
                              key={id}
                              className={`${
                                index + 1 !== passwordComplexity.length && 'mb-5'
                              } flex flex-row items-center`}
                            >
                              <CalcButton
                                type={0}
                                onClick={() => {
                                  sumFunction(id, 0);
                                }}
                                testId="passwordComplex-add"
                                disabled={!isEdit}
                              />
                              <div className="mx-2">
                                <input
                                  type="text"
                                  id="subject"
                                  data-testid="passwordComplex-value"
                                  className="h-10 w-12 rounded-md border border-solid border-gray-300 px-3"
                                  value={tempValues[id].focus ? tempValues[id].value : count}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    manualCalc(e, id)
                                  }
                                  disabled={!isEdit}
                                  onBlur={() => {
                                    checkTempValidation(id);
                                  }}
                                />
                              </div>

                              <CalcButton
                                type={1}
                                testId="passwordComplex-minus"
                                onClick={() => {
                                  sumFunction(id, 1);
                                }}
                                disabled={!isEdit}
                              />
                              <div className="ml-2 flex items-center text-base font-normal">
                                {required ? (
                                  <img className="mx-1 h-2 w-2" src={Asterix} alt="asterix" />
                                ) : (
                                  ''
                                )}
                                {t(name)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Card>

                  <SubCard className="bg-white">
                    <div className="flex w-2/3 flex-col">
                      <label
                        className="mb-2 flex items-center text-base font-semibold"
                        htmlFor="name"
                      >
                        {t('T_PASSWORD_EXPIRATION')}
                      </label>

                      <div className="flex flex-row items-baseline" key={`exp_0`}>
                        <div className="flex flex-row items-center text-base font-normal">
                          Expires in:
                          <CalcButton
                            type={0}
                            testId="add-password-expiry"
                            disabled={!isEdit}
                            className={'ml-2'}
                            onClick={() => {
                              sumFunction('passwordExpiry', 0);
                            }}
                          />
                          <div className="mx-2">
                            <input
                              type="text"
                              id="subject"
                              className="h-10 w-12 rounded-md border border-solid border-gray-300 px-3"
                              value={
                                tempValues.passwordExpiry.focus
                                  ? tempValues.passwordExpiry.value
                                  : expirySchema
                              }
                              data-testid="password-expiry-value"
                              disabled={!isEdit}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                manualCalc(e, 'passwordExpiry')
                              }
                              onBlur={() => {
                                checkTempValidation('passwordExpiry');
                              }}
                            />
                          </div>
                          <CalcButton
                            type={1}
                            className={'mr-2'}
                            testId="minus-password-expiry"
                            disabled={!isEdit}
                            onClick={() => {
                              sumFunction('passwordExpiry', 1);
                            }}
                          />
                          {t('T_DAYS')}
                        </div>
                      </div>
                    </div>
                  </SubCard>
                  <SubCard className="bg-white">
                    <div className="flex w-2/3 flex-col">
                      <label
                        className="mb-2 flex items-center text-base font-semibold"
                        htmlFor="name"
                      >
                        {t('T_PASSWORD_HISTORY')}
                      </label>

                      <div className="flex flex-row items-baseline" key={`password_history0`}>
                        <div className="flex flex-row items-center text-base font-normal">
                          Passwords to remember:
                          <CalcButton
                            type={0}
                            testId="password-remember-add"
                            disabled={!isEdit}
                            className={'ml-2'}
                            onClick={() => {
                              sumFunction('passwordHistory', 0);
                            }}
                          />
                          <div className="mx-2">
                            <input
                              type="text"
                              data-testid="password-remember-value"
                              id="subject"
                              className="h-10 w-12 rounded-md border border-solid border-gray-300 px-3"
                              value={
                                tempValues.passwordHistory.focus
                                  ? tempValues.passwordHistory.value
                                  : passwordHistory
                              }
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                manualCalc(e, 'passwordHistory')
                              }
                              disabled={!isEdit}
                              onBlur={() => {
                                checkTempValidation('passwordHistory');
                              }}
                            />
                          </div>
                          <CalcButton
                            type={1}
                            testId="password-remember-minus"
                            className={'mr-2'}
                            disabled={!isEdit}
                            onClick={() => {
                              sumFunction('passwordHistory', 1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </SubCard>
                </div>
                <div className="flex w-2/5 flex-col">
                  <Card
                    title={t('T_ROLE_DETAIL_SECURITY_OPTIONS_TITLE')}
                    icon={ShieldExclaimation}
                    alt={t('T_CARD_ALT_MESSAGE')}
                    height=""
                    width=""
                    className="mb-0 rounded-none rounded-t-lg"
                  >
                    <div className={`mb-5 flex flex-row items-center`}>
                      <Checkbox
                        height="20px"
                        width="20px"
                        testID="multi-factor-checkbox"
                        checked={mfaEnabled}
                        disabled={!isEdit}
                        onChange={() => {
                          setMfaEnabled(!mfaEnabled);
                        }}
                      />
                      <div className="ml-2 text-base font-normal">Multi-factor Authentication</div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoaderContainer>
  );
};

export default RoleDetail;
