import { HTTP_STATUS } from '../http/constants/http.status';

export const getFailureMessage = (response: any): string => {
  let msg = '';
  const { status, data, statusText = '' } = response;
  if (status >= HTTP_STATUS.HTTP_BAD_REQUEST) {
    msg =
      status === HTTP_STATUS.HTTP_NOT_FOUND
        ? statusText
        : data?.issues?.map((issue: any) => issue.details).join() || data || '';
  }
  return msg;
};
