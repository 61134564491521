import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import moment from 'moment';

import { find, compact, uniq, isEmpty } from 'lodash';

import { setMetaDataQuestionTypes } from '../../../app/appInitializeReducer';
import { modifyQuestionTypes } from '../../../util/appInitializeUtil';

import MenuButtonsPortal from '../../Menu/MenuButtonsPortal';
import ToastNotification from '../../Reusable/ToastNotification';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import TemplateTable from '../../shared/tables/TemplateTable';
import CreateQuestion from './QuetionTypeModal';

import { TableContainer } from '../../Organizations/OrganizationsList';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setURLPaths } from '../../../app/organizationReducer';
import { setToastData } from '../../../app/toastReducer';
import { AppConstants } from '../../../constants/AppConstants';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import { getQuestionTypeLabel, getDisplayType } from '../../../util/surveyUtils';
import { getUserName, getUserProfiles } from '../../../util/admin-utils';
import { ITableHeader } from '../../shared/tables/models/ITableHeader';
import { noDataAvailableMessage } from '../../Organizations/helpers';
import { useTranslation } from 'react-i18next';
import { getFailureMessage } from '../../../util/ErrorUtil';
import { getMetadataInstances } from '../../../http/metadata-service';
import { useTheme } from '../../../context/themeContext';
import { getUsers } from '../../../http/user-management-services';

import { EllipsisDiv } from '../../../styles/globalStyles';
import { getQuestionTypes } from './QueUtils';

/**
 * @parentId survey-questions
 * @manager Survey Question Listing
 * @overview
 *   <section>
 *       <p>
 *          Survey Question listing page is the default page the user is navigated on clicking <b>Survey Questions</b> navigation bar menuitem. Listing page will show all the survey question(s) in a paginated way.
 *           The Default pagination size be fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & survey question(s) data.
 *       </p>
 *       </br>
 *       <p>
 *           Admin user(s) can create new question by clicking on <b>New Question</b> button provided on the admin portal toolbar,
 *           which will navigate user to Survey Question creation page. <b>New Question</b> button will be shown based on logged in
 *           user role API permissions as described below.
 *       </p>
 *       </br>
 *       <p>
 *           Datagrid will display details of each survey question as row item with each column representing question property.
 *           <br>
 *           <ul>
 *               <li>Question Name</li>
 *               <li>Type</li>
 *               <li>Created by</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *       </p>
 *       <p>
 *           Sorting of survey question grid is supported on <i>Last Modified On</i>. The breadcrumb bar on the application toolbar will reflect location of the user in the admin portal. Clicking on suvrey question row item will drill down user to question details page which will be displayed in readonly mode.
 *       </p>
 *     </section>
 *     <section>
 *     <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the List Survey Question(s) Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *     <h4>Dependent System settings, Platform services & Role Permission</h3>
 *     <p>This section describes the list of dependent system settings & platform services required for functioning of metadata application listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *     <h5>Platform Service(s)</h4>
 *     <p>Table lists all the dependent platform service(s) with specific version(s) for Metadata application listing</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *           <tr>
 *             <td>User Management Service</td>
 *             <td>1.4.0</td>
 *         </tr>
 *       </table>
 *       <br>
 *     <h5>API Role Permission(s)</h4>
 *     <p>Table lists the required API role permissions for listing metadata applications page</p>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/applications</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-application.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *             <td>/applications/{id}/objects</td>
 *             <td>GET</td>
 *             <td>metadata-service.metadata-object.list</td>
 *             <td>Yes</td>
 *         </tr>
 *         <tr>
 *             <td>/objects/{id}/instances</td>
 *             <td>GET</td>
 *             <td>metadata-service.metadata-instance.list</td>
 *             <td>Yes</td>
 *         </tr>
 *           <tr>
 *             <td>/users</td>
 *             <td>GET</td>
 *             <td>user-management-service.user.list</td>
 *             <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *   <p>Sequence Diagram for metadata application listing page</p>
 *   </section>
 */

function QuestionList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const usersMap: Map<string, any> = new Map<string, any>();
  const toastData = useAppSelector((state) => state.toast);

  const [isLoading, setIsLoading] = useState(true);
  const [questionsList, setQuestionsList] = useState<any>([]);
  const [paging, setPaging] = useState<any>(null);
  const [size, setSize] = useState<number>(AppConstants.DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState<number>(AppConstants.DEFAULT_PAGE);
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const [selectOptionsIcons, setSelectOptionsIcons] = useState<any>([]);
  const [selectionObj, setSelectionObj] = useState<any>({});
  const [queList, setQueList] = useState([]);

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);

  let questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);

  const SURVEY_QUESTION_HEADERS: Array<ITableHeader> = [
    {
      key: 'name',
      description: 'T_QUESTION_NAME',
      sortEnabled: false,
      width: '35%',
      clickable: true,
      sortingInformation: {
        order: ''
      },
      render: (data: any) => {
        return <EllipsisDiv title={data?.name}>{data?.name}</EllipsisDiv>;
      }
    },
    {
      key: 'queType',
      description: 'T_TYPE',
      sortEnabled: false,
      width: '15%',
      clickable: true,
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'createdOn',
      description: 'T_CREATED_ON',
      sortEnabled: false,
      width: '25%',
      clickable: true,
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'updatedOn',
      description: 'T_LAST_MODIFIED_ON',
      sortEnabled: true,
      width: '25%',
      clickable: true,
      sortingInformation: {
        order: ''
      }
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'lastModifiedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: false,
    //   width: '25%',
    //   clickable: true,
    //   sortingInformation: {
    //     order: ''
    //   }
    // }
  ];
  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(SURVEY_QUESTION_HEADERS);

  useEffect(() => {
    const init = async () => {
      getSurveyQueTypeAndQueList();
      await createQuestionTypes();
      //getSurveyQuestionsList(currentPage, size);
      dispatch(setURLPaths([{ key: '/survey-question-list', label: `Survey Questions` }]));
    };
    init();
    return () => {
      // usersMap.clear();
    };
  }, []);

  const getSurveyQueTypeAndQueList = async () => {};

  const createQuestionTypes = async () => {
    let questionTypesObj: any;

    if (!isEmpty(questionTypes)) {
      questionTypesObj = questionTypes;
    } else {
      questionTypesObj = await getQuestionTypes(dispatch, metaDataObjectList);
    }
    questionTypes = questionTypesObj;
    setQueList(Object.values(questionTypesObj));
    getSurveyQuestionsList(currentPage, size);
  };

  const getSurveyQuestionsList = async (page: number, size: number, sort: string = AppConstants.DEFAULT_SORT_BY, sortType: string = AppConstants.DEFAULT_SORT_DESC) => {
    const questionsList: Array<any> = [];

    const questionListMetaDataObj: any = find(metaDataObjectList, { name: 'SurveyQuestions' });
    const { id } = questionListMetaDataObj;
    const questionListResponse = await getMetadataInstances(id, [], page, size, [sort, sortType]);
    const { status, data = {} } = questionListResponse;

    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: questionList = [], paging } = data;
      const users: Array<string> = questionList
        .filter((question: any) => !usersMap.get(question.createdBy))
        .map((question: any) => question.createdBy);

      if (users.length) {
        const userProfiles: Array<any> = await getUserProfiles(uniq(compact(users)));
        userProfiles.forEach((user: any) => {
          usersMap.set(user?.userId, user);
        });
      }

      if (questionList.length) {
        questionList.forEach(async (question: any, index: any) => {
          const { id, attributes, updatedOn, createdOn, createdBy, updatedBy } = question;
          const { active, type, answerOptions, apiType } = attributes;
          const displayType = getDisplayType(question);
          questionsList.push({
            ...attributes,
            id,
            queType: getQuestionTypeLabel(apiType, displayType, questionTypes, type),
            queStatus: active ? 'Active' : 'Inactive',
            updatedOn: moment(updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT),
            lastModifiedBy: updatedBy,
            createdOn: moment(createdOn).format(AppConstants.DEFAULT_DATE_FORMAT),
            createdBy: getUserName(usersMap.get(createdBy))
          });
        });
        setQuestionsList(questionsList);
        setPaging(paging);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        noDataAvailableMessage(setQuestionsList, setSize, setPaging, paging, t);
      }
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(questionListResponse),
          isToastActive: true,
          toastType: 'error'
        })
      );
      setIsLoading(false);
    }
  };

  const refreshTableData = (
    name: string = '',
    page: number,
    size: number,
    sort: string = '',
    sortType: string = ''
  ) => {
    if(!isEmpty(sort)){
      setTableValues(name, page, size, sort, sortType);
    }else{
      setTableValues(name, page, size);
    }
  };

  const setTableValues = (
    name: string = '',
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setSize(size);
    setCurrentPage(page);
    setIsLoading(true);
    getSurveyQuestionsList(page, size, sort, sortType);
  };

  const getQuestionType = (type: any, answerOptions: any) => {
    if (type === 'text' && answerOptions.length) {
      return 'single';
    }
    return type;
  };

  const questionSelector = (data: any) => {
    const { type, answerOptions, id } = data;
    roleAPIPermissions[AppConstants.PERMISSION_METADATA_INSTANCE_GET]
      ? navigate(`/survey-question/edit/${id}?type=${getQuestionType(type, answerOptions)}`)
      : dispatch(
          setToastData({
            toastMessage: 'User do not have permission to view Questions',
            isToastActive: true,
            toastType: 'error'
          })
        );
  };
  return (
    <LoaderContainer isLoading={isLoading}>
      <ToastNotification
        message={toastData.toastMessage}
        isActive={toastData.isToastActive}
        setIsActive={() => {
          dispatch(
            setToastData({
              toastMessage: '',
              isToastActive: false,
              toastType: null
            })
          );
        }}
      />
      <MenuButtonsPortal>
        <CreateQuestion
          selectOptions={selectOptions}
          selectOptionsIcons={selectOptionsIcons}
          selectionObj={selectionObj}
          queList={queList}
        />
      </MenuButtonsPortal>
      <TableContainer>
        <TemplateTable
          tableData={questionsList}
          onRefreshTableData={refreshTableData}
          size={size.toString()}
          currentPage={currentPage.toString()}
          templateSelector={questionSelector}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={paging}
          searchPlaceholder="Search question name"
          isSearchable={false}
          containerClass="mt-0"
          theme={useTheme().theme}
        />
      </TableContainer>
    </LoaderContainer>
  );
}

export default QuestionList;
