import { useEffect, useRef, useState } from 'react';
import { DropDownListContainer, DropDownHeader, DropDownList, ListItem } from './BiDropdownStyles';
import DropdownSvg from '../../../assets/dropdown.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useDidMountEffect } from '../../../util/customHooks';
type Props = {
  options: Array<string> | Array<{ key: string; description: string }>;
  onSelectedOptionChange: (option: any) => void;
  value: string;
  containerClassName?: string;
  menuPositionTop?: boolean;
};
const BiDropdown = ({
  options,
  onSelectedOptionChange,
  value,
  containerClassName,
  menuPositionTop
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('10');
  const selectRef = useRef<any>(null);

  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value);
    toggling();
    onSelectedOptionChange(value);
  };

  useOutsideClick(
    selectRef,
    () => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    false
  );

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <div
      className={
        'align-center relative mt-4 flex rounded border border-solid bg-white px-3 py-1 ' +
        containerClassName
      }
    >
      <DropDownHeader onClick={toggling}>
        {selectedOption || '10'}
        <img src={DropdownSvg} alt="dropdownIcon" />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer ref={selectRef}>
          <DropDownList
            className="max-h-96 overflow-y-auto overflow-x-hidden"
            isDropTop={menuPositionTop}
          >
            {options.map((currentOption) =>
              typeof currentOption == 'string' ? (
                <ListItem onClick={onOptionClicked(currentOption)} key={Math.random()}>
                  {currentOption}
                </ListItem>
              ) : (
                <ListItem onClick={onOptionClicked(currentOption.key)} key={Math.random()}>
                  {currentOption.description}
                </ListItem>
              )
            )}
          </DropDownList>
        </DropDownListContainer>
      )}
    </div>
  );
};

export default BiDropdown;
