import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Label, Input, ViewPasswordEye } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';
import { formatPhoneNumber } from '../../../util/functions';
import { formatNameField } from '../../../util/validators';
import { useTranslation } from 'react-i18next';
import { eye } from '../../../assets/components/svgs';
import InfoPopover from './InfoPopover';
import { SpinnerIconWrapper } from '../../primitives/inputs/BISearchAndClick';

interface NewOrganizationSampleData {
  name?: string;
  phone?: string;
  fax?: string;
  address?: string;
  city?: string;
  zip?: string;
  state?: string;
  country?: string;
  roleName?: string;
  createdBy?: string;
}

interface InputFieldProps {
  type: string;
  title?: string;
  id: string;
  key?: string;
  label: string;
  placeholder: any;
  fieldKey: string;
  required: boolean;
  formData: NewOrganizationSampleData;
  setFormData: Function;
  formError: NewOrganizationSampleData;
  asterixFirst?: boolean;
  disabled?: boolean;
  className?: string;
  handleChange?: Function | null;
  onBlurhandler?: Function | null;
  min?: number;
  max?: number;
  autoComplete?: string;
  errorClass?: string;
  disableLabel?: boolean;
  validateField?: boolean;
  onClick?: Function | null;
  ref?: any;
  style?: any;
  step?: number;
  outline?: boolean;
  allowSplChar?: boolean;
  overrideValue?: any;
  popoverContent?: React.ReactNode;
  prefixValue?: string;
  isLoading?: boolean;
  CustomIcon?: any;
}
const Image = styled.img`
  width: 8px;
  height: 8px;
`;

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

const ViewErrorContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: right;
  margin-left: auto;
  top: 28px;
  margin-right: 10px;
  right: 0;
`;
function InputField({
  type,
  id,
  label,
  placeholder,
  fieldKey,
  required,
  formData,
  setFormData,
  formError,
  asterixFirst,
  disabled,
  className,
  handleChange = null,
  onBlurhandler = null,
  min = 0,
  max = 0,
  autoComplete = '',
  errorClass = '',
  disableLabel = false,
  validateField = true,
  onClick,
  ref,
  style = {},
  outline = false,
  allowSplChar = false,
  title = '',
  overrideValue = '',
  popoverContent = null,
  prefixValue = '',
  isLoading = false,
  CustomIcon
}: InputFieldProps) {
  const { t } = useTranslation();
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  let inputProps: any = {};
  if (min) {
    inputProps.minLength = min;
  }
  if (max) {
    inputProps.maxLength = max;
  }
  if (autoComplete) {
    inputProps.autoComplete = autoComplete;
  }
  return (
    <>
      {!disableLabel ? (
        <Label htmlFor={id}>
          {asterixFirst && required ? <Image src={Asterix} className="mr-1" /> : ''}
          {t(label)}
          {required && !asterixFirst ? <Image src={Asterix} /> : ''}
          {popoverContent ? (
            <InfoPopover className="z-10 ml-2" content={popoverContent}></InfoPopover>
          ) : (
            ''
          )}
        </Label>
      ) : (
        ''
      )}

      <div className="flex w-full flex-row">
        {prefixValue ? (
          <Input
            title={title}
            className={className ? `${className} w-1/4` : 'w-1/4'}
            type="text"
            id={`${id}-prefix`}
            placeholder={t(placeholder) || ''}
            required={true}
            style={style || {}}
            outline={outline}
            value={prefixValue}
            disabled={true}
          />
        ) : (
          ''
        )}
        <Input
          title={title}
          className={
            className
              ? `${className} ${prefixValue && 'ml-2'} w-full`
              : `${prefixValue && 'ml-2'} w-full`
          }
          type={viewPassword ? 'text' : type}
          id={id}
          ref={ref}
          data-testId={`${t(label)}_input-field`}
          placeholder={t(placeholder) || ''}
          required={required}
          style={style || {}}
          outline={outline}
          value={
            overrideValue.length && overrideValue
              ? overrideValue
              : formData[fieldKey as keyof NewOrganizationSampleData]
              ? formData[fieldKey as keyof NewOrganizationSampleData]
              : ''
          }
          onBlur={(e) => {
            if (onBlurhandler) {
              onBlurhandler(e);
            }
          }}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
          onChange={(e) => {
            const ONLY_NUMBER_REGEX = /^[\(\+1\)]*[0-9\b]+$/;
            const COUNTRY_CODE_REGEX = /^(?=[^0]{2})\+[0-9]+$/;
            if (handleChange) {
              handleChange(e);
            } else if (fieldKey === 'phone' || fieldKey === 'fax') {
              if (
                e.target.value === '' ||
                ONLY_NUMBER_REGEX.test(e.target.value.replace(/[^\d]/g, ''))
              ) {
                const formattedNumber = formatPhoneNumber(e.target.value);
                setFormData({ ...formData, [fieldKey]: formattedNumber });
              }
            } else if (fieldKey === 'countryCode') {
              if (e.target.value === '+' || COUNTRY_CODE_REGEX.test(e.target.value)) {
                setFormData({ ...formData, [fieldKey]: e.target.value });
              }
            } else if (fieldKey === 'zip') {
              if (e.target.value === '' || ONLY_NUMBER_REGEX.test(e.target.value)) {
                setFormData({ ...formData, [fieldKey]: e.target.value.slice(0, 5) });
              }
            } else if (/name/gi.test(label)) {
              if (allowSplChar) {
                setFormData({ ...formData, [fieldKey]: e.target.value });
              } else {
                const formattedName = formatNameField(e.target.value, validateField);
                setFormData({ ...formData, [fieldKey]: formattedName });
              }
            } else {
              setFormData({ ...formData, [fieldKey]: e.target.value });
            }
          }}
          disabled={disabled} //(fieldKey === 'type' ? true : false) || disabled
          {...inputProps}
        />
        {isLoading ? (
          <SpinnerIconWrapper className="absolute right-3 mt-2.5 bg-white"></SpinnerIconWrapper>
        ) : (
          ''
        )}

        {CustomIcon && !isLoading ? (
          <CustomIcon
            className="absolute right-3 mt-2.5 h-6 w-6 cursor-pointer"
            onClick={onClick}
          ></CustomIcon>
        ) : (
          ''
        )}
      </div>

      {type === 'password' && (
        <ViewErrorContainer>
          <ViewPasswordEye
            onClick={() => {
              setViewPassword(!viewPassword);
            }}
          >
            <div className="m-auto w-fit">{eye}</div>
            <div className="-mt-[7px]	select-none">{viewPassword ? 'hide' : 'view'}</div>
          </ViewPasswordEye>
        </ViewErrorContainer>
      )}

      <ErrorLabel className={errorClass}>
        {formError[fieldKey as keyof NewOrganizationSampleData]
          ? formError[fieldKey as keyof NewOrganizationSampleData]
          : ''}
      </ErrorLabel>
    </>
  );
}

export default InputField;
