import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { hamMenuRight } from '../../assets/components/svgs';
import {
  Bottom,
  BreadcumbsContainer,
  DynamicContainer,
  Location,
  MenuButton,
  MenuButtonContainer,
  BreadCrumbs,
  DynamicButtonContainer
} from './MenuStyles';
import urlPaths from '../../components/constants/top-menu-constants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { resetTemplateRoute } from '../../app/templateReducer';
import { useTheme } from '../../context/themeContext';
import { RouteConstants } from '../../constants/RouteConstants';

type Props = {
  buttons?: JSX.Element;
  breadcrumbs?: JSX.Element;
  setSidebarIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarIsActive: boolean;
};

const MenuBottom = ({ buttons, breadcrumbs, setSidebarIsActive, sidebarIsActive }: Props) => {
  const location = useLocation().pathname;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (storedURLPaths.length) {
      const pathname: any = storedURLPaths.filter((path: any) => path.key === location);
      if (pathname.length) {
        if (pathname[0].label !== title) {
          setTitle(pathname[0].label);
        }
      }
    }
    if (!storedURLPaths.length && isLoading) {
      dispatch(setURLPaths(urlPaths));
      setIsLoading(false);
    }
  }, [storedURLPaths, location]);

  return (
    <Bottom>
      <MenuButtonContainer theme={useTheme().theme}>
        <MenuButton
          isActive={sidebarIsActive}
          onClick={() => setSidebarIsActive((state) => !state)}
        >
          {hamMenuRight}
          MENU
        </MenuButton>
      </MenuButtonContainer>
      <div className="flex h-full w-[97%] items-center border-b-[1.7px] pr-5">
        {breadcrumbs ? (
          <DynamicContainer>
            <BreadcumbsContainer>
              <Location role="withbread" theme={useTheme().theme}>
                {title}assaas
              </Location>
              <BreadCrumbs role="breadcrumbs">{breadcrumbs}</BreadCrumbs>
            </BreadcumbsContainer>
            <DynamicButtonContainer role="buttons">{buttons}</DynamicButtonContainer>
          </DynamicContainer>
        ) : (
          <Location
            id="header-location"
            className="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap"
            role="nobread"
            theme={useTheme().theme}
          >
            <Link
              id="header-title-link"
              to={
                location === '/create-template'
                  ? '/templates'
                  : location.split('/')[1] === 'organization'
                  ? RouteConstants.ROUTE_OS_ORGANIZATION_LISTING
                  : location.split('/')[1] === 'admin'
                  ? '/admin-list'
                  : location.split('/')[1] === 'roles'
                  ? '/roles'
                  : location.split('/')[1] === 'permission'
                  ? '/permissionset-list'
                  : location.split('/')[1] === 'templates'
                  ? '/templates'
                  : location.split('/')[1] === 'metadata'
                  ? RouteConstants.ROUTE_MDS_APPLN_LIST
                  : location.split('/')[1] === 'survey-question'
                  ? '/survey-question-list'
                  : location.split('/')[1] === 'survey-template'
                  ? '/survey-template/list'
                  : location.split('/')[1] === 'config-key'
                  ? '/config-key-list'
                  : location.split('/')[1] === 'mobile-configuration'
                  ? '/mobile-configuration/list'
                  : location.split('/')[1] === 'consents' && location.split('/')[2] === 'fieldgroup'
                  ? '/consents/fieldgroup'
                  : location.split('/')[1] === 'consents'
                  ? '/consents'
                  : location
              } //Explicit Handling of Create Template route and organization to return to templates and organization-list respectively.
              onClick={() => {
                if (location === '/templates' || location === '/create-template') {
                  dispatch(resetTemplateRoute());
                  navigate('/template');
                } else {
                  navigate(location);
                }
              }}
              className="hover:text-primary-600 transition duration-150 ease-in-out"
              data-te-toggle="tooltip"
              data-te-placement="bottom"
              title={title}
            >
              {title}
            </Link>
          </Location>
        )}
        <div id="header-bottom-right-container" className="flex w-1/2 flex-auto justify-end" />
      </div>
    </Bottom>
  );
};

export default MenuBottom;
