import React, { useEffect, useState } from 'react';
import Modal from '../../shared/ModalPortal/Modal';
import styled from 'styled-components';
import { AppConstants } from '../../../constants/AppConstants';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TemplateTable from '../../shared/tables/TemplateTable';
import { ITableHeader } from '../../shared/tables/models/ITableHeader';
import Checkbox from '../../shared/Fields/Checkbox';
import { ActionButtons } from '../../Reusable/ActionButton';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';

import { getQuestionTypeIcon, getQuestionTypeLabel } from '../../../util/surveyUtils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../context/themeContext';

import { EllipsisDiv } from '../../../styles/globalStyles';
import { isEmpty, filter } from 'lodash';
import { getQuestionTypes } from '../Question/QueUtils';

const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;
  min-height: 25vh;
  max-height: 85vh;
  overflow: auto;
`;

interface AddQuestionDialogProps {
  questions: any;
  setQuestions: Function;
  closePopup: Function;
  addedQuestions: any;
  getNewQuestions: Function;
  paging: any;
  setPaging: Function;
  size: number;
  setSize: Function;
  currentPage: number;
  setCurrentPage: Function;
  pagingLoading: boolean;
  setPagingLoading: Function;
  closeModal: Function;
}

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const AddQuestionDialog = ({
  questions,
  addedQuestions,
  setQuestions,
  closePopup,
  closeModal,
  getNewQuestions,
  paging,
  setPaging,
  size,
  setSize,
  currentPage,
  setCurrentPage,
  pagingLoading,
  setPagingLoading
}: AddQuestionDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tempQuestions, setTempQuestions] = useState<any>(addedQuestions);

  let questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);

  const setQuestionTypes = async () => {
    let questionTypesObj: any;

    if (!isEmpty(questionTypes)) {
      questionTypesObj = questionTypes;
    } else {
      questionTypesObj = await getQuestionTypes(dispatch, metaDataObjectList);
    }
    questionTypes = questionTypesObj;
  };

  useEffect(() => {
    const init = async () => {
      await setQuestionTypes();
    };
    init();
  }, []);

  const toggleCheckbox = (index: number, val: any) => {
    let copy = [...addedQuestions];
    let existIndex = addedQuestions.findIndex(
      (item: any) =>
        (item.text === val.question || item.question == val.question) &&
        (item.text === val.text || item.displayType === val.text)
    );
    if (existIndex === -1) {
      if (copy.length < 25) {
        copy.push(val);
        setQuestions(copy);
        setTempQuestions(copy);
      }
    } else {
      copy.splice(existIndex, 1);
      setQuestions(copy);
      setTempQuestions(copy);
    }
  };

  const getCount = () => {
    const filteredQue = filter(addedQuestions, (que: any) => {
      return que.type !== 'group';
    });
    return filteredQue.length;
  };

  const TABLE_HEADERS: Array<ITableHeader> = [
    {
      key: 'checked',
      description: '',
      sortEnabled: false,
      width: '5%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any, index: any) => {
        return (
          <>
            <Checkbox
              className="mx-auto"
              height="20px"
              width="20px"
              disabled={
                addedQuestions.length === 25 &&
                !addedQuestions.some((o: any) => {
                  return o.text === data.question || o.question == data.question;
                })
              }
              checked={addedQuestions.some((o: any) => {
                return o.text === data.question || o.question == data.question;
              })}
              onChange={() => toggleCheckbox(index, data)}
              testID="addQuestionCheckbox"
            />
          </>
        );
      }
    },
    {
      key: 'question',
      description: 'Question Name',
      sortEnabled: false, // TODO: Add Sort
      width: '75%',
      sortingInformation: {
        order: ''
      },
      render: (data: any) => {
        return <EllipsisDiv title={data?.question}>{data?.question}</EllipsisDiv>;
      },
      nastedData: false
    },
    {
      key: 'type',
      description: 'Question Type',
      sortEnabled: false, // TODO: Add Sort
      width: '20%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,

      render: (data: any, index: any) => {
        return (
          <div className="flex flex-row">
            <div className="mr-2 self-center">
              {getQuestionTypeIcon(data?.apiType, data?.displayType, questionTypes, data?.type)}
            </div>
            <div className="text-sm font-normal">
              {getQuestionTypeLabel(data?.apiType, data?.displayType, questionTypes, data?.type)}
            </div>
          </div>
        );
      }
    }
  ];

  const invokAPI = async (name: string, page: number, size: number) => {
    getNewQuestions(name, page, size);
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc'
  ) => {
    setTableValues(name, page, size, sort, sortType);
  };

  const setTableValues = (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc'
  ) => {
    setSize(size);
    setCurrentPage(page);
    setPagingLoading(true);
    invokAPI(name, page, size);
  };

  return (
    <>
      <LoaderContainer isLoading={pagingLoading}>
        <Modal
          showHeader={true}
          showCloseIcon={true}
          width="75rem"
          headerText={t('T_SURVEY_SELECT_QUESTIONS')}
          toggleVisiblity={(value: boolean) => {
            refreshTableData('', AppConstants.DEFAULT_PAGE, AppConstants.DEFAULT_PAGE_SIZE);
            closePopup(value);
          }}
          isVisible={true}
          parentOverflowProperty="overflow-hidden"
          body={
            <>
              <Container>
                {/* <div className="flex w-full flex-row justify-between">
                <div>
                  <label className="flex items-center text-sm font-normal" htmlFor="name">
                    Select up to 30 questions to add to this survey
                  </label>
                </div>
                <div></div>
              </div> */}
                <CustomersTableContainer>
                  <TemplateTable
                    tableData={questions}
                    size={size.toString()}
                    currentPage={currentPage.toString()}
                    templateSelector={() => {}}
                    onRefreshTableData={refreshTableData}
                    tableHeaders={TABLE_HEADERS}
                    setTableHeaders={null}
                    searchPlaceholder={t('T_SURVEY_SEARCH_FOR_QUESTION')}
                    containerClass="mt-0"
                    isSearchable={false}
                    tableName={t('T_SURVEY_SELECT_QUESTIONS')}
                    pagingData={paging}
                    theme={useTheme().theme}
                  />
                </CustomersTableContainer>
              </Container>
              <ActionButtons
                primaryButtonLabel={`${t('T_SURVEY_ADD_SELECTED')} (${getCount()})`}
                primaryButtonDisabled={getCount() === 0}
                primaryButtonAction={() => {
                  setQuestions(tempQuestions);
                  //refreshTableData('', AppConstants.DEFAULT_PAGE, AppConstants.DEFAULT_PAGE_SIZE);
                  closeModal();
                }}
                secondaryButtonLabel={t('T_CANCEL')}
                secondaryButtonAction={() => {
                  //refreshTableData('', AppConstants.DEFAULT_PAGE, AppConstants.DEFAULT_PAGE_SIZE);
                  closePopup(false);
                }}
                primaryButtonWidth="full"
                primaryButtonClass={'px-4 mr-4'}
              />
            </>
          }
        />
      </LoaderContainer>
    </>
  );
};

export default AddQuestionDialog;
