import { find } from 'lodash';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import { AppConstants } from '../../../constants/AppConstants';
import { getGlobalBucketName } from '../../../util/AppSetting';

import { setMetaDataQuestionTypes } from '../../../app/appInitializeReducer';
import { modifyQuestionTypes } from '../../../util/appInitializeUtil';
import { getMetadataInstances } from '../../../http/metadata-service';
import { createSignedURL, listFiles, uploadFile } from '../../../http/asset-management-services';
import {
  date,
  time,
  datetime,
  text,
  likert,
  multi,
  url,
  media,
  single,
  display,
  boolean,
  integer,
  decimal,
  plusSign,
  bodymap
} from '../../../assets/components/svgs';

export const getQuestionTypeInstances = async (id: string, dispatch: any) => {
  const response = await getMetadataInstances(
    id,
    [],
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );
  const { status, data: instanceResponse = {} } = response;
  const { data: instances = [] } = instanceResponse;
  if (status === HTTP_STATUS.HTTP_OK) {
    dispatch(setMetaDataQuestionTypes(modifyQuestionTypes(instances)));
  }
  return modifyQuestionTypes(instances);
};

export const getQuestionTypes = async (dispatch: any, metaDataObjectList: any) => {
  const questionTypeMetaData: any = find(metaDataObjectList, {
    name: AppConstants.OBJECTS_SURVEY_QUESTION_TYPES
  });

  if (questionTypeMetaData) {
    const { id: questionTypeMetaDataId } = questionTypeMetaData || {};
    if (questionTypeMetaDataId) {
      return await getQuestionTypeInstances(questionTypeMetaDataId, dispatch);
    }
  }
};

export const getImagesToUpload = (formData: any) => {
  const uploadMediaTypes = [
    'likertIconsWithLabels',
    'likertIcons',
    'media',
    'bodymap',
    'bodyMapSingle',
    'bodyMapMulti'
  ];
  const imagesArr: any = [];
  if (uploadMediaTypes.includes(formData.type) || uploadMediaTypes.includes(formData.displayType)) {
    if (formData.fileUploadObj) {
      Object.values(formData.fileUploadObj).forEach((fileObj: any) => {
        imagesArr.push(fileObj);
      });
    }
  }
  return imagesArr;
};

export const downLoadImages = async (imagesArr: any, formData: any) => {
  let createSignedURLArr: any = [];
  let downloadFileArr: any = {};

  const { bucketName, path } = formData.extension;

  Object.values(imagesArr).forEach((fileName: any) => {
    const payload = {
      filePath: `${path}${fileName}`,
      fileName,
      bucketName,
      versioning: true,
      expirationTime: 1440,
      action: AppConstants.GCS_UPLOAD_DOWNLOAD,
      publicObject: true
    };
    createSignedURLArr.push(createSignedURL(payload));
  });

  if (createSignedURLArr.length) {
    await Promise.all(createSignedURLArr).then((signedDataArr: any) => {
      signedDataArr.forEach((signedData: any, index: any) => {
        if (signedData.status === HTTP_STATUS.HTTP_CREATED) {
          let { url, headers = {} } = signedData.data;
          let { data: fileData } = signedData?.config;
          downloadFileArr[index] = { url: url, fileName: JSON.parse(fileData).fileName };
        }
      });
    });
  }
  return downloadFileArr;
};

export const updateMediaToBucket = async (
  imagesToUpload: any,
  errorHandling: any,
  formData: any,
  t: any
) => {
  let createSignedURLArr: any = [];
  let createSignedUrlData: any = [];
  let uploadFileArr: any = [];
  let uplodedFileObjArray: any = [];

  const uploadMediaTypes = [
    'likertIconsWithLabels',
    'likertIcons',
    'media',
    'bodymap',
    'bodyMapSingle',
    'bodyMapMulti'
  ];
  if (uploadMediaTypes.includes(formData.type) || uploadMediaTypes.includes(formData.displayType)) {
    imagesToUpload.forEach((uploadFileObj: any) => {
      const { file, fileName } = uploadFileObj;
      if (file) {
        uplodedFileObjArray.push(uploadFileObj);
        const [fileObj] = file;
        if (fileObj.name) {
          //const {surveyName} = formData;
          // const path = formData.extension.object
          //   ? `${JSON.parse(formData.extension.object).filePath}`
          //   : `${formData.extension.filePath}`;
          // const surveyPath = ${surveyDetailsForm.surveyName.replace(
          //   /\s+/g,
          //   '_'
          // )}_${timeStamp}/`;

          const path = formData.extension.object
            ? `${JSON.parse(formData.extension.object).path}${fileName}`
            : `${formData.extension.path}${fileName}`;
          const isPublicObject = formData.extension.object
            ? JSON.parse(formData.extension.object).permission === 'public'
            : formData.extension.permission === 'public';
          const payload = {
            filePath: path,
            bucketName: getGlobalBucketName(),
            versioning: true,
            action: AppConstants.GCS_UPLOAD_ACTION,
            publicObject: isPublicObject,
            expirationTime: AppConstants.GCS_MAX_EXPIRATION_TIME,
            metadata: {
              fileName: fileObj.name,
              type: fileObj.type,
              size: fileObj.size,
              [AppConstants.HEADER_CACHE_CONTROL]: AppConstants.VALUE_NO_CACHE,
              [AppConstants.HEADER_MAX_AGE]: AppConstants.VALUE_MAX_AGE_MINIMUM,
              [AppConstants.CONTENT_DISPOSITION]: `attachment; filename=${fileName}`
            }
          };

          createSignedURLArr.push(createSignedURL(payload));
        }
      }
    });

    if (createSignedURLArr.length) {
      await Promise.all(createSignedURLArr).then((signedDataArr: any) => {
        signedDataArr.forEach((signedData: any, index: any) => {
          if (signedData.status === HTTP_STATUS.HTTP_CREATED) {
            let { url, headers = {} } = signedData.data;
            if (uplodedFileObjArray[index].file) {
              const [fileObj] = uplodedFileObjArray[index].file;
              const uploadPayload = {
                url,
                body: fileObj,
                headers: { ...headers, [AppConstants.HEADER_CONTENT_TYPE]: fileObj.type }
              };

              uploadFileArr.push(
                uploadFile(
                  uploadPayload,
                  () => {},
                  (err: string) => {
                    errorHandling(t('T_NOT_UPLOADED'));
                  }
                )
              );
            }
          } else {
            errorHandling(t('T_NOT_UPLOADED'));
          }
        });
      });
      if (uploadFileArr.length) {
        await Promise.all(uploadFileArr);
      }
    }
  }
};

export const SELECT_OPTIONS_ICON: any = {
  date: date,
  time: time,
  datetime,
  text,
  likert,
  multi,
  url,
  media: media,
  single,
  display,
  boolean,
  integer,
  decimal,
  plusSign,
  bodymap
};

export const setQueTypeIcons = (queList: any) => {
  if (queList && queList.length) {
    const sortedQueList = queList.sort((a: any, b: any) => a.type.localeCompare(b.type));
    return sortedQueList.map((queItem: any) => {
      return { ...queItem, icon: SELECT_OPTIONS_ICON[queItem.type], key: queItem.type };
    });
  }
};

export const getFolderpathWithTimeStamp = (formData: any, timeStamp: any) => {
  return `${AppConstants.SURVEY_MEDIA_PATH}${formData.type}/${formData.questionName.replace(
    /\s+/g,
    '_'
  )}_${timeStamp}/`;
};

export const getFilePathWithTimeStamp = (formData: any, mediaVal: any, timeStamp: any) => {
  const { fileName } = mediaVal;
  if (fileName) {
    const lastIndex = fileName.lastIndexOf('.');
    const fileWithTimeStamp = `${fileName.substr(0, lastIndex)}_${timeStamp}${fileName.substr(
      lastIndex
    )}`;
    return `${AppConstants.SURVEY_MEDIA_PATH}${formData.type}/${fileWithTimeStamp}`;
  }
};

export const getFileNameWithTimeStamp = (fileName: any, timeStamp: any) => {
  if (fileName) {
    const selectedFileName = fileName.replace(/\s+/g, '_');
    const lastIndex = selectedFileName.lastIndexOf('.');
    const fileWithTimeStamp = `${selectedFileName.substr(
      0,
      lastIndex
    )}_${timeStamp}${selectedFileName.substr(lastIndex)}`;
    return fileWithTimeStamp;
  }
};

export const getFileNameWithoutTimeStamp = (fileName: any) => {
  if (fileName) {
    const selectedFileName = fileName.replace(/\s+/g, '_');
    const lastIndex = selectedFileName.lastIndexOf('.');
    const timeStampIndex = selectedFileName.lastIndexOf('_');
    const fileWithoutTimeStamp = `${selectedFileName.substr(
      0,
      timeStampIndex
    )}${selectedFileName.substr(lastIndex)}`;
    return fileWithoutTimeStamp;
  }
};
//<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 470">
//<svg xmlns="http://www.w3.org/2000/svg" width="180" height="471" fill="none">
export const SVGMap = (props: any, updatePathSelection: any = '', svgAttributes: any) => {
  return (
    <svg
      width={svgAttributes['width'] ? svgAttributes['width'] : '400'}
      height={svgAttributes['height'] ? svgAttributes['height'] : '400'}
      viewBox={svgAttributes['viewBox'] ? svgAttributes['viewBox'] : '0 0 400 400'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {props.map((location: any) => {
        const {
          path,
          id,
          label,
          isChecked,
          selectionColor,
          defaultColor,
          stroke,
          strokelinecap,
          strokelinejoin,
          isSelectable,
          fillRule,
          clipRule
        } = location;
        return (
          <path
            d={path}
            id={id}
            name={label || ''}
            fill={isChecked || label !== '' ? selectionColor : defaultColor}
            stroke={stroke}
            stroke-linecap={strokelinecap}
            stroke-linejoin={strokelinejoin}
            data-isSelectable={isSelectable}
            data-defaultColor={defaultColor}
            fill-rule={fillRule}
            clip-rule={clipRule}
            data-selectionColor={selectionColor}
            onClick={
              updatePathSelection && isSelectable
                ? (e: any) => {
                    updatePathSelection(e);
                  }
                : () => {}
            }
          />
        );
      })}
    </svg>
  );
};

export const updatebodyMapSvgTags = (
  pathTags: any,
  isRenderedFromFile: any,
  fileName: any = ''
) => {
  const updatedPathTags = [];

  for (let i = 0; i < pathTags.length; i++) {
    updatedPathTags.push({
      path: isRenderedFromFile ? pathTags[i].getAttribute('d') : pathTags[i].path,
      id: isRenderedFromFile ? `${fileName}_${i}` : pathTags[i].id,
      label: isRenderedFromFile ? pathTags[i].getAttribute('name') || '' : pathTags[i].label,
      fill: isRenderedFromFile
        ? pathTags[i].getAttribute('data-defaultColor')
        : pathTags[i].defaultColor,
      fillRule: isRenderedFromFile ? pathTags[i].getAttribute('fill-rule') : pathTags[i].fillRule,
      clipRule: isRenderedFromFile ? pathTags[i].getAttribute('clip-rule') : pathTags[i].clipRule,
      stroke: isRenderedFromFile ? pathTags[i].getAttribute('stroke') : pathTags[i].stroke,
      strokelinecap: isRenderedFromFile
        ? pathTags[i].getAttribute('stroke-linecap')
        : pathTags[i].strokelinecap,
      strokelinejoin: isRenderedFromFile
        ? pathTags[i].getAttribute('stroke-linejoin')
        : pathTags[i].strokelinejoin,
      isChecked: isRenderedFromFile ? false : pathTags[i].isChecked,
      defaultColor: isRenderedFromFile
        ? pathTags[i].getAttribute('data-defaultColor')
        : pathTags[i].defaultColor,
      selectionColor: isRenderedFromFile
        ? pathTags[i].getAttribute('data-selectionColor')
        : pathTags[i].selectionColor,
      isSelectable: isRenderedFromFile
        ? pathTags[i].getAttribute('data-isSelectable')
        : pathTags[i].isSelectable
    });
  }
  return updatedPathTags;
};

export const getSVGAttributes = (svgTag: any) => {
  const svgAttributes: any = {};
  svgAttributes['width'] = svgTag[0].getAttribute('width');
  svgAttributes['height'] = svgTag[0].getAttribute('height');
  svgAttributes['viewBox'] = svgTag[0].getAttribute('viewBox');

  return svgAttributes;
};

export const extractBodyMapSvg = (file: File): Promise<any> =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const svgData: any = reader.result;
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgData, 'image/svg+xml');
      const svgTag = doc.getElementsByTagName('svg');
      const svgAttributes = getSVGAttributes(svgTag);
      const pathTags = doc.getElementsByTagName('path');
      const generatedSvg = updatebodyMapSvgTags(pathTags, true, file.name);
      resolve({ fileData: reader.result, svgPathTags: pathTags, generatedSvg, svgAttributes });
    };
    reader.onerror = (error) => reject(error);
  });
