import styled from 'styled-components';

export type Props = {
  justifyContent: string;
};

export type WidthProp = {
  width: string;
  hover?: boolean;
  notCursor?: boolean;
};

export type RowHoverProp = {
  hover?: boolean;
};

export const Row = styled.div<Props>`
  display: flex;
  // justify-content: ${({ justifyContent }) => justifyContent}
`;

export type GroupProps = {
  width: string;
  marginBottom?: string;
};

export const Group = styled.section<GroupProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  margin-bottom: ${({marginBottom}) => (marginBottom ? marginBottom : '15px')};
`;

export const TableHeading = styled.th<WidthProp>`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px 16px;
  width: ${(props) => props.width};
  height: 54px;
  border: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #007179;
  box-shadow: inset 0px -1px 0px #007179;
  color: #262626;
  font-weight: 600;
  font-family: Open Sans;
  font-size: 14px;
  background: #f5f5f5;
`;

export const TableData = styled.td<WidthProp>`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 14px 16px;
  width: ${(props) => props.width};
  border-bottom: 1px solid #d9d9d9;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : '#262626')};
  font-weight: 400;
  cursor: ${(props) => (props.notCursor ? '' : 'pointer')};
  

  // ${(props) =>
    props.hover
      ? `:hover {
  //   background-color: #d7eafc;
  //   cursor: pointer;
  // }`
      : 'cursor: none '}
`;

export const TableDataDiv = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

export const TableRow = styled.tr<RowHoverProp>`
  display: flex;
  :nth-child(odd) {
    background-color: #ffffff;
  }
  :nth-child(even) {
    background-color: #fafafa;
  }
  :hover {
    td {
      font-weight: bold;
    }
  }
  // ${(props) =>
    props.hover
      ? `:hover {
//   background-color: #d7eafc;
//   cursor: pointer;
// }`
      : 'pointer-events: none'}
`;

export const SorterIcon = styled.img`
  cursor: pointer;
  margin-left: 10px;
`;

export const TableHead = styled.thead`
  text-align: left;
  // padding: 8px;
`;
export const TableBody = styled.tbody``;

export const Table = styled.table`
    height: 100%
`;

export const TableFooter = styled.tfoot``;

export const NoDataTableRow = styled(TableRow)`
  height: 100%;
`;

export const NoDataTableCell = styled(TableData)`
  align-items: center;
  justify-content: center;
`;
