import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Clipboard } from '../../assets/components/svgs';
import Card from '../shared/cards/Card';
import InputField from '../shared/Fields/InputField';
import { setFormData } from '../../app/carePlanReducer';
import { AppConstants } from '../../constants/AppConstants';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import TextAreaField from '../shared/Fields/TextAreaField';
import { getEncounterListItems } from './carePlanUtils';

interface FormProps {
  isEdit: boolean;
}

const CarePlanDetails = ({ isEdit }: FormProps) => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const encounterListData: any = useAppSelector((state) => state.carePlanData.encounterList);

  const formData = useAppSelector((state) => state.carePlanData.formData);
  const [formError, setFormError] = useState<any>({});

  const updateFormData = (value: any) => {
    dispatch(setFormData(value));
  };

  return (
    <div className="h-[75vh] overflow-hidden p-4">
      <Card
        title={t('T_CARE_PLAN_DETAILS')}
        icon={Clipboard}
        alt={t('T_CARD_ALT_MESSAGE')}
        height=""
        width=""
      >
        <div className="flex w-full flex-wrap">
          <div className="mb-5 flex w-full">
            <div className="flex w-1/2 flex-col">
              <InputField
                type={'text'}
                id={'careplanTitle'}
                label={t('T_TITLE')}
                placeholder={''}
                fieldKey={'title'}
                asterixFirst={true}
                required={true}
                formData={formData}
                setFormData={updateFormData}
                formError={formError}
                disabled={!isEdit}
                max={AppConstants.MAX_LENGTH_IMPORT_NAME}
              />
            </div>
            <div className="w-1/2 pl-5">
              <InputField
                type={'text'}
                id={'intent'}
                label={t('T_CARE_PLAN_INTENT')}
                placeholder={''}
                fieldKey={'intent'}
                asterixFirst={true}
                required={false}
                formData={formData}
                setFormData={updateFormData}
                formError={formError}
                disabled={!isEdit}
                max={AppConstants.MAX_LENGTH_IMPORT_NAME}
              />
            </div>
          </div>

          <div className="mb-5 flex w-full flex-col">
            <TextAreaField
              fieldKey={'description'}
              type={'text'}
              id={'description'}
              label={t('T_DESCRIPTION')}
              placeholder={''}
              required
              formData={formData}
              setFormData={updateFormData}
              formError={formError}
              asterixFirst={false}
              rows={3}
              disabled={!isEdit}
            ></TextAreaField>
          </div>
          <div className="flex w-full flex-wrap">
            <div className="mb-5 flex w-full">
              <div className="flex w-1/2 flex-col">
                <InputField
                  type={'text'}
                  id={'careplanCategory'}
                  label={t('T_CARE_PLAN_CATEGORY')}
                  placeholder={''}
                  fieldKey={'category'}
                  asterixFirst={true}
                  required={false}
                  formData={formData}
                  setFormData={updateFormData}
                  formError={formError}
                  disabled={!isEdit}
                  max={AppConstants.MAX_LENGTH_IMPORT_NAME}
                />
              </div>
              <div className="w-1/2 pl-5">
                <PickList
                  fieldKey={'key'}
                  fieldValues={['value']}
                  label={t('T_CARE_PLAN_ENCOUNTER')}
                  selectedItems={formData.encounter}
                  showAddOption={false}
                  onAddItem={() => {}}
                  onSelectedChange={(item: any) => {
                    updateFormData({ encounter: item });
                  }}
                  items={getEncounterListItems(encounterListData)}
                  disabled={!isEdit}
                  readOnly={false}
                  type={PICKLIST_TYPES.SINGLE}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CarePlanDetails;
