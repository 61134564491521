import React, { useEffect } from 'react';
import { AppConstants } from '../../constants/AppConstants';
import { LogoContainer, Top } from './MenuStyles';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { SystemSetting } from '../../util/RoleUtil';
import { getOrganizationSettings } from '../../http/configuration-services';
import { getLoggedInOrg } from '../../util/admin-utils';
import { setOrganizationSetting } from '../../app/organizationReducer';
import { useTranslation } from 'react-i18next';

type Props = {
  children?: React.ReactNode;
};

const MenuLogo = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const organizationSetting: SystemSetting = useAppSelector(
    (state) => state.organization.organizationSetting
  );

  useEffect(() => {
    async function init() {
      if (!Object.keys(organizationSetting).length) {
        let orgData = getLoggedInOrg();
        if (orgData) {
          let response = await getOrganizationSettings(
            orgData.organizationId,
            AppConstants.CATEGORY_ORGANIZATION_SETTINGS,
            AppConstants.DEFAULT_PAGE,
            AppConstants.MAXIMUM_PAGE_SIZE
          );

          if (response?.data?.data?.length) {
            dispatch(setOrganizationSetting(response?.data?.data[0]));
          }
        }
      }
    }
    init();
  }, [organizationSetting]);

  return (
    <Top>
      <LogoContainer>
        {/* <h1>Roche</h1>
        <h2>Admin Portal</h2> organizationSetting?.value?.customizations?.logoURL || */}
        <img
          src={
            organizationSetting?.value?.customizations?.logoURL || AppConstants.LOGO_SOLUTION_URL
          }
          alt={t('T_ADMIN_PORTAL') || ''}
          height={40}
          width={215}
        />
      </LogoContainer>
      {children}
    </Top>
  );
};

export default MenuLogo;
