// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useAppDispatch } from '../../app/hooks';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { SystemSetting } from '../../util/RoleUtil';
import Asterix from '../../assets/asterix.svg';
//import { asterisk } from '../../assets/components/svgs';

import {
  formatPayloadForAPICall,
  getSolutionSettingsJSONString,
  getSolutionSettingObject
} from '../../util/AppSetting';
import {
  createMultipleSystemSetting,
  updateMultipleSystemSetting,
  updateSystemSetting
} from '../../http/configuration-services';
import { setToastData } from '../../app/toastReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { AppConstants } from '../../constants/AppConstants';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';

interface JSONEditorProps {
  setIsLoading: Function;
  jsonString?: string;
  setJsonString?: any;
  isComponent?: boolean;
  disabled?: boolean;
  DMSsetting?: boolean;
  showButtons?: boolean;
  showLineNumber?: boolean;
  showFormatJSONButton?: boolean;
  showImportJSONButton?: boolean;
  formatJSONButtonLabel?: string;
  JSONEditorLabel?: string;
  required?: boolean;
}

interface SolutionSettingsStateProps {
  id: string;
  key: string;
}

const buttonStyle =
  'mr-2 mt-2 h-[35px] border border-primary text-[14px] leading-4 text-primary text-white bg-primary';

const ImportJSONLabel = styled.label`
  cursor: pointer;
`;

const ImportInput = styled.input`
  display: none;
`;

const JSONEditor: React.FC<JSONEditorProps> = ({
  setIsLoading,
  jsonString = '',
  disabled = false,
  setJsonString = () => {},
  isComponent = false,
  DMSsetting = false,
  showButtons = true,
  showLineNumber = false,
  showFormatJSONButton = true,
  showImportJSONButton = true,
  formatJSONButtonLabel,
  JSONEditorLabel = '',
  required = false
}) => {
  const inputRef = useRef<any>({});
  const dispatch = useAppDispatch();

  const [error, setError] = useState('');
  const [readOnly, setReadOnly] = useState(disabled);
  const [json, setJSON] = useState<string>(jsonString);
  const [jsonCopy, setJSONCopy] = useState('');
  const lineCounterRef = useRef<any>(null);
  const textareaRef = useRef<any>(null);

  const solSettingObj: SystemSetting = getSolutionSettingObject();

  const { t } = useTranslation();

  let fileReader: any = new FileReader();
  fileReader.onload = (event: any) => {
    setJSON(prettyPrintJSON(event.target.result));
  };

  useEffect(() => {
    if (DMSsetting) {
      setIsLoading(true);
      getSolutionSettingsJSONString((data: string) => {
        setJSON(prettyPrintJSON(data));
        setJSONCopy(prettyPrintJSON(data));
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!DMSsetting) {
      setJSON(prettyPrintJSON(jsonString));
    }
  }, [jsonString]);

  useEffect(() => {
    setReadOnly(disabled);
  }, [disabled]);

  const prettyPrintJSON = (value: string) => {
    if (!value || !value.trim()) {
      setError('');
      setJsonString('');
      return value;
    }
    try {
      const prettyJson = JSON.stringify(JSON.parse(value), undefined, 4);
      setError('');
      setJsonString(prettyJson);
      return prettyJson;
    } catch (e: any) {
      console.error(e.message);
      setJsonString(value);
      setError(e.message);
      return value;
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;
    // fileChange(fileObj);
    fileReader.readAsText(fileObj);
  };

  // ****  commenting this for now incase if we need this in future or else we can remove this. ****
  // const fileChange = (file: File) => {
  //   let urlFile = URL.createObjectURL(file);
  //   fetch(urlFile)
  //     .then((response) => response.text())
  //     .then((data) => {
  //       setJSON(prettyPrintJSON(data));
  //     });
  // };

  const sortUpdateAndCreateSettingAsPerIds = () => {
    let createSetting: SystemSetting[] = [];
    let updateSetting: SystemSetting[] = [];
    const settingJson = JSON.parse(json);

    settingJson.forEach((setting: any) => {
      if (setting.id) {
        updateSetting.push(formatPayloadForAPICall(setting));
      } else {
        createSetting.push(formatPayloadForAPICall(setting));
      }
    });
    return { create: createSetting, update: updateSetting };
  };

  const handleSave = async () => {
    const data = sortUpdateAndCreateSettingAsPerIds();
    let APICallPromises = [];
    setIsLoading(true);

    data.create.length && APICallPromises.push(createMultipleSystemSetting(data.create));
    data.update.length && APICallPromises.push(updateMultipleSystemSetting(data.update));

    const responses = await Promise.all(APICallPromises);

    let solutionSettingsState: SolutionSettingsStateProps[] = [];
    let callUpdateSetting: boolean = false;

    responses.forEach((response) => {
      if (response.status === HTTP_STATUS.HTTP_BAD_REQUEST) {
        dispatch(
          setToastData({
            toastMessage: response.data.issues
              .filter((issue: any) => !!issue.details)
              .map((issue: any) => `${issue.details}`)
              .join(),
            isToastActive: true,
            toastType: 'error'
          })
        );
      } else {
        let APIResponse = response.data || [];
        solutionSettingsState.push(
          ...APIResponse.map((ele: any) => {
            return { id: ele.id, key: ele.key };
          })
        );

        setJSONCopy(json);
        setReadOnly(true);
        callUpdateSetting = true;
      }

      if (callUpdateSetting) {
        dispatch(
          setToastData({
            toastMessage: t('T_TOAST_MESSAGE_APP_SETTINGS_SUCCESS'),
            isToastActive: true,
            toastType: 'success'
          })
        );

        sessionStorage.setItem(
          AppConstants.SOLUTION_APPLICATION_SETTINGS,
          JSON.stringify({ data: solSettingObj, value: { settings: solutionSettingsState } })
        );

        updateSolutionSettings(solutionSettingsState);
      }

      setIsLoading(false);
    });
  };

  const updateSolutionSettings = async (newSettings: any) => {
    let payload: SystemSetting = solSettingObj;

    payload['value'] = { settings: newSettings };
    payload['categories'] = solSettingObj?.categories?.map((category: any) => category.id);

    const updateResponse = await updateSystemSetting(solSettingObj.id as string, payload);
    if (updateResponse) {
      if (updateResponse.status === HTTP_STATUS.HTTP_BAD_REQUEST) {
        console.error(updateResponse.data.issues);
      }
    }
  };

  const isEmpty = (value: string) => {
    return !value || !value.trim();
  };

  const lineCount: any = useMemo(() => {
    if (showLineNumber) {
      return json.split('\n').length;
    }
  }, [json]);

  const linesArr: any = useMemo(() => {
    if (showLineNumber) {
      return Array.from({ length: Math.max(1, lineCount) }, (_, i) => i + 1);
    }
  }, [lineCount]);

  const handleTextareaScroll = () => {
    if (showLineNumber) {
      if (lineCounterRef.current && textareaRef.current) {
        lineCounterRef.current.scrollTop = textareaRef.current.scrollTop;
      }
    }
  };

  return (
    <div className="h-inherit">
      {!isComponent && (
        <MenuButtonsPortal>
          {!readOnly ? (
            <>
              <BiButton
                className={'mr-2 text-primary'}
                type="button"
                onClick={() => {
                  setJSON(jsonCopy);
                  setReadOnly(DMSsetting ? true : false);
                  setError('');
                }}
              >
                {t('T_CANCEL')}
              </BiButton>

              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  handleSave();
                }}
                disabled={!!error}
              >
                {t('T_SAVE')}
              </BiButton>
            </>
          ) : (
            <>
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  setReadOnly(DMSsetting ? false : true);
                }}
              >
                {t('T_EDIT')}
              </BiButton>
            </>
          )}
        </MenuButtonsPortal>
      )}

      <div className="h-inherit">
        <div className={`flex flex-wrap`}>
          {JSONEditorLabel ? (
            <div className="flex w-1/2 items-center">
              {required ? <Image src={Asterix} /> : ''}
              {JSONEditorLabel}
            </div>
          ) : (
            ''
          )}
          {!readOnly && (
            <>
              <div className={`${JSONEditorLabel ? 'w-1/2' : 'w-full'} text-right`}>
                {!isEmpty(json) && showFormatJSONButton && (
                  <BiButton
                    className={JSONEditorLabel ? buttonStyle + ' !mt-0' : buttonStyle}
                    type="button"
                    onClick={() => {
                      setJSON(prettyPrintJSON(json));
                    }}
                    disabled={isEmpty(json)}
                  >
                    {formatJSONButtonLabel ? formatJSONButtonLabel : t('T_FORMAT_JSON')}
                  </BiButton>
                )}

                {showImportJSONButton && (
                  <span>
                    <input
                      style={{ display: 'none' }}
                      ref={inputRef}
                      type="file"
                      accept={'.json'}
                      onChange={handleFileChange}
                      data-testid="import-json-file"
                    />

                    <BiButton className={buttonStyle} type="button" onClick={handleClick}>
                      {t('T_IMPORT_JSON')}
                    </BiButton>
                  </span>
                )}
              </div>
            </>
          )}

          <div
            className={`mt-2 mb-2 ${DMSsetting ? 'h-[72vh]' : 'h-[48vh]'}  w-full pr-2`}
            style={useTheme().theme.bgColorStyleForJsonEditor}
          >
            {!readOnly && <div className="text-sm text-danger">{error}</div>}
            <div className="relative h-full">
              {showLineNumber && (
                <div
                  ref={lineCounterRef}
                  className="flex-flex-col absolute m-0 h-full w-12 resize-none overflow-y-hidden rounded-md border border-solid border-gray-300 bg-gray-100 py-4 px-1 text-right leading-tight text-gray-400 shadow-none outline-none focus-visible:outline-none"
                >
                  {linesArr.map((count: any) => (
                    <div key={count}>{count}</div>
                  ))}
                </div>
              )}
              <textarea
                data-testid="content"
                ref={textareaRef}
                id="content"
                className={`h-full w-full resize-none rounded-md border border-solid border-gray-300 ${
                  showLineNumber ? 'px-12 leading-tight focus-visible:outline-none' : 'px-4'
                } pt-4 pb-4 `}
                disabled={readOnly}
                rows={3}
                value={json}
                onChange={(e) => {
                  setJSON(e.target.value);
                  prettyPrintJSON(e.target.value);
                }}
                onScroll={handleTextareaScroll}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

export default JSONEditor;
