import { find, groupBy, identity, orderBy, isEmpty, uniq, compact } from 'lodash';

import { getMetadataInstances } from '../../http/metadata-service';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { AppConstants } from '../../constants/AppConstants';
import { getUsers } from '../../http/user-management-services';

import { getSystemSettings } from '../../http/configuration-services';
import { setConfigList } from '../../app/mobileConfigurationReducer';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import {
  createSignedURL,
  getPublicMobileConfig,
  uploadFile
} from '../../http/asset-management-services';
import { Dictionary } from '@reduxjs/toolkit';
import { getGlobalBucketName } from '../../util/AppSetting';

import moment from 'moment';

let layoutConfigKey = 'LayoutConfiguration';

export const getCatalogConfigData = async (
  metaDataObjectList: any,
  dispatch: Function,
  fetchUsers: boolean,
  successCb: Function,
  failureCb: Function,
  pagination: any = { page: AppConstants.DEFAULT_PAGE, size: AppConstants.DEFAULT_PAGE_SIZE },
  sort: string[] = [AppConstants.DEFAULT_SORT_BY, AppConstants.DEFAULT_SORT_DESC]
) => {

  let { page, size } = pagination;
  const mobileConfigurationList: Array<any> = [];
  let userName: string = '';
  let response: any;

  let users: Array<string>;
  const usersMap: Map<string, any> = new Map<string, any>();

  const mobileConfigurationMetaDataObj: any = find(metaDataObjectList, {
    name: 'ConfigurationCatalog'
  });

  if (!mobileConfigurationMetaDataObj) {
    failureCb({});
    return;
  }

  const { id } = mobileConfigurationMetaDataObj;
  const mobileConfigurationResponse = await getMetadataInstances(id, [], page, size, sort);

  if (mobileConfigurationResponse.status === HTTP_STATUS.HTTP_OK) {
    const {
      data: { data: mobileConfigArr, paging }
    } = mobileConfigurationResponse;

    if (mobileConfigArr.length) {
      dispatch(setConfigList(mobileConfigArr[0]));
      if (fetchUsers) {
        users = mobileConfigArr[0].attributes.catalog
          .filter((cat: any) => !usersMap.get(cat.updatedBy))
          .map((ele: any) => ele.updatedBy);

        if (users.length) {
          const userList: Array<any> = await getUserProfiles(uniq(compact(users)));
          userList.forEach((user: any) => {
            usersMap.set(user?.userId, user);
          });
        }
      }

      mobileConfigArr.forEach(async (config: any, index: any) => {
        const { id, attributes } = config;
        const { catalog } = attributes;

        userName = userName ? ` by ${userName}` : '';
        if (catalog.length) {
          catalog.forEach((catalogData: any) => {
            const {
              category,
              updatedOn,
              displayName,
              version,
              valueMetaData,
              configMetaData,
              layoutColumn,
              updatedBy
            } = catalogData;
            mobileConfigurationList.push({
              id,
              displayName,
              version: 'v' + version,
              category,
              valueMetaData,
              configMetaData,
              layoutColumn,
              updatedOn: fetchUsers
                ? moment(updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT) +
                  ` by ${getUserName(usersMap.get(updatedBy)) || 'N/A'}`
                : '',
              updatedBy,
            });
          });
        }
      });

      successCb(mobileConfigurationList, paging);
    } else {
      failureCb(paging);
    }
  }
};

const checkValue = (arr: any, key: string) => {
  return find(arr, { key: key });
};

export const updateGCPFile = async (
  jsonData: string,
  key: string,
  userId: string,
  changedField: string = '',
  filePath: string = AppConstants.CONFIGURATION_MOBILE_GCS_FILEPATH
) => {

  return new Promise(async (resolve) => {
    const blob = new Blob([jsonData], { type: AppConstants.VALUE_TEXT_PLAIN });
    const file = new File([blob], key);

    const payload = {
      filePath: filePath,
      bucketName: getGlobalBucketName(),
      versioning: true,
      action: AppConstants.GCS_UPLOAD_ACTION,
      publicObject: true,
      expirationTime: AppConstants.GCS_MAX_EXPIRATION_TIME,
      metadata: {
        fileName: file.name,
        key: key,
        userId: userId,
        [AppConstants.HEADER_CACHE_CONTROL]: AppConstants.VALUE_NO_CACHE,
        [AppConstants.HEADER_MAX_AGE]: AppConstants.VALUE_MAX_AGE_MINIMUM
      }
    };

    const uploadResponse = await createSignedURL(payload);
    const { status, data } = uploadResponse;
    if (status === HTTP_STATUS.HTTP_CREATED) {
      const { url, headers = {} } = data;
      const uploadPayload = {
        url,
        body: file,
        headers: { ...headers, [AppConstants.HEADER_CONTENT_TYPE]: file.type }
      };
      const response = await uploadFile(
        uploadPayload,
        () => {},
        (err: string) => {
          console.error(err);
        }
      );
      resolve({ ...response, key: changedField });
    } else {
      resolve({ ...uploadResponse });
    }
  });
};

export const getGroupedConfig = async (
  metaDataObjectList: any,
  metaConfigKey: string,
  valueMetaData: 'MobileStyleConfig' | 'MobileConfig' | 'File'
) => {
  let keyList = [metaConfigKey, layoutConfigKey];

  let toFetchMetaDataObj = metaDataObjectList.filter((obj: any) => keyList.includes(obj.name));

  let metaDataResponse = new Map();

  await Promise.all(
    toFetchMetaDataObj.map(async ({ id, name }: any) => {
      let res = await getMetadataInstances(
        id,
        [],
        AppConstants.DEFAULT_PAGE,
        AppConstants.MAXIMUM_PAGE_SIZE
      );
      metaDataResponse.set(name, res.data);
    })
  );

  const valueConfigurationResponse = await getSystemSettings(
    valueMetaData,
    '',
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );

  const publicConfig = await fetch(AppConstants.MOBILE_CONFIG_PUBLIC_DATA);
  let publicConfigJSON: any;

  if (publicConfig.status === HTTP_STATUS.HTTP_OK) {
    publicConfigJSON = await publicConfig.json();
  }

  if (valueConfigurationResponse.status === HTTP_STATUS.HTTP_OK) {
    metaDataResponse.set(valueMetaData, valueConfigurationResponse.data);
  }
  const fieldLayoutMap = new Map(
    metaDataResponse
      .get(layoutConfigKey)
      ?.data?.map(({ attributes }: any) => [attributes.key, attributes])
  );

  const fieldArray = metaDataResponse
    .get(metaConfigKey)
    ?.data?.filter((ele: any) => ele.attributes.valueMetaData === valueMetaData)
    .map((item: any) => {
      //TODO
      let val = checkValue(metaDataResponse.get(valueMetaData).data, item.attributes.key);
      val.error = { value: '' };
      if (!isEmpty(val)) {
        return {
          key: item.attributes.key,
          metadata: { ...item.attributes },
          layout: fieldLayoutMap.get(item.attributes.key) || fieldLayoutMap.get('default'),
          values: val
        };
      }
    })
    .filter((ele: any) => ele);

  const sortedFieldArray = fieldArray.sort((initialEle: any, nextEle: any) => {
    return (
      initialEle.layout.groupSequence - nextEle.layout.groupSequence ||
      initialEle.layout.fieldSequence - nextEle.layout.fieldSequence
    );
  });
  return {
    mobileConfig: groupBy(sortedFieldArray, (obj) => {
      return obj.metadata.groupType;
    }),
    publicConfig: publicConfigJSON
  };
};

export const sanitizeJSONValue = (value: string) => {
  const content: string = decodeURIComponent(value).replace(/\n\r|\r\n/g, '');
  return content;
};

const getOption = ([firstCharacter, ...remaining]: any) => {
  return [firstCharacter.toUpperCase(), ...remaining].join('');
};

export const getFileConfiguration = async (
  metaDataObjectList: Array<any> = [],
  configCategory: string
) => {
  let fileConfig: any = {};
  let privateConfigCheck: boolean = false;

  let publicConfigCheck: boolean = false;
  let globalInstances: any = {};
  let fileName: any = {},
    dropdownOptions: Array<any> = [],
    fileKeyMap: any = {};

  const metaDataObjectKey: string = AppConstants.FIELD_METADATA_OBJECT_NAME;

  const metaDataObject = metaDataObjectList.find((obj: any) => metaDataObjectKey === obj.name);

  const { id: metaDataObjectId } = metaDataObject;

  const metaDataInstancesResponse: any = await getMetadataInstances(
    metaDataObjectId,
    [],
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );

  const { status: instanceStatus, data: instanceData } = metaDataInstancesResponse;

  if (instanceStatus === HTTP_STATUS.HTTP_OK) {
    const { data: instances = [] } = instanceData;
    const filteredInstances: Array<any> = instances.filter(
      (item: any) => item.attributes?.category === configCategory
    );

    filteredInstances.map((instance: any) => {
      const { attributes } = instance;
      const { key, keyType } = attributes || {};
      if (keyType === 'PUBLIC') {
        publicConfigCheck = true;
      } else {
        privateConfigCheck = true;
      }
      fileConfig[key] = { attributes };
    });
  }

  const fileAttributes = (imageObj: any) => {
    fileName = {
      ...fileName,
      [imageObj.key.split('.')[0] + getOption(imageObj.key.split('.')[1])]:
        imageObj?.value?.displayName || imageObj?.value?.fileName || ''
    };
    fileKeyMap = {
      ...fileKeyMap,
      [getOption(imageObj.key.split('.')[1])]: imageObj.key
    };
  };

  if (publicConfigCheck) {
    const globalConfigResponse = await getPublicMobileConfig();
    const { status: globalConfigStatus, data: globalConfigData } = globalConfigResponse;
    if (globalConfigStatus === HTTP_STATUS.HTTP_OK) {
      globalInstances = { ...globalConfigData };

      Object.entries(globalConfigData?.[configCategory]).forEach(([key, value]) => {
        if (fileConfig[key]) {
          fileAttributes({ key, value: fileConfig[key].attributes });
          fileConfig[key] = { ...fileConfig[key], value };
          dropdownOptions = [...dropdownOptions, key];
        }
      });
    }
  }

  if (privateConfigCheck) {
    const response = await getSystemSettings(
      configCategory,
      '',
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: imageDetails } = data;

      imageDetails.map(async (imageObj: any) => {
        const { value, key } = imageObj;
        if (fileConfig[key].attributes?.keyType === 'PROTECTED') {
          dropdownOptions = [...dropdownOptions, key];
          fileAttributes(imageObj);
          fileConfig[imageObj.key].value = imageObj;
          //Create a download URL
          const { fileName, bucketName, filePath } = value;
          const payload = {
            filePath: `${filePath}/${fileName}`,
            fileName,
            bucketName,
            versioning: true,
            expirationTime: 1440,
            action: AppConstants.GCS_UPLOAD_DOWNLOAD,
            publicObject: true
          };
          const downloadResponse = await createSignedURL(payload);

          const { data, status } = downloadResponse;
          if (status === HTTP_STATUS.HTTP_CREATED) {
            fileConfig[key].value.value.url = data.url;
          } else {
            fileConfig[key].value.value.url = '';
          }
        }
      });
    }
  }

  return {
    fileName,
    fileKeyMap,
    dropdownOptions,
    fileConfig,
    globalInstances
  };
};

export const getMobileConfigurations = async (
  metaDataObjectList: Array<any> = [],
  configCategory: string
) => {
  
  const metaDataObjectKeys: Array<string> = [
    AppConstants.FIELD_METADATA_OBJECT_NAME,
    AppConstants.FIELD_GROUP_OBJECT_NAME,
    AppConstants.FIELD_LAYOUT_OBJECT_NAME
  ];

  let metaDataObjects = metaDataObjectList.filter((obj: any) =>
    metaDataObjectKeys.includes(obj.name)
  );

  let metaDataResponse: Map<string, any> = new Map<string, any>();

  await Promise.all(
    metaDataObjects.map(async ({ id, name }: any) => {
      const response: any = await getMetadataInstances(
        id,
        [],
        AppConstants.DEFAULT_PAGE,
        AppConstants.MAXIMUM_PAGE_SIZE
      );

      const { status, data } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        const { data: instances = [] } = data;
        const filteredInstances: Array<any> = instances.filter(
          (item: any) => item.attributes?.category === configCategory
        );
        const keyValueMap: Map<string, any> = new Map(
          filteredInstances.map((instance: any) => {
            const { attributes } = instance;
            const { key, groupId } = attributes || {};
            return [key || groupId, instance];
          })
        );
        metaDataResponse.set(name, keyValueMap);
      }
      return response;
    })
  );

  const response = await getSystemSettings(
    configCategory,
    '',
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );
  const { status, data } = response;
  if (status === HTTP_STATUS.HTTP_OK) {
    const { data: systemSettings = [] } = data;
    metaDataResponse.set(
      configCategory,
      new Map(systemSettings.map((setting: any) => [setting.key, setting]))
    );
  }

  // Read Global Setting
  let globalInstances: any = {};
  const globalConfigResponse = await getPublicMobileConfig();
  const { status: globalConfigStatus, data: globalConfigData } = globalConfigResponse;
  if (globalConfigStatus === HTTP_STATUS.HTTP_OK) {
    globalInstances = { ...globalConfigData };
  }

  const displayFields: Array<any> = new Array<any>();
  const groupInstances: Map<string, any> =
    metaDataResponse.get(AppConstants.FIELD_GROUP_OBJECT_NAME) || new Map();

  const layoutInstances: Map<string, any> =
    metaDataResponse.get(AppConstants.FIELD_LAYOUT_OBJECT_NAME) || new Map();
  const metaInstances: Map<string, any> =
    metaDataResponse.get(AppConstants.FIELD_METADATA_OBJECT_NAME) || new Map();
  const configInstances: Map<string, any> = metaDataResponse.get(configCategory) || new Map();

  metaInstances.forEach((metadata, key) => {
    const { attributes } = metadata || {};
    const { groupId, defaultValues: valueDefaults = [], active = false } = attributes || {};
    if (active) {
      const defaultValues = valueDefaults.map((defaultValue: any) => {
        let { key, value } = defaultValue;
        try {
          value = typeof value === 'string' ? JSON.parse(sanitizeJSONValue(value)) : value;
        } catch (e) {
          // console.log(e);
        }
        return { key, value };
      });

      const value = globalInstances[configCategory]?.[key] || '';
      displayFields.push({
        key,
        groupId,
        value,
        metadata: { ...metadata, attributes: { ...attributes, defaultValues } },
        layout: { ...(layoutInstances.get(key) || {}) },
        // group: { ...(groupInstances.get(groupId) || {}) },
        valueConfig: { ...(configInstances.get(key) || {}) }
      });
    }
  });

  displayFields.sort((a: any, b: any) => a.layout?.attributes?.order - b.layout?.attributes?.order);

  const grouphierarchy = getGroupHierarchy(
    Array.from(groupInstances.values()),
    Array.from(metaInstances.values())
  );
  const fieldByGroup: Dictionary<any[]> = groupBy(displayFields, (item) => item.groupId);

  return { grouphierarchy, fieldByGroup, globalInstances };
};

const getGroupHierarchy = (fieldGroups: Array<any> = [], metaInstances: Array<any> = []) => {
  if (!fieldGroups.length) {
    return fieldGroups;
  }
  fieldGroups.sort((a: any, b: any) => a.attributes?.order - b.attributes?.order);
  const allGroups: Map<string, any> = new Map(
    fieldGroups.map((fieldGroup: any) => [
      fieldGroup.attributes?.groupId,
      { ...fieldGroup, children: [] }
    ])
  );
  fieldGroups.forEach((fieldGroup: any) => {
    const { attributes } = fieldGroup;
    const { groupId, parentId } = attributes;
    const parentNode = allGroups.get(parentId);
    if (parentNode) {
      const childNode = allGroups.get(groupId);
      parentNode.children.push(childNode);
    }
  });
  const fieldGroupIds: Set<string> = new Set(
    metaInstances.map((instance: any) => instance.attributes?.groupId)
  );
  return Array.from(allGroups.values()).filter(
    (node: any) => fieldGroupIds.has(node.attributes?.groupId) && !node.attributes?.parentId
  );
};

export const treeify = (nodes: Array<any> = []) => {
  let rootNodes: Array<any> = [];
  const parentNodes: Array<any> = nodes.filter((node: any) => !node.group?.attributes?.parentId);
  if (parentNodes.length) {
    const allNodes: Map<string, any> = new Map(
      nodes.map((node: any) => [node.id, { ...node, children: [] }])
    );

    nodes.forEach((node: any) => {
      const { id, parentId } = node;
      const parentNode = allNodes.get(parentId);
      if (parentNode) {
        const childNode = allNodes.get(id);
        parentNode.children.push(childNode);
      }
    });
    rootNodes = Array.from(allNodes.values()).filter(
      (node: any) => !node.group?.attributes?.parentId
    );
  }
  return rootNodes;
};
