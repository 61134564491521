import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AuthorsCollab, CareTeam } from '../../assets/components/svgs';
import Card from '../shared/cards/Card';
import {
  setCareTeamsList,
  setConditionsList,
  setDeviceList,
  setFormData,
  setOrgList,
  setPatientUserList,
  setPractitionerList,
  setPractitionerRoleList
} from '../../app/carePlanReducer';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import { AppConstants } from '../../constants/AppConstants';
import {
  fetchDeviceList,
  fetchPatientRoleUserList,
  fetchPractitionerRole,
  fetchPractitionerRoleUserList,
  getCarePlanConditions,
  getCarePlanOrganizations,
  getCareTeamsList
} from './carePlanUtils';
import { userFilterQuery } from '../../util/admin-utils';
import { getUsers } from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import Pills from './Reusable/Pills';
import { cloneDeep, findIndex } from 'lodash';
import styled from 'styled-components/macro';

interface FormProps {
  isEdit: boolean;
}

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

const Collaborators = ({ isEdit }: FormProps) => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formData = useAppSelector((state) => state.carePlanData.formData);
  const formError = useAppSelector((state) => state.carePlanData.formError);
  const carePlanOrgList: any = useAppSelector((state) => state.carePlanData.orgList) || [];
  const carePlanUserList: any =
    useAppSelector((state) => state.carePlanData.patientUsersList) || [];
  const careTeamList: any = useAppSelector((state) => state.carePlanData.careTeamList) || [];
  const deviceList: any = useAppSelector((state) => state.carePlanData.deviceList) || [];
  const practitionerList: any =
    useAppSelector((state) => state.carePlanData.practitionerList) || [];
  const practitionerRoleList: any =
    useAppSelector((state) => state.carePlanData.practitionerRoleList) || [];

  const [authorOptions, setAuthorOptions] = useState([]);
  const [contributorOptions, setContributorOptions] = useState([]);

  const updateFormData = (value: any) => {
    dispatch(setFormData(value));
  };

  const fetchDataAndUpdateOptions = async (
    dispatchFunction: Function,
    setOptionsFunction: Function,
    list: Array<any>,
    fetchDataFunction: Function
  ) => {
    if (!list.length) {
      const data = await fetchDataFunction();
      dispatch(dispatchFunction(data));
      setOptionsFunction(data);
    } else {
      setOptionsFunction(list);
    }
  };

  useEffect(() => {
    if (!careTeamList.length) {
      fetchDataAndUpdateOptions(setCareTeamsList, setAuthorOptions, careTeamList, getCareTeamsList);
    }
  }, [careTeamList]);

  useEffect(() => {
    if (formData.authorCategory.length) {
      const [selection] = formData.authorCategory;
      console.log(selection);
      switch (selection.key) {
        case 'organization':
          fetchDataAndUpdateOptions(
            setOrgList,
            setAuthorOptions,
            carePlanOrgList,
            getCarePlanOrganizations
          );
          break;
        case 'care_team':
          fetchDataAndUpdateOptions(
            setCareTeamsList,
            setAuthorOptions,
            careTeamList,
            getCareTeamsList
          );
          break;
        case 'patient':
          fetchDataAndUpdateOptions(
            setPatientUserList,
            setAuthorOptions,
            carePlanUserList,
            fetchPatientRoleUserList
          );
          break;
        case 'device':
          fetchDataAndUpdateOptions(setDeviceList, setAuthorOptions, deviceList, fetchDeviceList);
          break;
        case 'practitioner':
          fetchDataAndUpdateOptions(
            setPractitionerList,
            setAuthorOptions,
            practitionerList,
            fetchPractitionerRoleUserList
          );
          break;
        case 'practitionerRole':
          fetchDataAndUpdateOptions(
            setPractitionerRoleList,
            setAuthorOptions,
            practitionerRoleList,
            fetchPractitionerRole
          );
          break;
      }
    }
  }, [formData.authorCategory]);

  useEffect(() => {
    if (formData.contributorCategory.length) {
      const [selection] = formData.contributorCategory;
      switch (selection.key) {
        case 'organization':
          fetchDataAndUpdateOptions(
            setOrgList,
            setContributorOptions,
            carePlanOrgList,
            getCarePlanOrganizations
          );
          break;
        case 'care_team':
          fetchDataAndUpdateOptions(
            setCareTeamsList,
            setContributorOptions,
            careTeamList,
            getCareTeamsList
          );
          break;
        case 'patient':
          fetchDataAndUpdateOptions(
            setPatientUserList,
            setContributorOptions,
            carePlanUserList,
            fetchPatientRoleUserList
          );
          break;
        case 'device':
          fetchDataAndUpdateOptions(
            setDeviceList,
            setContributorOptions,
            deviceList,
            fetchDeviceList
          );
          break;
        case 'practitioner':
          fetchDataAndUpdateOptions(
            setPractitionerList,
            setContributorOptions,
            practitionerList,
            fetchPractitionerRoleUserList
          );
          break;
        case 'practitionerRole':
          fetchDataAndUpdateOptions(
            setPractitionerRoleList,
            setContributorOptions,
            practitionerRoleList,
            fetchPractitionerRole
          );
          break;
      }
    }
  }, [formData.contributorCategory]);

  const selectedAuthorKey = formData?.authorCategory?.[0]?.key;
  const selectedCategoryKey = formData?.contributorCategory?.[0]?.key;

  const removeCondition = (itemKey: string, itemValue: string) => {
    let clonedData = cloneDeep(formData?.careTeam);
    const index = findIndex(clonedData, function (item: any) {
      return item?.[itemKey] === itemValue;
    });

    if (index !== -1) {
      clonedData.splice(index, 1);
      updateFormData({ careTeam: clonedData });
    }
  };

  return (
    <div className="h-[75vh] overflow-hidden p-4">
      <Card
        title={t('T_CARE_PLAN_AUTHORS')}
        icon={AuthorsCollab}
        alt={t('T_CARD_ALT_MESSAGE')}
        height=""
        width=""
      >
        <div className="flex w-full flex-wrap">
          <div className="mb-5 flex w-full">
            <div className="flex w-1/2 flex-col">
              <PickList
                fieldKey={'key'}
                fieldValues={['value']}
                label={t('T_CARE_PLAN_AUTHOR_CATEGORY')}
                selectedItems={formData.authorCategory}
                showAddOption={false}
                onSelectedChange={(items: any) => {
                  if (selectedAuthorKey !== items?.[0]?.key) {
                    updateFormData({ author: [] });
                    setAuthorOptions([]);
                  }
                  updateFormData({ authorCategory: items });
                }}
                items={AppConstants.CAREPLAN_COLLABORATORS_AUTHOR}
                disabled={!isEdit}
                readOnly={false}
                type={PICKLIST_TYPES.SINGLE}
              />
            </div>
            <div className="w-1/2 pl-5">
              <PickList
                fieldKey={
                  selectedAuthorKey === 'organization'
                    ? 'id'
                    : selectedAuthorKey === 'patient'
                    ? 'userId'
                    : selectedAuthorKey === 'care_team'
                    ? 'id'
                    : selectedAuthorKey === 'device'
                    ? 'id'
                    : 'id'
                }
                fieldValues={
                  selectedAuthorKey === 'organization'
                    ? ['name']
                    : selectedAuthorKey === 'patient'
                    ? ['mail']
                    : selectedAuthorKey === 'care_team'
                    ? ['name']
                    : selectedAuthorKey === 'device'
                    ? ['serialNumber']
                    : ['name']
                }
                label={t('T_CARE_PLAN_AUTHOR')}
                selectedItems={formData.author}
                onSelectedChange={(item: any) => {
                  updateFormData({ author: item });
                }}
                items={authorOptions}
                disabled={!isEdit}
                readOnly={false}
                type={PICKLIST_TYPES.SINGLE}
              />
              {formError?.author && <ErrorLabel>{formError.author}</ErrorLabel>}
            </div>
          </div>

          <div className="flex w-full flex-wrap">
            <div className="mb-5 flex w-full">
              <div className="flex w-1/2 flex-col">
                <PickList
                  fieldKey={'key'}
                  fieldValues={['value']}
                  label={t('T_CARE_PLAN_CONTRIBUTOR_CATEGORY')}
                  selectedItems={formData.contributorCategory}
                  onSelectedChange={(items: any) => {
                    if (selectedCategoryKey !== items?.[0]?.key) {
                      updateFormData({ contributor: [] });
                      setContributorOptions([]);
                    }
                    updateFormData({ contributorCategory: items });
                  }}
                  items={AppConstants.CAREPLAN_COLLABORATORS_AUTHOR}
                  disabled={!isEdit}
                  readOnly={false}
                  type={PICKLIST_TYPES.SINGLE}
                />
              </div>
              <div className="w-1/2 pl-5">
                <PickList
                  fieldKey={
                    selectedCategoryKey === 'organization'
                      ? 'id'
                      : selectedCategoryKey === 'patient'
                      ? 'userId'
                      : selectedCategoryKey === 'care_team'
                      ? 'id'
                      : selectedCategoryKey === 'device'
                      ? 'id'
                      : ''
                  }
                  fieldValues={
                    selectedCategoryKey === 'organization'
                      ? ['name']
                      : selectedCategoryKey === 'patient'
                      ? ['mail']
                      : selectedCategoryKey === 'care_team'
                      ? ['name']
                      : selectedCategoryKey === 'device'
                      ? ['serialNumber']
                      : ['']
                  }
                  label={t('T_CARE_PLAN_CONTRIBUTOR')}
                  selectedItems={formData.contributor}
                  onSelectedChange={(item: any) => {
                    updateFormData({ contributor: item });
                  }}
                  items={contributorOptions}
                  disabled={!isEdit}
                  readOnly={false}
                  type={PICKLIST_TYPES.SINGLE}
                />
                {formError?.contributor && <ErrorLabel>{formError.contributor}</ErrorLabel>}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card
        title={t('T_CARE_PLAN_CARE_TEAM')}
        icon={CareTeam}
        alt={t('T_CARD_ALT_MESSAGE')}
        height=""
        width=""
      >
        <div className="flex w-full flex-wrap">
          <div className="mb-5 flex w-full flex-col">
            <PickList
              fieldKey={'id'}
              fieldValues={['name']}
              label={t('T_CARE_PLAN_CARE_TEAM')}
              selectedItems={formData.careTeam}
              onSelectedChange={(items: any) => {
                updateFormData({ careTeam: items });
              }}
              items={careTeamList}
              disabled={!isEdit}
              readOnly={false}
              type={PICKLIST_TYPES.MULTI}
            />
          </div>
          {formData?.careTeam?.length ? (
            <div>
              <div className="mb-2.5 text-base font-normal leading-6">
                {/* {t('T_CARE_TEAM_MEMBERS').replace('%s', formData?.careTeam[0]?.name)} */}
                {t('T_CARE_TEAM_SELECTED')}
              </div>
              <div className="mb-5 flex max-h-[20rem] w-full flex-wrap gap-2.5 overflow-y-auto">
                {formData?.careTeam?.map((item: any, index: number) => {
                  return (
                    <Pills
                      // isLast={index === formData?.careTeam[0]?.participants.length - 1}
                      isLast={index === formData?.careTeam.length - 1}
                      key={index}
                      name={item?.name}
                      onClick={() => {
                        removeCondition('id', item?.id);
                      }}
                      removeAll={() => {
                        updateFormData({ careTeam: [] });
                      }}
                      disabled={!isEdit}
                    ></Pills>
                  );
                })}
                {/* {formData?.careTeam[0]?.participants.map((item: any, index: number) => {
                  return (
                    <Pills
                      isLast={index === formData?.careTeam[0]?.participants.length - 1}
                      key={index}
                      name={item?.id}
                      onClick={() => {
                        //TODO
                      }}
                      removeAll={() => {
                        //TODO
                      }}
                    ></Pills>
                  );
                })} */}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </Card>
    </div>
  );
};

export default Collaborators;
