import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import InputField from '../../../shared/Fields/InputField';

import { MenuConstants } from '../../../../constants/MenuConstants';

import DotMenu, { Options } from '../../../primitives/dotMenu/DotMenu';

import styled from 'styled-components';
import Checkbox from '../../../shared/Fields/Checkbox';
import checkCircle from '../../../../assets/checkCircle.svg';
import _, { isEmpty, reject, remove, find } from 'lodash';
import eye from '../../../../assets/eye.svg';

import { getQuestionTypeLabel } from '../../../../util/surveyUtils';
import { setToastData } from '../../../../app/toastReducer';
import { AppConstants } from '../../../../constants/AppConstants';
import { Label } from '../../../AccountProfile/ProfileStyles';
import PickList, { PICKLIST_TYPES } from '../../../shared/Fields/PickList';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import { asterisk } from '../../../../assets/components/svgs';
import BiButton from '../../../primitives/buttons/BiButton.primitive';
import SelectField from '../../../shared/Fields/SelectField';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { getQuestionTypes } from '../../Question/QueUtils';

function ConditionalComponent({
  questionData,
  questionOption,
  currentIndex,
  setConditionalQuestion,
  conditionalQuestion,
  index,
  removeCondition,
  setConditionalToAddedQue,
  disabled,
  collection
}: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectQue, setSelectQue] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [selectOption, setSelectedOption] = useState<any[]>([]);
  const [operator, setOperator] = useState<any[]>([
    AppConstants.SURVEY_CONDITIONAL_DEFAULT_COMPARATOR
  ]);

  const [formData, setFormData] = useState({});

  let questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);

  const setQuestionTypes = async () => {
    let questionTypesObj: any;

    if (!isEmpty(questionTypes)) {
      questionTypesObj = questionTypes;
    } else {
      questionTypesObj = await getQuestionTypes(dispatch, metaDataObjectList);
    }
    questionTypes = questionTypesObj;
  };

  useEffect(() => {
    let temp = conditionalQuestion[index];
    const { question = null, selectedOption = null, operator = null } = temp || {};
    if (question && selectedOption) {
      const { displayType } = question;
      setSelectQue([
        {
          value: question.question || question.text,
          key: question.id,
          data: question,
          queType: displayType
        }
      ]);
      if (displayType === 'integer' || displayType === 'decimal') {
        setFormData({ conditionVal: selectedOption.value.toString() });
      } else {
        setSelectedOption([selectedOption]);
      }
      setOperator(operator);
    }

    const init = async () => {
      //await setQuestionTypes();
    };
    init();
  }, []);

  useEffect(() => {
    const [ques = null] = selectQue;
    let temp = [
      { value: 'Yes', key: 'Yes' },
      { value: 'No', key: 'No' }
    ];

    if (ques) {
      const { data } = ques;
      const { displayType } = data;
      let tempD = conditionalQuestion[index];
      const { question = null, selectedOption = null, operator = null } = tempD || {};

      if (displayType === 'boolean' || (operator && operator[0].value === 'exists')) {
        setOptions(AppConstants.SURVEY_CONDITONAL_BOOLEAN_VALUES);
      } else if (
        displayType === 'multi' ||
        displayType === 'single' ||
        displayType === 'bodyMapSingle'
      ) {
        setOptions(
          ques.data.answerOption.map((ele: any) => {
            return {
              value: typeof ele.value === 'string' ? ele.value : ele.value.display,
              key: typeof ele.value === 'string' ? ele.value : ele.value.display
            };
          })
        );
      }
      setSelectedOption([]);
      if (
        (displayType !== 'integer' && displayType !== 'decimal') ||
        (operator && operator[0].value === 'exists')
      ) {
        if (question && selectedOption) {
          setSelectedOption([selectedOption]);
        }
      }
    }
  }, [selectQue]);

  const setConditionalQuestionData = (option: any) => {
    const [que = null] = selectQue;
    const { enableWhen = [] } = questionData;
    if (que) {
      const { data, queType } = que;
      let temp = [...conditionalQuestion];
      if (queType === 'integer' || queType === 'decimal') {
        setConditionalQuestion({ question: data, selectedOption: option, operator: operator });
      } else {
        const [opt] = option;
        setConditionalQuestion({ question: data, selectedOption: opt, operator: operator });
        setSelectedOption(option);
      }
    }
  };

  useEffect(() => {
    if (conditionalQuestion && conditionalQuestion[0]) {
      questionData.enableWhen = conditionalQuestion;
      setConditionalToAddedQue(questionData);
    }
  }, [conditionalQuestion]);

  useEffect(() => {
    const [ques = null] = selectQue;
    if (ques) {
      const { data } = ques;
      const { displayType } = data;
      if (operator && operator[0].value === 'exists') {
        setOptions(AppConstants.SURVEY_CONDITONAL_BOOLEAN_VALUES);
      } else if (
        displayType === 'multi' ||
        displayType === 'single' ||
        displayType === 'bodyMapSingle'
      ) {
        setOptions(
          ques.data.answerOption.map((ele: any) => {
            return {
              value: typeof ele.value === 'string' ? ele.value : ele.value.display,
              key: typeof ele.value === 'string' ? ele.value : ele.value.display
            };
          })
        );
      }
    }
  }, [operator]);

  const updateCondAnswer = () => {
    setSelectedOption([]);
    setFormData({ conditionVal: '' });
  };

  const getAnswerField = (operator: any) => {
    const [que = null] = selectQue;
    const { queType = '' } = que || {};

    switch (queType) {
      case 'integer':
      case 'decimal':
        if (operator && operator[0].value === 'exists') {
          return (
            <PickList
              fieldKey={'key'}
              fieldValues={['value']}
              selectedItems={selectOption}
              onSelectedChange={(items: any) => {
                setConditionalQuestionData(items);
              }}
              items={options}
              disabled={disabled || !selectQue.length}
              readOnly={false}
              type={PICKLIST_TYPES.SINGLE}
            />
          );
        } else {
          return (
            <InputField
              className="w-full"
              type="number"
              id="numericValue"
              disableLabel={true}
              label=""
              placeholder="Enter Value"
              fieldKey="conditionVal"
              required={false}
              formData={formData}
              handleChange={(e: any) => {
                setConditionalQuestionData(e.target.value);
                setFormData({ conditionVal: e.target.value });
              }}
              setFormData={setFormData}
              formError={{}}
              disabled={disabled || !selectQue.length}
            />
          );
        }

        break;
      case 'multi':
      case 'single':
      case 'boolean':
      case 'bodyMapSingle':
        return (
          <PickList
            fieldKey={'key'}
            fieldValues={['value']}
            selectedItems={selectOption}
            onSelectedChange={(items: any) => {
              setConditionalQuestionData(items);
            }}
            items={options}
            disabled={disabled || !selectQue.length}
            readOnly={false}
            type={PICKLIST_TYPES.SINGLE}
          />
        );
        break;
      default:
        return (
          <InputField
            className="w-full"
            type="number"
            id="integerValue"
            disableLabel={true}
            label=""
            placeholder="Enter Value"
            fieldKey="conditionVal"
            required={false}
            formData={formData}
            setFormData={setFormData}
            formError={{}}
            disabled={true}
          />
        );
    }
  };

  return (
    <div className="flex w-full px-5 py-4">
      <div className="w-[50%]">
        <Label>
          {asterisk}
          {t('T_QUESTION')}
        </Label>
        <PickList
          fieldKey={'key'}
          fieldValues={['value']}
          selectedItems={selectQue}
          onSelectedChange={(items: any) => {
            updateCondAnswer();
            setSelectQue(items);
          }}
          items={questionOption || []}
          disabled={disabled}
          readOnly={false}
          type={PICKLIST_TYPES.SINGLE}
        />
      </div>

      <div className="mx-3 mt-auto w-[7%]">
        <PickList
          fieldKey={'key'}
          fieldValues={['value']}
          selectedItems={operator}
          onSelectedChange={(items: any) => {
            updateCondAnswer();
            setOperator(items);
          }}
          items={AppConstants.SURVEY_CONDITIONAL_COMPARATOR_LIST || []}
          disabled={disabled}
          readOnly={false}
          type={PICKLIST_TYPES.SINGLE}
        />
      </div>

      <div className="w-[36%]">
        <Label>
          {asterisk}
          {t('T_ANSWER')}
        </Label>
        {getAnswerField(operator)}
      </div>

      <div className="mx-auto w-[7%]">
        <DeleteIcon
          className="mx-auto mt-9 h-6 w-6 text-gray-600 hover:text-black"
          onClick={() => {
            !disabled && removeCondition(index, conditionalQuestion);
          }}
          data-testid="delete-icon"
        />
      </div>
    </div>
  );
}

interface ConditionalBlockProps {
  questionData: any;
  collection: any;
  setQuestions: Function;
  contextMenuHandler: Function;
  currentIndex: number;
  disabled: boolean;
  containerClassName?: string;
  iconSize?: string;
  mode: number;
  groupIndex?: any;
  AddQuestionModal?: any;
  branch?: any;
  option?: any;
  setConditionalToAddedQue?: Function;
}

const Block = styled.div`
  padding: 1.25rem;
  // border-color: #bfbfbf;
  // border-bottom-width: 0.5px;
`;

const ConditionalBlock = ({
  questionData,
  collection,
  setQuestions,
  currentIndex,
  disabled,
  mode,
  contextMenuHandler,
  containerClassName = '',
  iconSize = '',
  groupIndex = '',
  AddQuestionModal = '',
  branch = '',
  option = '',
  setConditionalToAddedQue = () => {}
}: ConditionalBlockProps) => {
  const { t } = useTranslation();

  const toggleClass = ' transform translate-x-5';
  const [selectedOption, setSelectedOption] = useState<Options | null>();
  let {
    text,
    type,
    id,
    branchingConditons = [],
    options,
    readOnly,
    question,
    displayType,
    extension,
    answerOption = []
  } = questionData;

  const dispatch = useAppDispatch();
  const [questionOption, setQuestionOption] = useState<any[]>([]);
  const [conditionalCheck, setConditionalCheck] = useState<boolean>(false);
  const [questionAdded, setQuestionAdded] = useState<any>([]);

  const [formData, setFormData] = useState<any>({
    operator: AppConstants.SURVEY_CONDITIONAL_DEFAULT_OPTION,
    conditionQue: [null]
  });

  let questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);

  const setQuestionTypes = async () => {
    let questionTypesObj: any;

    if (!isEmpty(questionTypes)) {
      questionTypesObj = questionTypes;
    } else {
      questionTypesObj = await getQuestionTypes(dispatch, metaDataObjectList);
    }
    questionTypes = questionTypesObj;
  };

  useEffect(() => {
    const init = async () => {
      await setQuestionTypes();
    };
    const { enableWhen = [], enableBehavior = '' } = questionData || {};

    if (enableWhen && enableWhen.length) {
      setFormData({
        ...formData,
        operator: enableBehavior ? enableBehavior.toUpperCase() : '',
        conditionQue: enableWhen
      });
      if (enableWhen[0]) {
        setConditionalCheck(true);
      }
    }
  }, []);

  const [formErrorData, setFormErrorData] = useState<any>({ operator: '' });

  let dotMenuOptions: Array<any> = [
    {
      display: `${t('T_REMOVE_QUESTION')}`,
      key: MenuConstants.MENU_REMOVE_QUESTION
    }
  ];

  if (type !== 'display') {
    dotMenuOptions.unshift({
      display: readOnly ? `${t('T_REMOVE_READ_ONLY')}` : `${t('T_MARK_AS_READ_ONLY')}`,
      key: readOnly ? MenuConstants.MENU_REMOVE_READ_ONLY : MenuConstants.MENU_MARK_AS_READ_ONLY
    });
  }

  //if (!mode) {
  dotMenuOptions.unshift({
    display: `${t('T_EDIT_QUESTION_ANSWERS')}`,
    key: MenuConstants.MENU_EDIT_QUESTION_ANSWERS
  });
  //}

  const toggleRequired = () => {
    if (!disabled) {
      let clone = [...collection];
      clone[currentIndex] = { ...clone[currentIndex], required: !clone[currentIndex].required };
      setQuestions(clone);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      contextMenuHandler(id, selectedOption, option);
    }
  }, [selectedOption]);

  useEffect(() => {
    let temp: any[] = [];
    collection.forEach((que: any, index: number) => {
      const { displayType, text, question } = que;
      const allowedTypes = AppConstants.SURVEY_CONDITIONAL_ALLOWED_QUE_TYPES;
      if (allowedTypes.includes(displayType)) {
        if (index < currentIndex) {
          temp.push({ value: question || text, data: que, key: que.id, queType: displayType });
        }
      }
    });
    setQuestionOption(temp);
  }, [collection]);

  const removeCondition = (indexData: number, data: any[]) => {
    const arr = data || [];
    let rejectArr = [...arr];
    _.remove(rejectArr, (n, i) => {
      return i === indexData;
    });
    setFormData({ ...formData, conditionQue: rejectArr });
    questionData.enableWhen = rejectArr;
    setQuestionAdded([]);
  };

  const addConditionQuestion = (index: number, data: any) => {
    const temp = [...formData.conditionQue];
    if (index <= temp.length - 1) {
      temp[index] = data;
    } else if (data === null) {
      temp.push(null);
    }
    setFormData({ ...formData, conditionQue: temp });
  };

  useEffect(() => {
    const { conditionQue, operator } = formData;
    setQuestionAdded(conditionQue);
    questionData.enableBehavior = operator.toLowerCase();
    setConditionalToAddedQue(questionData);
  }, [formData]);

  return (
    <>
      {currentIndex > 0 ? (
        //conditional questions
        <>
          <button
            className={`collapsed accordion-button flex w-full flex-row justify-between rounded-b-lg px-5 py-3 transition focus:outline-none`}
            style={{ backgroundColor: '#F5F7FA' }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-${id}-${currentIndex}-${branch}`}
            aria-expanded="false"
            aria-controls={`collapse-${id}-${currentIndex}-${branch}`}
            onClick={() => {
              !disabled && setConditionalCheck(!conditionalCheck);
            }}
            id={`#collapse-${id}-${currentIndex}-${branch}`}
          >
            <div className="flex flex-row">
              <Checkbox
                className="mx-auto"
                height="20px"
                width="20px"
                disabled={disabled}
                checked={conditionalCheck}
              />

              <div className="ml-2 text-sm font-normal" style={{ color: '#262626' }}>
                Display this question if ...
              </div>
            </div>
          </button>
          <div
            id={`collapse-${id}-${currentIndex}-${branch}`}
            className={`accordion-collapse !visible collapse rounded-b-lg ${
              conditionalCheck ? `show` : ``
            }`}
            //</>className="accordion-collapse !visible collapse rounded-b-lg"
            aria-labelledby="headingTwo5"
            style={{ background: '#FAFAFA' }}
          >
            <div className="accordion-body flex flex-col">
              <Block className="flex">
                <div className="mt-2 flex-1 text-sm font-normal">
                  <div className="flex leading-[44px]">
                    Display this question only if{' '}
                    <div className="w-20">
                      <SelectField
                        type="text"
                        id="operator"
                        label={''}
                        placeholder={''}
                        fieldKey={'operator'}
                        required={true}
                        formData={formData}
                        setFormData={setFormData}
                        formError={formErrorData}
                        options={AppConstants.SURVEY_CONDITIONAL_OPTIONS_LIST || []}
                        maxHeight={false}
                        fontSize={'16px'}
                        disabled={disabled || formData.conditionQue.length < 2}
                        style={{
                          border: 'none',
                          background: 'transparent',
                          color: '#2C71AD'
                        }}
                      />
                    </div>{' '}
                    of the following conditions are met
                  </div>
                </div>

                <div className="w-[7%]" />
              </Block>

              <div>
                {questionAdded.map((ele: any, index: number) => {
                  return (
                    <ConditionalComponent
                      questionData={questionData}
                      disabled={disabled}
                      collection={collection}
                      questionOption={questionOption}
                      currentIndex={currentIndex}
                      setConditionalQuestion={(data: any) => {
                        addConditionQuestion(index, data);
                      }}
                      conditionalQuestion={formData.conditionQue}
                      index={index}
                      removeCondition={removeCondition}
                      setConditionalToAddedQue={setConditionalToAddedQue}
                    />
                  );
                })}
              </div>

              <div className="mb-4 mt-2 px-5">
                <BiButton
                  className={
                    'flex bg-transparent !p-0 leading-5 text-gray-400 enabled:!text-primary'
                  }
                  type="button"
                  onClick={() => {
                    addConditionQuestion(formData.conditionQue.length, null);
                  }}
                  disabled={disabled || formData.conditionQue.includes(null)}
                >
                  <span className="mr-2">
                    <PlusCircleIcon className="h-5" data-testid="plus-icon" />
                  </span>
                  <div>Add Condition</div>
                </BiButton>
              </div>

              {false && (
                <div className="border-t-[0.5px] border-solid border-[#BFBFBF] py-2 pr-5 text-right">
                  <BiButton
                    className={'mr-5'}
                    type="button"
                    onClick={() => {
                      setConditionalCheck(false);

                      let d = document.getElementById(`#collapse-${id}-${currentIndex}-${branch}`);
                      d?.click();
                    }}
                  >
                    {t('T_CANCEL')}
                  </BiButton>

                  <BiButton
                    className={'border border-solid border-white bg-primary text-white'}
                    type="button"
                    onClick={() => {}}
                  >
                    {t('T_APPLY_CONDITIONS')}
                  </BiButton>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ConditionalBlock;
