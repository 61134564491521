import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { find } from 'lodash';
import InputField from '../shared/Fields/InputField';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import { AppConstants } from '../../constants/AppConstants';
import { useState } from 'react';
import { DualingListPick } from '../shared/DualingList/DualingList';
import { Switch, ToggleButton } from '../shared/Fields/Switch';

interface FieldItemProps {
  fieldItem: any;
  fieldValue: any;
  fieldErrors: Array<string>;
  onValueChange: Function;
  direction: string;
}

const DEFAULT_KEY_ID = 'key';
const DEFAULT_VALUE_KEY = 'value';

export const FieldItem: React.FC<FieldItemProps> = ({
  fieldItem,
  fieldValue,
  fieldErrors = Array<string>(),
  onValueChange,
  direction = ''
}: FieldItemProps) => {
  const { key, metadata, groupId } = fieldItem;
  const { attributes: metaParams } = metadata;
  const {
    dataType,
    readOnly = false,
    disabled = false,
    defaultValues = [],
    displayKeys = [DEFAULT_VALUE_KEY],
    validator
  } = metaParams;

  const { t } = useTranslation();
  const [errors, setErrors] = useState<Array<string>>(fieldErrors);
  const [valueItem, setValueItem] = useState<any>(fieldValue?.value || fieldValue);
  const { condition = 'ANY', rules = [] } = validator || {};

  const isNumber = (value: any) => {
    if (typeof value === 'number') {
      return true;
    }
    if (typeof value === 'string') {
      return !isNaN(value as any);
    }
    return false;
  };

  const getMinSelection = () => {
    const constraints = rules.map((rule: any) => rule.min || 0);
    return condition === AppConstants.RULE_CONDITION_ALL
      ? Math.max(...constraints)
      : Math.min(...constraints);
  };

  const getMaxSelection = () => {
    const constraints = rules.map((rule: any) => rule.max || 1);
    return condition === AppConstants.RULE_CONDITION_ALL
      ? Math.max(...constraints)
      : Math.min(...constraints);
  };

  const onChange = (selection: any) => {
    let input: any = '';
    const errors: Array<string> = [];
    switch (dataType) {
      case 'MULTI':
      case 'ORDEREDMULTI':
        const length: number = selection.length;
        const minimum: number = getMinSelection();
        const maximum: number = getMaxSelection();

        rules.forEach((rule: any) => {
          const { error, min, max } = rule;
          if (length < minimum && min) {
            errors.push(error);
          }
          if (length > maximum && max) {
            errors.push(error);
          }
        });
        const values: Array<any> = selection.map((item: any) => item.value);
        onValueChange(key, values, groupId, errors);
        setErrors(errors);
        setValueItem(values);
        break;
      case 'SINGLE':
        if (selection.length) {
          const singleValue: any = selection[0];
          const { value: eleValue } = singleValue;
          onValueChange(key, eleValue, groupId);
          setErrors(errors);
          setValueItem(eleValue);
        }
        break;
      case 'BOOLEAN':
      case 'DATE':
      case 'TIME':
      case 'DATETIME':
      case 'FILE':
        const value =
          typeof selection === 'boolean'
            ? Boolean(selection).toString()
            : selection.value || selection;
        onValueChange(key, value, groupId);
        setErrors(errors);
        setValueItem(value);
        break;
      case 'COLOR':
      case 'STRING':
      case 'NUMBER':
      case 'INTEGER':
        let flag = true;

        input = selection instanceof Object ? selection.value : selection;
        const results: Array<boolean> = rules.map((rule: any) => {
          let success: boolean = false;
          const { regEx, scope = 'ig', min = 0, max = 9999 } = rule;

          if (Number(selection) < min && selection !== '') {
            flag = false;
          }
          if (!flag) return;

          if (regEx) {
            success = new RegExp(regEx, scope).test(input);
          }
          if (isNumber(input)) {
            success = success && input >= min && input < max + 1;
          } else {
            success = success && input.length > min && input.length < max;
          }
          return success;
        });

        const result: boolean =
          condition === AppConstants.RULE_CONDITION_ALL
            ? results.every((result: boolean) => result)
            : results.some((result: boolean) => result);

        if (!result) {
          results.forEach((status: any, index: number) => {
            const rule: any = rules[index] || {};
            const { error = '' } = rule;
            if (!status) {
              errors.push(error);
            }
          });
        }

        if (flag) {
          onValueChange(
            key,
            selection instanceof Object ? selection : selection.trim(),
            groupId,
            errors
          );
          setErrors(errors);
          setValueItem(selection);
        }
        break;
    }
  };

  const renderField = () => {
    let selectedItems: Array<any> = new Array();

    switch (dataType) {
      case 'BOOLEAN':
        const result: boolean = /true/i.test(valueItem);
        return (
          <div className="flex flex-row items-center justify-center">
            <Switch
              toggle={result}
              onClick={() => onChange(!result)}
              data-testid={'boolean-field'}
              id={key}
            >
              <ToggleButton toggle={result} />
            </Switch>
            <div className="ml-3 self-center">{result ? 'ON' : 'OFF'}</div>
          </div>
        );
      case 'STRING':
        return (
          <div className="flex flex-1 flex-col">
            <div
              className={`flex flex-col ${
                direction === AppConstants.DISPLAY_INLINE ? 'items-end' : 'items-start'
              }`}
            >
              <InputField
                type="text"
                id={key}
                label={''}
                placeholder={t('T_EMAIL')}
                fieldKey="value"
                required={true}
                max={getMaxSelection()}
                disableLabel
                formData={{ type: key, value: valueItem } as any}
                formError={errors.length ? ({ value: errors[0] } as any) : {}}
                errorClass="text-sm mt-1"
                setFormData={(e: any) => onChange(e.value)}
                // onBlurhandler={(e: any) => onChange(e.target.value)}
                disabled={disabled}
              />
            </div>
          </div>
        );
      case 'NUMBER':
      case 'INTEGER':
        return (
          <div className="mt-1 flex flex-row items-center">
            {/*<input
              data-testid={key}
              id="content"
              className="h-11 w-16 rounded-md border border-solid border-gray-300 px-2 pl-2"
              value={inputValue}
              onChange={(e: any) => onChange(e.target.value)}
              type="number"
              min={getMinSelection()}
              max={getMaxSelection()}
            /> */}
            <div
              className={`flex flex-col ${
                direction === AppConstants.DISPLAY_INLINE ? 'items-end' : 'items-start'
              }`}
            >
              <InputField
                type="number"
                id={key}
                label={''}
                className="h-11 w-24 rounded-md border border-solid border-gray-300 px-2 pl-2"
                placeholder={''}
                fieldKey="value"
                required={true}
                disableLabel
                formData={{ type: key, value: valueItem } as any}
                formError={errors.length ? ({ value: errors[0] } as any) : {}}
                errorClass="text-sm mt-1"
                setFormData={(e: any) => onChange(e.value)}
                onBlurhandler={(e: any) => onChange(e.target.value)}
                disabled={disabled}
              />
            </div>
          </div>
        );
      case 'DATE':
        break;
      case 'TIME':
        break;
      case 'DATETIME':
        break;
      case 'COLOR':
        return (
          <div className={`ml-1 flex  flex-row  pt-2.5`}>
            <input
              className="mt-2 cursor-pointer"
              data-testid="color-picker"
              type="color"
              id="style-color"
              name="style-color"
              value={valueItem}
              onChange={(e: any) => onChange(e.target.value)}
            ></input>
            <div className="ml-2 flex flex-col">
              <InputField
                type="text"
                id={'color-hex'}
                label={''}
                className="w-32"
                min={4}
                max={7}
                placeholder={'Input Hex Code'}
                fieldKey="value"
                required={true}
                disableLabel
                formData={{ type: 'color', value: valueItem } as any}
                formError={errors.length ? ({ value: errors[0] } as any) : {}}
                errorClass="text-sm ml-2"
                setFormData={(e: any) => onChange(e.value)}
                // onBlurhandler={(e: any) => onChange(e.target.value)}
                disabled={disabled}
              />
            </div>
          </div>
        );
      case 'FILE':
        break;
      case 'SINGLE':
        const defaultValue = find(defaultValues, { value: valueItem });
        if (defaultValue) {
          selectedItems.push(defaultValue);
        }
        return (
          <div className="flex flex-1 flex-col">
            <PickList
              fieldKey={DEFAULT_KEY_ID}
              fieldValues={displayKeys}
              selectedItems={selectedItems}
              onSelectedChange={(item: any) => {
                onChange(item);
              }}
              items={defaultValues.sort((a: any, b: any) => a.key - b.key)}
              disabled={disabled}
              readOnly={readOnly}
              type={PICKLIST_TYPES.SINGLE}
            />
            {errors.length ? <div className="ml-2 text-sm text-red-500">{errors[0]}</div> : ''}
          </div>
        );
      case 'MULTI':
        valueItem?.forEach((value: any) => {
          const defaultValue = find(defaultValues, { value });
          if (defaultValue) {
            selectedItems.push(defaultValue);
          }
        });
        return (
          // <div className={display == 'INLINE' ? 'mr-1' : 'mb-1'}>
          <div className="flex flex-1 flex-col">
            <PickList
              fieldKey={DEFAULT_KEY_ID}
              fieldValues={displayKeys}
              selectedItems={selectedItems}
              onSelectedChange={(item: any) => {
                onChange(item);
              }}
              items={defaultValues.sort((a: any, b: any) => a.key - b.key)}
              disabled={disabled}
              readOnly={readOnly}
              type={PICKLIST_TYPES.MULTI}
            />
            {errors.length ? <div className="ml-2 text-sm text-red-500">{errors[0]}</div> : ''}
          </div>
        );
      case 'ORDEREDMULTI':
        valueItem?.forEach((value: any) => {
          const defaultValue = find(defaultValues, { value });
          if (defaultValue) {
            selectedItems.push(defaultValue);
          }
        });
        return (
          <div className="flex flex-1 flex-col py-3">
            <DualingListPick
              initialData={defaultValues.filter((defaultArr: any) =>
                selectedItems.every(
                  (selectedArr: any) => selectedArr[DEFAULT_KEY_ID] !== defaultArr[DEFAULT_KEY_ID]
                )
              )}
              setInitialData={() => {}}
              outputData={selectedItems}
              setOutputData={(item: any) => {
                onChange(item);
              }}
              fieldKey={DEFAULT_KEY_ID}
              fieldValues={displayKeys}
              styles={{
                listHeight: 'h-72',
                listWidth: 'w-60',
                buttonWidth: 'w-8',
                iconHeight: 'h-5',
                buttonHeight: 'h-8',
                fontSize: 'text-sm',
                listHeaderFontSize: 'text-base'
              }}
              disableSearch
            ></DualingListPick>
            {errors.length ? <div className="ml-2 text-sm text-red-500">{errors[0]}</div> : ''}
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return <>{renderField()}</>;
};
