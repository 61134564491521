import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';

import BiButton from '../primitives/buttons/BiButton.primitive';
import InputField from '../shared/Fields/InputField';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';

import refreshIcon from '../../assets/refresh.svg';
import close from '../../assets/close.svg';
import { Label } from '../Onboarding/OnboardingStyles';
import { chunk, isEmpty } from 'lodash';

interface FilterCardProps {
  filtersList: any;
  hideFilter: Function;
  applyFilters: Function;
}

const FilterCard: React.FC<FilterCardProps> = ({ filtersList, hideFilter, applyFilters }) => {
  const { t } = useTranslation();

  const [defaultFilterList, setDefaultFilterList] = useState<any>(filtersList);
  const [defaultFilter, setDefaultFilter] = useState<any>(filtersList);

  const getInitialFilter = () => {
    const initialFilter: any = {};
    filtersList.forEach((filter: any) => {
      if (filter?.selectedFilter?.length) {
        initialFilter[filter.id] = filter.selectedFilter;
      }
    });

    return initialFilter;
  };

  const [appliedFilter, setAppliedFilter] = useState<any>(getInitialFilter());

  const onPicklistChange = (
    item: any,
    filterId: any,
    isDependentFilter: any,
    fetchDependentFields: any,
    dependentChildId: any
  ) => {
    setAppliedFilter((prevState: any) => ({ ...prevState, [filterId]: item }));

    if (isDependentFilter) {
      //setAppliedFilter({ ...appliedFilter, [dependentChildId]: [] });
      setAppliedFilter((prevState: any) => ({ ...prevState, [dependentChildId]: [] }));
      fetchDependentFields(item);
    }
  };

  const onInputChange = (e: any, filterId: any) => {
    const val = e.target.value;
    setAppliedFilter((prevState: any) => ({ ...prevState, [filterId]: val }));
  };

  const resetDependentFilter = () => {
    filtersList.forEach((filter: any) => {
      const { isDependentFilter, fetchDependentFields, id } = filter;
      if (isDependentFilter) {
        if (fetchDependentFields) fetchDependentFields([]);
      }
    });
  };

  const onResetFilters = () => {
    resetDependentFilter();
    setAppliedFilter({});
  };

  const onApplyFilters = () => {
    applyFilters(appliedFilter);
    hideFilter(false);
  };

  const validateApplyFilter = () => {
    let toBeDisabled = true;
    const initialFilter = getInitialFilter();
    if (!isEmpty(initialFilter)) {
      return false;
    }
    if (isEmpty(appliedFilter)) {
      toBeDisabled = true;
    } else {
      const filtersApplied = Object.keys(appliedFilter);
      filtersApplied.forEach((key: any) => {
        if (appliedFilter[key].length) {
          toBeDisabled = false;
        }
      });
    }
    return toBeDisabled;
  };

  const getFilterField = (filterObj: any) => {
    const {
      component,
      type,
      selectedFilter,
      itemsList,
      label,
      fieldKey,
      fieldValues,
      id,
      disabled,
      fetchMoreData,
      hasMore = false,
      isDependentFilter,
      fetchDependentFields = () => {},
      dependentChildId = '',
      searchData = () => {},
      searchable,
      searchPlaceholder = '',
      formData,
      setFormData,
      formError
    } = filterObj;

    switch (component) {
      case 'picklist':
        return (
          <>
            <PickList
              fieldKey={fieldKey}
              required={false}
              fieldValues={[fieldValues]}
              selectedItems={appliedFilter[id] ? appliedFilter[id] : []}
              onSelectedChange={(item: any) => {
                onPicklistChange(
                  item,
                  id,
                  isDependentFilter,
                  fetchDependentFields,
                  dependentChildId
                );
              }}
              items={itemsList}
              disabled={disabled}
              readOnly={false}
              fetchMoreData={(id: any) => fetchMoreData(id)}
              searchData={searchData}
              searchable={searchable}
              searchPlaceholder={searchPlaceholder}
              hasMore={hasMore}
              type={type !== 'single' ? PICKLIST_TYPES.MULTI : PICKLIST_TYPES.SINGLE}
            />
          </>
        );
        break;
      case 'input':
        return (
          <InputField
            fieldKey={id}
            type={type}
            id={id}
            label={label}
            placeholder={searchPlaceholder}
            required={false}
            formData={appliedFilter[id] ? appliedFilter : {}}
            setFormData={() => {}}
            handleChange={(e: any) => onInputChange(e, id)}
            formError={{}}
            asterixFirst={false}
            disabled={false}
            allowSplChar={false}
            validateField={true}
            disableLabel={true}
          />
        );
        break;
    }
  };

  const renderFields = (fields: any) => {
    let widthClass = fields.length > 1 ? `w-1/${fields.length}` : 'w-full';

    return fields.map((field: any) => {
      return (
        <div className={`flex ${widthClass} flex-col pl-4`}>
          <div className="flex flex-row items-center">
            <Label>{field?.label ? t(field.label) : ''}</Label>
          </div>
          <div className="flex h-full items-center justify-start">{getFilterField(field)}</div>
        </div>
      );
    });
  };

  const getFilterFields = () => {
    const filterChunks = chunk(filtersList, 3);
    return filterChunks.map((filterFields) => {
      return <div className="mb-5 mt-5 flex">{renderFields(filterFields)}</div>;
    });
  };

  return (
    <>
      <div className="flex h-full items-center justify-end">
        <BiButton
          className={'ml-1 mr-2 text-primary'}
          type="button"
          style={{ padding: '0' }}
          onClick={() => {
            onResetFilters();
          }}
        >
          {t('T_RESET_FILTERS')}
        </BiButton>
        <BiButton
          className={'w-1/7 bg-primary text-white'}
          type="button"
          onClick={() => {
            onApplyFilters();
          }}
          disabled={validateApplyFilter()}
        >
          {t('T_FILTER_APPLY')}
        </BiButton>
        <img
          src={close}
          alt="headerLeftIcon"
          className="ml-2 h-4 cursor-pointer"
          onClick={() => hideFilter(false)}
        />
      </div>
      {getFilterFields()}
    </>
  );
};

export default FilterCard;
