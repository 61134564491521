import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  tableOptions: {}
};

export const templateTableReducer = createSlice({
  name: 'templateTableReducer',
  initialState,
  reducers: {
    setTableOptions: (state, action) => {
        state.tableOptions = action.payload;
    },
    resetTableOptions: () => initialState
  }
});

export const {
    setTableOptions,
    resetTableOptions
} = templateTableReducer.actions;

export default templateTableReducer.reducer;
