import { useState, memo } from 'react';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { updateFileMetaData, createSignedURL } from '../../http/asset-management-services';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';

interface ListContractsProps {
  bucketName: string;
  setActiveTab: Function;
  TABS: { [key: string]: string };
  toggleVisiblity: Function;
  setRows: Function;
  rows: any;
  loader: boolean;
  setLoader: Function;
  cache: Array<any>;
  setCache: Function;
  setToastMsg: Function;
}

function ListContracts({
  bucketName,
  setActiveTab,
  TABS,
  toggleVisiblity,
  loader,
  setLoader,
  setRows,
  rows,
  cache,
  setCache,
  setToastMsg
}: ListContractsProps) {
  const [deleteLoader, setDeleteLoader] = useState(false);
  const { t } = useTranslation();

  const deleteAsync = async (element: any) => {
    element.loading = true;
    setDeleteLoader(true);
    let body = {
      filePath: element.name,
      metadata: {
        isdeleted: true
      },
      bucketName
    };
    const filteredCache = cache.filter((file) => file.name !== element.name);
    let response = await updateFileMetaData(body);
    let data: any = {},
      status: any = '';
    if (response) {
      data = response.data;
      status = response.status;
      if (status == HTTP_STATUS.HTTP_OK) {
        setCache(filteredCache);
        setRows(filteredCache);
        setToastMsg(`${t('T_DELETED_CONTRACT_SUCCESS_MSG')}`, true, 'success');
        setDeleteLoader(false);
        if (rows.length === 0) {
          setActiveTab(`${t(TABS.UPLOAD_CONTRACT)}`);
        } else {
          setActiveTab(`${t(TABS.CONTRACTS)}`);
        }
      } else {
        setToastMsg(`${data.issues ? data.issues[0]?.details : 'Failure'}`, true, 'error');
        setDeleteLoader(false);
        toggleVisiblity('');
      }
    }
    element.loading = false;
  };

  const showPdfData = async (pdfData: any) => {
    let payload = {
      filePath: pdfData?.name,
      expirationTime: 123,
      action: 'DOWNLOAD',
      bucketName: bucketName,
      generation: pdfData?.generation
    };
    setLoader(true);
    let response = await createSignedURL(payload);
    if (response.status === HTTP_STATUS.HTTP_CREATED) {
      setLoader(false);
      window.open(response?.data?.url, '_blank');
    } else {
      setLoader(false);
    }
  };
  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>([
    {
      key: 'contract',
      description: `${t('T_CONTRACT')}`,
      sortEnabled: false,
      width: '50%',
      clickable: true,
      sortingInformation: {
        order: ''
      },
      styles: {
        rowStyle: {
          color: '#127ADB'
        },
        notCursor: false
      },
      nastedData: true,
      render: (data: any) => {
        let shortName = data?.name.slice(10, data.name.length);

        if (shortName?.length > 50) {
          shortName = `${shortName.slice(0, 20)}...${shortName.slice(
            shortName.length - 9,
            shortName.length
          )}`;
        }
        return shortName; //+ ' ' + ' (' + data?.versions + ')';
      }
    },
    {
      key: 'versioning',
      description: `${t('T_VERSION')}`,
      sortEnabled: false,
      width: '15%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return `${data.versions?.toFixed(1)}`;
      },
      styles: {
        notCursor: true
      }
    },
    {
      key: 'uploadedby',
      description: `${t('T_UPLOAD_BY')}`,
      sortEnabled: false,
      width: '35%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return data?.metadata?.name;
      },
      styles: {
        notCursor: true
      }
    },
    {
      key: 'dateandtime',
      description: `${t('T_DATE_AND_TIME')}`,
      sortEnabled: false,
      width: '35%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return new Date(data?.createTime).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: 'numeric'
        });
      },
      styles: {
        notCursor: true
      }
    },
    {
      key: 'deletebutton',
      description: '',
      sortEnabled: false,
      width: '7%'
    }
  ]);
  const refreshTableData = () => {};

  return (
    <div>
      <TemplateTable
        tableData={rows}
        showDeleteButton={true}
        onRefreshTableData={refreshTableData}
        tableHeaders={tableHeaders}
        setTableHeaders={setTableHeaders}
        isSearchable={false}
        isPaginated={false}
        deleteOnClick={deleteAsync}
        isLoading={loader}
        tableBodyClass="overflow-y-scroll block h-[300px]"
        containerClass="mt-0 "
        size=""
        currentPage={''}
        templateSelector={''}
        cellSelector={showPdfData}
        deleteLoader={deleteLoader}
        theme={useTheme().theme}
      />
    </div>
  );
}

export default memo(ListContracts);
