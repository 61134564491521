// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import InputField from '../../../shared/Fields/InputField';

import { MenuConstants } from '../../../../constants/MenuConstants';

import DotMenu, { Options } from '../../../primitives/dotMenu/DotMenu';

import styled from 'styled-components';
import Checkbox from '../../../shared/Fields/Checkbox';
import checkCircle from '../../../../assets/checkCircle.svg';
import _, { isEmpty, reject, remove, find } from 'lodash';
import eye from '../../../../assets/eye.svg';

import { getQuestionTypeLabel } from '../../../../util/surveyUtils';
import { setToastData } from '../../../../app/toastReducer';
import { AppConstants } from '../../../../constants/AppConstants';
import { Label } from '../../../AccountProfile/ProfileStyles';
import PickList, { PICKLIST_TYPES } from '../../../shared/Fields/PickList';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import { asterisk } from '../../../../assets/components/svgs';
import BiButton from '../../../primitives/buttons/BiButton.primitive';
import SelectField from '../../../shared/Fields/SelectField';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { getQuestionTypes } from '../../Question/QueUtils';
import ConditionalBlock from './ConditionalBlock';

function ConditionalComponent({
  questionData,
  questionOption,
  currentIndex,
  setConditionalQuestion,
  conditionalQuestion,
  index,
  removeCondition,
  setConditionalToAddedQue,
  disabled,
  collection
}: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectQue, setSelectQue] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [selectOption, setSelectedOption] = useState<any[]>([]);
  const [operator, setOperator] = useState<any[]>([
    AppConstants.SURVEY_CONDITIONAL_DEFAULT_COMPARATOR
  ]);

  const [formData, setFormData] = useState({});

  let questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);

  const setQuestionTypes = async () => {
    let questionTypesObj: any;

    if (!isEmpty(questionTypes)) {
      questionTypesObj = questionTypes;
    } else {
      questionTypesObj = await getQuestionTypes(dispatch, metaDataObjectList);
    }
    questionTypes = questionTypesObj;
  };

  useEffect(() => {
    let temp = conditionalQuestion[index];
    const { question = null, selectedOption = null, operator = null } = temp || {};
    if (question && selectedOption) {
      const { displayType } = question;
      setSelectQue([
        {
          value: question.question || question.text,
          key: question.id,
          data: question,
          queType: displayType
        }
      ]);
      if (displayType === 'integer' || displayType === 'decimal') {
        setFormData({ conditionVal: selectedOption.value.toString() });
      } else {
        setSelectedOption([selectedOption]);
      }
      setOperator(operator);
    }

    const init = async () => {
      //await setQuestionTypes();
    };
    init();
  }, []);

  useEffect(() => {
    const [ques = null] = selectQue;
    let temp = [
      { value: 'Yes', key: 'Yes' },
      { value: 'No', key: 'No' }
    ];

    if (ques) {
      const { data } = ques;
      const { displayType } = data;

      if (displayType === 'boolean') {
        setOptions(AppConstants.SURVEY_CONDITONAL_BOOLEAN_VALUES);
      } else if (displayType === 'multi' || displayType === 'single') {
        setOptions(
          ques.data.answerOption.map((ele: any) => {
            return {
              value: typeof ele.value === 'string' ? ele.value : ele.value.display,
              key: typeof ele.value === 'string' ? ele.value : ele.value.display
            };
          })
        );
      }
      setSelectedOption([]);
      if (displayType !== 'integer' && displayType !== 'decimal') {
        let tempD = conditionalQuestion[index];
        const { question = null, selectedOption = null, comparator = null } = tempD || {};
        if (question && selectedOption) {
          setSelectedOption([selectedOption]);
        }
      }
    }
  }, [selectQue]);

  const setConditionalQuestionData = (option: any) => {
    const [que = null] = selectQue;
    const { enableWhen = [] } = questionData;
    if (que) {
      const { data, queType } = que;
      let temp = [...conditionalQuestion];
      if (queType === 'integer' || queType === 'decimal') {
        setConditionalQuestion({ question: data, selectedOption: option, operator: operator });
      } else {
        const [opt] = option;
        setConditionalQuestion({ question: data, selectedOption: opt, operator: operator });
        setSelectedOption(option);
      }
    }
  };

  useEffect(() => {
    if (conditionalQuestion && conditionalQuestion[0]) {
      questionData.enableWhen = conditionalQuestion;
      setConditionalToAddedQue(questionData);
    }
  }, [conditionalQuestion]);

  useEffect(() => {
    const [ques = null] = selectQue;
    if (ques) {
      const { data } = ques;
      const { displayType } = data;
      if (operator[0].value === 'exists') {
        setOptions(AppConstants.SURVEY_CONDITONAL_BOOLEAN_VALUES);
      } else if (displayType === 'multi' || displayType === 'single') {
        setOptions(
          ques.data.answerOption.map((ele: any) => {
            return {
              value: typeof ele.value === 'string' ? ele.value : ele.value.display,
              key: typeof ele.value === 'string' ? ele.value : ele.value.display
            };
          })
        );
      }
    }
  }, [operator]);

  const updateCondAnswer = () => {
    setSelectedOption([]);
    setFormData({ conditionVal: '' });
  };

  const getAnswerField = () => {
    const [que = null] = selectQue;
    const { queType = '' } = que || {};

    switch (queType) {
      case 'integer':
      case 'decimal':
        return (
          <InputField
            className="w-full"
            type="number"
            id="numericValue"
            disableLabel={true}
            label=""
            placeholder="Enter Value"
            fieldKey="conditionVal"
            required={false}
            formData={formData}
            handleChange={(e: any) => {
              setConditionalQuestionData(e.target.value);
              setFormData({ conditionVal: e.target.value });
            }}
            setFormData={setFormData}
            formError={{}}
            disabled={disabled || !selectQue.length}
          />
        );
        break;
      case 'multi':
      case 'single':
      case 'boolean':
        return (
          <PickList
            fieldKey={'key'}
            fieldValues={['value']}
            selectedItems={selectOption}
            onSelectedChange={(items: any) => {
              setConditionalQuestionData(items);
            }}
            items={options}
            disabled={disabled || !selectQue.length}
            readOnly={false}
            type={PICKLIST_TYPES.SINGLE}
          />
        );
        break;
      default:
        return (
          <InputField
            className="w-full"
            type="number"
            id="integerValue"
            disableLabel={true}
            label=""
            placeholder="Enter Value"
            fieldKey="conditionVal"
            required={false}
            formData={formData}
            setFormData={setFormData}
            formError={{}}
            disabled={true}
          />
        );
    }
  };

  return (
    <div className="flex w-full px-5 py-4">
      <div className="w-[50%]">
        <Label>
          {asterisk}
          {t('T_QUESTION')}
        </Label>
        <PickList
          fieldKey={'key'}
          fieldValues={['value']}
          selectedItems={selectQue}
          onSelectedChange={(items: any) => {
            updateCondAnswer();
            setSelectQue(items);
          }}
          items={questionOption || []}
          disabled={disabled}
          readOnly={false}
          type={PICKLIST_TYPES.SINGLE}
        />
      </div>

      <div className="mx-3 mt-auto w-[7%]">
        <PickList
          fieldKey={'key'}
          fieldValues={['value']}
          selectedItems={operator}
          onSelectedChange={(items: any) => {
            updateCondAnswer();
            setOperator(items);
          }}
          items={AppConstants.SURVEY_CONDITIONAL_COMPARATOR_LIST || []}
          disabled={disabled}
          readOnly={false}
          type={PICKLIST_TYPES.SINGLE}
        />
      </div>

      <div className="w-[36%]">
        <Label>
          {asterisk}
          {t('T_ANSWER')}
        </Label>
        {getAnswerField()}
      </div>

      <div className="mx-auto w-[7%]">
        <DeleteIcon
          className="mx-auto mt-9 h-6 w-6 text-gray-600 hover:text-black"
          onClick={() => {
            !disabled && removeCondition(index, conditionalQuestion);
          }}
          data-testid="delete-icon"
        />
      </div>
    </div>
  );
}

interface QuestionCardProps {
  questionData: any;
  collection: any;
  setQuestions: Function;
  contextMenuHandler: Function;
  currentIndex: number;
  disabled: boolean;
  containerClassName?: string;
  iconSize?: string;
  mode: number;
  groupIndex?: any;
  AddQuestionModal?: any;
  branch?: string;
  option?: any;
  setConditionalToAddedQue?: Function;
  disableCond?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-width: 0.5px;
  border-radius: 8px;
  border-color: #bfbfbf;
  width: 100%;
`;

const Block = styled.div`
  padding: 1.25rem;
  // border-color: #bfbfbf;
  // border-bottom-width: 0.5px;
`;

// const API_TO_QUESTION_MAPPING: any = {
//   string: 'Display'
// };

export const API_TO_QUESTION_MAPPING: any = {
  choice: 'multi',
  display: 'display',
  boolean: 'boolean',
  decimal: 'numeric',
  integer: 'numeric',
  date: 'date',
  time: 'time',
  dateTime: 'datetime',
  url: 'url',
  quantity: 'numeric',
  text: 'text'
};

const QuestionCard = ({
  questionData,
  collection,
  setQuestions,
  currentIndex,
  disabled,
  mode,
  contextMenuHandler,
  containerClassName = '',
  iconSize = '',
  groupIndex = '',
  AddQuestionModal = '',
  branch = '',
  option = '',
  setConditionalToAddedQue = () => {},
  disableCond = false
}: QuestionCardProps) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(true);
  const toggleClass = ' transform translate-x-5';
  const [selectedOption, setSelectedOption] = useState<Options | null>();
  let {
    text,
    type,
    id,
    branchingConditons = [],
    options,
    readOnly,
    question,
    displayType,
    extension,
    answerOption = []
  } = questionData;

  const dispatch = useAppDispatch();
  // const [questionOption, setQuestionOption] = useState<any[]>([]);
  // const [conditionalCheck, setConditionalCheck] = useState<boolean>(false);
  // const [questionAdded, setQuestionAdded] = useState<any>([]);

  // const [formData, setFormData] = useState<any>({
  //   operator: AppConstants.SURVEY_CONDITIONAL_DEFAULT_OPTION,
  //   conditionQue: [null]
  // });

  let questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);

  const setQuestionTypes = async () => {
    let questionTypesObj: any;

    if (!isEmpty(questionTypes)) {
      questionTypesObj = questionTypes;
    } else {
      questionTypesObj = await getQuestionTypes(dispatch, metaDataObjectList);
    }
    questionTypes = questionTypesObj;
  };

  useEffect(() => {
    const init = async () => {
      await setQuestionTypes();
    };
    const { enableWhen = [], enableBehavior = '' } = questionData || {};

    // if (enableWhen.length) {
    //   setFormData({
    //     ...formData,
    //     operator: enableBehavior ? enableBehavior.toUpperCase() : '',
    //     conditionQue: enableWhen
    //   });
    //   if (enableWhen[0]) {
    //     setConditionalCheck(true);
    //   }
    // }
  }, []);

  //const [formErrorData, setFormErrorData] = useState<any>({ operator: '' });

  let dotMenuOptions: Array<any> = [
    {
      display: `${t('T_REMOVE_QUESTION')}`,
      key: MenuConstants.MENU_REMOVE_QUESTION
    }
  ];

  if (type !== 'display') {
    dotMenuOptions.unshift({
      display: readOnly ? `${t('T_REMOVE_READ_ONLY')}` : `${t('T_MARK_AS_READ_ONLY')}`,
      key: readOnly ? MenuConstants.MENU_REMOVE_READ_ONLY : MenuConstants.MENU_MARK_AS_READ_ONLY
    });
  }

  //if (!mode) {
  dotMenuOptions.unshift({
    display: `${t('T_EDIT_QUESTION_ANSWERS')}`,
    key: MenuConstants.MENU_EDIT_QUESTION_ANSWERS
  });
  //}

  const toggleRequired = () => {
    if (!disabled) {
      let clone = [...collection];
      clone[currentIndex] = { ...clone[currentIndex], required: !clone[currentIndex].required };
      setQuestions(clone);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      contextMenuHandler(id, selectedOption, option);
    }
  }, [selectedOption]);

  // useEffect(() => {
  //   let temp: any[] = [];
  //   collection.forEach((que: any, index: number) => {
  //     const { displayType, text, question } = que;
  //     const allowedTypes = AppConstants.SURVEY_CONDITIONAL_ALLOWED_QUE_TYPES;
  //     if (allowedTypes.includes(displayType)) {
  //       if (index < currentIndex) {
  //         temp.push({ value: question || text, data: que, key: que.id, queType: displayType });
  //       }
  //     }
  //   });
  //   setQuestionOption(temp);
  // }, [collection]);

  // const removeCondition = (indexData: number, data: any[]) => {
  //   const arr = data || [];
  //   let rejectArr = [...arr];
  //   _.remove(rejectArr, (n, i) => {
  //     return i === indexData;
  //   });
  //   setFormData({ ...formData, conditionQue: rejectArr });
  //   questionData.enableWhen = rejectArr;
  //   setQuestionAdded([]);
  // };

  // const addConditionQuestion = (index: number, data: any) => {
  //   const temp = [...formData.conditionQue];
  //   if (index <= temp.length - 1) {
  //     temp[index] = data;
  //   } else if (data === null) {
  //     temp.push(null);
  //   }
  //   setFormData({ ...formData, conditionQue: temp });
  // };

  // useEffect(() => {
  //   const { conditionQue, operator } = formData;
  //   setQuestionAdded(conditionQue);
  //   questionData.enableBehavior = operator.toLowerCase();
  //   setConditionalToAddedQue(questionData);
  // }, [formData]);

  return (
    <>
      <Container>
        <div className="flex w-full flex-row justify-between px-5 py-3">
          <div className="flex w-3/5 flex-col">
            <div className="text-sm capitalize" style={{ color: '#8C8C8C' }}>
              {getQuestionTypeLabel(
                questionData.apiType,
                questionData.displayType,
                questionTypes,
                questionData.type
              )}
            </div>
            <div className="mt-1 break-all text-base font-normal">
              <span className="mr-2 text-lg font-bold text-[#003A70]">
                {groupIndex && groupIndex + '.'}
                {currentIndex + 1}
              </span>
              {question || text}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center">
            <div className="relative flex flex-row items-center justify-center">
              {(questionData.answerOption.some((ele: any) => ele.initialSelected) ||
                questionData?.initial?.length > 0) && (
                <img
                  src={checkCircle}
                  className="mr-2 w-4"
                  title="This question has default a answer choice."
                />
              )}
              {readOnly && <img src={eye} className="mr-2 w-5" title={t('T_READ_ONLY_QUESTION')} />}
              <div className="mr-2 text-sm font-normal" style={{ color: '#595959' }}>
                Required
              </div>
              <div
                className={`flex h-7 w-12 cursor-pointer items-center rounded-full  p-1 ${
                  disabled && 'cursor-not-allowed bg-transparent'
                }`}
                onClick={() => {
                  !readOnly && toggleRequired();
                }}
                style={{ backgroundColor: questionData.required ? '#0074E0' : '#BFBFBF' }}
              >
                <div
                  className={
                    'h-5 w-5 transform rounded-full bg-white shadow-md duration-300 ease-in-out' +
                    (questionData.required ? toggleClass : null)
                  }
                ></div>
              </div>
              {/* <div className="ml-6 mr-5 h-5 cursor-pointer">
                <img src={ThreeDot} alt={'three-dot'} />
              </div> */}
              {!disabled ? (
                <DotMenu
                  options={dotMenuOptions}
                  onSelectedOptionChange={(value) => {
                    setSelectedOption(value);
                  }}
                  value={selectedOption}
                  containerClassName={containerClassName}
                  iconSize={iconSize}
                  disabled={disabled}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {!disableCond && (
          <ConditionalBlock
            questionData={questionData}
            collection={collection}
            currentIndex={currentIndex}
            setQuestions={setQuestions}
            disabled={disabled}
            mode={mode}
            contextMenuHandler={contextMenuHandler}
            AddQuestionModal={AddQuestionModal}
            setConditionalToAddedQue={setConditionalToAddedQue}
          ></ConditionalBlock>
        )}
      </Container>
    </>
  );
};

export default QuestionCard;
