import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { useNavigate } from 'react-router-dom';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { AppConstants } from '../../constants/AppConstants';
import { RouteConstants } from '../../constants/RouteConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
// import { setBucket } from '../../app/consentReducer';
import moment from 'moment';
import { debounce } from 'lodash';
import { getBucketById, getListOfBuckets } from '../../http/asset-management-services';
import BiButton from '../primitives/buttons/BiButton.primitive';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import AddCircle from '../../assets/addCircle.svg';
import { setBucketData, setFolderData } from '../../app/bucketReducer';
import { MenuOptions } from './ContextMenu';
import { getBucketRelativeName, hasBucketAccess } from './BucketUtil';
import { EllipsisDiv } from '../../styles/globalStyles';
import TemplateTable from '../shared/tables/TemplateTable';
import { setTableOptions } from '../../app/templateTableReducer';

/**
 * @parentId file-management
 * @manager Bucket Listing
 * @overview Lists Buckets within a tenant
 *   <section>
 *       <p>
 *           Bucket listing page lists all the buckets in the targetted tenant. User can navigate to Bucket listing page by clicking <b>File Upload</b> navigation menuitem under the <strong>Platform Core</strong> menu group of admin portal left sidebar.
 *           By Default bucket listing page will display all the buckets in flat table hireachry with pagination support & buckets (name) are sorted by created-on field timestamp in descending order. The Default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer.
 *           Datagrid footer will also display the current page and total number of pages available based on the choosen page size & buckets data. User can switch to next/previous pages using the <b>Next</b> & <b>Previous</b> buttons enabled based on the content in the data grid footer.
 *       </p>
 *       <br>
 *       <p>
 *           Buckets(s) can be filtered based on the bucket name using the search field provided above the Bucket listing data grid. Admin user(s) can create new Bucket by clicking on <b>New Bucket</b> button provided on the admin portal toolbar,
 *           which will navigate user to Bucket creation page. <b>New Bucket</b> button will be shown based on logged in user role API permissions as described below.
 *       </p>
 *       <br>
 *       <p>
 *           Datagrid will display details of each bucket as row item with each column representing below object property.
 *           <br>
 *           <ul>
 *               <li>Status</li>
 *               <li>Name</li>
 *               <li>Description</li>
 *               <li>Allow Versioning</li>
 *               <li>Created On</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *       </p>
 *       <p>
 *           Sorting of table grid is only supported on <i>Status</i>, <i>Name</i>, <i>Description</i>, <i>Created on</i>, <i>Last Modified On</i> column.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the List Bucket Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>AMS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>AMS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of bucket listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for bucket listing</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Asset Management Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for bucket listing page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/bucket</td>
 *               <td>GET</td>
 *               <td>asset-management-service.bucket.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/bucket</td>
 *               <td>POST</td>
 *               <td>asset-management-service.bucket.create</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>/bucket/listfiles</td>
 *               <td>POST</td>
 *               <td>asset-management-service.bucket.files</td>
 *               <td>No</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *   <section>
 *    <p>Sequence Diagram for Bucket listing page</p>
 *   </section>
 *   </section>
 */

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const BucketList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [page, setPage] = useState(AppConstants.DEFAULT_PAGE);
  const [size, setSize] = useState(AppConstants.DEFAULT_PAGE_SIZE);
  const [pagingOpts, setPagingOpts] = useState<any>(null);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);
  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);

  const BucketSelector = (data: any, step: number) => {
    const { roles = [] } = userProfile || {};
    const [userRole] = roles;

    if (
      userProfile &&
      roleAPIPermissions[AppConstants.PERMISSION_AMS_LIST_FILES] &&
      hasBucketAccess(data, userProfile?.userId, userRole?.roleId) &&
      data?.active
    ) {
      fetchBucketNavigate(data?.id);
    }
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sortOrder: string = AppConstants.DEFAULT_AMS_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setIsLoading(true);
    setTableValues(name, page, size, sortOrder, sortType);
  };

  const debouncedCall = useRef(
    debounce(async (name, page, size, sortOrder, sortType) => {
      fetchBuckets(name, page, size, sortOrder, sortType);
    }, 400)
  ).current;

  const setTableValues = (
    name: string = '',
    page: number,
    size: number,
    sortOrder: string = AppConstants.DEFAULT_AMS_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    debouncedCall(name, page, size, sortOrder, sortType);
  };

  const pagingRef: any = useRef();

  const setPagingRef = (paging: any) => {
    pagingRef.current = paging;
  };

  const fetchBuckets = async (
    name: string = '',
    page: number = AppConstants.DEFAULT_PAGE,
    size: number = AppConstants.DEFAULT_PAGE_SIZE,
    sortOrder: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    let response = await getListOfBuckets(name, page, size, [`${sortOrder},${sortType}`]);
    if (response?.status === HTTP_STATUS.HTTP_OK) {
      const { data } = response;
      const { data: bucketData = [] } = data;

      if (bucketData.length) {
        setRows(bucketData);
      } else {
        setRows(`${t('T_NO_DATA_AVAILABLE_MSG')}`);
      }
      setPagingRef(data?.paging);
      setIsLoading(false);
    } else {
      let error = getFailureMessage(response);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const {
      tableFilters = '',
      searchText = '',
      currentPage = AppConstants.DEFAULT_PAGE,
      size = AppConstants.DEFAULT_PAGE_SIZE,
      sortName = AppConstants.DEFAULT_SORT_BY,
      sortType = AppConstants.DEFAULT_SORT_DESC
    } = globalFilters['bucket-list'] || {};
    
    fetchBuckets(searchText, currentPage, size, sortName, sortType);
    dispatch(
      setTableOptions({
        ['bucket-list']: {
          searchText: searchText,
          currentPage,
          size,
          sortName: sortName,
          sortType: sortType,
          tableFilters: tableFilters
        }
      })
    );
    dispatch(setFolderData({}));
    dispatch(
      setURLPaths([
        {
          key: RouteConstants.ROUTE_AMS_FILES,
          label: t('T_BUCKET')
        }
      ])
    );
  }, []);

  const fetchBucketNavigate = async (id: string) => {
    setIsLoading(true);
    const response = await getBucketById(id);
    const { data, status } = response;
    if (HTTP_STATUS.isSuccess(status)) {
      dispatch(setBucketData(data || {}));
      navigate(
        RouteConstants.ROUTE_AMS_FOLDER_LIST.replace(':id', data.id).replace(
          ':folderId',
          ':folders'
        )
      );
    } else {
      let error = getFailureMessage(response);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
      setIsLoading(false);
    }
  };

  const getMenuOptions = (data: any): Array<any> => {
    let optionList: any = [];
    const { roles = [] } = userProfile || {};
    const [userRole] = roles;

    if (
      userProfile &&
      roleAPIPermissions[AppConstants.PERMISSION_AMS_GET_BUCKET] &&
      hasBucketAccess(data, userProfile?.userId, userRole?.roleId)
    ) {
      optionList.push({
        display: t('T_BUCKET_DETAILS'),
        action: () => {
          navigate(`${RouteConstants.ROUTE_AMS_FILE_DETAILS.replace(':id', data.id)}`);
        }
      });
    }
    return optionList;
  };

  const BucketHeaders: Array<ITableHeader> = [
    {
      key: 'active',
      description: 'T_STATUS',
      sortEnabled: true,
      width: '8%',
      clickable: true,
      render: (data: any) => {
        return data.active ? 'Active' : 'Not Active';
      },
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'name',
      description: 'T_NAME',
      sortEnabled: true,
      clickable: true,
      width: '26%',
      sortingInformation: {
        order: ''
      },
      render: (data: any) => {
        const name: string = getBucketRelativeName(data?.name);
        return <EllipsisDiv title={name}>{name}</EllipsisDiv>;
      }
    },
    {
      key: 'name',
      description: '',
      sortEnabled: false,
      width: '5%',
      nastedData: true,
      clickable: false,
      render: (data: any) => {
        const menuOptions: Array<any> = getMenuOptions(data);
        return menuOptions.length ? (
          <MenuOptions options={menuOptions} folderData={data} />
        ) : (
          <div />
        );
      },
      sortingInformation: {
        order: ''
      },
      styles: {
        paddingRight: '0',
        paddingLeft: '0',
        borderLeft: 'none',
        rowStyle: { fontWeight: 'normal', padding: '0px' }
      }
    },
    {
      key: 'description',
      description: 'T_DESCRIPTION',
      sortEnabled: true,
      clickable: true,
      width: '26%',
      sortingInformation: {
        order: ''
      },

      render: (data: any) => {
        return (
          <EllipsisDiv className="w-full flex-1" title={data?.description}>
            {data?.description}
          </EllipsisDiv>
        );
      }
    },
    {
      key: 'versioning',
      description: 'T_ALLOW_VERSIONING',
      sortEnabled: false,
      clickable: true,
      width: '10%',
      render: (data: any) => {
        return data.versioning ? 'Yes' : 'No';
      },
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'createdOn',
      description: 'T_CREATED_ON',
      sortEnabled: true,
      clickable: true,
      width: '15%',
      render: (data: any) => {
        return data.createdOn
          ? moment(data.createdOn).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '';
      },
      sortingInformation: {
        order: 'desc'
      }
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: false,
    //   width: '15%',
    //   clickable: true,
    //   sortingInformation: {
    //     order: ''
    //   }
    // },
    {
      key: 'updatedOn',
      description: 'T_LAST_MODIFIED_ON',
      sortEnabled: true,
      width: '15%',
      clickable: true,
      render: (data: any) => {
        return data.updatedOn
          ? moment(data.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '';
      },
      sortingInformation: {
        order: ''
      }
    }
  ];

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(BucketHeaders);

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <MenuButtonsPortal>
          {roleAPIPermissions[AppConstants.PERMISSION_AMS_CREATE_BUCKET] && (
            <BiButton
              className={'flex flex-row bg-primary text-white'}
              type="button"
              onClick={() => {
                navigate(RouteConstants.ROUTE_AMS_FILES_CREATE_NEW_BUCKET);
              }}
            >
              <img src={AddCircle} className="mr-2 w-6 text-center"></img>
              {t('T_NEW_BUCKET')}
            </BiButton>
          )}
        </MenuButtonsPortal>
        <CustomersTableContainer style={{ height: 'inherit' }}>
          <TemplateTable
            tableData={rows}
            onRefreshTableData={refreshTableData}
            size={globalFilters?.size?.toString()}
            currentPage={globalFilters?.currentPage}
            setCurrentPage={(page: any) => {
              setCurrentActivePage(parseInt(page));
            }}
            cellSelector={BucketSelector}
            templateSelector={BucketSelector}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            pagingData={pagingRef.current}
            containerClass="mt-0"
            isSearchable={true}
            showDeleteButton={true}
            theme={useTheme().theme}
            tablePage="bucket-list"
            isLocalPaginator={false}
            totalDataLength={rows.length}
            isPaginated={true}
            disablePageSize={false}
            globalFilters={globalFilters}
            searchPlaceholder={t('T_SEARCH_NAME_BUCKET_NAME') || ''}
          />
        </CustomersTableContainer>
      </LoaderContainer>
    </>
  );
};

export default BucketList;
