import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Checkbox from '../../../shared/Fields/Checkbox';
import InputField from '../../../shared/Fields/InputField';

interface FreeTextProps {
  formData: any;
  setFormData: any;
  formError: any;
  editMode?: boolean;
}

const FreeText = ({ formData, setFormData, formError, editMode }: FreeTextProps) => {
  const params = useParams();
  const [checkboxSelected, setCheckboxSelected] = useState({
    option1: false,
    option2: false
  });

  useEffect(() => {
    if (editMode && params.id) {
      let tempData: any = {};
      Object.keys(formData?.answerOptions).forEach((options: any, index: any) => {
        tempData = {
          ...tempData,
          [`option${index + 1}`]: formData.answerOptions[options].isSelected
        };
      });
      setCheckboxSelected(tempData);
    }
  }, [formData]);

  return (
    <>
      <div className="flex w-full flex-row">
        <div className="flex flex-row items-center">
          <Checkbox
            checked={checkboxSelected.option1}
            onChange={() => {
              const value = !checkboxSelected.option1;
              setCheckboxSelected({ ...checkboxSelected, option1: value });
              setFormData({
                ...formData,
                answerOptions: {
                  ...formData?.answerOptions,
                  option1: {
                    ...formData?.answerOptions?.option1,
                    isSelected: value
                  }
                }
              });
            }}
            disabled={editMode}
          />
          <div className="ml-2  flex flex-row items-center text-base font-normal">
            Set minimum answer length to
            <InputField
              className="mx-2 my-1 w-[9ch]"
              type="text"
              id="min-word-count"
              label=""
              placeholder="Enter #"
              fieldKey="option1"
              required={false}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              handleChange={(e: any) => {
                setFormData({
                  ...formData,
                  option1: e.target.value,
                  answerOptions: {
                    ...formData?.answerOptions,
                    option1: {
                      ...formData?.answerOptions?.option1,
                      value: e.target.value
                    }
                  }
                });
              }}
              disabled={editMode}
            />
            words
          </div>
        </div>
      </div>
      <div className="flex w-full flex-row">
        <div className="flex flex-row items-center">
          <Checkbox
            checked={checkboxSelected.option2}
            onChange={() => {
              const value = !checkboxSelected.option2;
              setCheckboxSelected({ ...checkboxSelected, option2: value });
              setFormData({
                ...formData,
                answerOptions: {
                  ...formData?.answerOptions,
                  option2: {
                    ...formData?.answerOptions?.option2,
                    isSelected: value
                  }
                }
              });
            }}
            disabled={editMode}
          />
          <div className="ml-2 flex flex-row items-center text-base font-normal">
            Set maximum answer length to{' '}
            <InputField
              className="mx-2 my-1 w-[9ch]"
              type="text"
              id="max-word-count"
              label=""
              placeholder="Enter #"
              fieldKey="option2"
              required={false}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              handleChange={(e: any) => {
                setFormData({
                  ...formData,
                  option2: e.target.value,
                  answerOptions: {
                    ...formData?.answerOptions,
                    option2: {
                      ...formData?.answerOptions?.option2,
                      value: e.target.value
                    }
                  }
                });
              }}
              disabled={editMode}
            />
            words
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeText;
