import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getApplication } from '../../http/metadata-service';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setApplication } from '../../app/metadataReducer';
import EditIcon from '@heroicons/react/outline/PencilIcon';
import PlusIcon from '@heroicons/react/outline/PlusIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import AttributeTreeNode from './AttributeTreeNode';
import {
  OverlayMessageDiv,
  colorBackgroundAlert3,
  colorForLightGrayBackground
} from '../../styles/globalVariables';
import { EllipsisDiv } from '../../styles/globalStyles';

interface AddNodeActionProps {
  onClick: Function;
}

export const AddNodeAction: React.FC<AddNodeActionProps> = ({ onClick }: AddNodeActionProps) => (
  <>
    <div className=" ml-1 mr-1 flex flex-row items-center justify-center rounded border-[1px] border-solid border-[#99B0C6]">
      <PlusIcon
        className="m-1 h-4 w-4 text-gray-600 hover:text-black"
        onClick={() => onClick()}
        data-testid="delete-icon"
      />
    </div>
  </>
);

interface EditNodeActionProps {
  onClick: Function;
}

export const EditNodeAction: React.FC<EditNodeActionProps> = ({ onClick }: EditNodeActionProps) => (
  <>
    <div className="ml-1 mr-1 flex flex-row items-center justify-center rounded border-[1px] border-solid border-[#99B0C6]">
      <EditIcon
        className="m-1 h-4 w-4 text-gray-600 hover:text-black"
        onClick={() => onClick()}
        data-testid="delete-icon"
      />
    </div>
  </>
);

interface DeleteNodeActionProps {
  onClick: Function;
  disabled?: Boolean;
  disabledMessage?: String;
}

export const DeleteNodeAction: React.FC<DeleteNodeActionProps> = ({
  onClick,
  disabled = false,
  disabledMessage = ''
}: DeleteNodeActionProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if (disabled) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        className={`ml-1 mr-1 flex flex-row items-center justify-center rounded border-[1px] border-solid ${
          disabled ? 'cursor-not-allowed border-[#99B0C6]' : 'border-[#99B0C6] hover:border-black'
        }`}
      >
        <TrashIcon
          className={`m-1 h-4 w-4 text-gray-600 ${disabled ? 'opacity-50' : 'hover:text-black'}`}
          onClick={() => !disabled && onClick()}
          data-testid="delete-icon"
        />
      </div>
      {showTooltip && <OverlayMessageDiv>{disabledMessage}</OverlayMessageDiv>}
    </div>
  );
};
interface AttributeHeaderProps {
  onClick: Function;
  t: Function;
  edit: Boolean;
}

const AttributeHeaders: React.FC<AttributeHeaderProps> = ({ t, edit }: AttributeHeaderProps) => (
  <>
    <div className="flex-start m-1 flex h-auto min-h-[2rem] flex-row flex-wrap items-center bg-[#F5F5F5] font-medium ">
      <EllipsisDiv className={`w-${30}} flex-4 m-1 flex flex-col`} style={{ width: `${30}rem` }}>
        {t('T_NAME')}
      </EllipsisDiv>
      <EllipsisDiv className={`w-${12}} flex-2 m-1 flex flex-row`} style={{ width: `${12}rem` }}>
        {t('T_TYPE')}
      </EllipsisDiv>
      <EllipsisDiv className="m-1 flex flex-1 flex-row">{t('T_REQUIRED')}</EllipsisDiv>
      <EllipsisDiv className="m-1 flex flex-1 flex-row">{t('T_ALLOW_EDITS')}</EllipsisDiv>
      <EllipsisDiv className="m-1 flex flex-1 flex-row">{t('T_IS_SEARCHABLE')}</EllipsisDiv>
      <EllipsisDiv className="m-1 flex flex-1 flex-row">{t('T_IS_SORTABLE')}</EllipsisDiv>
      <EllipsisDiv className=" m-1 flex flex-1 flex-row flex-wrap justify-end">
        {t('T_HASH_OF_CHARATERS')}
      </EllipsisDiv>
      <EllipsisDiv className="m-1 flex flex-1 flex-row flex-wrap justify-end">
        {t('T_MIN_HASH')}
      </EllipsisDiv>
      <EllipsisDiv className="m-1 flex flex-1 flex-row flex-wrap justify-end">
        {t('T_MAX_HASH')}
      </EllipsisDiv>
      {edit && (
        <EllipsisDiv className="m-1 flex flex-1 flex-row flex-wrap justify-end">
          {t('T_ACTIONS')}
        </EllipsisDiv>
      )}
    </div>
  </>
);
interface AttributeTreeProps {
  edit?: boolean;
  readOnly: boolean;
  attributes: Array<any>;
  hasInstances?: boolean;

  onAddNode: Function;
  onRemoveNode: Function;
  onValueChange: Function;
  expandTreeNode: Function;
  onDataTypeChange: Function;
}

const AttributeTree: React.FC<AttributeTreeProps> = ({
  edit = false,
  readOnly = false,
  hasInstances = false,

  onAddNode,
  onRemoveNode,
  onValueChange,
  expandTreeNode,
  onDataTypeChange,
  attributes = new Array<any>()
}: AttributeTreeProps) => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const application = useAppSelector((state) => state.metadata.application);

  useEffect(() => {
    if (params.id) {
      getMetadataApplication(params.id);
    } else {
      dispatch(setURLPaths([{ key: '/metadata/new', label: 'Metadata New' }]));
    }
  }, []);

  const getMetadataApplication = async (id: string) => {
    if (!application) {
      setIsLoading(true);
      const response = await getApplication(id);
      const { status, data } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        dispatch(setApplication(data));
      }
      setIsLoading(false);
    }
  };

  const result: boolean = true;

  return (
    <LoaderContainer isLoading={isLoading}>
      <div>
        <AttributeHeaders t={t} edit={!readOnly} onClick={() => {}} />
        {attributes.map((attribute: any, index: number) => {
          return (
            <AttributeTreeNode
              level={0}
              order={index}
              readOnly={readOnly}
              key={Math.random()}
              attribute={attribute}
              onAddNode={onAddNode}
              hasInstances={hasInstances}
              onRemoveNode={onRemoveNode}
              onValueChange={onValueChange}
              expandTreeNode={expandTreeNode}
              onDataTypeChange={onDataTypeChange}
            />
          );
        })}
      </div>
    </LoaderContainer>
  );
};

export default React.memo(AttributeTree);
