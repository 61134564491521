import React, { useEffect, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { MenuConstants } from '../../../../constants/MenuConstants';

import { imgAltIcon, multiDropdown, multiDropdownUp } from '../../../../assets/components/svgs';
import ReactDomServer from 'react-dom/server';
import LoaderContainer from '../../../shared/loaderContainer/LoaderContainer';
import { clone, filter, find, isEmpty, remove, uniq, isEqual } from 'lodash';
import styled from 'styled-components/macro';
import InputField from '../../../shared/Fields/InputField';
import BiButton from '../../../primitives/buttons/BiButton.primitive';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../../../constants/AppConstants';
import { getGlobalBucketName } from '../../../../util/AppSetting';
import { removeArrayElement } from '../../../../util/consent-utils';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import Dropzone from '../../../shared/DragAndDrop/Dropzone';
import { useDispatch } from 'react-redux';
import { setToastData } from '../../../../app/toastReducer';
import Modal from '../../../shared/ModalPortal/Modal';
import bodyMapIcon from '../../../../assets/bodymap-icon.svg';
import Checkbox from '../../../shared/Fields/Checkbox';
import {
  createSignedURL,
  downloadWithRedirection
} from '../../../../http/asset-management-services';
import { HTTP_STATUS } from '../../../../http/constants/http.status';
import { getFailureMessage } from '../../../../util/ErrorUtil';

import {
  extractBodyMapSvg,
  updatebodyMapSvgTags,
  SVGMap,
  downLoadImages,
  getFileNameWithTimeStamp,
  getFileNameWithoutTimeStamp
} from '../QueUtils';
import ConfigureBodyMap from './ConfigureBodyMap';
import { DropDownList } from '../../../shared/Fields/SelectField';

import { DropDownContainer, RemoveDefaultButton } from './MultipleSelection';
import BodyMapConfigKeyMenu from './BodyMapConfigKeyMenu';
import { useDidMountEffect } from '../../../../util/customHooks';

const BodyMapQuestion = ({
  formData,
  formError,
  setFormData,
  editMode,
  editFromSurveyTemplate = false
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [fileData, setFileData] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedItemIndex, setSelecteItemIndex] = useState<any>('');
  const [svgImgPaths, setSvgImgPath] = useState<any>({});
  const [selectedSvgImgPath, setSelectedSvgImgPath] = useState<any>();
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [displayType, setDisplayType] = useState('bodyMapSingle');
  const [bodyMapImageLabel, setBodyMapImageLabel] = useState<any>({});
  const [initialBodyMapImageLabel, setInitialBodyMapImageLabel] = useState<any>({});
  const [isOpen, setIsOpen] = useState<any>({ option1: false, option2: false });
  const [checkValue, setCheckValue] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [toDeleteData, setToDeleteData] = useState<any>({});
  const [svgAttributes, setSvgAttributes] = useState({});

  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    updateSelectedChoicesType(checkValue);
  }, [checkValue]);

  useDidMountEffect(() => {
    if (!selectedItem) {
      const selectedData = getSelectedLabels();
      // setSelectedChoices(selectedData);
      const pathObj = svgImgPaths[selectedItemIndex];
      updatePathObj(pathObj, '', '', false, selectedData);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (fileData.length) {
      let temp = fileData;
      temp = [...selectedImages, ...fileData];
      //processSvgImg(fileData[0].img, selectedImages.length, fileData[0]);
      fileData.forEach((tempFile: any, index: any) => {
        processSvgImg(tempFile.img, selectedImages.length, tempFile);
      });
      setSelectedImages(temp);
    }
  }, [fileData]);

  useOutsideClick(
    dropdownRef,
    () => {
      let tempIsOpen: any = {};
      Object.keys(isOpen).forEach((option: any) => {
        if (isOpen[option]) {
          setIsOpen({ ...isOpen, [option]: !isOpen[option] });
        }
      });

      // setIsOpen(tempIsOpen);
    },
    false
  );

  const getSelectedLabels = () => {
    const clonedBodyMapImageLabel = bodyMapImageLabel;
    let selectedLabels: any = {};
    for (let k in clonedBodyMapImageLabel) {
      if (clonedBodyMapImageLabel[k].imageAnswerOptions) {
        clonedBodyMapImageLabel[k].imageAnswerOptions.forEach((imgAnsOpt: any) => {
          selectedLabels[imgAnsOpt.id] = imgAnsOpt.label;
        });
      }
    }
    return selectedLabels;
  };

  const getSelectedObjAnswerOptions = () => {
    let answerOptions: any = [];
    const clonedBodyMapImageLabel = clone(bodyMapImageLabel);
    for (let k in clonedBodyMapImageLabel) {
      if (clonedBodyMapImageLabel[k].imageAnswerOptions) {
        answerOptions = [...clonedBodyMapImageLabel[k].imageAnswerOptions, ...answerOptions];
      }
    }

    return answerOptions;
  };

  const getFileUploadObj = () => {
    if (!isEqual(bodyMapImageLabel, initialBodyMapImageLabel)) {
      const { fileUploadObj: imgForUpload, fileObjNames } = getSvgImgForUpload();
      return imgForUpload;
    }
    return {};
  };

  useEffect(() => {
    setFormData({
      ...formData,
      answerOptions: getSelectedObjAnswerOptions(),
      fileUploadObj: getFileUploadObj(),
      displayType,
      extension: {
        path: `${AppConstants.SURVEY_MEDIA_PATH}${formData.type}/`,
        bodyMapImageLabel: bodyMapImageLabel,
        displayType,
        permission: 'public',
        bucketName: getGlobalBucketName()
      }
    });
  }, [bodyMapImageLabel, displayType]);

  useEffect(() => {
    updateExtensionData(formData);
    setIsLoading(false);
  }, []);

  const removeSelectedImage = (item: any, imageIndex: any) => {
    const updatebodyMapSvgTags: any = {};
    const updatedSelectedOptions: any = [];
    let arr = removeArrayElement(item, [...selectedImages]);
    setSelectedImages(arr);
    const clonedImagesLabel = clone(bodyMapImageLabel);
    delete clonedImagesLabel[imageIndex];
    Object.values(clonedImagesLabel).forEach((imgObj: any, index: any) => {
      updatebodyMapSvgTags[index] = imgObj;
    });
    setBodyMapImageLabel(updatebodyMapSvgTags);
    const clonedSvgImgPath = clone(svgImgPaths);
    delete clonedSvgImgPath[item.fileNameWithTime];
    setSvgImgPath(clonedSvgImgPath);
    setDeleteConfirmation(false);
  };

  const AllowMoreOptionChange = (item: any, allow: boolean) => {
    let arr = [...selectedImages];
    let i = arr.indexOf(item);
    if (i > -1) {
      arr[i].allowMoreOption = allow;
    }
    setSelectedImages(arr);
  };

  const getIsPathSelected = (pathObj: any, fileName: any) => {
    let isInitialSelected = false;
    const { extension } = formData;
    const { bodyMapImageLabel } = extension;
    Object.keys(bodyMapImageLabel).forEach((imgObj: any) => {
      if (bodyMapImageLabel[imgObj].imageName === fileName) {
        bodyMapImageLabel[imgObj].imageAnswerOptions.forEach((imgLabelObj: any) => {
          if (imgLabelObj.label === pathObj.label && imgLabelObj.isInitialSelected) {
            isInitialSelected = true;
          }
        });
      }
    });

    return isInitialSelected;
  };

  const updateImgAnswerOptions = (selectedPaths: any, fileName: any) => {
    let imageObjIndex = 0;
    const { extension } = formData;
    const { bodyMapImageLabel } = extension;
    Object.keys(bodyMapImageLabel).forEach((imgObj: any) => {
      if (bodyMapImageLabel[imgObj].imageName === fileName) {
        imageObjIndex = imgObj;
      }
    });
    setBodyMapImageLabel({
      ...bodyMapImageLabel,
      [imageObjIndex]: {
        ...bodyMapImageLabel[imageObjIndex],
        imageAnswerOptions: selectedPaths
      }
    });
  };

  const processSvgImg = (imageData: any, imageIndex: any, fileData: any) => {
    const init = async () => {
      return await extractBodyMapSvg(imageData);
    };
    init().then((data: any) => {
      let imageIndex: any = '';
      const fileName = fileData.fileNameWithTime;
      const svgPathTagsObj = updatebodyMapSvgTags(data.svgPathTags, true, `${fileName}`);
      const selectedPathTags: any = [];
      svgPathTagsObj.forEach((pathObj: any) => {
        if (pathObj.label) {
          pathObj.value = pathObj.label;
          pathObj.imgName = fileName;
          pathObj.isInitialSelected = getIsPathSelected(pathObj, fileName);
          selectedPathTags.push(pathObj);
        }
      });
      //setSelectedOptions((prevState: any) => [...prevState, ...selectedPathTags]);
      Object.keys(bodyMapImageLabel).forEach((imgObj: any) => {
        if (bodyMapImageLabel[imgObj].imageName === fileData.key) {
          imageIndex = imgObj;
        }
      });
      if (imageIndex) {
        setBodyMapImageLabel({
          ...bodyMapImageLabel,
          [imageIndex]: {
            ...bodyMapImageLabel[imageIndex],
            imageName: fileName,
            imageAnswerOptions: bodyMapImageLabel[imageIndex].imageAnswerOptions?.map(
              (pathObj: any) => {
                return { ...pathObj, imgName: fileName };
              }
            )
          }
        });
      }
      setSvgAttributes(data.svgAttributes);
      setSvgImgPath((prev: any) => ({ ...prev, [`${fileName}`]: svgPathTagsObj }));
      setIsLoading(false);
    });
  };

  const updatePathObj = (
    pathObj: any,
    pathId: any,
    label: any = '',
    updateLabel: any = false,
    formData: any
  ) => {
    const itemIndex = selectedItemIndex || 0;

    const updatedPathObj: any = [];
    pathObj.forEach((pathItem: any) => {
      if (pathItem.id === pathId) {
        pathItem.isChecked = !pathItem.isChecked;
        pathItem.label = updateLabel ? label : pathItem.label;
        updatedPathObj.push(pathItem);
      } else {
        if (formData && !formData[pathItem.id]) {
          pathItem.isChecked = false;
        }
        updatedPathObj.push(pathItem);
      }
    });
    setSvgImgPath({
      [selectedItemIndex]: updatebodyMapSvgTags(updatedPathObj, false, selectedItemIndex),
      ...svgImgPaths
    });
    setSelectedSvgImgPath(updatebodyMapSvgTags(updatedPathObj, false, selectedItemIndex));
  };

  const updatePathSelection = (path: any, formData: any) => {
    const pathObj = svgImgPaths[selectedItemIndex];
    updatePathObj(pathObj, path.target.id, '', false, formData);
  };

  const updateLabelsToextObj = (
    selectedItemIndex: any,
    selectedLabels: any,
    answerOptions: any
  ) => {
    Object.keys(bodyMapImageLabel).forEach((imageObjIndex: any) => {
      if (bodyMapImageLabel[imageObjIndex].imageName === selectedItemIndex) {
        setBodyMapImageLabel({
          ...bodyMapImageLabel,
          [imageObjIndex]: {
            ...bodyMapImageLabel[imageObjIndex],
            imageLabel: selectedLabels,
            imageAnswerOptions: answerOptions
          }
        });
      }
    });
  };

  const setSelectedChoices = (formData: any) => {
    const answerOptions: any = [];
    const selectedLabels: any = [];
    const pathObj = svgImgPaths[selectedItemIndex];
    Object.keys(formData).forEach((pathObjId) => {
      const selectedBodyPart = find(pathObj, { id: pathObjId });
      const selectedLabel = formData[pathObjId];
      if (selectedBodyPart && selectedLabel) {
        selectedBodyPart.label = selectedLabel;
        selectedBodyPart.value = selectedLabel;
        selectedBodyPart.imgName = selectedItemIndex;
        selectedLabels.push(selectedLabel);
        answerOptions.push(selectedBodyPart);
      }
      updatePathObj(pathObj, pathObjId, selectedLabel, true, formData);
      updateLabelsToextObj(selectedItemIndex, selectedLabels, answerOptions);
    });
    const uniqSelectedItems = uniq([...answerOptions, ...selectedOptions]);
    setSelectedOptions(uniqSelectedItems);
  };

  const getImagesLabel = (imageIndex: any) => {
    const imageTitle =
      { [`imageTitle_${imageIndex}`]: bodyMapImageLabel[imageIndex]?.imageTitle } || '';
    return imageTitle;
  };

  const updateImagesLabel = (e: any, imageIndex: any) => {
    setBodyMapImageLabel({
      ...bodyMapImageLabel,
      [imageIndex]: { ...bodyMapImageLabel[imageIndex], imageTitle: e.target.value }
    });
  };

  const isConfigureDisabled = (imageIndex: any) => {
    return bodyMapImageLabel[imageIndex]?.imageTitle || false;
  };

  const getBodyMapImageObj = (imageName: any) => {
    let selectedImgAnswerOptions: any = [];
    const clonedBodyMapImageLabel = clone(bodyMapImageLabel);
    for (let k in clonedBodyMapImageLabel) {
      if (clonedBodyMapImageLabel[k].imageName === imageName) {
        if (clonedBodyMapImageLabel[k].imageAnswerOptions) {
          selectedImgAnswerOptions = [...clonedBodyMapImageLabel[k].imageAnswerOptions];
        }
      }
    }

    return selectedImgAnswerOptions;
  };

  const deletedSelectedOption = (option: any) => {
    const deletedImgNameLabel = option.imgName;
    const pathObj = svgImgPaths[deletedImgNameLabel];
    const clonedSelectedOptions = getBodyMapImageObj(deletedImgNameLabel);
    remove(clonedSelectedOptions, { id: option.id });
    //setSelectedOptions(clonedSelectedOptions);
    const updateSelctedOptions = filter(clonedSelectedOptions, { imgName: deletedImgNameLabel });
    updateImgAnswerOptions(updateSelctedOptions, deletedImgNameLabel);
    const updatePathObj: any = [];
    pathObj.forEach((obj: any) => {
      if (obj.id === option.id) {
        obj.isChecked = false;
        obj.label = '';
        obj.fill = '#C9C9C9';
        updatePathObj.push(obj);
      } else {
        updatePathObj.push(obj);
      }
    });

    setSvgImgPath({
      [deletedImgNameLabel]: updatebodyMapSvgTags(updatePathObj, false, deletedImgNameLabel),
      ...svgImgPaths
    });
    setSelectedSvgImgPath(updatebodyMapSvgTags(updatePathObj, false, deletedImgNameLabel));
  };

  const getOptionText = (option: any, imageName: any) => {
    const clonedSelectedOptions = getBodyMapImageObj(imageName);
    const selectedOptionOfImg = filter(clonedSelectedOptions, (selectedOption: any) => {
      if (selectedOption.imgName === imageName && selectedOption.label === option.label) {
        return selectedOption;
      }
    });
    if (selectedOptionOfImg.length) {
      if (!selectedOptionOfImg[0].isInitialSelected) {
        return t('T_DEFAULT_OPTION_SELECTED');
      } else {
        return t('T_REMOVE_DEFAULT_SELECTED_MSG2');
      }
    }
  };

  const updateIsSelected = (option: any, imageName: any) => {
    const clonedSelectedOptions = getBodyMapImageObj(imageName);
    clonedSelectedOptions.forEach((selectedOption: any) => {
      if (selectedOption.imgName === imageName && selectedOption.label === option.label) {
        selectedOption.isInitialSelected = !selectedOption.isInitialSelected;
      } else if (!checkValue) {
        selectedOption.isInitialSelected = false;
      }
    });
    //setSelectedOptions(clonedSelectedOptions);
    const updateSelctedOptions = filter(clonedSelectedOptions, { imgName: imageName });
    updateImgAnswerOptions(updateSelctedOptions, imageName);
  };

  const handleRemoveField = (index: any) => {};

  const DefaultOptionSelect = (option: any, imageName: any) => {
    return (
      <>
        {!editMode && (
          <DropDownContainer className="ml-0 mr-3 cursor-pointer self-center">
            <div
              className="ml-3 cursor-pointer self-center"
              onClick={() =>
                setIsOpen({
                  ...isOpen,
                  [`${imageName}_${option.label}`]: !isOpen[`${imageName}_${option.label}`]
                })
              }
              ref={dropdownRef}
            >
              {isOpen[`${imageName}_${option.label}`] ? multiDropdownUp : multiDropdown}
            </div>
            {isOpen[`${imageName}_${option.label}`] && (
              <div>
                <StyledDropdownList maxHeight={true}>
                  <li onClick={() => updateIsSelected(option, imageName)}>
                    {getOptionText(option, imageName)}
                  </li>
                  <li
                    onClick={() => {
                      setIsOpen({ ...isOpen, [`${imageName}_${option.label}`]: false });
                      deletedSelectedOption(option);
                    }}
                    style={{ color: 'red' }}
                  >
                    {t('T_REMOVE_OPTION')}
                  </li>
                </StyledDropdownList>
              </div>
            )}
          </DropDownContainer>
        )}
      </>
    );
  };

  const renderSelectedOptionsForImage = (imageName: any) => {
    const selectedOptionsOfImg = getBodyMapImageObj(imageName);
    if (selectedOptionsOfImg.length) {
      return selectedOptionsOfImg.map((option: any, index: any) => {
        return (
          <div>
            <div className="mb-5 flex">
              <InputFieldContainer className="flex w-full flex-col" key={index}>
                <InputField
                  type={'text'}
                  disabled={true}
                  id={`rating-scale-option-${index}`}
                  disableLabel={true}
                  label={'test'}
                  //title={getLabelTitle(option.imgName)}
                  placeholder={'Label(optional'}
                  fieldKey={'value'}
                  required={false}
                  asterixFirst={true}
                  formData={option}
                  setFormData={() => {}}
                  formError={formError}
                />
              </InputFieldContainer>
              {/* <DeleteIcon
                  className="mx-auto ml-3 mt-2 h-5 text-danger"
                  onClick={(e: any) => {
                    !editMode && deletedSelectedOption(option);
                  }}
                  data-testid="delete-icon"
                /> */}
              <div className="flex">{DefaultOptionSelect(option, imageName)}</div>
            </div>
            {option?.isInitialSelected && (
              <div className="-mt-5 mb-5">{t('T_REMOVE_DEFAULT_SELECTED_MSG1')}</div>
            )}
          </div>
        );
      });
    }
  };

  const downloadImage = async (imageData: any): Promise<void> => {
    const payload: any = {
      filePath: `${AppConstants.SURVEY_MEDIA_PATH}${formData.type}/${imageData.fileNameWithTime}`,
      action: AppConstants.GCS_UPLOAD_DOWNLOAD,
      expirationTime: AppConstants.GCS_MIN_EXPIRATION_TIME,
      bucketName: getGlobalBucketName()
    };

    const response: any = await createSignedURL(payload);
    if (HTTP_STATUS.isSuccess(response?.status)) {
      window.open(response?.data?.url, '_blank');
      return;
    }
    throw getFailureMessage(response);
  };

  const contextMenuHandler = async (key: string, imageData: any, imageIndex: any) => {
    if (key === MenuConstants.SURVEY_BODY_MAP_IMAGE_DELETE) {
      !editMode && setToDeleteData({ imageData: imageData, imageIndex: imageIndex });
      !editMode && setDeleteConfirmation(true);
    }

    if (key === MenuConstants.SURVEY_BODY_MAP_IMAGE_DOWNLOAD) {
      setIsLoading(true);
      await downloadImage(imageData);
      setIsLoading(false);
    }
  };

  const configureField = (imageData: any, imageIndex: any) => {
    return (
      <>
        <div className="mb-3 flex w-full">
          <div className="mr-3 w-1/4">
            <InputField
              className="w-full"
              type={'text'}
              disabled={editMode}
              id={'imageTitle'}
              disableLabel={true}
              label={''}
              placeholder={t('T_IMAGE_TITLE')}
              fieldKey={`imageTitle_${imageIndex}`}
              required={false}
              asterixFirst={true}
              formData={getImagesLabel(imageIndex)}
              setFormData={() => {}}
              handleChange={(e: any) => {
                updateImagesLabel(e, imageIndex);
              }}
              formError={formError}
            />
          </div>
          <div className="flex w-3/4">
            <div className="flex w-11/12">
              <InputField
                className="pointer-events-none w-full !rounded-r-none bg-transparent"
                type="text"
                id="max-word-count"
                disableLabel={true}
                label=""
                placeholder="Placeholder"
                fieldKey="label"
                required={false}
                formData={imageData}
                setFormData={setFormData}
                formError={formError}
                handleChange={() => {}}
                disabled={editMode}
              />
              <BiButton
                className={`w-fit rounded-l-none border border-solid border-primary bg-primary py-1.5 text-white`}
                type="button"
                //disabled={!isConfigureDisabled(imageIndex) || editMode}
                onClick={(e: any) => {
                  let clonedBodyMapImageLabel: any = clone(bodyMapImageLabel);
                  setSelectedSvgImgPath(svgImgPaths[`${imageData.fileNameWithTime}`]);
                  setSelectedItem(imageData);
                  setSelecteItemIndex(`${imageData.fileNameWithTime}`);
                  clonedBodyMapImageLabel = {
                    ...clonedBodyMapImageLabel,
                    [imageIndex]: {
                      ...clonedBodyMapImageLabel[imageIndex],
                      imageName: imageData.fileNameWithTime
                    }
                  };
                  setBodyMapImageLabel(clonedBodyMapImageLabel);
                }}
              >
                {t('T_CONFIGURE')}
              </BiButton>
            </div>
            {/* <DeleteIcon
              className="mx-auto mt-2 h-6 text-danger"
              onClick={() => {
                //!editMode && removeSelectedImage(imageData, imageIndex);
                !editMode && setToDeleteData({ imageData: imageData, imageIndex: imageIndex });
                !editMode && setDeleteConfirmation(true);
              }}
              data-testid="delete-icon"
            /> */}
            <div className="flex w-1/12">
              <BodyMapConfigKeyMenu
                dropdownMenu={false}
                editMode={editMode}
                onOptionClick={(key: string) => contextMenuHandler(key, imageData, imageIndex)}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-wrap">
          <div className="mt-5 flex w-full flex-col">
            {renderSelectedOptionsForImage(imageData.fileNameWithTime)}
          </div>
        </div>
      </>
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles.length) {
        dispatch(
          setToastData({
            toastMessage: t('T_SURVEY_BODY_MAP_MAX_FILES_ERROR'),
            isToastActive: true,
            toastType: 'error'
          })
        );
        return;
      }
      setIsLoading(true);
      const timeStamp = moment().unix();
      let temp = acceptedFiles.map((ele: any) => {
        return {
          fileNameWithTime: getFileNameWithTimeStamp(ele.name, timeStamp),
          key: ele.name,
          label: ele.name,
          value: ele.name,
          img: ele,
          allowMoreOption: false
        };
      });

      setFileData(temp);
    },
    [file]
  );

  const getSelectedPaths = (selectedOptions: any) => {
    const pathTags: any = [];
    selectedOptions.map((pathObj: any) => {
      pathTags.push({
        path: pathObj.path,
        label: pathObj.label
      });
    });
    return pathTags;
  };

  const updateSelectedChoicesType = (value: any) => {
    setDisplayType(value === true ? 'bodyMapMulti' : 'bodyMapSingle');

    if (!value) {
      const clonedBodyMapImageLabel = clone(bodyMapImageLabel);
      for (let k in clonedBodyMapImageLabel) {
        let imgAnsOptions = clonedBodyMapImageLabel[k].imageAnswerOptions;
        let answerOptions = [];
        if (imgAnsOptions) {
          answerOptions = imgAnsOptions.map((options: any) => {
            return { ...options, isInitialSelected: false };
          });
        }
        clonedBodyMapImageLabel[k].imageAnswerOptions = answerOptions;
      }
      setBodyMapImageLabel(clonedBodyMapImageLabel);
    }
  };

  const getFile = (svgMap: any, fileName: any) => {
    const fileContent = ReactDomServer.renderToString(svgMap);
    var newImageFIle = new File([fileContent], fileName, { type: 'image/svg+xml' });
    return [newImageFIle];
  };

  const getSvgImgForUpload = () => {
    const fileUploadObj: any = {};
    const fileObjNames: any = {};
    Object.keys(svgImgPaths).forEach((obj: any, index) => {
      const labelData = filter(svgImgPaths[obj], (pathObj: any) => {
        return pathObj.label != '';
      });
      if (labelData.length) {
        const fileName = obj;
        fileUploadObj[index] = {
          fileName: fileName,
          path: `${AppConstants.SURVEY_MEDIA_PATH}${formData.type}/`,
          bucketName: getGlobalBucketName(),
          file: getFile(SVGMap(svgImgPaths[obj], '', svgAttributes), fileName)
        };
        fileObjNames[index] = fileName;
      }
    });
    return { fileUploadObj, fileObjNames };
  };

  const loadBodyImages = async (imagesDownloaded: any) => {
    const fileImagesData: any = [];
    if (!isEmpty(imagesDownloaded)) {
      setIsLoading(true);
      const filesToDownloadObj: any = [];
      Object.keys(imagesDownloaded).map(async (obj: any) => {
        filesToDownloadObj.push(imagesDownloaded[obj]);
      });
      for (const fileObj of filesToDownloadObj) {
        const image = await fetch(fileObj.url);
        const imageBlob = await image.blob();
        const imageFile = new File([imageBlob], fileObj.fileName, {
          type: 'image/svg+xml'
        });
        const timeStamp = moment().unix();
        let temp = {
          fileNameWithTime: fileObj.fileName,
          // fileNameWithTime: getFileNameWithTimeStamp(
          //   getFileNameWithoutTimeStamp(fileObj.fileName),
          //   timeStamp
          // ),
          key: imageFile.name,
          //label: imageFile.name,
          label: getFileNameWithoutTimeStamp(imageFile.name),
          value: imageFile.name,
          img: imageFile,
          allowMoreOption: false
        };
        fileImagesData.push(temp);
        setFileData([temp, ...fileData]);
      }
    }
    return fileImagesData;
  };

  const getBodyImages = async (bodyMapImages: any, formData: any) => {
    const imagesDownloaded = await downLoadImages(bodyMapImages, formData);
    await loadBodyImages(imagesDownloaded);
  };

  const readBodymapImgFromObj = (bodyMapImageLabel: any) => {
    const bodyMapImages: any = {};
    Object.keys(bodyMapImageLabel).forEach((imageObjIndex: any) => {
      bodyMapImages[imageObjIndex] = bodyMapImageLabel[imageObjIndex].imageName;
      // bodyMapImages[bodyMapImageLabel[imageObjIndex].imageName] =
      //   bodyMapImageLabel[imageObjIndex].generation;
    });
    return bodyMapImages;
  };

  const updateImageAnswerOptions = (formData: any, bodyMapImageLabel: any) => {
    const clonedBodyMapImageLabel = bodyMapImageLabel;
    const clonedFormData = formData;

    for (let k in clonedBodyMapImageLabel) {
      if (clonedBodyMapImageLabel[k].imageAnswerOptions) {
        clonedBodyMapImageLabel[k].imageAnswerOptions.forEach((imgAnsOpt: any) => {
          if (formData.answerOptions[imgAnsOpt.label]) {
            imgAnsOpt.isInitialSelected = formData.answerOptions[imgAnsOpt.label].isSelected;
          } else {
            imgAnsOpt.isInitialSelected = false;
          }
        });
      }
    }
    return clonedBodyMapImageLabel;
  };

  const updateExtensionData = (formData: any) => {
    if (formData.extension) {
      setIsLoading(true);
      const { extension = {} } = formData;
      const {
        displayType = 'bodyMapSingle',
        bodyMapImageLabel = {},
        permission = 'public'
      } = extension;
      const bodyMapImages = readBodymapImgFromObj(bodyMapImageLabel);
      const init = async () => {
        await getBodyImages(bodyMapImages, formData);
      };
      init();
      const updatedBodyMapImageLabel = updateImageAnswerOptions(formData, bodyMapImageLabel);
      setBodyMapImageLabel({
        ...updatedBodyMapImageLabel
      });
      setInitialBodyMapImageLabel({
        ...updatedBodyMapImageLabel
      });
      setCheckValue(displayType === 'bodyMapMulti');
      setDisplayType(displayType);
    }
  };

  const getIsDropZoneDisabled = () => {
    return (
      (selectedImages.length === 1 && !bodyMapImageLabel[0]?.imageTitle) ||
      selectedImages.length >= 2
    );
  };

  const getLabelTitle = (selectedImgName: any) => {
    let imgTitle = '';
    Object.keys(bodyMapImageLabel).forEach((imgObj: any) => {
      if (bodyMapImageLabel[imgObj].imageName === selectedImgName) {
        imgTitle = bodyMapImageLabel[imgObj].imageTitle;
      }
    });
    return imgTitle;
  };

  return (
    <>
      {editFromSurveyTemplate && (
        <>
          <LoaderContainer isLoading={isLoading}>
            {deleteConfirmation && (
              <Modal
                overflowVisible={true}
                showCloseIcon={true}
                width="30rem"
                showHeader={true}
                headerText={t('T_SURVEY_BODY_MAP_DELETE_CONFIRMATION_HEADER')}
                toggleVisiblity={(value: boolean) => {
                  setDeleteConfirmation(false);
                }}
                isVisible={deleteConfirmation}
                body={
                  <>
                    <div className="mb-5 flex flex-col">
                      <div className="">{t('T_SURVEY_BODY_MAP_DELETE_CONFIRMATION')}</div>
                    </div>

                    <ModalButton>
                      <BiButton
                        className={'mr-2 text-primary'}
                        type="button"
                        onClick={() => {
                          setDeleteConfirmation(false);
                        }}
                      >
                        {t('T_CANCEL')}
                      </BiButton>
                      <BiButton
                        className={'bg-primary text-white'}
                        type="button"
                        onClick={() => {
                          removeSelectedImage(toDeleteData.imageData, toDeleteData.imageIndex);
                        }}
                      >
                        {t('T_SURVEY_BODY_MAP_DELETE_CONFIRMATION_YES')}
                      </BiButton>
                    </ModalButton>
                  </>
                }
              />
            )}
            <div className="my-1 flex w-full flex-row text-base font-normal">
              {t('T_UPLOAD_BODYMAP_INFO_TEXT')}
            </div>

            <div className="w-full">
              <div className="mt-5">
                <Dropzone
                  onDrop={onDrop}
                  disabled={selectedImages.length >= 2}
                  accept={{ 'application/image': ['.svg'] }}
                  maxFiles={2 - selectedImages.length}
                />
                <div className="mt-8 flex">
                  <Checkbox
                    className="mr-2 cursor-default"
                    checked={checkValue}
                    onChange={() => {
                      setCheckValue(!checkValue);
                    }}
                  />
                  <div className="mr-auto text-base font-normal leading-4">
                    {t('T_BODY_MAP_CHECKBOX_TEXT')}
                  </div>
                </div>
                <div className="mt-5">
                  {selectedImages.map((imgData: any, index: any) => {
                    return <>{configureField(imgData, index)}</>;
                  })}
                </div>
              </div>
            </div>
          </LoaderContainer>

          <Modal
            showCloseIcon={true}
            width="48rem"
            headerText={t('T_CONFIGURE_BODY_MAP')}
            toggleVisiblity={(value: boolean) => {
              setSelectedItem(null);
            }}
            isVisible={selectedItem}
            zIndex="50"
            body={
              selectedItem && (
                <ConfigureBodyMap
                  imageData={selectedItem}
                  selectedOptions={selectedOptions}
                  selectedItemIndex={selectedItemIndex}
                  selectedSvgImgPath={selectedSvgImgPath}
                  updatePathSelection={updatePathSelection}
                  setSelectedChoices={setSelectedChoices}
                  AllowMoreOptionChange={AllowMoreOptionChange}
                  updateSelectedChoicesType={updateSelectedChoicesType}
                  closeModal={() => {
                    setSelectedItem(null);
                  }}
                  isDefaultImg={true}
                  bodyMapImageLabel={bodyMapImageLabel}
                  svgAttributes={svgAttributes}
                />
              )
            }
            headerLeftIcon={<img src={bodyMapIcon} className="h-7.5 w-4" alt="bodymapicon" />}
          />
        </>
      )}
    </>
  );
};

export default BodyMapQuestion;

const InputFieldContainer = styled.div`
  input {
    width: 100%;
  }
  button {
    display: flex;
    flex-direction: row;
  }
`;
export const StyledDropdownList = styled(DropDownList)`
  width: 210px;
  padding: 10px;
  top: 30px;
  left: -150px;
`;

export const ModalButton = styled('div')`
  display: flex;
  justify-content: end;
`;
