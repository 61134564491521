import React from 'react';
import styled from 'styled-components';

interface SpinnerProps {
  height?: string; //In Px/Rem
  width?: string; //In Px/Rem
  color?: string;
  margin?: string;
}

const Spinner = ({ height, width, color, margin }: SpinnerProps) => (
  <StyledSpinner viewBox="0 0 50 50" height={height} width={width} color={color} margin={margin}>
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
  </StyledSpinner>
);

const StyledSpinner = styled.svg<SpinnerProps>`
  animation: rotate 2s linear infinite;
  margin: ${({ margin }) => (margin ? margin : '-25px 0 0 -25px')};
  width: ${({ width }) => (width ? width : '50px')};
  height: ${({ height }) => (height ? height : '50px')};

  & .path {
    stroke: ${({ color }) => (color ? color : '#2C71AD')};
    strokelinecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default Spinner;
