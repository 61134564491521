// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { Children, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import InputField from '../shared/Fields/InputField';
import SelectField from '../shared/Fields/SelectField';
import { AppConstants } from '../../constants/AppConstants';
import moment from 'moment';
import { find, compact, uniq } from 'lodash';
import { getUsers } from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getUserName } from '../../util/admin-utils';

interface TemplateStatusDetailssProps {
  statusData: any;
}

function templateStatusDetails({ statusData = [] }: TemplateStatusDetailssProps) {
  const { t } = useTranslation();
  //   const [statusArray, setStatusArray] = useState([]);

  //   useEffect(() => {
  //     setStatusArray(templateData.statusChanges || []);
  //   }, []);

  const showStatus = (data: any) => {
    return (
      <>
        <div className="my-2.5">
          <div>{t(`T_${data.status}_BY`)}</div>
          <div className="font-light">{data?.changedByUser || '-'}</div>
        </div>
        <div className="my-2.5">
          <div>{t(`T_${data.status}_ON`)}</div>
          <div className="font-light">{moment(data?.timestamp).format(AppConstants.DEFAULT_DATE_FORMAT)}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="ml-5 mt-3">
        {statusData.map((status: any) => {
          return showStatus(status);
        })}
      </div>
    </>
  );
}

export default templateStatusDetails;
