export const getCodableValue = (data: any) => {
  const [item1 = null] = data || [];
  let value = '';
  if (item1) {
    const { text = null, coding = [] } = item1;
    if (text) {
      value = text;
    } else {
      const [code1 = null] = coding || [];
      if (code1) value = code1.display;
    }
  }
  return value || '';
};

export const getCodeFromCodable = (data: any) => {
  /**
   * @manager consent-utils
   * @name getCodeFromCodable
   * @description Get the codable object as input and return the code from object
   */
  // let code = '';
  // const { coding = [] } = data || {};
  // const [code1 = null] = coding || [];
  // if (code1) code = code1.code;

  // return code || '';
  const { code = '' } = data;
  return code;
};

export const getCodableValueArray = (data: any) => {
  let val: any = data.map((ele: any) => {
    const { text = null, coding = [] } = ele;
    let cat: string = '';
    if (text) {
      cat = text;
    } else {
      const [code1 = null] = coding || [];
      if (code1) cat = code1.display;
    }
    return cat;
  });

  return val;
};

export const getPurpose = (data: any) => {
  let val: any = [];
  if (data.purpose && data.purpose.length) {
    data.purpose.forEach((ele: any) => {
      val.push(ele?.display);
    });
  }
  return val;
};

export const getPurposeObject = (data: any) => {
  let obj: any = {
    code: data.code || '',
    system: data.system || '',
    display: data.display || '',
    version: data.version || '',
    userSelected: true
  };

  return obj;
};

export const getCategoryObject = (data: any) => {
  let obj = {};
  if (!data.coding) {
    obj = {
      text: data.display || '',
      coding: [data]
    };
    return obj;
  }
  return data;
};

export const getCodableObject = (data: any) => {
  let obj: any = {
    text: data.display || '',
    coding: [
      {
        code: data.code || '',
        system: data.system || '',
        display: data.display || '',
        version: data.version || '',
        userSelected: true
      }
    ]
  };
  return obj;
};

export const removeArrayElement = (item: any, data: any[]) => {
  let arr = data;
  let i = arr.indexOf(item);
  if (i > -1) {
    arr.splice(i, 1);
  }
  return arr;
};

export const GetObjectFromData = (data: any) => {
  const { coding = null, text = '' } = data;
  if (coding) {
    const [obj = {}] = coding;
    const { code = '', display = '', system = '' } = obj;
    return {
      code,
      system,
      display
    };
  } else {
    const { code = '', display = '', system = '' } = data;
    return {
      code,
      system,
      display
    };
  }
};

export const getPickListItems = (data: any, ele: any) => {
  const pickListObj = data.get(ele);
  const { coding = null, text = '' } = pickListObj;
  if (coding) {
    const [obj = {}] = coding || [];
    const { display = '' } = obj;
    return {
      key: text || display,
      value: text || display,
      //label: display,
      name: text || display
    };
  } else {
    const { code, display } = pickListObj;
    return {
      key: display,
      value: display,
      //label: display
      name: display
    };
  }
};

export const sortArray = (data: any) => {
  let t = data.sort((a: any, b: any) => {
    return a.value?.toString().localeCompare(b.value);
  });
  return t || [];
};
