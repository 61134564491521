import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'userManagement',
  initialState: {
    userProfileInfo: JSON.parse(localStorage.getItem('user-profile') || '{}') as any,
    email: ''
  },
  reducers: {
    setUserProfileInfo: (state, action) => {
      state.userProfileInfo = action.payload;
      localStorage.setItem('user-profile', JSON.stringify(action.payload));
    },
    registerEmail: (state, action) => {
      state.email = action.payload;
    }
  }
});

export const { setUserProfileInfo, registerEmail } = userSlice.actions;

export default userSlice.reducer;
