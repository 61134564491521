export class RouteConstants {
  /** Organization Service Routes */
  static readonly ROUTE_OS_ORGANIZATION_LISTING = '/organizations';

  static readonly ROUTE_ADMIN_LISTING = '/admin-list';
  static readonly ROUTE_PERMISSION_SET_LISTING = '/permissionset-list';
  static readonly ROUTE_TEMPLATE_LISTING = '/templates';
  static readonly ROUTE_SURVEY_TEMPLATE_LISTING = '/survey-template/list';
  static readonly ROUTE_SURVEY_TEMPLATE_CREATE = '/survey-template/new';
  static readonly ROUTE_SURVEY_TEMPLATE_EDIT = '/survey-template/edit/:id';
  static readonly ROUTE_PROFILE = '/profile';
  static readonly ROUTE_USER_DETAILS = '/admin/:id';
  static readonly ROUTE_INVITED_USER_DETAILS = '/admin/invited/:id';
  static readonly ROUTE_MOBILE_CONFIG_LIST = '/mobile-configuration/list';
  static readonly ROUTE_MOBILE_CONFIG_STYLE = '/mobile-configuration/style';
  static readonly ROUTE_MOBILE_CONFIG_CONFIGURATION = '/mobile-configuration/configuration';
  static readonly ROUTE_MOBILE_CONFIG_FILE = '/mobile-configuration/file';
  static readonly ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION =
    '/mobile-configuration/language-translations';
  static readonly ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION_NEW =
    '/mobile-configuration/language-translations/new';
  static readonly ROUTE_CONSENTS = '/consents';
  static readonly ROUTE_CONSENTS_DETAILS = '/consents/:id';
  static readonly ROUTE_CONSENTS_FIELDGROUP = '/consents/fieldgroup';
  static readonly ROUTE_CONSENTS_FIELDGROUP_DETAILS = '/consents/fieldgroup/:id';
  static readonly ROUTE_NEW_CONSENTS = '/consents/new';

  static readonly ROUTE_ADMIN_LIST_ACTIVE_USERS = '/admin-list';
  static readonly ROUTE_ADMIN_LIST_INVITED_USERS = '/admin-list/invited';

  static readonly NOTIFICATION_TEMPLATE_DETAIL_CREATE = '/create-template/:id';
  static readonly NOTIFICATION_TEMPLATE_DETAIL_EDIT = '/templates/edit/:id';

  static readonly ROUTE_CONFIGURATIONSERVICE_LIST = '/config-key-list';
  static readonly ROUTE_USER_CONFIGURATIONSERVICE_LIST = '/users/:userId/config-key-list';
  static readonly ROUTE_ORG_CONFIGURATIONSERVICE_LIST = '/organization/:id/config-key-list';
  static readonly ROUTE_ORG_CONFIG_KEY_UPDATE = '/organization/:orgId/config-key/edit/:sysId';
  static readonly ROUTE_USER_CONFIG_KEY_UPDATE = '/users/:userId/config-key/edit/:sysId';
  static readonly ROUTE_CONFIG_KEY_UPDATE = '/config-key/edit/:sysId';
  static readonly ROUTE_CONFIG_KEY_CREATE = '/config-key/create';
  static readonly ROUTE_CATEGORIES_LIST = '/config-category-list';
  static readonly ROUTE_ORG_CATEGORIES_LIST = '/organization/:id/config-category-list';
  static readonly ROUTE_CATEGORIES_EDIT = '/config-category/edit/:id';
  static readonly ROUTE_CATEGORIES_CREATE = '/config-category/create';

  /** Access Control Service Routes */
  // static readonly ROUTE_ACS_PASSWD_POLICY_CREATE = '/passwordpolicies/new';
  // static readonly ROUTE_ACS_PASSWD_POLICY_LIST = '/passwordpolicies';
  // static readonly ROUTE_ACS_PASSWD_POLICY_VIEW = '/passwordpolicies/:id';

  /** Metadata Service UI Routes */
  static readonly ROUTE_MDS_APPLN_CREATE = '/metadata/applications/new';
  static readonly ROUTE_MDS_APPLN_LIST = '/metadata/applications';
  static readonly ROUTE_MDS_APPLN_VIEW = '/metadata/applications/:id';

  static readonly ROUTE_MDS_OBJECT_LIST = '/metadata/:id/objects';
  static readonly ROUTE_MDS_OBJECT_VIEW = '/metadata/:id/objects/:objectId';
  static readonly ROUTE_MDS_OBJECT_CREATE = '/metadata/:id/objects/new';

  static readonly ROUTE_MDS_INSTANCE_LIST = '/metadata/apps/:id/objects/:objectId/instances';
  static readonly ROUTE_MDS_INSTANCE_VIEW =
    '/metadata/apps/:id/objects/:objectId/instances/:instanceId';
  static readonly ROUTE_MDS_INSTANCE_CREATE = '/metadata/apps/:id/objects/:objectId/instances/new';

  static readonly ROUTE_CUSTOM_METADATA = '/custom/:id';

  /**Migration Routes */
  static readonly ROUTE_MIGRATION_EXPORT_LIST = '/migration/export';
  static readonly ROUTE_MIGRATION_IMPORT_LIST = '/migration/import';
  static readonly ROUTE_MIGRATION_EXPORT_DETAIL_CREATE = '/migration/export/new';
  static readonly ROUTE_MIGRATION_EXPORT_DETAIL_EDIT = '/migration/export/:id';
  static readonly ROUTE_MIGRATION_IMPORT_DETAIL_CREATE = '/migration/import/new';
  static readonly ROUTE_MIGRATION_IMPORT_DETAIL_EDIT = '/migration/import/:id';

  /** Password Policies */
  static readonly ROUTE_UMS_PASSWORD_POLICY_LIST = '/password-policies';
  static readonly ROUTE_UMS_PASSWORD_POLICY_VIEW = '/password-policies/:id';
  static readonly ROUTE_UMS_PASSWORD_POLICY_CREATE = '/password-policies/new';

  /** File Management */
  static readonly ROUTE_AMS_FILES = '/buckets';
  static readonly ROUTE_AMS_FILES_HISTORY = '/buckets/history/:id';
  static readonly ROUTE_AMS_FILE_DETAILS = '/buckets/:id';
  static readonly ROUTE_AMS_FILES_CREATE_NEW_BUCKET = '/buckets/new';

  static readonly ROUTE_AMS_FOLDER_LIST = '/buckets/:id/folders'; //NewFolder
  static readonly ROUTE_AMS_FOLDER_NESTED_LIST = '/buckets/:id/folders/:folderId/folders'; //NewFolder
  static readonly ROUTE_AMS_FOLDER_NEW = '/buckets/:id/folders/new'; //FolderDetails
  static readonly ROUTE_AMS_FOLDER_NESTED_NEW = '/buckets/:id/folders/:folderId/new'; //FolderDetails
  static readonly ROUTE_AMS_FOLDER_VIEW = '/buckets/:id/folders/:folderId'; //FolderDetails
  static readonly ROUTE_AMS_FOLDER_NESTED_VIEW = '/buckets/:id/folders/:folderId'; //FolderDetails

  /** Care Plan*/
  static readonly ROUTE_CARE_PLAN_TEMPLATE_LIST = '/careplans';
  static readonly ROUTE_CARE_PLAN_TEMPLATE_VIEW = '/careplans/:id';
  static readonly ROUTE_CARE_PLAN_TEMPLATE_CREATE = '/careplans/new';

  static readonly ROUTE_GDPR_CREATE = '/patient-request/new';
  static readonly ROUTE_GDPR_LIST = '/patient-request';

  /** Role Management Routes */
  static readonly ROUTE_ROLE_MANAGEMENT_LIST = '/roles';
  static readonly ROUTE_ROLE_MANAGEMENT_CREATE = '/roles/new';
  static readonly ROUTE_ROLE_MANAGEMENT_EDIT = '/roles/:id';
}
