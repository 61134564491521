import { constant } from 'lodash';
import { useEffect, useState } from 'react';
import { BiIconCheck } from '../../assets/components/svgs';
import {useTranslation} from "react-i18next";

interface StepperProps {
  stepperData?: any;
  activeStep: number;
  className?: string;
}

interface StepperItemProps {
  sequenceNumber: number;
  title: string;
  lastItem: boolean;
  active: number;
}

const StepperItem: React.FC<StepperItemProps> = ({ title, sequenceNumber, lastItem, active }) => {

  return (
    <>
      {sequenceNumber <= active ? (
        <>
          <div className={`relative flex items-center text-white`}>
            <div
              className={`h-6 w-6 rounded-full border-2 ${
                sequenceNumber === active
                  ? 'border-black text-black'
                  : 'border-stepGreen bg-stepGreen pt-[3px] pl-[2px]'
              } pt-0.5 text-center text-xs transition duration-500 ease-in-out`}
            >
              {sequenceNumber !== active ? BiIconCheck : sequenceNumber}
            </div>
            <div
              className={`absolute top-0 -ml-12 mt-7 w-32 text-center text-xs text-black ${
                sequenceNumber === active ? 'font-semibold' : 'text-gray-500'
              }
              }`}
            >
              {title}
            </div>
          </div>

          {lastItem ? (
            <></>
          ) : (
            <div
              className={`mx-2 flex-auto rounded border-t-4 ${
                sequenceNumber === active ? 'border-gray-300' : 'border-stepGreen'
              } transition duration-500 ease-in-out`}
              style={{ maxWidth: '130px', minWidth: '130px' }}
            ></div>
          )}
        </>
      ) : (
        <>
          <div className="relative flex items-center text-gray-500">
            <div className="text-x h-6 w-6 rounded-full border-2 border-gray-300 bg-gray-300 pt-0.5 text-center text-xs transition duration-500 ease-in-out">
              {sequenceNumber}
            </div>
            <div className="absolute top-0 -ml-12 mt-7 w-32 text-center text-xs text-gray-500">
              {title}
            </div>
          </div>

          {lastItem ? (
            <></>
          ) : (
            <div
              className="mx-2 flex-auto rounded border-t-4 border-gray-300 transition duration-500 ease-in-out"
              style={{ maxWidth: '130px' }}
            ></div>
          )}
        </>
      )}
    </>
  );
};

const Stepper: React.FC<StepperProps> = ({ stepperData, activeStep, className = '' }) => {
  const {t} = useTranslation();
  return (
    <>
      <div className={`mx-auto ${className}`}>
        <div className="p-4">
          <div className="flex items-center">
            {stepperData.map((ele: any, i: number) => {
              return (
                <StepperItem
                  key={`${ele.title}`}
                  sequenceNumber={ele.step}
                  title={`${t(ele.title)}`}
                  lastItem={i === stepperData.length - 1}
                  active={activeStep}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stepper;
