import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import TemplateTable from '../shared/tables/TemplateTable';
import { TableContainer } from '../Organizations/OrganizationsList';
import { getApplications, deleteApplication } from '../../http/metadata-service';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { AppConstants } from '../../constants/AppConstants';
import { useTheme } from '../../context/themeContext';
import { setURLPaths } from '../../app/organizationReducer';
import { useNavigate } from 'react-router-dom';
import { plusSign } from '../../assets/components/svgs';
import { setApplication } from '../../app/metadataReducer';
import { setToastData } from '../../app/toastReducer';
import {
  setCareTeamsList,
  setCareplanData,
  setConditionsList,
  setOrgList
} from '../../app/carePlanReducer';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { cloneDeep, debounce, findIndex, capitalize, filter, map, find, uniqBy } from 'lodash';
import { getFailureMessage } from '../../util/ErrorUtil';
import { RouteConstants } from '../../constants/RouteConstants';
// import DeleteConfirmModal from './DeleteConfirmModal';
import { EllipsisDiv } from '../../styles/globalStyles';
import Checkbox from '../shared/Fields/Checkbox';
import {
  changeMetadataComponent,
  deleteManifestItem,
  setManifest
} from '../../app/migrationExportReducer';
import { ActionButtons } from '../Reusable/ActionButton';
import AddCircle from '../../assets/addCircle.svg';
import { getCarePlanTemplates, getConditions, getCareTeams } from '../../http/careplan-services';
import { useDidMountEffect } from '../../util/customHooks';

import {
  getConfigKeyOrg,
  configServiceFilters,
  updateOrgResponse,
  updateSelectedFilter,
  getUserLoggedInOrg
} from '../ConfigurationService/Configutils';

import { setTableOptions, resetTableOptions } from '../../app/templateTableReducer';

import {
  mergeConditionsCareTeams,
  getConditionsList,
  getFilterCareTeamsList,
  getOrgForCarePlans,
  filterOrgNames,
  getOrgList,
  filterCareTeamNames,
  filterConditionNames,
  filterCareTeams
} from './carePlanUtils';
interface CarePlanTemplateListProps {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const CarePlanTemplateList = ({
  type = 'listing',
  toggleModal = () => {}
}: CarePlanTemplateListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState<any>([]);
  const [paging, setPaging] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const [currentPage, setCurrentPage] = useState<number>(AppConstants.DEFAULT_PAGE);

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const userProfileInfo: any = useAppSelector((state) => state.userManagement.userProfileInfo);

  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);

  const conditionsListData: any = useAppSelector((state) => state.carePlanData.conditionsList);

  const careTeamsListData: any = useAppSelector((state) => state.carePlanData.careTeamList);

  const carePlanOrgList: any = useAppSelector((state) => state.carePlanData.orgList);

  const [selectedItems, setSelectedItems] = useState<any>([]);

  const manifestCarePlansData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.globalCarePlanTemplates
  );

  const [loadNextData, setLoadNextData] = useState<any>({
    orgPaging: false,
    searchOrgName: '',
    searchOrg: false,
    searchCareTeamName: '',
    searchCareTeam: false,
    searchConditionName: '',
    searchCondition: false
  });

  useEffect(() => {
    const init = async () => {
      let [carePlanConditions, carePlanTeams] = await Promise.all([
        getCarePlanConditions(),
        getCareTeamsList()
      ]);

      await setFilterData(carePlanConditions, carePlanTeams);
    };

    dispatch(
      setURLPaths([
        { key: RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_LIST, label: 'Care Plan > Templates' }
      ])
    );
    if (type === 'migrationPreview') {
      setIsLoading(false);
      return;
    }
    init();
  }, []);

  const getCarePlanConditions = async () => {
    const queryOptions = { size: AppConstants.MAXIMUM_PAGE_SIZE };
    const conditionResponse = await getConditions(queryOptions);

    const { status, data: conditionsData } = conditionResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: conditions = [], paging } = conditionsData;
      if (conditions.length) {
        let filteredData = uniqBy([...conditionsListData, ...conditions], 'id');
        dispatch(setConditionsList(filteredData));
        return filteredData;
      }
    }
  };

  const getCareTeamsList = async () => {
    const queryOptions = { size: AppConstants.MAXIMUM_PAGE_SIZE };
    const careTeamResponse = await getCareTeams(queryOptions);

    const { status, data: careTeamsData } = careTeamResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: careTeams = [], paging } = careTeamsData;
      if (careTeams.length) {
        let filtered = uniqBy(filterCareTeams([...careTeamsListData, ...careTeams]), 'id');
        dispatch(setCareTeamsList(filtered));
        return filtered;
      }
    }
  };

  const loadCarePlanTemplates = async ({ ...options }) => {
    const { page, size, search = '', sortOrder = '', sortType = '', filters = {} } = options;

    const response = await getCarePlanTemplates(
      page,
      size,
      sortType ? [`${sortOrder},${sortType}`] : [],
      filters
    );
    const { status, data: careTemplateResponse } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: templates = [], paging } = careTemplateResponse;
      if (templates.length) {
        const updatedTemplateData = mergeConditionsCareTeams(
          templates,
          conditionsListData,
          careTeamsListData,
          carePlanOrgList
        );
        //setRows([...templates]);
        setRows([...updatedTemplateData]);
        setPaging(paging);
      } else {
        setRows(`${t('T_NO_DATA_AVAILABLE_MSG')}`);
      }
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const tableHeadersBuilder = () => {
    let TABLE_HEADERS: Array<ITableHeader> = [
      {
        key: 'status',
        description: 'T_STATUS',
        sortEnabled: true,
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (row: any) => {
          return capitalize(row?.status);
        },
        width: '10%'
      },
      {
        key: 'title',
        description: 'T_TITLE',
        sortEnabled: type !== 'migrationPreview',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.title}>
              {data?.title}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '30%' : type === 'migrationPreview' ? '35%' : '40%'
      },
      {
        key: 'intent',
        description: 'T_INTENT',
        sortEnabled: true,
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.intent}>
              {capitalize(data?.intent)}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '30%' : type === 'migrationPreview' ? '35%' : '40%'
      },
      {
        key: 'conditions',
        description: 'T_CONDITIONS',
        sortEnabled: false,
        clickable: false,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.conditions}>
              {capitalize(data?.conditions)}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '30%' : type === 'migrationPreview' ? '35%' : '40%'
      },
      {
        key: 'careTeams',
        description: 'T_CARETEAMS',
        sortEnabled: false,
        clickable: false,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.careTeams}>
              {capitalize(data?.careTeams)}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '30%' : type === 'migrationPreview' ? '35%' : '40%'
      },
      {
        key: 'orgData',
        description: 'T_ORGANIZATION',
        sortEnabled: true,
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return (
            <EllipsisDiv className="w-full flex-1" title={data?.orgList}>
              {capitalize(data?.orgList)}
            </EllipsisDiv>
          );
        },
        width: type === 'listing' ? '30%' : type === 'migrationPreview' ? '35%' : '40%'
      }
    ];
    return TABLE_HEADERS;
  };

  const [tableHeaders, setTableHeaders] = useState(tableHeadersBuilder());
  const isCheckboxSelected = (data: any) => {
    const { id } = data;

    return (
      findIndex(selectedItems, function (item: any) {
        return item.id === id;
      }) !== -1
    );
  };

  const toggleCheckbox = (data: any) => {
    const { id, status = '', name = '', title = '' } = data;

    let existingArr: any = cloneDeep(selectedItems);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      existingArr.splice(existingId, 1);
      setSelectedItems(existingArr);
      return;
    }
    existingArr.push({ id, status, name, title });
    setSelectedItems(existingArr);
  };

  const toggleOverWrite = (data: any) => {
    const { id } = data;

    let existingArr: any = cloneDeep(manifestCarePlansData);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      if (existingArr[existingId]?.overWrite) {
        delete existingArr[existingId].overWrite;
      } else {
        existingArr[existingId].overWrite = true;
      }

      dispatch(setManifest({ key: 'globalCarePlanTemplates', value: existingArr }));
      return;
    }
  };
  const fetchMoreOrgData = () => {
    setLoadNextData((prevState: any) => ({ ...prevState, orgPaging: true }));
  };

  const searchOrgData = (data: any) => {
    setLoadNextData((prevState: any) => ({
      ...prevState,
      searchOrgName: data,
      searchOrg: true,
      searchCareTeam: false,
      searchCondition: false
    }));
  };

  const searchCareTeam = (data: any) => {
    setLoadNextData((prevState: any) => ({
      ...prevState,
      searchCareTeamName: data,
      searchCareTeam: true,
      searchOrg: false,
      searchCondition: false
    }));
  };

  const searchConditions = (data: any) => {
    setLoadNextData((prevState: any) => ({
      ...prevState,
      searchConditionName: data,
      searchCondition: true,
      searchOrg: false,
      searchCareTeam: false
    }));
  };

  const carePlanListFilters = [
    // {
    //   id: 'status',
    //   label: 'T_FILTER_KEY_STATUS',
    //   component: 'picklist',
    //   type: 'multiple',
    //   itemsList: AppConstants.CAREPLAN_STATUS,
    //   selectedFilter: [],
    //   fieldKey: 'key',
    //   fieldValues: 'value',
    //   disabled: false,
    //   hasMore: true,
    //   paging: { size: 25, page: 1 }
    // },
    {
      id: 'orgFilter',
      label: 'T_FILTER_KEY_ORGANIZATIONS',
      component: 'picklist',
      type: 'multiple',
      itemsList: [],
      selectedFilter: [],
      fieldKey: 'name',
      fieldValues: 'name',
      disabled: false,
      hasMore: false,
      paging: { size: 1000, page: 1 },
      searchable: true
    },
    {
      id: 'conditions',
      label: 'T_FILTER_KEY_CONDITIONS',
      component: 'picklist',
      type: 'multiple',
      itemsList: [],
      selectedFilter: [],
      fieldKey: 'key',
      fieldValues: 'value',
      disabled: false,
      hasMore: false,
      paging: { size: 25, page: 1 },
      searchable: false
    },
    {
      id: 'careTeamFilter',
      label: 'T_FILTER_KEY_CARETEAMS',
      component: 'picklist',
      type: 'multiple',
      itemsList: [],
      selectedFilter: [],
      fieldKey: 'id',
      fieldValues: 'displayVal',
      disabled: false,
      hasMore: false,
      paging: { size: 1000, page: 1 },
      searchable: true
    },
    {
      id: 'titleFilter',
      label: 'T_FILTER_KEY_TITLE',
      component: 'input',
      type: 'text',
      itemsList: [],
      selectedFilter: [],
      fieldKey: 'carePlanTitle',
      disabled: false,
      searchable: true,
      searchPlaceholder: 'Search by title',
      onlySearch: true
    },
    {},
    {}
    // {
    //   id: 'intentFilter',
    //   label: 'T_FILTER_KEY_INTENT',
    //   component: 'picklist',
    //   type: 'multiple',
    //   itemsList: AppConstants.CAREPLAN_INTENT,
    //   selectedFilter: [],
    //   fieldKey: 'key',
    //   fieldValues: 'value',
    //   disabled: false,
    //   hasMore: false
    // }
  ];

  const [paramObj, setParamObj] = useState({
    orgFilter: '',
    status: ''
  });

  const serachFuncMapping: any = {
    orgFilter: searchOrgData,
    careTeamFilter: searchCareTeam,
    conditions: searchConditions
  };

  const carePlanFiltersList = carePlanListFilters.map((filter: any) => {
    return {
      ...filter,
      fetchMoreData: fetchMoreOrgData,
      searchData: serachFuncMapping[filter.id]
    };
  });

  const [filterList, setFilterList] = useState<Array<any>>(carePlanFiltersList);
  const [filterPillsData, setFilterPillsData] = useState<Array<any>>([]);

  useDidMountEffect(() => {
    const { tableFilters, searchText, currentPage, size, sortName, sortType } =
      globalFilters['careplan-list'];
    applyFilters(searchText, currentPage, size, sortName, sortType, tableFilters);
  }, [globalFilters]);

  useDidMountEffect(async () => {
    if (loadNextData.searchOrg) {
      loadOrgSearchData();
    }

    if (loadNextData.searchCareTeam) {
      loadCareTeamSearchData();
    }

    if (loadNextData.searchCondition) {
      loadConditionSearchData();
    }
  }, [loadNextData]);

  const loadOrgSearchData = () => {
    const orgResponse = filterOrgNames(loadNextData.searchOrgName, carePlanOrgList);
    updateFilterSetData(orgResponse, conditionsListData, careTeamsListData);
    updateSelectedFilter(
      filterList,
      filterPillsData,
      '',
      fetchMoreOrgData,
      setFilterPillsData,
      () => {} //setFilterList
    );
  };

  const loadCareTeamSearchData = () => {
    const careTeamFilterResponse = filterCareTeamNames(
      loadNextData.searchCareTeamName,
      careTeamsListData
    );
    updateFilterSetData(carePlanOrgList, conditionsListData, careTeamFilterResponse);
    updateSelectedFilter(
      filterList,
      filterPillsData,
      '',
      fetchMoreOrgData,
      setFilterPillsData,
      () => {} //setFilterList
    );
  };

  const loadConditionSearchData = () => {
    const conditionFilterResp = filterConditionNames(
      loadNextData.searchConditionName,
      conditionsListData
    );
    updateFilterSetData(carePlanOrgList, conditionFilterResp, careTeamsListData);
    updateSelectedFilter(
      filterList,
      filterPillsData,
      '',
      fetchMoreOrgData,
      setFilterPillsData,
      () => {} //setFilterList
    );
  };

  const updateFilterSetData = (orgResponse: any, carePlanConditions: any, carePlanTeams: any) => {
    let initialFilterList: any = [...filterList];

    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'orgFilter') {
        return {
          ...obj,
          itemsList: getOrgList(orgResponse)
        };
      } else if (obj.id === 'conditions') {
        return {
          ...obj,
          itemsList: getConditionsList(carePlanConditions)
        };
      } else if (obj.id === 'careTeamFilter') {
        return {
          ...obj,
          itemsList: getFilterCareTeamsList(carePlanTeams)
        };
      } else {
        return { ...obj };
      }
    });
    setFilterList(currentFilterList);
  };

  const setFilterData = async (carePlanConditions: any, carePlanTeams: any) => {
    let orgResponse = await getOrgForCarePlans(loadNextData, filterList, userProfileInfo);

    updateFilterSetData(orgResponse, carePlanConditions, carePlanTeams);

    if (orgResponse.length) {
      dispatch(setOrgList(uniqBy([...carePlanOrgList, ...orgResponse], 'id')));
    }

    const {
      tableFilters = {},
      searchText = '',
      currentPage: filterCurrentPage = AppConstants.DEFAULT_PAGE,
      size: filterSize = type === 'listing'
        ? AppConstants.DEFAULT_PAGE_SIZE
        : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
      sortName = '',
      sortType = ''
    } = globalFilters['careplan-list'] || {};

    dispatch(
      setTableOptions({
        ['careplan-list']: {
          searchText: searchText,
          currentPage: filterCurrentPage,
          size: filterSize,
          sortName: sortName,
          sortType: sortType,
          tableFilters: tableFilters
        }
      })
    );
  };

  const applyFilters = async (
    search: string = '',
    page: number,
    size: number,
    sortOrder: string = '',
    sortType: string = '',
    filters: any
  ) => {
    setIsLoading(true);
    const appliedFilterList = Object.keys(filters);
    setFilterPillsData(updatePillsData(filters, appliedFilterList));
    setFilterList(updateFilterListSelected(filters));
    setParamObj(updateParamObj(filters, appliedFilterList));

    loadCarePlanTemplates({ page, size, search, sortOrder, sortType, filters });
  };

  const updateFilterListSelected = (appliedFilters: any) => {
    const initialFilterList: any = [...filterList];
    const updatedFilterList = initialFilterList.map((filterObj: any) => {
      const selectedFilter = appliedFilters[filterObj.id] ? appliedFilters[filterObj.id] : [];
      return { ...filterObj, selectedFilter };
    });

    return updatedFilterList;
  };

  const updatePillsData = (appliedFilters: any, appliedFilterList: any) => {
    const pillsData: any = [];
    if (appliedFilterList && appliedFilterList.length) {
      appliedFilterList.forEach((key: any) => {
        switch (key) {
          case 'orgFilter':
            if (appliedFilters[key]) {
              appliedFilters[key].forEach((item: any) => {
                pillsData.push({
                  name: item.name,
                  label: 'Organization',
                  id: key,
                  key: item.id
                  //hideIcon: queryParams.id ? true : false
                });
              });
            }

            break;
          case 'status':
            if (appliedFilters[key]) {
              appliedFilters[key].forEach((item: any) => {
                pillsData.push({
                  name: item.value,
                  label: 'Status',
                  id: key,
                  key: item.key
                  //hideIcon: queryParams.id ? true : false
                });
              });
            }
            break;
          case 'conditions':
            if (appliedFilters[key]) {
              appliedFilters[key].forEach((item: any) => {
                pillsData.push({
                  name: item.value,
                  label: 'Conditions',
                  id: key,
                  key: item.key
                  //hideIcon: queryParams.id ? true : false
                });
              });
            }
            break;
          case 'careTeamFilter':
            if (appliedFilters[key]) {
              appliedFilters[key].forEach((item: any) => {
                pillsData.push({
                  name: item.name,
                  label: 'CareTeams',
                  id: key,
                  key: item.id
                  //hideIcon: queryParams.id ? true : false
                });
              });
            }
            break;
          case 'intentFilter':
            if (appliedFilters[key]) {
              appliedFilters[key].forEach((item: any) => {
                pillsData.push({
                  name: item.value,
                  label: 'Intent',
                  id: key,
                  key: item.key
                  //hideIcon: queryParams.id ? true : false
                });
              });
            }
            break;
          case 'titleFilter':
            if (appliedFilters[key] && appliedFilters[key].length) {
              pillsData.push({
                name: appliedFilters[key],
                label: 'Title',
                id: key,
                key: appliedFilters[key]
                //hideIcon: queryParams.id ? true : false
              });
            }
            break;
        }
      });
    }

    return pillsData;
  };

  const updateParamObj = (appliedFilters: any, appliedFilterList: any) => {
    const initialParamObj: any = { ...paramObj };
    const initialFilterList: any = [...filterList];
    initialFilterList.forEach((filterObj: any) => {
      switch (filterObj.id) {
        case 'orgFilter':
          //initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'id').join(',');
          initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'id');
          break;
        case 'status':
        case 'conditions':
        case 'careTeamFilter':
        case 'intentFilter':
          initialParamObj[filterObj.id] = map(appliedFilters[filterObj.id], 'key');
          break;
      }
    });

    return initialParamObj;
  };

  const removeFromPillsFilter = (pillsFilterObj: any) => {
    let initialFilterList: any = [...filterList];
    const initialFilterObj = find(initialFilterList, { id: pillsFilterObj.id });
    if (initialFilterObj.id === 'status' || initialFilterObj.id === 'intentFilter') {
      initialFilterObj.selectedFilter = filter(initialFilterObj.selectedFilter, (obj) => {
        return obj.value !== pillsFilterObj.name;
      });
    }

    if (initialFilterObj.id === 'conditions') {
      initialFilterObj.selectedFilter = filter(initialFilterObj.selectedFilter, (obj) => {
        return obj.key !== pillsFilterObj.key;
      });
    }

    if (initialFilterObj.id === 'careTeamFilter') {
      initialFilterObj.selectedFilter = filter(initialFilterObj.selectedFilter, (obj) => {
        return obj.id !== pillsFilterObj.key;
      });
    }

    if (initialFilterObj.id === 'orgFilter') {
      initialFilterObj.selectedFilter = filter(initialFilterObj.selectedFilter, (obj) => {
        return obj.name !== pillsFilterObj.name;
      });
    }

    if (initialFilterObj.id === 'titleFilter') {
      initialFilterObj.selectedFilter = [];
    }

    const updatedFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === pillsFilterObj.id) {
        return { ...obj, initialFilterObj };
      } else {
        return { ...obj };
      }
    });
    updateParamObjAfterremovePills(updatedFilterList);
    updatePillsDataAfterRemovePills(updatedFilterList);
    setFilterList(updatedFilterList);
  };

  const updatePillsDataAfterRemovePills = (updatedFilterList: any) => {
    const pillsData: any = [];
    updatedFilterList.forEach((obj: any) => {
      switch (obj.id) {
        case 'orgFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({
              name: item.name,
              label: 'Organization',
              id: obj.id,
              key: item.id
              // hideIcon: queryParams.id ? true : false
            });
          });
          break;
        case 'status':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({
              name: item.value,
              label: 'Status',
              id: item.id,
              key: item.key
              // hideIcon: queryParams.id ? true : false
            });
          });
          break;
        case 'conditions':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({
              name: item.value,
              label: 'Conditions',
              id: item.id,
              key: item.key
              // hideIcon: queryParams.id ? true : false
            });
          });
          break;
        case 'careTeamFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({
              name: item.value,
              label: 'CareTeams',
              id: item.id,
              key: item.id
              // hideIcon: queryParams.id ? true : false
            });
          });
          break;
        case 'intentFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({
              name: item.value,
              label: 'Intent',
              id: item.id,
              key: item.key
              // hideIcon: queryParams.id ? true : false
            });
          });
          break;
        case 'titleFilter':
          obj.selectedFilter.forEach((item: any) => {
            pillsData.push({
              name: item.value,
              label: 'Title',
              id: item.id,
              key: item.key
              // hideIcon: queryParams.id ? true : false
            });
          });
          break;
      }
    });
    setFilterPillsData(pillsData);
  };

  const saveSelection = () => {
    dispatch(setManifest({ key: 'globalCarePlanTemplates', value: selectedItems }));
    toggleModal();
  };

  const updateParamObjAfterremovePills = (updatedFilterList: any) => {
    const initialParamObj: any = { ...paramObj };
    updatedFilterList.forEach((obj: any) => {
      initialParamObj[obj.id] = obj.selectedFilter;
      // switch (obj.id) {
      //   case 'orgFilter':
      //     //initialParamObj[obj.id] = map(obj.selectedFilter, 'id').join(',');
      //     initialParamObj[obj.id] = map(obj.selectedFilter, 'id');
      //     break;
      //   case 'statusFilter':
      //     //initialParamObj[obj.id] = map(obj.selectedFilter, 'key').join(',');
      //     initialParamObj[obj.id] = map(obj.selectedFilter, 'key');
      //     break;
      // }
    });
    setParamObj(initialParamObj);
    setIsLoading(true);
    const {
      searchText = '',
      currentPage: filterCurrentPage = AppConstants.DEFAULT_PAGE,
      size: filterSize = type === 'listing'
        ? AppConstants.DEFAULT_PAGE_SIZE
        : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
      sortName = '',
      sortType = ''
    } = globalFilters['careplan-list'] || {};

    dispatch(
      setTableOptions({
        ['careplan-list']: {
          searchText: searchText,
          currentPage: filterCurrentPage,
          size: filterSize,
          sortName: sortName,
          sortType: sortType,
          tableFilters: initialParamObj
        }
      })
    );
  };

  const refreshTableData = (
    search: string = '',
    page: number,
    size: number,
    sortOrder: string = '',
    sortType: string = '',
    filters: any
  ) => {
    setTableValues(page, size, search, sortOrder, sortType, filters);
  };

  const setTableValues = (
    page: number,
    size: number,
    search: string,
    sortOrder: string = '',
    sortType: string = '',
    filters: any
  ) => {
    if (type === 'migrationPreview') {
      setCurrentActivePage(page * size - size);
    }
    setIsLoading(true);
  };

  const elementSelector = (data: any, step: number) => {
    if (
      type === 'listing' &&
      roleAPIPermissions[
        (AppConstants.PERMISSION_CAREPLAN_TEMPLATE_GET,
        AppConstants.PERMISSION_CAREPLAN_TEMPLATE_PATCH)
      ]
    ) {
      const { id } = data;
      dispatch(setCareplanData(data));
      navigate(RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_VIEW.replace(':id', id));
    } else {
      dispatch(
        setToastData({
          toastMessage: 'User do not have permission to view Survey',
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const removeSelection = () => {};

  return (
    <LoaderContainer isLoading={isLoading}>
      <TableContainer className={`${type !== 'listing' && '!pb-0'}`}>
        {type === 'listing' ? (
          <MenuButtonsPortal>
            {roleAPIPermissions[AppConstants.PERMISSION_CAREPLAN_TEMPLATE_CREATE] && (
              <BiButton
                className={'flex flex-row bg-primary text-white'}
                type="button"
                onClick={() => {
                  navigate(RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_CREATE);
                }}
              >
                <img src={AddCircle} className="mr-2 w-6 text-center"></img>
                {t('T_NEW_CAREPLAN')}
              </BiButton>
            )}
          </MenuButtonsPortal>
        ) : (
          ''
        )}

        {/* <TemplateTable
          tableData={
            type === 'migrationPreview'
              ? rows.slice(currentActivePage, currentActivePage + size)
              : rows
          }
          onRefreshTableData={refreshTableData}
          size={size.toString()}
          currentPage={currentPage.toString()}
          isSearchable={type !== 'migrationPreview'}
          templateSelector={applicationSelector}
          cellSelector={applicationSelector}
          searchPlaceholder={t('T_SEARCH_BY_TEMPLATE_TITLE')}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={paging}
          theme={useTheme().theme}
          containerClass="mt-0"
          deleteOnClick={confirmDelete}
          setCurrentPage={setCurrentPage}
          isLocalPaginator={type === 'migrationPreview'}
          totalDataLength={rows.length}
          isPaginated={type !== 'migrationPreview'}
          disablePageSize={type !== 'listing'}
        /> */}
        <TemplateTable
          tableData={
            type === 'migrationPreview'
              ? rows.slice(
                  currentActivePage,
                  currentActivePage +
                    (globalFilters['careplan-list'] ? globalFilters['careplan-list'].size : 1)
                )
              : rows
          }
          templateSelector={elementSelector}
          onRefreshTableData={refreshTableData}
          isSearchable={false}
          searchPlaceholder={t('T_SEARCH_NAME_CARE_PLAN_TITLE_TEMPLATE') || ''}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={paging}
          containerClass="mt-0"
          showDeleteButton={true}
          theme={useTheme().theme}
          isLocalPaginator={type === 'migrationPreview'}
          totalDataLength={rows.length}
          isPaginated={type !== 'migrationPreview'}
          disablePageSize={type !== 'listing'}
          deleteOnClick={removeSelection}
          isFilterable={true}
          filtersList={filterList}
          applyFilters={applyFilters}
          pillsData={filterPillsData}
          removeFilter={removeFromPillsFilter}
          defaultPillsLabel={t('T_CONFIG_CATEGORIES_FILTERS_DEFAULT_VIEW')}
          globalFilters={globalFilters}
          tablePage="careplan-list"
          type={type}
          filterWidth={80}
        />
      </TableContainer>
      {/* {deleteConfirmation && (
        <DeleteConfirmModal
          width={'35rem'}
          title={t('T_DELETE_CONFIRMATION_TITLE')}
          onClose={() => confirmDelete(-1, false)}
          children={
            <div>
              <span>
                {t('T_DELETE_CONFIRMATION_TEXT')}
                <b>{rows[deletableIndex]?.name} </b>
                {t('T_APPLICATION')}?
              </span>
            </div>
          }
          onCancel={() => confirmDelete(-1, false)}
          // confirmClassName={'rose-600'}
          onConfirm={() => {
            setDeleteConfirmation(false);
            setTimeout(() => removeApplication(deletableIndex));
          }}
        />
      )} */}
      {type === 'migrationSelection' ? (
        <div className="w-full">
          <ActionButtons
            primaryButtonLabel={t('T_SAVE')}
            primaryButtonAction={saveSelection}
            secondaryButtonLabel={t('T_CANCEL')}
            secondaryButtonAction={toggleModal}
            primaryButtonWidth="full"
            primaryButtonClass={'px-4'}
          />
        </div>
      ) : (
        ''
      )}
    </LoaderContainer>
  );
};

export default CarePlanTemplateList;
