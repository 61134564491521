import {BrowserRouter} from 'react-router-dom';
import {Routes} from './routes/Routes';
import {GlobalStyle} from './styles/globalStyles';
import './styles/tailwindstyles.css';
import {initialTheme, ThemeContext} from "./context/themeContext";
import {useEffect, useState} from "react";
import {getThemColors} from "./http/asset-management-services";
import {updateTheme} from "./context/Themes";
import LoaderContainer from "./components/shared/loaderContainer/LoaderContainer";

export const App = (): JSX.Element => {
    const [theme, setTheme] = useState(initialTheme);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getThemColors().then(res => {
            if (res) {
                const {primaryColor, secondaryColor, hoverColor, striveColor} = res;
                const updatedTheme = updateTheme(primaryColor, secondaryColor, hoverColor, striveColor);
                setTheme(updatedTheme);
                setLoading(false);
            }
        });
    }, []);

    if (!loading) {
        return (
            <ThemeContext.Provider value={{theme, setTheme}}>
                <BrowserRouter>
                    <GlobalStyle/>
                    <Routes/>
                </BrowserRouter>
            </ThemeContext.Provider>
        );
    } else {
        return (<LoaderContainer isLoading={!loading}>
        </LoaderContainer>)
    }
};
