import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const HeadingBar = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 42px;
  border-bottom: 1px solid #d9d9d9;
`;

const HeadingIconContainer = styled.label`
  height: 30px;
  width: 30px;
  background: #eaf5fa;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const DragIconContainer = styled.label`
  height: 30px;
  width: 30px;
  // background: #eaf5fa;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const HeadingIcon = styled.img`
  height: 17px;
  width: 17px;
`;

const HeadingLabel = styled.label`
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  margin: 0px 2px;
`;

const Body = styled.section<BodyStyles>`
  padding: 20px;
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: ${({ flex }) => flex};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

type BodyStyles = {
  justifyContent?: string;
  flex?: number;
  display?: string;
  flexDirection?: string;
};

type Props = {
  title: string;
  icon: string | JSX.Element;
  alt: string;
  height?: string;
  children: any;
  width?: string;
  justifyContent?: string;
  flex?: number;
  display?: string;
  flexDirection?: string;
  desktopWidth?: string;
  className?: string;
  bodyStyle?: any;
  actions?: JSX.Element;
  headingClass?: string;
  dragIcon?: string | JSX.Element;
};

const Card = (props: Props) => {
  const {
    alt,
    icon,
    title,
    children,
    justifyContent,
    flex,
    display,
    flexDirection,
    className,
    bodyStyle,
    actions,
    headingClass = '',
    dragIcon = null
  } = props;
  return (
    <section
      data-testId={title}
      className={`mb-4 flex flex-col rounded-lg border border-solid border-white bg-white ${className}`}
    >
      <HeadingBar className={headingClass}>
        {dragIcon && (
          <DragIconContainer>
            {typeof dragIcon === 'string' ? (
              <HeadingIcon src={dragIcon} alt={alt} />
            ) : (
              <div>{dragIcon}</div>
            )}
          </DragIconContainer>
        )}
        <HeadingIconContainer>
          {typeof icon === 'string' ? <HeadingIcon src={icon} alt={alt} /> : <div>{icon}</div>}
        </HeadingIconContainer>
        <HeadingLabel>{title}</HeadingLabel>
        {actions}
      </HeadingBar>
      <Body
        justifyContent={justifyContent}
        flex={flex}
        display={display}
        flexDirection={flexDirection}
        style={bodyStyle}
      >
        {children}
      </Body>
    </section>
  );
};

export default Card;
