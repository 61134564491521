import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logOut } from '../../app/loginReducer';
import { LogoutRequestBody } from '../../app/models/auth/loginUser';
import { logout } from '../../http/authentication-services';
import { resetOrganizationManagement } from '../../app/organizationReducer';
import { dropdown } from '../../assets/components/svgs';
import { useOutsideDetect } from '../../util/customHooks';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import MenuLogo from './MenuLogo';
import {
  DropdownButton,
  Dropdown,
  DropdownItem,
  EmailContainer,
  Name,
  Role,
  SignOutButton,
  UserContainer
} from './MenuStyles';
import { fetchFromCookie, getUserName, getUserRoles } from '../../util/admin-utils';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';

type Props = {
  email?: JSX.Element;
};

const MenuTop = ({ email }: Props) => {
  const wrapperRef = useRef(null);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownIsActive, setDropdownIsActive] = useState(false);
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);
  const { t } = useTranslation();

  useOutsideDetect(wrapperRef, () => {
    setDropdownIsActive(false);
  });

  const deleteCookies = async () => {
    document.cookie = 'session-jwt' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'iPlanetDirectoryPro' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'amlbcookie' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  const signOut = async () => {
    setIsLoading(true);
    const logoutBody: LogoutRequestBody = {
      client_id: 'oidc_pkce',
      token: fetchFromCookie('access-token') || '' //localStorage.getItem('access-token') || ''
    };
    await logout(logoutBody);
    dispatch(logOut());
    dispatch(resetOrganizationManagement());
    deleteCookies();
    return `${t('T_LOGOUT_MSG')}`;
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <MenuLogo>
          {email ? <EmailContainer>{email}</EmailContainer> : null}
          <Dropdown marginLeft={!!email}>
            <UserContainer>
              <Name>{getUserName(userProfile)}</Name>
              <Role>
                {getUserRoles(userProfile)
                  .map((role: any) => role.name)
                  .join()}
              </Role>
            </UserContainer>
            <DropdownButton
              role="dropdownbutton"
              onClick={() => setDropdownIsActive(!dropdownIsActive)}
              theme={useTheme().theme}
            >
              {dropdown}
            </DropdownButton>
            {dropdownIsActive && (
              <DropdownItem ref={wrapperRef} style={{ zIndex: '1' }}>
                <Link to="/profile" onClick={() => setDropdownIsActive(!dropdownIsActive)}>
                  {t('T_MY_ACCOUNT')}
                </Link>
              </DropdownItem>
            )}
          </Dropdown>
          <SignOutButton onClick={signOut} theme={useTheme().theme}>
            {t('T_SIGN_OUT')}
          </SignOutButton>
        </MenuLogo>
      </LoaderContainer>
    </>
  );
};

export default MenuTop;
