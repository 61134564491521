export class MenuConstants {
  static readonly MENU_MANAGE_CONTRACTS = 'menu_manage_contracts';
  static readonly MENU_INVITE_ADMINISTRATORS = 'menu_invite_admins';
  static readonly MENU_VIEW_ADMINISTRATORS = 'menu_view_admins';
  static readonly MENU_EDIT_ORGANIZATION_DETAILS = 'menu_edit_organization_details';
  static readonly MENU_VIEW_ORGANIZATION_TEMPLATES = 'menu_view_organization_templates';
  static readonly MENU_NEW_CHILD_ORGANIZATION = 'menu_child_organization';
  static readonly MENU_VIEW_CATEGORIES = 'menu_view_categories';
  static readonly MENU_VIEW_SETTINGS = 'menu_view_settings';

  //Menu constants in survey template question card

  static readonly MENU_EDIT_QUESTION_ANSWERS = 'edit_qa';
  static readonly MENU_VIEW_ANSWERS = 'view_answers';
  static readonly MENU_MARK_AS_READ_ONLY = 'mark_read_only';
  static readonly MENU_REMOVE_READ_ONLY = 'remove_read_only';
  static readonly MENU_REMOVE_QUESTION = 'remove_qa';
  static readonly MENU_REMOVE_GROUP = 'remove_group';

  // Menu constants for Metadata
  static readonly MENU_EDIT_METADATA = 'menu_edit_metadata';
  static readonly MENU_METADATA_LIST_OBJECT = 'menu_metadata_list_object';
  static readonly MENU_METADATA_ADD_OBJECT = 'menu_metadata_add_object';

  static readonly MENU_EDIT_METADATA_OBJECT = 'menu_edit_metadata_object';
  static readonly MENU_VIEW_METADATA_OBJECT = 'menu_metadata_view_object';
  static readonly MENU_DELETE_METADATA_OBJECT = 'menu_metadata_delete_object';

  static readonly MENU_LIST_METADATA_INSTANCE = 'menu_metadata_list_instance';
  static readonly MENU_VIEW_METADATA_INSTANCE = 'menu_metadata_view_instance';
  static readonly MENU_EDIT_METADATA_INSTANCE = 'menu_edit_metadata_instance';
  static readonly MENU_DELETE_METADATA_INSTANCE = 'menu_metadata_delete_instance';
  static readonly MENU_CREATE_METADATA_INSTANCE = 'menu_metadata_create_instance';

  static readonly CONFIG_KEY_CREATE_SETTINGS = 'config_key_settings_create';
  static readonly CONFIG_KEY_VIEW_CATEGORIES = 'config_key_view_categories';
  static readonly CONFIG_KEY_CREATE_NEW_CATEGORY = 'config_key_create_new_category';
  static readonly CONFIG_KEY_CATEGORY_DELETE = 'config_key_category_delete';

  static readonly MENU_VIEW_LOG = 'migration_view_log';
  static readonly MENU_DOWNLOAD_ZIP = 'migration_download_zip';
  static readonly MENU_JOB_DELETE = 'migration_job_delete';
  static readonly MENU_JOB_CANCEL = 'migration_job_cancel';
  static readonly MENU_JOB_RETRIGGER = 'migration_job_retrigger';
  static readonly MENU_JOB_START = 'migration_job_start';
  static readonly MENU_JOB_STOP = 'migration_job_stop';
  static readonly MENU_JOB_MODIFY = 'migration_job_modify';

  static readonly SURVEY_BODY_MAP_IMAGE_DELETE = 'body_map_image_delete';
  static readonly SURVEY_BODY_MAP_IMAGE_DOWNLOAD = 'body_map_image_download';
}
