import { RouteConstants } from '../../constants/RouteConstants';
import { getLoggedInOrgName } from '../../util/admin-utils';

const orgName = getLoggedInOrgName();

const urlPaths = [
  { key: RouteConstants.ROUTE_OS_ORGANIZATION_LISTING, label: orgName || '' },
  { key: '/profile', label: 'T_PROFILE' },
  { key: '/roles', label: 'T_ROLE_MANAGEMENT' },
  { key: '/templates', label: 'T_NOTIFICATION_TEMPLATES' },
  { key: '/admin-list', label: 'T_USERS' }
];

export default urlPaths;
