import { createSlice } from '@reduxjs/toolkit';
import { AppConstants } from '../constants/AppConstants';
import { filterEncounters } from '../components/CarePlan/carePlanUtils';
import { isArray } from 'lodash';

const initialState: any = {
  careplanData: {} as any,
  conditionsList: [],
  careTeamList: [],
  patientUsersList: [],
  encounterList: [],
  orgList: [],
  deviceList:[],
  practitionerList: [],
  practitionerRoleList: [],
  formData: {
    title: '',
    intent: '',
    description: '',
    category: [],
    encounter: '',
    authorCategory: [],
    author: [],
    contributorCategory: [],
    periodStart: '',
    periodEnd: '',
    contributor: [],
    careTeam: [],
    conditions: [],
    organization: [],
    status: { key: 'draft', value: 'Draft' }
  },
  formError: {
    description: '',
    author: '',
    contributor: '',
    periodStart: '',
    periodEnd: ''
  } as any
};
export const carePlanReducer = createSlice({
  name: 'carePlanData',
  initialState,
  reducers: {
    setCareplanData: (state, action) => {
      state.careplanData = action.payload;
    },
    setConditionsList: (state, action) => {
      state.conditionsList = action.payload;
    },
    setCareTeamsList: (state, action) => {
      state.careTeamList = action.payload;
    },
    setPatientUserList: (state, action) => {
      state.patientUsersList = action.payload;
    },
    setEncounterList: (state, action) => {
      state.encounterList =action.payload;
    },
    setOrgList: (state, action) => {
      state.orgList = action.payload;
    },
    setDeviceList: (state, action) => {
      state.deviceList = action.payload;
    },
    setPractitionerList: (state, action) => {
      state.practitionerList = action.payload;
    },
    setPractitionerRoleList: (state, action) => {
      state.practitionerRoleList = action.payload;
    },
    setFormData: (state, action) => {
      const updateObject = action.payload;
      Object.keys(updateObject).forEach((key) => {
        state.formData[key] = updateObject[key];
      });
    },
    setFormError: (state, action) => {
      const updateObject = action.payload;
      Object.keys(updateObject).forEach((key) => {
        state.formError[key] = updateObject[key];
      });
    },
    resetFormData: (state) => {
      state.formData = initialState.formData;
    },
    resetCarePlanReducer: () => initialState
  }
});

export const {
  setConditionsList,
  setCareTeamsList,
  setOrgList,
  setFormData,
  setPatientUserList,
  setCareplanData,
  resetCarePlanReducer,
  setFormError,
  resetFormData,
  setEncounterList,
  setDeviceList,
  setPractitionerList,
  setPractitionerRoleList
} = carePlanReducer.actions;

export default carePlanReducer.reducer;
