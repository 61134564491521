import { find, isEmpty, omit } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setConfigList, setSelectedConfig } from '../../app/mobileConfigurationReducer';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import { camera, downloadIcon, resetIcon } from '../../assets/components/svgs';
import { AppConstants } from '../../constants/AppConstants';
import { RouteConstants } from '../../constants/RouteConstants';
import { createSignedURL, listFiles, uploadFile } from '../../http/asset-management-services';
import { getSystemSettings, updateSystemSetting } from '../../http/configuration-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { updateMetadataInstance } from '../../http/metadata-service';
import { getUsers } from '../../http/user-management-services';
import { getLoginUserId, getUserName } from '../../util/admin-utils';
import { getBucketName, getGlobalBucketName } from '../../util/AppSetting';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import Card from '../shared/cards/Card';
import SelectField from '../shared/Fields/SelectField';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import Modal from '../shared/ModalPortal/Modal';
import { useTheme } from '../../context/themeContext';
import { getCatalogConfigData, getFileConfiguration, updateGCPFile } from './MobileConfigUtil';

/**
 * @parentId mobile-configuration
 * @manager Mobile Configuration Image
 * @overview
 *  <section>
 *  <p>
 *    <b>MobileFileConfig</b> is a catalog item which stores the paths to various files used in the DMS Mobile Application. These include background images, logo files etc. The List of options are driven from metadata. These include both public and private files. Public Files are accessible without any authentication whereas private files require authentication.
 *  </p>
 *  <br />
 *       <p>
 *           <b>View File</b><br/>
 *           User Can select the list of file options from the dropdown list, on clicking of the dropdown item the details of the image/file are populated.
 *           <br/><br/>
 *
 *           <b>Download File</b><br/>
 *           After Selecting a file, a download button is located at the button, on clicking of it will download the image/file.
 *           <br/><br/>
 *
 *           <b>Update File</b><br/>
 *           After Selecting a file, user can click on Change Image button, upon which a system popup will become visible asking the user to select the new file. Upon the file selection the save button will now become visible and on clicking of the button will initiate the save process.
 *           <br/><br/>
 *           <b>Note: </b>The format supported for files depends on the details stored on metadata for the particular file object.<br/>
 *           <br/><br/>
 *       </p>
 *  </section>
 *  <section>
 *  <h4>Failure Status Codes</h4>
 *  <p> This section describes the Mobile Configuration Status Code information. <br>
 *  <table>
 *    <tr>
 *      <th>HTTP Status Code</th>
 *      <th>Service Error Code</th>
 *      <th>Error Message</th>
 *    </tr>
 *    <tr>
 *      <td>403</td>
 *      <td>CS_PERMISSION_DENIED</td>
 *      <td>You do not have permission to view this page.</td>
 *    </tr>
 *    <tr>
 *      <td>500</td>
 *      <td>CS_INTERNAL_ERROR</td>
 *      <td>Internal Server Error</td>
 *    </tr>
 *    <tr>
 *      <td>503</td>
 *      <td></td>
 *      <td>Service Unavailable</td>
 *    </tr>
 *  </table>
 *  </p>
 *  </section> <section>
 *  <h4>Dependent System settings, Platform services & Role Permission </h4>
 *  <p>This section describes the list of dependent system settings & platform services required for functioning of Mobile Configuration Image page.</p>
 *  <h5>System Settings
 *  </h5>
 *  <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *  <br>
 *  <table>
 *  <tr>
 *    <th>Key</th>
 *    <th>Type</th>
 *    <th>Value</th>
 *    <th>Scope</th>
 *  </tr>
 *  <tr>
 *    <td>None</td>
 *    <td>None</td>
 *    <td>None</td>
 *    <td>None</td>
 *  </tr>
 *  </table>
 *  <br>
 *  <h5>Platform Service(s) </h5>
 *  <p>Table lists all the dependent platform service(s) with specific version(s) for  Mobile Configuration Image.</p>
 *  <br>
 *  <table>
 *    <tr>
 *      <th>Service Name</th>
 *      <th>Version</th>
 *    </tr>
 *    <tr>
 *      <td>Configuration Service</td>
 *      <td>1.3.0</td>
 *    </tr>
 *    <tr>
 *    <td>Metadata Service</td>
 *    <td>1.1.0</td>
 *    </tr>
 *    <tr>
 *    <td>Asset Management Service</td>
 *    <td>1.0.0</td>
 *    </tr>
 *  </table>
 *  <br>
 *  <h5>API Role Permission(s) </h5>
 *    <p>Table lists the required API role permissions for Mobile Configuration Image.</p>
 *    <br>
 *    <table>
 *      <tr>
 *        <th>Feature</th>
 *        <th>API URL</th>
 *        <th>API Method</th>
 *        <th>API Permission</th>
 *        <th>Required</th>
 *      </tr>
 *      <tr>
 *        <td>View Private Values</td>
 *        <td>/system-settings/</td>
 *        <td>GET</td>
 *        <td>configuration-service.setting.list</td>
 *        <td>Yes</td>
 *      </tr>
 *      <tr>
 *        <td>Edit Private Values</td>
 *        <td>/system-settings/batch</td>
 *        <td>PUT</td>
 *        <td>configuration-service.setting.update.batch</td>
 *        <td>Yes</td>
 *      </tr>
 *      <tr>
 *             <td>Get Metadata Instance</td>
 *             <td>/objects/{id}/instances</td>
 *             <td>GET</td>
 *             <td>metadata-service.metadata-instance.list</td>
 *             <td>Yes</td>
 *      </tr>
 *      <tr>
 *             <td>Update Metadata Instance</td>
 *             <td>/objects/{id}/instances/{id}</td>
 *             <td>PUT</td>
 *             <td>metadata-service.metadata-instance.update</td>
 *             <td>Yes</td>
 *      </tr>
 *       <tr>
 *             <td>Create Signed URL</td>
 *             <td>/signed-url</td>
 *             <td>POST</td>
 *             <td>asset-management-service.signed-url.create</td>
 *             <td>Yes</td>
 *      </tr>
 *    </table>
 *    </section>
 */

const MobileConfigurationImageComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectFile, setSelectFile] = useState<any>({ file: '' });
  const [selectFileError, setSelectFileError] = useState<any>({});
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);
  const [defaultImageData, setDefaultImageData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>({});
  const [filePreview, setFilePreview] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [isFileDeleted, setIsFileDeleted] = useState<any>({});
  const [currentFile, setCurrentFile] = useState<any>();
  const [currentFileType, setCurrentFileType] = useState<any>(null);
  const [tempDropdown, setTempDropdown] = useState<any>({ file: '' });
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [globalConfiguration, setGlobalConfiguration] = useState<any>(null);
  const [explicitValidation, setExplicitValidation] = useState<any>({
    fileSize: { value: false, errorText: '' },
    fileExtension: { value: false, errorText: '' }
  });
  const [resetData, setResetData] = useState<any>({
    fileName: '',
    filePreview: null,
    currentFileType: null
  });
  const fileRef = useRef<any>(null);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('user-profile') || '');
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const selectedCatalog = useAppSelector((state) => state.mobileConfiguration.selectedConfig);
  const configCatalog = useAppSelector((state) => state.mobileConfiguration.configCatalog);
  const config = 'MobileFileConfig';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCancel = () => {
    navigate(-1);
  };
  const handleValidation = () => {
    let isSaveDisabled = true;
    if (file && Object.keys(file).length) {
      isSaveDisabled = false;
    }
    return isSaveDisabled;
  };

  const handleSave = async () => {
    function errorHandling(errorText: string) {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: errorText,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }

    setIsLoading(true);
    setConfirmToggle(false);

    let flag = false,
      msg = '',
      key = selectFile.file || tempDropdown.file;

    const imageData = defaultImageData[key];

    const { attributes, value } = imageData;
    const { keyType } = attributes;
    let globalInstances = { ...globalConfiguration };
    const fileObj = key.split('.')[0] + getOption(key.split('.')[1]);
    if (file[fileObj]) {
      const path = `${AppConstants.MOBILE_CONFIGURATION_IMAGES_PATH}/${key}.${
        file[fileObj]?.type.split('/')[1].split('+')[0]
      }`;

      const payload = {
        filePath: path,
        bucketName: getGlobalBucketName(),
        versioning: true,
        action: AppConstants.GCS_UPLOAD_ACTION,
        publicObject: keyType === 'PUBLIC',
        expirationTime: AppConstants.GCS_MAX_EXPIRATION_TIME,
        metadata: {
          fileName: file[fileObj].name,
          key: key,
          userId: userData ? userData.userId : '',
          [AppConstants.HEADER_CACHE_CONTROL]: AppConstants.VALUE_NO_CACHE,
          [AppConstants.HEADER_MAX_AGE]: AppConstants.VALUE_MAX_AGE_MINIMUM
        }
      };

      const signedUrlResponse = await createSignedURL(payload);

      if (signedUrlResponse.status === HTTP_STATUS.HTTP_CREATED) {
        let { url, headers = {} } = signedUrlResponse.data;

        const uploadPayload = {
          url,
          body: file[fileObj],
          headers: { ...headers, [AppConstants.HEADER_CONTENT_TYPE]: file[fileObj]?.type }
        };

        let uploadFileRes = await uploadFile(
          uploadPayload,
          () => {},
          (err: string) => {
            errorHandling(t('T_NOT_UPLOADED'));
          }
        );

        if (uploadFileRes.status === HTTP_STATUS.HTTP_OK) {
          url = url.split('?')[0];
          if (keyType === 'PUBLIC') {
            globalInstances[config][key].url = url;
            globalInstances[config][key].type = file[fileObj].type.split('+')[0];
            globalInstances[config][key].fileName = file[fileObj].name;
            let updateLanguageConfigVersion: any = await updateGCPFile(
              JSON.stringify(globalInstances, null, '\t'),
              AppConstants.GLOBAL_MOBILE_CONFIG_FILE,
              getLoginUserId(),
              key
            );

            if (updateLanguageConfigVersion.status === HTTP_STATUS.HTTP_OK) {
              flag = true;
            }
          } else {
            const body = {
              ...value,
              value: {
                type: 'uri',
                fileName: `${key}.${file[fileObj]?.type.split('/')[1].split('+')[0]}`,
                filePath: AppConstants.MOBILE_CONFIGURATION_IMAGES_PATH,
                mimeType: file[fileObj]?.type,
                bucketName: getGlobalBucketName()
              },
              categories: value.categories.map((category: any) => category.id)
            };

            const patchResponse = await updateSystemSetting(value.id, body);
            if (patchResponse.status === HTTP_STATUS.HTTP_OK) {
              flag = true;
            }
          }

          if (flag) {
            let instanceId: string = '',
              objectId: string = '',
              configAttributes: any = [];
            if (configCatalog) {
              const { id, metadataObjectId, attributes } = configCatalog;
              (instanceId = id), (objectId = metadataObjectId), (configAttributes = attributes);
            }
            const { catalog } = configAttributes;
            const fileCategory = selectedCatalog?.category ? selectedCatalog.category : config;

            const newCatalog = catalog.map((catalogData: any) => {
              if (catalogData.category !== fileCategory) {
                return catalogData;
              } else {
                return {
                  ...catalogData,
                  version: catalogData.version + 1,
                  updatedBy: userData?.userId,
                  updatedOn:
                    moment(new Date(), 'YYYY-MM-DD hh:mm:ss').toISOString().split('.')[0] + 'Z'
                };
              }
            });
            const body = {
              attributes: { ...configAttributes, catalog: newCatalog },
              label: configCatalog.label || configCatalog.attributes?.type
            };
            const response = await updateMetadataInstance(objectId, instanceId, body);
            if (response.status === HTTP_STATUS.HTTP_OK) {
              dispatch(
                setToastData({
                  toastMessage: `${key} ${t('T_UPDATED_MESSAGE')}`,
                  isToastActive: true,
                  toastType: 'success'
                })
              );
              setFile(null);
            }
            setIsLoading(false);
          }
        } else {
          errorHandling(t('T_NOT_UPLOADED'));
        }
      } else {
        errorHandling(t('T_NOT_UPLOADED'));
      }
    } else {
      errorHandling(t('T_NOT_UPLOADED'));
    }
  };

  const handleChangeImage = () => {
    fileRef.current.click();
  };

  const resetExplicitValidation = () => {
    setExplicitValidation({
      fileSize: { value: false, errorText: '' },
      fileExtension: { value: false, errorText: '' }
    });
  };

  const resetImage = () => {
    const { fileName, filePreview, currentFileType } = resetData;
    setFile(null);
    setFileName({ ...fileName });
    setFilePreview(filePreview);
    setCurrentFileType(currentFileType);
    resetExplicitValidation();
  };

  const discardChange = () => {
    const { fileName, filePreview, currentFileType } = resetData;
    setFile(null);
    setConfirmToggle(false);
    setSelectFile(tempDropdown);
    handleDefaultFilePreview(tempDropdown.file, '');
    setTempDropdown({ file: '' });
  };

  const handleChange = (e: any) => {
    const { attributes } = defaultImageData[selectFile.file];
    const { validator, description } = attributes || {};
    const { rules } = validator || {};

    const maxConstraint = rules?.filter((rule: any) => rule.max)[0];
    const regExConstraint = rules?.filter((rule: any) => rule.regEx)[0];

    const maxSize = maxConstraint?.max || AppConstants.MAX_FILE_SIZE;
    const regEx =
      regExConstraint?.regEx ||
      '([a-zA-Z0-9s_\\.-:])+(' + AppConstants.ACCEPT_FILE_TYPE.join('|') + ')$';

    let tempFile: any = file ? { ...file } : {};
    let tempIsFileDeleted: any = { ...isFileDeleted };
    tempIsFileDeleted?.[currentFile] && delete tempIsFileDeleted[currentFile];

    const uploadedFile = e.target.files[0];
    const { size = '' } = e.target.files[0];
    const name = uploadedFile?.name || uploadedFile?.fileName;

    if (size > maxSize) {
      setExplicitValidation({
        ...explicitValidation,
        fileSize: { value: true, errorText: maxConstraint?.error || t('T_FILE_SIZE_ERROR_MSG') }
      });
    } else {
      resetExplicitValidation();
    }

    if (!new RegExp(regEx, 'ig').test(name.toLowerCase())) {
      setExplicitValidation({
        ...explicitValidation,
        fileExtension: {
          value: true,
          errorText: regExConstraint?.error || t('T_INVALID_FILE_EXTENSION')
        }
      });
    } else {
      resetExplicitValidation();
    }

    tempFile = { ...tempFile, [currentFile]: uploadedFile };

    setFileName({ ...fileName, [currentFile]: name });
    setFile(tempFile);
    fileRef.current.value = '';
  };

  const getOption = ([firstCharacter, ...remaining]: any) => {
    return [firstCharacter.toUpperCase(), ...remaining].join('');
  };

  const downloadDefaultImage = async () => {
    let mobileConfigCatalog = selectedCatalog;
    if (isEmpty(mobileConfigCatalog)) {
      await getCatalogConfigData(
        metaDataObjectList,
        dispatch,
        true,
        (data: any, paging: any) => {
          mobileConfigCatalog = data.filter((item: any) => item.category === config)[0]; //TODO: check from route
          dispatch(setSelectedConfig(mobileConfigCatalog));
        },
        (paging: any) => {
          navigate(RouteConstants.ROUTE_MOBILE_CONFIG_LIST);
        }
      );
    }

    const { category: configCategory = {} } = mobileConfigCatalog;

    let getFileConfigData = await getFileConfiguration(metaDataObjectList, configCategory);
    const { fileName, fileKeyMap, dropdownOptions, fileConfig, globalInstances } =
      getFileConfigData;

    setFileName({ ...fileName });
    setResetData({ ...resetData, fileName });
    setDropdownOptions(dropdownOptions);
    setDefaultImageData(fileConfig);
    setIsLoading(false);
    setGlobalConfiguration(globalInstances);
  };

  const handleDownloadImage = async () => {
    const element = document.createElement('a');
    element.href = filePreview?.[currentFile];
    element.setAttribute('target', '_blank');
    element.download;
    document.body.appendChild(element);
    element.click();
  };

  useEffect(() => {
    dispatch(
      setURLPaths([
        {
          key: '/mobile-configuration/file',
          label: `Mobile Configuration > File`
        }
      ])
    );
  }, [currentFileType]);

  const init = async () => {
    setIsLoading(true);

    downloadDefaultImage();
  };

  useEffect(() => {
    init();
  }, []);

  const handleDefaultFilePreview = (mode: any, key: string) => {
    let tempPreview: any = filePreview ? { ...filePreview } : {};
    const keys = mode;
    const selectedFile = keys.split('.')[0] + getOption(keys.split('.')[1]);
    const image = defaultImageData[keys];
    let localType = file?.[selectedFile]
      ? file[selectedFile]?.type
      : image?.attributes?.keyType === 'PUBLIC'
      ? image?.value?.type
      : image?.value?.value?.mimeType;
    let localPreview = {
      ...tempPreview,
      [selectedFile]: filePreview?.[selectedFile]
        ? filePreview?.[selectedFile]
        : image.attributes.keyType === 'PUBLIC'
        ? image.value.url
        : image.value.value.url
    };
    setCurrentFile(selectedFile);
    setCurrentFileType(localType);

    setFilePreview(localPreview);
    setResetData({ ...resetData, filePreview: localPreview, currentFileType: localType });
    setIsLoading(false);
  };

  const setDropdownValue = (value: any) => {
    const { file: key } = value;
    if (key) {
      if (isEmpty(file)) {
        setIsLoading(true);
        setSelectFile(value);
        handleDefaultFilePreview(key, '');
      } else {
        setTempDropdown(value);
        setConfirmToggle(true);
      }
    } else {
      setCurrentFile(null);
      setFilePreview(null);
    }
  };

  useEffect(() => {
    let fileReader: any,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          let tempPreview: any = filePreview ? { ...filePreview } : {};
          tempPreview = { ...tempPreview, [currentFile]: result };
          setFilePreview(tempPreview);
          setCurrentFileType(file[currentFile]?.type);
        }
      };
      fileReader.readAsDataURL(file?.[currentFile]);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const descriptionBlock = () => {
    const { attributes } = defaultImageData[selectFile.file];
    const { validator, description } = attributes || {};
    const { rules } = validator || {};
    const maxSize = rules?.filter((rule: any) => rule.max)[0]?.max || '';
    return (
      <div className="mb-8 text-sm">
        {description ? <div className="mb-4">{description}</div> : ''}
        {/* <div>Files supported: JPG, PNG, SVG</div> */}
        {maxSize ? <div>Max Upload Size: {Math.floor(maxSize / 1024 / 1024)}MB</div> : ''}
      </div>
    );
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <MenuButtonsPortal>
        <BiButton
          className={'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'}
          type="button"
          onClick={handleCancel}
        >
          {t('T_CANCEL')}
        </BiButton>
        <BiButton
          className={'bg-primary py-1.5 text-white'}
          type="button"
          disabled={
            handleValidation() ||
            explicitValidation.fileSize.value ||
            explicitValidation.fileExtension.value
          }
          onClick={handleSave}
        >
          {t('T_SAVE')}
        </BiButton>
      </MenuButtonsPortal>

      <Modal
        showHeader={true}
        showCloseIcon={true}
        width="26.5rem"
        headerText={t('T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_HEADER')}
        headerStyles="text-base"
        toggleVisiblity={(value: boolean) => {
          setConfirmToggle(value);
        }}
        className="overflow-y-hidden"
        isVisible={confirmToggle}
        headerBackgroundColor="#FAFAFA"
        containerBackgroundColor="#FFFFFF"
        primaryButtonText={t('T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_YES')}
        primaryButtonWidth={'8rem'}
        secondaryButtonText={t('T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_NO')}
        secondaryButtonWidth={'8rem'}
        primaryOnClick={handleSave}
        secondaryOnClick={discardChange}
        body={
          !isLoading && (
            <Container>
              <div className="mb-4 text-sm font-normal">
                {t('T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_SUB')}
              </div>
            </Container>
          )
        }
      />

      <div
        className="flex w-full flex-row justify-start overflow-visible  px-2 py-3"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <div className="flex w-full flex-col">
          <div className="mb-6 flex w-1/2">
            <div className="p-2"></div>
            <div className="w-full">
              <SelectField
                type={'text'}
                id={'image-file-select'}
                label={t('T_SELECT_FILE')}
                placeholder={''}
                fieldKey={'file'}
                required={false}
                formData={selectFile}
                setFormData={setDropdownValue}
                formError={selectFileError}
                options={dropdownOptions}
                disabled={
                  explicitValidation.fileSize.value || explicitValidation.fileExtension.value
                }
              />
            </div>
          </div>
          {currentFile ? (
            <div className="flex w-3/4">
              <div className="p-2"></div>
              <Card
                title={currentFileType ? getOption(currentFileType?.split('/')[0]) : ''}
                icon={camera}
                alt={''}
                height={''}
                width={''}
                className="w-[67%]"
              >
                {selectFile.file ? descriptionBlock() : ''}

                <div className="flex flex-col items-center justify-center">
                  {filePreview ? (
                    <>
                      <Img src={filePreview?.[currentFile]} previewImg={filePreview} />
                    </>
                  ) : (
                    <div className="flex h-56 w-full items-center justify-center border-dotted border-[#D9D9D9] bg-gray-100">
                      <p className="text-5xl font-bold text-[#CACACA]">{t('T_IMAGE_HERE')}</p>
                    </div>
                  )}

                  <div className="my-4 flex flex-col">
                    {Object.entries(explicitValidation).map(([key, value]: any) => {
                      return value.value ? (
                        <div className="ml-2 text-sm text-red-500">{value.errorText}</div>
                      ) : (
                        ''
                      );
                    })}
                  </div>

                  <div className="mt-6 flex content-baseline justify-end">
                    {/* <BiButton
                      className={
                        'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'
                      }
                      type="button"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                      disabled={isFileDeleted?.[currentFile] ? true : false}
                    >
                      {trashIcon}
                    </BiButton> */}
                    {!file ? (
                      <BiButton
                        className={
                          'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'
                        }
                        type="button"
                        onClick={handleDownloadImage}
                      >
                        {downloadIcon}
                      </BiButton>
                    ) : (
                      <BiButton
                        className={
                          'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'
                        }
                        type="button"
                        onClick={resetImage}
                      >
                        {resetIcon}
                      </BiButton>
                    )}
                    <BiButton
                      className={
                        'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'
                      }
                      type="button"
                      onClick={handleChangeImage}
                    >
                      {currentFileType?.split('/')[0] !== 'image'
                        ? `${t('T_CHANGE_FILE')}`
                        : `${t('T_CHANGE_IMAGE')}`}
                    </BiButton>
                    <input
                      type="file"
                      ref={fileRef}
                      data-testid={'config-image'}
                      onChange={handleChange}
                      style={{ display: 'none' }}
                      // accept={AppConstants.ACCEPT_FILE_TYPE.join(', ')}
                    />
                  </div>
                </div>
              </Card>

              <div className="flex w-[33%] flex-col">
                <div className="ml-5">
                  <div>
                    <div>{t('T_FILE_NAME')}</div>
                    <div>{fileName?.[currentFile]}</div>
                  </div>
                  <div className="my-2.5">
                    <div>{t('T_FILE_TYPE')}</div>
                    <div>{currentFileType}</div>
                  </div>
                  <div>
                    <div>{t('T_LAST_MODIFIED')}</div>
                    <div>{selectedCatalog.lastModified}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </LoaderContainer>
  );
};

const Img = styled.img<any>`
  /* background-image: url(${(props) => props.previewImg}); */
`;

export default MobileConfigurationImageComponent;

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;
