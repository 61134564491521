import { useEffect, useState } from 'react';
import styled from 'styled-components';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import Checkbox from '../shared/Fields/Checkbox';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/themeContext';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const adminFeatures = JSON.parse(sessionStorage.getItem('adminFeatures') || '{}');

interface AdminPortalFeatureProps {
  ActionButtons?: any;
  setStpperStep?: Function;
  tableData?: any;
  setTableData?: any;
  handleFinish?: Function;
  active?: boolean;
  theme?: any;
}

export const onSelectChange = (
  data: any,
  index: any,
  field: any,
  tableData: any,
  setTableData: any
) => {
  let tempTableData = tableData;
  for (let i = 0; i < tableData.length; i++) {
    if (tempTableData[i].name === data.name) {
      tempTableData[i].checked[field] = !data.checked[field];
    }
  }
  setTableData([...tempTableData]);
};

const AdminFeaturesDataGrid: React.FC<AdminPortalFeatureProps> = ({
  ActionButtons = null,
  setStpperStep = () => {},
  tableData,
  setTableData,
  active = true,
  theme
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState('10');
  const [currentPage, setCurrentPage] = useState('1');
  const [currentActivePage, setCurrentActivePage] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentActivePage(parseInt(currentPage) * parseInt(size) - parseInt(size));
  }, [size, currentPage]);

  useEffect(() => {
    setTableHeaders([
      {
        key: tableData[0]?.label ? 'label' : 'name',
        description: 'Name',
        sortEnabled: false,
        width: '80%',
        sortingInformation: {
          order: ''
        }
      },
      {
        key: 'all',
        description: 'All',
        sortEnabled: false,
        width: '20%',
        nastedData: true,
        styles: {
          justifyContent: 'center'
        },
        render: (data: any, index: any) => {
          return (
            <>
              <Checkbox
                className="mx-auto"
                height="20px"
                width="20px"
                checked={data?.checked?.All}
                disabled={!active || (!active && !data?.checked?.All)}
                onChange={() =>
                  active && onSelectChange(data, index, 'All', tableData, setTableData)
                }
              />
            </>
          );
        },
        sortingInformation: {
          order: ''
        }
      }
    ]);
  }, [active]);

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>([
    {
      key: 'label',
      description: `${t('T_NAME')}`,
      sortEnabled: false,
      width: '80%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'all',
      description: `${t('T_ALL')}`,
      sortEnabled: false,
      width: '20%',
      nastedData: true,
      render: (data: any, index: any) => {
        return (
          <>
            <Checkbox
              className="mx-auto"
              height="20px"
              width="20px"
              checked={data?.checked?.All}
              onChange={() => active && onSelectChange(data, index, 'All', tableData, setTableData)}
            />
          </>
        );
      },
      sortingInformation: {
        order: ''
      }
    }
  ]);

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <div style={{ minHeight: '60vh' }}>
          <CustomersTableContainer>
            <TemplateTable
              tableData={tableData.slice(currentActivePage, currentActivePage + parseInt(size))}
              isSearchable={false}
              size={size}
              currentPage={currentPage}
              templateSelector={() => {}}
              onRefreshTableData={() => {}}
              tableHeaders={tableHeaders}
              setTableHeaders={setTableHeaders}
              searchPlaceholder={t('T_SEARCH_HINT_MSG') || ''}
              isPaginated={false}
              isLocalPaginator={true}
              totalDataLength={tableData.length}
              setSize={setSize}
              setCurrentPage={setCurrentPage}
              containerClass="mt-0"
              tableName={t('T_ADMIN_PERMISSION') || ''}
              theme={useTheme().theme}
              tableBodyClass="max-h-[50vh] block overflow-y-scroll"
            />
          </CustomersTableContainer>
        </div>

        {ActionButtons && (
          <ActionButtons
            primaryButtonLabel={t('T_NEXT_STEP')}
            primaryButtonAction={() => {
              setStpperStep(4);
            }}
            secondaryButtonLabel={t('T_BACK_TO_APP_FEATURES')}
            secondaryButtonAction={() => {
              setStpperStep(2);
            }}
          />
        )}
      </LoaderContainer>
    </>
  );
};

export default AdminFeaturesDataGrid;
