import { createSlice } from '@reduxjs/toolkit';

export const templateSlice = createSlice({
  name: 'templateData',
  initialState: {
    templatePayload: '' as any,
    step: 1
  },
  reducers: {
    setTemplateStep: (state, action) => {
      state.step = action.payload;
    },
    setTemplateData: (state, action) => {
      state.templatePayload = action.payload;
    },
    resetTemplateRoute: (state) => {
      (state.templatePayload = {}), (state.step = 1);
    }
  }
});

export const { setTemplateData, setTemplateStep, resetTemplateRoute } = templateSlice.actions;

export default templateSlice.reducer;
