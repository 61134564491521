import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../../hooks/queryParams';
import { useTranslation } from 'react-i18next';
import { RouteConstants } from '../../../constants/RouteConstants';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import Modal from '../../shared/ModalPortal/Modal';
import addModulesIcon from '../../../assets/addModulesIcon.svg';
import styled from 'styled-components';
import { Label } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';
import AddCircle from '../../../assets/addCircle.svg';
import dropdownInitial from '../../../assets/solidDropdownInitial.svg';
import dropdownExpanded from '../../../assets/solidDropdown.svg';
import { colouredAddCircle } from '../../../assets/components/svgs';
import ModulesTable from './ModulesTable';
import { flatMap, map, property } from 'lodash';
import { RefreshIcon } from '@heroicons/react/outline';

interface ModuleCardProps {
  manifest: any;
  toggleModal: Function;
  disabled: boolean;
  toggleExpanded: Function;
}

const Container = styled.div`
  width: 100%;
  min-height: 25vh;
  max-height: 90vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const collapsibleCss = 'collapsed transition focus:outline-none';

const ModuleCard = ({ manifest, toggleModal, disabled, toggleExpanded }: ModuleCardProps) => {
  const { title, key, component } = manifest;
  const { t } = useTranslation();
  const selections = useAppSelector((state: any) => state.migrationExport.manifest);
  const [expanded, setExpanded] = useState<boolean>(false);

  // const getSystemSettingCount = () => {
  //   const globalOrgSettings = selections['globalSettingsOrg'];
  //   const globalSettings = selections['globalSettings'];
  //   const mappedOrgItemsLength = globalOrgSettings.length
  //     ? flatMap(map(globalOrgSettings, property('dependentArtifactConfigurations.configurations')))
  //         .length
  //     : 0;
  //   let mergedCount = mappedOrgItemsLength + globalSettings.length;
  //   return mergedCount;
  // };

  // const getRolesCount = () => {
  //   const orgRoles = selections['orgRoles'];
  //   const globalRoles = selections['globalRoles'];
  //   const mappedOrgItemsLength = orgRoles.length
  //     ? flatMap(map(orgRoles, property('dependentArtifactConfigurations.roles'))).length
  //     : 0;
  //   let mergedCount = mappedOrgItemsLength + globalRoles.length;
  //   return mergedCount;
  // };

  const getCount = (
    orgItems: Array<any> = [],
    globalItems: Array<any> = [],
    dependentKey: string
  ) => {
    const mappedOrgItemsLength = orgItems.length
      ? flatMap(map(orgItems, property(dependentKey))).length
      : 0;
    return mappedOrgItemsLength + globalItems.length;
  };

  const getSystemSettingCount = () => {
    const globalOrgSettings = selections['globalSettingsOrg'];
    const globalSettings = selections['globalSettings'];
    return getCount(
      globalOrgSettings,
      globalSettings,
      'dependentArtifactConfigurations.configurations'
    );
  };

  const getRolesCount = () => {
    const orgRoles = selections['orgRoles'];
    const globalRoles = selections['globalRoles'];
    return getCount(orgRoles, globalRoles, 'dependentArtifactConfigurations.roles');
  };

  const getTemplatesCount = () => {
    const orgRoles = selections['orgTemplates'];
    const globalRoles = selections['globalTemplates'];
    return getCount(orgRoles, globalRoles, 'dependentArtifactConfigurations.templates');
  };

  const selectionLength =
    key === 'globalSettings'
      ? getSystemSettingCount()
      : key === 'globalRoles'
      ? getRolesCount()
      : key === 'globalTemplates'
      ? getTemplatesCount()
      : selections?.[key].length;

  const collapsibleFunction = async () => {
    // if (!expanded) {
    //   await toggleExpanded(key);
    // }
    if (selectionLength > 0) {
      setExpanded(!expanded);
    }
  };

  return (
    <div className={`flex flex-col border-y-[1px]`}>
      <button
        type="button"
        className={`flex w-full flex-row items-center justify-between px-5 ${
          expanded && selectionLength > 0 ? 'pt-7' : 'py-7'
        } ${selectionLength > 0 && `cursor-pointer`}`}
        onClick={collapsibleFunction}
      >
        <div className={`flex flex-row items-center justify-center`}>
          {selectionLength > 0 ? (
            <img
              src={expanded ? dropdownExpanded : dropdownInitial}
              className={'mr-2 h-3 w-3'}
            ></img>
          ) : (
            ''
          )}
          <div className="flex flex-col justify-start">
            <div className="text-start font-semibold">{t(title)}</div>
            {selectionLength ? (
              <div className="mt-1 text-start text-sm font-normal text-[#1890FF]">
                {selectionLength} {t('T_ITEMS_SELECTED')}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {!disabled ? (
          <BiButton
            className={`mr-2 flex flex-row items-center rounded-r-[5px] border border-[#99B0C6] text-primary`}
            type="button"
            onClick={toggleModal}
          >
            <div className="mr-2 h-4 w-4"> {colouredAddCircle}</div>
            {t('T_BUTTON_ADD')}
          </BiButton>
        ) : (
          ''
        )}
      </button>
      {selectionLength ? (
        <div
          className={`accordion-collapse rounded-b-lg ${collapsibleCss} ${
            expanded ? 'show' : 'hidden'
          }`}
          aria-expanded={expanded}
        >
          <div className="flex flex-col">
            {component ? (
              <>
                {/* <RefreshIcon className="mb-2 mr-4 h-6 w-6 cursor-pointer self-end"></RefreshIcon> */}
                {component('preview')}
              </>
            ) : (
              <ModulesTable
                config={manifest}
                toggleModal={toggleModal}
                type="preview"
              ></ModulesTable>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ModuleCard;
