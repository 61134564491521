import { createSlice } from '@reduxjs/toolkit';

const initialPwPolicy = {
  name: '',
  pwdMaxAge: 0,
  pwdMinLength: 0,
  characterSets: []
};
export const contextSlice = createSlice({
  name: 'user',
  initialState: {
    passwordPolicy: initialPwPolicy
  },
  reducers: {
    setPasswordPolicyAttr: (state: any, action: any) => {
      state.passwordPolicy[action.payload.key] = action.payload.value;
    },
    resetPwPolicy: (state: any) => {
      state.passwordPolicy = initialPwPolicy;
    }
  }
});

export const { setPasswordPolicyAttr, resetPwPolicy } = contextSlice.actions;

export default contextSlice.reducer;
