import { Label } from '../../AccountProfile/ProfileStyles';
import styled from 'styled-components/macro';
import Asterix from '../../../assets/asterix.svg';
//import closeCircle from '../../../assets/close-circle.svg';
import { closeCircle } from '../../../assets/components/svgs';
import { useRef, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';
import './pills.css';

interface PillsButtonProps {
  label?: string;
  formData?: any;
  setFormData?: Function;
  rows?: number;
  pillsData: Array<any>;
  removeFilter: Function;
  defaultPillsLabel?: string;
  disabled?: boolean;
  pillMaxWidth?: string;
  pillId?: string;
}

const PillsButton = ({
  label,
  formData,
  setFormData,
  rows,
  pillsData,
  removeFilter,
  defaultPillsLabel,
  disabled = false,
  pillMaxWidth = '40%',
  pillId = 'pillContainer'
}: PillsButtonProps) => {
  const { t } = useTranslation();

  const isEllipsisActive = () => {
    let d = document.getElementById(pillId);
    return d && d.offsetHeight < d.scrollHeight;
  };

  return (
    <>
      {label && <Label>{t(label)}</Label>}
      <PillsContainer>
        {/* {!pillsData.length && defaultPillsLabel && (
          <div className="ml-2 text-[12px] text-[#595959]">{defaultPillsLabel}</div>
        )}
        <PillsMultiLine>
          {pillsData &&
            pillsData.map((obj) => {
              return (
                <div
                  className={`my-1 mr-1 inline-flex items-center rounded-full bg-[#E4F2FF] px-3 py-1 text-[#003A70] max-w-[${pillMaxWidth}]`}
                >
                  {obj.label && <PillsContainerSpanlabel>{obj.label}:</PillsContainerSpanlabel>}
                  {obj.name && (
                    <PillsContentName>
                      <span title={obj.name}>{obj.name}</span>
                    </PillsContentName>
                  )}
                  <PillsConatinerAnchor
                    className={disabled ? 'disabled' : ''}
                    onClick={() => removeFilter(obj)}
                  >
                    {closeCircle}
                  </PillsConatinerAnchor>
                </div>

                // <button className="my-1 mr-1 inline-flex items-center rounded-full bg-[#E4F2FF] py-1 px-3 text-[#003A70]">
                //   {obj.label && <PillsContainerSpanlabel>{obj.label}:</PillsContainerSpanlabel>}
                //   <span>{obj.name}</span>
                //   {!obj.hideIcon && (
                //     <PillsConatinerAnchor
                //       className={disabled ? 'disabled' : ''}
                //       onClick={() => removeFilter(obj)}
                //     >
                //       {closeCircle}
                //     </PillsConatinerAnchor>
                //   )}
                // </button>
              );
            })}
        </PillsMultiLine> */}

        <div className="box-pill">
          <input className="input-pill" type="checkbox" id={`pillButton-${pillId}`} />
          <p id={pillId} className="p-pill">
            {pillsData &&
              pillsData.map((obj) => {
                return (
                  <div
                    className={`mr-1 mt-1 py-[0.1rem] inline-flex items-center rounded-full bg-[#E4F2FF] px-3 text-[#003A70] max-w-[${pillMaxWidth}]`}
                  >
                    {obj.label && <PillsContainerSpanlabel>{obj.label}:</PillsContainerSpanlabel>}
                    {obj.name && (
                      <PillsContentName>
                        <span title={obj.name}>{obj.name}</span>
                      </PillsContentName>
                    )}
                    <PillsConatinerAnchor
                      className={disabled ? 'disabled' : ''}
                      onClick={() => removeFilter(obj)}
                    >
                      {closeCircle}
                    </PillsConatinerAnchor>
                  </div>
                );
              })}
          </p>
          {isEllipsisActive() ? (
            <>
              <div
                className="label-pill"
                dangerouslySetInnerHTML={{
                  __html: `<label role="button" for="${`pillButton-${pillId}`}">Show more</label>`
                }}
              />
              <div
                className="label-not-pill"
                dangerouslySetInnerHTML={{
                  __html: `<label role="button" for="${`pillButton-${pillId}`}">Show less</label>`
                }}
              />
            </>
          ) : (
            ''
          )}
        </div>
      </PillsContainer>
    </>
  );
};

const PillsContainer = styled('div')`
  // width: 100%;
  // display: flex;
  // flex-wrap: wrap;
`;

const PillsContent = styled('div')`
  //max-width: 40%;
`;

const PillsContentName = styled('p')`
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PillsMultiLine = styled('div')`
  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  // white-space: pre-wrap; /* let the text wrap preserving spaces */
`;

const PillsContainerSpan = styled('span')`
  cursor: auto;
`;
const PillsContainerSpanlabel = styled('span')`
  cursor: auto;
  color: #595959;
  margin-right: 0.25rem;
}
`;

const PillsConatinerAnchor = styled('a')`
  cursor: pointer;
  &.disabled {
    pointer-events: none;
  }
`;

const Text = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-start;
  flex-flow: row wrap;
`;

export default PillsButton;
