import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Input, Label } from '../AccountProfile/ProfileStyles';
import { Button } from '../Reusable/Shared';
import PasswordRequirement from './PasswordRequirement';
import { performRegisterPasswordValidation } from './registerPasswordValidation';
import Exclamation from '../../assets/exclamation.svg';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  acceptInvitations,
  getPasswordPolicyRegister,
  requestNewCode
} from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import {useTheme} from "../../context/themeContext";
import {useTranslation} from "react-i18next";

export const initialPasswordRequirements: any = {
  pwdMinLength: false,
  characterSets: []
};

const RegisterStepTwo = ({ position, setPosition, paramCode, paramEmail, setIsLoading }: any) => {
  const registeredEmail = useAppSelector((state) => state.userManagement.email);
  const [passwordRequirements, setPasswordRequirements] = useState(initialPasswordRequirements);
  const [inviteCode, setInviteCode] = useState(paramCode ? paramCode : '');
  const [createPassword, setCreatePassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [notMatching, setNotMatching] = useState('');
  const [codeButtonDisable, setCodeButtonDisable] = useState(true);
  const confirmPasswordRef = useRef<any>(null);
  const [passwordPolicy, setPasswordPolicy] = useState<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let errors: any = {};
  const {t} = useTranslation();

  const callApi = async (paramEmail: any) => {
    const response = await getPasswordPolicyRegister(paramEmail);
    if (response.status === HTTP_STATUS.HTTP_OK) {
      setPasswordPolicy(response.data);
    }
  };
  useEffect(() => {
    if (position === -100) {
      if (paramEmail) {
        callApi(paramEmail);
      } else {
        callApi(registeredEmail);
      }
    }
  }, [position]);

  useEffect(() => {
    if (
      inviteCode &&
      inviteCode.length &&
      createPassword &&
      confirmPassword &&
      !notMatching &&
      passwordRequirements.pwdMinLength &&
      passwordRequirements.characterSets.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (
      passwordPolicy &&
      passwordPolicy.characterSets &&
      passwordPolicy.characterSets.length > 0 &&
      passwordRequirements.characterSets.length === 0
    ) {
      let characterSetObject = passwordPolicy.characterSets.map(() => false);
      setPasswordRequirements({
        ...passwordRequirements,
        characterSets: [...characterSetObject]
      });
    }
  }, [
    inviteCode,
    createPassword,
    confirmPassword,
    notMatching,
    passwordRequirements,
    passwordPolicy
  ]);

  const handleRegisterSubmit = async () => {
    setIsLoading(true);
    const acceptInvBody = {
      invitationIdentifierType: 'EMAIL',
      invitationIdentifier: registeredEmail,
      code: inviteCode,
      userName: registeredEmail,
      password: createPassword,
      email: registeredEmail
    };
    const response = await acceptInvitations(acceptInvBody);
    if (response.status === HTTP_STATUS.HTTP_OK || response.status === HTTP_STATUS.HTTP_CREATED) {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: `${t('T_TOAST_NOTIFICATION_FOR_CREATED_ACCOUNT_SUCCESS_MSG')}`,
          isToastActive: true,
          toastType: 'success'
        })
      );
      setTimeout(() => navigate('/auth'), 3000);
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage:
            response?.data?.issues?.[0]?.code === 'UMS_PARAM_INVALID'
              ? `${t('T_TOAST_NOTIFICATION_ERR_MSG')}`
              : response?.data?.issues?.[0]?.details,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const handlePasswordChange = (e: any) => {
    setCreatePassword(e.target.value);
    errors = performRegisterPasswordValidation(
      { password: e.target.value },
      setPasswordRequirements,
      passwordPolicy,
        t
    );
    if (confirmPassword && e.target.value !== confirmPassword) {
      setNotMatching(errors.confirmPassword);
    } else {
      setNotMatching('');
    }
  };

  const handleConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
    errors = performRegisterPasswordValidation(
      { password: createPassword, confirmPassword: e.target.value },
      setPasswordRequirements,
      passwordPolicy,
        t
    );
    errors?.confirmPassword ? setNotMatching(errors.confirmPassword) : setNotMatching('');
  };
  const handleNewCodeRequest = async () => {
    const newCodeReqBody = {
      identifierType: 'EMAIL',
      identifier: registeredEmail
    };
    const response = await requestNewCode(newCodeReqBody);
    if (response.status === HTTP_STATUS.HTTP_OK || response.status === HTTP_STATUS.HTTP_CREATED) {
      dispatch(
        setToastData({
          toastMessage: `${t('T_TOAST_NOTIFICATION_FOR_NEW_CODE_SEND')}`,
          isToastActive: true,
          toastType: 'success'
        })
      );
      setCodeButtonDisable(true);
    } else {
      dispatch(
        setToastData({
          toastMessage: response?.data?.issues?.[0]?.details,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const codeButtonEnable = () => {
    if (paramCode) {
      return codeButtonDisable;
    }
    return false;
  };

  return (
    <>
      <div
        style={{ display: 'block', bottom: '48%', left: '100%' }}
        className={`${position === -100 ? 'visible relative overflow-hidden' : 'invisible '}`}
      >
        {/* Top Half - Enter your code */}
        <div className="h-[500px]">
          <div className="flex h-[206px] flex-row items-center justify-center bg-[#f5f7fa] pl-[50px]">
            <div className="mr-[47px] flex w-80 flex-col">
              <h1 className="text-2xl font-light text-primary">{t('T_ENTER_CODE')}</h1>
              <p className="mt-[20px] mb-[13px] max-w-[345px] text-base font-light">
                {t('T_REGISTER_ENTER_CODE_MSG')} {' '}
                <span className="block">
                  <b>{registeredEmail}</b>
                </span>
              </p>
              <button
                type="button"
                className="mb-0 mb-[25px] text-left text-sm text-blue-500"
                onClick={handleNewCodeRequest}
              >
                {t('T_BUTTON_REQ_FOR_NEW_CODE')}
              </button>
            </div>
            <div>
              <Label htmlFor="inviteCode-input">{t('T_INVITE_CODE')}</Label>
              <Input
                id="inviteCode-input"
                name="inviteCode"
                type="text"
                className="h-8 h-[44px] w-[320px] placeholder:text-sm"
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
                disabled={codeButtonEnable()}
              />
            </div>
          </div>
          {/* Bottom half - Enter your password */}
          <div className="my-[10px] mx-auto flex h-[250px] w-full flex-row items-center justify-center bg-[#f5f7fa] pl-[50px]">
            <div className="order-2 flex w-80 flex-col gap-2">
              <h1 className="mb-[20px] hidden text-2xl font-light text-primary">{t('T_CREATE_PASSWORD')}</h1>
              <Label htmlFor="create-password-field">{t('T_CREATE_PASSWORD')}</Label>
              <Input
                id="create-password-field"
                name="password"
                type="password"
                className="h-[44px] w-[320px]"
                value={createPassword}
                onChange={(e) => handlePasswordChange(e)}
                onKeyDown={(e) => {
                  e.key === 'Enter' ? confirmPasswordRef.current.focus() : '';
                }}
              />
              <Label htmlFor="confirm-password-field">{t('T_CONFIRM_PASSWORD')}</Label>
              <Input
                ref={confirmPasswordRef}
                id="confirm-password-field"
                name="confirmPassword"
                type="password"
                className="h-[44px w-[320px]"
                value={confirmPassword}
                onChange={(e) => handleConfirmPassword(e)}
                onKeyDown={(e) => {
                  e.key === 'Enter' && !isDisabled ? handleRegisterSubmit() : '';
                }}
              />
              {notMatching ? (
                <ErrorLabel>
                  <Img src={Exclamation} />
                  {notMatching}
                </ErrorLabel>
              ) : null}
            </div>

            <div className="order-1  mr-[47px] flex w-80 flex-col gap-0 gap-7">
              <h1 className="flex text-2xl font-light text-primary">{t('T_CREATE_PASSWORD')}</h1>

              <PasswordRequirement
                passwordRequirements={passwordRequirements}
                passwordPolicy={passwordPolicy}
              />
            </div>
          </div>
        </div>

        {/* Bottom of card - Next button */}
        <div className="sticky bottom-0 flex h-[102px] items-center justify-end bg-white px-[20px] pr-[60px]">
          <Button
            color="secondary"
            width="352px"
            onClick={() => {
              setPosition((state: any) => 0);
            }}
            theme={useTheme().theme}
          >
            {t('T_BACK')}
          </Button>
          <div className="ml-4">
            <Button
              color="primary"
              width="352px"
              onClick={() => {
                handleRegisterSubmit();
              }}
              disabled={isDisabled}
              theme={useTheme().theme}
            >
              {t('T_DONE')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const ErrorLabel = styled.span`
  font-size: 13px !important;
  color: red;
`;

const Img = styled.img`
  height: 17px;
  width: 17px;
  display: inline;
`;

export default RegisterStepTwo;
