import { compact } from 'lodash';
import { OrganizationInterface } from '../models/organization/Organization.Interface';
import { CodingConcept } from './HL7Util';

import {
  getLoggedInOrg,
  getLoggedInOrg as getLoggedInUserOrg,
  getLoginUserRoles as getLoggedInUserRoles,
  getUserRoles
} from './admin-utils';

import {
  getChildOrganizations,
  getOrganization,
  getOrganizations
} from '../http/organization-services';

import { AppConstants } from '../constants/AppConstants';

import {  find, filter } from 'lodash';
import { HTTP_STATUS } from '../http/constants/http.status';

export const getOrganizationType = (organization: any = {}) => {
  let orgType = '';
  const { type = [] } = organization;
  if (type && type.length) {
    orgType = type[0]?.text || orgType;
  }
  return orgType.trim();
};

export const parseTemplateLiteral = (expression: string = '${code}', variableObj: any) => {
  const regexp = /\${\s?([^{}\s]*)\s?}/g;
  let parsedString = expression.replace(regexp, (substring, variables, i) => {
    variables = variableObj[variables];
    return variables;
  });
  return parsedString;
};

export const getOragnizationAddress = (organization: any = {}) => {
  const orgAddress = new Array<string>();
  const { address = [] } = organization;
  const [address1 = []] = address || [];
  const { city, line = [], postalCode, state } = address1;

  if (compact(line).length) {
    orgAddress.push(line.join());
  }
  if (city) {
    orgAddress.push(city);
  }
  if (state) {
    orgAddress.push(state);
  }
  if (postalCode) {
    orgAddress.push(postalCode);
  }
  return orgAddress.join();
};

export const customOrgTypeCoding = (type: string) => {
  let payload = {
    coding: [
      {
        system: 'http://terminology.hl7.org/CodeSystem/organization-type',
        version: '0.1.0',
        code: 'other',
        display: 'Other',
        userSelected: false
      }
    ],
    text: type || ''
  };

  return payload;
};

export const getOrgList = async (loadNextData: any, paging: any, userProfileInfo: any) => {
  const { searchOrgName } = loadNextData;
  const { size, page } = paging;
  const [role] = getUserRoles(userProfileInfo);
  const { organizationId } = getLoggedInOrg(userProfileInfo);
  let response;
  if (searchOrgName) {
    response = await getOrganizations(searchOrgName, page, size, `name,asc`);
  } else {
    response = await getChildOrganizations(
      organizationId,
      AppConstants.QUERY_PARAM_LEVEL_DESENDANTS,
      '',
      page,
      size,
      [`name,asc`]
    );
  }

  const { status, data: organizationResp } = response;
  if (status == HTTP_STATUS.HTTP_OK) {  
    return organizationResp;
  }
};

export  const getUserLoggedInOrg = async (userProfileInfo: any) => {
  const { organizationId } = getLoggedInOrg(userProfileInfo);
  const loggedInOrgResponse = await getOrganization(organizationId);
  const { status, data } = loggedInOrgResponse;
  if (status == HTTP_STATUS.HTTP_OK) {
    return data;
  }
};

export const updateOrgResponse = (obj: any, orgResponse: any, loadNextData:any, setLoadNextData: any) => {
  if (orgResponse) {
    let sortedOrgList;
    const { data: orgList, paging: pagingResponse } = orgResponse;
    let { paging: nextpagingObj } = obj;
    const { size, page } = pagingResponse;
    if (pagingResponse.next) {
      nextpagingObj = { page: page + 1, size };
    }
    setLoadNextData({ ...loadNextData, orgPaging: false, searchOrg: false });

    sortedOrgList = [...obj.itemsList, ...orgList].sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    return {
      ...obj,
      itemsList: sortedOrgList,
      paging: nextpagingObj,
      hasMore: pagingResponse.next !== null
    };
  }
};

export const updateRoleResponse = (obj: any, orgResponse: any, loadNextData:any, setLoadNextData: any) => {
  if (orgResponse) {
    let sortedOrgList;
    const { data: orgList, paging: pagingResponse } = orgResponse;
    let { paging: nextpagingObj } = obj;
    const { size, page } = pagingResponse;
    if (pagingResponse.next) {
      nextpagingObj = { page: page + 1, size };
    }
    setLoadNextData({ ...loadNextData, rolePaging: false, searchRole: false });

    sortedOrgList = [...obj.itemsList, ...orgList].sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    return {
      ...obj,
      itemsList: sortedOrgList,
      paging: nextpagingObj,
      hasMore: pagingResponse.next !== null
    };
  }
};
