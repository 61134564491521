import React, { useState } from 'react';

interface Option {
  id: number;
  value: string;
}

import { useTranslation } from 'react-i18next';
import { Input, Label } from '../AccountProfile/ProfileStyles';
import BiButton from '../primitives/buttons/BiButton.primitive';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import PlusIcon from '@heroicons/react/outline/PlusIcon';
import { plusSign } from '../../assets/components/svgs';
import { cloneDeep } from 'lodash';

interface EnumOptionsProps {
  options: Array<string>;
  setOptions: Function;
}

const EnumOptions: React.FC<EnumOptionsProps> = ({ options, setOptions }: EnumOptionsProps) => {
  const [newOption, setNewOption] = useState<string>('');
  const [editedOption, setEditedOption] = useState<any>({ index: -1, value: '' });

  const { t } = useTranslation();

  const handleAddOption = () => {
    const trimmedOption = newOption.trim();
    if (trimmedOption !== '' && !options.includes(trimmedOption)) {
      setOptions([...options, trimmedOption]);
      setNewOption('');
    }
  };

  const handleDeleteOption = (value: string) => {
    const cloneOptions = cloneDeep(options);
    const index = options.indexOf(value);
    if (index !== -1) {
      cloneOptions.splice(index, 1);
    }

    setOptions(cloneOptions);
  };

  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value.trim();
    setOptions(updatedOptions);
  };

  const handleOptionBlur = (index: number, value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue !== options[index] && !options.includes(trimmedValue)) {
      handleOptionChange(index, trimmedValue);
    }
    setEditedOption({ index: -1, value: '' });
  };

  return (
    <div>
      <Label>{t('T_ENUM_OPTIONS')}</Label>
      <div className="mb-4 flex items-center">
        <Input
          type="text"
          data-testid="enum-addition-input"
          id={`enum-addtion-input`}
          name={t('T_ENUM_ADD_PLACEHOLDER')}
          className="flex-1 rounded border px-3 py-2"
          maxLength={256}
          required={true}
          value={newOption}
          placeholder={t('T_ENUM_ADD_PLACEHOLDER')}
          onChange={(e) => setNewOption(e.target.value)}
        />

        <div
          data-testid="enum-add-button"
          className="ml-2 flex cursor-pointer items-center justify-center text-primary"
          onClick={handleAddOption}
        >
          <div className="mr-2 self-center text-primary">{plusSign}</div> {t('T_ADD_OPTION')}
        </div>
      </div>

      {options.length ? (
        <div>
          <Label>{t('T_ENUM_ADDED_OPTIONS')}</Label>
          <ul className="max-h-48 overflow-y-auto rounded border border-gray-300 p-2">
            {options.map((option: string, index: number) => (
              <li key={index} className="mb-2 flex items-center">
                <Input
                  type="text"
                  data-testid={`enum-edit-input`}
                  id={`enum-edit-input`}
                  className="mr-2 flex-1 rounded border"
                  maxLength={256}
                  required={true}
                  value={
                    editedOption.index === index && editedOption.value ? editedOption.value : option
                  }
                  onChange={(e) => setEditedOption({ index, value: e.target.value })}
                  onBlur={(e) => handleOptionBlur(index, e.target.value)}
                />

                <DeleteIcon
                  data-testid="delete-icon"
                  className="m-auto h-[40px] w-[40px] cursor-pointer p-2 text-[#127ADB] hover:text-sky-800"
                  onClick={() => handleDeleteOption(option)}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default EnumOptions;
