import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components/macro';

import {
  crossIcon,
  dragIcon,
  multiDropdown,
  multiDropdownUp,
  plusSign
} from '../../../../assets/components/svgs';

import RadioButton from '../../../shared/Fields/RadioButton';
import InputField from '../../../shared/Fields/InputField';
import { DropDownContainer, RemoveDefaultButton, StyledDropdownList } from './MultipleSelection';

const BooleanQuestion = ({
  formData,
  formError,
  setFormData,
  editMode,
  editFromSurveyTemplate = false
}: any) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<any>({ option1: false, option2: false });
  const [initialValue, setInitialValue] = useState<any>({
    option1: formData.initial ? (formData.initial[0] === 'true' ? true : false) : false,
    option2: formData.initial ? (formData.initial[0] === 'false' ? true : false) : false
  });

  const [booleanVal, setBooleanVal] = useState<any>({
    yes: 'Yes',
    no: 'No'
  });

  useEffect(() => {
    setFormData({ ...formData, initial: [] });

    if (initialValue[`option1`]) {
      setFormData({ ...formData, initial: ['true'] });
    }

    if (initialValue[`option2`]) {
      setFormData({ ...formData, initial: ['false'] });
    }
  }, [initialValue]);
  return (
    <>
      <div className="mb-5 mt-5 flex w-full flex-row">
        {editFromSurveyTemplate && (
          <div className="self-center">
            <RadioButton
              selectedField={initialValue[`option1`] ? `option1` : 'none'}
              option={`option1`}
              name={`booleanQuestion`}
              onClick={() => {
                !editMode &&
                  setInitialValue({
                    option1: !initialValue[`option1`],
                    option2: false
                  });
              }}
            />
          </div>
        )}

        <div className="flex w-full">
          <InputField
            className="w-full"
            type="text"
            id="max-word-count"
            label=""
            placeholder="Enter #"
            fieldKey="yes"
            required={false}
            formData={booleanVal}
            setFormData={setBooleanVal}
            formError={formError}
            handleChange={() => {}}
            disabled={true}
          />
        </div>
        {editFromSurveyTemplate && !editMode && (
          <DropDownContainer className="ml-3 cursor-pointer self-center">
            <div
              className="ml-3 cursor-pointer self-center"
              onClick={() => setIsOpen({ ...isOpen, [`option1`]: !isOpen[`option1`] })}
            >
              {isOpen[`option1`] ? multiDropdownUp : multiDropdown}
            </div>
            {isOpen[`option1`] && (
              <div>
                <StyledDropdownList maxHeight={true}>
                  <li
                    onClick={() => {
                      setInitialValue({
                        option1: !initialValue[`option1`],
                        option2: false
                      });
                      setIsOpen({ ...isOpen, [`option1`]: false });
                    }}
                  >
                    {initialValue[`option1`]
                      ? 'Remove default selection'
                      : t('T_DEFAULT_OPTION_SELECTED')}
                  </li>
                </StyledDropdownList>
              </div>
            )}
          </DropDownContainer>
        )}
      </div>
      <div className="mb-5 mt-5 flex w-full flex-row">
        {editFromSurveyTemplate && (
          <div className="self-center">
            <RadioButton
              selectedField={initialValue[`option2`] ? `option2` : 'none'}
              option={`option2`}
              name={`booleanQuestion`}
              onClick={() => {
                !editMode &&
                  setInitialValue({
                    option1: false,
                    option2: !initialValue[`option2`]
                  });
              }}
            />
          </div>
        )}

        <div className="flex w-full">
          <InputField
            className="w-full"
            type="text"
            id="max-word-count"
            label=""
            placeholder="Enter #"
            fieldKey="no"
            required={false}
            formData={booleanVal}
            setFormData={setBooleanVal}
            formError={formError}
            handleChange={() => {}}
            disabled={true}
          />
        </div>
        {editFromSurveyTemplate && !editMode && (
          <DropDownContainer className="ml-3 cursor-pointer self-center">
            <div
              className="ml-3 cursor-pointer self-center"
              onClick={() => setIsOpen({ ...isOpen, [`option2`]: !isOpen[`option2`] })}
            >
              {isOpen[`option2`] ? multiDropdownUp : multiDropdown}
            </div>
            {isOpen[`option2`] && (
              <div>
                <StyledDropdownList maxHeight={true}>
                  <li
                    onClick={() => {
                      setInitialValue({
                        option1: false,
                        option2: !initialValue[`option2`]
                      });
                      setIsOpen({ ...isOpen, [`option2`]: false });
                    }}
                  >
                    {initialValue[`option2`]
                      ? 'Remove default selection'
                      : t('T_DEFAULT_OPTION_SELECTED')}
                  </li>
                </StyledDropdownList>
              </div>
            )}
          </DropDownContainer>
        )}
      </div>
    </>
  );
};

const InputFieldContaner = styled.div`
  input {
    width: 100%;
  }
`;

export default BooleanQuestion;
