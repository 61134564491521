import {
  getChildOrganizations,
  getOrganization,
  getOrganizations
} from '../../http/organization-services';

import {
  getLoggedInOrg,
  getLoggedInOrg as getLoggedInUserOrg,
  getLoginUserRoles as getLoggedInUserRoles,
  getUserRoles
} from '../../util/admin-utils';

import { AppConstants } from '../../constants/AppConstants';

import { find, filter } from 'lodash';
import { HTTP_STATUS } from '../../http/constants/http.status';

export const getConfigKeyOrg = async (loadNextData: any, filterList: any, userProfileInfo: any) => {
  const { searchOrgName } = loadNextData;
  const { paging } = find(filterList, { id: 'orgFilter' });
  const { size, page } = paging;
  const [role] = getUserRoles(userProfileInfo);
  const { organizationId } = getLoggedInOrg(userProfileInfo);
  let response;
  if (searchOrgName) {
    response = await getOrganizations(searchOrgName, page, size, `name,asc`);
  } else {
    response = await getChildOrganizations(
      organizationId,
      AppConstants.QUERY_PARAM_LEVEL_DESENDANTS,
      '',
      page,
      size,
      [`name,asc`]
    );
  }

  const { status, data: organizationResp } = response;
  if (status == HTTP_STATUS.HTTP_OK) {
    const { data, paging } = organizationResp;
    // if (data.length) {
    //   return organizationResp;
    // }
    return organizationResp;
  }
};

export const configServiceFilters = [
  {
    id: 'orgFilter',
    label: 'T_FILTER_KEY_ORGANIZATIONS',
    component: 'picklist',
    type: 'single',
    itemsList: [],
    selectedFilter: [],
    fieldKey: 'name',
    fieldValues: 'name',
    //disabled: queryParams.id ? true : false,
    disabled: false,
    hasMore: true,
    //fetchMoreData: fetchMoreOrgData,
    paging: { size: 25, page: 1 },
    isDependentFilter: true,
    dependentChildId: 'categoriesFilter',
    //fetchDependentFields: fetchDependentFields,
    //searchData: searchOrgData,
    searchable: true
  },
  {
    id: 'categoriesFilter',
    label: 'T_FILTER_KEY_CATEGORIES',
    component: 'picklist',
    type: 'single',
    itemsList: [],
    selectedFilter: [],
    fieldKey: 'name',
    fieldValues: 'name',
    disabled: false,
    hasMore: true,
    //fetchMoreData: fetchMoreCategoriesData,
    paging: { size: 25, page: 1 }
  },
  {
    id: 'dataTypeFilter',
    label: 'T_FILTER_KEY_DATATYPE',
    component: 'picklist',
    type: 'single',
    itemsList: AppConstants.CONFIG_KEY_DATA_TYPES_PICKLIST,
    selectedFilter: [],
    fieldKey: 'key',
    fieldValues: 'key',
    disabled: false
  }
];

export const setEmptyItemsList = (filterType: any, filterList: any, setFilterList: any) => {
  let initialFilterList: any = [...filterList];
  const currentFilterList = initialFilterList.map((obj: any) => {
    if (obj.id === filterType) {
      return {
        ...obj,
        itemsList: [],
        paging: { page: 1, size: 25 },
        hasMore: true
      };
    } else {
      return { ...obj };
    }
  });
  setFilterList(currentFilterList);
};

export const updateOrgResponse = (
  obj: any,
  orgResponse: any,
  loadNextData: any,
  setLoadNextData: any
) => {
  if (orgResponse) {
    let sortedOrgList;
    const { data: orgList, paging: pagingResponse = {} } = orgResponse;
    let { paging: nextpagingObj } = obj;
    const { size = '', page } = pagingResponse;
    if (pagingResponse.next) {
      nextpagingObj = { page: page + 1, size };
    }
    setLoadNextData({ ...loadNextData, orgPaging: false, searchOrg: false });

    sortedOrgList = [...obj.itemsList, ...orgList].sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    return {
      ...obj,
      itemsList: sortedOrgList,
      paging: nextpagingObj,
      hasMore: pagingResponse.next !== null
    };
  }
};

export const updateSelectedFilter = (
  filterList: any,
  filterPillsData: any = [],
  orgId: any,
  fetchMoreOrgData: any,
  setFilterPillsData: any,
  setFilterList: any,
  setFilterCallback: any = () => {},
) => {
  let initialFilterList: any = [...filterList];

  const pillsData: any = [...filterPillsData];
  if (orgId) {
    const initialFilterObj = find(initialFilterList, { id: 'orgFilter' });
    const filteredOrg = filter(initialFilterObj.itemsList, { id: orgId });
    if (!filteredOrg.length) {
      fetchMoreOrgData();
      return;
    }

    const selectedFilter = filteredOrg ? filteredOrg : [];
    initialFilterObj.selectedFilter = selectedFilter;
    if (!pillsData.length) {
      pillsData.push({
        name: selectedFilter[0].name,
        label: 'Organization',
        id: 'orgFilter'
        //hideIcon: true
      });
    }
    setFilterPillsData(pillsData);
    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'orgFilter') {
        return { ...initialFilterObj };
      } else {
        return { ...obj };
      }
    });
    setFilterList(currentFilterList);
  } else {
    setFilterCallback();
  }
};

export const getUserLoggedInOrg = async (userProfileInfo: any) => {
  const { organizationId } = getLoggedInOrg(userProfileInfo);
  const loggedInOrgResponse = await getOrganization(organizationId);
  const { status, data } = loggedInOrgResponse;
  if (status == HTTP_STATUS.HTTP_OK) {
    return data;
  }
};
