import BaseAPI from './api';
import env from '../environment';
import { AppConstants } from '../constants/AppConstants';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const globalHeadersMultipart = {
  'Content-Type': 'multipart/form-data'
};

const API = BaseAPI?.axiosInstance;

export const listMigrationExport = async (
  size: number,
  page: number,
  filters: string = '',
  sorters: string = ''
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.migrationPath}/export?size=${size}&page=${page}`;

  if (filters) {
    uri += `&${filters}`;
  }

  if (sorters) {
    uri += `${sorters}`;
  }
  const response = await API.get(uri, { headers });
  return response;
};

export const getMigrationExportJob = async (id: number) => {
  const headers = { ...globalHeaders };
  let uri = `${env.migrationPath}/export/${id}`;

  const response = await API.get(uri, { headers });
  return response;
};

export const listMigrationImport = async (
  size: number,
  page: number,
  filters: string = '',
  sorters: string = ''
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.migrationPath}/import?size=${size}&page=${page}`;

  if (filters) {
    uri += `&${filters}`;
  }

  if (sorters) {
    uri += `${sorters}`;
  }
  const response = await API.get(uri, { headers });
  return response;
};

export const getMigrationImportJob = async (id: number) => {
  const headers = { ...globalHeaders };
  let uri = `${env.migrationPath}/import/${id}`;

  const response = await API.get(uri, { headers });

  return response;
};

export const startExportJob = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.migrationPath}/export`, body, {
    headers
  });
  return response;
};

export const startImportJob = async (body: any) => {
  const headers = { ...globalHeadersMultipart };
  const response = await API.post(`${env.migrationPath}/import`, body, {
    headers
  });
  return response;
};



export const getMigrationLogData= async (url: string) => {
  const headers = { ...globalHeaders };

  const response = await API.get(url, { headers });

  return response;
};