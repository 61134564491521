import { useTranslation } from 'react-i18next';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import Card from '../shared/cards/Card';
import Clipboard from '../../assets/clipboard.svg';
import { useTheme } from '../../context/themeContext';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { useCallback, useEffect, useRef, useState } from 'react';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { colouredAddCircle } from '../../assets/components/svgs';
import {
  createSignedURL,
  getGCPFile,
  getPublicMobileConfig,
  uploadFile
} from '../../http/asset-management-services';
import axios from 'axios';
import BaseAPI from '../../http/api';
import { camera, downloadIcon, trashIcon } from '../../assets/components/svgs';
import SelectField from '../shared/Fields/SelectField';
import Dropzone from '../shared/DragAndDrop/Dropzone';
import { cloneDeep, findIndex, isEmpty } from 'lodash';
import { getCatalogConfigData, updateGCPFile } from './MobileConfigUtil';
import { RouteConstants } from '../../constants/RouteConstants';
import { setConfigList, setSelectedConfig } from '../../app/mobileConfigurationReducer';
import { AppConstants } from '../../constants/AppConstants';
import { HTTP_STATUS } from '../../http/constants/http.status';
import moment from 'moment';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import { IconButton } from '../primitives/buttons/IconButton';
import { setToastData } from '../../app/toastReducer';
import { getGlobalBucketName } from '../../util/AppSetting';
import { updateMetadataInstance } from '../../http/metadata-service';
import Modal from '../shared/ModalPortal/Modal';
import styled from 'styled-components';
import { ExclamationIcon } from '@heroicons/react/outline';
import { getFailureMessage } from '../../util/ErrorUtil';

const API = BaseAPI?.axiosInstance;

/**
 * @parentId mobile-configuration
 * @manager Mobile Configuration Language Translations
 * @overview
 *  <section>
 *  <p>
 *    <b>Language Translations</b> is a catalog item which is used to manage the language pack files used in localisation of the BI DMS Mobile Application. These are primarily JSON Files which are stored on the Google Cloud Platform. Their configuration is however maintained in configuration.json file which holds data like versioning, file location path.
 *  </p>
 *  <br />
 *       <p>
 *           <b>View Language Pack File</b><br/>
 *           User Can select the list of language pack file options from the dropdown list, on clicking of the dropdown item the details of the file are populated.
 *           <br/><br/>
 *
 *           <b>Download Language Pack File</b><br/>
 *           After Selecting a language pack, a download button is located at the button, on clicking of it will download the JSON File.
 *           <br/><br/>
 *
 *           <b>Update Language Pack</b><br/>
 *           After Selecting a language Pack, user can click on Upload language file button, upon which a system popup will become visible asking the user to select the new file. Upon the file selection the save button will now become visible and on clicking of the button will initiate the save process.
 *           <br/><br/>
 *       </p>
 *  </section>
 *  <section>
 *  <h4>Failure Status Codes</h4>
 *  <p> This section describes the Mobile Configuration Status Code information. <br>
 *  <table>
 *    <tr>
 *      <th>HTTP Status Code</th>
 *      <th>Service Error Code</th>
 *      <th>Error Message</th>
 *    </tr>
 *    <tr>
 *      <td>403</td>
 *      <td>CS_PERMISSION_DENIED</td>
 *      <td>You do not have permission to view this page.</td>
 *    </tr>
 *    <tr>
 *      <td>500</td>
 *      <td>CS_INTERNAL_ERROR</td>
 *      <td>Internal Server Error</td>
 *    </tr>
 *    <tr>
 *      <td>503</td>
 *      <td></td>
 *      <td>Service Unavailable</td>
 *    </tr>
 *  </table>
 *  </p>
 *  </section> <section>
 *  <h4>Dependent System settings, Platform services & Role Permission </h4>
 *  <p>This section describes the list of dependent system settings & platform services required for functioning of Mobile Configuration Language Translations page.</p>
 *  <h5>System Settings
 *  </h5>
 *  <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *  <br>
 *  <table>
 *  <tr>
 *    <th>Key</th>
 *    <th>Type</th>
 *    <th>Value</th>
 *    <th>Scope</th>
 *  </tr>
 *  <tr>
 *    <td>None</td>
 *    <td>None</td>
 *    <td>None</td>
 *    <td>None</td>
 *  </tr>
 *  </table>
 *  <br>
 *  <h5>Platform Service(s) </h5>
 *  <p>Table lists all the dependent platform service(s) with specific version(s) for Mobile Configuration Language Translations.</p>
 *  <br>
 *  <table>
 *    <tr>
 *      <th>Service Name</th>
 *      <th>Version</th>
 *    </tr>
 *    <tr>
 *      <td>Configuration Service</td>
 *      <td>1.3.0</td>
 *    </tr>
 *    <tr>
 *    <td>Metadata Service</td>
 *    <td>1.1.0</td>
 *    </tr>
 *    <tr>
 *    <td>Asset Management Service</td>
 *    <td>1.0.0</td>
 *    </tr>
 *  </table>
 *  <br>
 *  <h5>API Role Permission(s) </h5>
 *    <p>Table lists the required API role permissions for Mobile Configuration Language Translations.</p>
 *    <br>
 *    <table>
 *      <tr>
 *        <th>Feature</th>
 *        <th>API URL</th>
 *        <th>API Method</th>
 *        <th>API Permission</th>
 *        <th>Required</th>
 *      </tr>
 *      <tr>
 *        <td>View Private Values</td>
 *        <td>/system-settings/</td>
 *        <td>GET</td>
 *        <td>configuration-service.setting.list</td>
 *        <td>Yes</td>
 *      </tr>
 *      <tr>
 *        <td>Edit Private Values</td>
 *        <td>/system-settings/batch</td>
 *        <td>PUT</td>
 *        <td>configuration-service.setting.update.batch</td>
 *        <td>Yes</td>
 *      </tr>
 *      <tr>
 *             <td>Get Metadata Instance</td>
 *             <td>/objects/{id}/instances</td>
 *             <td>GET</td>
 *             <td>metadata-service.metadata-instance.list</td>
 *             <td>Yes</td>
 *      </tr>
 *      <tr>
 *             <td>Update Metadata Instance</td>
 *             <td>/objects/{id}/instances/{id}</td>
 *             <td>PUT</td>
 *             <td>metadata-service.metadata-instance.update</td>
 *             <td>Yes</td>
 *      </tr>
 *       <tr>
 *             <td>Create Signed URL</td>
 *             <td>/signed-url</td>
 *             <td>POST</td>
 *             <td>asset-management-service.signed-url.create</td>
 *             <td>Yes</td>
 *      </tr>
 *    </table>
 *    </section>
 */

const MobileConfigurationLanguageComponent = () => {
  const { t } = useTranslation();
  const inputRef = useRef<any>({});
  const navigate = useNavigate();
  const [permission, setPermission] = useState(true);
  const [loading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [selectFileError, setSelectFileError] = useState<any>({});
  const [languageConfig, setLanguageConfig] = useState<any>({});
  const [currentLanguage, setCurrentLanguage] = useState<any>({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [tempDropdown, setTempDropdown] = useState({});
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const config = 'MobileTranslations';

  const userData = JSON.parse(localStorage.getItem('user-profile') || '');

  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const selectedCatalog = useAppSelector((state) => state.mobileConfiguration.selectedConfig);
  const configCatalog = useAppSelector((state) => state.mobileConfiguration.configCatalog);

  const [file, setFile] = useState<Array<File>>([]);

  useEffect(() => {
    async function initialise() {
      setBreadCrumb();
      getDefaultConfig();
    }

    initialise();
  }, []);

  const getDefaultConfig = async () => {
    let mobileConfigCatalog = selectedCatalog;
    if (isEmpty(mobileConfigCatalog)) {
      await getCatalogConfigData(
        metaDataObjectList,
        dispatch,
        true,
        (data: any, paging: any) => {
          mobileConfigCatalog = data.filter((item: any) => item.category === config)[0]; //TODO: check from route
          dispatch(setSelectedConfig(mobileConfigCatalog));
        },
        (paging: any) => {
          dispatch(
            setToastData({
              isToastActive: true,
              toastMessage: t('T_ERROR_SOMETHING_WRONG'),
              toastType: 'error'
            })
          );
          navigate(RouteConstants.ROUTE_MOBILE_CONFIG_LIST);
        }
      );
    }
    setIsLoading(false);
  };

  const getConfig = async () => {
    const { configMetaData } = selectedCatalog;

    try {
      const gcpLanguageConfig = await getPublicMobileConfig();
      let { status: languageConfigStatus, data: languageConfigData } = gcpLanguageConfig;
      if (languageConfigStatus === HTTP_STATUS.HTTP_OK) {
        const { MobileTranslations } = languageConfigData;
        setLanguageConfig(languageConfigData);
        setDropdownOptions(MobileTranslations.languages);
        if (!isEmpty(currentLanguage)) {
          const { id } = currentLanguage;
          let index = findIndex(MobileTranslations.languages, {
            id
          });

          setCurrentLanguage(MobileTranslations.languages[index]);
        }
        setIsLoading(false);
      }
    } catch (e) {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_ERROR_SOMETHING_WRONG'),
          toastType: 'error'
        })
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedCatalog)) {
      getConfig();
    }
  }, [selectedCatalog]);

  const setDropdownValue = (value: any) => {
    const [dropdownValue] = value;

    if (!isEmpty(dropdownValue)) {
      if (isEmpty(file)) {
        setCurrentLanguage(dropdownValue);
      } else {
        setTempDropdown(dropdownValue);
        setConfirmToggle(true);
      }
    } else {
      setCurrentLanguage({});
    }
  };

  const handleUpload = () => {
    inputRef.current.click();
  };

  const deleteLanguage = async () => {
    const { locale } = currentLanguage;
    let currentLanguageConfig = cloneDeep(languageConfig);
    const { MobileTranslations } = currentLanguageConfig;
    let { languages } = MobileTranslations;
    let index = findIndex(languages || [], {
      locale
    });
    languages.splice(index, 1);
    setConfirmDelete(false);
    setIsLoading(true);
    let response: any = await updateGCPFile(
      JSON.stringify(currentLanguageConfig, null, '\t'),
      AppConstants.GLOBAL_MOBILE_CONFIG_FILE,
      userData ? userData.userId : ''
    );
    if (HTTP_STATUS.isSuccess(response.status)) {
      setCurrentLanguage({});
      setTempDropdown({});

      let metaDataUpdatePayload = {
        label: configCatalog.label || configCatalog.attributes?.type,
        attributes: cloneDeep(configCatalog.attributes)
      };

      let catalogIndex = findIndex(metaDataUpdatePayload.attributes.catalog, {
        category: config
      });

      metaDataUpdatePayload.attributes.version += 1;
      metaDataUpdatePayload.attributes.catalog[catalogIndex].version += 1;
      metaDataUpdatePayload.attributes.catalog[catalogIndex].updatedOn =
        moment(new Date(), 'YYYY-MM-DD hh:mm:ss').toISOString().split('.')[0] + 'Z';
      metaDataUpdatePayload.attributes.catalog[catalogIndex].updatedBy = userData
        ? userData.userId
        : '';

      const instanceResponse = await updateMetadataInstance(
        configCatalog.metadataObjectId,
        configCatalog.id,
        metaDataUpdatePayload
      );

      if (instanceResponse.status === HTTP_STATUS.HTTP_OK) {
        dispatch(
          setToastData({
            toastMessage: t('T_MOBILE_CONFIGURATION_LANGUAGE_UPDATED_SUCCESS'),
            isToastActive: true,
            toastType: 'success'
          })
        );
        setFile([]);
        if (inputRef?.current?.value) {
          inputRef.current.value = '';
        }

        await getConfig();
      } else {
        dispatch(
          setToastData({
            toastMessage: getFailureMessage(response),
            isToastActive: true,
            toastType: 'error'
          })
        );
      }
    }
  };

  const downloadFile = (id: string) => {
    const url = `${AppConstants.MOBILE_CONFIGURATION_LANGUAGE_BUCKET_PATH}${AppConstants.MOBILE_CONFIGURATION_LANGUAGE_BUCKET_FOLDER}${id}.${AppConstants.DATA_TYPE_JSON}`;
    window.open(url, '_blank');
  };

  const cancelNavigation = () => {
    navigate('/mobile-configuration/list');
  };

  const formValidator = () => {
    if (!isEmpty(file)) {
      return false;
    }
    return true;
  };

  const setBreadCrumb = () => {
    dispatch(
      setURLPaths([
        ...storedURLPaths,
        {
          key: RouteConstants.ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION,
          label: `${t('T_MOBILE_CONFIGURATION')} > ${t('T_MOBILE_CONFIGURATION_LANGUAGE')}`
        }
      ])
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles.length) {
        dispatch(
          setToastData({
            toastMessage: t('T_NOT_UPLOADED'),
            isToastActive: true,
            toastType: 'error'
          })
        );
        return;
      }
      // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
      setFile(acceptedFiles);
    },
    [file]
  );

  const bumpVersionStore = (index: number) => {
    let updatedData = cloneDeep(configCatalog);
    updatedData.attributes.version += 1;
    updatedData.attributes.catalog[index].version += 1;
    dispatch(setConfigList(updatedData));
  };

  const saveData = async () => {
    function errorHandling(errorText: string) {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: errorText,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    const { id } = currentLanguage;

    setIsLoading(true);
    setConfirmToggle(false);
    const payload = {
      filePath: `${AppConstants.MOBILE_CONFIGURATION_LANGUAGE_BUCKET_FOLDER}${id}.${AppConstants.DATA_TYPE_JSON}`,
      bucketName: getGlobalBucketName(),
      versioning: true,
      action: AppConstants.GCS_UPLOAD_ACTION,
      publicObject: true,
      expirationTime: AppConstants.GCS_MAX_EXPIRATION_TIME,
      metadata: {
        fileName: `${id}.${AppConstants.DATA_TYPE_JSON}`,
        key: id,
        userId: userData ? userData.userId : '',
        [AppConstants.HEADER_CACHE_CONTROL]: AppConstants.VALUE_NO_CACHE,
        [AppConstants.HEADER_MAX_AGE]: AppConstants.VALUE_MAX_AGE_MINIMUM
      }
    };

    const signedUrlResponse = await createSignedURL(payload);

    if (signedUrlResponse.status === HTTP_STATUS.HTTP_CREATED) {
      const { url, headers = {} } = signedUrlResponse.data;
      let [fileObj] = file;
      const uploadPayload = {
        url,
        body: fileObj,
        headers: { ...headers, [AppConstants.HEADER_CONTENT_TYPE]: fileObj?.type }
      };

      let uploadFileRes = await uploadFile(
        uploadPayload,
        () => {},
        (err: string) => {
          errorHandling(t('T_NOT_UPLOADED'));
        }
      );

      if (uploadFileRes.status === HTTP_STATUS.HTTP_OK) {
        let currentLanguageConfig = { ...languageConfig };

        const { MobileTranslations } = currentLanguageConfig;
        const { catalogId } = MobileTranslations;
        currentLanguageConfig.MobileTranslations.version += 1;

        let currentLanguageIndex = findIndex(MobileTranslations.languages, {
          id
        });
        currentLanguageConfig.MobileTranslations.languages[currentLanguageIndex].version += 1;
        let updateLanguageConfigVersion: any = await updateGCPFile(
          JSON.stringify(currentLanguageConfig, null, '\t'),
          AppConstants.GLOBAL_MOBILE_CONFIG_FILE,
          userData ? userData.userId : ''
        );

        if (updateLanguageConfigVersion.status === HTTP_STATUS.HTTP_OK) {
          let metaDataUpdatePayload = {
            label: configCatalog.label || configCatalog.attributes?.type,
            attributes: cloneDeep(configCatalog.attributes)
          };

          let catalogIndex = findIndex(metaDataUpdatePayload.attributes.catalog, {
            category: config
          });

          metaDataUpdatePayload.attributes.version += 1;
          metaDataUpdatePayload.attributes.catalog[catalogIndex].version += 1;
          metaDataUpdatePayload.attributes.catalog[catalogIndex].updatedOn =
            moment(new Date(), 'YYYY-MM-DD hh:mm:ss').toISOString().split('.')[0] + 'Z';
          metaDataUpdatePayload.attributes.catalog[catalogIndex].updatedBy = userData
            ? userData.userId
            : '';

          const instanceResponse = await updateMetadataInstance(
            configCatalog.metadataObjectId,
            configCatalog.id,
            metaDataUpdatePayload
          );

          if (instanceResponse.status === HTTP_STATUS.HTTP_OK) {
            dispatch(
              setToastData({
                toastMessage: t('T_MOBILE_CONFIGURATION_LANGUAGE_UPDATED_SUCCESS'),
                isToastActive: true,
                toastType: 'success'
              })
            );
            setFile([]);
            inputRef.current.value = '';
            await getConfig();
          } else {
            errorHandling(t('T_MOBILE_CONFIGURATION_VERSION_UPDATING_ERROR'));
          }
        }
      } else {
        errorHandling(t('T_NOT_UPLOADED'));
      }
    } else {
      errorHandling(t('T_NOT_UPLOADED'));
    }
  };

  const discardChange = () => {
    setFile([]);
    inputRef.current.value = '';
    setConfirmToggle(false);
    setCurrentLanguage(tempDropdown);
    setTempDropdown({});
  };

  const { id = '', version = '', updated = '' } = currentLanguage ?? {};

  return (
    <LoaderContainer isLoading={loading}>
      <MenuButtonsPortal>
        <BiButton
          className={'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'}
          type="button"
          onClick={cancelNavigation}
        >
          {t('T_CANCEL')}
        </BiButton>
        {permission && (
          <BiButton
            className={'bg-primary py-1.5 text-white'}
            type="button"
            disabled={formValidator()}
            onClick={saveData}
          >
            {t('T_SAVE')}
          </BiButton>
        )}
      </MenuButtonsPortal>

      <Modal
        showHeader={true}
        showCloseIcon={true}
        width="26.5rem"
        headerText={t('T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_HEADER')}
        headerStyles="text-base"
        toggleVisiblity={(value: boolean) => {
          setConfirmToggle(value);
        }}
        className="overflow-y-hidden"
        isVisible={confirmToggle}
        headerBackgroundColor="#FAFAFA"
        containerBackgroundColor="#FFFFFF"
        primaryButtonText={t('T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_YES')}
        primaryButtonWidth={'8rem'}
        secondaryButtonText={t('T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_NO')}
        secondaryButtonWidth={'8rem'}
        primaryOnClick={saveData}
        secondaryOnClick={discardChange}
        body={
          !loading && (
            <Container>
              <div className="mb-4 text-sm font-normal">
                {t('T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_SUB')}
              </div>
            </Container>
          )
        }
      />

      <div
        className="flex w-full flex-row justify-start p-5"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <Card
          title="Language"
          icon={Clipboard}
          alt="Clip Icon"
          height=""
          width=""
          className="w-3/4"
        >
          <div className="flex flex-col">
            <div className="flex w-full flex-row justify-between">
              <div className={'w-1/2'}>
                <PickList
                  fieldKey={'id'}
                  fieldValues={['name']}
                  label={t('T_LANGUAGE')}
                  selectedItems={
                    !isEmpty(currentLanguage) ? new Array(1).fill(currentLanguage) : []
                  }
                  onSelectedChange={(item: any) => {
                    setDropdownValue(item);
                  }}
                  items={dropdownOptions}
                  disabled={false}
                  readOnly={false}
                  type={PICKLIST_TYPES.SINGLE}
                />
              </div>
              <div className="ml-2 mr-2 flex flex-row self-end">
                {!isEmpty(currentLanguage) && (
                  <div className="ml-2 mr-2 flex items-center justify-center">
                    <IconButton
                      className={
                        '!m-0 h-10 w-10 border border-solid border-primary-disabled text-primary'
                      }
                      disabled={isEmpty(currentLanguage)}
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                    >
                      {trashIcon}
                    </IconButton>
                  </div>
                )}
                <div className=" ml-2 mr-2 flex">
                  <BiButton
                    className={`flex h-11 flex-row items-center rounded-r-[5px] border border-[#99B0C6] text-primary`}
                    type="button"
                    onClick={() => {
                      navigate(RouteConstants.ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION_NEW);
                    }}
                  >
                    <div className="mr-2 h-4 w-4"> {colouredAddCircle}</div>
                    {t('T_LANGUAGE_ADD_BUTTON')}
                  </BiButton>
                </div>
              </div>
            </div>
            {!isEmpty(currentLanguage) ? (
              <>
                <div className="mt-5 text-sm">{t('T_MOBILE_CONFIGURATION_LANGUAGE_INFO')}</div>
                <div className="mx-auto w-[75%] py-5">
                  <Dropzone
                    onDrop={onDrop}
                    accept={{ 'application/json': ['.json'] }}
                    maxFiles={1}
                  />
                </div>
                <div className="flex flex-row items-center justify-center">
                  <IconButton
                    className={
                      '!m-0 h-10 w-10 border border-solid border-primary-disabled text-primary'
                    }
                    disabled={isEmpty(currentLanguage)}
                    onClick={() => {
                      downloadFile(id);
                    }}
                  >
                    {downloadIcon}
                  </IconButton>

                  <BiButton
                    className={'ml-4 bg-primary text-white'}
                    type="button"
                    onClick={handleUpload}
                    disabled={isEmpty(currentLanguage)}
                  >
                    {t('T_MOBILE_CONFIGURATION_LANGUAGE_UPLOAD_HEADER')}
                  </BiButton>
                  <input
                    type="file"
                    ref={inputRef}
                    data-testid={'language-ref'}
                    onChange={(e) => {
                      onDrop(e.target.files);
                    }}
                    style={{ display: 'none' }}
                    accept={'.json'}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </Card>
        {!isEmpty(currentLanguage) ? (
          <div className="ml-5 flex w-[33%] flex-col text-[#595959]">
            <div>
              <div className="text-sm">{t('T_FILE_NAME')}</div>
              <div className="text-base">{`${id}.${AppConstants.DATA_TYPE_JSON}`}</div>
            </div>
            <div className="my-2.5">
              <div className="text-sm">{t('T_FILE_TYPE')}</div>
              <div className="text-base">{AppConstants.VALUE_APPLICATION_JSON}</div>
            </div>
            <div>
              <div className="text-sm">{t('T_VERSION')}</div>
              <div className="text-base">{version}</div>
            </div>
            <div className="my-2.5">
              <div className="text-sm">{t('T_LAST_MODIFIED')}</div>
              <div className="text-base">{moment(updated).format(AppConstants.DEFAULT_DATE_FORMAT)}</div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal
        showCloseIcon={true}
        width="48rem"
        headerText={t('T_CONFIRM_DELETION')}
        toggleVisiblity={(value: boolean) => {
          setConfirmDelete(value);
        }}
        isVisible={confirmDelete}
        body={
          <div>
            <div>{t('T_DELETE_LANGUAGE_CONTENT')}</div>
            <div className="mt-8 flex">
              <BiButton
                className={'ml-auto mr-2 text-primary'}
                type="button"
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                {t('T_CANCEL')}
              </BiButton>
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  deleteLanguage();
                }}
              >
                {t('T_DELETE')}
              </BiButton>
            </div>
          </div>
        }
        headerLeftIcon={<ExclamationIcon className="h-7.5 w-6" />}
      />
    </LoaderContainer>
  );
};

export default MobileConfigurationLanguageComponent;

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;
