import { createSlice, current } from '@reduxjs/toolkit';

export const mobileConfigurationSlice = createSlice({
  name: 'mobileConfiguration',
  initialState: {
    configCatalog: null as any,
    selectedConfig: null as any,
    dirtyConfiguraion: {} as any
  },
  reducers: {
    setSelectedConfig: (state, action) => {
      state.selectedConfig = action.payload;
    },
    setConfigList: (state, action) => {
      state.configCatalog = action.payload;
    },
    setDirtyConfiguration: (state, action) => {
      state.dirtyConfiguraion = action.payload;
    },
    deleteDirtyConfiguration: (state, action) => {
      const stateClone = Object.assign({}, state);
      const { key, category } = action.payload;
      delete stateClone.dirtyConfiguraion?.[category]?.fieldValues[key];
      delete stateClone.dirtyConfiguraion?.[category]?.fieldErrors[key];
      state.dirtyConfiguraion = stateClone.dirtyConfiguraion;
    }
  }
});

export const { setSelectedConfig, setConfigList, setDirtyConfiguration, deleteDirtyConfiguration } =
  mobileConfigurationSlice.actions;

export default mobileConfigurationSlice.reducer;
