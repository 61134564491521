import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    customers: [],
    customersTable: [],
    onboardingFirstFlow: {
      selectedStep: 1,
      stepperSteps: [
        {
          key: 1,
          description: 'T_CUSTOMER_STEPPER_DESCRIPTION',
          filled: false
        },
        {
          key: 2,
          description: 'T_CUSTOMER_STEPPER_CODE_PLUS_PASSWORD',
          filled: false
        }
      ],
      email: '',
      invitationCode: '',
      password: '',
      passwordConfirm: ''
    },
    onboardingConsentFlow: {
      selectedStep: 1,
      retrievedSteps: false,
      stepperSteps: [
        {
          key: 1,
          description: 'T_CUSTOMER_STEPPER_EXPLORE',
          filled: false,
          isFullScrolled: false,
          isAccepted: false
        }
      ]
    }
  },
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setCustomersTable: (state, action) => {
      const formattedCustomers: any = [];
      action.payload.forEach((customer: any) => {
        const formattedCustomer = {
          ...customer,
          city: '',
          state: '',
          phoneNumber: '',
          address: '',
          status: ''
        };

        formattedCustomer.status = customer.active ? 'T_ACTIVE' : 'T_NOT_ACTIVE';
        if (customer && customer.address && customer.address[0]) {
          formattedCustomer.city = customer.address[0].city;
          formattedCustomer.state = customer.address[0].state;
          formattedCustomer.address = customer.address[0].text;
        }

        if (
          customer &&
          customer.telecom &&
          customer.telecom.length > 0 &&
          customer.telecom.find((telecom: any) => telecom.system === 'phone')
        ) {
          const telecom = customer.telecom.find((telecom: any) => telecom.system === 'phone');
          formattedCustomer.phoneNumber = telecom.value;
        }
        formattedCustomers.push(formattedCustomer);
      });
      state.customersTable = formattedCustomers;
    },
    setOnboardingFirstFlow: (state, action) => {
      state.onboardingFirstFlow.email = action.payload.email
        ? action.payload.email
        : state.onboardingFirstFlow.email;
      state.onboardingFirstFlow.invitationCode =
        action.payload.invitationCode !== undefined || null
          ? action.payload.invitationCode
          : state.onboardingFirstFlow.invitationCode;

      state.onboardingFirstFlow.password =
        action.payload.password !== undefined || null
          ? action.payload.password
          : state.onboardingFirstFlow.password;

      state.onboardingFirstFlow.passwordConfirm =
        action.payload.passwordConfirm !== undefined || null
          ? action.payload.passwordConfirm
          : state.onboardingFirstFlow.passwordConfirm;
    },
    markStepFilled: (state, action) => {
      state.onboardingFirstFlow.stepperSteps.map(
        (step) => (step.filled = step.key === action.payload.selectedStep)
      );
    },
    markStepUnfilled: (state, action) => {
      state.onboardingFirstFlow.stepperSteps.map((step) => {
        if (step.key === action.payload.selectedStep) {
          step.filled = false;
        }
        return step;
      });
    },
    setSelectedStep: (state, action) => {
      state.onboardingFirstFlow.selectedStep = action.payload.selectedStep;
    },
    setOnboardingConsentFlow: (state, action) => {
      state.onboardingConsentFlow.stepperSteps = [
        ...state.onboardingConsentFlow.stepperSteps,
        ...action.payload.stepperSteps
      ];
    },
    markConsentStepFilled: (state, action) => {
      let moreFilled = true;
      state.onboardingConsentFlow.stepperSteps.map((step) => {
        step.filled = step.key === action.payload.selectedStep;
        if (moreFilled) {
          step.filled = true;
          step.isFullScrolled = true;
          step.isAccepted = true;
          moreFilled = step.key === action.payload.selectedStep ? false : moreFilled;
        }
      });
    },
    markConsentStepUnfilled: (state, action) => {
      state?.onboardingConsentFlow?.stepperSteps?.map((step) => {
        if (step?.key === action?.payload?.selectedStep) {
          step.filled = false;
        }
        return step;
      });
    },
    setConsentSelectedStep: (state, action) => {
      state.onboardingConsentFlow.selectedStep = action.payload.selectedStep;
    }
  }
});

export const { setCustomers } = customersSlice.actions;
export const { setCustomersTable } = customersSlice.actions;
export const { setOnboardingFirstFlow } = customersSlice.actions;
export const { markStepFilled } = customersSlice.actions;
export const { markStepUnfilled } = customersSlice.actions;
export const { setSelectedStep } = customersSlice.actions;

export const { setOnboardingConsentFlow } = customersSlice.actions;
export const { markConsentStepFilled } = customersSlice.actions;
export const { markConsentStepUnfilled } = customersSlice.actions;
export const { setConsentSelectedStep } = customersSlice.actions;

export default customersSlice.reducer;
