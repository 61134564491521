import styled from 'styled-components/macro';

export const Switch = styled.div<{ toggle: boolean }>`
  width: 44px;
  height: 22px;
  background: ${({ toggle }) => (!toggle ? '#BFBFBF' : '#0074e0')};
  border-radius: 16px;
  position: relative;
  transition: ease-out 0.5s;
  cursor: pointer;
  disabled
`;

export const ToggleButton = styled.div<{ toggle: boolean }>`
  position: absolute;
  height: 18px;
  top: calc(50% - 18px / 2);
  background: #ffffff;
  border-radius: 16px;
  width: 16px;
  ${({ toggle }) => (!toggle ? `left: 2px;` : 'right: 2px;')}
`;
