import { useState, useEffect } from 'react';
import { Input, Button, Label } from '../AccountProfile/ProfileStyles';
import { enrollUser, getUsers, inviteUser } from '../../http/user-management-services';
import {
  formatNameField,
  validateEmail,
  validateName,
  hasSpecialCharacter
} from '../../util/validators';
import Asterix from '../../assets/asterix.svg';
import styled from 'styled-components/macro';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../../components/shared/tables/models/ITableHeader';
import Spinner from '../primitives/Spinner/Loader';
import _, { compact, flattenDeep, cloneDeep, isEqual, filter } from 'lodash';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getUMSInvitationRoles } from '../../util/admin-utils';
import { setToastData } from '../../app/toastReducer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { AppConstants } from '../../constants/AppConstants';
import { MsgConstants } from '../../constants/ErrorMsgs';
import { NotificationCommunicationTemplate } from '../../util/Organzation';
import { getNotificationTemplates, NotificationTemplate } from '../../http/notification-services';
import { useTheme } from '../../context/themeContext';
import DropDownField from '../shared/Fields/DropdownField';
import { useTranslation } from 'react-i18next';
import { getBucketName } from '../../util/AppSetting';
import { getOrganizationType } from '../../util/OrganizationUtil';

interface InviteOrgProps {
  organization: any;
  toggleVisiblity: Function;
}

const bottomBorder = {
  borderBottom: '1px solid black'
};

const Image = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 4px;
`;

const EditButton = styled.button`
  padding: 3px 8px;
  border: 0.3px solid black;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: -7px;
  font-size: 12px;
`;

const DeleteButton = styled.button`
  padding: 3px 8px;
  border: 0.3px solid black;
  border-radius: 5px;
  font-size: 12px;
  background-color: #2c71ad;
  color: #ffffff;
`;

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

interface AdminFormProps {
  firstName: string;
  setFirstName: Function;
  lastName: string;
  setLastName: Function;
  email: string;
  setEmail: Function;
  oldEmail: string;
  setOldEmail: Function;
  editPayload: any;
  emailError: string;
  setEmailError: Function;
  adminArray: any;
  addAdmin: Function;
  updateAdmin: Function;
  cancelEdit: Function;
  roles: any[];
  selectRole: any;
  setSelectRole: Function;
}
const AdminForm: React.FC<AdminFormProps> = ({
  firstName,
  lastName,
  setFirstName,
  setLastName,
  email,
  setEmail,
  editPayload,
  oldEmail,
  setOldEmail,
  emailError,
  setEmailError,
  adminArray,
  addAdmin,
  updateAdmin,
  cancelEdit,
  roles,
  selectRole,
  setSelectRole
}) => {
  const [fnameError, setFnameError] = useState('');
  const [lnameError, setLnameError] = useState('');
  const [roleError, setRoleError] = useState('');
  const { t } = useTranslation();

  const setStateEmpty = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setOldEmail('');
    setSelectRole({ value: '', key: '' });
  };

  const emailExist = () => {
    let flag = true;
    if (validateEmail(email)) {
      adminArray.forEach((admin: any) => {
        if (admin.email === email && email !== oldEmail) {
          flag = false;
          setEmailError(`${t('T_VALIDATION_ERR_EMAIL_EXITS_MSG')}`);
        }
      });
    } else {
      flag = false;
      setEmailError(`${t('T_VALIDATION_ENTER_VALID_EMAIL_MSG')}`);
    }
    return flag;
  };

  const validateFirstLastNameStr = () => {
    const fname = validateName(firstName, t);
    const lname = validateName(lastName, t);
    const specialCharacterError = `${t('T_VALIDATION_ERR_NAME_MSG')}`;
    //const fnameHasSpecialCharacters = hasSpecialCharacter(firstName) ? specialCharacterError : '';
    //const lnameHasSpecialCharacters = hasSpecialCharacter(lastName) ? specialCharacterError : '';

    if (fname || lname) {
      setFnameError(fname);
      setLnameError(lname);
      return false;
    }

    // if (fnameHasSpecialCharacters || lnameHasSpecialCharacters) {
    //   setFnameError(fnameHasSpecialCharacters);
    //   setLnameError(lnameHasSpecialCharacters);
    //   return false;
    // }

    return true;
  };

  const submitEditForm = async (e: any) => {
    e.preventDefault();
    let payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: selectRole
    };
    try {
      const validName = validateFirstLastNameStr();
      if (emailExist() && validName) {
        updateAdmin(payload);
        setStateEmpty();
        // console.log(payload);
      } else {
        console.log('Else');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    let payload = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email,
      role: selectRole
    };
    try {
      const validName = validateFirstLastNameStr();
      if (emailExist() && roleValidation() && validName) {
        addAdmin(payload);
        setStateEmpty();
        // console.log(payload);
      } else {
        console.log(t('T_ALREADY_USED'));
      }
    } catch (error) {}
  };

  const roleValidation = () => {
    let flag = true;
    if (selectRole.value === '') {
      setRoleError(`${t('T_VALIDATION_SELECT_USER_ROLE_MSG')}`);
      flag = false;
    }
    return flag;
  };

  return (
    <>
      <form onSubmit={editPayload.isEdit ? submitEditForm : submitForm}>
        <div className="flex">
          <div className="flex w-1/2 flex-col">
            <Label htmlFor="admin-first-name">
              <Image src={Asterix} alt="asterix" /> {t('T_FIRST_NAME')}
            </Label>
            <Input
              id="admin-first-name"
              value={firstName}
              onChange={({ target: { value } }) => {
                //const formattedValue = formatNameField(value);
                setFirstName(value);
                setFnameError('');
              }}
            />
            <ErrorLabel>{fnameError}</ErrorLabel>
          </div>

          <div className="flex w-1/2 flex-col pl-2">
            <Label htmlFor="admin-first-name">
              <Image src={Asterix} alt="asterix" /> {t('T_LAST_NAME')}
            </Label>
            <Input
              id="admin-last-name"
              value={lastName}
              onChange={({ target: { value } }) => {
                //const formattedValue = formatNameField(value);
                setLastName(value);
                setLnameError('');
              }}
            />
            <ErrorLabel>{lnameError}</ErrorLabel>
          </div>
        </div>

        <div className="flex">
          <div className="mt-1 flex w-2/3 flex-col">
            <Label htmlFor="admin-email">
              <Image src={Asterix} alt="asterix" /> {t('T_EMAIL')}
            </Label>
            <Input
              id="admin-email"
              value={email}
              onChange={({ target: { value } }) => {
                setEmail(value);
                setEmailError('');
              }}
            />
            <ErrorLabel>{emailError}</ErrorLabel>
          </div>

          <div className="mt-1 flex w-1/3 flex-col pl-2">
            <Label htmlFor="admin-role">
              <Image src={Asterix} alt="asterix" /> {t('T_ROLE')}
            </Label>
            <DropDownField
              fieldKey={'value'}
              data={selectRole}
              setData={(v: any) => {
                setSelectRole(v);
              }}
              options={roles.map((ele: any) => {
                return {
                  value: ele,
                  key: ele
                };
              })}
              disabled={false}
            />
            <ErrorLabel>{roleError}</ErrorLabel>
          </div>
        </div>

        <div className="mt-5 w-full text-right">
          {email.length || firstName.length || lastName.length ? (
            <BiButton
              className={`mr-4 px-4`}
              onClick={() => {
                setStateEmpty();
                cancelEdit();
              }}
              type="button"
            >
              {t('T_CANCEL')}
            </BiButton>
          ) : (
            <></>
          )}
          <Button
            disabled={
              !email.trim().length ||
              !firstName.trim().length ||
              !lastName.trim().length ||
              !selectRole.value
            }
            className={`px-4 ${
              !email.trim().length ||
              !firstName.trim().length ||
              !lastName.trim().length ||
              !selectRole.value
                ? 'cursor-not-allowed'
                : ''
            }`}
            style={{ width: '140px' }}
            onClick={editPayload.isEdit ? submitEditForm : submitForm}
            theme={useTheme().theme}
          >
            {editPayload.isEdit ? t('T_BUTTON_UPDATE') : t('T_BUTTON_ADD')}
          </Button>
        </div>
      </form>
    </>
  );
};

/**
 * @parentId user-list
 * @manager Invite User(s) to Organization
 * @overview Invite User(s) to organization
 *   <section>
 *       <p>
 *           User's can be invited to an organization through invitation only mechanism. Admin user can invite user(s) by selecting <b>Invite Users</b> menu from organization listing page context menu or from <b>Manage Organization</b> drop down menu option in organization detials page(only in Edit Mode).
 *           In the invite user dialog user need to provide <b>First Name, Last Name, Mail & Role </b> of the user to be invited. Role is an drop down field which gets the list of values from <b>solution.organization.invitation.policy</b> or <b>user.management.invitation.policy</b> system setting. If admin wants to filter the roles to be invited on organization basis,
 *           them possible roles to invited for each organization needs to be defined in <b>solution.organization.invitation.policy</b> system setting. Invite user(s) Dialog would look for the list of roles to be invited for that specific organization in <b>solution.organization.invitation.policy</b> system settings and list them in roles drop down,
 *           If there is no <b>solution.organization.invitation.policy</b> system setting defined or there is no roles mapped for specific organization type, Invite user(s) will fallback to <b>user.management.invitation.policy</b> system setting to get the possible roles that the logged-in user (role) can invite and display them in the drop down.
 *           <br/><br/>
 *           Since user invitation needs a notification template to send an invitation. Currenty all user invites made from Admin Portal would use the default <b>ADMIN_Invitation</b> notification template, unless there is a role specific template available in the inviting organization or its upstream parent hierarchy.
 *           While inviting user for an organization with a specific role, Admin Portal will look an notification template specific to that role. Ex. If the user is been invited for "Clinician" role, Admin Portal will look for a notification template <b>ADMIN_Invitation__Clinician</b> in the inviting organization and its upstream organization,
 *           if it finds one or more templates from inviting & parent organization, it will pick the template from inviting organization, ir not from immediate parent organization and use that template for making an user invitation.
 *           This way admin(s) can have custom template for every role in every organization and control the content of the invitation template
 *           <br/><br/>
 *           Admin Portal allows multiple user(s) to invited with different role(s) in a single iteration by adding mutiple user(s) in Invite User(s) modal dialog. However currently it can not invite a user into multiple role(s). If there is an existing user in an organization, on inviting the same user to another organization with the previous role, user will automatically get enrolled into the target organization.
 *       </p>
 *
 *       <p>
 *          <h4>Invite User Fields</h4>
 *          Create/View/Edit notification template page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>FirstName</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>LastName</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>E-Mail</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Role</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *          </table>
 *          <br/>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes user invite failure status code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>UMS_PERMISSION_DENIED</td>
 *               <td>Resource {0} does not have permission to {1}.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>UMS_PARAM_NOT_EXISTS</td>
 *               <td>Resource {0} with {1} does not exist.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>UMS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of for user invition to organization.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>solution.organization.invitation.policy</td>
 *               <td>JSON</td>
 *               <td>{"Clinic":[{"role":["Clinic Admin"],"isAdmin":true,"template":"ADMIN_Invitation","enrollments":[{"role":"Clinic Admin","owner":true,"parent":true}]}],"Sponsor":[{"role":["Sponsor Admin"],"isAdmin":true,"template":"ADMIN_Invitation"}],"Health System":[{"role":["Health System Customer Admin","CMO"],"isAdmin":true,"template":"ADMIN_Invitation"}],"Standalone Clinic":[{"role":["Clinic Customer Admin","CMO"],"isAdmin":true,"template":"ADMIN_Invitation"}]}</td>
 *               <td>Global</td>
 *           </tr>
 *           <tr>
 *               <td>user.management.invitation.policy</td>
 *               <td>JSON</td>
 *               <td>{"IT_ADMIN":["IT_ADMIN","APPLICATION_CLIENT"]}</td>
 *               <td>Organization</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for user invition to organization.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>Organization Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>Access Control Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>Notification Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for user invition to organization</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/invitations</td>
 *               <td>POST</td>
 *               <td>N/A</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{id}</td>
 *               <td>GET</td>
 *               <td>organization-service.organization.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/notifications/templates</td>
 *               <td>GET</td>
 *               <td>notification-service.template.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/system-settings</td>
 *               <td>GET</td>
 *               <td>configuration-service.setting.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{organization-id}/settings</td>
 *               <td>GET</td>
 *               <td>configuration-service.organization-setting.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for user invition to organization</p>
 *   </section>
 */
const NewInviteOrg: React.FC<InviteOrgProps> = ({ organization, toggleVisiblity }) => {
  const theme = useTheme().theme;
  const dispatch = useAppDispatch();

  const [invited, setInvited] = useState(0);
  const [loader, setLoader] = useState(true);
  const [registered, setRegistered] = useState(0);
  const [adminArray, setAdminArray] = useState<any[]>([]);
  const [inviteLoader, setInviteLoader] = useState(false);

  //For Form
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');

  const [oldEmail, setOldEmail] = useState('');
  const [editPayload, setEditPayload] = useState({ isEdit: false, editIndex: 0 });
  const [emailError, setEmailError] = useState('');

  const [invitingRoles, setInvitingRoles] = useState<any>([]);
  const [selectRole, setSelectRole] = useState({ value: '', key: '' });

  const [adminInvitationTemplate, setAdminInvitationTemplate] =
    useState<NotificationCommunicationTemplate>();

  const orgInvitationPolicy: any = useAppSelector(
    (state) => state.configuration.orgInvitationPolicy
  );

  const umsInvitationPolicy: any = useAppSelector(
    (state) => state.configuration.userInvitationPolicy
  );

  const userProfile: any = useAppSelector((state) => state.userManagement.userProfileInfo);

  const { id, name } = organization;
  const bucketName = getBucketName(id);
  const { t } = useTranslation();

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    if (editPayload.isEdit) {
      setEdit(editPayload);
    }
  }, [editPayload]);

  const callAPI = async () => {
    getAdminInvitationTemplates(id);
    setInvitingRoles(getInvitingRoles());

    // const response: any = await getUsers(
    //   userFilterQuery('', [id]),
    //   AppConstants.DEFAULT_PAGE,
    //   AppConstants.MAXIMUM_PAGE_SIZE
    // );
    // const { status, data: userResponse } = response;
    // if (status === HTTP_STATUS.HTTP_OK) {
    //   const { data: userList = [] } = userResponse;
    //   getRegisteredStatistics(userList);
    // }
    setLoader(false);
  };

  const getInvitingRoles = () => {
    let invitationPolices: Array<any> = new Array<string>();
    let invitationRoles: Array<string> = new Array<string>();

    if (orgInvitationPolicy && Object.keys(orgInvitationPolicy).length) {
      const orgType = getOrganizationType(organization);
      invitationPolices = orgInvitationPolicy[orgType?.trim()] || [];
      invitationRoles = flattenDeep(
        invitationPolices
          .filter((invitationPolicy: any) => !!invitationPolicy.isAdmin)
          .map((invitationPolicy: any) => invitationPolicy.role)
      );
    }

    if (!compact(invitationRoles).length) {
      invitationRoles = getUMSInvitationRoles(umsInvitationPolicy, userProfile);
    }
    return invitationRoles;
  };

  const getRegisteredStatistics = (users: Array<any> = []) => {
    let active: number = 0;
    let invite: number = 0;
    const registeredStatus: Array<string> = [AppConstants.USER_ACCOUNT_STATUS_ACTIVE];
    const pendingStatus: Array<string> = [
      AppConstants.USER_ACCOUNT_STATUS_INVITED,
      AppConstants.USER_ACCOUNT_STATUS_REINVITED
    ];

    users.forEach((user) => {
      const { accountStatus } = user;
      if (registeredStatus.includes(accountStatus)) {
        active += 1;
      } else if (pendingStatus.includes(accountStatus)) {
        invite += 1;
      }
    });

    setInvited(invite);
    setRegistered(active);
    setLoader(false);
  };

  const getAdminInvitationTemplates = async (organizationId: string) => {
    // const response = await getOrganizationSettings(
    //   organizationId,
    //   AppConstants.CATEGORY_APPLICATION_SYSTEM,
    //   AppConstants.DEFAULT_PAGE,
    //   AppConstants.MAXIMUM_PAGE_SIZE
    // );
    // const { status, data: settingResponse } = response;
    // if (status == HTTP_STATUS.HTTP_OK) {
    //   const { data: settingList = [] } = settingResponse;
    //   const [orgSetting] = settingList.filter(
    //     (setting: SystemSetting) => setting.key === AppConstants.CATEGORY_COMMUNICATION_TEMPLATE
    //   );
    //   if (orgSetting) {
    //     const { value = {} } = orgSetting;
    //     const { templates = [] } = value;
    //     const invitationTemplates: Array<NotificationCommunicationTemplate> = templates.filter(
    //       (template: NotificationCommunicationTemplate) =>
    //         template.usage === AppConstants.TEMPLATE_ADMIN_INVITATION_USAGE
    //     );
    //     let invitationTemplate: any = '';
    //     if (invitationTemplates.length > 1) {
    //       [invitationTemplate] = invitationTemplates.filter(
    //         (template: NotificationCommunicationTemplate) =>
    //           template?.category
    //             ?.trim()
    //             ?.toLowerCase()
    //             ?.startsWith(AppConstants.TEMPLATE_ADMIN_INVITATION_PREFIX.toLowerCase())
    //       );
    //     } else {
    //       [invitationTemplate] = invitationTemplates;
    //     }
    //     setAdminInvitationTemplate(invitationTemplate);
    //   }
    // }
    const adminPortalCommTpl = AppConstants.ADMINPORTAL_COMMUNICATION_TEMPLATES;
    let invitationTemplate: any = '';
    [invitationTemplate] = filter(adminPortalCommTpl, {
      usage: AppConstants.TEMPLATE_ADMIN_INVITATION_USAGE
    });
    setAdminInvitationTemplate(invitationTemplate);
  };

  const getInvitationOrganization = () => {
    let orgId = id;
    const { parentId } = organization;
    const orgType = getOrganizationType(organization);

    const invitationPolices: Array<any> =
      (orgInvitationPolicy && orgInvitationPolicy[orgType]) || [];
    if (invitationPolices.length) {
      const [invitationPolicy] = invitationPolices.filter(
        (invitationPolicy: any) => invitationPolicy?.isAdmin
      );

      const { enrollments = [] } = invitationPolicy || {};
      if (enrollments.length) {
        const [primryOwner] = enrollments.filter((enrollment: any) => enrollment?.owner);
        if (primryOwner) {
          const { parent = false } = primryOwner;
          if (parent) {
            orgId = parentId || orgId;
          }
        }
      }
    }
    return orgId;
  };

  const getRoleInvitationTemplate = async (roleName: string): Promise<any> => {
    let template: string = AppConstants.TEMPLATE_ADMIN_INVITATION_USAGE;
    const { category: templateName = '' } = (adminInvitationTemplate as any) || {
      category: AppConstants.TEMPLATE_ADMIN_INVITATION_USAGE
    };

    const customTemplateByRole = `${templateName}__${roleName.trim().replace(/ /g, '_')}`;
    const response = await getNotificationTemplates(
      customTemplateByRole?.toString(),
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE,
      AppConstants.DEFAULT_SORT_BY,
      AppConstants.DEFAULT_SORT_DESC,
      id,
      true,
      true
    );
    const { status, data: responseBody } = response;
    if (status == HTTP_STATUS.HTTP_OK && responseBody?.data?.length) {
      const templates: Array<any> = responseBody?.data?.filter(
        (template: NotificationTemplate) => template?.category === customTemplateByRole
      );
      const [customTemplate] = templates;
      template = customTemplate?.category || templateName;
    } else {
      template = templateName;
    }
    return template;
  };

  const inviteAdmins = async () => {
    setInviteLoader(true);

    // let roleName: string = '';
    let success: boolean = true;
    const orgId: string = getInvitationOrganization();

    // const invitedUsers: Array<any> = new Array<any>();
    let msg = `${name} ${t('T_ADMIN_INVITED_SUCCESS_MSG')}`;
    const responses = await Promise.all(
      adminArray?.map(async (admin) => {
        const { email, firstName, lastName, role = {} } = admin;
        const { value: roleName = '' } = role;

        const template: string = await getRoleInvitationTemplate(roleName);
        return inviteUser({
          orgId,
          roleName,
          template,
          email,
          name: [
            {
              given: firstName?.trim(),
              family: lastName?.trim()
            }
          ],
          firstName: firstName?.trim(),
          lastName: lastName?.trim()
        });
      })
    );

    if (!isEqual(id, orgId)) {
      const enrolledUsers: Array<string> = responses
        .filter((response: any) => response.status === HTTP_STATUS.HTTP_CREATED)
        .map((response: any) => response.data.userId);
      if (enrolledUsers.length) {
        await Promise.allSettled(
          enrolledUsers.map((userId: string) => enrollUser(userId, [{ organizationId: id }]))
        );
      }
    }

    const failedResponses: Array<any> = responses?.filter(
      (response: any) => response?.status >= HTTP_STATUS.HTTP_BAD_REQUEST
    );

    if (failedResponses.length) {
      let duplicateEmail: string[] = [];
      let msgSet = new Set();
      await Promise.all(
        failedResponses.map(async (response: any, index: number) => {
          await Promise.all(
            response?.data?.issues?.map(async (issue: any) => {
              if (issue.code === AppConstants.INVITE_ADMIN_DUPLICATE_EMAIL_ERROR) {
                const filter = `[{"field":"mail","operation":"eq","value":"${adminArray[index].email}"}]`;
                const userResponse: any = await getUsers(
                  filter,
                  AppConstants.DEFAULT_PAGE,
                  AppConstants.DEFAULT_PAGE_SIZE
                );
                const { status, data: usersResponse } = userResponse;
                if (status == HTTP_STATUS.HTTP_OK) {
                  const { data: userList = [] } = usersResponse;
                  if (userList.length) {
                    const { roles = [], userId } = userList[0];
                    roles.map(async (role: any) => {
                      if (role.name === adminArray[index].role.key) {
                        const enrollUsers: Array<any> = new Array<any>();
                        enrollUsers.push({ organizationId: id });

                        if (!isEqual(id, orgId)) {
                          enrollUsers.push({ organizationId: orgId });
                        }
                        await enrollUser(userId, enrollUsers);
                        success = true;
                      } else {
                        duplicateEmail.push(adminArray[index].email);
                      }
                    });
                  } else {
                    msgSet.add(issue.details);
                  }
                }
              } else {
                msgSet.add(issue.details);
              }
            })
          );
        })
      );

      if (duplicateEmail.length) {
        let duplicateEmailError = `${t(MsgConstants.INVITE_ADMIN_USER_EXIST)}[${duplicateEmail
          .map((email: string) => email)
          .join()}]`;

        msgSet.add(duplicateEmailError);
      }
      msg = Array.from(msgSet).join();
      success = false;
    }

    if (success) {
      dispatch(setToastData({ toastMessage: msg, isToastActive: success, toastType: 'success' }));
      setAdminArray([]);
      toggleVisiblity();
    } else {
      dispatch(setToastData({ toastMessage: msg, isToastActive: true, toastType: 'error' }));
    }
    setInviteLoader(false);
  };

  // const updateOrganizationBucket = async (users: Array<any> = []) => {
  //   if (!users.length) {
  //     return;
  //   }
  //   const responses: Array<any> = await Promise.all([
  //     listRoles('', AppConstants.DEFAULT_PAGE, AppConstants.MAXIMUM_PAGE_SIZE),
  //     listBuckets(AppConstants.DEFAULT_PAGE, AppConstants.MAXIMUM_PAGE_SIZE)
  //   ]);
  //   const [rolesResponse, buckets] = responses;
  //   const { status: rolesStatus, data: rolesData } = rolesResponse;

  //   if (rolesStatus == HTTP_STATUS.HTTP_OK) {
  //     const { data: rolesList = [] } = rolesData;

  //     // const buckets: any = await listBuckets();
  //     const userBucket = buckets?.data?.data?.filter((bucket: any) => bucket.name == bucketName);

  //     if (userBucket.length) {
  //       const [bucket] = userBucket;
  //       const { bucketOwners = [], bucketRoleOwners = [], bucketRolePermissions = [] } = bucket;

  //       const rolesMap: Map<string, any> = new Map<string, any>(
  //         rolesList.map((role: any) => [role?.name, role])
  //       );
  //       const bucketRoleOwnerIds: Array<string> = bucketRoleOwners.map(
  //         (bucketRoleOwner: any) => bucketRoleOwner?.roleId
  //       );

  //       const newBucketRoleOwnerIds = Array<string>();
  //       users.forEach((user: any) => {
  //         const role: any = rolesMap.get(user?.roleName);
  //         if (role) {
  //           const { id } = role;
  //           if (!bucketRoleOwnerIds.includes(id)) {
  //             newBucketRoleOwnerIds.push(id);
  //           }
  //         }
  //       });

  //       let payload = {
  //         bucketOwners: Array.from(
  //           new Set([
  //             ...bucketOwners?.map((item: any) => item?.userId),
  //             ...users.map((user: any) => user.userId)
  //           ])
  //         ),
  //         bucketRoleOwners: Array.from(
  //           new Set([
  //             ...newBucketRoleOwnerIds,
  //             ...bucketRoleOwners?.map((item: any) => item?.roleId)
  //           ])
  //         ),
  //         bucketRolePermissions: [
  //           ...bucketRolePermissions,
  //           ...newBucketRoleOwnerIds.map((roleId: any) => {
  //             return { roleId, writePermission: true };
  //           })
  //         ]
  //       };

  //       await updateBucketPermissions(payload, bucket?.id);
  //     }
  //   }
  // };

  // const addUsersToChildrenOrg = async (organization: any, users: Array<any> = []) => {
  //   const { id } = organization;
  //   const response = await getChildOrganizations(
  //     id,
  //     AppConstants.QUERY_PARAM_LEVEL_DIRECT,
  //     '',
  //     AppConstants.DEFAULT_PAGE,
  //     AppConstants.MAXIMUM_PAGE_SIZE
  //   );
  //   const { status, data } = response;
  //   if (status == HTTP_STATUS.HTTP_OK) {
  //     const { data: children = [] } = data;
  //     const orgIds = children?.map((org: any) => {
  //       return { organizationId: org?.id };
  //     });
  //     // orgIds.length && Promise.all(users.map((user: any) => enrollUser(user?.userId, orgIds)));
  //     orgIds.length &&
  //       users.map((user: any) =>
  //         Promise.all(orgIds.map((item: any) => enrollUser(user?.userId, [item])))
  //       );
  //   }
  // };

  const addAdmin = (admin: any) => {
    let temp = cloneDeep(adminArray);
    temp.push(admin);
    setAdminArray(temp);
    setLoader(false);
  };

  const setEdit = (editPayload: any) => {
    setFirstName(adminArray[editPayload.editIndex].firstName);
    setLastName(adminArray[editPayload.editIndex].lastName);
    setEmail(adminArray[editPayload.editIndex].email);
    setOldEmail(adminArray[editPayload.editIndex].email);
    setSelectRole(adminArray[editPayload.editIndex].role);
  };

  const updateAdmin = (admin: any) => {
    let temp = cloneDeep(adminArray);
    temp[editPayload.editIndex].email = admin.email;
    temp[editPayload.editIndex].firstName = admin.firstName;
    temp[editPayload.editIndex].lastName = admin.lastName;
    temp[editPayload.editIndex].role = admin.role;

    setAdminArray(temp);
    setInviteLoader(false);
    setEditPayload({ isEdit: false, editIndex: 0 });
  };

  const removeAdmin = (index: number) => {
    let updatedArr = cloneDeep(adminArray);
    if (editPayload.editIndex === index) {
      setEditPayload({ isEdit: false, editIndex: 0 });
    }
    updatedArr.splice(index, 1);
    setAdminArray(updatedArr);
    setLoader(false);
  };

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>([
    {
      key: 'firstName',
      description: `${t('T_FIRST_NAME')}`,
      sortEnabled: false,
      width: '15%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'lastName',
      description: `${t('T_LAST_NAME')}`,
      sortEnabled: false,
      width: '15%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'email',
      description: `${t('T_EMAIL')}`,
      sortEnabled: false,
      width: '35%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'role',
      description: `${t('T_ROLE')}`,
      sortEnabled: false,
      width: '25%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        const { role } = data || {};
        return (role && role.value) || '';
      }
    },
    {
      key: 'buttons',
      description: `${t('T_ACTIONS')}`,
      sortEnabled: false,
      width: '10%',
      sortingInformation: {
        order: ''
      }
    }
  ]);

  return loader ? (
    <div className="flex w-full items-center justify-center py-40">
      <Spinner height="40px" width="40px"></Spinner>
    </div>
  ) : (
    <div
      className={`w-50 pb-4 pt-0 text-sm ${
        inviteLoader ? 'pointer-events-none cursor-not-allowed' : ''
      }`}
    >
      {/* <div className="flex py-2" style={bottomBorder}>
        <div className="w-1/2">
          {registered} {t('T_REGISTERED')}
        </div>
        <div className="w-1/2">
          {invited} {t('T_INVITED')}
        </div>
      </div> */}

      <div className="pt-2">
        <AdminForm
          addAdmin={addAdmin}
          adminArray={adminArray}
          updateAdmin={updateAdmin}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          oldEmail={oldEmail}
          setOldEmail={setOldEmail}
          editPayload={editPayload}
          emailError={emailError}
          setEmailError={setEmailError}
          cancelEdit={() => {
            setEditPayload({ isEdit: false, editIndex: 0 });
          }}
          roles={invitingRoles}
          selectRole={selectRole}
          setSelectRole={setSelectRole}
        />
      </div>

      <div>
        <p className="mb-3 mt-3">
          {t('T_INVITED_USERS_TO_MSG')} <b className="break-all">{name}</b>
        </p>
        <div
          className="w-full"
          style={{
            minHeight: '200px',
            maxHeight: '250px',
            overflowX: 'auto',
            borderBottom: '1px solid gray'
          }}
        >
          <div className={`mb-6 flex w-full flex-col flex-wrap`}>
            <TemplateTable
              tableData={adminArray}
              onRefreshTableData={() => {}}
              size={'10'}
              currentPage={'0'}
              templateSelector={() => {}}
              tableHeaders={tableHeaders}
              setTableHeaders={setTableHeaders}
              pagingData={{}}
              containerClass="mt-0"
              isSearchable={false}
              isPaginated={false}
              showEditDeleteButton={true}
              editOnClick={(index: number) => {
                setEditPayload({
                  isEdit: true,
                  editIndex: index
                });
              }}
              deleteOnClick={(index: number) => {
                removeAdmin(index);
              }}
            />
          </div>
        </div>

        <div className="mt-2 w-full text-right">
          <BiButton className={`px-4} mr-4`} onClick={() => toggleVisiblity()} type="button">
            {t('T_CANCEL')}
          </BiButton>

          <Button
            disabled={!adminArray.length || loader || inviteLoader}
            className={`px-4 ${!adminArray.length && 'cursor-not-allowed'}`}
            onClick={() => inviteAdmins()}
            style={{ width: '140px' }}
            theme={theme}
          >
            {inviteLoader ? (
              <div className="flex w-full items-center justify-center">
                <Spinner height="25px" width="25px" color="#ffffff" margin="0px"></Spinner>
              </div>
            ) : (
              `${t('T_INVITE_USER')}`
            )}
          </Button>
        </div>
      </div>

      {/* <ToastContainer>
        <ToastNotification
          message={messageInviteAPI}
          isActive={inviteToast}
          setIsActive={setInviteToast}
          status={failure ? 'error': 'success'}
        />
      </ToastContainer> */}
    </div>
  );
};

export default NewInviteOrg;
