import { useEffect, useState } from 'react';
import styled from 'styled-components';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

interface FieldListTableProps {
  fieldList: Array<any>;
}

function FieldListTable({ fieldList }: FieldListTableProps) {
  const { t } = useTranslation();

  const FieldGroupHeaders: Array<ITableHeader> = [
    {
      key: 'name',
      description: 'T_NAME',
      sortEnabled: false,
      width: '30%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'display',
      description: 'T_DISPLAY',
      sortEnabled: false,
      width: '70%',
      sortingInformation: {
        order: ''
      }
    }
  ];

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(FieldGroupHeaders);

  return (
    <>
      <CustomersTableContainer>
        <TemplateTable
          tableData={fieldList}
          showDeleteButton={true}
          onRefreshTableData={() => {}}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          isSearchable={false}
          isPaginated={false}
          tableBodyClass="overflow-y-scroll block h-[400px]"
          containerClass="mt-0 mb-0"
          size=""
          currentPage={''}
          templateSelector={''}
          theme={useTheme().theme}
        />
      </CustomersTableContainer>
    </>
  );
}

export default FieldListTable;
