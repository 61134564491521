import React, { useEffect } from 'react';

type BrowserEventHandlerProps = {
  children: React.ReactNode;
};

const BrowserEventHandler = ({ children }: BrowserEventHandlerProps) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
  }, []);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  return <>{children}</>;
};

export default BrowserEventHandler;
