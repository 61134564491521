import { closeCircle } from '../../../assets/components/svgs';

interface CardProps {
  name: string;
  onClick: any;
  isLast: boolean;
}

const SelectedCard = ({ name, onClick, isLast }: CardProps) => {
  return (
    <div
      className={`flex w-full items-center rounded-md border bg-secondary px-4 py-2 shadow-md ${
        !isLast && 'mb-2.5'
      }`}
    >
      <p className="flex-grow text-base font-medium capitalize">{name}</p>
      <div onClick={onClick} className="h-5 w-5 cursor-pointer">
        {closeCircle}
      </div>
    </div>
  );
};

export default SelectedCard;
