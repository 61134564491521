import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Conditions, Organization, closeCircle } from '../../assets/components/svgs';
import Card from '../shared/cards/Card';
import { setConditionsList, setFormData, setOrgList } from '../../app/carePlanReducer';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import { AppConstants } from '../../constants/AppConstants';
import { getConditions } from '../../http/careplan-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  getCarePlanConditions,
  getCarePlanOrgList,
  getCarePlanOrganizations,
  getConditionsList
} from './carePlanUtils';
import { getOrganizations } from '../../http/organization-services';

import { IconButton } from '../primitives/buttons/IconButton';
import { cloneDeep, findIndex } from 'lodash';
import PillsButton from '../shared/Fields/PillsButton';
import SelectedCard from './Reusable/SelectedCars';
import Pills from './Reusable/Pills';

interface FormProps {
  isEdit: boolean;
}

const Assignment = ({ isEdit }: FormProps) => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formData = useAppSelector((state) => state.carePlanData.formData);
  const conditionsListData: any =
    useAppSelector((state) => state.carePlanData.conditionsList) || [];
  const carePlanOrgList: any = useAppSelector((state) => state.carePlanData.orgList) || [];
  const [formError, setFormError] = useState<any>({});

  const userProfileInfo: any = useAppSelector((state) => state.userManagement.userProfileInfo);

  const updateFormData = (value: any) => {
    dispatch(setFormData(value));
  };

  useEffect(() => {
    if (!conditionsListData.length) {
      getCarePlanConditions().then((conditions: any) => {
        if (conditions?.length) {
          dispatch(setConditionsList(conditions));
        }
      });
    }
  }, [conditionsListData]);

  useEffect(() => {
    if (!carePlanOrgList.length) {
      getCarePlanOrganizations().then((orgs: any) => {
        if (orgs?.length) {
          dispatch(setOrgList(orgs));
        }
      });
    }
  }, [carePlanOrgList]);

  const removeCondition = (fieldKey: string, itemKey: string, itemValue: string) => {
    let clonedData = cloneDeep(formData?.[fieldKey]);
    const index = findIndex(clonedData, function (item: any) {
      return item?.[itemKey] === itemValue;
    });

    if (index !== -1) {
      clonedData.splice(index, 1);
      updateFormData({ [fieldKey]: clonedData });
    }
  };

  return (
    <div className="h-[75vh] overflow-hidden p-4">
      <Card
        title={t('T_CARE_PLAN_CONDITIONS')}
        icon={Conditions}
        alt={t('T_CARD_ALT_MESSAGE')}
        height=""
        width=""
      >
        <div className="flex w-full flex-wrap">
          <div className="mb-5 flex w-full">
            <div className="flex w-full flex-col">
              <PickList
                fieldKey={'key'}
                fieldValues={['value']}
                label={t('T_CARE_PLAN_ADD_CONDITIONS')}
                selectedItems={formData?.conditions}
                showAddOption={false}
                onSelectedChange={(items: any) => {
                  updateFormData({ conditions: items });
                }}
                items={getConditionsList(conditionsListData)}
                disabled={!isEdit}
                readOnly={false}
                type={PICKLIST_TYPES.MULTI}
              />
            </div>
          </div>
        </div>

        {formData?.conditions?.length ? (
          <div className="mb-5 max-h-[20rem] overflow-y-auto">
            {formData?.conditions.map((item: any, index: number) => {
              return (
                <SelectedCard
                  isLast={index === formData.conditions.length - 1}
                  key={index}
                  name={item?.value}
                  onClick={() => {
                    removeCondition('conditions', 'key', item?.key);
                  }}
                ></SelectedCard>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </Card>
      <Card
        title={t('T_CARE_PLAN_ORGANIZATION')}
        icon={Organization}
        alt={t('T_CARD_ALT_MESSAGE')}
        height=""
        width=""
      >
        <div className="flex w-full flex-wrap">
          <div className="mb-5 flex w-full flex-col">
            <PickList
              fieldKey={'id'}
              fieldValues={['name']}
              label={t('T_CARE_PLAN_ADD_ORGANIZATION')}
              selectedItems={formData.organization}
              showAddOption={false}
              onSelectedChange={(items: any) => {
                updateFormData({ organization: items });
              }}
              items={carePlanOrgList}
              disabled={!isEdit}
              readOnly={false}
              type={PICKLIST_TYPES.MULTI}
            />
          </div>
          {formData?.organization?.length ? (
            <div>
              <div className="mb-2.5 text-base font-normal leading-6">
                {t('T_CARE_PLAN_CONNECTED_ORG')}
              </div>
              <div className="mb-5 flex max-h-[20rem] w-full flex-wrap gap-2.5 overflow-y-auto">
                {formData?.organization.map((item: any, index: number) => {
                  return (
                    <Pills
                      isLast={index === formData.organization.length - 1}
                      key={index}
                      name={item?.name}
                      onClick={() => {
                        removeCondition('organization', 'id', item?.id);
                      }}
                      removeAll={() => {
                        updateFormData({ organization: [] });
                      }}
                      disabled={!isEdit}
                    ></Pills>
                  );
                })}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </Card>
    </div>
  );
};

export default Assignment;
