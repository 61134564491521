import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Modal from '../shared/ModalPortal/Modal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import FilterIcon from '../../assets/filter.svg';
import userIcon from '../../assets/user-group.svg';
import FilterCard from './FilterCard';
import PillsButton from '../shared/Fields/PillsButton';

interface FilterProps {
  filtersList: any;
  applyFilters: Function;
  pillsData: any;
  removeFilter: Function;
  defaultPillsLabel: string;
  hasMore?: boolean;
  fetchMoreData?: Function;
  filterWidth?: any;
}

const Filter: React.FC<FilterProps> = ({
  filtersList,
  applyFilters,
  pillsData,
  removeFilter,
  defaultPillsLabel,
  hasMore,
  fetchMoreData,
  filterWidth
}) => {
  const { t } = useTranslation();
  const [showFilterCard, setShowFilterCard] = useState(false);

  const openFilter = () => {
    setShowFilterCard(true);
  };

  const getFilterWidth = () => {
    if (filterWidth) {
      return `${filterWidth}rem`;
    }

    return `${filtersList.length * 25}rem`;
  };
  // console.log(pillsData, filtersList);

  return (
    <div className="flex py-2 pt-[5px] leading-[30px]">
      <div className="my-auto flex min-w-[6rem]">
        <BiButton
          className={
            'mr-2 flex border border-solid border-[#99B0C6] !py-[6px] text-[12px] font-semibold leading-[15px]'
          }
          type="button"
          onClick={() => {
            openFilter();
          }}
        >
          <img src={FilterIcon} alt={'filter'} className="mr-1" />
          {t('T_FILTERS')}
        </BiButton>
      </div>
      <div className="w-full">
        {pillsData.length ? (
          <div className="-mt-[5px]">
            <PillsButton
              rows={2}
              pillsData={pillsData || []}
              removeFilter={removeFilter}
            ></PillsButton>
          </div>
        ) : (
          <span className="text-[12px] font-normal">{defaultPillsLabel}</span>
        )}
      </div>

      {showFilterCard && (
        <>
          <img src={FilterIcon} />
          <Modal
            overflowVisible={true}
            showCloseIcon={true}
            width={getFilterWidth()}
            showHeader={false}
            headerText={''}
            toggleVisiblity={(value: boolean) => {
              setShowFilterCard(false);
            }}
            isVisible={showFilterCard}
            body={
              <FilterCard
                filtersList={[...filtersList]}
                hideFilter={setShowFilterCard}
                applyFilters={applyFilters}
              />
            }
          />
        </>
      )}
    </div>
  );
};

const PillsDataContainer = styled.div`
  width: 100%;
`;

export default Filter;
