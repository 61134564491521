// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import DynamicFormFieldRenderer from '../Reusable/DynamicFormFieldRenderer';
import { permissionDetailsFormSchema } from '../../util/constants';
import { validateName } from '../../util/validators';
import { useTranslation } from 'react-i18next';

interface PermissionDetailsProps {
  ActionButtons?: any;
  setStpperStep?: Function;
  detailsData: any;
  closeModal?: Function;
  center?: boolean;
  setDetailsData?: Function;
  disabled?: boolean;
  edit?: boolean;
}

/**
 * @parentId permission set
 * @manager Create, View, Edit - Permission Set
 * @overview Create, View, Edit of Permission Set
 *   <section>
 *
 *      <p>
 *           <b>Create Permission Set</b><br/>
 *           Clicking on <b>New Permission Set</b> from Permission Set listing page will open modal dailog to create new permission set. Clicking on <b>Cancel</b> will close the modal dailog and take the user to Permission Set listing page.
 *           <br/><br/>
 *           Permission Set creation is of 4 steps which include, <b>Permission Set Details, Application Features, Admin Portal Features, Other Settings.</b><br/>
 *           <b>Permission Set Details</b><br/>
 *           In this step user provides <b>Permission set name</b> mandatory field and <b>Description</b>.<br/>
 *           <b>Application Features</b><br/>
 *           In this step DataGrid is displayed and lists Application/Solution Features individual items, with granular functionality for each item like <b>View, Edit, Create, Delete, Export, Invite</b>
 *           The application features datagrid is populated based on the Configuration Setting <b>APPLICATION_NAME.features</b> assocuated to category <b>Application_Config</b>.<br/>
 *           <b>Admin Portal Features</b><br/>
 *           In this step DataGrid is displayed with all the services available of Admin portal Features, for which user can enable or disable the features to be displayed in menu bar.
 *           The admin portal features is populated based on the object <b>AdminPortal.features</b> instances.<br/>
 *           <b>Other Settings</b><br/>
 *           In this step any other settings with respect to solution can be added in the provided JSON editor.
 *           <br/><br/> Clicking on finish creates the new permission set.
 *       </p>
 *       <p>
 *           <b>View Permission Set</b><br/>
 *           Clicking on permission set row on the Permission Set listing page will take user to View Permission Set in READ-ONLY mode. Clicking on <b>Back to Permission Set</b> will take the user to Permission Set listing page.
 *           This page displays 4 steps which include <b>Permission Set Details, Application Features, Admin Portal Features, Other Settings.</b> to view the permission set created.
 *           <br/><br/>
 *        </p>
 *       <p>
 *           <b>Edit Permission Set</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Name, Description, Value, Application Features, Admin Portal Features, Other Settings</b>. Clicking on <b>Cancel</b> will take the user to Permission set details in READ-ONLY mode.
 *           <br/>
 *        </p>
 *
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Metadata Application Status Code information.
 *       <br>
 *       <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>CS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *            <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create metadata application page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit metadata application.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Configuration Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *            <tr>
 *               <td>/applications</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-application.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/applications/{appId}/objects</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-object.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/applications/{appId}/objects/{objId}/instances</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-instances.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>View Configuration Key</td>
 *               <td>/system-settings/{id}</td>
 *               <td>GET</td>
 *               <td>configuration-service.setting.get</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create metadata application</p>
 *   </section>
 */

const PermissionDetails: React.FC<PermissionDetailsProps> = ({
  ActionButtons = null,
  setStpperStep = () => {},
  detailsData,
  closeModal = () => {},
  center = true,
  setDetailsData = () => {},
  disabled = false,
  edit = true
}) => {
  const [permissionDetailsForm, setpermissionDetailsForm] = useState({
    permissionName: '',
    roleName: '',
    description: ''
  });

  const [permissionDetailsFormError, setpermissionDetailsFormError] = useState({
    permissionName: '',
    description: ''
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (detailsData?.permissionName) {
      setpermissionDetailsForm(detailsData);
    }
  }, [detailsData]);

  useEffect(() => {
    if (setDetailsData) {
      if (permissionDetailsForm?.permissionName) {
        handleValidation();
      }
      setDetailsData(permissionDetailsForm);
    }
  }, [permissionDetailsForm]);

  const handleValidation = () => {
    let errors: any = {
      permissionName: '',
      description: ''
    };

    errors['permissionName'] = validateName(permissionDetailsForm.permissionName.trim(), t);
    setpermissionDetailsFormError({ ...errors });
    return errors;
  };

  const handleNext = () => {
    const errorDescription = handleValidation();

    if (
      Object.keys(errorDescription).length ===
      Object.keys(errorDescription).filter((validateError) => {
        return errorDescription[validateError as keyof any] === '';
      }).length
    ) {
      if (ActionButtons) setStpperStep(2, { permissionDetails: permissionDetailsForm });
    }
  };

  const schema = [...permissionDetailsFormSchema];
  const index = schema.findIndex((item: any) => item.id === 'roleName');
  if (index != -1 && !edit) {
    schema.splice(index, 1);
  }

  return (
    <div>
      <div style={{ minHeight: '60vh' }}>
        <div className={`${center ? 'mx-auto' : ''} flex w-11/12 flex-wrap`}>
          <DynamicFormFieldRenderer
            layout={2}
            dynamicFormData={schema}
            formData={permissionDetailsForm}
            setFormData={setpermissionDetailsForm}
            formError={permissionDetailsFormError}
            editable={true}
            asterixFirst={true}
            disabled={disabled}
          />
        </div>
      </div>

      {ActionButtons && (
        <ActionButtons
          primaryButtonLabel={t('T_NEXT')}
          primaryButtonAction={() => {
            handleNext();
          }}
          secondaryButtonLabel={t('T_CANCEL')}
          secondaryButtonAction={() => {
            closeModal();
          }}
        />
      )}
    </div>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default PermissionDetails;
