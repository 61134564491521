import React, { useEffect, useState } from 'react';
import { clone, find, isEqual, isEmpty, filter } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import MenuButtonsPortal from '../../Menu/MenuButtonsPortal';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import DynamicFormFieldRenderer from '../../Reusable/DynamicFormFieldRenderer';
import Card from '../../shared/cards/Card';
import { useQuery } from '../../../hooks/queryParams';
import { AppConstants } from '../../../constants/AppConstants';
import { clipboard, clipboardCheck } from '../../../assets/components/svgs';
import { NewOrganizationFormLayout, questionSchema } from '../../../util/constants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setURLPaths } from '../../../app/organizationReducer';
import AnswerDetails from './AnswerDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteQuestionModal from './DeleteQuestionModal';
import { HTTP_STATUS } from '../../../http/constants/http.status';
import {
  createMetadataInstances,
  getMetadataInstance,
  updateMetadataInstance,
  deleteMetadataInstance
} from '../../../http/survey-service';
import { createSignedURL, listFiles, uploadFile } from '../../../http/asset-management-services';

import { setToastData } from '../../../app/toastReducer';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import { getUsers } from '../../../http/user-management-services';
import { getUserName } from '../../../util/admin-utils';
import { urlPATTERN } from '../../../util/constants';
import { fieldIsEmpty, validateNameLength } from '../../../util/validators';
import { getGlobalBucketName } from '../../../util/AppSetting';
import { getImagesToUpload, getQuestionTypes, updateMediaToBucket } from './QueUtils';
import AuditInfo from '../../Common/AuditInfo';

interface NewQuestionProps {
  editMode?: boolean;
  editFromSurveyTemplate?: boolean;
  questionId?: number | null;
}

/**
 * @parentId survey-questions
 * @manager Create, View, Edit - Survey Question
 * @overview Create, View, Edit of Survey Question
 *   <section>
 *       <p>
 *           <b>View Survey Question</b><br/>
 *           Clicking on survey question row on the question listing page will take user to View Survey Question Page in READ-ONLY mode. Clicking on <b>Back to Survey Question List</b> will take the user to survey question listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i>
 *           <br/><br/>
 *           <b>Delete Survey Question</b><br/>
 *           On View Survey Question page we can see the <b>Delete Question</b> button. On click of Delete button we will see an confimation popup to delete question.
 *           <br/><br/>
 *           <b>Edit Survey Question</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Display Name, Active, Description & Properties</b> fields. Clicking on <b>Cancel</b> will take the user to metadata application listing page.
 *           <br/><br/>
 *           <b>Create Survey Question</b><br/>
 *           Clicking on <b>New Question</b> button on survey question listing page will take user to New Survey Question creation page. Clicking on <b>Cancel</b> will take the user to survey question listing page.
 *           <br/><br/>
 *           User can create new survey question by entering <b>Type</b>, <b>Question Name</b> & <b>Question</b> mandatory field and clicking on <b> Save </b> button on the admin portal toolbar.
 *           <b>Description</b> & <b>Answer Details</b> are additional non mandatory fields which user can use to enter more detail about the question. Description is an freetext field & Properties is an JSON (Javascript Object Notation).
 *           <br/><br/><b>Answer Details</b> fields can changes as per the selected question type.
 *           <br/><br/>Avaliable Question Types:
 *           <ul> <li>Date</li> <li>Time</li> <li>Date & Time</li> <li>Free Text</li> <li>Multiple Selection</li> <li>URL</li> <li>Single Selection</li> <li>Display (read-only)</li> <li>Boolean</li> <li>Integer</li> </li>Decimal</li> </li>Media</li> </li>Liekrt</li> </li>Body Map</li> </ul>
 *       </p>
 *
 *       <p>
 *          <h4>View/Create/Edit Survey Question Fields</h4>
 *          Create/View/Edit survey question page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Type</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Question Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Question</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Description</td>
 *               <td>No</td>
 *               <td>String</td>
 *               <td>4096</td>
 *            </tr>
 *            <tr>
 *               <td>Answer Details</td>
 *               <td>No</td>
 *               <td>-</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> While creating or Editing an question we cannot change type, once we select a type from dropdowm it will be readonly field in form.
 *           <b>Note:</b> Answer details field will be vary as per the question type.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Survey Question Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_PARAM_DUPLICATE</td>
 *               <td>Duplicate label, <b>Question Name</b> for resource type MetadataInstance.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create survey question page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit survey question.</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating survey question page</p>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Survey Question</td>
 *               <td>/object/{ObjId}/instance/{id}</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-instance.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Survey Question</td>
 *               <td>/object/{ObjId}/instance/{id}</td>
 *               <td>PUT</td>
 *               <td>metadata-service.metadata-instance.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Survey Question</td>
 *               <td>/object/{ObjId}/instance/{id}</td>
 *               <td>POST</td>
 *               <td>metadata-service.metadata-instance.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Delete Survey Question (View Survey Question)</td>
 *               <td>/object/{ObjId}/instance/{id}</td>
 *               <td>DELETE</td>
 *               <td>metadata-service.metadata-instance.delete</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>List users (View & Edit Survey Question)</td>
 *               <td>/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create Survey Question</p>
 *   </section>
 */

const NewQuestion: React.FC<NewQuestionProps> = ({
  editMode,
  editFromSurveyTemplate,
  questionId
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const questionListMetaDataObj: any = find(metaDataObjectList, { name: 'SurveyQuestions' });
  const navigate = useNavigate();
  const location = useLocation();
  const flowType = location.pathname.split('/').at(-1);
  const params = useParams();
  const [permission, setPermission] = useState(false);
  const [isEdit, setIsEdit] = useState(flowType === 'new');
  const [isLoading, setIsLoading] = useState(true);
  const [resetData, setResetData] = useState();
  const [mode, setMode] = useState<number | null>(flowType === 'new' ? 1 : 0); //'1 for create; 0 for edit'

  const [initialFormData, setInitialFormData] = useState<any>({});
  const [disableButton, setDisableButton] = useState(false);
  const query = useQuery();
  const selectedQuestionType = query.get('type');

  const [formData, setFormData] = useState<any>({
    queList: [],
    questionTypeLabel: '',
    questionName: '',
    question: '',
    questionDescription: '',
    questionStatus: 'Active',
    answerValues: false,
    initial: [],
    extension: {},
    type: selectedQuestionType,
    status: true,
    version: 0,
    displayType: selectedQuestionType
  });
  const [formError, setFormError] = useState<any>({
    questionTypeLabel: '',
    questionName: '',
    question: '',
    questionDescription: ''
  });

  useEffect(() => {
    if (mode === 1) {
      setIsLoading(false);
    }
  }, [mode]);

  const getQueTypeLabel = (queList: any) => {
    const selectedType = selectedQuestionType || '';
    if (queList && queList.length) {
      const { label, answerOptions } = find(queList, { type: selectedType });
      // if (label === 'text' && answerOptions.length) {
      //   return find(queList, { type: 'single' }).label;
      // }
      return label;
    }
  };

  const getMetaDataQueTypes = async () => {
    let questionTypesObj: any;

    if (!isEmpty(questionTypes)) {
      questionTypesObj = questionTypes;
    } else {
      setIsLoading(true);
      questionTypesObj = await getQuestionTypes(dispatch, metaDataObjectList);
      setIsLoading(false);
    }

    const queList: any = Object.values(questionTypesObj);
    const questionTypeLabel = getQueTypeLabel(queList);

    setFormData((prevState: any) => ({
      ...prevState,
      queList,
      questionTypeLabel
    }));
    if (!isEdit) {
      setIsLoading(true);
      getQuestionDetails(questionListMetaDataObj.id, params.id, queList);
    }

    dispatch(
      setURLPaths([
        {
          key: '/survey-question/new',
          label: `New ${questionTypeLabel} Question`
        }
      ])
    );
  };

  useEffect(() => {
    setPermission(true);
    getMetaDataQueTypes();
  }, []);

  useEffect(() => {
    if (disableButton) {
      setDisableButton(false);
      setFormError({});
    }
  }, [formData]);

  const getQuestionDetails = async (objectId: any, id: any, queList: any) => {
    let questionData: any;

    const response = await getMetadataInstance(objectId, id);
    const { status, data = {} } = response;

    if (status === HTTP_STATUS.HTTP_OK) {
      const { attributes = {}, createdBy, createdOn, updatedOn, id, updatedBy } = data;
      let createdUser, modifiedUser;

      const usersResponse = await getUsers(
        `[{"field":"userId","operation":"in","value":"${createdBy},${updatedBy}"}]`
      );

      const { status: usersResponseStatus, data: usersResponseData } = usersResponse;

      if (usersResponseStatus === HTTP_STATUS.HTTP_OK) {
        const { data: usersList } = usersResponseData;
        createdUser = filter(usersList, { userId: createdBy });
        modifiedUser = filter(usersList, { userId: updatedBy });
      }

      const {
        type,
        name,
        question,
        description,
        active,
        version,
        answerValues,
        answerOptions,
        initial,
        extension
      } = attributes;
      questionData = {
        extension: extension ? JSON.parse(extension?.object || {}) : {},
        initial,
        queList,
        questionTypeLabel: getQueTypeLabel(queList),
        type: selectedQuestionType,
        questionName: name,
        question: question,
        questionDescription: description,
        questionStatus: active ? 'Active' : 'Inactive',
        modified: moment(updatedOn).format('MMMM DD YYYY h:mm A'),
        created: moment(createdOn).format('MMMM DD YYYY h:mm A'),
        status: active,
        version: version,
        answerValues: answerValues.length ? true : false,
        name: createdUser && createdUser.length ? getUserName(createdUser[0]) : null,
        modifiedBy: modifiedUser && modifiedUser.length ? getUserName(modifiedUser[0]) : null,
        queId: id
      };

      if (answerOptions.length && type !== 'quantity' && type !== 'integer' && type !== 'decimal') {
        answerOptions.forEach((answer: any, index: any) => {
          questionData = {
            ...questionData,
            [`option${index + 1}`]: answer.value,
            answerOptions: {
              ...questionData.answerOptions,
              [`option${index + 1}`]: {
                value: answer.value,
                isSelected: answer.initialSelected
              }
            }
          };
        });
      }
      if (
        answerOptions.length &&
        (type === 'quantity' || type === 'integer' || type === 'decimal')
      ) {
        const valueObject = JSON.parse(answerOptions[0].value);
        questionData = {
          ...questionData,
          enableUnits: valueObject.unit ? true : false,
          enableSymbols: valueObject.comparator ? true : false,
          isWholeNumber: valueObject.type === 'integer' ? true : false
        };
      }
      setFormData(questionData);
      setInitialFormData(questionData);
      setIsLoading(false);
    }
  };

  const setDispatchError = (resp: any) => {
    setIsLoading(false);
    dispatch(
      setToastData({
        toastMessage: resp.data.issues[0].details,
        isToastActive: true,
        toastType: 'error'
      })
    );
  };

  const setDispatchSuccess = (resp: any, message: string, toastType: string = 'success') => {
    dispatch(
      setToastData({
        toastMessage: message,
        isToastActive: true,
        toastType: toastType
      })
    );
    navigate('/survey-question-list');
  };

  const deleteQuestion = async () => {
    setIsLoading(true);
    const instanceResponse = await deleteMetadataInstance(questionListMetaDataObj.id, params.id);
    if (instanceResponse.status === HTTP_STATUS.HTTP_OK) {
      setDispatchSuccess(instanceResponse, t('T_SURVEY_QUESTION_DELETED_SUCCESSFULLY'), 'success');
    } else {
      setDispatchError(instanceResponse);
    }
  };

  const setInititalData = (initial: any, type: any) => {
    const initialVal = Array.isArray(initial) ? (initial.length ? initial[0] : '') : initial;
    return initialVal ? [initialVal] : [];
  };

  const validateFormData = () => {
    let errors: any = {
      questionName: '',
      question: '',
      questionDescription: ''
    };
    errors['questionName'] = fieldIsEmpty(formData.questionName.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(formData.questionName.trim(), 255)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG');
    errors['question'] = fieldIsEmpty(formData.question.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(formData.question.trim(), 1024)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_TOO_LONG_MSG');
    errors['questionDescription'] = fieldIsEmpty(formData?.questionDescription?.trim())
      ? ''
      : validateNameLength(formData.questionDescription.trim(), 4096)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG_4096');
    setFormError({ ...errors });
    return errors;
  };

  const getApiType = (formData: any) => {
    const { queList } = formData;
    if (queList.length) {
      return find(queList, { type: selectedQuestionType }).apiType;
    }
    return '';
  };

  const handleSave = async () => {
    function errorHandling(errorText: string) {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: errorText,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    const errors = validateFormData();
    if (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    ) {
      setDisableButton(true);
      return;
    }
    setIsLoading(true);
    let body: any = {
      instances: [
        {
          label: formData.question.trim(),
          attributes: {
            type: formData.type,
            apiType: getApiType(formData),
            name: formData.questionName.trim(),
            question: formData.question.trim(),
            description: formData.questionDescription.trim()
              ? formData.questionDescription.trim()
              : ' ',
            answerValues: formData.answerValues
              ? Object.keys(formData.answerOptions).map((option: any) =>
                  formData.answerOptions[option].value.trim()
                )
              : [],
            answerOptions: formData?.answerOptions
              ? Object.keys(formData.answerOptions).map((option: any) => {
                  return {
                    value: formData.answerOptions[option].value.trim(),
                    initialSelected: formData.answerOptions[option].isSelected
                  };
                })
              : [],
            initial: formData.initial?.length
              ? setInititalData(formData.initial, formData.type)
              : [],
            active: params?.id ? formData.status : true,
            version: params?.id ? formData.version + 1 : 0,
            extension: formData.extension ? { object: JSON.stringify(formData.extension) } : ''
          }
        }
      ]
    };

    //const imagesToUpload = getImagesToUpload(formData);
    const imagesToUpload = getImagesToUpload(formData);

    //await updateMediaToBucket(imagesToUpload, errorHandling, formData);
    await updateMediaToBucket(imagesToUpload, errorHandling, formData, t);

    if (params.id) {
      const instanceResponse = await updateMetadataInstance(
        questionListMetaDataObj.id,
        params.id,
        body.instances[0]
      );
      if (instanceResponse.status === HTTP_STATUS.HTTP_OK) {
        setDispatchSuccess(instanceResponse, t('T_SURVEY_QUESTION_UPDATED_MSG'));
      } else {
        setDispatchError(instanceResponse);
      }
    } else {
      const instanceResponse = await createMetadataInstances(questionListMetaDataObj.id, body);
      if (instanceResponse.status === HTTP_STATUS.HTTP_CREATED) {
        setDispatchSuccess(instanceResponse, t('T_SURVEY_QUESTION_CREATED_MSG'));
      } else {
        setDispatchError(instanceResponse);
      }
    }
  };

  const validateInitialDate = (type: any, initial: any) => {
    const dateFormat: any = {
      datetime: 'YYYY-MM-DD HH:mm',
      date: 'YYYY-MM-DD',
      time: 'HH:mm'
    };

    const initialVal = Array.isArray(initial) ? (initial.length ? initial[0] : '') : initial;
    if (initialVal && moment(initialVal, dateFormat[type], true).isValid()) {
      return false;
    }
    return true;
  };

  const validateUserOptions = (answerOptions: any) => {
    const answerOptionsValue = Object.keys(answerOptions).filter((option: any, index: number) =>
      answerOptions[option].value.trim()
    );
    return answerOptionsValue.length !== Object.keys(answerOptions).length;
  };

  const validateButton = () => {
    let isSaveDisabled = false;
    if (disableButton) {
      return disableButton;
    }
    const {
      questionName,
      question,
      initial,
      type,
      answerOptions = [],
      questionDescription = '',
      extension
    } = formData;
    const {
      questionName: initialQuestionName = '',
      question: initialQuestion = '',
      initial: initialDefaultVal = [],
      answerOptions: initialAnswerOptions = [],
      questionDescription: initialDescription = '',
      extension: initialExtension
    } = initialFormData;

    if (params.id) {
      if (!isSaveDisabled && (!questionName.trim() || !question.trim())) {
        isSaveDisabled = true;
      }
      if (
        !isSaveDisabled &&
        questionName === initialQuestionName &&
        question === initialQuestion &&
        questionDescription === initialDescription &&
        isEqual(answerOptions, initialAnswerOptions) &&
        isEqual(initial, initialDefaultVal) &&
        isEqual(extension, initialExtension)
      ) {
        isSaveDisabled = true;
      }
    } else {
      if (!isSaveDisabled && (!questionName.trim() || !question.trim())) {
        isSaveDisabled = true;
      }
    }
    if (!isSaveDisabled) {
      switch (type) {
        case 'datetime':
        case 'date':
        case 'time':
          isSaveDisabled = initial && initial.length ? validateInitialDate(type, initial) : false;
          break;
        case 'url':
          const validURL = (url: any) => {
            return !urlPATTERN.test(url);
          };
          isSaveDisabled = initial && initial.length ? validURL(initial) : false;
          break;
        case 'single':
        case 'multi':
          isSaveDisabled = validateUserOptions(answerOptions);
          break;
      }
    }

    return isEdit ? isSaveDisabled : false;
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setResetData(initialFormData);
    setIsEdit(false);
  };

  const getAnswerDetailsBlock = () => {
    const { type } = formData;
    const allowedAnswerTypes = ['multi', 'single', 'numeric', 'boolean', 'likert', 'media'];

    if (allowedAnswerTypes.includes(type) || editFromSurveyTemplate) {
      return (
        <div className="flex">
          <div className="flex w-3/4">
            <div className="p-2"></div>
            <Card
              title={t('T_ANSWER_DETAILS')}
              icon={clipboardCheck}
              alt={'answer detail icon'}
              height={''}
              width={''}
              className="w-full"
            >
              <div className="flex w-full flex-wrap">
                <AnswerDetails
                  key={JSON.stringify(formData.queId)}
                  formData={formData}
                  resetData={resetData}
                  setFormData={setFormData}
                  setFormError={setFormError}
                  formError={formError}
                  questionType={formData.type}
                  editMode={!isEdit}
                />
              </div>
            </Card>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <div className="flex max-h-screen w-full flex-col justify-start overflow-y-scroll  bg-gray-100 px-2 py-3">
          <div className="flex">
            <div className={editFromSurveyTemplate ? 'flex' : 'flex w-3/4'}>
              <div className="p-2"></div>
              <Card
                title={t('T_QUESTION_DETAILS')}
                icon={clipboard}
                alt={'question detail icon'}
                height={''}
                width={''}
                className="w-full"
              >
                {permission && (
                  <MenuButtonsPortal>
                    <BiButton
                      className={'mr-2 text-primary'}
                      type="button"
                      onClick={() => {
                        isEdit && !mode ? handleCancel() : navigate(-1);
                      }}
                    >
                      {!isEdit ? t('T_BUTTON_BACK_TO_SURVEY_LIST') : t('T_CANCEL')}
                    </BiButton>
                    <BiButton
                      className={'bg-primary text-white'}
                      type="button"
                      onClick={() => {
                        !mode && !isEdit ? setIsEdit(true) : handleSave();

                        //save logic
                      }}
                      disabled={validateButton()}
                    >
                      {!mode && !isEdit ? t('T_EDIT') : t('T_SAVE')}
                    </BiButton>
                  </MenuButtonsPortal>
                )}
                <div className="flex w-full flex-wrap">
                  <DynamicFormFieldRenderer
                    dynamicFormData={questionSchema}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    setFormError={setFormError}
                    layout={NewOrganizationFormLayout.twoColumn}
                    editable={isEdit}
                  />
                </div>
              </Card>
            </div>
            {!mode && !editFromSurveyTemplate && (
              <div className="ml-5 flex w-1/4 flex-col">
                <div>
                  <DeleteQuestionModal deleteQuestion={deleteQuestion} />
                </div>
                <div className="my-7">
                  {(formData.created || formData?.name) && (
                    <div>
                      <AuditInfo
                        label={'CREATED'}
                        date={formData?.created || ''}
                        user={formData?.name || ''}
                        isUserId={false}
                      />
                    </div>
                  )}
                  {(formData.modified || formData.modifiedBy) && (
                    <div>
                      <AuditInfo
                        label={'LAST_MODIFIED'}
                        date={formData?.modified || ''}
                        user={formData?.modifiedBy || ''}
                        isUserId={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {getAnswerDetailsBlock()}
        </div>
      </LoaderContainer>
    </>
  );
};

export default NewQuestion;
