import { cloneDeep, findIndex } from 'lodash';

export const toggleGlobalCheckbox = (selectedItems: Array<any> = [], data: any): Array<any> => {
  const { id } = data;

  let existingArr: any = cloneDeep(selectedItems);
  let existingId = findIndex(existingArr, function (item: any) {
    return item.id === id;
  });

  if (existingId !== -1) {
    existingArr.splice(existingId, 1);
    return existingArr;
  }
  existingArr.push({ ...data });
  return existingArr;
};

export const toggleOrgCheckbox = (
  selectedOrgItems: Array<any> = [],
  data: any,
  dependencyKey: string
): Array<any> => {
  let { id, organizationId = '' } = data;

  if (data?.data?.orgId?.length) {
    organizationId = data?.data?.orgId;
  }
  let existingArr: any = cloneDeep(selectedOrgItems);
  let orgIndex = findIndex(existingArr, function (item: any) {
    return item.id === organizationId;
  });

  if (orgIndex !== -1) {
    let existingId = findIndex(
      existingArr[orgIndex]?.dependentArtifactConfigurations?.[dependencyKey],
      function (item: any) {
        return item.id === id;
      }
    );
    if (existingId !== -1) {
      existingArr[orgIndex]?.dependentArtifactConfigurations?.[dependencyKey].splice(existingId, 1);
      return existingArr;
    } else {
      existingArr[orgIndex]?.dependentArtifactConfigurations?.[dependencyKey].push({
        ...data
      });
      return existingArr;
    }
  } else {
    let payload = {
      id: organizationId,
      dependentArtifactConfigurations: {
        [dependencyKey]: [
          {
            ...data
          }
        ]
      }
    };
    existingArr.push(payload);
    return existingArr;
  }
};

export const isGlobalSelected = (selectedItems: Array<any> = [], data: any): boolean => {
  const { id} = data;

  return (
    findIndex(selectedItems, function (item: any) {
      return item.id === id;
    }) !== -1
  );
};

export const isOrgSpecificSelected = (
  selectedOrgItems: Array<any> = [],
  data: any,
  dependencyKey: string
): boolean => {
 let { id, organizationId = '' } = data;

 if (data?.data?.orgId?.length) {
    organizationId = data?.data?.orgId;
  }

  let orgIndex = findIndex(selectedOrgItems, function (item: any) {
    return item.id === organizationId;
  });
  if (orgIndex !== -1) {
    return (
      findIndex(
        selectedOrgItems[orgIndex]?.dependentArtifactConfigurations?.[dependencyKey],
        function (item: any) {
          return item.id === id;
        }
      ) !== -1
    );
  } else {
    return false;
  }
};

export const toggleOrgOverrideCheckbox = (
  manifestData: Array<any>,
  data: any,
  dependencyKey: string
) => {
let { id, organizationId = '' } = data;

if (data?.data?.orgId?.length) {
    organizationId = data?.data?.orgId;
  }

  let existingArr: any = cloneDeep(manifestData);
  let existingId = findIndex(existingArr, function (item: any) {
    return item.id === organizationId;
  });

  let itemId = findIndex(
    existingArr[existingId]?.dependentArtifactConfigurations?.[dependencyKey],
    function (item: any) {
      return item.id === id;
    }
  );

  if (itemId !== -1) {
    if (
      existingArr[existingId]?.dependentArtifactConfigurations?.[dependencyKey]?.[itemId]?.overWrite
    ) {
      delete existingArr[existingId].dependentArtifactConfigurations?.[dependencyKey]?.[itemId]
        .overWrite;
    } else {
      existingArr[existingId].dependentArtifactConfigurations[dependencyKey][itemId].overWrite =
        true;
    }

    return existingArr;
  }
};

export const toggleGlobalOverrideCheckbox = (manifestData: any, data: any) => {
  const { id } = data;
  let existingArr: any = cloneDeep(manifestData);
  let existingId = findIndex(existingArr, function (item: any) {
    return item.id === id;
  });

  if (existingId !== -1) {
    if (existingArr[existingId]?.overWrite) {
      delete existingArr[existingId].overWrite;
    } else {
      existingArr[existingId].overWrite = true;
    }
  }

  return existingArr;
};

export const removeOrgData = (selectedOrgItems: Array<any>, data: any, dependencyKey: string) => {
let { id, organizationId = '' } = data;

if (data?.data?.orgId?.length) {
    organizationId = data?.data?.orgId;
  }
  let existingArr: any = cloneDeep(selectedOrgItems);
  let orgIndex = findIndex(existingArr, function (item: any) {
    return item.id === organizationId;
  });
  if (orgIndex !== -1) {
    let existingId = findIndex(
      existingArr[orgIndex]?.dependentArtifactConfigurations?.[dependencyKey],
      function (item: any) {
        return item.id === id;
      }
    );

    if (existingId !== -1) {
      existingArr[orgIndex]?.dependentArtifactConfigurations?.[dependencyKey].splice(existingId, 1);

      return existingArr;
    }
  }
  return existingArr;
};
