import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { AppConstants } from '../../constants/AppConstants';
import { RouteConstants } from '../../constants/RouteConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { fileHistory } from '../../http/asset-management-services';
import BiButton from '../primitives/buttons/BiButton.primitive';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import { download, getFullPath, trimFolderFileName } from './BucketUtil';
import { downloadDocument, getFileSize, randomNumber } from './BucketUtil';
import FileMetaDataModal from './FileMetaDataModal';
import { MenuOptions } from './ContextMenu';
import moment from 'moment';
import { setTableOptions } from '../../app/templateTableReducer';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const FileVersionHistoryList = () => {
  const params = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentActivePage, setCurrentActivePage] = useState(0);
  const [tablePage, setTablePage] = useState({});

  const pagingRef: any = useRef();

  const setPagingRef = (paging: any) => {
    pagingRef.current = paging;
  };

  const [content, setContent] = useState<any>(null);
  const [metaDataEntries, setMetaDataEntries] = useState<Array<any>>([]);

  const bucketInfo = useAppSelector((state) => state.bucketSlice.bucketInfo);
  const folderInfo = useAppSelector((state) => state.bucketSlice.folderInfo);
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};
  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);

  useEffect(() => {
    dispatch(
      setURLPaths([
        {
          key: RouteConstants.ROUTE_AMS_FILES_HISTORY.replace(':id', params.id || ''),
          label: `Bucket > ${bucketInfo?.name} > ${params.id} > history`
        }
      ])
    );
    fetchFileHistory(AppConstants.DEFAULT_PAGE, AppConstants.DEFAULT_PAGE_SIZE);
    const {
      tableFilters = '',
      searchText = '',
      currentPage: filterCurrentPage = AppConstants.DEFAULT_PAGE,
      size: filterSize = AppConstants.DEFAULT_PAGE_SIZE,
      sortName = AppConstants.DEFAULT_SORT_BY,
      sortType = AppConstants.DEFAULT_SORT_DESC
    } = globalFilters['file-history-list'] || {};

    dispatch(
      setTableOptions({
        ['file-history-list']: {
          searchText: searchText,
          currentPage: filterCurrentPage,
          size: filterSize,
          sortName: sortName,
          sortType: sortType,
          tableFilters: tableFilters
        }
      })
    );
  }, []);

  const fetchFileHistory = async (
    page: number = AppConstants.DEFAULT_PAGE,
    size: number = AppConstants.DEFAULT_PAGE_SIZE
  ) => {
    const fullPath: string = getFullPath(folderInfo);
    const payload: any = {
      filePath: fullPath ? `${getFullPath(folderInfo)}/${params.id}` : params.id,
      bucketName: bucketInfo?.name
    };
    const resposne = await fileHistory(payload, page, size);
    const { status, data } = resposne;
    if (HTTP_STATUS.isSuccess(status)) {
      setPagingRef(data?.paging);
      const { data: versions = [] } = data;
      setRows(
        versions.map((version: any, index: any) => {
          return { ...version, version: (versions.length - index).toFixed(1) };
        })
      );
    } else {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: getFailureMessage(resposne),
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  

  // const byte_format = (bytes: any) => {
  //   bytes = bytes.toString().replace(/[^0-9.]/g, '');
  //   let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  //   bytes = parseInt(bytes);
  //   if (bytes <= 0 || isNaN(bytes)) return '0 B';
  //   let i = Math.floor(Math.log(bytes) / Math.log(1024));
  //   return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  // };

  const getMenuOptions = () => {
    let tempOptionList: any = [];
    if (
      roleAPIPermissions[AppConstants.PERMISSION_AMS_SIGNED_URL] ||
      roleAPIPermissions[AppConstants.PERMISSION_AMS_DOWNLOD_REDIRECTION]
    ) {
      tempOptionList.push({
        key: 'download_file',
        display: t('T_DOWNLOAD_FILE'),
        action: async (file: any) => {
          setIsLoading(true);
          try {
            await downloadDocument(file, bucketInfo?.name);
            // await download(file, bucketInfo?.name);
          } catch (err) {
            dispatch(setToastData({ isToastActive: true, toastMessage: err, toastType: 'error' }));
          }
          setIsLoading(false);
        }
      });
    }
    tempOptionList.push({
      key: 'view_file_metadata',
      display: t('T_VIEW_FILE_METADATA'),
      action: (file: any) => {
        setContent(file);
        const { metadata = {} } = file;
        setMetaDataEntries(
          Object.keys(metadata).map((name: string, index: number) => {
            return { id: randomNumber(), index, name, value: metadata[name] };
          })
        );
      }
    });
    return tempOptionList;
  };

  const BucketHeaders: Array<ITableHeader> = [
    {
      key: 'version',
      description: 'T_VERSION',
      sortEnabled: false,
      width: '7%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'name',
      description: 'T_NAME',
      sortEnabled: false,
      width: '30%',
      sortingInformation: {
        order: ''
      },
      render: (row: any) => {
        return trimFolderFileName(row?.name);
      },
      styles: { borderRight: 'none' }
    },
    {
      key: 'name',
      description: '',
      sortEnabled: false,
      width: '4%',
      clickable: false,
      render: (data: any) => {
        const menuOptions: Array<any> = getMenuOptions();
        return menuOptions.length ? (
          <MenuOptions options={getMenuOptions()} folderData={data} />
        ) : (
          <div />
        );
      },
      sortingInformation: {
        order: ''
      },
      styles: {
        paddingRight: '0',
        paddingLeft: '0',
        borderLeft: 'none',
        rowStyle: { fontWeight: 'normal', padding: '0px' }
      }
    },
    {
      key: 'contentType',
      description: 'T_FILE_TYPE',
      sortEnabled: false,
      width: '10%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'size',
      description: 'T_SIZE',
      sortEnabled: false,
      width: '10%',
      sortingInformation: {
        order: ''
      },
      nastedData: true,
      render: (data: any) => {
        return getFileSize(data.size);
      }
    },
    {
      key: 'createdOn',
      description: 'T_CREATED_ON',
      sortEnabled: false,
      width: '20%',
      render: (data: any) => {
        return data.createTime
          ? moment(data.createTime).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '';
      },
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'updatedOn',
      description: 'T_LAST_MODIFIED_ON',
      sortEnabled: false,
      width: '20%',
      render: (data: any) => {
        return data.updateTime
          ? moment(data.updateTime).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '';
      },
      sortingInformation: {
        order: ''
      }
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: false,
    //   width: '20%',
    //   sortingInformation: {
    //     order: ''
    //   }
    // }
  ];

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(BucketHeaders);

  const refreshTableData = (
    name: string = '',
    page: number = AppConstants.DEFAULT_PAGE,
    size: number = AppConstants.DEFAULT_PAGE_SIZE,
    sortOrder: string = AppConstants.DEFAULT_AMS_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setIsLoading(true);
    fetchFileHistory(page, size);
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <MenuButtonsPortal>
          {true && (
            <BiButton
              className={'ml-auto mr-2 text-primary'}
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('T_BACK_TO_FILES')}
            </BiButton>
          )}
        </MenuButtonsPortal>
        <CustomersTableContainer style={{ height: 'inherit' }}>
          <TemplateTable
            tableData={rows}
            onRefreshTableData={refreshTableData}
            size={globalFilters?.size?.toString()}
            currentPage={globalFilters?.currentPage}
            setCurrentPage={(page: any) => {
              setCurrentActivePage(parseInt(page));
            }}
            cellSelector={() => {}}
            templateSelector={() => {}}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            pagingData={pagingRef.current}
            containerClass="mt-0"
            isSearchable={false}
            showDeleteButton={true}
            theme={useTheme().theme}
            tablePage="file-history-list"
            isLocalPaginator={false}
            totalDataLength={rows.length}
            isPaginated={true}
            disablePageSize={false}
            globalFilters={globalFilters}
          />
        </CustomersTableContainer>
        <FileMetaDataModal
          disabled={true}
          content={content}
          setContent={setContent}
          metaDataEntries={metaDataEntries}
        />
      </LoaderContainer>
    </>
  );
};

export default FileVersionHistoryList;
