import Card from '../shared/cards/Card';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import UserRole from '../../assets/user-cog.svg';
import Asterix from '../../assets/asterix.svg';
import { useState, useEffect } from 'react';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { setToastData } from '../../app/toastReducer';
import ToastNotification from '../Reusable/ToastNotification';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DynamicFormFieldRenderer from '../Reusable/DynamicFormFieldRenderer';
import { roleDetailsFormSchema } from '../../util/constants';
import LockIcon from '../../assets/lock-alt.svg';
import ShieldExclaimation from '../../assets/shield-excl.svg';
import Checkbox from '../shared/Fields/Checkbox';
import { setNewRoleData, setPermissionSet } from '../../app/rolesAndFeatureReducer';
import moment from 'moment';

import {
  createPasswordPolicy,
  getPasswordPolicy,
  updatePasswordPolicy
} from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  addPermissionsToRole,
  createRole,
  deletePermissionsFromRole,
  getPermissions,
  getRole,
  updateRole
} from '../../http/access-management-service';
import { passwordComplexitySchema } from './Schema';
import { setURLPaths } from '../../app/organizationReducer';
import {
  Category,
  checkRoleFieldLength,
  createApplicationRole,
  getApplicationCategory,
  getUnAssignedPermissionSets,
  SystemSetting,
  updateApplicationContextKey,
  updatePermissionSet,
  updatePermissionSetInstance
} from '../../util/RoleUtil';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import {
  find,
  findIndex,
  cloneDeep,
  differenceBy,
  isArray,
  uniq,
  compact,
  isEmpty,
  orderBy,
  isEqual,
  keyBy,
  differenceWith
} from 'lodash';
import {
  getLoggedInOrg,
  getLoginUserId,
  getUserName,
  getUserProfiles
} from '../../util/admin-utils';
import { createEvent } from '../../http/event-services';
import { getMetadataInstances, updateMetadataInstance } from '../../http/metadata-service';
import { getSystemSettings } from '../../http/configuration-services';
import {
  checkPermissionSetMetadataExists,
  loadPermissionSetList
} from '../PermissionSet/PermissionSetUtil';
import InputField from '../shared/Fields/InputField';
import { validateNameLength } from '../../util/validators';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import PickListWithApiSearch from '../shared/Fields/PickListWithAPISearch';
import TextAreaField from '../shared/Fields/TextAreaField';
import { getOrganization, getOrganizations } from '../../http/organization-services';
import { DualingListPick } from '../shared/DualingList/DualingList';
import { RouteConstants } from '../../constants/RouteConstants';
import TabDrawer from '../Reusable/TabDrawer';
import NewPermissionSet from '../PermissionSet/NewPermissionSet';
import PasswordPolicyModal from '../PasswordPolicy/PasswordPolicyModal';
import AuditInfo from '../Common/AuditInfo';
interface AUDIT_SCHEMA {
  createdBy: string;
  updatedBy: string;
  createdOn: string;
  updatedOn: string;
}

interface Manifest {
  page: number;
  searchText: string;
}

const ROLE_FORM_SCHEMA = {
  roleName: '',
  permissionSet: [],
  roleDescription: '',
  passwordPolicy: [],
  organization: [],
  apiPermissions: [],
  mfaEnabled: true,
  locationHeuristics: false
};

const ROLE_AUDIT_SCHEMA = {
  createdBy: '',
  updatedBy: '',
  createdOn: '',
  updatedOn: ''
};

const RoleManagement = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<any>(ROLE_FORM_SCHEMA);
  const [formDataClone, setFormDataClone] = useState<any>(ROLE_FORM_SCHEMA);
  const [auditData, setAuditData] = useState<AUDIT_SCHEMA>(ROLE_AUDIT_SCHEMA);
  const [formError, setFormError] = useState(ROLE_FORM_SCHEMA);
  const [rolePermissions, setRolePermissions] = useState<any>([]);
  const [rolePermissionsClone, setRolePermissionsClone] = useState<any>([]);

  const [organizationList, setOrganizationList] = useState<any>([]);
  const [orgManifest, setOrgManifest] = useState<Manifest>({
    page: 0,
    searchText: ''
  });

  const [passwordPolicyList, setPasswordPolicyList] = useState<any>([]);
  const [passwordPolicyManifest, setPasswordPolicyManifest] = useState<Manifest>({
    page: 0,
    searchText: ''
  });

  const location = useLocation();
  const navigate = useNavigate();
  const toastData = useAppSelector((state) => state.toast);
  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);

  const [permissionSetList, setPermissionSetList] = useState<Array<SystemSetting>>([]);
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(`${t('T_ROLE_DETAILS')}`);

  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const permissionSetObject: any = find(metaDataObjectList, {
    name: AppConstants.METADATA_OBJECT_PERMISSION_SET
  });

  const apiRolePermissions = useAppSelector((state) => state.initialLoadData.apiPermissions);

  const [orgHasMore, setOrgHasMore] = useState(true);
  const [passwordPolicyHasMore, setPasswordPolicyHasMore] = useState(true);
  const [createPermissionModal, setCreatePermissionModal] = useState(false);
  const [createPasswordPolicyModal, setCreatePasswordPolicyModal] = useState(false);

  const [roleData, setRoleData] = useState<any>(null);
  const permissionSetData = useAppSelector((state) => state.rolesAndFeature.permissionSet);
  const orgDetails = useAppSelector((state) => state.rolesAndFeature.orgDetails);

  const loggedInOrg = getLoggedInOrg();
  const userId = getLoginUserId();

  const fetchPermissionSetData = async () => {
    const permissionSetList = await getUnAssignedPermissionSets(2, permissionSetObject?.id);
    setPermissionSetList(permissionSetList);
  };

  useEffect(() => {
    async function init() {
      if (!params.id) {
        getAPIPermissions();
        // const getRolePermissionsResponse = await getPermissions(AppConstants.MAXIMUM_PAGE_SIZE);
        fetchPermissionSetData();
        setBreadCrumb();

        // setRolePermissions(
        //   orderBy(getRolePermissionsResponse?.data?.data, ['apiPermission'], ['asc'])
        // );
        setIsEdit(true);
        setIsLoading(false);
      } else {
        saveEditResponse(null);
      }
    }

    init();
  }, []);

  const getAPIPermissions = async (rolePermissions: Array<any> = []) => {
    const response = await getPermissions(AppConstants.MAXIMUM_PAGE_SIZE);
    const { status, data } = response;
    if (HTTP_STATUS.isSuccess(status)) {
      const { data: permissionList = [] } = data;

      const orderedData = orderBy(
        differenceBy(permissionList, rolePermissions, 'id'),
        [AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY],
        [AppConstants.DEFAULT_SORT_ASC]
      );
      setRolePermissions(orderedData);
      setRolePermissionsClone(orderedData);
    }
  };

  const loadPermissionSet = async (roleData: any) => {
    let permissionSetsUnassigned: Array<SystemSetting> = [];

    const instanceResponse = await getMetadataInstances(
      permissionSetObject?.id,
      [],
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );

    const { data, status } = instanceResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: permissionSets = [] } = data;
      const mappedPermSets = permissionSets.filter(
        (permissionSet: SystemSetting) => permissionSet?.attributes?.roles?.[0] === roleData.name
      );
      permissionSetsUnassigned = permissionSets.filter((permissionSet: SystemSetting) => {
        const { attributes = {} } = permissionSet;
        const { roles = [] } = attributes;
        return !roles.length;
      });
      setPermissionSetList(permissionSetsUnassigned);
      if (mappedPermSets.length) {
        dispatch(setPermissionSet(mappedPermSets[0]));
        return mappedPermSets[0];
      } else {
        return '';
      }
    }
  };

  const saveEditResponse = async (data: any) => {
    let editData = cloneDeep(data || roleData);
    let permissionSet = cloneDeep(permissionSetData);
    let organizationData = cloneDeep(orgDetails);

    if (!roleData && !editData) {
      let response = await getRole(params.id);

      if (response.status === HTTP_STATUS.HTTP_OK) {
        editData = response.data;
        if (isEmpty(permissionSet)) {
          permissionSet = await loadPermissionSet(response.data);
        }

        setRoleData(editData);
      }
    }

    const {
      name = '',
      locationHeuristics = false,
      mfa = true,
      permissions = [],
      passwordPolicy = '',
      description = '',
      organizationId = ''
    } = editData;

    let passwordPolicyData = {
      name: passwordPolicy
    };

    let getRolePermissionsResponse, getPasswordPolicyDetails: any;

    if (!rolePermissions.length) {
      getAPIPermissions(permissions);
    }

    // if (!formData.passwordPolicy.length && !editData.passwordPolicy.length) {
    //   getPasswordPolicyDetails = await getPasswordPolicy(
    //     passwordPolicy,
    //     AppConstants.DEFAULT_PAGE,
    //     AppConstants.DEFAULT_PAGE_SIZE
    //   );

    //   if (getPasswordPolicyDetails.status === HTTP_STATUS.HTTP_OK) {
    //     passwordPolicyData = getPasswordPolicyDetails?.data?.data || [];
    //   }
    // }

    if (!formData.organization.length && organizationId.length && isEmpty(organizationData)) {
      let orgResponse = await getOrganization(organizationId);

      if (orgResponse.status === HTTP_STATUS.HTTP_OK) {
        organizationData = [orgResponse.data];
      }
    }
    setBreadCrumb(name);

    let formObj: any = {
      roleName: name,
      permissionSet: !isEmpty(formData.permissionSet)
        ? formData.permissionSet
        : !isEmpty(permissionSet)
        ? [permissionSet]
        : [],
      roleDescription: description,
      passwordPolicy: !isEmpty(formData.passwordPolicy)
        ? formData.passwordPolicy
        : [passwordPolicyData],
      organization: !isEmpty(formData.organization)
        ? formData.organization
        : !isEmpty(organizationData)
        ? organizationData
        : [],
      apiPermissions: orderBy(
        permissions,
        [AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY],
        [AppConstants.DEFAULT_SORT_ASC]
      ),
      mfaEnabled: mfa,
      locationHeuristics
    };

    setFormData(formObj);
    setFormDataClone(formObj);
    setIsLoading(false);
  };

  const isRoleDetailsChanged = () => {
    if (params?.id) {
      const fieldsToCheck = ['mfaEnabled', 'locationHeuristics', 'roleName', 'roleDescription'];

      for (const field of fieldsToCheck) {
        if (!isEqual(formData[field], formDataClone[field])) {
          return true;
        }
      }

      if (formData?.permissionSet[0]?.label !== formDataClone?.permissionSet[0]?.label) {
        return true;
      }

      if (formData?.organization[0]?.name !== formDataClone?.organization[0]?.name) {
        return true;
      }

      if (formData?.passwordPolicy[0]?.name !== formDataClone?.passwordPolicy[0]?.name) {
        return true;
      }

      return false;
    }
    return true;
  };

  const isApiPermissionsChanged = () => {
    if (params?.id) {
      if (formData.apiPermissions.length !== formDataClone.apiPermissions.length) {
        return true;
      }

      const originalApiPermissions = keyBy(
        formData.apiPermissions,
        AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY
      );
      const modifiedApiPermissions = keyBy(
        formDataClone.apiPermissions,
        AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY
      );

      for (const apiPermission in originalApiPermissions) {
        if (
          !modifiedApiPermissions[apiPermission] ||
          !isEqual(originalApiPermissions[apiPermission], modifiedApiPermissions[apiPermission])
        ) {
          return true;
        }
      }

      return false;
    }
    return true;
  };

  const fetchOrg = async (name: string = '') => {
    const { searchText, page } = orgManifest;
    if (searchText === name) {
      let response = await getOrganizations(
        name,
        page + 1,
        AppConstants.DEFAULT_PAGE_SIZE,
        `${AppConstants.DEFAULT_SORT_BY},${AppConstants.DEFAULT_SORT_DESC}`
      );
      const { status, data: orgResponse } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        setOrgManifest({ ...orgManifest, page: page + 1 });
        setOrgHasMore(orgResponse?.paging?.next !== null);
        setOrganizationList([...organizationList, ...orgResponse.data]);
      }
    } else {
      setOrgManifest({ ...orgManifest, searchText: name });
      let response = await getOrganizations(
        name,
        AppConstants.DEFAULT_PAGE,
        AppConstants.DEFAULT_PAGE_SIZE,
        `${AppConstants.DEFAULT_SORT_BY},${AppConstants.DEFAULT_SORT_DESC}`
      );
      if (response.status === HTTP_STATUS.HTTP_OK) {
        setOrgManifest({ ...orgManifest, searchText: name, page: AppConstants.DEFAULT_PAGE });
        setOrgHasMore(response?.data?.paging?.next !== null);
        setOrganizationList(response.data.data);
      }
    }
  };

  const fetchPasswordPolicies = async (name: string = '') => {
    const { searchText, page } = passwordPolicyManifest;
    const updateCurrentPasswordPolicy = (arr: any) => {
      if (arr.length) {
        let filteredPolicy = arr.filter(
          (item: any) => item?.name === formData?.passwordPolicy[0]?.name
        );
        if (filteredPolicy.length) {
          setFormData({ ...formData, passwordPolicy: filteredPolicy });
        }
      }
    };
    if (searchText === name) {
      const response = await getPasswordPolicy(name, page + 1, AppConstants.DEFAULT_PAGE_SIZE, []);
      const { status, data: passPolicyResponse } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        setPasswordPolicyManifest({ ...passwordPolicyManifest, page: page + 1 });
        setPasswordPolicyHasMore(passPolicyResponse?.paging?.next !== null);
        setPasswordPolicyList([...passwordPolicyList, ...passPolicyResponse?.data]);
        if (!formData.passwordPolicy?.id) {
          updateCurrentPasswordPolicy(passPolicyResponse?.data);
        }
      }
    } else {
      setPasswordPolicyManifest({ ...passwordPolicyManifest, searchText: name });
      const response = await getPasswordPolicy(
        name,
        AppConstants.DEFAULT_PAGE,
        AppConstants.DEFAULT_PAGE_SIZE,
        []
      );
      const { status, data: passPolicyResponse } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        setPasswordPolicyManifest({
          ...passwordPolicyManifest,
          searchText: name,
          page: AppConstants.DEFAULT_PAGE
        });
        setPasswordPolicyHasMore(passPolicyResponse?.paging?.next !== null);
        setPasswordPolicyList(passPolicyResponse?.data);
        if (!formData.passwordPolicy?.id) {
          updateCurrentPasswordPolicy(passPolicyResponse?.data);
        }
      }
    }
  };

  const setBreadCrumb = (name?: string) => {
    if (params.id) {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: RouteConstants.ROUTE_ROLE_MANAGEMENT_EDIT.replace(':id', params.id),
            label: `${t('T_BREADCRUMB_LABEL_FOR_ROLE_MGMT_EDIT_PAGE')} > ${name}`
          }
        ])
      );
    } else {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: RouteConstants.ROUTE_ROLE_MANAGEMENT_CREATE,
            label: `${t('T_BREADCRUMB_LABEL_FOR_ROLE_MGMT_CREATE_PAGE')}`
          }
        ])
      );
    }
  };

  const savePayload = async () => {
    setIsLoading(true);

    const {
      roleName,
      roleDescription,
      mfaEnabled,
      apiPermissions,
      passwordPolicy,
      organization,
      locationHeuristics
    } = formData;

    const payload: any = {
      name: roleName.trim(),
      mfa: mfaEnabled,
      locationHeuristics,
      description: roleDescription,
      passwordPolicy: passwordPolicy[0]?.name || '',
      apiPermissions: apiPermissions.map((permission: any) => permission?.apiPermission)
    };

    if (!payload.passwordPolicy.length) {
      delete payload.passwordPolicy;
    }

    if (organization.length) {
      payload.organizationId = organization[0]?.id;
    }

    let roleResponse;
    if (params?.id) {
      let flag = !isRoleDetailsChanged() && isApiPermissionsChanged();

      if (flag) {
        let addedPermissions = differenceBy(
          formData?.apiPermissions,
          formDataClone?.apiPermissions,
          AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY
        ).map((perm: any) => perm[AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY]);

        if (addedPermissions.length) {
          let permissionsPayload = {
            permissions: addedPermissions
          };
          roleResponse = await addPermissionsToRole(params.id, permissionsPayload);
        } else {
          let removedPermissions = differenceWith(
            formDataClone?.apiPermissions,
            formData?.apiPermissions,
            (permClone: any, perm: any) =>
              isEqual(
                perm?.[AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY],
                permClone?.[AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY]
              )
          ).map((perm: any) => perm[AppConstants.ACCESS_CONTROL_API_PERMISSION_KEY]);

          let permissionsPayload = {
            permissions: removedPermissions
          };
          roleResponse = await deletePermissionsFromRole(params.id, permissionsPayload);
        }
      } else {
        roleResponse = await updateRole(params.id, payload);
      }
    } else {
      roleResponse = await createRole(payload);
    }

    if (![HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(roleResponse.status)) {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(roleResponse),
          isToastActive: true,
          toastType: 'error'
        })
      );
      return;
    }

    const [permissionSet] = formData.permissionSet;
    const metadataPayload = {
      label: permissionSet?.label || '',
      attributes: {
        ...permissionSet?.attributes,
        roles: [payload.name.trim()]
      }
    };

    if (permissionSetObject?.id && permissionSet?.id) {
      const instanceResponse = await updateMetadataInstance(
        permissionSetObject.id,
        permissionSet.id,
        metadataPayload
      );

      if (instanceResponse.status !== HTTP_STATUS.HTTP_OK) {
        setIsLoading(false);
        dispatch(
          setToastData({
            toastMessage: getFailureMessage(roleResponse),
            isToastActive: true,
            toastType: 'error'
          })
        );
        return;
      }
    }

    if (params?.id) {
      dispatch(
        setToastData({
          toastMessage: t('T_ROLE_UPDATE_SUCCESS_MSG'),
          isToastActive: true,
          toastType: 'success'
        })
      );
      setIsLoading(false);
      saveEditResponse(roleResponse.data);
      setIsEdit(false);
    } else {
      dispatch(
        setToastData({
          toastMessage: t('T_ROLE_CREATED_SUCCESS_MSG'),
          isToastActive: true,
          toastType: 'success'
        })
      );
      navigate(RouteConstants.ROUTE_ROLE_MANAGEMENT_LIST);
    }
  };

  const getRoleAuditInfo = async () => {
    if (!roleData) {
      return new Map<string, any>();
    }
    const { createdBy, updatedBy } = roleData || {};
    const userProfiles: Array<any> = await getUserProfiles(uniq(compact([createdBy, updatedBy])));
    return new Map<string, any>(
      userProfiles.map((userProfile: any) => [userProfile.userId, userProfile])
    );
  };

  const fetchAuditData = async () => {
    const { createdBy, createdOn, updatedBy, updatedOn } = roleData || {};
    const userInfoMap: Map<string, any> = await getRoleAuditInfo();

    setAuditData({
      createdBy: getUserName(userInfoMap.get(createdBy)),
      updatedBy: getUserName(userInfoMap.get(updatedBy)),
      updatedOn: moment(updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT),
      createdOn: moment(createdOn).format(AppConstants.DEFAULT_DATE_FORMAT)
    });
  };

  useEffect(() => {
    if (roleData) {
      fetchAuditData();
    }
  }, [roleData]);

  const setNewPermissionSet = (data: any) => {
    if (!isEmpty(data)) {
      setFormData({ ...formData, permissionSet: data });
    }
    fetchPermissionSetData();
  };

  const setNewPasswordPolicy = (data: any) => {
    setCreatePasswordPolicyModal(false);
    if (!isEmpty(data)) {
      setFormData({ ...formData, passwordPolicy: [data] });
    }
  };

  const tabConfig = [
    {
      label: t('T_ROLE_DETAILS'),
      component: (
        <div className="p-4">
          <Card
            title={t('T_ROLE_DETAIL_CARD_TITLE')}
            icon={UserRole}
            alt={t('T_CARD_ALT_MESSAGE')}
            height=""
            width=""
          >
            <div className="flex w-full flex-wrap">
              <div className="mb-5 flex w-full">
                <div className="flex w-1/2 flex-col">
                  <InputField
                    type={'text'}
                    id={'roleName'}
                    label={t('T_APPLICATION_ROLE_NAME')}
                    placeholder={''}
                    fieldKey={'roleName'}
                    asterixFirst={true}
                    required={true}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    disabled={!isEdit}
                    max={AppConstants.MAX_LENGTH_IMPORT_NAME}
                    handleChange={(e: any) => {
                      const { value = '' } = e.target;
                      if (checkRoleFieldLength(value)) {
                        setFormData({ ...formData, roleName: value });
                      }
                    }}
                  />
                </div>
                <div className="w-1/2 pl-5">
                  <PickList
                    fieldKey={'id'}
                    fieldValues={['label']}
                    label={t('T_PERMISSION_SET')}
                    selectedItems={formData.permissionSet}
                    showAddOption={
                      apiRolePermissions[AppConstants.PERMISSION_ROLE_PERMISSION_CREATE]
                    }
                    onAddItem={() => {
                      setCreatePermissionModal(true);
                    }}
                    onSelectedChange={(items: any) => {
                      setFormData({ ...formData, permissionSet: items });
                    }}
                    items={permissionSetList}
                    disabled={!isEdit || (params.id !== undefined && formData.permissionSet.length)}
                    readOnly={false}
                    type={PICKLIST_TYPES.SINGLE}
                  />
                </div>
              </div>
              <div className="mb-5 flex w-full">
                <div className="flex w-1/2 flex-col">
                  <PickListWithApiSearch
                    fieldKey={'id'}
                    fieldValues={['name']}
                    selectedItems={formData.organization}
                    onSelectedChange={(items: any) => {
                      setFormData({ ...formData, organization: items });
                    }}
                    items={organizationList}
                    readOnly={false}
                    type={PICKLIST_TYPES.SINGLE}
                    disabled={!isEdit}
                    searchable={true}
                    fetchMoreData={fetchOrg}
                    hasMore={orgHasMore}
                    searchPlaceholder={'T_SEARCH_ORG_NAME'}
                    label={'T_ORGANIZATION'}
                    initialLoad={async () => {
                      await fetchOrg();
                    }}
                  />
                </div>
                <div className="flex w-1/2 flex-col pl-5">
                  <PickListWithApiSearch
                    fieldKey={'id'}
                    fieldValues={['name']}
                    selectedItems={formData.passwordPolicy}
                    onSelectedChange={(items: any) => {
                      setFormData({ ...formData, passwordPolicy: items });
                    }}
                    items={passwordPolicyList}
                    disabled={!isEdit}
                    readOnly={false}
                    type={PICKLIST_TYPES.SINGLE}
                    searchable={true}
                    fetchMoreData={fetchPasswordPolicies}
                    hasMore={passwordPolicyHasMore}
                    searchPlaceholder={'T_SELECT'}
                    label={'T_PASSWORD_POLICY'}
                    initialLoad={async () => {
                      await fetchPasswordPolicies();
                    }}
                    onAddItem={() => {
                      setCreatePasswordPolicyModal(true);
                    }}
                    showAddOption={
                      apiRolePermissions[AppConstants.PERMISSION_UMS_PASSWORD_POLICY_CREATE]
                    }
                  />
                </div>
              </div>

              <div className="mb-5 flex w-full flex-col">
                <TextAreaField
                  fieldKey={'roleDescription'}
                  type={'text'}
                  id={'roleDescription'}
                  label={t('T_ROLE_DESCRIPTION')}
                  placeholder={''}
                  required={false}
                  formData={formData}
                  setFormData={setFormData}
                  formError={formError}
                  asterixFirst={false}
                  rows={3}
                  disabled={!isEdit}
                ></TextAreaField>
              </div>
            </div>
          </Card>
          <Card
            title={t('T_ROLE_DETAIL_SECURITY_OPTIONS_TITLE')}
            icon={ShieldExclaimation}
            alt={t('T_CARD_ALT_MESSAGE')}
            height=""
            width=""
            className="mb-4 mt-2 w-full rounded-none rounded-t-lg"
          >
            <div className={`flex w-full flex-col`}>
              <div className="mb-2 flex flex-row">
                <Checkbox
                  height="20px"
                  width="20px"
                  testID="multi-factor-checkbox"
                  checked={formData.mfaEnabled}
                  disabled={!isEdit}
                  onChange={() => {
                    setFormData({ ...formData, mfaEnabled: !formData.mfaEnabled });
                  }}
                />
                <div className="ml-2 text-base font-normal">
                  {t('T_MULTI_FACTOR_AUTHENTICATION')}
                </div>
              </div>
              <div className="mt-4 flex flex-row">
                <Checkbox
                  height="20px"
                  width="20px"
                  testID="multi-factor-checkbox"
                  checked={formData.locationHeuristics}
                  disabled={!isEdit}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      locationHeuristics: !formData.locationHeuristics
                    });
                  }}
                />
                <div className="ml-2 text-base font-normal">{t('T_LOCATION_HEURISTICS')}</div>
              </div>
            </div>
          </Card>
        </div>
      )
    },
    {
      label: t('T_ROLE_API_PERMISSIONS'),
      component: (
        <div className="flex w-full flex-wrap p-4">
          <DualingListPick
            initialData={rolePermissions}
            setInitialData={setRolePermissions}
            outputData={formData.apiPermissions}
            setOutputData={(data: any) => setFormData({ ...formData, apiPermissions: data })}
            fieldKey="id"
            fieldValues={['apiPermission']}
            inputHeader={t('T_ROLE_API_PERMISSIONS_AVAILABLE')}
            outputHeader={t('T_ROLE_API_PERMISSIONS_SELECTED')}
            orderingControls={false}
            disabled={!isEdit}
            styles={{
              listWidth: 'w-[50%]',
              listHeight: 'h-[50rem]',
              buttonHeight: 'h-8',
              buttonWidth: 'w-8'
            }}
          ></DualingListPick>
        </div>
      )
    }
  ];

  const discardChanges = () => {
    setIsEdit(false);
    setFormData(formDataClone);
    setRolePermissions(rolePermissionsClone);
    setActiveTab(`${t('T_ROLE_DETAILS')}`);
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      {createPermissionModal && (
        <NewPermissionSet
          setNewDataLoaded={setNewPermissionSet}
          fromRole
          openModal={createPermissionModal}
          setOpenModal={setCreatePermissionModal}
        />
      )}

      {createPasswordPolicyModal && (
        <PasswordPolicyModal
          openModal={createPasswordPolicyModal}
          setNewDataLoaded={setNewPasswordPolicy}
          setOpenModal={setCreatePasswordPolicyModal}
        ></PasswordPolicyModal>
      )}
      <div className="flex w-3/4 flex-col  overflow-visible px-2 py-3">
        <MenuButtonsPortal>
          <>
            <BiButton
              className={'mr-2 text-primary'}
              type="button"
              onClick={() => {
                if (params.id && isEdit) {
                  discardChanges();
                } else {
                  navigate(-1);
                }
              }}
            >
              {params.id && isEdit ? t('T_CANCEL') : t('T_ROLE_DETAILS_BACK_TO_ROLE_BTN')}
            </BiButton>

            {isEdit ? (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={savePayload}
                disabled={
                  !isEdit ||
                  !formData.roleName ||
                  (params.id !== undefined && !isRoleDetailsChanged() && !isApiPermissionsChanged())
                }
              >
                {t('T_SAVE')}
              </BiButton>
            ) : (
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => setIsEdit(true)}
              >
                {t('T_EDIT')}
              </BiButton>
            )}
          </>
        </MenuButtonsPortal>
        <ToastNotification
          message={toastData.toastMessage}
          isActive={toastData.isToastActive}
          setIsActive={() =>
            dispatch(
              setToastData({
                toastMessage: '',
                isToastActive: false,
                toastType: null
              })
            )
          }
          status={toastData.toastType}
        />

        <div style={{ border: '1px solid #D9D9D9', borderRadius: '7px', overflow: 'auto' }}>
          <TabDrawer
            borderBottom={true}
            tabConfig={tabConfig}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>

      {!isLoading && params.id && (
        <div className="ml-4">
          {(auditData.createdOn || auditData?.createdBy) && (
            <div>
              <AuditInfo
                label={'CREATED'}
                date={auditData?.createdOn || ''}
                user={auditData?.createdBy || ''}
                isUserId={false}
              />
            </div>
          )}
          {(auditData.updatedOn || auditData?.updatedBy) && (
            <div>
              <AuditInfo
                label={'LAST_MODIFIED'}
                date={auditData?.updatedOn || ''}
                user={auditData?.updatedBy || ''}
                isUserId={false}
              />
            </div>
          )}
        </div>
      )}
    </LoaderContainer>
  );
};

export default RoleManagement;
