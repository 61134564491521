import Card from '../../../shared/cards/Card';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import LoaderContainer from '../../../shared/loaderContainer/LoaderContainer';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Clipboard from '../../../../assets/clipboard.svg';
import { AppConstants } from '../../../../constants/AppConstants';
import DragIcon from '../../../../assets/dragIcon.svg';
import QuestionCard from '../SubComponents/QuestionCard';
import { clone, isEqual, reject, cloneDeep } from 'lodash';
import { setToastData } from '../../../../app/toastReducer';
import { MenuConstants } from '../../../../constants/MenuConstants';
import DotMenu, { Options } from '../../../primitives/dotMenu/DotMenu';
import groupQuestionIcon from '../../../../assets/groupquestion.svg';
import { dragIcon } from '../../../../assets/components/svgs';
import Checkbox from '../../../shared/Fields/Checkbox';
import ConditionalBlock from '../SubComponents/ConditionalBlock';

export const apiTypeField: any = {
  single: 'text',
  multi: 'choice',
  choice: 'choice',
  datetime: 'dateTime',
  text: 'text',
  date: 'date',
  time: 'time',
  url: 'url',
  display: 'display',
  boolean: 'boolean',
  decimal: 'decimal',
  integer: 'integer'
};

export const API_TO_QUESTION_MAPPING: any = {
  choice: 'multi',
  display: 'display',
  boolean: 'boolean',
  decimal: 'decimal',
  integer: 'integer',
  date: 'date',
  time: 'time',
  dateTime: 'datetime',
  url: 'url',
  quantity: 'numeric',
  text: 'single'
};

export const Q_TYPE_TO_A_TYPE_MAPPING: any = {
  url: 'uri',
  date: 'date',
  dateTime: 'dateTime',
  datetime: 'dateTime',
  time: 'time',
  integer: 'integer',
  decimal: 'decimal'
};

interface DragQuestionListProps {
  questionList: any;
  isEdit: boolean;
  toggleAddGroupQuestion: any;
  questionIndex: any;
  setUpdatedQuestions: any;
  removeGroupQuestion: any;
  setEditQAModal: any;
  editQuestion: any;
  AddQuestionModal?: any;
  setConditionalToAddedQue?: Function;
  currentIndex?: any;
  disabled?: any;
  collection?: any;
}

const DragQuestionList = ({
  questionList,
  isEdit,
  toggleAddGroupQuestion,
  questionIndex,
  setUpdatedQuestions,
  removeGroupQuestion,
  setEditQAModal,
  editQuestion,
  AddQuestionModal = '',
  setConditionalToAddedQue,
  currentIndex,
  disabled,
  collection
}: DragQuestionListProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [mode, setMode] = useState(0); //0 is for Edit, 1 is for Create
  const [questions, setQuestions] = useState([]);
  const [addedQuestions, setAddedQuestions] = useState<any[]>([]);
  const [addedGroupQuestion, setAddedGroupQuestion] = useState<any[]>([]);
  const [groupId, setGroupId] = useState<string>('');
  const [conditionalCheck, setConditionalCheck] = useState<boolean>(false);

  const [dragId, setDragId] = useState();
  const [dragType, setDragType] = useState<string>('');

  useEffect(() => {
    const { text: questionName = '', item: questions = [], id = '' } = cloneDeep(questionList);

    let temp = { ...questionList };
    if (questionName) {
      temp = cloneDeep(questions);
    }
    setAddedGroupQuestion(temp);
    setGroupId(id);
  }, [questionList]);

  const handleDrag = (ev: any) => {
    if (!isEdit) {
      setDragId(ev.currentTarget.id);
      setDragType(ev.currentTarget.datatype);
    }
  };

  const handleDrop = (ev: any) => {
    if (!isEdit && dragType === ev.currentTarget.datatype) {
      let clone = [...addedGroupQuestion];
      const draggedQuestion = clone.find((ele: any) => ele.id === dragId);
      const droppedQuestion = clone.find((ele: any) => ele.id === ev.currentTarget.id);
      let draggedQuestionIndex = draggedQuestion ? clone.indexOf(draggedQuestion) : -1;
      let droppedQuestionIndex = droppedQuestion ? clone.indexOf(droppedQuestion) : -1;
      let temp = clone[draggedQuestionIndex];
      clone.splice(draggedQuestionIndex, 1); //Removal
      clone.splice(droppedQuestionIndex, 0, temp); //Adding
      SetUpdatedQuestionList(clone);
      ev.stopPropagation();
    }
  };

  const SetUpdatedQuestionList = (queArr: any[]) => {
    setAddedGroupQuestion(queArr);
    setUpdatedQuestions(queArr, questionIndex);
  };

  const removeQuestion = (id: number) => {
    const modifiedQueArr = [...addedGroupQuestion];
    // setAddedQuestions(reject(modifiedQueArr, { id: id }));
    const temp = reject(modifiedQueArr, { id: id });
    if (temp.length) {
      SetUpdatedQuestionList(temp);
    } else {
      removeGroupQuestion(groupId);
    }

    dispatch(
      setToastData({
        toastMessage: t('T_REMOVE_SURVEY_QUESTION_SUCCESS'),
        isToastActive: true,
        toastType: 'success'
      })
    );
  };

  const toggleReadOnly = (id: number, readOnly: boolean) => {
    const queArr = addedGroupQuestion.map((obj) => {
      if (obj.id === id) {
        return { ...obj, readOnly: readOnly };
      }
      return obj;
    });
    // setAddedQuestions(queArr);
    SetUpdatedQuestionList(queArr);
  };

  const contextMenuHandler = (id: number, selectedOption: any) => {
    const { key: menuName } = selectedOption;
    switch (menuName) {
      case MenuConstants.MENU_REMOVE_QUESTION:
        removeQuestion(id);
        break;
      case MenuConstants.MENU_REMOVE_READ_ONLY:
        toggleReadOnly(id, false);
        break;
      case MenuConstants.MENU_MARK_AS_READ_ONLY:
        toggleReadOnly(id, true);
        break;
      case MenuConstants.MENU_EDIT_QUESTION_ANSWERS:
        setEditQAModal(true);
        editQuestion(id);
        break;
    }
  };

  const listQuestionList = (questionList: any, groupId: string) => {
    return (
      <>
        {addedGroupQuestion.map((item: any, index: number) => {
          return (
            <div
              className={`mt-5 flex w-full flex-row justify-between ${
                isEdit && 'cursor-pointer'
              } rounded-lg border-[0.5px] border-[#d9d9d9] bg-[#ffffff] px-4`}
              draggable={isEdit}
              onDragOver={(ev: any) => {
                ev.preventDefault();
              }}
              onDragStart={handleDrag}
              onDrop={handleDrop}
              id={item.id}
              key={item.id}
              datatype={`group-${groupId}`}
            >
              <img className="mr-5 cursor-pointer" src={DragIcon} alt={'three-dot'} />
              <QuestionCard
                questionData={item}
                collection={addedGroupQuestion}
                currentIndex={index}
                setQuestions={(val: any) => {
                  SetUpdatedQuestionList(val);
                }}
                disabled={isEdit}
                mode={mode}
                contextMenuHandler={contextMenuHandler}
                groupIndex={questionIndex + 1}
                disableCond={false}
                AddQuestionModal={AddQuestionModal}
                setConditionalToAddedQue={setConditionalToAddedQue}
              ></QuestionCard>

              {/* <div className="bg-[#F5F7FA] text-sm">
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={false}
                  // onChange={() => console.log(true)}
                />
                <span>Display additional questions based on answers</span>
              </div> */}
            </div>
          );
        })}
      </>
    );
  };

  let dotMenuOptions: Array<any> = [
    {
      display: `${t('T_REMOVE_GROUP')}`,
      key: MenuConstants.MENU_REMOVE_GROUP
    }
  ];

  const GroupMenu = () => {
    return (
      <>
        <DotMenu
          options={dotMenuOptions}
          onSelectedOptionChange={(value) => {
            const { key: menuName } = value;
            switch (menuName) {
              case MenuConstants.MENU_REMOVE_GROUP:
                removeGroupQuestion(groupId, true);
                break;
            }
          }}
          value={''}
          containerClassName={'ml-auto'}
          // iconSize={iconSize}
          // disabled={disabled}
        />
      </>
    );
  };

  const listGroupQuestionList = (groupQuestionList: any) => {
    const {
      text: questionName = '',
      description = '',
      item: questions = [],
      id = ''
    } = questionList || {};
    return (
      <>
        <div className="mt-3 flex w-full flex-col">
          <Card
            title={'Group Question'}
            icon={groupQuestionIcon}
            alt="groupQuestionIcon"
            height=""
            width=""
            className="!rounded-lg border border-solid !border-[#d9d9d9] bg-[#FAFAFA]"
            actions={GroupMenu()}
            headingClass={'bg-[#F5F7FA] text-[#4575B4]'}
            dragIcon={dragIcon}
          >
            <div className="flex">
              <div className="w-4/5 text-[#262626]">
                <div className="text-base font-medium">
                  <span className="mr-2 text-lg font-bold text-[#003A70]">{questionIndex + 1}</span>{' '}
                  {questionName}
                </div>
                <div className="mt-2.5 text-base font-normal">{description}</div>
              </div>
              <div
                className="w-1/5 text-right text-base font-normal text-[#99B0C6]"
                onClick={() => {
                  toggleAddGroupQuestion();
                }}
              >
                {t('T_ADD_GROUP_QUESTION')}
              </div>
            </div>
            {listQuestionList(groupQuestionList || [], id)}
            {/* {currentIndex > 0 && (
              <div className="mb-[-20px] ml-[-20px] mr-[-20px] mt-[20px] flex">
                <div className="w-full text-[#262626]">
                  <button
                    className={`collapsed accordion-button flex w-full flex-row justify-between rounded-b-lg px-5 py-3 transition focus:outline-none`}
                    style={{ backgroundColor: '#F5F7FA' }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${id}`}
                    aria-expanded="false"
                    aria-controls={`collapse-${id}`}
                    onClick={() => {
                      !disabled && setConditionalCheck(!conditionalCheck);
                    }}
                    id={`#collapse-${id}`}
                  >
                    <div className="flex flex-row">
                      <Checkbox
                        className="mx-auto"
                        height="20px"
                        width="20px"
                        disabled={disabled}
                        checked={conditionalCheck}
                      />

                      <div className="ml-2 text-sm font-normal" style={{ color: '#262626' }}>
                        Display this question if ...
                      </div>
                    </div>
                  </button>
                  <div
                    id={`collapse-${id}-${currentIndex}`}
                    className={`accordion-collapse !visible collapse rounded-b-lg ${
                      conditionalCheck ? `show` : ``
                    }`}
                    //</>className="accordion-collapse !visible collapse rounded-b-lg"
                    aria-labelledby="headingTwo5"
                    style={{ background: '#FAFAFA' }}
                  ></div>
                </div>
              </div>
            )} */}
            <div className="mb-[-20px] ml-[-20px] mr-[-20px] mt-[20px] flex">
              <div className="w-full text-[#262626]">
                <ConditionalBlock
                  questionData={questionList}
                  collection={collection}
                  currentIndex={currentIndex}
                  setQuestions={setQuestions}
                  disabled={disabled}
                  mode={mode}
                  branch={(questionIndex + 1).toString()}
                  groupIndex={(questionIndex + 1).toString()}
                  contextMenuHandler={contextMenuHandler}
                  AddQuestionModal={AddQuestionModal}
                  setConditionalToAddedQue={setConditionalToAddedQue}
                ></ConditionalBlock>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  };

  return addedGroupQuestion.length ? listGroupQuestionList(addedGroupQuestion) : <></>;
};

export default DragQuestionList;

export const DropDownContainer = styled.div`
  position: relative;
  overflow: visible;
`;

const Block = styled.div`
  padding: 1.25rem;
  // border-color: #bfbfbf;
  // border-bottom-width: 0.5px;
`;
