import { Suspense, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Navigate, Route, Routes as RoutesReactRouterDom } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logOut } from '../app/loginReducer';
import { LogoutRequestBody } from '../app/models/auth/loginUser';
import { logout } from '../http/authentication-services';

import env from '../environment';

import { GenericNotFound, Login } from './paths';
import Menu from '../components/Menu/Menu';
import AppInitialize from '../components/AppInitialize/AppInitialize';
import TemplateList from '../components/Templates/TemplateList';
import CreateTemplateForm from '../components/Templates/CreateTemplate/CreateTemplateForm';
import CreateOrganization from '../components/Organizations/CreateOrganization';
import AdminList from '../components/Admins/AdminList';
import AdminContainer from '../components/Admins/AdminContainer';
import AdminDetails from '../components/Admins/AdminDetails';
import OrganizationsList from '../components/Organizations/OrganizationsList';
import ShowOrganization from '../components/Organizations/ShowOrganization';
import Register from '../components/Register/Register.container';
import RoleDetail from '../components/Roles/RoleDetail';
import RoleList from '../components/Roles/RoleList';
import PermissionSetList from '../components/PermissionSet/PermissionSetList';
import ShowPermissionSet from '../components/PermissionSet/ShowPermissionSet';
import ApplicationSettings from '../components/AppSettings/ApplicationSettings';
import TemplateViewer from '../components/Templates/TemplateViewer';
import SurveyList from '../components/Survey/Template/SurveyList';
import SurveyTemplateDetail from '../components/Survey/Template/TemplateDetail';
import QuestionList from '../components/Survey/Question/QuestionList';
import NewQuestion from '../components/Survey/Question/NewQuestion';
import { RouteConstants } from '../constants/RouteConstants';
import { useTranslation } from 'react-i18next';
import ProtectRoute from '../components/RoutePermission/ProtectRoute';
import { AppConstants } from '../constants/AppConstants';
import { APIKeyConstants } from '../constants/APIKeyConstants';
import MobileConfigurationListComponent from '../components/MobileConfiguration/MobileConfigurationListComponent';
import MobileConfigurationComponent from '../components/MobileConfiguration/MobileConfiguration';
import MobileConfigurationLanguageComponent from '../components/MobileConfiguration/MobileConfigurationLanguage';
import MobileConfigurationImageComponent from '../components/MobileConfiguration/MobileConfigurationImage';
import ConsentContainer from '../components/Consent/consentContainer';
import FieldGroupDetails from '../components/Consent/fieldGroupDetails';
import ConsentTemplateDetails from '../components/Consent/consentTemplateDetails';
import MetadataList from '../components/Metadata/MetadataList';
import CreateMetadata from '../components/Metadata/CreateMetadata';
import ConfigurationService from '../components/ConfigurationService/ConfigurationService';
import ConfigKeyList from '../components/ConfigurationService/ConfigKeyList';
import ConfigKeyDetails from '../components/ConfigurationService/ConfigKeyDetails';
import CategoriesList from '../components/ConfigurationService/CategoriesList';
import CategoryDetails from '../components/ConfigurationService/CategoryDetails';
import CreateObject from '../components/Metadata/CreateObject';
import ObjectList from '../components/Metadata/ObjectList';
import { CustomComponent } from '../components/Custom/Custom';
import Modal from '../components/shared/ModalPortal/Modal';
import BiButton from '../components/primitives/buttons/BiButton.primitive';
import InstanceList from '../components/Metadata/InstanceList';
import CreateInstance from '../components/Metadata/CreateInstance';
import MigrationContainer from '../components/Migration/MigrationContainer';
import ExportDetail from '../components/Migration/Details/ExportDetail';
import ImportDetail from '../components/Migration/ImportDetails/ImportDetail';
import TemplateDetail from '../components/Templates/TemplateDetail';
import { fetchFromCookie } from '../util/admin-utils';
import PasswordPolicyList from '../components/PasswordPolicy/PasswordPolicyList';
import PasswordPolicy from '../components/PasswordPolicy/PasswordPolicy';
import BucketList from '../components/FileManagement/BucketList';
import BucketDetail from '../components/FileManagement/BucketDetail';
import NewFolder from '../components/FileManagement/NewFolder';
import RoleManagement from '../components/Roles/RoleManagement';
import FolderDetails from '../components/FileManagement/FolderDetails';
import FileVersionHistoryList from '../components/FileManagement/FileHistoryList';
import CarePlanTemplateList from '../components/CarePlan/CarePlanTemplateList';
import CarePlanTemplate from '../components/CarePlan/CarePlanTemplate';
import AddNewLanguage from '../components/MobileConfiguration/AddNewLanguage';
import CreateNewRequest from '../components/PatientRequests/CreateNewRequest';

export const Routes = () => {
  let warningTime: any = useRef(null);
  let logoutTime: any = useRef(null);
  let remainingTime: any = useRef(null);

  const dispatch = useAppDispatch();
  const [showAccountLockout, setShowAccountLockout] = useState(false);
  const [showInactivityDialog, setShowInactivityDialog] = useState(false);
  const [timerText, setTimerText] = useState('');
  const loggedIn = useAppSelector((state) => state.login.isLoggedIn);
  const initialDataLoaded = useAppSelector((state) => state.initialLoadData.initialDataLoaded);
  const solutionConfigurationDefaults: any = useAppSelector(
    (state) => state.configuration.solutionConfigurationDefaults
  );
  const { t } = useTranslation();

  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;

  function resetTimer() {
    const { session = {} } = solutionConfigurationDefaults;
    const {
      inactivityLogout: inactivityLogoutTime = AppConstants.DEFAULT_INACTIVITY_LOGOUT_TIME,
      inactivityWarning = AppConstants.DEFAULT_INACTIVITY_WARNING_TIME
    } = session;
    // const inactivityLogouttime = 120000;
    // const inactivityWarningtime = 60000;

    // "inactivityLogout": 1800000,
    //     "inactivityWarning": 1500000

    if (loggedIn && !showInactivityDialog) {
      clearTimeout(warningTime.current);
      clearTimeout(logoutTime.current);
      warningTime.current = setTimeout(() => warnInactivity(), inactivityWarning);
      logoutTime.current = setTimeout(() => inactivityLogout(), inactivityLogoutTime);
    }
  }

  async function inactivityLogout() {
    clearInterval(remainingTime.current);
    setShowInactivityDialog(false);
    const logoutBody: LogoutRequestBody = {
      client_id: 'oidc_pkce',
      token: fetchFromCookie('access-token') || '' // localStorage.getItem('access-token') || ''
    };
    await logout(logoutBody);
    dispatch(logOut());
    return `${t('T_LOGOUT_MSG')}`;
  }

  function warnInactivity() {
    const { session = {} } = solutionConfigurationDefaults;
    let {
      inactivityLogout = AppConstants.DEFAULT_INACTIVITY_LOGOUT_TIME,
      inactivityWarning = AppConstants.DEFAULT_INACTIVITY_WARNING_TIME
    } = session;

    // let inactivityLogout = 120000;
    // let inactivityWarning = 60000;
    let remainingTimeText = '';
    clearInterval(remainingTime.current);
    remainingTime.current = setInterval(() => {
      const timeleft = inactivityLogout - inactivityWarning;
      let minutes, seconds;
      minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
      if (timeleft < 0) {
        clearInterval(remainingTime.current);
      }
      inactivityWarning = inactivityWarning + 1000;
      remainingTimeText = `${minutes} minutes and ${seconds} seconds.`;
      setTimerText(remainingTimeText);
      setShowInactivityDialog(true);
    }, 1000);

    return remainingTimeText;
  }

  return (
    <Suspense fallback={<p>Loading</p>}>
      {loggedIn && showInactivityDialog && (
        <Modal
          overflowVisible={true}
          showCloseIcon={true}
          width="30rem"
          showHeader={true}
          headerText={t('T_SESSION_TIMEOUT_HEADER')}
          toggleVisiblity={(value: boolean) => {
            clearInterval(remainingTime.current);
            setShowInactivityDialog(false);
          }}
          isVisible={showInactivityDialog}
          body={
            <>
              <div className="mb-5 flex flex-col">
                <div className="">{`${t('T_SESSION_TIMEOUT_BODY_TEXT')} ${timerText}`}</div>
              </div>

              <ModalButton>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    clearInterval(remainingTime.current);
                    setShowInactivityDialog(false);
                  }}
                >
                  {t('T_SESSION_STAY_SIGNED_IN')}
                </BiButton>
                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    inactivityLogout();
                  }}
                  // disabled={!permissions.create}
                >
                  {t('T_SESSION_SIGN_OUT')}
                </BiButton>
              </ModalButton>
            </>
          }
        />
      )}
      {loggedIn && (
        <Menu modalIsActive={false}>
          <AppInitialize>
            {initialDataLoaded && (
              <RoutesReactRouterDom>
                {/* <Route path="/profile" element={<ProfileMainView />} /> */}
                <Route path={RouteConstants.ROUTE_PROFILE} element={<AdminDetails />} />
                <Route path="/404" element={<GenericNotFound />} />
                <Route
                  path="/templates"
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_LIST]}
                    >
                      <TemplateList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path="/templates/:id"
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_GET]}
                    >
                      <TemplateList />
                    </ProtectRoute>
                  }
                />

                {/* <Route
                  path="/templates/edit/:id"
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_UPDATE]}
                    >
                      <TemplateViewer />
                    </ProtectRoute>
                  }
                /> */}
                <Route
                  path={RouteConstants.NOTIFICATION_TEMPLATE_DETAIL_EDIT}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_UPDATE]}
                    >
                      <TemplateDetail />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.NOTIFICATION_TEMPLATE_DETAIL_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_CREATE]}
                    >
                      <TemplateDetail />
                    </ProtectRoute>
                  }
                />

                <Route
                  path="/role-management/create"
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_ROLE_CREATE]}>
                      <RoleDetail />
                    </ProtectRoute>
                  }
                />

                <Route
                  path="/role-management/edit/:id"
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_ROLE_UPDATE]}>
                      <RoleDetail />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_ROLE_MANAGEMENT_LIST}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_ROLE_LIST]}>
                      <RoleList />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_ROLE_MANAGEMENT_CREATE}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_ROLE_CREATE]}>
                      <RoleManagement />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_ROLE_MANAGEMENT_EDIT}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_ROLE_CREATE]}>
                      <RoleManagement />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_ADMIN_LIST_ACTIVE_USERS}
                  element={<AdminContainer defaultTab="Active Users" />}
                />
                <Route
                  path={RouteConstants.ROUTE_ADMIN_LIST_INVITED_USERS}
                  element={<AdminContainer defaultTab="Invited Users" />}
                />

                {/* <Route path="/admin-list" element={<AdminList />} /> */}
                {/* <Route path="/admin-list/:id" element={<AdminList />} /> */}
                <Route
                  path="/admin-list/:id"
                  element={<AdminContainer defaultTab="Active Users" />}
                />
                <Route
                  path="/admin-list/invited/:id"
                  element={<AdminContainer defaultTab="Invited Users" />}
                />
                <Route path={RouteConstants.ROUTE_USER_DETAILS} element={<AdminDetails />} />
                <Route
                  path={RouteConstants.ROUTE_INVITED_USER_DETAILS}
                  element={<AdminDetails />}
                />
                <Route
                  path="/organization/new"
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_ORGNIZATION_CREATE]}>
                      <CreateOrganization />
                    </ProtectRoute>
                  }
                />
                <Route
                  path="/organization/edit/:id"
                  element={
                    <ProtectRoute
                      apiPermissions={[
                        AppConstants.PERMISSION_ORGANIZATION_UPDATE,
                        AppConstants.PERMISSION_ORGANIZATION_PATCH
                      ]}
                    >
                      <CreateOrganization />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_OS_ORGANIZATION_LISTING}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_ORGANIZATION_LIST_CHILDREN]}
                    >
                      <OrganizationsList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path="/organization/:id"
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_ORGANIZATION_GET]}>
                      <ShowOrganization />
                    </ProtectRoute>
                  }
                />
                <Route path="/permissionset-list" element={<PermissionSetList />} />
                <Route path="/permission/:id" element={<ShowPermissionSet />} />
                <Route path="/app-settings" element={<ApplicationSettings DMSsetting={true} />} />
                <Route
                  path={RouteConstants.ROUTE_SURVEY_TEMPLATE_LISTING}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_QUESTIONNAIRE_LIST]}>
                      <SurveyList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_SURVEY_TEMPLATE_CREATE}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_QUESTIONNAIRE_CREATE]}>
                      <SurveyTemplateDetail />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_SURVEY_TEMPLATE_EDIT}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_QUESTIONNAIRE_UPDATE]}>
                      <SurveyTemplateDetail />
                    </ProtectRoute>
                  }
                />
                <Route
                  path="/survey-question-list"
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_METADATA_INSTANCE_LIST]}>
                      <QuestionList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path="/survey-question/new"
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_METADATA_INSTANCE_CREATE]}
                    >
                      <NewQuestion />
                    </ProtectRoute>
                  }
                />
                <Route
                  path="/survey-question/edit/:id"
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_METADATA_INSTANCE_UPDATE]}
                    >
                      <NewQuestion />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MOBILE_CONFIG_LIST}
                  element={<MobileConfigurationListComponent />}
                />
                <Route
                  path={RouteConstants.ROUTE_MOBILE_CONFIG_STYLE}
                  element={<MobileConfigurationComponent />}
                />
                <Route
                  path={RouteConstants.ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION}
                  element={<MobileConfigurationLanguageComponent />}
                />
                <Route
                  path={RouteConstants.ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION_NEW}
                  element={<AddNewLanguage />}
                />
                <Route
                  path={RouteConstants.ROUTE_MOBILE_CONFIG_CONFIGURATION}
                  element={<MobileConfigurationComponent />}
                />
                <Route
                  path={RouteConstants.ROUTE_MOBILE_CONFIG_FILE}
                  element={<MobileConfigurationImageComponent />}
                />
                <Route
                  path={RouteConstants.ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION}
                  element={<MobileConfigurationLanguageComponent />}
                />
                <Route
                  path={RouteConstants.ROUTE_MDS_APPLN_LIST}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_METADATA_APPN_LIST]}>
                      <MetadataList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MDS_APPLN_CREATE}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_METADATA_APPN_CREATE]}>
                      <CreateMetadata />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MDS_APPLN_VIEW}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_METADATA_INSTANCE_UPDATE]}
                    >
                      <CreateMetadata />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_MDS_OBJECT_LIST}
                  element={
                    <ProtectRoute
                      apiPermissions={[
                        AppConstants.PERMISSION_METADATA_APPN_GET,
                        AppConstants.PERMISSION_METADATA_OBJECT_LIST
                      ]}
                    >
                      <ObjectList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MDS_OBJECT_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[
                        AppConstants.PERMISSION_METADATA_APPN_GET,
                        AppConstants.PERMISSION_METADATA_OBJECT_CREATE
                      ]}
                    >
                      <CreateObject />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MDS_OBJECT_VIEW}
                  element={
                    <ProtectRoute
                      apiPermissions={[
                        AppConstants.PERMISSION_METADATA_APPN_GET,
                        AppConstants.PERMISSION_METADATA_OBJECT_GET
                      ]}
                    >
                      <CreateObject />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_MDS_INSTANCE_LIST}
                  element={
                    <ProtectRoute
                      apiPermissions={[
                        AppConstants.PERMISSION_METADATA_OBJECT_GET,
                        AppConstants.PERMISSION_METADATA_APPN_GET,
                        AppConstants.PERMISSION_METADATA_INSTANCE_LIST
                      ]}
                    >
                      <InstanceList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MDS_INSTANCE_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_METADATA_INSTANCE_CREATE]}
                    >
                      <CreateInstance />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MDS_INSTANCE_VIEW}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_METADATA_INSTANCE_GET]}>
                      <CreateInstance />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_CONSENTS_FIELDGROUP}
                  element={<ConsentContainer defaultTab="Field Groups" />}
                />
                <Route
                  path={RouteConstants.ROUTE_CONSENTS}
                  element={<ConsentContainer defaultTab="Consent Templates" />}
                />
                <Route
                  path={RouteConstants.ROUTE_CONSENTS_FIELDGROUP_DETAILS}
                  element={<FieldGroupDetails />}
                />
                <Route
                  path={RouteConstants.ROUTE_CONSENTS_DETAILS}
                  element={<ConsentTemplateDetails />}
                />
                <Route
                  path={RouteConstants.ROUTE_NEW_CONSENTS}
                  element={<ConsentTemplateDetails isCreateNew={true} />}
                />
                <Route
                  path={RouteConstants.ROUTE_MIGRATION_EXPORT_LIST}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_MIGRATION_EXPORT_LIST]}>
                      <MigrationContainer defaultTab={'EXPORT'} />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MIGRATION_IMPORT_LIST}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_MIGRATION_IMPORT_LIST]}>
                      <MigrationContainer defaultTab={'IMPORT'} />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MIGRATION_EXPORT_DETAIL_EDIT}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_MIGRATION_EXPORT_GET]}>
                      <ExportDetail />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MIGRATION_EXPORT_DETAIL_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_MIGRATION_EXPORT_CREATE]}
                    >
                      <ExportDetail />
                    </ProtectRoute>
                  }
                ></Route>
                <Route
                  path={RouteConstants.ROUTE_MIGRATION_IMPORT_DETAIL_EDIT}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_MIGRATION_IMPORT_GET]}>
                      <ImportDetail />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_MIGRATION_IMPORT_DETAIL_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_MIGRATION_IMPORT_CREATE]}
                    >
                      <ImportDetail />
                    </ProtectRoute>
                  }
                ></Route>
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={loggedIn ? RouteConstants.ROUTE_OS_ORGANIZATION_LISTING : 'auth'}
                    />
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_CONFIGURATIONSERVICE_LIST}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_SERVICE_SETTING_LIST]}
                    >
                      <ConfigKeyList />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_ORG_CONFIGURATIONSERVICE_LIST}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_SERVICE_ORG_SETTING_LIST]}
                    >
                      <ConfigKeyList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_USER_CONFIGURATIONSERVICE_LIST}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_SERVICE_ORG_SETTING_LIST]}
                    >
                      <ConfigKeyList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_CONFIG_KEY_UPDATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_KEY_SETTING_UPDATE]}
                    >
                      <ConfigKeyDetails />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_ORG_CONFIG_KEY_UPDATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_KEY_SETTING_UPDATE]}
                    >
                      <ConfigKeyDetails />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_USER_CONFIG_KEY_UPDATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_KEY_SETTING_UPDATE]}
                    >
                      <ConfigKeyDetails />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_CONFIG_KEY_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_KEY_SETTING_CREATE]}
                    >
                      <ConfigKeyDetails />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_CATEGORIES_LIST}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_KEY_SETTING_CREATE]}
                    >
                      <CategoriesList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_ORG_CATEGORIES_LIST}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_KEY_SETTING_CREATE]}
                    >
                      <CategoriesList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_CATEGORIES_EDIT}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_KEY_SETTING_CREATE]}
                    >
                      <CategoryDetails />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_CATEGORIES_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CONFIG_KEY_SETTING_CREATE]}
                    >
                      <CategoryDetails />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_UMS_PASSWORD_POLICY_LIST}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_UMS_PASSWORD_POLICY_LIST]}
                    >
                      <PasswordPolicyList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_UMS_PASSWORD_POLICY_VIEW}
                  element={
                    <ProtectRoute
                      apiPermissions={[
                        AppConstants.PERMISSION_UMS_PASSWORD_POLICY_LIST,
                        AppConstants.PERMISSION_UMS_PASSWORD_POLICY_UPDATE
                      ]}
                    >
                      <PasswordPolicy />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_UMS_PASSWORD_POLICY_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_UMS_PASSWORD_POLICY_CREATE]}
                    >
                      <PasswordPolicy />
                    </ProtectRoute>
                  }
                />

                <Route
                  path={RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_LIST}
                  element={
                    <ProtectRoute apiPermissions={[AppConstants.PERMISSION_CAREPLAN_TEMPLATE_LIST]}>
                      <CarePlanTemplateList />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_VIEW}
                  element={
                    <ProtectRoute
                      apiPermissions={[
                        AppConstants.PERMISSION_CAREPLAN_TEMPLATE_GET,
                        AppConstants.PERMISSION_CAREPLAN_TEMPLATE_PATCH
                      ]}
                    >
                      <CarePlanTemplate />
                    </ProtectRoute>
                  }
                />
                <Route
                  path={RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_CREATE}
                  element={
                    <ProtectRoute
                      apiPermissions={[AppConstants.PERMISSION_CAREPLAN_TEMPLATE_CREATE]}
                    >
                      <CarePlanTemplate />
                    </ProtectRoute>
                  }
                />

                <Route path={RouteConstants.ROUTE_CUSTOM_METADATA} element={<CustomComponent />} />
                <Route path={RouteConstants.ROUTE_AMS_FILES} element={<BucketList />} />
                <Route path={RouteConstants.ROUTE_AMS_FILE_DETAILS} element={<BucketDetail />} />
                <Route
                  path={RouteConstants.ROUTE_AMS_FILES_CREATE_NEW_BUCKET}
                  element={<BucketDetail />}
                />
                <Route path={RouteConstants.ROUTE_AMS_FOLDER_LIST} element={<NewFolder />} />
                <Route path={RouteConstants.ROUTE_AMS_FOLDER_NESTED_LIST} element={<NewFolder />} />
                <Route
                  path={RouteConstants.ROUTE_AMS_FILES_HISTORY}
                  element={<FileVersionHistoryList />}
                />
                <Route path={RouteConstants.ROUTE_AMS_FOLDER_NEW} element={<FolderDetails />} />
                <Route path={RouteConstants.ROUTE_AMS_FOLDER_VIEW} element={<FolderDetails />} />
                <Route
                  path={RouteConstants.ROUTE_AMS_FOLDER_NESTED_NEW}
                  element={<FolderDetails />}
                />
                <Route
                  path={RouteConstants.ROUTE_GDPR_CREATE}
                  element={<CreateNewRequest />}
                ></Route>
              </RoutesReactRouterDom>
            )}
          </AppInitialize>
        </Menu>
      )}
      {!loggedIn && (
        <RoutesReactRouterDom>
          <Route path="/auth" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="*"
            element={
              <Navigate to={loggedIn ? RouteConstants.ROUTE_OS_ORGANIZATION_LISTING : 'auth'} />
            }
          />
        </RoutesReactRouterDom>
      )}
    </Suspense>
  );
};

export const ModalButton = styled('div')`
  display: flex;
  justify-content: end;
`;
