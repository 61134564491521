import styled from 'styled-components';

export const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;

export const RowsPerPageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const CurrentResultsWrapper = styled.div``;
export const PageSelectionWrapper = styled.div``;

export const PagingText = styled.div`
  cursor: pointer;
  padding: 3px 8px;
  border: 0.3px solid black;
  border-radius: 5px;
  font-size: 12px;
  &:hover { 
     ${(props) => props.theme.bgPrimaryStyleColor}
     ${(props) => props.theme.bgHoverTextWhiteStyleColor}
    }
`;
