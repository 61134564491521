import { clinicBuilding } from '../../assets/components/svgs';
import sideBarPaths from '../constants/side-menu.constants';
import { IUserFeatures } from './models/Menu';
import { useAppSelector } from '../../app/hooks';
import {
  SideButton,
  SideGroupButton,
  LinkContainer,
  StyledSidebar,
  SideButtonTitle,
  AccordionItem,
  SideGroupSubButton,
  SideGroupButtonTitle,
  VersionDiv,
  AccordionUl
} from './MenuStyles';
import { useState } from 'react';
import { SideBarItemConfig } from '../../app/models/menu/sideBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetTemplateRoute } from '../../app/templateReducer';
import { useTheme } from '../../context/themeContext';
import env from '../../environment';
import { dropdown } from '../../assets/components/svgs';
import { RouteConstants } from '../../constants/RouteConstants';
import { getPermittedItems } from '../../util/appInitializeUtil';
import { AppConstants } from '../../constants/AppConstants';
import { filter, isEmpty, orderBy } from 'lodash';
import { resetGlobalSortSearch } from '../../app/tableReducer';

type Props = {
  sidebarIsActive: boolean;
  modalIsActive?: boolean;
};

export const appRoutes: any = {
  key1: ['organization', 'organization-list'],
  key2: ['admin', 'admin-list', 'profile'],
  Key3: ['templates', 'create-template'],
  key4: ['role-management'],
  key5: ['permission', 'permissionset-list'],
  key6: ['app-settings'],
  key8side: ['survey-question-list'],
  key9: ['survey-template', 'survey-template/list'],
  key10: ['mobile-configuration/list'],
  key11: ['consents', 'consents/fieldgroup'],
  key12: [RouteConstants.ROUTE_MDS_APPLN_LIST],
  key13: ['config-key-list'],
  key14: [
    'migration',
    RouteConstants.ROUTE_MIGRATION_EXPORT_LIST,
    RouteConstants.ROUTE_MIGRATION_IMPORT_LIST
  ],
  key15: [RouteConstants.ROUTE_UMS_PASSWORD_POLICY_LIST],
  key16: ['files', RouteConstants.ROUTE_AMS_FILES],
  key17: [RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_LIST],
  key18: ['patient-request', RouteConstants.ROUTE_GDPR_LIST],
  key19: ['patient-request', RouteConstants.ROUTE_GDPR_CREATE]
};
export const getSideBarConfigFromKey = (key: string, sideBarPath: any): SideBarItemConfig => {
  const [sideItem] = sideBarPath.filter((item: SideBarItemConfig) => item.key === key);
  return sideItem || null;
};

const Sidebar = ({ sidebarIsActive }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(0);
  let loaded = false;

  const rolePermissions = useAppSelector<any>((state: any) => {
    return state?.login?.rolePermissions;
  });

  const metaDataPermissions = useAppSelector(
    (state) => state.initialLoadData.metaDataPermissionSet
  );

  const mobileConfigCatalog = useAppSelector((state) => state.initialLoadData.mobileConfigCatalog);

  const adminFeatures = useAppSelector<IUserFeatures>((state: any) => {
    return JSON.parse(state?.login?.adminFeatures);
  });

  const permissions = useAppSelector((state) => state.initialLoadData.apiPermissions);

  const groupMenuItems = (menuItem: any) => {
    var temp: any = menuItem;

    let groupObject: any = {};
    temp.forEach((element: any) => {
      if (Object.keys(element).includes('groupName')) {
        if (Object.keys(groupObject).includes(element.groupName)) {
          groupObject[element.groupName]?.push(element);
        } else {
          groupObject[element.groupName] = [element];
        }
      } else {
        groupObject[element.name] = [element];
      }
    });
    let menuListArray: any = [];
    Object.keys(groupObject).forEach((element: any) => {
      const menuItems = orderBy(
        groupObject[element] || [],
        [AppConstants.DEFAULT_MENU_ITEM_KEY],
        [AppConstants.DEFAULT_SORT_ASC]
      );
      const groupSequence = menuItems[0]?.groupSequence;
      let tempItem = {
        name: element,
        menu: menuItems,
        key: groupSequence,
        groupSequence
      };
      menuListArray.push(tempItem);
    });
    var sortMenuList = menuListArray.sort((a: any, b: any) => {
      return a.key - b.key;
    });
    return sortMenuList;
  };

  let permitedMenuItems = getPermittedItems(
    adminFeatures?.features,
    //rolePermissions,
    metaDataPermissions.adminPortal,
    permissions,
    true
  );
  // permitedMenuItems = permitedMenuItems.filter((menuItem: any) => menuItem.key !== 'key10');

  if (isEmpty(mobileConfigCatalog)) {
    permitedMenuItems = filter(permitedMenuItems, (menuItem: any) => {
      return menuItem.name !== AppConstants.MOBILE_PLATFORM_SIDEBAR;
    });
  }

  const GroupItems = groupMenuItems(permitedMenuItems);

  const changeItemSelected = (i: number) => {
    setSelectedItem(i);
    loaded = !loaded;
  };

  const navigateToItem = async (item: any) => {
    const { path } = item;

    //TODO: This is a temporary hack to fix the issue
    if (path === '/templates') {
      dispatch(resetTemplateRoute());
    }
    navigate(item?.path);
  };

  const isGroupSelected = (item: any) => {
    var itemKey: any = item.map((ele: any) => {
      return appRoutes[ele.key];
    });

    return itemKey.flat(1).includes(location.pathname.split('/')[1]);
  };

  return (
    <StyledSidebar isActive={sidebarIsActive} theme={useTheme().theme}>
      <LinkContainer
        isActive={sidebarIsActive}
        className="scrollbar-track-{transparent} scrollbar-thin scrollbar-thumb-rounded scrollbar-track-rounded-full scrollbar-thumb-stone-300"
        id="sideNavParent"
      >
        {GroupItems?.map((menuData: any, menuIndexN: number) => (
          <AccordionItem key={`accordion-${menuData.name}`}>
            {menuData.groupSequence ? (
              // Group button
              <>
                <SideGroupButton
                  className="accordion-button focus:outline-none"
                  data-active-classes="text-red-600"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="dark"
                  data-bs-toggle="collapse"
                  data-bs-target={'#collapseSidenavEx' + menuIndexN}
                  aria-expanded="false"
                  aria-controls={'collapseSidenavEx' + menuIndexN}
                  role="sidebutton"
                  isActive={sidebarIsActive}
                  key={getSideBarConfigFromKey(menuData.name, sideBarPaths)?.key}
                  isSelected={isGroupSelected(menuData.menu)}
                  title={menuData.name}
                  theme={useTheme().theme}
                >
                  <div className="h-6 w-6">
                    {getSideBarConfigFromKey(menuData.name, sideBarPaths)?.icon || clinicBuilding}
                  </div>
                  <SideButtonTitle isActive={sidebarIsActive}>
                    {menuData.label ? menuData.label : menuData.name}
                  </SideButtonTitle>
                  <div
                    className="ml-auto w-fit"
                    style={{ display: sidebarIsActive ? 'block' : 'none' }}
                  >
                    {dropdown}
                  </div>
                </SideGroupButton>

                <AccordionUl
                  className="collapse"
                  id={'collapseSidenavEx' + menuIndexN}
                  // aria-labelledby={'sidenavEx' + menuIndexN}
                  data-bs-parent={'#sideNavParent'}
                >
                  {menuData.menu?.map((menu: any, menuIndex: number) => (
                    <li key={menu.name} className="relative">
                      <SideGroupSubButton
                        role="sidebutton"
                        isActive={sidebarIsActive}
                        isSelected={appRoutes[menu.key]?.includes(location.pathname.split('/')[1])}
                        onClick={() => {
                          dispatch(resetGlobalSortSearch());
                          changeItemSelected(menuIndex);
                          getSideBarConfigFromKey(menu.name, sideBarPaths) &&
                            navigateToItem(getSideBarConfigFromKey(menu.name, sideBarPaths));
                        }}
                        key={getSideBarConfigFromKey(menu.name, sideBarPaths)?.key}
                        title={menu.name}
                        theme={useTheme().theme}
                      >
                        <div
                          className="h-5 w-5"
                          style={{ visibility: sidebarIsActive ? 'hidden' : 'visible' }}
                        >
                          {getSideBarConfigFromKey(menu.name, sideBarPaths)?.icon || clinicBuilding}
                        </div>
                        <SideGroupButtonTitle isActive={sidebarIsActive}>
                          {menu.label ? menu.label : menu.name}
                        </SideGroupButtonTitle>
                      </SideGroupSubButton>
                    </li>
                  ))}
                </AccordionUl>
              </>
            ) : (
              // Single button
              <SideButton
                role="sidebutton"
                isActive={sidebarIsActive}
                isSelected={isGroupSelected(menuData.menu)}
                onClick={() => {
                  dispatch(resetGlobalSortSearch());
                  changeItemSelected(menuIndexN);
                  getSideBarConfigFromKey(menuData.name, sideBarPaths) &&
                    navigateToItem(getSideBarConfigFromKey(menuData.name, sideBarPaths));
                }}
                key={getSideBarConfigFromKey(menuData.name, sideBarPaths)?.key}
                title={menuData.name}
                theme={useTheme().theme}
              >
                <div className="h-6 w-6">
                  {getSideBarConfigFromKey(menuData.name, sideBarPaths)?.icon || clinicBuilding}
                </div>
                <SideButtonTitle isActive={sidebarIsActive}>
                  {menuData.menu[0].label || menuData.menu[0].name}
                </SideButtonTitle>
              </SideButton>
            )}
          </AccordionItem>
        ))}
      </LinkContainer>

      <VersionDiv isActive={sidebarIsActive}> V{env.version}</VersionDiv>
    </StyledSidebar>
  );
};

export default Sidebar;
