import React, { useEffect, useState } from 'react';
import InputField from '../../../shared/Fields/InputField';
import { useTranslation } from 'react-i18next';

interface NUMERICProps {
  formData: any;
  setFormData: any;
  editMode?: boolean;
  formError: any;
  editFromSurveyTemplate: boolean;
}
const NumericComponent = ({
  formData,
  setFormData,
  editMode,
  formError,
  editFromSurveyTemplate = false
}: NUMERICProps) => {
  const { t } = useTranslation();

  return (
    <>
      {editFromSurveyTemplate && (
        <>
          <div className="mt-6 flex w-full flex-row">
            <span>Set initial Value to:</span>
          </div>
          <div className="mt-3 flex w-full flex-row">
            <InputField
              className="w-full"
              type="number"
              id="max-word-count"
              label=""
              placeholder="Enter Initial Value"
              fieldKey="initial"
              required={false}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              disabled={editMode}
            />
          </div>
        </>
      )}
    </>
  );
};

export default NumericComponent;
