import { useEffect, useState } from 'react';
import DotMenu, { Options } from '../../primitives/dotMenu/DotMenu';
import Modal from '../../shared/ModalPortal/Modal';
import ToastNotification from '../../Reusable/ToastNotification';
import { MenuConstants } from '../../../constants/MenuConstants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../../constants/AppConstants';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { getMigrationExportJob, getMigrationImportJob } from '../../../http/migration-service';
import { getFailureMessage } from '../../../util/ErrorUtil';
import { setToastData } from '../../../app/toastReducer';
import ActionDropdown from '../../primitives/dropdowns/ActionDropdown';
import { RouteConstants } from '../../../constants/RouteConstants';
import { HTTP_STATUS } from '../../../http/constants/http.status';

interface MigrationMenuProps {
  className?: string;
  iconSize?: string;
  migrationJob: any;
  setIsLoading: Function;
  dropdownMenu?: boolean;
  jobType: string;
  setEdit?: Function;
  reTrigger?: Function;
  viewLog?: Function;
}

const MigrationContextMenu: React.FC<MigrationMenuProps> = ({
  iconSize = '',
  migrationJob,
  className = '',
  setIsLoading,
  dropdownMenu = false,
  jobType,
  setEdit = () => {},
  reTrigger = () => {},
  viewLog = () => {}
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const options: Array<any> = [];
  const { id, status, migrationDataPath = '', migrationLogPath = '' } = migrationJob;

  const [selectedOption, setSelectedOption] = useState<Options | null>();

  const negativeOption = (label: any) => {
    return <div className="flex text-[#D73027]">{label}</div>;
  };

  if (dropdownMenu) {
    options.push({
      display: `${t('T_MODIFY_MIGRATION_JOB')}`,
      key: MenuConstants.MENU_JOB_MODIFY,
      action: (data: any) => {
        setEdit(true);
      }
    });
  }

  if (['completed', 'success'].includes(status)) {
    options.push({
      display: `${t('T_VIEW_LOG')}`,
      key: MenuConstants.MENU_VIEW_LOG,
      action: (data: any) => {
        downloadLog(id, migrationLogPath, jobType);
      }
    });
    options.push({
      display: `${t('T_DONWLOAD_ZIP_FILE')}`,
      key: MenuConstants.MENU_DOWNLOAD_ZIP,
      action: () => {
        downloadJobZip(id, migrationDataPath, jobType);
      }
    });
  }

  if (
    ['failed', 'unknown', 'abandoned'].includes(status) &&
    jobType === AppConstants.MIGRATION_JOB_EXPORT
  ) {
    options.push({
      display: `${t('T_RE_TRIGGER')}`,
      key: MenuConstants.MENU_JOB_RETRIGGER,
      action: (data: any) => {
        reTrigger(data);
      }
    });
  }

  if (
    jobType === AppConstants.MIGRATION_JOB_IMPORT &&
    ['failed', 'unknown', 'abandoned'].includes(status)
  ) {
    options.push({
      display: `${t('T_VIEW_LOG')}`,
      key: MenuConstants.MENU_VIEW_LOG,
      action: (data: any) => {
        downloadLog(id, migrationLogPath, jobType);
      }
    });
    options.push({
      display: `${t('T_DONWLOAD_ZIP_FILE')}`,
      key: MenuConstants.MENU_DOWNLOAD_ZIP,
      action: () => {
        downloadJobZip(id, migrationDataPath, jobType);
      }
    });
  }

  if (['starting', 'stopping', 'unknown', 'started'].includes(status)) {
    options.push({
      display: `${t('T_VIEW_LOG')}`,
      key: MenuConstants.MENU_VIEW_LOG,
      action: (data: any) => {
        downloadLog(id, migrationLogPath, jobType);
      }
    });
  }

  if (['stopping', 'stopped'].includes(status)) {
    options.push({
      display: `${t('T_START')}`,
      key: MenuConstants.MENU_JOB_START,
      action: (data: any) => {}
    });

    options.push({
      display: negativeOption(`${t('T_CANCEL')}`),
      key: MenuConstants.MENU_JOB_CANCEL,
      action: (data: any) => {
        console.log('Delete Export job: ', data);
      }
    });
  }

  const [toasterMessage, setToasterMessage] = useState('');
  const [isToastActive, setIsToastActive] = useState(false);
  const [toasterType, setToasterType] = useState<any>('success');

  const downloadJobZip = async (id: number, filePath: string, type: string = 'IMPORT') => {
    if (filePath) {
      window.open(filePath);
    } else {
      let response;

      if (type === 'IMPORT') {
        response = await getMigrationImportJob(id);
      } else {
        response = await getMigrationExportJob(id);
      }

      const { data: jobData, status } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        const { migrationDataPath: path = '' } = jobData;
        if (path) {
          window.open(path);
        } else {
          dispatch(
            setToastData({
              isToastActive: true,
              toastMessage: t('T_MIGRATION_JOB_DOWNLOAD_NOT_FOUND'),
              toastType: 'error'
            })
          );
        }
      } else {
        let error = getFailureMessage(jobData);
        dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
      }
    }
    setIsLoading(false);
  };

  const downloadLog = async (id: number, filePath: string, type: string = 'import') => {
    // let flag = ['starting', 'stopping', 'unknown', 'started'].includes(status);
    if (filePath) {
      // if (flag) {
      viewLog(filePath);
      // } else {
      //   window.open(filePath);
      // }
    } else {
      setIsLoading(true);
      let response;

      if (type === 'IMPORT') {
        response = await getMigrationImportJob(id);
      } else {
        response = await getMigrationExportJob(id);
      }

      const { data: jobData, status } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        const { migrationLogPath: path = '' } = jobData;
        if (path) {
          // if (flag) {
          viewLog(path);
          // } else {
          //   window.open(path);
          // }
        } else {
          dispatch(
            setToastData({
              isToastActive: true,
              toastMessage: t('T_MIGRATION_JOB_LOG_NOT_FOUND'),
              toastType: 'error'
            })
          );
        }
      } else {
        let error = getFailureMessage(response);
        dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <ToastNotification
        message={toasterMessage}
        isActive={isToastActive}
        setIsActive={setIsToastActive}
        status={toasterType}
      />
      <div>
        {dropdownMenu ? (
          <ActionDropdown
            options={options}
            onSelectedOptionChange={(value: any) => {
              value.action(value);
            }}
            value={selectedOption}
            defaultLabel={'Manage Export Job'}
          />
        ) : (
          <DotMenu
            options={options}
            onSelectedOptionChange={(value) => {
              setSelectedOption(value);
              value.action(value);
            }}
            value={selectedOption}
            containerClassName={className}
            iconSize={iconSize}
          />
        )}
      </div>
    </>
  );
};

export default MigrationContextMenu;
