import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { deleteTemplate, getNotificationTemplates } from '../../http/notification-services';
import { useNavigate, useParams } from 'react-router-dom';
import { setTemplateData, setTemplateStep } from '../../app/templateReducer';
import BiButton from '../primitives/buttons/BiButton.primitive';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setToastData } from '../../app/toastReducer';

import { getLoggedInOrg } from '../../util/admin-utils';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getFailureMessage } from '../../util/ErrorUtil';
import { AppConstants } from '../../constants/AppConstants';
import { useQuery } from '../../hooks/queryParams';
import { getOrganization } from '../../http/organization-services';
import { RouteConstants } from '../../constants/RouteConstants';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import Checkbox from '../shared/Fields/Checkbox';
import { cloneDeep, filter, findIndex, flatMap, map, property } from 'lodash';
import { deleteManifestItem, setManifest } from '../../app/migrationExportReducer';
import { ActionButtons } from '../Reusable/ActionButton';
import moment from 'moment';
import TemplateFilter from './TemplateFilter';
import { useDidMountEffect } from '../../util/customHooks';
import {
  isGlobalSelected,
  isOrgSpecificSelected,
  removeOrgData,
  toggleGlobalCheckbox,
  toggleGlobalOverrideCheckbox,
  toggleOrgCheckbox,
  toggleOrgOverrideCheckbox
} from '../Migration/migrationUtil';
import AddCircle from '../../assets/addCircle.svg';
import { setTableOptions } from '../../app/templateTableReducer';

export interface filterConfigProps {
  type: string;
  name: string;
}

export const initNotificationSchema = (arr: any, cb: Function) => {
  const returnedArr: any = [];

  arr.forEach(function (value: any) {
    const obj = {
      category: '',
      subject: '',
      // owner: '',
      createdOn: moment(value?.createdOn).format(AppConstants.DEFAULT_DATE_FORMAT),
      createdBy: value?.createdBy,
      updatedOn: moment(value?.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT),
      lastModifiedBy: value?.updatedBy,
      type: '',
      id: '',
      data: '' as any,
      subjectKey: 1,
      contentKey: 0,
      description: '',
      fromEmail: '',
      fromName: ''
    };

    obj.category = value.category;
    obj.description = value?.description || '';
    if (value.channel === 'sms') {
      // obj.subject = value.description;
      obj.contentKey = value.params.findIndex(
        (elem: { key: string }) => elem.key === 'sms_content'
      );
    } else if (value.channel === 'email') {
      obj.contentKey = value.params.findIndex(
        (elem: { key: string }) => elem.key === 'email_content'
      );
      obj.subjectKey = value.params.findIndex(
        (elem: { key: string }) => elem.key === 'email_subject'
      );
      obj.subject = obj.subjectKey != -1 ? value.params[obj.subjectKey]?.value : '';
    } else if (value.channel === 'push_notification') {
      obj.contentKey = value.params.findIndex(
        (elem: { key: string }) => elem.key === 'push_notification_content'
      );
      obj.subjectKey = value.params.findIndex(
        (elem: { key: string }) => elem.key === 'push_notification_subject'
      );
      obj.subject = value.params[obj.subjectKey]?.value || '';
    }
    // obj.owner = value.createdBy ? value.createdBy : '';
    obj.type = value.channel;
    obj.id = value.id;
    obj.data = value;

    obj.fromEmail = value?.configParams?.fromEmail || '';
    obj.fromName = value?.configParams?.fromName || '';

    returnedArr.push(obj);
  });

  if (cb) {
    cb(returnedArr);
  }
};

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;
/**
 * @parentId notifications
 * @manager Notification Templates
 * @overview Lists all the notification templates associated with an organization.
 *   <section>
 *       <p>
 *           Notification template listing page lists all the notification templates asscociated with an organization. User can navigate to notification template listing page by clicking <b>Notification Templates</b> navigation menuitem under the <strong>Platform Core</strong> menu group of admin portal left sidebar.
 *           By Default notification template listing page will display all the notification templates of user logged-in organization in flat table hireachry with pagination support & templates(s) are sorted by created-on field timestamp in descending order. The Default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer.
 *           Datagrid footer will also display the current page and total number of pages available based on the choosen page size & notification templates data. User can switch to next/previous pages using the <b>Next</b> & <b>Previous</b> buttons enabled based on the content in the data grid footer.
 *       </p>
 *       <br>
 *       <p>
 *           Notification(s) can be filtered based on the template name using the search field provided above the notification template listing data grid. Admin user(s) with <b>notification-service.template.create</b> API role permission can create new Notification Template by clicking on <b>Create New Template</b> button provided on the notification template listing page toolbar,
 *           which will navigate user to notification template creation page. <b>Create New Template</b> button will be shown based on logged in user role <b>notification-service.template.create</b> API permissions as described below.
 *       </p>
 *       <br/>
 *       <p>
 *           Notification templates specifc to organization can be listing by selecting <b>View Templates</b> in organization context menu or from organization details page <b>Manage Organization</b> drop down menu.
 *           Datagrid will display below properties of each notification template as columns. These columns are fixed in width and can't be hidden, changed or re-ordered.
 *           <br/>
 *           <ul>
 *               <li>Name</li>
 *               <li>Subject</li>
 *               <li>Type</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *       </p>
 *       <p>
 *          Sorting of notification template(s) is only supported on <i>Name</i> and <i>Last Modified On</i> columns, However the default sort of the listing page is based on <b>Created On</b> field with descending sort order. The breadcrumb on the adminportal toolbar will reflect organization name for which templates are being listed.
 *          Clicking on notification template rowitem will drill down user to template details page which will display notification template page in view only mode.
 *       </p>
 *     </section>
 *
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes possibile failure status code information while listing notification templates.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>NS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>NS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td>None</td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings, platform services & role API permissions required for the functioning of notification template(s) listing page.</p>
 *       <br/>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for notification template listing page.</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Notification Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>Organization Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>Access Control Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for notification template listing page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/notifications/templates</td>
 *               <td>GET</td>
 *               <td>notification-service.template.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{id}</td>
 *               <td>GET</td>
 *               <td>organization-service.organization.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/roles/{id}</td>
 *               <td>GET</td>
 *               <td>accesscontrol-service.role.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/notifications/templates</td>
 *               <td>POST</td>
 *               <td>otification-service.template.create</td>
 *               <td>No</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for motifcation template listing page</p>
 *   </section>
 */

interface TemplateListProps {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const TemplateList = ({ type = 'listing', toggleModal = () => {} }: TemplateListProps) => {
  const query = useQuery();
  const params = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [pagingOpts, setPagingOpts] = useState(null);
  const [size, setSize] = useState(
    type === 'listing' ? AppConstants.DEFAULT_PAGE_SIZE : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
  );
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedOrgItems, setSelectedOrgItems] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(AppConstants.DEFAULT_PAGE);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const [rows, setRows] = useState([]);
  const [mapLoading, setMapLoading] = useState(0);
  const [userProfiles, setUserProfiles] = useState([]);
  const step = useAppSelector((state) => state?.templateData?.step);

  const loginOrgId = getLoggedInOrg()?.organizationId;

  const [formFilterData, setFormFilterData] = useState<filterConfigProps>({
    type: '',
    name: ''
  });

  let userMap: any = new Map();
  const { t } = useTranslation();

  const manifestTemplateData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.globalTemplates
  );
  const manifestOrgTemplateData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.orgTemplates
  );

  const apiPermissions: any = useAppSelector((state) => state.initialLoadData?.apiPermissions) || {};
  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);


  useEffect(() => {
    const {
      tableFilters = '',
      searchText = '',
      currentPage = AppConstants.DEFAULT_PAGE,
      size = type === 'listing'
        ? AppConstants.DEFAULT_PAGE_SIZE
        : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
      sortName = AppConstants.DEFAULT_SORT_BY, //category
      sortType = AppConstants.DEFAULT_SORT_DESC //asc
    } = globalFilters['notification-list'] || {};
    
    const init = async () => {
      if(tableFilters !== ''){
        setFormFilterData(tableFilters);
      }else{
        callAPI(searchText, tableFilters, currentPage, size, sortName, sortType);
      }
      if (type === 'listing') {
        initBreadcrump();
      }
    };
    if (type === 'migrationPreview') {
      setIsLoading(false);
      return;
    }
    init();
  }, []);

  const getOrgId = () => {
    let id: any = params.id;
    if (!id) {
      const loggedInOrg = getLoggedInOrg();
      id = loggedInOrg?.organizationId;
    }
    return id;
  };

  const initBreadcrump = async () => {
    const response = await getOrganization(getOrgId());
    const { status, data } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { name } = data;
      dispatch(
        setURLPaths([
          {
            key: params?.id
              ? `${RouteConstants.ROUTE_TEMPLATE_LISTING}/${params?.id}`
              : `${RouteConstants.ROUTE_TEMPLATE_LISTING}`,
            label: `${t('T_NOTIFICATION_TEMPLATES')} > ${name}`
          }
        ])
      );
    }
  };

  const getFilters = (filterData: filterConfigProps) => {
    let filter: string[] = [];
    Object.entries(filterData).forEach(([key, value]) => {
      if (value) {
        if (key === 'type') {
          let tempVal = value.map((ele: any) => ele.key);
          filter.push(`channel=${tempVal.join(',')}`);
        } else {
          filter.push(`${key}=${value}`);
        }
      }
    });
    let [ele1] = filter;
    return ele1 === 'channel=' ? '' : filter.join('&');
  };

  const callAPI = async (
    name: string,
    tableFilters: filterConfigProps,
    page: number = AppConstants.DEFAULT_PAGE,
    size: number = type === 'listing' ? AppConstants.DEFAULT_PAGE_SIZE: AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
    sort: string = AppConstants.DEFAULT_SORT_BY, //category
    sortType: string = AppConstants.DEFAULT_SORT_DESC //asc
  ) => {
    const filter: string = getFilters(tableFilters);
    const paramOrgId = params.id;
    const ignore_org = Boolean(params.id);
    dispatch(
      setTableOptions({
        ['notification-list']: {
          searchText: name,
          currentPage: page,
          size,
          sortName: sort,
          sortType,
          tableFilters: tableFilters
        }
      })
    );
    const response = await getNotificationTemplates(
      name,
      page,
      size,
      sort,
      sortType,
      paramOrgId,
      ignore_org,
      false,
      filter
    );
    if (response?.data?.data && !response?.data?.data?.length) {
      noDataAvailableMessage(setRows, setSize, setPagingOpts, response.data.paging, t);
      setIsLoading(false);
    } else {
      setPagingOpts(response.data.paging);
      initNotificationSchema(response?.data?.data || [], (response: any) => {
        setRows(response);
        setIsLoading(false);
      });
    }
  };

  const createMap = async (data: any) => {
    var orgDetailsMap: any = new Map(data.map((i: any) => [i.owner, ''] as [string, string]));

    for (const [key, value] of orgDetailsMap.entries()) {
      if (userProfiles.length) {
        let pos = userProfiles
          .map(function (element: any) {
            return element.userId;
          })
          .indexOf(key);
        if (pos !== -1) {
          orgDetailsMap.set(key, userProfiles[pos]);
        }
      }
    }
    userMap = orgDetailsMap;
    setMapLoading(mapLoading + 1);
  };

  // useEffect(() => {
  //   createMap(userTemplateData);
  // }, [userData]);

  const templateSelector = (data: any, step: number) => {
    if (type === 'listing') {
      dispatch(setTemplateStep(step));
      dispatch(setTemplateData(data));
      navigate(`/templates/edit/${data.id}`);
    }
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sort: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC,
    filterData: any = '' 
  ) => {
    setTableValues(name, page, size, sort, sortType, filterData);
  };

  const setTableValues = (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = 'desc',
    filterData: any = ''
  ) => {
    setSize(size);
    setCurrentPage(page);
    setIsLoading(true);
    const filter = filterData !== '' ? filterData : globalFilters['notification-list'].tableFilter;
    callAPI(name, filter, page, size, sort, sortType);
  };

  const isCheckboxSelected = (data: any) => {
    const { orgId = '' } = data.data;

    if (orgId.length) {
      return isOrgSpecificSelected(selectedOrgItems, data, 'templates');
    } else {
      return isGlobalSelected(selectedItems, data);
    }
  };
  const toggleCheckbox = (data: any) => {
    const { id, orgId = '' } = data.data;

    if (orgId.length) {
      let arr = toggleOrgCheckbox(selectedOrgItems, data, 'templates');

      setSelectedOrgItems(arr);
    } else {
      let arr = toggleGlobalCheckbox(selectedItems, data);

      setSelectedItems(arr);
    }
  };

  const toggleOverWrite = (data: any) => {
    const { id, orgId = '' } = data.data;

    if (orgId.length) {
      let arr = toggleOrgOverrideCheckbox(manifestOrgTemplateData, data, 'templates');
      dispatch(setManifest({ key: 'orgTemplates', value: arr }));
    } else {
      dispatch(
        setManifest({
          key: 'globalTemplates',
          value: toggleGlobalOverrideCheckbox(manifestTemplateData, data)
        })
      );
      return;
    }
  };

  const templateHeaderBuilder = () => {
    let NOTIFICATION_HEADERS: Array<ITableHeader> = [
      {
        key: 'category',
        description: `${t('T_TEMPLATE_NAME')}`,
        sortEnabled: type !== 'migrationPreview',
        width: type === 'listing' ? '35%' : type === 'migrationPreview' ? '40%' : '45%',
        sortingInformation: {
          order: ''
        }
      },
      {
        key: 'subject',
        description: `${t('T_SUBJECT')}`,
        sortEnabled: false,
        width: ['listing', 'migrationPreview'].includes(type) ? '30%' : '35%',
        sortingInformation: {
          order: ''
        }
      },
      {
        key: 'type',
        description: `${t('T_TYPE')}`,
        sortEnabled: false,
        width: '10%',
        nastedData: true,
        render: (data: any) => {
          let channel = data['type'];
          let value: string = 'Email';
          if (channel) {
            const tempType = channel.trim().toLowerCase();
            if (tempType === 'sms') {
              value = 'SMS';
            } else if (tempType === 'push_notification') {
              value = 'PUSH';
            }
          }
          return value;
        },
        sortingInformation: {
          order: ''
        }
      }
    ];

    switch (type) {
      case 'listing':
        NOTIFICATION_HEADERS.push({
          key: 'createdOn',
          description: `${t('T_CREATED_ON')}`,
          sortEnabled: false,
          width: '15%',
          nastedData: false,
          sortingInformation: {
            order: ''
          }
        });

        NOTIFICATION_HEADERS.push({
          key: 'updatedOn',
          description: `${t('T_LAST_MODIFIED_ON')}`,
          sortEnabled: true,
          width: '15%',
          sortingInformation: {
            order: ''
          }
        });
        // TODO: Need clarity on sorting and showing user name
        // NOTIFICATION_HEADERS.push({
        //   key: 'lastModifiedBy',
        //   description: `${t('T_LAST_MODIFIED_BY')}`,
        //   sortEnabled: false,
        //   width: '15%',
        //   nastedData: false,
        //   sortingInformation: {
        //     order: ''
        //   }
        // });

        if (query.get('devmode')) {
          NOTIFICATION_HEADERS.push({
            key: 'buttons',
            description: `${t('T_ACTIONS')}`,
            sortEnabled: false,
            width: '13%',
            sortingInformation: {
              order: ''
            }
          });
        }
        break;

      case 'migrationSelection':
        NOTIFICATION_HEADERS.unshift({
          key: 'checked',
          description: '',
          sortEnabled: false,
          width: '10%',
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={isCheckboxSelected(data)}
                  onChange={() => {
                    toggleCheckbox(data);
                  }}
                  testID="addModuleCheckbox"
                />
              </>
            );
          }
        });

        break;
      case 'migrationPreview':
        NOTIFICATION_HEADERS.push(
          {
            key: 'overWrite',
            description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            nastedData: true,
            render: (data: any, index: any) => {
              return (
                <>
                  <Checkbox
                    className="mx-auto"
                    height="20px"
                    width="20px"
                    onChange={() => {
                      toggleOverWrite(data);
                    }}
                    checked={data?.overWrite}
                    testID="allowOverWriteCheckbox"
                  />
                </>
              );
            }
          },
          {
            key: 'buttons',
            description: t('T_ACTIONS'),
            sortEnabled: false,
            width: '10%',
            sortingInformation: {
              order: ''
            },
            styles: {
              justifyContent: 'center'
            }
          }
        );
        break;
    }

    return NOTIFICATION_HEADERS;
  };

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(templateHeaderBuilder());

  const removeNotification = async (index: number) => {
    const row: any = rows[type === 'listing' ? index : index + currentActivePage];
    if (row) {
      let error = false;
      const { id, category } = row;
      if (type === 'migrationPreview') {
        if (row?.data?.orgId?.length) {
          let arr: any = removeOrgData(selectedOrgItems, row, 'templates');
          dispatch(setManifest({ key: 'orgTemplates', value: arr }));
          return;
        } else {
          dispatch(deleteManifestItem({ key: 'globalTemplates', id: id }));
          return;
        }
      }

      let toastMessage = `${category} ${t('T_DELETED_SUCCESS_MESSAGE')}`;
      const response = await deleteTemplate(id);
      const { status } = response;
      if (status == HTTP_STATUS.HTTP_OK) {
        rows.splice(index, 1);
        setRows([...rows]);
      } else {
        error = !error;
        toastMessage = getFailureMessage(response);
      }
      dispatch(
        setToastData({
          toastMessage,
          isToastActive: true,
          toastType: error ? 'error' : 'success'
        })
      );
    }
  };

  useEffect(() => {
    if (manifestOrgTemplateData?.length) {
      setSelectedOrgItems(manifestOrgTemplateData);
    }
  }, [manifestOrgTemplateData]);

  useEffect(() => {
    setSelectedItems(manifestTemplateData);
  }, [manifestTemplateData]);

  useEffect(() => {
    if (type === 'migrationSelection') {
      setTableHeaders(templateHeaderBuilder());
    } else if (type === 'migrationPreview') {
      const mappedOrgItems = selectedOrgItems.length
        ? flatMap(map(selectedOrgItems, property('dependentArtifactConfigurations.templates')))
        : [];
      let mergedItems: any = [...selectedItems, ...mappedOrgItems];
      setTableHeaders(templateHeaderBuilder());
      setRows(mergedItems);
      setIsLoading(false);
    }
  }, [selectedItems, selectedOrgItems]);

  useEffect(() => {
    if (type === 'migrationPreview') {
      setCurrentActivePage(currentPage * size - size);
    }
  }, [currentPage]);

  const saveSelection = () => {
    dispatch(setManifest({ key: 'globalTemplates', value: selectedItems }));
    if (selectedOrgItems.length) {
      dispatch(setManifest({ key: 'orgTemplates', value: selectedOrgItems }));
    }
    toggleModal();
  };

  useDidMountEffect(() => {
    const {
      searchText = '',
      currentPage = AppConstants.DEFAULT_PAGE,
      size = type === 'listing'
        ? AppConstants.DEFAULT_PAGE_SIZE
        : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
      sortName = AppConstants.DEFAULT_SORT_BY, //category
      sortType = AppConstants.DEFAULT_SORT_DESC //asc
    } = globalFilters['notification-list'] || {};
    refreshTableData(searchText, currentPage, size, sortName, sortType, formFilterData);
  }, [formFilterData]);

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <CustomersTableContainer className={`${type !== 'listing' && '!pb-0'}`}>
          {type === 'listing' ? (
            <MenuButtonsPortal>
              {apiPermissions[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_CREATE] ? (
                <BiButton
                  className={'flex flex-row bg-primary text-white'}
                  type="button"
                  disabled={!apiPermissions[AppConstants.PERMISSION_NOTIFICATION_TEMPLATE_CREATE]}
                  onClick={() => {
                    const orgId = params?.id || loginOrgId;
                    navigate(`/create-template/${orgId}`);
                  }}
                >
                  <img src={AddCircle} className="mr-2 w-6 text-center"></img>
                  {t('T_CREATE_NEW_TEMPLATE')}
                </BiButton>
              ) : null}
            </MenuButtonsPortal>
          ) : (
            ''
          )}

          <TemplateFilter filterConfig={formFilterData} setFilterConfig={setFormFilterData} />

          <TemplateTable
            tableClassName={`${type === 'listing' && '!h-[98%]'}`}
            key={mapLoading}
            tableData={
              type === 'migrationPreview'
                ? rows.slice(currentActivePage, currentActivePage + size)
                : rows
            }
            size={size.toString()}
            setSize={setSize}
            currentPage={currentPage.toString()}
            templateSelector={templateSelector}
            onRefreshTableData={refreshTableData}
            tableHeaders={tableHeaders}
            setCurrentPage={setCurrentPage}
            setTableHeaders={setTableHeaders}
            containerClass="mt-0"
            searchPlaceholder={t('T_SEARCH_PLACEHOLDER_HINT') || ''}
            pagingData={pagingOpts}
            showDeleteButton={true}
            deleteOnClick={removeNotification}
            theme={useTheme().theme}
            isSearchable={type !== 'migrationPreview'}
            isLocalPaginator={type === 'migrationPreview'}
            totalDataLength={rows.length}
            isPaginated={type !== 'migrationPreview'}
            disablePageSize={type !== 'listing'}
            hasExternalCustomFilter={true}
            globalFilters={globalFilters}
            tablePage="notification-list"
          />
          {type === 'migrationSelection' ? (
            <div className="w-full">
              <ActionButtons
                primaryButtonLabel={t('T_SAVE')}
                primaryButtonAction={saveSelection}
                secondaryButtonLabel={t('T_CANCEL')}
                secondaryButtonAction={toggleModal}
                primaryButtonWidth="full"
                primaryButtonClass={'px-4'}
              />
            </div>
          ) : (
            ''
          )}
        </CustomersTableContainer>
      </LoaderContainer>
    </>
  );
};

export default TemplateList;
