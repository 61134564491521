import { isEqual } from 'lodash';
import { AppConstants } from '../../constants/AppConstants';
import { getRole } from '../../http/access-management-service';
import { setToastData } from '../../app/toastReducer';

import {
  createSystemSetting,
  getSettingById,
  getSystemSettings,
  updateSystemSetting
} from '../../http/configuration-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  createMetadataInstances,
  getMetadataInstances,
  updateMetadataInstance
} from '../../http/metadata-service';
import { Category, formatApplicationPrivileges } from '../../util/RoleUtil';

//flag 1->System Settings flag 2->Metadata Instance //flag 0->Both

export async function createPermissionSets(
  flag: number,
  systemSettingBody: any,
  metadataInstanceBody: any
) {
  switch (flag) {
    case 1:
      const response = await createSystemSetting(systemSettingBody);
      return response;
    case 2:
      const instanceResponse = await createMetadataInstances(
        metadataInstanceBody.id,
        metadataInstanceBody.body
      );
      return instanceResponse;
    case 0:
      let successResponse: any = [];
      const systemSettingResponse = await createSystemSetting(systemSettingBody);
      if (systemSettingResponse.status === HTTP_STATUS.HTTP_CREATED) {
        successResponse.push(systemSettingResponse);
      }
      if (metadataInstanceBody?.id) {
        const newInstanceResponse = await createMetadataInstances(
          metadataInstanceBody?.id,
          metadataInstanceBody.body
        );
      }
      return successResponse;
  }
}

export async function loadApplicationFeatures(
  permissionSetData: any,
  sortedFeature: any,
  applicationModelCategory: any,
  systemSettingBodyCategory: any
) {
  let tempSolutionRole: any, returnObj: any;
  const roleData = await getRole(permissionSetData?.value?.roles?.[0]?.roleId);
  const privilages = formatApplicationPrivileges(sortedFeature);
  const payload = {
    key: permissionSetData?.value?.roles?.[0]?.mapping?.key,
    dataType: AppConstants.DATA_TYPE_JSON,
    categories: [applicationModelCategory?.id],
    description: null,
    value: {
      privileges: privilages?.length ? privilages : [],
      roleName: roleData?.data?.name
    }
  };
  const response: any = await createSystemSetting(payload);
  tempSolutionRole = response.data;
  returnObj.solutionRole = response.data;
  if (response.status === HTTP_STATUS.HTTP_OK || response.status === HTTP_STATUS.HTTP_CREATED) {
    const payload2 = {
      ...systemSettingBodyCategory,
      value: {
        ...systemSettingBodyCategory.value,
        organizationId: permissionSetData?.organizationId,
        roles: permissionSetData.value.roles.length
          ? [
              {
                ...permissionSetData.value.roles[0],
                mapping: {
                  id: response?.data?.id,
                  key: permissionSetData?.value?.roles?.[0]?.mapping?.key
                }
              }
            ]
          : []
      }
    };
    const response2 = await updateSystemSetting(permissionSetData.id, payload2);
    if (response2.status === HTTP_STATUS.HTTP_OK) {
      returnObj.applicationFeatureError = '';
    }
    systemSettingBodyCategory.value.roles[0].mapping = {
      id: response?.data?.id,
      key: permissionSetData?.value?.roles?.[0]?.mapping?.key
    };
  }
  return returnObj;
}

export const updateOtherSettings = async (
  settingObj: any,
  originalSettingObj: any,
  changedSetting: any
) => {
  if (settingObj?.length) {
    settingObj.forEach((settings: any) => {
      const resp = Array.isArray(originalSettingObj)
        ? originalSettingObj?.some((obj: any) => {
            return isEqual(settings, obj);
          })
        : '';
      if (!resp) {
        changedSetting.push(settings);
      }
    });

    const updateResponse = await Promise.all(
      changedSetting?.map((settingId: any) => {
        const payload = { ...settingId, categories: [settingId?.categories[0]?.id] };
        return updateSystemSetting(settingId.id, payload);
      })
    );
  }
};

export async function updateCategoryPermissionSet(
  tempSolutionRole: any,
  permissionSetData: any,
  settingObj: any,
  originalSettingObj: any,
  systemSettingBody: any,
  sortedFeature: any,
  triggerEvent?: any,
  permissionSetInstances?: any,
  systemSettingBodyCategory?: any
) {
  let changedSetting: any = [],
    returnObj: any = {
      contentChanged: false,
      data: {},
      isPermissionSetUpdated: false
    };
  if (settingObj?.length) {
    settingObj.forEach((settings: any) => {
      const resp = Array.isArray(originalSettingObj)
        ? originalSettingObj?.some((obj: any) => {
            return isEqual(settings, obj);
          })
        : '';
      if (!resp) {
        changedSetting.push(settings);
      } else {
      }
    });

    const updateResponse = await Promise.all(
      changedSetting?.map((settingId: any) => {
        const payload = { ...settingId, categories: [settingId?.categories[0]?.id] };
        return updateSystemSetting(settingId.id, payload);
      })
    );
  }
  const response = await updateSystemSetting(permissionSetData?.id, systemSettingBodyCategory);

  if (response.status == HTTP_STATUS.HTTP_OK) {
    returnObj.isPermissionSetUpdated = true;
    if (permissionSetData.value.roles.length) {
      const privileges = formatApplicationPrivileges(sortedFeature);
      let updateAppRole: any;

      updateAppRole = {
        ...tempSolutionRole,
        categories: tempSolutionRole.categories.map((category: Category) => category.id as string)
      };

      updateAppRole.value.privileges = privileges;

      const updateApplicationMappedResponse = await updateSystemSetting(
        tempSolutionRole.id,
        updateAppRole
      );
      if (updateApplicationMappedResponse.status === HTTP_STATUS.HTTP_OK) {
        await triggerEvent(sortedFeature);

        returnObj.contentChanged = true;
        returnObj.data = {
          ...response.data,
          role: {
            ...updateApplicationMappedResponse.data,
            name: updateApplicationMappedResponse.data.value.rolename
          }
        };
      }
    } else {
      returnObj.contentChanged = true;
      returnObj.data = response.data;
    }
    await updatePermissionSetMetadataInstance(
      permissionSetInstances,
      permissionSetData,
      systemSettingBody,
      sortedFeature,
      triggerEvent,
      settingObj,
      originalSettingObj
    );
  }
  return returnObj;
}

export const checkPermissionSetMetadataExists = async (id: any, name: any) => {
  let updateInstance: any = [];
  const permissionSetInstanceList = await getMetadataInstances(id);
  if (permissionSetInstanceList.status === HTTP_STATUS.HTTP_OK) {
    const {
      data: { data: list = [] }
    } = permissionSetInstanceList;
    updateInstance = list.filter((item: any) => item.attributes.name === name);
  }
  return updateInstance;
};

export const updatePermissionSetMetadataInstance = async (
  permissionSetObject: any,
  permissionSetData: any,
  systemSettingBody: any,
  sortedFeature: any,
  triggerEvent: Function,
  settingObj: any,
  originalSettingObj: any,
  permissionSetId?: any,
  flag: number = 0
) => {
  let updateInstance: any = [];
  if (flag === 0) {
    if (permissionSetObject?.id) {
      updateInstance = await checkPermissionSetMetadataExists(
        permissionSetObject?.id,
        permissionSetData?.value?.name
      );
      if (updateInstance.length) {
        const updateInstanceResponse = await updateMetadataInstance(
          permissionSetObject?.id,
          updateInstance[0]?.id,
          systemSettingBody
        );
      }
    }
  } else if (flag === 2) {
    let returnObj: any = {
      contentChanged: false,
      data: {},
      isPermissionSetUpdated: false
    };
    const updateSettingResponse = await updateOtherSettings(settingObj, originalSettingObj, []);
    const response2 = await updateMetadataInstance(
      permissionSetObject?.id,
      permissionSetId,
      systemSettingBody
    );
    if (response2.status == HTTP_STATUS.HTTP_OK) {
      returnObj.isPermissionSetUpdated = true;
      if (permissionSetData.attributes.roles.length) {
        await triggerEvent(flag, sortedFeature);
        returnObj.contentChanged = true;
        returnObj.data = response2.data;
        // const appRoleResponse = await getSystemSettings(
        //   AppConstants.CATEGORY_APPLICATION_MODEL,
        //   AppConstants.DATA_TYPE_JSON,
        //   AppConstants.DEFAULT_PAGE,
        //   AppConstants.MAXIMUM_PAGE_SIZE,
        //   []
        // );
        // if (appRoleResponse.status === HTTP_STATUS.HTTP_OK) {
        //   let updateAppRole: any, tempSolutionRole: any;
        //   const privileges = formatApplicationPrivileges(sortedFeature);
        //   const {
        //     data: { data: applicationModelList = [] }
        //   } = appRoleResponse;

        //   tempSolutionRole = applicationModelList.filter(
        //     (appModel: any) => appModel.value.rolename === permissionSetData?.attributes?.roles?.[0]
        //   );

        //   if (!tempSolutionRole.length) {
        //     const roleData = await getRole(permissionSetData?.attributes?.roles?.[0]);
        //     if (roleData.status === HTTP_STATUS.HTTP_OK) {
        //       tempSolutionRole = applicationModelList.filter(
        //         (appModel: any) => appModel.value.rolename === roleData?.data?.name
        //       );
        //     }
        //   }
        //   if (tempSolutionRole?.length) {
        //     updateAppRole = {
        //       ...tempSolutionRole?.[0],
        //       categories: tempSolutionRole?.[0]?.categories?.map(
        //         (category: Category) => category?.id as string
        //       )
        //     };
        //     updateAppRole.value.privileges = privileges;
        //     const updateApplicationMappedResponse = await updateSystemSetting(
        //       tempSolutionRole?.[0]?.id,
        //       updateAppRole
        //     );
        //     if (updateApplicationMappedResponse.status === HTTP_STATUS.HTTP_OK) {

        //     }
        //   } else {
        //     returnObj.contentChanged = true;
        //     returnObj.data = response2.data;
        //   }
        // }
      } else {
        returnObj.data = response2.data;
      }
    }
    return returnObj;
  }
};

export async function updatePermissionSet(
  flag: number,
  systemSettingsData: any,
  permissionSetInstanceData: any
) {
  const {
    permissionSetData,
    sortedFeature,
    systemSettingBodyCategory,
    settingObj,
    originalSettingObj,
    solutionRole
  } = systemSettingsData;
  const { permissionSetInstances, systemSettingBody, permissionSetId, triggerEvent } =
    permissionSetInstanceData;
  switch (flag) {
    case 0:
      const response3 = await updateCategoryPermissionSet(
        solutionRole,
        permissionSetData,
        settingObj,
        originalSettingObj,
        systemSettingBody,
        sortedFeature,
        triggerEvent,
        permissionSetInstances,
        systemSettingBodyCategory
      );
      return response3;
      break;
    case 2:
      const response = await updatePermissionSetMetadataInstance(
        permissionSetInstances,
        permissionSetData,
        systemSettingBody,
        sortedFeature,
        triggerEvent,
        settingObj,
        originalSettingObj,
        permissionSetId,
        flag
      );
      return response;
      break;
    default:
      break;
  }

  // const response3 = await updateCategoryPermissionSet(
  //   solutionRole,
  //   permissionSetData,
  //   settingObj,
  //   originalSettingObj,
  //   systemSettingBody,
  //   sortedFeature,
  //   triggerEvent,
  //   permissionSetInstances,
  //   systemSettingBodyCategory
  // );
  // return response3;
}

export async function loadPermissionSetList(
  flag: number,
  systemSettingsData: any,
  permissionSetInstanceData: any,
  sort: string[] = [AppConstants.DEFAULT_SORT_BY, AppConstants.DEFAULT_SORT_DESC]
) {
  switch (flag) {
    case 1:
      const { category, page, size } = systemSettingsData;
      const response: any = await getSystemSettings(
        category,
        AppConstants.DATA_TYPE_JSON,
        page,
        size,
        sort
      );
      return { flag, response };
    case 2:
      const {
        permissionSetInstances,
        page: currentPage,
        size: currentSize,
        name = ''
      } = permissionSetInstanceData;
      const labels = [];
      if (name) {
        labels.push(name);
      }
      const instanceResponse = await getMetadataInstances(
        permissionSetInstances?.id,
        labels,
        currentPage,
        currentSize,
        sort,
      );
      return { flag, response: instanceResponse };
  }
}

export const loadOtherSettings = async (settings: Array<any> = []) => {
  let successResponses: any = [],
    rejectResponses: any = [],
    categoriesKeys: any = {};

  settings.forEach((setting: any) => {
    if (setting?.categories?.[0]) {
      categoriesKeys[setting?.categories?.[0]] = categoriesKeys[setting?.categories?.[0]]
        ? [...categoriesKeys[setting?.categories?.[0]], setting?.key]
        : [setting?.key];
    }
  });
  const categorySystemSettingResponse = await Promise.all(
    Object.keys(categoriesKeys).map((category: any) =>
      getSystemSettings(category, '', AppConstants.DEFAULT_PAGE, AppConstants.MAXIMUM_PAGE_SIZE)
    )
  );
  const responses = await Promise.all(
    settings.map((setting: any) => {
      if (setting?.id && !setting?.categories?.[0]) {
        return getSettingById(setting?.id);
      }
    })
  );

  categorySystemSettingResponse.forEach((response: any) => {
    if (response?.status === HTTP_STATUS.HTTP_OK) {
      response.data.data.forEach((setting: any) => {
        if (categoriesKeys[setting?.categories?.[0]?.name]?.includes(setting.key)) {
          successResponses.push(setting);
        }
      });
    }
  });

  responses.forEach((response: any) => {
    if (response && response?.status === HTTP_STATUS.HTTP_OK) {
      successResponses.push(response.data);
    } else if (response) {
      rejectResponses.push(response.data.issues[0].details);
    }
  });
  return { successResponses, rejectResponses };
};

export const getApplSolnFeatures = async (webPortal:any, setCollectData:any, collectdata:any, dispatch:any) => {
  if (!webPortal) {
    const applFeaturesResp = await fetchApplFeaturesFromConfig(dispatch);
    const applFeatures = applFeaturesResp
      ? JSON.stringify(applFeaturesResp)
      : JSON.stringify({ value: { features: [] } });

    sessionStorage.setItem(AppConstants.SESSION_SOLUTION_KEY, applFeatures);
    setCollectData({ ...collectdata, applicationFeatures: JSON.parse(applFeatures) });
  } else {
    const applFeatures = await fetchApplFeaturesFromMetaData(webPortal.id, dispatch);

    sessionStorage.setItem(
      AppConstants.SESSION_SOLUTION_KEY,
      JSON.stringify({ value: { features: applFeatures } })
    );
    setCollectData({
      ...collectdata,
      applicationFeatures: { value: { features: applFeatures } }
    });
  }
}

export const fetchApplFeaturesFromConfig = async (dispatch: any) => {
    let applicationFeatures = {};
    const response = await getSystemSettings(
      AppConstants.CATEGORY_APPLICATION_CONFIG,
      AppConstants.DATA_TYPE_JSON,
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );
    const { status, data } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: settingList = [] } = data;
      [applicationFeatures] = settingList.filter(
        (setting: any) => setting.key == AppConstants.SOLUTION_FEATURES
      );
    } else {
      dispatch(
        setToastData({
          toastMessage: response.data.issues[0].details,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    return applicationFeatures
}

export const fetchApplFeaturesFromMetaData = async (id: string, dispatch: any) => {
  const response = await getMetadataInstances(
    id,
    [],
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );
  const { status: status, data: data } = response;
  let features: any;
  if (status === HTTP_STATUS.HTTP_OK) {
    const { data: featureList = [] } = data;
    features = featureList.map((feature: any) => feature.attributes);
  } else {
    dispatch(
      setToastData({
        toastMessage: response.data.issues[0].details,
        isToastActive: true,
        toastType: 'error'
      })
    );
  }

  return features;
}