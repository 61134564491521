import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICustomer {
  parentId: string;
  id: string;
  name: string;
  phone: string;
  fax: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  type?: string;
}

export interface ITemplate {
  id: string;
  category: string;
}

interface ICustomerFormState {
  customer: ICustomer;
  step: number; // TODO: PUT FIXED CONSTANT OPTIONS: "NEW_CUSTOMER_OVERLAY" | "NEW_CUSTOMER_CONFIRMATION_OVERLAY" | "CUSTOMER_FORM" | "CUSTOMER_ACTIVATION"
  template: ITemplate;
  roles: string[];
  contract?: string;
  isFormValid: boolean;
}

type ISetCustomer = PayloadAction<Partial<ICustomer>>;

type ISetStep = PayloadAction<number>;

type ISetType = PayloadAction<string>;

type ISetTemplate = PayloadAction<ITemplate>;

type ISetRole = PayloadAction<string[]>;

type ISetIsFormValid = PayloadAction<boolean>;

const initialState: ICustomerFormState = {
  customer: {
    parentId: '',
    id: '',
    name: '',
    type: 'T_HEALTH_SYSTEM',
    phone: '',
    fax: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  },
  step: 1,
  template: {
    id: '',
    category: ''
  },
  roles: [],
  isFormValid: false
};

export const customerFormSlice = createSlice({
  name: 'customerForm',
  initialState,
  reducers: {
    setCustomer: (state: ICustomerFormState, action: ISetCustomer) => ({
      ...state,
      customer: {
        ...state.customer,
        ...action.payload
      }
    }),
    setStep: (state: ICustomerFormState, action: ISetStep) => ({
      ...state,
      step: action.payload
    }),
    setCustomerType: (state: ICustomerFormState, action: ISetType) => ({
      ...state,
      customer: {
        ...state.customer,
        type: action.payload
      }
    }),
    unsetCustomerType: (state: ICustomerFormState) => ({
      ...state,
      customer: {
        ...state.customer,
        type: undefined
      }
    }),
    setTemplate: (state: ICustomerFormState, action: ISetTemplate) => ({
      ...state,
      template: action.payload
    }),
    setRoles: (state: ICustomerFormState, action: ISetRole) => ({
      ...state,
      roles: action.payload
    }),
    setIsFormValid: (state: ICustomerFormState, action: ISetIsFormValid) => ({
      ...state,
      isFormValid: action.payload
    }),
    resetCustomer: () => initialState
  }
});

export const {
  setCustomer,
  setStep,
  setCustomerType,
  unsetCustomerType,
  setTemplate,
  setRoles,
  setIsFormValid,
  resetCustomer
} = customerFormSlice.actions;

export default customerFormSlice.reducer;
