// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { Children, useEffect, useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import InputField from '../shared/Fields/InputField';
import { HTTP_STATUS } from '../../http/constants/http.status';
import SelectField from '../shared/Fields/SelectField';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { AppConstants } from '../../constants/AppConstants';
import { FileTypeConstant } from '../../constants/FileTypeConstant';
import Dropzone from '../shared/DragAndDrop/Dropzone';
import { useDidMountEffect } from '../../util/customHooks';
import { cloneDeep, isString, map } from 'lodash';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';

interface ConsentTemplateDetailsProps {
  setFileData: Function;
  fileData: any;
  editMode: boolean;
  options: any;
}

function ConsentAttachment({
  setFileData,
  fileData,
  editMode = false,
  options = new Map()
}: ConsentTemplateDetailsProps) {
  const { t } = useTranslation();
  const fileRef = useRef<any>(null);

  const [FILE, CONTENT, URL] = AppConstants.CONSENT_ATTACHMENT_TYPE_OPTIONS;
  const [languageOptions, setLanguageOptions] = useState<any>([]);
  const [languageCodes, setLanguageCodes] = useState<any>([]);
  const [fileIndex, setFileIndex] = useState<any>(null);

  const [formData, setFormData] = useState<any>([
    {
      attachmentType: FILE
    }
  ]);

  useEffect(() => {
    setLanguageOptions((Array.from(options.keys()) || []).sort());
    setLanguageCodes(Array.from(options.values() || []).map((item: any) => item.code));
  }, [options]);

  const [formErrorData, setFormErrorData] = useState<any>([
    {
      attachmentType: ''
    }
  ]);

  useEffect(() => {
    if (fileRef.current === null) {
      fileRef.current = document.createElement('input');
      fileRef.current.type = 'file';
      fileRef.current.style.display = 'none';
      document.body.appendChild(fileRef.current);
    }
    return () => {
      if (fileRef.current) {
        document.body.removeChild(fileRef.current);
      }
    };
  }, []);

  const updateFormData = (arr: any, isError: boolean = false) => {
    let output: any = [];

    arr.map((attachment: any) => {
      const { url, data, size } = attachment;
      if (!url && data && isString(data)) {
        output.push({ attachmentType: isError ? '' : CONTENT });
      } else if (url && !data && size === '0') {
        output.push({ attachmentType: isError ? '' : URL });
      } else {
        output.push({ attachmentType: isError ? '' : FILE });
      }
    });
    return output;
  };

  useEffect(() => {
    setFormData(updateFormData(fileData, false));
    setFormErrorData(updateFormData(fileData, true));
  }, [editMode, fileData.length]);

  const handleAttachmentChange = (index: number, attachment: any) => {
    const updatedAttachments = [...fileData];
    updatedAttachments[index] = attachment;
    setFileData(updatedAttachments);
    setFileIndex(null);
  };

  const handleRemoveAttachment = (index: number) => {
    const updatedAttachments = fileData.filter((_: any, i: number) => i !== index);
    setFileData(updatedAttachments);
  };

  const UploadContent = (attachment: any, index: number) => {
    return (
      <>
        <div className="my-[3px]">{t('T_CONTENT')}</div>
        <textarea
          id="content"
          className={`resize-vertical min-h-[100px] w-full rounded-md border-[0.5px] border-solid border-[#8c8c8c] p-[10px] `}
          rows={4}
          value={attachment.data}
          onChange={(e) => {
            handleAttachmentChange(index, { ...attachment, data: e.target.value });
          }}
          disabled={!editMode}
        />
      </>
    );
  };

  const UploadURL = (attachment: any, index: number) => {
    return (
      <>
        <InputField
          className="w-full"
          type="text"
          id={'url'}
          label={t('T_URL')}
          placeholder={t('T_URL')}
          fieldKey="url"
          required={false}
          formData={attachment}
          formError={formErrorData[index]}
          setFormData={(data: any) => {
            handleAttachmentChange(index, data);
          }}
          disabled={!editMode}
        />
      </>
    );
  };

  const getLanguageKey = (value: string) => {
    for (let [key, obj] of options) {
      if (value?.split('.')[0]?.split('_')[1]?.includes(obj.code)) {
        return key;
      }
    }
    return undefined;
  };

  const SelectFile = (selectedFile: File) => {
    const fileExtension: any = selectedFile.name.split('.').pop();

    let filejson: any = {
      data: selectedFile,
      size: selectedFile.size,
      title: selectedFile.name,
      contentType: AppConstants.ACCEPT_FILE_TYPE_FOR_CONSENT_ATTACHMENT.includes(
        `.${fileExtension.toLowerCase()}`
      )
        ? FileTypeConstant.FILE_TYPES.find((ele) => ele.type === selectedFile.type)?.apiType ||
          FileTypeConstant.WILDCARD_OPTION
        : FileTypeConstant.WILDCARD_OPTION
    };

    let languageKey = getLanguageKey(selectedFile.name);

    if (languageKey) {
      filejson.language = options.get(languageKey)?.display || '';
    }

    handleAttachmentChange(fileIndex, filejson);
  };

  const onDropFiles = (acceptedFiles: Array<any>) => {
    let toPushFiles: any = [];
    acceptedFiles.forEach((file: any) => {
      const fileExtension = file.name.split('.').pop();

      const newAttachment: any = {
        data: file,
        size: file.size,
        title: file.name,
        contentType: AppConstants.ACCEPT_FILE_TYPE_FOR_CONSENT_ATTACHMENT.includes(
          `.${fileExtension.toLowerCase()}`
        )
          ? FileTypeConstant.FILE_TYPES.find((ele) => ele.type === file.type)?.apiType ||
            FileTypeConstant.WILDCARD_OPTION
          : FileTypeConstant.WILDCARD_OPTION
      };

      let languageKey = getLanguageKey(file.name);

      if (languageKey) {
        newAttachment.language = options.get(languageKey)?.display || '';
      }

      toPushFiles.push(newAttachment);
    });
    setFileData([...fileData, ...toPushFiles]);
  };

  const UploadWithFile = (attachment: any, index: number) => {
    return (
      <div className="mt-6 flex content-baseline">
        {editMode && (
          <>
            <BiButton
              className={
                'mr-2 w-[200px] border border-solid border-primary-disabled py-1.5 text-primary'
              }
              type="button"
              onClick={(e: any) => {
                if (fileRef.current) {
                  setFileIndex(index);
                  fileRef.current.click();
                }
              }}
            >
              {attachment.data ? t('T_CHANGE_FILE') : t('T_SELECT_FILE')}
            </BiButton>
            <input
              type="file"
              key={index}
              ref={fileRef}
              data-testid={'consent-attachment'}
              onChange={(e) => {
                let file: any = e.target.files;
                const [file1] = file;
                SelectFile(file1);
              }}
              style={{ display: 'none' }}
            />
          </>
        )}
        {!editMode && attachment.url && (
          <>
            <BiButton
              className={
                'w-min-[200px] mr-2 border border-solid border-primary-disabled py-1.5 text-primary'
              }
              type="button"
              onClick={() => {
                window.open(attachment.url);
              }}
            >
              {t('T_DOWNLOAD_ATTACHMENT')}
            </BiButton>
          </>
        )}
        {formData[index]?.attachmentType === FILE && editMode && (
          <div className="leading-[40px]">
            File Size: <span className="font-light">{attachment.size || 0} bytes</span>
          </div>
        )}
      </div>
    );
  };

  const attachmentCard = (fileData: any, index: number) => {
    return (
      <div
        key={index}
        className="mt-4 w-full rounded-lg border p-4"
        style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
      >
        <div className="mb-2 flex items-center justify-between">
          <div className="text-lg font-bold">
            {t('T_ATTACHMENT')} {index + 1}
          </div>
          {editMode && (
            <div>
              <BiButton
                className="text-red-500"
                type="button"
                onClick={() => handleRemoveAttachment(index)}
              >
                {t('T_REMOVE_ATTACHMENT')}
              </BiButton>
            </div>
          )}
        </div>
        <div className="flex w-full">
          <div className="mr-2 w-1/2">
            <SelectField
              type="text"
              id="attachmenttype"
              label={t('T_ATTACHMENT_TYPE')}
              placeholder={t('T_ATTACHMENT_TYPE')}
              fieldKey={'attachmentType'}
              required={false}
              formData={formData[index]}
              setFormData={(data: any) => {
                if (formData[index]?.attachmentType !== data.attachmentType) {
                  let formDataLocal = [...formData];
                  formDataLocal[index] = data;
                  handleAttachmentChange(index, {
                    size: AppConstants.DEFAULT_CONSENT_ATTACHMENT_FILESIZE,
                    contentType: AppConstants.CONSENT_DEFAULT_ATTACHMENT_CONTENT_TYPE,
                    data: '',
                    url: '',
                    title: '',
                    language: ''
                  });
                  setFormData(formDataLocal);
                }
              }}
              formError={formErrorData[index]}
              options={AppConstants.CONSENT_ATTACHMENT_TYPE_OPTIONS}
              maxHeight={true}
              fontSize={'16px'}
              disabled={!editMode}
            />
          </div>
          <div className="ml-2 w-1/2">
            <PickList
              fieldKey={'language'}
              label={t('T_LANGUAGE')}
              fieldValues={['language']}
              selectedItems={fileData.language ? [fileData] : []}
              onSelectedChange={(data: any) => {
                let [{ language }] = data;
                if (fileData?.language !== language) {
                  let fileDataLocal = cloneDeep(fileData);
                  fileDataLocal.language = language;
                  handleAttachmentChange(index, fileDataLocal);
                }
              }}
              items={map((languageOptions || []).sort(), (item) => ({
                language: item
              }))}
              disabled={!editMode}
              readOnly={!editMode}
              hasMore={false}
              type={PICKLIST_TYPES.SINGLE}
              containerHeight={
                formData.length > 1 && formData.length - 1 !== index
                  ? '!max-h-[320px]'
                  : '!max-h-[200px]'
              }
            />
          </div>
        </div>

        <div className="w-full">
          <div className="mt-[10px] flex w-full">
            <div className="mr-2 w-1/2">
              <InputField
                className="w-full"
                type="text"
                id={'title'}
                label={t('T_TITLE')}
                placeholder={t('T_TITLE')}
                fieldKey="title"
                required={false}
                formData={fileData}
                formError={formErrorData[index]}
                setFormData={(data: any) => {
                  handleAttachmentChange(index, data);
                }}
                disabled={!editMode || formData[index]?.attachmentType === FILE}
              />
            </div>

            <div className="ml-2  w-1/2">
              <SelectField
                type="text"
                id="contenttype"
                label={t('T_CONTENT_TYPE')}
                placeholder={t('T_CONTENT_TYPE')}
                fieldKey={'contentType'}
                required={false}
                formData={fileData}
                formError={formErrorData[index]}
                setFormData={(data: any) => {
                  handleAttachmentChange(index, data);
                }}
                options={(FileTypeConstant.FILE_TYPES_OPTION || []).sort()}
                maxHeight={false}
                fontSize={'16px'}
                disabled={!editMode || formData[index]?.attachmentType === FILE}
              />
            </div>
          </div>

          <div className="mt-[10px] w-full">
            {formData[index]?.attachmentType === FILE && UploadWithFile(fileData, index)}
            {formData[index]?.attachmentType === CONTENT && UploadContent(fileData, index)}
            {formData[index]?.attachmentType === URL && UploadURL(fileData, index)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div>
        {/* <div className="mb-2 text-base">{t('T_DROP_DRAG_FILE_MSG')}</div> */}
        {editMode ? (
          <div className="mx-auto mb-2 w-full">
            <Dropzone
              onDrop={(acceptedFiles: any) => {
                onDropFiles(acceptedFiles);
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      {/* <div className="mb-2 mt-4 text-base">{t('T_ADD_MANUALLY')}</div> */}
      {fileData.length === formData.length
        ? fileData.map((attachment: any, index: number) => attachmentCard(attachment, index))
        : ''}
    </div>
  );
}

const Container = styled.div`
  width: 100%;
`;

export default ConsentAttachment;
