import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToastData } from '../../app/toastReducer';
import { LoginDropdownContainer, ToastContainer } from '../Auth/AuthStyles';
import MenuLogo from '../Menu/MenuLogo';
import ToastNotification from '../Reusable/ToastNotification';
import InitalRegister from './InitialRegister.component';
import RegisterStepTwo from './Register.component';
import RegisterCard from './RegisterCard';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';

export interface IPasswordPolicyResponse {
  id: string;
  name: string;
  pwdInHistory: number;
  pwdMaxAge: number;
  pwdMinLength: number;
  characterSets: Array<string>;
}

function Register() {
  const [position, setPosition] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toastData = useAppSelector((state) => state.toast);
  const dispatch = useAppDispatch();
  const urlSearchParam: any = window.location.search;
  const params = new Map(urlSearchParam.slice(1).split('&').map((kv: any) => kv.split('=')))
  const paramEmail = params && params.get('email');
  const paramCode = params && params.get('code');

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <MenuLogo>
          <LoginDropdownContainer>
          </LoginDropdownContainer>
        </MenuLogo>
        <ToastContainer>
          <ToastNotification
            message={toastData.toastMessage}
            isActive={toastData.isToastActive}
            setIsActive={() =>
              dispatch(
                setToastData({
                  toastMessage: '',
                  isToastActive: false,
                  toastType: null
                })
              )
            }
            status={toastData.toastType}
          />
        </ToastContainer>
        <RegisterCard position={position} setPosition={setPosition}>
          <InitalRegister position={position} setPosition={setPosition} paramEmail={paramEmail} />
          <RegisterStepTwo
            position={position}
            setPosition={setPosition}
            paramCode={paramCode}
            paramEmail={paramEmail}
            setIsLoading={setIsLoading}
          />
        </RegisterCard>
      </LoaderContainer>
    </>
  );
}

export default Register;
