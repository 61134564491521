import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
  name: 'adminData',
  initialState: {
    adminPayload: {} as any,
    orgId: ''
  },
  reducers: {
    setAdminData: (state, action) => {
      state.adminPayload = action.payload;
    },
    setOrganizationId: (state, action) => {
      state.orgId = action.payload;
    }
  }
});

export const { setAdminData, setOrganizationId } = adminSlice.actions;

export default adminSlice.reducer;
