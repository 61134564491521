// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from '../shared/ModalPortal/Modal';
import { Button } from '../Reusable/Shared';
import Stepper from '../Reusable/Stepper';
import BiButton from '../primitives/buttons/BiButton.primitive';
import {
  createSystemSetting,
  getCategories,
  getSystemSettings
} from '../../http/configuration-services';
import { AppConstants } from '../../constants/AppConstants';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getAppFeatureColumns, getAppFeatureRows } from '../../util/ColumnUtil';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToastData } from '../../app/toastReducer';
import ToastNotification from '../Reusable/ToastNotification';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import ApplicationSettings from '../AppSettings/ApplicationSettings';
import { normalizeKey } from '../../util/RoleUtil';
import { getLoggedInOrg } from '../../util/admin-utils';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';

import { find } from 'lodash';
import { createMetadataInstances, getMetadataInstances } from '../../http/metadata-service';
import { setPagingOpt, setPermissionSetList } from '../../app/permissionSetReducer';

import { getPermittedItems } from '../../util/appInitializeUtil';
import PasswordPolicy from './PasswordPolicy';
import { ActionButtons } from '../PermissionSet/NewPermissionSet';

const PasswordPolicyModal = ({ setNewDataLoaded, openModal, setOpenModal }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const toastData = useAppSelector((state) => state.toast);
  const { t } = useTranslation();

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <ToastNotification
          message={toastData.toastMessage}
          isActive={toastData.isToastActive}
          setIsActive={() =>
            dispatch(
              setToastData({
                toastMessage: '',
                isToastActive: false,
                toastType: null
              })
            )
          }
          status={toastData.toastType}
        />

        <Modal
          showHeader={true}
          showCloseIcon={true}
          width="85rem"
          headerText={t('T_NEW_PASSWORD_POLICY')}
          toggleVisiblity={(value: boolean) => {
            setOpenModal(false);
          }}
          isVisible={openModal}
          body={
            <div>
              <PasswordPolicy
                setNewDataLoaded={setNewDataLoaded}
                buttonActions={(saveAction: Function, disabled: boolean) => {
                  return (
                    <ActionButtons
                      primaryButtonLabel={t('T_CREATE')}
                      primaryButtonAction={saveAction}
                      secondaryButtonLabel={t('T_CANCEL')}
                      disabled={disabled}
                      secondaryButtonAction={() => {
                        setOpenModal(false);
                      }}
                    />
                  );
                }}
              ></PasswordPolicy>
            </div>
          }
        />
      </LoaderContainer>
    </>
  );
};

export default PasswordPolicyModal;
