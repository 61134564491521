import environment from '../environment';

export class AppConstants {
  static readonly DATA_TYPE_JSON = 'json';
  static readonly APPLICATION_NAME = environment.ENV_APPLICATION_NAME;

  static readonly DEFAULT_INACTIVITY_LOGOUT_TIME = environment.inactivityAutoLogoutTime;
  static readonly DEFAULT_INACTIVITY_WARNING_TIME = environment.inactivityWarningTime;

  static readonly ADMINPORTAL_COMMUNICATION_TEMPLATES = [
    {
      usage: 'ADMIN_Invitation',
      channel: 'email',
      category: 'ADMIN_Invitation'
    },
    {
      usage: 'Account_Deactivated',
      channel: 'email',
      category: 'Account_Deactivated'
    },
    {
      usage: 'ADMIN_Reset_Password',
      channel: 'email',
      category: 'ADMIN_Reset_Password'
    },
    {
      usage: 'Change_Password_Success',
      channel: 'email',
      category: 'Change_Password_Success'
    }
  ];
  static readonly SOLN_COMMUNICATION_TEMPLATES_CATEGORY = 'solution.communication.templates';
  static readonly USER_PROFILE_CONSENT_TEMPLATE_NAME = 'UserProfileConsentTemplate';
  static readonly USER_PROFILE_FIELD_GROUP_NAME = 'user profile';
  static readonly USER_PROFILE_CONSENT_GIVER_TYPE = 'USER';
  static readonly USER_PROFILE_CONSENT_RECEIVER_TYPE = 'ROLE';

  static readonly DEFAULT_PAGE = 1;
  static DEFAULT_PAGE_SIZE = 20;
  static readonly MAXIMUM_PAGE_SIZE = 1000;
  static DEFAULT_PAGE_SIZES = [5, 10, 20];
  static DEFAULT_MIGRATION_LOG_REFRESH_INTERVAL = 10000;
  static readonly MODULE_LIST_DEFAULT_PAGE_SIZE = 5;

  static readonly CONDITION_ANY = 'any';
  static readonly CONDITION_ALL = 'all';
  static readonly DEFAULT_TEXT_MAX_LENGTH = 255;

  static readonly DEFAULT_DATE_FORMAT = 'MMMM DD YYYY h:mm A';
  static readonly DEFAULT_METADATA_INSTANCE_DATE_FORMAT = 'YYYY-MM-DD';
  static readonly DEFAULT_METADATA_INSTANCE_UTC_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
  static readonly DEFAULT_METADATA_INSTANCE_TIME_FORMAT = 'h:mm A';

  static readonly DEFAULT_CAREPLAN_DATE_FORMAT = 'MMMM DD, YYYY';
  static readonly DEFAULT_CAREPLAN_TIME_FORMAT = 'h:mm A';

  static readonly DEFAULT_SORT_ASC = 'asc';
  static readonly DEFAULT_SORT_DESC = 'desc';
  static readonly DEFAULT_MENU_ITEM_KEY = 'label';
  static readonly ACCESS_CONTROL_API_PERMISSION_KEY = 'apiPermission';

  static readonly PAGE_LAYOUT_MONO = 1;
  static readonly PAGE_LAYOUT_DUAL = 2;
  static readonly PAGE_LAYOUT_TRI = 3;
  static readonly PAGE_LAYOUT_QUAD = 4;

  static readonly RULE_CONDITION_ALL = 'ALL';
  static readonly RULE_CONDITION_ANY = 'ANY';

  static readonly DISPLAY_INLINE = 'INLINE';
  static readonly DISPLAY_BLOCK = 'BLOCK';

  static readonly DEFAULT_TOAST_SUCCESS_TTL = 3000;
  static readonly DEFAULT_TOAST_ERROR_TTL = 6000;

  static readonly GCS_UPLOAD_ACTION = 'UPLOAD';
  static readonly GCS_UPLOAD_DOWNLOAD = 'DOWNLOAD';
  static readonly GCS_MAX_EXPIRATION_TIME = 1440;
  static readonly GCS_MIN_EXPIRATION_TIME = 10;

  static readonly MINIMUM_PASSWORD_EXPIRY = 0;
  static readonly MINIMUM_PASSWORD_HISTORY = 0;

  static readonly DEFAULT_MIN_PASSWORD_LENGTH = 6;
  static readonly DEFAULT_MAX_PASSWORD_EXPIRY = 90;
  static readonly DEFAULT_MIN_PASSWORD_HISTORY = 5;

  static readonly DEFAULT_SPECICAL_CHARS_IN_PASSWORD = 1;
  static readonly DEFAULT_NUMERIC_CHARS_IN_PASSWORD = 1;
  static readonly DEFAULT_LOWERCASE_CHARS_IN_PASSWORD = 1;
  static readonly DEFAULT_UPPERCASE_CHARS_IN_PASSWORD = 1;

  static readonly MAX_FILE_SIZE = 5120;
  static readonly ACCEPT_FILE_TYPE = ['.png', '.svg', '.jpg', '.jpeg'];
  static readonly ACCEPT_FILE_TYPE_FOR_CONSENT_ATTACHMENT = [
    '.png',
    '.svg',
    '.jpg',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
    '.bmp',
    '.svg',
    '.json',
    '.xml',
    '.pdf',
    '.txt'
  ];

  static readonly HEADER_CONTENT_TYPE = 'Content-Type';
  static readonly VALUE_APPLICATION_PDF = 'application/pdf';
  static readonly VALUE_APPLICATION_JSON = 'application/json';

  static readonly HEADER_CACHE_CONTROL = 'Cache-Control';
  static readonly VALUE_PUBLIC = 'public';
  static readonly VALUE_NO_CACHE = 'no-cache';
  static readonly CONTENT_DISPOSITION = 'Content-Disposition';

  static readonly HEADER_MAX_AGE = 'max-age';
  static readonly VALUE_MAX_AGE_MAXIMUM = 3600;
  static readonly VALUE_MAX_AGE_MINIMUM = 0;

  static readonly VALUE_TEXT_PLAIN = 'text/plain';

  static readonly DEFAULT_ASSETS_FOLDER_PATH = 'assets/';
  static readonly DEFAULT_CONTRACTS_FOLDER_PATH = 'contracts/';

  static readonly USER_ACCOUNT_STATUS_ACTIVE = 'active';
  static readonly USER_ACCOUNT_STATUS_INVITED = 'invited';
  static readonly USER_ACCOUNT_STATUS_REINVITED = 'reinvited';

  static readonly DEFAULT_SORT_BY = 'createdOn';
  static readonly DEFAULT_AMS_SORT_BY = 'createTime';
  static readonly DEFAULT_SORT_BY_NAME = 'name';
  static readonly DEFAULT_SORT_ORDER = this.DEFAULT_SORT_ASC;

  static readonly SESSION_ADMINPORTAL_KEY = 'adminFeatures';
  static readonly SESSION_SOLUTION_KEY = 'solutionFeatures';

  static readonly ORGANIZATION_TELECOM_SYSTEM_PHONE_KEY = 'phone';

  static readonly FIELD_GROUP_OBJECT_NAME = 'FieldGroup';
  static readonly FIELD_LAYOUT_OBJECT_NAME = 'FieldLayout';
  static readonly FIELD_METADATA_OBJECT_NAME = 'FieldMetaData';

  static readonly CATEGORY_ADMINPORTAL = 'AdminPortal';
  static readonly CATEGORY_PERMISSION_SETS = 'PermissionSets';
  static readonly CATEGORY_APPLICATION_CONFIG = 'Application_Config';
  static readonly CATEGORY_APPLICATION_SYSTEM = 'Application_System';
  static readonly CATEGORY_APPLICATION_MODEL = 'Application_Model';
  static readonly CATEGORY_COMMUNICATION_TEMPLATE = 'solution.communication.templates';
  static readonly CATEGORY_ORGANIZATION_SETTINGS = 'solution.organization.settings';
  static readonly CATEGORY_ASSET_MANAGEMENT_BUCKET_FILES = 'asset-management-service.bucket.files';
  static readonly CONSENT_ATTACHMENT_LANGUAGE_CATEGORY =
    'access.control.consent.template.default.values';
  static readonly CATEGORY_SOLUTION_CONFIG = 'Solution_Config';
  static readonly CATEGORY_USER_MGMT_INVITATION_POLICY = 'user.management.invitation.policy';
  static readonly CATEGORY_ACCESS_CONTROL_CONSENT_TEMPLATE_DEFAULT_VALUES =
    'access.control.consent.template.default.values';

  static readonly SOLUTION_ORG_TYPES_KEY = 'solution.organization.types';
  static readonly SOLUTION_CONFIGURATION_DEFAULTS = 'solution.configuration.defaults';
  static readonly SOLUTION_ORG_CREATION_POLICY_KEY = 'solution.organization.creation.policy';
  static readonly SOLUTION_ORG_INVITATION_POLICY_KEY = 'solution.organization.invitation.policy';
  static readonly SOLUTION_APPLICATION_SETTINGS = 'solution.application.settings';
  static readonly SOLUTION_ORG_ADDRESS_COUNTRY_DEFAULT_VALUES =
    'solution.organization.address.country.default.values';

  static readonly SOLUTION_FEATURES = `${this.APPLICATION_NAME}.features`;
  static readonly ADMINPORTAL_FEATURES = `${this.CATEGORY_ADMINPORTAL}.features`;
  static readonly SOLUTION_APPLICATION_CONTEXT_KEY = `application_context.definition.${this.APPLICATION_NAME}`;

  static readonly SOLUTION_ASSIGNMENT = 'assignment';
  static readonly SOLUTION_FEATURE_KEY = `${this.APPLICATION_NAME}.feature`;

  static readonly APPLICATION_DATA_CONSENT_TEMPLATES_KEY = 'data.consent.templates';
  static readonly APPLICATION_DATA_CONSENT_TEMPLATES_ELEMENT = 'dataConsentTemplates';
  static readonly APPLICATION_DATA_CONSENT_TEMPLATES_ELEMENT_TYPE = 'DataConsentTemplateRoleType';

  static readonly LEGAL_DOCUMENT_KEY = 'legal_document';
  static readonly LEGAL_DOCUMENT_ELEMENT = 'legalDocuments';
  static readonly LEGAL_DOCUMENT_ELEMENT_TYPE = 'RoleLegalDocument[]';

  static readonly APP_FEATURE_ASSIGNMENT = 'feature.assignment';
  static readonly FEATURE_ASSIGNMENT_ELEMENT = 'featureAssignment';
  static readonly FEATURE_ASSIGNMENT_TYPE = 'RoleFeatureAssignmentType';
  static readonly SOLUTION_ROLES = 'solution.roles';

  static readonly QUERY_PARAM_LEVEL = 'level';
  static readonly QUERY_PARAM_LEVEL_DIRECT = 'direct';
  static readonly QUERY_PARAM_LEVEL_DESENDANTS = 'descendants';
  static readonly QUERY_PARAM_IGNORE_PERMISSIONS = 'ignorePermissions';

  static readonly QUERY_PARAM_PAGE = 'page';
  static readonly QUERY_PARAM_SIZE = 'size';
  static readonly QUERY_PARAM_NAME = 'name';
  static readonly QUERY_PARAM_CATEGORY = 'category';
  static readonly QUERY_PARAM_SORT = 'sort';
  static readonly QUERY_PARAM_FILTERS = 'filters';
  static readonly QUERY_PARAM_INSTANCE_LABELS = 'instance-labels';
  static readonly QUERY_PARAM_WILDCARD = 'wildcard';
  static readonly QUERY_PARAM_OBJECT_NAMES = 'object-names';
  static readonly QUERY_PARAM_STATUSES = 'statuses';
  static readonly QUERY_PARAM_ROLEIDS = 'role-ids';
  static readonly QUERY_ORG_ID = 'org-id';
  static readonly QUERY_FIRST_NAME = 'first-name';

  static readonly SOLUTION_QUERY_PARAM_PARENT_ORG = 'parentOrg';

  static readonly SOLUTION_BANNER_IMG = 'solution.png';
  static readonly SOLUTION_BANNER_ICON = 'solution.ico';
  static readonly ADMIN_SPLASH_IMG = 'splash.png';

  // dev-dm-asset-management-service/assets/dms-roche/mobile/configuration

  static readonly ASSET_MANAGEMENT_SERVICE = 'asset-management-service';
  static readonly BASE_STORAGE_URL = `https://storage.googleapis.com/${environment.ENV_TENANT_NAME}-${this.ASSET_MANAGEMENT_SERVICE}/assets`;

  static readonly GLOBAL_MOBILE_CONFIG_FILE = 'configuration.json';
  static readonly CONFIGURATION_MOBILE = `configuration/${this.GLOBAL_MOBILE_CONFIG_FILE}`;
  static readonly MOBILE_CONFIGURATION_FOLDER = `${environment.ENV_SOLUTION_NAME}/mobile/configuration`;
  static readonly MOBILE_CONFIGURATION_PATH = `${environment.ENV_SOLUTION_NAME}/mobile/configuration/${this.GLOBAL_MOBILE_CONFIG_FILE}`;
  static readonly MOBILE_CONFIGURATION_IMAGES_PATH = `${this.MOBILE_CONFIGURATION_FOLDER}/images`;
  static readonly MOBILE_CONFIGURATION_LANGUAGE_BUCKET_PATH = `https://storage.googleapis.com/${environment.ENV_TENANT_NAME}-${this.ASSET_MANAGEMENT_SERVICE}/`;
  static readonly MOBILE_CONFIGURATION_LANGUAGE_BUCKET_FOLDER = `public/`;

  static readonly CONFIGURATION_MOBILE_GCS_FOLDER = `assets/${this.MOBILE_CONFIGURATION_FOLDER}`;
  static readonly CONFIGURATION_MOBILE_GCS_FILEPATH = `assets/${this.MOBILE_CONFIGURATION_PATH}`;

  static readonly SURVEY_MEDIA_PATH = `assets/${environment.ENV_SOLUTION_NAME}/survey/`;

  static readonly DEFAULT_NOTIFICATION_DELIVERY_METHOD = 'SMTP';
  static readonly DEFAULT_CHANGE_PASSWORD_SUCCESS_TEMPLATE = 'Change_Password_Success';

  static readonly LOGO_SOLUTION_URL = `${AppConstants.BASE_STORAGE_URL}/${environment.ENV_SOLUTION_NAME}/${this.SOLUTION_BANNER_IMG}`;
  static readonly LOGO_SPLASH_URL = `${AppConstants.BASE_STORAGE_URL}/${environment.ENV_SOLUTION_NAME}/${this.ADMIN_SPLASH_IMG}`;
  static readonly MOBILE_CONFIG_PUBLIC_DATA = `${AppConstants.BASE_STORAGE_URL}/${this.MOBILE_CONFIGURATION_PATH}`;

  static readonly TEMPLATE_ADMIN_INVITATION_PREFIX = 'ADMIN_';
  static readonly TEMPLATE_ADMIN_INVITATION_USAGE = 'ADMIN_Invitation';

  static readonly TASK_TRACKER_STATUS_FAILED = 'failed';
  static readonly TASK_TRACKER_STATUS_COMPLETE = 'complete';
  static readonly TASK_TRACKER_STATUS_INPROGRESS = 'in-progress';

  // Errro Codes
  static readonly INVITE_ADMIN_DUPLICATE_EMAIL_ERROR = 'UMS_PARAM_DUPLICATE';
  static readonly INTERNAL_SERVER_ERROR = 'UMS_INTERNAL_ERROR';
  static readonly INVITE_ADMIN_PARAM_NOT_EXIST = 'NS_PARAM_NOT_EXISTS';
  static readonly NOTIFICATION_DUPLICATE_NAME_ERROR = 'NS_PARAM_DUPLICATE';
  static readonly INVALID_PASSWORD_ERROR = 'UMS_PARAM_INVALID';

  static readonly AMS_PARAM_DUPLICATE_BUCKET = 'AMS_PARAM_DUPLICATE_BUCKET';

  /** API PERMISSIONS **/

  // User Management Service API's
  static readonly PERMISSION_UMS_LIST_USERS = 'user-management-service.user.list';
  static readonly PERMISSION_UMS_INVITE_USER = 'user-management-service.invitation.create';

  static readonly PERMISSION_UMS_PASSWORD_POLICY_LIST =
    'user-management-service.password-policy.list';
  static readonly PERMISSION_UMS_PASSWORD_POLICY_CREATE =
    'user-management-service.password-policy.create';
  static readonly PERMISSION_UMS_PASSWORD_POLICY_UPDATE =
    'user-management-service.password-policy.patch';
  static readonly PERMISSION_UMS_PASSWORD_POLICY_DELETE =
    'user-management-service.password-policy.delete';

  // Asset Management Service API's
  static readonly PERMISSION_AMS_LIST_FILES = 'asset-management-service.bucket.files';
  static readonly PERMISSION_AMS_CREATE_BUCKET = 'asset-management-service.bucket.create';
  static readonly PERMISSION_AMS_UPDATE_BUCKET = 'asset-management-service.bucket.patch';
  static readonly PERMISSION_AMS_GET_BUCKET = 'asset-management-service.bucket.get';
  static readonly PERMISSION_AMS_DELETE_BUCKET = 'asset-management-service.bucket.delete';
  static readonly PERMISSION_AMS_BUCKET_LIST = 'asset-management-service.bucket.list';
  static readonly PERMISSION_AMS_FILE_VERSION = 'asset-management-service.bucket.files.versions';
  static readonly PERMISSION_AMS_SIGNED_URL = 'asset-management-service.signed-url.create';
  static readonly PERMISSION_AMS_DOWNLOD_REDIRECTION =
    'asset-management-service.signed-url.create.redirect';
  static readonly PERMISSION_AMS_GET_FOLDER = 'asset-management-service.folder.get';
  static readonly PERMISSION_AMS_DELETE_FOLDER = 'asset-management-service.folder.delete';
  static readonly PERMISSION_AMS_UPDATE_FOLDER = 'asset-management-service.folder.patch';
  static readonly PERMISSION_AMS_DELETE_FOLDER_HARD = 'asset-management-service.folder.delete.hard';
  static readonly PERMISSION_AMS_DELETE_FILE = 'asset-management-service.file.delete';
  static readonly PERMISSION_AMS_DELETE_FILE_HARD = 'asset-management-service.file.delete.hard';
  static readonly PERMISSION_AMS_FILE_METADATA_UPDATE =
    'asset-management-service.file.metadata.update';

  // static readonly PERMISSION_NOTIFICATION_TEMPLATE_GET = 'notification-service.template.get';

  // Notification Service API's
  static readonly PERMISSION_NOTIFICATION_TEMPLATE_CREATE = 'notification-service.template.create';
  static readonly PERMISSION_NOTIFICATION_TEMPLATE_UPDATE = 'notification-service.template.update';
  static readonly PERMISSION_NOTIFICATION_TEMPLATE_LIST = 'notification-service.template.list';
  static readonly PERMISSION_NOTIFICATION_TEMPLATE_GET = 'notification-service.template.get';
  static readonly PERMISSION_NOTIFICATION_TEMPLATE_DELETE = 'notification-service.template.delete';

  // Organization Service API's
  static readonly PERMISSION_ORGNIZATION_CREATE = 'organization-service.organization.create';
  static readonly PERMISSION_ORGANIZATION_LIST = 'organization-service.organization.list';
  static readonly PERMISSION_ORGANIZATION_LIST_CHILDREN =
    'organization-service.organization.list.children';
  static readonly PERMISSION_ORGANIZATION_GET = 'organization-service.organization.get';
  static readonly PERMISSION_ORGANIZATION_UPDATE = 'organization-service.organization.update';
  static readonly PERMISSION_ORGANIZATION_PATCH = 'organization-service.organization.patch';

  // Access Control Service API's
  static readonly PERMISSION_ROLE_CREATE = 'accesscontrol-service.role.create';
  static readonly PERMISSION_ROLE_UPDATE = 'accesscontrol-service.role.update';
  static readonly PERMISSION_ROLE_LIST = 'accesscontrol-service.role.list';
  static readonly PERMISSION_ROLE_GET = 'accesscontrol-service.role.get';
  static readonly PERMISSION_ROLE_PERMISSION_UPDATE = 'accesscontrol-service.permission.update';
  static readonly PERMISSION_ROLE_PERMISSION_CREATE = 'accesscontrol-service.permission.create';
  static readonly PERMISSION_ROLE_PERMISSION_PATCH = 'accesscontrol-service.permission.patch';
  static readonly PERMISSION_ROLE_ASSIGNMENT_GIVERS_LIST =
    'accesscontrol-service.consent.assignment.givers.list';

  // Configuration Service API's
  static readonly PERMISSION_CONFIG_SERVICE_SETTING_LIST = 'configuration-service.setting.list';
  static readonly PERMISSION_CONFIG_SERVICE_SETTING_DELETE = 'configuration-service.setting.delete';
  static readonly PERMISSION_CONFIG_SERVICE_CATEGORY_DELETE =
    'configuration-service.category.delete';
  static readonly PERMISSION_CONFIG_SERVICE_ORG_CATEGORY_DELETE =
    'configuration-service.organization-category.delete';

  static readonly PERMISSION_CONFIG_SERVICE_ORG_SETTING_LIST =
    'configuration-service.organization-setting.list';
  static readonly PERMISSION_CONFIG_SERVICE_ORG_CATEGORIES_LIST =
    'configuration-service.organization-category.list';
  static readonly PERMISSION_CONFIG_KEY_SETTING_UPDATE = 'configuration-service.setting.update';
  static readonly PERMISSION_CONFIG_KEY_SETTING_CREATE = 'configuration-service.setting.create';

  // Metadata Service API's
  static readonly PERMISSION_METADATA_APPN_LIST = 'metadata-service.metadata-application.list';
  static readonly PERMISSION_METADATA_APPN_GET = 'metadata-service.metadata-application.get';
  static readonly PERMISSION_METADATA_APPN_CREATE = 'metadata-service.metadata-application.create';
  static readonly PERMISSION_METADATA_APPN_DELETE = 'metadata-service.metadata-application.delete';
  static readonly PERMISSION_METADATA_APPN_UPDATE = 'metadata-service.metadata-application.update';

  static readonly PERMISSION_METADATA_OBJECT_LIST = 'metadata-service.metadata-object.list';
  static readonly PERMISSION_METADATA_OBJECT_GET = 'metadata-service.metadata-object.get';
  static readonly PERMISSION_METADATA_OBJECT_CREATE = 'metadata-service.metadata-object.create';
  static readonly PERMISSION_METADATA_OBJECT_DELETE = 'metadata-service.metadata-object.delete';
  static readonly PERMISSION_METADATA_OBJECT_UPDATE = 'metadata-service.metadata-object.update';
  static readonly PERMISSION_METADATA_OBJECT_PATCH = 'metadata-service.metadata-object.patch';

  static readonly PERMISSION_METADATA_INSTANCE_LIST = 'metadata-service.metadata-instance.list';
  static readonly PERMISSION_METADATA_INSTANCE_GET = 'metadata-service.metadata-instance.get';
  static readonly PERMISSION_METADATA_INSTANCE_CREATE = 'metadata-service.metadata-instance.create';
  static readonly PERMISSION_METADATA_INSTANCE_UPDATE = 'metadata-service.metadata-instance.update';
  static readonly PERMISSION_METADATA_INSTANCE_DELETE = 'metadata-service.metadata-instance.delete';

  // Survey Service API's
  static readonly PERMISSION_QUESTIONNAIRE_CREATE = 'survey-service.questionnaire.create';
  static readonly PERMISSION_QUESTIONNAIRE_LIST = 'survey-service.questionnaire.list';
  static readonly PERMISSION_QUESTIONNAIRE_GET = 'survey-service.questionnaire.get';
  static readonly PERMISSION_QUESTIONNAIRE_UPDATE = 'survey-service.questionnaire.update';

  // Migration Service API's
  static readonly PERMISSION_MIGRATION_EXPORT_LIST = 'migration-service.migration-export.list';
  static readonly PERMISSION_MIGRATION_IMPORT_LIST = 'migration-service.migration-import.list';
  static readonly PERMISSION_MIGRATION_EXPORT_GET = 'migration-service.migration-export.get';
  static readonly PERMISSION_MIGRATION_IMPORT_GET = 'migration-service.migration-import.get';
  static readonly PERMISSION_MIGRATION_EXPORT_CREATE = 'migration-service.migration-export.create';
  static readonly PERMISSION_MIGRATION_IMPORT_CREATE = 'migration-service.migration-import.create';

  // CarePlan Service API's
  static readonly PERMISSION_CAREPLAN_TEMPLATE_LIST = 'careplan-service.careplan.list';
  static readonly PERMISSION_CAREPLAN_TEMPLATE_CREATE = 'careplan-service.careplan.list';
  static readonly PERMISSION_CAREPLAN_TEMPLATE_GET = 'careplan-service.careplan.get';
  static readonly PERMISSION_CAREPLAN_TEMPLATE_UPDATE = 'careplan-service.careplan.update';
  static readonly PERMISSION_CAREPLAN_TEMPLATE_PATCH = 'careplan-service.careplan.patch';
  static readonly PERMISSION_CAREPLAN_TEMPLATE_DELETE = 'careplan-service.careplan.delete';

  // Metadata Object Types
  static readonly OBJECTS_SURVEY_QUESTIONS = 'SurveyQuestions';
  static readonly OBJECTS_SURVEY_QUESTION_TYPES = 'QuestionTypes';
  //static readonly OBJECTS_SURVEY_QUESTION_TYPES = 'QuestionTypes_Dev';

  // Admin Microservice
  static readonly ACCESS_CONTROL_SERVICE = 'Access Control Service';

  static readonly BI_INTERNAL_ROLES = ['APPLICATION_CLIENT', 'BUSINESS_USER', 'BI_DATA_IMEX_USER'];

  static readonly CONFIG_KEY_DATA_TYPES = [
    'String',
    'Integer',
    'Float',
    'Boolean',
    'DateTime',
    'Date',
    'Json'
  ];

  static readonly CONFIG_KEY_DATA_TYPES_PICKLIST = [
    { key: 'String', value: 'String' },
    { key: 'Integer', value: 'Integer' },
    { key: 'Float', value: 'Float' },
    { key: 'Boolean', value: 'Boolean' },
    { key: 'DateTime', value: 'DateTime' },
    { key: 'Date', value: 'Date' },
    { key: 'Json', value: 'Json' }
  ];

  static readonly SURVEY_STATUS_TYPES = [
    { key: 'draft', value: 'Draft', display: 'Draft', name: 'Draft' },
    { key: 'active', value: 'Active', display: 'Active', name: 'Active' },
    { key: 'retired', value: 'Retire', display: 'Retire', name: 'Retire' },
    { key: 'unknown', value: 'Unknown', display: 'Unknown', name: 'Unknown' }
  ];

  static readonly DEFAULT_CONSENT_TYPE = 'DENY';
  static readonly DEFAULT_CONSENT_ATTACHMENT_FILESIZE = '0';
  static readonly CONSENT_PURPOSE_KEY = 'ConsentPurpose';
  static readonly CONSENT_ACTION_KEY = 'ConsentAction';
  static readonly CONSENT_CATEGORY_KEY = 'ConsentCategory';
  static readonly CONSENT_SCOPE_KEY = 'ConsentScope';

  static readonly SYSTEM_CONFIG_CONSENT_PURPOSE_KEY =
    'access.control.consent.template.purpose.default.codes';
  static readonly SYSTEM_CONFIG_CONSENT_ACTION_KEY =
    'access.control.consent.template.action.default.codes';
  static readonly SYSTEM_CONFIG_CONSENT_CATEGORY_KEY =
    'access.control.consent.template.category.default.codes';
  static readonly SYSTEM_CONFIG_CONSENT_TYPE_KEY =
    'access.control.consent.template.type.default.values';
  static readonly SYSTEM_CONFIG_CONSENT_STATUS_KEY =
    'access.control.consent.template.status.default.values';
  static readonly SYSTEM_CONFIG_CONSENT_SCOPE_KEY =
    'access.control.consent.template.scope.default.codes';
  static readonly SYSTEM_CONFIG_CONSENT_ATTACHMENT_LANGUAGE_KEY =
    'access.control.consent.template.attachment.common.languages';

  static readonly CONSENT_TYPE_PERMIT = 'PERMIT';
  static readonly CONSENT_TYPE_DENY = 'DENY';
  static readonly CONSENT_STATUS_OPTIONS = [
    'DRAFT',
    'PROPOSED',
    'ACTIVE',
    'REJECTED',
    'INACTIVE',
    'ENTERED_IN_ERROR'
  ];
  static readonly CONSENT_ATTACHMENT_TYPE_OPTIONS = ['File', 'Content', 'URL'];
  static readonly CONSENT_TEMPLATE_STATUS_COLORS: any = {
    REJECTED: '#FEDDCD',
    INACTIVE: '#8C8C8C',
    ACTIVE: '#D9F2E8',
    PROPOSED: '#FEEFC7'
  };

  static readonly CONSENT_DEFAULT_ATTACHMENT_CONTENT_TYPE = 'WILDCARD';
  static readonly CONSENT_FIELDGROUP_OPTION_ALL = { key: 'all', value: 'all', label: 'all' };
  static readonly USER_LOCKED_OUT_MSG = 'User Locked Out.';
  static readonly PASSWORD_EXPIRED_MSG = 'Password is expired';

  static readonly METADATA_OBJECT_PERMISSION_SET = 'PermissionSet';
  static readonly METADATA_OBJECT_ADMIN_FEATURES = 'AdminPortal.features';
  static readonly METADATA_MOBILE_CATALOG = 'ConfigurationCatalog';
  static readonly EVENT_SOURCE = 'dm-admin-portal';
  static readonly EVENT_TYPE_CREATE = 'APPLICATION_ROLE_CREATED';
  static readonly EVENT_TYPE_DELETE = 'APPLICATION_ROLE_DELETED';
  static readonly EVENT_TYPE_UPDATE = 'APPLICATION_ROLE_UPDATED';

  static readonly MOBILE_PLATFORM_SIDEBAR = 'Mobile Platform';

  static readonly MAX_CONSENT_VERSION = 2147483647;
  static readonly MIN_CONSENT_VERSION = 1;

  static readonly MIGRATION_JOB_EXPORT = 'EXPORT';
  static readonly MIGRATION_JOB_IMPORT = 'IMPORT';
  static readonly MAX_LENGTH_IMPORT_NAME = 1024;
  static readonly MAX_LENGTH_IMPORT_DESCRIPTION = 4096;

  static readonly MAX_LENGTH_BUCKET_NAME = 63;
  static readonly MAX_LENGTH_BUCKET_DESCRIPTION = 4096;

  // Likert scale question options
  static readonly LIKERT_SCALE_OPTIONS = {
    'Custom Range': [],
    Frequency: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
    Quality: ['Very Poor', 'Poor', 'Fair', 'Good', 'Excellent'],
    Intensity: ['None', 'Very mild', 'Mild', 'Moderate', 'Severe'],
    Agreement: [
      'Strongly disagree',
      'Disagree',
      'Neither agree nor disagree',
      'Agree',
      'Strongly agree'
    ],
    Approval: ['Strongly disapprove', 'Disapprove', 'Neutral', 'Approve', 'Strongly approve'],
    Awareness: [
      'Not at all aware',
      'Slightly aware',
      'Moderately aware',
      'Very aware',
      'Extremely aware'
    ],
    Importance: [
      'Not at all important',
      'Slightly important',
      'Moderately important',
      'Very important',
      'Extremely important'
    ],
    Familiarity: [
      'Not at all familiar',
      'Slightly familiar',
      'Moderately familiar',
      'Very familiar',
      'Extremely familiar'
    ],
    Satisfaction: [
      'Not at all satisfied',
      'Slightly satisfied',
      'Moderately satisfied',
      'Very satisfied',
      'Extremely satisfied'
    ],
    Performance: [
      'Far below standards',
      'Below standards',
      'Meets standards',
      'Above standards',
      'Far above standards'
    ]
  };

  static readonly LIKERT_SCALE_FIELD_RANGE = ['1', '2', '3', '4', '5'];

  static readonly QUESTION_TYPE_GROUP = 'group';

  static readonly SURVEY_CONDITIONAL_OPTIONS_LIST = ['ANY', 'ALL'];
  static readonly SURVEY_CONDITIONAL_DEFAULT_OPTION = 'ANY';
  static readonly SURVEY_CONDITIONAL_COMPARATOR_LIST = [
    { value: '=', key: 'equal' },
    { value: 'exists', key: 'exists' },
    { value: '!=', key: 'not_equal' },
    { value: '<', key: 'less_than' },
    { value: '>', key: 'greater_than' },
    { value: '<=', key: 'less_than_equal' },
    { value: '>=', key: 'greater_than_equal' }
  ];

  static readonly SURVEY_CONDITONAL_BOOLEAN_VALUES = [
    { value: 'Yes', key: 'Yes' },
    { value: 'No', key: 'No' }
  ];
  static readonly SURVEY_CONDITIONAL_DEFAULT_COMPARATOR = { value: '=', key: 'equal' };

  static readonly SURVEY_CONDITIONAL_ALLOWED_QUE_TYPES = [
    'boolean',
    'single',
    'integer',
    'decimal',
    'bodyMapSingle'
  ];

  static readonly CAREPLAN_STATUS = [
    { key: 'draft', value: 'Draft' },
    { key: 'active', value: 'Active' },
    { key: 'on-hold', value: 'On-hold' },
    { key: 'revoked', value: 'Revoked' },
    { key: 'completed', value: 'Completed' },
    { key: 'entered-in-error', value: 'Entered-in-error' },
    { key: 'unknown', value: 'Unknown' }
  ];

  static readonly CAREPLAN_STATUS_DEFAULT = 'draft';

  static readonly CAREPLAN_INTENT = [
    { key: 'proposal', value: 'Proposal' },
    { key: 'plan', value: 'Plan' },
    { key: 'order', value: 'Order' },
    { key: 'option', value: 'Option' }
  ];

  static readonly CAREPLAN_COLLABORATORS_AUTHOR = [
    { key: 'organization', value: 'Organization' },
    { key: 'care_team', value: 'Care Team' },
    { key: 'patient', value: 'Patient' },
    { key: 'practitioner', value: 'Practitioner' },
    { key: 'practitionerRole', value: 'Practitioner Role' },
    { key: 'device', value: 'Device' },
    
  ];

  static readonly CAREPLAN_USER_PATIENT_ROLE_TYPE = 'Patient';
  static readonly CAREPLAN_USER_PRACTITIONER_ROLE_TYPE = 'Practitioner';
  static readonly CAREPLAN_DATE_FORMAT='YYYY-MM-DDTHH:mm:ssZ';

  static DEFAULT_COUNTRY_CODE = '+1';
}
