import { useEffect, useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import close from '../../../assets/close.svg';
import styled from 'styled-components';
import { Button } from '../../Reusable/Shared';
const modalRoot = document.querySelector('#backdrop-root') as HTMLElement;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.625rem;
  margin-top: auto;
  padding-right: 1.5rem;
`;

interface ModalProps {
  body?: ReactNode;
  customFooter?: ReactNode;
  isVisible: boolean;
  headerText: string;
  width: string;
  headerLeftIcon?: any;
  showCloseIcon?: boolean;
  toggleVisiblity: Function;
  primaryButtonText?: string;
  primaryOnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primaryButtonWidth?: string;
  secondaryButtonText?: string;
  secondaryOnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  secondaryButtonWidth?: string;
  tertiaryButtonText?: string;
  tertiaryOnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tertiaryButtonWidth?: string;
  tertiaryButtonDisabled?: boolean;
  dangerButtonText?: string;
  dangerOnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dangerButtonWidth?: string;
  dangerButtonDisabled?: boolean;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  showHeader?: boolean;
  containerBackgroundColor?: string;
  headerBackgroundColor?: string;
  overflowVisible?: boolean;
  className?: string;
  parentOverflowProperty?: string;
  headerStyles?: string;
  zIndex?: string;
  twoButton?: boolean;
  buttonLayout?: '213' | '123'; //How Primary Secondary or Terinary Buttons will be displayed
}

const Modal = ({
  body,
  customFooter,
  isVisible,
  headerText,
  headerStyles = '',
  headerLeftIcon,
  showCloseIcon,
  toggleVisiblity,
  primaryButtonText,
  primaryOnClick,
  primaryButtonWidth,
  secondaryButtonText,
  secondaryOnClick,
  secondaryButtonWidth,
  tertiaryButtonText,
  tertiaryOnClick,
  tertiaryButtonWidth,
  tertiaryButtonDisabled = false,
  dangerButtonText,
  dangerOnClick,
  dangerButtonWidth,
  dangerButtonDisabled = false,
  width,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  headerBackgroundColor = '#FAFAFA',
  containerBackgroundColor = '#FFFFFF',
  showHeader = true,
  overflowVisible = true,
  className,
  parentOverflowProperty = '',
  zIndex = '10',
  twoButton = true,
  buttonLayout = '213'
}: ModalProps) => {
  const el = useRef(document.createElement('div'));
  useEffect(() => {
    const current = el.current;

    modalRoot?.appendChild(current);
    return () => void modalRoot?.removeChild(current);
  }, []);

  interface ButtonProps {
    type: 'primary' | 'secondary' | 'tertiary' | 'danger';
    onClick: any;
  }
  function ButtonComponent({ type, onClick }: ButtonProps) {
    return (
      <Button
        color={type}
        id={type}
        width={
          type === 'primary'
            ? primaryButtonWidth
            : type === 'secondary'
            ? secondaryButtonWidth
            : type === 'tertiary'
            ? tertiaryButtonWidth
            : type === 'danger'
            ? dangerButtonWidth
            : primaryButtonWidth
        }
        onClick={onClick}
        disabled={
          type === 'primary'
            ? primaryButtonDisabled
            : type === 'secondary'
            ? secondaryButtonDisabled
            : type === 'tertiary'
            ? tertiaryButtonDisabled
            : type === 'danger'
            ? dangerButtonDisabled
            : primaryButtonDisabled
        }
        classes="text-ellipsis overflow-hidden"
      >
        {type === 'primary'
          ? primaryButtonText
          : type === 'secondary'
          ? secondaryButtonText
          : type === 'tertiary'
          ? tertiaryButtonText
          : type === 'danger'
          ? dangerButtonText
          : primaryButtonText}
      </Button>
    );
  }

  return isVisible ? (
    createPortal(
      <div
        className={`fixed inset-0 z-${zIndex} flex ${
          parentOverflowProperty ? parentOverflowProperty : 'overflow-auto'
        } bg-gray-600/90`}
        datatest-id="backdropModal"
      >
        <div
          className={`relative m-auto w-full ${className ? className : ''}`}
          style={{ width: width }}
        >
          {/* Render Children */}
          <div
            className={`${
              overflowVisible ? 'overflow-visible' : ' overflow-hidden'
            } rounded shadow-xl`}
          >
            {showHeader && (
              <div
                className="flex h-16 items-center justify-between px-6"
                style={{ background: headerBackgroundColor }}
              >
                {/* Header */}
                <div className="flex flex-row items-center">
                  {headerLeftIcon &&
                    (typeof headerLeftIcon == 'string' ? (
                      <img src={headerLeftIcon} alt="headerLeftIcon" className="h-6" />
                    ) : (
                      headerLeftIcon
                    ))}
                  <div
                    className={`text-xl font-semibold ${
                      headerLeftIcon && 'ml-1.5'
                    } ${headerStyles}`}
                  >
                    {headerText}
                  </div>
                </div>
                {showCloseIcon && (
                  <img
                    src={close}
                    alt="headerLeftIcon"
                    className="h-5 cursor-pointer"
                    onClick={() => toggleVisiblity(!isVisible)}
                  />
                )}
              </div>
            )}
            {body && (
              <div className="px-6 pb-4 pt-3" style={{ background: containerBackgroundColor }}>
                {body}
              </div>
            )}{' '}
            {(customFooter || primaryButtonText || secondaryButtonText) && (
              <div className="pb-4 pt-3" style={{ background: '#FFFFFF' }}>
                {customFooter ? (
                  { customFooter }
                ) : buttonLayout === '213' ? (
                  twoButton ? (
                    <ButtonContainer>
                      <ButtonComponent
                        type={secondaryButtonText ? 'secondary' : 'tertiary'}
                        onClick={secondaryOnClick || tertiaryOnClick}
                      ></ButtonComponent>
                      <Button
                        color={primaryButtonText ? 'primary' : 'danger'}
                        width={primaryButtonText ? primaryButtonWidth : dangerButtonWidth}
                        onClick={primaryOnClick || dangerOnClick}
                        disabled={primaryButtonDisabled}
                        classes="text-ellipsis overflow-hidden"
                        id={primaryButtonText ? 'primary-button' : 'danger'}
                      >
                        {primaryButtonText || dangerButtonText}
                      </Button>
                    </ButtonContainer>
                  ) : (
                    <ButtonContainer>
                      <ButtonComponent
                        type={'secondary'}
                        onClick={secondaryOnClick}
                      ></ButtonComponent>
                      <ButtonComponent type={'primary'} onClick={primaryOnClick}></ButtonComponent>
                      {tertiaryButtonText ? (
                        <ButtonComponent
                          type={'tertiary'}
                          onClick={tertiaryOnClick}
                        ></ButtonComponent>
                      ) : (
                        ''
                      )}
                      {dangerButtonText ? (
                        <ButtonComponent type={'danger'} onClick={dangerOnClick}></ButtonComponent>
                      ) : (
                        ''
                      )}
                    </ButtonContainer>
                  )
                ) : twoButton ? (
                  <ButtonContainer>
                    <Button
                      color={primaryButtonText ? 'primary' : 'danger'}
                      width={primaryButtonText ? primaryButtonWidth : dangerButtonWidth}
                      onClick={primaryOnClick || dangerOnClick}
                      disabled={primaryButtonDisabled}
                      classes="text-ellipsis overflow-hidden"
                      id={primaryButtonText ? 'primary-button' : 'danger'}
                    >
                      {primaryButtonText || dangerButtonText}
                    </Button>
                    <ButtonComponent
                      type={secondaryButtonText ? 'secondary' : 'tertiary'}
                      onClick={secondaryOnClick || tertiaryOnClick}
                    ></ButtonComponent>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer>
                    <ButtonComponent type={'primary'} onClick={primaryOnClick}></ButtonComponent>
                    <ButtonComponent
                      type={'secondary'}
                      onClick={secondaryOnClick}
                    ></ButtonComponent>
                    {tertiaryButtonText ? (
                      <ButtonComponent
                        type={'tertiary'}
                        onClick={tertiaryOnClick}
                      ></ButtonComponent>
                    ) : (
                      ''
                    )}
                    {dangerButtonText ? (
                      <ButtonComponent type={'danger'} onClick={dangerOnClick}></ButtonComponent>
                    ) : (
                      ''
                    )}
                  </ButtonContainer>
                )}
              </div>
            )}
          </div>
        </div>
      </div>,
      el.current
    )
  ) : (
    <div></div>
  );
};

export default Modal;
