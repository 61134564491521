import { isEmpty } from 'lodash';

export const METADATA_OBJECT_DATA_TYPE_DATE = 'Date';
export const METADATA_OBJECT_DATA_TYPE_TIME = 'Time';
export const METADATA_OBJECT_DATA_TYPE_ENUM = 'Enum';
export const METADATA_OBJECT_DATA_TYPE_DATETIME = 'Datetime';
export const METADATA_OBJECT_DATA_TYPE_NUMBER = 'Number';
export const METADATA_OBJECT_DATA_TYPE_BOOLEAN = 'Boolean';
export const METADATA_OBJECT_DATA_TYPE_INTEGER = 'Integer';

export const METADATA_OBJECT_DATA_TYPE_STRING = 'String';
export const METADATA_OBJECT_DATA_TYPE_STRING_ARRAY = 'String[]';

export const METADATA_OBJECT_DATA_TYPE_OBJECT = 'Object';
export const METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY = 'Object[]';

export const METADATA_OBJECT_ATTRIBUTE_NAME = 'name';
export const METADATA_OBJECT_ATTRIBUTE_TYPE = 'type';
export const METADATA_OBJECT_ATTRIBUTE_REQUIRED = 'isRequired';
export const METADATA_OBJECT_ATTRIBUTE_EDITABLE = 'editable';
export const METADATA_OBJECT_ATTRIBUTE_SEARCHABLE = 'searchable';
export const METADATA_OBJECT_ATTRIBUTE_SORTABLE = 'sortable';
export const METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH = 'maxLength';
export const METADATA_OBJECT_ATTRIBUTE_MIN_VALUE = 'min';
export const METADATA_OBJECT_ATTRIBUTE_MAX_VALUE = 'max';
export const METADATA_OBJECT_ATTRIBUTE_ENUM = 'enum';
export const METADATA_OBJECT_ATTRIBUTE_ATTRIBUTES = 'attributes';

export const UNKNOWN_ATTRIBUTE_ACTION = -1;
export const ADD_ATTRIBUTE_ACTION = 0;
export const EDIT_ATTRIBUTE_ACTION = 1;
export const DELETE_ATTRIBUTE_ACTION = 2;

const METADATA_OBJECT_DATA_TYPES: Array<any> = [
  METADATA_OBJECT_DATA_TYPE_DATE,
  METADATA_OBJECT_DATA_TYPE_TIME,
  METADATA_OBJECT_DATA_TYPE_ENUM,
  METADATA_OBJECT_DATA_TYPE_NUMBER,
  METADATA_OBJECT_DATA_TYPE_BOOLEAN,
  METADATA_OBJECT_DATA_TYPE_INTEGER,
  METADATA_OBJECT_DATA_TYPE_DATETIME,
  METADATA_OBJECT_DATA_TYPE_STRING,
  METADATA_OBJECT_DATA_TYPE_STRING_ARRAY,
  METADATA_OBJECT_DATA_TYPE_OBJECT,
  METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY
];

export const METADATA_INSTANCE_DATA_TYPE_USER_INPUTS: Array<any> = [
  METADATA_OBJECT_DATA_TYPE_DATE,
  METADATA_OBJECT_DATA_TYPE_TIME,
  METADATA_OBJECT_DATA_TYPE_ENUM,
  METADATA_OBJECT_DATA_TYPE_NUMBER,
  METADATA_OBJECT_DATA_TYPE_BOOLEAN,
  METADATA_OBJECT_DATA_TYPE_INTEGER,
  METADATA_OBJECT_DATA_TYPE_DATETIME,
  METADATA_OBJECT_DATA_TYPE_STRING,
  METADATA_OBJECT_DATA_TYPE_STRING_ARRAY
];
export const DEFAULT_STRING_LENGTH: number = 256;
export const DEFAULT_STRING_MIN_LENGTH: number = 1;
export const DEFAULT_STRING_MAX_LENGTH: number = 10240;
// export const INTEGER_MINIMUM: number = -2147483648;
// export const INTEGER_MAXIMUM: number = 2147483647;
// export const NUMBER_MINIMUM: number = -1.234567891e8;
// export const NUMBER_MAXIMUM: number = 1.234567891e8;
export const INTEGER_MINIMUM: number = 1;
export const INTEGER_MAXIMUM: number = 2147483647;
export const NUMBER_MINIMUM: number = 1;
export const NUMBER_MAXIMUM: number = 2147483647;

export const DELETABLE_ATTRIBUTES: Array<string> = ['edit', 'expanded', 'level', 'errors', 'isNew'];

export const getDataTypes = (parentNodeType?: string) => {
  if (!parentNodeType) {
    return [...METADATA_OBJECT_DATA_TYPES];
  }

  let dataTypes: Array<string> = new Array<string>();
  if ([METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY].includes(parentNodeType as string)) {
    dataTypes.push(METADATA_OBJECT_DATA_TYPE_OBJECT);
  } else {
    dataTypes = [...METADATA_OBJECT_DATA_TYPES];
  }
  return dataTypes;
};

export const isPrimitiveType = (type: string) => {
  return [
    METADATA_OBJECT_DATA_TYPE_STRING_ARRAY,
    METADATA_OBJECT_DATA_TYPE_STRING,
    METADATA_OBJECT_DATA_TYPE_BOOLEAN,
    METADATA_OBJECT_DATA_TYPE_NUMBER,
    METADATA_OBJECT_DATA_TYPE_INTEGER,
    METADATA_OBJECT_DATA_TYPE_ENUM
  ].includes(type);
};

export const isObjectType = (type: string): boolean => {
  return [METADATA_OBJECT_DATA_TYPE_OBJECT, METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY].includes(type);
};

export const isNumericType = (type: string) => {
  return [METADATA_OBJECT_DATA_TYPE_NUMBER, METADATA_OBJECT_DATA_TYPE_INTEGER].includes(type);
};

export const isIntegerType = (type: string) => {
  return [METADATA_OBJECT_DATA_TYPE_INTEGER].includes(type);
};

export const isStringType = (type: string) => {
  return [METADATA_OBJECT_DATA_TYPE_STRING, METADATA_OBJECT_DATA_TYPE_STRING_ARRAY].includes(type);
};

export const isEnumType = (type: string) => {
  return [METADATA_OBJECT_DATA_TYPE_ENUM].includes(type);
};

export const isStringArrayType = (type: string) => {
  return [METADATA_OBJECT_DATA_TYPE_STRING_ARRAY].includes(type);
};

export const reconcileNode = (node: any, type: string) => {
  if (!node || !type) {
    return;
  }

  node[METADATA_OBJECT_ATTRIBUTE_TYPE] = type;
  if (isNumericType(type)) {
    node[METADATA_OBJECT_ATTRIBUTE_MIN_VALUE] = isIntegerType(type)
      ? INTEGER_MINIMUM
      : NUMBER_MINIMUM;
    node[METADATA_OBJECT_ATTRIBUTE_MAX_VALUE] = isIntegerType(type)
      ? INTEGER_MAXIMUM
      : NUMBER_MAXIMUM;
  } else {
    delete node[METADATA_OBJECT_ATTRIBUTE_MIN_VALUE];
    delete node[METADATA_OBJECT_ATTRIBUTE_MAX_VALUE];
  }

  if (isStringType(type)) {
    node[METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH] = DEFAULT_STRING_LENGTH;
  } else {
    delete node[METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH];
  }

  const { attributes = [] } = node;
  if (isObjectType(type)) {
    node[METADATA_OBJECT_ATTRIBUTE_ATTRIBUTES] = attributes.length ? attributes : [];
  } else {
    delete node[METADATA_OBJECT_ATTRIBUTE_ATTRIBUTES];
  }
};

export const buildObjectAttributes = (attributes: Array<any> = []): Array<any> => {
  return attributes.map((attribute: any) => {
    return buildAttributeNode(attribute);
  });
};

const buildAttributeNode = (attribute: any) => {
  if (attribute) {
    DELETABLE_ATTRIBUTES.forEach((deleteField: any) => {
      delete attribute[deleteField];
    });
    if (attribute?.type !== METADATA_OBJECT_DATA_TYPE_ENUM && attribute?.enum) {
      delete attribute.enum;
    }
    const { attributes = [] } = attribute;
    attributes.forEach((item: any) => buildAttributeNode(item));
    return attribute;
  }
};

export const validateAttributeName = (name: string = '', t: Function): string => {
  let error: string = '';
  const VALID_ATTRIBUTE_NAME_PATTERN = /^([a-zA-Z]|[a-zA-Z]+[a-zA-Z0-9-_]*[a-zA-Z0-9])$/;
  if (name) {
    const value: string = name.trim();
    if (isEmpty(value)) {
      error = t('T_EMPTY_ATTRIBUTE_NAME');
    } else if (value.length > 255) {
      error = t('T_MAXIMUM_ATTRIBUTE_NAME_LENGTH');
    } else if (!VALID_ATTRIBUTE_NAME_PATTERN.test(value)) {
      error = t('T_INVALID_ATTRIBUTE_NAME');
    } else {
      error = '';
    }
  } else {
    error = t('T_EMPTY_ATTRIBUTE_NAME');
  }
  return error;
};

export const validateMaximumLength = (value: number, t: Function): string => {
  let error: string = '';
  if (value < DEFAULT_STRING_MIN_LENGTH) {
    error = t('T_MINIMUM_ATTRIBUTE_MAX_LENGTH');
  } else if (value > DEFAULT_STRING_MAX_LENGTH) {
    error = t('T_MAXIMUM_ATTRIBUTE_MAX_LENGTH');
  } else {
    error = '';
  }
  return error;
};

export const generateRandomKey = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const length = 8;
  let randomKey = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomKey += characters.charAt(randomIndex);
  }

  return randomKey;
};
