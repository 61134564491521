import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Label, OptionalLabel, TextArea } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';
import { useTranslation } from 'react-i18next';

interface SupportedFields {
  roleDescription: string;
}

interface InputFieldProps {
  type: string;
  id: string;
  label: string;
  placeholder: any;
  fieldKey: string;
  required: boolean;
  formData: SupportedFields;
  setFormData: Function;
  formError: SupportedFields;
  asterixFirst?: boolean;
  secondaryLabel?: string;
  rows?: number;
  disabled?: boolean;
  maxLength?: number;
  handleChange?: Function | null;
  classname?: string;
  style?: CSSProperties;
}
const Image = styled.img`
  width: 8px;
  height: 8px;
`;

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;
function TextAreaField({
  type,
  id,
  label,
  placeholder,
  fieldKey,
  required,
  formData,
  setFormData,
  formError,
  asterixFirst,
  secondaryLabel,
  disabled,
  rows,
  maxLength,
  handleChange = null,
  style = {}
}: InputFieldProps) {
  const { t } = useTranslation();
  return (
    <>
      <Label htmlFor={id}>
        {asterixFirst && required ? <Image src={Asterix} className="mr-1" /> : ''}
        {t(label)} <OptionalLabel>{secondaryLabel}</OptionalLabel>
        {required && !asterixFirst ? <Image src={Asterix} /> : ''}
      </Label>
      <TextArea
        id={id}
        placeholder={t(placeholder) || ''}
        rows={rows ? rows : 3}
        disabled={disabled}
        wrap="hard"
        required={required}
        data-testId={`${t(label)}_textArea-field`}
        draggable
        style={style}
        value={
          formData[fieldKey as keyof SupportedFields]
            ? formData[fieldKey as keyof SupportedFields]
            : ''
        }
        onChange={(e) => {
          if (handleChange) {
            handleChange(e);
          } else {
            setFormData({ ...formData, [fieldKey]: e.target.value });
          }
        }}
        maxLength={maxLength ? maxLength : undefined}
      />
      <ErrorLabel>
        {formError[fieldKey as keyof SupportedFields]
          ? formError[fieldKey as keyof SupportedFields]
          : ''}
      </ErrorLabel>
    </>
  );
}

export default TextAreaField;
