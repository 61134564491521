import { BiSearchIconInput } from '../../primitives/inputs/BiSearchIconInput.primitive';

import {
  Group,
  Row,
  SorterIcon,
  Table,
  TableData,
  TableFooter,
  TableHeading,
  TableRow,
  TableHead,
  TableBody,
  NoDataTableRow,
  NoDataTableCell
} from './TemplateTableStyles';
import Sorter from '../../../assets/sorter.svg';
import SorterAsc from '../../../assets/sorter-asc.svg';
import SorterDesc from '../../../assets/sorter-desc.svg';
import { ITableHeader } from './models/ITableHeader';
import Paginator from '../paginator/Paginator';
import { Children, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import EditIcon from '@heroicons/react/outline/PencilIcon';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import { useLocation } from 'react-router-dom';
import LocalPaginator from '../paginator/LocalPaginator';
import Spinner from '../../primitives/Spinner/Loader';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setToastData } from '../../../app/toastReducer';
import { AppConstants } from '../../../constants/AppConstants';
import Filter from '../../ConfigurationService/Filter';

import { setTableOptions, resetTableOptions } from '../../../app/templateTableReducer';
import { useDidMountEffect } from '../../../util/customHooks';

interface TemplateProps {
  tableData: any;
  size?: string;
  currentPage?: string;
  setCurrentPage?: Function;
  setSize?: Function;
  onRefreshTableData: any;
  templateSelector: any;
  cellSelector?: Function;
  tableHeaders: Array<ITableHeader>;
  setTableHeaders: any;
  searchPlaceholder?: string;
  pagingData?: any;
  containerClass?: string;
  isPaginated?: boolean;
  isSearchable?: boolean;
  showEditDeleteButton?: boolean; //If using this send an object in headers with key: 'buttons',
  showDeleteButton?: boolean;
  editOnClick?: Function;
  deleteOnClick?: Function;
  isLocalPaginator?: boolean; //Local Pagination props
  totalDataLength?: number; // For Local Pagination
  tableName?: string;
  isLoading?: boolean;
  tableBodyClass?: string;
  deleteLoader?: boolean;
  theme?: any;
  tableClassName?: string;
  disablePageSize?: boolean;
  isMultiColumnSort?: boolean;
  isFilterable?: boolean;
  filtersList?: any;
  applyFilters?: Function;
  pillsData?: any;
  removeFilter?: Function;
  defaultPillsLabel?: string;
  globalFilters?: any;
  tablePage?: string;
  type?: string;
  hasExternalCustomFilter?: boolean;
  filterWidth?: any;
}

const TemplateTable: React.FC<TemplateProps> = ({
  tableData,
  onRefreshTableData,
  templateSelector,
  cellSelector = undefined,
  tableHeaders,
  setTableHeaders,
  searchPlaceholder = 'Search Name',
  pagingData,
  containerClass,
  isPaginated = true,
  isSearchable = true,
  showEditDeleteButton,
  showDeleteButton,
  editOnClick,
  deleteOnClick,
  isLocalPaginator,
  totalDataLength = 0,
  tableName,
  isLoading,
  tableBodyClass,
  deleteLoader,
  theme,
  tableClassName = '',
  disablePageSize = false,
  isMultiColumnSort = true,
  isFilterable = false,
  filtersList = {},
  applyFilters = () => {},
  pillsData = {},
  removeFilter = () => {},
  defaultPillsLabel = 'Filters',
  globalFilters = {},
  tablePage = '',
  type = 'listing',
  size,
  currentPage,
  setSize,
  setCurrentPage,
  hasExternalCustomFilter = false,
  filterWidth = 0
}: TemplateProps) => {
  const [searchText, setSearchText] = useState('');
  const [sortType, setSortType] = useState(AppConstants.DEFAULT_SORT_DESC);
  const [sortName, setSortName] = useState(AppConstants.DEFAULT_SORT_BY);
  const [tableFilters, setTableFilters] = useState({});

  const [tableSize, setTableSize] = useState<any>(size);

  const [tableCurrentPage, setTableCurrentPage] = useState<any>(AppConstants.DEFAULT_PAGE);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (tablePage && globalFilters[tablePage]) {
      const { searchText, currentPage, size, sortName, sortType, tableFilters } =
        globalFilters[tablePage];
      if (searchText) {
        setSearchText(searchText);
      }

      if (currentPage) {
        setTableCurrentPage(currentPage);
      }

      if (size) {
        setTableSize(size);
      }

      if (sortName) {
        setSortName(sortName);
      }

      if (sortType) {
        setSortType(sortType);
      }

      let tableHeadersTemp = [...tableHeaders];
      if (!isMultiColumnSort) {
        tableHeadersTemp.forEach((ele: any, i: number) => {
          if (ele.sortingInformation) ele.sortingInformation!.order = '';
        });
      } else {
        tableHeadersTemp.forEach((ele: any, i: number) => {
          if (ele.sortingInformation) {
            if (ele.key === sortName) {
              ele.sortingInformation!.order = sortType;
            }
          }
        });
      }
      setTableHeaders(tableHeadersTemp);

      if (tableFilters) {
        setTableFilters(tableFilters);
      }
    }
  }, [globalFilters]);

  const sizeChange = (page: string, size: string) => {
    onRefreshTableData(searchText, page, size, sortName, sortType, tableFilters);
    if (tablePage) {
      dispatch(
        setTableOptions({
          [tablePage]: {
            searchText: searchText,
            currentPage: page,
            size: size,
            sortName: sortName,
            sortType: sortType,
            tableFilters: tableFilters
          }
        })
      );
    }
  };

  const debouncedSearch = useRef(
    debounce(async (data, currentPage, size, sortName, sortType, tableFilters) => {
      if (data && data.trim()) {
        await onRefreshTableData(data.trim(), currentPage, size, sortName, sortType, tableFilters);
        if (tablePage) {
          dispatch(
            setTableOptions({
              [tablePage]: {
                searchText: data.trim(),
                currentPage: currentPage,
                size: size,
                sortName: sortName,
                sortType: sortType,
                tableFilters: tableFilters
              }
            })
          );
        }
      } else if (!data) {
        await onRefreshTableData(data, currentPage, size, sortName, sortType, tableFilters);
        if (tablePage) {
          dispatch(
            setTableOptions({
              [tablePage]: {
                searchText: data,
                currentPage: currentPage,
                size: size,
                sortName: sortName,
                sortType: sortType,
                tableFilters: tableFilters
              }
            })
          );
        }
      }
    }, 400)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (e.target.value.length < AppConstants.DEFAULT_TEXT_MAX_LENGTH) {
      debouncedSearch(
        e.target.value,
        AppConstants.DEFAULT_PAGE,
        tableSize,
        sortName,
        sortType,
        tableFilters
      );
    } else {
      dispatch(
        setToastData({
          toastMessage: t('T_SEARCH_ERROR'),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const setFilters = (appliedFilters: any) => {
    setTableFilters(appliedFilters);
    //applyFilters(searchText, currentPage, size, sortName, sortType, appliedFilters);
    dispatch(
      setTableOptions({
        [tablePage]: {
          searchText: searchText,
          currentPage: AppConstants.DEFAULT_PAGE,
          size: tableSize,
          sortName: sortName,
          sortType: sortType,
          tableFilters: appliedFilters
        }
      })
    );
  };

  return (
    <div className="h-full">
      {(isSearchable || isFilterable) && (
        <>
          {isSearchable && (
            <Row justifyContent="flex-start" className="float-right">
              <Group width="335">
                <BiSearchIconInput
                  isEmailTemplate
                  autoFocus
                  value={searchText}
                  placeholder={searchPlaceholder}
                  onChange={handleChange}
                  data-testid={`${searchPlaceholder}_search-field`}
                />
              </Group>
            </Row>
          )}
          {isFilterable && (
            <Row justifyContent="flex-start" className="float-left">
              <Group width="" marginBottom="0">
                <Filter
                  filterWidth={filterWidth}
                  filtersList={filtersList}
                  applyFilters={setFilters}
                  pillsData={pillsData}
                  removeFilter={removeFilter}
                  defaultPillsLabel={defaultPillsLabel}
                />
              </Group>
            </Row>
          )}
        </>
      )}

      <div
        className={`w-full ${
          isSearchable || isFilterable || hasExternalCustomFilter ? 'pb-12' : ''
        } ${tableClassName}`}
        style={{ height: 'inherit' }}
      >
        <Table width="100%" className={`flex h-fit max-h-full flex-col`}>
          <TableHead className="flex-initial">
            <TableRow key={'header-row'}>
              {tableHeaders.map((tableHeader, index: number) => {
                return (
                  <TableHeading
                    key={`${tableHeader.key}-${index}`}
                    width={tableHeader.width}
                    className={`${
                      (tableName === `${t('T_USER_PERMISSION')}` && index === 1) ||
                      (tableName === `${t('T_TABLE_APP_PERMISSION_NAME')}` && index === 3)
                        ? 'justify-center'
                        : ''
                    }`}
                    style={tableHeader.styles}
                  >
                    {t(tableHeader.description)}
                    {tableHeader.sortEnabled ? (
                      <SorterIcon
                        onClick={() => {
                          // sort(tableHeader, index)
                          let sortTypeTemp: any = '';
                          if (!tableHeader.sortingInformation?.order) {
                            sortTypeTemp = 'desc';
                          }
                          if (tableHeader.sortingInformation?.order === 'desc') {
                            sortTypeTemp = 'asc';
                          }

                          setSortType(sortTypeTemp);
                          setSortName(tableHeader.key);

                          let tableHeadersTemp = [...tableHeaders];

                          if (!isMultiColumnSort) {
                            tableHeadersTemp.forEach((ele: any, i: number) => {
                              if (ele.sortingInformation) ele.sortingInformation!.order = '';
                            });
                          }

                          tableHeadersTemp[index].sortingInformation!.order = sortTypeTemp;

                          setTableHeaders(tableHeadersTemp);
                          const tempTableSize = tablePage ? tableSize : size;
                          if (sortTypeTemp) {
                            onRefreshTableData(
                              searchText,
                              currentPage,
                              tempTableSize,
                              tableHeader.key,
                              sortTypeTemp,
                              tableFilters
                            );
                            if (tablePage) {
                              dispatch(
                                setTableOptions({
                                  [tablePage]: {
                                    searchText: searchText,
                                    currentPage: currentPage ? currentPage : tableCurrentPage,
                                    size: tablePage ? tableSize : size,
                                    sortName: tableHeader.key,
                                    sortType: sortTypeTemp,
                                    tableFilters: tableFilters
                                  }
                                })
                              );
                            }
                          } else {
                            onRefreshTableData(searchText, currentPage, size, '', '', tableFilters);
                            if (tablePage) {
                              dispatch(
                                setTableOptions({
                                  [tablePage]: {
                                    searchText: searchText,
                                    currentPage: currentPage ? currentPage : tableCurrentPage,
                                    size: tablePage ? tableSize : size,
                                    sortName: '',
                                    sortType: '',
                                    tableFilters: tableFilters
                                  }
                                })
                              );
                            }
                          }
                        }}
                        src={
                          tableHeader.sortingInformation?.order === 'desc'
                            ? SorterDesc
                            : tableHeader.sortingInformation?.order === 'asc'
                            ? SorterAsc
                            : Sorter
                        }
                        alt="sorterImg"
                      />
                    ) : (
                      ''
                    )}
                  </TableHeading>
                );
              })}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody className="flex h-full w-full items-center justify-center py-12">
              <TableRow>
                <td>
                  <div>
                    <Spinner height="40px" width="40px"></Spinner>
                  </div>
                </td>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody
              className={`h-full flex-auto overflow-auto
               ${tableBodyClass}`}
              key="table_body"
            >
              {typeof tableData === 'string' ? (
                <NoDataTableRow key={'NoDataTableRow'}>
                  <NoDataTableCell width="100%">{tableData}</NoDataTableCell>
                </NoDataTableRow>
              ) : (
                tableData?.map((element: any, index: number) => {
                  return element.loading ? (
                    <TableRow key={'spinnerTableRow'}>
                      <td
                        key={index}
                        data-testid="delete-loader"
                        className=" flex h-[40px] w-full items-center justify-center py-10"
                      >
                        <Spinner height="40px" width="40px"></Spinner>
                      </td>
                    </TableRow>
                  ) : (
                    <TableRow
                      data-testid="table-row"
                      key={index}
                      onClick={() => {
                        if (!cellSelector) templateSelector(element, 2);
                      }}
                      hover={false}
                      style={{ overflowWrap: 'anywhere' }}
                    >
                      {tableHeaders.map((header) => {
                        return (
                          <TableData
                            key={header.key}
                            width={header.width}
                            onClick={() => {
                              if (cellSelector && header.clickable)
                                cellSelector(element, header.key);
                            }}
                            notCursor={header?.styles?.notCursor}
                            hover={header.key === 'contract' ? true : false}
                            style={header?.styles?.rowStyle}
                            className={
                              header.key === 'contract'
                                ? 'overflow-hidden text-ellipsis'
                                : header.key === 'buttons'
                                ? 'items-center justify-center'
                                : ''
                            }
                            // role={header.key === 'contract' ? "contract-file" : " "}
                            data-testid="table-data-item"
                            // aria-label={`file`}
                          >
                            {header.render
                              ? header.render(element, index)
                              : element && element[header.key]}
                            {(showEditDeleteButton || showDeleteButton) &&
                            header.key == 'buttons' ? (
                              <div className="flex">
                                {!showDeleteButton && (
                                  <EditIcon
                                    className="mr-3 h-6 w-6 text-gray-600 hover:text-black"
                                    onClick={() => editOnClick && editOnClick(index)}
                                  />
                                )}
                                <DeleteIcon
                                  className="h-6 w-6 text-gray-600 hover:text-black"
                                  onClick={() => deleteOnClick && deleteOnClick(index)}
                                  data-testid="delete-icon"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                            <div>
                              {header.key === 'deletebutton' && !deleteLoader ? (
                                <DeleteIcon
                                  data-testid="delete-icon"
                                  className="m-auto h-[40px] w-[40px] cursor-pointer p-2 text-[#127ADB] hover:text-sky-800"
                                  onClick={() => deleteOnClick && deleteOnClick(element, index)}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </TableData>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          )}
          {isPaginated && (
            <TableFooter className="flex-1">
              <TableRow key={'footer'} hover={true}>
                <TableData
                  key={'TableFooter'}
                  hover={true}
                  width={'100%'}
                  style={{ paddingTop: '7px', paddingBottom: '7px' }}
                >
                  <Paginator
                    onSizeChanged={sizeChange}
                    size={tablePage ? tableSize : size}
                    currentPage={tablePage ? tableCurrentPage : currentPage}
                    pagingData={pagingData}
                    refreshPaginatedData={(page: number) => {
                      onRefreshTableData(searchText, page, size, sortName, sortType, tableFilters);
                      if (tablePage) {
                        dispatch(
                          setTableOptions({
                            [tablePage]: {
                              searchText: searchText,
                              currentPage: page,
                              size: tablePage ? tableSize : size,
                              sortName: sortName,
                              sortType: sortType,
                              tableFilters: tableFilters
                            }
                          })
                        );
                      }
                    }}
                    containerClass={containerClass ? containerClass : 'py-5'}
                    theme={theme}
                    disablePageSize={disablePageSize}
                  ></Paginator>
                </TableData>
              </TableRow>
            </TableFooter>
          )}
          {isLocalPaginator && (
            <TableFooter className="flex-1">
              <TableRow key={'footer'} hover={true}>
                <TableData
                  key={'TableFooter'}
                  hover={true}
                  width={'100%'}
                  style={{ paddingTop: '7px', paddingBottom: '7px' }}
                >
                  <LocalPaginator
                    onSizeChanged={setSize}
                    disablePageSize={disablePageSize}
                    size={size ? parseInt(size) : tableSize}
                    dataLength={tableData.length}
                    currentPage={currentPage ? currentPage : tableCurrentPage}
                    setCurrentPage={setCurrentPage}
                    pagingData={pagingData}
                    containerClass={containerClass ? containerClass : 'py-5'}
                    theme={theme}
                    totalDataLength={totalDataLength}
                  ></LocalPaginator>
                </TableData>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </div>
    </div>
  );
};

export default TemplateTable;
