import { useEffect, useState, forwardRef, useRef, memo } from 'react';
import EditIcon from '../../assets/edit-pencil.svg';
import HtmlEditor from '../primitives/htmlEditor/HtmlEditor';
import Asterix from '../../assets/asterix.svg';
import styled from 'styled-components';
import { Label } from '../AccountProfile/ProfileStyles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setTemplateData } from '../../app/templateReducer';
import { LabelIconHead } from './TemplateStyles';
import { useTranslation } from 'react-i18next';
import Card from '../shared/cards/Card';

const Image = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 5px;
`;

type Props = {
  disabled?: boolean;
  htmlEditorRef?: any;
  isCodeEditor?: any;
  mode?: string;
  setEmailBodyError: Function;
};

const EditTemplate = forwardRef(
  ({ disabled, mode, htmlEditorRef, setEmailBodyError }: Props, ref: any) => {
    const dispatch = useAppDispatch();

    const data = useAppSelector((state) => state.templateData.templatePayload);
    const [templateBody, setTemplateBody] = useState(data?.data?.params[data?.contentKey]?.value);
    const { t } = useTranslation();

    const changeReducerData = (keyName: string, payload: any, isParam: boolean) => {
      const localData = JSON.parse(JSON.stringify(data));
      if (isParam) {
        const indexLocal = ['email_subject', 'push_notification_subject'].includes(keyName)
          ? data.subjectKey
          : data.contentKey;

        localData.data.params[indexLocal].value = payload;
        if (keyName === 'push_notification_subject') {
          localData.subject = payload;
        }
      } else {
        localData[keyName] = payload;
      }
      dispatch(setTemplateData(localData));
    };

    const onSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (data.type == 'email') {
        changeReducerData('email_subject', event.target.value, true);
      } else if (data.type == 'sms') {
        changeReducerData('subject', event.target.value, false);
      } else {
        changeReducerData('push_notification_subject', event.target.value, true);
      }
    };

    const changeEmailContent = (payload: any) => {
      changeReducerData('email_content', payload, true);
    };

    const changeSmsContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      changeReducerData('sms_content', event.target.value, true);
    };

    const changePushContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      changeReducerData('push_notification_content', event.target.value, true);
    };

    useEffect(() => {
      if (data.type !== 'email') {
        setTemplateBody('');
      }
    }, [data.type]);

    useEffect(() => {
      if (data.type == 'email') {
        changeEmailContent(templateBody);
      }
    }, [templateBody]);

    // useEffect(() => {
    //   setTemplateBody(data.data.params[data.contentKey].value);
    // }, [data.data.params[data.contentKey].value]);

    return (
      <div className="mt-5 flex h-fit w-full flex-col justify-start overflow-visible">
        <Card
          title={t('T_TEMPLATE_CONTENT')} //mode === 'create' ? t('T_TEMPLATE_CONTENT') : t('T_EDIT_TEMPLATE_CONTENT')
          icon={EditIcon}
          alt="organization icon image"
          height=""
          width=""
        >
          <div className="flex w-full flex-wrap">
            <form className="w-full">
              {data.type !== 'sms' ? (
                <div>
                  <Label htmlFor="subject">
                    <Image src={Asterix} alt="asterix" /> {t('T_SUBJECT')}
                  </Label>
                  <input
                    type="text"
                    id="subject"
                    className="h-12 w-full rounded-md border border-solid border-gray-300 px-4"
                    disabled={disabled}
                    value={
                      ['email', 'push_notification'].includes(data.type)
                        ? data.data.params[data.subjectKey]?.value || ''
                        : data.subject
                    }
                    onChange={onSubjectChange}
                  />
                </div>
              ) : (
                ''
              )}
              <div className="mt-4">
                <Label>
                  <Image src={Asterix} alt="asterix" />
                  {data.type === 'email' ? t('T_BODY') : t('T_TEMPLATE_NOTIFICATION_CONTENT')}
                </Label>
                {data && data.type === 'email' ? (
                  <HtmlEditor
                    htmlContent={templateBody}
                    onChange={setTemplateBody}
                    disabled={disabled}
                    htmlRef={htmlEditorRef}
                    setEmailBodyError={setEmailBodyError}
                    // height={Math.round(window.innerHeight / (100 / 40)) + 'px'}
                    height={`calc(80vh)`}
                  />
                ) : (
                  <textarea
                    id="content"
                    className="w-full rounded-md border border-solid border-gray-300 px-4 pt-4"
                    disabled={disabled}
                    rows={3}
                    value={data.data.params[data.contentKey].value}
                    onChange={(e) =>
                      data.type == 'sms' ? changeSmsContent(e) : changePushContent(e)
                    }
                  />
                )}
              </div>
            </form>
          </div>
        </Card>
      </div>
    );
  }
);

export default EditTemplate;
