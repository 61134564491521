import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../context/themeContext';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AppConstants } from '../../constants/AppConstants';
import { EllipsisDiv } from '../../styles/globalStyles';
import { ITableHeader } from '../../components/shared/tables/models/ITableHeader';
import { getPasswordPolicy } from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { setURLPaths } from '../../app/organizationReducer';
import { RouteConstants } from '../../constants/RouteConstants';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import TemplateTable from '../shared/tables/TemplateTable';
import { debounce } from 'lodash';
import { setToastData } from '../../app/toastReducer';
import { getFailureMessage } from '../../util/ErrorUtil';
import { setPasswordPolicy } from '../../app/passwdPolicyReducer';
import AddCircle from '../../assets/addCircle.svg';

/**
 * @parentId password policy
 * @manager Password Policy Listing
 * @overview Lists all the password policies
 *   <section>
 *       <p>
 *           Password policies listing page can be navigated from <b>Password Policies</b>, navigation menuitem under <b>Security</b> dropdown group item from left sidebar of admin portal.
 *           Password policies listing page will display all the password policies in flat table hireachry with pagination support & password policy(s) sorted by created-on field timestamp & descending order of creation. The Default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer.
 *           Datagrid footer will also display the current page and total number of pages available based on the choosen page size & application data. User can switch to next/previous pages using the <b>Next</b> & <b>Previous</b> buttons enabled based on the content in the data grid footer.
 *       </p>
 *       <br>
 *       <p>
 *           Password policiy can be filtered based on password policy name using the search field provided above the password policy listing data grid.
 *           Admin user(s) with <b>user-management-service.password-policy.create</b> API role permission can create new password policy by clicking on <b>New Password Policy</b> button provided on the password policy listing page toolbar.
 *           Clicking on it will navigate user to password Policy creation page.
 *       </p>
 *       <br/>
 *       <p>
 *           Datagrid will display below properties of each password policy as columns. These columns are fixed in width and can't be hidden, changed or re-ordered.
 *           <br/>
 *           <ul>
 *               <li>Password Policy</li>
 *               <li>Password History</li>
 *               <li>Password Expiration</li>
 *               <li>Minimum number of characters</li>
 *           </ul>
 *       </p>
 *       <p>
 *          Sorting of password policy columns is only supported on <i>Password Policy</i> column, However the default sort of the listing page is based on <b>Created On</b> field with Descending order.
 *          Clicking on password policy rowitem will drill down user to password policy details page which will display password policy page in view only mode.
 *       </p>
 *     </section>
 *
 *
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes the List Password Policy Status Code information.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>UMS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>UMS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td>None</td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings, platform services & role API permissions required for the functioning of password policy listing page.</p>
 *       <br/>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for password policy listing page.</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for password policy listing page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/password-policies</td>
 *               <td>GET</td>
 *               <td>user-management-service.password-policy.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for password policy listing page</p>
 *   </section>
 */

const PasswordPolicyList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState<any>([]);
  const [pagingOpts, setPagingOpts] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(AppConstants.DEFAULT_PAGE);
  const [size, setSize] = useState<number>(AppConstants.DEFAULT_PAGE_SIZE);

  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const PASSWORD_POLICY_HEADERS: Array<ITableHeader> = [
    {
      key: 'name',
      description: t('T_PASSWORD_POLICY'),
      sortEnabled: true,
      width: '40%',
      clickable: true,
      sortingInformation: {
        order: ''
      },
      render: (data: any) => {
        return (
          <EllipsisDiv className="w-full flex-1" title={data?.name}>
            {data?.name}
          </EllipsisDiv>
        );
      },
      styles: { borderRight: 'none' }
    },
    {
      key: 'pwdInHistory',
      description: t('T_PASSWORD_HISTORY'),
      sortEnabled: false,
      clickable: true,
      width: '20%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'pwdMaxAge',
      description: t('T_PASSWORD_EXPIRATION'),
      sortEnabled: false,
      clickable: true,
      width: '20%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'pwdMinLength',
      description: t('T_MINIMUM_NO_OF_CHARS'),
      sortEnabled: false,
      clickable: true,
      width: '20%',
      sortingInformation: {
        order: ''
      }
    }
  ];

  const [tableHeaders, setTableHeaders] = useState(PASSWORD_POLICY_HEADERS);

  useEffect(() => {
    dispatch(
      setURLPaths([
        { key: RouteConstants.ROUTE_UMS_PASSWORD_POLICY_LIST, label: 'Password Policies' }
      ])
    );
    loadPasswordPolicies('', page, size);
  }, []);

  const loadPasswordPolicies = async (
    name: string,
    page: number,
    size: number,
    sort: string = '',
    sortType: string = ''
  ) => {
    const response = await getPasswordPolicy(name, page, size, sort ? [`${sort},${sortType}`] : []);
    const { status, data: appResponse = {} } = response;
    if (HTTP_STATUS.isSuccess(status)) {
      const { data = [], paging } = appResponse;
      if (data.length) {
        setRows([...data]);
        setPagingOpts(paging);
      } else {
        noDataAvailableMessage(setRows, setSize, setPagingOpts, paging, t);
      }
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const refreshTableData = (
    name: string = '',
    page: number,
    size: number,
    sort: string,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setTableValues(name, page, size, sort, sortType);
  };

  const debouncedCall = useRef(
    debounce((name, page, size, sort, sortType) => {
      setSize(size);
      setPage(page);
      setIsLoading(true);
      if (sort && sort.length) {
        sort = AppConstants.DEFAULT_SORT_BY_NAME;
      }
      loadPasswordPolicies(name, page, size, sort, sortType);
    }, 400)
  ).current;

  const setTableValues = (
    name: string = '',
    page: number,
    size: number,
    sort: string = '',
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    debouncedCall(name, page, size, sort, sortType);
  };

  const passwdPolicySelector = (data: any) => {
    dispatch(setPasswordPolicy(data));
    navigate(RouteConstants.ROUTE_UMS_PASSWORD_POLICY_VIEW.replace(':id', data?.id));
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <TableContainer>
        <MenuButtonsPortal>
          {roleAPIPermissions[AppConstants.PERMISSION_UMS_PASSWORD_POLICY_CREATE] && (
            <BiButton
              className={'flex flex-row bg-primary text-white'}
              type="button"
              onClick={() => {
                navigate(RouteConstants.ROUTE_UMS_PASSWORD_POLICY_CREATE);
              }}
            >
              <img src={AddCircle} className="mr-2 w-6 text-center"></img>
              {t('T_NEW_PASSWORD_POLICY')}
            </BiButton>
          )}
        </MenuButtonsPortal>

        <TemplateTable
          tableData={rows}
          onRefreshTableData={refreshTableData}
          size={size.toString()}
          currentPage={page.toString()}
          isSearchable={true}
          templateSelector={passwdPolicySelector}
          cellSelector={passwdPolicySelector}
          searchPlaceholder={t('T_PASSWORD_POLICY_BY_NAME')}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          pagingData={pagingOpts}
          theme={useTheme().theme}
          containerClass="mt-0"
          setCurrentPage={setPage}
        />
      </TableContainer>
    </LoaderContainer>
  );
};

export const TableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;
export default PasswordPolicyList;
