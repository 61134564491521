import { ReactNode } from 'react';

type ListViewProps = {
  maxHeight?: string;
  id: string;
  children?: ReactNode;
  containerClassName?: string;
};
const ListView = ({ maxHeight, id, children, containerClassName }: ListViewProps) => {
  return (
    <div
      id={id}
      data-testid={id}
      className={'overflow-y-auto ' + containerClassName}
      style={{ maxHeight: maxHeight ? maxHeight : 'unset' }}
    >
      {children}
    </div>
  );
};
export default ListView;
