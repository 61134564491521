import InfiniteScroll from 'react-infinite-scroll-component';

import styled, { css } from 'styled-components/macro';
import DropdownSvg from '../../../assets/dropdown.svg';
import { Label } from '../../AccountProfile/ProfileStyles';
import Asterix from '../../../assets/asterix.svg';

import Check from '../../../assets/check.svg';
import { useEffect, useRef, useState } from 'react';
import { debounce, isFunction } from 'lodash';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';
import { compact, isObject } from 'lodash';
import { useAppDispatch } from '../../../app/hooks';
import { setToastData } from '../../../app/toastReducer';
import { AppConstants } from '../../../constants/AppConstants';
import React, { ChangeEvent, FocusEvent } from 'react';
import { colorLightGray6, colorLightGray7 } from '../../../styles/globalVariables';
import Search from '../../../assets/search.svg';
import Remove from '../../../assets/times-circle.svg';
import BiSearchAndClick from '../../primitives/inputs/BISearchAndClick';
import { addCircleBlack } from '../../../assets/components/svgs';

export const enum PICKLIST_TYPES {
  SINGLE = 0,
  MULTI = 1
}

interface PickListProps {
  type: number;
  fieldKey: string;
  fieldValues: Array<string>;
  items: Array<any>;
  selectedItems: Array<any>;
  onSelectedChange: Function;
  disabled: boolean;
  readOnly: boolean;
  label?: string;
  required?: boolean;
  fetchMoreData?: Function;
  hasMore?: boolean;
  searchPlaceholder?: string;
  searchable?: boolean;
  searchData?: Function;
  initialLoad?: Function;
  isLoading?: boolean;
  noDataMessage?: string;
  showAddOption?: boolean;
  onAddItem?: Function;
}

function PickListWithApiSearch({
  fieldKey,
  fieldValues = [],
  items = [],
  selectedItems = [],
  onSelectedChange,
  disabled = false,
  readOnly = false,
  type = PICKLIST_TYPES.SINGLE,
  label = '',
  required = false,
  fetchMoreData = () => {},
  searchData = () => {},
  hasMore = false,
  searchPlaceholder = 'Search',
  searchable = false,
  initialLoad = () => {},
  isLoading = false,
  noDataMessage = '',
  showAddOption = false,
  onAddItem = () => {}
}: PickListProps) {
  const selectRef = useRef(null);
  const dispatch = useAppDispatch();

  const [keyValues, setKeyValues] = useState<any>({});
  const [searchText, setSearchText] = useState('');
  const [flag, setFlag] = useState<boolean>(false);

  const setSelectedItems = () => {
    let keyValues = {};
    if (selectedItems.length) {
      keyValues = selectedItems.reduce((acc: any, elem: any) => {
        acc[elem[fieldKey]] = elem;
        return acc;
      }, {});
    }

    return setKeyValues(keyValues);
  };

  useEffect(() => {
    if (selectedItems.length) {
      setSelectedItems();
    }
  }, [selectedItems]);

  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();

  const toggling = () => {
    if (!flag && isFunction(initialLoad)) {
      initialLoad();
      setFlag(true);
    }
    setShowPopup(!showPopup);
  };

  useOutsideClick(
    selectRef,
    () => {
      if (showPopup) {
        setShowPopup(false);
      }
    },
    false
  );
  const onOptionClicked = (item: any) => () => {
    if (!item || readOnly) {
      return;
    }

    let newKeyValues = { ...keyValues };
    // const selection: any = isObject(item.value) ? item.value : item;
    if (newKeyValues[item[fieldKey]]) {
      delete newKeyValues[item[fieldKey]];
    } else {
      if (!type) {
        newKeyValues = {};
      }
      newKeyValues[item[fieldKey]] = item;
    }
    onSelectedChange(Object.values(newKeyValues));
    setKeyValues(newKeyValues);

    if (!type) {
      setShowPopup(false);
    }
  };

  const getDisplayValue = (item: any = {}) =>
    compact(fieldValues.map((fieldValue: string) => item[fieldValue])).join();

  let selection: string = t('T_SELECT');
  const length = Object.keys(keyValues).length;

  if (length) {
    selection = type
      ? `${length}` + t('T_ITEMS_SELECTED')
      : Object.values(keyValues)
          .map((item: any) => getDisplayValue(isObject(item.value) ? item.value : item))
          .join();
  }

  const fetchMoreDataFunc = () => {
    fetchMoreData(searchText);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (e.target.value.length < AppConstants.DEFAULT_TEXT_MAX_LENGTH) {
      debouncedSearch(e.target.value);
    } else {
      dispatch(
        setToastData({
          toastMessage: t('T_SEARCH_ERROR'),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const debouncedSearch = useRef(
    debounce(async (data) => {
      if (data && data.trim()) {
        searchData(data.trim());
      } else if (!data) {
        searchData(data);
      }
      fetchMoreData(data);
    }, 400)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleAddItem = () => {
    if (isFunction(onAddItem)) {
      onAddItem();
    }
  };

  return (
    <>
      {label && (
        <Label>
          {required ? <Image className="mr-1" src={Asterix} /> : ''}
          {t(label)}
        </Label>
      )}

      <DropDownContainer className={disabled ? 'disabled' : ''}>
        <DropDownHeader onClick={toggling} data-testid="select-field-header">
          {selection === t('T_SELECT') ? (
            selection
          ) : (
            <Divtext title={selection}>{selection}</Divtext>
          )}
          <img src={DropdownSvg} />
        </DropDownHeader>
        {showPopup && (
          <DropDownListContainer ref={selectRef} data-testid="select-option-list">
            <DropDownList showAddOption={showAddOption}>
              {searchable && (
                <SearchPickListItem onClick={onOptionClicked('')} data-testid="search-field">
                  <BiSearchAndClick
                    autoFocus
                    isLoading={isLoading}
                    value={searchText}
                    placeholder={t(searchPlaceholder)}
                    onChange={handleSearchChange}
                    searchIcon={searchText.length ? Remove : Search}
                    onClickIcon={() => {
                      if (searchText.length) {
                        setSearchText('');
                        fetchMoreData('');
                      }
                    }}
                  />
                </SearchPickListItem>
              )}

              {!searchable && (
                <PickListItem onClick={onOptionClicked('')}>{t('T_SELECT')}</PickListItem>
              )}
              {!hasMore && !items.length && (
                <PickListItem onClick={onOptionClicked('')}>
                  {noDataMessage || t('T_NO_DATA_AVAILABLE_MSG')}
                </PickListItem>
              )}
              <InfiniteScrollOuterDiv>
                <InfiniteScroll
                  dataLength={items.length}
                  next={fetchMoreDataFunc}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  height={hasMore ? 280 : 'auto'}
                >
                  {items.map((item: any) => (
                    <PickListItem
                      onClick={onOptionClicked(item)}
                      key={item.key}
                      data-testid="select-option-item"
                    >
                      <ImageContainer>
                        {!!keyValues[item[fieldKey]] && <CheckMark src={Check} />}
                      </ImageContainer>
                      {item.icon ? <div className="mr-2 self-center">{item.icon}</div> : ''}
                      <Divtext title={getDisplayValue(isObject(item.value) ? item.value : item)}>
                        {getDisplayValue(isObject(item.value) ? item.value : item)}
                      </Divtext>
                    </PickListItem>
                  ))}
                </InfiniteScroll>
              </InfiniteScrollOuterDiv>

              {/* {items.map((item: any) => (
                <PickListItem
                  onClick={onOptionClicked(item)}
                  key={item.key}
                  data-testid="select-option-item"
                >
                  <ImageContainer>
                    {!!keyValues[item[fieldKey]] && <CheckMark src={Check} />}
                  </ImageContainer>
                  <Text>{getDisplayValue(isObject(item.value) ? item.value : item)}</Text>
                </PickListItem>
              ))} */}
              {showAddOption && !readOnly && (
                <AddPickListItem
                  data-testid="add-option"
                  onClick={handleAddItem}
                  className="bg-secondary"
                >
                  <div className="bg-red mr-2 w-6 self-center">{addCircleBlack}</div>
                  <div className="text-base font-medium ">
                    {t('T_CREATE')} {t(label)}
                  </div>
                </AddPickListItem>
              )}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </>
  );
}

export const Divtext = styled('div')`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  justify-content: flex-start;
  flex-flow: row wrap;
  flex: 1 0 0;
  word-break: break-all;
`;

export const DropDownContainer = styled('div')`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  width: 100%;
  height: 44px;
  background: #ffffff;

  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    background-color: #f5f5f5;
  }
`;

export const DropDownHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfiniteScrollOuterDiv = styled('div')`
  width: 100%;
`;

export const DropDownListContainer = styled('div')``;

interface DropDownListProps {
  showAddOption: boolean;
}

export const DropDownList = styled('ul')<DropDownListProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0px;
  position: absolute;
  width: 100%;
  max-height: ${(props) => (props.showAddOption ? '390px' : '280px')};
  z-index: 1;
  left: 0;
  top: 45px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  overflow-y: auto;
`;

export const picklistStyled = css`
  display: flex;
  flex: 1 1 0;
  flex-flow: row wrap;
  align-items: center;
  height: 1.5rem

  list-style: none;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding: 9px 10px;
  cursor: pointer;
  width: 100%;
`;

export const AddPickListItem = styled('li')`
  border-top: 1px solid rgb(${colorLightGray6});
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const PickListItem = styled('li')`
  ${picklistStyled}
  &:hover {
    color: white;
    background: var(--color-bi-gray-700);
  }
`;

export const SearchPickListItem = styled('li')`
  ${picklistStyled}
`;

export const ImageContainer = styled.div`
  width: 1.5rem;
  height: 1rem;
  display: flex;
  justify-content: flex-start;
  color: green;
`;

export const CheckMark = styled.img`
  width: 1.5rem;
  height: 1rem;
`;

export const Text = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-start;
  flex-flow: row wrap;
`;

export const Image = styled.img`
  width: 8px;
  height: 8px;
`;

export default PickListWithApiSearch;
