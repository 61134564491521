import BaseAPI from './api';
import env from '../environment';
import {
  LoginUser,
  AuthenticationResponse,
  ErrorResponse,
  Auth3Response,
  AccessTokenResponse,
  LogoutRequestBody
} from '../app/models/auth/loginUser';
import authRequest, { accessTokenRequest } from './../app/authConstants';
import { HTTP_STATUS } from './constants/http.status';
import { fetchFromCookie, setCookie } from '../util/admin-utils';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

export interface SwitchContext {
  userOrg: String;
  userRole: String;
  tokenId: String;
}

export const authStep1 = (user: LoginUser): Promise<AuthenticationResponse> => {
  const headers = {
    'X-Username': user.username,
    'X-Password': user.password,
    ...globalHeaders
  };

  const response = API.post<AuthenticationResponse>(
    `${env.authpath}/authenticate`,
    {},
    { headers }
  ).then((res: { data: any }) => res?.data);
  return response;
};

export const authStep2 = async (step1Auth: AuthenticationResponse) => {
  const headers = { ...globalHeaders };
  const response = await API.post<AuthenticationResponse | ErrorResponse>(
    `${env.authpath}/authenticate`,
    step1Auth,
    {
      headers
    }
  ).then((res: any) => res);
  return response;
};

export const authStep3 = async (requestBody: AuthenticationResponse) => {
  const headers = { ...globalHeaders };
  const response = await API.post<Auth3Response>(`${env.authpath}/authenticate`, requestBody, {
    headers
  }).then((res: any) => res);
  return response;
};

export const authStep4 = async (requestBody: URLSearchParams) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  const response = await API.post(`${env.authpath}/authorize`, requestBody, {
    headers,
    maxRedirects: 0
  })
    .then((res: any) => res)
    .catch((res: any) => res);
  return response;
};

export const authStep5 = async (requestBody: URLSearchParams) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  const response = await API.post<AccessTokenResponse>(
    `${env.authpath}/access_token`,
    requestBody,
    { headers }
  ).then((res: any) => res);
  return response;
};

export const refreshToken = async (requestBody: URLSearchParams) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  const response = await API.post(`${env.authpath}/access_token`, requestBody, { headers }).then(
    (res: any) => res
  );
  return response;
};

export const logout = async (requestBody: LogoutRequestBody) => {
  const headers = {
    ...globalHeaders,
    apikey: env.apikey,
    iPlanetDirectoryPro: localStorage.getItem('token-id')!
  };
  const response = await API.post(`${env.authpath}/token/revoke`, requestBody, { headers }).then(
    (res: any) => res
  );
  return response;
};

export const switchSessionContext = (payload: SwitchContext | null, t?: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    if (!payload) {
      console.error(t('T_CONSOLE_ERROR_MSG'));
      reject({
        data: { issues: [{ details: `${t('T_SWITCH_SESSION_EMPTY_SESSION_MSG')}` }] },
        success: false
      });
    }

    const headers = {
      ...globalHeaders,
      // Authorization: `Bearer ${localStorage.getItem('access-token')}`
      Authorization: `Bearer ${fetchFromCookie('access_token')}`
    };
    const response = await API.post(`${env.authpath}/sessions`, payload, { headers }).then(
      (res: any) => res
    );
    const { status } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      // let tokenId: any = localStorage.getItem('token-id');
      let tokenId: any = fetchFromCookie('token-id');
      authRequest.set('csrf', tokenId);

      const resp = await authStep4(authRequest);
      // const code = /code=(.+?)&/.exec(resp.request.responseURL ?? '');
      // const extractedCode = code != null ? code[1] : '';
      const { authorizationCode } = resp;
      accessTokenRequest.set('code', authorizationCode);
      const lastResp = await authStep5(accessTokenRequest);

      // localStorage.setItem('access-token', lastResp.data.access_token);
      // localStorage.setItem('refresh-token', lastResp.data.refresh_token);
      // localStorage.setItem('code', authorizationCode);
      setCookie('access-token', lastResp.data.access_token);
      setCookie('refresh-token', lastResp.data.refresh_token);
      setCookie('code', authorizationCode);
    }
    resolve(response);
  });
};

export const getUserProfile = async () => {
  const headers = { ...globalHeaders };
  const response = await API.get(`${env.userManagementPath}/users/profile`, { headers }).then(
    (res: any) => res
  );
  return response;
};

export const getUserRol = async (userRol: string) => {
  const headers = { ...globalHeaders, 'Content-Type': 'application/json', Accept: '*/*' };
  const response = await API.get(`${env.accessControlPath}/roles/${userRol}`, {
    headers,
    data: {}
  }).then((res: any) => res);
  return response;
};

export const createRole = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.accessControlPath}/roles`, body, { headers }).then(
    (res: any) => res
  );
  return response;
};

export const updateUserProfile = async (userId: string, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.patch(`${env.userManagementPath}/users/${userId}`, body, {
    headers
  }).then((res) => res);
  return response;
};
