import {
    colorBiText, colorForGrayBackground, colorForLightGrayBackground,
    colorLightGray9, colorProductDisabled,
    colorProductPrimary,
    colorProductPrimaryHover, colorRed3, colorRed6, colorSecondaryHover,
    colorWhite
} from "../styles/globalVariables";
import {css} from "styled-components/macro";

export const updateTheme = (primaryColor = '', secondaryColor = '', hoverColor: string, striveColor: string): any => {
    return {
        primaryAppColor: primaryColor ? primaryColor : `rgb(${colorProductPrimary})`,
        whiteAppColor: `rgb(${colorWhite})`,
        primaryAppHoverColor: secondaryColor ? secondaryColor : `rgb(${colorProductPrimaryHover})`,
        disabledColor: secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`,
        breadcrumbTextColor: primaryColor ? primaryColor : `rgb(${colorLightGray9})`,
        sidebarBtnHoverBgColor: secondaryColor ? secondaryColor : `rgb(${colorWhite})`,
        sidebarBtnHoverTextColor: primaryColor ? primaryColor : `rgb(${colorProductPrimary})`,
        bgPrimaryStyleColor: {backgroundColor: primaryColor ? primaryColor : `rgb(${colorProductPrimary})`},
        bgPrimaryStyleStriveColor: {backgroundColor: striveColor ? striveColor : `rgb(${colorProductPrimary})`},
        bgColorStyleForLoggedInPage: {backgroundColor: secondaryColor ? secondaryColor : `rgb(${colorForLightGrayBackground})`},
        themeTextStyleColor: {color: primaryColor ? primaryColor : `rgb(${colorBiText})`},
        bgColorStyleForLoginPage: {backgroundColor: secondaryColor ? secondaryColor : `rgb(${colorProductPrimary})`},
        bgColorStyleForJsonEditor: {backgroundColor: secondaryColor ? `rgb(${colorWhite})` : `rgb(${colorForLightGrayBackground})`},
        bgHoverTextWhiteStyleColor: {color: `rgb(${colorWhite})`},
        signInBtnStyle: {
        primary: css`
            background-color: ${primaryColor ? primaryColor : `rgb(${colorProductPrimary})`};
            color: rgb(${colorWhite});
            border: 1px solid ${primaryColor ? primaryColor : `rgb(${colorProductPrimary})`};
        
            &:hover {
              background-color: ${secondaryColor ? hoverColor : `rgb(${colorProductPrimaryHover})`};
            }
        
            &:disabled {
              background-color: ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
              color: rgb(${colorWhite});
              border: 1px solid ${primaryColor ? primaryColor : `rgb(${colorProductDisabled})`};
        
              &:hover {
                background-color: ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
                color: rgb(${colorWhite});
                border: 1px solid ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
              }
            }
           ` ,
        secondary: css`
            background-color: rgb(${colorWhite});
            color: ${primaryColor ? primaryColor : `rgb(${colorProductPrimary})`};
            border: 1px solid ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
        
            &:hover {
              background-color: rgb(${colorSecondaryHover});
              color: ${secondaryColor ? hoverColor : `rgb(${colorProductPrimaryHover})`};
              border: 1px solid ${secondaryColor ? hoverColor : `rgb(${colorProductPrimaryHover})`};
            }
        
            &:disabled {
              background-color: rgb(${colorWhite});
              color: ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
              &:hover {
                background-color: rgb(${colorWhite});
                border: 1px solid ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
                color: ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
              }
            }
          `,
          tertiary: css`
            color: ${primaryColor ? primaryColor : `rgb(${colorProductPrimary})`};
            background-color: rgb(${colorWhite});
        
            &:hover {
              color: ${secondaryColor ? hoverColor : `rgb(${colorProductPrimaryHover})`};
            }
        
            &:disabled {
              color: ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
              &:hover {
                background-color: rgb(${colorWhite});
                color: ${secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`};
              }
            }
          `,
          danger: css`
            background-color: rgb(${colorWhite});
            color: rgb(${colorRed6});
            border: 1px solid rgb(${colorRed6});
        
            &:hover {
              background-color: rgb(${colorRed3});
            }
        
            &:disabled {
              border: 1px solid rgb(${colorRed3});
              color: rgb(${colorRed3});
              &:hover {
                background-color: rgb(${colorWhite});
                border: 1px solid rgb(${colorRed3});
                color: rgb(${colorRed3});
              }
            }
          `
        },
        secondaryHoverBgColor: {backgroundColor: secondaryColor ? secondaryColor: `rgb(${colorSecondaryHover})`},
        bgColorForMyAccountPage: {backgroundColor: secondaryColor ? secondaryColor: `rgb(${colorForGrayBackground})`},
        bgPrimaryDisabledStyleColor: {backgroundColor: secondaryColor ? secondaryColor : `rgb(${colorProductDisabled})`}
    }
};