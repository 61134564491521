import styled, { css } from 'styled-components/macro';
import {
  colorLightGray6,
  colorLightGray7,
  colorProductPrimary
} from '../../../styles/globalVariables';

const BiSearchIconInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px;
  border: 0.5px solid rgb(${colorLightGray7});
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  cursor: pointer;
`;

const SearchIconWrapper: any = styled.div`
  background-image: url(${(props: any) => props.searchIcon});
  background-repeat: no-repeat;
  background-position: center right 0px;
  height: 25px;
  width: 25px;
`;

export const SpinnerIconWrapper = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid rgb(${colorProductPrimary});
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SearchInput: any = styled.input<SearchInputProps>`
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  ::placeholder {
    color: rgb(${colorLightGray6});
  }
`;

type SearchInputProps = {
  searchIcon: any;
  onClickIcon?: Function;
  onChange?: Function;
  onBlur?: Function;
  autoFocus?: boolean;
  value: any;
  placeholder: string;
  isLoading?: boolean;
};

const BiSearchAndClick: React.FC<SearchInputProps> = ({
  searchIcon,
  onChange,
  onBlur,
  autoFocus,
  value,
  placeholder,
  onClickIcon,
  isLoading = false
}) => {
  const handleClickIcon = (event: any) => {
    if (onClickIcon) {
      onClickIcon(event);
    }
  };
  return (
    <BiSearchIconInputWrapper>
      <SearchInput
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        autoFocus={autoFocus}
        data-testId={`${placeholder}_search-field-click`}
      />
      {isLoading ? (
        <SpinnerIconWrapper></SpinnerIconWrapper>
      ) : (
        <SearchIconWrapper searchIcon={searchIcon} onClick={handleClickIcon} />
      )}
    </BiSearchIconInputWrapper>
  );
};

export default BiSearchAndClick;
