import { createSlice } from '@reduxjs/toolkit';

interface IPermission {
  apiPermission: string | never;
}
const apiPermissions: Array<string> = [];
export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: [],
    role: {
      name: '',
      description: '',
      mfa: false,
      locationHeuristics: false,
      permissions: [],
      apiPermissions
    }
  },
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
      state.role.apiPermissions = [];
      state.role.permissions.forEach((permission: IPermission) => {
        if (permission && permission.apiPermission) {
          state.role.apiPermissions.push(permission.apiPermission);
        }
      });
    }
  }
});

export const { setRoles, setRole } = rolesSlice.actions;

export default rolesSlice.reducer;
