import React from 'react';
import styled from 'styled-components/macro';

interface CheckboxProps {
  onChange?: React.MouseEventHandler<HTMLDivElement>;
  checked: boolean;
  className?: string;
  height?: string;
  width?: string;
  disabled?: boolean;
  testID?: string;
}

interface IconProps {
  disabled?: boolean;
}

const Icon = styled.svg<IconProps>`
  fill: none;
  stroke: ${({ disabled }) => (disabled ? '#595959' : '#FFFFFF')};
  strokewidth: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<CheckboxProps>`
  display: inline-block;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: ${({ height }) => (height ? height : '16px')};
  height: ${({ width }) => (width ? width : '16px')};
  background: ${({ checked, disabled }) =>
    checked && !disabled ? '#2c71ad' : disabled ? '#D9D9D9' : '#ffffff'};
  border-radius: 4px;
  transition: all 150ms;
  border: ${({ checked, disabled }) =>
    checked && !disabled
      ? '1px solid #0560A9'
      : disabled
      ? '1px solid #BFBFBF'
      : '1px solid #99b0c6'};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px red;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

function Checkbox({
  onChange,
  checked,
  className,
  height,
  width,
  disabled,
  testID
}: CheckboxProps) {
  return (
    <div className={`flex ${className}`}>
      <HiddenCheckbox checked={checked} onChange={() => onChange} />
      <StyledCheckbox
        checked={checked}
        height={height}
        width={width}
        disabled={disabled}
        onClick={onChange}
        data-testid={testID}
      >
        <Icon viewBox="0 0 24 24" disabled={disabled}>
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </div>
  );
}

export default Checkbox;
