import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from '../shared/ModalPortal/Modal';

import BiButton from '../primitives/buttons/BiButton.primitive';
import { useTheme } from '../../context/themeContext';

import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import ToastNotification from '../Reusable/ToastNotification';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import TemplateTable from '../shared/tables/TemplateTable';
import Checkbox from '../shared/Fields/Checkbox';

import { TableContainer } from '../Organizations/OrganizationsList';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import { AppConstants } from '../../constants/AppConstants';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { useTranslation } from 'react-i18next';
import { useDidMountEffect } from '../../util/customHooks';
import { getOrganization } from '../../http/organization-services';

import {
  getCategories,
  addCategoryToOrganization,
  getOrganizationCategories,
  deleteCategoryFromOrganization,
  deleteCategory
} from '../../http/configuration-services';
import Filter from './Filter';
import CategoriesTable from './CategoriesTable';
import { getChildOrganizations } from '../../http/organization-services';

import {
  getLoggedInOrg,
  getLoggedInOrg as getLoggedInUserOrg,
  getLoginUserRoles as getLoggedInUserRoles,
  getUserRoles
} from '../../util/admin-utils';
import AssignCategoryToOrg from './AssignCategoryToOrg';
import { cloneDeep, filter, findIndex, isEmpty, map, pull, pullAllBy, remove } from 'lodash';
import { getFailureMessage } from '../../util/ErrorUtil';

import {
  getConfigKeyOrg,
  configServiceFilters,
  setEmptyItemsList,
  updateOrgResponse,
  updateSelectedFilter,
  getUserLoggedInOrg
} from './Configutils';
import { ActionButtons } from '../Reusable/ActionButton';
import { deleteManifestItem, setManifest } from '../../app/migrationExportReducer';
import { MenuConstants } from '../../constants/MenuConstants';
import { EllipsisDiv } from '../../styles/globalStyles';
import ConfigKeyMenu from './ConfigKeyMenu';
import { setGlobalTableFilters } from '../../app/tableReducer';
import AddCircle from '../../assets/addCircle.svg';
import { Group, Row } from '../shared/tables/TemplateTableStyles';
import { setTableOptions } from '../../app/templateTableReducer';

/**
 * @parentId configuration service
 * @manager Categories Listing
 * @overview
 *   <section>
 *       <p>
 *           Categories listing page lists all the categories associated to the logged in organization.Categories listing page can be navigated from <b>Configuration Keys</b> listing page drop down menu toolbar.
 *           Categories listed are in paginated way. The default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & categories data.
 *       </p>
 *       <br>
 *       <p>
 *           The user can also navigate to <b>Categories List</b> through Organization screen, by click on context menu with option 'View Categories' of organization name row item.
 *           If the user is navigated from Organization screen to <b>Categories List</b>, the Categories assigned to organization will be listed.
 *       </p>
 *       <br>
 *       <p>
 *           The listed categories can also be assigned to Organization by clicking on checkbox of category row item, then in toolbar menu drop down select the organization to be assigned to.
 *           Actual assignment of category to organization happens on confirmation from user.
 *       </p>
 *       <br>
 *       <br>
 *        <p>
 *          Categories can be filtered based on,
 *          <ul>
 *              <li>
 *                  <b>Organization:</b>
 *                  <br>
 *                  <p>
 *                    Organiztion filter is a dropdown, which is single select, the dropdown lists the names of organizations, the user can scroll the list in dropdown to select the organization.
 *                    Or the user can search the organizaion name in the search box dropdown to get the list of organization names.
 *                    On apply of the filter the <b>Categories</b> listed are associated to organization.
 *                  </p>
 *                </li>
 *          </ul>
 *       </p>
 *       <br>
 *
 *        <p>
 *           Datagrid will display details of each Categories as row item with each column representing Category property.
 *           <br/>
 *           <ul>
 *               <li>Select Check Box</li>
 *                <li>Category Name</li>
 *               <li>Description</li>
 *           </ul>
 *           <b>Note:</b> Delete action is shown in context menu based on logged in user role API permission to delete Category. Actual deletion happens after user confirms on the deletion prompt.
 *       </p>
 *       <p>
 *           Sorting of Categories grid is supported on <i>Category Name</i>, <i>Description</i> column.The breadcrumb bar on the toolbar will reflect location of the user in the admin portal. Clicking on Category rowitem will drill down user to Category details page which will be displayed in readonly mode.
 *       </p>
 *     </section>
 *     <section>
 *       <h4>Failure Status Codes</h3>
 *       <p>
 *          This section describes the List Configuration keys Status Code information.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *            </tr>
 *             <tr>
 *               <td>400</td>
 *               <td>CS_SORT_UNKNOWN</td>
 *               <td>Unknown sort parameter name</td>
 *            </tr>
 *            <tr>
 *               <td>403</td>
 *               <td>CS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *            </tr>
 *            <tr>
 *               <td>500</td>
 *               <td>CS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *            </tr>
 *            <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *            </tr>
 *          </table>
 *       </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of categories listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for Categories listing</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Configuration Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for listing configuration keys page</p>
 *       <br/>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/categories</td>
 *               <td>GET</td>
 *               <td>configuration-service.category.list</td>
 *               <td>Yes</td>
 *           </tr>
 *            <tr>
 *               <td>/organizations/{organization-id}/categories/{category-id}</td>
 *               <td>POST</td>
 *               <td>configuration-service.organization-category.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>/organizations/{organization-id}/categories</td>
 *               <td>GET</td>
 *               <td>configuration-service.organization-category.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for Categories listing page</p>
 *   </section>
 */

interface CategoryListProps {
  type?: 'listing' | 'migrationSelection' | 'migrationPreview';
  toggleModal?: Function;
}

const CategoriesList = ({ type = 'listing', toggleModal = () => {} }: CategoryListProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toastData = useAppSelector((state) => state.toast);
  const queryParams = useParams();
  const [isOrgFlow, setisOrgFlow] = useState(queryParams.id ? true : false);
  const [isLoading, setIsLoading] = useState(true);
  const [categoriesList, setCategoriesList] = useState<any>([]);
  const apiPermissions: any = useAppSelector((state) => state.initialLoadData?.apiPermissions);
  const userProfileInfo: any = useAppSelector((state) => state.userManagement.userProfileInfo);
  const [orgData, setOrgData] = useState<any>([]);
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const selectedCategoriesRef = useRef<any>();
  const setSelectedCategoriesRef = (payload: any) => {
    selectedCategoriesRef.current = payload;
  };

  const [enableAssignToOrg, setEnableAssignToOrg] = useState(false);
  const [toggledCategory, setToggledCategory] = useState<any>();
  const [confirmOrgSelection, setConfirmOrgSelection] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState({ key: '', display: '' });
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({ id: '' });
  const [currentActivePage, setCurrentActivePage] = useState(0);
  const [tablePage, setTablePage] = useState({});

  const [initialCall, setInitialCall] = useState(false);

  //Migration
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const manifestCategoriesData = useAppSelector(
    (state: any) => state.migrationExport?.manifest?.globalCategories
  );

  const globalParamObj: any = useAppSelector((state) => state.tableSortSearchSlice.filters);
  const globalTableFilterRoute: any = useAppSelector((state) => state.tableSortSearchSlice.route);
  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);

  const resetTable = useRef(false);

  const setResetTable = (value: boolean) => {
    resetTable.current = value;
  };

  const [paramObj, setParamObj] = useState({
    orgFilter: ''
  });


  const [loadNextData, setLoadNextData] = useState<any>({
    orgPaging: false,
    categoriesPaging: false,
    searchOrgName: '',
    searchOrg: false
  });

  useEffect(() => {
    const init = async () => {
      if (type === 'listing') {
        setFilterData();
      }
    };
    if (type === 'migrationPreview') {
      setIsLoading(false);
      return;
    }
    init();

    dispatch(setURLPaths([{ key: '/config-category-list', label: 'Categories' }]));

    return () => {
      if (selectedCategoriesRef.current || orgFilterRef.current) {
        dispatch(
          setGlobalTableFilters({
            selectedOrg: orgFilterRef.current,
            selectedCategories: selectedCategoriesRef.current
          })
        );
      }
    };
  }, []);

  useDidMountEffect(() => {
    const { tableFilters, searchText, currentPage, size, sortName, sortType } =
      globalFilters['categories-list'];
    applyFilters(searchText, currentPage, size, sortName, sortType, tableFilters);
  }, [globalFilters]);

  useDidMountEffect(() => {
    if (type !== 'migrationPreview') {
      setIsLoading(true);
    }
  }, [paramObj]);

  useEffect(() => {
    updateCategoriesList();
  }, [toggledCategory]);

  useEffect(() => {
    setEnableAssignToOrg(!!selectedCategories.length);
    setSelectedCategoriesRef(selectedCategories);
  }, [selectedCategories]);

  useDidMountEffect(async () => {
    if (loadNextData.orgPaging && !loadNextData.searchOrg) {
      loadOrgData();
    }
    if (loadNextData.searchOrg && !loadNextData.orgPaging) {
      loadOrgData();
    }
  }, [loadNextData]);

  const loadOrgData = async () => {
    let orgResponse = await getConfigKeyOrg(loadNextData, filterList, userProfileInfo);
    let initialFilterList: any = [...filterList];
    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'orgFilter') {
        return updateOrgResponse(obj, orgResponse, loadNextData, setLoadNextData);
      } else {
        return { ...obj };
      }
    });
    setOrgData(orgResponse.data);
    setFilterList(currentFilterList);
    updateSelectedFilter(
      filterList,
      filterPillsData,
      queryParams.id,
      fetchMoreOrgData,
      setFilterPillsData,
      updateFilterList,
    );
  };


  const fetchMoreOrgData = () => {
    setLoadNextData((prevState: any) => ({ ...prevState, orgPaging: true }));
  };

  const updateFilterList = (currentFilterList: any) => {
    setFilterList(currentFilterList);
    const filterList: any = {};
    currentFilterList.forEach((filter: any) => {
      if (filter?.selectedFilter?.length) {
        filterList[filter.id] = filter.selectedFilter;
      }
    });
    const {
      tableFilters = '',
      searchText = '',
      currentPage: filterCurrentPage = AppConstants.DEFAULT_PAGE,
      size: filterSize = AppConstants.DEFAULT_PAGE_SIZE,
      sortName = '',
      sortType = ''
    } = globalFilters['categories-list'] || {};

    dispatch(
      setTableOptions({
        ['categories-list']: {
          searchText: searchText,
          currentPage: filterCurrentPage,
          size: filterSize,
          sortName: sortName,
          sortType: sortType,
          tableFilters: isEmpty(filterList) ? tableFilters : filterList,
        }
      })
    );
  }

  const searchOrgData = (data: any) => {
    setEmptyItemsList('orgFilter', filterList, setFilterList);
    setLoadNextData((prevState: any) => ({ ...prevState, searchOrgName: data, searchOrg: true }));
  };

  const categoryFilters = filter(configServiceFilters, { id: 'orgFilter' }).map((filter: any) => {
    return {
      ...filter,
      fetchMoreData: fetchMoreOrgData,
      searchData: searchOrgData,
      searchPlaceholder: t('T_SEARCH_ORG_NAME')
    };
  });

  const [filterList, setFilterList] = useState<Array<any>>(categoryFilters);
  const [filterPillsData, setFilterPillsData] = useState<Array<any>>([]);

  const orgFilterRef = useRef();

  const setOrgFilterRef = (payload: any) => {
    orgFilterRef.current = payload;
  };

  

  const setFilterData = async () => {
    let [orgResponse, loggedInOrgResponse] = await Promise.all([
      getConfigKeyOrg(loadNextData, filterList, userProfileInfo),
      getUserLoggedInOrg(userProfileInfo)
    ]);

    if (orgResponse && orgResponse.data) {
      orgResponse.data = [...orgResponse.data, loggedInOrgResponse];
      orgResponse.data = orgResponse.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
    }

    let initialFilterList: any = [...filterList];
    const currentFilterList = initialFilterList.map((obj: any) => {
      if (obj.id === 'orgFilter') {
        return updateOrgResponse(obj, orgResponse, loadNextData, setLoadNextData);
      } else {
        return { ...obj };
      }
    });
    if (orgResponse && orgResponse.data) {
      setOrgData(orgResponse.data);
    }
    if(queryParams.id){
      setFilterList(currentFilterList);
      updateSelectedFilter(
        filterList,
        [],
        queryParams.id,
        fetchMoreOrgData,
        setFilterPillsData,
        updateFilterList,
      );
    }else{
      updateFilterList(currentFilterList);
    }
    
  };

  const addCategoriesToOrganization = async (selectedOrg: any) => {
    setIsLoading(true);
    let error = false,
      toastMessage = `${t('T_CATEGORIES_ASSIGNED_SUCCESS')}`;
    let response = await Promise.all(
      selectedCategories.map((category: any) => {
        return addCategoryToOrganization(selectedOrg.key, category?.id);
      })
    );
    const responseObj = response[0];
    const { status, data } = responseObj;
    if (status === HTTP_STATUS.HTTP_CREATED) {
      error = false;
      toastMessage = `${t('T_CATEGORIES_ASSIGNED_SUCCESS')}`;
    } else if (status === HTTP_STATUS.HTTP_BAD_REQUEST) {
      const { issues = [] } = data;
      if (issues.length && issues[0].code === 'CS_PARAM_DUPLICATE') {
        error = false;
        toastMessage = `${t('T_CATEGORIES_ASSIGNED_SUCCESS')}`;
      } else {
        error = true;
        toastMessage = getFailureMessage(responseObj);
      }
    } else {
      error = true;
      toastMessage = getFailureMessage(responseObj);
    }
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType: error ? 'error' : 'success'
      })
    );
    setIsLoading(false);
    if (!error) {
      navigate('/config-category-list');
    }
    setConfirmOrgSelection(false);
  };

  const checkConfigPermission = (permissionType: string) => {
    let hasPermission = false;
    const permissionMap: any = {
      sysSettingList: AppConstants.PERMISSION_CONFIG_SERVICE_SETTING_LIST,
      orgsysSettingList: AppConstants.PERMISSION_CONFIG_SERVICE_ORG_SETTING_LIST,
      sysSettingUpdate: AppConstants.PERMISSION_CONFIG_KEY_SETTING_UPDATE,
      orgsysSettingUpdate: AppConstants.PERMISSION_CONFIG_KEY_SETTING_UPDATE
    };
    if (isOrgFlow) {
      if (apiPermissions[permissionMap[`org${permissionType}`]]) {
        hasPermission = true;
      }
    } else {
      if (apiPermissions[permissionMap[permissionType]]) {
        hasPermission = true;
      }
    }
    return hasPermission;
  };

  const updateCheckedCategories = (categoriesList: any) => {
    const selectedCatIdArr = map(selectedCategories, 'id');
    if (!selectedCatIdArr.length) {
      setCategoriesList(categoriesList);
    } else {
      const updatedCategories = categoriesList.map((category: any) => {
        if (selectedCatIdArr.includes(category.id)) {
          return { ...category, checked: true };
        } else {
          return { ...category };
        }
      });
      setCategoriesList([...updatedCategories]);
    }
  };

  const getConfigCategories = async (
    page: number = AppConstants.DEFAULT_PAGE,
    size: number = type === 'listing'
      ? AppConstants.DEFAULT_PAGE_SIZE
      : AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE,
    sortOrder: string = AppConstants.DEFAULT_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC,
    filter: any = paramObj
  ) => {
    const { orgFilter = '' } = filter;
    const response = await getCategories(orgFilter, page, size, [`${sortOrder},${sortType}`]);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: categoriesList = [], paging } = data;
      // setTablePageRef(paging);
      setTablePage(paging);
      //setCategoriesList(categoriesList);
      updateCheckedCategories(categoriesList);
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const refreshTableData = (
    name: string,
    page: number,
    size: number,
    sortOrder: string,
    sortType: string,
    filters: any
  ) => {
    if (sortOrder && sortType) {
      setTableValues(name, page, size, sortOrder, sortType, filters);
    } else {
      setTableValues(
        name,
        page,
        size,
        AppConstants.DEFAULT_SORT_BY,
        AppConstants.DEFAULT_SORT_ORDER,
        filters
      );
    }
  };

  const setTableValues = async (
    name: string,
    page: number,
    size: number,
    sortOrder: string,
    sortType: string,
    filters: any
  ) => {
    let localParamObj = { orgFilter: '' }; //For Initial or first call, filters are being sent from TableGrid to avoid recall of APIs;
    if (
      !initialCall &&
      filters?.selectedOrg?.orgFilter &&
      !isEmpty(filters?.selectedOrg?.orgFilter)
    ) {
      localParamObj.orgFilter = filters?.selectedOrg?.orgFilter[0]?.id;
    }
    setInitialCall(true);
    setIsLoading(true);

    await getConfigCategories(
      page,
      size,
      sortOrder,
      sortType,
      initialCall
        ? paramObj
        : localParamObj.orgFilter
        ? localParamObj
        : { orgFilter: queryParams.id }
    );
  };

  const configkeySelector = (data: any) => {
    if (type === 'listing') {
      checkConfigPermission('sysSettingUpdate')
        ? navigate(`/config-category/edit/${data.id}`)
        : dispatch(
            setToastData({
              toastMessage: t('T_CONFIG_PERMISSION_KEY_ERROR'),
              isToastActive: true,
              toastType: 'error'
            })
          );
    }
  };

  const toggleCheckbox = (data: any) => {
    if (type === 'listing') {
      setToggledCategory(data);
      return;
    }

    const { id } = data;

    let existingArr: any = cloneDeep(selectedItems);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      existingArr.splice(existingId, 1);
      setSelectedItems(existingArr);
      return;
    }
    existingArr.push(data);
    setSelectedItems(existingArr);
  };

  const updateCategoriesList = (data: any = toggledCategory) => {
    if (data) {
      const categoryRowChecked = { ...data, checked: !data.checked };
      const categories = categoriesList.map((category: any) =>
        category.id === data.id ? categoryRowChecked : category
      );
      if (categoryRowChecked.checked) {
        setSelectedCategories([...selectedCategories, categoryRowChecked]);
      } else {
        setSelectedCategories(
          filter(selectedCategories, (category: any) => {
            return category.id !== data.id;
          })
        );
      }
      //const checkedcategoriesList = filter(categories, { checked: true });
      //setSelectedCategories([...selectedCategories, ...checkedcategoriesList]);
      setCategoriesList([...categories]);
    }
  };

  const updatePillsData = (appliedFilters: any) => {
    const appliedOrgFilter = appliedFilters['orgFilter'] ? appliedFilters['orgFilter'] : [];
    const pillsData: any = appliedOrgFilter.length
      ? [
          {
            name: appliedOrgFilter[0].name,
            label: 'Organization',
            id: 'orgFilter'
            //hideIcon: queryParams.id ? true : false
          }
        ]
      : [];
    return pillsData;
  };

  const updateFilterListSelected = (appliedFilters: any) => {
    const initialFilterList: any = [...filterList];

    const updatedFilterList = initialFilterList.map((filterObj: any) => {
      const selectedFilter = appliedFilters[filterObj.id] ? appliedFilters[filterObj.id] : [];
      return { ...filterObj, selectedFilter };
    });

    return updatedFilterList;
  };

  const updateParamObj = (appliedFilters: any) => {
    const initialParamObj: any = { ...paramObj };
    const initialFilterList: any = [...filterList];
    initialParamObj['orgFilter'] = map(appliedFilters['orgFilter'], 'id').join(',');

    return initialParamObj;
  };

  const applyFilters = async (search: string = '',
  page: number,
  size: number,
  sortOrder: string = '',
  sortType: string = '',
  filters: any) => {
    // setPagingObj({
    //   ...pagingObj,
    //   size: AppConstants.DEFAULT_PAGE_SIZE,
    //   currentPage: AppConstants.DEFAULT_PAGE
    // });
    setResetTable(true);
    setOrgFilterRef(filters);
    setSelectedCategories([]);
    setFilterPillsData(updatePillsData(filters));
    setFilterList(updateFilterListSelected(filters));
    setParamObj(updateParamObj(filters));
    await getConfigCategories(page, size, sortOrder, sortType, updateParamObj(filters));
  };

  const setFilters = (appliedFilters: any) => {
    const { tableFilters, searchText, currentPage, size, sortName, sortType } =
      globalFilters['categories-list'];
    dispatch(
      setTableOptions({
        ['categories-list']: {
          searchText: searchText,
          currentPage: AppConstants.DEFAULT_PAGE,
          size: size,
          sortName: sortName,
          sortType: sortType,
          tableFilters: appliedFilters
        }
      })
    );
  }

  const removeFromPillsFilter = async (appliedFilters: any) => {
    if (queryParams.id) {
      navigate('/config-category-list');
    }
    setOrgFilterRef(appliedFilters);
    dispatch(
      setTableOptions({
        ['categories-list']: {
          ...globalFilters['categories-list'],
          tableFilters: {}
        }
      })
    );
    setResetTable(true);
    setSelectedCategories([]);
    setFilterPillsData([]);
    setFilterList(updateFilterListSelected(appliedFilters));
    setParamObj(updateParamObj(appliedFilters));
    await getConfigCategories(...Array(4), updateParamObj(appliedFilters));
  };

  const onOrgSelected = (selectedOrg: any) => {
    setSelectedOrg(selectedOrg);
    setConfirmOrgSelection(true);
  };

  const onOrgConfirmSelection = () => {
    addCategoriesToOrganization(selectedOrg);
  };

  const checkDeleteConfirm = (selectedCategory: any, currentActivePage: any = 0) => {
    if (type !== 'listing') {
      dispatch(
        deleteManifestItem({
          key: 'globalCategories',
          id: categoriesList[selectedCategory + currentActivePage]?.id
        })
      );
      return;
    }
    let isDeleteAllowed = !!apiPermissions[AppConstants.PERMISSION_CONFIG_SERVICE_CATEGORY_DELETE];
    const { orgFilter } = paramObj;
    if (orgFilter) {
      isDeleteAllowed =
        !!apiPermissions[AppConstants.PERMISSION_CONFIG_SERVICE_ORG_CATEGORY_DELETE];
    }
    if (isDeleteAllowed) {
      setSelectedCategory(selectedCategory);
      setDeleteConfirmation(true);
    } else {
      dispatch(
        setToastData({
          toastMessage: `${t('T_USER_NO_PERMISSION_ACTION_MESSAGE')}`,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const deleteCategoryList = async () => {
    const { orgFilter } = paramObj;
    setIsLoading(true);
    const { id: categoryId } = selectedCategory;
    let response,
      error = false,
      toastMessage = `${t('T_CATEGORY_REMOVED_FROM_ORG')}`;
    if (orgFilter) {
      response = await deleteCategoryFromOrganization(orgFilter, categoryId);
      const { status, data } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
      } else {
        error = !error;
        toastMessage = getFailureMessage(response);
      }
    } else {
      response = await deleteCategory(categoryId);
      const { status, data } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        toastMessage = `${t('T_CATEGORY_DELETED')}`;
      } else {
        error = !error;
        toastMessage = getFailureMessage(response);
      }
    }
    setDeleteConfirmation(false);
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType: error ? 'error' : 'success'
      })
    );
    getConfigCategories();
  };

  useEffect(() => {
    if (type === 'migrationPreview') {
      setCategoriesList(selectedItems);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (manifestCategoriesData?.length) {
      setSelectedItems(manifestCategoriesData);
    }
  }, [manifestCategoriesData]);

  const saveSelection = () => {
    dispatch(setManifest({ key: 'globalCategories', value: selectedItems }));
    toggleModal();
  };

  // useEffect(() => {
  //   const { currentPage, size } = pagingObj;
  //   if (type === 'migrationPreview') {
  //     setCurrentActivePage(currentPage * size - size);
  //   }
  // }, [pagingObj.currentPage]);

  const toggleOverWrite = (data: any) => {
    const { id } = data;

    let existingArr: any = cloneDeep(manifestCategoriesData);
    let existingId = findIndex(existingArr, function (item: any) {
      return item.id === id;
    });

    if (existingId !== -1) {
      if (existingArr[existingId]?.overWrite) {
        delete existingArr[existingId].overWrite;
      } else {
        existingArr[existingId].overWrite = true;
      }
      dispatch(setManifest({ key: 'globalCategories', value: existingArr }));
      return;
    }
  };

  const isCheckboxSelected = (data: any) => {
    const { id } = data;

    return (
      findIndex(selectedItems, function (item: any) {
        return item.id === id;
      }) !== -1
    );
  };

  const contextMenuHandler = async (key: string, data: any) => {
    const isDeleteAllowed =
      !!apiPermissions[AppConstants.PERMISSION_CONFIG_SERVICE_CATEGORY_DELETE];
    if (key === MenuConstants.CONFIG_KEY_CATEGORY_DELETE && isDeleteAllowed) {
      checkDeleteConfirm(data);
    }
  };
  const toggleCategoriesList = (data: any, toggleCheckbox: Function, categoriesList: any) => {
    toggleCheckbox(data);
  };

  useEffect(() => {
    if (['migrationSelection', 'migrationPreview'].includes(type)) {
      setTableHeaders(categoryHeaderBuilder());
    }
  }, [selectedItems]);

  const categoryHeaderBuilder = () => {
    let categoriesHeaders: Array<ITableHeader> = [
      {
        key: 'checked',
        description: '',
        sortEnabled: false,
        width: type === 'listing' ? '5%' : '10%',
        sortingInformation: {
          order: ''
        },
        render: (data: any, index: any) => {
          return (
            <Checkbox
              className="mx-auto"
              height="20px"
              width="20px"
              checked={type === 'listing' ? data.checked : isCheckboxSelected(data)}
              onChange={() => toggleCategoriesList(data, toggleCheckbox, categoriesList)}
              testID="addModuleCheckbox"
            />
          );
        },
        styles: {
          rowStyle: { padding: '0px' }
        }
      },

      {
        key: 'name',
        description: 'T_CATEGORY_NAME',
        sortEnabled: type !== 'migrationPreview',
        width: type === 'migrationPreview' ? '40%' : '45%',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return <EllipsisDiv title={data?.name}>{data?.name}</EllipsisDiv>;
        },
        styles: {
          borderRight: 'none'
        }
      },

      {
        key: 'description',
        description: 'T_CATEGORY_KEY_DESCRIPTION',
        sortEnabled: type !== 'migrationPreview',
        width: type === 'listing' ? '46%' : type === 'migrationPreview' ? '35%' : '45%',
        clickable: true,
        sortingInformation: {
          order: ''
        },
        render: (data: any) => {
          return <EllipsisDiv title={data?.description}>{data?.description}</EllipsisDiv>;
        }
      }
    ];

    switch (type) {
      case 'listing':
        categoriesHeaders.splice(-1, 0, {
          key: 'options',
          description: '',
          sortEnabled: false,
          width: '4%',
          clickable: false,
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any) => {
            return (
              <div className="hover:bg-[#d9d9d9]" style={{ borderRadius: '10px' }}>
                <ConfigKeyMenu
                  dropdownMenu={false}
                  onOptionClick={(key: string) => contextMenuHandler(key, data)}
                />
              </div>
            );
          },
          styles: {
            paddingRight: '0',
            paddingLeft: '0',
            borderLeft: 'none',
            rowStyle: { padding: '0px' }
          }
        });

        break;
      case 'migrationPreview':
        categoriesHeaders.shift();

        categoriesHeaders.push({
          key: 'overWrite',
          description: 'T_CONFIG_KEY_ALLOW_OVERRIDE',
          sortEnabled: false,
          width: '15%',
          sortingInformation: {
            order: ''
          },
          nastedData: true,
          render: (data: any, index: any) => {
            return (
              <>
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  onChange={() => {
                    toggleOverWrite(data);
                  }}
                  checked={data?.overWrite}
                  testID="allowOverWriteCheckbox"
                />
              </>
            );
          }
        });
        categoriesHeaders.push({
          key: 'buttons',
          description: t('T_ACTIONS'),
          sortEnabled: false,
          width: '10%',
          sortingInformation: {
            order: ''
          },
          styles: {
            justifyContent: 'center'
          }
        });

        break;
    }

    return categoriesHeaders;
  };

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(categoryHeaderBuilder());

  useEffect(() => {
    if (
      globalParamObj?.selectedOrg &&
      !isEmpty(globalParamObj?.selectedOrg) &&
      location.pathname === globalTableFilterRoute
    ) {
      const { tableFilters, searchText, currentPage, size, sortName, sortType } =
      globalFilters['categories-list'];
      applyFilters(searchText, currentPage, size, sortName, sortType, globalParamObj?.selectedOrg);
    }
  }, [globalParamObj?.selectedOrg, globalTableFilterRoute]);

  useEffect(() => {
    if (
      globalParamObj?.selectedCategories?.length &&
      location.pathname === globalTableFilterRoute
    ) {
      if (categoriesList.length) {
        let categoriesListClone = cloneDeep(categoriesList);
        globalParamObj?.selectedCategories.forEach((data: any) => {
          if (data) {
            const categoryRowChecked = { ...data, checked: !data.checked };

            categoriesListClone = categoriesListClone.map((category: any) =>
              category.id === data.id ? categoryRowChecked : category
            );
          }
        });
        setCategoriesList([...categoriesListClone]);
        setSelectedCategories(globalParamObj?.selectedCategories);
        dispatch(
          setGlobalTableFilters({
            ...globalParamObj,
            selectedCategories: []
          })
        );
      }
    }
  }, [categoriesList, globalParamObj, globalTableFilterRoute]);

  return (
    <LoaderContainer isLoading={isLoading}>
      {deleteConfirmation && (
        <Modal
          overflowVisible={true}
          showCloseIcon={true}
          width="30rem"
          showHeader={true}
          headerText={t('T_CATEGORY_DELETE_CONFIRMATION_HEADER')}
          toggleVisiblity={(value: boolean) => {
            setDeleteConfirmation(false);
          }}
          isVisible={deleteConfirmation}
          body={
            <>
              <div className="mb-5 flex flex-col">
                <div className="">
                  {paramObj.orgFilter
                    ? t('T_CATEGORY_DELETE_CONFIRMATION_ORG')
                    : t('T_CATEGORY_DELETE_CONFIRMATION')}
                </div>
              </div>

              <ModalButton>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    setDeleteConfirmation(false);
                  }}
                >
                  {t('T_CANCEL')}
                </BiButton>
                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    deleteCategoryList();
                  }}
                  // disabled={!permissions.create}
                >
                  {t('T_CATEGORY_DELETE_CONFIRMATION_PROMPT_YES')}
                </BiButton>
              </ModalButton>
            </>
          }
        />
      )}
      {confirmOrgSelection && (
        <Modal
          overflowVisible={true}
          showCloseIcon={true}
          width="30rem"
          showHeader={true}
          headerText={t('T_CONFIRM_ASSIGNMENT')}
          toggleVisiblity={(value: boolean) => {
            setConfirmOrgSelection(false);
          }}
          isVisible={confirmOrgSelection}
          body={
            <>
              <div className="flex flex-col">
                <div className="">{t('T_CATEGORIES_ASSIGNED_TO')}</div>
                <div className="break-all">{selectedOrg?.display}</div>
              </div>
              <div className="mb-8 mt-8 flex">
                <OrderedList className={'ml-4'} type="1">
                  {selectedCategories.map((category: any) => {
                    return <li className="break-all">{category.name}</li>;
                  })}
                </OrderedList>
              </div>
              <ModalButton>
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    setConfirmOrgSelection(false);
                  }}
                >
                  {t('T_CANCEL')}
                </BiButton>
                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    onOrgConfirmSelection();
                  }}
                  // disabled={!permissions.create}
                >
                  {t('T_CONFIRM')}
                </BiButton>
              </ModalButton>
            </>
          }
        />
      )}
      <TableContainer className={`${type !== 'listing' && '!pb-0'}`}>
        {type === 'listing' ? (
          <MenuButtonsPortal>
            <BiButton
              className={'mr-2 text-primary'}
              type="button"
              onClick={() => {
                navigate('/config-key-list');
              }}
            >
              {t('T_BACK_TO_CONFIGURATION_KEYS')}
            </BiButton>
            <BiButton
              className={'flex flex-row bg-primary text-white'}
              type="button"
              onClick={() => {
                navigate('/config-category/create');
              }}
              // disabled={!permissions.create}
            >
              <img src={AddCircle} className="mr-2 w-6 text-center"></img>
              {t('T_CATEGORY_NEW')}
            </BiButton>
          </MenuButtonsPortal>
        ) : (
          ''
        )}
        <div className="h-full">
          <div
            className={`w-full pb-11 ${type !== 'listing' && '!pb-0'}`}
            style={{ height: 'inherit' }}
          >
            {type === 'listing' ? (
              <>
                <Row justifyContent="flex-start" className="float-left">
                  <Group width="">
                    <Filter
                      filtersList={filterList}
                      applyFilters={setFilters}
                      pillsData={filterPillsData}
                      removeFilter={removeFromPillsFilter}
                      defaultPillsLabel={t('T_CONFIG_CATEGORIES_FILTERS_DEFAULT_VIEW')}
                    />
                  </Group>
                </Row>
                <Row justifyContent="flex-start" className="float-right">
                  <Group width="">
                    <AssignCategoryToOrg
                      filtersList={filterList}
                      orgList={orgData}
                      onOrgSelected={onOrgSelected}
                      enableAssignToOrg={enableAssignToOrg}
                      selectedCategories={selectedCategories}
                      searchData={searchOrgData}
                      searchable={true}
                      fetchMoreData={fetchMoreOrgData}
                      hasMore={true}
                    />
                  </Group>
                </Row>
              </>
            ) : (
              ''
            )}
            {/* 
            <CategoriesTable
              filterList={filterList}
              categoriesList={categoriesList}
              refreshTableData={refreshTableData}
              configkeySelector={configkeySelector}
              templateSelector={configkeySelector}
              cellSelector={configkeySelector}
              tablePage={tablePage}
              //deleteCategory={deleteCategoryList}
              deleteCategory={checkDeleteConfirm}
              toggleCheckbox={toggleCheckbox}
              type={type}
              selectedItems={selectedItems}
              toggleOverWrite={toggleOverWrite}
            /> */}

            <TemplateTable
              tableData={
                type === 'migrationPreview'
                  ? categoriesList.slice(
                      currentActivePage,
                      currentActivePage + AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
                    )
                  : categoriesList
              }
              onRefreshTableData={refreshTableData}
              size={
                type === 'migrationPreview'
                  ? AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE
                  : globalFilters?.size?.toString()
              }
              currentPage={
                type === 'migrationPreview' ? currentActivePage : globalFilters?.currentPage
              }
              setCurrentPage={(page: any) => {
                setCurrentActivePage(parseInt(page));
              }}
              cellSelector={configkeySelector}
              templateSelector={configkeySelector}
              tableHeaders={tableHeaders}
              setTableHeaders={setTableHeaders}
              pagingData={tablePage}
              containerClass="mt-0"
              isSearchable={false}
              showDeleteButton={true}
              theme={useTheme().theme}
              tablePage="categories-list"
              isLocalPaginator={type === 'migrationPreview'}
              totalDataLength={categoriesList.length}
              isPaginated={type !== 'migrationPreview'}
              disablePageSize={type !== 'listing'}
              deleteOnClick={checkDeleteConfirm}
              searchPlaceholder={t('T_SEARCH_ORG_NAME') || ''}
              globalFilters={globalFilters}
            />

            {type === 'migrationSelection' ? (
              <div className="w-full">
                <ActionButtons
                  primaryButtonLabel={t('T_SAVE')}
                  primaryButtonAction={saveSelection}
                  secondaryButtonLabel={t('T_CANCEL')}
                  secondaryButtonAction={toggleModal}
                  primaryButtonWidth="full"
                  primaryButtonClass={'px-4'}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </TableContainer>
    </LoaderContainer>
  );
};

export const OrderedList = styled('ol')`
  list-style: auto;
`;

export const ModalButton = styled('div')`
  display: flex;
  justify-content: end;
`;

export default CategoriesList;
