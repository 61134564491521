// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { Children, useEffect, useState } from 'react';
import Card from '../shared/cards/Card';
import { useNavigate, useParams } from 'react-router-dom';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import styled from 'styled-components/macro';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import userIcon from '../../assets/user-group.svg';
import consentIcon from '../../assets/consentIcon.svg';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import InputField from '../shared/Fields/InputField';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import RadioButton from '../shared/Fields/RadioButton';
import { RouteConstants } from '../../constants/RouteConstants';
import SelectField from '../shared/Fields/SelectField';
import BiButton from '../primitives/buttons/BiButton.primitive';
import {
  createConsentTemplate,
  listFieldGropus,
  updateConsentTemplate,
  createNewVersiononsentTemplate,
  removeAttachmentsFromConsent,
  updateAttachmentsOfConsent,
  addAttachmentsToConsent
} from '../../http/access-management-service';
import { getSystemSettings } from '../../http/configuration-services';
import { AppConstants } from '../../constants/AppConstants';
import ConsentAttachment from './consentAttachment';
import { createSignedURL, uploadFile } from '../../http/asset-management-services';
import { getGlobalBucketName } from '../../util/AppSetting';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import { setToastData } from '../../app/toastReducer';
import { getFailureMessage } from '../../util/ErrorUtil';
import { setConsentTemplate } from '../../app/consentReducer';
import {
  getPurpose,
  getCodableValue,
  getCodableObject,
  getPurposeObject,
  getCodeFromCodable,
  removeArrayElement,
  getPickListItems,
  getCategoryObject,
  GetObjectFromData,
  sortArray
} from '../../util/consent-utils';
import TemplateStatusDetails from './templateStatusDetails';
import {
  find,
  compact,
  uniq,
  filter,
  isEqual,
  some,
  findIndex,
  isEmpty,
  differenceWith
} from 'lodash';
import { getUsers } from '../../http/user-management-services';
import { getUserName } from '../../util/admin-utils';
import Modal from '../shared/ModalPortal/Modal';
import PaperClipIcon from '@heroicons/react/outline/PaperClipIcon';
import PillsButton from '../shared/Fields/PillsButton';
import { consentNameValidation, validateName } from '../../util/validators';
import { Label } from '../AccountProfile/ProfileStyles';
import Asterix from '../../assets/asterix.svg';
import { PlusCircleIcon } from '@heroicons/react/solid';
import ToastNotification from '../Reusable/ToastNotification';
import AuditInfo from '../Common/AuditInfo';

function CardSection({ children }: any) {
  return (
    <div
      className={`flex w-full flex-col justify-start  overflow-visible bg-gray-100`}
      style={useTheme().theme.bgColorStyleForLoggedInPage}
    >
      <div className="flex w-full">
        <div className="flex w-full">
          <div className="p-2"></div>
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  );
}

interface ConsentTemplateDetailsProps {
  isCreateNew?: boolean;
}

const defaultConsetState = {
  name: '',
  category: [],
  type: AppConstants.DEFAULT_CONSENT_TYPE,
  purpose: [],
  status: '',
  action: [],
  fieldGroups: [],
  scope: ''
};

/**
 * @parentId consent-management
 * @manager Create, View, Edit - Consent Template
 * @overview Create, View, Edit of Consent Template
 *   <section>
 *       <p>
 *           <b>View Consent Template</b><br/>
 *           Clicking on Consent Template row on the consent template listing page will take user to View Consent Template Page in READ-ONLY mode. Clicking on <b>Back to Consent Template List</b> will take the user to Consent Template listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i>
 *           <br/><br/>
 *
 *           <b>Edit Consent Template</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Consent Name, Category, Purpose of consent, Scope, Action, Field groups, Status</b> fields. Clicking on <b>Cancel</b> will take the user to metadata application listing page.<br>
 *           When user click <b>Save</b> button while editing an consent user will get an option to update an <i>Version</i> of the consent.
 *           <br/><br/>
 *
 *           <b>Create Consent Template</b><br/>
 *           Clicking on <b>New Consent</b> button on Consent Template listing page will take user to New Consent Template creation page. Clicking on <b>Cancel</b> will take the user to Consent Template listing page.
 *           <br/><br/>
 *
 *           User can create new Consent Template by entering <b>Consent Name</b>, <b>Action</b> & <b>Status</b> mandatory field and clicking on <b> Save </b> button on the admin portal toolbar.
 *           <b>Purpose of consent</b>, <b>Scope</b>, <b>Category</b>, <b>Field groups</b> & <b>Attachments</b> are additional non mandatory fields which user can use to enter more detail about the consent template.
 *           <br/><br/><b>Attachment</b> fields can changes as per the selected attachment type.
 *           <br/><br/>Avaliable Attachment Types:
 *           <ul> <li>File (can upload different type of media file)</li> <li>Content (plain text content)</li> <li>URL</li></ul>
 *       </p>
 *
 *       <p>
 *          <h4>View/Create/Edit Consent Template Fields</h4>
 *          Create/View/Edit Consent Template page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Consent Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>255</td>
 *            </tr>
 *            <tr>
 *               <td>Category</td>
 *               <td>No</td>
 *               <td>CondableConceptDTO[]</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Purpose of consent</td>
 *               <td>No</td>
 *               <td>CodableDTO[]</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Action</td>
 *               <td>Yes</td>
 *               <td>CondableConceptDTO[]</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Scope</td>
 *               <td>No</td>
 *               <td>CondableConceptDTO</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Allow user to deny consent</td>
 *               <td>Yes</td>
 *               <td>Boolean</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Field Groups</td>
 *               <td>No</td>
 *               <td>N/A</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Status</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> Fields under the attachment changes as per the attachment type.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Consent Template Status Code information.
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_PARAM_DUPLICATE</td>
 *               <td>Duplicate label, <b>Name</b> for resource type ConsentTemplate.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create Consent Template page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *                   <td>access.control.consent.template.type.default.values</td>
 *                   <td>String</td>
 *                   <td>PERMIT</td>
 *                   <td>None</td>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.status.default.values</td>
 *                   <td>String</td>
 *                   <td>DRAFT</td>
 *                   <td>None</td>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.action.default.codes</td>
 *                   <td>json</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.category.default.values</td>
 *                   <td>json</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *               </tr>
 *               <tr>
 *                   <td>access.control.consent.template.purpose.default.values</td>
 *                   <td>json</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *               </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit Consent Template.</p>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Access Control Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *           <tr>
 *               <td>Configuration Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating Consent Template page</p>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Consent Template</td>
 *               <td>/consent/templates/{id}</td>
 *               <td>GET</td>
 *               <td>accesscontrol-service.consent.template.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Consent Template</td>
 *               <td>/consent/templates/{id}</td>
 *               <td>PUT</td>
 *               <td>accesscontrol-service.consent.template.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Consent Template</td>
 *               <td>/consent/templates</td>
 *               <td>POST</td>
 *               <td>accesscontrol-service.consent.template.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create New Version Consent Template</td>
 *               <td>/consent/templates/{id}/version</td>
 *               <td>POST</td>
 *               <td>accesscontrol-service.consent.template.version.create</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>List Field groups</td>
 *               <td>/field-groups</td>
 *               <td>GET</td>
 *               <td>accesscontrol-service.fieldgroup.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>List users (View & Edit Consent Template)</td>
 *               <td>/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>List system settings</td>
 *               <td>/system-settings</td>
 *               <td>GET</td>
 *               <td>configuration-service.setting.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create Consent Template</p>
 *   </section>
 */

function ConsentTemplateDetails({ isCreateNew = false }: ConsentTemplateDetailsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [editMode, setEditMode] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const templateData = useAppSelector((state) => state.consent.template);
  const defaultValueSettings = useAppSelector((state) => state.consent.defaultValueSettings);
  const toastData = useAppSelector((state) => state.toast);

  const [purposeOption, setPurposeOption] = useState<any>([]);
  const [categoryOption, setCategoryOption] = useState<any>([]);
  const [actionOption, setActionOption] = useState<any>([]);
  const [scopeOption, setScopeOption] = useState<any>([]);
  const [statusOption, setStatusOption] = useState<any>([]);
  const [languageOption, setLanguageOption] = useState<any>([]);
  const [statusChanges, setStatusChanges] = useState<any>('');

  const [fieldGroupSelected, setFieldGroupSelected] = useState<any>(true);
  const [fieldGroupOption, setFieldGroupOption] = useState<any>([]);

  const [versionCheck, setVersionCheck] = useState<boolean>(false);
  const [changeData, setDataChanges] = useState<any>(defaultConsetState);

  const [formData, setFormData] = useState<any>(defaultConsetState);

  const [formErrorData, setFormErrorData] = useState<any>({
    name: '',
    category: '',
    type: '',
    purpose: '',
    status: '',
    action: '',
    fieldGroups: '',
    scope: ''
  });

  const [attachments, setAttachments] = useState<any>([
    // {
    //   data: '',
    //   url: '',
    //   size: AppConstants.DEFAULT_CONSENT_ATTACHMENT_FILESIZE,
    //   contentType: AppConstants.CONSENT_DEFAULT_ATTACHMENT_CONTENT_TYPE,
    //   title: '',
    //   language: ''
    // }
  ]);

  const handleAddAttachment = () => {
    const newAttachment: any = {
      data: '',
      url: '',
      size: AppConstants.DEFAULT_CONSENT_ATTACHMENT_FILESIZE,
      contentType: AppConstants.CONSENT_DEFAULT_ATTACHMENT_CONTENT_TYPE,
      title: '',
      language: ''
    };
    setAttachments([...attachments, newAttachment]);
  };

  const [dirtyFormData, setDirtyFormData] = useState<any>(defaultConsetState);
  const [dirtyAttachments, setDirtyAttachments] = useState<any>([
    {
      data: '',
      url: '',
      size: AppConstants.DEFAULT_CONSENT_ATTACHMENT_FILESIZE,
      contentType: AppConstants.CONSENT_DEFAULT_ATTACHMENT_CONTENT_TYPE,
      title: '',
      language: ''
    }
  ]);

  const [dirtyCheck, setDirtyCheck] = useState<boolean>(false);

  useEffect(() => {
    if (!isCreateNew && editMode) {
      if (isEqual(dirtyFormData, formData) && isEqual(dirtyAttachments, attachments)) {
        setDirtyCheck(true);
      } else {
        setDirtyCheck(false);
      }
    }
  }, [formData, attachments, editMode]);

  useEffect(() => {
    if (!isCreateNew)
      if (!templateData.name) {
        navigate(RouteConstants.ROUTE_CONSENTS);
      } else {
        setIsLoading(true);
        setConsentData();

        dispatch(
          setURLPaths([
            {
              key: `${RouteConstants.ROUTE_CONSENTS}/${params.id}`,
              label: `Consent Template > ${templateData.name}`
            }
          ])
        );
      }
  }, [templateData, fieldGroupOption, languageOption]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const SYSTEM_SETTING: Array<any> = defaultValueSettings.length
        ? defaultValueSettings
        : await GetSystemSettingsForConsentConfig();
      const STATUS_CHANGES: Array<any> = await getSortedStatusDetails();
      await fetchFieldGroups();

      let purposeMap: Map<string, any> = new Map<string, any>();
      let categoryMap: Map<string, any> = new Map<string, any>();
      let actionMap: Map<string, any> = new Map<string, any>();
      let scopeMap: Map<string, any> = new Map<string, any>();
      let languageMap: Map<string, any> = new Map<string, any>();

      SYSTEM_SETTING.forEach((ele: any) => {
        if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_PURPOSE_KEY) {
          ele.value.forEach((data: any) => {
            // const [code = null] = data.coding || [];
            // purposeMap.set(data.text, data);
            const d = GetObjectFromData(data);
            purposeMap.set(d.display, d);
            // purposeMap.set(data.code, data);
          });
        } else if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_CATEGORY_KEY) {
          ele.value.forEach((data: any) => {
            //categoryMap.set(data.text, data);
            const d = GetObjectFromData(data);
            categoryMap.set(d.display, d);
            // categoryMap.set(data.code, data);
          });
        } else if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_ACTION_KEY) {
          ele.value.forEach((data: any) => {
            actionMap.set(data.text, data);
          });
        } else if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_SCOPE_KEY) {
          ele.value.forEach((data: any) => {
            //scopeMap.set(data.text, data);
            const d = GetObjectFromData(data);
            scopeMap.set(d.display, d);
          });
        } else if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_STATUS_KEY) {
          setStatusOption(ele.allowedValues || []);
        } else if (ele.key === AppConstants.SYSTEM_CONFIG_CONSENT_ATTACHMENT_LANGUAGE_KEY) {
          ele.value.forEach((data: any) => {
            languageMap.set(data.display, data);
          });
        }
      });

      setPurposeOption(purposeMap);
      setCategoryOption(categoryMap);
      setActionOption(actionMap);
      setScopeOption(scopeMap);
      setLanguageOption(languageMap);
      setStatusChanges(STATUS_CHANGES);
    };

    init();
    if (isCreateNew) setEditMode(true);
  }, []);

  useEffect(() => {
    setDataChanges(formData);
  }, [formData]);

  const resetForm = () => {
    setFormData({
      name: '',
      category: [],
      type: '',
      purpose: [],
      status: '',
      action: [],
      fieldGroups: [],
      scope: []
    });
  };

  const handleValidation = () => {
    const { name, status, action, type } = formData;
    return !name.trim() || !status.trim() || !action.length || !type.trim();
  };

  const getOptionObject = (data: any) => {
    const { coding = null, text = '' } = data;
    const [item1] = coding || [data];
    const { display = '', code = '', system = '' } = item1;
    return { display: text || display, code, system };
  };

  const checkIsOptionExist = (list: any, code: string) => {
    const arrVal: any = Array.from(list.values());
    const [filteredItem = null] = arrVal.filter((t: any) => {
      return t?.code === code;
    });
    return filteredItem && getOptionObject(filteredItem);
  };

  const setConsentData = () => {
    const {
      name = '',
      status = '',
      action = '',
      type = '',
      category = [],
      fieldGroups = [],
      purpose = [],
      scope = ''
    } = templateData || {};
    setIsLoading(true);
    let formPayload = {
      name,
      status,
      type,
      purpose: (purpose || []).map((ele: any) => {
        let ret = { key: ele.display, value: ele.display, label: ele.display };
        if (purposeOption.size && purposeOption) {
          let tp = checkIsOptionExist(purposeOption, ele.code);
          if (!tp) {
            setPurposeOption(purposeOption.set(ele.display, ele));
          } else {
            ret = { key: tp.display, value: tp.display, label: tp.display };
          }
        }
        return ret;
      }),
      category: (category || []).map((ele: any) => {
        let val = getCodableValue([ele]);
        let code = getCodeFromCodable(ele);
        let ret = { key: val, value: val, label: val };
        if (categoryOption.size && categoryOption) {
          const ta = checkIsOptionExist(categoryOption, code);
          if (!ta) {
            setCategoryOption(categoryOption.set(val, ele));
          } else {
            ret = { key: ta.display, value: ta.display, label: ta.display };
          }
        }
        return ret;
      }),
      action: (action || []).map((ele: any) => {
        let val = getCodableValue([ele]);
        let code = getCodeFromCodable(ele);
        let ret = { key: val, value: val, label: val };
        if (actionOption.size && actionOption) {
          const ta = checkIsOptionExist(actionOption, code);
          if (!ta) {
            setActionOption(actionOption.set(val, ele));
          } else {
            ret = { key: ta.display, value: ta.display, label: ta.display };
          }
        }
        return ret;
      }),
      scope:
        scope &&
        ([scope] || []).map((ele: any) => {
          let val = getCodableValue([ele]);
          if (scopeOption.size >= 0) {
            setScopeOption(scopeOption.set(val, getOptionObject(ele)));
          }
          return { key: val, value: val, label: val };
        }),
      fieldGroups: fieldGroupOption.filter((ele: any) => {
        if (fieldGroups.includes(ele.value)) {
          return ele;
        }
      })
    };

    setFormData(formPayload);

    setDirtyFormData(formPayload);
    setAttachmentInForm();
    setIsLoading(false);
  };

  const setAttachmentInForm = () => {
    const attachmentsData = templateData?.attachments || [];
    const attachmentData = templateData?.attachment || null;

    const processAttachment = (attachment: any) => {
      const {
        contentType = '',
        size = '',
        language = '',
        data = '',
        url = '',
        title = '',
        id = ''
      } = attachment;

      let lang: any = null;
      if (languageOption.size) {
        lang = find(Array.from(languageOption.values()), { code: language });
      }

      if (contentType) {
        return {
          contentType,
          id,
          language: lang ? lang.display : language,
          size: size || AppConstants.DEFAULT_CONSENT_ATTACHMENT_FILESIZE,
          title,
          data,
          url
        };
      }
      return null;
    };

    if (attachmentsData.length > 0) {
      const attachmentArr = attachmentsData.map(processAttachment).filter(Boolean);
      setAttachments(attachmentArr);
      setDirtyAttachments(attachmentArr);
    } else if (attachmentData) {
      const attchPayload = processAttachment(attachmentData);
      if (attchPayload) {
        setAttachments([attchPayload]);
        setDirtyAttachments([attchPayload]);
      }
    }
  };

  const uploadFileAndGetURL = async (
    bucketName: string,
    file: File,
    prefix: string,
    publicObject: boolean = false
  ) => {
    let imageURI: string = '';
    const { name } = file;
    const payload = {
      filePath: `${prefix}/${name.trim()}`,
      expirationTime: AppConstants.GCS_MAX_EXPIRATION_TIME,
      bucketName,
      versioning: true,
      action: AppConstants.GCS_UPLOAD_ACTION,
      publicObject
    };
    const response = await createSignedURL(payload);
    const { status, data } = response;
    let { url, headers = {} } = data;
    if (status == HTTP_STATUS.HTTP_CREATED) {
      imageURI = url;
      const uploadPayload = {
        url,
        body: file,
        headers: {
          ...headers,
          [AppConstants.HEADER_CONTENT_TYPE]: file.type
        }
      };

      await uploadFile(
        uploadPayload,
        () => {},
        (err: string) => {
          console.error(err);
        }
      );
    }
    return imageURI;
  };

  const getFileUrl = async (file: File) => {
    let bucketName = getGlobalBucketName();

    let url = await uploadFileAndGetURL(bucketName, file, 'consent', true);
    return url.split('?X-Goog-Algorithm')[0];
  };

  const ValidationData = () => {
    let validaName = validateName(formData.name, t);
    if (validaName) {
      setFormErrorData({ ...formErrorData, name: validaName });
      return false;
    }
    return true;
  };

  const getPayload = async () => {
    let attachmentArr: any = [];

    await Promise.all(
      attachments.map(async (attachment: any) => {
        let url = attachment.url;
        let data = attachment.data;
        let language = attachment.language && languageOption.get(attachment.language).code;

        if (
          attachment.size.toString() !== AppConstants.DEFAULT_CONSENT_ATTACHMENT_FILESIZE &&
          data
        ) {
          url = await getFileUrl(attachment.data);
          data = '';
        }

        if (url || data) {
          let attachmentObj = { ...attachment, ...{ url, data, language } };

          if (attachmentObj?.id) {
            delete attachmentObj?.id;
          }
          attachmentArr.push(attachmentObj);
        }
      })
    );

    const { action: xyz, category, purpose, fieldGroups, scope, name } = formData;
    const [scope1 = null] = scope || [];

    let payload: any = {
      ...formData,
      ...{
        name: name.replace(/\s{2,}/g, ' ').trim(),
        action: xyz.map((ele: any) => {
          let act = actionOption.get(ele.value);
          return act;
        }),
        category: category.map((ele: any) => {
          let cat = getCategoryObject(categoryOption.get(ele.key));
          //let cat = categoryOption.get(ele.key);
          return cat;
        }),
        purpose: purpose.map((ele: any) => {
          return getPurposeObject(purposeOption.get(ele.key));
        }),
        scope: scope1 && getCodableObject(scopeOption.get(scope1.value)),
        fieldGroups: fieldGroups.map((ele: any) => ele.value),
        attachments: attachmentArr
      }
    };

    return payload;
  };

  const CreateConsentTemplateCall = async () => {
    let payload = await getPayload();
    let response = await createConsentTemplate(payload);
    const { status, data } = response;

    if (status === HTTP_STATUS.HTTP_CREATED) {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_CONSENT_CREATED_SUCCESS'),
          toastType: 'success'
        })
      );
      navigate(RouteConstants.ROUTE_CONSENTS);
    } else {
      let error = getFailureMessage(response);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }

    setIsLoading(false);
  };

  const GetSystemSettingsForConsentConfig = async () => {
    const newSettings = await getSystemSettings(
      'access.control.consent.template.default.values',
      '',
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE
    );

    const { status, data } = newSettings;
    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      return data?.data;
    } else {
      return [];
    }
  };

  const fetchFieldGroups = async () => {
    let resposne = await listFieldGropus(
      AppConstants.MAXIMUM_PAGE_SIZE,
      AppConstants.DEFAULT_PAGE,
      ''
    );
    const { status, data } = resposne;
    if (status === HTTP_STATUS.HTTP_OK) {
      // let options: any = [AppConstants.CONSENT_FIELDGROUP_OPTION_ALL];
      let options: any = [];

      setFieldGroupOption([
        ...options,
        ...data.data.map((ele: any) => {
          return { key: ele.id, value: ele.name, label: ele.name };
        })
      ]);
    }
  };

  const getSortedStatusDetails = async () => {
    setIsLoading(true);
    const { statusChanges = [] } = templateData || {};
    let purposeMap: Map<string, any> = new Map<string, any>();
    const usersMap: Map<string, any> = new Map<string, any>();
    const users: Array<string> = statusChanges.map((ele: any) => ele.changedBy);

    if (users.length) {
      const uniqueUsers = uniq(compact(users));
      let usersResponse = await getUsers(
        `[{"field":"userId","operation":"in","value":"${uniqueUsers.join()}"}]`
      );
      const { status: userStatus, data: usersData = {} } = usersResponse;
      if (userStatus === HTTP_STATUS.HTTP_OK) {
        const { data: userList = [] } = usersData;
        userList.forEach((user: any) => {
          usersMap.set(user?.userId, user);
        });
      }
    }

    statusChanges.forEach((ele: any) => {
      purposeMap.set(ele.status, {
        ...ele,
        ...{ changedByUser: getUserName(usersMap.get(ele.changedBy)) }
      });
    });

    let myArray: any = [...Array.from(purposeMap.values())].reverse();
    setIsLoading(false);
    return myArray;
  };

  const UpdateConsentTemplate = async (createVersion: boolean = false) => {
    let attachmentFlag = !isEqual(dirtyAttachments, attachments);

    let templateId = templateData.id;
    let updatedTemplate = templateData;

    if (createVersion) {
      let newVersionResponse = await createNewVersiononsentTemplate(templateData.id);
      const { status, data } = newVersionResponse;
      if (status === HTTP_STATUS.HTTP_CREATED) {
        setVersionCheck(false);
        templateId = data.id;
        updatedTemplate = data;
      }
    }

    if (attachmentFlag) {
      setIsLoading(true);
      await updateAttachmentsForConsent(updatedTemplate, createVersion);
    }

    let payload = await getPayload();
    let response = await updateConsentTemplate(templateId, payload);
    const { status, data } = response;

    if (status === HTTP_STATUS.HTTP_OK) {
      setEditMode(false);
      dispatch(
        setConsentTemplate({
          ...data,
          ...{
            purpose: data.purpose,
            category: data.category,
            action: data.action,
            scope: data.scope
          }
        })
      );

      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_CONSENT_UPDATED_SUCCESS'),
          toastType: 'success'
        })
      );
    } else {
      let error = getFailureMessage(response);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }

    setIsLoading(false);
  };

  const CreateNewVersionPopup = () => {
    return (
      <div>
        <div>{`${t('T_NEW_VERSION_TEXT')} ${templateData.name}.`}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 min-w-[100px] border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setVersionCheck(false);
              setIsLoading(true);
              UpdateConsentTemplate(false);
            }}
          >
            {t('T_NO')}
          </BiButton>

          <BiButton
            className={'border border-solid border-white bg-primary text-white'}
            type="button"
            onClick={() => {
              setIsLoading(true);
              UpdateConsentTemplate(true);
            }}
          >
            {t('T_CREATE_NEW_VERSION')}
          </BiButton>
        </div>
      </div>
    );
  };

  // const removeArrayElement = (item: any, data: any[]) => {
  //   let arr = data;
  //   let i = arr.indexOf(item);
  //   if (i > -1) {
  //     arr.splice(i, 1);
  //   }
  //   return arr;
  // };
  const updateAttachmentsForConsent = async (updatedTemplateDetails: any, createVersion: any) => {
    let responseData;
    const handleFailure = (response: any) => {
      let error = getFailureMessage(response);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    };

    const constructFileObj = async (attachment: any) => {
      let url = attachment.url;
      let data = attachment.data;
      let language = attachment.language && languageOption.get(attachment.language).code;

      if (attachment.size.toString() !== AppConstants.DEFAULT_CONSENT_ATTACHMENT_FILESIZE && data) {
        url = await getFileUrl(attachment.data);
        data = '';
      }

      if (url || data) {
        let attachmentObj = { ...attachment, ...{ url, data, language } };

        if (attachmentObj?.id) {
          delete attachmentObj?.id;
        }
        return attachmentObj;
      }
      return {};
    };
    const updatedAttachMents = updatedTemplateDetails.attachments;
    if (createVersion) {
      updatedAttachMents.map(async (attachment: any) => {
        if (attachment?.id) {
          try {
            let res = await removeAttachmentsFromConsent(
              updatedTemplateDetails?.id,
              attachment?.id
            );
            if (!HTTP_STATUS.isSuccess(res?.status)) {
              handleFailure(res);
            }
            if (HTTP_STATUS.isSuccess(res?.status)) {
              responseData = res?.data;
            }
          } catch (error) {
            dispatch(
              setToastData({
                isToastActive: true,
                toastMessage: t('T_ERROR_SOMETHING_WRONG'),
                toastType: 'error'
              })
            );
          }
        }
      });
    }

    const comparator = (attachment: any, dirtyAttachment: any) =>
      attachment.id === dirtyAttachment.id;

    const differenceArray = differenceWith(attachments, updatedAttachMents, comparator);

    if (differenceArray.length) {
      let filesArr = await Promise.all(
        differenceArray.map(async (attachment) => {
          return await constructFileObj(attachment);
        })
      );

      try {
        let res = await addAttachmentsToConsent(updatedTemplateDetails?.id, filesArr);
        if (HTTP_STATUS.isSuccess(res?.status)) {
          responseData = res?.data;
        }
        if (!HTTP_STATUS.isSuccess(res?.status)) {
          handleFailure(res);
        }
      } catch (error) {
        dispatch(
          setToastData({
            isToastActive: true,
            toastMessage: t('T_ERROR_SOMETHING_WRONG'),
            toastType: 'error'
          })
        );
      }
    }
    if (!createVersion) {
      await Promise.all(
        dirtyAttachments.map(async (attachment: any) => {
          const attachIndex = findIndex(attachments, { id: attachment?.id });
          if (attachIndex === -1) {
            if (attachment?.id) {
              // Delete
              try {
                let res = await removeAttachmentsFromConsent(templateData?.id, attachment?.id);
                if (!HTTP_STATUS.isSuccess(res?.status)) {
                  handleFailure(res);
                }
                if (HTTP_STATUS.isSuccess(res?.status)) {
                  responseData = res?.data;
                }
              } catch (error) {
                dispatch(
                  setToastData({
                    isToastActive: true,
                    toastMessage: t('T_ERROR_SOMETHING_WRONG'),
                    toastType: 'error'
                  })
                );
              }
            }
          } else if (!isEqual(attachments[attachIndex], attachment)) {
            try {
              let fileObj = await constructFileObj(attachments[attachIndex]);
              if (!isEmpty(fileObj)) {
                let res = await updateAttachmentsOfConsent(
                  templateData?.id,
                  attachment?.id,
                  fileObj
                );
                if (HTTP_STATUS.isSuccess(res?.status)) {
                  responseData = res?.data;
                }
                if (!HTTP_STATUS.isSuccess(res?.status)) {
                  handleFailure(res);
                }
              }
            } catch (error) {
              dispatch(
                setToastData({
                  isToastActive: true,
                  toastMessage: t('T_ERROR_SOMETHING_WRONG'),
                  toastType: 'error'
                })
              );
            }
          }
        })
      );
    }

    setIsLoading(false);
    dispatch(
      setToastData({
        isToastActive: true,
        toastMessage: t('T_CONSENT_ATTACHMENTS_UPDATED_SUCCESS'),
        toastType: 'success'
      })
    );

    if (!isEmpty(responseData?.attachments)) {
      dispatch(
        setConsentTemplate({
          ...templateData,
          attachments: responseData?.attachments
        })
      );
    }
    setEditMode(false);
  };

  const handleEditCheck = () => {
    setVersionCheck(true);
  };
  return (
    <LoaderContainer isLoading={isLoading || (!isCreateNew && !statusChanges)}>
      <MenuButtonsPortal>
        <>
          {!editMode && (
            <>
              <div
                className="mr-4 cursor-pointer text-sm leading-10 text-primary hover:text-sky-500"
                onClick={() => navigate(RouteConstants.ROUTE_CONSENTS)}
              >
                {t('T_RETURN_CONSENT_TEMPLATES')}
              </div>
              <BiButton
                className={'bg-primary py-1.5 text-white'}
                type="button"
                disabled={handleValidation()}
                onClick={() => {
                  setEditMode(true);
                }}
              >
                {t('T_EDIT')}
              </BiButton>
            </>
          )}

          {editMode && (
            <>
              <BiButton
                className={'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'}
                type="button"
                onClick={() => {
                  if (isCreateNew) {
                    resetForm();
                    navigate(RouteConstants.ROUTE_CONSENTS);
                  } else {
                    setEditMode(false);
                    setConsentData();
                  }
                }}
              >
                {t('T_CANCEL')}
              </BiButton>
              <BiButton
                className={'bg-primary py-1.5 text-white'}
                type="button"
                disabled={handleValidation() || dirtyCheck}
                onClick={() => {
                  if (ValidationData()) {
                    if (isCreateNew) {
                      setIsLoading(true);
                      CreateConsentTemplateCall();
                    } else {
                      // UpdateConsentTemplate();
                      handleEditCheck();
                    }
                  }
                }}
              >
                {t('T_SAVE')}
              </BiButton>
            </>
          )}
        </>
      </MenuButtonsPortal>

      <section className="w-full">
        <div className="flex">
          <div className="flex w-4/5 flex-col  overflow-visible px-2 py-3">
            <div
              className="w-full bg-gray-100 pt-5"
              style={useTheme().theme.bgColorStyleForLoggedInPage}
            >
              <CardSection>
                <Card
                  title={t('T_CONSENT_DETAILS')}
                  icon={consentIcon}
                  alt={t('T_USER_CARD_ALT_MESSAGE')}
                  height=""
                  width=""
                >
                  <div className="relative w-full">
                    <div className="mb-4 w-full">
                      <InputField
                        className="w-full"
                        type="text"
                        id={'consent_name'}
                        label={t('T_CONSENT_NAME')}
                        placeholder={t('T_CONSENT_NAME')}
                        fieldKey="name"
                        required={true}
                        formData={formData}
                        formError={formErrorData}
                        setFormData={setFormData}
                        disabled={!editMode}
                        handleChange={(e: any) => {
                          const { value = '' } = e.target || {};
                          if (consentNameValidation(value)) {
                            setFormData({ ...formData, name: value });
                          }
                        }}
                      />
                    </div>

                    <div className="mb-4 flex w-full">
                      <div className="mr-2 w-1/2">
                        <Label>{t('T_CATEGORY')}</Label>
                        <PickList
                          fieldKey={'key'}
                          fieldValues={['value']}
                          selectedItems={changeData.category}
                          onSelectedChange={(items: any) => {
                            setFormData({ ...formData, category: items });
                          }}
                          items={sortArray(
                            Array.from(categoryOption.keys()).map((ele: any) => {
                              return getPickListItems(categoryOption, ele);
                            }) || []
                          )}
                          disabled={!editMode}
                          readOnly={false}
                          type={PICKLIST_TYPES.MULTI}
                        />
                        <div
                          className="mt-2 flex w-full flex-col flex-wrap"
                          style={editMode ? {} : { pointerEvents: 'none' }}
                        >
                          {(!isLoading || editMode) && (
                            <PillsButton
                              pillId={'category-pill'}
                              pillMaxWidth={'100%'}
                              formData={formData}
                              setFormData={setFormData}
                              rows={2}
                              pillsData={formData.category}
                              removeFilter={(item: any) => {
                                let arr = removeArrayElement(item, formData.category);
                                setFormData({ ...formData, category: arr });
                                setDataChanges('category');
                              }}
                            ></PillsButton>
                          )}
                        </div>
                      </div>
                      <div className="ml-2 w-1/2">
                        <Label>{t('T_PURPOSE_OF_CONSENT')}</Label>
                        <PickList
                          fieldKey={'key'}
                          fieldValues={['value']}
                          selectedItems={changeData.purpose}
                          onSelectedChange={(items: any) => {
                            setFormData({ ...formData, purpose: items });
                          }}
                          items={sortArray(
                            Array.from(purposeOption.keys()).map((ele: any) => {
                              return getPickListItems(purposeOption, ele);
                            }) || []
                          )}
                          disabled={!editMode}
                          readOnly={false}
                          type={PICKLIST_TYPES.MULTI}
                        />
                        <div
                          className="mt-2 flex w-full flex-col flex-wrap"
                          style={editMode ? {} : { pointerEvents: 'none' }}
                        >
                          {(!isLoading || editMode) && (
                            <PillsButton
                              pillId={'purpose-pill'}
                              pillMaxWidth={'100%'}
                              formData={formData}
                              setFormData={setFormData}
                              rows={2}
                              pillsData={formData.purpose}
                              removeFilter={(item: any) => {
                                let arr = removeArrayElement(item, formData.purpose);
                                setFormData({ ...formData, purpose: arr });
                                setDataChanges('purpose');
                              }}
                            ></PillsButton>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 flex w-full">
                      <div className="mr-2 w-1/2">
                        <Label>
                          {t('T_ACTION')} <Image src={Asterix} />
                        </Label>
                        <PickList
                          fieldKey={'key'}
                          fieldValues={['value']}
                          selectedItems={changeData.action}
                          onSelectedChange={(items: any) => {
                            setFormData({ ...formData, action: items });
                          }}
                          items={sortArray(
                            Array.from(actionOption.keys()).map((ele: any) => {
                              return getPickListItems(actionOption, ele);
                            }) || []
                          )}
                          disabled={!editMode}
                          readOnly={false}
                          type={PICKLIST_TYPES.MULTI}
                        />
                        <div
                          className="mt-2 flex w-full flex-col flex-wrap"
                          style={editMode ? {} : { pointerEvents: 'none' }}
                        >
                          {(!isLoading || editMode) && (
                            <PillsButton
                              pillId={'action-pill'}
                              pillMaxWidth={'100%'}
                              formData={formData}
                              setFormData={setFormData}
                              rows={2}
                              pillsData={formData.action}
                              removeFilter={(item: any) => {
                                let arr = removeArrayElement(item, formData.action);
                                setFormData({ ...formData, action: arr });
                                setDataChanges('action');
                              }}
                            ></PillsButton>
                          )}
                        </div>
                      </div>

                      <div className="ml-2 w-1/2">
                        <Label>{t('T_SCOPE')}</Label>
                        <PickList
                          fieldKey={'key'}
                          fieldValues={['value']}
                          selectedItems={changeData.scope || []}
                          onSelectedChange={(items: any) => {
                            setFormData({ ...formData, scope: items });
                          }}
                          items={(
                            Array.from(scopeOption.keys()).map((ele: any) => {
                              return getPickListItems(scopeOption, ele);
                            }) || []
                          ).sort()}
                          disabled={!editMode}
                          readOnly={false}
                          type={PICKLIST_TYPES.SINGLE}
                        />
                      </div>
                    </div>

                    <div className="mb-0 flex w-full">
                      <div className="flex w-1/2">
                        <div className="leading-[60px]">{t('T_ALLOW_USER_TO_DENY_CONSENT')}:</div>
                        <div className="flex leading-[60px]">
                          <RadioButton
                            className={'ml-3 mr-2 cursor-pointer'}
                            name={`exp`}
                            fontSize="16px"
                            fontColor="#262626"
                            selectedField={
                              formData.type === AppConstants.CONSENT_TYPE_DENY && 'Yes'
                            }
                            option={'Yes'}
                            disabled={!editMode}
                            label={'Yes'}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                ...{ type: AppConstants.CONSENT_TYPE_DENY }
                              });
                            }}
                          ></RadioButton>
                          <RadioButton
                            className={'cursor-pointer'}
                            name={`exp`}
                            fontSize="16px"
                            fontColor="#262626"
                            selectedField={
                              formData.type === AppConstants.CONSENT_TYPE_PERMIT && 'No'
                            }
                            option={'No'}
                            disabled={!editMode}
                            label={'No'}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                ...{ type: AppConstants.CONSENT_TYPE_PERMIT }
                              });
                            }}
                          ></RadioButton>
                        </div>
                      </div>
                      <div className="mx-2 w-1/2"></div>
                    </div>
                  </div>
                </Card>
              </CardSection>
            </div>

            <div
              className="w-full bg-gray-100 pt-3"
              style={useTheme().theme.bgColorStyleForLoggedInPage}
            >
              <CardSection>
                <Card
                  title={t('T_FIELDGROUPS')}
                  icon={userIcon}
                  alt={t('T_USER_CARD_ALT_MESSAGE')}
                  height=""
                  width=""
                >
                  <div className="relative w-full" style={{ minHeight: '80px' }}>
                    <div>
                      {(!isLoading || editMode) && (
                        <PickList
                          fieldKey={'key'}
                          fieldValues={['value']}
                          selectedItems={changeData.fieldGroups}
                          onSelectedChange={(items: any) => {
                            setFormData({ ...formData, fieldGroups: items });
                          }}
                          items={sortArray(fieldGroupOption || [])}
                          disabled={!editMode}
                          readOnly={false}
                          type={PICKLIST_TYPES.MULTI}
                        />
                      )}
                    </div>

                    <div>
                      <div
                        className="mt-2 flex w-full flex-col flex-wrap"
                        style={editMode ? {} : { pointerEvents: 'none' }}
                      >
                        <PillsButton
                          pillId={'fieldgroup-pill'}
                          pillMaxWidth={'100%'}
                          formData={formData}
                          setFormData={setFormData}
                          rows={2}
                          pillsData={formData.fieldGroups}
                          removeFilter={(item: any) => {
                            let arr = removeArrayElement(item, formData.fieldGroups);
                            setFormData({ ...formData, fieldGroups: arr });
                            setDataChanges(true);
                          }}
                        ></PillsButton>
                      </div>
                    </div>
                  </div>
                </Card>
              </CardSection>
            </div>

            <div
              className="w-full bg-gray-100 pt-3"
              style={useTheme().theme.bgColorStyleForLoggedInPage}
            >
              <CardSection className="w-full">
                <Card
                  title={t('T_ATTACHMENTS')}
                  icon={<PaperClipIcon className="w-[20px]" />}
                  headingClass="px-2"
                  alt={t('T_USER_CARD_ALT_MESSAGE')}
                  height=""
                  width=""
                  actions={
                    editMode ? (
                      <PlusCircleIcon
                        className="ml-auto h-6 w-6 cursor-pointer text-gray-600 hover:text-black"
                        onClick={() => {
                          if (editMode) {
                            handleAddAttachment();
                          }
                        }}
                        data-testid="add-attachment"
                      />
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="relative w-full">
                    {(!isLoading || editMode) && (
                      <ConsentAttachment
                        setFileData={setAttachments}
                        fileData={attachments}
                        editMode={editMode}
                        options={languageOption}
                      />
                    )}
                  </div>
                </Card>
              </CardSection>
            </div>
          </div>

          <div className="mt-[25px] w-[20%]">
            <div className="ml-5 w-4/5">
              {!isCreateNew && (
                <div className="mb-[10px]">
                  <span>{t('T_VERSION')}: </span>
                  <span>{templateData.version}</span>
                </div>
              )}

              <SelectField
                type="text"
                id="status"
                label={t('T_STATUS')}
                placeholder={t('T_STATUS')}
                fieldKey={'status'}
                required={true}
                formData={formData}
                setFormData={setFormData}
                formError={formErrorData}
                options={([...statusOption] || []).sort()}
                maxHeight={false}
                fontSize={'16px'}
                disabled={!editMode}
                style={
                  AppConstants.CONSENT_TEMPLATE_STATUS_COLORS[formData.status]
                    ? {
                        backgroundColor:
                          AppConstants.CONSENT_TEMPLATE_STATUS_COLORS[formData.status]
                      }
                    : {}
                }
              />

              {!editMode && (
                <div className="text-[14px] font-normal text-[#7D7D7D]">
                  {t(`T_${formData.status}_INFO`)}
                </div>
              )}
            </div>

            {!isCreateNew && !editMode && (
              <>
              <TemplateStatusDetails statusData={statusChanges || []} />
              {(templateData.createdBy || templateData.createdOn) && (
                <div className="ml-5">
                  <AuditInfo
                    label={'CREATED'}
                    date={templateData?.createdOn || ''}
                    user={templateData?.createdBy || ''}
                  />
                </div>
                )}
                {(templateData.updatedOn || templateData?.updatedBy) && (
                <div className="ml-5">
                  <AuditInfo
                    label={'LAST_MODIFIED'}
                    date={templateData?.updatedOn || ''}
                    user={templateData?.updatedBy || ''}
                  />
                </div>
                )}
                </>
            )}
          </div>
        </div>
      </section>
      <ToastNotification
        message={toastData.toastMessage}
        isActive={toastData.isToastActive}
        setIsActive={() =>
          dispatch(
            setToastData({
              toastMessage: '',
              isToastActive: false,
              toastType: null
            })
          )
        }
        status={toastData.toastType}
      />
      <Modal
        showCloseIcon={true}
        width="35rem"
        headerText={'Create New Version'}
        toggleVisiblity={(value: boolean) => {
          setVersionCheck(!versionCheck);
        }}
        isVisible={versionCheck}
        body={CreateNewVersionPopup()}
        headerLeftIcon={consentIcon}
      />
    </LoaderContainer>
  );
}

const Container = styled.div`
  width: 100%;
`;
const Image = styled.img`
  width: 8px;
  height: 8px;
`;

export default ConsentTemplateDetails;
