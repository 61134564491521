import React, {createContext, useContext} from "react";
import {
    colorBiText, colorForGrayBackground, colorForLightGrayBackground,
    colorLightGray9, colorProductDisabled,
    colorProductPrimary, colorProductPrimaryHover, colorRed3, colorRed6, colorSecondaryHover,
    colorWhite
} from "../styles/globalVariables";
import {css} from "styled-components/macro";


export const initialTheme = {
    primaryAppColor: `rgb(${colorProductPrimary})`,
    whiteAppColor: `rgb(${colorWhite})`,
    primaryAppHoverColor: `rgb(${colorProductPrimaryHover})`,
    disabledColor: `rgb(${colorProductDisabled})`,
    breadcrumbTextColor: `rgb(${colorLightGray9})`,
    sidebarBtnHoverBgColor: `rgb(${colorWhite})`,
    sidebarBtnHoverTextColor: `rgb(${colorProductPrimary})`,
    bgPrimaryStyleColor: {backgroundColor: `rgb(${colorProductPrimary})`},
    bgPrimaryStyleStriveColor: {backgroundColor: `rgb(${colorProductPrimary})`},
    bgColorStyleForLoggedInPage: {backgroundColor: `rgb(${colorForLightGrayBackground})`},
    themeTextStyleColor: {color: `rgb(${colorBiText})`},
    bgColorStyleForLoginPage: {backgroundColor: `rgb(${colorProductPrimary})`},
    bgColorStyleForJsonEditor: {backgroundColor: `rgb(${colorForLightGrayBackground})`},
    bgHoverTextWhiteStyleColor: {color: `rgb(${colorWhite})`},
    signInBtnStyle: {
        primary: css`
            background-color:rgb(${colorProductPrimary});
            color: rgb(${colorWhite});
            border: 1px solid rgb(${colorProductPrimary});
        
            &:hover {
              background-color: rgb(${colorProductPrimaryHover});
            }
        
            &:disabled {
              background-color: rgb(${colorProductDisabled});
              color: rgb(${colorWhite});
              border: 1px solid rgb(${colorProductDisabled});
        
              &:hover {
                background-color: rgb(${colorProductDisabled});
                color: rgb(${colorWhite});
                border: 1px solid rgb(${colorProductDisabled});
              }
            }
          `,
        secondary: css`
            background-color: rgb(${colorWhite});
            color: rgb(${colorProductPrimary});
            border: 1px solid rgb(${colorProductDisabled});
        
            &:hover {
              background-color: rgb(${colorSecondaryHover});
              color: rgb(${colorProductPrimaryHover});
              border: 1px solid rgb(${colorProductPrimaryHover});
            }
        
            &:disabled {
              background-color: rgb(${colorWhite});
              color: rgb(${colorProductDisabled});
              &:hover {
                background-color: rgb(${colorWhite});
                border: 1px solid rgb(${colorProductDisabled});
                color: rgb(${colorProductDisabled});
              }
            }
          `,
        tertiary: css`
            color: rgb(${colorProductPrimary});
            background-color: rgb(${colorWhite});
        
            &:hover {
              color: rgb(${colorProductPrimaryHover});
            }
        
            &:disabled {
              color: rgb(${colorProductDisabled});
              &:hover {
                background-color: rgb(${colorWhite});
                color: rgb(${colorProductDisabled});
              }
            }
          `,
        danger: css`
            background-color: rgb(${colorWhite});
            color: rgb(${colorRed6});
            border: 1px solid rgb(${colorRed6});
        
            &:hover {
              background-color: rgb(${colorRed3});
            }
        
            &:disabled {
              border: 1px solid rgb(${colorRed3});
              color: rgb(${colorRed3});
              &:hover {
                background-color: rgb(${colorWhite});
                border: 1px solid rgb(${colorRed3});
                color: rgb(${colorRed3});
              }
            }
          `
    },
    secondaryHoverBgColor: {backgroundColor: `rgb(${colorSecondaryHover})`},
    bgColorForMyAccountPage: {backgroundColor: `rgb(${colorForGrayBackground})`},
    bgPrimaryDisabledStyleColor: {backgroundColor: `rgb(${colorProductDisabled})`},
}

export type ITheme = {
    primaryAppColor: string;
    whiteAppColor: string;
    primaryAppHoverColor: string;
    disabledColor: string;
    breadcrumbTextColor: string;
    sidebarBtnHoverBgColor: string;
    sidebarBtnHoverTextColor: string;
    bgPrimaryStyleColor: any;
    bgPrimaryStyleStriveColor: any;
    bgColorStyleForLoggedInPage: any;
    themeTextStyleColor: any;
    bgColorStyleForLoginPage: any;
    bgColorStyleForJsonEditor: any;
    bgHoverTextWhiteStyleColor: any;
    signInBtnStyle: any;
    secondaryHoverBgColor: any;
    bgColorForMyAccountPage: any;
    bgPrimaryDisabledStyleColor: any;
}

export type ThemeContextType = {
    theme: ITheme;
    setTheme: (Theme: ITheme) => void;
}

export const ThemeContext = createContext<ThemeContextType>({
    theme: initialTheme,
    setTheme: ITheme => console.warn('no theme provided.')
});

export const useTheme = () => useContext(ThemeContext);


