import { IPasswordPolicyResponse } from './Register.container';

export const performRegisterPasswordValidation = (
  values: any,
  setPasswordRequirements: React.Dispatch<React.SetStateAction<any>>,
  passwordPolicy: IPasswordPolicyResponse,
  t: any
) => {
  let errors: any = {};
  if (!values.inviteCode) {
    errors.inviteCode = `${t('T_VALIDATION_ERR_FOR_PASSWORD_INVITE_CODE')}`;
  }

  if (values.password.length < passwordPolicy?.pwdMinLength) {
    errors.password = `${t('T_VALIDATION_ERR_PASSWORD_POLICY_MSG1')} ${
      passwordPolicy.pwdMinLength
    } ${t('T_VALIDATION_ERR_PASSWORD_POLICY_MSG2')}`;
    setPasswordRequirements((state: any) => ({ ...state, pwdMinLength: false }));
  } else if (typeof values === 'string' && values?.length < passwordPolicy?.pwdMinLength) {
    setPasswordRequirements((state: any) => ({ ...state, pwdMinLength: false }));
  } else {
    setPasswordRequirements((state: any) => ({ ...state, pwdMinLength: true }));
  }
  let req: Array<boolean> = [];
  passwordPolicy?.characterSets?.forEach((policy) => {
    let result = true;
    let message = '';
    const count = policy.slice(0, 1) || 0;
    const charaterSet = policy.slice(2) || '';
    if (charaterSet.includes('[]')) {
      result = countSpecial(values.password, charaterSet) >= count;
      if (!result) {
        message = `${t('T_VALIDATION_ERR_PASSWORD_POLICY_MSG4')}`;
      }
      errors.password = !result && `${t('T_VALIDATION_ERR_PASSWORD_POLICY_MSG4')}`;
    } else {
      const expression = `(?=(.*[${charaterSet}]){${count},})`;
      const regex = new RegExp(expression, 'g');
      result = regex.test(values.password);
      if (!result) {
        message = `${t('T_VALIDATION_ERR_PASSWORD_POLICY_MSG3')} ${charaterSet}`;
      }
    }
    if (message) {
      errors.password = message;
    }
    req = [...req, result];

    // if (policy.slice(0, 2) === '1:' && values.password !== '' && policy?.slice(2, 4) !== '~!') {
    //   if (!new RegExp(`(?=.*?[${policy.slice(2)}])`).test(values.password)) {
    //     errors.password = `${t('T_VALIDATION_ERR_PASSWORD_POLICY_MSG3')} ${policy.slice(2)}`;
    //     req = [...req, false];
    //   } else {
    //     req = [...req, true];
    //   }
    // } else if (!/[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(values.password)) {
    //   errors.password = `${t('T_VALIDATION_ERR_PASSWORD_POLICY_MSG4')}`;
    //   req = [...req, false];
    // } else {
    //   req = [...req, true];
    // }
  });
  setPasswordRequirements((state: any) => ({ ...state, characterSets: [...req] }));

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = `${t('T_VALIDATION_ERR_PASSWORD_POLICY_MSG5')}`;
  }

  return errors;
};

const countSpecial = (value: string = '', charaterSet: string = ''): Number => {
  let count: number = 0;
  let charaters: any = {};
  for (let i = 0; i < value.length; i++) {
    if (!charaterSet.includes(value[i])) {
      continue;
    }
    let charater = charaters[value[i]] || 0;
    charaters[value[i]] = ++charater;
  }
  return Object.keys(charaters).length;
};
