import { useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  colorBorderAlert1,
  colorGreen,
  colorInfoToast,
  colorWhite,
  gridCenter
} from '../../styles/globalVariables';
import { close, errorIcon, infoIcon, successIcon } from '../../assets/components/svgs';
import { uTransitionTranslateYModal } from '../../styles/utility';
import { useAppSelector } from '../../app/hooks';
import { AppConstants } from '../../constants/AppConstants';

/**
 * 3 options for status: "success" | "error" | null = Info toast
 */

const StyledErrorToast = styled.div`
  position: fixed;
  left: 50%;
  top: 30px;
  display: flex;
  justify-content: center;
  ${uTransitionTranslateYModal}
  z-index: 10000;
  display: flex;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
`;

interface IStatus {
  status?: string;
}

const IconContainer = styled.div<IStatus>`
  width: 3.75rem;
  background-color: rgb(
    ${(p) =>
      p.status === 'success'
        ? colorGreen
        : p.status === 'error'
        ? colorBorderAlert1
        : colorInfoToast}
  );
  ${gridCenter}
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
`;

const MessageContainer = styled.div<IStatus>`
  background-color: rgb(${colorWhite});
  display: flex;
  align-items: center;
  justify-content: center;
  border-end-end-radius: 4px;
  border-start-end-radius: 4px;
  border: 2px solid
    rgb(
      ${(p) =>
        p.status === 'success'
          ? colorGreen
          : p.status === 'error'
          ? colorBorderAlert1
          : colorInfoToast}
    );

  & > button {
    align-self: flex-start;
    height: 0.75rem;
    width: 0.75rem;
    ${gridCenter}
    margin: .625rem;
  }

  & > button > svg {
    height: 0.75rem;
    width: 0.75rem;
  }
`;

const Message = styled.p`
  font-size: 17px;
  padding: 1rem 0rem 1rem 1rem;
  width: 80ch;
  overflow: hidden;
  text-align: center;
`;

type Props = {
  message: string;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  status?: 'success' | 'error';
  keepActive?: boolean;
};

const ToastNotification = ({
  message,
  isActive,
  setIsActive,
  status,
  // keepActive = status === 'error' ? true : false
  keepActive = false
}: Props) => {
  // const solutionConfigurationDefaults: any = useAppSelector(
  //   (state) => state.configuration.solutionConfigurationDefaults
  // );
  const solutionConfigurationDefaults: any = {
    toast: {
      infoTTL: AppConstants.DEFAULT_TOAST_SUCCESS_TTL,
      errorTTL: AppConstants.DEFAULT_TOAST_ERROR_TTL
    }
  };
  const {
    toast = {
      infoTTL: AppConstants.DEFAULT_TOAST_SUCCESS_TTL,
      errorTTL: AppConstants.DEFAULT_TOAST_ERROR_TTL
    }
  } = solutionConfigurationDefaults || {};
  useEffect(() => {
    if (isActive && !keepActive) {
      setTimeout(
        () => {
          setIsActive(false);
        },
        status === 'error' ? toast?.errorTTL : toast?.infoTTL
      );
    }
  }, [setIsActive, isActive]);
  return (
    <StyledErrorToast isActive={isActive}>
      <IconContainer status={status}>
        {status === 'success' ? successIcon : status === 'error' ? errorIcon : infoIcon}
      </IconContainer>
      <MessageContainer status={status}>
        <Message>{message}</Message>
        <button onClick={() => setIsActive(false)}>{close}</button>
      </MessageContainer>
    </StyledErrorToast>
  );
};

export default ToastNotification;
