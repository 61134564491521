import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { RouteConstants } from '../../constants/RouteConstants';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import Card from '../shared/cards/Card';
import SettingIcon from '../../assets/toolkit.svg';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import InputField from '../shared/Fields/InputField';
import TextAreaField from '../shared/Fields/TextAreaField';
import { Label } from '../AccountProfile/ProfileStyles';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { setToastData } from '../../app/toastReducer';
import RadioButton from '../shared/Fields/RadioButton';
import { lock_icon } from '../../assets/components/svgs';
import { consentNameValidation, fieldIsEmpty, validateNameLength } from '../../util/validators';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import {
  getBucketById,
  createBucket,
  updateBucketPermissions,
  deleteBucket
} from '../../http/asset-management-services';
import { setURLPaths } from '../../app/organizationReducer';
import TabToggleButton from '../Reusable/TabToggleButton';
import PickList from '../shared/Fields/PickList';
import PickListWithApiSearch, { PICKLIST_TYPES } from '../shared/Fields/PickListWithAPISearch';
import { getUsers } from '../../http/user-management-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { listRoles } from '../../http/access-management-service';

import ActionDropdown from '../primitives/dropdowns/ActionDropdown';
import _, { filter, find, isEmpty, isEqual, isString, uniq } from 'lodash';
import ExclamationIcon from '@heroicons/react/outline/ExclamationIcon';
import Modal from '../shared/ModalPortal/Modal';
import moment from 'moment';
import { getUserName } from '../../util/admin-utils';
import { setBucketData } from '../../app/bucketReducer';
import { StatusField } from '../shared/Fields/StatusField';
import environment from '../../environment';
import { getBucketRelativeName, hasBucketAccess } from './BucketUtil';
import { SubItemForRoleAndUser } from './SubItem';
import AuditInfo from '../Common/AuditInfo';

/**
 * @parentId file-management
 * @manager Create, Read, Update, Delete - Bucket
 * @overview Create, Read, Update, Delete Bucket
 *   <section>
 *       <p>
 *           <b>View Bucket</b><br/>
 *           Clicking on bucket row item on the bucket listing page will take user to view bucket details page in view only mode. Clicking on <b>Back to Bucket List</b> will take the user to bucket(s) listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i>
 *           <br/><br/>
 *           <b>Edit Bucket</b><br/>
 *           Editing of Bucket is only possible if the logged in user role API permission has edit permissions. i.e <strong>asset-management-service.bucket.patch</strong>. Clicking on <b>Manage></b> and then <b>Edit</b> button on the admin portal toolbar will change the page into editing mode, which allows users to edit <b>Description</b>, <b>Add/Remove Individual Users with access level</b>, <b>Add/Remove User Roles with access level</b>, <b>Deactivate Bucket</b> and <b>Save the Bucket</b>. Clicking on <b>Cancel</b> will take the user back to bucket details page in view only mode.
 *           Once a bucket is created, the bucket name cannot be changed.
 *           <br/><br/>
 *           <b>Create Bucket</b><br/>
 *           <b>New Bucket</b> button is only enabled if the logged-in user role API permission has create permission. i.e <strong>asset-management-service.bucket.create</strong>
 *           Clicking on <b>New Bucket</b> button on bucket listing page will take user to new bucket creation page. Clicking on <b>Cancel</b> will take the user to bucket listing page.
 *           <br/><br/>
 *           User can create new bucket by entering <b>Name</b>,<b>Description</b> mandatory fields and clicking on <b>Save</b> button on the admin portal toolbar. <b>Adding Individual Users</b> and <b>Role Groups</b> are optional.
 *       </p> <br/>
 *       <p>
 *          <h4>Context Menu Options</h4>
 *          View/Edit bucket page will display the following options in context menu in Admin portal toolbar:
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Name</th>
 *               <th>Description</th>
 *               <th>Permission Needed</th>
 *            </tr>
 *            <tr>
 *               <td>Edit</td>
 *               <td>Changes to Edit Mode from Read-Only (View) Mode</td>
 *               <td>asset-management-service.bucket.patch</td>
 *            </tr>
 *            <tr>
 *               <td>Delete</td>
 *               <td>Used to delete the bucket.</td>
 *               <td>asset-management-service.bucket.delete</td>
 *            </tr>
 *              <tr>
 *               <td>Files & Folders</td>
 *               <td>Navigates to Bucket Files and Folders Page</td>
 *               <td>asset-management-service.bucket.files, User has bucket access (Either User or Role level).</td>
 *            </tr>
 *          </table>
 *          <br/>
 *        </p>
 *       <p>
 *          <h4>View/Create/Edit bucket Fields</h4>
 *          Create/View/Edit bucket page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Field Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>Textfield</td>
 *               <td>63</td>
 *            </tr>
 *             <tr>
 *               <td>Description</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>Textfield</td>
 *               <td>4096</td>
 *            </tr>
 *             <tr>
 *               <td>Bucket User Owners</td>
 *               <td>Yes</td>
 *               <td>Array</td>
 *               <td>Dropdown</td>
 *               <td>NA</td>
 *            </tr>
 *            <tr>
 *               <td>Bucker Role Owners</td>
 *               <td>Yes</td>
 *               <td>Array</td>
 *               <td>Dropdown</td>
 *               <td>NA</td>
 *            </tr>
 *          </table>
 *          <br/>
 *     </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes Create/Edit notifcate template failure status code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>AMS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>AMS_PARAM_DUPLICATE</td>
 *               <td>Duplicate bucket.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>AMS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create/edit/view bucket page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit bucket page.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Access Control Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *           <tr>
 *               <td>Asset Management Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API bucket permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>Create Bucket</td>
 *               <td>/bucket</td>
 *               <td>POST</td>
 *               <td>asset-management-service.bucket.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Get bucket by ID</td>
 *               <td>/bucket/{bucket-id}</td>
 *               <td>GET</td>
 *               <td>asset-management-service.bucket.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Update bucket</td>
 *               <td>/bucket/{bucket-id}</td>
 *               <td>PUT</td>
 *               <td>asset-management-service.bucket.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Delete Bucket</td>
 *               <td>/bucket/{bucket-id}</td>
 *               <td>DELETE</td>
 *               <td>asset-management-service.bucket.delete</td>
 *               <td>No</td>
 *           </tr>
 *           <tr>
 *               <td>View Bucket Contents</td>
 *               <td>/bucket/listfiles</td>
 *               <td>POST</td>
 *               <td>asset-management-service.bucket.files</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <section>
 *    <p>Sequence Diagram for Bucket Creation, View and Update Page</p>
 *   </section>
 *   </section>
 */

const READ_TYPE_OPTION = [
  { key: 'Read & write', value: 'READ & WRITE' },
  { key: 'Read Only', value: 'READ ONLY' },
  { key: 'Owner', value: 'OWNER' }
];

const CREATE_BUCKET_FORM_SCHEMA = {
  name: '',
  description: '',
  versioning: false,
  active: true,
  selectedUser: [],
  selectedRole: []
};

function FileContextMenu({ options }: any) {
  const [selectedOption] = useState<any>('');
  const { t } = useTranslation();
  return (
    <ActionDropdown
      className="!w-40"
      options={options}
      onSelectedOptionChange={(value: any) => {
        value.action();
      }}
      value={selectedOption}
      defaultLabel={t('T_MANAGE')}
    />
  );
}

const BucketDetail = () => {
  const params = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>(CREATE_BUCKET_FORM_SCHEMA);
  const [formDataCopy, setFormDataCopy] = useState<any>(CREATE_BUCKET_FORM_SCHEMA);
  const [formError, setFormError] = useState<any>({
    name: '',
    description: ''
  });

  const [bucket, setBucket] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<string>(t('T_INDIVIDUAL_USER') || '');
  const [roleList, setRoleList] = useState<any[]>([]);
  const [roleListTemp, setRoleListTemp] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [usedUserList, setUsedUserList] = useState<any>(null);
  const [disableButton, setDisableButton] = useState(false);

  const [userHasMore, setUserHasMore] = useState<boolean>(true);
  const [picklistLoading, setPicklistLoading] = useState<boolean>(false);
  const [roleHasMore, setRoleHasMore] = useState<boolean>(true);

  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showDeactivate, setShowDeactivate] = useState<boolean>(false);

  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};

  const permissionFailure = () => {
    dispatch(
      setToastData({
        isToastActive: true,
        toastMessage: t('T_NO_PERMISSION_TO_VIEW_THIS_PAGE'),
        toastType: 'error'
      })
    );
    navigate(RouteConstants.ROUTE_AMS_FILES);
  };

  useEffect(() => {
    async function init() {
      if (!params.id) {
        if (roleAPIPermissions[AppConstants.PERMISSION_AMS_CREATE_BUCKET]) {
          fetchRoleData('', 1, formData?.selectedRole);
          fetchUserData('', 1, [], formData?.selectedUser);
          setIsEdit(true);
        } else {
          permissionFailure();
        }
      } else {
        if (roleAPIPermissions[AppConstants.PERMISSION_AMS_GET_BUCKET]) {
          setIsLoading(true);
          await getBucketDetailById(params.id);
        } else {
          permissionFailure();
        }
      }
    }

    init();
  }, []);

  const getBucketDetailById = async (id: any) => {
    const response = await getBucketById(id);
    const { data, status } = response;
    if (HTTP_STATUS.isSuccess(status)) {
      let IdArr: any = getIdFromData(data);
      fetchRoleData('', 1, data?.bucketRolePermissions);
      fetchUserData('', 1, IdArr.userList);

      dispatch(
        setURLPaths([
          {
            key: `${RouteConstants.ROUTE_AMS_FILES}/${params.id}`,
            label: `${t('T_BUCKET')} > ${getBucketRelativeName(data?.name)}`
          }
        ])
      );
      setIsEdit(false);

      setBucket(data);
      SetFileDataInForm(data, true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      let error = getFailureMessage(response);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
  };

  useEffect(() => {
    if (disableButton) {
      setDisableButton(false);
    }
  }, [formData]);

  useEffect(() => {
    if (formData.selectedUser.length) {
      let mappedUsers = formData.selectedUser.map((item: any) => item?.key);
      setUserList(
        filter(userList, (item: any) => {
          return !mappedUsers.includes(item?.userId);
        })
      );
    }
  }, [formData.selectedUser]);

  const filterRoleSelection = (roleData: any) => {
    let mappedRoles = roleData.map((item: any) => item?.key);
    setRoleList(
      filter(roleListTemp, (item: any) => {
        return !mappedRoles.includes(item?.id);
      })
    );
  };

  useEffect(() => {
    filterRoleSelection(formData.selectedRole);
  }, [formData.selectedRole]);

  useEffect(() => {
    if (usedUserList) {
      SetFileDataInForm(bucket, true);
      setIsLoading(false);
    }
  }, [usedUserList, roleListTemp]);

  const SetFileDataInForm = async (bucketData: any = bucket, setCopyState: boolean = false) => {
    if (bucketData) {
      let permissionData = getPermissionsData();
      let obj = {
        name: bucketData.name.split(`${environment.ENV_TENANT_NAME}-`).join(''),
        description: bucketData.description,
        versioning: bucketData.versioning,
        active: bucketData.active,
        ...permissionData
      };
      if (setCopyState) {
        setFormDataCopy(obj);
      }
      setFormData(obj);
    }
  };

  const fetchRoleData = async (
    name: string = '',
    page: number = AppConstants.DEFAULT_PAGE,
    selectedRoles = formData.selectedRole
  ) => {
    let trimmedName = name.trim();
    let mappedIds = selectedRoles.length
      ? selectedRoles.map((item: any) => item?.roleId || item?.key)
      : [];
    setPicklistLoading(true);
    if ((!trimmedName || trimmedName === '') && roleListTemp.length) {
      let mappedIds = formData.selectedRole.length
        ? formData.selectedRole.map((item: any) => item?.key)
        : [];
      setRoleList(
        filter(roleListTemp, (item: any) => {
          return !mappedIds.includes(item?.id);
        })
      );
      setPicklistLoading(false);
      return [];
    }
    if (!roleListTemp.length) {
      let response;
      response = await listRoles(trimmedName, true, page, AppConstants.MAXIMUM_PAGE_SIZE, '', '');
      const { status, data: rolesResponse } = response;
      setRoleListTemp(rolesResponse.data);
      setPicklistLoading(false);

      if (HTTP_STATUS.isSuccess(status)) {
        setRoleList(
          filter(rolesResponse.data, (item: any) => {
            return !mappedIds.includes(item?.id);
          })
        );
      }
      setRoleHasMore(rolesResponse.paging?.next !== null);
    } else {
      if (trimmedName) {
        setRoleList(
          filter(roleListTemp, (item: any) => {
            return (
              !mappedIds.includes(item?.id) &&
              item.name.toLowerCase().includes(trimmedName.toLowerCase())
            );
          })
        );
      }
      setRoleHasMore(false);
      setPicklistLoading(false);
    }
  };

  const fetchUserData = async (
    name: string = '',
    page: number,
    idArr: any[] = [],
    selectedUsers: any[] = []
  ) => {
    let trimmedName = name.trim();
    let apiFilter = trimmedName
      ? `[{"field":"mail","operation":"co","value":"${trimmedName}"}]`
      : '';
    let idFilter = `[{"field":"userId","operation":"in","value":"${idArr.join(',')}"}]`;
    setPicklistLoading(true);

    if ((!trimmedName || trimmedName === '') && !idArr.length) {
      setUserList([]);
      setUserHasMore(false);
      setPicklistLoading(false);
      return [];
    }
    let response = await getUsers(
      idArr.length ? idFilter : apiFilter,
      page,
      AppConstants.DEFAULT_PAGE_SIZE,
      ''
    );

    let mappedUsers = selectedUsers.length ? selectedUsers.map((item) => item?.key) : idArr;

    const { status, data: usersResponse } = response;
    let retVal = null;
    setPicklistLoading(false);
    if (HTTP_STATUS.isSuccess(status)) {
      if (trimmedName) {
        setUserHasMore(usersResponse.paging?.next !== null);
        let filteredResponse = filter([...userList, ...usersResponse.data], (item: any) => {
          return !mappedUsers.includes(item?.userId);
        });

        if (!params?.id) {
          filteredResponse = filter(filteredResponse, (item: any) => {
            return item.userId !== userProfile?.userId;
          });
        }
        setUserList(filteredResponse);
      } else {
        if (idArr.length) {
          retVal = [...usersResponse.data];
          setUsedUserList([...usersResponse.data]);
          setUserHasMore(usersResponse.paging?.next !== null);
          return retVal;
        } else {
          retVal = [...userList, ...usersResponse.data];
          setUserList(
            filter(retVal, (item: any) => {
              return !mappedUsers.includes(item?.userId);
            })
          );
          setUserHasMore(usersResponse.paging?.next !== null);
          return retVal;
        }
      }
    } else {
      if (idArr.length) {
        setUsedUserList([...idArr]);
      } else {
        setUserList([...userList]);
      }
      return retVal;
    }
  };

  const getIdFromData = (bucketDetail: any) => {
    const {
      bucketOwners = [],
      bucketPermissions = [],
      bucketRoleOwners = [],
      bucketRolePermissions = [],
      createdBy = '',
      updatedBy = ''
    } = bucketDetail || {};

    let userList = [...bucketOwners, ...bucketPermissions].map((ele: any) => ele.userId);
    userList = uniq([...userList, createdBy, updatedBy]);

    let roleList = [...bucketRoleOwners, ...bucketRolePermissions].map((ele: any) => ele.roleId);
    return { userList, roleList };
  };

  const getPermissionsData = () => {
    const {
      bucketOwners = [],
      bucketPermissions = [],
      bucketRoleOwners = [],
      bucketRolePermissions = []
    } = bucket || {};

    let roleListtemp = [...bucketOwners, ...bucketPermissions];
    let userData: any = [];

    roleListtemp.forEach((user: any) => {
      const { userId, writePermission = null } = user;
      const bucketUser: any = find(usedUserList || [], { userId: userId });
      const { mail = '' } = bucketUser || {};

      if (writePermission === null) {
        userData.push({
          key: userId,
          value: mail || userId,
          permissionKey: 'OWNER',
          permisssion: READ_TYPE_OPTION[2]
        });
      } else {
        userData.push({
          key: userId,
          value: mail || userId,
          permissionKey: writePermission ? READ_TYPE_OPTION[0].value : READ_TYPE_OPTION[1].value,
          permisssion: writePermission ? READ_TYPE_OPTION[0] : READ_TYPE_OPTION[1]
        });
      }
    });

    let bucketRoleList = [...bucketRoleOwners, ...bucketRolePermissions];
    let roleData: any = [];

    bucketRoleList.forEach((user: any) => {
      const { roleId, writePermission = null } = user;
      const bucketRole: any = find(roleListTemp || [], { id: roleId });
      const { name = '' } = bucketRole || {};
      if (writePermission === null) {
        roleData.push({
          key: roleId,
          value: name || roleId,
          permissionKey: 'OWNER',
          permisssion: READ_TYPE_OPTION[2]
        });
      } else {
        roleData.push({
          key: roleId,
          value: name || roleId,
          permissionKey: writePermission ? READ_TYPE_OPTION[0].value : READ_TYPE_OPTION[1].value,
          permisssion: writePermission ? READ_TYPE_OPTION[0] : READ_TYPE_OPTION[1]
        });
      }
    });

    return { selectedRole: roleData, selectedUser: userData };
  };

  const getPayload = () => {
    const { name, description, versioning, active, selectedUser, selectedRole } = formData;
    let payload = {
      name: `${environment.ENV_TENANT_NAME}-${name}`.trim(),
      description: description.trim(),
      versioning,
      active,
      bucketOwners: [],
      bucketPermissions: [],
      bucketRoleOwners: [],
      bucketRolePermissions: []
    };

    let ownersUser: any = [];
    let permissionsUser: any = [];
    selectedUser.forEach((user: any) => {
      if (user.permissionKey === 'OWNER') {
        ownersUser.push(user.key);
      } else {
        permissionsUser.push({
          userId: user.key,
          writePermission: user.permissionKey === 'READ & WRITE'
        });
      }
    });
    payload.bucketOwners = ownersUser;
    payload.bucketPermissions = permissionsUser;

    let ownersRole: any = [];
    let permissionsRole: any = [];
    selectedRole.forEach((role: any) => {
      if (role.permissionKey === 'OWNER') {
        ownersRole.push(role.key);
      } else {
        permissionsRole.push({
          roleId: role.key,
          writePermission: role.permissionKey === 'READ & WRITE'
        });
      }
    });
    payload.bucketRoleOwners = ownersRole;
    payload.bucketRolePermissions = permissionsRole;

    return payload;
  };

  const validateFormData = () => {
    let errors: any = {
      name: '',
      description: ''
    };
    errors['name'] = fieldIsEmpty(formData.name.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(formData.name.trim(), 63)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_CUSTOM_MSG').replace('${LENGTH}', '63');

    errors['description'] = fieldIsEmpty(formData?.description?.trim())
      ? ''
      : validateNameLength(formData.description.trim(), 4096)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG_4096');

    setFormError(errors);
    return errors;
  };

  const createNewBucket = async () => {
    const errors = validateFormData();
    if (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    ) {
      setDisableButton(true);
      return;
    }
    setIsLoading(true);
    let payload: any = getPayload();
    let createResponse = await createBucket(payload);
    const { status } = createResponse;
    if (status === HTTP_STATUS.HTTP_CREATED) {
      setIsLoading(false);
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_BUCKET_CREATION_SUCCESS_MSG'),
          toastType: 'success'
        })
      );
      navigate(RouteConstants.ROUTE_AMS_FILES);
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: getFailureMessage(createResponse),
          toastType: 'error'
        })
      );
    }
  };

  const updateBucket = async () => {
    const errors = validateFormData();
    if (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    ) {
      setDisableButton(true);
      return;
    }
    setIsLoading(true);
    let payload: any = getPayload();
    let createResponse = await updateBucketPermissions(payload, bucket.id);
    const { status } = createResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      setUserList([]);
      setUserHasMore(true);
      setRoleList([]);
      setRoleHasMore(true);
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_BUCKET_UPDATED_SUCCESS_MSG'),
          toastType: 'success'
        })
      );
      await getBucketDetailById(bucket.id);
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: getFailureMessage(createResponse),
          toastType: 'error'
        })
      );
    }
  };

  const activateBucket = async (active: boolean) => {
    let payload: any = getPayload();
    payload.active = active;
    let createResponse = await updateBucketPermissions(payload, bucket.id);
    const { data, status } = createResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      setBucket(data);
      setFormData({ ...formData, active: data.active });
      const toastMessage: string = data?.active
        ? t('T_BUCKET_ACTIVATED_SUCCESS_MSG')
        : t('T_BUCKET_DEACTIVATED_SUCCESS_MSG');
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: toastMessage.replace('%s', data?.name),
          toastType: 'success'
        })
      );
    } else {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: getFailureMessage(createResponse),
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const DeleteBucketCall = async () => {
    let deleteResponse = await deleteBucket(bucket.id);
    const { data, status } = deleteResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_BUCKET_DELETE_SUCCESS_MSG'),
          toastType: 'success'
        })
      );
      navigate(RouteConstants.ROUTE_AMS_FILES);
    } else {
      setIsLoading(false);
      let error = getFailureMessage(deleteResponse);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
  };

  const cleanupUser = () => {
    setUserList([]);
    setUserHasMore(false);
  };

  const cleanupRole = () => {
    filterRoleSelection(formData.selectedRole);
  };

  const tabConfig = [
    {
      label: t('T_INDIVIDUAL_USER'),
      component: (
        <div>
          <SubItemForRoleAndUser
            formData={formData}
            formError={formError}
            setFormData={setFormData}
            formDataKey={'selectedUser'}
            setOptionsList={setUserList}
            optionsList={userList.map((ele: any) => {
              return { ...ele, name: ele.mail, id: ele.userId };
            })}
            fetchDataAPICall={fetchUserData}
            hasMore={userHasMore}
            setHasMore={setUserHasMore}
            isLoading={picklistLoading}
            setIsLoading={setPicklistLoading}
            cleanup={cleanupUser}
            type="bucket"
          />
        </div>
      )
    },
    {
      label: t('T_USER_ROLE'),
      component: (
        <div>
          <SubItemForRoleAndUser
            formData={formData}
            key={roleListTemp.length}
            formError={formError}
            setFormData={setFormData}
            formDataKey={'selectedRole'}
            optionsList={roleList}
            setOptionsList={setRoleList}
            fetchDataAPICall={fetchRoleData}
            hasMore={roleHasMore}
            setHasMore={setRoleHasMore}
            isLoading={picklistLoading}
            setIsLoading={setPicklistLoading}
            cleanup={cleanupRole}
            type="bucket"
          />
        </div>
      )
    }
  ];

  const getMenuOptions = () => {
    let options: any = [];

    if (roleAPIPermissions[AppConstants.PERMISSION_AMS_UPDATE_BUCKET]) {
      options.push({
        display: t('T_EDIT'),
        action: () => {
          setIsEdit(true);
        }
      });
    }

    if (roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_BUCKET]) {
      options.push({
        display: t('T_DELETE'),
        action: () => {
          setShowDeletePopup(true);
        }
      });
    }
    const { roles = [] } = userProfile || {};
    const [userRole] = roles;
    if (
      userProfile &&
      roleAPIPermissions[AppConstants.PERMISSION_AMS_LIST_FILES] &&
      hasBucketAccess(bucket, userProfile?.userId, userRole?.roleId) &&
      bucket?.active
    ) {
      options.push({
        display: t('T_BUCKET_FILE_FOLDER'),
        action: () => {
          dispatch(setBucketData(bucket || {}));
          navigate(
            RouteConstants.ROUTE_AMS_FOLDER_LIST.replace(':id', bucket.id).replace(
              ':folderId',
              ':folders'
            )
          );
        }
      });
    }

    return options;
  };

  const RadioButtons = (disable: boolean, formDataKey: string) => {
    return (
      <>
        <div
          className="flex"
          id="metadata-versioning-container"
          data-testId="metadata-versioning-container"
        >
          <RadioButton
            selectedField={formData[formDataKey]}
            option={true}
            name={formDataKey + 'Yes'}
            label={t('T_YES')}
            className="mb-3 mr-3"
            id="metadata-versioning-true"
            data-testId="metadata-versioning-true"
            disabled={disable}
            onClick={() => {
              setFormData({ ...formData, [formDataKey]: true });
            }}
          />
          <RadioButton
            selectedField={formData[formDataKey]}
            option={false}
            id="metadata-versioning-false"
            data-testId="metadata-versioning-false"
            name={formDataKey + 'No'}
            label={t('T_NO')}
            className="mb-3"
            disabled={disable}
            onClick={() => {
              setFormData({ ...formData, [formDataKey]: false });
            }}
          />
        </div>
      </>
    );
  };

  const validator = () => {
    if (disableButton) {
      return !disableButton;
    }
    let enable: boolean = false;
    const { name = '', description = '' } = formData;
    if (params?.id) {
      enable = !isEqual(bucket, formData);
    } else {
      enable = name.trim().length && description.trim().length;
    }
    return enable;
  };

  const isBucketDetailsChanged = () => {
    if (params?.id) {
      const fieldsToCheck = ['name', 'description', 'selectedUser', 'selectedRole'];

      for (const field of fieldsToCheck) {
        if (!isEqual(formData[field], formDataCopy[field])) {
          return true;
        }
      }

      return false;
    }
    return true;
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <div className="flex w-full">
        <div className="w-3/4">
          <div className="full flex flex-col  overflow-visible px-2 py-3">
            <Card
              title={t('T_BUCKET_DETAILS')}
              icon={SettingIcon}
              alt={'T_BUCKET_DETAILS'}
              height={''}
              width={''}
              className=""
            >
              <MenuButtonsPortal>
                {isEdit ? (
                  <>
                    <BiButton
                      className={'mr-2 text-primary'}
                      type="button"
                      onClick={() => {
                        if (params.id) {
                          SetFileDataInForm();
                          setIsEdit(false);
                        } else {
                          navigate(RouteConstants.ROUTE_AMS_FILES);
                        }
                      }}
                    >
                      {t('T_CANCEL')}
                    </BiButton>

                    {!!params.id ? (
                      <BiButton
                        className={'bg-primary text-white'}
                        type="button"
                        onClick={() => {
                          updateBucket();
                        }}
                        disabled={!validator() || !isBucketDetailsChanged()}
                      >
                        {t('T_UPDATE')}
                      </BiButton>
                    ) : (
                      <BiButton
                        className={'bg-primary text-white'}
                        type="button"
                        onClick={() => {
                          createNewBucket();
                        }}
                        disabled={!validator()}
                      >
                        {t('T_CREATE')}
                      </BiButton>
                    )}
                  </>
                ) : (
                  <>
                    <BiButton
                      className={'mr-2 text-primary'}
                      type="button"
                      onClick={() => navigate(RouteConstants.ROUTE_AMS_FILES)}
                    >
                      {t('T_BACK_TO_BUCKET_LIST')}
                    </BiButton>

                    <div>
                      <FileContextMenu options={getMenuOptions()} />
                    </div>
                  </>
                )}
              </MenuButtonsPortal>
              <div className="flex w-full flex-wrap">
                <div className="mb-5 flex w-full">
                  <div className="flex w-3/4 flex-col">
                    <InputField
                      type={'text'}
                      id={'bucketName'}
                      data-testId="metadata-bucketName-input"
                      label={t('T_NAME')}
                      placeholder={''}
                      fieldKey={'name'}
                      // prefixValue={`${environment.ENV_TENANT_NAME}-`}
                      asterixFirst
                      required
                      formData={formData}
                      setFormData={setFormData}
                      // popoverContent={
                      //   <div className="w-[480px] p-4">
                      //     <p className="mb-2 font-bold">{t('T_GCP_BUCKET_RULE_HEADER')}</p>
                      //     <ol className="ml-6 list-decimal">
                      //       <li className="mb-2">{t('T_GCP_BUCKET_RULE_1')}</li>
                      //       <li className="mb-2">{t('T_GCP_BUCKET_RULE_2')}</li>
                      //       <li className="mb-2">{t('T_GCP_BUCKET_RULE_3')}</li>
                      //     </ol>
                      //   </div>
                      // }
                      formError={formError}
                      disabled={!isEdit || isString(params.id)}
                      max={AppConstants.MAX_LENGTH_BUCKET_NAME}
                      handleChange={(e: any) => {
                        const { value = '' } = e.target;
                        if (consentNameValidation(value)) {
                          setFormData({ ...formData, name: value });
                        }
                      }}
                    />
                  </div>
                  <div className="mt-5 w-1/4 pl-7">
                    <Label id="metadata-label-versioning" data-testId="metadata-label-versioning">
                      {t('T_ALLOW_VERSIONING')}?
                    </Label>
                    {RadioButtons(!!params.id, 'versioning')}
                  </div>
                </div>
                <div className="mb-5 flex w-full flex-wrap">
                  <div className="flex w-3/4 flex-col">
                    <TextAreaField
                      fieldKey={'description'}
                      type={'text'}
                      id={'importDesc'}
                      data-testId="metadata-description-input"
                      label={t('T_DESCRIPTION')}
                      placeholder={''}
                      required
                      formData={formData}
                      setFormData={setFormData}
                      formError={formError}
                      asterixFirst
                      rows={3}
                      disabled={!isEdit}
                      maxLength={AppConstants.MAX_LENGTH_BUCKET_DESCRIPTION}
                    ></TextAreaField>
                  </div>

                  {/* <div className="mt-5 w-1/4 pl-7">
                    <Label>{t('T_ALLOW_VERSIONING')}?</Label>
                    {RadioButtons(!!params.id, 'versioning')}
                  </div> */}
                </div>
              </div>
            </Card>

            <Card
              title={t('T_BUCKET_OWNERS')}
              icon={lock_icon}
              alt={'T_BUCKET_OWNERS'}
              height={''}
              width={''}
              className=""
            >
              <Label>{t('T_BUCKET_OWNER_DESCRIPTION_TEXT')}</Label>

              <div className="mt-4">
                <TabToggleButton
                  activteTab={activeTab}
                  tabConfig={tabConfig}
                  changeTab={setActiveTab}
                  disabled={!isEdit}
                />
              </div>
            </Card>
          </div>
        </div>

        {!isLoading && params.id && (
          <div className="ml-4">
            <StatusField
              type={'text'}
              id={'status'}
              data-testId="metadata-status-select"
              label={t('T_STATUS')}
              placeholder={t('T_STATUS')}
              required={false}
              value={formData['active'] ? 'active' : 'inactive'}
              error={''}
              disabled={true}
              layout="100"
              metadata={{
                active: {
                  style: { background: '#D9F2E8', border: '1px solid #8C8C8C' },
                  description: `${t('T_BUCKET_MSG')} ${t('T_ACTIVE')}`,
                  display: t('T_ACTIVE')
                },
                inactive: {
                  style: { background: '#E8A6B3', border: '1px solid #E3342F' },
                  description: `${t('T_BUCKET_MSG')} ${t('T_NOT_ACTIVE')}`,
                  display: t('T_NOT_ACTIVE')
                }
              }}
            />
            {(bucket?.createdOn || bucket?.createdBy) && (
              <div>
                <AuditInfo
                  label={'CREATED'}
                  date={bucket?.createdOn || ''}
                  user={bucket?.createdBy || ''}
                />
              </div>
            )}
            {(bucket?.updatedOn || bucket?.updatedBy) && (
              <div>
                <AuditInfo
                  label={'LAST_MODIFIED'}
                  date={bucket?.updatedOn || ''}
                  user={bucket?.updatedBy || ''}
                />
              </div>
            )}

            {/* {bucket?.active && ( */}
            {roleAPIPermissions[AppConstants.PERMISSION_AMS_UPDATE_BUCKET] && (
              <div>
                <BiButton
                  className={`mt-5 h-10 w-48 border border-solid leading-5 hover:text-white ${
                    bucket?.active
                      ? 'border-red-600 text-danger hover:bg-red-600'
                      : 'border-green-600 text-green hover:bg-[#28b980]'
                  }`}
                  type="button"
                  onClick={() => {
                    setShowDeactivate(true);
                  }}
                >
                  {t(bucket?.active ? 'T_DEACTIVATE' : 'T_ACTIVATE')}
                </BiButton>
              </div>
            )}
            {/* )} */}
          </div>
        )}
      </div>

      <Modal
        showCloseIcon={true}
        width="48rem"
        headerText={
          showDeletePopup
            ? t('T_DELETE_CONFIRMATION_TITLE')
            : t(
                bucket?.active
                  ? 'T_DEACTIVATE_CONFIRMATION_TITLE'
                  : 'T_ACTIVATION_CONFIRMATION_TITLE'
              )
        }
        toggleVisiblity={(value: boolean) => {
          setShowDeactivate(value);
          setShowDeletePopup(value);
        }}
        isVisible={showDeletePopup || showDeactivate}
        body={
          <div>
            <div>
              {showDeactivate
                ? t(
                    bucket?.active
                      ? 'T_DEACTIVATE_BUCKET_CONFIRMATION_TEXT'
                      : 'T_ACTIVATION_BUCKET_CONFIRMATION_TEXT'
                  )
                : t('T_BUCKET_DELETE_CONFIRMATION_TEXT')}
            </div>
            <div className="mt-8 flex">
              <BiButton
                className={'ml-auto mr-2 text-primary'}
                type="button"
                onClick={() => {
                  setShowDeactivate(false);
                  setShowDeletePopup(false);
                }}
              >
                {t('T_CANCEL')}
              </BiButton>
              <BiButton
                className={'bg-primary text-white'}
                type="button"
                onClick={() => {
                  setIsLoading(true);
                  if (showDeactivate) {
                    activateBucket(!bucket.active);
                    setShowDeactivate(false);
                  } else {
                    DeleteBucketCall();
                  }
                }}
              >
                {showDeactivate
                  ? t(bucket.active ? 'T_BUTTON_YES_DEACTIVATE' : 'T_BUTTON_YES_ACTIVATE')
                  : t('T_BUCKET_DELETE_CONFIRM')}
              </BiButton>
            </div>
          </div>
        }
        headerLeftIcon={<ExclamationIcon className="h-7.5 w-6" />}
      />
    </LoaderContainer>
  );
};

export default BucketDetail;
