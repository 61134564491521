import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ItemObj {
  id: string;
}

const initialState: any = {
  metadataComponent: { route: 'metadata', id: '', objectId: '' },
  manifest: {
    organizations: [],
    globalSettingsOrg: [],
    orgRoles: [],
    orgTemplates: [],
    globalSettings: [],
    globalCategories: [],
    globalTemplates: [],
    globalQuestionnaires: [],
    globalCarePlanTemplates: [],
    globalRoles: [],
    passwordPolicies: [],
    permissions: [],
    applications: [],
    tempApplications: [],
    metadata: []
  }
};

export const migrationExportSlice = createSlice({
  name: 'migrationExport',
  initialState,
  reducers: {
    changeMetadataComponent: (state, action) => {
      state.metadataComponent = action.payload;
    },
    setManifest: (state, action) => {
      const { value, key } = action.payload;
      state.manifest[key] = value;
    },
    deleteManifestItem: (state, action) => {
      const { id, key } = action.payload;
      state.manifest[key] = state.manifest[key].filter(function (item: ItemObj) {
        return item.id != id;
      });
    },
    resetMigrationReducer: () => initialState,
    updateManifest: (state, action: PayloadAction<{ [key: string]: any }>) => {
      const updates = action.payload;
      Object.keys(updates).forEach((key) => {
        state.manifest[key] = updates[key];
      });
    }
  }
});

export const {
  setManifest,
  deleteManifestItem,
  changeMetadataComponent,
  resetMigrationReducer,
  updateManifest
} = migrationExportSlice.actions;

export default migrationExportSlice.reducer;
