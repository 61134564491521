import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../../hooks/queryParams';
import { useTranslation } from 'react-i18next';
import { RouteConstants } from '../../../constants/RouteConstants';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import Modal from '../../shared/ModalPortal/Modal';
import addModulesIcon from '../../../assets/addModulesIcon.svg';
import styled from 'styled-components';
import TemplateTable from '../../shared/tables/TemplateTable';
import { ITableHeader } from '../../shared/tables/models/ITableHeader';
import Checkbox from '../../shared/Fields/Checkbox';
import { EllipsisDiv } from '../../../styles/globalStyles';
import { useTheme } from '../../../context/themeContext';
import { find, sortBy } from 'lodash';
import { AppConstants } from '../../../constants/AppConstants';
import ModulesTable from './ModulesTable';

interface AddModulesProps {
  config: any;
  toggleModal: Function;
}

const Container = styled.div`
  width: 100%;
  min-height: 25vh;
  max-height: 90vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const AddModules = ({ config, toggleModal }: AddModulesProps) => {
  const { title, component } = config;
  const { t } = useTranslation();

  return (
    <Modal
      showHeader={true}
      showCloseIcon={true}
      headerLeftIcon={
        <div className="flex h-7 w-7 items-center justify-center rounded-full bg-[#EAF5FA]">
          <img src={addModulesIcon} />
        </div>
      }
      isVisible={true}
      headerText={`Select ${t(title)}`}
      width={'75rem'}
      toggleVisiblity={(value: boolean) => {
        toggleModal(value);
      }}
      parentOverflowProperty="overflow-hidden"
      body={
        component ? (
          component('selection')
        ) : (
          <ModulesTable config={config} toggleModal={toggleModal} type="selection"></ModulesTable>
        )
      }
    />
  );
};

export default AddModules;
