import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  passwordPolicy: null as any
};

export const passwdPolicySlice = createSlice({
  name: 'passwordPolicy',
  initialState,
  reducers: {
    setPasswordPolicy: (state, action) => {
      state.passwordPolicy = action.payload;
    },
    resetPasswordPolicy: () => initialState
  }
});

export const { setPasswordPolicy,resetPasswordPolicy } = passwdPolicySlice.actions;

export default passwdPolicySlice.reducer;
