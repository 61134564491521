import { createSlice } from '@reduxjs/toolkit';
import { AppConstants } from '../constants/AppConstants';

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState: {
    organizationTypes: JSON.parse(
      sessionStorage.getItem(AppConstants.SOLUTION_ORG_TYPES_KEY) || '[]'
    ),
    orgInvitationPolicy: JSON.parse(
      sessionStorage.getItem(AppConstants.SOLUTION_ORG_INVITATION_POLICY_KEY) || '{}'
    ),
    orgCreationPolicy: JSON.parse(
      sessionStorage.getItem(AppConstants.SOLUTION_ORG_CREATION_POLICY_KEY) || '{}'
    ),
    solutionSettings: JSON.parse(
      sessionStorage.getItem(AppConstants.SOLUTION_APPLICATION_SETTINGS) || '{}'
    ),
    userInvitationPolicy: JSON.parse(
      sessionStorage.getItem(AppConstants.CATEGORY_USER_MGMT_INVITATION_POLICY) || '{}'
    ),
    orgAddressCountryList: JSON.parse(
      sessionStorage.getItem(AppConstants.SOLUTION_ORG_ADDRESS_COUNTRY_DEFAULT_VALUES) || '[]'
    ),
    solutionConfigurationDefaults: JSON.parse(
      sessionStorage.getItem(AppConstants.SOLUTION_CONFIGURATION_DEFAULTS) || '{}'
    ),
    settingKeyDetails: {},
    categoryDetails: {}
  },
  reducers: {
    setOrganizationTypes: (state, action) => {
      state.organizationTypes = action.payload;
      sessionStorage.setItem(AppConstants.SOLUTION_ORG_TYPES_KEY, JSON.stringify(action.payload));
    },
    setOrgInvitationPolicy: (state, action) => {
      state.orgInvitationPolicy = action.payload;
      sessionStorage.setItem(
        AppConstants.SOLUTION_ORG_INVITATION_POLICY_KEY,
        JSON.stringify(action.payload)
      );
    },
    setOrgCreationPolicy: (state, action) => {
      state.orgCreationPolicy = action.payload;
      sessionStorage.setItem(
        AppConstants.SOLUTION_ORG_CREATION_POLICY_KEY,
        JSON.stringify(action.payload)
      );
    },
    setSolutionSettings: (state, action) => {
      state.solutionSettings = action.payload;
      sessionStorage.setItem(
        AppConstants.SOLUTION_APPLICATION_SETTINGS,
        JSON.stringify(action.payload)
      );
    },
    setUserInvitationPolicy: (state, action) => {
      state.userInvitationPolicy = action.payload;
      sessionStorage.setItem(
        AppConstants.CATEGORY_USER_MGMT_INVITATION_POLICY,
        JSON.stringify(action.payload)
      );
    },
    setOrgAddressCountryList: (state, action) => {
      state.orgAddressCountryList = action.payload;
      sessionStorage.setItem(
        AppConstants.SOLUTION_ORG_ADDRESS_COUNTRY_DEFAULT_VALUES,
        JSON.stringify(action.payload)
      );
    },
    setSolutionConfigurationDefaults: (state, action) => {
      state.solutionConfigurationDefaults = action.payload;
      if (action.payload?.migration) {
        if (action.payload.migration?.logRefreshInterval) {
          AppConstants.DEFAULT_MIGRATION_LOG_REFRESH_INTERVAL =
            action.payload.migration?.logRefreshInterval;
        }
      }
      if (action.payload?.dataGrid) {
        if (action.payload.dataGrid?.defaultPageSize) {
          AppConstants.DEFAULT_PAGE_SIZE = action.payload.dataGrid.defaultPageSize;
        }
        if (action.payload.dataGrid?.pages) {
          AppConstants.DEFAULT_PAGE_SIZES = action.payload.dataGrid.pages;
        }
      }
      sessionStorage.setItem(
        AppConstants.SOLUTION_CONFIGURATION_DEFAULTS,
        JSON.stringify(action.payload)
      );
    },
    setSettingKeyDetails: (state, action) => {
      state.settingKeyDetails = action.payload;
    },
    setCategoryDetails: (state, action) => {
      state.categoryDetails = action.payload;
    }
  }
});

export const {
  setOrganizationTypes,
  setOrgInvitationPolicy,
  setOrgCreationPolicy,
  setSolutionSettings,
  setUserInvitationPolicy,
  setOrgAddressCountryList,
  setSolutionConfigurationDefaults,
  setSettingKeyDetails,
  setCategoryDetails
} = configurationSlice.actions;

export default configurationSlice.reducer;
