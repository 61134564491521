import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import Asterix from '../../assets/asterix.svg';
import Asterix from '../../../assets/asterix.svg';

export const Label = styled.label`
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const InputStatus = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  height: 44px;
  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  text-transform: capitalize;
`;

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

interface StatusFieldProps {
  label: string;
  required: boolean;
  disabled: boolean;
  type: string;
  id: string;
  placeholder: any;
  value: string;
  error: any;
  layout: string;
  metadata: any;
}

export function StatusField({
  label,
  required,
  disabled,
  type,
  id,
  placeholder,
  value,
  error,
  layout,
  metadata = {}
}: StatusFieldProps) {
  const statusField: any = {
    registered: { background: '#bfe4bf', border: '1px solid green' },
    cancelled: { background: '#E8A6B3', border: '1px solid #e3342f' }
  };
  const { t } = useTranslation();

  return (
    <>
      <div className={`my-2.5 mb-5 pr-4 ${layout === '50' ? 'w-1/2' : 'w-full'}`}>
        <Label htmlFor={'id demo'}>
          {t(label)}
          {required ? <Image src={Asterix} /> : ''}
        </Label>
        <InputStatus
          className={`w-48 capitalize`}
          style={metadata[value]?.style || {}}
          type={type}
          id={id}
          placeholder={t(placeholder) || ''}
          required={required}
          value={metadata[value]?.display || value}
          disabled={disabled}
        />
        <div className="text-sm text-gray-700">{metadata[value]?.description || ''}</div>
        <ErrorLabel>{error[id] ? error[id] : ''}</ErrorLabel>
      </div>
    </>
  );
}
