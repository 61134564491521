import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DotMenu, { Options } from '../primitives/dotMenu/DotMenu';
import { MenuConstants } from '../../constants/MenuConstants';
import ActionDropdown from '../primitives/dropdowns/ActionDropdown';
import { find } from 'lodash';

interface AssignCategoryToOrgProps {
  orgList: any;
  onOrgSelected: Function;
  enableAssignToOrg: Boolean;
  selectedCategories: any;
  searchData?: any;
  searchable?: boolean;
  fetchMoreData?: Function;
  hasMore?: boolean;
  filtersList?: any;
}

const AssignCategoryToOrg: React.FC<AssignCategoryToOrgProps> = ({
  orgList,
  onOrgSelected,
  enableAssignToOrg,
  selectedCategories,
  searchData,
  searchable,
  fetchMoreData,
  hasMore = false,
  filtersList
}) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<Options | null>();
  const [dropDownOptions, setDropDownOptions] = useState<any>([]);
  const [orgFilterObj, setOrgFilterObj] = useState<any>({});
  const { t } = useTranslation();

  useEffect(() => {
    if (filtersList && filtersList.length) {
      const orgFilterObj = find(filtersList, { id: 'orgFilter' });
      if (orgFilterObj) {
        const options = orgFilterObj.itemsList.map((org: any) => {
          return {
            display: org.name,
            key: org.id
          };
        });
        setOrgFilterObj(orgFilterObj);
        setDropDownOptions(options);
      }
    }
  }, [filtersList]);

  return (
    <>
      <ActionDropdown
        options={dropDownOptions}
        className={!enableAssignToOrg ? 'disabled' : ''}
        onSelectedOptionChange={(value: any) => {
          onOrgSelected(value);
        }}
        searchData={searchData}
        searchable={searchable}
        fetchMoreData={fetchMoreData}
        hasMore={orgFilterObj.hasMore}
        searchPlaceholder={t('T_SEARCH_ORG_NAME')}
        value={selectedOption}
        defaultLabel={
          enableAssignToOrg
            ? `${t('T_ASSIGN_SELECTED')} (${selectedCategories.length})`
            : t('T_SELECT_CATEGORY_TO_ASSIGN')
        }
      />
    </>
  );
};

export default AssignCategoryToOrg;
