import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Label } from '../Onboarding/OnboardingStyles';
import InputField from '../shared/Fields/InputField';
import CheckIcon from '@heroicons/react/outline/CheckIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import { isUndefined } from 'lodash';

import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import './ObjectNode.css';
import { AddNodeAction, DeleteNodeAction, EditNodeAction } from './AttributeTree';
import { getDataTypes, isObjectType } from './metadataUtil';

const Switch = styled.div<{ toggle: boolean }>`
  width: 44px;
  height: 22px;
  background: ${({ toggle }) => (!toggle ? '#BFBFBF' : '#0074e0')};
  border-radius: 16px;
  position: relative;
  transition: ease-out 0.5s;
  cursor: pointer;
  disabled
`;

const ToggleButton = styled.div<{ toggle: boolean }>`
  position: absolute;
  height: 18px;
  top: calc(50% - 18px / 2);
  background: #ffffff;
  border-radius: 16px;
  width: 16px;
  ${({ toggle }) => (!toggle ? `left: 2px;` : 'right: 2px;')}
`;

const RowItem = styled.li<{ showborder: boolean }>`
  border-bottom: ${({ showborder }) => (showborder ? '1px solid #d9d9d9' : 'unset')};
  &:nth-child(odd) {
    background-color: #ffffff;
  }
  &:nth-child(even) {
    background-color: #fafafa;
  }
`;

const DEFAULT_KEY_ID = 'key';
const DEFAULT_VALUE_KEY = 'value';

export interface Attribute {
  type: string;
  name: string;
  isRequired: boolean;
  editable: boolean;
  maxLength: number;
  min: number;
  max: number;
  attributes?: Array<any>;
  edit: boolean;
  expanded: boolean;
  parent?: Attribute;
  errors?: any;
  level?: number;
  isNew?: boolean;
  enum?: Array<string>;
  searchable?: boolean;
  sortable?: boolean;
  // parentNode: Attribute | null;
}

export interface AttributeTreeNodeProps {
  order: number;
  level: number;
  readOnly: boolean;
  attribute: Attribute;
  hasInstances?: boolean;

  onAddNode: Function;
  onRemoveNode: Function;
  onValueChange: Function;
  expandTreeNode: Function;
  onDataTypeChange: Function;
}

const AttributeTreeNode: React.FC<AttributeTreeNodeProps> = ({
  level = 0,
  order = 0,
  readOnly = false,
  hasInstances = false,
  attribute,
  onAddNode,
  onRemoveNode,
  onValueChange,
  expandTreeNode,
  onDataTypeChange
}: AttributeTreeNodeProps) => {
  const { t } = useTranslation();
  const {
    name,
    type,
    isRequired = false,
    editable = true,
    maxLength,
    min,
    max,
    attributes = [],
    parent,
    edit = false,
    expanded = false,
    errors = {},
    isNew = false,
    searchable = false,
    sortable = false
  } = attribute;

  const [isEdit, setIsEdit] = useState<boolean>(edit);
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
  const [children, setChildren] = useState<Array<any>>(attributes);

  const [formData, setFormData] = useState<any>({
    name,
    type,
    isRequired,
    editable,
    searchable,
    sortable,
    maxLength,
    min,
    max
  });
  const [formError, setFormError] = useState<any>(errors);

  const enableCommit = () => {
    const isEmptyName = !name.trim().length;
    if (!type || isEmptyName) {
      // setFormError({ ...formError, name: !!isEmptyName, type: !!type });
      return false;
    }
    let enable: boolean = true;
    const newFormError: any = { name: false, type: false };
    if (['String'].includes(type)) {
      enable = !!(maxLength > 0);
      newFormError['maxLength'] = !enable;
    }
    if (['Integer', 'Number'].includes(type)) {
      const minEnable = min >= Number.MIN_VALUE;
      const maxEnable = max <= Number.MAX_VALUE;
      enable = !!(minEnable && maxEnable);
      newFormError['min'] = !minEnable;
      newFormError['max'] = !maxEnable;
    }
    // setFormError({ ...formError, ...newFormError });
    return enable;
  };

  const onDataTypeSelection = (value: any) => {
    setFormData({ ...formData, type: value });
    if (onDataTypeChange) {
      onDataTypeChange(getParentChaining(), value, level, order);
    }
  };

  const onChange = (key: string, value: any, focusOut: boolean = false) => {
    if (focusOut && onValueChange) {
      onValueChange(getParentChaining(), key, value, level, order);
    }
  };

  const getParentChaining = () => {
    const { parent } = attribute;
    let parentNode: any = parent;
    const parentChain: Array<any> = new Array<any>(attribute);
    while (parentNode) {
      parentChain.splice(0, 0, parentNode);
      parentNode = parentNode.parent || null;
    }
    return parentChain;
  };

  const createChildNode = () => {
    if (onAddNode) {
      onAddNode(getParentChaining(), level + 1, attributes.length);
    }
  };

  const deleteNode = () => {
    if (onRemoveNode) {
      onRemoveNode(true, getParentChaining(), level, order);
    }
  };

  const toggleEdit = () => {
    // setIsEdit(!isEdit);
    if (onValueChange) {
      onValueChange(getParentChaining(), 'edit', !isEdit, level, order);
    }
  };

  const expandTree = () => {
    setIsExpanded(!isExpanded);
    if (expandTreeNode) {
      expandTreeNode(getParentChaining(), level, order, !isExpanded);
    }
  };

  const isRootNode = () => !parent;

  const isLeafElement = !children.length;

  return (
    <ul
      id={`${isRootNode() ? 'topNode' : ''}`}
      className={isRootNode() ? 'flex-col' : 'active flex-col'}
    >
      <RowItem key={`${level}_${order}`} className="width-full flex flex-wrap" showborder={true}>
        <span
          className={`flex items-center ${
            !isLeafElement ? (isExpanded ? 'caret caret-down' : 'caret') : 'w-4'
          }`}
          onClick={() => expandTree()}
        ></span>

        <div className={`w-${29 - level} m-1 flex flex-col`} style={{ width: `${29 - level}rem` }}>
          <Label
            className={`flex flex-1 justify-center ${isLeafElement && !isRootNode ? 'ml-5' : ''}`}
          >
            {name}
          </Label>
        </div>

        <div className={`w-${12} m-1 flex  flex-row`} style={{ width: `${12}rem` }}>
          <Label className="flex flex-1 justify-center">{type}</Label>
        </div>

        <div className="m-1 flex flex-1 flex-row">
          <Label className="flex flex-1 justify-center">
            {formData.isRequired ? 'True' : 'False'}
          </Label>
        </div>

        <div className="m-1 flex flex-1 flex-row justify-center">
          <Label className="flex flex-1 justify-center">
            {formData.editable ? 'True' : 'False'}
          </Label>
        </div>

        <div className="m-1 flex flex-1 flex-row justify-center">
          <Label className="flex flex-1 justify-center">
            {formData.searchable ? 'True' : 'False'}
          </Label>
        </div>

        <div className="m-1 flex flex-1 flex-row justify-center">
          <Label className="flex flex-1 justify-center">
            {formData.sortable ? 'True' : 'False'}
          </Label>
        </div>

        <div className=" m-1 flex flex-1 flex-row flex-wrap justify-center">
          {maxLength && (
            <Label className="flex flex-1 items-end" style={{ alignItems: 'end' }}>
              {maxLength}
            </Label>
          )}
        </div>

        <div className="m-1 flex flex-1 flex-row flex-wrap justify-center">
          {min && (
            <Label className="flex flex-1 items-end" style={{ alignItems: 'end' }}>
              {min}
            </Label>
          )}
        </div>

        <div className="m-1 flex flex-1 flex-row flex-wrap justify-center">
          {max && (
            <Label className="flex flex-1 items-end" style={{ alignItems: 'end' }}>
              {max}
            </Label>
          )}
        </div>

        {!readOnly && (
          <div className="m-1 flex flex-1 flex-row flex-wrap justify-end">
            {!readOnly && (
              <div className="flex flex-1 flex-row flex-wrap justify-end">
                <EditNodeAction onClick={() => toggleEdit()}></EditNodeAction>
                {/* {() && <DeleteNodeAction onClick={() => deleteNode()} />} */}
                {
                  <DeleteNodeAction
                    onClick={() => deleteNode()}
                    disabled={hasInstances && !isNew}
                    disabledMessage={t('T_METADATA_DELETE_ATTRIBUTE_NOT_ALLOWED').replace(
                      '%s',
                      name
                    )}
                  />
                }
                {isObjectType(type) && <AddNodeAction onClick={() => createChildNode()} />}
              </div>
            )}
          </div>
        )}
      </RowItem>
      {/* )} */}
      {isExpanded &&
        children.map((childAttribute: any, index: number) => {
          const childNode = { ...childAttribute, parent: attribute };
          return (
            <AttributeTreeNode
              order={index}
              key={Math.random()}
              level={level + 1}
              attribute={childNode}
              readOnly={readOnly}
              onAddNode={onAddNode}
              hasInstances={hasInstances}
              onRemoveNode={onRemoveNode}
              onValueChange={onValueChange}
              expandTreeNode={expandTreeNode}
              onDataTypeChange={onDataTypeChange}
            />
          );
        })}
    </ul>
  );
};

// export default AttributeNode;
export default React.memo(AttributeTreeNode);
