import Card from '../../shared/cards/Card';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import { useState, useEffect } from 'react';
import MenuButtonsPortal from '../../Menu/MenuButtonsPortal';
import BiButton from '../../primitives/buttons/BiButton.primitive';
import { useNavigate, useParams } from 'react-router-dom';
import { setToastData } from '../../../app/toastReducer';
import ToastNotification from '../../Reusable/ToastNotification';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import DynamicFormFieldRenderer from '../../Reusable/DynamicFormFieldRenderer';
import { surveyDetailsFormSchema } from '../../../util/constants';
import Clipboard from '../../../assets/clipboard.svg';
import Modal from '../../shared/ModalPortal/Modal';
import { boolean, display, multiDropdown, multiDropdownUp } from '../../../assets/components/svgs';
import SelectField from '../../shared/Fields/SelectField';
import PickList, { PICKLIST_TYPES } from '../../shared/Fields/PickList';

import { HTTP_STATUS } from '../../../http/constants/http.status';
import { setURLPaths } from '../../../app/organizationReducer';

import { AppConstants } from '../../../constants/AppConstants';
import { getFailureMessage } from '../../../util/ErrorUtil';
import DragIcon from '../../../assets/dragIcon.svg';
import AddQuestionDialog from './AddQuestionDialog';
import QuestionCard from './SubComponents/QuestionCard';
import PreviewQuestionDialog from './PreviewQuestionDialog';
import {
  createSurveyTemplate,
  updateSurveyTemplate,
  getQuestionnaire,
  deleteSurveyInstance
} from '../../../http/survey-service';
import { setNewSurveyDetails } from '../../../app/surveyReducer';
import { MenuConstants } from '../../../constants/MenuConstants';
import EditQuestion from './SubComponents/EditQuestionCard';
import { useDidMountEffect } from '../../../util/customHooks';

import { listFiles } from '../../../http/asset-management-services';

import {
  isEmpty,
  isEqual,
  reject,
  clone,
  upperCase,
  cloneDeepWith,
  noop,
  sortBy,
  flattenDeep,
  flatMap,
  findIndex,
  cloneDeep,
  filter,
  zipObject,
  map
} from 'lodash';
import { useTheme } from '../../../context/themeContext';
import ExclamationIcon from '@heroicons/react/outline/ExclamationIcon';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './templateDetail.css';
import checkCircle from '../../../assets/checkCircle.svg';
import { find } from 'lodash';
import {
  getQuestionTypeLabel,
  getDisplayType,
  getUpdatedQueArr,
  getInitialQueObj
} from '../../../util/surveyUtils';
import { getMetadataInstances } from '../../../http/metadata-service';
import { fieldIsEmpty, validateNameLength } from '../../../util/validators';
import AddQuestionMenu from './AddQuestionMenu';
import AddGroupQuestionDialog from './AddGroupQuestionDialog';
import Checkbox from '../../shared/Fields/Checkbox';
import DragQuestionList from './DragQuestionList/DragQuestionList';
import Asterix from '../../../assets/asterix.svg';
import { getImagesToUpload, getQuestionTypes, updateMediaToBucket } from '../Question/QueUtils';
import {
  getOrgList,
  getUserLoggedInOrg,
  updateOrgResponse,
  updateRoleResponse
} from '../../../util/OrganizationUtil';
import { getRole, listRoles } from '../../../http/access-management-service';
import { setOrgDetails } from '../../../app/rolesAndFeatureReducer';
import { getOrganization } from '../../../http/organization-services';

interface DynamicObject {
  [key: string]: any;
}

interface ContextMenuSelection {
  display: string;
  key: string;
}

interface SurveyDetails {
  surveyName: string;
  surveyTitle: string;
  description: string;
  surveyId: string;
  surveyOrgId: string;
  surveyRoleId: string;
  surveyStatus: string;
}

const statusField: any = {
  active: '#bfe4bf',
  draft: '#FFFFFF',
  retired: '#FFFFFF',
  unknown: '#FFFFFF'
};

const statusSuccessMessage: any = {
  active: 'T_SURVEY_ACTIVATED_SUCCESSFULLY',
  draft: 'T_SURVEY_DRAFT_SUCCESSFULLY',
  retired: 'T_SURVEY_RETIRED_SUCCESSFULLY',
  unknown: 'T_SURVEY_UNKNOWN_SUCCESSFULLY'
};

const statusTypes: any = [
  { key: 'draft', value: 'Draft', display: 'Draft' },
  { key: 'active', value: 'Active', display: 'Active' },
  { key: 'retired', value: 'Retire', display: 'Retire' },
  { key: 'unknown', value: 'Unknown', display: 'Unknown' }
];

//const statusTypes: any = ['Draft', 'Active', 'Retire', 'Unknown'];

export const apiTypeField: any = {
  single: 'text',
  multi: 'choice',
  choice: 'choice',
  datetime: 'dateTime',
  dateTime: 'dateTime',
  text: 'text',
  date: 'date',
  time: 'time',
  url: 'url',
  display: 'display',
  boolean: 'boolean',
  decimal: 'decimal',
  integer: 'integer',
  group: 'group',
  media: 'url',
  likert: 'likert'
};

export const API_TO_QUESTION_MAPPING: any = {
  choice: 'multi',
  display: 'display',
  boolean: 'boolean',
  decimal: 'decimal',
  integer: 'integer',
  date: 'date',
  time: 'time',
  dateTime: 'datetime',
  url: 'url',
  quantity: 'numeric',
  text: 'single',
  media: 'media',
  likert: 'likert'
};

export const Q_TYPE_TO_A_TYPE_MAPPING: any = {
  url: 'uri',
  date: 'date',
  dateTime: 'dateTime',
  datetime: 'dateTime',
  time: 'time',
  integer: 'integer',
  decimal: 'decimal'
};

/**
 * @parentId survey templates
 * @manager Create, View, Edit - Survey Template
 * @description Ability to Create, View, Edit of survey template
 * @overview
 *   <section>
 *
 *      <p>
 *           <b>Create Survey Template</b><br/><br/>
 *           Clicking on <b>New survey template</b> on survey templates listing page will take user to survey template creation page. Clicking on <b>Back To Survey List</b> will take the user to survey templates listing page.
 *           <br/><br/>
 *           User can create new survey template by entering <b>Survey Name, Survey Title</b> as mandatory fields and clicking on <b> Save </b> button on the admin portal toolbar.<br/><br/>
 *           <b>Description, Survey Questions</b> are additional non mandatory fields which user can use to enter more detail about the survey template. Description is an freetext field.
 *           <br/><br/>
 *           Adding of <b>Survey Questions</b> can be done clicking <b>Add Question</b> or <b>Add Group Question</b>.
 *          <br/><br/>
 *           Clicking <b>Add Question</b> or  <b>Add Group Question</b>,
 *           will open a modal dailog listing different type of questions in a paginated way which can be added to create survey template.
 *           You will be able to create upto max of 25 questions, which includes normal question types like boolean, single, etc along with group question.
 *           Group question is combination of individual question types, the max of 5 questions can be added to the group.
 *           The survey questions modal dailog contains a Grid Which displays <b>Checkbox</b> to select the question to be added, <b>Question Name</b> & <b>Question Type</b>.<br/><br/>
 *           Once Survey Questions are selected, clicking on the <b>Add Selected</b> will add questions to the template for creation.<br/><br/>
 *           User can additionally <b>Edit Question & Answers, Mark Read Only & Remove Question</b> by clicking on the context menu. Also the questions added can also be dragged to change the order of display.
 *           <br/><br/>Clicking on <b>Save</b> will create the survey template in <b>Draft State</b>.<br/><br/>
 *           User can also add the condition for the added question, the condition added to the question is displayed based on the criteria mentioned.
 *           User can preview the questions added by clicking on the <b>Preview</b> button.
 *       </p>
 *      <p>
 *        <b>Add Condition for the question</b><br/>
 *        User should be able to determine if a question should be asked, based on the responses of the previous question(s).
 *        Conditon can be set to following set of questions:
 *        <ul <li>Date</li> <li>Time</li> <li>Date & Time</li> <li>Free Text</li> <li>Multiple Selection</li> <li>URL</li> <li>Single Selection</li> <li>Display (read-only)</li> <li>Boolean</li> <li>Integer</li> </li>Decimal</li> </li>Media</li> </li>Liekrt</li> </li>Body Map</li> <li>Group Question</li> </ul>
 *         User will be able to select Boolean, Single Selection, Integer & Decimal type questions as pre-requisite on which conditions can be defined
 *         For Boolean question, the answer will be compared with True OR False values.
 *         For Decimal & Integer questions, answer will be compared with a specific number.
 *         For Single selection, the answer will be compared with one of the answer choices.
 *         User will be able to define the the relation of answer choices with one of the operators "=, !=, <, >, <=, >=, exists".
 *         User will also be able to specify if "ANY" or "ALL" of the defined conditions need to be met for displaying conditional question
 *      </p>
 *       <p>
 *           <b>View Survey Template</b><br/>
 *           Clicking on survey template row item on the survey templates listing page will take user to view survey template in READ-ONLY mode. Clicking on <b>Back to Survey List</b> will take the user to survey templates listing page.
 *           <br/><br/>
 *           In READ-ONLY mode user can change the state of survey to either <b>Draft</b> or <b>Active</b> or <b>Retire</b> or <b>Unknown</b>.<br/><br/>
 *           Also user can delete the survey by clicking on <b>Delete Survey</b>.<br/><br/>
 *           Changing status to <b>Active</b> opens the Date Picker to select the date range for start and end of survey.<br/><br/>
 *           Changing status to <b>Retire</b> or <b>Unknown</b> opens a confirmation box to deactivate survey or to the unknown status respectively.
 *        </p>
 *       <p>
 *           <b>Edit Survey Template</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Survey Name, Survey Title, Description & Questions</b> fields. Clicking on <b>Cancel</b> will take the user to survey template details in READ-ONLY mode.
 *           <br/><br/>
 *           User can additionally <b>Edit Question & Answers, Mark Read Only & Remove Question</b> by clicking on the context menu. Also the questions added can also be dragged to change the order of display.
 *           Clicking on <b>Save</b> will update the existing survey template.
 *        </p>
 *
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Survey Template Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>SS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>SS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create survey template page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit survey template.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Configuration Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *            <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating survey template</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Survey Template</td>
 *               <td>/questionnaires/{id}</td>
 *               <td>GET</td>
 *               <td>survey-service.questionnaire.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Survey Template</td>
 *               <td>/questionnaires/{id}</td>
 *               <td>PUT</td>
 *               <td>survey-service.questionnaire.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Survey Template</td>
 *               <td>/questionnaires</td>
 *               <td>POST</td>
 *               <td>survey-service.questionnaire.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Delete Survey Template</td>
 *               <td>/questionnaires/{id}</td>
 *               <td>DELETE</td>
 *               <td>survey-service.questionnaire.delete</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Get MetaData Application</td>
 *               <td>/applications</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-application.list</td>
 *               <td>Yes</td>
 *           </tr>
 *
 *           <tr>
 *               <td>Get MetaData Application Objects</td>
 *               <td>/applications/{appId}/objects</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-object.list</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Get MetaData Application Object Instances</td>
 *               <td>/objects/{objId}/instances</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-instance.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create survey template</p>
 *   </section>
 */

const SurveyTemplateDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryParams = useParams();

  const [flowType, setFlowType] = useState<any>({
    isCreate: location.pathname.split('/').at(-1) === 'new',
    isEdit: queryParams.id ? true : false,
    isReadOnly: queryParams.id ? true : false
  });

  const [mode, setMode] = useState(1); //0 is for Edit, 1 is for Create
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [addedQuestions, setAddedQuestions] = useState<any[]>([]);
  const [addedGroupQuestion, setAddedGroupQuestion] = useState<any[]>([]);
  const [initialAddedQuestions, setInitialAddedQuestions] = useState<any[]>([]);
  const [modifiedQuestion, setModifiedQuestion] = useState<any>({});
  const [addQuestionModal, setAddQuestionModal] = useState(false);
  const [addGroupQuestionModal, setAddGroupQuestionModal] = useState(false);
  const [previewQuestionModal, setPreviewQuestionModal] = useState(false);
  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const [editQAModal, setEditQAModal] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState<any>();
  const [initialEditedQuestion, setInitialEditedQuestion] = useState<any>();
  const [enableWhen, setEnableWhen] = useState<any[]>([]);

  const [paging, setPaging] = useState<any>(null);
  const [size, setSize] = useState<number>(AppConstants.DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState<number>(AppConstants.DEFAULT_PAGE);
  const [pagingLoading, setPagingLoading] = useState(false);
  //const [surveyStatus, setSurveyStatus] = useState('draft');
  const [selectedOption, setSelectedOption] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [updateRender, setUpdateRender] = useState(false);

  const [createUpdatePayload, setCreateUpdatePayload] = useState({});

  const [surveyDetailsForm, setSurveyDetailsForm] = useState<any>({
    surveyName: '',
    surveyTitle: '',
    description: '',
    surveyId: '',
    surveyOrgId: '',
    surveyRoleId: '',
    surveyStatus: [{ key: 'draft', value: 'Draft', display: 'Draft' }]
  });
  const [initialSurveyDetailsForm, setInitialSurveyDetailsForm] = useState<any>({
    surveyName: '',
    surveyTitle: '',
    description: '',
    surveyId: '',
    surveyOrgId: '',
    surveyRoleId: '',
    surveyStatus: [{ key: 'draft', value: 'Draft', display: 'Draft' }]
  });

  const [surveyDetailsFormError, setSurveyDetailsFormError] = useState<any>({
    title: '',
    description: ''
  });

  const [loadNextData, setLoadNextData] = useState<any>({
    orgPaging: false,
    rolespaging: false,
    searchOrgName: '',
    searchOrg: false,
    searchRoleName: '',
    searchRole: false
  });

  const [assignSurveyToOrgObj, setAssignSurveyToOrgObj] = useState<any>({
    itemsList: [],
    selectedFilter: [],
    paging: { size: 25, page: 1 },
    hasMore: true,
    orgId: ''
  });

  const [assignSurveyToRoleObj, setAssignSurveyToRoleObj] = useState<any>({
    itemsList: [],
    selectedFilter: [],
    paging: { size: 25, page: 1 },
    hasMore: true,
    roleId: ''
  });

  const [dragId, setDragId] = useState();
  const [dragType, setDragType] = useState<string>('');

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const toastData = useAppSelector((state) => state.toast);
  const data = useAppSelector((state) => state.survey.surveyDetails);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const roleAPIPermissions = useAppSelector((state) => state.initialLoadData.apiPermissions);
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);

  const [groupQuestionIndex, setGroupQuestionIndex] = useState<number>(-1);
  const [queWarningList, setQueWarningList] = useState<any>([]);
  let questionTypes: any = useAppSelector((state) => state.initialLoadData.metaDataQuestionTypes);

  let timeStamp: any;
  let enableWhenWarnQueList: any = {};

  const getIsEditOrReadOnly = () => {
    const { isEdit, isReadOnly } = flowType;
    if (isEdit && isReadOnly) {
      return true;
    } else if (isEdit && !isReadOnly) {
      return false;
    }
    return false;
  };

  const setisReadOnly = (isReadOnly: boolean, toBeReverted: boolean) => {
    if (toBeReverted) {
      revertChanges();
    }

    setFlowType({ ...flowType, isEdit: queryParams.id && isReadOnly, isReadOnly: isReadOnly });
  };

  const setQuestionTypes = async () => {
    let questionTypesObj: any;

    if (!isEmpty(questionTypes)) {
      questionTypesObj = questionTypes;
    } else {
      questionTypesObj = await getQuestionTypes(dispatch, metaDataObjectList);
    }
    questionTypes = questionTypesObj;
  };

  useEffect(() => {
    async function init() {
      await setQuestionTypes();

      if (!params.id) {
        setIsEdit(true);
        setIsLoading(false);
        setMode(1);
        setBreadCrumb(1);
      } else {
        setMode(0);
        saveEditResponse();
      }
    }

    init();
  }, []);

  // useDidMountEffect(() => {
  //   toggleStatus(surveyDetailsForm.surveyStatus);
  // }, [surveyDetailsForm.surveyStatus]);

  useEffect(() => {
    if (disableButton) {
      setDisableButton(false);
      setSurveyDetailsFormError({
        title: '',
        description: ''
      });
    }
  }, [surveyDetailsForm]);

  const toggleAddQuestion = async () => {
    if (!questions.length) {
      setIsLoading(true);
      await getNewQuestions();
      setIsLoading(false);
    }
    setInitialAddedQuestions([...addedQuestions]);
    setAddQuestionModal(true);
  };

  const toggleAddGroupQuestion = async (index: number) => {
    if (!questions.length) {
      setIsLoading(true);
      await getNewQuestions();
      setIsLoading(false);
    }
    setInitialAddedQuestions([...addedQuestions]);
    setGroupQuestionIndex(index);
    setAddGroupQuestionModal(true);
  };

  const getNewQuestions = async (
    name: string = '',
    page: number = AppConstants.DEFAULT_PAGE,
    size: number = AppConstants.DEFAULT_PAGE_SIZE
  ) => {
    const questionnaireObject: any = find(metaDataObjectList, {
      name: AppConstants.OBJECTS_SURVEY_QUESTIONS
    });
    const { id } = questionnaireObject;
    let response = await getMetadataInstances(id, [], page, size);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { paging, data: questionList = [] } = data;
      setPaging(paging);
      formatQuestions(questionList);
    }
  };

  const setBreadCrumb = (mode: number, id?: string, name?: string) => {
    if (!mode) {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: `/survey-template/edit/${id}`,
            label: `Platform Core > Survey Templates > View ${name}`
          }
        ])
      );
    } else {
      dispatch(
        setURLPaths([
          ...storedURLPaths,
          {
            key: `/survey-template/new`,
            label: `Platform Core > Survey Templates > New Survey Template`
          }
        ])
      );
    }
  };

  const validateSave = () => {
    const { surveyName, surveyTitle } = surveyDetailsForm;
    return surveyName.trim() === '';
  };

  const hasErrors = (): boolean => {
    return (
      Object.entries(surveyDetailsFormError).filter((key: any, value: any) => value.length).length >
      0
    );
  };

  const isDataChanges = () => {
    return (
      isEqual(surveyDetailsForm, initialSurveyDetailsForm) &&
      isEqual(addedQuestions, initialAddedQuestions)
    );
  };

  const getChoiceQueAnswerOption = (queItem: any) => {
    const { displayType, answerOption } = queItem;
    const { surveyName } = surveyDetailsForm;
    const updatedAnsOptions: any = [];
    let optionPayload = {};

    switch (displayType) {
      case 'multi':
      case 'single':
        answerOption.forEach((queAnswer: any, innerIndex: number) => {
          const { initialSelected, value } = queAnswer;
          const displayValue = typeof value === 'string' ? value : value.display;
          optionPayload = {
            initialSelected: initialSelected,
            value: {
              type: 'coding',
              system: 'http://www.brightinsight/fhircode',
              code: `BI.${surveyName.trim()}.C${innerIndex + 1}`,
              display: displayValue,
              userSelected: true
            }
          };
          updatedAnsOptions.push(optionPayload);
        });
        break;
      // case 'single':
      //   answerOption.forEach((queAnswer: any) => {
      //     const { initialSelected, value } = queAnswer;
      //     const displayValue = typeof value === 'string' ? value : value.value;
      //     optionPayload = {
      //       initialSelected: initialSelected,
      //       value: {
      //         type: 'string',
      //         value: displayValue
      //       }
      //     };
      //     updatedAnsOptions.push(optionPayload);
      //   });
      //   break;
      case 'likert':
      case 'likertIconsWithLabels':
      case 'likertIcons':
        answerOption.forEach((queAnswer: any, innerIndex: number) => {
          const { initialSelected, value } = queAnswer;
          const displayValue = typeof value === 'string' ? value : value.display;
          optionPayload = {
            initialSelected: initialSelected,
            value: {
              type: 'coding',
              system: displayValue
                ? `SURVEY_LIKERT_${upperCase(displayValue).replace(/\s+/g, '_')}`
                : '',
              code: innerIndex,
              display: displayValue,
              userSelected: true
            }
          };
          updatedAnsOptions.push(optionPayload);
        });
        break;
      case 'bodyMapSingle':
      case 'bodyMapMulti':
        answerOption.forEach((queAnswer: any, innerIndex: number) => {
          const { initialSelected, value } = queAnswer;
          const displayValue = typeof value === 'string' ? value : value.display;
          optionPayload = {
            initialSelected: initialSelected,
            value: {
              type: 'coding',
              system: displayValue
                ? `SURVEY_BODYMAP_${upperCase(displayValue).replace(/\s+/g, '_')}`
                : '',
              code: displayValue,
              display: displayValue,
              userSelected: true
            }
          };
          updatedAnsOptions.push(optionPayload);
        });
    }
    return updatedAnsOptions;
  };

  const getQueAnswerOption = (queItem: any) => {
    const { apiType } = queItem;
    let answerOption: any = [];
    switch (apiType) {
      case 'choice':
      case 'text':
        return getChoiceQueAnswerOption(queItem);
        break;
    }

    return answerOption;
  };

  const getMediaTypeExt = (extensionObj: any) => {
    if (!extensionObj.fileName) {
      delete extensionObj.fileName;
      delete extensionObj.fileType;
      delete extensionObj.file;
    }
    return extensionObj;
  };

  const getQueExtension = (queItem: any) => {
    const { displayType, apiType, extension, type } = queItem;
    const { object } = extension;

    switch (displayType) {
      case 'media':
        return getMediaTypeExt(JSON.parse(object));
        break;
      case 'likert':
      case 'likertIconsWithLabels':
      case 'likertIcons':
      case 'bodyMapSingle':
      case 'bodyMapMulti':
        return JSON.parse(object);
        break;
    }

    // if (apiType === 'choice' && type === 'bodymap') {
    //   return { displayType: 'bodymap' };
    // }

    return {};
  };

  const getAddedQueLinkId = (condQueName: string, addedQueArr: any) => {
    return find(addedQueArr, { text: condQueName });
  };

  const getBooleanAnsValue = (selectedOption: any) => {
    const { key: selectedAnswer } = selectedOption;
    return { type: 'boolean', value: selectedAnswer === 'Yes' };
  };

  const getEnableWhenAnswer = (
    selectedOption: any,
    answerOption: any,
    displayType: any,
    operatorValue: any
  ) => {
    const { key: selectedAnswer } = selectedOption;
    let enableWhenAns;

    if (operatorValue === 'exists' || displayType === 'boolean') {
      enableWhenAns = getBooleanAnsValue(selectedOption);
    } else {
      switch (displayType) {
        case 'integer':
        case 'decimal':
          enableWhenAns = {
            type: displayType,
            value: selectedOption.value || selectedOption
          };
          break;
        case 'multi':
        case 'single':
        case 'bodyMapSingle':
          enableWhenAns = {
            type: 'coding',
            code: `BI.${surveyDetailsForm.surveyName}`,
            system: 'http://www.brightinsight/fhircode',
            display:
              typeof selectedOption.value === 'string' ? selectedOption.value : selectedAnswer.value
          };
      }
    }
    return enableWhenAns;
  };

  const getEnableWhen = (queItem: any, addedQueArr: any) => {
    const updatedEnableWhen: any = [];
    const enableWhenNotSatisfiedList: any = [];
    const { enableWhen = [], apiType, question: displayQue, text: displayQueText } = queItem;
    const queCardText = displayQue || displayQueText;

    if (enableWhen && enableWhen.length) {
      enableWhen.forEach((condition: any) => {
        if (condition) {
          const { question, operator, selectedOption } = condition;
          const { text, displayType, question: questionName } = question;
          const queText = questionName || text;
          const addedQueLinkId = getAddedQueLinkId(queText, addedQueArr);
          if (addedQueLinkId) {
            const { linkId, answerOption } = addedQueLinkId;
            updatedEnableWhen.push({
              question: linkId,
              operator: operator[0].value,
              answer: getEnableWhenAnswer(
                selectedOption,
                answerOption,
                displayType,
                operator[0].value
              )
            });
          } else {
            //enableWhenWarnQueList = [...enableWhenWarnQueList, queItem];
            enableWhenWarnQueList = {
              ...enableWhenWarnQueList,
              [queCardText]: enableWhenWarnQueList[queCardText]
                ? [...enableWhenWarnQueList[queCardText], condition]
                : [condition]
            };
            //setEnableWhenWarnQueList([...enableWhenWarnQueList, queItem]);
          }
        }
      });
    }

    return updatedEnableWhen;
  };

  const getQueItemList = (questionData: any) => {
    const addedQueData = [...questionData];
    const updatedQueData: any = [];
    addedQueData.forEach((que: any, index: number) => {
      const queItem = { ...que };
      const {
        question,
        text,
        initial,
        apiType,
        readOnly,
        displayType,
        required,
        item,
        enableBehavior,
        id,
        linkId
      } = queItem;

      timeStamp = timeStamp + 1;
      const updatedQueItem: any = {};
      updatedQueItem['linkId'] = `${timeStamp}`;
      updatedQueItem['text'] = question || text;
      updatedQueItem['required'] = required;
      updatedQueItem['readOnly'] = apiType === 'display' ? true : readOnly;
      updatedQueItem['answerOption'] = getQueAnswerOption(queItem);
      updatedQueItem['extension'] = getQueExtension(queItem);
      updatedQueItem['initial'] = initial;
      //updatedQueItem['type'] = displayType === 'single' ? 'text' : apiType;
      updatedQueItem['type'] = apiType;
      updatedQueItem['displayType'] = displayType;
      updatedQueItem['item'] = apiType === 'group' ? getQueItemList(item) : [];
      updatedQueItem['enableWhen'] = getEnableWhen(queItem, updatedQueData);
      if (enableBehavior) {
        updatedQueItem['enableBehavior'] = enableBehavior;
      }
      if (linkId) {
        updatedQueItem['id'] = id;
      }

      updatedQueData.push(updatedQueItem);
    });

    return updatedQueData;
  };

  const getOrgId = () => {
    const { selectedFilter = [] } = assignSurveyToOrgObj || {};
    return selectedFilter[0]?.id || null;
  };

  const getRoleId = () => {
    const { selectedFilter = [] } = assignSurveyToRoleObj || {};
    return selectedFilter.length ? selectedFilter[0]?.id : null;
  };

  const validateFormData = () => {
    let errors: any = {
      surveyName: '',
      surveyTitle: '',
      description: ''
    };

    errors['surveyName'] = fieldIsEmpty(surveyDetailsForm.surveyName.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(surveyDetailsForm.surveyName.trim(), 255)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG');
    errors['surveyTitle'] = fieldIsEmpty(surveyDetailsForm.surveyTitle.trim())
      ? ''
      : validateNameLength(surveyDetailsForm.surveyTitle.trim(), 1024)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_TOO_LONG_MSG');
    errors['description'] = fieldIsEmpty(surveyDetailsForm?.description?.trim())
      ? ''
      : validateNameLength(surveyDetailsForm.description.trim(), 4096)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG_4096');
    setSurveyDetailsFormError({ ...errors });
    return errors;
  };

  const createTemplate = async (requestBody: any = '') => {
    setIsLoading(true);
    const payload = requestBody || { ...createUpdatePayload };
    let response = await createSurveyTemplate(payload);

    if ([HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(response.status)) {
      dispatch(
        setToastData({
          toastMessage: t('T_SURVEY_CREATION_SUCCESS'),
          isToastActive: true,
          toastType: 'success'
        })
      );
      navigate('/survey-template/list');
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: response.data.issues[0].details,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  // const getSurveyImgArr = () => {
  //   const cloneAddedQue = clone(addedQuestions);

  //   const addedQueData = [...cloneAddedQue];
  //   const updatedQueData: any = [];
  //   addedQueData.forEach((que: any, index: number) => {
  //     const queItem = { ...que };
  //     const {
  //       question,
  //       text,
  //       initial,
  //       apiType,
  //       readOnly,
  //       displayType,
  //       required,
  //       item,
  //       enableBehavior,
  //       id,
  //       linkId
  //     } = queItem;

  //     timeStamp = timeStamp + 1;
  //     const updatedQueItem: any = {};
  //     updatedQueItem['linkId'] = `${timeStamp}`;
  //     updatedQueItem['text'] = question || text;
  //     updatedQueItem['required'] = required;
  //     updatedQueItem['readOnly'] = apiType === 'display' ? true : readOnly;
  //     updatedQueItem['answerOption'] = getQueAnswerOption(queItem);
  //     updatedQueItem['extension'] = getQueExtension(queItem);
  //     updatedQueItem['initial'] = initial;
  //     //updatedQueItem['type'] = displayType === 'single' ? 'text' : apiType;
  //     updatedQueItem['type'] = apiType;
  //     updatedQueItem['displayType'] = displayType;
  //     updatedQueItem['item'] = apiType === 'group' ? getQueItemList(item) : [];
  //     updatedQueItem['enableWhen'] = getEnableWhen(queItem, updatedQueData);
  //     if (enableBehavior) {
  //       updatedQueItem['enableBehavior'] = enableBehavior;
  //     }
  //     if (linkId) {
  //       updatedQueItem['id'] = id;
  //     }

  //     updatedQueData.push(updatedQueItem);
  //   });

  //   return updatedQueData;

  // }

  // const updateSurveyMedia = () => {
  //   function errorHandling(errorText: string) {
  //     setIsLoading(false);
  //     dispatch(
  //       setToastData({
  //         toastMessage: errorText,
  //         isToastActive: true,
  //         toastType: 'error'
  //       })
  //     );
  //   }

  //   const cloneAddedQue = clone(addedQuestions);

  //   const { id } = modifiedQuestion;
  //   delete modifiedQuestion.question;

  //   setIsLoading(true);

  //   const imagesToUpload = getImagesToUpload(modifiedQuestion);
  //   await updateMediaToBucket(imagesToUpload, errorHandling, modifiedQuestion, t);
  //   if (!isEmpty(modifiedQuestion.fileUploadObj)) {
  //     const extensionObj = await addGenerationToModifiedQue();
  //     modifiedQuestion.extension = extensionObj;
  //   }
  //   delete modifiedQuestion.fileUploadObj;
  // };

  const saveTemplate = async () => {
    const errors = validateFormData();
    if (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    ) {
      setDisableButton(true);
      return;
    }
    timeStamp = moment().unix();
    const { surveyName, surveyTitle, description, surveyStatus } = surveyDetailsForm;
    let payload: any = {
      name: surveyName.trim(),
      title: surveyTitle.trim(),
      description: description.trim(),
      status: surveyStatus[0].key,
      //item: removeIdfromGroup(formatQuestionPayload(addedQuestions)),
      item: getQueItemList(addedQuestions),
      organizationId: getOrgId(),
      roleId: getRoleId(),
      //organizationId: null,
      parentId: null,
      identifier: null,
      effectivePeriodStart: null,
      effectivePeriodEnd: null,
      businessVersion: null,
      questionnaireVersion: 1,

      derivedFromQuestionnaireId: null,
      experimental: null,
      questionnaireDate: null,
      publisher: null,
      contact: null,
      purpose: null,
      copyright: null,
      copyrightLabel: null,
      approvalDate: null,
      lastReviewDate: null,
      code: null,
      initial: [],
      tags: null
    };

    setIsLoading(true);

    if (!isEmpty(enableWhenWarnQueList)) {
      setQueWarningList(enableWhenWarnQueList);
      setIsLoading(false);
      setSelectedOption('enableWhenWarning');
      setCreateUpdatePayload(payload);
      enableWhenWarnQueList = {};
    } else {
      createTemplate(payload);
    }
  };

  const removeItemId = (questionsData: any) => {
    const itemLength = questionsData?.length;
    if (itemLength) {
      questionsData.forEach((question: any) => {
        const itemLength = question.item?.length;
        if (itemLength) {
          delete question.id;
          removeItemId(question.item);
        } else {
          delete question.id;
        }
      });
    }
    return questionsData;
  };

  const updateTemplate = async (requestBody: any = '', status: any = '') => {
    setIsLoading(true);
    const payload = requestBody || { ...createUpdatePayload };
    let updateId = payload.id;
    delete payload.id;

    let response = await updateSurveyTemplate(updateId, payload);
    if ([HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(response.status)) {
      dispatch(
        setToastData({
          toastMessage:
            status === ''
              ? t('T_SURVEY_UPDATED_SUCCESSFULLY')
              : t(`${statusSuccessMessage[status]}`),
          isToastActive: true,
          toastType: 'success'
        })
      );
      // if (status === 'draft') {
      //   setStartDate(null);
      //   setEndDate(null);
      // }
      setIsLoading(false);
      setisReadOnly(true, false);
      setInitialAddedQuestions([...addedQuestions]);
      dispatch(setNewSurveyDetails(response.data));

      //navigate('/survey-template/list');
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
      setIsLoading(false);
    }
  };

  const editTemplate = async (status: string = '', removeDate: boolean = false) => {
    //enableWhenWarnQueList = [];
    const errors = validateFormData();
    if (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    ) {
      setDisableButton(true);
      return;
    }
    setIsLoading(true);
    setIsEdit(false);
    timeStamp = moment().unix();
    let payload = { ...data };
    payload.name = surveyDetailsForm.surveyName.trim();
    payload.title = surveyDetailsForm.surveyTitle.trim();
    payload.description = surveyDetailsForm.description.trim();
    payload.status = status || surveyDetailsForm.surveyStatus[0].key;
    //payload.item = formatQuestionPayload(removeItemId(addedQuestions));
    payload.item = getQueItemList(addedQuestions);
    //payload.item = formatQuestionPayload(addedQuestions);
    payload.organizationId = getOrgId();
    payload.roleId = getRoleId();
    //payload.organizationId = null;
    // if (status === 'draft') {
    //   payload.effectivePeriodStart = null;
    //   payload.effectivePeriodEnd = null;
    // } else {
    //   payload.effectivePeriodStart =
    //     startDate && !removeDate ? moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ') : null;
    //   payload.effectivePeriodEnd =
    //     endDate && !removeDate ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ') : null;
    // }

    payload.effectivePeriodStart =
      startDate && !removeDate ? moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ') : null;
    payload.effectivePeriodEnd =
      endDate && !removeDate ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ') : null;

    if (!isEmpty(enableWhenWarnQueList)) {
      setQueWarningList(enableWhenWarnQueList);
      setIsLoading(false);
      setSelectedOption('enableWhenWarning');
      setCreateUpdatePayload(payload);
      enableWhenWarnQueList = {};
    } else {
      updateTemplate(payload, status);
    }
  };

  const formatQuestions = (questions: any = []) => {
    let formatted: any = [];

    setQuestions(
      questions.map((item: any) => {
        const linkId: string = '';
        const displayType: string = getDisplayType(item);
        const required: boolean = false;
        const readOnly: boolean = false;
        const { attributes = {}, id } = item;
        const {
          answerOptions: answerOption,
          type,
          apiType,
          name: text,
          question,
          initial,
          extension
        } = attributes;
        return {
          type,
          apiType,
          text,
          linkId,
          required,
          readOnly,
          answerOption,
          displayType,
          id,
          question,
          initial,
          extension
        };
      })
    );
    setPagingLoading(false);
  };

  const sortAddedQuestions = (list: any) => {
    const sortedList = sortBy(list, ['linkId']);
    setAddedQuestions(sortedList);
    setInitialAddedQuestions(sortedList);
  };

  const updateExtensionObj = (itemsList: any) => {
    const queArr = itemsList.map((question: any) => {
      if (question.item.length) {
        updateExtensionObj(question.item);
      }
      return { ...question, extension: { object: JSON.stringify(question.extension) } };
    });
    return queArr;
  };

  const getComparator = (operator: string) => {
    const comparatorList = AppConstants.SURVEY_CONDITIONAL_COMPARATOR_LIST;
    return find(comparatorList, { value: operator });
  };

  const getAnswer = (answer: any, operator: any) => {
    const { value: operatorValue }: any = getComparator(operator);
    const { type, value, display } = answer;
    if (type === 'boolean' || operatorValue === 'exists') {
      return value.toString() === 'true'
        ? { value: 'Yes', key: 'Yes' }
        : { value: 'No', key: 'No' };
    } else if (type === 'coding') {
      return { value: display, key: display };
    } else {
      return answer;
    }
  };

  const getConditionalQuesFromAPI = (enableWhen: any, collection: any) => {
    const condQueArr: any = [];
    //Conditional Ques for this question
    if (enableWhen && enableWhen.length) {
      enableWhen.forEach((condQue: any) => {
        const { question: questionLinkId, operator, answer } = condQue;
        const condQueSelected = find(collection, { linkId: questionLinkId });
        if (condQueSelected) {
          condQueArr.push({
            question: condQueSelected,
            selectedOption: getAnswer(answer, operator),
            operator: [getComparator(operator)]
          });
        }
      });
    }

    return condQueArr.length ? condQueArr : [null];
  };

  const updateAdditionalQueProperties = (itemsList: any) => {
    const queArr = itemsList.map((question: any) => {
      const { type, enableWhen = [] } = question;
      if (question.item.length) {
        return {
          ...question,
          apiType: type,
          enableWhen: getConditionalQuesFromAPI(enableWhen, itemsList),
          item: updateAdditionalQueProperties(question.item)
        };
      } else {
        return {
          ...question,
          apiType: type,
          enableWhen: getConditionalQuesFromAPI(enableWhen, itemsList),
          extension: { object: JSON.stringify(question.extension) }
        };
      }
    });
    return queArr;
  };

  const saveEditResponse = async () => {
    let editData = data;
    let response = await getQuestionnaire(params.id);
    editData = response.data;
    dispatch(setNewSurveyDetails(editData));

    setSurveyDetailsForm({
      ...surveyDetailsForm,
      surveyId: editData.id,
      surveyName: editData.name,
      surveyTitle: editData.title,
      description: editData.description,
      surveyOrgId: editData.organizationId,
      surveyStatus: filter(statusTypes, { key: editData.status })
    });

    setInitialSurveyDetailsForm({
      ...initialSurveyDetailsForm,
      surveyId: editData.id,
      surveyName: editData.name,
      surveyTitle: editData.title,
      description: editData.description,
      surveyOrgId: editData.organizationId,
      surveyStatus: filter(statusTypes, { key: editData.status })
    });

    //setSurveyStatus(editData.status);
    if (editData.effectivePeriodStart) {
      setStartDate(new Date(editData.effectivePeriodStart));
    }
    if (editData.effectivePeriodEnd) {
      setEndDate(new Date(editData.effectivePeriodEnd));
    }

    if (editData.item?.length) {
      sortAddedQuestions(updateAdditionalQueProperties(editData.item));
    }

    setBreadCrumb(0, editData.id, editData.name);

    if (editData.organizationId || editData.roleId) {
      if (editData.organizationId) {
        const orgData = await getOrganization(editData.organizationId);
        setAssignSurveyToOrgObj({ ...assignSurveyToOrgObj, selectedFilter: [orgData.data] });
      }

      if (editData.roleId) {
        const roleData = await getRole(editData.roleId);
        setAssignSurveyToRoleObj({ ...assignSurveyToRoleObj, selectedFilter: [roleData.data] });
      }
    }
    setIsLoading(false);
  };

  const revertChanges = () => {
    setAddedQuestions(initialAddedQuestions);
    if (addQuestionModal) {
      setAddQuestionModal(false);
    } else {
      setSurveyDetailsForm(initialSurveyDetailsForm);
    }
  };

  const revertGroupQuestionChanges = () => {
    setAddedGroupQuestion(initialAddedQuestions);
    if (addQuestionModal) {
      setAddGroupQuestionModal(false);
    } else {
      setSurveyDetailsForm(initialSurveyDetailsForm);
    }
  };

  const handleDrag = (ev: any) => {
    if (!flowType.isReadOnly) {
      setDragId(ev.currentTarget.id);
      setDragType(ev.currentTarget.datatype);
    }
  };

  const removeQuestion = (id: number, isGroup = false) => {
    const modifiedQueArr = [...addedQuestions];
    setAddedQuestions(reject(modifiedQueArr, { id: id }));
    dispatch(
      setToastData({
        toastMessage: isGroup
          ? t('T_REMOVE_SURVEY_GROUP_QUESTION_SUCCESS')
          : t('T_REMOVE_SURVEY_QUESTION_SUCCESS'),
        isToastActive: true,
        toastType: 'success'
      })
    );
  };

  const toggleReadOnly = (id: number, readOnly: boolean) => {
    const queArr = addedQuestions.map((obj) => {
      if (obj.id === id) {
        return { ...obj, readOnly: readOnly, required: readOnly === true ? false : obj.required };
      }
      return obj;
    });
    setAddedQuestions(queArr);
  };
  interface AnswerOptions {
    [key: string]: { value: string; isSelected: string };
  }

  function getEditedQuestion(id: number, queList: any) {
    for (let i = 0; i < queList.length; i++) {
      if (queList[i].id === id) {
        return queList[i];
      }
      if (queList[i]?.item?.length) {
        const result: any = getEditedQuestion(id, queList[i].item);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const addGenerationToModifiedQue = async () => {
    let fileObj: any = {};
    let fileDetailsObj: any = {};

    let clonedModifiedQue = clone(modifiedQuestion);
    const { fileUploadObj, extension } = clonedModifiedQue;
    let extensionObj = JSON.parse(extension.object);
    const { bodyMapImageLabel } = extensionObj;

    if (!isEmpty(fileUploadObj)) {
      for (let k in fileUploadObj) {
        let payload: any = {
          folderPath: fileUploadObj[k].path,
          expirationTime: 30,
          generateSignedUrl: false,
          bucketName: fileUploadObj[k].bucketName,
          criteria: { name: fileUploadObj[k].fileName }
        };

        fileObj[fileUploadObj[k].fileName] = listFiles(payload, {
          page: AppConstants.DEFAULT_PAGE,
          size: AppConstants.DEFAULT_PAGE_SIZE
        });
      }
    }

    const loadedResources = zipObject(
      Object.keys(fileObj),
      await Promise.all(Object.values(fileObj))
    );

    for (let k in loadedResources) {
      const response: any = loadedResources[k];
      if (response.status === HTTP_STATUS.HTTP_OK) {
        const { data } = response;
        const { data: fileDetails } = data;
        if (fileDetails.length) {
          fileDetailsObj[k] = fileDetails[0].generation;
        }
      }
    }

    Object.keys(bodyMapImageLabel).forEach((imgObjIndex: any) => {
      const imgObjGeneration = fileDetailsObj[bodyMapImageLabel[imgObjIndex].imageName];
      bodyMapImageLabel[imgObjIndex].generation = imgObjGeneration;
    });

    return { object: JSON.stringify(extensionObj) };
  };

  const getInitialAddedQue = () => {
    const clonedAddedQue = cloneDeep(addedQuestions);
    const { id } = modifiedQuestion;
    const initialAddedQue = getInitialQueObj(id, clonedAddedQue);
    return initialAddedQue;
  };

  const saveToQuestionnaire = async () => {
    function errorHandling(errorText: string) {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: errorText,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    let queToUpdate = clone(modifiedQuestion);
    const { id, displayTpe, fileUploadObj } = queToUpdate;
    delete queToUpdate.question;

    setIsLoading(true);

    const imagesToUpload = getImagesToUpload(queToUpdate);
    await updateMediaToBucket(imagesToUpload, errorHandling, queToUpdate, t);
    if (
      isEmpty(fileUploadObj) &&
      (displayTpe === 'bodyMapSingle' || displayTpe === 'bodyMapMulti')
    ) {
      queToUpdate = getInitialAddedQue();
    }

    delete queToUpdate.fileUploadObj;
    setIsLoading(false);
    const clonedAddedQue = cloneDeep(addedQuestions);
    const updatedAddedQueArr: any = getUpdatedQueArr(queToUpdate, id, clonedAddedQue);
    setAddedQuestions([...updatedAddedQueArr]);

    setEditQAModal(false);
  };

  const setConditionalToAddedQue = (questionData: any) => {
    const { id } = questionData;
    const clonedAddedQue = cloneDeep(addedQuestions);
    const updatedAddedQueArr: any = getUpdatedQueArr(questionData, id, clonedAddedQue);
    setAddedQuestions([...updatedAddedQueArr]);
  };

  const setAnswerOptions = (answerData: any, questionType: any, apiType: any, displayType: any) => {
    const answerOptions: any = [];
    switch (displayType) {
      case 'multi':
      case 'single':
        const answerKeys = Object.keys(answerData);
        answerKeys.forEach((key, index) => {
          answerOptions.push({
            initialSelected: answerData[key].isSelected,
            value: {
              type: 'coding',
              system: 'http://www.brightinsight/fhircode',
              code: `BI.${surveyDetailsForm.surveyName}.C${index + 1}`,
              display: answerData[key].value,
              userSelected: true
            }
          });
        });
        break;
      // case 'single':
      //   const answerKeysSingleChoice = answerData ? Object.keys(answerData) : [];
      //   if (answerKeysSingleChoice.length) {
      //     answerKeysSingleChoice.forEach((key, index) => {
      //       answerOptions.push({
      //         initialSelected: answerData[key].isSelected,
      //         value: {
      //           type: 'string',
      //           value: answerData[key].value
      //         }
      //       });
      //     });
      //   }
      //   break;
      case 'likert':
      case 'likertIconsWithLabels':
      case 'likertIcons':
        const answerKeysLikertChoice = answerData ? Object.values(answerData) : [];
        answerKeysLikertChoice.forEach((queAnswer: any, innerIndex: number) => {
          const { isSelected, value } = queAnswer;
          const displayValue = typeof value === 'string' ? value : value.value;
          answerOptions.push({
            initialSelected: isSelected,
            value: {
              type: 'coding',
              system: displayValue
                ? `SURVEY_LIKERT_${upperCase(displayValue).replace(/\s+/g, '_')}`
                : '',
              code: innerIndex,
              display: displayValue,
              userSelected: true
            }
          });
        });
        break;
      case 'bodyMapSingle':
      case 'bodyMapMulti':
        const answerKeysBodyMapChoice = answerData ? Object.values(answerData) : [];
        answerKeysBodyMapChoice.forEach((queAnswer: any, innerIndex: number) => {
          const { isInitialSelected, value } = queAnswer;
          const displayValue = typeof value === 'string' ? value : value.value;
          answerOptions.push({
            initialSelected: isInitialSelected,
            value: {
              type: 'coding',
              system: displayValue
                ? `SURVEY_BODYMAP_${upperCase(displayValue).replace(/\s+/g, '_')}`
                : '',
              code: displayValue,
              display: displayValue,
              userSelected: true
            }
          });
        });
        break;
    }
    return answerOptions;
  };

  const setInitialValue = (initialValue: any, questionType: any, apiType: any) => {
    let initialVal: any = [];
    switch (apiType) {
      case 'boolean':
        if (initialValue?.length && initialValue[0]) {
          initialVal.push({
            value: {
              type: 'boolean',
              value: `${initialValue[0] === 'true'}`
            }
          });
        }
        break;
      case 'integer':
      case 'decimal':
        if (initialValue?.length && initialValue[0]) {
          initialVal.push({
            value: {
              type: apiType,
              value: initialValue[0]
            }
          });
        }
        break;
      case 'url':
        if (initialValue?.length && initialValue[0]) {
          initialVal.push({
            value: {
              type: 'uri',
              value: initialValue[0]
            }
          });
        }
        break;
      case 'date':
        if (initialValue?.length && initialValue[0]) {
          initialVal.push({
            value: {
              type: 'date',
              value: initialValue[0]
            }
          });
        }
        break;
      case 'time':
        if (initialValue?.length && initialValue[0]) {
          initialVal.push({
            value: {
              type: 'time',
              value: initialValue[0]
            }
          });
        }
        break;
      case 'dateTime':
        if (initialValue?.length && initialValue[0]) {
          initialVal.push({
            value: {
              type: 'dateTime',
              value:
                moment(initialValue[0], 'YYYY-MM-DD hh:mm:ss')?.toISOString()?.split('.')[0] + 'Z'
            }
          });
        }
        break;
    }

    return initialVal;
  };

  // function getEditedQuestion(id: number, queList: any) {
  //   for (let i = 0; i < queList.length; i++) {
  //     if (queList[i].id === id) {
  //       return queList[i];
  //     }
  //     if (queList[i].item.length) {
  //       const result: any = getEditedQuestion(id, queList[i].item);
  //       if (result) {
  //         return result;
  //       }
  //     }
  //   }
  //   return null;
  // }

  const setItemQuestion = (updatedQue: any) => {
    const {
      questionId,
      question,
      answerOptions,
      questionType,
      apiType,
      initial,
      extension,
      fileUploadObj,
      displayType
    } = updatedQue;
    const initialVal = Array.isArray(initial) ? (initial.length ? initial[0] : '') : initial;
    //let modifiedQueData = addedQuestions.find((obj) => obj.id === questionId);
    let modifiedQueData = getEditedQuestion(questionId, addedQuestions);
    modifiedQueData = {
      ...modifiedQueData,
      text: question,
      question,
      fileUploadObj,
      displayType,
      extension: { object: JSON.stringify(extension) }
    };
    if (
      ['date', 'time', 'datetime', 'url', 'boolean', 'display', 'integer', 'decimal'].includes(
        apiType
      )
    ) {
      modifiedQueData.answerOption = [];
    } else {
      modifiedQueData.answerOption = setAnswerOptions(
        answerOptions,
        questionType,
        apiType,
        displayType
      );
    }
    //if (modifiedQueData.linkId) {
    if (['url', 'boolean', 'date', 'dateTime', 'time', 'integer', 'decimal'].includes(apiType)) {
      modifiedQueData.initial = setInitialValue([initialVal], questionType, apiType);
    } else {
      modifiedQueData.initial = [];
    }
    // } else {
    //   //modifiedQueData.question = '';
    //   modifiedQueData.initial = [];
    // }
    setModifiedQuestion(modifiedQueData);
    //setInitialEditedQuestion()
    // setEditedQuestion(modifiedQueData);
  };

  const validateInitialDate = (type: any, initial: any) => {
    const dateFormat: any = {
      datetime: 'YYYY-MM-DD HH:mm',
      date: 'YYYY-MM-DD',
      time: 'HH:mm'
    };
    //initial[0]?.value?.value || initial[0];
    const initialVal = Array.isArray(initial) ? (initial.length ? initial[0] : '') : initial;
    if (initialVal && !moment(initialVal, dateFormat[type], true).isValid()) {
      return false;
    }
    return true;
  };

  const updateQuestionnaire = (updatedQuestion: any) => {
    setItemQuestion(updatedQuestion);
    //validateButton();
  };

  interface QuestionData {
    questionType: string;
    questionName: string;
    question: string;
    questionDescription: string;
    questionStatus: string;
    answerValues: Boolean;
    answerOptions: any;
  }
  const setQuestionType = (apiType: any, displayType: any, type: any) => {
    // if (apiType === 'text' && displayType === 'single') {
    //   return 'single';
    // }
    if (apiType === 'url' && displayType === 'media') {
      return 'media';
    }
    if (apiType === 'choice') {
      if (
        displayType === 'likert' ||
        displayType === 'likertIconsWithLabels' ||
        displayType === 'likertIcons'
      ) {
        return 'likert';
      }

      if (displayType === 'bodyMapMulti' || displayType === 'bodyMapSingle') {
        return 'bodymap';
      }

      if (type === 'bodymap') {
        return type;
      }
      return displayType;
    }
    return apiType;
  };

  const editQuestion = (id: number) => {
    //const questionData = addedQuestions.find((que) => que.id === id);
    const questionData = getEditedQuestion(id, addedQuestions);
    const {
      type,
      text,
      answerValueSet,
      answerOption,
      initial,
      extension,
      apiType,
      displayType,
      question = ''
    } = questionData;

    const modifiedQueData: any = {
      questionId: id,
      questionTypeLabel: getQuestionTypeLabel(apiType, displayType, questionTypes, type),
      questionName: question || text,
      question: question || text,
      questionDescription: text,
      questionStatus: 'Active',
      answerValues: false,
      answerOptions: [],
      apiType,
      type: setQuestionType(apiType, displayType, type),
      displayType,
      extension: extension.object ? JSON.parse(extension.object) : extension
    };

    if (initial?.length) {
      if (type === 'dateTime') {
        modifiedQueData.initial = [
          moment(initial[0]?.value?.value || initial[0]).format('YYYY-MM-DD hh:mm:ss')
        ];
      } else {
        modifiedQueData.initial = [`${initial[0]?.value?.value || initial[0]}`];
      }
    } else {
      modifiedQueData.initial = [];
    }

    if (answerValueSet && answerValueSet.length) {
      modifiedQueData.answerValues = true;
    }
    if (type === 'string') {
      modifiedQueData.answerOptions = [
        {
          value: answerOption[0].value.value,
          initialSelected: answerOption[0].value.initialSelected
        }
      ];
    }
    if (answerOption.length) {
      const answerOptions: AnswerOptions = {};
      answerOption.forEach((answer: any, index: any) => {
        // if (displayType === 'single') {
        //   answerOptions[`option${index + 1}`] = {
        //     value: typeof answer.value === 'string' ? answer.value : answer.value.value,
        //     isSelected: answer.initialSelected
        //   };
        //   modifiedQueData[`option${index + 1}`] =
        //     typeof answer.value === 'string' ? answer.value : answer.value.value;
        // } else if (displayType === 'multi') {
        if (displayType === 'multi' || displayType === 'single') {
          answerOptions[`option${index + 1}`] = {
            value: answer.value.display ? answer.value.display : answer.value,
            isSelected: answer.initialSelected
          };
          modifiedQueData[`option${index + 1}`] = answer.value.display
            ? answer.value.display
            : answer.value;
        } else if (
          displayType === 'likert' ||
          displayType === 'likertIconsWithLabels' ||
          displayType === 'likertIcons'
        ) {
          const displayValue = answer.value.display ? answer.value.display : answer.value;
          answerOptions[`option${index + 1}`] = {
            value: typeof displayValue === 'string' ? displayValue : '',
            isSelected: answer.initialSelected
          };
          modifiedQueData[`option${index + 1}`] =
            typeof displayValue === 'string' ? displayValue : '';
        } else if (displayType === 'bodyMapSingle' || displayType === 'bodyMapMulti') {
          const displayValue = answer.value.display ? answer.value.display : answer.value;
          answerOptions[displayValue] = {
            value: typeof displayValue === 'string' ? displayValue : '',
            isSelected: answer.initialSelected
          };
        }
      });
      modifiedQueData.answerValues = true;
      modifiedQueData.answerOptions = answerOptions;
    } else {
      delete modifiedQueData.answerOptions;
    }

    setEditedQuestion(modifiedQueData);
    setInitialEditedQuestion(modifiedQueData);
  };

  const contextMenuHandler = (id: number, selectedOption: ContextMenuSelection) => {
    const { key: menuName } = selectedOption;
    switch (menuName) {
      case MenuConstants.MENU_REMOVE_QUESTION:
        removeQuestion(id);
        break;
      case MenuConstants.MENU_REMOVE_READ_ONLY:
        toggleReadOnly(id, false);
        break;
      case MenuConstants.MENU_MARK_AS_READ_ONLY:
        toggleReadOnly(id, true);
        break;
      case MenuConstants.MENU_EDIT_QUESTION_ANSWERS:
        setEditQAModal(true);
        editQuestion(id);
        break;
    }
  };

  const handleDrop = (ev: any) => {
    if (dragType === ev.currentTarget.datatype) {
      let clone = [...addedQuestions];
      const draggedQuestion = clone.find((ele: any) => ele.id === dragId);
      const droppedQuestion = clone.find((ele: any) => ele.id === ev.currentTarget.id);
      let draggedQuestionIndex = draggedQuestion ? clone.indexOf(draggedQuestion) : -1;
      let droppedQuestionIndex = droppedQuestion ? clone.indexOf(droppedQuestion) : -1;
      let temp = clone[draggedQuestionIndex];
      clone.splice(draggedQuestionIndex, 1); //Removal
      clone.splice(droppedQuestionIndex, 0, temp); //Adding
      setAddedQuestions(clone);
    }
  };

  const toggleStatus = (ev: any) => {
    switch (ev.toLowerCase()) {
      case 'active':
        setSelectedOption('activate');
        break;
      case 'retired':
        setSelectedOption('deactivate');
        break;
      case 'draft':
        setSelectedOption('draft');
        break;
      case 'unknown':
        setSelectedOption('unknown');
        break;
    }
    setIsOpen(false);
  };

  const deleteSurveyAPI = async () => {
    setIsLoading(true);
    let response = await deleteSurveyInstance(data.id);

    if ([HTTP_STATUS.HTTP_CREATED, HTTP_STATUS.HTTP_OK].includes(response.status)) {
      dispatch(
        setToastData({
          toastMessage: 'Survey Deleted Successfully.',
          isToastActive: true,
          toastType: 'success'
        })
      );

      navigate('/survey-template/list');
    } else {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: 'Survey Deletion Failed.',
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
  };

  const activateValidator = () => {
    if (!startDate || !endDate) {
      return true;
    }
    return false;
  };

  const deactivateSurvey = () => {
    return (
      <div>
        <div>{t('T_SURVEY_DEACTIVATE_PROMPT')}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={
              'border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setSelectedOption('');
              setSurveyDetailsForm({
                ...surveyDetailsForm,
                surveyStatus: filter(statusTypes, { key: 'retired' })
              });
              editTemplate('retired');
            }}
          >
            {t('T_SURVEY_DEACTIVATE_PROMPT_YES')}
          </BiButton>
        </div>
      </div>
    );
  };

  const draftSurvey = () => {
    return (
      <div>
        <div>
          <div className="text-base">Do you want to change the status to draft?</div>
          {/* {surveyDetailsForm.surveyStatus[0].key === 'active' ? (
            <div className="mt-3 text-sm">
              <b>Note: This action will reset effective start and end date.</b>
            </div>
          ) : (
            ''
          )} */}
        </div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={
              'border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setSelectedOption('');
              setSurveyDetailsForm({
                ...surveyDetailsForm,
                surveyStatus: filter(statusTypes, { key: 'draft' })
              });
              editTemplate('draft');
            }}
          >
            Yes
          </BiButton>
        </div>
      </div>
    );
  };
  const unknownSurvey = () => {
    return (
      <div>
        <div>Do you want to change the status to unknown?</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={
              'border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setSelectedOption('');
              setSurveyDetailsForm({
                ...surveyDetailsForm,
                surveyStatus: filter(statusTypes, { key: 'unknown' })
              });
              editTemplate('unknown');
            }}
          >
            Yes
          </BiButton>
        </div>
      </div>
    );
  };

  const deleteSurvey = () => {
    return (
      <div>
        <div>{t('T_SURVEY_DELETE_PROMPT')}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={
              'border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setSelectedOption('');
              deleteSurveyAPI();
            }}
          >
            {t('T_SURVEY_DELETE_PROMPT_YES')}
          </BiButton>
        </div>
      </div>
    );
  };

  // const getEnableWhenWarnText = () => {
  //   const warningText = queWarningList
  //   return (
  //     <>
  //     {Object.keys(queWarningList).forEach((questionName: any) => {
  //       <>
  //       <span>{`Following question display conditions will be affected:`}</span>
  //       <ol>
  //         <OrderedListItem>${questionName}</OrderedListItem>
  //       </ol>
  //       </>
  //     }}
  //       <div className="mt-5">
  //         <span>{`Following question display conditions will be affected, do you want to continue?`}</span>
  //         <ol className="mt-5" type="1">
  //           {queWarningList.map((que: any) => {
  //             return <OrderedListItem>{`${que.text}`}</OrderedListItem>;
  //           })}
  //         </ol>
  //       </div>
  //     </>
  //   );
  // };

  const getEnableWhenWarnText = () => {
    return (
      <>
        {Object.keys(queWarningList).map((questionName: any) => {
          return (
            <>
              <div className="mt-4">
                {`Following question display conditions will be affected: `}
                {<b>{questionName}</b>}
              </div>
              <div className="mt-2">{`Please re-evaluate the following conditions before saving:`}</div>
              <ol className="mb-6 mt-2">
                {queWarningList[questionName].map((condition: any) => {
                  const { question, operator, selectedOption } = condition;
                  const { text, question: questionName } = question;
                  const { value } = selectedOption;
                  const { value: operatorValue } = operator[0];
                  return (
                    <OrderedListItem>
                      {`${questionName || text} ${operatorValue} ${value}`}
                    </OrderedListItem>
                  );
                })}
              </ol>
            </>
          );
        })}
        <div>{`Do you want to continue without review?`}</div>
      </>
    );
  };

  // const getEnableWhenWarnText = () => {
  //   return (
  //     <>
  //       <span className="mt-8 font-bold">{`Please re-evaluate conditions for below questions:`}</span>
  //       <ol className="mb-6 mt-4">
  //         {Object.keys(queWarningList).map((questionName: any) => {
  //           return <OrderedListItem>{questionName}</OrderedListItem>;
  //         })}
  //       </ol>
  //       <span>{`Do you want to continue without review?`}</span>
  //     </>
  //   );
  // };

  const showEnableWhenWarning = () => {
    return (
      <div>
        <div>{getEnableWhenWarnText()}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={
              'border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
            }
            type="button"
            onClick={() => {
              setSelectedOption('');
              flowType.isCreate ? createTemplate() : updateTemplate();
            }}
          >
            {t('T_CONTINUE')}
          </BiButton>
        </div>
      </div>
    );
  };

  const setSurveyDates = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const removeSurveyDate = () => {
    return (
      <div>
        <div>{t('T_SURVEY_CONFIRM_START_END_DATE_BODY')}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              //setSurveyDetailsForm({ ...surveyDetailsForm, surveyStatus: 'draft' });
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={'border border-solid border-white bg-primary text-white'}
            type="button"
            // disabled={activateValidator()}
            onClick={() => {
              setSelectedOption('');
              setStartDate(null);
              setEndDate(null);
              editTemplate('', true);
            }}
          >
            {t('T_SURVEY_REMOVE_SET_DATES_PROMPT_YES')}
          </BiButton>
        </div>
      </div>
    );
  };

  const setSurveyStartandEnd = () => {
    return (
      <div>
        <div>{t('T_SURVEY_SELECT_START_AND_DATE_INFO')}</div>

        <div className="mt-4 flex w-full flex-row items-center justify-center">
          <div className="align-center mr-6 flex flex-col">
            <div className="mb-2 text-center text-base font-semibold">
              {t('T_SURVEY_EFFECTIVE_START_END')}
            </div>
            <DatePicker
              selected={startDate}
              onChange={setSurveyDates}
              fixedHeight
              startDate={startDate}
              minDate={new Date()}
              endDate={endDate}
              showDisabledMonthNavigation
              shouldCloseOnSelect={false}
              calendarClassName={'text-base'}
              selectsRange
              inline
            />
          </div>
        </div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={'border border-solid border-white bg-primary text-white'}
            type="button"
            disabled={activateValidator()}
            onClick={() => {
              setSelectedOption('');
              editTemplate();
            }}
          >
            {t('T_SURVEY_SET_START_AND_DATE')}
          </BiButton>
        </div>
      </div>
    );
  };

  const activateSurvey = () => {
    return (
      <div>
        <div>{t('T_SURVEY_ACTIVATE_INFO')}</div>

        <div className="mt-8 text-right">
          <BiButton
            className={'mr-5 border border-solid border-primary text-primary'}
            type="button"
            onClick={() => {
              //setSurveyDetailsForm({ ...surveyDetailsForm, surveyStatus: 'draft' });
              setSelectedOption('');
            }}
          >
            {t('T_CANCEL')}
          </BiButton>

          <BiButton
            className={'border border-solid border-white bg-primary text-white'}
            type="button"
            // disabled={activateValidator()}
            onClick={() => {
              setSelectedOption('');
              setSurveyDetailsForm({
                ...surveyDetailsForm,
                surveyStatus: filter(statusTypes, { key: 'active' })
              });
              editTemplate('active');
            }}
          >
            {t('T_SURVEY_ACTIVATE_PROMPT_YES')}
          </BiButton>
        </div>
      </div>
    );
  };

  const validateUpdateQuestionButton = () => {
    if (!Object.keys(modifiedQuestion).length) {
      return false;
    } else if (!modifiedQuestion?.question?.trim() || !modifiedQuestion?.text?.trim()) {
      return true;
    } else if (modifiedQuestion?.type === 'bodymap' && !modifiedQuestion?.answerOption?.length) {
      return true;
    }
    return false;
  };

  const AddQuestionModalComp = (
    selectedQuestions: any,
    setSelectedQuestion: any,
    open: any,
    setOpen: any
  ) => {
    return (
      open && (
        <AddQuestionDialog
          questions={questions}
          addedQuestions={selectedQuestions}
          setQuestions={setSelectedQuestion}
          closePopup={() => {
            setOpen(false);
          }}
          closeModal={() => {
            setOpen(false);
          }}
          getNewQuestions={getNewQuestions}
          paging={paging}
          setPaging={setPaging}
          size={size}
          setSize={setSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagingLoading={pagingLoading}
          setPagingLoading={setPagingLoading}
        ></AddQuestionDialog>
      )
    );
  };

  const listQuestionList = (questionList: any) => {
    return (
      <>
        {questionList.map((item: any, index: number) => {
          const { type = '' } = item || {};
          return (
            <div
              className={`mt-3 flex w-full flex-row justify-between py-2 ${
                !getIsEditOrReadOnly() && 'cursor-pointer'
              } rounded-lg ${
                type !== AppConstants.QUESTION_TYPE_GROUP &&
                false &&
                'border-[0.5px] border-[#d9d9d9] px-4'
              } `}
              draggable={!getIsEditOrReadOnly()}
              onDragOver={(ev: any) => {
                ev.preventDefault();
              }}
              onDragStart={handleDrag}
              onDrop={handleDrop}
              id={item?.id}
              key={item?.id}
              datatype="individual"
            >
              {type === AppConstants.QUESTION_TYPE_GROUP ? (
                <>
                  <DragQuestionList
                    questionList={item}
                    isEdit={getIsEditOrReadOnly()}
                    questionIndex={index}
                    toggleAddGroupQuestion={() => toggleAddGroupQuestion(index)}
                    setUpdatedQuestions={(updatedList: any[], index: number) => {
                      const clonedAddedQue = cloneDeep(addedQuestions);
                      const temp = clonedAddedQue.map((ele: any, i: number) => {
                        if (i === index) {
                          ele.item = cloneDeep(updatedList);
                        }
                        return ele;
                      });
                      setAddedQuestions(cloneDeep(temp));
                    }}
                    removeGroupQuestion={removeQuestion}
                    setEditQAModal={setEditQAModal}
                    editQuestion={editQuestion}
                    AddQuestionModal={AddQuestionModalComp}
                    setConditionalToAddedQue={setConditionalToAddedQue}
                    currentIndex={index}
                    disabled={getIsEditOrReadOnly()}
                    collection={addedQuestions}
                  />
                </>
              ) : (
                <>
                  <img className="mr-5 cursor-pointer" src={DragIcon} alt={'three-dot'} />

                  <QuestionCard
                    questionData={item}
                    collection={addedQuestions}
                    currentIndex={index}
                    setQuestions={setAddedQuestions}
                    disabled={getIsEditOrReadOnly()}
                    mode={mode}
                    contextMenuHandler={contextMenuHandler}
                    AddQuestionModal={AddQuestionModalComp}
                    setConditionalToAddedQue={setConditionalToAddedQue}
                  ></QuestionCard>
                </>
              )}

              {/* <div className="bg-[#F5F7FA] text-sm">
                <Checkbox
                  className="mx-auto"
                  height="20px"
                  width="20px"
                  checked={false}
                  // onChange={() => console.log(true)}
                />
                <span>Display additional questions based on answers</span>
              </div> */}
            </div>
          );
        })}
      </>
    );
  };

  const loadOrgData = async () => {
    let orgResponse = await getOrgList(loadNextData, { size: 25, page: 1 }, userProfile);
    const updatedOrgResponse = updateOrgResponse(
      assignSurveyToOrgObj,
      orgResponse,
      loadNextData,
      setLoadNextData
    );
    setAssignSurveyToOrgObj(updatedOrgResponse);
    // setAssignSurveyToOrgObj((prevState: any) => ({
    //   ...prevState,
    //   updatedOrgResponse
    // }));
  };

  const loadRoleData = async () => {
    const { searchRoleName } = loadNextData;
    const { page, size } = assignSurveyToRoleObj.paging;
    let rolesResponse = await listRoles(
      searchRoleName,
      false,
      1,
      25,
      AppConstants.DEFAULT_SORT_BY,
      AppConstants.DEFAULT_SORT_DESC
    );

    setAssignSurveyToRoleObj(
      updateRoleResponse(assignSurveyToRoleObj, rolesResponse.data, loadNextData, setLoadNextData)
    );
  };

  useDidMountEffect(async () => {
    if (loadNextData.orgPaging && !loadNextData.searchOrg) {
      loadOrgData();
    }
    if (loadNextData.searchOrg && !loadNextData.orgPaging) {
      loadOrgData();
    }
    if (loadNextData.rolePaging && !loadNextData.searchRole) {
      loadRoleData();
    }
    if (loadNextData.searchRole && !loadNextData.rolePaging) {
      loadRoleData();
    }
  }, [loadNextData]);

  const onOrgSelectionChange = (item: any) => {
    setAssignSurveyToOrgObj({ ...assignSurveyToOrgObj, selectedFilter: item });
    setSurveyDetailsForm({ ...surveyDetailsForm, surveyOrgId: item.id });
  };

  const fetchMoreOrgData = () => {
    if (assignSurveyToOrgObj.hasMore) {
      setLoadNextData((prevState: any) => ({ ...prevState, orgPaging: true }));
    }
  };

  const searchOrgData = (data: any) => {
    setAssignSurveyToOrgObj((prevState: any) => ({
      ...prevState,
      itemsList: []
    }));
    if (data) {
      setLoadNextData((prevState: any) => ({
        ...prevState,
        searchOrgName: data,
        searchOrg: true
      }));
    }
  };

  const searchRoleData = (data: any) => {
    setAssignSurveyToRoleObj((prevState: any) => ({
      ...prevState,
      itemsList: []
    }));

    if (data) {
      setLoadNextData((prevState: any) => ({
        ...prevState,
        searchRoleName: data,
        searchRole: true
      }));
    }
  };

  const onRoleSelectionChange = (item: any) => {
    setAssignSurveyToRoleObj({ ...assignSurveyToRoleObj, selectedFilter: item });
    setSurveyDetailsForm({ ...surveyDetailsForm, surveyRoleId: item.id });
  };

  const fetchMoreRoleData = () => {
    if (assignSurveyToRoleObj.hasMore) {
      setLoadNextData((prevState: any) => ({ ...prevState, rolePaging: true }));
    }
  };

  const onSurveyStatusChange = (item: any) => {
    const [statusVal] = item;
    setSurveyDetailsForm({ ...surveyDetailsForm, surveyStatus: item });
    toggleStatus(statusVal.key);
  };

  const getModalHeaderText = () => {
    if (selectedOption === 'delete') {
      return `${t('T_SURVEY_DELETE_SURVEY')}`;
    } else if (selectedOption === 'enableWhenWarning') {
      return `${t('T_SURVEY_RE_EVALUATE_QUESTIONS')}`;
    } else if (selectedOption === 'setDates') {
      return `${t('T_SURVEY_SELECT_START_AND_DATE')}`;
    } else if (selectedOption === 'removeSetDates') {
      return `${t('T_SURVEY_CONFIRM_START_END_DATE_HEADER')}`;
    } else {
      return `${t('T_SURVEY_CHANGE_STATUS')}`;
    }
  };

  const rootProps = {
    onOrgSelectionChange: onOrgSelectionChange,
    searchOrgData: searchOrgData,
    fetchMoreOrgData: fetchMoreOrgData,
    orgSelectedItems: assignSurveyToOrgObj?.selectedFilter || [],
    orgOptionsList: assignSurveyToOrgObj?.itemsList,
    hasMoreOrg: assignSurveyToOrgObj?.hasMore,

    roleOptionsList: assignSurveyToRoleObj?.itemsList,
    onRoleSelectionChange: onRoleSelectionChange,
    searchRoleData: searchRoleData,
    fetchMoreRoleData: fetchMoreRoleData,
    roleSelectedItems: assignSurveyToRoleObj?.selectedFilter || [],
    hasMoreRole: assignSurveyToRoleObj?.hasMore
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      {addQuestionModal && (
        <AddQuestionDialog
          questions={questions}
          addedQuestions={addedQuestions}
          setQuestions={setAddedQuestions}
          closePopup={revertChanges}
          closeModal={() => {
            setAddQuestionModal(false);
          }}
          getNewQuestions={getNewQuestions}
          paging={paging}
          setPaging={setPaging}
          size={size}
          setSize={setSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagingLoading={pagingLoading}
          setPagingLoading={setPagingLoading}
        ></AddQuestionDialog>
      )}

      {addGroupQuestionModal && (
        <AddGroupQuestionDialog
          questions={questions}
          // addedQuestions={groupQuestionIndex >= 0 ? addedGroupQuestion[groupQuestionIndex] : {}}
          addedQuestions={groupQuestionIndex >= 0 ? addedQuestions[groupQuestionIndex] : {}}
          setQuestions={(val: any) => {
            // if (groupQuestionIndex >= 0) {
            //   addedGroupQuestion[groupQuestionIndex] = val[0];
            // } else if (addedGroupQuestion.length) {
            //   let temp = [...addedGroupQuestion, ...val];
            //   // temp.push(val);
            //   console.log(temp);
            //   setAddedGroupQuestion(temp);
            // } else {
            //   setAddedGroupQuestion(val);
            // }
            if (groupQuestionIndex >= 0) {
              addedQuestions[groupQuestionIndex] = val[0];
            } else {
              let temp = [...addedQuestions, ...val];
              setAddedQuestions(temp);
            }
          }}
          closePopup={revertGroupQuestionChanges}
          closeModal={() => {
            setAddGroupQuestionModal(false);
          }}
          getNewQuestions={getNewQuestions}
          paging={paging}
          setPaging={setPaging}
          size={size}
          setSize={setSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagingLoading={pagingLoading}
          setPagingLoading={setPagingLoading}
          isEdit={getIsEditOrReadOnly()}
        ></AddGroupQuestionDialog>
      )}

      {previewQuestionModal && (
        <PreviewQuestionDialog
          questions={addedQuestions}
          closePopup={setPreviewQuestionModal}
        ></PreviewQuestionDialog>
      )}
      <div
        className="flex h-fit w-full flex-row p-5"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <div className="flex h-fit w-full flex-col justify-start overflow-visible ">
          <Card
            title="Survey details"
            icon={Clipboard}
            alt="organization icon image"
            height=""
            width=""
          >
            <MenuButtonsPortal>
              {flowType.isCreate && (
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    navigate('/survey-template/list');
                  }}
                >
                  {t('T_BACK_TO_SURVEY_LIST')}
                </BiButton>
              )}
              {!flowType.isCreate && (
                <BiButton
                  className={'mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    getIsEditOrReadOnly()
                      ? navigate('/survey-template/list')
                      : setisReadOnly(true, true);
                  }}
                >
                  {getIsEditOrReadOnly() ? t('T_BACK_TO_SURVEY_LIST') : t('T_CANCEL')}
                </BiButton>
              )}

              {!getIsEditOrReadOnly() && (
                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    flowType.isCreate ? saveTemplate() : editTemplate();
                  }}
                  disabled={hasErrors() || validateSave() || isDataChanges()}
                >
                  {t('T_SAVE')}
                </BiButton>
              )}
              {getIsEditOrReadOnly() && (
                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    setisReadOnly(false, false);
                  }}
                  //disabled={getEditDisabledForOverride()}
                >
                  {t('T_EDIT')}
                </BiButton>
              )}
            </MenuButtonsPortal>

            <ToastNotification
              message={toastData.toastMessage}
              isActive={toastData.isToastActive}
              setIsActive={() =>
                dispatch(
                  setToastData({
                    toastMessage: '',
                    isToastActive: false,
                    toastType: null
                  })
                )
              }
              status={toastData.toastType}
            />
            <div className="flex w-full flex-wrap">
              <DynamicFormFieldRenderer
                layout={2}
                dynamicFormData={surveyDetailsFormSchema}
                formData={surveyDetailsForm}
                setFormData={setSurveyDetailsForm}
                formError={surveyDetailsFormError}
                editable={true}
                asterixFirst={true}
                disabled={getIsEditOrReadOnly()}
                rootProps={rootProps}
              />
            </div>
          </Card>
          <div className="flex w-full flex-col">
            <Card
              title="Survey Questions"
              icon={Clipboard}
              alt="Clip Icon"
              height=""
              width=""
              className="rounded-none rounded-t-lg"
            >
              <div className="flex flex-col">
                <div className="flex w-full flex-row justify-between">
                  <div>
                    <label className="flex items-center text-base font-normal" htmlFor="name">
                      {/* <img className="mx-1 h-2 w-2" src={Asterix} alt="asterix" /> */}
                      {t('T_ADD_25_QUESTIONS')}
                    </label>
                  </div>
                  <div className="flex">
                    {addedQuestions.length ? (
                      <BiButton
                        className={
                          'mr-5 mt-[3px] border border-solid border-primary-disabled py-[1px] text-primary '
                        }
                        type="button"
                        onClick={() => {
                          setPreviewQuestionModal(true);
                        }}
                      >
                        {t('T_SURVEY_PREVIEW')}
                      </BiButton>
                    ) : (
                      ''
                    )}
                    {!getIsEditOrReadOnly() ? (
                      <div>
                        <AddQuestionMenu
                          AddQuestions={toggleAddQuestion}
                          AddGroupQuestions={() => toggleAddGroupQuestion(-1)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div>
                  {addedQuestions.length ? listQuestionList(addedQuestions) : ''}
                  {/* {addedGroupQuestion.length ? listGroupQuestionList(addedGroupQuestion) : ''} */}
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="flex w-1/4 flex-col pl-5">
          {!mode && (
            <>
              <div className="w-60">
                {/* {!getIsEditOrReadOnly() && (
                  
                )} */}
                <>
                  <PickList
                    fieldKey={'key'}
                    label={t('T_STATUS')}
                    required={false}
                    fieldValues={['value']}
                    selectedItems={surveyDetailsForm.surveyStatus}
                    onSelectedChange={(item: any) => {
                      if (item.length) {
                        onSurveyStatusChange(item);
                      } else {
                        onSurveyStatusChange(surveyDetailsForm.surveyStatus);
                      }
                    }}
                    items={statusTypes}
                    disabled={getIsEditOrReadOnly()}
                    readOnly={false}
                    hasMore={false}
                    type={PICKLIST_TYPES.SINGLE}
                    style={
                      !getIsEditOrReadOnly() && statusField[surveyDetailsForm.surveyStatus[0].key]
                        ? {
                            backgroundColor: statusField[surveyDetailsForm.surveyStatus[0].key]
                          }
                        : {}
                    }
                  />
                </>
                {/* {getIsEditOrReadOnly() && (
                  <div className="text-l mt-1 w-60 text-gray-700">
                    Status:{' '}
                    <div
                      className={`${
                        surveyDetailsForm.surveyStatus[0].key === 'active'
                          ? 'bg-[#9bd7b2]'
                          : 'bg-[#FFF6DD]'
                      } text-l mt-1 w-60 text-gray-700`}
                    >
                      {surveyDetailsForm.surveyStatus[0].display}
                    </div>
                  </div>
                )} */}
              </div>
              <div className="mt-6">
                {!startDate && !endDate && !getIsEditOrReadOnly() && (
                  <BiButton
                    disabled={getIsEditOrReadOnly()}
                    className={
                      'w-60 border border-solid border-[#0074E0] bg-[#DCEEFE] text-[#0074E0]'
                    }
                    type="button"
                    onClick={() => {
                      setSelectedOption('setDates');
                    }}
                  >
                    {t('T_SURVEY_SET_DATES')}
                  </BiButton>
                )}
                {startDate && endDate && (
                  <>
                    <div className="mt-1 w-3/5 text-sm text-[#595959]">
                      <p>{t('T_SURVEY_START_DATE')}</p>
                      <p>{moment(startDate).format('MMM DD, YYYY')}</p>
                    </div>
                    <div className="mt-2 w-3/5 text-sm text-[#595959]">
                      <p>{t('T_SURVEY_END_DATE')}</p>
                      <p>{moment(endDate).format('MMM DD, YYYY')}</p>
                    </div>
                    {!getIsEditOrReadOnly() && (
                      <div className="mt-6 text-left">
                        <BiButton
                          disabled={getIsEditOrReadOnly()}
                          className={
                            'w-60 border border-solid border-[#0074E0] bg-[#DCEEFE] text-[#0074E0]'
                          }
                          type="button"
                          onClick={() => {
                            setSelectedOption('removeSetDates');
                          }}
                        >
                          {t('T_SURVEY_REMOVE_SET_DATES')}
                        </BiButton>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="mt-6">
                <BiButton
                  className={
                    'w-60 border border-solid border-red-600 text-danger hover:bg-red-600 hover:text-white'
                  }
                  type="button"
                  onClick={() => {
                    setSelectedOption('delete');
                  }}
                >
                  {t('T_SURVEY_DELETE_TEXT')}
                </BiButton>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        showCloseIcon={true}
        width={'35rem'}
        headerText={getModalHeaderText()}
        toggleVisiblity={(value: boolean) => {
          setSelectedOption('');
        }}
        isVisible={[
          'activate',
          'delete',
          'deactivate',
          'draft',
          'unknown',
          'enableWhenWarning',
          'setDates',
          'removeSetDates'
        ].includes(selectedOption)}
        body={
          selectedOption === 'enableWhenWarning'
            ? showEnableWhenWarning()
            : selectedOption === 'delete'
            ? deleteSurvey()
            : selectedOption === 'setDates'
            ? setSurveyStartandEnd()
            : selectedOption === 'removeSetDates'
            ? removeSurveyDate()
            : selectedOption === 'deactivate'
            ? deactivateSurvey()
            : selectedOption === 'draft'
            ? draftSurvey()
            : selectedOption === 'unknown'
            ? unknownSurvey()
            : activateSurvey()
        }
        headerLeftIcon={
          selectedOption === 'delete' ? <ExclamationIcon className="h-7.5 w-6" /> : ''
        }
      />

      {editQAModal && (
        <Modal
          showHeader={true}
          showCloseIcon={true}
          width="62rem"
          headerText={t('T_EDIT_QUESTION')}
          toggleVisiblity={(value: boolean) => {
            setEditQAModal(false);
          }}
          isVisible={true}
          overflowVisible={true}
          className=""
          //body={<NewQuestion editFromSurveyTemplate={true} questionId={editedQaId} />}
          body={
            <EditQuestion
              editFrom="surveyTemplate"
              editedQuestion={editedQuestion}
              updateQuestionnaire={updateQuestionnaire}
            />
          }
          secondaryOnClick={() => setEditQAModal(false)}
          secondaryButtonText={t('T_CANCEL')}
          secondaryButtonWidth={'100px'}
          primaryButtonText={t('T_BUTTON_UPDATE')}
          primaryButtonWidth={'100px'}
          primaryOnClick={saveToQuestionnaire}
          primaryButtonDisabled={validateUpdateQuestionButton()}
        />
      )}
    </LoaderContainer>
  );
};

export default SurveyTemplateDetail;

export const DropDownContainer = styled.div`
  position: relative;
  overflow: visible;
`;

const DropDownList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0px;
  position: absolute;
  width: 200px;
  max-height: content;
  z-index: 1;
  left: 0;
  top: 45px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  overflow-y: auto;
`;

const ListItem = styled('li')`
  list-style: none;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding: 9px 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const OrderedListItem = styled('li')`
  list-style: inside;
  margin-top: 3px;
`;
