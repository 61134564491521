import React from 'react';
import styled from 'styled-components/macro';

interface RadioButtonProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  selectedField: any;
  option: any;
  className?: string;
  size?: string;
  disabled?: boolean;
  label?: string;
  fontSize?: string;
  fontColor?: string;
  name: string;
  id?: string;
}

interface RadioProps {
  size: string;
  disabled?: boolean;
  checked: boolean;
}

interface RadioLabelProps {
  size: string;
  fontColor?: string;
}

const RadioLabel = styled.label<RadioLabelProps>`
  font-family: Open Sans;
  font-size: ${({ size }) => size};
  font-weight: 400, Regular;
  font-style: normal;
  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};
  margin-left: 5px;
`;

const RadioContainer = styled.div<RadioProps>`
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
    width: ${({ size }) => size};
    height:${({ size }) => size};
    border: 2px solid #99B0C6;
    transition: 0.2s all linear;
    margin-right: 5px;
    position: relative;
    top: 4px;
    background-color:${({ disabled, checked }) => (disabled && checked ? '#595959' : '#FFFFFF')} ;
  }
  
  input:checked {
    border:${({ disabled }) => (disabled ? '5px solid #BFBFBF' : '5px solid #0560A9')} ;
  }
  
  button:hover,
  button:focus {
  color: #999;


button:active {
  background-color: white;
  color: black;
  outline: 1px solid #0560A9;
}`;

function RadioButton({
  onClick,
  selectedField,
  className,
  size,
  option,
  label,
  fontSize,
  fontColor,
  disabled,
  name,
  id = ''
}: RadioButtonProps) {
  return (
    <RadioContainer
      size={size ? size : '20px'}
      className={`items-baseline justify-center ${className}`}
      disabled={disabled}
      checked={selectedField === option}
    >
      <input
        type="radio"
        name={name}
        disabled={disabled}
        value="email"
        data-testid={`radio-btn-${option}${id}`}
        checked={selectedField === option}
        onClick={onClick}
        onChange={() => {}}
      />
      <RadioLabel
        htmlFor={label}
        size={fontSize ? fontSize : '16px'}
        fontColor={fontColor ? fontColor : '#505050'}
      >
        {label}
      </RadioLabel>
    </RadioContainer>
  );
}

export default RadioButton;
