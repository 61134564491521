import React, { useState } from 'react';
import InfoIcon from '@heroicons/react/outline/InformationCircleIcon';
import { colorProductPrimaryHover } from '../../../styles/globalVariables';

interface InfoPopoverProps {
  content: React.ReactNode;
  className?: string;
}

const InfoPopover: React.FC<InfoPopoverProps> = ({ content, className = '' }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopoverOn = () => {
    setIsPopoverOpen(true);
  };

  const togglePopoverOff = () => {
    setIsPopoverOpen(false);
  };

  return (
    <div
      className={`relative inline-block ${className}`}
      onMouseEnter={togglePopoverOn}
      onMouseLeave={togglePopoverOff}
    >
      <span className="cursor-pointer text-blue-500 hover:underline">
        <InfoIcon className="h-5 w-5" style={{ color: colorProductPrimaryHover }}></InfoIcon>
      </span>
      {isPopoverOpen && (
        <div className="absolute left-0 top-0 ml-2 mt-2 rounded-md border border-gray-300 bg-white p-2 shadow-lg">
          <div className="arrow absolute left-full top-0 h-4 w-4 -translate-y-1/2 rotate-45 bg-white"></div>
          {content}
        </div>
      )}
    </div>
  );
};

export default InfoPopover;
