import { Label } from '../../AccountProfile/ProfileStyles';
import styled from 'styled-components/macro';
import Asterix from '../../../assets/asterix.svg';
import DropdownSvg from '../../../assets/dropdown.svg';
import { useRef, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';

interface NewOrganizationSampleData {
  name: string;
  phone: string;
  fax: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  permissionSetName: any;
}

interface SelectFieldProps {
  type: string;
  id: string;
  label: string;
  placeholder: string;
  fieldKey: string;
  required: boolean;
  formData: NewOrganizationSampleData;
  setFormData: Function;
  formError: NewOrganizationSampleData;
  options?: any;
  disabled?: boolean;
  objectKey?: string;
  icon?: JSX.Element[] | any;
  maxHeight?: boolean;
  fontSize?: string;
  style?: any;
  asterixFirst?: Boolean;
}

const ErrorLabel = styled.span`
  fontsize: 11px;
  color: red;
`;

function SelectField({
  type,
  id,
  label,
  placeholder,
  fieldKey,
  required,
  formData,
  setFormData,
  formError,
  options,
  disabled,
  objectKey,
  icon,
  maxHeight = false,
  fontSize = '',
  style = {},
  asterixFirst
}: SelectFieldProps) {
  const selectRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  const toggling = () => {
    setShowPopup(!showPopup);
  };

  useOutsideClick(
    selectRef,
    () => {
      if (showPopup) {
        setShowPopup(false);
      }
    },
    false
  );
  const onOptionClicked = (value: any) => () => {
    if (!value) {
      return;
    }
    setShowPopup(false);
    setFormData({ ...formData, [fieldKey]: value });
  };

  const objAccess = (obj: any, path: any) =>
    path.split('.').reduce((value: any, el: any) => value && value[el], obj);

  const { t } = useTranslation();

  return (
    <>
      {label ? (
        <Label htmlFor={id}>
          {asterixFirst && required ? <Image src={Asterix} className="mr-1" /> : ''}
          {t(label)}
          {required && !asterixFirst ? <Image src={Asterix} /> : ''}
        </Label>
      ) : (
        ''
      )}
      <DropDownContainer className={disabled ? 'disabled' : ''} style={style || {}}>
        <DropDownHeader onClick={toggling} data-testid="select-field-header">
          {objectKey
            ? objAccess(formData[fieldKey as keyof NewOrganizationSampleData], objectKey) ||
              `Select`
            : formData[fieldKey as keyof NewOrganizationSampleData] || `Select`}
          <img src={DropdownSvg} />
        </DropDownHeader>
        {showPopup && (
          <DropDownListContainer ref={selectRef}>
            <DropDownList data-testid="select-option-list" maxHeight={maxHeight}>
              <ListItem onClick={onOptionClicked('')} fontSize={fontSize}>
                {t('T_SELECT')}
              </ListItem>
              {options.map((option: any, index: any) =>
                typeof option === 'string' ? (
                  <ListItem
                    className="flex items-center"
                    onClick={onOptionClicked(option)}
                    key={Math.random()}
                    fontSize={fontSize}
                  >
                    {icon ? (
                      <div
                        className="mr-2 self-center"
                        dangerouslySetInnerHTML={{ __html: icon[index] }}
                      ></div>
                    ) : (
                      ''
                    )}
                    {option}
                  </ListItem>
                ) : objectKey && option === Object(option) ? (
                  <ListItem
                    onClick={onOptionClicked(option)}
                    key={Math.random()}
                    fontSize={fontSize}
                  >
                    {objAccess(option, objectKey)}
                  </ListItem>
                ) : option.countryName === formData['country'] ? (
                  option.regions.map((state: any) => {
                    return (
                      <ListItem
                        onClick={onOptionClicked(state.name)}
                        key={Math.random()}
                        fontSize={fontSize}
                      >
                        {state.name}
                      </ListItem>
                    );
                  })
                ) : (
                  ''
                )
              )}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>

      <ErrorLabel>
        {formError[fieldKey as keyof NewOrganizationSampleData]
          ? formError[fieldKey as keyof NewOrganizationSampleData]
          : ''}
      </ErrorLabel>
    </>
  );
}

export const DropDownContainer = styled('div')`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  width: 100%;
  height: 44px;
  background: #ffffff;

  border: 0.5px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    background-color: #f5f5f5;
  }
`;

const DropDownHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DropDownListContainer = styled('div')``;

export const DropDownList = styled('ul')<{ maxHeight: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0px;
  position: absolute;
  width: 100%;
  max-height: ${(p: any) => (p.maxHeight ? 'content' : '280px')};
  z-index: 1;
  left: 0;
  top: 45px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  overflow-y: auto;
`;

const ListItem = styled('li')<{ fontSize: string }>`
  list-style: none;
  width: 77px;
  font-family: 'Open Sans';
  font-size: ${(p: any) => (p.fontSize ? p.fontSize : '14px')};
  line-height: 22px;
  color: #262626;
  // margin: 9px 10px;
  padding: 9px 10px;
  cursor: pointer;
  width: 100%;

  &:hover {
    color: white;
    background: var(--color-bi-gray-700);
  }
`;

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

export default SelectField;
