import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { AppConstants } from '../../constants/AppConstants';
import { useQuery } from '../../hooks/queryParams';
import { RouteConstants } from '../../constants/RouteConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { listFieldGropus } from '../../http/access-management-service';
import { setFieldGroup } from '../../app/consentReducer';
import moment from 'moment';
import { find, compact, uniq } from 'lodash';
import { getUsers } from '../../http/user-management-services';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import { getSortTableArray } from '../../util/table-utils';

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

/**
 * @parentId consent-management
 * @manager FieldGroupList
 * @overview
 *   <section>
 *           <p>
 *               Fieldgroup listing page is the default page the user is navigated on clicking <b>Consent Management</b> navigation bar menuitem which will open an consent page and then click on <b>Field Groups</b> in tabitem. Listing page will show all the Fieldgroup(s) in a paginated way.
 *               The Default pagination size be fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & Fieldgroup data.
 *           </p>
 *           </br>
 *           <p>
 *               Fieldgroup(s) can be filtered based on the fieldgroup name using the search field provided above the fieldgroup listing data grid.
 *           </p>
 *           </br>
 *           <p>
 *               Datagrid will display details of each Fieldgroup as row item with each column representing fieldgroup property.
 *               <br>
 *               <ul>
 *                   <li>Name</li> <li>Resource Type</li> <li>Is Default</li> <li>Created On</li> <li>Last Modified On</li>
 *               </ul>
 *           </p>
 *           <p>
 *               Sorting of Fieldgroup grid is supported on <i>Name, Resource Type, Is Default, Last Modified On</i> columns. The breadcrumb bar on the application toolbar will reflect location of the user in the admin portal. Clicking on user rowitem will drill down user to user details page which will be displayed in readonly mode.
 *           </p>
 *         </section>
 *         <section>
 *          <h4>Failure Status Codes</h3>
 *           <p>
 *           This section describes the List Fieldgroup(s) Status Code information.
 *           <table>
 *               <tr>
 *                   <th>HTTP Status Code</th>
 *                   <th>Service Error Code</th>
 *                   <th>Error Message</th>
 *               </tr>
 *               <tr>
 *                   <td>403</td>
 *                   <td>UMS_PERMISSION_DENIED</td>
 *                   <td>You do not have permission to view this page</td>
 *               </tr>
 *               <tr>
 *                   <td>500</td>
 *                   <td>UMS_INTERNAL_ERROR</td>
 *                   <td>Internal Server Error</td>
 *               </tr>
 *               <tr>
 *                   <td>503</td>
 *                   <td></td>
 *                   <td>Service Unavailable</td>
 *               </tr>
 *           </table>
 *       </p>
 *       </section
 *       <section>
 *          <h4>Dependent System settings, Platform services & Role Permission</h3>
 *          <p>This section describes the list of dependent system settings & platform services required for functioning of Fieldgroup(s) listing page.</p>
 *           <h5>System Settings</h4>
 *           <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *           <table>
 *               <tr>
 *                   <th>Key</th>
 *                   <th>Type</th>
 *                   <th>Value</th>
 *                   <th>Scope</th>
 *               </tr>
 *               <tr>
 *                   <td>None</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *               </tr>
 *           </table>
 *           <br>
 *          <h5>Platform Service(s)</h4>
 *          <p>Table lists all the dependent platform service(s) with specific version(s) for User(s) listing</p>
 *           <table>
 *               <tr>
 *                   <th>Service Name</th>
 *                   <th>Version</th>
 *               </tr>
 *               <tr>
 *                   <td>Configuration Service</td>
 *                   <td>1.4.0</td>
 *               </tr>
 *               <tr>
 *                   <td>User Management Service</td>
 *                   <td>1.4.0</td>
 *               </tr>
 *           </table>
 *           <br>
 *          <h5>API Role Permission(s)</h4>
 *          <p>Table lists the required API role permissions for listing Fieldgroup(s) page</p>
 *           <table>
 *               <tr>
 *                   <th>API URL</th>
 *                   <th>API Method</th>
 *                   <th>API Permission</th>
 *                   <th>Required</th>
 *               </tr>
 *               <tr>
 *                   <td>/field-groups</td>
 *                   <td>GET</td>
 *                   <td>accesscontrol-service.fieldgroup.list</td>
 *                   <td>Yes</td>
 *               </tr>
 *               <tr>
 *                   <td>/users</td>
 *                   <td>GET</td>
 *                   <td>user-management-service.user.list</td>
 *                   <td>Yes</td>
 *               </tr>
 *           </table>
 *       </section>
 *       <section>
 *        <p>Sequence Diagram for Fieldgroup(s) listing page</p>
 *       </section>
 */

const FieldGroupList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [page, setPage] = useState(AppConstants.DEFAULT_PAGE);
  const [size, setSize] = useState(AppConstants.DEFAULT_PAGE_SIZE);
  const [pagingOpts, setPagingOpts] = useState<any>(null);

  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const userProfileMap: Map<string, any> = new Map<string, any>();

  const fieldGroupSelector = (data: any, step: number) => {
    dispatch(setFieldGroup(data));
    navigate(`${RouteConstants.ROUTE_CONSENTS_FIELDGROUP}/${data.id}`);
  };

  const refreshTableData = (name: string, page: number, size: number) => {
    setIsLoading(true);
    setTableValues(name, page, size);
  };

  const setTableValues = (name: string = '', page: number, size: number) => {
    setSize(size);
    setPage(page);
    fetchFieldGroups(size, page, name);
  };

  const fetchFieldGroups = async (size: number, page: number, name: string = '') => {
    const sorters: string[] = getSortTableArray(tableHeaders);
    let resposne = await listFieldGropus(size, page, name, sorters.join());
    if (resposne.status === HTTP_STATUS.HTTP_OK) {
      await fetchCreatedBy(resposne);
    } else {
      let error = getFailureMessage(resposne);
      dispatch(setToastData({ isToastActive: true, toastMessage: error, toastType: 'error' }));
    }
  };

  const fetchCreatedBy = async (resposne: any) => {
    const { data = {}, status } = resposne;
    const questionsList: Array<any> = [];
    if (resposne.status === HTTP_STATUS.HTTP_OK) {
      const { data: questionList = [], paging } = data;
      const users: Array<string> = questionList
        .filter((question: any) => !userProfileMap.get(question.createdBy))
        .map((question: any) => question.createdBy);

      const userIds: Array<string> = uniq(compact(users));
      if (userIds.length) {
        const userProfiles: Array<any> = await getUserProfiles(userIds);
        userProfiles.forEach((userProfile: any) => {
          userProfileMap.set(userProfile?.userId, userProfile);
        });
      }

      if (questionList.length) {
        questionList.forEach(async (question: any, index: any) => {
          const { createdOn, createdBy } = question;
          question;
          questionsList.push({
            ...question,
            ...{
              createdOn: moment(createdOn).format(AppConstants.DEFAULT_DATE_FORMAT),
              createdByUser: getUserName(userProfileMap.get(createdBy))
            }
          });
        });
        setRows(questionsList);
        setPagingOpts(paging);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        noDataAvailableMessage(setRows, setSize, setPagingOpts, paging, t);
      }
    }
  };

  useEffect(() => {
    fetchFieldGroups(size, page);
    dispatch(
      setURLPaths([
        {
          key: RouteConstants.ROUTE_CONSENTS_FIELDGROUP,
          label: 'Consents'
        }
      ])
    );
  }, []);

  const FieldGroupHeaders: Array<ITableHeader> = [
    {
      key: 'name',
      description: 'T_NAME',
      sortEnabled: true,
      width: '25%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'resourceType',
      description: 'T_RESOURCE_TYPE',
      sortEnabled: true,
      width: '25%',
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'isDefault',
      description: 'T_IS_DEFAULT',
      sortEnabled: true,
      width: '10%',
      nastedData: true,
      render: (data: any) => {
        return data.isDefault ? 'Yes' : 'No';
      },
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'createdOn',
      description: 'T_CREATED_ON',
      sortEnabled: true,
      width: '40%',
      nastedData: true,
      render: (data: any) => {
        return `${data.createdOn} ${data.createdByUser && `by ${data.createdByUser}`}`;
      },
      sortingInformation: {
        order: ''
      }
    },{
      key: 'updatedOn',
      description: 'T_LAST_MODIFIED_ON',
      sortEnabled: true,
      render: (data: any) => {
        return data.updatedOn
          ? moment(data.updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT)
          : '';
      },
      sortingInformation: {
        order: '',
      },
      width: '25%'
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: false,
    //   width: '25%',
    //   render: (data: any) => {
    //     return data?.updatedBy;
    //   }
    // }
  ];

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(FieldGroupHeaders);

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <CustomersTableContainer style={{ height: 'inherit' }}>
          <TemplateTable
            tableData={rows}
            size={size.toString()}
            currentPage={page.toString()}
            templateSelector={fieldGroupSelector}
            onRefreshTableData={refreshTableData}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            searchPlaceholder={t('T_SEARCH_NAME_FIELDGROUP') || ''}
            pagingData={pagingOpts}
            containerClass="mt-0"
            showDeleteButton={true}
            theme={useTheme().theme}
            isMultiColumnSort={false}
          />
        </CustomersTableContainer>
      </LoaderContainer>
    </>
  );
};

export default FieldGroupList;
