import { useTranslation } from 'react-i18next';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { useTheme } from '../../context/themeContext';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { useEffect, useState } from 'react';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import {
  getCatalogConfigData,
  getMobileConfigurations as getConfigurationByCategory,
  updateGCPFile
} from './MobileConfigUtil';
import MobileConfigurationDynamicUI from './RenderDynamicComponent';
import { cloneDeep, findIndex, isEmpty, upperFirst, find, isEqual, xorWith, sortBy } from 'lodash';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  setSelectedConfig,
  setConfigList,
  setDirtyConfiguration,
  deleteDirtyConfiguration
} from '../../app/mobileConfigurationReducer';
import { updateMultipleSystemSetting } from '../../http/configuration-services';
import { updateMetadataInstance } from '../../http/metadata-service';
import { setToastData } from '../../app/toastReducer';
import moment from 'moment';
import { RouteConstants } from '../../constants/RouteConstants';
import { getLoginUserId } from '../../util/admin-utils';
import { AppConstants } from '../../constants/AppConstants';
import { useQuery } from '../../hooks/queryParams';
import AuditInfo from '../Common/AuditInfo';

const ROUTE_MAPPING: any = {
  style: 'MobileStyleConfig',
  configuration: 'MobileConfig'
};

interface MobileConfigToolbarProps {
  onSave: Function;
  onCancel: Function;
}

const MobileConfigToolbar: React.FC<MobileConfigToolbarProps> = ({
  onSave,
  onCancel
}: MobileConfigToolbarProps) => {
  const { t } = useTranslation();

  const apiRolePermissions = useAppSelector((state) => state.initialLoadData.apiPermissions);
  const selectedConfig = useAppSelector((state) => state.mobileConfiguration.selectedConfig);
  const dirtyConfiguration = useAppSelector((state) => state.mobileConfiguration.dirtyConfiguraion);
  const isDirty = () => {
    const { category } = selectedConfig || {};
    if (!category) {
      return false;
    }
    const categoryContext = dirtyConfiguration[category] || {};
    const { fieldErrors = {}, fieldValues = {} } = categoryContext;

    return !Object.values(fieldErrors).length && !!Object.values(fieldValues).length;
  };

  return (
    <MenuButtonsPortal>
      <BiButton
        className={'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'}
        type="button"
        onClick={() => onCancel()}
      >
        {t('T_CANCEL')}
      </BiButton>
      {apiRolePermissions[AppConstants.PERMISSION_METADATA_INSTANCE_UPDATE] && (
        <BiButton
          className={'bg-primary py-1.5 text-white'}
          type="button"
          disabled={!isDirty()}
          onClick={() => onSave()}
        >
          {t('T_SAVE')}
        </BiButton>
      )}
    </MenuButtonsPortal>
  );
};

/**
 * @parentId mobile-configuration
 * @manager App Config, Styles Config - Mobile Configuration
 * @overview
 *  <section>
 *  <p>
 *    <b>App Config</b> allows users to control settings for the BI DMS Mobile Application. These include various validations fields, display settings, fields to display, control over various options. Depending on these settings the mobile app behaves accordingly. This catalog is highly configurable and driven entirely from metadata.
 *    <b>App Config</b> also includes audit information such as <i>Last Modified By</i> and <i>Last Modified On</i>
 *  </p>
 *  <br />
 *  <p>
 *    <b>Styles Config</b> allows users to control the styling aspect for the BI DMS Mobile Application. These include Font Family for both iOS and Android platforms, background and font colour options and other styling options. These are also configurable and driven entirely from metadata.
 *  </p>
 *  <p> This metadata driven configuration includes: <br>
 *  <ul>
 *    <li>
 *      <b>Styling of the form:</b> The entire layout is configurable including the direction of fields, order of fields, card structure and even the grouping. Grouping basically helps in field categorization as it puts the fields in the rightful category to make operation much more easier. For example: Anything regarding account controls comes under “My Account” Group Section, this helps in easier classification. The Group is also configurable where the layout icons can be customised.
 *    </li>
 *    <br />
 *    <li>
 *      <b>Fields and validation:</b> The Fields and validation are also configurable where the datatype, its associated validators, label display texts and the type of field. Type of field refers to what will be rendered to the user in order to do selection, these are as follows: <br />
 *      <p>
 *      <table>
 *        <tr>
 *          <th>Field Type</th>
 *          <th>UI Rendered</th>
 *          <th>Description</th>
 *        </tr>
 *        <tr>
 *          <td>BOOLEAN</td>
 *          <td>Switch</td>
 *          <td>An ON/OFF Button like switch that holds boolean values.</td>
 *        </tr>
 *        <tr>
 *          <td>STRING</td>
 *          <td>InputField</td>
 *          <td>This is a simple text input field used to record some data from the user in the form of text.</td>
 *        </tr>
 *        <tr>
 *          <td>NUMBER/INTEGER</td>
 *          <td>InputField</td>
 *          <td>This is a simple text input field used to record only numeric data.</td>
 *        </tr>
 *        <tr>
 *          <td>COLOR</td>
 *          <td>Input with Color Picker</td>
 *          <td>This is a colour picker which is mainly used for Styles Config. This allows users to enter colour in form of a hex code or they can make use of the colour picker by clicking on the colour symbol.</td>
 *        </tr>
 *        <tr>
 *          <td>SINGLE</td>
 *          <td>Single Selection PickList</td>
 *          <td>This is a list component which has dropdown options and users can select only one out of the various options.</td>
 *        </tr>
 *        <tr>
 *          <td>MULTI</td>
 *          <td>Multi Selection PickList</td>
 *          <td>This is a list component with dropdown options which allows the user to select/unselect multiple options. The Order of selection does not matter in this type.</td>
 *        </tr>
 *        <tr>
 *          <td>ORDEREDMULTI</td>
 *          <td>Ordered Multi Selection</td>
 *          <td>This is a special type of multi-selection in which users can select/unselect and also change the order of selected items. It is useful in scenarios where the ordering is required.</td>
 *        </tr>
 *      </table>
 *    </li>
 *    <br />
 *    <li>
 *      <b>Values:</b> Depending on its type a value can either be stored publicly or privately. Coming to public these values are stored via a configuration.json file on Google Cloud Platform bucket. To access these public values a user can do so without an authentication since the file is stored on a bucket as a public URL. Private Configuration values are stored in system-settings and need authentication in order to access
 *    </li>
 *  </ul>
 *  </section>
 *  <section>
 *  <h4>Failure Status Codes</h4>
 *  <p> This section describes the Mobile Configuration Status Code information. <br>
 *  <table>
 *    <tr>
 *      <th>HTTP Status Code</th>
 *      <th>Service Error Code</th>
 *      <th>Error Message</th>
 *    </tr>
 *    <tr>
 *      <td>403</td>
 *      <td>CS_PERMISSION_DENIED</td>
 *      <td>You do not have permission to view this page.</td>
 *    </tr>
 *    <tr>
 *      <td>500</td>
 *      <td>CS_INTERNAL_ERROR</td>
 *      <td>Internal Server Error</td>
 *    </tr>
 *    <tr>
 *      <td>503</td>
 *      <td></td>
 *      <td>Service Unavailable</td>
 *    </tr>
 *  </table>
 *  </p>
 *  </section> <section>
 *  <h4>Dependent System settings, Platform services & Role Permission </h4>
 *  <p>This section describes the list of dependent system settings & platform services required for functioning of Mobile Configuration page.</p>
 *  <h5>System Settings
 *  </h5>
 *  <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *  <br>
 *  <table>
 *  <tr>
 *    <th>Key</th>
 *    <th>Type</th>
 *    <th>Value</th>
 *    <th>Scope</th>
 *  </tr>
 *  <tr>
 *    <td>None</td>
 *    <td>None</td>
 *    <td>None</td>
 *    <td>None</td>
 *  </tr>
 *  </table>
 *  <br>
 *  <h5>Platform Service(s) </h5>
 *  <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit metadata application.</p>
 *  <br>
 *  <table>
 *    <tr>
 *      <th>Service Name</th>
 *      <th>Version</th>
 *    </tr>
 *    <tr>
 *      <td>Configuration Service</td>
 *      <td>1.3.0</td>
 *    </tr>
 *    <tr>
 *    <td>Metadata Service</td>
 *    <td>1.1.0</td>
 *    </tr>
 *    <tr>
 *    <td>Asset Management Service</td>
 *    <td>1.0.0</td>
 *    </tr>
 *  </table>
 *  <br>
 *  <h5>API Role Permission(s) </h5>
 *    <p>Table lists the required API role permissions for creating metadata application page</p>
 *    <br>
 *    <table>
 *      <tr>
 *        <th>Feature</th>
 *        <th>API URL</th>
 *        <th>API Method</th>
 *        <th>API Permission</th>
 *        <th>Required</th>
 *      </tr>
 *      <tr>
 *        <td>View Private Values/td>
 *        <td>/system-settings/</td>
 *        <td>GET</td>
 *        <td>configuration-service.setting.list</td>
 *        <td>Yes</td>
 *      </tr>
 *      <tr>
 *        <td>Edit Private Values</td>
 *        <td>/system-settings/batch</td>
 *        <td>PUT</td>
 *        <td>configuration-service.setting.update.batch</td>
 *        <td>Yes</td>
 *      </tr>
 *      <tr>
 *             <td>Get Metadata Instance</td>
 *             <td>/objects/{id}/instances</td>
 *             <td>GET</td>
 *             <td>metadata-service.metadata-instance.list</td>
 *             <td>Yes</td>
 *      </tr>
 *      <tr>
 *             <td>Update Metadata Instance</td>
 *             <td>/objects/{id}/instances/{id}</td>
 *             <td>PUT</td>
 *             <td>metadata-service.metadata-instance.update</td>
 *             <td>Yes</td>
 *      </tr>
 *      <tr>
 *             <td>Create Signed URL</td>
 *             <td>/signed-url</td>
 *             <td>POST</td>
 *             <td>asset-management-service.signed-url.create</td>
 *             <td>Yes</td>
 *      </tr>
 *    </table>
 *    </section>
 */

const MobileConfigurationComponent = () => {
  let fieldValues: any = {};
  let fieldErrors: any = {};

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);
  const configCatalog = useAppSelector((state) => state.mobileConfiguration.configCatalog);
  const selectedConfig = useAppSelector((state) => state.mobileConfiguration.selectedConfig);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);

  const [loading, setIsLoading] = useState(true);
  const [defaultConfigurations, setDefaultConfigurations] = useState<any>({});
  const [configurations, setConfigurations] = useState<any>({});
  const [mode, setMode] = useState({ initialised: false, value: '', displayName: '' });

  useEffect(() => {
    async function initialise() {
      let pathName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      setMode({
        initialised: true,
        value: ROUTE_MAPPING[pathName],
        displayName: upperFirst(pathName)
      });
    }
    initialise();

    return function reset() {
      dispatch(setDirtyConfiguration({}));
    };
  }, []);

  useEffect(() => {
    if (mode.initialised) {
      setBreadCrumb();
      getMetadataInstance();
    }
  }, [mode]);

  const getMetadataInstance = async () => {
    setIsLoading(true);
    let mobileConfigCatalog = selectedConfig;

    if (isEmpty(mobileConfigCatalog)) {
      await getCatalogConfigData(
        metaDataObjectList,
        dispatch,
        false,
        (data: any, paging: any) => {
          mobileConfigCatalog = data.filter((item: any) => item.category === mode.value)[0]; //TODO: check from route
          dispatch(setSelectedConfig(mobileConfigCatalog));
        },
        (paging: any) => {
          navigate(RouteConstants.ROUTE_MOBILE_CONFIG_LIST);
        }
      );
    }

    const { category: configCategory = {} } = mobileConfigCatalog;
    const groupConfigurations: any = await getConfigurationByCategory(
      metaDataObjectList,
      configCategory
    );
    setConfigurations(groupConfigurations);
    setDefaultConfigurations(groupConfigurations);
    setIsLoading(false);
  };

  const cancelNavigation = () => {
    navigate(RouteConstants.ROUTE_MOBILE_CONFIG_LIST);
  };

  const setBreadCrumb = () => {
    dispatch(
      setURLPaths([
        ...storedURLPaths,
        {
          key:
            mode.value === 'MobileStyleConfig'
              ? RouteConstants.ROUTE_MOBILE_CONFIG_STYLE
              : RouteConstants.ROUTE_MOBILE_CONFIG_CONFIGURATION,
          label: `${t('T_MOBILE_CONFIGURATION_PLATFORM')} > ${t(
            'T_MOBILE_CONFIGURATION_COMPONENTS'
          )} > ${mode.displayName}`
        }
      ])
    );
  };

  const changeFieldValue = async (
    key: string,
    val: any,
    groupId: string = '',
    errors: Array<string> = []
  ) => {
    const { fieldByGroup = {} } = defaultConfigurations;
    let defaultSetting = find(fieldByGroup?.[groupId], { key });
    const { metadata, value, valueConfig } = defaultSetting;
    const { attributes } = metadata;
    const { keyType, displayKeys = [], dataType = '' } = attributes;
    const [displayKey] = displayKeys;
    const privateValue =
      keyType === 'PROTECTED'
        ? !isEmpty(valueConfig)
          ? valueConfig.dataType === AppConstants.DATA_TYPE_JSON
            ? valueConfig.value.value
            : valueConfig.value
          : value
        : {};
    const defaultValue = keyType === 'PUBLIC' ? value : privateValue;

    fieldValues = Object.assign({}, { ...fieldValues }, { [key]: val });
    if (errors && errors.length) {
      fieldErrors = Object.assign({}, { ...fieldErrors }, { [key]: errors });
    } else {
      if (fieldErrors[key]) {
        fieldErrors = Object.assign({}, { ...fieldErrors }, { [key]: errors });
        delete fieldErrors[key];
      }
    }

    const { category } = selectedConfig;

    if (
      Array.isArray(val) && dataType !== 'ORDEREDMULTI'
        ? isEqual(sortBy(val, displayKey), sortBy(defaultValue, displayKey))
        : isEqual(val, defaultValue)
    ) {
      dispatch(deleteDirtyConfiguration({ category, key }));
      delete fieldValues[key];
    } else {
      dispatch(setDirtyConfiguration({ [category]: { fieldErrors, fieldValues } }));
    }
  };

  const bumpVersionStore = (index: number) => {
    let updatedData = cloneDeep(configCatalog);
    updatedData.attributes.version += 1;
    updatedData.attributes.catalog[index].version += 1;
    dispatch(setConfigList(updatedData));
  };

  const savePayload = async () => {
    setIsLoading(true);

    const dirtyKeys = Object.keys(fieldValues);
    const { fieldByGroup = {} } = configurations;

    const fieldItemMap: Map<string, any> = new Map(
      Object.values(fieldByGroup)
        .flatMap((fields: any) => fields)
        .map((field: any) => [field.key, field])
    );

    const dirtyPublicKeys = dirtyKeys.filter((dirtyKey: string) => {
      const fieldItem: any = fieldItemMap.get(dirtyKey);
      const { metadata = {} } = fieldItem;
      const { attributes = {} } = metadata;
      const { keyType } = attributes;
      return keyType !== 'PROTECTED';
    });

    const promiseQueue: Array<any> = new Array<any>();

    const { globalInstances = {} } = configurations;

    if (dirtyPublicKeys.length) {
      const { category } = selectedConfig;
      if (category) {
        const original: any = globalInstances[category];
        const modified = dirtyPublicKeys.reduce((acc: any, elem: string) => {
          const fieldValue = fieldValues[elem];
          acc[elem] = fieldValue || original[elem];
          return acc;
        }, {});
        const payload: any = { ...globalInstances, [category]: { ...original, ...modified } };
        promiseQueue.push(
          updateGCPFile(
            JSON.stringify(payload, null, '\t'),
            AppConstants.GLOBAL_MOBILE_CONFIG_FILE,
            getLoginUserId()
          )
        );
      }
    }

    const protectedPayload: Array<any> = dirtyKeys
      .filter((dirtyKey: string) => {
        const fieldItem: any = fieldItemMap.get(dirtyKey);
        const { metadata = {} } = fieldItem;
        const { attributes = {} } = metadata;
        const { keyType } = attributes;
        return keyType === 'PROTECTED';
      })
      .map((dirtyKey: string) => {
        const value = fieldValues[dirtyKey];
        const fieldItem: any = fieldItemMap.get(dirtyKey);
        const { valueConfig = {} } = fieldItem;
        const { categories = [], dataType } = valueConfig;

        return {
          ...valueConfig,
          value: Array.isArray(value) ? { dataType, value } : value,
          categories: categories.map((category: any) => category.id)
        };
      });

    if (protectedPayload.length) {
      promiseQueue.push(updateMultipleSystemSetting(protectedPayload));
    }
    const responses: Array<any> = await Promise.all(promiseQueue);
    const result: boolean = responses.every((response: any) =>
      [HTTP_STATUS.HTTP_OK, HTTP_STATUS.HTTP_UNAUTHORIZED].includes(response.status)
    );

    if (result) {
      let metaDataUpdatePayload = {
        label: configCatalog.label || configCatalog.attributes?.type,
        attributes: cloneDeep(configCatalog.attributes)
      };

      let catalogIndex = findIndex(metaDataUpdatePayload.attributes.catalog, {
        category: mode.value
      });

      metaDataUpdatePayload.attributes.version += 1;
      metaDataUpdatePayload.attributes.catalog[catalogIndex].version += 1;
      metaDataUpdatePayload.attributes.catalog[catalogIndex].updatedOn =
        moment(new Date(), 'YYYY-MM-DD hh:mm:ss').toISOString().split('.')[0] + 'Z';
      metaDataUpdatePayload.attributes.catalog[catalogIndex].updatedBy = userProfile?.userId;

      const instanceResponse = await updateMetadataInstance(
        configCatalog.metadataObjectId,
        configCatalog.id,
        metaDataUpdatePayload
      );

      if (instanceResponse.status === HTTP_STATUS.HTTP_OK) {
        bumpVersionStore(catalogIndex);

        dispatch(
          setToastData({
            toastMessage: t('T_MOBILE_CONFIGURATION_UPDATED_MSG'),
            isToastActive: true,
            toastType: 'success'
          })
        );
        // fieldValues = Object.assign({});
        // fieldErrors = Object.assign({});
        dispatch(setDirtyConfiguration({}));
        await getMetadataInstance();
      } else {
        dispatch(
          setToastData({
            toastMessage: t('T_ERROR_SOMETHING_WRONG'),
            isToastActive: true,
            toastType: 'error'
          })
        );
      }
    } else {
      dispatch(
        setToastData({
          toastMessage: t('T_ERROR_SOMETHING_WRONG'),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  let pageLayout: number = selectedConfig?.layoutColumn || AppConstants.PAGE_LAYOUT_MONO;
  const query = useQuery();
  if (query.get('layout')) {
    pageLayout = query.get('layout');
  }

  return (
    <LoaderContainer isLoading={loading}>
      <div
        className="flex h-fit w-full flex-row p-5"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <MobileConfigToolbar onSave={savePayload} onCancel={cancelNavigation} />
        {selectedConfig && !isEmpty(configurations) ? (
          <MobileConfigurationDynamicUI
            configurations={configurations}
            pageLayout={pageLayout}
            fieldValues={fieldValues}
            fieldErrors={fieldErrors}
            onValueChange={changeFieldValue}
          />
        ) : (
          ''
        )}
        <div className="w-1/4 pl-4">
          {(selectedConfig?.updatedOn || selectedConfig?.updatedBy) && !isEmpty(configurations) && (
            <div>
              <AuditInfo
                label={'LAST_MODIFIED'}
                date={selectedConfig?.updatedOn || ''}
                user={selectedConfig?.updatedBy || ''}
                isDateFormatted={true}
              />
            </div>
          )}
        </div>
      </div>
    </LoaderContainer>
  );
};

export default MobileConfigurationComponent;
