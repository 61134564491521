import React, { useState } from 'react';
import Checkbox from '../../../shared/Fields/Checkbox';
import RadioButton from '../../../shared/Fields/RadioButton';

interface NumberEntryProps {
  formData: any;
  setFormData: any;
  formError: any;
  editMode?: boolean;
  editFromSurveyTemplate?: boolean;
}
const NumberEntry = ({
  formData,
  setFormData,
  formError,
  editMode,
  editFromSurveyTemplate = false
}: NumberEntryProps) => {
  // const [formData, setFormData] = useState<any>({});
  return (
    <>
      <div className="flex w-full flex-row">
        <div className="self-center">
          <RadioButton
            label="Whole numbers"
            selectedField={formData?.isWholeNumber ? 'Whole numbers' : ''}
            option={'Whole numbers'}
            name={''}
            onClick={() => setFormData({ ...formData, isWholeNumber: true })}
            fontColor={'#262626'}
            disabled={editMode}
          />
        </div>
      </div>
      <div className="mt-3 flex w-full flex-row">
        <div className="self-center">
          <RadioButton
            label="Decimal"
            selectedField={
              typeof formData.isWholeNumber === 'boolean' && !formData?.isWholeNumber
                ? 'Decimal'
                : ''
            }
            option={'Decimal'}
            name={''}
            onClick={() => {
              setFormData({ ...formData, isWholeNumber: false });
            }}
            fontColor={'#262626'}
            disabled={editMode}
          />
        </div>
      </div>
      <div className="mt-6 flex w-full flex-row">
        <div className="ml-0.5 self-center">
          <Checkbox
            checked={formData?.enableUnits ? formData?.enableUnits : false}
            onChange={() => {
              if (typeof formData?.enableUnits === 'boolean') {
                setFormData({ ...formData, enableUnits: !formData.enableUnits });
              } else {
                setFormData({ ...formData, enableUnits: true });
              }
            }}
            disabled={editMode}
          />
        </div>
        <div className="ml-4">Enable units (mg/dL, mmHg)</div>
      </div>
      <div className="mt-3 flex w-full flex-row">
        <div className="ml-0.5 self-center">
          <Checkbox
            checked={formData?.enableSymbols ? formData?.enableSymbols : false}
            onChange={() => {
              if (typeof formData?.enableSymbols === 'boolean') {
                setFormData({ ...formData, enableSymbols: !formData.enableSymbols });
              } else {
                setFormData({ ...formData, enableSymbols: true });
              }
            }}
            disabled={editMode}
          />
        </div>
        <div className="ml-4">Enable comparison symbols ({'>'}, ≥, =)</div>
      </div>
    </>
  );
};

export default NumberEntry;
