// base http client axios instance

import axios, { AxiosInstance } from 'axios';
import env from '../environment';
import { refreshToken } from './authentication-services';
import { refreshTokenRequest } from '../app/authConstants';
import store from '../app/store';
import { logOut } from '../app/loginReducer';
// import { fetchFromCookie, setCookie } from '../util/admin-utils';

class BaseAPIClient {
  isRefreshingtoken = false;
  axiosInstance: AxiosInstance;
  failedQueue: Array<any> = [];
  isFetchingToken = false;

  constructor() {
    const fetchFromCookie = (key: string) => {
      const cookieString = document.cookie;
      const cookies = cookieString.split('; ');

      const cookie = cookies.find((cookie) => cookie.startsWith(`${key}=`));

      if (cookie) {
        return cookie.split('=')[1];
      }

      return null;
    };

    const setCookie = (key: string, value: string) => {
      document.cookie = `${key}=${encodeURIComponent(value)}; path=/;`;
    };
    this.axiosInstance = axios.create({
      baseURL: `${env.api}${env.namespace}`,
      headers: {
        apikey: env.apikey
        // 'X-Organization': env.orgId
      },
      maxRedirects: 0
    });

    this.axiosInstance.interceptors.request.use(
      function (config: any) {
        if (
          localStorage.getItem('access-token') !== '' &&
          !config?.url?.includes('authentication-service')
        ) {
          // config.headers['Authorization'] = `Bearer ${localStorage.getItem('access-token')}`;
          config.headers['Authorization'] = `Bearer ${fetchFromCookie('access-token')}`;
        }
        return config;
      },
      (error: any) => {
        return error;
      }
    );

    const processQueue = (access_token: any) => {
      this.failedQueue.forEach((errorRequestInstance) => {
        errorRequestInstance.resolve(access_token);
      });

      this.failedQueue = [];
    };

    this.axiosInstance.interceptors.response.use(
      async (response: any) => {
        return response;
      },
      async (error: any) => {
        const that = this;
        const originalRequest = error.config;

        if (this.isRefreshingtoken && error?.response?.status === 401) {
          return new Promise(function (resolve, reject) {
            that.failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest._queued = true;
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return this.axiosInstance
                .request({
                  ...originalRequest,
                  headers: { ...originalRequest.headers, Authorization: `Bearer ${token}` }
                })
                .catch(() => {
                  sessionStorage.setItem('loggedIn', 'false');
                  return Promise.reject(error);
                });
            })
            .catch((err) => {
              sessionStorage.setItem('loggedIn', 'false');
              return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
            });
        }

        if (
          error?.response?.status === 401 &&
          !error?.response?.config?.url?.includes('authentication-service') &&
          !this.isRefreshingtoken
        ) {
          this.isFetchingToken = true;
          this.isRefreshingtoken = true;
          // const refreshTokenString = localStorage.getItem('refresh-token');
          const refreshTokenString = fetchFromCookie('refresh-token');
          refreshTokenRequest.set('refresh_token', refreshTokenString || '');
          const refreshResponse = await refreshToken(refreshTokenRequest)
            .finally(() => {
              this.isRefreshingtoken = false;
              this.isFetchingToken = false;
            })
            .catch((err) => {
              store.dispatch(logOut());
              return err;
            });

          const { data: refreshData } = refreshResponse;
          const { access_token, refresh_token, issues = [] } = refreshData;
          // localStorage.setItem('access-token', access_token);
          // localStorage.setItem('refresh-token', refresh_token);
          setCookie('access-token', access_token);
          setCookie('refresh-token', refresh_token);
          if (issues.length) {
            store.dispatch(logOut());
          } else {
            processQueue(access_token);
            return this.axiosInstance
              .request({
                ...originalRequest,
                headers: { ...originalRequest.headers, Authorization: `Bearer ${access_token}` }
              })
              .catch(() => {
                sessionStorage.setItem('loggedIn', 'false');
              });
          }
          return error.response;
        } else {
          return error.response;
        }
      }
    );
  }
}

export default new BaseAPIClient();
