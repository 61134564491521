import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import InputField from '../shared/Fields/InputField';
import PhoneField from '../shared/Fields/PhoneField';
import NonEditableFields from '../shared/Fields/NonEditableFields';
import SelectField from '../shared/Fields/SelectField';
import { useParams } from 'react-router-dom';
import TextAreaField from '../shared/Fields/TextAreaField';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import PillsButton from '../shared/Fields/PillsButton';
import CountryState from '../shared/Fields/CountryState';
import { find } from 'lodash';
interface APIFormData {
  key: string;
  id: string;
  type: string;
  tag: string;
  label: string;
  placeholder: string;
  required: boolean;
  width: string;
  colSpan: number;
  seq: number;
  dynamicDisplayField?: Function;
  getCurrentSelection?: Function;
  options?: Array<any>;
}

interface Props {
  dynamicFormData: APIFormData[];
  formData: any; //NewOrganizationSampleData
  setFormData: Function;
  formError: any; //NewOrganizationSampleData
  layout: number;
  editable: boolean;
  asterixFirst?: boolean;
  disabled?: boolean;
  customSelectData?: any;
  fetchMoreItemsList?: any;
  setFormError?: Function;
  rootProps?: any;
}

function DynamicFormFieldRenderer({
  layout,
  dynamicFormData,
  formData,
  setFormData,
  formError,
  editable,
  asterixFirst,
  disabled,
  customSelectData,
  rootProps
}: Props) {
  const { t } = useTranslation();

  const params = useParams();
  const isDisabled = (inputObject: any) => {
    if (inputObject.key === 'state' && formData['country'] === '') {
      return true;
    } else if (
      inputObject.key === 'city' &&
      (formData['country'] === '' || formData['state'] === '')
    ) {
      return true;
    } else if (params?.id && inputObject.key === 'type') {
      return false;
    } else {
      return false;
    }
  };
  const renderField: any = (inputObject: any) => {
    switch (inputObject.tag) {
      case 'input':
        return (
          <InputField
            fieldKey={inputObject.key}
            type={inputObject.type}
            id={inputObject.id}
            label={inputObject.label}
            className={inputObject.className}
            placeholder={inputObject.placeholder}
            overrideValue={inputObject.overrideValue}
            required={inputObject.required}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            asterixFirst={asterixFirst}
            disabled={disabled || inputObject.disabled}
            min={inputObject?.min || 0}
            max={inputObject?.max || 0}
            allowSplChar={inputObject?.allowSplChar || false}
            validateField={
              typeof inputObject?.validateField === 'boolean' ? inputObject?.validateField : true
            }
          />
        );
        break;
      case 'picklist':
        return (
          <PickList
            label={inputObject.label}
            fieldKey={inputObject.key}
            required={inputObject.required || false}
            fieldValues={inputObject.fieldValues}
            selectedItems={
              inputObject.customSelectedItems
                ? rootProps[inputObject.customSelectedItems]
                : inputObject.selectOptions
            }
            items={
              inputObject.customOptions ? rootProps[inputObject.customOptions] : inputObject.options
            }
            disabled={disabled || inputObject.disabled}
            readOnly={inputObject.readOnly || false}
            type={inputObject.type !== 'single' ? PICKLIST_TYPES.MULTI : PICKLIST_TYPES.SINGLE}
            onSelectedChange={
              inputObject.onSelectedChange ? rootProps[inputObject.onSelectedChange] : () => {}
            }
            fetchMoreData={
              inputObject.fetchMoreData ? rootProps[inputObject.fetchMoreData] : () => {}
            }
            searchData={inputObject.searchData ? rootProps[inputObject.searchData] : () => {}}
            searchable={inputObject.searchable}
            searchPlaceholder={t(inputObject.searchPlaceholder)}
            hasMore={inputObject.customHasMore ? rootProps[inputObject.customHasMore] : false}
            onlySearch={inputObject.onlySearch}
          />
        );
        break;
      case 'phone':
        return (
          <PhoneField
            fieldKey={inputObject.key}
            codeFieldKey={inputObject.countryCode}
            type={inputObject.type}
            id={inputObject.id}
            label={inputObject.label}
            placeholder={inputObject.placeholder}
            required={inputObject.required}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            asterixFirst={asterixFirst}
            disabled={disabled || inputObject.disabled}
            codeDisabled={inputObject.codeDisabled}
            min={inputObject?.min || 0}
            max={inputObject?.max || 0}
            validateField={
              typeof inputObject?.validateField === 'boolean' ? inputObject?.validateField : true
            }
          />
        );
      case 'select':
        return (
          <SelectField
            fieldKey={inputObject.key}
            objectKey={inputObject.objectKey}
            type={inputObject.type}
            id={inputObject.id}
            label={inputObject.label}
            placeholder={inputObject.placeholder}
            required={inputObject.required}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            options={customSelectData ? customSelectData : inputObject.options}
            disabled={isDisabled(inputObject) || disabled || inputObject.disabled}
          />
        );
      case 'textarea':
        return (
          <TextAreaField
            fieldKey={inputObject.key}
            type={inputObject.type}
            id={inputObject.id}
            label={inputObject.label}
            placeholder={inputObject.placeholder}
            secondaryLabel={inputObject.secondaryLabel}
            required={inputObject.required}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            asterixFirst={asterixFirst}
            rows={inputObject.rows}
            disabled={disabled}
            maxLength={inputObject?.max}
          ></TextAreaField>
        );

      case 'countryState':
        const { options } = inputObject;
        return options.length ? (
          <CountryState
            fieldKey={inputObject.key}
            objectKey={inputObject.objectKey}
            type={inputObject.type}
            id={inputObject.id}
            label={inputObject.label}
            placeholder={inputObject.placeholder}
            required={inputObject.required}
            formData={formData}
            setFormData={setFormData}
            dynamicDisplayField={inputObject.dynamicDisplayField}
            getCurrentSelection={inputObject.getCurrentSelection}
            formError={formError}
            options={options}
            disabled={isDisabled(inputObject) || disabled || inputObject.disabled}
          />
        ) : (
          <InputField
            fieldKey={inputObject.key}
            type={inputObject.type}
            id={inputObject.id}
            label={inputObject.label}
            placeholder={inputObject.placeholder}
            required={inputObject.required}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            asterixFirst={asterixFirst}
            disabled={disabled || inputObject.disabled}
            min={inputObject?.min || 0}
            max={inputObject?.max || 0}
            validateField={
              typeof inputObject?.validateField === 'boolean' ? inputObject?.validateField : true
            }
          />
        );
    }
  };
  return (
    <>
      {dynamicFormData
        .sort((firstObject, secondObject) => firstObject.seq - secondObject.seq)
        .map((inputObject, index) => {
          return (
            <FormGroup
              key={index}
              className={
                layout === 1
                  ? 'my-3 flex w-full flex-col px-1'
                  : layout === 2
                  ? inputObject.colSpan === 1
                    ? 'my-3 flex w-1/2 flex-col px-1'
                    : 'my-3 flex w-full flex-col px-1'
                  : layout === 3
                  ? inputObject.colSpan === 1
                    ? 'my-3 flex w-1/3 flex-col px-1'
                    : inputObject.colSpan === 2
                    ? 'my-3 flex w-2/3 flex-col px-1'
                    : inputObject.colSpan === 3
                    ? 'my-3 flex w-full flex-col px-1'
                    : ''
                  : layout === 4
                  ? inputObject.colSpan === 1
                    ? 'my-3 flex w-1/6 flex-col px-1'
                    : inputObject.colSpan === 2
                    ? 'my-3 flex w-1/4 flex-col px-1'
                    : inputObject.colSpan === 3
                    ? 'my-3 flex w-1/3 flex-col px-1'
                    : inputObject.colSpan === 4
                    ? 'my-3 flex w-2/3 flex-col px-1'
                    : inputObject.colSpan === 5
                    ? 'my-3 flex w-1/2 flex-col px-1'
                    : inputObject.colSpan === 6
                    ? 'my-3 flex w-full flex-col px-1'
                    : ''
                  : ''
              }
            >
              {editable ? (
                renderField(inputObject)
              ) : inputObject.tag === 'phone' ? (
                renderField(inputObject)
              ) : (
                <>
                  <NonEditableFields
                    fieldKey={inputObject.key}
                    label={inputObject.label}
                    required={inputObject.required}
                    formData={formData}
                    dynamicDisplayField={(value: string) => {
                      if (inputObject.dynamicDisplayField) {
                        let element = find(inputObject.options, function (item: any) {
                          return item.code === value || item.name === value;
                        });
                        return inputObject.dynamicDisplayField(element?.code, element?.name);
                      } else {
                        return '';
                      }
                    }}
                  />
                </>
              )}
            </FormGroup>
          );
        })}
    </>
  );
}

const FormGroup = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

export default DynamicFormFieldRenderer;
