import { createSlice } from '@reduxjs/toolkit';

export const consentSlice = createSlice({
  name: 'consent',
  initialState: {
    fieldGroup: {} as any,
    template: {} as any,
    defaultValueSettings: [] as any
  },
  reducers: {
    setFieldGroup: (state, action) => {
      state.fieldGroup = action.payload;
    },
    setConsentTemplate: (state, action) => {
      state.template = action.payload;
    },
    setDefaultValueSettings: (state, action) => {
      state.defaultValueSettings = action.payload;
    }
  }
});

export const { setFieldGroup, setConsentTemplate, setDefaultValueSettings } = consentSlice.actions;

export default consentSlice.reducer;
