import { isEmpty } from 'lodash';
import moment from 'moment';
import { AppConstants } from '../constants/AppConstants';
import { SwitchContext, switchSessionContext } from '../http/authentication-services';
import { HTTP_STATUS } from '../http/constants/http.status';
import { getUsers } from '../http/user-management-services';
import { SystemSetting } from './RoleUtil';

import {
  createConsentAssignment,
  getConsentAssignByGiverId,
  listConsentTemplates,
  listFieldGropus,
  listRoles
} from '../http/access-management-service';
import { filter, zipObject, keys } from 'lodash';

export const fetchFromCookie = (key: string) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');

  const cookie = cookies.find((cookie) => cookie.startsWith(`${key}=`));

  if (cookie) {
    return cookie.split('=')[1];
  }

  return null;
};


export const getSessionContext = (): SwitchContext | null => {
  let tokenId: string | null = fetchFromCookie('token-id'); //localStorage.getItem('token-id');
  if (!tokenId) return null;
  const userProfile = JSON.parse(localStorage.getItem('user-profile') || '{}');
  const { roles = [], organization = [] } = userProfile;

  const loggedInRoles: Array<any> = roles.filter((rol: any) => rol.loggedIn);
  const loggedInOrgs: Array<any> = organization.filter((org: any) => org.loggedIn);
  if (!loggedInOrgs.length || !loggedInRoles.length) return null;

  const [loggedInRole] = loggedInRoles;
  const [loggedInOrg] = loggedInOrgs;

  const { roleId: userRole } = loggedInRole;
  const { organizationId: userOrg } = loggedInOrg;

  return { userOrg, userRole, tokenId };
};

export const setCookie = (key: string, value: string) => {
  document.cookie = `${key}=${encodeURIComponent(value)}; path=/;`;
};

export const switchSession = (userOrg: string): Promise<any> => {
  const sourceContext: SwitchContext | null = getSessionContext();
  const { tokenId, userRole }: any = sourceContext;
  const targetContext: SwitchContext = {
    userOrg,
    userRole,
    tokenId
  };
  // Session context switch to newly created organization
  return switchSessionContext(targetContext);
};

export const getLoginUserName = () => {
  return getUserName(JSON.parse(localStorage.getItem('user-profile') || '{}'));
};

export const getLoginUserRoles = () => {
  return getUserRoles(JSON.parse(localStorage.getItem('user-profile') || '{}'));
};

export const getLoginUserId = () => {
  return getUserId(JSON.parse(localStorage.getItem('user-profile') || '{}'));
};

export const getLoggedInOrgName = () => {
  return getLoggedOrgName(JSON.parse(localStorage.getItem('user-profile') || '{}'));
};

export const getUserName = (userProfile: any) => {
  let profile: string = '';
  if (userProfile) {
    const { name = [], firstName, lastName, userName, mail } = userProfile;
    if (name.length) {
      const { text, given, family } = name[0];
      profile = text ? text : given && family ? `${given} ${family}` : '';
    }

    if (!profile) {
      profile = firstName && lastName ? `${firstName} ${lastName}` : userName || mail || '';
    }
  }
  return profile;
};

export const getUserRoles = (userProfile: any) => {
  let userRoles: Array<any> = [];
  if (!userProfile || !Object.keys(userProfile).length) {
    return userRoles;
  }
  const { roles = [] } = userProfile;
  userRoles = [...roles];
  return userRoles;
};

export const getUserId = (userProfile: any) => {
  const { userId } = userProfile;
  return userId;
};

export const getLoggedInOrg = (userProfile?: any) => {
  let loggedInOrg;
  let profile = userProfile || JSON.parse(localStorage.getItem('user-profile') || '{}');
  if (!profile) {
    return loggedInOrg;
  }
  const { organization = [] } = profile;
  [loggedInOrg] = organization.filter((e: any) => e.loggedIn);
  return loggedInOrg;
};

export const getLoggedOrgName = (userProfile: any) => {
  let data: string = '';
  if (!userProfile || !Object.keys(userProfile).length) {
    return data;
  }
  const { loggedInOrgName } = userProfile;

  return loggedInOrgName ? loggedInOrgName : '';
};

export const isInviteExpired = (adminData: any) => {
  let flag = false;
  const adminStatus = adminData.accountStatus || adminData.status;
  const adminInviteExpiration = adminData.verificationExpirationDTM || adminData.expirationDate
  if ((adminStatus === 'invited' || adminStatus === 'reinvited')) {
    let expireDateTime = new Date(adminInviteExpiration);
    let currentDateTime = new Date();

    if (expireDateTime < currentDateTime) {
      flag = true;
    }
  }

  return flag;
};

export const getUMSInvitationRoles = (umsInvitationPolicy: SystemSetting, userProfile: any) => {
  let roles = [];
  let LoginRole = getUserRoles(userProfile);
  let defaultRole = LoginRole[0]?.name;
  if (defaultRole) {
    roles = umsInvitationPolicy?.value[defaultRole] || [];
  }

  // Removes duplicate roles if any.
  if (roles.length) {
    roles = Array.from(new Set(roles));
  }
  return roles.filter((role: string) => !AppConstants.BI_INTERNAL_ROLES.includes(role));
};

export const getUserProfiles = async (userIds: Array<string> = []) => {
  let userProfiles: Array<any> = new Array();
  const userFilter = `[{"field":"userId","operation":"in","value":"${userIds.join()}"}]`;
  const userResponse = await getUsers(
    userFilter,
    AppConstants.DEFAULT_PAGE,
    AppConstants.MAXIMUM_PAGE_SIZE
  );
  const { status, data: responseData } = userResponse;
  if (status === HTTP_STATUS.HTTP_OK) {
    const { data: userList = [] } = responseData;
    userProfiles = [...userList];
  }
  return userProfiles;
};

export const userFilterQuery = (
  name: string,
  organizationIds: Array<string> = [],
  roles: Array<string> = []
) => {
  const userQuery: Array<string> = new Array<string>();
  if (organizationIds.length) {
    const ORG_FILTER: string = `{"field":"organization/organizationId","operation":"in","value":"${organizationIds.join()}"}`;
    userQuery.push(ORG_FILTER);
  }

  if (roles.length) {
    const ROLE_FILTER: string = `{"field":"roles/name","operation":"in","value":"${roles.join()}"}`;
    userQuery.push(ROLE_FILTER);
  } else {
    const NO_ROLE_FILTER: string = `{"field":"roles/name","operation":"in","value":"${AppConstants.BI_INTERNAL_ROLES.join()}", "notOperation":true}`;
    userQuery.push(NO_ROLE_FILTER);
  }

  if (!isEmpty(name)) {
    const NAME_FILTER: string = `{"field":"firstName","operation":"co","value":"${name}"}`;
    userQuery.push(NAME_FILTER);
  }
  return `[${userQuery.join()}]`;
};

export const getBase64 = (file: File): Promise<any> =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const getConsentRoleList = (invitationPolicy: any, loggedInRole: any) => {
    const {value: invitationRoles} = invitationPolicy;
    const consentRolesList: any = [];

    Object.keys(invitationRoles).forEach((role: any) => {
      if(invitationRoles[role].includes(loggedInRole)) {
        consentRolesList.push(role)
      }
    })
    return consentRolesList;
  }

  const getConsentRoleListWithId = async (consentRoleList: any) => {
    let consentRoleListNameObj:any = {}
    let consentRolesWithId:any = {}

    consentRoleList.forEach((roleName: any) => {
      consentRoleListNameObj[roleName] = listRoles(roleName,true, 1, 1000)
    })
  
    const loadedResources =  zipObject(keys(consentRoleListNameObj), await Promise.all(Object.values(consentRoleListNameObj)));

    for(let k in loadedResources) {
      const response:any = loadedResources[k];
      if (response.status === HTTP_STATUS.HTTP_OK) {
        const {data} = response;
        const {data:roleObjArr} = data;
        const roleNameId = filter(roleObjArr, {name: k});
        if(roleNameId.length ) {
          consentRolesWithId[k] = roleNameId[0].id;
        }
        
      }
    }

    return consentRolesWithId

  }

  export const checkUserProfileConsent = async (userConsent: any, invitationPolicy: any, userRoles: any) => {
    let consentForRoles:any = [];
    const { data } = userConsent;
    const { data: consentList } = data;
    const [loggedInUser] = userRoles;
 
    const consentRolesList = getConsentRoleList(invitationPolicy, loggedInUser.name)
    
    const userProfileConsentList = filter(consentList, (consentObj: any) => {
      return (consentObj?.consentTemplate?.name === AppConstants.USER_PROFILE_CONSENT_TEMPLATE_NAME 
        && consentObj?.receiverType === AppConstants.USER_PROFILE_CONSENT_RECEIVER_TYPE);
    });
    
    if(userProfileConsentList.length) {
      const consentRolesWithId = await getConsentRoleListWithId(consentRolesList);
       Object.values(consentRolesWithId).forEach((roleId:any) => {
        const consentOfRole = filter(userProfileConsentList, {'receiverId': roleId})
        if(!consentOfRole.length) {
          consentForRoles.push(roleId) ;
        }
      })
    } else {
      const consentRolesWithId = await getConsentRoleListWithId(consentRolesList);
      consentForRoles = Object.values(consentRolesWithId)
    }

    return {
      isImplicitUserConsentAdded: consentForRoles.length,
      consentForRoles: consentForRoles
    };
  };

  export const getCreatedUserRoleId = async (createdBy: any) => {
    let createdUserProfileData;
    const createdUser: Array<any> = await getUserProfiles([createdBy]);
    if (createdUser.length) {
      [createdUserProfileData] = createdUser;
    }
    
    const [createdUserProfileRole] =createdUserProfileData?.roles || []
    return [createdUserProfileRole.roleId]
    
  }

  const getConsentTemplate = async () => {
    let userProfileTemplateId = '';
    const templateResponse = await listConsentTemplates(
      AppConstants.MAXIMUM_PAGE_SIZE,
      AppConstants.DEFAULT_PAGE,
      AppConstants.USER_PROFILE_CONSENT_TEMPLATE_NAME
    );

    if (templateResponse.status === HTTP_STATUS.HTTP_OK) {
      const { data = {}, status } = templateResponse;
      const { data: templateDetails = [] } = data;
      const userProfileConsentTemplate = filter(templateDetails, {name: AppConstants.USER_PROFILE_CONSENT_TEMPLATE_NAME});
      if(userProfileConsentTemplate.length ) {
        userProfileTemplateId = userProfileConsentTemplate[0].id;
      }
    }

    return userProfileTemplateId;
  };

  const getUserProfileFieldGroup = async () => {
    let fieldGroupId = '';

    const fieldGroupResponse = await listFieldGropus(
      AppConstants.MAXIMUM_PAGE_SIZE,
      AppConstants.DEFAULT_PAGE,
      AppConstants.USER_PROFILE_FIELD_GROUP_NAME
    );

    if (fieldGroupResponse.status === HTTP_STATUS.HTTP_OK) {
      const { data = {}, status } = fieldGroupResponse;
      const { data: fieldGroupList = [] } = data;
      const userProfileFieldGroup = filter(fieldGroupList, {name: AppConstants.USER_PROFILE_FIELD_GROUP_NAME});
      if(userProfileFieldGroup.length ) {
        fieldGroupId = userProfileFieldGroup[0].id;
      }
    }

    return fieldGroupId;
  };

  export const createConsentAssignForLoggedUser = async (consentForRoles: any, userProfile: any) => {
    let consentForRolesPromArr: any = [];

    const userProfileConsentTemplateId = await getConsentTemplate();
    if(!userProfileConsentTemplateId) {
      return;
    }
    const userProfileFieldGroupId = await getUserProfileFieldGroup();
    if(!userProfileFieldGroupId) {
      return;
    }

    const loggedInOrg = getLoggedInOrg(userProfile);

    const { userId } = userProfile;

    consentForRoles.forEach((roleId: any) => {
      const payLoad = {
        consentTemplateId: userProfileConsentTemplateId,
        giverType: AppConstants.USER_PROFILE_CONSENT_GIVER_TYPE,
        giverId: userId,
        receiverType: AppConstants.USER_PROFILE_CONSENT_RECEIVER_TYPE,
        receiverId: roleId,
        organizationId: loggedInOrg?.organizationId,
        startDate: moment()?.toISOString()?.split('.')[0] + 'Z',
        verified: true,
        fieldGroups: [
          {
            resourceId: 'ALL',
            fieldGroupId: userProfileFieldGroupId
          }
        ]
      };
      consentForRolesPromArr.push(createConsentAssignment(payLoad));
    });

    if (consentForRolesPromArr.length) {
      await Promise.all(consentForRolesPromArr).then((assignmentObj: any) => {
        assignmentObj.forEach((assignment: any, index: any) => {
          if (assignment.status === HTTP_STATUS.HTTP_CREATED) {
            console.log('Assignment created');
          }
        });
      });
    }
  };
