import {
  getChildOrganizations,
  getOrganization,
  getOrganizations
} from '../../http/organization-services';
import { find, filter, isEmpty, chain } from 'lodash';

import { HTTP_STATUS } from '../../http/constants/http.status';
import { AppConstants } from '../../constants/AppConstants';
import { getCareTeams, getConditions, getEncounterList } from '../../http/careplan-services';
import { userFilterQuery } from '../../util/admin-utils';
import { getUsers } from '../../http/user-management-services';
import { getDeviceList } from '../../http/device-services';
import { listRoles } from '../../http/access-management-service';

export const getHashObj = (array: any) => {
  return Object.assign({}, ...array.map((obj: any) => ({ [obj.id]: obj })));
};

export const getConditionsFromCode = (codingObj: any = {}, id: any) => {
  if (codingObj) {
    const { coding = {} } = codingObj;
    return coding.map((cond: any) => {
      return { key: id, value: cond.display };
    });
  }
  return [];
};

export const getConditionsList = (conditions: any) => {
  let conditionsList: any = [];
  conditions?.forEach((condition: any) => {
    const { code = {}, id } = condition;
    conditionsList = [...conditionsList, ...getConditionsFromCode(code, id)];
  });
  return conditionsList.filter((item: any) => item.value);
};

export const getCarePlanConditions = async () => {
  const queryOptions = { size: AppConstants.MAXIMUM_PAGE_SIZE };
  try {
    const conditionResponse = await getConditions(queryOptions);

    const { status, data: conditionsData } = conditionResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: conditions = [], paging } = conditionsData;
      if (conditions.length) {
        return conditions;
      }
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const getOrgList = (orgResponse: any) => {
  const { data: orgList } = orgResponse;
  return orgResponse;
};

export const getFilterCareTeamsList = (careTeams: any) => {
  let careTeamsList: any = [];
  careTeams?.forEach((careTeam: any) => {
    const { name = '', subjectType = '' } = careTeam;
    careTeamsList.push({ ...careTeam, displayVal: `${name} | ${subjectType}` });
  });
  return careTeamsList;
};

export const getConditionsById = (condIdArr: any, conditions: any) => {
  let condArr: any = [];
  const hashConditions = getHashObj(conditions);
  if (condIdArr && condIdArr.length) {
    condIdArr.forEach((conditionsId: any) => {
      const selectedConditions = hashConditions[conditionsId];
      if (selectedConditions) {
        const { code, id } = selectedConditions;
        condArr = getConditionsFromCode(code, id);
      }
    });
  }

  return condArr;
};

export const getCareTeamById = (careTeamIdArr: any, careTeams: any) => {
  let careTeamArr: any = [];
  const hashCareTeams = getHashObj(careTeams);
  if (careTeamIdArr && careTeamIdArr.length) {
    careTeamIdArr.forEach((careTeamId: any) => {
      const selectedcareTeam = hashCareTeams[careTeamId];
      if (selectedcareTeam) {
        careTeamArr.push(selectedcareTeam.name);
      }
    });
  }

  return careTeamArr;
};

export const filterCareTeams = (careTeams: any) => {
  return chain(careTeams)
    .filter((item) => item.subjectId !== null)
    .uniqWith((item1, item2) => item1.name === item2.name && item1.subjectId === item2.subjectId)
    .value();
};

export const filterEncounters = (encounters: any) => {
  return chain(encounters)
    .filter((item) => item.subjectId !== null)
    .uniqWith(
      (item1, item2) =>
        item1?.class?.display === item2?.class?.display && item1.subjectId === item2.subjectId
    )
    .value();
};

export const getCareTeamsList = async () => {
  try {
    const queryOptions = { size: AppConstants.MAXIMUM_PAGE_SIZE };
    const careTeamResponse = await getCareTeams(queryOptions);

    const { status, data: careTeamsData } = careTeamResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: careTeams = [], paging } = careTeamsData;
      if (careTeams.length) {
        return filterCareTeams(careTeams);
      }
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const getEncounterListData = async () => {
  const filterData = (list: Array<any>) => {
    return list;
  };
  try {
    const queryOptions = { size: AppConstants.MAXIMUM_PAGE_SIZE };
    const encounterDataResponse = await getEncounterList(queryOptions);
    const { status, data: encounterData } = encounterDataResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: encounters = [], paging } = encounterData;
      if (encounters.length) {
        return filterData(encounters);
      } else {
        return [];
      }
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const getEncounterListItems = (encounterListData: Array<any>) => {
  let encounterItems: any = [];
  encounterListData.forEach((encounter: any) => {
    encounterItems.push({ key: encounter.id, value: encounter?.class?.display || encounter.id });
  });
  return encounterItems;
};

export const fetchPatientRoleUserList = async () => {
  try {
    const filters = userFilterQuery('', [], [AppConstants.CAREPLAN_USER_PATIENT_ROLE_TYPE]);
    const response: any = await getUsers(
      filters,
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE,
      `${AppConstants.DEFAULT_SORT_BY},${AppConstants.DEFAULT_SORT_DESC}`
    );
    const { status, data: usersResponse } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: userList = [], paging } = usersResponse;
      if (userList && !userList.length) {
        return [];
      } else {
        return userList;
      }
    } else {
      return [];
    }
  } catch (e: any) {
    return [];
  }
};

export const fetchPractitionerRoleUserList = async () => {
  try {
    const filters = userFilterQuery('', [], [AppConstants.CAREPLAN_USER_PRACTITIONER_ROLE_TYPE]);
    const response: any = await getUsers(
      filters,
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE,
      `${AppConstants.DEFAULT_SORT_BY},${AppConstants.DEFAULT_SORT_DESC}`
    );
    const { status, data: usersResponse } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: userList = [], paging } = usersResponse;
      if (userList && !userList.length) {
        return [];
      } else {
        return userList;
      }
    } else {
      return [];
    }
  } catch (e: any) {
    return [];
  }
};

export const fetchDeviceList = async () => {
  try {
    const response: any = await getDeviceList(
      AppConstants.MAXIMUM_PAGE_SIZE,
      AppConstants.DEFAULT_PAGE,
      '',
      `${AppConstants.DEFAULT_SORT_BY},${AppConstants.DEFAULT_SORT_DESC}`
    );
    const { status, data: deviceResponse } = response;
    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: deviceList = [], paging } = deviceResponse;
      if (deviceList && !deviceList.length) {
        return [];
      } else {
        return deviceList;
      }
    } else {
      return [];
    }
  } catch (e: any) {
    return [];
  }
};

export const getCarePlanOrganizations = async () => {
  try {
    const orgResponse = await getOrganizations(
      '',
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE,
      `${AppConstants.QUERY_PARAM_NAME},${AppConstants.DEFAULT_SORT_ASC}`
    );

    const { status, data: orgData } = orgResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: organizations = [], paging } = orgData;
      if (organizations.length) {
        return organizations;
      }
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const fetchPractitionerRole = async () => {
  try {
    const roleResponse = await listRoles(
      AppConstants.CAREPLAN_USER_PRACTITIONER_ROLE_TYPE,
      true,
      AppConstants.DEFAULT_PAGE,
      AppConstants.MAXIMUM_PAGE_SIZE,
      ``
    );

    const { status, data: orgData } = roleResponse;
    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: role = [], paging } = orgData;
      if (role.length) {
        return role;
      }
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const getCarePlanOrgList = (orgIdArr: any, orgList: any) => {
  let orgNameArr: any = [];
  const hashOrgList = getHashObj(orgList);
  if (orgIdArr && orgIdArr.length) {
    orgIdArr.forEach((orgId: any) => {
      const orgNameObj = hashOrgList[orgId];
      if (orgNameObj) {
        orgNameArr.push(orgNameObj.name);
      }
    });
  }

  return orgNameArr;
};

export const mergeConditionsCareTeams = (
  carePlans: any,
  conditions: any,
  careTeams: any,
  orgList: any
) => {
  const updatedCarePlans: any = [];
  carePlans.forEach((carePlan: any) => {
    const { conditionIds, careteamIds, organizationIds } = carePlan;
    carePlan['conditions'] = getConditionsById(conditionIds, conditions)
      .map((obj: any) => obj.value)
      .join(',');
    carePlan['careTeams'] = getCareTeamById(careteamIds, careTeams).join(',');
    carePlan['orgList'] = getCarePlanOrgList(organizationIds, orgList).join(',');
    updatedCarePlans.push(carePlan);
  });

  return updatedCarePlans;
};

export const carePlanFilterQuery = (filters: any, query: any) => {
  if (!isEmpty(filters)) {
    const filtersList = Object.keys(filters);
    filtersList.forEach((filterKey: any) => {
      switch (filterKey) {
        case 'orgFilter':
          if (filters['orgFilter'].length) {
            const orgData = filters['orgFilter'];
            if (orgData.length) {
              query += `&organization-id=${orgData.map((obj: any) => obj.id)}`;
            }
          }
          break;
        case 'status':
          const status = filters['status'];
          if (status.length) {
            //query += `&status=${status.key}`;
            query += `&status=${status.map((obj: any) => obj.key)}`;
          }
          break;
        case 'conditions':
          const conditions = filters['conditions'];
          if (conditions.length) {
            query += `&condition-id=${conditions.map((obj: any) => obj.key)}`;
          }
          break;
        case 'careTeamFilter':
          const careTeams = filters['careTeamFilter'];
          if (careTeams.length) {
            query += `&careteam-id=${careTeams.map((obj: any) => obj.id)}`;
          }
          break;
        case 'intentFilter':
          const intentFilter = filters['intentFilter'];
          if (intentFilter && intentFilter.length) {
            query += `&intent=${intentFilter.map((obj: any) => obj.key)}`;
          }
          break;
        case 'titleFilter':
          const titleFilter = filters['titleFilter'];
          if (titleFilter && titleFilter.length) {
            query += `&title=${titleFilter}`;
          }
          break;
      }
    });
  }
  return query;
};

export const getOrgForCarePlans = async (
  loadNextData: any,
  filterList: any,
  userProfileInfo: any
) => {
  const { searchOrgName } = loadNextData;
  const { paging } = find(filterList, { id: 'orgFilter' });
  const { size, page } = paging;
  let response;
  response = await getOrganizations(searchOrgName ? searchOrgName : '', page, size, `name,asc`);

  const { status, data: organizationResp } = response;
  if (status == HTTP_STATUS.HTTP_OK) {
    const { data, paging } = organizationResp;
    return data;
  }
};

export const filterOrgNames = (keyword: string, orgList: any) => {
  return orgList.filter((org: any) => org.name.toLowerCase().includes(keyword.toLowerCase()));
};

export const filterCareTeamNames = (keyword: string, careTeams: any) => {
  return careTeams.filter(
    (team: any) => team && team.name && team.name.toLowerCase().includes(keyword.toLowerCase())
  );
};

export const filterConditionNames = (keyword: string, conditions: any) => {
  return conditions.filter((cond: any) => cond.key.toLowerCase().includes(keyword.toLowerCase()));
};
