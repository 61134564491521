import React from 'react';
import Modal from '../shared/ModalPortal/Modal';
import { useTranslation } from 'react-i18next';
import FileMetaData from './FileMetaData';

interface FileMetaDataModalProps {
  content: any;
  disabled?: boolean;
  setContent: Function;
  metaDataEntries: Array<any>;

  saveFileMetaData?: Function;
  addItemFunction?: Function;
  removeItemFunction?: Function;
  updateItemFunction?: Function;
  hasContentChanged?: Function;
}

const FileMetaDataModal = ({
  content,
  setContent,
  disabled = false,
  addItemFunction,
  saveFileMetaData,
  hasContentChanged,
  removeItemFunction,
  updateItemFunction,
  metaDataEntries = []
}: FileMetaDataModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isVisible={!!content}
      headerText={t('T_FILE_METADATA')}
      showCloseIcon={true}
      toggleVisiblity={setContent}
      width="65rem"
      body={
        <FileMetaData
          {...content}
          disabled={disabled}
          metadataEntries={metaDataEntries}
          addItemFunction={addItemFunction}
          deleteFunction={removeItemFunction}
          updateFunction={updateItemFunction}
        />
      }
      // secondaryOnClick={() => setContent(null)}
      // secondaryButtonText={'Cancel'}
      secondaryButtonWidth={'100px'}
      primaryButtonText={t('T_UPDATE')}
      primaryButtonWidth={'100px'}
      primaryOnClick={() => {
        saveFileMetaData && saveFileMetaData();
      }}
      primaryButtonDisabled={!(hasContentChanged && hasContentChanged())}
    ></Modal>
  );
};
export default React.memo(FileMetaDataModal);
