import BaseAPI from './api';
import env from '../environment';
import { AppConstants } from '../constants/AppConstants';
import { isEmpty } from 'lodash';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

export const getQuestionnaires = async (
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: Array<string> = [],
  name: string = '',
  filters: any = {}
) => {
  const headers = { ...globalHeaders };
  let query = `?${AppConstants.QUERY_PARAM_PAGE}=${page}&size=${size}`;

  if(!isEmpty(filters)){
    if(filters['orgFilter'] && filters['orgFilter'].length){
      const [orgData] = filters['orgFilter']
      query += `&organization-id=${orgData.id}`;
    }
    if(filters['statusFilter'] && filters['statusFilter'].length){
      const [statusData] = filters['statusFilter']
      query += `&status=${statusData.key}`;
    } 
  }

  if (name) {
    query += `&name=${name}`;
  }

  if (sort.length) {
    query += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }

  let uri = `${env.surveyPath}/questionnaires${query}`;
  return await API.get(uri, { headers });
};

export const getQuestionnaire = async (id: string = '') => {
  const headers = { ...globalHeaders };
  let uri = `${env.surveyPath}/questionnaires/${id}`;

  return await API.get(uri, { headers });
};

// export const getApplicationList = async () => {
//   const headers = { ...globalHeaders };
//   const response = await API.get(`${env.metadataPath}/applications`, { headers });
//   return response;
// };

// export const getMetadataObjectLst = async (id: any) => {
//   const headers = { ...globalHeaders };
//   const response = await API.get(`${env.metadataPath}/applications/${id}/objects`, { headers });
//   return response;
// };

// export const getMetadataInstanceList = async (
//   id: any,
//   name: string = '',
//   page: number = AppConstants.DEFAULT_PAGE,
//   size: number = AppConstants.DEFAULT_PAGE_SIZE
// ) => {
//   const headers = { ...globalHeaders };
//   let query = `?page=${page}&size=${size}`;

//   if (name) {
//     query += `&label=${name}`;
//   }

//   let uri = `${env.metadataPath}/objects/${id}/instances${query}`;
//   const response = await API.get(uri, { headers });
//   return response;
// };

export const createSurveyTemplate = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.surveyPath}/questionnaires`, body, {
    headers
  });
  return response;
};
export const createMetadataInstances = async (id: any, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.post(`${env.metadataPath}/objects/${id}/instances`, body, { headers });
  return response;
};

export const updateSurveyTemplate = async (id: string, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(`${env.surveyPath}/questionnaires/${id}`, body, {
    headers
  });
  return response;
};
export const getMetadataInstance = async (objectId: any, id: any) => {
  const headers = { ...globalHeaders };
  const response = await API.get(`${env.metadataPath}/objects/${objectId}/instances/${id}`, {
    headers
  });
  return response;
};

export const updateMetadataInstance = async (objectId: any, id: any, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(
    `${env.metadataPath}/objects/${objectId}/instances/${id}`,
    { ...body },
    {
      headers
    }
  );
  return response;
};

export const deleteMetadataInstance = async (objectId: any, id: any) => {
  const headers = { ...globalHeaders };
  const response = await API.delete(`${env.metadataPath}/objects/${objectId}/instances/${id}`, {
    headers
  });
  return response;
};

export const deleteSurveyInstance = async (deleteId: any) => {
  const headers = { ...globalHeaders };
  return await API.delete(`${env.surveyPath}/questionnaires/${deleteId}`, { headers });
};
