import env from '../environment';

export const authRequests = {
  redirect_uri: 'http://localhost',
  // redirect_uri: 'https://brightinsight.com',
  //redirect_uri: 'https://ennmh1fbdtg6g.x.pipedream.net',
  client_id: 'oidc_pkce',
  scope: 'openid biOrg biUserId biRoles',
  response_type: 'code',
  state: 'abc12345',
  decision: 'allow',
  code_challenge: 'lKrE7FUKpExIuGLo25Ab2K1t_5e3QKiYKt8M3Oedn0s',
  code_challenge_method: 'S256',
  nonce: '12345abc',
  apikey: env.apikey
};

const authRequest = new URLSearchParams();
authRequest.set('redirect_uri', authRequests.redirect_uri);
authRequest.set('client_id', 'oidc_pkce');
authRequest.set('scope', 'openid biOrg biUserId biRoles');
authRequest.set('response_type', 'code');
authRequest.set('state', 'abc12345');
authRequest.set('decision', 'allow');
authRequest.set('code_challenge', 'lKrE7FUKpExIuGLo25Ab2K1t_5e3QKiYKt8M3Oedn0s');
authRequest.set('code_challenge_method', 'S256');
authRequest.set('nonce', '12345abc');
authRequest.set('apikey', env.apikey);
authRequest.set('response_mode', 'form');

export const accessTokenRequest = new URLSearchParams();
accessTokenRequest.set('grant_type', 'authorization_code');
accessTokenRequest.set('client_id', 'oidc_pkce');
accessTokenRequest.set('redirect_uri', authRequests.redirect_uri);
accessTokenRequest.set(
  'code_verifier',
  'FvIEu~XM3E_raXS49o.jXR4sbOLxVL2eyZptGhWvWpVjLUU-UK.LC-JcgVPH-ajn2tHgysmOcVB8ApTzFGc98t~ttjd.l.oQ.JfWce_YCwQCfie4A0ROGda02-l_mHxn'
);

export const refreshTokenRequest = new URLSearchParams();
refreshTokenRequest.set('grant_type', 'refresh_token');
refreshTokenRequest.set('client_id', 'oidc_pkce');
refreshTokenRequest.set('redirect_uri', authRequests.redirect_uri);
refreshTokenRequest.set(
  'code_verifier',
  'FvIEu~XM3E_raXS49o.jXR4sbOLxVL2eyZptGhWvWpVjLUU-UK.LC-JcgVPH-ajn2tHgysmOcVB8ApTzFGc98t~ttjd.l.oQ.JfWce_YCwQCfie4A0ROGda02-l_mHxn'
);

export default authRequest;
