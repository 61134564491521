import { cloneDeep, get, has, isEmpty, find } from 'lodash';
import { createSignedURL, downloadWithRedirection } from '../../http/asset-management-services';
import { AppConstants } from '../../constants/AppConstants';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { getFailureMessage } from '../../util/ErrorUtil';
import environment from '../../environment';

export const hasBucketAccess = (bucket: any = {}, userId: string, roleId: string): boolean => {
  let access: boolean = false;
  if (!bucket) {
    return access;
  }
  const {
    bucketOwners = [],
    bucketRoleOwners = [],
    bucketPermissions = [],
    bucketRolePermissions = []
  } = bucket;

  const owners: Array<any> = [...bucketOwners, ...bucketPermissions];
  const permissions: Array<any> = [...bucketRoleOwners, ...bucketRolePermissions];
  if (!owners.length && !permissions.length) {
    access = true;
  } else {
    const userAccess: any = find(owners, ['userId', userId]);
    const roleAccess: any = find(permissions, ['roleId', roleId]);
    access = !!userAccess || !!roleAccess;
  }
  return access;
};

export const getBucketRelativeName = (
  bucketName: string,
  tenantName: string = environment.ENV_TENANT_NAME
) => {
  let relativeName: string = bucketName;
  if (!isEmpty(relativeName)) {
    const tenantPrefix: string = `${tenantName}-`;
    // Only provide relative name if tenant name is prefix of the bucket name
    if (relativeName.startsWith(tenantPrefix)) {
      relativeName = relativeName.substring(tenantPrefix.length);
    }
  }
  return relativeName;
};

export const getFileSize = (bytes: any): string => {
  // return size > 1048576 ? `${Math.ceil(size / 1048576)} MB` : `${Math.ceil(size / 1024)} KB`;
  bytes = bytes.toString().replace(/[^0-9.]/g, '');
  let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  bytes = parseInt(bytes);
  if (bytes <= 0 || isNaN(bytes)) return '0 B';
  let i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

export const downloadDocument = async (docObject: any, bucketName: string): Promise<void> => {
  const { name: filePath, generation, url } = docObject;
  if (url) {
    window.open(url, '_blank');
    return;
  }
  const payload: any = {
    filePath,
    generation,
    action: AppConstants.GCS_UPLOAD_DOWNLOAD,
    expirationTime: AppConstants.GCS_MIN_EXPIRATION_TIME,
    metadata: {
      [AppConstants.CONTENT_DISPOSITION]: `attachment; filename=${getFileName(filePath)}`
    }
  };
  if (bucketName) {
    payload.bucketName = bucketName;
  }
  const response: any = await createSignedURL(payload);
  if (HTTP_STATUS.isSuccess(response?.status)) {
    window.open(response?.data?.url, '_blank');
    return;
  }
  throw getFailureMessage(response);
};

export const download = async (docObject: any, bucketName: string): Promise<void> => {
  const { name: filePath, generation, url } = docObject;
  if (url) {
    window.open(url, '_blank');
    return;
  }
  const payload: any = {
    filePath,
    generation,
    action: AppConstants.GCS_UPLOAD_DOWNLOAD,
    expirationTime: AppConstants.GCS_MIN_EXPIRATION_TIME
  };
  if (bucketName) {
    payload.bucketName = bucketName;
  }
  let response: any = await downloadWithRedirection(payload);
  if (HTTP_STATUS.isSuccess(response?.status)) {
    return;
  }
  throw getFailureMessage(response);
};

export const randomNumber = (): number => Math.round(Math.random() * 1000000);

export const getFileName = (fileName: string) => {
  if (!fileName || isEmpty(fileName)) {
    return '';
  }
  const index: number = fileName.lastIndexOf('/');
  return index !== -1 ? fileName.substring(index + 1) : fileName;
};

export const folderDetailsPayload = (folderDetails: any, newFolderDetails: any) => {
  function getObject(obj: any, value: string) {
    if (obj.id === value) {
      return obj;
    }
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        const result: any = getObject(obj[key], value);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  }

  const result = getObject(folderDetails, newFolderDetails?.id);

  if (result) {
    return result;
  }

  if (newFolderDetails?.parentFolderId === newFolderDetails?.bucketId) {
    let payload: any = cloneDeep(newFolderDetails);
    payload.parentFolderDetails = null;
    return payload;
  }

  let payload: any = cloneDeep(newFolderDetails);
  if (folderDetails.id !== newFolderDetails.id) {
    payload.parentFolderDetails = folderDetails;
  }
  return payload;
};

export const getFullPath = (folderInfo: any) => {
  function hasParentFolderDetails(obj: any) {
    return (
      obj &&
      obj.hasOwnProperty('parentFolderDetails') &&
      obj.parentFolderDetails &&
      !isEmpty(obj.parentFolderDetails)
    );
  }

  const collectNamesRecursive = (obj: any, names: string[] = []) => {
    if (!obj || !obj.name) {
      return names;
    }

    if (hasParentFolderDetails(obj)) {
      names = collectNamesRecursive(obj.parentFolderDetails, names);
    }

    names.push(obj.name);

    return names;
  };

  const obj: any = cloneDeep(folderInfo);
  const names = collectNamesRecursive(obj);

  return names.join('/');
};

export const trimFolderFileName = (name: string) => {
  return name.split('/').pop();
};
