interface TabToggleProps {
  activteTab: string;
  tabConfig: any[];
  changeTab: Function;
  disabled?: boolean;
}

function TabToggleButton({ activteTab, tabConfig, changeTab, disabled = false }: TabToggleProps) {
  return (
    <>
      <div className={`flex w-fit rounded-md border border-solid border-[#D9D9D9] p-1 text-base`}>
        {tabConfig.map((item: any, index: number) => {
          return (
            <div
              className={`rounded-md px-3 py-1.5 ${
                item.label === activteTab
                  ? 'pointer-events-none bg-primary text-white'
                  : 'cursor-pointer text-primary'
              }`}
              onClick={() => {
                changeTab(item.label);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>

      <div className={`mt-4 ${disabled && 'cursor-not-allowed opacity-60'}`}>
        <div className={`${disabled && 'pointer-events-none'}`}>
          {tabConfig.map((item: any) => {
            return item.label === activteTab && item.component;
          })}
        </div>
      </div>
    </>
  );
}

export default TabToggleButton;
