import {CSSProperties} from "react";
import {useTheme} from "../../../context/themeContext";

const BiButton: React.FC<{
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    className?: string;
    onClick?: Function;
    style?: CSSProperties;
}> = (props) => {
    const theme = useTheme().theme;

    const cssClass = () => {
    const classes = ['px-4', 'py-2', 'text-center', 'rounded-md', 'font-semibold'];
    const { disabled, className } = props;

    if (className && className.trim().length > 0) {
      classes.push(...className.split(' '));
    }

    if (disabled) {
      const index = classes.findIndex((item) => item === 'bg-primary');
      classes.splice(index, 1);
      classes.push('bg-disabled', 'cursor-not-allowed');
    }

    return classes.join(' ');
  };

  const onClick = () => {
    if (!props.disabled && props.onClick) {
      props.onClick();
    }
  };

  const cssStyles = () => {
    const { className, disabled } = props;
    const classes = [];
    if (className && className.trim().length > 0) {
      classes.push(...className.split(' '));
    }
    const index = classes.findIndex((item) => item === 'bg-primary');
    if(disabled && (index !== -1)){
        return theme.bgPrimaryDisabledStyleColor;
    } else if(index !== -1) {
      return theme.bgPrimaryStyleColor;
    } else
    return {}
  }

  return (
      <button
          className={cssClass()}
          type={props.type || 'button'}
          disabled={props.disabled}
          onClick={onClick}
          style={cssStyles()}
      >
        {props.children}
      </button>
  );
};

export default BiButton;
