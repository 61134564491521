import { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useInRouterContext, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { RouteConstants } from '../../constants/RouteConstants';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { setToastData } from '../../app/toastReducer';
import {
  deleteFolder,
  listFiles,
  deleteFile,
  getFolder,
  updateFileMetaData,
  createFolder
} from '../../http/asset-management-services';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import _, { cloneDeep, debounce, findIndex, isEmpty, isEqual, isUndefined, reduce } from 'lodash';
import ExclamationIcon from '@heroicons/react/outline/ExclamationIcon';
import Modal from '../shared/ModalPortal/Modal';
import moment from 'moment';
import { useTheme } from '../../context/themeContext';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import FolderAddIcon from '@heroicons/react/outline/FolderAddIcon';
import { DocumentTextIcon, FolderOpenIcon, UploadIcon } from '@heroicons/react/outline';

import UploadFiles from './UploadFiles';
import { setFolderData } from '../../app/bucketReducer';
import {
  folderDetailsPayload,
  getBucketRelativeName,
  getFullPath,
  trimFolderFileName
} from './BucketUtil';
import { MenuOptions } from './ContextMenu';
import {
  download,
  downloadDocument,
  getFileName,
  getFileSize,
  hasBucketAccess,
  randomNumber
} from './BucketUtil';
import FileMetaDataModal from './FileMetaDataModal';
import DeleteFileFolder from './DeleteFileFolder';
import { useDidMountEffect } from '../../util/customHooks';
import TemplateTable from '../shared/tables/TemplateTable';
import { setTableOptions } from '../../app/templateTableReducer';

/**
 * @parentId file-management
 * @manager List Files Folders
 * @overview Lists Folder and Files within a bucket
 *   <section>
 *       <p>
 *            Folder/File(s) listing page lists all the contents of the bucket, these include all the files and folders of the targetted bucket. User can navigate to Folder/File(s) listing page by:
 *           <ul>
 *           <li>Clicking on Menu Options on <b>Bucket Listing Page</b> row data item and selecting Files & Folders option.</li>
 *           <li>Clicking on Manage Button and selecting Files & Folders option in the <b>Bucket Detail Page</b>.</li>
 *           </ul>
 *           By Default Folder/File(s) listing page will display all the Folder/File(s) in flat table hireachry with pagination support & Folder/File (name) are sorted by createTime field timestamp in descending order. The Default pagination size is fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer.
 *           Datagrid footer will also display the current page and total number of pages available based on the choosen page size & Folder/File(s) data. User can switch to next/previous pages using the <b>Next</b> & <b>Previous</b> buttons enabled based on the content in the data grid footer.
 *       </p>
 *       <br>
 *       <p>
 *          Folder/File(s) can be filtered based on their names using the search field provided above the Folder/File(s) listing data grid.
 *          Admin user(s) can create new Folder by clicking on <b>New Folder</b> button provided on the admin portal toolbar  which will navigate user to Folder creation page.
 *          Admin user(s) can upload a file by clicking on <b>Upload</b> button which will open a modal dialog and allow selecting files locally.
 *          These action items will be shown based on logged in user role API permissions as described below
 *       </p>
 *       <br>
 *       <br>
 *       <p>
 *      Every Datagrid item has a menu button represented by a vertical three dots on click of which the following options (based on role permissions) are displayed:
 *      <br>
 *      Menu Options for a Datagrid item of type <b>File</b>
 *        <table>
 *           <tr>
 *               <th>Type</th>
 *               <th>Name</th>
 *               <th>Function Description</th>
 *                <th>API Permission</th>
 *                 <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>File</td>
 *               <td>Download</td>
 *               <td>Saves the file locally</td>
 *                <td>asset-management-service.signed-url.create, asset-management-service.signed-url.create.redirect </td>
 *                <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>File</td>
 *               <td>Version history</td>
 *               <td>Opens the Version History of the file and shows previous versions of it.</td>
 *               <td>asset-management-service.bucket.files.versions </td>
 *                 <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>File</td>
 *               <td>Delete</td>
 *               <td>Deletes a file with an option to recover it from the service end (Soft Delete)</td>
 *               <td>asset-management-service.file.delete</td>
 *                <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>File</td>
 *               <td>Permanent Delete</td>
 *               <td>Deletes or Removes a file <b>permanently</b></td>
 *               <td>asset-management-service.file.delete.hard</td>
 *                <td>Yes</td>
 *           </tr>
 *       </table>
 *       <br>
 *      Menu Options for a Datagrid item of type <b>Folder</b>
 *        <table>
 *           <tr>
 *               <th>Type</th>
 *               <th>Name</th>
 *               <th>Function Description</th>
 *                <th>API Permission</th>
 *                 <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>Folder</td>
 *               <td>Files & Folder</td>
 *               <td>Navigates and displayes the nested folders and files for the current folder.</td>
 *                <td>asset-management-service.bucket.files, asset-management-service.folder.get</td>
 *                <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Folder</td>
 *               <td>Delete</td>
 *               <td>Opens a modal popup which prompts to select deletion type based on permissions and access.</td>
 *               <td>asset-management-service.folder.delete, asset-management-service.folder.delete.hard</td>
 *                <td>Yes</td>
 *           </tr>
 *       </table>
 *       </p>
 *       <p>
 *           Datagrid will display details of each Folder/File(s) as row item with each column representing below object property.
 *           <br>
 *           <ul>
 *               <li>File/Folder Name</li>
 *               <li>File Type</li>
 *               <li>File Size</li>
 *               <li>Created On</li>
 *               <li>Last Modified On</li>
 *           </ul>
 *       </p>
 *       <p>
 *         Sorting of table grid is only supported on <i>File Type</i>, <i>Created on</i>, <i>Updated on</i> and <i>Last Modified On</i> column.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the List Bucket Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>AMS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>AMS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of Folder/File(s) listing page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for  Folder/File(s) listing</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Asset Management Service</td>
 *               <td>1.3.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for Folder/File(s) listing page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>/bucket/listfiles</td>
 *               <td>POST</td>
 *               <td>asset-management-service.bucket.files</td>
 *               <td>Yes</td>
 *           </tr>
 *             <tr>
 *               <td>/folder/{id}</td>
 *               <td>GET</td>
 *               <td>asset-management-service.folder.get</td>
 *               <td>Yes</td>
 *             </tr>
 *              <tr>
 *               <td>/signed-url</td>
 *               <td>POST</td>
 *               <td>asset-management-service.signed-url.create</td>
 *               <td>No</td>
 *             </tr>
 *               <tr>
 *               <td>/signed-url/redirect</td>
 *               <td>POST</td>
 *               <td>asset-management-service.signed-url.create.redirect</td>
 *               <td>No</td>
 *           </tr>
 *              <tr>
 *               <td>/bucket/filehistory</td>
 *               <td>POST</td>
 *               <td>asset-management-service.bucket.files.versions</td>
 *               <td>No</td>
 *           </tr>
 *             <tr>
 *               <td>/file/{asset-id}/{file-name}</td>
 *               <td>DELETE</td>
 *               <td>asset-management-service.file.delete</td>
 *               <td>No</td>
 *           </tr>
 *              <tr>
 *               <td>/file/{asset-id}/{file-name}/hard</td>
 *               <td>DELETE</td>
 *               <td>asset-management-service.file.delete.hard</td>
 *               <td>No</td>
 *           </tr>
 *              <tr>
 *               <td>/file/metadata</td>
 *               <td>POST</td>
 *               <td>asset-management-service.file.metadata.update</td>
 *               <td>No</td>
 *           </tr>
 *               <tr>
 *               <td>/folder</td>
 *               <td>POST</td>
 *               <td>asset-management-service.folder.create</td>
 *               <td>No</td>
 *           </tr>
 *             <tr>
 *               <td>/folder/{folder-id}</td>
 *               <td>DELETE</td>
 *               <td>asset-management-service.folder.delete</td>
 *               <td>No</td>
 *           </tr>
 *               <tr>
 *               <td>/folder/{folder-id}/hard</td>
 *               <td>DELETE</td>
 *               <td>asset-management-service.folder.delete.hard</td>
 *               <td>No</td>
 *           </tr>
 *               <tr>
 *               <td>/folder</td>
 *               <td>POST</td>
 *               <td>asset-management-service.folder.create</td>
 *               <td>No</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *   </section>
 */

const Image = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 4px;
`;

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const READ_TYPE_OPTION = [
  { key: 'Read & write', value: 'READ & WRITE' },
  { key: 'Read Only', value: 'READ ONLY' },
  { key: 'Owner', value: 'OWNER' }
];

const CREATE_BUCKET_FORM_SCHEMA = {
  name: '',
  description: '',
  selectedUser: [],
  selectedRole: []
};

const NewFolder = () => {
  const params = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const pagingRef: any = useRef();
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const bucketInfo = useAppSelector((state) => state.bucketSlice.bucketInfo);
  const folderInfo = useAppSelector((state) => state.bucketSlice.folderInfo);
  const roleAPIPermissions: any =
    useAppSelector((state) => state.initialLoadData.apiPermissions) || {};
  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);

  const [parentFolder, setParentFolder] = useState<any>(null);
  const [folderDetails, setFolderDetails] = useState<any>(null);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [showUploadFile, setShowUploadFile] = useState<boolean>(false);
  const [showNewFolderPopup, setshowNewFolderPopup] = useState<boolean>(false);

  const [deleteId, setDeleteId] = useState<any>('');
  const [deleteFileName, setDeleteFileName] = useState<any>('');

  const [showFileMetadata, setShowFileMetadata] = useState<any>(null);
  const [metaDataEntries, setMetaDataEntries] = useState<Array<any>>([]);
  const [currentActivePage, setCurrentActivePage] = useState(0);

  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);

  const showFolderContents = async (data: any, key: string) => {
    if (
      data.type === 'folder' &&
      roleAPIPermissions[AppConstants.PERMISSION_AMS_LIST_FILES] &&
      roleAPIPermissions[AppConstants.PERMISSION_AMS_GET_FOLDER]
    ) {
      let { folderId, name } = data;
      if (!folderId) {
        setIsLoading(true);
        const response: any = await createFolder({
          name: name.trim(),
          description: name.trim(),
          bucketId: bucketInfo?.id,
          metadata: {
            migrate: true
          }
        });
        const { status, data } = response;
        if (HTTP_STATUS.isSuccess(status)) {
          folderId = data?.id;
        }
        setIsLoading(false);
      }
      folderId && fetchDetailNavigate(folderId);
    } //TODO
  };

  const setPagingRef = (paging: any) => {
    pagingRef.current = paging;
  };

  const permissionFailure = () => {
    dispatch(
      setToastData({
        isToastActive: true,
        toastMessage: t('T_NO_PERMISSION_TO_VIEW_THIS_PAGE'),
        toastType: 'error'
      })
    );
    navigate(-1);
  };

  const refreshTableData = (
    name: string = '',
    page: number = AppConstants.DEFAULT_PAGE,
    size: number = AppConstants.DEFAULT_PAGE_SIZE,
    sortOrder: string = AppConstants.DEFAULT_AMS_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setIsLoading(true);
    setTableValues(name, page, size, sortOrder, sortType);
  };

  const debouncedCall = useRef(
    debounce(async (name, page, size, sortOrder, sortType) => {
      // if (params.folderId && (isEmpty(folderDetails) || !folderDetails)) {
      //   getFolderDetails(params.folderId);
      // } else {
      fetchListOfFiles(name, page, size, sortOrder, sortType);
      // }
    }, 400)
  ).current;

  const setTableValues = (
    name: string = '',
    page: number,
    size: number,
    sortOrder: string = AppConstants.DEFAULT_AMS_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    fetchListOfFiles(name, page, size, sortOrder, sortType);
  };

  const [selectedFolder, setSelectedFolder] = useState<any>(null);

  useEffect(() => {
    const { roles = [] } = userProfile || {};
    const [userRole] = roles;
    const {
      tableFilters = '',
      searchText = '',
      currentPage = AppConstants.DEFAULT_PAGE,
      size = AppConstants.DEFAULT_PAGE_SIZE,
      sortName = AppConstants.DEFAULT_AMS_SORT_BY,
      sortType = AppConstants.DEFAULT_SORT_DESC
    } = globalFilters['new-folder-list'] || {};

    if (
      !(
        userProfile &&
        roleAPIPermissions[AppConstants.PERMISSION_AMS_LIST_FILES] &&
        hasBucketAccess(bucketInfo, userProfile?.userId, userRole?.roleId)
      )
    ) {
      permissionFailure();
      return;
    }
    if (params?.folderId) {
      getFolderDetails(params.folderId);
    } else if (params?.id) {
      fetchListOfFiles(searchText, currentPage, size, sortName, sortType);
    }

    dispatch(
      setTableOptions({
        ['new-folder-list']: {
          searchText: searchText,
          currentPage,
          size,
          sortName: sortName,
          sortType: sortType,
          tableFilters: tableFilters
        }
      })
    );
    setIsLoading(true);
    SetBreadcrump();
  }, []);

  useEffect(() => {
    if (folderDetails && !isEmpty(folderDetails)) {
      setTableHeaders(BucketHeaders);
      fetchListOfFiles();
      SetBreadcrump();
    }
  }, [folderDetails]);

  const SetBreadcrump = () => {
    let fpath = params.folderId;
    let keyUrl = fpath
      ? `${RouteConstants.ROUTE_AMS_FOLDER_NESTED_LIST}`
      : `${RouteConstants.ROUTE_AMS_FILES}/${bucketInfo.id}`;

    let keyT = fpath
      ? keyUrl.replace(':folderId', fpath || '').replace(':id', bucketInfo?.id)
      : keyUrl + '/folders';
    let labelT = `${t('T_BUCKET')} > ${getBucketRelativeName(bucketInfo?.name)} ${
      fpath ? `> ${folderDetails?.name || fpath}` : ''
    }  >  ${t('T_BUCKET_FILE_FOLDER')}`;
    dispatch(
      setURLPaths([
        {
          key: keyT,
          label: labelT
        }
      ])
    );
  };

  const fetchListOfFiles = async (
    searchStr: string = '',
    page: any = AppConstants.DEFAULT_PAGE,
    size: any = AppConstants.DEFAULT_PAGE_SIZE,
    sortOrder: string = AppConstants.DEFAULT_AMS_SORT_BY,
    sortType: string = AppConstants.DEFAULT_SORT_DESC
  ) => {
    setIsLoading(true);
    const { name = '' } = bucketInfo;

    if (name) {
      let payload: any = {
        folderPath: params.folderId ? `${getFullPath(folderInfo)}/` : '',
        expirationTime: 30,
        generateSignedUrl: false,
        bucketName: name
      };

      if (searchStr) {
        payload.criteria = {
          name: searchStr
        };
      }

      const response = await listFiles(payload, { page: page, size: size }, [
        `${
          sortOrder === AppConstants.DEFAULT_SORT_BY ? AppConstants.DEFAULT_AMS_SORT_BY : sortOrder
        },${sortType}`
      ]);
      const { data, status } = response;
      if (HTTP_STATUS.isSuccess(status)) {
        const records: Array<any> = data?.data || [];
        setRows(records.length ? records : t('T_NO_DATA_AVAILABLE_MSG'));
        setPagingRef(data?.paging);
      } else {
        dispatch(
          setToastData({
            isToastActive: true,
            toastMessage: getFailureMessage(response),
            toastType: 'error'
          })
        );
      }
    }
    setIsLoading(false);
  };

  useDidMountEffect(() => {
    if (!isEmpty(folderInfo)) {
      setTableHeaders(BucketHeaders);
    }
  }, [folderInfo]);

  const getFolderDetails = async (id: string) => {
    setIsLoading(true);
    const response = await getFolder(id);
    const { data, status } = response;
    if (HTTP_STATUS.isSuccess(status)) {
      dispatch(setFolderData(folderDetailsPayload(folderInfo, data)));
      setFolderDetails(data);
    } else {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: getFailureMessage(response),
          toastType: 'error'
        })
      );
      setIsLoading(false);
    }
  };

  useDidMountEffect(() => {
    if (params?.folderId) {
      getFolderDetails(params.folderId);
    } else if (params?.id) {
      setFolderDetails(null);
      setTableHeaders(BucketHeaders);
      fetchListOfFiles();
      SetBreadcrump();
    }
  }, [params]);

  // useEffect(()=>{
  //   if(params?.folderId&&(isEmpty(folderDetails)||!folderDetails)){
  //     getFolderDetails(params.folderId);
  //   }
  // },[params?.folderId, folderDetails])

  const resetTableFilters = () => {
    dispatch(
      setTableOptions({
        ['new-folder-list']: {
          searchText: '',
          currentPage: AppConstants.DEFAULT_PAGE,
          size: AppConstants.DEFAULT_PAGE_SIZE,
          sortName: AppConstants.DEFAULT_SORT_BY,
          sortType: AppConstants.DEFAULT_SORT_DESC,
          tableFilters: ''
        }
      })
    );
  };

  const fetchDetailNavigate = async (id: string) => {
    resetTableFilters();
    navigate(
      RouteConstants.ROUTE_AMS_FOLDER_NESTED_LIST.replace(':id', bucketInfo.id).replace(
        ':folderId',
        id
      )
    );
  };

  const isFile = (data: any) => (data?.type === 'file' ? true : false);

  const getMenuOptions = (data: any, parentFld: any) => {
    let tempOptionList: any = [];

    if (data.type === 'folder' && roleAPIPermissions[AppConstants.PERMISSION_AMS_GET_FOLDER]) {
      tempOptionList.push({
        display: t('T_FOLDER_DETAILS'),
        action: async (folderData: any) => {
          let { folderId, name } = folderData;
          if (!folderId) {
            setIsLoading(true);
            const response: any = await createFolder({
              name: name.trim(),
              description: name.trim(),
              bucketId: bucketInfo?.id,
              metadata: {
                migrate: true
              }
            });
            const { status, data } = response;
            if (HTTP_STATUS.isSuccess(status)) {
              folderId = data?.id;
            }
            setIsLoading(false);
          }
          setParentFolder(data);
          let uri = `${RouteConstants.ROUTE_AMS_FOLDER_VIEW.replace(':id', bucketInfo?.id).replace(
            ':folderId',
            folderId
          )}`;
          navigate(isEmpty(folderDetails) ? uri : `${uri}?parentFolder=${folderDetails?.id}`);
        }
      });
    } //TODO

    if (isFile(data)) {
      if (
        roleAPIPermissions[AppConstants.PERMISSION_AMS_SIGNED_URL] ||
        roleAPIPermissions[AppConstants.PERMISSION_AMS_DOWNLOD_REDIRECTION]
      ) {
        tempOptionList.push({
          key: 'download_file',
          display: t('T_DOWNLOAD_FILE'),
          action: async (file: any) => {
            setIsLoading(true);
            try {
              await downloadDocument(file, bucketInfo?.name);
              // await download(file, bucketInfo?.name);
            } catch (err) {
              dispatch(
                setToastData({ isToastActive: true, toastMessage: err, toastType: 'error' })
              );
            }
            setIsLoading(false);
          }
        });
      }
      if (roleAPIPermissions[AppConstants.PERMISSION_AMS_FILE_VERSION]) {
        tempOptionList.push({
          key: 'file_history',
          display: t('T_VERSION_HISTORY'),
          action: (file: any) => {
            navigate(
              RouteConstants.ROUTE_AMS_FILES_HISTORY.replace(':id', file?.name.split('/').pop())
            );
          }
        });
      }
      tempOptionList.push({
        key: 'manage_file_metadata',
        display: t('T_MANAGE_FILE_METADATA'),
        action: (file: any) => {
          setShowFileMetadata(file);
          const { metadata = {} } = file;
          setMetaDataEntries(
            Object.keys(metadata).map((name: string, index: number) => {
              return {
                id: randomNumber(),
                name,
                value: metadata[name]
              };
            })
          );
        }
      });

      if (roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_FILE]) {
        tempOptionList.push({
          key: 'soft_delete',
          display: t('T_DELETE'),
          action: (file: any) => {
            // removeFile(file?.name);
            setDeleteFileName(file?.name);
          }
        });
      }

      // if (roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_FILE_HARD]) {
      //   tempOptionList.push({
      //     key: 'hard_delete',
      //     display: t('T_HARD_DELETE_FOLDER'),
      //     action: (file: any) => {
      //       removeFile(file?.name, true);
      //     }
      //   });
      // }
    } else {
      if (roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_FOLDER]) {
        tempOptionList.push({
          key: 'soft_delete',
          display: t('T_DELETE'),
          action: async (folder: any) => {
            let { folderId, name } = folder;
            if (!folderId) {
              setIsLoading(true);
              const response: any = await createFolder({
                name: name.trim(),
                description: name.trim(),
                bucketId: bucketInfo?.id,
                metadata: {
                  migrate: true
                }
              });
              const { status, data } = response;
              if (HTTP_STATUS.isSuccess(status)) {
                folderId = data?.id;
              }
              setIsLoading(false);
            }
            folderId && setDeleteId(folderId);
          }
        });
      }
      // if (roleAPIPermissions[AppConstants.PERMISSION_AMS_DELETE_FOLDER_HARD]) {
      //   tempOptionList.push({
      //     key: 'hard_delete',
      //     display: t('T_HARD_DELETE_FOLDER'),
      //     action: (folder: any) => {
      //       removeFolder(folder?.folderId, folder?.name, true);
      //     }
      //   });
      // }
    }
    return tempOptionList;
  };

  const removeFolder = async (id: string, name: string, hard: boolean = false) => {
    setIsLoading(true);
    let success: boolean = false;
    let toastMessage: string = t('T_FOLDER_DELETE_SUCCESS_MSG').replace('%s', getFileName(name));
    let response: any = await deleteFolder(id, hard);
    success = HTTP_STATUS.isSuccess(response?.status);
    if (success) {
      setSelectedFolder(null);
      setDeletePopup(false);
      fetchListOfFiles();
    } else {
      toastMessage = getFailureMessage(response);
    }
    dispatch(
      setToastData({
        isToastActive: true,
        toastMessage,
        toastType: success ? 'success' : 'error'
      })
    );
    setIsLoading(false);
  };

  const removeFile = async (fileName: string, hard: boolean = false) => {
    setIsLoading(true);
    let success: boolean = false;
    const name: string = getFileName(fileName);
    let toastMessage: string = t('T_FILE_DELETE_SUCCESS_MSG').replace('%s', name);
    try {
      const response: any = await deleteFile(folderDetails?.id || bucketInfo.id, name, hard);
      success = HTTP_STATUS.isSuccess(response?.status);

      if (success) {
        setDeletePopup(false);
        fetchListOfFiles();
      } else {
        toastMessage = getFailureMessage(response);
      }
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage,
          toastType: success ? 'success' : 'error'
        })
      );
      setIsLoading(false);
      setDeleteFileName('');
    } catch (e) {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_ERROR_SOMETHING_WRONG'),
          toastType: 'error'
        })
      );
      setIsLoading(false);
      setDeleteFileName('');
    }
  };

  const updateMetaData = async () => {
    const { name: bucketName } = bucketInfo;
    const { name: filePath } = showFileMetadata;
    const entries: Array<any> = metaDataEntries.filter(
      (item: any) => !isEmpty(item?.name?.trim()) && !isEmpty(item?.value?.trim())
    );
    const metadata: any = reduce(
      entries,
      (obj: any, param: any) => {
        obj[param.name] = param.value;
        return obj;
      },
      {}
    );
    const payload: any = {
      filePath,
      bucketName,
      metadata
    };
    setIsLoading(true);
    const response: any = await updateFileMetaData(payload);
    const { status, data } = response;
    if (HTTP_STATUS.isSuccess(status)) {
      setMetaDataEntries([]);
      setShowFileMetadata(null);
      let index: number = findIndex(rows, { name: filePath });
      if (index !== -1) {
        const [oldValue] = rows.splice(index, 1);
        rows.splice(index, 0, { ...oldValue, ...data });
        setRows([...rows]);
      }
      index = filePath.lastIndexOf('/');
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_FILE_METADATA_UPDATED_SUCCESS_MSG').replace(
            '%s',
            index !== -1 ? filePath?.substring(index + 1) : filePath
          ),
          toastType: 'success'
        })
      );
    } else {
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: getFailureMessage(response),
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const addFileMetaDataItem = () => {
    const name: string = '';
    const value: string = '';
    setMetaDataEntries([
      ...metaDataEntries,
      {
        id: randomNumber(),
        name,
        value
      }
    ]);
  };

  const removeFileMetaDataItem = (index: number) => {
    if (isUndefined(index) || index < 0 || index > metaDataEntries.length) {
      return;
    }
    let cloneArr = cloneDeep(metaDataEntries);
    cloneArr.splice(index, 1);

    setMetaDataEntries(cloneArr);
  };

  const updateFileMetaDataItem = (index: number, item: any) => {
    if (isUndefined(index) || index < 0 || index > metaDataEntries.length) {
      return;
    }
    metaDataEntries.splice(index, 1);
    metaDataEntries.splice(index, 0, item);
    setMetaDataEntries([...metaDataEntries]);
  };

  const enableMetaDataUpdate = () => {
    let enable: boolean = !!roleAPIPermissions[AppConstants.PERMISSION_AMS_FILE_METADATA_UPDATE];
    if (enable && showFileMetadata) {
      const { metadata = {} } = showFileMetadata;
      const entries: Array<any> = metaDataEntries.filter(
        (item: any) => !isEmpty(item?.name?.trim()) && !isEmpty(item?.value?.trim())
      );
      const reduceObject = reduce(
        entries,
        (obj: any, param: any) => {
          obj[param.name] = param.value;
          return obj;
        },
        {}
      );
      enable = !isEqual(metadata, reduceObject);
    }
    return enable;
  };

  const BucketHeaders: Array<ITableHeader> = [
    {
      key: 'name',
      description: 'T_FILE_FOLDER_NAME',
      sortEnabled: false,
      width: '40%',
      clickable: true,
      sortingInformation: {
        order: ''
      },
      render: (row: any) => {
        return trimFolderFileName(row?.name);
      },
      styles: { borderRight: 'none' }
    },
    {
      key: 'name',
      description: '',
      sortEnabled: false,
      width: '5%',
      clickable: false,
      render: (data: any) => {
        return <MenuOptions options={getMenuOptions(data, parentFolder)} folderData={data} />;
      },
      sortingInformation: {
        order: ''
      },
      styles: {
        paddingRight: '0',
        paddingLeft: '0',
        borderLeft: 'none',
        rowStyle: { fontWeight: 'normal', padding: '0px' }
      }
    },
    {
      key: 'type',
      description: 'T_FILE_TYPE',
      sortEnabled: true,
      clickable: true,
      width: '10%',
      sortingInformation: {
        order: ''
      },
      render: (data: any) => {
        const { type } = data;
        return type === 'file' ? (
          <DocumentTextIcon className="h-6 w-6 cursor-pointer hover:text-black" />
        ) : (
          <FolderOpenIcon className="h-6 w-6 cursor-pointer hover:text-black" />
        );
      }
    },
    {
      key: 'size',
      description: 'T_FILE_SIZE',
      clickable: true,
      sortEnabled: false,
      width: '15%',
      sortingInformation: {
        order: ''
      },
      render: (data: any) => (isFile(data) ? getFileSize(data?.size) : '')
    },
    // {
    //   key: 'versioning',
    //   description: 'T_VERSION',
    //   sortEnabled: false,
    //   width: '10%',
    //   clickable: true,
    //   render: (data: any) => {
    //     return data.versioning ? 'Yes' : 'No';
    //   },
    //   sortingInformation: {
    //     order: ''
    //   }
    // },
    {
      key: 'createTime',
      description: 'T_CREATED_ON',
      sortEnabled: true,
      width: '15%',
      clickable: true,
      render: (data: any) => {
        return `${
          data?.createTime ? moment(data?.createTime).format(AppConstants.DEFAULT_DATE_FORMAT) : ''
        }`;
      },
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'updateTime',
      description: 'T_LAST_MODIFIED_ON',
      sortEnabled: true,
      width: '15%',
      clickable: true,
      render: (data: any) => {
        return `${
          data?.updateTime ? moment(data?.updateTime).format(AppConstants.DEFAULT_DATE_FORMAT) : ''
        }`;
      },
      sortingInformation: {
        order: 'desc'
      }
    }
  ];

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(BucketHeaders);

  const handleBack = (type: 'text' | 'function') => {
    const {
      parentFolderId = '',
      bucketId = bucketInfo?.id || '',
      name = '',
      parentFolderDetails = {}
    } = folderInfo || folderDetails || {};

    const folderIdMatchesBucket = params?.folderId && parentFolderId === bucketId;
    const folderIdMatchesNested = params?.folderId && parentFolderId !== bucketId;

    const getFunctionValue = (path: string) =>
      type === 'function' ? path.replace(':id', bucketId) : t(path);

    const backToBucketList = getFunctionValue(RouteConstants.ROUTE_AMS_FOLDER_LIST);
    const backToFolderList = getFunctionValue(RouteConstants.ROUTE_AMS_FOLDER_NESTED_LIST);

    const value = folderIdMatchesBucket
      ? type === 'function'
        ? backToBucketList
        : t('T_BACK_TO_BUCKET_CONTENTS_LIST').replace('%s', bucketInfo?.name || '')
      : folderIdMatchesNested
      ? type === 'function'
        ? backToFolderList.replace(':folderId', parentFolderId)
        : t('T_BACK_TO_FOLDER_CONTENTS_LIST').replace(
            '%s',
            parentFolderDetails?.name || parentFolderId
          )
      : type === 'function'
      ? `${RouteConstants.ROUTE_AMS_FILES}/${bucketId}`
      : t('T_BACK_TO_BUCKET');

    if (type === 'function') {
      navigate(value);
      resetTableFilters();
    } else {
      return value;
    }
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <MenuButtonsPortal>
          {true && (
            <>
              <BiButton
                className={'max-w-1/2 mr-2 overflow-hidden whitespace-nowrap text-primary'}
                type="button"
                onClick={() => handleBack('function')}
              >
                {t('T_BACK')}
              </BiButton>
              <BiButton
                className={
                  'mr-2 flex h-10 border border-primary leading-5 text-primary hover:bg-[#0074e02e]'
                }
                type="button"
                onClick={() => {
                  // let uri = `${RouteConstants.ROUTE_AMS_FILES}/${bucketInfo.id}/new/details`;
                  // if (folderDetails) {
                  //   uri += `?parentFolder=${folderDetails.name}`;
                  // }
                  navigate(
                    folderDetails
                      ? `${RouteConstants.ROUTE_AMS_FOLDER_NESTED_NEW.replace(
                          ':id',
                          bucketInfo.id
                        ).replace(':folderId', folderDetails.id)}`
                      : RouteConstants.ROUTE_AMS_FOLDER_NEW.replace(':id', bucketInfo.id)
                  );
                }}
              >
                <FolderAddIcon className="mr-2 h-5" data-testid="plus-icon" />
                {t('T_NEW_FOLDER')}
              </BiButton>
              <BiButton
                className={
                  'flex h-10 border border-primary leading-5 text-primary hover:bg-[#0074e02e]'
                }
                type="button"
                onClick={() => {
                  setShowUploadFile(true);
                }}
              >
                <UploadIcon className="mr-2  h-5" data-testid="plus-icon" />
                {t('T_UPLOAD')}
              </BiButton>
            </>
          )}
        </MenuButtonsPortal>
        <CustomersTableContainer style={{ height: 'inherit' }}>
          <TemplateTable
            key={
              params?.folderId
                ? `${params?.folderId}-${folderInfo?.id || folderDetails?.id}`
                : params?.id
            }
            tableData={rows}
            onRefreshTableData={refreshTableData}
            size={globalFilters?.size?.toString()}
            currentPage={globalFilters?.currentPage}
            setCurrentPage={(page: any) => {
              setCurrentActivePage(parseInt(page));
            }}
            cellSelector={showFolderContents}
            templateSelector={showFolderContents}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            pagingData={pagingRef.current}
            containerClass="mt-0"
            isSearchable={true}
            showDeleteButton={true}
            theme={useTheme().theme}
            tablePage="new-folder-list"
            isLocalPaginator={false}
            totalDataLength={rows.length}
            isPaginated={true}
            globalFilters={globalFilters}
            disablePageSize={false}
            searchPlaceholder={t('T_SEARCH_NAME_FILE_NAME') || ''}
          />
        </CustomersTableContainer>

        {/* <Modal
          showCloseIcon={true}
          width="70rem"
          headerText={t('T_NEW_FOLDER')}
          toggleVisiblity={(value: boolean) => {
            setshowNewFolderPopup(value);
          }}
          isVisible={showNewFolderPopup}
          body={
            <div>
              <FolderDetails
                closePopup={() => {
                  setSelectedFolder(null);
                  setshowNewFolderPopup(false);
                }}
                bucketInfo={bucketInfo}
                fetchListOfFiles={fetchListOfFiles}
                updateFolder={selectedFolder}
                parentFolder={parentFolder?.folderId || ''}
                folderpath={params.folderPath === ':folders' ? '' : params.folderPath}
              />
            </div>
          }
          headerLeftIcon={<FolderAddIcon className="h-7.5 w-6" />}
        /> */}

        <Modal
          showCloseIcon={true}
          width="48rem"
          headerText={t('T_DELETE_CONFIRMATION_TITLE')}
          toggleVisiblity={(value: boolean) => {
            setDeletePopup(value);
          }}
          isVisible={deletePopup}
          body={
            <div>
              <div>{t('T_DELETE_CONFIRMATION_TEXT')}</div>
              <div className="mt-8 flex">
                <BiButton
                  className={'ml-auto mr-2 text-primary'}
                  type="button"
                  onClick={() => {
                    setSelectedFolder(null);
                    setDeletePopup(false);
                  }}
                >
                  {t('T_CANCEL')}
                </BiButton>
                <BiButton
                  className={'bg-primary text-white'}
                  type="button"
                  onClick={() => {
                    removeFolder(selectedFolder?.folderId, selectedFolder?.name, true);
                  }}
                >
                  {t('T_DELETE')}
                </BiButton>
              </div>
            </div>
          }
          headerLeftIcon={<ExclamationIcon className="h-7.5 w-6" />}
        />
        <UploadFiles
          isVisible={showUploadFile}
          toggleVisiblity={(isApiSuccess: boolean = false) => {
            if (isApiSuccess) {
              fetchListOfFiles();
            }
            setShowUploadFile(!showUploadFile);
          }}
          bucket={bucketInfo}
          showMetadata
          theme={useTheme().theme}
          fileLocation={params.folderId ? `${getFullPath(folderInfo)}/` : ''}
        />
        {/* <Modal
          isVisible={showFileMetadata}
          headerText={t('T_FILE_METADATA')}
          showCloseIcon={true}
          toggleVisiblity={setShowFileMetadata}
          width="65rem"
          body={
            <FileMetaData
              {...showFileMetadata}
              disabled={!roleAPIPermissions[AppConstants.PERMISSION_AMS_FILE_METADATA_UPDATE]}
              metadataEntries={metaDataEntries}
              addItemFunction={addFileMetaDataItem}
              deleteFunction={removeFileMetaDataItem}
              updateFunction={updateFileMetaDataItem}
            />
          }
          secondaryOnClick={() => setShowFileMetadata(null)}
          secondaryButtonText={'Cancel'}
          secondaryButtonWidth={'100px'}
          primaryButtonText={t('T_UPDATE')}
          primaryButtonWidth={'100px'}
          primaryOnClick={updateMetaData}
          primaryButtonDisabled={!enableMetaDataUpdate()}
        ></Modal> */}
        {deleteId.length ? (
          <DeleteFileFolder
            type="Folder"
            id={deleteId}
            setId={setDeleteId}
            fetchList={fetchListOfFiles}
          ></DeleteFileFolder>
        ) : (
          ''
        )}

        {deleteFileName.length ? (
          <DeleteFileFolder
            type="File"
            id={deleteFileName}
            setId={setDeleteFileName}
            fetchList={fetchListOfFiles}
            deleteFile={removeFile}
          ></DeleteFileFolder>
        ) : (
          ''
        )}
        {showFileMetadata && (
          <FileMetaDataModal
            content={showFileMetadata}
            setContent={setShowFileMetadata}
            metaDataEntries={metaDataEntries}
            saveFileMetaData={updateMetaData}
            addItemFunction={addFileMetaDataItem}
            hasContentChanged={enableMetaDataUpdate}
            removeItemFunction={removeFileMetaDataItem}
            updateItemFunction={updateFileMetaDataItem}
            disabled={!roleAPIPermissions[AppConstants.PERMISSION_AMS_FILE_METADATA_UPDATE]}
          />
        )}
      </LoaderContainer>
    </>
  );
};

export default NewFolder;
