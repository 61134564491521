// const params = new URLSearchParams(window.location.search);setUserId(params.get('userId'));

import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TabDrawer from '../Reusable/TabDrawer';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import PermissionDetails from './PermissionDetails';
import AdminFeaturesDataGrid from './AdminFeatureDataGrid';
import { setURLPaths } from '../../app/organizationReducer';
import ApplicationFeatureDataGrid from './ApplicationFeatureDataGrid';
import { AppConstants } from '../../constants/AppConstants';
import {
  createSystemSetting,
  getCategories,
  getSettingById,
  getSystemSettings,
  updateSystemSetting
} from '../../http/configuration-services';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setIsContentChanged, setPermissionData } from '../../app/permissionSetReducer';
import { setToastData } from '../../app/toastReducer';
import { getAppFeatureColumns, getAppFeatureRows } from '../../util/ColumnUtil';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import ApplicationSettings from '../AppSettings/ApplicationSettings';
import {
  Category,
  formatApplication,
  formatApplicationPrivileges,
  getCurrentDate,
  normalizeKey
} from '../../util/RoleUtil';
import { getFailureMessage } from '../../util/ErrorUtil';
import { getRole } from '../../http/access-management-service';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { RolePermission } from '../Organizations/helpers';
import { validateName } from '../../util/validators';
import { find, findIndex, isEqual, sortBy } from 'lodash';
import {
  getMetadataInstance,
  getMetadataInstances,
  updateMetadataInstance
} from '../../http/metadata-service';
import { getLoggedInOrg, getLoginUserId } from '../../util/admin-utils';
import { createEvent } from '../../http/event-services';
import {
  fetchApplFeaturesFromConfig,
  fetchApplFeaturesFromMetaData,
  getApplSolnFeatures,
  loadApplicationFeatures,
  loadOtherSettings,
  updatePermissionSet
} from './PermissionSetUtil';
import { getPermittedItems } from '../../util/appInitializeUtil';
import AuditInfo from '../Common/AuditInfo';

const buttonStyle =
  'mr-2 mt-2 h-[35px] border border-primary text-[14px] leading-4 text-primary text-white bg-primary';

const ShowPermissionSet = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const ADMIN_FEATURES = sessionStorage.getItem(AppConstants.SESSION_ADMINPORTAL_KEY);
  const SOLUTION_FEATURES = sessionStorage.getItem(AppConstants.SESSION_SOLUTION_KEY);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(`${t('T_LBL_FOR_PERMISSION_SET_DETAILS')}`);

  const [collectdata, setCollectData] = useState<any>({
    permissionDetails: null,
    applicationFeatures: JSON.parse(SOLUTION_FEATURES || '[]'),
    adminPortalFeatures: JSON.parse(ADMIN_FEATURES || '{}')
  });

  const { applicationFeatures } = collectdata;
  const [columnFeature, setColumnFeature] = useState<Array<any>>([]);

  const [adminFeatureTableData, setAdminFeatureTableData] = useState<any>([]);
  const adminFeatures = JSON.parse(sessionStorage.getItem('adminFeatures') || '{}');

  const [rows, setRows] = useState<any>([]);
  const [otherSettings, setOtherSettings] = useState<any>('');
  const [solutionRole, setSolutionRole] = useState<any>('');
  const [intialApplicationPermissions, setIntialApplicationPermissions] = useState<any>([]);
  const [intialAdminPermissions, setIntialAdminPermissions] = useState<any>([]);
  const [otherSettingInvalidData, setOtherSettingInvalidData] = useState([]);
  const [originalOtherSettingObj, setOriginalOtherSettingObj] = useState<any>('');
  const [applicationFeatureError, setApplicationFeatureError] = useState('');

  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const permissionSetData = useAppSelector((state) => state.permissionSet.permissionSetData);
  const [editPermission, setEditPermission] = useState(false);
  const [contentChanged, setContentChanged] = useState(false);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const webPortal: any = find(metaDataObjectList, { name: AppConstants.APPLICATION_NAME });
  const permissionSetObject: any = find(metaDataObjectList, {
    name: AppConstants.METADATA_OBJECT_PERMISSION_SET
  });
  const noChanges = useAppSelector((state) => state.permissionSet.noChanges);

  const loggedInOrg = getLoggedInOrg();
  const userId = getLoginUserId();

  const rolePermissions = useAppSelector<any>((state: any) => {
    return state?.login?.rolePermissions;
  });
  const metaDataPermissions = useAppSelector(
    (state) => state.initialLoadData.metaDataPermissionSet
  );
  const permissions = useAppSelector((state) => state.initialLoadData.apiPermissions);

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      let permission = editPermission;
      if (!editPermission) {
        permission = await enableEditPermission();
      }
      if (permission) {
        const id: any = params.id;
        !permissionSetData && params.id && (await loadPermissionSet(id));
        !Object.keys(applicationFeatures).length && (await getSolutionFeatures());
        if (permissionSetData && Object.keys(applicationFeatures).length) {
          if (permissionSetData.attributes && collectdata.applicationFeatures) {
            let permissionDetails = {
              permissionName: permissionSetData.attributes.name,
              roleName: permissionSetData?.role?.name
                ? permissionSetData?.role?.name
                : permissionSetData?.attributes?.roles?.[0]
                ? permissionSetData?.attributes?.roles?.[0]
                : '',
              description: permissionSetData.attributes.description
            };
            dispatch(
              setURLPaths([
                ...storedURLPaths,
                {
                  key: `/permission/${params.id}`,
                  label: `${t('T_BREADCRUMB_SHOW_PERMISSION_LBL')} ${
                    permissionSetData?.attributes?.name?.length > 100
                      ? permissionSetData?.attributes?.name.substring(0, 97) + '...'
                      : permissionSetData?.attributes?.name
                  }`
                }
              ])
            );
            setCollectData({ ...collectdata, permissionDetails: permissionDetails });

            if (Object.keys(applicationFeatures).length) {
              //Application Features

              let columns = getAppFeatureColumns(applicationFeatures, 'action');
              let rows = createRows(getAppFeatureRows(applicationFeatures, 'feature'));
              setColumnFeature(columns);
              let sortedData = sortBy(rows, 'name');
              setRows(sortedData);

              let selectedApplicationFeatures: Array<any> = [];
              // if (permissionSetData.value?.roles?.length) {
              //   //IF ROLE is assigned then we read from mapped ID

              //   let mappedRoleResponse = await getSettingById(
              //     permissionSetData?.value?.roles[0]?.mapping?.id
              //   );
              //   if (mappedRoleResponse.status !== HTTP_STATUS.HTTP_OK) {
              //     setApplicationFeatureError(`${t('T_ERROR_MSG_FOR_SHOW_PERMISSION_SET')}`);
              //   }
              //   setSolutionRole(mappedRoleResponse?.data);
              //   selectedApplicationFeatures = mappedRoleResponse?.data?.value?.privileges;
              //   formatApplicationFeature(selectedApplicationFeatures, rows, 'assigned');
              // } else {
              selectedApplicationFeatures = permissionSetData.attributes.permissions.application;
              formatApplicationFeature(selectedApplicationFeatures, sortedData, 'not-assigned');
              // }
            }
            if (adminFeatures) {
              let selectedAdminFeatures = permissionSetData.attributes.permissions.adminPortal;
              formatAdminFeatures(selectedAdminFeatures);
            }
            // if (permissionSetData.value.permissions?.settings?.length) {
            //   setOtherSettings(JSON.stringify(permissionSetData.value.permissions.settings));
            //   setOriginalOtherSettingObj(
            //     JSON.stringify(permissionSetData.value.permissions.settings)
            //   );
            // } else if (permissionSetData.value.roles?.[0]?.settings?.length) {
            //   getOtherSettings(permissionSetData.value.roles?.[0]?.settings);
            // }
            if (permissionSetData?.attributes?.permissions?.settings?.length) {
              getOtherSettings(permissionSetData.attributes.permissions.settings);
            } else {
              setOtherSettings(JSON.stringify(permissionSetData.attributes.permissions.settings));
              setOriginalOtherSettingObj(
                JSON.stringify(permissionSetData.attributes.permissions.settings)
              );
            }
            setIsLoading(false);
          }
        }
      } else {
        setIsLoading(false);
        dispatch(
          setToastData({
            toastMessage: t('T_USER_NO_PERMISSION_TOAST_MESSAGE'),
            isToastActive: true,
            toastType: 'error'
          })
        );
      }
    }
    init();
  }, [permissionSetData, collectdata.applicationFeatures]);

  const enableEditPermission = async () => {
    let permission = await RolePermission(AppConstants.PERMISSION_ROLE_PERMISSION_UPDATE);
    if (!permission) {
      permission = await RolePermission(AppConstants.PERMISSION_ROLE_PERMISSION_PATCH);
    }
    setEditPermission(permission);
    return permission;
  };
  const createRows = (rows: Array<any> = []): Array<any> => {
    return rows.map((row: any) => {
      let rowData = {};
      row.forEach((e: any, index: number) => {
        const { feature: name, action } = e;
        rowData = { ...rowData, name, [action]: { checked: false } };
      });
      return rowData;
    });
  };

  const formatApplicationFeature = (
    selectedFeatures: Array<any> = [],
    solnFeatures: Array<any> = [],
    type: string
  ) => {
    let tempRows = [...solnFeatures];
    selectedFeatures.forEach((ele: any, index: number) => {
      let eleIndex = tempRows.findIndex((item) => item.name == ele.feature);

      if (type == 'assigned') {
        if (eleIndex != -1) {
          if (tempRows[eleIndex][ele.action]) {
            tempRows[eleIndex][ele.action].checked = true;
          }
        }
      } else {
        ele?.functions?.forEach((func: any) => {
          if (tempRows[index] && tempRows[index][func.action]) {
            tempRows[index][func.action].checked = true;
          }
        });
      }
    });

    let sortedData = sortBy(tempRows, 'name');
    setRows(sortedData);
    setIntialApplicationPermissions(
      JSON.parse(sessionStorage.getItem(AppConstants.SESSION_SOLUTION_KEY) || '{}')
    );
  };

  const formatAdminFeatures = (selectedData: any) => {
    //Admin Portal Features
    for (let i = 0; i < adminFeatures?.features?.length; i++) {
      adminFeatures.features[i] = {
        ...adminFeatures?.features[i],
        checked: { All: false, View: false }
      };
    }
    selectedData?.forEach(function (ele: any, index: number) {
      let adminFeatureIndex = findIndex(adminFeatures?.features, function (item: any) {
        return item.name === ele.feature;
      });
      ele?.functions?.forEach(function (inner: any) {
        if (adminFeatureIndex !== -1) {
          adminFeatures.features[adminFeatureIndex].checked[inner?.action] = true;
        }
      });
    });
    let sortedData = sortBy(adminFeatures.features, 'label');
    setAdminFeatureTableData(sortedData);
    setIntialAdminPermissions(
      JSON.parse(sessionStorage.getItem(AppConstants.ADMINPORTAL_FEATURES) || '{}')
    );
  };

  const getSolutionFeatures = async () => {
    setIsLoading(true);
    await getApplSolnFeatures(webPortal, setCollectData, collectdata, dispatch);
    setIsLoading(false);
  };

  const loadPermissionSet = async (id: any) => {
    setIsLoading(true);
    // let response = await getSettingById(id);
    // const { status, data } = response;
    // if (status == HTTP_STATUS.HTTP_OK) {
    //   let role = null;
    //   const roleId = data?.value?.roles[0]?.roleId;
    //   if (roleId) {
    //     const roleResponse = await getRole(roleId);
    //     if (roleResponse?.status == HTTP_STATUS.HTTP_OK) {
    //       role = roleResponse?.data;
    //     }
    //   }
    //   dispatch(setPermissionData({ ...response.data, role }));
    // } else {
    //   dispatch(
    //     setToastData({
    //       toastMessage: getFailureMessage(response),
    //       isToastActive: true,
    //       toastType: 'error'
    //     })
    //   );
    // }
    let response = await getMetadataInstance(permissionSetObject?.id, id);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      let role = null;
      const roleId = data?.attributes?.roles?.[0];
      if (roleId) {
        const roleResponse = await getRole(roleId);
        if (roleResponse?.status == HTTP_STATUS.HTTP_OK) {
          role = roleResponse?.data;
        }
      }
      dispatch(setPermissionData({ ...response.data, role }));
    } else {
      dispatch(
        setToastData({
          toastMessage: getFailureMessage(response),
          isToastActive: true,
          toastType: 'error'
        })
      );
    }
    setIsLoading(false);
  };

  const getOtherSettings = async (settings: any) => {
    // const responses = await Promise.all(settings.map((setting: any) => getSettingById(setting.id)));
    // let successResponses: any = [],
    //   rejectResponses: any = [];
    // responses.forEach((response: any) => {
    //   if (response.status === HTTP_STATUS.HTTP_OK) {
    //     successResponses.push(response.data);
    //   } else {
    //     rejectResponses.push(response.data.issues[0].details);
    //   }
    // });
    // setOtherSettings(JSON.stringify(successResponses));
    // setOriginalOtherSettingObj(successResponses);
    // setOtherSettingInvalidData(rejectResponses);

    const { successResponses, rejectResponses } = await loadOtherSettings(settings);
    setOtherSettings(JSON.stringify(successResponses));
    setOriginalOtherSettingObj(successResponses);
    setOtherSettingInvalidData(rejectResponses);
  };

  const resetPermissionData = () => {
    setCollectData({
      permissionDetails: {
        permissionName: '',
        description: ''
      },
      applicationFeatures: JSON.parse(
        sessionStorage.getItem(AppConstants.SESSION_SOLUTION_KEY) || '{}'
      ),
      adminPortalFeatures: JSON.parse(
        sessionStorage.getItem(AppConstants.ADMINPORTAL_FEATURES) || '{}'
      )
    });
  };

  const handleCancelEdit = () => {
    setOtherSettings(JSON.stringify(permissionSetData.attributes.permissions.settings));
    setCollectData({
      ...collectdata,
      applicationFeatures: intialApplicationPermissions,
      adminPortalFeatures: intialAdminPermissions
    });
  };

  const apiCallback = (response: any) => {
    dispatch(
      setToastData({
        toastMessage: `${response?.attributes?.name} ${t('T_UPDATE_SUCCESS_MSG')}`,
        isToastActive: true,
        toastType: 'success'
      })
    );
    dispatch(setPermissionData(response));
    setEdit(false);
    setIsLoading(false);
  };

  const handleValidation = () => {
    let errors: any = {
      permissionName: '',
      description: ''
    };

    errors['permissionName'] = validateName(collectdata.permissionDetails.permissionName.trim(), t);
    return errors;
  };

  const validator = () => {
    if (!collectdata.permissionDetails.permissionName.trim()) {
      return true;
    } else if (collectdata.permissionDetails.permissionName.trim()) {
      const errorDescription = handleValidation();
      if (
        Object.keys(errorDescription).length !==
        Object.keys(errorDescription).filter((validateError) => {
          return errorDescription[validateError as keyof any] === '';
        }).length
      ) {
        return true;
      }
    }
    return false;
  };

  const triggerEvent = async (flag: number, sortedFeature: any) => {
    let rows = createRows(getAppFeatureRows(intialApplicationPermissions, 'feature'));
    let selectedApplicationFeatures =
      flag === 2
        ? permissionSetData?.attributes?.permissions?.application
        : permissionSetData.value.permissions.application;
    let tempRows = [...rows],
      tempOriginalOtherSettingObj: any;
    selectedApplicationFeatures.forEach((ele: any, index: number) => {
      let eleIndex = tempRows.findIndex((item) => item.name == ele.feature);

      ele?.functions?.forEach((func: any) => {
        if (tempRows[index] && tempRows[index][func.action]) {
          tempRows[index][func.action].checked = true;
        }
      });
    });
    if (typeof originalOtherSettingObj !== 'string') {
      try {
        tempOriginalOtherSettingObj = JSON.stringify(originalOtherSettingObj);
      } catch (e) {
        tempOriginalOtherSettingObj = '';
      }
    } else {
      tempOriginalOtherSettingObj = originalOtherSettingObj;
    }
    const eventPayload: any = {
      userId: userId,
      organizationId: loggedInOrg?.organizationId,
      source: AppConstants.EVENT_SOURCE,
      type: AppConstants.EVENT_TYPE_UPDATE,
      time: getCurrentDate(),
      data: {}
    };
    if (!isEqual(tempRows, applicationFeatures?.value?.features)) {
      eventPayload.data.permissions = formatApplication(sortedFeature);
    }
    if (otherSettings !== tempOriginalOtherSettingObj) {
      eventPayload.data.settings = otherSettings;
    }
    // if (Object.keys(eventPayload.data).length) {
    //   const eventResponse = await createEvent(eventPayload);
    // }
  };

  const handleEdit = async (
    permissionDetails: any,
    applicationFeatures: any,
    adminFeatures: any,
    otherSettings: any
  ) => {
    setIsLoading(true);
    // const { data } = await getCategories(
    //   '',
    //   AppConstants.DEFAULT_PAGE,
    //   AppConstants.MAXIMUM_PAGE_SIZE
    // );

    const userData = JSON.parse(localStorage.getItem('user-profile') || '');
    // const categoryMap: Map<string, any> = new Map(
    //   data?.data?.map((category: any) => [category.name, category])
    // );
    // const permissionSetCategory = categoryMap.get(AppConstants.CATEGORY_PERMISSION_SETS);
    // const applicationModelCategory = categoryMap.get(AppConstants.CATEGORY_APPLICATION_MODEL);

    let settingObj: any = [],
      originalSettingObj: any = [];
    try {
      settingObj = JSON.parse(otherSettings);
    } catch (e) {
      settingObj = [];
    }
    try {
      originalSettingObj = JSON.parse(originalOtherSettingObj);
    } catch (e) {
      originalSettingObj = [];
    }
    const sortedFeature = applicationFeatures.map((feature: any) => {
      const isFunction = (key: string, options: any = {}) =>
        key !== 'name' && options?.[key]?.checked;

      return {
        feature: feature?.name,
        functions: Object.keys(feature)
          .filter((property: string) => isFunction(property, feature))
          .map((action: any) => {
            return {
              action,
              apiPermission: []
            };
          })
      };
    });

    const sortedAdminFeatures = adminFeatures.map((feature: any) => {
      return {
        feature: feature.name,
        functions: feature.checked.All
          ? [
              {
                action: 'All',
                apiPermissions: feature.functions.map((permission: any) => {
                  return permission.permissions[0].claim;
                })
              }
            ]
          : []
      };
    });

    const sortedSettings = settingObj?.map((setting: any) => {
      return {
        id: setting?.id,
        key: setting?.key,
        categories: [setting?.categories?.[0]?.name]
      };
    });

    const systemSettingBodyCategory = {
      id: permissionSetData.id,
      key: normalizeKey(permissionDetails.permissionName),
      // categories: [permissionSetCategory?.id],
      value: {
        name: permissionDetails.permissionName.trim(),
        organizationId: permissionSetData?.value?.organizationId,
        description: permissionDetails?.description || '',
        roles: permissionSetData?.value?.roles?.length
          ? [
              {
                ...permissionSetData?.value?.roles[0],
                settings:
                  settingObj && settingObj.length
                    ? settingObj.map((obj: any) => {
                        return { id: obj.id, key: obj.key };
                      })
                    : []
              }
            ]
          : [],
        permissions: {
          application: permissionSetData?.value?.roles?.length ? [] : sortedFeature,
          adminPortal: sortedAdminFeatures,
          settings: permissionSetData?.value?.roles?.length ? '' : settingObj // empty this
        },
        createdBy: permissionSetData?.value?.createdBy,
        createdOn: permissionSetData?.value?.createdOn,
        createdByOrg: permissionSetData?.value?.createdByOrg,
        updatedBy: userData?.userId,
        updatedOn: new Date().toISOString()
      },
      dataType: AppConstants.DATA_TYPE_JSON
    };

    const systemSettingBodyForMetadata = {
      label: permissionDetails.permissionName.trim(),
      attributes: {
        name: permissionDetails.permissionName.trim(),
        organizationId: permissionSetData?.attributes?.organizationId,
        description: permissionDetails?.description || '',
        roles: permissionSetData?.role?.name
          ? [permissionSetData?.role?.name]
          : permissionSetData?.attributes?.roles?.[0]
          ? [permissionSetData?.attributes?.roles?.[0]]
          : [],
        permissions: {
          application: sortedFeature,
          adminPortal: sortedAdminFeatures,
          settings: sortedSettings.length ? sortedSettings : [] // empty this
        },
        createdBy: permissionSetData?.attributes?.createdBy,
        createdOn: permissionSetData?.attributes?.createdOn,
        createdByOrg: permissionSetData?.attributes?.createdByOrg,
        updatedBy: userData?.userId,
        //updatedOn: new Date().toISOString()
        updatedOn: moment().toISOString().split('.')[0] + 'Z'
      }
    };
    let tempSolutionRole: any = '';
    // if (applicationFeatureError) {
    //   const response = await loadApplicationFeatures(
    //     permissionSetData,
    //     sortedFeature,
    //     applicationModelCategory,
    //     systemSettingBodyCategory
    //   );
    //   if (response.solutionRole) {
    //     tempSolutionRole = response.solutionRole;
    //     setSolutionRole(response.solutionRole);
    //   }
    //   if (response.applicationFeatureError) {
    //     setApplicationFeatureError(response.applicationFeatureError);
    //   }
    // } else {
    //   tempSolutionRole = solutionRole;
    // }

    const response = await updatePermissionSet(
      2,
      {
        permissionSetData,
        sortedFeature,
        systemSettingBodyCategory,
        setApplicationFeatureError,
        settingObj,
        originalSettingObj,
        solutionRole: tempSolutionRole
      },
      {
        permissionSetInstances: permissionSetObject,
        systemSettingBody: systemSettingBodyForMetadata,
        permissionSetId: params.id,
        triggerEvent
      }
    );
    if (response.contentChanged) {
      setContentChanged(true);
    }
    if (Object.keys(response.data).length) {
      apiCallback(response.data);
    }
    if (!response.isPermissionSetUpdated) {
      dispatch(
        setToastData({
          toastMessage: t('T_ERROR_SOMETHING_WRONG'),
          isToastActive: true,
          toastType: 'error'
        })
      );
      setIsLoading(false);
    }
  };

  const tabConfig = [
    {
      label: t('T_LBL_FOR_PERMISSION_SET_DETAILS'),
      component: (
        <div className="ml-3">
          <PermissionDetails
            detailsData={collectdata.permissionDetails}
            center={false}
            disabled={!edit}
            setDetailsData={(data: any) =>
              setCollectData({ ...collectdata, permissionDetails: data })
            }
          />
        </div>
      )
    },
    {
      label: t('T_LBL_APPLICATION_FEATURES'),
      component: (
        <>
          <ApplicationFeatureDataGrid
            applicationFeatures={applicationFeatures}
            resetData={resetPermissionData}
            rows={rows}
            setRows={setRows}
            columnFeature={columnFeature}
            active={edit}
          />
          {applicationFeatureError ? (
            <ul className="mx-4">
              <li className="text-red-600">{applicationFeatureError}</li>
            </ul>
          ) : null}
        </>
      )
    },
    {
      label: t('T_LBL_FOR_USER_PORTAL_FEATURES'),
      component: (
        <AdminFeaturesDataGrid
          tableData={adminFeatureTableData}
          setTableData={setAdminFeatureTableData}
          active={edit}
          theme={useTheme().theme}
        />
      )
    },
    {
      label: t('T_LBL_FOR_OTHERS_SETTINGS'),
      component: (
        <>
          <ApplicationSettings
            jsonString={otherSettings}
            setJsonString={setOtherSettings}
            isComponent
            isEdit={edit}
          />
          {otherSettingInvalidData.length ? (
            <ul className=" mx-4 my-4">
              {otherSettingInvalidData.map((data: any) => (
                <li className="text-red-600">{data}</li>
              ))}
            </ul>
          ) : null}
        </>
      )
    }
  ];

  return (
    <div className="w-full">
      <MenuButtonsPortal>
        {edit ? (
          <>
            <BiButton
              className={'mr-2 text-primary'}
              type="button"
              onClick={() => {
                setIsLoading(true);
                setEdit(false);
                handleCancelEdit();
              }}
            >
              {t('T_CANCEL')}
            </BiButton>

            <BiButton
              className={'bg-primary text-white'}
              type="button"
              disabled={validator()}
              onClick={() => {
                handleEdit(
                  collectdata.permissionDetails,
                  rows,
                  adminFeatureTableData,
                  otherSettings
                );
              }}
            >
              {t('T_SAVE')}
            </BiButton>
          </>
        ) : (
          <>
            <BiButton
              className={'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'}
              type="button"
              onClick={() => {
                navigate('/permissionset-list');
                if (!contentChanged) {
                  dispatch(setIsContentChanged(true));
                }
              }}
            >
              {t('T_BUTTON_BACK_TO_PERMISSION_SET_LIST')}
            </BiButton>
            <BiButton
              className={'bg-primary text-white'}
              type="button"
              disabled={!editPermission}
              onClick={() => {
                setEdit(true);
              }}
            >
              {t('T_EDIT')}
            </BiButton>
          </>
        )}
      </MenuButtonsPortal>

      <LoaderContainer isLoading={isLoading}>
        <div className="flex flex-row">
          <div
            className="ml-4 mt-4 w-10/12"
            style={{ border: '1px solid #D9D9D9', borderRadius: '7px' }}
          >
            <TabDrawer
              borderBottom={true}
              tabConfig={tabConfig}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="w-2/12 pl-3">
            {(permissionSetData?.createdOn || permissionSetData?.createdBy) && (
              <div>
                <AuditInfo
                  label={'CREATED'}
                  date={permissionSetData?.createdOn || ''}
                  user={permissionSetData?.createdBy || ''}
                />
              </div>
            )}
            {(permissionSetData?.updatedOn || permissionSetData?.updatedBy) && (
              <div>
                <AuditInfo
                  label={'LAST_MODIFIED'}
                  date={permissionSetData?.updatedOn || ''}
                  user={permissionSetData?.updatedBy || ''}
                />
              </div>
            )}
          </div>
        </div>
      </LoaderContainer>
    </div>
  );
};

export default ShowPermissionSet;
