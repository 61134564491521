import BaseAPI from './api';
import env from '../environment';

const globalHeaders = {
  'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;

export const createEvent = async (body: any) => {
  const headers = { ...globalHeaders };
  return await API.post(`${env.eventPath}/event`, body, { headers });
};
