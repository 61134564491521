import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DotMenu, { Options } from '../../../primitives/dotMenu/DotMenu';
import { MenuConstants } from '../../../../constants/MenuConstants';
import ActionDropdown from '../../../primitives/dropdowns/ActionDropdown';

interface BodyMapConfigKeyMenuProps {
  className?: string;
  iconSize?: string;
  dropdownMenu?: boolean;
  onOptionClick?: Function;
  editMode?: boolean;
}

const BodyMapConfigKeyMenu: React.FC<BodyMapConfigKeyMenuProps> = ({
  iconSize = '',
  className = '',
  dropdownMenu = false,
  onOptionClick,
  editMode = false
}) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<Options | null>();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedOption) {
      switch (selectedOption.key) {
        case MenuConstants.SURVEY_BODY_MAP_IMAGE_DELETE:
          if (onOptionClick) {
            onOptionClick(MenuConstants.SURVEY_BODY_MAP_IMAGE_DELETE);
          }
          break;
        case MenuConstants.SURVEY_BODY_MAP_IMAGE_DOWNLOAD:
          if (onOptionClick) {
            onOptionClick(MenuConstants.SURVEY_BODY_MAP_IMAGE_DOWNLOAD);
          }
          break;
      }
    }
  }, [selectedOption]);

  const dotMenuOptions: Array<any> = [
    {
      display: `${t('T_DELETE')}`,
      key: MenuConstants.SURVEY_BODY_MAP_IMAGE_DELETE
    }
  ];

  if (editMode) {
    dotMenuOptions.push({
      display: `${t('T_DOWNLOAD_FILE')}`,
      key: MenuConstants.SURVEY_BODY_MAP_IMAGE_DOWNLOAD
    });
  }

  return (
    <>
      <DotMenu
        options={dotMenuOptions}
        onSelectedOptionChange={(value) => {
          setSelectedOption(value);
        }}
        value={selectedOption}
      />
    </>
  );
};

export default BodyMapConfigKeyMenu;
