import React, { forwardRef, useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon, PlusIcon, XIcon } from '@heroicons/react/solid';
import Asterix from '../../assets/asterix.svg';
import { Input, Label, TextArea } from '../AccountProfile/ProfileStyles';
import styled from 'styled-components/macro';
import { Switch, ToggleButton } from '../shared/Fields/Switch';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import {
  METADATA_INSTANCE_DATA_TYPE_USER_INPUTS,
  METADATA_OBJECT_DATA_TYPE_ENUM,
  METADATA_OBJECT_DATA_TYPE_BOOLEAN,
  METADATA_OBJECT_DATA_TYPE_DATE,
  METADATA_OBJECT_DATA_TYPE_DATETIME,
  METADATA_OBJECT_DATA_TYPE_INTEGER,
  METADATA_OBJECT_DATA_TYPE_NUMBER,
  METADATA_OBJECT_DATA_TYPE_OBJECT,
  METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY,
  METADATA_OBJECT_DATA_TYPE_STRING_ARRAY,
  METADATA_OBJECT_DATA_TYPE_TIME,
  METADATA_OBJECT_DATA_TYPE_STRING
} from './metadataUtil';
import InputField from '../shared/Fields/InputField';
import { AppConstants } from '../../constants/AppConstants';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';

const DEFAULT_KEY_ID = 'key';
const DEFAULT_VALUE_KEY = 'value';

const Image = styled.img`
  width: 8px;
  height: 8px;
`;

interface FormSchema {
  name: string;
  type: string;
  editable?: boolean;
  maxLength?: number;
  isRequired?: boolean;
  min?: number;
  max?: number;
  attributes?: FormSchema[];
  value?: any;
  keyPath?: string;
  enum?: any;
}

interface JSONFormCreatorProps {
  schema: FormSchema[];
  setSchema: Function;
  disabled: boolean;
  addToSchemaArray: Function;
  deleteFromSchemaArray: Function;
  addDeleteToStringArray: Function;
  isEditMode: boolean;
}

const calculateRows = (content: string) => {
  let length = content.length;
  return length > 64 && length < 128
    ? 2
    : length >= 128 && length < 255
    ? 3
    : length >= 255
    ? 4
    : 1;
};

const JSONFormCreator: React.FC<JSONFormCreatorProps> = ({
  schema,
  setSchema,
  disabled,
  addToSchemaArray,
  deleteFromSchemaArray,
  addDeleteToStringArray,
  isEditMode
}) => {
  const { t } = useTranslation();

  const [collapsedMap, setCollapsedMap] = useState<{ [key: string]: boolean }>({}); //eg {test1:false}

  const toggleCollapse = (fieldName: string) => {
    setCollapsedMap((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName]
    }));
  };

  const renderField = (field: FormSchema) => {
    const {
      name,
      type,
      editable = true,
      maxLength,
      isRequired = false,
      min,
      max,
      attributes,
      value,
      keyPath = '',
      enum: enumTypes = []
    } = field;

    const indent = 5;
    const verticalMargin = 3;
    const dataType =
      type !== METADATA_OBJECT_DATA_TYPE_OBJECT &&
      type !== METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY &&
      type !== METADATA_OBJECT_DATA_TYPE_STRING_ARRAY
        ? `(${type})`
        : '';

    const DateInputField = forwardRef(({ value, onClick }: any, ref) => (
      <Input
        type={'text'}
        id={name}
        name={name}
        className="!w-64 rounded border px-3 py-2"
        required={isRequired}
        value={value}
        onClick={onClick}
        ref={ref}
        onChange={(e: any) => {}}
        data-testid="datetime-input"
        disabled={disabled || (isEditMode && !editable)} //|| !editable
      />
    ));

    if (METADATA_INSTANCE_DATA_TYPE_USER_INPUTS.includes(type)) {
      const boolValue: boolean = /true/i.test(value);
      return (
        <div key={name} className={`ml-${indent} mb-${verticalMargin}`}>
          <div className="flex flex-row">
            {type === METADATA_OBJECT_DATA_TYPE_STRING_ARRAY && !disabled && (
              <button className="mr-2 focus:outline-none" onClick={() => toggleCollapse(name)}>
                {collapsedMap[name] ? (
                  <ChevronRightIcon className="h-6 w-6 text-black" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6 text-black" />
                )}
              </button>
            )}
            <Label htmlFor={keyPath} className="flex items-center">
              {isRequired ? <Image src={Asterix} className="mr-1" /> : ''}
              {name} {dataType}
            </Label>
            {type === METADATA_OBJECT_DATA_TYPE_STRING_ARRAY && !disabled ? (
              <button
                className="ml-2  hover:text-gray-500 focus:outline-none"
                onClick={() => addDeleteToStringArray(keyPath, 'add')}
                data-testid="String[]-addElement"
              >
                <PlusIcon className="h-5 w-5 text-black" />
              </button>
            ) : (
              ''
            )}
          </div>

          {type === METADATA_OBJECT_DATA_TYPE_STRING_ARRAY ? (
            !collapsedMap[name] &&
            value?.map((strValue: string, strIndex: number) => {
              return (
                <div
                  className={`ml-${indent} ${
                    value.length - 1 !== strIndex && `mb-${verticalMargin}`
                  } relative right-2 border-l-[1px] border-dashed border-l-gray-600 pl-3`}
                >
                  {value.length > 1 ? (
                    <div className="flex items-center">
                      <p className="mb-1 font-bold">[{strIndex}]</p>
                      {!disabled ? (
                        <button
                          className="ml-2  hover:text-gray-500 focus:outline-none"
                          data-testid="String[]-removeElement"
                          onClick={() => addDeleteToStringArray(keyPath, 'delete', strIndex)}
                        >
                          <XIcon className="h-6 w-6 text-black" />
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  <TextArea
                    data-testid="String[]-textfield"
                    id={`${name}-${strIndex}`}
                    name={name}
                    className={`!w-3/4  rounded border px-3 py-2 ${
                      strValue.length > 255 && '!resize-y'
                    }`}
                    maxLength={maxLength}
                    required={isRequired}
                    value={strValue}
                    onChange={(e: any) => {
                      setSchema(keyPath, e.target.value, strIndex);
                    }}
                    disabled={disabled || (isEditMode && !editable)}
                    rows={calculateRows(strValue)}
                    cols={20}
                  />
                </div>
              );
            })
          ) : type === METADATA_OBJECT_DATA_TYPE_BOOLEAN ? (
            <div className="flex flex-row">
              <Switch
                toggle={boolValue}
                onClick={() => setSchema(keyPath, !boolValue)}
                data-testid={'boolean-field'}
                id={name}
              >
                <ToggleButton toggle={boolValue} />
              </Switch>
              <div className="ml-3 self-end">{boolValue ? `${t('TRUE')}` : `${t('FALSE')}`}</div>
            </div>
          ) : type === METADATA_OBJECT_DATA_TYPE_ENUM &&
            enumTypes.length &&
            typeof enumTypes[0] === 'string' ? (
            <div className="!w-full">
              <PickList
                fieldKey={DEFAULT_KEY_ID}
                fieldValues={[DEFAULT_VALUE_KEY]}
                selectedItems={[{ [DEFAULT_KEY_ID]: value, [DEFAULT_VALUE_KEY]: value }]}
                onSelectedChange={(item: any) => {
                  setSchema(keyPath, item[0][DEFAULT_VALUE_KEY]);
                }}
                items={enumTypes.map((item: string) => {
                  return { key: item, value: item };
                })}
                disabled={disabled || (isEditMode && !editable)}
                readOnly={isEditMode && !editable}
                type={PICKLIST_TYPES.SINGLE}
              />
            </div>
          ) : type === METADATA_OBJECT_DATA_TYPE_DATE ? (
            <DatePicker
              selected={value ? new Date(value) : null}
              disabledKeyboardNavigation
              dateFormat={`yyyy-MM-dd`}
              scrollableYearDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={(date: any) => {
                setSchema(
                  keyPath,
                  moment(date).format(AppConstants.DEFAULT_METADATA_INSTANCE_DATE_FORMAT)
                );
              }}
              customInput={<DateInputField />}
            />
          ) : type === METADATA_OBJECT_DATA_TYPE_DATETIME ? (
            <DatePicker
              selected={value ? new Date(value) : null}
              disabledKeyboardNavigation
              showTimeSelect
              timeIntervals={30}
              dateFormat={'yyyy-MM-dd h:mm aa'}
              onChange={(date: any) => {
                setSchema(
                  keyPath,
                  moment(date)
                    .utc()
                    .format(AppConstants.DEFAULT_METADATA_INSTANCE_UTC_DATETIME_FORMAT)
                );
              }}
              customInput={<DateInputField />}
            />
          ) : type === METADATA_OBJECT_DATA_TYPE_TIME ? (
            <DatePicker
              selected={
                value
                  ? new Date(
                      `01/01/1970 ${moment(
                        value,
                        AppConstants.DEFAULT_METADATA_INSTANCE_TIME_FORMAT
                      ).format('HH:mm:ss')}`
                    )
                  : null
              }
              disabledKeyboardNavigation
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              dateFormat={`h:mm aa`}
              onChange={(date: any) => {
                setSchema(
                  keyPath,
                  moment(date).format(AppConstants.DEFAULT_METADATA_INSTANCE_TIME_FORMAT)
                );
              }}
              customInput={<DateInputField />}
            />
          ) : type === METADATA_OBJECT_DATA_TYPE_STRING ? (
            <TextArea
              id={name}
              data-testid={`textfield`}
              name={name}
              className={`!w-3/4 rounded border px-3 py-2 ${value.length > 255 && '!resize-y'}`}
              maxLength={maxLength}
              required={isRequired}
              value={value}
              onChange={(e: any) => {
                setSchema(keyPath, e.target.value);
              }}
              disabled={disabled || (isEditMode && !editable)}
              rows={calculateRows(value)}
              cols={20}
            />
          ) : (
            <Input
              type={
                [METADATA_OBJECT_DATA_TYPE_NUMBER, METADATA_OBJECT_DATA_TYPE_INTEGER].includes(type)
                  ? 'number'
                  : type === METADATA_OBJECT_DATA_TYPE_DATETIME
                  ? 'datetime-local'
                  : type === METADATA_OBJECT_DATA_TYPE_DATE
                  ? 'date'
                  : 'text'
              }
              id={name}
              data-testid={`textfield`}
              name={name}
              className={`${
                [METADATA_OBJECT_DATA_TYPE_NUMBER, METADATA_OBJECT_DATA_TYPE_INTEGER].includes(type)
                  ? 'w-48'
                  : '!w-1/2'
              } rounded border px-3 py-2`}
              maxLength={maxLength}
              required={isRequired}
              min={min}
              max={max}
              value={value}
              onChange={(e: any) => {
                setSchema(
                  keyPath,
                  type === METADATA_OBJECT_DATA_TYPE_NUMBER
                    ? min !== undefined && max !== undefined
                      ? Number(e.target.value) < min
                        ? min
                        : Number(e.target.value) > max
                        ? max
                        : Number(e.target.value)
                      : Number(e.target.value)
                    : type === METADATA_OBJECT_DATA_TYPE_INTEGER
                    ? min !== undefined && max !== undefined
                      ? parseFloat(e.target.value) < min
                        ? min
                        : parseFloat(e.target.value) > max
                        ? max
                        : parseFloat(e.target.value)
                      : parseFloat(e.target.value)
                    : e.target.value
                );
              }}
              disabled={disabled || (isEditMode && !editable)}
            />
          )}
        </div>
      );
    }
    if (
      type === METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY &&
      Array.isArray(attributes) &&
      Array.isArray(attributes[0])
    ) {
      return (
        <div key={name} className={`ml-${indent} mt-${verticalMargin}`}>
          <div className="flex items-center">
            <button className="mr-2 focus:outline-none" onClick={() => toggleCollapse(name)}>
              {collapsedMap[name] ? (
                <ChevronRightIcon className="h-6 w-6 text-black" />
              ) : (
                <ChevronDownIcon className="h-6 w-6 text-black" />
              )}
            </button>
            <Label htmlFor={keyPath} className="mb-1 flex items-center font-bold">
              {isRequired ? <Image src={Asterix} className="mr-1" /> : ''}
              {name}
            </Label>
            {!disabled ? (
              <button
                className="ml-2  hover:text-gray-500 focus:outline-none"
                onClick={() => addToSchemaArray(keyPath)}
              >
                <PlusIcon className="h-5 w-5 text-black" />
              </button>
            ) : (
              ''
            )}
          </div>

          {!collapsedMap[name] && (
            <div className={`ml-${indent}`}>
              {attributes.map((subAttributes: any, subIndex: number) => (
                <div key={`${name}-${subIndex}`}>
                  <div className="flex items-center">
                    <button
                      className="mr-2 focus:outline-none"
                      onClick={() => toggleCollapse(`${name}-${subIndex}`)}
                    >
                      {collapsedMap[`${name}-${subIndex}`] ? ( //Add Collapse
                        <ChevronRightIcon className="h-6 w-6 text-black" />
                      ) : (
                        <ChevronDownIcon className="h-6 w-6 text-black" />
                      )}
                    </button>
                    <p className="mb-1 font-bold">[{subIndex}]</p>
                    {!disabled ? (
                      <button
                        className="ml-2  hover:text-gray-500 focus:outline-none"
                        onClick={() => deleteFromSchemaArray(keyPath, subIndex)}
                      >
                        <XIcon className="h-6 w-6 text-black" />
                      </button>
                    ) : (
                      ''
                    )}
                  </div>

                  {!collapsedMap[`${name}-${subIndex}`] && ( //Add Collapse TODO
                    <div className={`ml-${indent + 1}`}>
                      {subAttributes.map((subAttribute: FormSchema, attributeIndex: number) => (
                        <JSONFormCreator
                          key={`${name}-${subIndex}-${attributeIndex}`}
                          schema={[subAttribute]}
                          setSchema={setSchema}
                          disabled={disabled}
                          addToSchemaArray={addToSchemaArray}
                          deleteFromSchemaArray={deleteFromSchemaArray}
                          addDeleteToStringArray={addDeleteToStringArray}
                          isEditMode={isEditMode}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }

    if (type === METADATA_OBJECT_DATA_TYPE_OBJECT_ARRAY && attributes) {
      return (
        <div key={name} className={`ml-${indent} mt-${verticalMargin}`}>
          <div className="flex items-center">
            <button className="mr-2 focus:outline-none" onClick={() => toggleCollapse(name)}>
              {collapsedMap[name] ? (
                <ChevronRightIcon className="h-6 w-6 text-black" />
              ) : (
                <ChevronDownIcon className="h-6 w-6 text-black" />
              )}
            </button>
            <Label htmlFor={keyPath} className="mb-1 flex items-center font-bold">
              {isRequired ? <Image src={Asterix} className="mr-1" /> : ''}
              {name}
            </Label>
            {!disabled ? (
              <button className="ml-2 focus:outline-none" onClick={() => addToSchemaArray(keyPath)}>
                <PlusIcon className="h-5 w-5 text-black" />
              </button>
            ) : (
              ''
            )}
          </div>

          {!collapsedMap[name] && (
            <div className={`ml-${indent + 1}`}>
              {attributes.map((attribute) => (
                <JSONFormCreator
                  key={attribute.name}
                  schema={[attribute]}
                  setSchema={setSchema}
                  disabled={disabled}
                  addToSchemaArray={addToSchemaArray}
                  deleteFromSchemaArray={deleteFromSchemaArray}
                  addDeleteToStringArray={addDeleteToStringArray}
                  isEditMode={isEditMode}
                />
              ))}
            </div>
          )}
        </div>
      );
    }

    if (type === 'Object' && attributes) {
      return (
        <div key={name} className={`ml-${indent} mt-${verticalMargin}`}>
          <div className="flex items-center">
            <button className="mr-2 focus:outline-none" onClick={() => toggleCollapse(name)}>
              {collapsedMap[name] ? (
                <ChevronRightIcon className="h-6 w-6 text-black" />
              ) : (
                <ChevronDownIcon className="h-6 w-6 text-black" />
              )}
            </button>
            <Label htmlFor={keyPath} className="mb-1 flex items-center font-bold">
              {isRequired ? <Image src={Asterix} className="mr-1" /> : ''}
              {name}
            </Label>
          </div>

          {!collapsedMap[name] && (
            <div className={`ml-${indent + 1}`}>
              {Object.keys(attributes).map((attrName: any) => (
                <JSONFormCreator
                  key={attrName}
                  schema={[attributes[attrName]]}
                  setSchema={setSchema}
                  disabled={disabled}
                  addToSchemaArray={addToSchemaArray}
                  deleteFromSchemaArray={deleteFromSchemaArray}
                  addDeleteToStringArray={addDeleteToStringArray}
                  isEditMode={isEditMode}
                />
              ))}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <ul className="relative right-3 border-l-[1px] border-dashed border-l-gray-600">
      {schema.map(renderField)}
    </ul>
  );
};

export default JSONFormCreator;
