export const a = '123';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../../hooks/queryParams';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TemplateTable from '../../shared/tables/TemplateTable';
import { ITableHeader } from '../../shared/tables/models/ITableHeader';
import Checkbox from '../../shared/Fields/Checkbox';
import { useTheme } from '../../../context/themeContext';
import { cloneDeep } from 'lodash';
import { AppConstants } from '../../../constants/AppConstants';
import LoaderContainer from '../../shared/loaderContainer/LoaderContainer';
import { deleteManifestItem, setManifest } from '../../../app/migrationExportReducer';
import { ActionButtons } from '../../Reusable/ActionButton';

const Container = styled.div`
  width: 100%;
  min-height: 25vh;
  max-height: 90vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

interface ModulesTableProps {
  config: any;
  toggleModal: Function;
  type: 'selection' | 'preview';
}

const ModulesTable = ({ config, toggleModal, type }: ModulesTableProps) => {
  const query = useQuery();
  const params = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { tableHeaders: propsHeaders, apiData, searchField, key: reducerKey, title } = config;

  const manifestReducer = useAppSelector(
    (state: any) => state.migrationExport.manifest?.[reducerKey]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsClone, setRowsClone] = useState(apiData);
  const [size, setSize] = useState(AppConstants.MODULE_LIST_DEFAULT_PAGE_SIZE);
  const [currentActivePage, setCurrentActivePage] = useState(0);
  const [currentPage, setCurrentPage] = useState(AppConstants.DEFAULT_PAGE);
  const [selectedItemsSet, setSelectedItemsSet] = useState(new Set<string>());

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>([]);

  const headerBuilder = () => {
    let headers = [...propsHeaders];
    if (type === 'selection') {
      headers.unshift({
        key: 'checked',
        description: '',
        sortEnabled: false,
        width: '5%',
        sortingInformation: {
          order: ''
        },
        nastedData: true,
        render: (data: any, index: any) => {
          return (
            <>
              <Checkbox
                className="mx-auto"
                height="20px"
                width="20px"
                checked={selectedItemsSet.has(data.id)}
                onChange={() => {
                  const { id } = data;
                  let existingSet = new Set(selectedItemsSet);
                  if (existingSet.has(id)) {
                    existingSet.delete(id);
                    setSelectedItemsSet(existingSet);
                    return;
                  }
                  existingSet.add(id);
                  setSelectedItemsSet(existingSet);
                }}
                testID="addModuleCheckbox"
              />
            </>
          );
        }
      });
    } else {
      headers.push({
        key: 'buttons',
        description: t('T_ACTIONS'),
        sortEnabled: false,
        width: '13%',
        sortingInformation: {
          order: ''
        }
      });
    }

    return headers;
  };

  useEffect(() => {
    if (type === 'selection') {
      setRows(apiData);
    } else {
      let filteredData = apiData.filter((obj: any) =>
        manifestReducer.find((item: any) => obj.id === item.id)
      );
      setRows(filteredData);
    }
    setTableHeaders(headerBuilder());
  }, [selectedItemsSet]);

  useEffect(() => {
    if (manifestReducer.length) {
      let manifestSet: Set<string> = new Set(
        manifestReducer.map((item: any) => {
          return item.id;
        })
      );
      setSelectedItemsSet(manifestSet);
    }
  }, [manifestReducer]);

  const saveSelection = () => {
    let arr = Array.from(selectedItemsSet);
    let value = arr.map((id: string) => {
      return { id };
    });
    dispatch(setManifest({ key: reducerKey, value }));
    toggleModal(false);
  };

  const refreshTableData = (name: string) => {
    let copy = cloneDeep(rowsClone);
    let searchResult = copy.filter((item: any) => item?.[searchField].includes(name));
    setRows(searchResult);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentActivePage(currentPage * size - size);
  }, [size, currentPage]);

  const deleteItem = (index: any) => {
    dispatch(deleteManifestItem({ key: reducerKey, id: manifestReducer[index]?.id }));
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <Container>
        <>
          <div className="w-full">
            <TemplateTable
              tableData={
                rows.length
                  ? rows.slice(currentActivePage, currentActivePage + size)
                  : `${t('T_NO_DATA_AVAILABLE_MSG')}`
              }
              size={size.toString()}
              currentPage={currentPage.toString()}
              setSize={setSize}
              setCurrentPage={setCurrentPage}
              templateSelector={() => {}}
              onRefreshTableData={refreshTableData}
              tableHeaders={tableHeaders}
              setTableHeaders={setTableHeaders}
              searchPlaceholder={`Search ${t(title)}`}
              containerClass="mt-0"
              tableClassName="!pb-0"
              isSearchable={type === 'selection'}
              isPaginated={false}
              isLocalPaginator={true}
              totalDataLength={rows.length}
              tableName={t('T_SURVEY_SELECT_QUESTIONS')}
              theme={useTheme().theme}
              showDeleteButton={type === 'preview'}
              deleteOnClick={deleteItem}
              disablePageSize
            />
          </div>
        </>

        {type === 'selection' ? (
          <div className="w-full p-5">
            <ActionButtons
              primaryButtonLabel={`Add Selected (${selectedItemsSet.size})`}
              primaryButtonAction={saveSelection}
              secondaryButtonLabel={t('T_CANCEL')}
              secondaryButtonAction={() => {
                toggleModal(false);
              }}
              primaryButtonWidth="full"
              primaryButtonClass={'px-4'}
            />
          </div>
        ) : (
          ''
        )}
      </Container>
    </LoaderContainer>
  );
};

export default ModulesTable;
