import { createSlice } from '@reduxjs/toolkit';

export const contextSlice = createSlice({
  name: 'context',
  initialState: {
    contextSelected: null,
    modeledTypes: [],
    predefinedKeys: [],
    appKeys: [],
    buttonFunction: null,
    settings: null,
    orgSettings: null,
    orgPredefinedKeys: [],
    orgAppKeys: [],
    orgModeledTypes: [],
    appContext: null
  },
  reducers: {
    selectContext: (state, action) => {
      state.contextSelected = action.payload;
    },
    setPredefinedKeys: (state, action) => {
      state.predefinedKeys = action.payload;
    },
    setModeledTypes: (state, action) => {
      state.modeledTypes = action.payload;
      state.orgModeledTypes = action.payload;
    },
    setAppKeys: (state, action) => {
      state.appKeys = action.payload;
    },
    setButtonFlunction: (state, action) => {
      state.buttonFunction = action.payload;
    },
    setAppSettings: (state, action) => {
      state.settings = action.payload;
    },
    setOrgSettings: (state, action) => {
      state.orgSettings = action.payload;
    },
    setOrgPredefinedKeys: (state, action) => {
      state.orgPredefinedKeys = action.payload;
    },
    setOrgAppKeys: (state, action) => {
      state.orgAppKeys = action.payload;
    },
    setOrgModeledTypes: (state, action) => {
      state.orgModeledTypes = action.payload;
      state.modeledTypes = action.payload;
    },
    setAppContext: (state, action) => {
      state.appContext = action.payload;
    }
  }
});

export const {
  selectContext,
  setPredefinedKeys,
  setModeledTypes,
  setAppKeys,
  setButtonFlunction,
  setAppSettings,
  setOrgSettings,
  setOrgPredefinedKeys,
  setOrgAppKeys,
  setOrgModeledTypes,
  setAppContext
} = contextSlice.actions;

export default contextSlice.reducer;
