import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import { fileCode } from '../../assets/components/svgs';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { createApplication, getApplication, updateApplication } from '../../http/metadata-service';
import JSONEditor from '../AppSettings/JSONEditor';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import Card from '../shared/cards/Card';
import InputField from '../shared/Fields/InputField';
import RadioButton from '../shared/Fields/RadioButton';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import MetadataMenu from './MetadataMenu';
import { setApplication } from '../../app/metadataReducer';
import { isEmpty, isEqual } from 'lodash';
import { RouteConstants } from '../../constants/RouteConstants';
import { fieldIsEmpty, validateNameLength } from '../../util/validators';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { type } from '@testing-library/user-event/dist/type';
import AuditInfo from '../Common/AuditInfo';

/**
 * @parentId metadata
 * @manager Create, View, Edit - Metadata Application
 * @overview Create, View, Edit of Metadata Application
 *   <section>
 *       <p>
 *           <b>View Metadata Application</b><br/>
 *           Clicking on metadata application row on the application listing page will take user to View Metadata Application Page in READ-ONLY mode. Clicking on <b>Back to Metadata</b> will take the user to metadata application listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i>
 *           <br/><br/>
 *           <b>Edit Metadata Application</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Display Name, Active, Description & Properties</b> fields. Clicking on <b>Cancel</b> will take the user to metadata application listing page.
 *           <br/><br/>
 *           <b>Create Metadata Application</b><br/>
 *           Clicking on <b>New Application</b> button on metadata application listing page will take user to New Metadata Application creation page. Clicking on <b>Cancel</b> will take the user to metadata application listing page.
 *           <br/><br/>
 *           User can create new metadata application by entering <b>Name</b> & <b>Display Name</b> mandatory field and clicking on <b> Save </b> button on the admin portal toolbar.
 *           <b>Description</b> & <b>Properties</b> are additional non mandatory fields which user can use to enter more detail about the application. Description is an freetext field & Properties is an JSON (Javascript Object Notation).
 *       </p>
 *
 *       <p>
 *          <h4>View/Edit Metadata Application Dropdown Menu</h4>
 *          Following are the menu items listed under metadata application <b>Manage Metadata</b> drop down.
 *          <br>
 *          <ul>
 *            <li>Edit Metadata</li>
 *            <li>View Objects</li>
 *            <li>Add Objects</li>
 *          </ul>
 *       </p>
 *
 *       <p>
 *          <h4>View/Create/Edit Metadata Application Fields</h4>
 *          Create/View/Edit metadata application page will display below fields with following validation constraints.
 *          <br/>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Active</td>
 *               <td>Yes</td>
 *               <td>Boolean</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Display Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Description</td>
 *               <td>No</td>
 *               <td>String</td>
 *               <td>4096</td>
 *            </tr>
 *            <tr>
 *               <td>Properties</td>
 *               <td>No</td>
 *               <td>JSON(Javascript Object Notation)</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> Active status is set to <b>TRUE</b> by default for new metadata application. Setting it to <b>FALSE</b> will mark the object READ-ONLY and will block any new creation or editing of object instances.
 *       </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       This section describes the Create Metadata Application Status Code information.
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_PARAM_DUPLICATE</td>
 *               <td>Duplicate name, <b>Name</b> for resource type MetadataApplication.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create metadata application page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create/View/Edit metadata application.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Metadata Application</td>
 *               <td>/applications/{id}</td>
 *               <td>GET</td>
 *               <td>metadata-service.metadata-application.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Metadata Application</td>
 *               <td>/applications/{id}</td>
 *               <td>PUT</td>
 *               <td>metadata-service.metadata-application.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Metadata Application</td>
 *               <td>/applications</td>
 *               <td>POST</td>
 *               <td>metadata-service.metadata-application.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>List users (View & Edit Metadata Application)</td>
 *               <td>/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create metadata application</p>
 *   </section>
 */
const CreateMetadata = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isEdit, setIsEdit] = useState(!params?.id);
  const [jsonString, setJsonString] = useState<string>('{}');

  const [isLoading, setIsLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState<string>('');
  const [disableButton, setDisableButton] = useState(false);

  const application = useAppSelector((state) => state.metadata.application);

  const [formData, setFormData] = useState<any>({
    name: '',
    active: true,
    displayName: '',
    description: '',
    properties: ''
  });
  const [formError, setFormError] = useState<any>({
    name: '',
    displayName: '',
    description: ''
  });

  useEffect(() => {
    const init = async () => {
      if (params?.id) {
        setIsLoading(true);
        await getMetaDataAppln(params?.id);
        setIsLoading(false);
      } else {
        dispatch(
          setURLPaths([
            {
              key: RouteConstants.ROUTE_MDS_APPLN_CREATE,
              label: 'Metadata > Applications > New Application'
            }
          ])
        );
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (jsonString) {
      setFormData({ ...formData, properties: jsonString });
    }
  }, [jsonString]);

  useEffect(() => {
    if (disableButton) {
      setDisableButton(false);
      setFormError({});
    }
  }, [formData]);

  const getCreatedByName = async (userId: string) => {
    const users: Array<any> = await getUserProfiles([userId]);
    if (users.length) {
      setCreatedBy(getUserName(users[0]));
    }
  };

  const getMetaDataAppln = async (id: string) => {
    let namespace: any = application;
    if (!namespace) {
      const response = await getApplication(id);
      const { status, data } = response;
      if (status === HTTP_STATUS.HTTP_OK) {
        namespace = data;
      }
    }

    if (namespace) {
      const { name, properties, updatedOn, createdOn, createdBy } = namespace;
      dispatch(setApplication({ ...namespace }));
      await getCreatedByName(createdBy);
      setFormData({
        ...namespace,
        updatedOn: moment(updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT),
        createdOn: moment(createdOn).format(AppConstants.DEFAULT_DATE_FORMAT)
      });
      setJsonString(JSON.stringify(properties));

      dispatch(
        setURLPaths([
          {
            key: RouteConstants.ROUTE_MDS_APPLN_VIEW.replace(':id', id),
            label: `Metadata > Applications > ${name}`
          }
        ])
      );
    }
  };

  const disableSave = () => {
    let isSaveDisabled = false;
    if (disableButton) {
      return disableButton;
    }
    let jsonError: boolean = false;
    const { name, displayName, description = '', properties, active } = formData;
    if (params.id) {
      const {
        name: initialName,
        displayName: initialDisplayName,
        description: initialDescription,
        properties: initialProperties,
        active: initialActive
      } = application;

      let propertiesJSON: any = properties;
      try {
        propertiesJSON = JSON.parse(JSON.stringify(properties));
      } catch (e) {
        jsonError = true;
      }
      if (
        name.trim() === initialName &&
        displayName.trim() === initialDisplayName &&
        description &&
        description.trim() === initialDescription &&
        active === initialActive &&
        isEqual(propertiesJSON, initialProperties)
      ) {
        isSaveDisabled = true;
      } else {
        isSaveDisabled = false;
      }
    } else {
      if (properties) {
        try {
          JSON.parse(properties.trim());
        } catch (e) {
          jsonError = true;
        }
      }
      isSaveDisabled = isEmpty(name.trim()) || isEmpty(displayName.trim());
    }
    return isSaveDisabled || jsonError;
  };

  const validateFormData = () => {
    let errors: any = {
      name: '',
      displayName: '',
      description: ''
    };
    errors['name'] = fieldIsEmpty(formData.name.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(formData.name.trim(), 1024)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_TOO_LONG_MSG');
    errors['displayName'] = fieldIsEmpty(formData.displayName.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(formData.displayName.trim(), 1024)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_TOO_LONG_MSG');
    errors['description'] = fieldIsEmpty(formData?.description?.trim())
      ? ''
      : validateNameLength(formData.description.trim(), 4096)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_LENGTH_MSG_4096');
    setFormError({ ...errors });
    return errors;
  };

  const handleSave = async () => {
    const errors = validateFormData();
    if (
      Object.keys(errors).length !==
      Object.keys(errors).filter((validateError) => {
        return errors[validateError] === '';
      }).length
    ) {
      setDisableButton(true);
      return;
    }
    setIsLoading(true);
    const { name, displayName = '', description = '', active = true, properties = '' } = formData;
    const payload = {
      name: name.trim(),
      displayName: (displayName && displayName.trim()) || '',
      description: (description && description.trim()) || '',
      active,
      properties:
        typeof properties === 'string'
          ? properties.trim().length
            ? JSON.parse(properties)
            : null
          : properties
    };

    if (!params?.id) {
      const response = await createApplication(payload);
      if (response.status === HTTP_STATUS.HTTP_CREATED) {
        dispatch(
          setToastData({
            toastMessage: t('T_NEW_METADATA_CREATED_SUCCESS_MESSAGE').replace('%s', name),
            isToastActive: true,
            toastType: 'success'
          })
        );
        setIsLoading(false);
        navigate(RouteConstants.ROUTE_MDS_APPLN_LIST);
      } else {
        dispatch(
          setToastData({
            toastMessage: getFailureMessage(response),
            isToastActive: true,
            toastType: 'error'
          })
        );
        setIsLoading(false);
      }
    } else {
      const response = await updateApplication(params.id, payload);
      if (response.status === HTTP_STATUS.HTTP_OK) {
        dispatch(
          setToastData({
            toastMessage: t('T_METADATA_UPDATED_SUCCESS_MESSAGE').replace('%s', name),
            isToastActive: true,
            toastType: 'success'
          })
        );
        setIsEdit(false);
      } else {
        dispatch(
          setToastData({
            toastMessage: getFailureMessage(response),
            isToastActive: true,
            toastType: 'error'
          })
        );
      }
      setIsLoading(false);
    }
  };

  const getCancelButtonText = () => {
    if (!isEdit) {
      return t('T_BACK_TO_METADATA');
    }
    return t('T_CANCEL');
  };

  const handleChange = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleOnCancel = () => {
    const { name, displayName, description, properties, active } = application;
    setFormData({ ...formData, name, displayName, description, properties, active });
    setJsonString(JSON.stringify(properties));
    setIsEdit(false);
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <MenuButtonsPortal>
        <BiButton
          className="bg-white text-primary"
          type="button"
          onClick={() => {
            if (!params?.id) {
              navigate(-1);
              return;
            }
            if (isEdit) {
              handleOnCancel();
            } else {
              navigate(RouteConstants.ROUTE_MDS_APPLN_LIST);
            }
          }}
        >
          {getCancelButtonText()}
        </BiButton>
        {isEdit ? (
          <BiButton
            className="bg-primary text-white"
            type="button"
            onClick={handleSave}
            disabled={disableSave()}
          >
            {t('T_SAVE')}
          </BiButton>
        ) : (
          <MetadataMenu metadata={application || {}} setEdit={setIsEdit} />
        )}
      </MenuButtonsPortal>
      <div
        className="flex max-h-screen w-full flex-col justify-start overflow-y-scroll  bg-gray-100 px-2 py-3"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <div className="flex">
          <div className="flex w-1/2">
            <div className="p-2"></div>
            <Card
              title="Metadata Details"
              icon={fileCode}
              alt="file-code-icon"
              className="w-full"
              height={''}
              width={''}
            >
              <div className="flex w-full flex-col flex-wrap">
                <div className="mb-5 flex w-full flex-row">
                  <div className="flex w-3/4 flex-col">
                    <InputField
                      type={'text'}
                      id={'metadata-name'}
                      label={'T_NAME'}
                      placeholder={''}
                      fieldKey={'name'}
                      asterixFirst={true}
                      required={true}
                      formData={formData}
                      setFormData={setFormData}
                      formError={formError}
                      disabled={!!params?.id}
                      handleChange={(e: any) => {
                        handleChange('name', e.target.value);
                      }}
                      min={1}
                      max={1024}
                    />
                  </div>
                  <div className="flex w-1/4 flex-col pl-4">
                    <div className="flex flex-row items-center">{t('T_ACTIVE')}</div>
                    <div className="flex h-full items-center justify-start">
                      <RadioButton
                        selectedField={formData?.active ? 'Yes' : 'none'}
                        option={'Yes'}
                        name={'Yes'}
                        label={t('T_YES')}
                        onClick={() => {
                          setFormData({ ...formData, active: true });
                        }}
                        disabled={!isEdit}
                      />
                      <RadioButton
                        selectedField={!formData?.active ? 'No' : 'none'}
                        option={'No'}
                        name={'No'}
                        label={t('T_NO')}
                        className="pl-2"
                        onClick={() => {
                          setFormData({ ...formData, active: false });
                        }}
                        disabled={!isEdit}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-5 flex w-full flex-col">
                  <InputField
                    type={'text'}
                    id={'display-name'}
                    label={'T_DISPLAY_NAME'}
                    placeholder={''}
                    fieldKey={'displayName'}
                    asterixFirst={true}
                    required={true}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    disabled={!isEdit}
                    handleChange={(e: any) => {
                      handleChange('displayName', e.target.value);
                    }}
                    min={1}
                    max={1024}
                  />
                </div>
                <div className="mb-5 flex w-full flex-col">
                  <InputField
                    type={'text'}
                    id={'description'}
                    label={'T_DESCRIPTION'}
                    placeholder={''}
                    fieldKey={'description'}
                    required={false}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    disabled={!isEdit}
                    min={0}
                    max={4096}
                  />
                </div>
                <div className="mb-5">
                  <JSONEditor
                    setIsLoading={setIsLoading}
                    isComponent={true}
                    jsonString={jsonString}
                    setJsonString={setJsonString}
                    disabled={!isEdit}
                    showLineNumber={true}
                    showFormatJSONButton={true}
                    showImportJSONButton={false}
                    formatJSONButtonLabel="{}"
                    JSONEditorLabel={t('T_PROPERTIES')}
                  />
                </div>
              </div>
            </Card>
          </div>
          {params?.id && (
            <div className="ml-5 flex w-1/4 flex-col">
              <div className="my-7">
                <div>
                  <div>{t('T_METADATA_ID')}</div>
                  <div>{formData?.id}</div>
                </div>
                {(formData?.createdOn || formData?.createdBy) && (
                  <div>
                    <AuditInfo
                      label={'CREATED'}
                      date={formData?.createdOn || ''}
                      user={formData?.createdBy || ''}
                    />
                  </div>
                )}
                {(formData?.updatedOn || formData?.updatedBy) && (
                  <div>
                    <AuditInfo
                      label={'LAST_MODIFIED'}
                      date={formData?.updatedOn || ''}
                      user={formData?.updatedBy || ''}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </LoaderContainer>
  );
};

export default CreateMetadata;
