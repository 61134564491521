import BaseAPI from './api';
import env from '../environment';
import {
  ResetPasswordResponse,
  ResetPasswordStep1,
  ResetPasswordStep2,
  inviteorganization
} from '../app/models/auth/loginUser';
import { AppConstants } from '../constants/AppConstants';

const globalHeaders = {
  [AppConstants.HEADER_CONTENT_TYPE]: AppConstants.VALUE_APPLICATION_JSON
};

const API = BaseAPI?.axiosInstance;

export const resetPasswordStep1 = async (requestBody: ResetPasswordStep1) => {
  const headers = { ...globalHeaders };
  const response = await API.post<ResetPasswordResponse>(
    `${env.userManagementPath}/users/password`,
    requestBody,
    { headers }
  ).then((res: any) => res);
  return response;
};

export const resetPasswordStep2 = async (requestBody: ResetPasswordStep2) => {
  const headers = { ...globalHeaders };
  const response = await API.post<ResetPasswordResponse>(
    `${env.userManagementPath}/users/password`,
    requestBody,
    { headers }
  ).then((res: any) => res);
  return response;
};

export const getUsers = async (
  filters?: string,
  page: number = 1,
  size: number = 10,
  sorters: string = ''
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.userManagementPath}/users?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;
  if (filters) {
    uri += `&${AppConstants.QUERY_PARAM_FILTERS}=${filters}`;
  }
  if (sorters) {
    uri += `${sorters}`;
  }
  return await API.get(uri, { headers }).then((res: any) => res);
};

export const getInvitedUsers = async (
  page: number = 1,
  size: number = 10,
  sorters: string = '',
  orgId?: string,
  name?: string
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.userManagementPath}/invitations?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;

  if (orgId) {
    uri += `&${AppConstants.QUERY_ORG_ID}=${orgId}`;
  }

  if (name) {
    uri += `&${AppConstants.QUERY_FIRST_NAME}=${name}`;
  }

  if (sorters) {
    uri += `${sorters}`;
  }
  return await API.get(uri, { headers }).then((res: any) => res);
};

export const getUserCount = async (statuses: Array<string> = [], roleids: Array<string> = []) => {
  const headers = { ...globalHeaders };
  let uri = `${env.userManagementPath}/users/count?${AppConstants.QUERY_PARAM_PAGE}=${AppConstants.DEFAULT_PAGE}&${AppConstants.QUERY_PARAM_SIZE}=${AppConstants.DEFAULT_PAGE_SIZE}`;
  if (statuses.length) {
    uri += `&${AppConstants.QUERY_PARAM_STATUSES}=${statuses.join()}`;
  }
  if (roleids.length) {
    uri += `&${AppConstants.QUERY_PARAM_ROLEIDS}=${roleids.join()}`;
  }
  return await API.get(uri, { headers }).then((res: any) => res);
};

export const inviteUser = async (requestBody: any) => {
  const headers = { ...globalHeaders };
  const response = API.post<inviteorganization>(
    `${env.userManagementPath}/invitations`,
    requestBody,
    { headers }
  );
  return response;
};

export const deleteUser = async (userId: string) => {
  const headers = { ...globalHeaders };
  const response = API.delete(`${env.userManagementPath}/users/${userId}`, { headers });
  return response;
};

export const hardDeleteUser = async (userId: string) => {
  const headers = { ...globalHeaders };
  const response = API.delete(`${env.userManagementPath}/users/${userId}/account`, { headers });
  return response;
};

export const reinviteUser = async (userId: string) => {
  const headers = { ...globalHeaders };
  const response = API.put(`${env.userManagementPath}/invitations/${userId}`, { headers });
  return response;
};

export const cancelInviteUser = async (userId: string) => {
  const headers = { ...globalHeaders };
  const response = API.delete(`${env.userManagementPath}/invitations/${userId}`, { headers });
  return response;
};

export const updateUser = async (requestBody: any, userId: string) => {
  const headers = { ...globalHeaders };
  const response = API.patch(`${env.userManagementPath}/users/${userId}`, requestBody, { headers });
  return response;
};

export const enrollmentCheck = async (userId: string, orgId: string) => {
  const headers = { ...globalHeaders };
  const response = API.get(`${env.userManagementPath}/users/${userId}/organizations/${orgId}`, {
    headers
  });
  return response;
};

export const enrollUser = async (userId: string, requestBody: any) => {
  const headers = { ...globalHeaders };
  const response = API.patch(
    `${env.userManagementPath}/users/${userId}/organizations`,
    requestBody,
    { headers }
  );
  return response;
};

export const excludeUser = async (userId: string, orgId: string) => {
  const headers = { ...globalHeaders };
  const response = API.delete(`${env.userManagementPath}/users/${userId}/organizations/${orgId}`, {
    headers
  });
  return response;
};

export const requestNewCode = async (body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.put(`${env.userManagementPath}/users/verification-tokens`, body, {
    transformRequest: (data, headers: any) => {
      delete headers.Authorization;
      headers.put['Content-Type'] = 'application/json';
      return JSON.stringify(data);
    }
  });
  return response;
};

export const acceptInvitations = async (body: any) => {
  const headers = { ...globalHeaders };
  const uri = `${env.userManagementPath}/invitations/verification-tokens`;
  return await API.post(uri, body, { headers });
};

export const createPasswordPolicy = async (body: any) => {
  const headers = { ...globalHeaders };
  const uri = `${env.userManagementPath}/password-policies`;
  return await API.post(uri, body, { headers });
};

export const updatePasswordPolicy = async (id: string, body: any) => {
  const headers = { ...globalHeaders };
  const response = await API.patch(`${env.userManagementPath}/password-policies/${id}`, body, {
    headers
  });
  return response;
};

export const deletePasswordPolicy = async (id: string) => {
  const headers = { ...globalHeaders };
  const response = await API.delete(`${env.userManagementPath}/password-policies/${id}`, {
    headers
  });
  return response;
};

export const getPasswordPolicy = async (
  name: string,
  page: number = AppConstants.DEFAULT_PAGE,
  size: number = AppConstants.DEFAULT_PAGE_SIZE,
  sort: Array<string> = []
) => {
  const headers = { ...globalHeaders };
  let uri = `${env.userManagementPath}/password-policies?${AppConstants.QUERY_PARAM_PAGE}=${page}&${AppConstants.QUERY_PARAM_SIZE}=${size}`;
  if (name) {
    uri += `&${AppConstants.QUERY_PARAM_NAME}=${name}`;
  }
  if (sort && sort.length) {
    uri += '&' + sort.map((e: string) => `sort=${e}`).join('&');
  }
  const response = await API.get(uri, { headers });
  return response;
};

export const getPasswordPolicyRegister = async (identifier: any) => {
  const headers = { ...globalHeaders };
  const encodedIdentifier = encodeURIComponent(identifier);
  const response = await API.get(
    `${env.userManagementPath}/password-policies/users?identifierType=EMAIL&identifier=${encodedIdentifier}`,
    { headers }
  );
  return response;
};

export const changePassword = async (id: string, body: any) => {
  const headers = { ...globalHeaders };
  let uri = `${env.userManagementPath}/users/${id}/password`;
  return await API.put(uri, body, { headers }).then((res: any) => res);
};
