import packageInfo from '../package.json';

const API_PROXIES = '/api-proxies/';
const inactivityAutoLogoutTime = process.env.REACT_APP_INATIVITY_AUTO_LOGOUT
  ? Number(`${process.env.REACT_APP_INATIVITY_AUTO_LOGOUT}`)
  : 1800000;
const inactivityWarningTime = process.env.REACT_APP_INATIVITY_WARNING
  ? Number(`${process.env.REACT_APP_INATIVITY_WARNING}`)
  : 1500000;

const environment = {
  production: false,
  api: `${process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://api-us-dev.brightinsight.com/pro-proserv-nonprod-dev-bmx/'}`,
  apigeeEndpoint: `${
    process.env.REACT_APP_APIGEE_ENDPOINT ? process.env.REACT_APP_APIGEE_ENDPOINT : ''
  }`,
  namespace: `${process.env.REACT_APP_NAMESPACE ? process.env.REACT_APP_NAMESPACE : ''}`,
  authpath: 'authentication-service',
  userManagementPath: 'user-management-service',
  accessControlPath: 'access-control-service',
  organizationPath: 'organization-service',
  assetManagementPath: 'asset-management-service',
  configurationPath: 'configuration-service',
  notificationPath: 'notification-service',
  surveyPath: 'survey-service',
  metadataPath: 'metadata-service',
  migrationPath: 'migration-service',
  devicePath: 'device-service',
  eventPath: 'event-service',
  carePlanPath: 'careplan-service',
  conditionPath: 'condition-service',
  careTeamPath: 'care-team-service',
  encounterServicePath: 'encounter-service',
  apikey: process.env.REACT_APP_API_KEY || '',
  orgId: process.env.REACT_APP_ORG_ID || '',
  version: packageInfo.version,
  inactivityAutoLogoutTime,
  inactivityWarningTime,
  ENV_TENANT_NAME: process.env.REACT_APP_TENANT_NAME || '',
  ENV_SOLUTION_NAME: process.env.REACT_APP_SOLUTION_NAME || '',
  ENV_APPLICATION_NAME: process.env.REACT_APP_APPLICATION_NAME || ''
};

export default environment;
