import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import { fileCode } from '../../assets/components/svgs';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { createApplication, getApplication, updateApplication } from '../../http/metadata-service';
import JSONEditor from '../AppSettings/JSONEditor';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import BiButton from '../primitives/buttons/BiButton.primitive';
import Card from '../shared/cards/Card';
import InputField from '../shared/Fields/InputField';
import RadioButton from '../shared/Fields/RadioButton';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import { setApplication } from '../../app/metadataReducer';
import { isEmpty, isEqual } from 'lodash';
import { RouteConstants } from '../../constants/RouteConstants';
import { fieldIsEmpty, validateEmail, validateNameLength } from '../../util/validators';
import { AppConstants } from '../../constants/AppConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { type } from '@testing-library/user-event/dist/type';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import TextAreaField from '../shared/Fields/TextAreaField';
import Asterix from '../../assets/asterix.svg';
import { Label } from '../AccountProfile/ProfileStyles';
import PickListWithApiSearch from '../shared/Fields/PickListWithAPISearch';
import { getUsers } from '../../http/user-management-services';
import AutoCompleteInput from '../shared/Fields/AutocompleteInput';

const CreateNewRequest = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);

  const [isEdit, setIsEdit] = useState(!params?.id);

  const [isLoading, setIsLoading] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);

  const [formData, setFormData] = useState<any>({
    name: '',
    email: '',
    message: '',
    rectificationMessage: '',
    restrictionType: ''
  });
  const [requestType, setRequestType] = useState([]);
  const [formError, setFormError] = useState<any>({
    name: '',
    email: '',
    requestType: '',
    rectificationMessage: '',
    restrictionType: ''
  });

  useEffect(() => {
    const init = async () => {
      if (params?.id) {
        setIsLoading(true);
        setIsLoading(false);
      } else {
        dispatch(
          setURLPaths([
            {
              key: RouteConstants.ROUTE_GDPR_CREATE,
              label: 'Patient Requests > New Request'
            }
          ])
        );
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (!isEmpty(userProfile)) {
      setFormData({ ...formData, name: getUserName(userProfile) });
    }
  }, [userProfile]);

  useEffect(() => {
    if (Object.values(formError).some((value) => value !== '')) {
      setFormError({});
    }
  }, [formData]);

  const getSelectedType = () => {
    const [option]: any = requestType;
    return option?.key || '';
  };

  const validateFormData = () => {
    let errors: any = {
      name: '',
      email: '',
      requestType: '',
      rectificationMessage: '',
      restrictionType: ''
    };
    errors['name'] = fieldIsEmpty(formData.name.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateNameLength(formData.name.trim(), 1024)
      ? ''
      : t('T_VALIDATION_ERR_TEXT_TOO_LONG_MSG');
    errors['email'] = fieldIsEmpty(formData.email.trim())
      ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
      : validateEmail(formData.email)
      ? ''
      : t('T_VALIDATION_ERR_EMAIL_MSG');
    errors['requestType'] = !requestType.length ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG') : '';

    if (getSelectedType() === 'rectification') {
      errors['rectificationMessage'] = fieldIsEmpty(formData.rectificationMessage)
        ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG')
        : validateNameLength(formData.displayName.trim(), 1024)
        ? ''
        : t('T_VALIDATION_ERR_TEXT_TOO_LONG_MSG');
    }

    if (getSelectedType() === 'restriction') {
      errors['restriction'] =
        formData.restrictionType === '' ? t('T_VALIDATION_INPUT_ERR_EMPTY_MSG') : '';
    }

    setFormError({ ...errors });
    return errors;
  };

  const handleSave = async () => {
    const errors = validateFormData();
    const isValid = Object.values(errors).every((error) => error === '');

    if (isValid) {
      setReviewMode(true);
    }
  };

  const getCancelButtonText = () => {
    if (reviewMode) {
      return t('T_BACK');
    }
    return t('T_CANCEL');
  };

  const handleChange = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleOnCancel = () => {
    setIsEdit(false);
  };

  const REQUEST_OPTIONS = [
    { key: 'request', value: t('T_NEW_PATIENT_REQUEST_TYPE_REQUEST') },
    { key: 'export', value: t('T_NEW_PATIENT_REQUEST_TYPE_EXPORT') },
    {
      key: 'rectification',
      value: t('T_NEW_PATIENT_REQUEST_TYPE_RECTIFICATION')
    },
    { key: 'deletion', value: t('T_NEW_PATIENT_REQUEST_TYPE_DELETION') },
    { key: 'restriction', value: t('T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION') }
  ];

  const fetchUserData = async (name: string = '') => {
    let trimmedName = name.trim();
    let apiFilter = trimmedName
      ? `[{"field":"mail","operation":"co","value":"${trimmedName}"}]`
      : '';

    if (!trimmedName || trimmedName === '') {
      return [];
    }
    let response = await getUsers(
      apiFilter,
      AppConstants.DEFAULT_PAGE,
      AppConstants.DEFAULT_PAGE_SIZE,
      ''
    );

    const { status, data: usersResponse } = response;
    if (HTTP_STATUS.isSuccess(status)) {
      return usersResponse.data;
    } else {
      return [];
    }
  };

  return (
    <LoaderContainer isLoading={isLoading}>
      <MenuButtonsPortal>
        <BiButton
          className="bg-white text-primary"
          type="button"
          onClick={() => {
            if (!reviewMode) {
              navigate(-1);
              return;
            } else {
              setReviewMode(false);
            }
          }}
        >
          {getCancelButtonText()}
        </BiButton>
        {isEdit ? (
          <BiButton
            className="bg-primary text-white"
            type="button"
            onClick={handleSave}
            disabled={false}
          >
            {reviewMode ? t('T_NEW_PATIENT_REQUEST_CREATE_REQUEST') : t('T_SAVE')}
          </BiButton>
        ) : (
          ''
        )}
      </MenuButtonsPortal>
      <div
        className="flex max-h-screen w-full flex-col justify-start overflow-y-scroll  bg-gray-100 px-2 py-3"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <div className="flex">
          <div className="flex w-1/2">
            <div className="p-2"></div>
            <Card
              title={t('T_NEW_PATIENT_REQUEST_HEADER')}
              icon={fileCode}
              alt="file-code-icon"
              className="w-full"
              height={''}
              width={''}
            >
              <div className="flex w-full flex-col flex-wrap">
                <div className="mb-6 flex w-full flex-col">
                  <InputField
                    type={'text'}
                    id={'patient-name'}
                    label={'T_NEW_PATIENT_REQUEST_NAME'}
                    placeholder={''}
                    fieldKey={'name'}
                    asterixFirst
                    required={true}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    disabled={formData.name || reviewMode}
                    handleChange={(e: any) => {
                      handleChange('name', e.target.value);
                    }}
                    min={1}
                    max={1024}
                  />
                </div>
                <div className="mb-6 flex w-full flex-col">
                  <AutoCompleteInput
                    formData={formData}
                    formError={formError}
                    setFormData={setFormData}
                    fieldKey={'email'}
                    dataKey={'mail'}
                    fetchData={fetchUserData}
                    label={'T_NEW_PATIENT_REQUEST_EMAIL'}
                  />
                </div>
                <div className="mb-6 flex w-full flex-col">
                  <PickList
                    fieldKey={'key'}
                    fieldValues={['value']}
                    required
                    label={t('T_NEW_PATIENT_REQUEST_TYPE')}
                    selectedItems={requestType}
                    onSelectedChange={(items: any) => {
                      setRequestType(items);
                    }}
                    items={REQUEST_OPTIONS}
                    disabled={reviewMode}
                    readOnly={false}
                    type={PICKLIST_TYPES.SINGLE}
                  />
                </div>
                {getSelectedType() === 'rectification' ? (
                  <div className="mb-6 flex w-full flex-wrap">
                    <div className="flex w-full flex-col">
                      <TextAreaField
                        fieldKey={'rectificationMessage'}
                        type={'text'}
                        id={'rectificationMessage'}
                        label={t('T_NEW_PATIENT_REQUEST_TYPE_RECTIFICATION_MESSAGE')}
                        placeholder={''}
                        required
                        formData={formData}
                        setFormData={setFormData}
                        formError={formError}
                        asterixFirst
                        rows={3}
                        disabled={reviewMode}
                        maxLength={AppConstants.MAX_LENGTH_IMPORT_DESCRIPTION}
                      ></TextAreaField>
                    </div>
                  </div>
                ) : getSelectedType() === 'restriction' ? (
                  <div className="mb-6 flex w-full flex-col flex-wrap">
                    <Label className="flex flex-row">
                      <img src={Asterix} className="mr-1 h-2 w-2" />
                      {t('T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION_HEADER')}
                    </Label>
                    <div className="mt-2 flex flex-row">
                      <RadioButton
                        selectedField={formData.restrictionType}
                        option={true}
                        className="mb-3"
                        name={t('T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION_ENABLE')}
                        label={t('T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION_ENABLE')}
                        onClick={() => {
                          setFormData({ ...formData, restrictionType: true });
                        }}
                        disabled={reviewMode}
                      />
                      <RadioButton
                        selectedField={formData.restrictionType}
                        option={false}
                        className="mb-3 ml-6"
                        name={t('T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION_DISABLE')}
                        label={t('T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION_DISABLE')}
                        onClick={() => {
                          setFormData({ ...formData, restrictionType: false });
                        }}
                        disabled={reviewMode}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="mb-6 flex w-full flex-wrap">
                  <div className="flex w-full flex-col">
                    <TextAreaField
                      fieldKey={'message'}
                      type={'text'}
                      id={'message'}
                      label={t('T_NEW_PATIENT_REQUEST_ADDTIONAL_MESSAGE')}
                      placeholder={''}
                      required={false}
                      formData={formData}
                      setFormData={setFormData}
                      formError={formError}
                      asterixFirst
                      rows={3}
                      disabled={reviewMode}
                      maxLength={AppConstants.MAX_LENGTH_IMPORT_DESCRIPTION}
                    ></TextAreaField>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </LoaderContainer>
  );
};

export default CreateNewRequest;
