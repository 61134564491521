import { SideBarItemConfig } from '../../app/models/menu/sideBar';
import {
  template,
  library,
  userGroup,
  SettingIcon,
  KeyIcon,
  permissionSetIcon,
  configuration,
  lock_icon,
  mobileNotch,
  userOrg,
  platformCore,
  configurationKey,
  security,
  application,
  applicationRoles,
  consentMgmt,
  passwordPolicy,
  configKeys,
  metaData,
  surveyTemp,
  surveyQue,
  notificationTemp,
  migration,
  usersList,
  organization,
  fileCode,
  FileManagement,
  PatientRequest 
} from '../../assets/components/svgs';
import { RouteConstants } from '../../constants/RouteConstants';

// These are for mapping view/menu keys to paths in the router
const keyPaths = Object.freeze([
  {
    key: 'Organizations',
    path: RouteConstants.ROUTE_OS_ORGANIZATION_LISTING,
    label: 'T_ORGANIZATION_MANAGEMENT',
    icon: organization
  },
  {
    key: 'Users and Orgs',
    icon: userOrg
  },
  {
    key: 'Platform Core',
    icon: platformCore
  },
  {
    key: 'Application',
    icon: application
  },
  {
    key: 'Notification Templates',
    path: '/templates',
    label: 'T_EMAIL_TEMPLATE',
    icon: notificationTemp
  },
  {
    key: 'Application Roles',
    path: '/roles',
    label: 'T_ROLE',
    icon: applicationRoles
  },
  {
    key: 'Administrators',
    path: '/admin-list',
    label: 'T_USERS',
    icon: usersList
  },
  {
    key: 'Permission Sets',
    path: '/permissionset-list',
    label: 'T_PERMISSION_SETS',
    icon: permissionSetIcon
  },
  {
    key: 'DMS Settings',
    path: '/app-settings',
    label: 'T_APP_SETTINGS',
    icon: SettingIcon
  },
  {
    key: 'IVD Settings',
    path: '/app-settings',
    label: 'T_APP_SETTINGS',
    icon: SettingIcon
  },
  {
    key: 'Settings',
    path: '/app-settings',
    label: 'T_APP_SETTINGS',
    icon: SettingIcon
  },
  {
    key: 'Configuration',
    path: '',
    label: 'T_CONFIGURATION',
    icon: configurationKey
  },
  {
    key: 'Security',
    path: '',
    label: 'T_SECURITY',
    icon: security
  },
  {
    key: 'Survey Questions',
    path: '/survey-question-list',
    icon: surveyQue
  },
  {
    key: 'Survey Templates',
    path: '/survey-template/list',
    icon: surveyTemp
  },
  {
    key: 'Mobile Platform',
    icon: mobileNotch,
    label: 'T_MOBILE_PLATFORM',
    path: '/mobile-configuration/list'
  },
  {
    key: 'Consent Management',
    path: '/consents',
    label: 'Consent Management',
    icon: consentMgmt
  },
  {
    key: 'Password Policies',
    path: RouteConstants.ROUTE_UMS_PASSWORD_POLICY_LIST,
    label: 'Password Policies',
    icon: passwordPolicy
  },
  {
    key: 'Metadata',
    label: 'T_METADATA',
    path: RouteConstants.ROUTE_MDS_APPLN_LIST,
    icon: metaData
  },
  {
    key: 'Configuration Keys',
    label: 'T_CONFIGURATION_KEYS',
    path: '/config-key-list',
    icon: configKeys
  },
  {
    key: 'Migration',
    label: 'T_MIGRATION',
    path: RouteConstants.ROUTE_MIGRATION_EXPORT_LIST,
    icon: migration
  },
  {
    key: 'Files',
    label: 'T_FILES',
    path: RouteConstants.ROUTE_AMS_FILES,
    icon: FileManagement
  },
  {
    key: 'Care Plan Template',
    label: 'T_CARE_PLAN',
    path: RouteConstants.ROUTE_CARE_PLAN_TEMPLATE_LIST,
    icon: FileManagement
  },
  {
    key: 'Patient Requests',
    icon: PatientRequest 
  },
  {
    key: 'Create Request',
    label: 'T_CREATE_REQUEST',
    path: RouteConstants.ROUTE_GDPR_CREATE,
    icon:   KeyIcon
  },
  {
    key: 'Past Requests',
    label: 'T_PAST_REQUESTS',
    path: RouteConstants.ROUTE_GDPR_LIST,
    icon:  KeyIcon
  }
]);

export default keyPaths as SideBarItemConfig[];
