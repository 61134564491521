import { createSlice } from '@reduxjs/toolkit';

export const rolesAndFeatureSlice = createSlice({
  name: 'rolesData',
  initialState: {
    newRolePayload: '' as any,
    permissionSet: {} as any,
    orgDetails: {} as any,
    step: 1
  },
  reducers: {
    setRolesStep: (state, action) => {
      state.step = action.payload;
    },
    setNewRoleData: (state, action) => {
      state.newRolePayload = action.payload;
    },
    setPermissionSet: (state, action) => {
      state.permissionSet = action.payload;
    },
    setOrgDetails: (state, action) => {
      state.orgDetails = action.payload;
    }
  }
});

export const { setNewRoleData, setRolesStep, setPermissionSet ,setOrgDetails} = rolesAndFeatureSlice.actions;

export default rolesAndFeatureSlice.reducer;
