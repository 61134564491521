import BiButton from './BiButton.primitive';

interface IconButtonProps {
  disabled?: boolean;
  onClick: Function;
  children: any;
  className?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
  disabled = false,
  onClick,
  children,
  className = ''
}: IconButtonProps) => {
  return (
    <BiButton
      className={`mt-4 flex items-center justify-center rounded-[5px] border-[1px] border-solid !px-0 !py-0 ${className} ${
        disabled
          ? '!border-slate-300  !bg-transparent !text-neutral-300 !opacity-[0.3]'
          : 'border-[#003A70] text-[#003A70]'
      }`}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </BiButton>
  );
};
