import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

import styled from 'styled-components/macro';
import { imgAltIcon, multiDropdown, multiDropdownUp } from '../../../../assets/components/svgs';
import BiButton from '../../../primitives/buttons/BiButton.primitive';
import InputField from '../../../shared/Fields/InputField';
import SelectField from '../../../shared/Fields/SelectField';
import RadioButton from '../../../shared/Fields/RadioButton';

import PickList, { PICKLIST_TYPES } from '../../../shared/Fields/PickList';

import { AppConstants } from '../../../../constants/AppConstants';
import { DropDownContainer, RemoveDefaultButton } from './MultipleSelection';
import { DropDownList } from '../../../shared/Fields/SelectField';

import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { clone, findIndex, isEmpty } from 'lodash';
import { options } from '../../../primitives/dropdowns/BiDropdownStyles';
import { getBase64 } from '../../../../util/admin-utils';
import DeleteIcon from '@heroicons/react/outline/TrashIcon';
import { getGlobalBucketName } from '../../../../util/AppSetting';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { downLoadImages, getFileNameWithTimeStamp, getFolderpathWithTimeStamp } from '../QueUtils';

function FileUploadField({ index, setFileToOptionData }: any) {
  const fileRef = useRef<any>(null);
  const maxFileSize = '5120';

  return (
    <>
      <div className="flex">
        <BiButton
          className="ml-2 h-[44px] self-end border !p-[12px] hover:!border-[#0074E0] hover:bg-[#DCEEFE] hover:!text-[#0074E0]"
          onClick={(e: any) => {
            fileRef.current.click();
          }}
          type="button"
        >
          {imgAltIcon}
        </BiButton>
        <input
          id={index}
          type="file"
          ref={fileRef}
          data-testid={'media-attachment'}
          onChange={(e) => {
            let file: any = e.target.files;
            const [fileObj] = file;
            const init = async () => {
              return await getBase64(fileObj);
            };
            const { size = '' } = fileObj;

            // if (size > maxFileSize) {
            //   setFormError({
            //     fileName: t(`T_FILE_SIZE_SURVEY_MEDIA_${contentType.toUpperCase()}_ERROR_MSG`)
            //   });
            // } else {
            //   setFormError({ fileName: '' });
            // }

            init().then((data: any) => {
              const timeStamp = moment().unix();
              setFileToOptionData(index, {
                fileName: getFileNameWithTimeStamp(fileObj.name, timeStamp),
                file: file,
                base64: data
              });
            });
            //e.target.value = '';
          }}
          style={{ display: 'none' }}
          accept={['.jpeg', '.jpg', '.png', '.svg'].join(', ')}
        />
      </div>
    </>
  );
}

interface LikertProps {
  formData: any;
  setFormData: any;
  formError: any;
  editMode?: boolean;
  editFromSurveyTemplate?: boolean;
  resetData?: any;
  isPreview?: boolean;
}
const Likert = ({
  formData,
  setFormData,
  formError,
  editMode = false,
  editFromSurveyTemplate = false,
  isPreview = false,
  resetData
}: LikertProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const flowType = location.pathname.split('/').at(-1);
  const [isEdit, setIsEdit] = useState(flowType !== 'new');

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [permission, setPermission] = useState('public');

  const [ratingRange, setRatingRange] = useState<any>({ from: [{ val: 1 }], to: [{ val: 5 }] });

  const [likertType, setLikertType] = useState<any>('likertIconsWithLabels');

  const [ratingScaleOptions, setRatingScaleOptions] = useState<any>([
    {
      name: 'Custom Range'
    }
  ]);

  const [likertImages, setLikertImages] = useState<any>({});

  const [fileUploadObj, setFileUploadObj] = useState<any>({});

  const [withMedia, setWithMedia] = useState<any>(true);

  const [defaultExt, setDefaultExt] = useState<any>({
    bucketName: getGlobalBucketName(),
    path: `${AppConstants.SURVEY_MEDIA_PATH}`
  });

  const LIKERT_SCALE_OPTIONS: any = AppConstants.LIKERT_SCALE_OPTIONS;

  const FIELD_RANGE = AppConstants.LIKERT_SCALE_FIELD_RANGE;

  const [isOpen, setIsOpen] = useState<any>({ option1: false, option2: false });
  const [isDefaultSelected, setIsDefaultSelected] = useState<any>({
    option1: false,
    option2: false
  });

  const [optionValue, setOptionValue] = useState<any>({
    option1: '',
    option2: ''
  });

  const dropdownRef = useRef<any>(null);

  const getLikertImages = async (likertImages: any, formData: any) => {
    const imagesDownloaded = await downLoadImages(likertImages, formData);
    setFileUploadObj(imagesDownloaded);
  };

  const updateExtensionData = (formData: any) => {
    if (formData.extension) {
      const { extension = {} } = formData;
      const {
        displayType = 'likert',
        likertImages = {},
        permission = 'public',
        ratingRange = { from: [{ val: 1 }], to: [{ val: 5 }] }
      } = extension;
      const init = async () => {
        await getLikertImages(likertImages, formData);
      };
      setSelectedOptions(formData.answerOptions ? Object.values(formData.answerOptions) : []);
      setRatingScaleOptions(
        formData?.extension?.ratingScale
          ? [{ name: formData.extension.ratingScale }]
          : [
              {
                name: 'Custom Range'
              }
            ]
      );
      init();
      setRatingRange(ratingRange);
      setLikertImages(likertImages);
      setLikertType(displayType);
      setPermission(permission);
    }
  };

  useEffect(() => {
    if (
      location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === 'new' &&
      !editFromSurveyTemplate
    ) {
      setFormData({ ...formData, ratingScale: 'Custom Range', from: '1', to: '5' });
    } else {
      updateExtensionData(formData);
    }
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      answerOptions: selectedOptions,
      displayType: likertType,
      extension: {
        ...defaultExt,
        ratingRange: ratingRange,
        displayType: likertType,
        path: `${AppConstants.SURVEY_MEDIA_PATH}${formData.type}/`,
        ratingScale:
          ratingScaleOptions && ratingScaleOptions.length ? ratingScaleOptions[0].name : '',
        likertImages
      },
      fileUploadObj
    });
  }, [selectedOptions, likertImages, fileUploadObj, likertType, ratingRange]);

  useEffect(() => {
    if (resetData) {
      updateExtensionData(resetData);
    }
  }, [resetData]);

  const setCustomrangeOptions = (item: any, index: any) => {
    const customOptions = clone(selectedOptions);
    customOptions.splice(index, 1);
    customOptions.splice(index, 0, item);
    setSelectedOptions(customOptions);
  };

  const getImageUrl = (fileObj: any, index: any) => {
    let file: any = fileObj.file;
    const [selectedFile] = file;
    const init = async () => {
      return await getBase64(selectedFile);
    };

    init().then((data: any) => {
      //setFileToOptionData(index, data);
      return data;
    });
  };

  const getImageContainer = (fileObj: any) => {
    if (
      ratingScaleOptions &&
      ratingScaleOptions.length &&
      ratingScaleOptions[0].name === 'Custom Range'
    ) {
      return (
        <div className="h-[70px] w-[70px] rounded-lg border border-[#D9D9D9] p-1.5">
          <img
            className=" h-[55px] w-[55px]"
            src={fileObj.base64 || fileObj.url}
            alt={'likert-img'}
          />
        </div>
      );
    } else {
      return (
        <div className="max-h-[10rem] max-w-[14rem] rounded-lg border border-[#D9D9D9] p-1.5">
          <img className="" src={fileObj.base64 || fileObj.url} alt={'likert-img'} />
        </div>
      );
    }
  };

  const ShowLikertImage = (fileObj: any, index: string) => {
    return (
      !isEmpty(fileObj) &&
      fileObj.fileName && (
        <div className="mb-5 flex">
          {getImageContainer(fileObj)}
          {!isPreview &&
            ratingScaleOptions &&
            ratingScaleOptions.length &&
            ratingScaleOptions[0].name === 'Custom Range' && (
              <div
                className="-ml-3.5 -mt-3 h-[22px] w-[22px] cursor-pointer rounded-full border border-solid border-[#D9D9D9] hover:border-none hover:bg-[#FFF1F0] hover:!text-[red]"
                style={{
                  boxShadow:
                    '0px 0px 1px 0.5px rgba(0, 0, 0, 0.04), 0px 0.5px 1.5px rgba(0, 0, 0, 0.19)'
                }}
              >
                <DeleteIcon
                  className="mx-auto mt-[2px] h-4 text-gray-600 hover:text-black"
                  onClick={() => {
                    setFileToOptionData(index, {
                      fileName: '',
                      file: '',
                      base64: ''
                    });
                  }}
                  data-testid="delete-icon"
                />
              </div>
            )}
        </div>
      )
    );
  };

  const setFileToOptionData = (index: any, fileObj: any) => {
    if (!selectedOptions[index]) {
      setCustomrangeOptions({ value: '', isSelected: false }, index);
    }
    setLikertImages({ ...likertImages, [index]: fileObj.fileName });
    setFileUploadObj({ ...fileUploadObj, [index]: fileObj });
  };

  const renderCustomRange = (selectedOptions: any) => {
    let rowsData: any = [];
    const to = ratingRange.to[0].val;
    const from = ratingRange.from[0].val;
    for (let i = 0; i < parseInt(to) - parseInt(from) + 1; i++) {
      rowsData.push(
        <div>
          {(likertType === 'likertIconsWithLabels' || likertType === 'likert') && (
            <div className="mb-5 flex">
              <InputFieldContainer className="flex w-full flex-col" key={i}>
                <div className="flex w-full flex-row">
                  <div className="flex w-full flex-col">
                    <InputField
                      type={'text'}
                      disabled={isPreview}
                      id={`rating-scale-option-${i}`}
                      label={`${i + 1}`}
                      placeholder={'Label(optional)'}
                      fieldKey={'value'}
                      required={false}
                      asterixFirst={true}
                      formData={
                        selectedOptions.length && selectedOptions[i]
                          ? selectedOptions[i]
                          : { value: '', isSelected: false }
                      }
                      setFormData={(item: any) => {
                        setCustomrangeOptions(item, i);
                      }}
                      formError={formError}
                    />
                  </div>

                  {!isPreview &&
                    editFromSurveyTemplate &&
                    likertType === 'likertIconsWithLabels' && (
                      <FileUploadField index={i} setFileToOptionData={setFileToOptionData} />
                    )}
                </div>
              </InputFieldContainer>
              {!isPreview && editFromSurveyTemplate && <div>{DefaultOptionSelect(i)}</div>}
            </div>
          )}
          {!isPreview && editFromSurveyTemplate && likertType === 'likertIcons' && (
            <>
              <div className="mb-5 flex">
                <FileUploadField index={i} setFileToOptionData={setFileToOptionData} />
                {!isPreview && editFromSurveyTemplate && (
                  <div className="flex">{DefaultOptionSelect(i)}</div>
                )}
              </div>
            </>
          )}
          {(likertType === 'likertIcons' || likertType === 'likertIconsWithLabels') &&
            //ShowLikertImage(formData?.extension?.[`option${i + 1}`]?.img, `option${i + 1}`)}
            ShowLikertImage(fileUploadObj[i], `${i}`)}
          {selectedOptions[i]?.isSelected && (
            <div className="-mt-5 mb-5">{t('T_REMOVE_DEFAULT_SELECTED_MSG1')}</div>
          )}
        </div>
      );
    }
    return rowsData;
  };

  const getOptionText = (index: number) => {
    const likertOptions = clone(selectedOptions);
    if (likertOptions.length && likertOptions[index]) {
      if (!likertOptions[index].isSelected) {
        return t('T_DEFAULT_OPTION_SELECTED');
      } else {
        return t('T_REMOVE_DEFAULT_SELECTED_MSG2');
      }
    } else {
      return t('T_DEFAULT_OPTION_SELECTED');
    }
  };

  const updateIsSelected = (index: number) => {
    const likertOptions = clone(selectedOptions);
    if (likertOptions.length && likertOptions[index]) {
      likertOptions[index].isSelected = !likertOptions[index].isSelected;
      likertOptions.forEach((option: any, i: any) => {
        if (i !== index) {
          likertOptions[i].isSelected = false;
        }
      });
    }
    setSelectedOptions(likertOptions);
  };

  const DefaultOptionSelect = (index: number) => {
    return (
      <>
        {!editMode && (
          <DropDownContainer className="ml-0 mr-3 mt-9 cursor-pointer self-center">
            <div
              className="ml-3 cursor-pointer self-center"
              onClick={() =>
                setIsOpen({
                  ...isOpen,
                  [`option${index + 1}`]: !isOpen[`option${index + 1}`]
                })
              }
              ref={dropdownRef}
            >
              {isOpen[`option${index + 1}`] ? multiDropdownUp : multiDropdown}
            </div>
            {isOpen[`option${index + 1}`] && (
              <div>
                {likertType === 'likertIcons' && (
                  <StyledDropdownListForIconsOnly maxHeight={true}>
                    <li onClick={() => updateIsSelected(index)}>{getOptionText(index)}</li>
                  </StyledDropdownListForIconsOnly>
                )}
                {likertType !== 'likertIcons' && (
                  <StyledDropdownList maxHeight={true}>
                    <li onClick={() => updateIsSelected(index)}>{getOptionText(index)}</li>
                  </StyledDropdownList>
                )}
              </div>
            )}
          </DropDownContainer>
        )}
      </>
    );
  };

  useOutsideClick(
    dropdownRef,
    () => {
      let tempIsOpen: any = {};
      Object.keys(isOpen).forEach((option: any) => {
        if (isOpen[option]) {
          setIsOpen({ ...isOpen, [option]: !isOpen[option] });
        }
      });

      // setIsOpen(tempIsOpen);
    },
    false
  );

  const getLikertScaleOptions = () => {
    return Object.keys(LIKERT_SCALE_OPTIONS).map((option: any) => {
      return { name: option };
    });
  };

  const onRatingScaleChange = (scale: any, likertSelectedOption: any) => {
    const [ratingScale] = scale;
    let likertImages: any = {};
    setLikertImages({});
    setFileUploadObj({});
    setRatingScaleOptions(scale);
    const to = ratingRange.to[0].val;
    const from = ratingRange.from[0].val;

    if (ratingScale && ratingScale.name !== 'Custom Range') {
      setRatingRange({ from: [{ val: 1 }], to: [{ val: 5 }] });
      setSelectedOptions(
        LIKERT_SCALE_OPTIONS[ratingScale.name].map((option: any) => {
          return { value: option, isSelected: false };
        })
      );
      if (likertSelectedOption !== 'likert') {
        LIKERT_SCALE_OPTIONS[ratingScale.name].map((option: any, index: any) => {
          likertImages[index] = `${ratingScale.name.toLowerCase().replace(/\s+/g, '_')}_${option
            .toLowerCase()
            .replace(/\s+/g, '_')}.png`;
        });
      }

      setLikertImages(likertImages);
      getLikertImages(likertImages, formData);
    } else {
      const customRangeOptions = [];
      for (let i = 0; i < parseInt(to) - parseInt(from) + 1; i++) {
        customRangeOptions.push({ value: '', isSelected: false });
      }
      setSelectedOptions([]);
    }
  };

  return (
    <>
      {!isPreview && (
        <>
          <div className="my-1 flex w-full flex-row">{t('T_SELECT_LIKERT_TYPE')}</div>
          <div className="my-1 flex w-full flex-row">
            <div className="self-center">
              <RadioButton
                selectedField={likertType}
                option={'likertIconsWithLabels'}
                name={`likertScaleType`}
                onClick={() => {
                  setLikertImages({});
                  setFileUploadObj({});
                  onRatingScaleChange(ratingScaleOptions, 'likertIconsWithLabels');
                  !editMode && setLikertType('likertIconsWithLabels');
                }}
              />
            </div>
            <div className="my-auto mr-5 flex w-fit text-[14px]">
              {t('T_LIKERT_SCALE_ICONS_LABELS')}
            </div>
            <div className="self-center">
              <RadioButton
                selectedField={likertType}
                option={'likertIcons'}
                name={`likertScaleType`}
                onClick={() => {
                  setLikertImages({});
                  setFileUploadObj({});
                  onRatingScaleChange(ratingScaleOptions, 'likertIcons');
                  !editMode && setLikertType('likertIcons');
                }}
              />
            </div>
            <div className="w--fit my-auto mr-5 flex text-[14px]">{t('T_LIKERT_SCALE_ICONS')}</div>
            <div className="self-center">
              <RadioButton
                selectedField={likertType}
                option={'likert'}
                name={`likertScaleType`}
                onClick={() => {
                  setLikertImages({});
                  setFileUploadObj({});
                  onRatingScaleChange(ratingScaleOptions, 'likert');
                  !editMode && setLikertType('likert');
                }}
              />
            </div>
            <div className="my-auto mr-5 flex w-fit text-[14px]">{t('T_LIKERT_SCALE')}</div>
          </div>

          <div className="mt-5 flex w-full flex-row">
            <SelectFieldContainer className="flex w-1/2 flex-row">
              <PickList
                fieldKey={'name'}
                required={true}
                fieldValues={['name']}
                selectedItems={ratingScaleOptions}
                onSelectedChange={(item: any) => {
                  onRatingScaleChange(item, likertType);
                }}
                items={getLikertScaleOptions()}
                disabled={editMode}
                readOnly={false}
                type={PICKLIST_TYPES.SINGLE}
              />
            </SelectFieldContainer>
            <div className="align-center flex w-1/2 flex-row justify-center">
              <SelectFieldContainer className=" ml-7 flex flex-row">
                <PickList
                  fieldKey={'val'}
                  required={false}
                  fieldValues={['val']}
                  label={'From'}
                  selectedItems={ratingRange.from}
                  onSelectedChange={(item: any) => {
                    //onRatingScaleChange(item);
                  }}
                  items={FIELD_RANGE.map((range: any) => {
                    return { val: range };
                  })}
                  disabled={true}
                  readOnly={false}
                  type={PICKLIST_TYPES.SINGLE}
                />
              </SelectFieldContainer>
              <SelectFieldContainer className="mx-3 flex  flex-row">
                <PickList
                  fieldKey={'val'}
                  required={false}
                  label={'To'}
                  fieldValues={['val']}
                  selectedItems={ratingRange.to}
                  onSelectedChange={(item: any) => {
                    setRatingRange({ ...ratingRange, to: item });
                    setSelectedOptions(selectedOptions.slice(0, item[0].val));
                  }}
                  items={FIELD_RANGE.map((range: any) => {
                    return { val: range };
                  })}
                  disabled={
                    (ratingScaleOptions &&
                    ratingScaleOptions.length &&
                    ratingScaleOptions[0].name === 'Custom Range'
                      ? false
                      : true) || editMode
                  }
                  readOnly={false}
                  type={PICKLIST_TYPES.SINGLE}
                />
              </SelectFieldContainer>
            </div>
          </div>
        </>
      )}

      <div className="mt-5 flex w-full flex-col">
        {ratingScaleOptions &&
        ratingScaleOptions.length &&
        ratingScaleOptions[0].name === 'Custom Range'
          ? editFromSurveyTemplate && renderCustomRange(selectedOptions)
          : selectedOptions &&
            selectedOptions?.map((option: any, index: any) => {
              return (
                <div>
                  <div className="mb-5 flex">
                    {(likertType === 'likertIconsWithLabels' || likertType === 'likert') && (
                      <InputFieldContainer className="flex w-full flex-col" key={index}>
                        <InputField
                          type={'text'}
                          disabled={true}
                          id={`rating-scale-option-${index}`}
                          label={`${index + 1}`}
                          placeholder={'Label(optional'}
                          fieldKey={'value'}
                          required={false}
                          asterixFirst={true}
                          formData={option}
                          setFormData={() => {}}
                          formError={formError}
                        />
                      </InputFieldContainer>
                    )}
                    {/* {!isPreview &&
                      editFromSurveyTemplate &&
                      (likertType === 'likertIconsWithLabels' || likertType === 'likertIcons') && (
                        <>
                          <FileUploadField
                            index={index}
                            setFileToOptionData={setFileToOptionData}
                          />
                        </>
                      )} */}
                    {!isPreview && editFromSurveyTemplate && (
                      <div className="flex">{DefaultOptionSelect(index)}</div>
                    )}
                  </div>

                  {/* {!isPreview && editFromSurveyTemplate && likertType === 'likertIcons' && (
                    <div className=" mb-5 flex">
                      <FileUploadField index={index} setFileToOptionData={setFileToOptionData} />
                    </div>
                  )} */}
                  {/* {!isPreview && editFromSurveyTemplate && (
                    <div className="flex">{DefaultOptionSelect(index)}</div>
                  )} */}

                  {(likertType === 'likertIcons' || likertType === 'likertIconsWithLabels') &&
                    ShowLikertImage(fileUploadObj[index], index)}
                  {option?.isSelected && (
                    <div className="-mt-5 mb-5">{t('T_REMOVE_DEFAULT_SELECTED_MSG1')}</div>
                  )}
                </div>
              );
            })}
      </div>
    </>
  );
};

const SelectFieldContainer = styled.div`
  label {
    margin-right: 1rem;
  }
`;
const InputFieldContainer = styled.div`
  input {
    width: 100%;
  }
  button {
    display: flex;
    flex-direction: row;
  }
`;

export const StyledDropdownList = styled(DropDownList)`
  width: 210px;
  padding: 10px;
  top: 30px;
  left: -150px;
`;

export const StyledDropdownListForIconsOnly = styled(DropDownList)`
  width: 210px;
  padding: 10px;
  top: 30px;
  left: 0px;
`;

export default Likert;
