import { Button } from '../Reusable/Shared';
import { useTheme } from '../../context/themeContext';

interface ActionButtonsProps {
  primaryButtonLabel: string;
  primaryButtonAction: Function;
  secondaryButtonLabel: string;
  secondaryButtonAction: Function;
  primaryButtonClass?: string;
  primaryButtonWidth?: string;
  primaryButtonDisabled?: boolean;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  primaryButtonAction,
  primaryButtonLabel,
  secondaryButtonAction,
  secondaryButtonLabel,
  primaryButtonClass = '',
  primaryButtonWidth = '',
  primaryButtonDisabled = false
}) => {
  return (
    <div className="flex w-full">
      <div className="ml-auto"></div>
      {secondaryButtonLabel && (
        <Button
          color={'secondary'}
          width={'fit'}
          classes={'px-4 mr-4'}
          onClick={() => {
            secondaryButtonAction();
          }}
          theme={useTheme().theme}
        >
          {secondaryButtonLabel}
        </Button>
      )}
      <Button
        color={'primary'}
        width={primaryButtonWidth}
        onClick={() => {
          primaryButtonAction();
        }}
        disabled={primaryButtonDisabled}
        classes={primaryButtonClass}
      >
        {primaryButtonLabel}
      </Button>
    </div>
  );
};
