import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import { setToastData } from '../../app/toastReducer';
import { icon_svg_hierarchy, icon_svg_object } from '../../assets/components/svgs';
import { HTTP_STATUS } from '../../http/constants/http.status';
import {
  createMetadataObject,
  getApplication,
  getMetadataInstances,
  getMetadataObject,
  updateMetadataObject
} from '../../http/metadata-service';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import Card from '../shared/cards/Card';
import InputField from '../shared/Fields/InputField';
import RadioButton from '../shared/Fields/RadioButton';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setApplication, setMetadataObject } from '../../app/metadataReducer';
import PlusIcon from '@heroicons/react/outline/PlusIcon';
import { cloneDeep, compact, find, isEmpty, isEqual, isUndefined, uniq } from 'lodash';
import AttributeTree from './AttributeTree';
import {
  getDataTypes,
  reconcileNode,
  buildObjectAttributes,
  UNKNOWN_ATTRIBUTE_ACTION,
  ADD_ATTRIBUTE_ACTION,
  EDIT_ATTRIBUTE_ACTION,
  METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH,
  METADATA_OBJECT_ATTRIBUTE_MIN_VALUE,
  METADATA_OBJECT_ATTRIBUTE_MAX_VALUE,
  DELETE_ATTRIBUTE_ACTION,
  isObjectType,
  METADATA_OBJECT_ATTRIBUTE_ATTRIBUTES
} from './metadataUtil';
import BiButton from '../primitives/buttons/BiButton.primitive';
import ObjectMenu from './ObjectMenu';
import { RouteConstants } from '../../constants/RouteConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { getUserName, getUserProfiles } from '../../util/admin-utils';
import moment from 'moment';
import { AuditBlock, AuditItem } from '../../styles/globalVariables';
import { AppConstants } from '../../constants/AppConstants';
import TextAreaField from '../shared/Fields/TextAreaField';
import { Attribute } from './AttributeTreeNode';
import CreateNodeModal from './CreateNodeModal';
import DeleteConfirmModal from './DeleteConfirmModal';
import { useTheme } from '../../context/themeContext';
import { EllipsisDiv } from '../../styles/globalStyles';
import AuditInfo from '../Common/AuditInfo';

interface AddNodeActionProps {
  onClick: Function;
  editMode: boolean;
}

const AddNodeAction: React.FC<AddNodeActionProps> = ({
  onClick,
  editMode = true
}: AddNodeActionProps) => (
  <>
    <div className="ml-1 mr-1 flex flex-row items-center justify-center rounded border-[1px] border-solid border-[#99B0C6]">
      {editMode && (
        <PlusIcon
          className="m-1 h-4 w-4 text-gray-600 hover:text-black"
          onClick={() => onClick()}
          data-testid="delete-icon"
        />
      )}
    </div>
  </>
);

interface AttributeHeaderProps {
  onClick: Function;
  t: Function;
}

const AttributeHeaders: React.FC<AttributeHeaderProps> = ({ onClick, t }: AttributeHeaderProps) => (
  <>
    <div className="flex-start m-1 flex h-12 flex-1 flex-row flex-wrap items-center bg-[#F5F5F5] ">
      <div className="flex-1 flex-row">{t('T_NAME')}</div>
      <div className="flex-1 flex-row">{t('T_TYPE')}</div>
      <div className="flex-1 flex-row">{t('T_REQUIRED?')}</div>
      <div className="flex-1 flex-row">{t('T_ALLOW_EDITS?')}</div>
      <div className="flex-1 flex-row">{t('T_IS_SEARCHABLE')}</div>
      <div className="flex-1 flex-row">{t('T_IS_SORTABLE')}</div>
      <div className="flex-1 flex-row">{t('T_HASH_OF_CHARATERS')}</div>
      <div className="flex-1 flex-row">{t('T_MIN_HASH')}</div>
      <div className="flex-1 flex-row">{t('T_MAX_HASH')}</div>
      <div className="flex-1 flex-row">{t('T_ACTIONS')}</div>
    </div>
  </>
);

/**
 * @parentId metadata
 * @manager Create, View, Edit Metadata Object
 * @overview Create, View, Edit - New Metadata Object
 *   <section>
 *       <p>
 *           <b>View Metadata Object</b><br/>
 *           Clicking on metadata object row in the metadata object listing page will take user to View Metadata Object Page in READ-ONLY mode. Clicking on <b>Back to <application-name> Objects</b> will take the user to metadata object listing page.
 *           Details page also includes audit information such as <i>Created On</i>, <i>Created By</i>, <i>Last Modified By</i> and <i>Last Modified On</i>
 *           <br/><br/>
 *           <b>Edit Metadata Object</b><br/>
 *           Clicking on <b>Edit</b> button on the admin portal toolbar will change it to editing mode, which allows users to edit <b>Name, Active, Description & Attributes</b> fields. Clicking on <b>Cancel</b> will take the user to metadata object listing page.
 *           <br/><br/>
 *           <b>Create Metadata Object</b><br/>
 *           Clicking on <b>New Object</b> button on metadata object listing page or <b>Add Object</b> from metadata application listing page <b>Manage Metadata</b> dropdown menu will take user to New Metadata Application creation page. Clicking on <b>Cancel</b> will take the user to metadata object listing page.
 *           <br/><br/>
 *           User can create new metadata object by entering <b>Name</b> & <b>Attributes</b> mandatory fields and clicking on <b>Save</b> button on the admin portal toolbar.
 *           <b>Description</b> is an non mandatory freetext field which user can use to enter more detail about the metadata object. Attributes is an JSON (Javascript Object Notation) object which defines the schema definition (like column name, datatype, size, required, editable etc)
 *       </p>
 *       <p>
 *          <h4>View/Edit Metadata Object Dropdown Menu</h4>
 *          <p>Following are the menu items listed under metadata object <b>Manage Object</b> drop down.</p>
 *          <br>
 *          <ul>
 *            <li>Edit Object</li>
 *            <li>View Instances</li>
 *          </ul>
 *       </p>
 *       <p>
 *           <h4>Create/View/Edit Metadata Object Fields</h4>
 *           <p>Create/View/Edit metadata object page will display below fields with following validation constraints.</p>
 *           <br>
 *          <table>
 *            <tr>
 *               <th>Field Name</th>
 *               <th>Required</th>
 *               <th>Data Type</th>
 *               <th>Max Length</th>
 *            </tr>
 *            <tr>
 *               <td>Name</td>
 *               <td>Yes</td>
 *               <td>String</td>
 *               <td>1024</td>
 *            </tr>
 *            <tr>
 *               <td>Active</td>
 *               <td>Yes</td>
 *               <td>Boolean</td>
 *               <td>N/A</td>
 *            </tr>
 *            <tr>
 *               <td>Description</td>
 *               <td>No</td>
 *               <td>String</td>
 *               <td>4096</td>
 *            </tr>
 *            <tr>
 *               <td>Attributes</td>
 *               <td>Yes</td>
 *               <td>JSON(Javascript Object Notation</td>
 *               <td>N/A</td>
 *            </tr>
 *          </table>
 *          <br/>
 *           <b>Note:</b> Active status is set to <b>TRUE</b> by default for new metadata application. Setting it to <b>FALSE</b> will mark the object READ-ONLY and will block any new creation or editing of object instances.
 *       </p>

 *       <p>
 *         <h4> Metadata Object Attributes </h4>
 *         <p>Following are the attribute types supported while defining object schema definition.</p>
 *          <br>
 *          <table>
 *            <tr>
 *               <th>Attribute Type</th>
 *               <th>Applicable Properties</th>
 *               <th>Mandatory Property</th>
 *            </tr>
 *            <tr>
 *               <td>String/String[]</td>
 *               <td>name, maxLength, isRequired (Default: FALSE), editable (Default: TRUE)</td>
 *               <td>maxLength</td>
 *            </tr>
 *            <tr>
 *               <td>Number/Integer</td>
 *               <td>name, min, max, isRequired (Default: FALSE), editable (Default: TRUE)</td>
 *               <td>min, max</td>
 *            </tr>
 *            <tr>
 *               <td>Enum</td>
 *               <td>name, enum, isRequired (Default: FALSE), editable (Default: TRUE)</td>
 *               <td>enum</td>
 *            </tr>
 *            <tr>
 *               <td>Boolean/Datetime/Date/Time</td>
 *               <td>name, isRequired (Default: FALSE), editable (Default: TRUE)</td>
 *               <td>min, max</td>
 *            </tr>
 *            <tr>
 *               <td>Object/Object[]</td>
 *               <td>name, attributes, isRequired (Default: FALSE), editable (Default: TRUE)</td>
 *               <td>attributes</td>
 *            </tr>
 *          </table>
 *          <br/>
 *          <b>Note:</b> Attribute type of <b>Object/Object[]</b> should contain alteast a left level primitive element of type (String/String[]/Number/Integer/Boolean/Datetime/Date/Time)
 *      </p>
 *     </section>
 *     <section>
 *      <h4>Failure Status Codes</h3>
 *       <p>
 *       <p>This section describes the Create Metadata Object Status Code information.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>HTTP Status Code</th>
 *               <th>Service Error Code</th>
 *               <th>Error Message</th>
 *           </tr>
 *           <tr>
 *               <td>403</td>
 *               <td>MDS_PERMISSION_DENIED</td>
 *               <td>You do not have permission to view this page.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_BAD_FORMAT</td>
 *               <td>Bad Syntax: {0} must be a {1}.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_ERROR_PARSING</td>
 *               <td>Error parsing resource JSON or XML ({0}).</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_NOT_EXIST</td>
 *               <td>Resource Id {0} does not exist.</td>
 *           </tr>
 *           <tr>
 *               <td>400</td>
 *               <td>MDS_PARAM_DUPLICATE</td>
 *               <td>Duplicate name, <b><object-name></b> for resource type MetadataObject.</td>
 *           </tr>
 *           <tr>
 *               <td>500</td>
 *               <td>MDS_INTERNAL_ERROR</td>
 *               <td>Internal Server Error</td>
 *           </tr>
 *           <tr>
 *               <td>503</td>
 *               <td></td>
 *               <td>Service Unavailable</td>
 *           </tr>
 *       </table>
 *   </p>
 *   </section
 *   <section>
 *      <h4>Dependent System settings, Platform services & Role Permission</h3>
 *      <p>This section describes the list of dependent system settings & platform services required for functioning of create metadata application page.</p>
 *       <h5>System Settings</h4>
 *       <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Key</th>
 *               <th>Type</th>
 *               <th>Value</th>
 *               <th>Scope</th>
 *           </tr>
 *           <tr>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *               <td>None</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>Platform Service(s)</h4>
 *      <p>Table lists all the dependent platform service(s) with specific version(s) for create metadata application.</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Service Name</th>
 *               <th>Version</th>
 *           </tr>
 *           <tr>
 *               <td>Metadata Service</td>
 *               <td>1.2.0</td>
 *           </tr>
 *           <tr>
 *               <td>User Management Service</td>
 *               <td>1.4.0</td>
 *           </tr>
 *       </table>
 *       <br>
 *      <h5>API Role Permission(s)</h4>
 *      <p>Table lists the required API role permissions for creating metadata application page</p>
 *       <br>
 *       <table>
 *           <tr>
 *               <th>Feature</th>
 *               <th>API URL</th>
 *               <th>API Method</th>
 *               <th>API Permission</th>
 *               <th>Required</th>
 *           </tr>
 *           <tr>
 *               <td>View Object</td>
 *               <td>/applications/{appId}/objects/{id}</td>
 *               <td>POST</td>
 *               <td>metadata-service.metadata-object.get</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Edit Object</td>
 *               <td>/applications/{appId}/objects/{id}</td>
 *               <td>PUT</td>
 *               <td>metadata-service.metadata-object.update</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>Create Object</td>
 *               <td>/applications/{appId}/objects/{id}</td>
 *               <td>POST</td>
 *               <td>metadata-service.metadata-object.create</td>
 *               <td>Yes</td>
 *           </tr>
 *           <tr>
 *               <td>List users (View & Edit Metadata Object)</td>
 *               <td>/users</td>
 *               <td>GET</td>
 *               <td>user-management-service.user.list</td>
 *               <td>Yes</td>
 *           </tr>
 *       </table>
 *   </section>
 *   <section>
 *    <p>Sequence Diagram for create metadata object</p>
 *   </section>
 */
const CreateObject = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(!params.objectId);
  const [instances, setInstances] = useState<Array<any>>([]);

  const [attribute, setAttribute] = useState<Attribute>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [attributeInfo, setAttributeInfo] = useState<any>();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const application = useAppSelector((state) => state.metadata.application);
  const metadataObject = useAppSelector((state: any) => state.metadata.metadataObject);

  const [formData, setFormData] = useState<any>({
    name: '',
    active: true,
    description: ''
  });
  const [formError, setFormError] = useState<any>({
    name: '',
    description: ''
  });
  const [attributes, setAttributes] = useState<Array<any>>([]);
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      if (params.id) {
        await getMetadataApplication(params.id);
      }
      if (params.objectId) {
        await getApplicationObject(params.id, params.objectId);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (!params.objectId) {
      setIsLoading(false);
      return;
    }
    const init = async () => {
      const {
        name = '',
        description = '',
        active = true,
        attributes = [],
        createdBy,
        createdOn,
        updatedBy,
        updatedOn
      } = metadataObject || {};
      const userInfoMap: Map<string, any> = await getObjectAuditInfo();
      setFormData({
        name,
        description,
        active,
        createdBy: getUserName(userInfoMap.get(createdBy)),
        updatedBy: getUserName(userInfoMap.get(updatedBy)),
        updatedOn: moment(updatedOn).format(AppConstants.DEFAULT_DATE_FORMAT),
        createdOn: moment(createdOn).format(AppConstants.DEFAULT_DATE_FORMAT)
      });

      if (attributes.length) {
        //Hack: New objects are extensible.
        setAttributes(JSON.parse(JSON.stringify(attributes)));
      }
      setIsLoading(false);
    };
    init();
  }, [metadataObject]);

  useEffect(() => {
    const breadcrumb: Array<string> = ['Metadata'];
    if (application) breadcrumb.splice(1, 0, application?.name);
    if (metadataObject) breadcrumb.splice(2, 0, metadataObject?.name);

    const label: string = breadcrumb.join(' > ');
    const key: string = RouteConstants.ROUTE_MDS_OBJECT_VIEW.replace(':id', params?.id).replace(
      ':objectId',
      params?.objectId
    );

    dispatch(setURLPaths([{ key, label }]));
  }, [application, metadataObject]);

  useEffect(() => {
    const init = async () => {
      if (isEdit && params?.objectId) {
        setIsLoading(true);
        const response: any = await getMetadataInstances(
          params?.objectId,
          [],
          AppConstants.DEFAULT_PAGE,
          1
        );
        const { status, data } = response;
        if (status === HTTP_STATUS.HTTP_OK) {
          const { data: instances = [] } = data;
          setInstances(instances);
        }
        setIsLoading(false);
      }
    };
    init();
  }, [isEdit]);

  const getObjectAuditInfo = async () => {
    if (!metadataObject) {
      return new Map<string, any>();
    }
    const { createdBy, updatedBy } = metadataObject || {};
    const userProfiles: Array<any> = await getUserProfiles(uniq(compact([createdBy, updatedBy])));
    return new Map<string, any>(
      userProfiles.map((userProfile: any) => [userProfile.userId, userProfile])
    );
  };

  const getMetadataApplication = async (id: string) => {
    if (application) {
      return;
    }
    const response = await getApplication(id);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setApplication(data));
    }
  };

  const getApplicationObject = async (applicationId: string, objectId: string) => {
    if (metadataObject) {
      return;
    }
    const response = await getMetadataObject(applicationId, objectId);
    const { status, data } = response;
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(setMetadataObject(data));
    }
  };

  const disableSave = () => {
    let isSaveDisabled: boolean = true;
    if (params?.objectId && metadataObject) {
      const { name, active, description, attributes: attributes1 } = metadataObject;

      isSaveDisabled = isEqual(
        { name, active, description, attributes: attributes1 },
        {
          name: formData.name.trim(),
          description: formData.description.trim(),
          active: formData.active,
          attributes: buildObjectAttributes(cloneDeep(attributes))
        }
      );
    } else {
      isSaveDisabled = isEmpty(formData.name.trim()) || isEmpty(attributes);
    }
    return isSaveDisabled;
  };

  const handleSave = async () => {
    setIsLoading(true);
    let toastType: string = 'success';
    let toastMessage: string = (
      params.objectId
        ? t('T_METADATA_OBJECT_UPDATED_SUCCESS')
        : t('T_METADATA_OBJECT_CREATED_SUCCESS')
    ).replace('%s', formData.name);

    const payload: any = {
      name: formData.name?.trim(),
      description: formData.description?.trim() || '',
      active: formData.active,
      attributes: buildObjectAttributes(cloneDeep(attributes))
    };

    let response: any;
    if (!params.objectId) {
      response = await createMetadataObject(params.id, payload);
    } else {
      response = await updateMetadataObject(params.id, params.objectId, payload);
    }
    const { status, data = {} } = response;
    const success: boolean = HTTP_STATUS.isSuccess(status);
    if (!success) {
      toastType = 'error';
      toastMessage = getFailureMessage(response);
    } else {
      setIsEdit(!isEdit);
    }
    dispatch(
      setToastData({
        toastMessage,
        isToastActive: true,
        toastType
      })
    );
    setIsLoading(false);

    if (success) {
      if (!params.objectId) {
        const { metadataApplicationId } = data;
        navigate(RouteConstants.ROUTE_MDS_OBJECT_LIST.replace(':id', metadataApplicationId));
      } else {
        dispatch(setMetadataObject(data));
      }
    }
  };

  const getCancelButtonText = (): string => {
    if (!params?.objectId || isEdit) {
      return t('T_CANCEL');
    }
    return t('T_BACK_TO_OBJECTS').replace(
      '%s',
      application?.name.length < 50 ? application?.name : `${application?.name.substring(0, 50)}...`
    );
  };

  const addNode = (parentChain: Array<any> = [], level: number = 0, order: number = 0) => {
    let nodeType: string = '';
    const parentNode: any = findNode(parentChain, order, false);
    if (parentNode) {
      const { type } = parentNode;
      const nodeDataTypes: Array<string> = getDataTypes(type);
      nodeType = nodeDataTypes.length == 1 ? nodeDataTypes[0] : '';
    }

    setShowModal(true);
    setAttribute(createEmptyNode(level, nodeType));
    setAttributeInfo({ parentChain, level, order, operation: ADD_ATTRIBUTE_ACTION });
  };

  const createEmptyNode = (
    level: number,
    type?: string,
    name?: string,
    isRequired?: boolean,
    editable?: boolean,
    serachable?: boolean,
    sortable?: boolean
  ) => {
    const newNode: any = {
      name: name || '',
      type: type || '',
      isRequired: isUndefined(isRequired) ? false : isRequired,
      editable: isUndefined(editable) ? true : editable,
      serachable: isUndefined(serachable) ? false : serachable,
      sortable: isUndefined(sortable) ? false : sortable,
      // edit: true,
      level,
      isNew: true
    };
    if (type) reconcileNode(newNode, type);
    return newNode;
  };

  const findNode = (parentChain: Array<any> = [], order: number = 0, expand?: boolean): any => {
    let node: any;

    const isLastElement = (items: Array<any> = [], index: number = 0) => {
      let lastItem: boolean = true;
      if (!items.length) {
        return lastItem;
      }
      return items.length - 1 === index;
    };

    if (parentChain.length) {
      let rootNodes: Array<any> = attributes;
      parentChain.forEach((item: any, index: number) => {
        const { name } = item;
        node = find(rootNodes, { name });
        if (node) {
          if (expand) {
            node['expanded'] = expand;
          }
          rootNodes = node.attributes;
        }
      });
    } else {
      if (order < attributes.length) {
        node = attributes[order];
      }
    }
    return node;
  };

  const confirmDelete = (
    show: boolean = false,
    parentChain: Array<any> = [],
    level: number = 0,
    order: number = 0
  ) => {
    setDeleteConfirmation(show);
    setAttributeInfo(
      show ? { parentChain, level, order, operation: DELETE_ATTRIBUTE_ACTION } : null
    );
  };

  const onDeleteNode = () => {
    const { level, order, parentChain = [], operation } = attributeInfo;
    if (operation !== DELETE_ATTRIBUTE_ACTION) {
      return;
    }
    if (!level) {
      attributes.splice(order, 1);
    } else {
      let newParentChain: Array<any> = parentChain;
      if (parentChain.length) {
        newParentChain.splice(parentChain.length - 1);
      }

      const parentNode: any = findNode(newParentChain, order, true);
      if (parentNode) {
        const { attributes: children = [] } = parentNode;
        children.splice(order, 1);
      }
    }
    setAttributes([...attributes]);
    setAttributeInfo(null);
  };

  const onDataTypeChange = (
    parentChain: Array<any> = [],
    type: string,
    level: number,
    order: number
  ) => {
    const node: any = findNode(parentChain, order);
    if (node) {
      reconcileNode(node, type);
      setAttributes([...attributes]);
    }
  };

  const onValueChange = (
    parentChain: Array<any> = [],
    key: any,
    value: any,
    level: number = 0,
    order: number = 0,
    nodeName: string
  ) => {
    setShowModal(true);
    setAttribute(findNode(parentChain, order, true));
    setAttributeInfo({ parentChain, level, order, operation: EDIT_ATTRIBUTE_ACTION });
  };

  const onAttributeUpdate = (node: Attribute) => {
    if (!attributeInfo || !node) {
      return;
    }

    const { parentChain = [], order = 0, operation = UNKNOWN_ATTRIBUTE_ACTION } = attributeInfo;
    if (operation == ADD_ATTRIBUTE_ACTION) {
      if (!parentChain.length) {
        setAttributes([...attributes, { ...node }]);
      } else {
        const parentNode: any = findNode(parentChain, order, true);
        if (parentNode) {
          const { attributes = [] } = parentNode;
          attributes.push({ ...node });
        }
        setAttributes([...attributes]);
      }
    } else if (operation == EDIT_ATTRIBUTE_ACTION) {
      let parentNode: any = findNode(parentChain, order, true);
      if (parentNode) {
        parentNode = Object.assign(parentNode, node);
        if (!node[METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH]) {
          delete parentNode[METADATA_OBJECT_ATTRIBUTE_MAX_LENGTH];
        }
        if (!node[METADATA_OBJECT_ATTRIBUTE_MIN_VALUE]) {
          delete parentNode[METADATA_OBJECT_ATTRIBUTE_MIN_VALUE];
        }
        if (!node[METADATA_OBJECT_ATTRIBUTE_MAX_VALUE]) {
          delete parentNode[METADATA_OBJECT_ATTRIBUTE_MAX_VALUE];
        }
        if (!isObjectType(parentNode?.type)) {
          delete parentNode[METADATA_OBJECT_ATTRIBUTE_ATTRIBUTES];
        }
        // if (!node[METADATA_OBJECT_ATTRIBUTE_ENUM]) {
        //   delete parentNode[METADATA_OBJECT_ATTRIBUTE_ENUM];
        // }
      }
      setAttributes([...attributes]);
    }
    setShowModal(false);
    setAttributeInfo(null);
  };

  const expandTreeNode = (
    parentChain: Array<any> = [],
    level: number = 0,
    order: number = 0,
    expand: boolean = false
  ) => {
    findNode(parentChain, order, expand);
    // setAttributes([...attributes]);
  };

  const handleChange = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleOnCancel = () => {
    const { name = '', description = '', attributes = [], active = true } = metadataObject;
    setFormData({ name, description, active });
    setAttribute(attributes);
    setIsEdit(false);
  };
  return (
    <LoaderContainer isLoading={isLoading}>
      <MenuButtonsPortal>
        <BiButton
          className="bg-white text-primary"
          type="button"
          onClick={() => {
            if (!params?.objectId) {
              navigate(-1);
              return;
            }
            if (isEdit) {
              // setIsEdit(false);
              handleOnCancel();
            } else {
              navigate(RouteConstants.ROUTE_MDS_OBJECT_LIST.replace(':id', params.id));
            }
          }}
        >
          {getCancelButtonText()}
        </BiButton>
        {isEdit ? (
          <BiButton
            className="bg-primary text-white"
            type="button"
            onClick={handleSave}
            disabled={disableSave()}
          >
            {t('T_SAVE')}
          </BiButton>
        ) : (
          metadataObject && <ObjectMenu metadataObject={metadataObject} setEdit={setIsEdit} />
        )}
      </MenuButtonsPortal>
      <div
        className="flex max-h-screen w-full flex-col justify-start overflow-y-scroll bg-gray-100 px-2 py-3"
        style={useTheme().theme.bgColorStyleForLoggedInPage}
      >
        <div className="flex w-full flex-row">
          <div className="w-3/4 p-2">
            <Card
              title={t('T_OBJECT_DETAILS')}
              icon={icon_svg_object}
              alt="file-code-icon"
              className="flex flex-1"
            >
              <div className="flex w-full flex-col flex-wrap">
                <div className="mb-5 flex w-full flex-row">
                  <div className="flex w-3/4 flex-col">
                    <InputField
                      type={'text'}
                      id={'metadata-name'}
                      label={'T_NAME'}
                      placeholder={''}
                      fieldKey={'name'}
                      asterixFirst={true}
                      required={true}
                      formData={formData}
                      setFormData={setFormData}
                      formError={formError}
                      disabled={!isEdit}
                      handleChange={(e: any) => {
                        handleChange('name', e.target.value);
                      }}
                      min={1}
                      max={1024}
                    />
                  </div>
                  <div className="flex w-1/4 flex-col pl-4">
                    <div className="flex flex-row items-center">{t('T_ACTIVE')}</div>
                    <div className="flex h-full items-center justify-start">
                      <RadioButton
                        selectedField={formData?.active ? 'Yes' : 'none'}
                        option={'Yes'}
                        name={'Yes'}
                        label={t('T_YES')}
                        onClick={() => {
                          setFormData({ ...formData, active: true });
                        }}
                        disabled={!isEdit}
                      />
                      <RadioButton
                        selectedField={!formData?.active ? 'No' : 'none'}
                        option={'No'}
                        name={'No'}
                        label={t('T_NO')}
                        className="pl-2"
                        onClick={() => {
                          setFormData({ ...formData, active: false });
                        }}
                        disabled={!isEdit}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-col flex-wrap">
                  <TextAreaField
                    fieldKey={'description'}
                    type={'text'}
                    id={'object_description'}
                    label={t('T_DESCRIPTION')}
                    placeholder={''}
                    required={false}
                    formData={formData}
                    setFormData={setFormData}
                    formError={formError}
                    asterixFirst={false}
                    rows={4}
                    disabled={!isEdit}
                    maxLength={4096}
                  ></TextAreaField>
                </div>

                {/* <InputField
                  type={'text'}
                  id={'description'}
                  label={'T_DESCRIPTION'}
                  placeholder={''}
                  fieldKey={'description'}
                  required={false}
                  formData={formData}
                  setFormData={setFormData}
                  formError={formError}
                  className="mb-5"
                  disabled={!isEdit}
                /> */}
              </div>
            </Card>
          </div>
          <div className="w-1/2 p-2">
            {params.objectId && (
              <div className="ml-5 flex flex-1 flex-col">
                <AuditBlock>
                  <AuditItem>
                    <div>{t('T_METADATA_NAME')}</div>
                    <EllipsisDiv className="w-3/4 flex-1" title={application?.name}>
                      {application?.name}
                    </EllipsisDiv>
                    {/* <div>{application?.name}</div> */}
                  </AuditItem>
                  <AuditItem>
                    <div>{t('T_METADATA_ID')}</div>
                    <div>{application?.id}</div>
                  </AuditItem>
                  <AuditItem>
                    {(formData?.createdOn || formData?.createdBy) && (
                    <div>
                      <AuditInfo
                        label={'CREATED'}
                        date={formData?.createdOn || ''}
                        user={formData?.createdBy || ''}
                        isUserId={false}
                      />
                    </div>
                    )}
                    {(formData?.updatedOn || formData?.updatedBy) && (
                    <div>
                      <AuditInfo
                        label={'LAST_MODIFIED'}
                        date={formData?.updatedOn || ''}
                        user={formData?.updatedBy || ''}
                        isUserId={false}
                      />
                    </div>
                    )}
                  </AuditItem>
                </AuditBlock>
              </div>
            )}
          </div>
        </div>
        <div className="fex-col w-full p-2">
          <Card
            title={t('T_ATTRIBUTES')}
            icon={icon_svg_hierarchy}
            alt="file-code-icon"
            className="w-full"
            actions={
              <div className="flex flex-1 justify-end">
                <AddNodeAction
                  onClick={() => addNode([], 0, attributes.length)}
                  editMode={isEdit}
                />
              </div>
            }
          >
            <AttributeTree
              readOnly={!isEdit}
              onAddNode={addNode}
              attributes={attributes}
              onRemoveNode={confirmDelete}
              onValueChange={onValueChange}
              expandTreeNode={expandTreeNode}
              hasInstances={!!instances.length}
              onDataTypeChange={onDataTypeChange}
            />
            {/* {!JSONMode && (
              <TreeView
                nodes={attributes}
                // onAddNode={addNode}
                // onValueChange={onValueChange}
                // onDataTypeChange={onDataTypeChange}
                // onRemoveNode={onDeleteNode}
                readOnly={!isEdit}
              />
            )} */}
            {/* {JSONMode && (
                <JSONEditor
                  setIsLoading={setIsLoading}
                  isComponent={true}
                  jsonString={jsonString}
                  setJsonString={setJsonString}
                  disabled={!isEdit}
                  showLineNumber={true}
                  showFormatJSONButton={true}
                  showImportJSONButton={false}
                  formatJSONButtonLabel="{}"
                  JSONEditorLabel={t('T_ATTRIBUTES')}
                />
              )} */}
          </Card>
        </div>
      </div>
      {deleteConfirmation && (
        <DeleteConfirmModal
          width={'35rem'}
          title={t('T_DELETE_CONFIRMATION_TITLE')}
          onClose={() => confirmDelete()}
          children={
            <div>
              <span>
                {t('T_DELETE_CONFIRMATION')}
                <b>
                  {` ${attributeInfo?.parentChain[attributeInfo?.parentChain?.length - 1]?.name} `}
                </b>
                {t('T_ATTRIBUTE')}?
              </span>
            </div>
          }
          onCancel={() => confirmDelete()}
          onConfirm={() => {
            setDeleteConfirmation(false);
            setTimeout(() => onDeleteNode());
          }}
        />
      )}
      {showModal && (
        <CreateNodeModal
          show={showModal}
          attribute={attribute}
          onClose={setShowModal}
          onCancel={setShowModal}
          onSave={onAttributeUpdate}
          hasInstances={!!instances.length}
          operation={attributeInfo?.operation}
          level={attributeInfo?.parentChain?.length || 0}
          baseParent={attributeInfo?.parentChain?.length ? attributeInfo?.parentChain[0] : null}
        />
      )}
    </LoaderContainer>
  );
};

export default React.memo(CreateObject);
