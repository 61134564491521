import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { App } from './App';
import { store } from './app/store';
import 'tw-elements';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';

const rootElement = document.getElementById('root');

render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <Provider store={store}>
        <ToastContainer />
        <App />
      </Provider>
    </React.Suspense>
  </React.StrictMode>,
  rootElement
);
