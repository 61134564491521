import InputField from '../../../shared/Fields/InputField';
import { useTranslation } from 'react-i18next';
import { urlPATTERN } from '../../../../util/constants';

interface URLProps {
  formData: any;
  setFormData: any;
  editMode?: boolean;
  formError: any;
  setFormError?: any;
  editFromSurveyTemplate?: boolean;
}
const URLComponent = ({
  formData,
  setFormData,
  editMode,
  formError,
  setFormError,
  editFromSurveyTemplate
}: URLProps) => {
  const { initial = [] } = formData;
  const { t } = useTranslation();

  const validateField = () => {
    if (formData.initial && formData.initial.length) {
      if (!urlPATTERN.test(formData.initial)) {
        setFormError({ initial: 'Invalid URL format. Valid format is http://example.com.' });
      } else if (urlPATTERN.test(formData.initial)) {
        setFormError({ initial: '' });
      }
    }
  };

  return (
    <>
      {editFromSurveyTemplate && (
        <>
          <div className="flex w-full flex-row">
            <span>Set initial Value to:</span>
          </div>
          <div className="mt-3 flex w-full flex-col">
            <InputField
              className="w-full"
              type="text"
              id="max-word-count"
              label=""
              placeholder=" http://example.com"
              fieldKey="initial"
              required={false}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              disabled={editMode}
              onBlurhandler={(e: any) => {
                validateField();
              }}
              handleChange={(e: any) => {
                if (formError.initial) {
                  setFormError({ ...formError, initial: '' });
                }
                setFormData({ ...formData, initial: e.target.value ? [e.target.value] : [] });
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default URLComponent;
