import { useTranslation } from 'react-i18next';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import Card from '../shared/cards/Card';
import Clipboard from '../../assets/clipboard.svg';
import { useTheme } from '../../context/themeContext';
import BiButton from '../primitives/buttons/BiButton.primitive';
import { useCallback, useEffect, useRef, useState } from 'react';
import MenuButtonsPortal from '../Menu/MenuButtonsPortal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setURLPaths } from '../../app/organizationReducer';
import {
  createSignedURL,
  getGCPFile,
  getPublicMobileConfig,
  uploadFile
} from '../../http/asset-management-services';
import axios from 'axios';
import BaseAPI from '../../http/api';
import { camera, downloadIcon, trashIcon } from '../../assets/components/svgs';
import SelectField from '../shared/Fields/SelectField';
import Dropzone from '../shared/DragAndDrop/Dropzone';
import { cloneDeep, findIndex, isEmpty, some } from 'lodash';
import { getCatalogConfigData, updateGCPFile } from './MobileConfigUtil';
import { RouteConstants } from '../../constants/RouteConstants';
import { setConfigList, setSelectedConfig } from '../../app/mobileConfigurationReducer';
import { AppConstants } from '../../constants/AppConstants';
import { HTTP_STATUS } from '../../http/constants/http.status';
import moment from 'moment';
import PickList, { PICKLIST_TYPES } from '../shared/Fields/PickList';
import { IconButton } from '../primitives/buttons/IconButton';
import { setToastData } from '../../app/toastReducer';
import { getGlobalBucketName } from '../../util/AppSetting';
import { updateMetadataInstance } from '../../http/metadata-service';
import Modal from '../shared/ModalPortal/Modal';
import styled from 'styled-components';
import { validateName } from '../../util/validators';
import { PaperClipIcon, PlusCircleIcon } from '@heroicons/react/outline';
import InputField from '../shared/Fields/InputField';
import UploadContracts from '../ManageContracts/UploadContracts';

const API = BaseAPI?.axiosInstance;

function CardSection({ children }: any) {
  return (
    <div
      className={`flex w-full flex-col justify-start  overflow-visible bg-gray-100`}
      style={useTheme().theme.bgColorStyleForLoggedInPage}
    >
      <div className="flex w-full">
        <div className="flex w-full">
          <div className="p-2"></div>
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  );
}

const defaultFormData = {
  name: '',
  language: '',
  locale: '',
  pickerTitle: '',
  pickerButtonTitle: '',
  version: 1,
  public: true,
  isEnabled: true,
  isDefault: false
};

const defaultFormError = {
  name: '',
  language: '',
  locale: '',
  pickerTitle: '',
  pickerButtonTitle: ''
};

export interface FileInfo {
  file: File;
  metadata: any;
  versioning: boolean;
  publicObject: boolean;
  issues?: Array<any>;
}

const AddNewLanguage = () => {
  const { t } = useTranslation();
  const inputRef = useRef<any>({});
  const navigate = useNavigate();
  const [configData, setConfigData] = useState<any>(null);
  const [languageConfigList, setLanguageConfigList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<any>(defaultFormData);
  const [fileError, setFileError] = useState({ errorText: '', fileName: '' });
  const [loader, setLoader] = useState(false);
  const [formErrorData, setFormErrorData] = useState<any>(defaultFormError);

  const config = 'MobileTranslations';

  const userData = JSON.parse(localStorage.getItem('user-profile') || '');

  const storedURLPaths = useAppSelector((state) => state.organization.urlPaths);
  const metaDataObjectList = useAppSelector((state) => state.initialLoadData.metaDataObjectList);
  const selectedCatalog = useAppSelector((state) => state.mobileConfiguration.selectedConfig);
  const configCatalog = useAppSelector((state) => state.mobileConfiguration.configCatalog);

  const [files, setFiles] = useState<Array<FileInfo>>([]);

  useEffect(() => {
    async function initialise() {
      setBreadCrumb();
      await getConfig();
    }

    initialise();
  }, []);

  const getConfig = async () => {
    try {
      const gcpLanguageConfig = await getPublicMobileConfig();
      let { status, data } = gcpLanguageConfig;
      if (status === HTTP_STATUS.HTTP_OK) {
        const { MobileTranslations } = data;
        setConfigData(data);
        setLanguageConfigList(MobileTranslations.languages);
        setIsLoading(false);
      }
    } catch (e) {
      navigate(-1);
      dispatch(
        setToastData({
          isToastActive: true,
          toastMessage: t('T_ERROR_SOMETHING_WRONG'),
          toastType: 'error'
        })
      );
    }
  };

  const formValidator = () => {
    if (!isEmpty(files)) {
      return false;
    }
    return true;
  };

  const setBreadCrumb = () => {
    dispatch(
      setURLPaths([
        ...storedURLPaths,
        {
          key: RouteConstants.ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION_NEW,
          label: `${t('T_MOBILE_CONFIGURATION')} > ${t('T_MOBILE_CONFIGURATION_LANGUAGE')} > ${t(
            'T_CREATE'
          )}`
        }
      ])
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles.length) {
        dispatch(
          setToastData({
            toastMessage: t('T_NOT_UPLOADED'),
            isToastActive: true,
            toastType: 'error'
          })
        );
        return;
      }
      // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too

      let [fileInfo] = acceptedFiles;
      setFiles([{ file: fileInfo, versioning: false, publicObject: true, metadata: {} }]);
    },
    [files]
  );

  const isLocaleExisting = () => {
    return some(languageConfigList, { locale: formData.locale });
  };

  const ValidationData = () => {
    let validaName = validateName(formData.name, t);
    if (validaName) {
      setFormErrorData({ ...formErrorData, name: validaName });
      return false;
    }

    if (isLocaleExisting()) {
      setFormErrorData({ ...formErrorData, locale: t('T_LOCALE_EXISTING_ERROR') });
      return false;
    }

    if (!files.length) {
      dispatch(
        setToastData({
          toastMessage: t('T_NO_FILE_CHOSEN'),
          isToastActive: true,
          toastType: 'error'
        })
      );
      return false;
    }
    return true;
  };

  const handleValidation = () => {
    const { name, language, locale, pickerTitle, pickerButtonTitle } = formData;
    return (
      !name.trim() ||
      !language.trim() ||
      !locale.length ||
      !pickerTitle.trim() ||
      !pickerButtonTitle.trim()
    );
  };

  const removeFileFromUploadList = (selectedIndex: number, fileName: string) => () => {
    if (fileName == fileError.fileName) {
      setFileError({ errorText: '', fileName: '' });
    }
    setFiles([]);
  };

  const savePayload = async () => {
    function errorHandling(errorText: string) {
      setIsLoading(false);
      dispatch(
        setToastData({
          toastMessage: errorText,
          isToastActive: true,
          toastType: 'error'
        })
      );
    }

    const { name, language, locale, pickerTitle, pickerButtonTitle } = formData;
    const [fileInfo] = files;
    const { file, versioning = true, publicObject = true, metadata = {} } = fileInfo;

    const filePath = `${AppConstants.MOBILE_CONFIGURATION_LANGUAGE_BUCKET_FOLDER}${locale}.${AppConstants.DATA_TYPE_JSON}`;
    const payload = {
      filePath,
      bucketName: getGlobalBucketName(),
      versioning: true,
      action: AppConstants.GCS_UPLOAD_ACTION,
      publicObject: true,
      expirationTime: AppConstants.GCS_MAX_EXPIRATION_TIME,
      metadata: {
        fileName: `${locale}.${AppConstants.DATA_TYPE_JSON}`,
        key: locale,
        userId: userData ? userData.userId : '',
        [AppConstants.HEADER_CACHE_CONTROL]: AppConstants.VALUE_NO_CACHE,
        [AppConstants.HEADER_MAX_AGE]: AppConstants.VALUE_MAX_AGE_MINIMUM
      }
    };

    const signedUrlResponse = await createSignedURL(payload);

    if (signedUrlResponse.status === HTTP_STATUS.HTTP_CREATED) {
      const { url, headers = {} } = signedUrlResponse.data;

      const uploadPayload = {
        url,
        body: file,
        headers: { ...headers, [AppConstants.HEADER_CONTENT_TYPE]: file?.type }
      };

      let uploadFileRes = await uploadFile(
        uploadPayload,
        () => {},
        (err: string) => {
          errorHandling(t('T_NOT_UPLOADED'));
        }
      );

      if (uploadFileRes.status === HTTP_STATUS.HTTP_OK) {
        let newLanguageConfig = {
          ...formData,
          id: locale,
          updated: moment(new Date(), 'YYYY-MM-DD hh:mm:ss').toISOString().split('.')[0] + 'Z',
          location: `${AppConstants.MOBILE_CONFIGURATION_LANGUAGE_BUCKET_PATH}${filePath}`
        };

        let currentLanguageConfig = cloneDeep(configData);
        currentLanguageConfig.MobileTranslations.version += 1;
        currentLanguageConfig.MobileTranslations.languages.push(newLanguageConfig);

        let updateLanguageConfigVersion: any = await updateGCPFile(
          JSON.stringify(currentLanguageConfig, null, '\t'),
          AppConstants.GLOBAL_MOBILE_CONFIG_FILE,
          userData ? userData.userId : ''
        );

        if (updateLanguageConfigVersion.status === HTTP_STATUS.HTTP_OK) {
          dispatch(
            setToastData({
              toastMessage: t('T_MOBILE_CONFIGURATION_LANGUAGE_ADDED_SUCCESS'),
              isToastActive: true,
              toastType: 'success'
            })
          );
          setFiles([]);
          inputRef.current.value = '';
          navigate(RouteConstants.ROUTE_MOBILE_CONFIG_LANGUAGE_TRANSLATION);
        } else {
          errorHandling(t('T_ERROR_SOMETHING_WRONG'));
        }
      } else {
        errorHandling(t('T_NOT_UPLOADED'));
      }
    } else {
      errorHandling(t('T_NOT_UPLOADED'));
    }
  };

  const updateFileInfo = (index: number, fileInfo: FileInfo) => {
    if (index !== -1) {
      files.splice(index, 1);
      files.splice(index, 0, fileInfo);
      setFiles([...files]);
    }
  };

  return (
    <LoaderContainer isLoading={loading}>
      <MenuButtonsPortal>
        <>
          <>
            <BiButton
              className={'mr-2 border border-solid border-primary-disabled py-1.5 text-primary'}
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('T_CANCEL')}
            </BiButton>
            <BiButton
              className={'bg-primary py-1.5 text-white'}
              type="button"
              disabled={handleValidation()}
              onClick={() => {
                if (ValidationData()) {
                  setIsLoading(true);
                  savePayload();
                }
              }}
            >
              {t('T_SAVE')}
            </BiButton>
          </>
        </>
      </MenuButtonsPortal>
      <section className="w-full">
        <div className="flex">
          <div className="flex w-4/5 flex-col  overflow-visible px-2 py-3">
            <div
              className="w-full bg-gray-100 pt-5"
              style={useTheme().theme.bgColorStyleForLoggedInPage}
            >
              <CardSection>
                <Card
                  title={t('T_LANGUAGE_DETAILS')}
                  icon={Clipboard}
                  alt={t('T_USER_CARD_ALT_MESSAGE')}
                  height=""
                  width=""
                >
                  <div className="relative w-full">
                    <div className="mb-4 w-full">
                      <InputField
                        className="w-full"
                        type="text"
                        id={'language_name'}
                        label={t('T_NAME')}
                        placeholder={t('T_NAME')}
                        fieldKey="name"
                        required={true}
                        formData={formData}
                        formError={formErrorData}
                        setFormData={setFormData}
                        disabled={loading}
                        handleChange={(e: any) => {
                          const { value = '' } = e.target || {};
                          setFormErrorData({ ...formErrorData, name: '' });
                          setFormData({ ...formData, name: value });
                        }}
                      />
                    </div>

                    <div className="mb-4 flex w-full">
                      <div className="mr-2 w-1/2">
                        <InputField
                          className="w-full"
                          type="text"
                          id={'language_code'}
                          label={t('T_LANGUAGE')}
                          placeholder={t('T_LANGUAGE')}
                          fieldKey="language"
                          required={true}
                          formData={formData}
                          formError={formErrorData}
                          setFormData={setFormData}
                          disabled={loading}
                          handleChange={(e: any) => {
                            const { value = '' } = e.target || {};
                            setFormErrorData({ ...formErrorData, language: '' });
                            setFormData({ ...formData, language: value });
                          }}
                        />
                      </div>
                      <div className="ml-2 w-1/2">
                        <InputField
                          className="w-full"
                          type="text"
                          id={'locale'}
                          label={t('T_LOCALE')}
                          placeholder={t('T_LOCALE')}
                          fieldKey="locale"
                          required={true}
                          formData={formData}
                          formError={formErrorData}
                          setFormData={setFormData}
                          disabled={loading}
                          handleChange={(e: any) => {
                            const { value = '' } = e.target || {};
                            setFormErrorData({ ...formErrorData, locale: '' });
                            setFormData({ ...formData, locale: value });
                          }}
                        />
                      </div>
                    </div>

                    <div className="mb-4 flex w-full">
                      <div className="mr-2 w-1/2">
                        <InputField
                          className="w-full"
                          type="text"
                          id={'language_picker_title'}
                          label={t('T_LANGUAGE_PICKER_TITLE')}
                          placeholder={t('T_LANGUAGE_PICKER_TITLE')}
                          fieldKey="pickerTitle"
                          required={true}
                          formData={formData}
                          formError={formErrorData}
                          setFormData={setFormData}
                          disabled={loading}
                          handleChange={(e: any) => {
                            const { value = '' } = e.target || {};
                            setFormErrorData({ ...formErrorData, pickerTitle: '' });
                            setFormData({ ...formData, pickerTitle: value });
                          }}
                        />
                      </div>
                      <div className="ml-2 w-1/2">
                        <InputField
                          className="w-full"
                          type="text"
                          id={'pickerButtonTitle'}
                          label={t('T_LANGUAGE_PICKER_BUTTON_TITLE')}
                          placeholder={t('T_LANGUAGE_PICKER_BUTTON_TITLE')}
                          fieldKey="pickerButtonTitle"
                          required={true}
                          formData={formData}
                          formError={formErrorData}
                          setFormData={setFormData}
                          disabled={loading}
                          handleChange={(e: any) => {
                            const { value = '' } = e.target || {};
                            setFormErrorData({ ...formErrorData, pickerButtonTitle: '' });
                            setFormData({ ...formData, pickerButtonTitle: value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </CardSection>
            </div>

            <div
              className="w-full bg-gray-100 pt-3"
              style={useTheme().theme.bgColorStyleForLoggedInPage}
            >
              <CardSection className="w-full">
                <Card
                  title={t('T_MOBILE_CONFIGURATION_LANGUAGE_UPLOAD_HEADER')}
                  icon={<PaperClipIcon className="w-[20px]" />}
                  alt={t('T_USER_CARD_ALT_MESSAGE')}
                  height=""
                  width=""
                >
                  <div className="relative w-full">
                    <UploadContracts
                      key={'T_UPLOAD_NEW_FILES'}
                      files={files}
                      fileError={fileError}
                      loader={loader}
                      onDrop={onDrop}
                      removeFileFromUploadList={removeFileFromUploadList}
                      updateFileInfo={updateFileInfo}
                      acceptAllFiles={false}
                      disableOptions
                      maxFiles={1}
                      customFormat={{ 'application/json': ['.json'] }}
                      dropzoneClass="!w-full !pt-2"
                      cardClass="!w-full"
                    />
                  </div>
                </Card>
              </CardSection>
            </div>
          </div>
        </div>
      </section>
    </LoaderContainer>
  );
};

export default AddNewLanguage;

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;
