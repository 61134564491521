import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TemplateTable from '../shared/tables/TemplateTable';
import { ITableHeader } from '../shared/tables/models/ITableHeader';
import { deleteUser, getUsers, getInvitedUsers } from '../../http/user-management-services';
import { getOrganization } from '../../http/organization-services';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderContainer from '../shared/loaderContainer/LoaderContainer';
import { setAdminData, setOrganizationId } from '../../app/adminReducer';
import { setURLPaths } from '../../app/organizationReducer';
import { HTTP_STATUS } from '../../http/constants/http.status';
import { setToastData } from '../../app/toastReducer';
import { noDataAvailableMessage } from '../Organizations/helpers';
import { AppConstants } from '../../constants/AppConstants';
import { getUserName, userFilterQuery } from '../../util/admin-utils';
import AdminMenu from './AdminMenu';
import { useQuery } from '../../hooks/queryParams';
import { RouteConstants } from '../../constants/RouteConstants';
import { getFailureMessage } from '../../util/ErrorUtil';
import { useTheme } from '../../context/themeContext';
import { useTranslation } from 'react-i18next';
import { getSortTableArray } from '../../util/table-utils';
import moment from 'moment';

/**
 * @parentId user-list
 * @manager Invited User(s) Listing
 * @overview
 *   <section>
 *           <p>
 *               Invited Users listing page is navigated on clicking <b>Invited Users</b> tab on active users listing page. Listing page will show all the invited user(s) for the logged in org in a paginated way.
 *               The Default pagination size be fixed to 20 items, which can be changed to 50 or 100 from the dropdown menu of datagrid footer. Datagrid footer will also display the current page and total number of pages available based on the choosen page size & user data.
 *           </p>
 *           </br>
 *           <p>
 *               Invited User(s) can be filtered based on the user name using the search field provided above the user listing data grid.
 *           </p>
 *           </br>
 *           <p>
 *               Datagrid will display details of each user as row item with each column representing user property.
 *               <br>
 *               <ul>
 *                   <li>First Name</li>
 *                   <li>Last Name</li>
 *                   <li>Status</li>
 *                   <li>Role</li>
 *                   <li>Email Address</li>
 *                   <li>Organization</li>
 *                   <li>Options</li>
 *               </ul>
 *           </p>
 *           <p>
 *               Sorting of user grid is supported on <i>First Name, Last Name, Status, Email Address</i> columns. The breadcrumb bar on the application toolbar will reflect location of the user in the admin portal. Clicking on user rowitem will drill down user to user details page which will be displayed in readonly mode.
 *           </p>
 *         </section>
 *         <section>
 *          <h4>Failure Status Codes</h3>
 *           <p>
 *           This section describes the List User(s) Status Code information.
 *           <table>
 *               <tr>
 *                   <th>HTTP Status Code</th>
 *                   <th>Service Error Code</th>
 *                   <th>Error Message</th>
 *               </tr>
 *               <tr>
 *                   <td>403</td>
 *                   <td>UMS_PERMISSION_DENIED</td>
 *                   <td>You do not have permission to view this page</td>
 *               </tr>
 *               <tr>
 *                   <td>500</td>
 *                   <td>UMS_INTERNAL_ERROR</td>
 *                   <td>Internal Server Error</td>
 *               </tr>
 *               <tr>
 *                   <td>503</td>
 *                   <td></td>
 *                   <td>Service Unavailable</td>
 *               </tr>
 *           </table>
 *       </p>
 *       </section
 *       <section>
 *          <h4>Dependent System settings, Platform services & Role Permission</h3>
 *          <p>This section describes the list of dependent system settings & platform services required for functioning of User(s) listing page.</p>
 *           <h5>System Settings</h4>
 *           <p>Table lists all the dependent system setting(s) defined in configuration service with either global/organization scope</p>
 *           <table>
 *               <tr>
 *                   <th>Key</th>
 *                   <th>Type</th>
 *                   <th>Value</th>
 *                   <th>Scope</th>
 *               </tr>
 *               <tr>
 *                   <td>None</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *                   <td>None</td>
 *               </tr>
 *           </table>
 *           <br>
 *          <h5>Platform Service(s)</h4>
 *          <p>Table lists all the dependent platform service(s) with specific version(s) for User(s) listing</p>
 *           <table>
 *               <tr>
 *                   <th>Service Name</th>
 *                   <th>Version</th>
 *               </tr>
 *               <tr>
 *                   <td>User Management Service</td>
 *                   <td>1.4.0</td>
 *               </tr>
 *               <tr>
 *                   <td>Organization Service</td>
 *                   <td>1.3.1</td>
 *               </tr>
 *           </table>
 *           <br>
 *          <h5>API Role Permission(s)</h4>
 *          <p>Table lists the required API role permissions for listing User(s) page</p>
 *           <table>
 *               <tr>
 *                   <th>API URL</th>
 *                   <th>API Method</th>
 *                   <th>API Permission</th>
 *                   <th>Required</th>
 *               </tr>
 *               <tr>
 *                   <td>/invitations</td>
 *                   <td>GET</td>
 *                   <td>user-management-service.invitation.list</td>
 *                   <td>Yes</td>
 *               </tr>
 *               <tr>
 *                   <td>/organizations</td>
 *                   <td>GET</td>
 *                   <td>organization-service.organization.get</td>
 *                   <td>Yes</td>
 *               </tr>
 *           </table>
 *       </section>
 *       <section>
 *        <p>Sequence Diagram for User(s) listing page</p>
 *       </section>
 */

const CustomersTableContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const AdminInvitedList = () => {
  const query = useQuery();
  const params = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(AppConstants.DEFAULT_PAGE);
  const [size, setSize] = useState(AppConstants.DEFAULT_PAGE_SIZE);
  const [pagingOpts, setPagingOpts] = useState<any>(null);

  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [loadedOrgIds, setLoadedOrgIds] = useState<Array<string>>([]);
  const [organizations, setOrganizations] = useState<Map<string, any>>(new Map());
  const [organization, setOrganization] = useState<any>();
  const { t } = useTranslation();

  const userProfile = useAppSelector((state) => state.userManagement.userProfileInfo);
  // const organizationTypes: any = useAppSelector((state) => state.configuration.organizationTypes);
  const orgCreationPolicy: any = useAppSelector((state) => state.configuration.orgCreationPolicy);
  // const orgInvitationPolicy: any = useAppSelector(
  //   (state) => state.configuration.orgInvitationPolicy
  // );
  const umsInvitationPolicy: any = useAppSelector(
    (state) => state.configuration.userInvitationPolicy
  );
  const globalFilters: any = useAppSelector((state) => state.templateTableReducer.tableOptions);


  // const userProfile = JSON.parse(localStorage.getItem('user-profile') || '{}');

  useEffect(() => {
    const {
      searchText = '',
      currentPage = AppConstants.DEFAULT_PAGE,
      size: currentSize = AppConstants.DEFAULT_PAGE_SIZE,
    } = globalFilters['invited-list'] || {};

    const init = async () => {
      const id = getOrgId();
      const response = await getOrganization(id);
      dispatch(setOrganizationId(id));
      const { status, data } = response;
      if (status == HTTP_STATUS.HTTP_OK) {
        setOrganization(data);
        const { name } = data;
        dispatch(
          setURLPaths([
            {
              key: params?.id
                ? `${RouteConstants.ROUTE_ADMIN_LISTING}/${params?.id}`
                : `${RouteConstants.ROUTE_ADMIN_LISTING}`,
              label: `Users > ${name}`
            }
          ])
        );
      }
      invokeAPI(searchText, currentPage, currentSize);
    };
    init();
    return cleanup();
  }, []);

  const cleanup = () => {
    setLoadedOrgIds([]);
    setOrganizations(new Map());
    setRows([]);
  };

  const updateAdminListKeys = (adminList: any) => {
    return adminList.map((obj: any) => {
      return { ...obj, accountStatus: obj.status, mail: obj.email, roles: obj.roleName };
    });
  };

  const invokeAPI = async (
    name: string,
    page: number,
    size: number = AppConstants.DEFAULT_PAGE
  ) => {
    let id: any = getOrgId();
    setLoadedOrgIds([...loadedOrgIds, id]);
    const roles: Array<string> = [];

    let organizationIds: Array<string> = new Array(id);
    const sorters: string[] = getSortTableArray(tableHeaders);

    //const response: any = await getUsers(filters, page, size, sorters.join());
    const response: any = await getInvitedUsers(page, size, sorters.join(), id, name);
    const { status, data: usersResponse } = response;

    if (status == HTTP_STATUS.HTTP_OK) {
      const { data: adminList = [], paging } = usersResponse;
      const updatedAdminList = updateAdminListKeys(adminList);
      if (updatedAdminList && !updatedAdminList.length) {
        noDataAvailableMessage(setRows, setSize, setPagingOpts, paging, t);
      } else {
        await getAdminOrgs(updatedAdminList);
        setRows(updatedAdminList);
        setPagingOpts(paging);
      }
    } else {
      dispatch(
        setToastData({
          toastType: 'error',
          isToastActive: true,
          toastMessage: response?.data?.issues.map((issue: any) => issue?.details).join() || ''
        })
      );
    }
    setIsLoading(false);
  };

  const getOrgId = (): string => {
    let id: any = params.id;
    if (!id) {
      const { organization = [] } = userProfile;
      const [loginOrg] = organization.filter((org: any) => org.isOwner && org.loggedIn);
      const { organizationId } = loginOrg;
      id = organizationId;
    }
    return id;
  };

  const getAdminOrgs = async (users: Array<any> = []) => {
    const loadableOrgIds: Set<string> = new Set();
    users.forEach((user: any) => {
      const [ownerOrg] = user?.organization?.filter((org: any) => org.isOwner);
      if (!loadedOrgIds.includes(ownerOrg?.organizationId)) {
        loadableOrgIds.add(ownerOrg?.organizationId);
      }
    });
    await getOrganizations(Array.from(loadableOrgIds.values()));
  };

  const getOrganizations = async (orgIds: Array<string> = []) => {
    const responses: Array<any> = await Promise.all(
      orgIds.map((orgId: string) => {
        // Checking if org id is same then we don't need to fetch Org for same id
        // if (orgId !== getOrgId()) {
        return getOrganization(orgId);
        // }
      })
    );
    responses.forEach((response: any) => {
      if (response) {
        const { status, data } = response;
        if (status == HTTP_STATUS.HTTP_OK) {
          organizations.set(data?.id, data);
          loadedOrgIds.push(data?.id);
        }
      }
    });
    setLoadedOrgIds(loadedOrgIds);
    setOrganizations(organizations);
    setIsLoading(false);
  };

  const getOrganizationName = (userOrgs: Array<any> = []) => {
    let name: string = '';
    const [ownerOrg] = userOrgs.filter((org: any) => org.isOwner);
    if (ownerOrg) {
      const { organizationId } = ownerOrg;
      const orgnization: any = organizations.get(organizationId);
      name = orgnization?.name;
    }
    return name;
  };

  const adminSelector = (data: any, step: number) => {
    data['orgnaization'] = getOrganizationName(data.organization);
    dispatch(setAdminData(data));
    navigate(`/admin/invited/${data.userId}`);
  };

  const refreshTableData = (name: string, page: number, size: number) => {
    setIsLoading(true);
    setTableValues(name, page, size);
  };

  const setTableValues = (name: string, page: number, size: number) => {
    setSize(size);
    setPage(page);
    invokeAPI(name, page, size);
  };

  const ADMIN_HEADERS: Array<ITableHeader> = [
    {
      key: 'firstName',
      description: 'T_FIRST_NAME',
      sortEnabled: true,
      width: '15%',
      clickable: true,
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'lastName',
      description: 'T_LAST_NAME',
      sortEnabled: true,
      width: '15%',
      clickable: true,
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'status',
      description: 'T_STATUS',
      sortEnabled: true,
      width: '10%',
      clickable: true,
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'role',
      description: 'T_ROLE',
      sortEnabled: false,
      width: '20%',
      nastedData: true,
      clickable: true,
      render: (data: any) => {
        const { roleName = [] } = data || {};
        return (roleName.length && roleName[0]?.name) || '';
      },
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'email',
      description: 'T_EMAIL_ADDRESS',
      sortEnabled: true,
      width: '30%',
      clickable: true,
      sortingInformation: {
        order: ''
      }
    },
    {
      key: 'organization',
      description: 'T_ORGANIZATION',
      sortEnabled: false,
      width: '17%',
      nastedData: true,
      clickable: true,
      render: (data: any) => {
        const { organization = [] } = data || {};
        return getOrganizationName(organization);
      },
      sortingInformation: {
        order: ''
      }
    },
    // TODO: Need clarity on sorting and showing user name
    // {
    //   key: 'updatedBy',
    //   description: 'T_LAST_MODIFIED_BY',
    //   sortEnabled: false,
    //   clickable: true,
    //   width: '15%',
    //   sortingInformation: {
    //     order: ''
    //   },
    //   render: (data: any) => {
    //     return getUserName(data.updatedBy)
    //   },
    // },
    // {
    //   key: 'options',
    //   description: '',
    //   sortEnabled: false,
    //   width: '4%',
    //   sortingInformation: {
    //     order: ''
    //   },
    //   clickable: false,
    //   nastedData: true,
    //   render: (data: any) => {
    //     const { original, userId } = data;
    //     return (
    //       <div className="hover:bg-[#d9d9d9]" style={{ borderRadius: '10px' }}>
    //         <AdminMenu
    //           className="px-0 py-0"
    //           //iconSize="h-[28px] py-[4px] min-w-[20px]"
    //           userId={userId}
    //         />
    //       </div>
    //     );
    //   },
    //   styles: {
    //     paddingRight: '0',
    //     paddingLeft: '0',
    //     borderLeft: 'none',
    //     rowStyle: { fontWeight: 'normal', padding: '0px' }
    //   }
    // }
  ];

  if (query.get('devmode')) {
    ADMIN_HEADERS.push({
      key: 'buttons',
      description: 'T_ACTIONS',
      sortEnabled: false,
      width: '13%',
      sortingInformation: {
        order: ''
      }
    });
  }

  const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(ADMIN_HEADERS);

  const removeAdmin = async (index: number) => {
    const row: any = rows[index];
    if (row) {
      let error = false;
      const { userId, firstName, lastName } = row;
      let toastMessage = `${firstName} ${lastName} ${t('T_DELETED_SUCCESS_MESSAGE')}`;
      const response = await deleteUser(userId);
      const { status } = response;
      if (status == HTTP_STATUS.HTTP_OK) {
        rows.splice(index, 1);
        setRows([...rows]);
      } else {
        error = !error;
        toastMessage = getFailureMessage(response);
      }
      dispatch(
        setToastData({
          toastMessage,
          isToastActive: true,
          toastType: error ? 'error' : 'success'
        })
      );
    }
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading}>
        <CustomersTableContainer style={{ height: 'inherit' }}>
          <TemplateTable
            tableData={rows}
            size={size.toString()}
            currentPage={page.toString()}
            templateSelector={adminSelector}
            cellSelector={adminSelector}
            onRefreshTableData={refreshTableData}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            searchPlaceholder={t('T_SEARCH_USER_NAME') || ''}
            pagingData={pagingOpts}
            containerClass="mt-0"
            showDeleteButton={true}
            deleteOnClick={removeAdmin}
            theme={useTheme().theme}
            globalFilters={globalFilters}
            tablePage="invited-list"
          />
        </CustomersTableContainer>
      </LoaderContainer>
    </>
  );
};

export default AdminInvitedList;
