import TabDrawer from '../Reusable/TabDrawer';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/queryParams';
import { useTranslation } from 'react-i18next';
import { RouteConstants } from '../../constants/RouteConstants';
import ExportList from './Listing/ExportListing';
import ImportList from './Listing/ImportListing';
import { apiPermissionCheck } from '../../util/RoleUtil';
import { AppConstants } from '../../constants/AppConstants';

interface MigrationContainerProps {
  defaultTab?: string;
}

const MigrationContainer = ({ defaultTab }: MigrationContainerProps) => {
  const query = useQuery();
  const params = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState(defaultTab || AppConstants.MIGRATION_JOB_EXPORT);
  const roleAPIPermisions: any =
    useAppSelector((state) => state.initialLoadData?.apiPermissions) || {};

  const tabPaths: any = {
    EXPORT: RouteConstants.ROUTE_MIGRATION_EXPORT_LIST,
    IMPORT: RouteConstants.ROUTE_MIGRATION_IMPORT_LIST
  };

  const tabConfig = [];

  if (apiPermissionCheck(roleAPIPermisions, [AppConstants.PERMISSION_MIGRATION_EXPORT_LIST])) {
    tabConfig.push({
      label: AppConstants.MIGRATION_JOB_EXPORT,
      component: <ExportList />
    });
  }

  if (apiPermissionCheck(roleAPIPermisions, [AppConstants.PERMISSION_MIGRATION_IMPORT_LIST])) {
    tabConfig.push({
      label: AppConstants.MIGRATION_JOB_IMPORT,
      component: <ImportList />
    });
  }

  const ChangeTab = (tab: string) => {
    navigate(tabPaths[tab]);
    setActiveTab(tab);
  };

  return (
    <div style={{ width: '100%' }}>
      <TabDrawer
        borderBottom={false}
        tabConfig={tabConfig}
        activeTab={activeTab}
        setActiveTab={ChangeTab}
        style={{
          width: '100%',
          background: '#fff',
          height: '48px',
          borderBottom: '0.5px solid',
          paddingTop: '2px'
        }}
        childStyle={{ height: 'calc(100vh - 12.5rem)' }}
      />
    </div>
  );
};

export default MigrationContainer;
