const translationLocalEN = {
  T_HIDE: 'hide',
  T_VIEW: 'view',
  T_CANCEL: 'Cancel',
  T_RESET: 'Reset',
  T_EDIT: 'Edit',
  T_SAVE: 'Save',
  T_EXPORT: 'Export',
  T_EMAIL: 'Email',
  T_SMS: 'SMS',
  T_PUSH: 'Push',
  T_BACK: 'Back',
  T_EXPIRES_IN: 'Expires in:',
  T_PASSWORD_TO_REMEMBER: 'Passwords to remember:',
  T_PASSWORD_POLICY_NAME: 'Password Policy Name',
  T_PASSWORD_POLICY_CANNOT_BE_EMPTY: 'Password policy name cannot be empty',
  T_PASSWORD_POLICY_ALREADY_EXISTS: 'That password policy name is taken. Try another.',
  T_PASSWORD_POLICY_CREATE_SUCCESS: 'Password policy created successfully.',
  T_PASSWORD_POLICY_UPDATE_SUCCESS: 'Password policy %s updated successfully.',
  T_PASSWORD_POLICIES: 'Password Polices',
  T_BACK_TO_PASSWORD_POLICIES: 'Back to Password Polices',
  T_PASSWORD_POLICY_BY_NAME: 'Search password policy by name',
  T_NEW_ROLE: 'New Role',

  T_TOAST_MSG_PART1: 'No default',
  T_TOAST_MSG_PART2: 'defined. Please contact your IT administrator.',
  T_TOAST_ERR_MSG_PART1: 'Failed to fetch',
  T_TOAST_ERR_MSG_PART2: '. Please contact your IT administrator.',
  T_ORGANIZATION: 'Organization',
  T_PASSWORD: 'Password',
  T_FORGOT_PASSWORD: 'Forgot Password?',
  T_CAPS_LOCK_ON: 'Caps Lock is on',
  T_REMEMBER_EMAIL: 'Remember email',
  T_SIGN_IN: 'Sign in',
  T_FIRST_NAME: 'First Name',
  T_LAST_NAME: 'Last Name',

  T_ROLE: 'Role',
  T_DESCRIPTION: 'Description',
  T_ORGANIZATION_NAME: 'Organization Name',
  T_EMAIL_ADDRESS: 'Email Address',
  T_NEVER_REMEMBER: 'Never remember',
  T_ACTIONS: 'Actions',
  T_MY_ACCOUNT: 'My Account',
  T_SIGN_OUT: 'SIGN OUT',
  T_NEW_PASSWORD: 'New Password',
  T_CONFIRM_PASSWORD: 'Confirm Password',
  T_SET_NEW_PASSWORD: 'Set new password',
  T_CURRENT_PASSWORD: 'Current Password',

  T_NEVER_TIME_OUT: 'Never times out',

  T_BACK_TO_NOTIFICATION_BTN: 'Back to Notification list',
  T_BACK_TO_ORGANIZATION_LIST_BTN: 'Back to organization list',

  T_ERROR_NOT_FOUND: '404 - Not found',
  T_ERROR_SOMETHING_WRONG: 'something went wrong.',

  T_SEARCH_PLACEHOLDER_HINT: 'Search by template name',
  T_SEARCH_ROLE_NAME_PLACEHOLDER: 'Search by role name',
  T_SEARCH_USER_NAME: 'Search by user firstname',
  T_SEARCH_ORG_NAME: 'Search by organization name',
  T_SEARCH_BY_PERMISSION_SET: 'Search permission set',
  T_SEARCH_NAME: 'Search with name',
  T_SEARCH_ERROR: 'Search string can not be more than 256 characters',
  T_SEARCH_NAME_FIELDGROUP: 'Search by fieldgroup name',

  T_DELETED_SUCCESS_MESSAGE: 'deleted successfully.',
  T_TEMPLATE_SAVED_SUCCESS_MSG: 'Template saved successfully',
  T_TEMPLATE_CREATED_SUCCESS_MSG: 'Template created successfully',
  T_DEFAULT_ALL_NOTIFICATION_TEMPLATE: 'Default view: All Channel types',
  T_ROLE_CREATED_SUCCESS_MSG: 'Role created successfully.',
  T_ROLE_FIELD_MAX_LIMIT_ERROR: 'Characters in Role name and description should be less than 1024',
  T_ROLE_UPDATE_SUCCESS_MSG: 'Role updated successfully.',
  T_ORG_CREATED_SUCCESS_MSG: 'Organization Created Successfully',
  T_CHANGE_PASSWORD_SUCCESS_MSG: 'Password Changed Successfully',
  T_UPDATE_SUCCESS_MSG: 'updated Successfully.',
  T_LOGOUT_MSG: 'user logged out',

  T_FAILED_CREATE_SESSION_MSG: 'Failed to create session context.',
  T_FAILED_CREATE_MSG: 'creation failed.',

  T_ERROR_MSG_FOR_SHOW_PERMISSION_SET:
    'Unable to fetch application feature data. Please contact your administrator.',

  T_VALIDATION_ERR_EMAIL_MSG: 'Please enter a valid email address',
  T_VALIDATION_ERR_EMPTY_MSG: 'Field should not be empty',
  T_VALIDATION_ERR_POST_CODE_MSG: 'Postal Code should be of 5 digits only.',
  T_VALIDATION_ERR_FOR_PASSWORD_INVITE_CODE: 'Must enter an invite code.',
  T_VALIDATION_ERR_PASSWORD_POLICY_MSG1: 'Must contain at least',
  T_VALIDATION_ERR_PASSWORD_POLICY_MSG2: 'characters',
  T_VALIDATION_ERR_PASSWORD_POLICY_MSG3: 'Must contain atleast 1 character from',
  T_VALIDATION_ERR_PASSWORD_POLICY_MSG4: 'Must contain special character',
  T_VALIDATION_ERR_PASSWORD_POLICY_MSG5: 'Passwords do not match.',

  T_CARD_ALT_MESSAGE: 'organization icon image',
  T_DESC_FOR_ORG_CREATE_BUCKET: 'Automated bucket created by Admin Portal for organization:',
  T_DESC_FOR_PERMISSION_HEADER: 'Permission Set Name',
  T_DESC_FOR_APP_ROLE_ASSIGNED: 'Application Role Assigned',

  T_LBL_FOR_PERMISSION_SET_DETAILS: 'Permission set details',
  T_LBL_APPLICATION_FEATURES: 'Application Features',
  T_LBL_FOR_USER_PORTAL_FEATURES: 'Admin Portal Features',
  T_LBL_FOR_OTHERS_SETTINGS: 'Other Settings',
  T_APP_SETTINGS: 'App Settings',
  T_LBL_FOR_SETTINGS_JSON: 'Settings JSON',

  T_WARN_MSG_PART1: 'No category:',
  T_WARN_MSG_PART2: 'added to organization:',

  T_REQUIRED_CONSTRAINT_ERROR: 'Can not increase the scope from not required to required',
  T_LOGIN_ERROR_MESSAGE: 'Not able to sign in, Please try again',
  T_LOGIN_TOAST_MESSAGE: 'Account successfully created. Please sign in to begin using the portal',
  T_LOGIN_TOAST_FAILURE: 'Login failure',
  T_LOGIN_TOAST_PASSWORD_EXPIRED_FAILURE: 'Your password has expired and must be changed',
  T_TOAST_NOTIFICATION_OTP_MSG: 'You will receive One Time Password on valid registered email.',
  T_TOAST_NOTIFICATION_FOR_NEW_CODE_SEND: 'A new code has been sent to your email id.',
  T_TOAST_NOTIFICATION_FOR_CREATED_ACCOUNT_SUCCESS_MSG:
    'Account successfully created. Please sign in to begin using the portal.',
  T_TOAST_NOTIFICATION_ERR_MSG: 'Password is already created for this email',
  T_TOAST_ORGANIZATION_CREATE_SUCCESS: 'Organization created successfully',

  T_LOGIN_HEADING: 'Sign in to your account',
  T_LOGIN_SUB_HEADING: 'Please enter your credentials to proceed',
  T_LOGIN_SPLASH_MESSAGE1: 'Administration Portal',
  T_LOGIN_SPLASH_MESSAGE2: 'Forgot your password?',
  T_LOGIN_SPLASH_MESSAGE3: 'Code + New Password',
  T_LOGIN_ALERT_MESSAGE:
    'Your account has been locked due to too many failed attempts. Please contact your system administrator.',
  T_LOGIN_IMAGE_BARS_ALT_MESSAGE: 'bars',
  T_LOGIN_IMAGE_ADMIN_PORTAL_ALT_MESSAGE: 'AdminPortal',

  T_ROLE_DETAILS: 'Role Details',
  T_ROLE_DETAIL_CARD_TITLE: 'Application Role Details',
  T_ROLE_DETAILS_BACK_TO_ROLE_BTN: 'Back to Roles list',
  T_ROLE_DETAIL_SECURITY_CARD_TITLE: 'Application Role Security',
  T_ROLE_DETAIL_SECURITY_OPTIONS_TITLE: 'Account Security Options',
  T_ROLE_API_PERMISSIONS: 'API Permissions',
  T_ROLE_API_PERMISSIONS_AVAILABLE: 'Available API Permissions',
  T_ROLE_API_PERMISSIONS_SELECTED: 'Selected API Permissions',

  T_USER_DETAIL_TITLE: 'User Details',
  T_USER_CARD_ALT_MESSAGE: 'admin icon image',
  T_ORG_DETAIL_TITLE: 'Organization Details',
  T_STEPS_INSTRUCTIONS_FOR_CREATE_ORG_MSG:
    'Complete the following steps to activate this organization:',

  T_TRUE: 'True',
  T_FALSE: 'False',

  T_DUALING_LIST_DEFAULT_SEARCH_PLACEHOLDER: 'Search permission',

  T_PHONE: 'Phone',
  T_NEW_ORGANIZATION: 'New Organization',
  T_CREATE_CHILD_ORGANIZATION: 'Create Child Organization',
  T_NAME: 'Name',
  T_LABEL: 'Label',
  T_FAX: 'Fax',
  T_ADDRESS: 'Address',
  T_CITY: 'City',
  T_STATE: 'State',
  T_ZIP: 'Zip',
  T_COUNTRY: 'Country',
  T_TYPE: 'Type',
  T_APPLICATION_ROLE_NAME: 'Application Role Name',
  T_MULTI_FACTOR_AUTHENTICATION: 'Multi-Factor Authentication',
  T_LOCATION_HEURISTICS: 'Enable Location Heuristics',
  T_PERMISSION_SET: 'Permission Set',
  T_SELECT: 'Select',
  T_ROLE_DESCRIPTION: 'Role Description',
  T_DESC_PERMISSION_SET: 'Name of permission set',
  T_DESC_PERMISSION_DESCRIPTION: 'Describe what this permission set allows this user to do',
  T_VALIDATION_ERR_PASSWORD_MSG1: '8 or more characters',
  T_VALIDATION_ERR_PASSWORD_MSG2: 'Upper & lowercase letters',
  T_VALIDATION_ERR_PASSWORD_MSG3: 'At least one number',
  T_VALIDATION_ERR_PASSWORD_MSG4: 'At least one special character (e.g. $#!”)',

  T_MENU_ORGANIZATION: 'Organization MGM',
  T_MENU_CONFIGURATION: 'Configuration Service',
  T_MENU_USER_MANAGEMENT: 'User Role and Password',
  T_MENU_APPLICATION_CONTEXT: 'Application Context',
  T_MENU_APP_FEATURE: 'App Feature',
  T_MENU_COMMUNICATION_TEMPLATE: 'communication Template',
  T_MENU_PATIENT_DATA: 'Patient Data',
  T_MENU_RULE_CONFIGURATION: 'Rule Configuration',
  T_MENU_SURVEY_CREATION: 'survey creation',
  T_ROCHE_ADMIN_TITLE_CUSTOMERS: 'customers',
  T_CUSTOMER_ADMIN_TITLE_FOR_CLINICS: 'clinics',
  T_CUSTOMER_ADMIN_TITLE_FOR_ADMIN: 'admins',
  T_CUSTOMER_STEPPER_DESCRIPTION: 'Enter Email',
  T_CUSTOMER_STEPPER_CODE_PLUS_PASSWORD: 'Code + Password',
  T_CUSTOMER_STEPPER_EXPLORE: 'Explore',
  T_ACTIVE: 'Active',
  T_NOT_ACTIVE: 'Not Active',
  T_BUCKET_MSG: 'This bucket is ',

  T_COOKIES_TITLE: 'Cookies',
  T_COOKIES_ALT_MSG: 'Password Lock Icon',
  T_COOKIES_TEXT_MSG: 'Allow site to save and read cookie data (recommended)',
  T_LANGUAGE_ADD_BUTTON: 'Add New Language',
  T_LANGUAGE: 'Language',
  T_LANGUAGE_DETAILS: 'Language Details',
  T_LANGUAGE_NAME: 'Language Name',
  T_LANGUAGE_PICKER_TITLE: 'Picker Title',
  T_LANGUAGE_PICKER_BUTTON_TITLE: 'Picker Button Title',
  T_LOCALE: 'Locale',
  T_LOCALE_EXISTING_ERROR: 'This Locale already exists',
  T_DELETE_LANGUAGE_CONTENT: 'Do you want to delete this language?',

  T_PASSWORD_LOCK_ICON: 'Password Lock Icon',
  T_LANGUAGE_TEXT: 'Set your language for this portal',
  T_CHANGE_LANGUAGE: 'Change Language?',
  T_TIMES_ICON_ALT_MSG: 'close',
  T_CHANGE_LANGUAGE_MSG: 'Your clinician portal language will convert from English to Español.',
  T_OK: 'OK',
  T_PROFILE_INFORMATION: 'Profile Information',
  T_ADMIN_ICON_ALT_MSG: 'admin icon image',
  T_PHONE_NUMBER: 'Phone Number',
  T_SAVE_CHANGES: 'Save Changes',
  T_PROFILE_INFORMATION_CARD_TITLE: 'Organization Details',
  T_PROFILE_UPLOAD_IMAGE: 'profile upload image',
  T_UPLOAD_PHOTO: 'Upload Photo',
  T_RULE_MAX_PHOTO_SIZE: 'Max upload size: 3MB',
  T_RULE_FILE_TYPE_SUPPORT: 'Files supported: JPG, PNG, SVG',
  T_RULE_PHOTO_RESOLUTION: 'File resolution must be 250 x 70 pixels or larger',
  T_TOAST_NOTIFICATION_ALT_MSG: 'Check Image',
  T_TOAST_NOTIFICATION_PASSWORD_SUCCESS: 'Password successfully updated.',
  T_CLOSE_ICON_ALT_MSG: 'Close Button',
  T_USER: 'User',
  T_TOAST_DEACTIVATED_MSG: 'is deactivated successfully.',
  T_TOAST_RE_INVITED_MSG: 'is reinvited successfully.',
  T_TOAST_RE_CANCEl_INVITE_MSG: 'invitation cancelled successfully.',
  T_DEACTIVATE_TITLE:
    'This member will no longer have access and will be permanently removed from the system.',
  T_CANCEL_INVITATION_TITLE: 'This member invitation will be cancelled.',
  T_BUTTON_YES_DEACTIVATE: 'Yes, Deactivate',
  T_BUTTON_YES_CANCEL_INVITATION: 'Yes, Cancel Invitation',
  T_BUTTON_YES_ACTIVATE: 'Yes, Activate',
  T_RE_INVITE_USER: 'Reinviting user',
  T_BUTTON_YES_RE_INVITE: 'Yes, Reinvite',
  T_DEACTIVATE_ACCOUNT: 'Deactivate Account',
  T_CANCEL_INVITATION: 'Cancel Invitation',
  T_DEACTIVATE_ACCOUNT_MSG: 'This member will no longer have access to the portal.',
  T_BUTTON_RE_INVITE_USER: 'Reinvite User',
  T_BUTTON_CANCEL_USER_INVITATION: 'Cancel User Invitation',
  T_DEACTIVATE_USER: 'Deactivate User',
  T_IS_UPDATE_SUCCESS_MSG: 'is updated successfully.',
  T_STATUS: 'Status',
  T_TOAST_MESSAGE_APP_SETTINGS_SUCCESS: 'Application settings updated successfully.',
  T_FORMAT_JSON: 'Format JSON',
  T_IMPORT_JSON: ' Import JSON',
  T_PASSWORD_MATCH_MSG: 'Passwords should Match',
  T_SESSION_TIMEOUT: 'Session timeout',
  T_SESSION_TIMEOUT_MSG1: 'Your session will timeout due to inactivity in',
  T_SESSION_TIMEOUT_MSG2: 'minutes and',
  T_SESSION_TIMEOUT_MSG3: 'seconds.',
  T_BUTTON_SESSION_PRIMARY_TITLE: 'Stay signed in',
  T_VALIDATION_AUTH_NEEDED_MSG: 'Incorrect email address or password.',
  T_VALIDATION_SETTINGS_LENGTH_MSG: 'No application configuration defined for',
  T_APP_SETTINGS_MISSING_MSG: 'Missing application settings',
  T_VERIFICATION_ALERT_TOAST_MSG1: 'A new code has been sent. Code expires in 24 hours.',
  T_VERIFICATION_ALERT_TOAST_MSG2: 'Request limit exceeded. Contact your admin for support.',
  T_VALIDATION_ERR_EXPIRED_CODE_MSG: 'Invalid or expired code. Try again.',
  T_STATUS_LOADING_IN_MSG: 'Logging In...',
  T_BUTTON_SIGN_IN_SECURELY: 'Sign in securely',
  T_BUTTON_REQ_FOR_NEW_CODE: 'Request a new code',
  T_BUTTON_RETURN_TO_SIGN_IN_PAGE: 'Return to Sign in page',
  T_PROFILE: 'Profile',
  T_ROLE_MANAGEMENT: 'Role Management',
  T_NOTIFICATION_TEMPLATES: 'Notification Templates',
  T_USERS: 'Users',
  T_DELETED_CONTRACT_SUCCESS_MSG: 'Contract deleted successfully',
  T_CONTRACT: 'Contract',
  T_VERSION: 'Version',
  T_CATEGORY: 'Category',
  T_UPLOAD_BY: 'Uploaded By',
  T_DATE_AND_TIME: 'Date/Time',
  T_ADMIN_PORTAL: 'AdminPortal',
  T_VALIDATION_DUPLICATE_BUCKET_MSG1: 'Filename Error: The name of the file',
  T_VALIDATION_DUPLICATE_BUCKET_MSG2: 'already exists',
  T_VALIDATION_DUPLICATE_BUCKET_MSG3: 'is invalid',
  T_AMS_PARAM_DUPLICATE_FILE_MSG: 'File with the same name already exists.',
  T_FILES_UPLOAD_PARTIAL_SUCCESS: '%s/%d files uploaded successfully.',
  T_FILES_UPLOAD_ALL_FAILURE: '%s/%d files failed to upload.',
  T_FILE_UPLOAD_SUCCESS: '%s file uploaded successfully.',
  T_ACCOUNT_PASSWORD_CHANGE_SUCCESSFULL: 'Account password has been changed successfully.',
  T_OVERRIDDEN_CONFIGURATION: 'This configuration has local value for current organization ',
  T_INHERITED_CONFIGURATION: 'This configuration is inheriting value from parent organization',
  T_VALIDATION_DUPLICATE_BUCKET_MSG4: 'Unknown Error for the file',
  T_NO_FILE_CHOSEN: 'No files chosen',
  T_HEALTH_SYSTEM: 'Health System',
  T_NO_DATA_AVAILABLE_MSG: 'No data available. Insert some records.',
  T_NO_SEARCH_DATA_MSG: 'Start typing for relevant results.',
  T_CONTRACTS: 'Contracts',
  T_UPLOAD_NEW_CONTRACT: 'Upload New Contracts',
  T_CONTRACT_UPLOAD_SUCCESS_MSG: 'Contracts uploaded successfully.',
  T_NOT_UPLOADED: 'not uploaded',
  T_MANAGE_CONTRACT: 'Manage Contracts',
  T_VALIDATION_ERR_EMAIL_EXITS_MSG: 'This email already exists',
  T_VALIDATION_ENTER_VALID_EMAIL_MSG: 'Please enter a valid email address',
  T_VALIDATION_ERR_NAME_MSG: 'Name should not contain special characters.',
  T_VALIDATION_SELECT_USER_ROLE_MSG: 'Please select a user role.',
  T_BUTTON_UPDATE: 'Update',
  T_BUTTON_ADD: 'Add',
  T_ADMIN_INVITED_SUCCESS_MSG: 'admin(s) invited successfully.',
  T_EDIT_ORGANIZATION_DETAILS: 'Edit Organization Details',
  T_INVITE_USERS: 'Invite Users',
  T_VIEW_USERS: 'View Users',
  T_VIEW_TEMPLATES: 'View Templates',
  T_PARENT: 'Parent',
  T_SHOW_ORG_CARD_ALT_MSG: 'contracts icon image',
  T_SEARCH_APP_FEATURES: 'Search application feature',
  T_TABLE_APP_PERMISSION_NAME: 'Application Permission',
  T_BUTTON_BACK_TO_PERMISSION_SET: 'Back to Permission set details',
  T_NEXT: 'Next',
  T_TOAST_CREATED_SUCCESS_MSG: 'Created Successfully.',
  T_BUTTON_NEW_PERMISSION_SET: 'New permission set',
  T_BUTTON_LABEL_FOR_BACK_TO_PORTAL: 'Back to Admin Portal features',
  T_FINISH: 'Finish',
  T_HEADER_TITLE_FOR_NEW_PERMISSION: 'New Permission',
  T_ADMIN_PORTAL_FEATURES: 'Admin Portal features',
  T_PERMISSION_SETS: 'Permission Sets',
  T_BUTTON_BACK_TO_PERMISSION_SET_LIST: 'Back to permission set list',
  T_MANAGE_ORGANIZATION: 'Manage organization',
  T_UPPERCASE: 'Upper case',
  T_LOWERCASE: 'Lower case',
  T_DIGITS: 'Digits',
  T_PASSWORD_POLICY: 'Password Policy',
  T_NEW_PASSWORD_POLICY: 'New Password Policy',
  T_SEARCH_PASSWORD_POLICY_BY_NAME: 'Search password policy by name',
  T_PASSPORT_POLICY_HINT_MSG1: 'Your Password must include:',
  T_PASSPORT_POLICY_HINT_MSG2: 'or more characters.',
  T_AT_LEAST: 'Atleast',
  T_ENTER_CODE: 'Enter code',
  T_REGISTER_ENTER_CODE_MSG:
    'Please enter the code found in your invitation email that was sent to',
  T_INVITE_CODE: 'Invite Code',
  T_CREATE_PASSWORD: 'Create Password',
  T_CHANGE_PASSWORD: 'Change Password',
  T_DONE: 'Done',
  T_UPDATE: 'Update',
  T_CREATE_YOUR_ACCOUNT: 'Create your account',
  T_REMEMBER_PASSWORD: 'Passwords to remember',
  T_DAYS: 'Days',
  T_DELETE_PASSWORD_POLICY: 'Delete Password Policy',
  T_PASSWORD_HISTORY: 'Password History',
  T_PASSWORD_EXPIRATION: 'Password Expiration',
  T_MINIMUM_NO_OF_CHARS: 'Minimum number of characters',
  T_MINIMUM_NO_OF_SPECIAL_CHARS: 'Minimum special character(s)',
  T_MINIMUM_NO_OF_NUMBERS: 'Minimum number(s)',
  T_MINIMUM_NO_OF_UPPERCASE: 'Minimum uppercase letter(s)',
  T_MINIMUM_NO_OF_LOWERCASE: 'Minimum lowercase letter(s)',

  T_PASSWORD_COMPLEXITY: 'Password Complexity',
  T_BREADCRUMB_LABEL_FOR_ROLE_MGMT_PAGE: 'Security > Roles',
  T_BREADCRUMB_LABEL_FOR_ROLE_MGMT_EDIT_PAGE: 'Security > Roles > View',
  T_BREADCRUMB_LABEL_FOR_ROLE_MGMT_CREATE_PAGE: 'Security > Roles > Add new role',
  T_ROLE_NAME: 'Role Name',
  T_MFA: 'MFA',
  T_MULTIFACTOR: 'Multifactor',
  T_ON: 'On',
  T_OFF: 'Off',
  T_PASSWORD_CHECK_MSG_FOR_SPECIAL_CHARS: 'Requires at least one special character (@#!*)',
  T_PASSWORD_CHECK_FOR_AT_LEAST_ONE_NUM: 'Requires at least one number',
  T_PASSWORD_CHECK_ONE_UPPER_CASE_MSG: 'Requires at least one uppercase letter',
  T_PASSWORD_CHECK_ONE_LOWER_CASE_MSG: 'Requires at least one lowercase letter',
  T_DROP_DRAG_FILE_MSG: 'Drag and drop files here or',
  T_SHOWING: 'Showing',
  T_USER_PERMISSION: 'User Permission',
  T_CREATE_TEMPLATE: 'Create Template',
  T_TEMPLATE_TYPE: 'Template type',
  T_TEMPLATE_TYPE_SUB_TEXT: 'What type of template do you want to create?',
  T_TEMPLATE_HEADER: 'Template Header',
  T_SELECT_ONE: 'Select One.',
  T_NO_HEADER: 'No header.',
  T_TEMPLATE_DETAILS: 'Template Details',
  T_TEMPLATE_NAME: 'Template Name',
  T_TEMPLATE_FROM_NAME: 'From Name',
  T_TEMPLATE_FROM_EMAIL: 'From Email',
  T_TEMPLATE_FROM_NAME_PLACEHOLDER: 'Name used in the mailer field',
  T_TEMPLATE_FROM_EMAIL_PLACEHOLDER: 'Email used in the mailer field',
  T_CREATE_NEW_TEMPLATE_CONTENT: 'Create New Template Content',
  T_TEMPLATE_CONTENT: 'Template Content',
  T_TEMPLATE_NOTIFICATION_CONTENT: 'Notification content',
  T_EDIT_TEMPLATE_CONTENT: 'Edit Template Content',
  T_SUBJECT: 'Subject',
  T_BODY: 'Body',
  T_LAST_MODIFIED: 'Last Modified',
  T_CREATE_NEW_TEMPLATE: 'Create New Template',
  T_BREADCRUMB_NOTIFICATION_TEMPLATE_LBL: 'Platform Core > Notification Templates > View',
  T_BREADCRUMB_NOTIFICATION_CREATE_TEMPLATE_LBL:
    'Platform Core > Notification Templates > Create New Template',
  T_SAVE_TEMPLATE: 'Save Template',
  T_CREATE_ORGANIZATION_TASKS_CONTRACTS_MSG: 'Creating repositories for uploading contracts',
  T_CREATE_ORGANIZATION_TASKS_TEMPLATES_MSG: 'Creating Templates for this organization',
  T_CREATE_TEMPLATE_DUPLICATE_NAME_ERROR: 'Error: Duplicate Notification Template',
  T_CREATE_ORGANIZATION_TASKS_CHECK_MSG: 'Validating Data',
  T_TEMPLATES: 'Templates',
  T_VALIDATION_CHECK: 'Validation Check',
  T_TASK_TRACKER_ALT_MSG: 'task completed',
  T_TASK_FAILURE_ALT_MSG: 'task failure',
  T_TASK_INCOMPLETE_ALT_MSG: 'task incompleted',
  T_CONSOLE_ERROR_MSG: 'Empty session switch context...Failed',
  T_SWITCH_SESSION_EMPTY_SESSION_MSG: 'Empty session switch context',
  T_SESSION_EMPTY_MSG: 'Logged in user session context is empty',
  T_NO_DEFAULT_TEMPLATES_AVAILABLE: 'No default templates present in parent organization',
  T_ERROR_BOUNDARY_MSG: 'Algo deu errado.',
  T_VALIDATION_INPUT_ERR_EMPTY_MSG: 'Field can not be left empty.',
  T_VALIDATION_INPUT_ERR_ONLY_NUMBER_MSG: 'Field should contain only numbers.',
  T_VALIDATION_ERR_CONTACT_NUMBER: 'Number should be of 10 digits only.',
  T_VALIDATION_ERR_TEXT_LENGTH_CUSTOM_MSG: 'Text too long. Max length "${LENGTH}" characters.',
  T_VALIDATION_ERR_TEXT_LENGTH_MSG: 'Text too long. Max length 255 characters.',
  T_VALIDATION_ERR_TEXT_TOO_LONG_MSG: 'Text too long. Max length 1024 characters.',
  T_ORGANIZATION_MANAGEMENT: 'Organization Management',
  T_EMAIL_TEMPLATE: 'Email Template',
  T_RETURN_TO_USER_LIST: 'Return to User list',
  T_USER_IS_MSG: 'User is',
  T_DROP_FILE_HERE: 'Drop file(s) here',
  T_UPLOAD_FROM_COMPUTER: 'upload from your computer',
  T_PREVIOUS: 'Previous',
  T_ALREADY_USED: 'this email is already in use',
  T_INVITE_USER: 'Invite User',
  T_TWO_STEP_VERIFICATION: 'Two-step verification',
  T_TWO_STEP_VERIFICATION_MSG:
    'A verification code has been sent to your email. Please enter the code below to continue.',
  T_ENGLISH: 'English (US)',
  T_FRENCH: 'French',
  T_SPANISH: 'Spanish',
  T_PHOTOGRAPHY: '#photography',
  T_TRAVEL: '#travel',
  T_WINTER: 'winter',
  T_COLDEST_SUNSET: 'The Coldest Sunset',
  T_ALBUM_ALT_MESSAGE: 'Sunset in the mountains',
  T_SEARCH_ADMIN_NAME: 'Search Admin Name',
  T_CONTINUE: 'Continue',
  T_ONE_TIME_OTP_ENTER: 'Enter your One-Time Password sent to',
  T_NOT_RECEIVED_LBL: 'Didn’t receive an email?',
  T_NOT_RECEIVED_MSG: 'Be sure to check your spam folder before requesting a new code',
  T_RECEIVE_OTP_EMAIL_HELP_MSG:
    'Enter your email to receive a One-Time Password (OTP) to reset your password.',
  T_REQ_NEW_OTP: 'Request a new OTP',
  T_INVITED_USERS_TO_MSG: 'Invite user(s) to',
  T_REGISTERED: 'Registered',
  T_INVITED: 'Invited',
  T_ALL: 'All',
  T_SEARCH_HINT_MSG: 'Search admin portal functionality',
  T_ADMIN_PERMISSION: 'Admin Permission',
  T_NEXT_STEP: 'Next Step',
  T_BACK_TO_APP_FEATURES: 'Back to Application Features',
  T_BREADCRUMB_SHOW_PERMISSION_LBL: 'Security > Permission Sets > View',
  T_INVALID_EMAIL: 'Invalid Email',
  T_WELCOME_TO_ADMIN_MSG:
    'Welcome! Your administrator sent you an invitation to sign up for the Roche Admin portal. To continue creating an account, please enter the email where you received the invitation and code:',
  T_USER_NAME_HINT_MSG: 'This will also be your username.',
  T_CLOUD_UPLOAD: 'cloud upload',
  T_ORG_SETTINGS: 'Organization Settings',
  T_CLONE_DEFAULT_TEMPLATES: 'Clone Default Templates for the Current Organization',
  T_EDIT_QUESTION_ANSWERS: 'Edit question/answers',
  T_VIEW_ANSWERS: 'View answer(s)',
  T_MARK_AS_READ_ONLY: 'Mark as Read Only',
  T_REMOVE_READ_ONLY: 'Remove Read Only',
  T_REMOVE_QUESTION: 'Remove Question',
  T_READ_ONLY_QUESTION: 'Read-only question',
  T_EDIT_QUESTION: 'Edit Question',
  T_CHANNEL_TYPE: 'Channel Type',
  T_BUCKET_VERSIONING_DISABLED: 'Versioning not enabled for this bucket',
  T_READ_WRITE: 'READ & WRITE',
  T_READ_ONLY: 'READ ONLY',
  T_OWNER: 'OWNER',

  T_SURVEY_CREATION_BUTTON_TEXT: 'New survey template',
  T_REMOVE_SURVEY_QUESTION_SUCCESS: 'Question removed Successfully.',
  T_REMOVE_SURVEY_GROUP_QUESTION_SUCCESS: 'Group Question removed Successfully.',
  T_SURVEY_CREATION_SUCCESS: 'Survey Created Successfully.',
  T_SURVEY_CREATION_FAILURE: 'Survey Creation Failed.',
  T_SURVEY_DEACTIVATE: 'Deactivate Survey',
  T_SURVEY_DEACTIVATE_PROMPT: 'Do you want to retire this survey?',
  T_SURVEY_DEACTIVATE_PROMPT_YES: 'Yes, retire',
  T_SURVEY_DELETE_TEXT: 'Delete Survey',
  T_SURVEY_DELETE_PROMPT: 'Do you want to delete this survey?',
  T_SURVEY_DELETE_PROMPT_YES: 'Yes, delete',
  T_SURVEY_ACTIVATE: 'Activate Survey',
  T_SURVEY_ACTIVATE_PROMPT: 'Activate this survey?',
  T_SURVEY_ACTIVATE_INFO: 'Are you sure you want to activate?',
  T_SURVEY_ACTIVATE_PROMPT_CANCEL: 'Do not activate',
  T_SURVEY_ACTIVATE_PROMPT_YES: 'Yes, activate',
  T_SURVEY_INACTIVE_INFO: 'This survey is inactive.',
  T_SURVEY_ACTIVE_INFO: 'This survey is active.',
  T_SURVEY_EFFECTIVE_START: 'Effective Start Date',
  T_SURVEY_EFFECTIVE_END: 'Effective End Date',
  T_SURVEY_EFFECTIVE_START_END: 'Effective Start & End Date',
  T_SURVEY_PREVIEW: 'Preview',
  T_SURVEY_PREVIEW_QUESTIONS: 'Preview Questions',
  T_SURVEY_ADD_QUESTION: 'Add Question',
  T_SURVEY_DEFAULT_SELECTION: 'Selected by default',
  T_SURVEY_READ_ONLY: 'Read Only',
  T_SURVEY_DIFF_UI_INFO:
    'The patient app interface may look different. Default choice & Display conditions are only visible to you',
  T_SURVEY_STATUS: 'Status',
  T_SURVEY_SELECT_QUESTIONS: 'Select Questions',
  T_SURVEY_PERIOD_START: 'Period Start',
  T_SURVEY_PERIOD_END: 'Period End',

  T_QUESTION_NAME: 'Question Name',
  T_BUTTON_NEW_QUESTION: 'New question',
  T_QUESTION_MODAL_QUESTION: 'What type of question do you want to create?',
  T_QUESTION_MODAL_HEADER: 'Question type',
  T_QUESTION: 'Question',
  T_QUESTION_DETAILS: 'Question Details',
  T_ANSWER_DETAILS: 'Answer Details',
  T_TEXT_BLOCK_INPUT: 'Text block input',
  T_ADD_OPTION: 'Add option',
  T_DEFAULT_OPTION_SELECTED: 'Set as default',
  T_REMOVE_OPTION: 'Remove option',
  T_REMOVE_DEFAULT_SELECTED_MSG1: 'This option will be selected by default.',
  T_REMOVE_DEFAULT_SELECTED_MSG2: 'Remove default selection.',
  T_BOOLEAN_ANSWER_DESCRIPTION: 'Patient will select one option that applies.',
  T_TEXTBOX_ANSWER_DESCRIPTION:
    'Input will display to the user as an informational section with no input required.',
  T_SINGLE_SELECTION_ANSWER_DESCRIPTION:
    'Minimum two options required. Patient will select one option that applies.',
  T_URL_ANSWER_DESCRIPTION: 'Patients will enter in a URL in the following format:',
  T_MULTIPLE_SELECTION_ANSWER_DESCRIPTION:
    'Minimum two options required. Patient will select all options that apply.',
  T_DATE_TIME_ANSWER_DESCRIPTION: 'Patients will enter a date & time in following format:',
  T_TIME_ANSWER_DESCRIPTION: 'Patients will enter time in following format:',
  T_DATE_ANSWER_DESCRIPTION: 'Patients will enter date in following format:',
  T_EDIT_QUESTION_STATUS_DESCRIPTION: 'This queston is active and ready to be assigned to surveys.',
  T_CREATED_BY: 'Created by',
  T_CREATED_ON: 'Created on',
  T_UPDATED_BY: 'Updated by',
  T_UPDATED_ON: 'Updated on',
  T_START_DATE_TIME: 'Start date/time',
  T_END_DATE_TIME: 'End date/time',
  T_BUTTON_BACK_TO_SURVEY_LIST: 'Back to Survey Question list',
  T_SURVEY_QUESTION_CREATED_MSG: 'Survey question created successfully.',
  T_SURVEY_QUESTION_UPDATED_MSG: 'Survey question updated successfully.',
  T_EDIT_QUESTION_INACTIVE_STATUS_DESCRIPTION:
    'This queston is inactive and cannot be assigned to any surveys.',
  T_SURVEY_QUESTION_ACTIVATED: 'Survey question activated successfully.',
  T_SURVEY_QUESTION_DEACTIVATED: 'Survey question deactivated successfully.',
  T_SURVEY_QUESTION_DELETE_MODAL_BODY:
    'Deleting this question will remove it from all surveys where it has been assigned.',
  T_SURVEY_QUESTION_DELETE_BUTTON_LABEL: 'Yes, delete question',
  T_SURVEY_QUESTION_MODAL_CANCEL_BUTTON: 'No, cancel',
  T_SURVEY_QUESTION_DELETE_BUTTON: 'Delete question',
  T_SURVEY_QUESTION_DELETED_SUCCESSFULLY: 'Survey question deleted successfully.',
  T_INVALID_DATE_FORMAT_ERROR_MESSAGE: 'Invalid date format. Valid format is YYYY-MM-DD.',
  T_DATE_QUESTION_ANSWER_SECTION_LABEL: 'Set initial date value to',
  T_INVALID_DATE_TIME_FORMAT_ERROR_MESSAGE:
    'Invalid date & time format. Valid format is YYYY-MM-DD HH:mm',
  T_DATE_TIME_QUESTION_ANSWER_SECTION_LABEL: 'Set initial datetime value to',
  T_INVALID_TIME_FORMAT_ERROR_MESSAGE: 'Invalid time format. Valid format is hh:mm',
  T_TIME_QUESTION_ANSWER_SECTION_LABEL: 'Set initial time value to',
  T_VALID_DATE_FORMAT: 'YYYY-MM-DD',
  T_VALID_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
  T_VALID_TIME_FORMAT: 'HH:mm',
  T_USER_NO_PERMISSION_TOAST_MESSAGE: 'User do not have permission to view Permission',

  T_MOBILE_CONFIGURATION_PLATFORM: 'Mobile Configuration',
  T_MOBILE_CONFIGURATION_COMPONENTS: 'Components',
  T_MOBILE_CONFIGURATION: 'Mobile Configuration',
  T_MOBILE_CONFIGURATION_LANGUAGE: 'Language Translations',
  T_MOBILE_CONFIGURATION_UPDATED_MSG: 'Configuration updated successfully.',
  T_MOBILE_CONFIGURATION_INVALID_HEX: 'Invalid Hex code',
  T_MOBILE_CONFIGURATION_SELECT_ERROR: 'Please select an option',
  T_MOBILE_CONFIGURATION_LANGUAGE_UPLOAD_HEADER: 'Upload language file',
  T_MOBILE_CONFIGURATION_LANGUAGE_INFO:
    'Download the selected language file or upload an updated one',
  T_MOBILE_CONFIGURATION_LANGUAGE_ADDED_SUCCESS: 'Language added successfully.',
  T_MOBILE_CONFIGURATION_LANGUAGE_UPDATED_SUCCESS: 'Language updates saved.',
  T_MOBILE_CONFIGURATION_VERSION_UPDATING_ERROR: 'Error updating version.',
  T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_HEADER: 'Confirm Change?',
  T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_SUB: 'Do you want to save the file?',
  T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_YES: 'Yes, save',
  T_MOBILE_CONFIGURATION_CONFIRM_CHANGE_NO: 'No, discard',

  T_MOBILE_CONFIGURATION_IMAGE_MODAL_BODY:
    'Deleting this image will revert to default image settings.',
  T_MOBILE_CONFIGURATION_IMAGE_CANCEL_BUTTON: 'No, keep current image',
  T_MOBILE_CONFIGURATION_IMAGE_DELETE_BUTTON_LABEL: 'Yes, delete',
  T_CONFIRM_DELETION: 'Confirm Deletion',
  T_CHANGE_FILE: 'Change File',
  T_CHANGE_IMAGE: 'Change Image',
  T_FILE_NAME: 'File Name',
  T_IMAGE_HERE: 'image here',
  T_SELECT_FILE: 'Select File',
  T_INVALID_FILE_EXTENSION: 'Invalid file extension.',
  T_UPDATED_MESSAGE: 'updated successfully.',
  T_UPDATED_MESSAGE2: 'was not updated.',
  T_FILE_TYPE: 'File Type',
  T_LAST_MODIFIED_BY: 'Last Modified By',
  T_LAST_MODIFIED_ON: 'Last Modified On',
  T_FILE_SIZE_ERROR_MSG: 'File size should be less than 5MB',
  T_FILE_SIZE_SURVEY_MEDIA_IMAGE_ERROR_MSG: 'File size should be less than 5MB',
  T_FILE_SIZE_SURVEY_MEDIA_VIDEO_ERROR_MSG: 'File size should be less than 20MB',
  T_FILE_SIZE_SURVEY_MEDIA_AUDIO_ERROR_MSG: 'File size should be less than 10MB',

  T_ITEMS_SELECTED: ' items selected',
  T_PLEASE_SELECT_MINIMUM: 'Please select minimum',
  T_PLEASE_SELECT_MAXIMUM: 'Please select maximum',
  T_ITEMS: 'item(s)',

  T_RESOURCE_TYPE: 'Resource Type',
  T_IS_DEFAULT: 'Is Default',
  T_RETURN_FIELDGROUP: 'Return to field group list',
  T_FIELDS: 'Fields',
  T_FIELD_GROUP_DETAILS: 'Field Group Details',
  T_MODIFIED_BY: 'Modified By',
  T_MODIFIED_ON: 'Modified On',
  T_CREATED_BY_ORG: 'Created By Org',
  T_DISPLAY: 'Display',
  T_RESET_FILTER: 'Reset filter',
  T_APPLY_FILTER: 'Apply filter',
  T_CATEGORY_CODE: 'Category code',
  T_PURPOSE_CODE: 'Purpose code',
  T_CONSENT_NAME: 'Consent name',
  T_NEW_CONSENT: 'New Consent',
  T_FILTER: 'Filter',
  T_PURPOSE: 'Purpose',
  T_ACTION: 'Action',
  T_RETURN_CONSENT_TEMPLATES: 'Return to consent template list',
  T_CONSENT_DETAILS: 'Consent Details',
  T_ACTIVE_BY: 'Activated by',
  T_ACTIVE_ON: 'Activated On',
  T_PROPOSED_BY: 'Proposed by',
  T_PROPOSED_ON: 'Proposed on',
  T_DRAFT_BY: 'Draft by',
  T_DRAFT_ON: 'Draft on',
  T_INACTIVE_BY: 'Inactived by',
  T_INACTIVE_ON: 'Inactived on',
  T_REJECTED_BY: 'Rejected by',
  T_REJECTED_ON: 'Rejected on',
  T_ENTERED_IN_ERROR_BY: 'Entered in error by',
  T_ENTERED_IN_ERROR_ON: 'Entered in error on',
  T_PURPOSE_OF_CONSENT: 'Purpose of consent',
  T_ALLOW_USER_TO_DENY_CONSENT: 'Allow user to deny consent',
  T_DEFAULT_ALL_CONSENT_TEMPLATE: 'Default view: All Consent Templates',
  T_ATTACHMENT: 'Attachment',
  T_ATTACHMENTS: 'Attachments',
  T_REMOVE_ATTACHMENT: 'Remove Attachment',
  T_FIELDGROUPS: 'Field Groups',
  T_TITLE: 'Title',
  T_URL: 'URL',
  T_CONTENT_TYPE: 'Content type',
  T_BASE64: 'Base64',
  T_SELECT_IMAGE: 'Select Image',
  T_ADD_MANUALLY: 'Add Manually',
  T_ATTACHMENT_TYPE: 'Attachment type',
  T_DOWNLOAD_ATTACHMENT: 'Download Attachment',
  T_SCOPE: 'Scope',
  T_CONTENT: 'Content',
  T_ACTIVE_INFO: 'This consent is active',
  T_INACTIVE_INFO: 'This consent is not active',
  T_REJECTED_INFO: 'This consent has been rejected',
  T_PROPOSED_INFO: 'This consent has been proposed',
  T_DRAFT_INFO: 'This consent is not active',
  T_ENTERED_IN_ERROR_INFO: 'This consent is entered in error',
  T_CREATE_NEW_VERSION: 'Yes, Create New Version',
  T_NEW_VERSION_TEXT: 'Do you want to create a new version of consent template',
  T_CONSENT_UPDATED_SUCCESS:"Consent updated successfully.",
  T_CONSENT_CREATED_SUCCESS:"Consent created successfully.",
  T_CONSENT_ATTACHMENTS_UPDATED_SUCCESS:"Consent Attachments updated successfully.",

  T_METADATA: 'Metadata',
  T_METADATA_ID: 'Metadata ID',
  T_METADATA_NAME: 'Metadata Name',
  T_OBJECT_NAME: 'Object Name',
  T_DISPLAY_NAME: 'Display Name',
  T_PROPERTIES: 'Properties',
  T_YES: 'Yes',
  T_NO: 'No',
  T_DELETE_CONFIRMATION_TITLE: 'Delete Confirmation',
  T_DELETE_CONFIRMATION: 'Are you sure you would like to delete',
  T_DELETE_CONFIRMATION_TEXT: 'Are you sure you would like to delete?',
  T_DELETE_CONFIRMATION_PLACEHOLDER: 'Are you sure you would like to delete %s',
  T_BUCKET_DELETE_CONFIRM: 'Yes, Delete',
  T_FILE_FOLDER_DELETE_HEADER: 'Please select the delete operation',
  T_BUCKET_DELETE_CONFIRMATION_TEXT:
    'Deleting this bucket will result in the deletion of all the Folders & Files. Are you sure you would like to delete this bucket?',
  T_FOLDER_DELETE_SUCCESS_MSG: 'Folder %s has been deleted successfully.',
  T_FILE_DELETE_SUCCESS_MSG: 'File %s has been deleted successfully.',
  T_APPLICATION: 'application',
  T_OBJECT: 'object',
  T_INSTANCE: 'instance',
  T_ATTRIBUTE: 'attribute',
  T_NEW_METADATA: 'New Application',
  T_BACK_TO_METADATA: 'Back to Metadata',
  T_BACK_TO_APPLICATION: 'Back to %s Metadata',
  T_BACK_TO_OBJECTS: 'Back to %s Objects',
  T_BACK_TO_INSTANCES: 'Back to %s Instances',
  T_BACK_TO_OBJECT: 'Back to %s Object',
  T_EDIT_METADATA: 'Edit Metadata',

  T_NEW_METADATA_OBJECT: 'New Object',
  T_ADD_METADATA_OBJECT: 'Add Object',
  T_LIST_METADATA_OBJECTS: 'View Objects',
  T_EDIT_METADATA_OBJECT: 'Edit Object',
  T_MANAGE_METADATA_OBJECT: 'Manage Object',

  T_ENUM_OPTIONS: 'Enum Options',
  T_ENUM_ADD_PLACEHOLDER: 'Add Enum Option',
  T_ENUM_ADDED_OPTIONS: 'Added Options',
  T_ENUM_VIEW_ADDED: 'View Options',

  T_NEW_METADATA_INSTANCE: 'New Instance',
  T_ADD_METADATA_INSTANCE: 'Add Instance',
  T_LIST_METADATA_INSTANCES: 'View Instances',
  T_EDIT_METADATA_INSTANCE: 'Edit Instance',
  T_DELETE_METADATA_INSTANCE: 'Delete Instance',
  T_MANAGE_METADATA_INSTANCE: 'Manage Instance',

  T_METADATA_DELETE_ATTRIBUTE_NOT_ALLOWED:
    'Removing attribute %s not allowed because of an existing instance.',
  T_NEW_METADATA_CREATED_SUCCESS_MESSAGE: 'Metadata application %s created successfully.',
  T_METADATA_UPDATED_SUCCESS_MESSAGE: 'Metadata application %s has been updated successfully.',
  T_MANAGE_METADATA: 'Manage Metadata',
  T_CONFIGURATION_KEYS: 'Configuration keys',
  T_CONFIG_KEY_NAME: 'Key Name',
  T_CONFIG_KEY_DATA_TYPE: 'Data Type',
  T_CONFIG_KEY_DESCRIPTION: 'Description',
  T_CONFIG_MANAGE_KEYS: 'Manage configuration keys',
  T_CONFIG_PERMISSION_KEY_ERROR: 'User do not have permission to view this key',
  T_SETTING_DETAILS: 'Setting Details',
  T_CONFIG_KEY_CATEGORIES: 'Categories',
  T_CONFIG_KEY_CATEGORIES_ASSIGNED: 'Assigned Categories',
  T_CONFIG_KEY_ALLOWED_VALUES: 'Allowed Values (comma-separated)',
  T_CONFIG_KEY_DESC: 'Description',
  T_CONFIG_KEY_ALLOW_OVERRIDE: 'Allow override',
  T_CONFIG_KEY_VALUE: 'Value',
  T_CATEGORY_NAME: 'Category Name',
  T_CATEGORY_KEY_DESCRIPTION: 'Description',
  T_CATEGORY_DETAILS: 'Category Details',
  T_FILTERS: 'Filters',
  T_CONFIG_FILTERS_DEFAULT_VIEW: 'Default view: All Organizations, All Categories, All Data-types',
  T_CONFIG_CATEGORIES_FILTERS_DEFAULT_VIEW: 'Default view: All Organizations',
  T_CREATE_SETTINGS: 'Create system setting',
  T_VIEW_CATEGORIES: 'View categories',
  T_VIEW_SETTINGS: 'View Settings',
  T_CREATE_NEW_CATEGORY: 'Create new category',
  T_FILTER_KEY_ORGANIZATIONS: 'Organizations',
  T_FILTER_KEY_CATEGORIES: 'Categories',
  T_FILTER_KEY_DATATYPE: 'Data Types',
  T_FILTER_APPLY: 'Apply Filter',
  T_RESET_FILTERS: 'Reset Filters',
  T_CONFIG_KEY_CATEGORIES_NOT_ASSIGNED: 'No categories assigned',
  T_CATEGORY_NEW: 'New Category',
  T_BACK_TO_CONFIGURATION_KEYS: 'Back to Configuration Keys',
  T_CREATE_CONFIGURATION_KEY: 'Create Configuration Key',
  T_TOAST_CONFIGURATION_CREATED_SUCCESS_MSG: 'Configuration Key has been created successfully!',
  T_TOAST_CONFIGURATION_UPDATED_SUCCESS_MSG: 'Configuration Key has been updated successfully!',
  T_TOAST_CONFIGURATION_ORG_UPDATED_SUCCESS_MSG:
    'Configuration Key has been overridden at org level!',
  T_TOAST_CONFIGURATION_USER_UPDATED_SUCCESS_MSG:
    'Configuration Key has been overridden at user level!',
  T_ATTRIBUTES: 'Attributes',
  T_ATTRIBUTE_NAME: 'Attribute Name',
  T_ADD_ATTRIBUTE: 'Add Attribute',
  T_EDIT_ATTRIBUTE: 'Edit Attribute',
  T_REQUIRED: 'Required?',
  T_IS_REQUIRED: 'Required',
  T_ALLOW_EDITS: 'Allow Edits?',
  T_EDITABLE: 'Editable',
  T_SEARCHABLE: 'Searchable',
  T_SORTABLE: 'Sortable',
  T_IS_SEARCHABLE: 'Searchable?',
  T_IS_SORTABLE: 'Sortable?',
  T_HASH_OF_CHARATERS: '# of Charaters',
  T_MAX_HASH: 'Max #',
  T_MIN_HASH: 'Min #',
  T_MAX_LENGTH: 'Max Length',
  T_MAX: 'Max',
  T_MIN: 'Min',
  T_MAX_VALUE: 'Max Value',
  T_MIN_VALUE: 'Min Value',

  T_DUAL_LIST_INPUT_HEADER: 'Available Columns',
  T_DUAL_LIST_OUTPUT_HEADER: 'Selected Columns',
  T_SEARCH_METADATA_BY_NAME: 'Search metadata by name',
  T_SEARCH_OBJECTS_BY_NAME: 'Search objects by name',
  T_SEARCH_INSTANCES_BY_NAME: 'Search instances by name',
  T_VALIDATION_ERR_TEXT_LENGTH_MSG_4096: 'Text too long. Max length 4096 characters.',
  T_SELECT_CATEGORY_TO_ASSIGN: 'Select category to assign',
  T_CATEGORY_REMOVE: 'Remove',
  T_ASSIGN_LEVEL: 'Assign a level',
  T_WHAT_LEVEL_THIS_KEY_APPLY: 'What level does this key apply to?',
  T_SYSTEM_LEVEL: 'System level',
  T_ORGANIZATION_LEVEL: 'Organization level (my org)',
  T_CURRENT_USER_LEVEL: 'Current user',
  T_LOGGED_IN_USER_LEVEL: 'Logged in user',
  T_ASSIGN_SELECTED: 'Assign selected',
  T_CONFIRM_ASSIGNMENT: 'Confirm assignment',
  T_CONFIRM: 'Confirm',
  T_CATEGORIES_ASSIGNED_TO: 'The following categories are being assigned to: ',
  T_CATEGORIES_ASSIGNED_SUCCESS: 'Categories assigned successfully',
  T_CATEGORY_REMOVED_FROM_ORG: 'Category removed from org',
  T_CATEGORY_DELETED: 'Category deleted successfully',
  T_CATEGORY_DELETE_CONFIRMATION_HEADER: 'Delete category',
  T_CATEGORY_DELETE_CONFIRMATION: 'Do you want to delete this category?',
  T_CATEGORY_DELETE_CONFIRMATION_ORG: 'Do you want to remove this category from org?',
  T_SETTING_DELETE_CONFIRMATION_HEADER: 'Delete system setting',
  T_SETTING_DELETE_CONFIRMATION: 'Do you want to delete this system setting?',
  T_SETTING_DELETE_CONFIRMATION_ORG: 'Do you want to remove this sysytem setting from org?',
  T_CATEGORY_DELETE_CONFIRMATION_PROMPT_YES: 'Yes, delete',
  T_CATEGORY_CREATED: 'Category created successfully',
  T_CATEGORY_UPDATED: 'Category updated successfully',
  T_CATEGORY_NOT_ASSIGNED: 'category not assigned to org',
  T_CATEGORY_NOT_ASSIGNED_USER: 'category not assigned to user',
  T_BACK_TO_CATEGORY_LIST: 'Back to category list',

  T_METADATA_APPLN_DELELTE_SUCCESS: 'Application %s deleted successfully',
  T_METADATA_APPLN_CREATED_SUCCESS: 'Application %s created successfully',
  T_METADATA_APPLN_UPDATED_SUCCESS: 'Application %s updated successfully',

  T_METADATA_OBJECT_DELELTE_SUCCESS: 'Object %s deleted successfully',
  T_METADATA_OBJECT_CREATED_SUCCESS: 'Object %s created successfully',
  T_METADATA_OBJECT_UPDATED_SUCCESS: 'Object %s updated successfully',

  T_METADATA_INSTANCE_DELELTE_SUCCESS: 'Instance %s deleted successfully',
  T_METADATA_INSTANCE_CREATED_SUCCESS: 'Instance %s created successfully',
  T_METADATA_INSTANCE_UPDATED_SUCCESS: 'Instance %s updated successfully',

  T_DELETE: 'Delete',
  T_COUNTRY_CODE: 'C-Code',
  T_INVALID_CURRENT_PASSWORD_TEXT: 'Current password is not correct.',
  T_SESSION_TIMEOUT_HEADER: 'Session timeout',
  T_SESSION_TIMEOUT_BODY_TEXT: 'Your session will timout due to inactivity in ',
  T_SESSION_STAY_SIGNED_IN: 'Stay signed in',
  T_SESSION_SIGN_OUT: 'Sign out',
  T_OBJECT_DETAILS: 'Object Details',
  T_INSTANCE_DETAILS: 'Instance Details',
  T_SYSTEM_SETTING_DELETED: 'System setting deleted',
  T_USER_NO_PERMISSION_ACTION_MESSAGE: 'User does not have permission to perform this action',
  T_NO_PERMISSION_TO_VIEW_THIS_PAGE: 'You do not have permission to view this page',

  T_EMPTY_ATTRIBUTE_NAME: 'Attribute name cannot be empty',
  T_MAXIMUM_ATTRIBUTE_NAME_LENGTH: 'Attribute name can not be more than 255 characters',
  T_INVALID_ATTRIBUTE_NAME:
    'Name can include only hypen or underscore character within them. Ex. object-_name',
  T_SELECT_ATTRIBUTE_TYPE: 'Please select attribute type',
  T_INVALID_ENUM_OPTIONS: 'Please add atleast one enum option',
  T_MINIMUM_ATTRIBUTE_MAX_LENGTH: 'Minimum no of characters should be greater than zero',
  T_MAXIMUM_ATTRIBUTE_MAX_LENGTH: 'Maximum no of characters should be less than or equal to 10240',

  T_INT4_CONSENT_VERSION_VALIDATION: 'Version should not be greater than 2147483646',

  T_EXPORT_NAME: 'Export Name',
  T_NEW_EXPORT: 'New Export',
  T_IMPORT_NAME: 'Import Name',
  T_NEW_IMPORT: 'New Import',
  T_VALIDATION_ONLY: 'Validation only?',
  T_DEAFULT_EXPORT_FILTER_INFO: 'Default view: All status, all exports',
  T_DEAFULT_IMPORT_FILTER_INFO: 'Default view: All status, all imports',
  T_VIEW_LOG: 'View log',
  T_DONWLOAD_ZIP_FILE: 'Download zip file',
  T_RE_TRIGGER: 'Re-trigger',
  T_STOP: 'Stop',
  T_START: 'Start',

  T_MIGRATION_EXPORT_FORM_CARD_HEADER: 'Export Details',
  T_MIGRATION_EXPORT_FORM_NAME: 'Export Name',
  T_MIGRATION_EXPORT_FORM_DESCRIPTION: 'Export Description',
  T_MIGRATION_FORM_TAB_MANIFEST_JSON: 'Manifest JSON',
  T_MIGRATION_FORM_TAB_MANIFEST: 'Manifest',
  T_MIGRATION_FORM_MANIFEST_INFO: 'Select at least one module in the following categories',
  T_MIGRATION_FORM_MANIFEST_ROLESPERMISSION: 'Roles & Permissions',
  T_MIGRATION_FORM_MANIFEST_SYSTEMSETTINGS: 'System Settings',
  T_MIGRATION_FORM_MANIFEST_NOTIFICATIONTEMPLATES: 'Notification Templates',
  T_MIGRATION_FORM_MANIFEST_SURVEYS: 'Surveys',
  T_MIGRATION_FORM_MANIFEST_METADATA: 'Metadata',
  T_MIGRATION_FORM_MANIFEST_OBJECTS: 'Objects',
  T_MIGRATION_FORM_MANIFEST_INSTANCES: 'Instances',
  T_MIGRATION_FORM_MANIFEST_ORGANIZATIONS: 'Organizations',
  T_MIGRATION_EXPORT_START_SUCCESS: 'Export created successfully!',
  T_MIGRATION_EXPORT_RETRIGGER_SUCCESS: 'Export retriggered successfully!',
  T_MIGRATION_IMPORT_RETRIGGER_SUCCESS: 'Import retriggered successfully!',
  T_MIGRATION_EXPORT_START_FAILURE: 'Server error. Could not save.',
  T_MIGRATION_EXPORT_MANIFEST_EMPTY_ERROR: 'Atleast one manifest module is required.',
  T_MIGRATION_FORM_MANIFEST_CATEGORIES: 'Categories',
  T_MIGRATION_BACK_TO_EXPORT_LIST: 'Back to Export List',
  T_MODIFY_MIGRATION_JOB: 'Modify migration job',
  T_IMPORT_DESCRIPTION: 'Import description',
  T_MIGRATION_FILE_CARD_HEADER: 'Migration File',
  T_MIGRATION_UPLOAD_IMPORT_ZIP: 'Upload Zip File',
  T_MIGRATION_FORM_CARD_HEADER: 'Import Details',
  I_MIGRATION_IMPORT_VALIDATION_ONLY: 'Validation Only',
  I_MIGRATION_IMPORT_VALIDATION_IMPORT: 'Validate & Import',
  T_MIGRATION_BACK_TO_IMPORT_LIST: 'Back to Import List',
  T_IMPORT_JOB: 'Import Job',
  T_LOG_VIEWER: 'Log Viewer',
  T_MIGRATION_JOB_LOG_NOT_FOUND: 'Job log URL not found',
  T_MIGRATION_JOB_DOWNLOAD_NOT_FOUND: 'Job download URL not found',
  T_MEDIA_QUESTION_FILE_UPLOAD_HEADER:
    'The patient will be able to upload a media file in response to this question',
  T_IMAGE_RADIO_MEDIA_QUESTION: 'Photo (jpeg, jpg or png)',
  T_VIDEO_RADIO_MEDIA_QUESTION: 'Video (mpeg-4, MP4, M4V, & MOV)',
  T_AUDIO_RADIO_MEDIA_QUESTION: 'Audio (wav, mp3)',
  T_BROWSE: 'Browse',

  T_DELETE_TEMPLATE_TEXT: 'Delete template',

  T_NAME_AND_DESCRIPTION: 'Name & Description',
  T_SELECT_QUESTIONS: 'Select questions',
  T_REMOVE_GROUP: 'Remove group',
  T_CREATE_GROUP_QUESTION: 'Create Group Question',
  T_QUESTION_TYPE: 'Question Type',
  T_ADD_GROUP_QUESTION: 'Edit/Add group question(s)',
  T_ADD_25_QUESTIONS: 'Add up to 25 questions to this survey',
  T_MENU_ADD_25_QUESTIONS: 'Add Up to 25 Questions',
  T_MENU_BUNDLE_5_QUESTIONS: 'Bundle Up to 5 Questions',
  T_ANSWER: 'Answer',
  T_APPLY_CONDITIONS: 'Apply Conditions',

  T_SELECT_IMAGES: 'Select image(s)',
  T_OR: 'OR',
  T_CONFIGURE: 'Configure',
  T_PREVIEW: 'Preview',
  T_UPLOAD_BODYMAP_INFO_TEXT:
    'Upload body map image(s) to configure each image to create answer choices.',
  T_CONFIGURE_BODY_MAP: 'Configure body map',
  T_PREVIEW_BODY_MAP: 'Preview body',
  T_CLICK_ADD_DRAG_FOR_SECTION: 'Click on a section to create an option.',
  T_CLICK_PREVIEV_LABEL_SELECTION: 'Click on selections to view the label.',
  T_BODY_MAP_CHECKBOX_TEXT: 'Allow patient to select more than one option',
  T_UPLOAD_FILE_PRIVATELY: 'Upload file privately',
  T_UPLOAD_FILE_PUBLIC: 'Upload file public',
  T_UPLOAD_FILE_PERMISSIONS: 'Upload file permissions:',
  T_SELECT_LIKERT_TYPE: 'Select likert type:',
  T_LIKERT_SCALE: 'Labels Only',
  T_LIKERT_SCALE_ICONS: 'Icons Only',
  T_LIKERT_SCALE_ICONS_LABELS: 'Icons & labels',
  T_BACK_TO_SURVEY_LIST: 'Back to Survey list',
  T_SEARCH_NAME_SURVEY_TEMPLATE: 'Search by survey template name',
  T_SEARCH_NAME_BUCKET_NAME: 'Search by bucket name',
  T_ALLOW_VERSIONING: 'Allow Versioning',
  T_PUBLIC_ACCESS: 'Public Access',
  T_FILE_VERSIONING: 'File Versioning',
  T_ACTIVATE: 'Activate',
  T_DEACTIVATE: 'Deactivate',
  T_BUCKET_DETAILS: 'Bucket Details',
  T_BUCKET_OWNERS: 'Bucket Owners',
  T_BACK_TO_FILES: 'Back to Files',
  T_BACK_TO_FOLDER: 'Back to Folder',
  T_BUCKET: 'Buckets',
  T_BACK_TO_BUCKET: 'Back to Bucket',
  T_BACK_TO_BUCKET_LIST: 'Back to Bucket List',
  T_BACK_TO_BUCKET_CONTENTS_LIST: 'Back to %s Bucket List',
  T_BACK_TO_FOLDER_CONTENTS_LIST: 'Back to %s Files & Folders',
  T_BACK_TO_FOLDER_LIST: 'Back to %s List',
  T_NEW_BUCKET: 'New Bucket',
  T_FILES: 'Files',
  T_CREATE: 'Create',
  T_BUCKET_OWNER_DESCRIPTION_TEXT: 'Add an individual user or a user role group as bucket owners.',
  T_INDIVIDUAL_USER: 'Individual User',
  T_USER_ROLE: 'User Role',
  T_ADD: 'Add',
  T_BUCKET_CREATION_SUCCESS_MSG: 'Bucket created successfully.',
  T_BUCKET_UPDATED_SUCCESS_MSG: 'Bucket updated successfully.',
  T_BUCKET_DEACTIVATED_SUCCESS_MSG: 'Bucket %s deactivated successfully.',
  T_BUCKET_ACTIVATED_SUCCESS_MSG: 'Bucket %s activated successfully.',
  T_FILE_METADATA_UPDATED_SUCCESS_MSG: 'File %s metadata updated successfully.',
  T_MANAGE: 'Manage',
  T_BUCKET_DELETE_SUCCESS_MSG: 'Bucket deleted successfully.',
  T_BUCKET_FILE_FOLDER: 'Files & Folders',
  T_FILE_FOLDER_NAME: 'File/Folder Name',
  T_FILE_SIZE: 'File Size',
  T_SEARCH_NAME_FILE_NAME: 'Search folder/file by name',
  T_NEW_FOLDER: 'New Folder',
  T_UPLOAD: 'Upload',
  T_KEY: 'Key',
  T_VALUE: 'Value',
  T_ADD_ITEM: 'Add Item',
  T_CUSTOM_METADATA: 'Metadata',
  T_UPLOAD_FILES: 'Upload Files',
  T_FOLDER_OWNER_DESCRIPTION_TEXT: 'Add an individual user or a user role group as folder owners.',
  T_FOLDER_NAME: 'Folder Name',
  T_DELETE_PERMISSION: 'Delete permission',
  T_HARD_DELETE_FOLDER: 'Permanent delete',
  T_HARD_DELETE_DESCRIPTION:
    'Hard delete refers to the permanent removal of data, with no option for recovery.',
  T_SOFT_DELETE_FOLDER: 'Soft delete',
  T_SOFT_DELETE_DESCRIPTION:
    'Soft delete allows you to retain and recover deleted data, rather than permanently deleting it.',
  T_DOWNLOAD_FILE: 'Download',
  T_VERSION_HISTORY: 'Version History',
  T_MANAGE_FILE_METADATA: 'Manage Metadata',
  T_VIEW_FILE_METADATA: 'View Metadata',
  T_FILE_METADATA: 'File Metadata',
  T_DEACTIVATE_BUCKET_CONFIRMATION_TEXT: 'Are you sure you would like to deactivate this bucket?',
  T_ACTIVATION_BUCKET_CONFIRMATION_TEXT: 'Are you sure you would like to activate this bucket?',
  T_DEACTIVATE_CONFIRMATION_TITLE: 'Deactivate Confirmation',
  T_ACTIVATION_CONFIRMATION_TITLE: 'Activation Confirmation',
  T_UPDATE_FOLDER: 'Update Folder',
  T_FOLDER_DETAILS: 'Folder Details',
  T_FOLDER_OWNERS: 'Folder Owners',
  T_SIZE: 'Size',
  T_FOLDER_CREATION_SUCCESS_MSG: 'Folder created successfully.',
  T_FOLDER_UPDATED_SUCCESS_MSG: 'Folder updated successfully.',
  T_IMAGE_TITLE: 'Specify Image Title',
  T_SURVEY_ORAGANIZATION_ID: 'Organization',
  T_SURVEY_ROLE_ID: 'Role',

  T_GCP_BUCKET_RULE_HEADER: 'Your bucket names must meet the following requirements:',
  T_GCP_BUCKET_RULE_1: 'Bucket names must contain 3-63 characters',
  T_GCP_BUCKET_RULE_2:
    'Bucket names can only contain lowercase letters, numeric characters, dashes (-), and underscores (_). Blank spaces are not allowed.',
  T_GCP_BUCKET_RULE_2a: 'a: Blank spaces are not allowed.',
  T_GCP_BUCKET_RULE_2b: 'b: Names containing dots -',
  T_GCP_BUCKET_RULE_2b_1: 'i: Shouldn’t be in the form of an IP address',
  T_GCP_BUCKET_RULE_2b_2:
    'ii: Can contain up to 222 characters, but each dot-separated component must not exceed 63 characters.',
  T_GCP_BUCKET_RULE_3: `Bucket names cannot contain "google" or close misspellings, such as "g00gle".`,
  T_CARE_PLAN: 'Care Plan Template',
  T_CARE_PLAN_LIST_BACK: 'Back to Care Plan list',
  T_CARE_PLAN_DETAILS_HEADER: 'CARE PLAN DETAILS',
  T_CARE_PLAN_COLLABORATORS: 'COLLABORATORS',
  T_CARE_PLAN_ASSIGNMENT: 'ASSIGNMENTS',
  T_CARE_PLAN_DETAILS: 'Care Plan Details',
  T_CARE_PLAN_ENCOUNTER: 'Encounter',
  T_CARE_PLAN_CATEGORY: 'Category',
  T_CARE_PLAN_AUTHORS: 'Author & contributors',
  T_CARE_PLAN_INTENT: 'Intent',
  T_CARE_PLAN_AUTHOR_CATEGORY: 'Author Category',
  T_CARE_PLAN_AUTHOR: 'Author',
  T_CARE_PLAN_CONTRIBUTOR_CATEGORY: 'Contributor Category',
  T_CARE_PLAN_CONTRIBUTOR: 'Contributor',
  T_CARE_PLAN_CONDITIONS: 'Conditions',
  T_CARE_PLAN_ADD_CONDITIONS: 'Add Conditions',
  T_CARE_PLAN_ORGANIZATION: 'Organizations',
  T_CARE_PLAN_ADD_ORGANIZATION: 'Add organizations',
  T_CARE_PLAN_CONNECTED_ORG: 'Organizations connected to this care plan:',
  T_CARETEAMS: 'Care teams',
  T_CARE_PLAN_CARE_TEAM: 'Care team',
  T_FILTER_KEY_STATUS: 'Status',
  T_FILTER_KEY_INTENT: 'Intent',
  T_FILTER_KEY_TITLE: 'Title',
  T_CONDITIONS: 'Conditions',
  T_NEW_CAREPLAN: 'New Care Plan',
  T_CREATE_REQUEST: 'Create Request',
  T_PAST_REQUESTS: 'Past Requests',
  T_SEARCH_BY_TEMPLATE_TITLE: 'Search by template title',
  T_SURVEY_ADD_SELECTED: 'Add selected',
  T_SURVEY_SEARCH_FOR_QUESTION: 'Search for a question',
  T_SURVEY_MAX_QUESTIONS_SELECTED: 'Max 25 questions can be selected.',
  T_CARE_TEAM_MEMBERS: 'Members in the %s:',
  T_CARE_TEAM_SELECTED: 'Selected Care Teams:',
  T_CARE_PLAN_CREATED_SUCCESS: 'Care Plan has been created',
  T_CARE_PLAN_UPDATED_SUCCESS: 'Care Plan %s updated successfully',
  T_CARE_PLAN_DELETED_SUCCESS: 'Care Plan deleted successfully',
  T_SERVER_ERROR: 'Server error. Could not save.',
  T_CARE_PLAN_DRAFT_HEADER: 'This Care Plan is a Draft',
  T_CARE_PLAN_ACTIVE_HEADER: 'This Care Plan is Active',
  T_CARE_PLAN_ONHOLD_HEADER: 'This Care Plan is On-Hold',
  T_CARE_PLAN_REVOKED_HEADER: 'This Care Plan has been Revoked',
  T_CARE_PLAN_COMPLETED_HEADER: 'This Care Plan is Completed',
  T_CARE_PLAN_ERROR_HEADER: 'This Care Plan is Entered-in-error',
  T_CARE_PLAN_UNKNOWN_HEADER: 'This Care Plan has an Unknown status',
  T_CARE_PLAN_SELECTION_REQUIRED: 'Please select an option',
  T_CARE_PLAN_START_DATE: 'Start Date',
  T_CARE_PLAN_END_DATE: 'End Date',
  T_CARE_PLAN_START_TIME: 'Start Time',
  T_CARE_PLAN_END_TIME: 'End Time',
  T_CARE_PLAN_DATE_PICKER: 'Select start & end date',
  T_CARE_PLAN_DELETE_PROMPT: 'Do you want to delete this Care Plan?',

  T_NEW_PATIENT_REQUEST_HEADER: 'Create Patient Data Request',
  T_NEW_PATIENT_REQUEST_NAME: 'Patient Name',
  T_NEW_PATIENT_REQUEST_EMAIL: 'Patient Email',
  T_NEW_PATIENT_REQUEST_CREATE_REQUEST: 'Create Request',
  T_NEW_PATIENT_REQUEST_TYPE: 'Data Request Type',
  T_NEW_PATIENT_REQUEST_TYPE_REQUEST: 'Data Request',
  T_NEW_PATIENT_REQUEST_TYPE_EXPORT: 'Data Export',
  T_NEW_PATIENT_REQUEST_TYPE_RECTIFICATION: 'Data Rectification',
  T_NEW_PATIENT_REQUEST_TYPE_RECTIFICATION_MESSAGE: 'Rectification Message',
  T_NEW_PATIENT_REQUEST_TYPE_DELETION: 'Data Deletion',
  T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION: 'Data Restriction',
  T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION_HEADER: 'Data Restriction Type',
  T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION_ENABLE: 'Enable Restriction',
  T_NEW_PATIENT_REQUEST_TYPE_RESTRICTION_DISABLE: 'Disable Restriction',
  T_NEW_PATIENT_REQUEST_ADDTIONAL_MESSAGE: 'Additional Message (Optional)',

  T_AUTOCOMPLETE_DEFAULT_SEARCH_TEXT: 'Search or add manually',

  T_SURVEY_UPDATE_ANSWER_CHOICE: 'Option saved successfully',
  T_SURVEY_LABEL_SELECTION: 'Label selection',
  T_SURVEY_UPDATE_LABEL_SELECTION: 'Update label',
  T_SURVEY_BODY_MAP_DELETE_CONFIRMATION_HEADER: 'Delete image',
  T_SURVEY_BODY_MAP_DELETE_CONFIRMATION: 'Do you want to delete this image?',
  T_SURVEY_BODY_MAP_DELETE_CONFIRMATION_YES: 'Yes, delete',
  T_SURVEY_ACTIVATED_SUCCESSFULLY: 'Survey activated',
  T_SURVEY_DRAFT_SUCCESSFULLY: 'Status changed to draft',
  T_SURVEY_RETIRED_SUCCESSFULLY: 'Survey retired',
  T_SURVEY_UNKNOWN_SUCCESSFULLY: 'Status changed to unknown',
  T_SURVEY_UPDATED_SUCCESSFULLY: 'Survey updated successfully',
  T_SURVEY_SET_DATES: 'Select date range',
  T_SURVEY_SELECT_START_AND_DATE: 'Select date range',
  T_SURVEY_SELECT_START_AND_DATE_INFO: 'Select a start & end date for displaying this survey.',
  T_SURVEY_SET_START_AND_DATE: 'Set date range',
  T_SURVEY_DELETE_SURVEY: 'Delete Survey',
  T_SURVEY_RE_EVALUATE_QUESTIONS: 'Re-evaluate Questions',
  T_SURVEY_CHANGE_STATUS: 'Change Status',
  T_SURVEY_REMOVE_SET_DATES: 'Remove date range',
  T_SURVEY_START_DATE: 'Start date',
  T_SURVEY_END_DATE: 'End date',
  T_SURVEY_CONFIRM_START_END_DATE_HEADER: 'Remove date range',
  T_SURVEY_CONFIRM_START_END_DATE_BODY: 'Do you want to remove date range?',
  T_SURVEY_REMOVE_SET_DATES_PROMPT_YES: 'Yes',
  T_SURVEY_BODY_MAP_MAX_FILES_ERROR: 'Max 2 files allowed',
  T_SURVEY_STATUS_FILTER: 'Status',

  T_YES_DELETE: 'Yes, Delete',
  T_NO_CANCEL: 'No, Cancel',
  T_SEARCH_NAME_CARE_PLAN_TITLE_TEMPLATE: 'Search by care plan template title',
  T_FILTER_KEY_CARETEAMS: 'Care Teams',
  T_FILTER_KEY_CONDITIONS: 'Conditions',

  T_REMOVE_ALL: 'Remove All'
};

export default translationLocalEN;
